import React from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import cn from 'clsx';
import config from 'config';

import Spoiler from 'components/Spoiler';

import InstitutionPresenter from 'presenters/InstitutionPresenter';

const InstitutionsList = (props) => {
  const { institutions } = props;

  const renderInstitution = (institution) => {
    const className = cn({
      [config.changesHighlightsClassName]: InstitutionPresenter.isRecentlyUpdated(institution),
      maskedText: InstitutionPresenter.isMasked(institution),
    });

    return (
      <li key={institution.id} className={className}>
        {isPresent(InstitutionPresenter.degree(institution)) && `${InstitutionPresenter.degree(institution)}, `}
        {InstitutionPresenter.organizationName(institution)}
        {` ${InstitutionPresenter.timeInterval(institution)}`}
      </li>
    );
  };

  return (
    <section id="education">
      <Spoiler header="Education History">
        <ul>{institutions.map(renderInstitution)}</ul>
      </Spoiler>
    </section>
  );
};

InstitutionsList.propTypes = {
  institutions: PropTypes.arrayOf(InstitutionPresenter.shape()).isRequired,
};

export default InstitutionsList;
