import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Box } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import AliasEditForm from 'forms/organization/AliasEditForm';

import styles from './AliasForm.module.css';

export default class AliasForm extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  state = { alias: AliasEditForm.defaultAttributes(), errors: null };

  setErrors = ({ errors }) => this.setState({ ...this.state, errors });

  resetForm = () => this.setState({ ...this.state, alias: AliasEditForm.defaultAttributes(), errors: null });

  changeFormField = (fieldName, value) =>
    this.setState({ ...this.state, alias: { ...this.state.alias, [fieldName]: value } });

  handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.changeFormField(fieldName, value);

  handleSubmitForm = () => {
    const { onSubmit } = this.props;
    const { alias } = this.state;
    const form = AliasEditForm.attributesToSubmit(alias);

    return onSubmit(form).then(this.resetForm).catch(this.setErrors);
  };

  render() {
    const { editable, saving } = this.props;
    const { alias, errors } = this.state;
    const { title } = alias;

    return (
      <div className={styles.form}>
        <VerticalFormFieldWithBottomErrors
          className={styles.field}
          errors={errors && errors.title}
          label="Add New Organization Name Alias"
          htmlFor="alias"
        >
          <TextInput onChange={this.handleFieldChange('title')} value={title} id="alias" />
        </VerticalFormFieldWithBottomErrors>

        <Box margin={{ left: 'small', bottom: 'small' }}>
          <Button
            className={styles.button}
            label="Add"
            primary
            onClick={this.handleSubmitForm}
            disabled={isBlank(title) || !editable || saving}
          />
        </Box>
      </div>
    );
  }
}
