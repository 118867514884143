import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil, equals } from 'ramda';

import Table from 'components/Table';

import Routes from 'routes/App';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import OrganizationRowCells from './components/OrganizationRowCells';
import OrganizationPolicy from 'policies/OrganizationPolicy';

class OrganizationList extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    bulkDestroyOrganizations: PropTypes.func.isRequired,
    filterOrganizations: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadOrganizations: PropTypes.func.isRequired,
    organizations: PropTypes.arrayOf(OrganizationPresenter.shape()).isRequired,
    nextPage: PropTypes.number,
    filters: PropTypes.shape(),
    totalCount: PropTypes.number,
    onSortChange: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { filters, filterOrganizations } = this.props;

    filterOrganizations({ ...filters });
  }

  componentDidUpdate(prevProps) {
    const { filters, filterOrganizations } = this.props;

    if (!equals(prevProps.filters, filters)) {
      filterOrganizations({ ...filters });
    }
  }

  tableColumns = [
    {
      name: 'id',
      field: 'id',
      width: '125',
    },
    {
      name: 'status',
      field: 'has_published',
    },
    {
      name: 'organization',
      field: 'draftVersionName',
    },
    {
      name: 'industry',
      field: 'draftVersionIndustryName',
    },
    {
      name: 'ticker',
      field: 'draftVersionTicker',
    },
    {
      name: 'Profiles',
      field: 'assotiatedProfilesCount',
    },
    {
      name: 'logo',
      field: 'draftVersionLogoPresent',
    },
  ];

  handleLoadMore = () => {
    const { loading, nextPage, loadOrganizations, filters } = this.props;

    if (!loading && !this.isAllOrganizationsLoaded()) {
      loadOrganizations(nextPage, { ...filters });
    }
  };

  isAllOrganizationsLoaded() {
    return isNil(this.props.nextPage);
  }

  filterOrganizations = (fieldName, value) => {
    const { filters, filterOrganizations } = this.props;

    filterOrganizations({ ...filters, [fieldName]: value });
  };

  handleDelete = (ids) => {
    const { bulkDestroyOrganizations, filterOrganizations, filters } = this.props;

    bulkDestroyOrganizations({ ids }).then(() => filterOrganizations(filters));
  };

  handleOrganizationClick = (organizationId) => {
    const { currentUser } = this.props;

    if (OrganizationPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminOrganizationPath(organizationId));
    }
  };

  render() {
    const { currentUser, organizations, totalCount, filters, onSortChange, loading } = this.props;

    return (
      <Table
        columns={this.tableColumns}
        sorts={filters.sortFields}
        onDelete={this.handleDelete}
        onMore={this.handleLoadMore}
        onRowClick={this.handleOrganizationClick}
        onSort={onSortChange}
        hasMore={!this.isAllOrganizationsLoaded()}
        rows={organizations}
        rowCellsComponent={<OrganizationRowCells />}
        totalRows={totalCount}
        rowsDeletable={OrganizationPolicy.canDestroyElement(currentUser)}
        loading={loading}
      />
    );
  }
}

export default OrganizationList;
