import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CompaniesListPage from './CompaniesListPage';
import * as actions from './CompaniesListPageActions';

const mapStateToProps = (state) => ({
  loading: state.CompaniesListPageReducer.loading,
  companies: state.CompaniesListPageReducer.companies,
  nextPage: state.CompaniesListPageReducer.nextPage,
  filters: state.CompaniesListPageReducer.filters,
  saving: state.CompaniesListPageReducer.saving,
  totalCount: state.CompaniesListPageReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesListPage);
