import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import Tooltip from 'rc-tooltip';

import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter from 'presenters/UserPresenter';

import CircleInformationIcon from 'icons/CircleInformationIcon';
import Link from 'components/v2/Link';

import styles from './Row.module.css';

const Row = (props) => {
  const { request } = props;
  const requestor = RequestPresenter.requestor(request);
  const isPresentProfile = RequestPresenter.isDelivered(request) && RequestPresenter.slug(request);

  const renderStateName = () => {
    if (RequestPresenter.isDelivered(request) || RequestPresenter.isCanceled(request)) {
      return RequestPresenter.stateName(request);
    }

    return 'In Process';
  };

  return (
    <tr>
      <td>{UserPresenter.fullName(requestor)}</td>
      <td>
        {isPresentProfile && (
          <Link
            className={styles.linkToProfile}
            href={Routes.profilePath(RequestPresenter.slug(request))}
            target="_blank"
          >
            {RequestPresenter.subjectFullName(request)}
          </Link>
        )}
        {!isPresentProfile && RequestPresenter.subjectFullName(request)}
      </td>
      <td>{RequestPresenter.subjectJobTitle(request)}</td>
      <td>{RequestPresenter.subjectOrganizationName(request)}</td>
      <td>
        <div className={styles.statusWrapper}>
          <span className={styles.statusName}>{renderStateName(request)}</span>
          {RequestPresenter.isCanceled(request) && (
            <Tooltip
              placement="top"
              trigger={['hover']}
              overlay={<span>{RequestPresenter.humanCancelationReason(request)}</span>}
              overlayStyle={{ width: 150, wordBreak: 'break-word' }}
            >
              <div>
                <CircleInformationIcon width={12} height={12} className={styles.statusIcon} />
              </div>
            </Tooltip>
          )}
        </div>
      </td>
      <td>{RequestPresenter.formattedCreatedAt(request)}</td>
      <td>{RequestPresenter.formattedDeliveredAt(request)}</td>
      <td>{RequestPresenter.humanRequestType(request)}</td>
    </tr>
  );
};

Row.propTypes = {
  request: PropTypes.shape(),
};

export default Row;
