import TopJobFunctionsConstants from './TopJobFunctionsConstants';
import TopJobFunctionsRepository from 'repositories/CurrentUser/Project/TopJobFunctionsRepository';

export function loadTopJobFunctions(projectId) {
  return (dispatch) => {
    dispatch({ type: TopJobFunctionsConstants.LOAD_PROJECT_TOP_JOB_FUNCTIONS_START });

    return TopJobFunctionsRepository.index(projectId).then(({ items }) => {
      dispatch({ type: TopJobFunctionsConstants.LOAD_PROJECT_TOP_JOB_FUNCTIONS_SUCCESS, jobFunctions: items });

      return items;
    });
  };
}
