import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Languages Tags</strong>
      </p>
      <ul>
        <li>Tag every language spoken.</li>
        <li>English is implied at this point, so do not tag English.</li>
        <li>
          Do not assume anything. Make sure to confirm that they actually speak a language, either because you see it on
          LinkedIn, or because you have confirmed that they grew up in a certain country—though be careful with
          countries like India where there are so many languages spoken.
        </li>
      </ul>
    </div>
  );
}
