import CompanyDepartmentUserRegistrationConstants from './CompanyDepartmentUserRegistrationConstants';

const initialState = {
  companyDepartment: null,
  loading: false,
  saving: false,
};

function CompanyDepartmentUserRegistrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CompanyDepartmentUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_DEPARTMENT_START: {
      return { ...initialState, loading: true };
    }
    case CompanyDepartmentUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_DEPARTMENT_SUCCESS: {
      const { companyDepartment } = action;

      return { ...state, companyDepartment, loading: false };
    }
    case CompanyDepartmentUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_DEPARTMENT_ERROR: {
      return { ...state, loading: false };
    }
    case CompanyDepartmentUserRegistrationConstants.CREATE_COMPANY_DEPARTMENT_USER_START: {
      return { ...state, saving: true };
    }
    case CompanyDepartmentUserRegistrationConstants.CREATE_COMPANY_DEPARTMENT_USER_SUCCESS: {
      return { ...state, saving: false };
    }
    case CompanyDepartmentUserRegistrationConstants.CREATE_COMPANY_DEPARTMENT_USER_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default CompanyDepartmentUserRegistrationReducer;
