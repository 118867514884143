import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(projectId, params) {
    const url = Routes.apiV1CurrentUserProjectSharesPath(projectId, params);

    return JsonFetchHelpers.get(url);
  },

  suggestions(projectId, params) {
    const url = Routes.suggestionsApiV1CurrentUserProjectSharesPath(projectId, params);

    return JsonFetchHelpers.get(url);
  },

  batchUpdate(projectId, shares) {
    const url = Routes.batchUpdateApiV1CurrentUserProjectSharesPath(projectId);

    return JsonFetchHelpers.put(url, shares);
  },

  destroy(projectId, shareId) {
    const url = Routes.apiV1CurrentUserProjectSharePath(projectId, shareId);

    return JsonFetchHelpers.delete(url);
  },
};
