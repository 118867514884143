import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProjectPage from './ProjectPage';
import * as actions from 'containers/UserPanel/containers/Project/ProjectActions';

const mapStateToProps = (state) => ({
  saving: state.ProjectReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
