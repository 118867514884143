import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';

import Link from 'components/v2/Link';
import Logotype from 'components/v2/Logotype';
import Routes from 'routes/App';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import styles from './AdminHeader.module.css';

export default class AdminHeader extends Component {
  handleLogOut = () => {
    const { onSignOut } = this.props;

    onSignOut();
  };

  render() {
    const { onStopImpersonate, currentUser } = this.props;
    return (
      <div className={styles.header}>
        <Link href={Routes.adminRootPath()}>
          <Logotype />
        </Link>
        <div className={styles.nav}>
          <ul>
            {CurrentUserPresenter.isImpersonating(currentUser) ? (
              <li>
                <Anchor onClick={onStopImpersonate}>Stop Impersonate</Anchor>
              </li>
            ) : null}
            <li>
              <Anchor href={Routes.rootPath()}>View App</Anchor>
            </li>
            <li>
              <span className={styles.separator} />
            </li>
            <li>
              <Anchor onClick={this.handleLogOut}>
                <strong> Logout</strong>
              </Anchor>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

AdminHeader.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  onSignOut: PropTypes.func.isRequired,
  onStopImpersonate: PropTypes.func.isRequired,
};
