import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { TextInput, Box } from 'grommet';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import TagEditForm from 'forms/TagEditForm';
import TagPresenter from 'presenters/TagPresenter';
import AsyncSelect from 'components/AsyncSelect';

import styles from './EditForm.module.css';

class EditForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    tag: TagPresenter.shape(),
    onTagSearch: PropTypes.func.isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      tag: this.props.tag,
      errors: null,
    };
  }

  handleUserChange = (parent) => {
    this.setState({ tag: { ...this.state.tag, parent } });
  };

  handleSubmit = () => {
    const { tag } = this.state;

    this.props.onSubmit(TagEditForm.attributesToSubmit(tag)).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  handleTagSearch = reactSelectDebounce(this.props.onTagSearch);

  updateField(fieldName, value) {
    this.setState({ tag: { ...this.state.tag, [fieldName]: value } });
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  render() {
    const { saving } = this.props;
    const { tag, errors } = this.state;

    return (
      <>
        {errors && errors.base && (
          <div className={styles.danger}>
            {' '}
            {errors.base.map((error) => (
              <span> {error} </span>
            ))}{' '}
          </div>
        )}

        <VerticalFormFieldWithBottomErrors label="Title:" errors={errors && errors.title} htmlFor="title">
          <TextInput value={tag.title} onChange={this.handleTextFieldChange('title')} id="title" />
        </VerticalFormFieldWithBottomErrors>

        <AsyncSelect
          loadOptions={this.handleTagSearch}
          getOptionValue={TagPresenter.id}
          getOptionLabel={TagPresenter.fullTitle}
          defaultOptions
          onChange={this.handleUserChange}
          placeholder="Parent Tag"
          value={tag.parent}
          isClearable
        />

        <Box direction="row" margin={{ top: 'medium' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </>
    );
  }
}

export default EditForm;
