import React, { Component } from 'react';
import { any, isNil, equals } from 'ramda';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import ProfileRowCells from './components/ProfileRowCells';

import Routes from 'routes/App';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import ProfilePolicy from 'policies/ProfilePolicy';

class ProfileList extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
    loadProfiles: PropTypes.func.isRequired,
    bulkDestroyProfiles: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    filterProfiles: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    totalCount: PropTypes.number,
    tableColumns: PropTypes.arrayOf(PropTypes.shape()),
    rowsDeletable: PropTypes.bool,
    onSortChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    rowsDeletable: true,
  };

  componentDidMount() {
    const { filters, filterProfiles } = this.props;

    filterProfiles(filters);
  }

  componentDidUpdate(prevProps) {
    const { filters, filterProfiles } = this.props;

    if (!equals(prevProps.filters, filters)) {
      filterProfiles(filters);
    }
  }

  isAllProfilesLoaded = () => {
    const { nextPage } = this.props;

    return isNil(nextPage);
  };

  canDeleteSelectedRows = (ids) => {
    const { profiles } = this.props;

    return !any(
      (profile) => ids.includes(ProfilePresenter.id(profile)) && ProfilePresenter.optedOut(profile),
      profiles,
    );
  };

  handleLoadMore = () => {
    const { loading, nextPage, loadProfiles, filters } = this.props;

    if (!loading && !this.isAllProfilesLoaded()) {
      loadProfiles(nextPage, filters);
    }
  };

  handleDelete = (ids) => {
    const { bulkDestroyProfiles, filterProfiles, filters } = this.props;

    bulkDestroyProfiles({ ids }).then(() => filterProfiles(filters));
  };

  handleProfileClick = (profileId) => {
    const { currentUser } = this.props;

    if (ProfilePolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminProfilePath(profileId));
    }
  };

  render() {
    const { currentUser, filters, profiles, rowsDeletable, tableColumns, totalCount, onSortChange } = this.props;

    return (
      <Table
        columns={tableColumns}
        canDeleteSelectedRows={this.canDeleteSelectedRows}
        sorts={filters.sortFields}
        onDelete={this.handleDelete}
        onMore={this.handleLoadMore}
        onRowClick={this.handleProfileClick}
        onSort={onSortChange}
        hasMore={!this.isAllProfilesLoaded()}
        rows={profiles}
        rowCellsComponent={<ProfileRowCells columns={tableColumns} />}
        totalRows={totalCount}
        rowsDeletable={rowsDeletable && ProfilePolicy.canDestroyElement(currentUser)}
      />
    );
  }
}

export default ProfileList;
