import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'containers/UserPanel/containers/Project/containers/AvailableProfilesAndColleaguesContainer'; // eslint-disable-line import/named
import Component from 'containers/UserPanel/containers/Project/containers/RelationshipsMapping/components/ExcludeProfilesForm';

const mapStateToProps = (state) => ({
  loading: state.AvailableProfilesAndColleaguesContainerReducer.loading,
  profiles: state.AvailableProfilesAndColleaguesContainerReducer.profiles,
  totalCount: state.AvailableProfilesAndColleaguesContainerReducer.totalCount,
  nextPage: state.AvailableProfilesAndColleaguesContainerReducer.nextPage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      filterProjectProfiles: actions.filterProjectAvailableProfilesAndColleagues,
      loadProjectProfiles: actions.loadProjectAvailableProfilesAndColleagues,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Component);
