import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  batchCreate(attributes) {
    const url = Routes.batchCreateApiV1CustomerRequestsPath();

    return FetchHelpers.post(url, attributes);
  },
};
