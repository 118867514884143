import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { assetPath } from 'utils/AssetsHelper';
import { isBlank } from 'utils/HelperMethods';
import { logClickOnAudienceInsightsShare } from 'utils/amplitude';

import LineChart from './components/LineChart';
import LocationMap from './components/LocationMap';
import Spinner from 'components/Spinner';
import ExportButton from 'containers/UserPanel/components/ExportButton';
import MostConnectedExecutives from './components/MostConnectedExecutives';
import Toplists from './components/Toplists';
import Header from 'containers/UserPanel/containers/Project/components/Header';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';

import ProjectPolicy from 'policies/ProjectPolicy';
import ProjectPresenter, {
  PROJECT_PROFILE_RELATIONSHIPS_PDF_EXPORT,
  PROJECT_AUDIENCE_INSIGHTS_PDF_EXPORT,
} from 'presenters/ProjectPresenter';
import AudienceInsightPresenter from 'presenters/Project/AudienceInsightPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import Routes from 'routes';

import styles from './AudienceInsights.module.css';

export default class AudienceInsights extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    loadAudienceInsights: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
    audienceInsight: AudienceInsightPresenter.shape(),
    loading: PropTypes.bool,
    onProjectDataExport: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      project: { id },
      loadAudienceInsights,
    } = this.props;
    loadAudienceInsights(id);
  }

  breadcrumbs() {
    const { project } = this.props;

    return [
      { name: 'Projects', to: Routes.projectsPath() },
      { name: ProjectPresenter.name(project), to: Routes.projectPath(ProjectPresenter.id(project)) },
      { name: 'Audience Insights' },
    ];
  }

  handleRelationshipsExport = (type) => (profile, search) => {
    const { onProjectDataExport } = this.props;
    const params = { type, params: { profileId: profile.id, search } };

    return onProjectDataExport(params);
  };

  handleExport = () => {
    const { onProjectDataExport } = this.props;

    logClickOnAudienceInsightsShare();

    return onProjectDataExport({ type: PROJECT_AUDIENCE_INSIGHTS_PDF_EXPORT });
  };

  render() {
    const { currentUser, loading, audienceInsight, project } = this.props;

    if (loading || isBlank(audienceInsight)) {
      return <Spinner />;
    }

    const organizationsCount = AudienceInsightPresenter.organizationsCount(audienceInsight);
    const profilesCount = AudienceInsightPresenter.profilesCount(audienceInsight);

    return (
      <>
        <Header
          currentUser={currentUser}
          title={`${ProjectPresenter.name(project)}: Audience Insights`}
          breadcrumbs={this.breadcrumbs()}
          project={project}
        >
          <BackgroundExportResultNotifier
            component={ExportButton}
            onExport={this.handleExport}
            onExportPropName="onClick"
            disabled={!ProjectPolicy.canExport(project)}
            label="Share"
          />
        </Header>
        <div className={styles.counters}>
          <div className={styles.counter}>
            <div className={styles.number}>{profilesCount}</div>
            <div className={styles.description}>Executives</div>
          </div>
          <div className={styles.counter}>
            <div className={styles.number}>{organizationsCount}</div>
            <div className={styles.description}>Accounts</div>
          </div>
        </div>
        <div className={styles.chart}>
          <LineChart data={audienceInsight} />
        </div>
        <Toplists audienceInsight={audienceInsight} project={project} />
        <MostConnectedExecutives
          project={project}
          profiles={AudienceInsightPresenter.mostConnectedExecutives(audienceInsight)}
          onRelationshipsExport={this.handleRelationshipsExport(PROJECT_PROFILE_RELATIONSHIPS_PDF_EXPORT)}
        />
        <LocationMap
          title="Executives map by location"
          map={assetPath('map.json')}
          data={AudienceInsightPresenter.workGeography(audienceInsight)}
        />
        <LocationMap
          title="Executives by country of origin"
          map={assetPath('countries_map.json')}
          data={AudienceInsightPresenter.originGeography(audienceInsight)}
        />
      </>
    );
  }
}
