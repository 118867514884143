import keyMirror from 'keymirror';

const CustomerUsageDashboardConstants = keyMirror({
  LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_SUCCESS: null,
  LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_ERROR: null,

  LOAD_ADMIN_CUSTOMER_USAGE_PUBLIC_API_STATISTICS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_PUBLIC_API_STATISTICS_SUCCESS: null,

  LOAD_ADMIN_CUSTOMER_USAGE_SEARCH_PROFILES_STATISTICS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_SEARCH_PROFILES_STATISTICS_SUCCESS: null,

  LOAD_ADMIN_CUSTOMER_USAGE_SIGN_IN_STATISTICS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_SIGN_IN_STATISTICS_SUCCESS: null,

  LOAD_ADMIN_CUSTOMER_USAGE_VIEWS_AND_DOWNLOADS_STATISTICS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_VIEWS_AND_DOWNLOADS_STATISTICS_SUCCESS: null,

  LOAD_ADMIN_CUSTOMER_USAGE_POWER_USERS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_POWER_USERS_SUCCESS: null,

  LOAD_ADMIN_CUSTOMER_USAGE_POPULAR_ORGANIZATIONS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_POPULAR_ORGANIZATIONS_SUCCESS: null,
});

export default CustomerUsageDashboardConstants;
