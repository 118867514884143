import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'clsx';
import { Button as GButton } from 'grommet';

import styles from './Button.module.css';

const Button = (props) => {
  const rootStyles = cn([styles.root], props.className);
  return (
    <GButton
      className={rootStyles}
      {...props}
      onClick={!props.disabled ? props.onClick : null}
      href={!props.disabled ? props.href : null}
      path={!props.disabled ? props.path : null}
      to={!props.disabled && props.href}
      as={!props.disabled && props.href && Link}
    />
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  path: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
