import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Summarize the executive’s current role and their career history.
      </p>
      <p>
        <strong>Example:</strong>
      </p>
      <p>
        Christine (Chris) McCarthy currently serves as SVP and CFO of Walt Disney Company, a role to which she was named
        in 2015. The first female CFO in the company’s history, McCarthy oversees the company’s worldwide finance
        organization, investor relations, corporate planning and control, tax, corporate treasury, corporate real
        estate, facilities, integrated supply chain management, and corporate citizenship. Most recently, the
        Massachusetts native served as Disney’s EVP, Corporate Real Estate, Alliances and Treasurer. Prior to joining
        Disney in 2000, McCarthy served as EVP and CFO of Imperial Bancorp. Early in her career she held various finance
        and planning positions at First Interstate Bancorp.
      </p>
      <p>
        <strong>Executive Summary Style Guidelines:</strong>
      </p>
      <ul>
        <li>Use full name in first instance; change to last name only in subsequent mentions</li>
        <li>Use full company name in first instance; can shorten in subsequent mentions</li>
        <li>Capitalize titles and company names</li>
        <li>Use abbreviations for C-Level role and VP roles</li>
        <li>Avoid anything too timely; the goal is for this section to be relatively evergreen</li>
        <ul>
          <li>
            For example: “has worked for Acme for 22 years” should be “has worked for Acme for more than two decades”
          </li>
          <li>Avoid including company information that will change every year, such as revenue, number of employees</li>
        </ul>
        <li>
          Do not go into lengthy descriptions of current responsibilities or past responsibilities in previous roles.
          Save the former for a “Current Role” current focus item; and do not delve into the latter unless there is
          something about past responsibilities that is particularly notable. For example, “In his previous CIO roles at
          United Airlines and Target, Jones was a strong advocate for outsourcing IT services and his outsourcing
          vendors of choice have been IBM and TCS.” While it might seem like this type of item would be a current focus
          issue, you should put it into the personal interests and attributes section in order to ensure it gets
          preserved if he moves on to another role in the future.
        </li>
        <li>Avoid anything too vague that will cause us to not know if the content is still accurate</li>
        <ul>
          <li>“was hired last year as CEO...” should be “was hired in 2017 as CEO”</li>
          <li>
            “Jones also serves on the board of the United Way” (this can change at any time—this kind of information
            should be limited to the “Other Boards and Organizations” section.)
          </li>
        </ul>
      </ul>
      <p>
        <strong>Pro Tip: </strong>
        <p>Add flavor by inserting little personal details (as long as they are evergreen) such as:</p>
      </p>
      <ul>
        <li>“Texas native Jones is the CEO of...”</li>
        <li>“Jones is a veteran retail IT executive, having spent his entire career in the industry.”</li>
        <li>“Born in the Philippines and raised in Florida, Jones is the current CEO of...”</li>
        <li>
          “Upon being named to his current role, Jones said, ‘I have long admired the Acme brand and am thrilled to be a
          part of its future...”
        </li>
        <li>“Jones is a company lifer, having first joined Acme back in 1989.”</li>
      </ul>
      <p>
        <strong>EU/UK Exceptions:</strong>
      </p>
      <ul>
        <li>For EU/UK profiles, avoid personal information such as birthplace. Stick to business issues only.</li>
      </ul>
      <p>
        <strong>Sources and Search Tips for This Section</strong>
      </p>
      <ul>
        <li>Search for LinkedIn profile and corporate biographies on corporate web pages</li>
        <li>Search for “Christine McCarthy” Disney 2020 and “Chris McCarthy” Disney 2020</li>
      </ul>
    </div>
  );
}
