import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Using descriptive headlines, link to articles, blog posts, and videos that would be helpful for anyone looking
        to learn about this executive. Extended interviews and long transcripts/articles that are too long for the
        current focus section should go here.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <ul>
        <li>Watch a June 2019 interview with CNBC</li>
        <li>Watch his keynote at AWS re:Invent November 2018</li>
        <li>Watch Bessant in an April 2019 testimony to the House Finance Committee</li>
        <li>Listen to Patel talk tech investments on a February 2019 podcast</li>
        <li>Listen to McGuire discuss his Irish heritage</li>
        <li>Read an in-depth interview about Benioff’s philanthropic efforts</li>
        <li>Read her May 2019 OpEd on tariffs in Bloomberg</li>
      </ul>

      <p>
        <strong>Other News & Interviews Style Guidelines:</strong>
      </p>
      <ul>
        <li>Start with the verb: Watch, Listen, Read</li>
        <li>Keep descriptions brief</li>
        <li>Use last name only when talking about the person</li>
        <li>Make sure every item has a date referenced</li>
        <li>Embed the source in the headline, when possible</li>
        <li>
          Embed the link in the headline and make sure to check “open in a new window” in the pop up where you put the
          link.
        </li>
        <li>
          Avoid “old” items (more than one year) unless they are evergreen (about their life, their family, their
          upbringing, a passion or pet cause (see last item in the list above). These items should be relatively
          evergreen (good for one year or more). So do not include something with too short of a shelf life; e.g. “Jones
          talks about the company’s plans for holiday 2019
        </li>
        <li>As you are updating profiles, delete old items and replace them with new ones as you find them</li>
        <li>
          We want five of these, maximum, on any one profile, so if you find something good and the profile already has
          five items, replace the oldest/least important item
        </li>
        <li>No bullets/numbers</li>
      </ul>
      <p>
        <strong>Sources and Search Tips for This Section</strong>
      </p>
      <ul>
        <li>As always, search for the name of the person, the company name and the current year.</li>
      </ul>
    </div>
  );
}
