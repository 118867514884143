import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfileRelationshipMappingAdvertisement from './ProfileRelationshipMappingAdvertisement';
import * as actions from './ProfileRelationshipMappingAdvertisementActions';

const mapStateToProps = (state) => ({
  loading: state.ProfileRelationshipMappingAdvertisementReducer.loading,
  relationships: state.ProfileRelationshipMappingAdvertisementReducer.relationships,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRelationshipMappingAdvertisement);
