import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(profileId, workId, work) {
    const url = Routes.apiV1AdminProfileDraftWorkPath(profileId, workId);
    return FetchHelpers.patch(url, { work });
  },

  create(profileId, work) {
    const url = Routes.apiV1AdminProfileDraftWorksPath(profileId);
    return FetchHelpers.post(url, { work });
  },

  destroy(profileId, workId) {
    const url = Routes.apiV1AdminProfileDraftWorkPath(profileId, workId);
    return FetchHelpers.delete(url);
  },
};
