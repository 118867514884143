import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { any, propEq, pathOr, values, flatten, pipe, uniq } from 'ramda';
import { components } from 'react-select';
import cn from 'clsx';
import { Spinner as Spinning } from 'grommet';
import Tooltip from 'rc-tooltip';

import { isBlank } from 'utils/HelperMethods';

import Button from 'components/Button';
import CreatableSelect from 'components/CreatableSelect';
import Icon from 'components/Icon';
import InfiniteScroll from 'components/InfiniteScroll';
import SelectControl from 'containers/UserPanel/containers/Project/containers/ProjectPage/containers/Deliverables/containers/ShareLink/containers/ShareModal/components/SelectControl';
import { Content, Actions } from 'containers/UserPanel/components/Modal';

import SharePresenter from 'presenters/Project/SharePresenter';

import styles from './ShareForm.module.css';

export default class ShareForm extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    nextPage: PropTypes.number,
    onFilterShares: PropTypes.func.isRequired,
    onLoadShareSuggestions: PropTypes.func.isRequired,
    onLoadShares: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    shares: PropTypes.arrayOf(SharePresenter.shape()).isRequired,
    shareWithUnregisteredUser: PropTypes.bool.isRequired,
  };

  state = { form: { shares: [] } };

  componentDidMount() {
    this.handleFilterShares();
  }

  handleLoadShareSuggestions = (emailCont) => {
    const { onLoadShareSuggestions, shareWithUnregisteredUser } = this.props;

    return onLoadShareSuggestions({ emailCont, per: 3 }).then(({ items, meta }) => {
      const { emailSuffixes } = meta;

      if (shareWithUnregisteredUser) {
        const suggestionsToInvite = emailSuffixes.map((suffix) => this.buildNewShare(`${emailCont}@${suffix}`));

        return [...items, ...suggestionsToInvite];
      }
      return items;
    });
  };

  handleLoadMoreShares = () => {
    const { isLoading, nextPage, onLoadShares } = this.props;

    if (!isLoading && !this.isAllSharesLoaded()) {
      onLoadShares({ page: nextPage });
    }
  };

  handleFilterShares = () => {
    const { onFilterShares } = this.props;

    return onFilterShares();
  };

  handleChangeShares = (sharesToAdd) => {
    const { form } = this.state;
    const sharesToRemove = form.shares.filter(SharePresenter.isPersisted);
    const newForm = { shares: [...sharesToRemove, ...sharesToAdd] };

    this.setState({ form: newForm });
  };

  handleRemoveShare = (share) => () =>
    this.setState({ form: { shares: [...this.state.form.shares, { ...share, _delete: true }] } });

  handleErrors = ({ errors }) => {
    if (isBlank(errors)) {
      return;
    }
    const { form } = this.state;
    const sharesErrors = pathOr({}, ['shares'], errors);
    const shares = form.shares.map((share, index) => ({ ...share, errors: sharesErrors[index] }));

    this.setState({ form: { ...form, shares } });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(form).catch(this.handleErrors);
  };

  isAllSharesLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  buttonTitle = () => {
    const { form } = this.state;
    const { shares } = form;
    const parts = [];

    if (any(SharePresenter.isPersisted, shares)) {
      parts.push('Save');
    }

    if (any(SharePresenter.isNewRecord, shares)) {
      parts.push('Share');
    }

    return parts.join(' and ') || 'Save';
  };

  isValidNewOption = () => false;

  buildNewShare = (email) => ({ id: null, userId: null, email, isAvailable: true });

  renderUnavailableTooltip = () => <span>Don&#39;t has access to projects.</span>;

  renderError = ({ errors, ...share }) => {
    if (isBlank(errors)) {
      return null;
    }

    const errorMessages = pipe(values, flatten, uniq)(errors);

    return (
      <span key={share.email}>
        <b> {share.email}</b> - {errorMessages}
      </span>
    );
  };

  renderOption = ({ children, ...props }) => {
    const isUnavailable = SharePresenter.isUnavailable(props.data);
    const className = cn({ [styles.disabledOption]: isUnavailable });

    return (
      <components.Option {...props}>
        <Tooltip overlay={this.renderUnavailableTooltip} trigger={isUnavailable ? ['hover'] : []} destroyTooltipOnHide>
          <span className={className}>
            {SharePresenter.withUnregisteredUser(props.data)
              ? `Invite "${props.data.email}" to create an account and share the project with`
              : children}
          </span>
        </Tooltip>
      </components.Option>
    );
  };

  renderMultiValueContainer = ({ children, ...props }) => (
    <components.MultiValueContainer {...props}>
      {isBlank(props.data.userId) && (
        <div className={styles.informationIcon}>
          <Icon name="information" />
        </div>
      )}
      {children}
    </components.MultiValueContainer>
  );

  renderNoOptions = () => null;

  renderLoader = () => (
    <div key="loader">
      <Spinning />
    </div>
  );

  render() {
    const { isLoading, isSaving, shares, shareWithUnregisteredUser } = this.props;
    const { form } = this.state;
    const newShares = form.shares.filter(SharePresenter.isNewRecord);
    const notMarkedForDeletionShares = shares.filter((share) => !any(propEq(share.id, 'id'), form.shares));
    const hasSharesWithUnregisteredUser = any(SharePresenter.withUnregisteredUser, newShares);

    return (
      <>
        <Content>
          <div>
            <div className={styles.title}>Share to:</div>
            <CreatableSelect
              className={styles.select}
              classNamePrefix="mailSelectBox"
              components={{
                Control: SelectControl,
                MultiValueContainer: this.renderMultiValueContainer,
                Option: this.renderOption,
              }}
              getOptionLabel={SharePresenter.email}
              getOptionValue={SharePresenter.email}
              inlinePlaceholder
              placeholder="Add people"
              isMulti
              isOptionDisabled={SharePresenter.isUnavailable}
              isValidNewOption={this.isValidNewOption}
              loadOptions={this.handleLoadShareSuggestions}
              noOptionsMessage={this.renderNoOptions}
              onChange={this.handleChangeShares}
              value={newShares}
            />
            <div className={styles.information}>
              {!shareWithUnregisteredUser && <span>Invite registered users at your company to view your project.</span>}
              {hasSharesWithUnregisteredUser && <span>Unregistered users will be invited to create an account.</span>}
              {form.shares.map(this.renderError)}
            </div>
            <div className={styles.title}>Already Shared With:</div>
            <div className={styles.shares}>
              <InfiniteScroll
                loadMore={this.handleLoadMoreShares}
                hasMore={!this.isAllSharesLoaded()}
                loader={this.renderLoader()}
                useWindow={false}
                researchForScrollParent
              >
                {notMarkedForDeletionShares.map((share) => (
                  <div key={share.email} className={styles.share}>
                    {share.email}
                    <Button className={styles.removeShareButton} key={share.id} onClick={this.handleRemoveShare(share)}>
                      <Icon name="close" />
                    </Button>
                  </div>
                ))}
                {!isLoading && notMarkedForDeletionShares.length === 0 && (
                  <span className={styles.noItems}>Project isn&#39;t shared with anyone</span>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </Content>

        <Actions>
          <Button primary onClick={this.handleSubmit} disabled={isSaving} label={this.buttonTitle()} />
        </Actions>
      </>
    );
  }
}
