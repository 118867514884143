import React from 'react';
import { useHistory } from 'react-router-polyfill';

import Routes from 'routes';

import CompanyPresenter from 'presenters/CompanyPresenter';
import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';
import LimitedAccessPresenter from 'presenters/Company/Department/LimitedAccessPresenter';

import ActionsMenu, { MenuItem } from 'components/v2/ActionsMenu';
import Link from 'components/v2/Link';

const Row = (props) => {
  const { company, companyDepartment } = props;

  const history = useHistory();

  const limitedAccess = CompanyDepartmentPresenter.limitedAccess(companyDepartment);
  const isDatabaseOnly = CompanyDepartmentPresenter.databaseOnly(companyDepartment);
  const isLimitedAccessEnabled = LimitedAccessPresenter.isEnabled(limitedAccess);
  const isProjectsAvailable = CompanyDepartmentPresenter.projectsAvailable(companyDepartment);
  const isProjectsExportEnabled = CompanyDepartmentPresenter.projectsExportEnabled(companyDepartment);
  const remainingRequestsNumber = CompanyDepartmentPresenter.requestsNumber(companyDepartment);
  const purchasedRequestsNumber = CompanyDepartmentPresenter.purchasedRequestsNumber(companyDepartment);
  const projectsNumber = CompanyDepartmentPresenter.projectsNumber(companyDepartment);
  const purchasedProjectsNumber = CompanyDepartmentPresenter.purchasedProjectsNumber(companyDepartment);
  const seatsNumber = CompanyDepartmentPresenter.seatsNumber(companyDepartment);
  const activeSeatsNumber = CompanyDepartmentPresenter.activeSeatsNumber(companyDepartment);

  const handleClick = () => history.push(Routes.editAdminCompanyDepartmentPath(company.id, companyDepartment.id));

  return (
    <tr onClick={handleClick}>
      <td>{CompanyDepartmentPresenter.name(companyDepartment)}</td>
      <td>{`${activeSeatsNumber}/${seatsNumber}`}</td>
      <td>{CompanyDepartmentPresenter.humanAccoutType(companyDepartment)}</td>
      <td>{isDatabaseOnly ? 'Yes' : 'No'}</td>
      <td>{isLimitedAccessEnabled ? 'Yes' : 'No'}</td>
      <td>{`${remainingRequestsNumber}/${purchasedRequestsNumber}`}</td>
      <td>{isProjectsAvailable ? 'Yes' : 'No'}</td>
      <td>{`${projectsNumber}/${purchasedProjectsNumber}`}</td>
      <td>{isProjectsExportEnabled ? 'Yes' : 'No'}</td>
      <td>
        <ActionsMenu>
          <MenuItem>
            <Link to={Routes.editAdminCompanyDepartmentPath(company.id, companyDepartment.id)}>Edit</Link>
          </MenuItem>
          <MenuItem>
            <Link to={Routes.editAdminCompanyDepartmentSelfRegistrationFormPath(company.id, companyDepartment.id)}>
              Edit Self Registration Form
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={Routes.editAdminCompanyDepartmentLimitedAccessPath(company.id, companyDepartment.id)}>
              Edit Limited Access Settings
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={Routes.editAdminCompanyDepartmentApiV2AccessSettingsPath(company.id, companyDepartment.id)}>
              Edit API v2 Access Settings
            </Link>
          </MenuItem>
        </ActionsMenu>
      </td>
    </tr>
  );
};

Row.propTypes = {
  company: CompanyPresenter.shape().isRequired,
  companyDepartment: CompanyDepartmentPresenter.shape().isRequired,
};

export default Row;
