import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/AsyncSelect';

const SORT_OPTIONS = [
  {
    label: 'Recently Updated',
    value: 'updated_at',
  },
  {
    label: 'Most Viewed',
    value: 'views',
  },
  {
    label: 'Executives A-Z',
    value: 'full_name',
  },
  {
    label: 'Companies A-Z',
    value: 'current_organization_name',
  },
];

export const DEFAULT_SORT_OPTION = SORT_OPTIONS[0];

const SortSelect = (props) => {
  const { onChange, placeholder, sort } = props;
  const currentSort = SORT_OPTIONS.find((s) => s.value === sort);

  return (
    <Select
      placeholder={placeholder}
      defaultOptions={SORT_OPTIONS}
      value={currentSort}
      onChange={onChange}
      classNamePrefix="search-sort"
    />
  );
};

SortSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  sort: PropTypes.string,
};

export default SortSelect;
