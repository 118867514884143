import LanguagePageConstants from './LanguagePageConstants';
import LanguagesRepository from 'repositories/admin/LanguagesRepository';
import { addNotification } from 'containers/Notifications';

export function loadLanguage({ id }) {
  return (dispatch) => {
    dispatch({ type: LanguagePageConstants.LOAD_LANGUAGE_START });

    return LanguagesRepository.show(id)
      .then(({ language }) => {
        dispatch({ type: LanguagePageConstants.LOAD_LANGUAGE_SUCCESS, language });
      })
      .catch((response) => {
        dispatch({ type: LanguagePageConstants.LOAD_LANGUAGE_ERROR });
        throw response;
      });
  };
}

export function updateLanguage(id, params) {
  return (dispatch) => {
    dispatch({ type: LanguagePageConstants.UPDATE_LANGUAGE_START });

    return LanguagesRepository.update(id, params)
      .then(({ language }) => {
        dispatch({ type: LanguagePageConstants.UPDATE_LANGUAGE_SUCCESS, language });
        addNotification('Language was successfully saved', 'normal')(dispatch);
      })
      .catch((json) => {
        dispatch({ type: LanguagePageConstants.UPDATE_LANGUAGE_ERROR });
        throw json;
      });
  };
}
