import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export const THIS_WEEK_TIMEFRAME_VALUE = 'this_week';
export const THIS_MONTH_TIMEFRAME_VALUE = 'this_month';
export const THIS_QUARTER_TIMEFRAME_VALUE = 'this_quarter';
export const THIS_YEAR_TIMEFRAME_VALUE = 'this_year';
export const LAST_WEEK_TIMEFRAME_VALUE = 'last_week';
export const LAST_MONTH_TIMEFRAME_VALUE = 'last_month';
export const LAST_QUARTER_TIMEFRAME_VALUE = 'last_quarter';
export const LAST_YEAR_TIMEFRAME_VALUE = 'last_year';

export const TIMEFRAMES = [
  { value: THIS_WEEK_TIMEFRAME_VALUE, label: 'This week' },
  { value: THIS_MONTH_TIMEFRAME_VALUE, label: 'This month' },
  { value: THIS_QUARTER_TIMEFRAME_VALUE, label: 'This quarter' },
  { value: THIS_YEAR_TIMEFRAME_VALUE, label: 'This year' },
  { value: LAST_WEEK_TIMEFRAME_VALUE, label: 'Last week' },
  { value: LAST_MONTH_TIMEFRAME_VALUE, label: 'Last month' },
  { value: LAST_QUARTER_TIMEFRAME_VALUE, label: 'Last quarter' },
  { value: LAST_YEAR_TIMEFRAME_VALUE, label: 'Last year' },
];

export default new Presenter(
  {
    value: PropTypes.string,
    label: PropTypes.string,
  },
  {},
);
