import ProfileContainerConstants from './ProfileContainerConstants';
import PublishedProfilesRepository from 'repositories/PublishedProfilesRepository';
import IdentifiersRepository from 'repositories/relsci/IdentifiersRepository';
import ProfileUpdateRequestRepository from 'repositories/Profile/UpdateRequestRepository';
import CurrentUserSubscriptionRepository from 'repositories/CurrentUser/SubscriptionsRepository';

export function loadIdentifiers(params = {}) {
  return (dispatch) =>
    IdentifiersRepository.index(params).then(({ data }) => {
      dispatch({
        type: ProfileContainerConstants.LOAD_RELSCI_PROFILE_SUCCESS,
        data,
      });
    });
}

export function loadProfile(id, params = {}) {
  return (dispatch) => {
    dispatch({ type: ProfileContainerConstants.LOAD_CUSTOMER_PROFILE_START });

    return PublishedProfilesRepository.show(id, params).then(({ profile, meta }) => {
      dispatch({
        type: ProfileContainerConstants.LOAD_CUSTOMER_PROFILE_SUCCESS,
        profile,
        meta,
      });

      return profile;
    });
  };
}

export function requestProfileUpdate(id) {
  return (dispatch) => {
    dispatch({ type: ProfileContainerConstants.REQUEST_CUSTOMER_PROFILE_UPDATE_START });

    return ProfileUpdateRequestRepository.create(id)
      .then((request) => {
        dispatch({ type: ProfileContainerConstants.REQUEST_CUSTOMER_PROFILE_UPDATE_SUCCESS });

        return request;
      })
      .catch((error) => {
        dispatch({ type: ProfileContainerConstants.REQUEST_CUSTOMER_PROFILE_UPDATE_ERROR });

        throw error;
      });
  };
}

export function updateBatchSubscriptions(subscriptionsList) {
  return (dispatch) => {
    dispatch({ type: ProfileContainerConstants.UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_START });

    return CurrentUserSubscriptionRepository.batchUpdate(subscriptionsList)
      .then(({ subscriptions, meta }) => {
        dispatch({
          type: ProfileContainerConstants.UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_SUCCESS,
          subscriptions,
          meta,
        });

        return subscriptions;
      })
      .catch(({ errors }) => {
        dispatch({ type: ProfileContainerConstants.UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_ERROR });

        throw errors;
      });
  };
}
