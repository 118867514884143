import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs';

import styles from './Header.module.css';

class Header extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, path: PropTypes.string })),
  };

  render() {
    const { breadcrumbs, title } = this.props;

    return (
      <>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs items={breadcrumbs} />
        </div>
        <div className={styles.headline}>
          <div className={styles.headlineTitle}>{title}</div>
        </div>
      </>
    );
  }
}

export default Header;
