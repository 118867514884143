import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { isNil } from 'ramda';

import { extendFormProps, isFunction } from './utils';

import Context from './Context';

const buildChildComponent = ({ Component, children, extendedFormProps }) => {
  if (!isNil(Component)) {
    return <Component {...extendedFormProps} />;
  }

  return isFunction(children) ? children(extendedFormProps) : children;
};

const Form = (props) => {
  const { api, children, component: Component, onSubmit, ...restProps } = props;

  return (
    <Formik {...restProps} onSubmit={onSubmit}>
      {(formProps) => {
        const extendedFormProps = extendFormProps(api, formProps);
        const childComponent = buildChildComponent({ Component, children, extendedFormProps });

        return <Context.Provider value={extendedFormProps}>{childComponent}</Context.Provider>;
      }}
    </Formik>
  );
};

Form.propTypes = {
  api: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  component: PropTypes.elementType,
  onSubmit: PropTypes.func.isRequired,
  validateOnChange: PropTypes.bool,
};

Form.defaultProps = {
  api: false,
  children: null,
  component: null,
  validateOnChange: false,
};

export default Form;
