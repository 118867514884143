import { adminCompanyLegalSettingRestHooks } from 'slices/admin/company/legalSettingSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useLegalSetting = () => {
  const { legalSetting, loadLegalSetting, updateLegalSetting } = adminCompanyLegalSettingRestHooks.use();

  return {
    legalSetting: legalSetting.item,
    legalSettingLoadingStatus: useFetchStatus(legalSetting.loadingStatus),
    legalSettingUpdatingStatus: useFetchStatus(legalSetting.updatingStatus),
    loadLegalSetting,
    updateLegalSetting,
  };
};

export default useLegalSetting;
