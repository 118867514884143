import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from 'components/Card';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';
import Parent from './components/Parent';
import Children from './components/Children';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './ParentChildRelationship.module.css';

export default class ParentChildRelationship extends Component {
  static propTypes = {
    childs: PropTypes.arrayOf(OrganizationPresenter.shape()).isRequired,
    isChanged: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
    parent: OrganizationPresenter.shape(),
  };

  render() {
    const { childs, isChanged, isEditable, isSaving, onChange, onReviewChanges, parent } = this.props;

    return (
      <Card>
        <HeadlineWithReviewChangesLink
          strong
          size="small"
          margin="none"
          title="Parent - Child Relationship"
          isChanged={isChanged}
          onReviewChanges={onReviewChanges}
        />
        <div className={styles.columns}>
          <div className={styles.column}>
            <Parent
              isEditable={isEditable}
              isSaving={isSaving}
              onParentAssign={onChange}
              onParentUnassign={onChange}
              parent={parent}
            />
          </div>
          <div className={styles.column}>
            <Children childs={childs} />
          </div>
        </div>
      </Card>
    );
  }
}
