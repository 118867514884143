import React from 'react';
import Routes from 'routes';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import WorkPresenter from 'presenters/WorkSearchPresenter';

import Link from 'components/v2/Link';
import ProfileAvatar from 'components/ProfileAvatar';
import Typography from 'components/v2/Typography';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';

import styles from './ProfileSuggestion.module.css';

const ProfileSuggestion = (props) => {
  const { profile } = props;

  const profileSlug = Routes.profilePath(ProfilePresenter.slug(profile));
  const profileFullName = ProfilePresenter.fullName(profile);
  const profileCurrentWorks = ProfilePresenter.currentWorks(profile);

  return (
    <Link className={styles.suggestion} href={profileSlug} tabIndex="-1" target="_blank">
      {!profile.isAvailableProfile && <div className={styles.cover} />}
      <div className={styles.content}>
        <ProfileAvatar size="28" src={ProfilePresenter.photoUrl(profile)} />
        <div className={styles.information}>
          <div className={styles.header}>
            <Typography className={styles.text} variant="h4" title={profileFullName}>
              {profileFullName}
            </Typography>
            <div>
              {ProfilePresenter.isNewToRole(profile) && <NewToRoleBadge />}
              {ProfilePresenter.isMemberFortune500(profile) && <Fortune500BoardMemberBadge />}
              {ProfilePresenter.isMemberFtse100(profile) && <Ftse100BoardMemberBadge />}
            </div>
          </div>
          {profileCurrentWorks.map((work) => (
            <div key={work.id} className={styles.work}>
              <Typography
                className={styles.text}
                color="boulder"
                variant="body2"
                title={WorkPresenter.organizationName(work)}
              >
                {WorkPresenter.organizationName(work)}
              </Typography>
              <Typography className={styles.text} color="boulder" variant="body2" title={WorkPresenter.jobTitle(work)}>
                {WorkPresenter.jobTitle(work)}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Link>
  );
};

ProfileSuggestion.propTypes = {
  profile: ProfilePresenter.shape().isRequired,
};

export default ProfileSuggestion;
