/**
 * File generated by js-routes 2.2.5
 * Based on Rails 7.0.5 routes of App::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (false &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /api/v1/admin/attribute_safe_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAttributeSafeCompaniesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attribute_safe_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/attribute_safe_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminAttributeSafeUsersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"attribute_safe_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompaniesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/dashboard(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDashboardPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/dashboard/projects(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDashboardProjectsPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/dashboard/requests(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDashboardRequestsPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments/:id(.:format)
 * @param {any} companyId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDepartmentPath = __jsr.r({"company_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments/:department_id/api_v2_access_settings(.:format)
 * @param {any} companyId
 * @param {any} departmentId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDepartmentApiV2AccessSettingsPath = __jsr.r({"company_id":{"r":true},"department_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"department_id"],[2,[7,"/"],[2,[6,"api_v2_access_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments/:department_id/limited_access(.:format)
 * @param {any} companyId
 * @param {any} departmentId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDepartmentLimitedAccessPath = __jsr.r({"company_id":{"r":true},"department_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"department_id"],[2,[7,"/"],[2,[6,"limited_access"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments/:department_id/limited_access/explicitly_granted_profiles(.:format)
 * @param {any} companyId
 * @param {any} departmentId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath = __jsr.r({"company_id":{"r":true},"department_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"department_id"],[2,[7,"/"],[2,[6,"limited_access"],[2,[7,"/"],[2,[6,"explicitly_granted_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDepartmentsPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments_statistics(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyDepartmentsStatisticsPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/legal_setting(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyLegalSettingPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"legal_setting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/match_users(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyMatchUsersPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"match_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/single_sign_on(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanySingleSignOnPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"single_sign_on"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/users(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCompanyUsersPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCountriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCountryPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/current_organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminCurrentOrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"current_organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/popular_organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardPopularOrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"popular_organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/power_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardPowerUsersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"power_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardProjectsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/public_api_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardPublicApiStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"public_api_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/search_profiles_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardSearchProfilesStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"search_profiles_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/sign_in_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardSignInStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"sign_in_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/customer_usage_dashboard/views_and_downloads_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardCustomerUsageDashboardViewsAndDownloadsStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"customer_usage_dashboard"],[2,[7,"/"],[2,[6,"views_and_downloads_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/aging_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticAgingStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"aging_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/executives_changes_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticExecutivesChangesStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"executives_changes_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/fortune500_profiles_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticFortune500ProfilesStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"fortune500_profiles_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/ftse100_profiles_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticFtse100ProfilesStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"ftse100_profiles_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/new_profiles_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticNewProfilesStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"new_profiles_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/organization_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticOrganizationUpdatesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"organization_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/priority_updates/:priority_update_id/request(.:format)
 * @param {any} priorityUpdateId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticPriorityUpdateRequestPath = __jsr.r({"priority_update_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"priority_updates"],[2,[7,"/"],[2,[3,"priority_update_id"],[2,[7,"/"],[2,[6,"request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/priority_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticPriorityUpdatesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"priority_updates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/database_analytic/profiles_updates_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardDatabaseAnalyticProfilesUpdatesStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"database_analytic"],[2,[7,"/"],[2,[6,"profiles_updates_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/production(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardProductionPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"production"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/productivity/requests_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardProductivityRequestsStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"productivity"],[2,[7,"/"],[2,[6,"requests_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/productivity/top_requestors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardProductivityTopRequestorsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"productivity"],[2,[7,"/"],[2,[6,"top_requestors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/remaining_request_numbers_statistics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardRemainingRequestNumbersStatisticsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"remaining_request_numbers_statistics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/editorial_dashboard/users_productivities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminEditorialDashboardUsersProductivitiesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editorial_dashboard"],[2,[7,"/"],[2,[6,"users_productivities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/full_profile_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminFullProfileRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"full_profile_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/impersonalization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminImpersonalizationPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"impersonalization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/industries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminIndustriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"industries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/institutions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminInstitutionPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"institutions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/institutions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminInstitutionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"institutions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/interests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminInterestPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/interests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminInterestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/job_functions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminJobFunctionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"job_functions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/languages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminLanguagePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/languages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminLanguagesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/naics_industry_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminNaicsIndustryCodesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"naics_industry_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/aliases/:alias_id(.:format)
 * @param {any} organizationId
 * @param {any} aliasId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftAliasPath = __jsr.r({"organization_id":{"r":true},"alias_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"aliases"],[2,[7,"/"],[2,[3,"alias_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/aliases(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftAliasesPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"aliases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/highlights/:highlight_id(.:format)
 * @param {any} organizationId
 * @param {any} highlightId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftHighlightPath = __jsr.r({"organization_id":{"r":true},"highlight_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"highlights"],[2,[7,"/"],[2,[3,"highlight_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/highlights(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftHighlightsPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"highlights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/logo(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftLogoPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"logo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/topics/:topic_id(.:format)
 * @param {any} organizationId
 * @param {any} topicId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftTopicPath = __jsr.r({"organization_id":{"r":true},"topic_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"topic_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/draft/topics(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationDraftTopicsPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/editor_notes/:id(.:format)
 * @param {any} organizationId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationEditorNotePath = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"editor_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/editor_notes(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationEditorNotesPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"editor_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/editor_works/:id(.:format)
 * @param {any} organizationId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationEditorWorkPath = __jsr.r({"organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"editor_works"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/editor_works(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationEditorWorksPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"editor_works"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/:organization_id/events(.:format)
 * @param {any} organizationId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationEventsPath = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminOrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/popular_organization_transcripts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminPopularOrganizationTranscriptPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"popular_organization_transcripts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/popular_organization_transcripts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminPopularOrganizationTranscriptsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"popular_organization_transcripts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/boards/:board_id(.:format)
 * @param {any} profileId
 * @param {any} boardId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftBoardPath = __jsr.r({"profile_id":{"r":true},"board_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"boards"],[2,[7,"/"],[2,[3,"board_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/boards(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftBoardsPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"boards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/highlights/:highlight_id(.:format)
 * @param {any} profileId
 * @param {any} highlightId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftHighlightPath = __jsr.r({"profile_id":{"r":true},"highlight_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"highlights"],[2,[7,"/"],[2,[3,"highlight_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/highlights(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftHighlightsPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"highlights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/institutions/:institution_id(.:format)
 * @param {any} profileId
 * @param {any} institutionId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftInstitutionPath = __jsr.r({"profile_id":{"r":true},"institution_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"institutions"],[2,[7,"/"],[2,[3,"institution_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/institutions(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftInstitutionsPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"institutions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/interests/:interest_id(.:format)
 * @param {any} profileId
 * @param {any} interestId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftInterestPath = __jsr.r({"profile_id":{"r":true},"interest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"interests"],[2,[7,"/"],[2,[3,"interest_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/interests(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftInterestsPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"interests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/photo(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftPhotoPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/topics/:topic_id(.:format)
 * @param {any} profileId
 * @param {any} topicId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftTopicPath = __jsr.r({"profile_id":{"r":true},"topic_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"topics"],[2,[7,"/"],[2,[3,"topic_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/topics(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftTopicsPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"topics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/works/:work_id(.:format)
 * @param {any} profileId
 * @param {any} workId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftWorkPath = __jsr.r({"profile_id":{"r":true},"work_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"works"],[2,[7,"/"],[2,[3,"work_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/draft/works(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileDraftWorksPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"draft"],[2,[7,"/"],[2,[6,"works"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/editor_notes/:id(.:format)
 * @param {any} profileId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileEditorNotePath = __jsr.r({"profile_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"editor_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/editor_notes(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileEditorNotesPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"editor_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/editor_works/:id(.:format)
 * @param {any} profileId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileEditorWorkPath = __jsr.r({"profile_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"editor_works"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:profile_id/editor_works(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfileEditorWorksPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"editor_works"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminProjectsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/published_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminPublishedProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"published_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminRequestPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/:request_id/organization(.:format)
 * @param {any} requestId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminRequestOrganizationPath = __jsr.r({"request_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"request_id"],[2,[7,"/"],[2,[6,"organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/:request_id/profile(.:format)
 * @param {any} requestId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminRequestProfilePath = __jsr.r({"request_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"request_id"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/request_statistic(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminRequestStatisticPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"request_statistic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/sample_profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSampleProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/sample_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSampleProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/sample_projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSampleProjectPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/sample_projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSampleProjectsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/sample_saved_searches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSampleSavedSearchPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_saved_searches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/sample_saved_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSampleSavedSearchesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sample_saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/saved_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminSavedSearchesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/standardized_job_titles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminStandardizedJobTitlesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"standardized_job_titles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminStatesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminTagPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/tags/:tag_id/populate(.:format)
 * @param {any} tagId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminTagPopulatePath = __jsr.r({"tag_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"tag_id"],[2,[7,"/"],[2,[6,"populate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminTagsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminUserPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1AdminUsersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CompanyPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/companies/:company_id/departments/:id(.:format)
 * @param {any} companyId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CompanyDepartmentPath = __jsr.r({"company_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/companies/:company_id/departments/:department_id/users(.:format)
 * @param {any} companyId
 * @param {any} departmentId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CompanyDepartmentUsersPath = __jsr.r({"company_id":{"r":true},"department_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"department_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/csrf_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CsrfTokenPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"csrf_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/company(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserCompanyPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"company"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/company/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserCompanyDashboardPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/company/dashboard/projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserCompanyDashboardProjectsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/company/dashboard/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserCompanyDashboardRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/company/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserCompanyUsersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserDashboardPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/exports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserExportPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserExportsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/limited_accesses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserLimitedAccessesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"limited_accesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/match_executives(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserMatchExecutivesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"match_executives"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/match_organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserMatchOrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"match_organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserNotificationPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserNotificationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/notifications_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserNotificationsSummaryPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"notifications_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/phone_number(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserPhoneNumberPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"phone_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/audience_insights(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectAudienceInsightsPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"audience_insights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/available_profiles/:id(.:format)
 * @param {any} projectId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectAvailableProfilePath = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"available_profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/available_profiles/:available_profile_id/relationships(.:format)
 * @param {any} projectId
 * @param {any} availableProfileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectAvailableProfileRelationshipsPath = __jsr.r({"project_id":{"r":true},"available_profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"available_profiles"],[2,[7,"/"],[2,[3,"available_profile_id"],[2,[7,"/"],[2,[6,"relationships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/available_profiles(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectAvailableProfilesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"available_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/available_profiles_and_colleagues/:available_profiles_and_colleague_id/relationships(.:format)
 * @param {any} projectId
 * @param {any} availableProfilesAndColleagueId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectAvailableProfilesAndColleagueRelationshipsPath = __jsr.r({"project_id":{"r":true},"available_profiles_and_colleague_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"available_profiles_and_colleagues"],[2,[7,"/"],[2,[3,"available_profiles_and_colleague_id"],[2,[7,"/"],[2,[6,"relationships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/available_profiles_and_colleagues(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectAvailableProfilesAndColleaguesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"available_profiles_and_colleagues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/csv_sources/:id(.:format)
 * @param {any} projectId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectCsvSourcePath = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"csv_sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/csv_source/:csv_source_id/csv_items/:id(.:format)
 * @param {any} projectId
 * @param {any} csvSourceId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectCsvSourceCsvItemPath = __jsr.r({"project_id":{"r":true},"csv_source_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"csv_source"],[2,[7,"/"],[2,[3,"csv_source_id"],[2,[7,"/"],[2,[6,"csv_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/csv_source/:csv_source_id/csv_items(.:format)
 * @param {any} projectId
 * @param {any} csvSourceId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectCsvSourceCsvItemsPath = __jsr.r({"project_id":{"r":true},"csv_source_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"csv_source"],[2,[7,"/"],[2,[3,"csv_source_id"],[2,[7,"/"],[2,[6,"csv_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/guide(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectGuidePath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"guide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/relationships_mapping(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectRelationshipsMappingPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"relationships_mapping"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/relationships_mapping_to_executives(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectRelationshipsMappingToExecutivesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"relationships_mapping_to_executives"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/shares/:id(.:format)
 * @param {any} projectId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectSharePath = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/shares(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectSharesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/sources/:id(.:format)
 * @param {any} projectId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectSourcePath = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/sources(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectSourcesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/targeting_sources/:id(.:format)
 * @param {any} projectId
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectTargetingSourcePath = __jsr.r({"project_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"targeting_sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/top_job_functions(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectTopJobFunctionsPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"top_job_functions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/top_roles(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectTopRolesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"top_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/top_tags(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectTopTagsPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"top_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/word/profiles(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectWordProfilesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"word"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/words(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectWordsPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"words"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserProjectsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/saved_searches/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserSavedSearchPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"saved_searches"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/saved_searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserSavedSearchesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"saved_searches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/subscriptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserSubscriptionPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/subscription_configurations/:kind(.:format)
 * @param {any} kind
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserSubscriptionConfigurationPath = __jsr.r({"kind":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"subscription_configurations"],[2,[7,"/"],[2,[3,"kind"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserSubscriptionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/targeting_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CurrentUserTargetingSearchIndexPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"targeting_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/help_request(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerHelpRequestPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"help_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/:profile_id/grant_accesses(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfileGrantAccessesPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"grant_accesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/:profile_id/relationships_mapping(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfileRelationshipsMappingPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"relationships_mapping"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/:profile_id/relationships_mapping/profiles/:profile_relationships_mapping_id/relationships(.:format)
 * @param {any} profileId
 * @param {any} profileRelationshipsMappingId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfileRelationshipsMappingProfileRelationshipsPath = __jsr.r({"profile_id":{"r":true},"profile_relationships_mapping_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"relationships_mapping"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_relationships_mapping_id"],[2,[7,"/"],[2,[6,"relationships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/:profile_id/relationships_mapping/projects(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfileRelationshipsMappingProjectsPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"relationships_mapping"],[2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/:profile_id/update_request(.:format)
 * @param {any} profileId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfileUpdateRequestPath = __jsr.r({"profile_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"profile_id"],[2,[7,"/"],[2,[6,"update_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/quick_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerQuickSearchIndexPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"quick_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/resource_center/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerResourceCenterCategoriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"resource_center"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/resource_center/resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerResourceCenterResourcesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"resource_center"],[2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/skinny_profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerSkinnyProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"skinny_profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/skinny_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1CustomerSkinnyProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"skinny_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/download_exports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1DownloadExportPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"download_exports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/relsci/identifiers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1RelsciIdentifiersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"relsci"],[2,[7,"/"],[2,[6,"identifiers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/reset_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1ResetPasswordPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/salesforce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1SalesforcePath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"salesforce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/sample_profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1SampleProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sample_profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/session(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1SessionPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"session"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/two_factor_authentications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1TwoFactorAuthenticationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"two_factor_authentications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/two_factor_authentications/phone_numbers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apiV1TwoFactorAuthenticationsPhoneNumbersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"two_factor_authentications"],[2,[7,"/"],[2,[6,"phone_numbers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/overview(/:version)(/:resource)(/:method)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apipieApipiePath = __jsr.r({"version":{},"resource":{},"method":{},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"overview"],[2,[1,[2,[7,"/"],[3,"version"]]],[2,[1,[2,[7,"/"],[3,"resource"]]],[2,[1,[2,[7,"/"],[3,"method"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/overview/apipie_checksum(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apipieApipieChecksumPath = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"overview"],[2,[7,"/"],[2,[6,"apipie_checksum"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/:id/assign_editor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignEditorApiV1AdminRequestPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign_editor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/targeting_search/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocompleteApiV1CurrentUserTargetingSearchIndexPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"targeting_search"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/profiles/autocomplete(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocompleteApiV1CustomerProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"autocomplete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/requests/batch_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batchCreateApiV1CustomerRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"batch_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/shares/batch_update(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batchUpdateApiV1CurrentUserProjectSharesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[6,"batch_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/subscriptions/batch_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batchUpdateApiV1CurrentUserSubscriptionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"batch_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/bulk_assign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkAssignApiV1AdminRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"bulk_assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/users/bulk_create(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkCreateApiV1AdminCompanyUsersPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminCompaniesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/departments/:department_id/limited_access/explicitly_granted_profiles/bulk_destroy(.:format)
 * @param {any} companyId
 * @param {any} departmentId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath = __jsr.r({"company_id":{"r":true},"department_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"department_id"],[2,[7,"/"],[2,[6,"limited_access"],[2,[7,"/"],[2,[6,"explicitly_granted_profiles"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/users/bulk_destroy(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminCompanyUsersPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/countries/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminCountriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/institutions/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminInstitutionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"institutions"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/interests/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminInterestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interests"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/languages/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminLanguagesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"languages"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/organizations/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminOrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/tags/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminTagsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/users/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1AdminUsersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/company/users/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkDestroyApiV1CurrentUserCompanyUsersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"company"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/tags/bulk_populate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkPopulateApiV1AdminTagsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"bulk_populate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/requests/bulk_update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulkUpdateStatusApiV1AdminRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"bulk_update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:id/clone(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cloneApiV1CurrentUserProjectPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/phone_number/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmApiV1CurrentUserPhoneNumberPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"phone_number"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/single_sign_ons/:id/consume(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const consumeApiV1SingleSignOnPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"single_sign_ons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"consume"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /csv/v1/admin/interests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const csvV1AdminInterestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"csv"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /csv/v1/admin/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const csvV1AdminOrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"csv"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /csv/v1/admin/tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const csvV1AdminTagsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"csv"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/single_sign_ons/:id/download_saml_metadata(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloadSamlMetadataApiV1AdminSingleSignOnPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"single_sign_ons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_saml_metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /email/link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const emailLinkPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/guides/:id/hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hideApiV1CurrentUserGuidePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"guides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /letter_opener
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letterOpenerWebPath = __jsr.r({}, [2,[7,"/"],[6,"letter_opener"]]);

/**
 * Generates rails route to
 * /letter_opener/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letterOpenerWebLettersPath = __jsr.r({}, [2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]]);

/**
 * Generates rails route to
 * /letter_opener/clear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letterOpenerWebClearLettersPath = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[6,"clear"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /letter_opener/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letterOpenerWebLetterPath = __jsr.r({"id":{"r":true},"style":{},"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"style"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /letter_opener/:id/delete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letterOpenerWebDeleteLetterPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /letter_opener/clear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letterOpenerWebClearPath = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[6,"clear"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newRailsConductorInboundEmailSourcePath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/oauth/introspect(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauthIntrospectPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"introspect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/oauth/revoke(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauthRevokePath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"revoke"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/oauth/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauthTokenPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/oauth/token/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const oauthTokenInfoPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"oauth"],[2,[7,"/"],[2,[6,"token"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/profiles/:id/opt_out(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const optOutApiV1AdminProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"opt_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/single_sign_ons/:id/passthrough(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const passthroughApiV1SingleSignOnPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"single_sign_ons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"passthrough"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pdf/v1/admin/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdfV1AdminProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pdf/v1/admin/thomson_reuters_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdfV1AdminThomsonReutersDocumentPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"thomson_reuters_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pdf/v1/customer/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdfV1CustomerProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /pdf/v1/customer/profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdfV1CustomerProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/single_sign_ons/:id/process_logout_response(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const processLogoutResponseApiV1SingleSignOnPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"single_sign_ons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"process_logout_response"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1CountriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/country_regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1CountryRegionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"country_regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/interests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1InterestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"interests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/job_functions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1JobFunctionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"job_functions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/naics_industry_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1NaicsIndustryCodesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"naics_industry_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/naics_two_industry_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1NaicsTwoIndustryCodesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"naics_two_industry_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1OrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1ProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1ProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1StatesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v1/world_regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV1WorldRegionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"world_regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2CountriesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/country_regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2CountryRegionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"country_regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/interests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2InterestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"interests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/job_functions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2JobFunctionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"job_functions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/naics_industry_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2NaicsIndustryCodesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"naics_industry_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/naics_two_industry_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2NaicsTwoIndustryCodesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"naics_two_industry_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2OrganizationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2ProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2ProfilesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/states(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2StatesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"states"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/api/v2/world_regions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicApiV2WorldRegionsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"world_regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/pdf/v1/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicPdfV1ProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/pdf/v2/profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publicPdfV2ProfilePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signedBlobId
 * @param {any} variationKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsBlobRepresentationPath = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signedBlobId
 * @param {any} variationKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsBlobRepresentationProxyPath = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailPath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inboundEmailId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailIncineratePath = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inboundEmailId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailReroutePath = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailSourcesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsConductorInboundEmailsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsDirectUploadsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encodedKey
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsDiskServicePath = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsInfoPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsInfoPropertiesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsInfoRoutesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMailersPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMailgunInboundEmailsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMandrillInboundEmailsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsMandrillInboundHealthCheckPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsPostmarkInboundEmailsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsRelayInboundEmailsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsSendgridInboundEmailsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signedId
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsServiceBlobPath = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signedId
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const railsServiceBlobProxyPath = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/phone_number/resend_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resendConfirmationApiV1CurrentUserPhoneNumberPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"phone_number"],[2,[7,"/"],[2,[6,"resend_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/two_factor_authentications/resend_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resendConfirmationApiV1TwoFactorAuthenticationsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"two_factor_authentications"],[2,[7,"/"],[2,[6,"resend_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/guides/:id/reveal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const revealApiV1CurrentUserGuidePath = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"guides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reveal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiqWebPath = __jsr.r({}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[6,"sidekiq"]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/projects/:project_id/shares/suggestions(.:format)
 * @param {any} projectId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suggestionsApiV1CurrentUserProjectSharesPath = __jsr.r({"project_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"project_id"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/customer/requests/suggestions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const suggestionsApiV1CustomerRequestsPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"requests"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encodedToken
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const updateRailsDiskServicePath = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/admin/companies/:company_id/match_users/validate(.:format)
 * @param {any} companyId
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validateApiV1AdminCompanyMatchUsersPath = __jsr.r({"company_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"company_id"],[2,[7,"/"],[2,[6,"match_users"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/current_user/match_executives/validate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validateApiV1CurrentUserMatchExecutivesPath = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"current_user"],[2,[7,"/"],[2,[6,"match_executives"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

