import ProfilesUpdatesStatisticsConstants from './ProfilesUpdatesStatisticsConstants';
import ProfilesUpdatesStatisticsRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/ProfilesUpdatesStatisticsRepository';

export function loadProfilesUpdatesStatistics() {
  return (dispatch) => {
    dispatch({
      type: ProfilesUpdatesStatisticsConstants.LOAD_DATABASE_ANALYTICS_PROFILES_UPDATES_STATISTICS_START,
    });

    return ProfilesUpdatesStatisticsRepository.show()
      .then(({ profilesUpdatesStatistics }) => {
        dispatch({
          type: ProfilesUpdatesStatisticsConstants.LOAD_DATABASE_ANALYTICS_PROFILES_UPDATES_STATISTICS_SUCCESS,
          profilesUpdatesStatistics,
        });

        return profilesUpdatesStatistics;
      })
      .catch((error) => {
        dispatch({
          type: ProfilesUpdatesStatisticsConstants.LOAD_DATABASE_ANALYTICS_PROFILES_UPDATES_STATISTICS_ERROR,
          error,
        });

        throw error;
      });
  };
}
