import { isBlank, isPresent } from 'utils/HelperMethods';

import UserPolicy from 'policies/UserPolicy';
import RequestPolicy from 'policies/RequestPolicy';

import UserPresenter from 'presenters/UserPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';

export default {
  canSearch(user) {
    return isPresent(user);
  },
  canSeeList(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },

  canSeeElement(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },

  canDestroyElement(user) {
    return UserPresenter.isAdmin(user);
  },

  canAddElement(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },

  canSeeDownloadButton(user) {
    if (!user) {
      return false;
    }

    return (
      UserPresenter.isDownloadLimited(user) ||
      UserPresenter.isAdmin(user) ||
      UserPresenter.isEditor(user) ||
      UserPresenter.isCustomerAccount(user) ||
      UserPresenter.isDemoAccount(user) ||
      UserPresenter.isPilotAccount(user)
    );
  },

  canDownload(user) {
    if (UserPresenter.isDownloadLimited(user)) {
      return CurrentUserPresenter.remainingProfileDownloadsNumber(user) > 0;
    }

    return (
      UserPresenter.isAdmin(user) ||
      UserPresenter.isEditor(user) ||
      UserPresenter.isCustomerAccount(user) ||
      UserPresenter.isDemoAccount(user) ||
      UserPresenter.isPilotAccount(user)
    );
  },
  canEdit(user, profile, request) {
    if (isBlank(request)) {
      return (
        UserPresenter.isAdmin(user) ||
        UserPresenter.isEditor(user) ||
        UserPresenter.isResearcher(user) ||
        UserPresenter.isResearchManager(user)
      );
    }

    const assignee = RequestPresenter.assignee(request);

    return (
      RequestPolicy.canEdit(user, request) &&
      (isBlank(assignee) || UserPresenter.id(assignee) === UserPresenter.id(user))
    );
  },
  canVisibilityChange(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchManager(user);
  },
  canExportList(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchManager(user);
  },

  canSeeEditorialUpdateDate(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },

  canSeeRelationshipsMappingPromotionBanner(user) {
    return !UserPolicy.canSeeProjects(user);
  },

  canSeeRelationshipsMappingInfo(user) {
    return UserPolicy.canSeeProjects(user);
  },

  canOptOut(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchEditor(user);
  },
};
