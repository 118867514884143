import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable, Data } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import ProjectPresenter from 'presenters/Company/Dashboard/ProjectPresenter';

import styles from './ProjectsUsage.module.css';

const TABLE_COLUMNS = [
  {
    property: 'status',
    header: 'Status',
    size: 'small',
    sortable: false,
    render: (project) => ProjectPresenter.status(project),
  },
  {
    property: 'name',
    header: 'Project Name',
    size: 'small',
    sortable: false,
    render: (project) => ProjectPresenter.name(project) || '-',
  },
  {
    property: 'creator_full_name',
    header: 'Full Name',
    size: 'small',
    sortable: false,
    render: (project) => ProjectPresenter.creatorFullName(project) || '-',
  },
  {
    property: 'created_at',
    header: 'Created Date',
    size: 'small',
    sortable: false,
    render: (project) => ProjectPresenter.formattedCreatedAt(project) || '-',
  },
  {
    property: 'deleted_at',
    header: 'Deleted Date',
    size: 'small',
    sortable: false,
    render: (project) => ProjectPresenter.formattedDeletedAt(project) || '-',
  },
  {
    property: 'profiles_count',
    header: 'Profiles Count',
    size: 'small',
    sortable: false,
    render: (project) => ProjectPresenter.formattedProfilesCount(project) || '-',
  },
];
export default class ProjectsUsage extends Component {
  static propTypes = {
    projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
    onLoad: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    activeProjectsCount: PropTypes.number,
  };

  isAllProjectsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { loading, nextPage, onLoad } = this.props;

    if (!loading && !this.isAllProjectsLoaded()) {
      onLoad({ page: nextPage });
    }
  };

  render() {
    const { activeProjectsCount, projects } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <span className={styles.title}>Projects</span>
          <span className={styles.value}>{activeProjectsCount}</span>
        </div>
        <Data id="id" data={projects}>
          <DataTable
            columns={TABLE_COLUMNS}
            step={5}
            onMore={this.isAllProjectsLoaded() ? undefined : this.handleLoadMore}
            primaryKey={false}
            size="small"
          />
        </Data>
      </div>
    );
  }
}
