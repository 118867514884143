import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(projectId, sourceId) {
    const url = Routes.apiV1CurrentUserProjectCsvSourcePath(projectId, sourceId);

    return JsonFetchHelpers.get(url);
  },
};
