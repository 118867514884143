import keyMirror from 'keymirror';

const CountriesListPageConstants = keyMirror({
  LOAD_COUNTRIES_START: null,
  LOAD_COUNTRIES_SUCCESS: null,

  FILTER_COUNTRIES_START: null,
  FILTER_COUNTRIES_SUCCESS: null,

  CREATE_COUNTRY_START: null,
  CREATE_COUNTRY_SUCCESS: null,
  CREATE_COUNTRY_ERROR: null,

  BULK_DESTROY_COUNTRIES_START: null,
  BULK_DESTROY_COUNTRIES_SUCCESS: null,
});

export default CountriesListPageConstants;
