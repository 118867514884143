import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Alert as AlertIcon } from 'grommet-icons';

import styles from './Alert.module.css';

export default class Alert extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return (
      <div className={styles.infoAlert}>
        <h4>
          <AlertIcon />
          Some of the actions were disabled.
        </h4>
        {children}
      </div>
    );
  }
}
