export default class ApiNetworkError {
  constructor(originalError) {
    this.originalError = originalError;
    this.isApiNetworkError = true;
    this.message = this.toString();
  }

  toString() {
    return `API Network Error: ${this.originalError.message}`;
  }
}
