import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import u from 'updeep';

import NewProjectForm from 'forms/NewProjectForm';
import SourceForm from 'forms/project/SourceForm';
import TargetItemForm from 'forms/project/TargetItemForm';

import FramedWizard, { WizardStep } from 'components/v2/FramedWizard';
import ProjectNameForm from './components/ProjectNameForm';
import SourceTypeSelection from 'containers/UserPanel/components/ProjectSourceTypeSelectionWizardStep';
import Targeting from 'containers/UserPanel/components/ProjectTargetingWizardStep';
import UploadExecutivesCsvForm from 'containers/UserPanel/components/ProjectUploadExecutivesCsvWizardStep';
import UploadOrganizationsCsvForm from 'containers/UserPanel/components/ProjectUploadOrganizationsCsvWizardStep';
import MatchedOrganizations from 'containers/UserPanel/containers/ProjectMatchedOrganizationsWizardStep';
import MatchedExecutives from 'containers/UserPanel/components/ProjectMatchedExecutivesWizardStep';
import RequestExecutives from 'containers/UserPanel/components/ProjectRequestExecutivesWizardStep';
import Final from './components/Final';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import SourcePresenter, { TARGETING_SOURCE_TYPE, DATABASE_SEARCH_KIND } from 'presenters/Project/SourcePresenter';
import { INCLUSION_KIND } from 'presenters/Project/TargetItemPresenter';

const BREADCRUMBS = [{ name: 'Projects', to: Routes.projectsPath() }, { name: 'Add Project' }];

const TITLE_STEP = 'title';
const SELECT_TYPE_STEP = 'select-type';
const TARGETING_STEP = 'targeting';
const UPLOAD_LIST_STEP = 'upload-list';
const MATCHED_ORGANIZATIONS = 'matched-organizations';
const CONFIRM_EXECUTIVES_STEP = 'confirm-executives';
const REQUEST_EXECUTIVES_STEP = 'request-executives';
const COMPLETE_STEP = 'complete';

export default class NewProjectWizard extends Component {
  static propTypes = {
    changeForm: PropTypes.func.isRequired,
    createProject: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape(),
    initializeWizard: PropTypes.func.isRequired,
    form: ProjectPresenter.shape().isRequired,
    history: PropTypes.shape().isRequired,
    project: ProjectPresenter.shape(),
    saved: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { initializeWizard } = this.props;
    const targetItem = TargetItemForm.defaultAttributes({ kind: INCLUSION_KIND });
    const source = SourceForm.defaultAttributes({
      kind: DATABASE_SEARCH_KIND,
      type: TARGETING_SOURCE_TYPE,
      targetItemsAttributes: [targetItem],
    });

    initializeWizard(NewProjectForm.defaultAttributes({ sourcesAttributes: [source] }));
  }

  handleWizardClose = () => {
    const { history } = this.props;

    history.push(Routes.projectsPath());
  };

  handleFieldChange = (path, value) => {
    const { changeForm } = this.props;
    const form = u.updateIn(path, value, this.props.form);

    changeForm(form);
  };

  handleProjectNameChange = (value) => this.handleFieldChange('name', value);

  handleSourceUpdate = (value) => this.handleFieldChange('sourcesAttributes.0', value);

  render() {
    const { currentUser, createProject, form, project, saved, saving } = this.props;
    const { sourcesAttributes: sources } = form;
    const [source] = sources;

    return (
      <FramedWizard breadcrumbs={BREADCRUMBS} onWizardClose={this.handleWizardClose}>
        <WizardStep path={Routes.newProjectWizardStepPath(TITLE_STEP)}>
          <ProjectNameForm form={form} onNameChange={this.handleProjectNameChange} />
        </WizardStep>

        {!CurrentUserPresenter.hasLimitedAccess(currentUser) && (
          <WizardStep path={Routes.newProjectWizardStepPath(SELECT_TYPE_STEP)}>
            <SourceTypeSelection source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isDatabaseSearchKind(source) && (
          <WizardStep path={Routes.newProjectWizardStepPath(TARGETING_STEP)}>
            <Targeting source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isOrganizationsListKind(source) && (
          <WizardStep path={Routes.newProjectWizardStepPath(UPLOAD_LIST_STEP)}>
            <UploadOrganizationsCsvForm source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isOrganizationsListKind(source) && (
          <WizardStep path={Routes.newProjectWizardStepPath(MATCHED_ORGANIZATIONS)}>
            <MatchedOrganizations source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isExecutivesListKind(source) && (
          <WizardStep path={Routes.newProjectWizardStepPath(UPLOAD_LIST_STEP)}>
            <UploadExecutivesCsvForm source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isExecutivesListKind(source) && (
          <WizardStep path={Routes.newProjectWizardStepPath(CONFIRM_EXECUTIVES_STEP)}>
            <MatchedExecutives source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isExecutivesListKind(source) && (
          <WizardStep path={Routes.newProjectWizardStepPath(REQUEST_EXECUTIVES_STEP)}>
            <RequestExecutives currentUser={currentUser} source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        <WizardStep path={Routes.newProjectWizardStepPath(COMPLETE_STEP)}>
          <Final form={form} onCreateProject={createProject} project={project} saving={saving} saved={saved} />
        </WizardStep>
      </FramedWizard>
    );
  }
}
