import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { Anchor } from 'grommet';
import { Close as CloseIcon, Edit as EditIcon, CircleInformation as CircleInformationIcon } from 'grommet-icons';

import Card from 'components/Card';
import Headline from 'components/grommet/Headline';
import StyleGuideModal from 'components/StyleGuideModal';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';

import BoardForm from '../BoardForm';

import BoardPresenter from 'presenters/BoardPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import Routes from 'routes';

import styles from './Board.module.css';

export default class Board extends Component {
  state = { edit: false };

  handleCancelEdit = () => this.setState({ edit: false });

  handleSubmit = ({ id, ...params }) => this.props.onUpdate(id, params).then(this.handleCancelEdit);

  handleEdit = () => this.setState({ edit: true });

  handleDestroy = () => {
    const { onDestroy, board } = this.props;

    onDestroy(board.id);
  };

  render() {
    const { edit } = this.state;
    const { editable, saving, board } = this.props;
    const organization = BoardPresenter.organization(board);
    const rootCardStyles = cn([styles.root], {
      [styles.rootFortune500]: BoardPresenter.isMemberFortune500(board) || BoardPresenter.isMemberFtse100(board),
      [styles.rootRelevant]: BoardPresenter.isRelevant(board),
    });

    return (
      <Card className={rootCardStyles}>
        <div className={styles.badges}>
          {BoardPresenter.isMemberFortune500(board) && <div className={cn(styles.badge, styles.fortune500)}>F500</div>}
          {BoardPresenter.isMemberFtse100(board) && <div className={cn(styles.badge, styles.fortune500)}>FTSE100</div>}
          {BoardPresenter.isRelevant(board) && (
            <CircleInformationIcon className={cn(styles.badge, styles.relevantIcon)} type="status" />
          )}
        </div>
        <Headline size="small" margin="none" className={styles.title}>
          <Anchor
            href={Routes.editAdminOrganizationPath(organization.id)}
            label={OrganizationPresenter.nameWithState(organization)}
            target="_blank"
          />
        </Headline>
        <div className={styles.description}>{BoardPresenter.description(board)}</div>
        <div className={styles.dates}> {BoardPresenter.timeInterval(board)} </div>
        {editable && (
          <div className={styles.panel}>
            <div className={styles.action}>
              <EditIcon color="light-1" onClick={this.handleEdit} />
            </div>
            <div className={styles.action}>
              <CloseIcon color="light-1" onClick={this.handleDestroy} />
            </div>
            <div className={styles.action}>
              <StyleGuideModal modalContent={StyleGuides.OtherBoardAndOrganizationStyleGuide}>
                <CircleInformationIcon color="light-1" />
              </StyleGuideModal>
            </div>
          </div>
        )}
        {edit && (
          <BoardForm
            title="Edit Board"
            board={board}
            saving={saving}
            onCancel={this.handleCancelEdit}
            onSubmit={this.handleSubmit}
          />
        )}
      </Card>
    );
  }
}

Board.propTypes = {
  editable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  board: BoardPresenter.shape(),
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
