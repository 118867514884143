import keyMirror from 'keymirror';

const CompanyDepartmentUserRegistrationConstants = keyMirror({
  LOAD_REGISTRATION_COMPANY_DEPARTMENT_START: null,
  LOAD_REGISTRATION_COMPANY_DEPARTMENT_SUCCESS: null,
  LOAD_REGISTRATION_COMPANY_DEPARTMENT_ERROR: null,

  CREATE_COMPANY_DEPARTMENT_USER_START: null,
  CREATE_COMPANY_DEPARTMENT_USER_SUCCESS: null,
  CREATE_COMPANY_DEPARTMENT_USER_ERROR: null,
});

export default CompanyDepartmentUserRegistrationConstants;
