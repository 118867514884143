import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import Card from 'components/Card';
import HighlightsItem from './components/HighlightsItem';
import withConfirmation from 'hoc/withConfirmation';

import styles from './Highlights.module.css';

const HighlightsComponent = (props) => {
  const { onDestroyHighlight, editable, highlights, saving } = props;

  return (
    <Box margin={{ bottom: 'medium' }}>
      <Card>
        <div className={styles.title}>Highlights</div>
        {highlights.map(({ id, highlightedText, tag: { fullTitle } }) => (
          <HighlightsItem
            text={highlightedText}
            title={fullTitle}
            id={id}
            key={id}
            onDelete={onDestroyHighlight}
            saving={saving}
            editable={editable}
          />
        ))}
      </Card>
    </Box>
  );
};

HighlightsComponent.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape()),
  onDestroyHighlight: PropTypes.func,
  saving: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
};

const Highlights = withConfirmation(HighlightsComponent, { onDestroyHighlight: 'Are you sure want to delete?' });
export default Highlights;
