/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function resetResourcesSlice(options) {
  const { pluralResource } = options;

  return {
    reducers: {
      resetResources(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.idle;
        state[pluralResource].moreLoadingStatus = FETCH_STATUSES.idle;
        state[pluralResource].items = [];
        state[pluralResource].meta = {};
        state[pluralResource].error = null;
      },
    },
    actionCreators(restDispatch) {
      return {
        resetResources: () => {
          restDispatch('resetResources');
        },
      };
    },
    abstractSelector: (state) => ({ resource: state[pluralResource] }),
  };
}
