import keyMirror from 'keymirror';

const ProfileContainerConstants = keyMirror({
  LOAD_RELSCI_PROFILE_START: null,
  LOAD_RELSCI_PROFILE_SUCCESS: null,

  LOAD_CUSTOMER_PROFILE_START: null,
  LOAD_CUSTOMER_PROFILE_SUCCESS: null,

  REQUEST_CUSTOMER_PROFILE_UPDATE_START: null,
  REQUEST_CUSTOMER_PROFILE_UPDATE_SUCCESS: null,
  REQUEST_CUSTOMER_PROFILE_UPDATE_ERROR: null,

  UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_START: null,
  UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_SUCCESS: null,
  UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_ERROR: null,
});

export default ProfileContainerConstants;
