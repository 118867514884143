import { includes, pick, reject, equals } from 'ramda';

import { PROJECT_MASTER_EXPORT } from 'presenters/ProjectPresenter';

export const EXPORTABLES = {
  AUDIENCE_INSIGHTS: 'audience_insights',
  BPA_BY_INITIATIVES: 'bpa_by_initiatives',
  BPA_BY_ROLE: 'bpa_by_role',
  BPA_BY_JOB_FUNCTIONS: 'bpa_by_job_functions',
  RM_TOP_50_RELATIONSHIPS: 'rm_top_50_relationships',
  RM_CONNECTIONS_TO_MY_EXECUTIVES: 'rm_connections_to_my_executives',
  WORDS_CLOUD_COMPANIES: 'words_cloud_companies',
  WORDS_CLOUD_BOARDS: 'words_cloud_boards',
  WORDS_CLOUD_EDUCATION: 'words_cloud_education',
  WORDS_CLOUD_INTERESTS: 'words_cloud_interests',
  WORDS_CLOUD_COMPANIES_TO_MY_EXECUTIVES: 'words_cloud_companies_to_my_executives',
  WORDS_CLOUD_BOARDS_TO_MY_EXECUTIVES: 'words_cloud_boards_to_my_executives',
  WORDS_CLOUD_EDUCATION_TO_MY_EXECUTIVES: 'words_cloud_education_to_my_executives',
  WORDS_CLOUD_INTERESTS_TO_MY_EXECUTIVES: 'words_cloud_interests_to_my_executives',
  WORDS_CLOUD_INCLUDE_EXECUTIVES: 'words_cloud_include_executives',
};

export function buildRecipientEmail(value) {
  return { label: value, value };
}

function recipientEmailValue(option) {
  return option.value;
}

export default {
  defaultAttributes(attributes) {
    return {
      type: PROJECT_MASTER_EXPORT,
      recipientEmails: [],
      ...attributes,
      params: {
        exportItems: [],
        ...attributes.params,
      },
    };
  },

  attributesToSubmit(attributes) {
    const { params } = attributes;
    const propsToSend = ['type', 'recipientEmails', 'params'];
    const paramsPropsToSend = ['exportItems'];
    const includeExecutives = includes(EXPORTABLES.WORDS_CLOUD_INCLUDE_EXECUTIVES, params.exportItems);
    const exportItems = reject(equals(EXPORTABLES.WORDS_CLOUD_INCLUDE_EXECUTIVES), params.exportItems);

    if (includeExecutives && includes(EXPORTABLES.WORDS_CLOUD_COMPANIES, exportItems)) {
      exportItems.push(EXPORTABLES.WORDS_CLOUD_COMPANIES_TO_MY_EXECUTIVES);
    }

    if (includeExecutives && includes(EXPORTABLES.WORDS_CLOUD_BOARDS, exportItems)) {
      exportItems.push(EXPORTABLES.WORDS_CLOUD_BOARDS_TO_MY_EXECUTIVES);
    }

    if (includeExecutives && includes(EXPORTABLES.WORDS_CLOUD_EDUCATION, exportItems)) {
      exportItems.push(EXPORTABLES.WORDS_CLOUD_EDUCATION_TO_MY_EXECUTIVES);
    }

    if (includeExecutives && includes(EXPORTABLES.WORDS_CLOUD_INTERESTS, exportItems)) {
      exportItems.push(EXPORTABLES.WORDS_CLOUD_INTERESTS_TO_MY_EXECUTIVES);
    }

    const recipientEmails = attributes.recipientEmails.map(recipientEmailValue);
    const permittedParams = pick(paramsPropsToSend, { ...params, exportItems });

    return pick(propsToSend, { ...attributes, recipientEmails, params: permittedParams });
  },
};
