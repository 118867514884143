import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import NewInterestForm from './components/NewInterestForm';

class NewInterestModal extends Component {
  static propTypes = {
    saving: PropTypes.bool.isRequired,
    createInterest: PropTypes.func.isRequired,
    onCreateSuccess: PropTypes.func.isRequired,
    interestName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  };

  handleSubmitNewInterest = (params) => {
    const { createInterest, onCreateSuccess } = this.props;

    return createInterest(params).then((interest) => {
      onCreateSuccess(interest);
    });
  };

  render() {
    const { onClose, saving, interestName } = this.props;

    return (
      <Modal onClose={onClose} size="medium" header="New Interest" overflow>
        <NewInterestForm interestName={interestName} onSubmit={this.handleSubmitNewInterest} saving={saving} />
      </Modal>
    );
  }
}

export default NewInterestModal;
