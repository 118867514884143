import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, pathOr, assocPath } from 'ramda';

import { useDebounce } from 'utils/debounceUtils';
import { buildRansackSortings, parseRansackSortings } from 'utils/ransackUtils';

import RequestPresenter from 'presenters/RequestPresenter';

import InfiniteScroll from 'components/v2/InfiniteScroll';
import Table, { TableBody, TableHead, TableLoaderRow } from 'components/v2/Table';
import SearchInput from 'components/SearchInput/SearchInput';

import Header from './components/Header';
import Row from './components/Row';

import styles from './RequestsHistory.module.css';

const RequestsHistory = (props) => {
  const { requests, onLoad, nextPage, onLoadMore, loadingMoreStatus, loadingStatus } = props;

  const [filters, setFilters] = useState({ q: { s: ['created_at desc'] } });
  const sortings = parseRansackSortings(pathOr([], ['q', 's'], filters));

  useEffect(() => {
    onLoad(filters);
  }, []);

  const handleRequestsFilter = useDebounce((newFilters) => onLoad(newFilters));

  const handleFiltersChange = (newFilters) => {
    handleRequestsFilter(newFilters);
    setFilters(newFilters);
  };

  const handleLoadMore = () => onLoadMore(filters);

  const handleSort = (newSortings) => {
    handleFiltersChange(assocPath(['q', 's'], buildRansackSortings(newSortings), filters));
    handleRequestsFilter.flush();
  };

  const handleSearch = ({ target: { value } }) =>
    handleFiltersChange(assocPath(['q', 'search_by_executives_cont'], value, filters));

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>Request History</span>

        <div className={styles.search}>
          <SearchInput onChange={handleSearch} placeholder="Search for executives and companies" responsive={false} />
        </div>
      </div>

      <div className={styles.content}>
        <InfiniteScroll
          hasMore={!isNil(nextPage)}
          loadingMoreStatus={loadingMoreStatus}
          onLoadMore={handleLoadMore}
          getScrollParent={false}
        >
          <Table>
            <TableHead>
              <Header sortings={sortings} onSortingsChange={handleSort} />
            </TableHead>
            <TableBody>
              {requests.map((request) => (
                <Row key={request.id} request={request} />
              ))}
              {(loadingStatus.isPending || loadingMoreStatus.isPending) && <TableLoaderRow colSpan={10} />}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

RequestsHistory.propTypes = {
  requests: PropTypes.arrayOf(RequestPresenter.shape()).isRequired,
  nextPage: PropTypes.number,
  onLoadMore: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  loadingMoreStatus: PropTypes.shape(),
  loadingStatus: PropTypes.shape(),
};

export default RequestsHistory;
