import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, TextInput } from 'grommet';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

class NewCompanyForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  state = {
    form: {
      name: '',
    },
    errors: null,
  };

  handleSubmit = () => {
    const { form } = this.state;
    this.setState({ errors: null });

    this.props.onSubmit(form).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  updateField(fieldName, value) {
    this.setState({ form: { ...this.state.form, [fieldName]: value } });
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  render() {
    const { saving } = this.props;
    const { form, errors } = this.state;

    return (
      <div>
        <VerticalFormFieldWithBottomErrors label="Company Name:" errors={errors && errors.name} htmlFor="name">
          <TextInput value={form.name} onChange={this.handleTextFieldChange('name')} id="name" />
        </VerticalFormFieldWithBottomErrors>

        <Box direction="row" margin={{ top: 'small' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default NewCompanyForm;
