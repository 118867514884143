import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { toRelativeCalendar } from 'utils/DateTime';

import Button from 'components/Button';
import AnchorLink from 'components/grommet/AnchorLink';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import Routes from 'routes';

import styles from './SavedSearch.module.css';

class SavedSearchComponent extends Component {
  static propTypes = {
    savedSearch: SavedSearchPresenter.shape(),
    statisticsStartedAt: PropTypes.string.isRequired,
    history: PropTypes.shape(),
  };

  handleStatisticsClick = (params) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { history, savedSearch } = this.props;
    const id = SavedSearchPresenter.id(savedSearch);
    const path = Routes.savedSearchPath(id, params);

    history.push(path);
  };

  renderStatistics(title, count, date, params) {
    return (
      <Button className={styles.statisticDetails} onClick={this.handleStatisticsClick(params)}>
        <div className={styles.statisticTitle}>
          {title}: <span className={styles.statisticCount}>{count}</span>
        </div>
        <div className={styles.statisticDate}>Updated: {toRelativeCalendar(date)}</div>
      </Button>
    );
  }

  render() {
    const { savedSearch, statisticsStartedAt } = this.props;
    const id = SavedSearchPresenter.id(savedSearch);

    return (
      <AnchorLink className={styles.root} to={Routes.savedSearchPath(id)}>
        {SavedSearchPresenter.isSample(savedSearch) && <div className={styles.sample}>sample</div>}
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.name}>{SavedSearchPresenter.name(savedSearch)}</div>
          </div>
          <div className={styles.info}>{SavedSearchPresenter.filtersCount(savedSearch)} filters</div>
        </div>
        <div className={styles.statistics}>
          {this.renderStatistics(
            'Updated Profiles',
            SavedSearchPresenter.significantlyUpdatedProfilesTotalCount(savedSearch),
            SavedSearchPresenter.significantlyUpdatedProfilesUpdatedAt(savedSearch),
            { significantlyUpdatedGte: statisticsStartedAt },
          )}
          {this.renderStatistics(
            'New Profiles',
            SavedSearchPresenter.addedProfilesTotalCount(savedSearch),
            SavedSearchPresenter.addedProfilesUpdatedAt(savedSearch),
            { profilesAddedGte: statisticsStartedAt },
          )}
          {this.renderStatistics(
            'Executive Changes',
            SavedSearchPresenter.newToRoleProfilesTotalCount(savedSearch),
            SavedSearchPresenter.newToRoleProfilesUpdatedAt(savedSearch),
            { recentCurrentWorkStartedGte: statisticsStartedAt },
          )}
        </div>
      </AnchorLink>
    );
  }
}

const SavedSearch = withRouter(SavedSearchComponent);

export default SavedSearch;
