import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dashboard from 'components/CorporateAccountDashboard';
import * as actions from './AdminDashboardActions';

const mapStateToProps = (state) => ({
  loading: state.AdminDashboardReducer.loading,
  dashboard: state.AdminDashboardReducer.dashboard,
  form: state.AdminDashboardReducer.form,
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      ...actions,
      loadDashboard: (params) => actions.loadDashboard(props.companyId, params),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
