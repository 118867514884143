import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { detectCsvFileDelimiter } from 'utils/fileUtils';

import { useCurrentUserExecutivesMatches } from 'hooks/currentUser';

import CsvIcon from 'icons/CsvIcon';

import Button from 'components/v2/Button';
import Dropzone, { FileStatus } from 'components/v2/Dropzone';
import Typography from 'components/v2/Typography';
import { useFormContext } from 'components/v2/Form';
import { useWizardContext } from 'components/v2/Wizard';

import Instruction from './components/Instruction';

import styles from './UploadCSVFileWizardStep.module.css';

const UploadCSVFileWizardStep = () => {
  const { matchExecutivesParamsValidationStatus, validateMatchExecutiveParams } = useCurrentUserExecutivesMatches();
  const { changeFieldError, errors, values, onFieldChange, onReset } = useFormContext();
  const { hasNextWizardStep, navigateNextWizardStep, navigatePreviousWizardStep } = useWizardContext();

  const fileErrors = errors.source?.file || [];
  const isFileValid =
    !isNil(values.source.file) && matchExecutivesParamsValidationStatus.isFulfilled && isEmpty(fileErrors);

  const handleFileValidate = async (file, delimiter) => {
    const { validationResult } = await validateMatchExecutiveParams({ file, delimiter });
    const { errors: newFileErrors, ...meta } = validationResult;

    onFieldChange('source.meta', meta);
    Object.entries(newFileErrors).forEach(([key, value]) => changeFieldError(`source.${key}`, value));
  };
  const handleFileChange = async (file) => {
    onReset();

    if (!isNil(file)) {
      const delimiter = await detectCsvFileDelimiter(file);

      onFieldChange('source.file', file);
      onFieldChange('source.delimiter', delimiter);

      handleFileValidate(file, delimiter);
    }
  };

  return (
    <div>
      <Typography variant="h1">Upload CSV File</Typography>
      <Instruction meta={values.source.meta} />
      <Dropzone acceptanceContentType={{ 'text/*': ['.csv'] }} onFileChange={handleFileChange}>
        <FileStatus
          errors={fileErrors}
          file={values.source.file}
          validationStatus={matchExecutivesParamsValidationStatus}
          uploadMessage="Drag & drop CSV-file here"
          successIcon={<CsvIcon width={54} height={54} />}
        />
      </Dropzone>

      <div className={styles.actions}>
        <Button onClick={navigatePreviousWizardStep} variant="outlined">
          Back
        </Button>
        <Button disabled={!hasNextWizardStep || !isFileValid} onClick={navigateNextWizardStep}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default UploadCSVFileWizardStep;
