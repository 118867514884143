import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AlertsPage from './AlertsPage';
import { actions as SavedSearchSubscriptionsActions } from './containers/SavedSearchSubscriptionsListContainer'; // eslint-disable-line import/named
import { actions as ProfileSubscriptionActions } from './containers/ProfileSubscriptionsListContainer'; // eslint-disable-line import/named
import { actions as OrganizationSubscriptionsActions } from './containers/OrganizationSubscriptionsListContainer'; // eslint-disable-line import/named
import { actions as ProjectSubscriptionsActions } from './containers/ProjectSubscriptionsListContainer'; // eslint-disable-line import/named
import { actions as SubscriptionConfigurationActions } from './containers/SubscriptionConfigurationContainer'; // eslint-disable-line import/named

const AlertsPageActions = {
  ...ProfileSubscriptionActions,
  ...OrganizationSubscriptionsActions,
  ...SavedSearchSubscriptionsActions,
  ...ProjectSubscriptionsActions,
  ...SubscriptionConfigurationActions,
};

const mapStateToProps = (state) => ({
  isProfileSubscriptionLoading: state.ProfileSubscriptionsListContainerReducer.loading,
  profileSubscriptions: state.ProfileSubscriptionsListContainerReducer.subscriptions,
  profileSubscriptionsNextPage: state.ProfileSubscriptionsListContainerReducer.meta.nextPage,
  profileSubscriptionsTotalCount: state.ProfileSubscriptionsListContainerReducer.meta.totalCount,

  isOrganizationSubscriptionsLoading: state.OrganizationSubscriptionsListContainerReducer.loading,
  organizationSubscriptions: state.OrganizationSubscriptionsListContainerReducer.subscriptions,
  organizationSubscriptionsTotalCount: state.OrganizationSubscriptionsListContainerReducer.meta.totalCount,
  organizationSubscriptionsNextPage: state.OrganizationSubscriptionsListContainerReducer.meta.nextPage,

  savedSearchSubscriptions: state.SavedSearchSubscriptionsListContainerReducer.subscriptions,
  isSavedSearchSubscriptionsLoading: state.SavedSearchSubscriptionsListContainerReducer.loading,
  savedSearchSubscriptionsTotalCount: state.SavedSearchSubscriptionsListContainerReducer.meta.totalCount,
  savedSearchSubscriptionsNextPage: state.SavedSearchSubscriptionsListContainerReducer.meta.nextPage,

  projectSubscriptions: state.ProjectSubscriptionsListContainerReducer.subscriptions,
  isProjectSubscriptionsLoading: state.ProjectSubscriptionsListContainerReducer.loading,
  projectSubscriptionsTotalCount: state.ProjectSubscriptionsListContainerReducer.meta.totalCount,
  projectSubscriptionsNextPage: state.ProjectSubscriptionsListContainerReducer.meta.nextPage,

  subscriptionConfigurationLoading: state.SubscriptionConfigurationContainerReducer.loading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(AlertsPageActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage);
