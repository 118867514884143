import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { FormNext, FormDown } from 'grommet-icons';
import styles from './Spoiler.module.css';

class Spoiler extends Component {
  static propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node,
    className: PropTypes.string,
  };

  state = {
    contentVisible: false,
  };

  handleToggleContent = () => {
    this.setState({
      contentVisible: !this.state.contentVisible,
    });
  };

  render() {
    const { header, children, className } = this.props;
    const { contentVisible } = this.state;
    const rootStyles = cn([styles.root], { [styles.rootOpened]: contentVisible }, className);
    const icon = contentVisible ? <FormDown /> : <FormNext />;

    return (
      <div className={rootStyles}>
        <div className={styles.mobileHeader} role="presentation" onClick={this.handleToggleContent}>
          <div className={styles.headerText}>{header}</div>
          <div className={styles.headerIcon}>{icon}</div>
        </div>
        <h3 className={styles.header}>{header}</h3>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

export default Spoiler;
