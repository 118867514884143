import React from 'react';
import PropTypes from 'prop-types';

import { TableHeadCell } from 'components/v2/Table';

import styles from './Header.module.css';

const Header = (props) => {
  const { sortings, onSortingsChange } = props;

  const handleSortingChange = (sorting) => onSortingsChange([sorting]);

  return (
    <tr className={styles.header}>
      <TableHeadCell field="name" sortable sortings={sortings} onSortingChange={handleSortingChange}>
        Department name
      </TableHeadCell>
      <TableHeadCell width="120px">Number of Seats</TableHeadCell>
      <TableHeadCell
        field="account_type"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="140px"
      >
        Account Type
      </TableHeadCell>
      <TableHeadCell
        field="database_only"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100px"
      >
        Database Only
      </TableHeadCell>
      <TableHeadCell
        field="limited_access.state"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="130px"
      >
        Limited Access
      </TableHeadCell>
      <TableHeadCell>Profile Requests</TableHeadCell>
      <TableHeadCell
        field="projects_available"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="80px"
      >
        Projects
      </TableHeadCell>
      <TableHeadCell>Number of Projects</TableHeadCell>
      <TableHeadCell field="projects_export_enabled" sortable sortings={sortings} onSortingChange={handleSortingChange}>
        Export On Projects
      </TableHeadCell>
      <TableHeadCell width="50px" />
    </tr>
  );
};

Header.propTypes = {
  sortings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSortingsChange: PropTypes.func.isRequired,
};

export default Header;
