import { reject, propEq } from 'ramda';
import ProjectConstants from './ProjectConstants';

const initialState = {
  loading: false,
  project: null,
  saving: false,
};

function ProjectReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProjectConstants.LOAD_PROJECT_START: {
      return { ...state, loading: true };
    }
    case ProjectConstants.LOAD_PROJECT_SUCCESS: {
      const { project } = action;

      return { ...state, loading: false, project };
    }
    case ProjectConstants.RELOAD_PROJECT_SUCCESS: {
      const { project } = action;

      if (project.id !== state.project.id) {
        return state;
      }

      return { ...state, project };
    }
    case ProjectConstants.CREATE_PROJECT_SOURCE_START: {
      return { ...state, saving: true };
    }
    case ProjectConstants.CREATE_PROJECT_SOURCE_SUCCESS: {
      const { source } = action;
      const { project } = state;
      const sources = [...project.sources, source];

      return { ...state, saving: false, project: { ...project, sources } };
    }
    case ProjectConstants.CREATE_PROJECT_SOURCE_ERROR: {
      return { ...state, saving: false };
    }
    case ProjectConstants.DESTROY_PROJECT_SOURCE_START: {
      return { ...state, saving: true };
    }
    case ProjectConstants.DESTROY_PROJECT_SOURCE_SUCCESS: {
      const { sourceId } = action;
      const { project } = state;
      const sources = reject(propEq(sourceId, 'id'), project.sources);

      return { ...state, saving: false, project: { ...project, sources } };
    }
    case ProjectConstants.DESTROY_PROJECT_SOURCE_ERROR: {
      return { ...state, saving: false };
    }
    case ProjectConstants.UPDATE_PROJECT_START: {
      return { ...state, saving: true };
    }
    case ProjectConstants.UPDATE_PROJECT_SUCCESS: {
      const { project } = action;

      return { ...state, saving: false, project };
    }
    case ProjectConstants.UPDATE_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    case ProjectConstants.DESTROY_PROJECT_START: {
      return { ...state, saving: true };
    }
    case ProjectConstants.DESTROY_PROJECT_SUCCESS: {
      return { ...state, saving: false };
    }
    case ProjectConstants.DESTROY_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    case ProjectConstants.CLONE_PROJECT_START: {
      return { ...state, saving: true };
    }
    case ProjectConstants.CLONE_PROJECT_SUCCESS: {
      return { ...state, saving: false };
    }
    case ProjectConstants.CLONE_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default ProjectReducer;
