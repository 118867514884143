import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { LUXON_DATE_FORMAT } from 'utils/DateTime';

import { Field, DatePicker } from 'components/v2/Form';
import Button from 'components/Button';
import Chart from './components/Chart';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'components/ExportButton';
import ProjectsUsage from './components/ProjectsUsage';
import TopViewed from './components/TopViewed';
import Counters from './components/Counters';
import RequestsHistory from './components/RequestsHistory';

import CompanyDashboardPresenter from 'presenters/Company/DashboardPresenter';
import ProjectPresenter from 'presenters/Company/Dashboard/ProjectPresenter';
import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';
import RequestPresenter from 'presenters/RequestPresenter';

import useBackgroundExportJobs from 'hooks/useBackgroundExportJobs';

import styles from './CorporateAccountDashboard.module.css';

const CorporateAccountDashboard = (props) => {
  const {
    dashboard,
    loading,
    form,
    to,
    from,
    showPreviousIntervalData,
    loadDashboard,
    changeDashboardForm,
    companyDepartments,
    exportType,
    companyId,
    requests,
    requestsLoadingMoreStatus,
    requestsLoadingStatus,
    requestsMeta,
    onLoadRequests,
    onLoadMoreRequests,
    projects,
    projectsLoadingMoreStatus,
    projectsLoadingStatus,
    projectsMeta,
    loadProjects,
    loadMoreProjects,
  } = props;

  const { departmentId, ...filters } = form;
  const period = CompanyDashboardPresenter.period(dashboard);
  const publicApiStatistics = CompanyDashboardPresenter.publicApi(dashboard);

  useEffect(() => {
    loadDashboard({ from, to });
  }, []);

  const { createBackgroundExportJob, showBackgroundExportJob } = useBackgroundExportJobs();

  const handleDashboardLoad = () => {
    loadDashboard(form);
  };

  const handleFieldChange = (fieldName) => (value) => changeDashboardForm({ ...form, [fieldName]: value });

  const handleDateFieldChange = (fieldName) => (value) => changeDashboardForm({ ...form, [fieldName]: value });

  const handleProjectsLoad = (newFilters) =>
    loadProjects({ ...filters, q: { ...newFilters.q, company_department_id_eq: departmentId } });

  const handleProjectsLoadMore = (newFilters) =>
    loadMoreProjects({
      ...filters,
      q: { ...newFilters.q, companyDepartmentIdEq: departmentId },
      page: projectsMeta.nextPage,
    });

  const handleRequestsLoad = (newFilters) => onLoadRequests({ ...filters, ...newFilters });

  const handleRequestLoadMore = (newFilters) =>
    onLoadMoreRequests({
      ...filters,
      ...newFilters,
      page: requestsMeta.nextPage,
    });

  const renderDateInput = (title, fieldName) => {
    const { [fieldName]: value } = form;

    return (
      <div className={styles.horizontalField}>
        <div className={styles.label}>{title}</div>
        <Field>
          <DatePicker value={value} onChange={handleDateFieldChange(fieldName)} dateFormat={LUXON_DATE_FORMAT} />
        </Field>
      </div>
    );
  };

  const renderCompanyDepartmentSelect = () => (
    <div className={styles.horizontalField}>
      <div className={styles.label}>Company Department</div>
      <Select
        className={styles.select}
        isClearable
        options={companyDepartments}
        onValueChange={handleFieldChange('departmentId')}
        getOptionValue={CompanyDepartmentPresenter.id}
        getOptionLabel={CompanyDepartmentPresenter.name}
        selectedOptionValue={departmentId}
        noBottom
      />
    </div>
  );

  const handleEventsCSVExport = () =>
    createBackgroundExportJob({
      exportableId: companyId,
      exportableType: 'Company',
      type: exportType,
      params: { search: form },
    });

  if (loading || !dashboard) {
    return <Spinner />;
  }

  return (
    <>
      <Counters dashboard={dashboard} />

      <div className={styles.horizontalForm}>
        {renderDateInput('From', 'from')}
        {renderDateInput('To', 'to')}
        {renderCompanyDepartmentSelect()}
        <div className={styles.controls}>
          <Button className={styles.button} label="Submit" onClick={handleDashboardLoad} primary />
          <BackgroundExportResultNotifier
            component={ExportButton}
            onExport={handleEventsCSVExport}
            onPoll={showBackgroundExportJob}
            onExportPropName="onClick"
            label="Export"
            shouldOpenOnCompletion
          />
        </div>
      </div>

      {publicApiStatistics.length > 0 && (
        <Chart
          title="API"
          data={publicApiStatistics}
          period={period}
          showPreviousIntervalData={showPreviousIntervalData}
        />
      )}

      <Chart
        title="Views & Downloads"
        data={CompanyDashboardPresenter.viewsAndDownloads(dashboard)}
        period={period}
        showPreviousIntervalData={showPreviousIntervalData}
      />

      <Chart
        title="Searches"
        data={CompanyDashboardPresenter.search(dashboard)}
        period={period}
        showPreviousIntervalData={showPreviousIntervalData}
      />

      <Chart
        title="Logins"
        data={CompanyDashboardPresenter.signIn(dashboard)}
        period={period}
        showPreviousIntervalData={showPreviousIntervalData}
      />
      <TopViewed dashboard={dashboard} />

      {CompanyDashboardPresenter.projectsCount(dashboard) > 0 && (
        <ProjectsUsage
          activeProjectsCount={CompanyDashboardPresenter.activeProjectsCount(dashboard)}
          projects={projects}
          nextPage={projectsMeta.nextPage}
          onLoad={handleProjectsLoad}
          onLoadMore={handleProjectsLoadMore}
          loadingMoreStatus={projectsLoadingMoreStatus}
          loadingStatus={projectsLoadingStatus}
        />
      )}

      <RequestsHistory
        requests={requests}
        nextPage={requestsMeta.nextPage}
        onLoadMore={handleRequestLoadMore}
        onLoad={handleRequestsLoad}
        loadingMoreStatus={requestsLoadingMoreStatus}
        loadingStatus={requestsLoadingStatus}
      />
    </>
  );
};

CorporateAccountDashboard.propTypes = {
  changeDashboardForm: PropTypes.func.isRequired,
  companyDepartments: PropTypes.arrayOf(CompanyDepartmentPresenter.shape()).isRequired,
  dashboard: CompanyDashboardPresenter.shape(),
  loading: PropTypes.bool.isRequired,
  loadDashboard: PropTypes.func.isRequired,
  form: PropTypes.shape(),
  from: PropTypes.string,
  to: PropTypes.string,
  showPreviousIntervalData: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  exportType: PropTypes.string.isRequired,
  requests: PropTypes.arrayOf(RequestPresenter.shape()).isRequired,
  requestsLoadingMoreStatus: PropTypes.shape(),
  requestsLoadingStatus: PropTypes.shape(),
  requestsMeta: PropTypes.shape(),
  onLoadRequests: PropTypes.func.isRequired,
  onLoadMoreRequests: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  projectsLoadingMoreStatus: PropTypes.shape(),
  projectsLoadingStatus: PropTypes.shape(),
  projectsMeta: PropTypes.shape(),
  loadProjects: PropTypes.func.isRequired,
  loadMoreProjects: PropTypes.func.isRequired,
};

export default CorporateAccountDashboard;
