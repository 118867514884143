import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Spoiler from 'components/Spoiler';
import RawHtml from 'components/RawHtml';

import TopicPresenter from 'presenters/TopicPresenter';

import styles from './ReviewTopicsList.module.css';

const ReviewTopicsList = (props) => {
  const { value } = props;

  return (
    <Spoiler>
      {value.map((topic) => (
        <Fragment key={topic.id}>
          <h4 className={styles.strong}>{TopicPresenter.title(topic)}</h4>
          <RawHtml className={styles.wysiwyg} innerHTML={TopicPresenter.description(topic)} />
        </Fragment>
      ))}
    </Spoiler>
  );
};

ReviewTopicsList.propTypes = {
  value: PropTypes.arrayOf(TopicPresenter.shape()).isRequired,
};

export default ReviewTopicsList;
