import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';

import { withAuthenticator } from 'containers/Authenticator';

import Navbar from './components/Navbar';
import Notifications from 'containers/Notifications';
import AdminHeader from './components/AdminHeader';

import styles from './Layout.module.css';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import { ContextProvider } from 'components/ContextProvider';

const LayoutComponent = (props) => {
  const { children, onSignOut, onStopImpersonate, currentUser } = props;
  const containerRef = useRef(null);
  const splitRightStyles = cn([styles.splitRight], 'client');

  return (
    <div className={styles.root}>
      <ContextProvider container={containerRef}>
        <AdminHeader onSignOut={onSignOut} onStopImpersonate={onStopImpersonate} currentUser={currentUser} />
        <div className={styles.split}>
          <div className={styles.splitLeft}>
            <Navbar currentUser={currentUser} onStopImpersonate={onStopImpersonate} onSignOut={onSignOut} />
          </div>
          <div className={splitRightStyles}>
            <div ref={containerRef} className={styles.main}>
              {children}
            </div>
          </div>
        </div>
      </ContextProvider>
      <Notifications />
    </div>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: CurrentUserPresenter.shape(),
  onSignOut: PropTypes.func.isRequired,
  onStopImpersonate: PropTypes.func.isRequired,
};

const Layout = withAuthenticator(LayoutComponent, ({ signOut, stopImpersonate }) => ({
  onSignOut: signOut,
  onStopImpersonate: stopImpersonate,
}));

export default Layout;
