import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Provide basic information such as name, common name (aka nickname), gender, current title/company
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <p>
        Dr. Jonathan (John) J. Jacobs
        <br />
        CEO
        <br />
        Acme Company
      </p>
      <p>
        <strong>Important Note: </strong>
        You need to create the “Employment History” section FIRST and then select the job that you want to appear in
        this section (should be their current job).
      </p>
      <p>
        <strong>Style Tips:</strong>
      </p>
      <ul>
        <li>Only use salutation if the person is a Dr.. We don’t want to use Mr. Mrs. Ms., etc.</li>
        <li>
          Common name is the name they are typically called. Make sure you know for certain whether they use a common
          name before you put one in there. For example, Andrew Jassy of AWS is very commonly called “Andy” including on
          his corporate bio. Do not make assumptions about a common name unless you see it used several places.
          Alternatively, do not ignore evidence that someone uses a common name. For example, if their corporate bio
          says “Andrew Jassy” but their LinkedIn and Twitter pages say “Andy Jassy,” then make sure you include “Andy”
          in the “Common Name” field.
        </li>
        <li>Never put a common name in the “Middle Name” field—we have had a problem with people doing this.</li>
        <li>
          If you only have a middle initial—not the full middle name--then include the middle initial with a period (see
          “J.” in the above example) in the Middle Name field.
        </li>
      </ul>
    </div>
  );
}
