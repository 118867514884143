import { prop } from 'ramda';
import SampleProfilesConstants from './SampleProfilesConstants';
import SampleProfilesRepository from 'repositories/admin/SampleProfilesRepository';
import PublishedProfilesRepository from 'repositories/admin/PublishedProfilesRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';

export function searchProfile(filters) {
  return () => {
    const params = { ...buildFilterParams(filters) };

    return PublishedProfilesRepository.index(params).then(({ items }) => items.map(prop('published')));
  };
}

export function changeSampleProfile(sampleProfile) {
  return { type: SampleProfilesConstants.CHANGE_SAMPLE_PROFILE, sampleProfile };
}

export function loadSampleProfiles(filters = {}) {
  return (dispatch) => {
    dispatch({ type: SampleProfilesConstants.LOAD_SAMPLE_PROFILES_START });

    const params = buildFilterParams(filters);
    return SampleProfilesRepository.index(params).then(({ items }) => {
      dispatch({ type: SampleProfilesConstants.LOAD_SAMPLE_PROFILES_SUCCESS, sampleProfiles: items });

      return items;
    });
  };
}

export function updateSampleProfile(sampleProfileId, attributes) {
  return (dispatch) => {
    dispatch({ type: SampleProfilesConstants.UPDATE_SAMPLE_PROFILE_START, sampleProfileId });

    return SampleProfilesRepository.update(sampleProfileId, attributes)
      .then(({ sampleProfile }) => {
        dispatch({ type: SampleProfilesConstants.UPDATE_SAMPLE_PROFILE_SUCCESS, sampleProfile });
        addNotification('Sample Porfile was successfully updated', 'normal')(dispatch);

        return sampleProfile;
      })
      .catch((error) => {
        dispatch({ type: SampleProfilesConstants.UPDATE_SAMPLE_PROFILE_ERROR, sampleProfileId });

        throw error;
      });
  };
}
