import React from 'react';
import PropTypes from 'prop-types';
import styles from './Profile.module.css';

import { Anchor } from 'grommet';
import DownloadAnchor from 'components/DownloadAnchor';
import { Trash as TrashIcon, DocumentDownload as DocumentDownloadIcon } from 'grommet-icons';
import ProfileAvatar from 'components/ProfileAvatar';
import NewToRoleBadge from 'components/client/NewToRoleBadge';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import ProfilesExportResultNotifier from 'containers/UserPanel/components/ProfilesExportResultNotifier';

import ProfilePolicy from 'policies/ProfilePolicy';
import ProjectPolicy from 'policies/ProjectPolicy';

import { NOTIFICATION_METHODS } from 'presenters/ExportJobPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import { PROFILE_PDF_EXPORT_JOB_TYPE } from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkSearchPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import { logClickOnProjectPageProfileDownload, logClickOnProjectPageProfileDelete } from 'utils/amplitude';

import Routes from 'routes';

const Profile = (props) => {
  const { profile, currentUser, saving, project, onRemove, onExport } = props;

  const currentWorks = ProfilePresenter.currentWorks(profile);

  const handleRemove = () => {
    logClickOnProjectPageProfileDelete();

    return onRemove(profile);
  };

  const handleExportPdf = () => {
    const params = { profileId: profile.id, currentUserId: CurrentUserPresenter.id(currentUser) };

    logClickOnProjectPageProfileDownload();

    return onExport({ type: PROFILE_PDF_EXPORT_JOB_TYPE, notificationMethod: NOTIFICATION_METHODS.NONE, params });
  };

  const renderCurrentWork = (currentWork) => (
    <div key={WorkPresenter.id(currentWork)} className={styles.company}>
      {WorkPresenter.organizationName(currentWork)}
      {`, ${WorkPresenter.jobTitle(currentWork)}`}
    </div>
  );

  return (
    <div className={styles.root}>
      <Anchor className={styles.general} href={Routes.profilePath(ProfilePresenter.slug(profile))} target="_blank">
        <div className={styles.general}>
          <ProfileAvatar size="60" src={ProfilePresenter.photoUrl(profile)} />
          <div className={styles.info}>
            <div className={styles.header}>
              <div className={styles.name}>{ProfilePresenter.fullName(profile)}</div>

              <div className={styles.profileLabels}>
                {ProfilePresenter.isNewToRole(profile) && <NewToRoleBadge />}

                {ProfilePresenter.isMemberFortune500(profile) && <Fortune500BoardMemberBadge />}
                {ProfilePresenter.isMemberFtse100(profile) && <Ftse100BoardMemberBadge />}
              </div>
            </div>
            {currentWorks.map(renderCurrentWork)}
          </div>
        </div>
      </Anchor>
      <div className={styles.actions}>
        {ProfilePolicy.canSeeDownloadButton(currentUser, profile) && (
          <ProfilesExportResultNotifier
            disabled={!ProjectPolicy.canExport(project)}
            className={styles.download}
            component={DownloadAnchor}
            onExport={handleExportPdf}
            onExportPropName="onClick"
            icon={<DocumentDownloadIcon />}
            shouldOpenOnCompletion
          />
        )}
        {ProjectPolicy.canRemoveProfile(project, profile) && (
          <Anchor className={styles.delete} onClick={handleRemove} disabled={saving}>
            <TrashIcon color="#00a0ce" />
          </Anchor>
        )}
      </div>
    </div>
  );
};

Profile.propTypes = {
  onExport: PropTypes.func.isRequired,
  project: ProjectPresenter.shape().isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
  profile: ProfilePresenter.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default Profile;
