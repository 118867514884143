import RolesConstants from './RolesConstants';

const initialState = {
  loading: false,
  roles: [],
};

function RolesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RolesConstants.LOAD_PROJECT_TOP_ROLES_START: {
      return { ...state, loading: true };
    }
    case RolesConstants.LOAD_PROJECT_TOP_ROLES_SUCCESS: {
      const { roles } = action;

      return { ...state, roles, loading: false };
    }
    default:
      return state;
  }
}

export default RolesReducer;
