import TopRelationshipsToMyExecutivesConstants from './TopRelationshipsToMyExecutivesConstants';
import RelationshipsMappingToExecutivesRepository from 'repositories/CurrentUser/Project/RelationshipsMappingToExecutivesRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function loadTopRelationshipsToMyExecutivesToExecutives(projectId, profilesFilters) {
  return (dispatch) => {
    dispatch({ type: TopRelationshipsToMyExecutivesConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_TO_EXECUTIVES_START });

    const params = buildFilterParams(profilesFilters);

    return RelationshipsMappingToExecutivesRepository.show(projectId, params).then(({ relationshipsMapping, meta }) => {
      dispatch({
        type: TopRelationshipsToMyExecutivesConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_TO_EXECUTIVES_SUCCESS,
        relationshipsMapping,
        userColleaguesExists: meta.userColleaguesExists,
        userBoardMembersExists: meta.userBoardMembersExists,
      });

      return relationshipsMapping;
    });
  };
}
