import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';

import { Search } from 'grommet-icons';
import { TextInput } from 'grommet';

import styles from './SearchInput.module.css';

export default class SearchInput extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const className = cn(styles.search, this.props.className);

    return <TextInput size="small" icon={<Search size="small" />} reverse {...this.props} className={className} />;
  }
}
