import PropTypes from 'prop-types';
import React from 'react';
import ReactDropzone from 'react-dropzone';
import { isNil } from 'ramda';
// import clsx from 'clsx';
// import { isPresent, isBlank } from 'utils/HelperMethods';
// import { isExceedMaxSize, isMatchFileExt } from 'utils/fileUtils';
// import cn from 'clsx';

// import Box from 'grommet/components/Box';
// import Icon from 'components/Icon';
// import Spinning from 'grommet/components/icons/Spinning';

import styles from './Dropzone.module.css';

const Dropzone = (props) => {
  const { acceptanceContentType, children, onFileChange } = props;

  const handleFileDrop = (acceptedFiles, rejectedFiles) => {
    const newFile = !isNil(acceptedFiles[0]) ? acceptedFiles[0] : rejectedFiles[0];

    onFileChange(newFile);
  };

  return (
    <ReactDropzone accept={acceptanceContentType} onDrop={handleFileDrop}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={styles.dropzone}>
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </ReactDropzone>
  );
};

Dropzone.propTypes = {
  acceptanceContentType: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  onFileChange: PropTypes.func.isRequired,
};

export default Dropzone;

/*
export default class CsvDropzone extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    file: PropTypes.shape(),
    isValidating: PropTypes.bool,
    isFileValid: PropTypes.bool,
  };

  handleDrop = (acceptedFiles, rejectedFiles) => {
    const { onLoad } = this.props;
    const file = isPresent(acceptedFiles[0]) ? acceptedFiles[0] : rejectedFiles[0];

    onLoad(file);
  };

  renderFileStatus() {
    const { isValidating, isFileValid, file } = this.props;

    if (isValidating) {
      return (
        <div className={styles.validating}>
          <Spinning size="large" />
          <div className={styles.message}>File is validating</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    if (isExceedMaxSize(file)) {
      return (
        <div className={styles.error}>
          <Icon name="notAllowed" />
          <div className={styles.message}>File size should be less than 4 megabytes</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    if (isFileValid) {
      return (
        <div className={styles.success}>
          <Icon name="csvIcon" />
          <div className={styles.message}>File successfully uploaded</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    if (!isMatchFileExt(file, 'csv')) {
      return (
        <div className={styles.error}>
          <Icon name="notAllowed" />
          <div className={styles.message}>Wrong file type. Please upload a .CSV</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    return (
      <div className={styles.error}>
        <Icon name="notAllowed" />
        <div className={styles.message}>Incorrect file</div>
        <div className={styles.anotherFile}>Upload another file</div>
      </div>
    );
  }

  renderFileUploadSuggestion() {
    return (
      <div className={styles.noFile}>
        <div className={styles.drop}>Drag & drop CSV-file here</div>
        <div className={styles.or}>or</div>
        <div className={styles.file}>Choose file</div>
      </div>
    );
  }

  render() {
    const { file, isValidating, isFileValid } = this.props;
    const dropStyles = cn([styles.dropWrapper], {
      [styles.dropWrapperError]: isPresent(file) && !isValidating && !isFileValid,
    });

    return (
      <Box direction="row" justify="center" margin={{ bottom: 'medium' }}>
        <Dropzone
          accept="text/csv,text/*,"
          onDrop={this.handleDrop}
          ref={this.assignDropzoneRef}
          className={dropStyles}
        >
          {isBlank(file) ? this.renderFileUploadSuggestion() : this.renderFileStatus()}
        </Dropzone>
      </Box>
    );
  }
}
  */
