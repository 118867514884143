import React from 'react';

export default function () {
  return (
    <div>
      <p>This is pretty standard information.</p>
      <p>
        Make sure you are including the address and main telephone number for the organization’s corporate headquarters
        or main office. Be very careful you have the main corporate headquarters phone number and not a “customer
        service” number.
      </p>
      <p>Include the website address of the company or organization.</p>
      <p>
        <strong>Style Tips: </strong>
      </p>
      <ul>
        <li>Use normal abbreviations often used in addresses such as “Ave.” “Blvd.” “St.”</li>
        <li>Corporate website link url must include the http:// prefix in order to work. Please include it always.</li>
      </ul>
    </div>
  );
}
