import * as Routes from 'routes/Api';
import FormDataFetchHelpers from 'utils/FetchHelpers/FormDataFetchHelpers';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(project) {
    const url = Routes.apiV1CurrentUserProjectsPath();

    return FormDataFetchHelpers.post(url, { project });
  },

  index(params = {}) {
    const url = Routes.apiV1CurrentUserProjectsPath(params);

    return JsonFetchHelpers.get(url);
  },

  show(id) {
    const url = Routes.apiV1CurrentUserProjectPath(id);

    return JsonFetchHelpers.get(url);
  },

  update(id, project = {}) {
    const url = Routes.apiV1CurrentUserProjectPath(id);

    return JsonFetchHelpers.patch(url, { project });
  },

  destroy(id) {
    const url = Routes.apiV1CurrentUserProjectPath(id);

    return JsonFetchHelpers.delete(url);
  },

  clone(id, project = {}) {
    const url = Routes.cloneApiV1CurrentUserProjectPath(id);

    return JsonFetchHelpers.post(url, { project });
  },
};
