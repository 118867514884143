import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ShareModal from './ShareModal';
import { actions } from 'containers/UserPanel/containers/Project/containers/SharesContainer'; // eslint-disable-line import/named

const mapStateToProps = (state) => ({
  isSharesLoading: state.SharesContainerReducer.isLoading,
  isSharesSaving: state.SharesContainerReducer.isSaving,
  shares: state.SharesContainerReducer.shares,
  sharesNextPage: state.SharesContainerReducer.nextPage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
