import u from 'updeep';
import { both, includes, equals, insert, mergeLeft, pipe, prop, propEq, reject } from 'ramda';
import RequestsKanbanBoardConstants from './RequestsKanbanBoardConstants';

const initialState = {
  columns: [],
  saving: false,
};

const defaultColumnState = {
  loading: false,
  cards: [],
  nextPage: null,
  totalCount: null,
  droppable: false,
};

function updateColumn(columnId, func, additionalCondition) {
  const columenCondition = propEq(columnId, 'id');
  const condition = additionalCondition ? both(columenCondition, additionalCondition) : columenCondition;

  return u.map(u.if(condition, func));
}

function updateCard(cardId, func) {
  return (column) => ({ ...column, cards: u.map(u.if(propEq(cardId, 'id'), func), column.cards) });
}

function removeCard(cardId) {
  return (column) => ({ ...column, cards: reject(propEq(cardId, 'id'), column.cards) });
}

function addCard(card, position) {
  return (column) => ({ ...column, cards: insert(position, card, column.cards) });
}

function isFilterEquals(filters) {
  return pipe(prop('filters'), equals(filters));
}

function RequestsKanbanBoardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RequestsKanbanBoardConstants.INITIALIZE_KANBAN_BOARD: {
      const columns = action.columns.map((column) => ({ ...defaultColumnState, ...column }));

      return { ...initialState, columns };
    }
    case RequestsKanbanBoardConstants.LOAD_REQUESTS_START: {
      const { columnId } = action;
      const columns = updateColumn(columnId, mergeLeft({ loading: true }))(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.LOAD_REQUESTS_SUCCESS: {
      const { columnId, cards, nextPage, totalCount, filters } = action;
      const updateAttrs = (column) => ({
        ...column,
        loading: false,
        cards: [...column.cards, ...cards],
        nextPage,
        totalCount,
      });
      const columns = updateColumn(columnId, updateAttrs, isFilterEquals(filters))(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.LOAD_REQUESTS_ERROR: {
      const { columnId } = action;
      const columns = updateColumn(columnId, mergeLeft({ loading: false }))(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.FILTER_REQUESTS_START: {
      const { columnId, filters } = action;
      const attributes = { loading: true, cards: [], nextPage: 1, totalCount: null, filters };
      const columns = updateColumn(columnId, mergeLeft(attributes))(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.FILTER_REQUESTS_SUCCESS: {
      const { columnId, cards, nextPage, totalCount, filters } = action;
      const attributes = { loading: false, cards, nextPage, totalCount };
      const columns = updateColumn(columnId, mergeLeft(attributes), isFilterEquals(filters))(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.FILTER_REQUESTS_ERROR: {
      const { columnId, filters } = action;
      const columns = updateColumn(columnId, mergeLeft({ loading: false }), isFilterEquals(filters))(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.SET_DROPPABLE_COLUMNS: {
      const { columnIds } = action;
      const setDroppable = (column) => ({ ...column, droppable: includes(column.id, columnIds) });

      return u({ columns: u.map(setDroppable) }, state);
    }
    case RequestsKanbanBoardConstants.MOVE_REQUEST: {
      const { requestId, fromColumnId, toColumnId, toPosition } = action;
      const column = state.columns.find(propEq(fromColumnId, 'id'));
      const card = column.cards.find(propEq(requestId, 'id'));

      const columns = pipe(
        updateColumn(fromColumnId, removeCard(requestId)),
        updateColumn(toColumnId, addCard(card, toPosition)),
      )(state.columns);

      return { ...state, columns };
    }
    case RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_START: {
      return { ...state, saving: true };
    }
    case RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_SUCCESS: {
      const { request } = action;
      const columns = u.map(updateCard(request.id, request))(state.columns);

      return { ...state, saving: false, columns };
    }
    case RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_ERROR: {
      return { ...state, saving: false };
    }
    case RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_DATA: {
      const { request } = action;
      const columns = u.map(updateCard(request.id, request))(state.columns);

      return { ...state, columns };
    }
    default:
      return state;
  }
}

export default RequestsKanbanBoardReducer;
