import PropTypes from 'prop-types';
import React from 'react';

import NotAllowedIcon from 'icons/NotAllowedIcon';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import styles from './FailStatus.module.css';

const FailStatus = (props) => {
  const { errors } = props;

  return (
    <div className={styles.failStatus}>
      <NotAllowedIcon color="orange" width={60} height={60} />
      {errors.map((error) => (
        <Typography key={error} variant="h3">
          {error}
        </Typography>
      ))}
      <Button variant="link">
        <Typography color="primary" variant="h3">
          Upload another file
        </Typography>
      </Button>
    </div>
  );
};

FailStatus.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FailStatus;
