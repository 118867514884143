import { pascalize } from 'humps';
import pluralize from 'pluralize';
import { includes } from 'ramda';

export function toResourceName(actionName, resource) {
  const capName = pascalize(resource);
  if (pluralize.isPlural(actionName)) {
    return actionName.replace(pluralize(capName), 'Resources');
  }
  return actionName.replace(capName, 'Resource');
}

export function toSliceName(actionName, resource) {
  return `${toResourceName(actionName, resource)}Slice`;
}

export function toRealName(actionName, resource) {
  const capName = pascalize(resource);
  if (includes('Resources', actionName)) {
    return actionName.replace('Resources', pluralize(capName));
  }
  return actionName.replace('Resource', capName);
}

export function keysToRealName(resource) {
  return (object) =>
    Object.keys(object).reduce((result, key) => ({ ...result, [toRealName(key, resource)]: object[key] }), {});
}
