import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Paragraph } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import Headline from 'components/grommet/Headline';
import Icon from 'components/Icon';

import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

import styles from './ReviewPersonInformation.module.css';

export default class ReviewPersonInformation extends Component {
  static propTypes = {
    value: ProfilePresenter.shape(),
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()),
  };

  renderCurrentWork = (currentWork) => {
    const { jobFunctions } = this.props;

    return (
      <Paragraph key={WorkPresenter.id(currentWork)} margin="none" className={styles.organization}>
        {WorkPresenter.fullJobTitle(currentWork)}
        <br />
        {WorkPresenter.organizationNameWithState(currentWork)}
        <br />
        Job Functions: {WorkPresenter.jobFunctionsStr(currentWork, jobFunctions)}
      </Paragraph>
    );
  };

  render() {
    const { value: profile } = this.props;
    const photoUrl = ProfilePresenter.photoUrl(profile);
    const profileFullName = ProfilePresenter.fullNameWithSalutation(profile);
    const currentWorks = ProfilePresenter.currentWorks(profile) || [];

    return (
      <div className={styles.root}>
        <div className={styles.avatar}>
          {isBlank(photoUrl) ? (
            <Icon name="avatarPlaceholder" className={styles.userpic} />
          ) : (
            <div className={styles.container}>
              <img src={photoUrl} className={styles.photo} alt={profileFullName} />
            </div>
          )}
        </div>
        <Box>
          <Headline size="small" margin="none" className={styles.fullName}>
            {profileFullName}
          </Headline>
          {currentWorks.map(this.renderCurrentWork)}
          <Paragraph margin="none" className={styles.date}>
            Last updated: {ProfilePresenter.formattedProfileUpdatedAt(profile)}
          </Paragraph>
        </Box>
      </div>
    );
  }
}
