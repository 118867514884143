import { dictionary } from 'components/Icon';

const CLASS_NAME = 'placeholder';

function mount(nodeGroup, props) {
  return nodeGroup
    .append('svg')
    .attr('class', CLASS_NAME)
    .attr('x', -props.node.avatarRadius)
    .attr('y', -props.node.avatarRadius)
    .attr('width', props.node.avatarRadius * 2)
    .attr('height', props.node.avatarRadius * 2)
    .attr('viewBox', dictionary.avatarPlaceholder.viewBox)
    .append('svg:use')
    .attr('xlink:href', dictionary.avatarPlaceholder.id);
}

export default { mount };
