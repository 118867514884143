import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InterestListPage from './InterestListPage';
import * as actions from './InterestListPageActions';

const mapStateToProps = (state) => ({
  loading: state.InterestListPageReducer.loading,
  interests: state.InterestListPageReducer.interests,
  nextPage: state.InterestListPageReducer.nextPage,
  filters: state.InterestListPageReducer.filters,
  selectedInterestIds: state.InterestListPageReducer.selectedInterestIds,
  totalCount: state.InterestListPageReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InterestListPage);
