import NewPasswordConstants from './NewPasswordConstants';

const initialState = {
  form: { password: '', passwordConfirmation: '' },
  errors: null,
  saving: false,
  isPasswordChanged: false,
};

function NewPasswordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NewPasswordConstants.INITIALIZE_NEW_PASSWORD_FORM: {
      return { ...initialState, form: { ...initialState.form, token: action.token } };
    }
    case NewPasswordConstants.CHANGE_NEW_PASSWORD_FORM: {
      return { ...state, form: action.form };
    }
    case NewPasswordConstants.UPDATE_NEW_PASSWORD_START: {
      return { ...state, saving: true };
    }
    case NewPasswordConstants.UPDATE_NEW_PASSWORD_SUCCESS: {
      return { ...state, saving: false, isPasswordChanged: true };
    }
    case NewPasswordConstants.UPDATE_NEW_PASSWORD_ERROR: {
      return { ...state, saving: false, errors: action.errors };
    }
    default:
      return state;
  }
}

export default NewPasswordReducer;
