import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import ResourcesRepository from 'repositories/customer/ResourceCenter/ResourcesRepository';

const restSlice = createRestSlice({
  resource: 'resource',
  repository: ResourcesRepository,
  slices: ['loadResources', 'loadMoreResources'],
});

const initialState = {
  ...restSlice.initialState,
};

const resourcesSlice = createSlice({
  name: 'clientCustomerResourceCenterResources',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientCustomerResourceCenterResourcesReducer = resourcesSlice.reducer;

export default resourcesSlice;

export const clientCustomerResourceCenterResourcesRestHooks = restSlice.hooks(
  resourcesSlice,
  'clientCustomerResourceCenterResourcesReducer',
);
