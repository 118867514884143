/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProductionRepository from 'repositories/admin/EditorialDashboard/ProductionRepository';

const initialState = {
  loading: false,
  requestTypesStatistics: null,
  requestsStatisticsByDays: null,
};

const adminEditorialDashboardProduction = createSlice({
  name: 'adminEditorialDashboardProduction',
  initialState,
  reducers: {
    loadProfilesStart(state) {
      state.isLoading = true;
    },
    loadProductionSuccess(state, { payload }) {
      const { requestTypesStatistics, requestsStatisticsByDays } = payload;

      state.isLoading = false;
      state.requestTypesStatistics = requestTypesStatistics;
      state.requestsStatisticsByDays = requestsStatisticsByDays;
    },
    loadProductionError(state) {
      state.isLoading = false;
    },
  },
});

const { actions } = adminEditorialDashboardProduction;

function loadProduction() {
  return (dispatch) => {
    dispatch(actions.loadProfilesStart());

    return ProductionRepository.show()
      .then(({ production }) => {
        dispatch(actions.loadProductionSuccess(production));

        return production;
      })
      .catch((errors) => {
        dispatch(actions.loadProductionError());

        throw errors;
      });
  };
}

export const useAdminEditorialDashboardProductionActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      loadProduction,
    },
    dispatch,
  );
};

export default adminEditorialDashboardProduction.reducer;
