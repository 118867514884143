import NewInterestModalConstants from './NewInterestModalConstants';
import InterestsRepository from 'repositories/admin/InterestsRepository';

export function createInterest(interestParams) {
  return (dispatch) => {
    dispatch({ type: NewInterestModalConstants.CREATE_INTEREST_START });

    return InterestsRepository.create(interestParams)
      .then(({ interest }) => {
        dispatch({ type: NewInterestModalConstants.CREATE_INTEREST_SUCCESS, interest });

        return interest;
      })
      .catch((json) => {
        dispatch({ type: NewInterestModalConstants.CREATE_INTEREST_ERROR });
        throw json;
      });
  };
}
