import TopTagsConstants from './TopTagsConstants';
import TopTagsRepository from 'repositories/CurrentUser/Project/TopTagsRepository';

export function loadTopTags(projectId) {
  return (dispatch) => {
    dispatch({ type: TopTagsConstants.LOAD_PROJECT_TOP_TAGS_START });

    return TopTagsRepository.index(projectId).then(({ items }) => {
      dispatch({ type: TopTagsConstants.LOAD_PROJECT_TOP_TAGS_SUCCESS, tags: items });

      return items;
    });
  };
}
