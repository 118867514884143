import React from 'react';
import PropTypes from 'prop-types';
import { isNil, propEq, update } from 'ramda';

import { useCurrentUserRemainingRequestsNumber } from 'hooks';

import SourcePresenter from 'presenters/Project/SourcePresenter';
import { DO_NOT_REQUEST_STATE_EVENT, REQUEST_PROFILE_STATE_EVENT } from 'presenters/Project/CsvItemPresenter';

import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import RequestsReviewTable from 'components/client/RequestsReviewTable';

import styles from './ProjectRequestExecutivesWizardStep.module.css';

const ProjectRequestExecutivesWizardStep = (props) => {
  const { source, updateSource } = props;
  const { csvItemsAttributes } = source;

  const [remainingRequestsNumber] = useCurrentUserRemainingRequestsNumber();

  const requests = csvItemsAttributes
    .filter(({ stateEvent }) => [DO_NOT_REQUEST_STATE_EVENT, REQUEST_PROFILE_STATE_EVENT].includes(stateEvent))
    .map((csvItemAttributes) => {
      const { firstName, lastName, jobTitle, organizationName } = csvItemAttributes;

      return {
        ...csvItemAttributes,
        subjectAttributes: { firstName, lastName, jobTitle, organizationName },
      };
    });

  const selectedRequestIndexes = requests
    .map(({ stateEvent }, index) => (stateEvent === REQUEST_PROFILE_STATE_EVENT ? index : null))
    .filter((index) => !isNil(index));

  const handleRequestsChange = (newRequests) => {
    const newCsvItemsAttributes = csvItemsAttributes.map((csvItemAttributes) => {
      const request = newRequests.find(propEq(csvItemAttributes.index, 'index'));

      return { ...csvItemAttributes, ...request?.subjectAttributes };
    });

    updateSource({ ...source, csvItemsAttributes: newCsvItemsAttributes });
  };

  const handleSelectedRequestExecutiveMatchIndexesChange = (requestIndexes) => {
    const newCsvItemsAttributes = requests.reduce((acc, request, index) => {
      const stateEvent = requestIndexes.includes(index) ? REQUEST_PROFILE_STATE_EVENT : DO_NOT_REQUEST_STATE_EVENT;
      const csvItemAttributesIndex = acc.findIndex(propEq(request.index, 'index'));

      return update(csvItemAttributesIndex, { ...acc[csvItemAttributesIndex], stateEvent }, acc);
    }, csvItemsAttributes);

    updateSource({ ...source, csvItemsAttributes: newCsvItemsAttributes });
  };

  return (
    <>
      <WizardTitle
        subtitle={
          <>
            If you would like to request profiles of any of these executives so they can be added to your project,
            <br />
            check the boxes next to their names below and submit the form.
          </>
        }
      />
      <div className={styles.root}>
        <RequestsReviewTable
          requests={requests}
          onRequestsChange={handleRequestsChange}
          onSelectedRequestIndexesChange={handleSelectedRequestExecutiveMatchIndexesChange}
          remainingRequestsNumber={remainingRequestsNumber}
          selectedRequestIndexes={selectedRequestIndexes}
        />
      </div>
      <WizardNavigation />
    </>
  );
};

ProjectRequestExecutivesWizardStep.propTypes = {
  source: SourcePresenter.shape().isRequired,
  updateSource: PropTypes.func.isRequired,
};

export default ProjectRequestExecutivesWizardStep;
