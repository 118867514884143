import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileBoardsRepository from 'repositories/admin/Profile/BoardsRepository';
import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';

import { actions } from 'slices/admin/profileSlice';

function createBoard(profileId, params) {
  return (dispatch) => {
    dispatch(actions.createBoardStart());

    return ProfileBoardsRepository.create(profileId, params)
      .then(({ board, meta }) => {
        dispatch(
          actions.createBoardSuccess({
            board,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta.boardsSortOrder,
          }),
        );

        addNotification('Other Board and Organizations was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return board;
      })
      .catch((error) => {
        dispatch(actions.createBoardError());

        throw error;
      });
  };
}

function updateBoard(profileId, boardId, params) {
  return (dispatch) => {
    dispatch(actions.updateBoardStart());

    return ProfileBoardsRepository.update(profileId, boardId, params)
      .then(({ board, meta }) => {
        dispatch(
          actions.updateBoardSuccess({
            board,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta.boardsSortOrder,
          }),
        );

        addNotification('Other Board and Organizations was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return board;
      })
      .catch((error) => {
        dispatch(actions.updateBoardError());

        throw error;
      });
  };
}

function destroyBoard(profileId, boardId) {
  return (dispatch) =>
    ProfileBoardsRepository.destroy(profileId, boardId).then(({ meta }) => {
      dispatch(actions.destroyBoardSuccess({ boardId, profileUpdatedAt: meta.profileUpdatedAt }));

      addNotification('Other Board and Organizations was successfully updated', 'normal')(dispatch);
      dispatch(initializeRequestForm(meta.request));
    });
}

export const useAdminProfileDraftBoardActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      createBoard,
      updateBoard,
      destroyBoard,
    },
    dispatch,
  );
};
