import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RequestsPage from './RequestsPage';
import * as actions from './RequestsPageActions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestsPage);
