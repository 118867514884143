import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import { timeInterval } from 'utils/DateTime';

export default new Presenter(
  {
    title: PropTypes.string,
    description: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    toPresentTime: PropTypes.bool,
    jobFunctions: PropTypes.arrayOf(PropTypes.string),
  },
  {
    timeInterval(context) {
      const from = this.from(context);
      const to = this.toPresentTime(context) ? 'Present' : this.to(context);

      return timeInterval(from, to);
    },

    jobFunctionsStr(context) {
      return this.jobFunctions(context).join(', ');
    },
  },
);
