import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'grommet';

import styles from './Chart.module.css';

import TagPresenter from 'presenters/Project/BPA/TagPresenter';

const MIN_TAG_WIDTH_PERCENT = 8;

const COLOR_STYLES = [
  { backgroundColor: '#00A0CE', color: '#fff' },
  { backgroundColor: '#FA652C', color: '#fff' },
  { backgroundColor: '#00CE34', color: '#fff' },
  { backgroundColor: '#F8E71C', color: '#444' },
  { backgroundColor: '#DEE0E3', color: '#444' },
];

const FALLBACK_COLOR_STYLES = COLOR_STYLES[COLOR_STYLES.length - 1];

export default class Chart extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(TagPresenter.shape()).isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleClick = (tag) => () => {
    const { onClick } = this.props;

    onClick(tag);
  };

  tagWidth = (tag) => {
    const { tags } = this.props;
    const total = tags.reduce((acc, t) => acc + TagPresenter.profilesCount(t), 0);
    const tagPercent = (TagPresenter.profilesCount(tag) * 100) / total;
    const reduceCoefficient = 1 - (MIN_TAG_WIDTH_PERCENT * tags.length) / 100;

    return tagPercent * reduceCoefficient + MIN_TAG_WIDTH_PERCENT;
  };

  renderItem = (tag, { backgroundColor, color }) => {
    const tagWidth = this.tagWidth(tag);

    return (
      <div
        key={TagPresenter.title(tag)}
        className={styles.item}
        style={{ backgroundColor, color, width: `${tagWidth}%` }}
      >
        <div className={styles.text} title={TagPresenter.title(tag)}>
          <Anchor
            onClick={this.handleClick(tag)}
            label={`${TagPresenter.title(tag)} (${TagPresenter.profilesCount(tag)})`}
          />
        </div>
      </div>
    );
  };

  render() {
    const { tags } = this.props;

    return (
      <div className={styles.chart}>
        {tags.map((tag, index) => this.renderItem(tag, COLOR_STYLES[index] || FALLBACK_COLOR_STYLES))}
      </div>
    );
  }
}
