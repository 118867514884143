import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Provide source links for everything contained within the profile.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <p>
        <a href="https://seekingalpha.com/article/4215033-charter-communications-inc-chtr-ceo-thomas-rutledge-q3-2018-results-earnings-call-transcript?part=single">
          https://seekingalpha.com/article/4215033-charter-communications-inc-chtr-ceo-thomas-rutledge-q3-2018-results-earnings-call-transcript?part=single
        </a>
      </p>
      <p>
        <a href="https://www.recode.net/2018/10/26/18030990/hillary-clinton-facebook-recode-decode-kara-swisher-podcast-interview">
          https://www.recode.net/2018/10/26/18030990/hillary-clinton-facebook-recode-decode-kara-swisher-podcast-interview
        </a>
      </p>
      <p>
        <strong>Style Tips:</strong>
      </p>
      <ul>
        <li>This is pretty straightforward. Just include the link of every source you used to create the profile.</li>
      </ul>
    </div>
  );
}
