const CLASS_NAME = 'clip-path';

export function clipPathId({ id }) {
  return `clip-${id}`;
}

function mount(nodeGroup, props) {
  const clipPath = nodeGroup.append('clipPath').attr('class', CLASS_NAME).attr('id', clipPathId);

  clipPath.append('circle').attr('r', props.node.avatarRadius);

  return clipPath;
}

export default { mount };
