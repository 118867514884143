import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1AdminRequestsPath(params);
    return FetchHelpers.get(url);
  },

  show(requestId) {
    const url = Routes.apiV1AdminRequestPath(requestId);
    return FetchHelpers.get(url);
  },

  update(requestId, request) {
    const url = Routes.apiV1AdminRequestPath(requestId);
    return FetchHelpers.patch(url, { request });
  },

  assignEditor(requestId, request) {
    const url = Routes.assignEditorApiV1AdminRequestPath(requestId);
    return FetchHelpers.put(url, { request });
  },

  bulkDestroy(params) {
    const url = Routes.bulkDestroyApiV1AdminRequestsPath(params);
    return FetchHelpers.delete(url);
  },

  bulkAssign(params) {
    const url = Routes.bulkAssignApiV1AdminRequestsPath();
    return FetchHelpers.put(url, params);
  },
  bulkUpdateStatus(params) {
    const url = Routes.bulkUpdateStatusApiV1AdminRequestsPath();
    return FetchHelpers.put(url, params);
  },
};
