const FULL_NAME_LABEL_CLASS_NAME = 'fulLNameLabel';
const ORGANIZATION_NAME_LABEL_CLASS_NAME = 'OrganizationLNameLabel';
const FULL_NAME_FONT_SIZE = 14;
const ORGANIZATION_NAME_FONT_SIZE = 12;
const LABEL_MARGIN = 2;

export const HEIGHT = FULL_NAME_FONT_SIZE + ORGANIZATION_NAME_FONT_SIZE + LABEL_MARGIN;

function organizationNames({ currentWorks }) {
  return currentWorks.map(({ organizationName }) => organizationName).join(', ');
}

function mount(nodeGroup, props) {
  const label = nodeGroup
    .append('text')
    .attr('class', FULL_NAME_LABEL_CLASS_NAME)
    .attr('font-size', `${FULL_NAME_FONT_SIZE}`)
    .attr('font-weight', 600)
    .attr('x', 0)
    .attr('dy', props.node.labelDy)
    .attr('fill', 'black')
    .text((d) => d.fullName)
    .style('text-anchor', 'middle')
    .style('pointer-events', 'none');

  if (props.showOrganizationName) {
    nodeGroup
      .append('text')
      .attr('class', ORGANIZATION_NAME_LABEL_CLASS_NAME)
      .attr('font-size', `${ORGANIZATION_NAME_FONT_SIZE}`)
      .attr('x', 0)
      .attr('dy', props.node.labelDy + FULL_NAME_FONT_SIZE + LABEL_MARGIN)
      .attr('fill', 'black')
      .text(organizationNames)
      .style('text-anchor', 'middle')
      .style('pointer-events', 'none');
  }

  return label;
}

export default { mount };
