import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import Icon from 'components/Icon/Icon';
import Tile from './components/Tile';

import ProjectPresenter, { RECOMMENDED_PROFILES_COUNT } from 'presenters/ProjectPresenter';
import SourcePresenter, {
  CSV_SOURCE_TYPE,
  TARGETING_SOURCE_TYPE,
  EXECUTIVES_LIST_KIND,
  DATABASE_SEARCH_KIND,
  ORGANIZATIONS_LIST_KIND,
} from 'presenters/Project/SourcePresenter';
import { INCLUSION_KIND } from 'presenters/Project/TargetItemPresenter';

import SourceForm from 'forms/project/SourceForm';
import TargetItemForm from 'forms/project/TargetItemForm';

import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import styles from './ProjectSourceTypeSelectionWizardStep.module.css';

const DATABASE_SEARCH_DEFAULT_ATTRIBUTES = {
  type: TARGETING_SOURCE_TYPE,
  kind: DATABASE_SEARCH_KIND,
  targetItemsAttributes: [TargetItemForm.defaultAttributes({ kind: INCLUSION_KIND })],
};
const EXECUTIVES_LIST_DEFAULT_ATTRIBUTES = {
  type: CSV_SOURCE_TYPE,
  kind: EXECUTIVES_LIST_KIND,
  targetItemsAttributes: [],
};
const ORGANIZATION_LIST_DEFAULT_ATTRIBUTES = {
  type: TARGETING_SOURCE_TYPE,
  kind: ORGANIZATIONS_LIST_KIND,
  targetItemsAttributes: [],
};

export default class ProjectSourceTypeSelectionWizardStep extends Component {
  static propTypes = {
    project: ProjectPresenter.shape(),
    source: SourcePresenter.shape().isRequired,
    updateSource: PropTypes.func.isRequired,
  };

  handleTypeChange = (attributes) => () => {
    const { updateSource, project } = this.props;
    const recommendedProfilesCount = isPresent(project)
      ? ProjectPresenter.recommendedProfilesCount(project)
      : RECOMMENDED_PROFILES_COUNT;
    const source = SourceForm.defaultAttributes({ ...attributes, recommendedProfilesCount });

    updateSource(source);
  };

  renderTile(name, isChecked, onChange) {
    return (
      <label htmlFor={name} className={styles.item}>
        <input type="radio" id={name} className={styles.input} onChange={onChange} checked={isChecked} />
        <div className={styles.content}>
          <div className={styles.icon}>
            <Icon name="target" />
          </div>
          <div className={styles.title}>Database Search</div>
          <div className={styles.description}>
            Create your list from <br /> our database.
          </div>
        </div>
      </label>
    );
  }

  renderComponent() {
    const { source } = this.props;

    return (
      <div className={styles.root}>
        <Tile
          name="targeting"
          icon={<Icon name="target" />}
          isChecked={SourcePresenter.isDatabaseSearchKind(source)}
          onChange={this.handleTypeChange(DATABASE_SEARCH_DEFAULT_ATTRIBUTES)}
          title="Database Search"
        >
          Create your list from <br /> our database.
        </Tile>

        <Tile
          name="executives-list"
          icon={<Icon name="profiles" />}
          isChecked={SourcePresenter.isExecutivesListKind(source)}
          onChange={this.handleTypeChange(EXECUTIVES_LIST_DEFAULT_ATTRIBUTES)}
          title="Search By Executives"
        >
          Upload your list of <br /> target executives.
        </Tile>

        <Tile
          name="organizations-list"
          icon={<Icon name="company" />}
          isChecked={SourcePresenter.isOrganizationsListKind(source)}
          onChange={this.handleTypeChange(ORGANIZATION_LIST_DEFAULT_ATTRIBUTES)}
          title="Search By Companies"
        >
          Upload your list of <br /> target companies.
        </Tile>
      </div>
    );
  }

  render() {
    return (
      <>
        <WizardTitle subtitle="Build your project." />
        {this.renderComponent()}
        <WizardNavigation />
      </>
    );
  }
}
