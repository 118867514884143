import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import TargetingSearchRepository from 'repositories/CurrentUser/TargetingSearchRepository';

const restSlice = createRestSlice({
  resource: 'targetingSearchDirectorBoard',
  repository: TargetingSearchRepository,
  slices: ['loadTargetingSearchDirectorBoards'],
});

const initialState = {
  ...restSlice.initialState,
};

const directorBoardsSlice = createSlice({
  name: 'clientTargetingSearchDirectorBoards',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientTargetingSearchDirectorBoardsReducer = directorBoardsSlice.reducer;

export default directorBoardsSlice;

export const clientTargetingSearchDirectorBoardsRestHooks = restSlice.hooks(
  directorBoardsSlice,
  'clientTargetingSearchDirectorBoardsReducer',
);
