import React from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import { FILTERS } from 'presenters/RelationshipsMappingPresenter';

import { Checkbox } from 'components/v2/Form';
import RelationshipsMappingLegend from 'containers/UserPanel/components/RelationshipsMappingLegend';

import styles from './SidebarFilters.module.css';

const SidebarFilters = (props) => {
  const { filters, onFiltersChange, children, disabled, isShowFilters } = props;

  return (
    <>
      <RelationshipsMappingLegend />

      {isShowFilters && (
        <>
          <div className={styles.separator} />

          <div className={styles.actionBlock}>
            <div>My Project</div>
            <div className={styles.field}>
              <Checkbox
                checked={filters[FILTERS.MUST_BE_EXECUTIVE]}
                onChange={onFiltersChange(FILTERS.MUST_BE_EXECUTIVE)}
                disabled={disabled}
              />
              <span className={styles.fieldTitle}>Executives</span>
            </div>

            <div className={styles.field}>
              <Checkbox
                checked={filters[FILTERS.MUST_BE_BOARD_OF_DIRECTORS]}
                onChange={onFiltersChange(FILTERS.MUST_BE_BOARD_OF_DIRECTORS)}
                disabled={disabled}
              />
              <span className={styles.fieldTitle}>Board of Directors</span>
              <div className={styles.badge}>F500</div>
            </div>

            <div className={styles.field}>
              <Checkbox
                checked={filters[FILTERS.MUST_BE_BOARD_OF_DIRECTORS_FTSE100]}
                onChange={onFiltersChange(FILTERS.MUST_BE_BOARD_OF_DIRECTORS_FTSE100)}
                disabled={disabled}
              />
              <span className={styles.fieldTitle}>Board of Directors</span>
              <div className={styles.badge}>FTSE 100</div>
            </div>
          </div>
        </>
      )}

      {isPresent(children) && <div className={styles.separator} />}
      {children}
    </>
  );
};

SidebarFilters.propTypes = {
  filters: PropTypes.shape(),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onFiltersChange: PropTypes.func,
  isShowFilters: PropTypes.bool,
};

SidebarFilters.defaultProps = {
  disabled: false,
};

export default SidebarFilters;
