import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { Link as ReactRouterLink } from 'react-router-dom';
import { propOr, isNil } from 'ramda';

import styles from './Link.module.css';

const VARIANTS = {
  default: styles.default,
  underscored: styles.underscored,
  noneDecoration: styles.noneDecoration,
};

const COLORS = {
  default: styles.defaultColor,
  primary: styles.primaryColor,
};

const Link = (props) => {
  const { className, color, href, variant, ...rest } = props;
  const variantClassName = propOr(VARIANTS.default, variant)(VARIANTS);
  const colorClassName = propOr(COLORS.default, color)(COLORS);
  const linkClassName = clsx(variantClassName, colorClassName, className);

  if (!isNil(href)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...rest} className={linkClassName} href={href} />;
  }

  return <ReactRouterLink {...rest} className={linkClassName} />;
};

Link.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string,
  path: PropTypes.oneOfType([PropTypes.shape({ pathname: PropTypes.string }), PropTypes.string]),
  variant: PropTypes.string,
};

Link.defaultProps = {
  className: null,
  color: null,
  href: null,
  path: null,
  variant: 'default',
};

export default Link;
