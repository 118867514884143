import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useClickOutside } from 'use-events';

import VerticalDotsIcon from 'icons/VerticalDotsIcon';

import Button from 'components/v2/Button';

import styles from './ActionsMenu.module.css';

const ActionsMenu = (props) => {
  const { actionComponent, children, className } = props;
  const actionsMenuClassName = clsx(styles.actionsMenu, className);

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleActionsMenuOpen = () => setIsOpen(true);
  const handleActionsMenuClose = () => setIsOpen(false);
  const handlePropagationStop = (event) => event.stopPropagation();

  useClickOutside([ref], handleActionsMenuClose);

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
    <div className={styles.actionsMenuContainer} onClick={handlePropagationStop}>
      <Button onClick={handleActionsMenuOpen} variant="icon">
        {actionComponent}
      </Button>
      {isOpen && (
        <div ref={ref} className={actionsMenuClassName}>
          {children}
        </div>
      )}
    </div>
  );
};

ActionsMenu.propTypes = {
  actionComponent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};

ActionsMenu.defaultProps = {
  actionComponent: <VerticalDotsIcon width={16} height={16} />,
  className: null,
};

export default ActionsMenu;
