import ProfilePresenter from 'presenters/ProfilePresenter';

export default {
  BriefBiography: {
    id: 'briefBiography',
    name: 'Executive Summary',
    isRecentlyUpdated: ProfilePresenter.isBriefBiographyRecentlyUpdated,
  },
  PublicPersonInformation: {
    id: 'publicPersonInformation',
    name: 'Personal Attributes and Interests',
    isRecentlyUpdated: ProfilePresenter.isPublicPersonInformationRecentlyUpdated,
  },
  CurrentFocusTopics: {
    id: 'current_focus_topics',
    name: 'Current Focus',
    isRecentlyUpdated: ProfilePresenter.isCurrentFocusTopicsRecentlyUpdated,
  },
  KeyChallengesTopics: {
    id: 'key_challenges_topics',
    name: 'Key Challenges',
    isRecentlyUpdated: ProfilePresenter.isKeyChallengesTopicsRecentlyUpdated,
  },
  Works: { id: 'works', name: 'Employment History', isRecentlyUpdated: ProfilePresenter.isWorksRecentlyUpdated },
  Boards: {
    id: 'boards',
    name: 'Other Boards and Organizations',
    isRecentlyUpdated: ProfilePresenter.isBoardsRecentlyUpdated,
  },
  Institutions: {
    id: 'institutions',
    name: 'Education History',
    isRecentlyUpdated: ProfilePresenter.isInstitutionsRecentlyUpdated,
  },
};
