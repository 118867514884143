import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Spinner as Spinning } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import InfiniteScroll from 'components/InfiniteScroll';
import SavedSearch from 'components/SavedSearch';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import styles from './SavedSearchesList.module.css';

class SavedSearchesList extends Component {
  isAllSavedSearchesLoaded = () => {
    const { nextPage } = this.props;
    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { isLoading, nextPage, loadNext } = this.props;

    if (!isLoading && !this.isAllSavedSearchesLoaded()) {
      loadNext(nextPage);
    }
  };

  handleCreateSubscription = (savedSearch) => {
    const { onCreateSubscription, onChangeSavedSearch } = this.props;
    const params = {
      kind: 'email_alert',
      savedSearchId: SavedSearchPresenter.id(savedSearch),
    };

    return onCreateSubscription(params).then(({ id }) => {
      onChangeSavedSearch({ ...savedSearch, subscriptionId: id });
    });
  };

  handleDestroySubscription = (savedSearch) => {
    const { onChangeSavedSearch, onDestroySubscription } = this.props;
    const subscriptionId = SavedSearchPresenter.subscriptionId(savedSearch);

    return onDestroySubscription(subscriptionId).then(() => {
      onChangeSavedSearch({ ...savedSearch, subscriptionId: null });
    });
  };

  renderLoader() {
    return (
      <div key="loader" className={styles.loaderBox}>
        <Spinning />
      </div>
    );
  }

  render() {
    const {
      currentUser,
      isLoading,
      onDeleteSavedSearch,
      isDeleting,
      isProjectSaving,
      isUpdating,
      onOpenSavedSearch,
      onCreateProject,
      onUpdateSavedSearch,
      savedSearches,
    } = this.props;

    if (!isLoading && isEmpty(savedSearches)) {
      return <div className={styles.empty}>You have no Saved Searches yet.</div>;
    }

    return (
      <div className={styles.root}>
        <InfiniteScroll
          loadMore={this.handleLoadMore}
          hasMore={!this.isAllSavedSearchesLoaded()}
          loader={this.renderLoader()}
          useWindow={false}
          researchForScrollParent
        >
          {savedSearches.map((savedSearch) => (
            <SavedSearch
              key={SavedSearchPresenter.id(savedSearch)}
              className={styles.savedSearch}
              currentUser={currentUser}
              savedSearch={savedSearch}
              onClick={onOpenSavedSearch}
              onCreateSubscription={this.handleCreateSubscription}
              onDestroySubscription={this.handleDestroySubscription}
              onDeleteSavedSearch={onDeleteSavedSearch}
              onCreateProject={onCreateProject}
              onUpdateSavedSearch={onUpdateSavedSearch}
              isDeleting={isDeleting}
              isUpdating={isUpdating}
              isProjectSaving={isProjectSaving}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}

SavedSearchesList.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  nextPage: PropTypes.number,
  loadNext: PropTypes.func.isRequired,
  savedSearches: PropTypes.arrayOf(SavedSearchPresenter.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChangeSavedSearch: PropTypes.func.isRequired,
  onDeleteSavedSearch: PropTypes.func.isRequired,
  onOpenSavedSearch: PropTypes.func.isRequired,
  onCreateProject: PropTypes.func.isRequired,
  onCreateSubscription: PropTypes.func.isRequired,
  onDestroySubscription: PropTypes.func.isRequired,
  onUpdateSavedSearch: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isProjectSaving: PropTypes.bool.isRequired,
};

export default SavedSearchesList;
