import ResetPasswordConstants from './ResetPasswordConstants';
import ResetPasswordRepository from 'repositories/ResetPasswordRepository';

export function changeForm(form) {
  return { type: ResetPasswordConstants.CHANGE_RESET_PASSWORD_FORM, form };
}

export function sendResetPasswordEmail(params) {
  return (dispatch) => {
    dispatch({ type: ResetPasswordConstants.SEND_RESET_PASSWORD_EMAIL_START });

    return ResetPasswordRepository.create(params)
      .then(() => dispatch({ type: ResetPasswordConstants.SEND_RESET_PASSWORD_EMAIL_SUCCESS }))
      .catch(({ errors }) => dispatch({ type: ResetPasswordConstants.SEND_RESET_PASSWORD_EMAIL_ERROR, errors }));
  };
}

export function initializeResetPasswordForm() {
  return { type: ResetPasswordConstants.INITIALIZE_RESET_PASSWORD_FORM };
}
