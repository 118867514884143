import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import FactPresenter from './Relationship/FactPresenter';

export default new Presenter(
  {
    profiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
    facts: PropTypes.arrayOf(FactPresenter.shape()),
    strength: PropTypes.number,
  },
  {
    id(relationship) {
      return this.profiles(relationship)
        .map((profile) => ProfileSearchPresenter.id(profile))
        .join('-');
    },
  },
);
