import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { __, all, includes, propEq, reject, uniq } from 'ramda';

import { logUncheckProjectPageShareFiles } from 'utils/amplitude';

import Button from 'components/Button';
import Checkbox from 'containers/UserPanel/components/Checkbox';
import MultiplyCreatableSelect from 'components/MultiplyCreatableSelect';
import SelectControl from 'containers/UserPanel/containers/Project/containers/ProjectPage/containers/Deliverables/containers/ShareLink/containers/ShareModal/components/SelectControl';
import { Content, Actions } from 'containers/UserPanel/components/Modal';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import Form, { EXPORTABLES, buildRecipientEmail } from 'forms/project/MasterExportForm';

import styles from './MasterExportForm.module.css';

const EXPORT_ITEMS = [
  {
    name: EXPORTABLES.AUDIENCE_INSIGHTS,
    header: 'Audience Insights',
    isAvailable: ProjectPresenter.isAudienceInsightsAvailable,
    subOptions: [],
  },
  {
    name: 'bpa',
    header: 'Business Priority Analysis',
    isAvailable: ProjectPresenter.isBusinessPriorityAnalysisAvailable,
    subOptions: [
      {
        name: EXPORTABLES.BPA_BY_INITIATIVES,
        header: 'By Initiatives',
        subOptions: [],
        isAvailable: ProjectPresenter.isBusinessPriorityAnalysisAvailable,
      },
      {
        name: EXPORTABLES.BPA_BY_ROLE,
        header: 'By Role',
        subOptions: [],
        isAvailable: ProjectPresenter.isBusinessPriorityAnalysisAvailable,
      },
      {
        name: EXPORTABLES.BPA_BY_JOB_FUNCTIONS,
        header: 'By Job Functions',
        subOptions: [],
        isAvailable: ProjectPresenter.isBusinessPriorityAnalysisAvailable,
      },
    ],
  },
  {
    name: 'rm',
    header: 'Relationship Mapping',
    isAvailable: ProjectPresenter.isRelationshipsMappingAvailable,
    subOptions: [
      {
        name: EXPORTABLES.RM_TOP_50_RELATIONSHIPS,
        header: 'Top 50 Relationships',
        subOptions: [],
        isAvailable: ProjectPresenter.isRelationshipsMappingAvailable,
      },
      {
        name: EXPORTABLES.RM_CONNECTIONS_TO_MY_EXECUTIVES,
        header: 'Connections to My Executives',
        subOptions: [],
        isAvailable: ProjectPresenter.isRelationshipsMappingAvailable,
      },
    ],
  },
  {
    name: 'words_cloud',
    header: 'Common Threads',
    isAvailable: ProjectPresenter.isWordsCloudAvailable,
    subOptions: [
      {
        name: EXPORTABLES.WORDS_CLOUD_COMPANIES,
        header: 'Companies',
        subOptions: [],
        isAvailable: ProjectPresenter.isWordsCloudAvailable,
      },
      {
        name: EXPORTABLES.WORDS_CLOUD_BOARDS,
        header: 'Boards',
        subOptions: [],
        isAvailable: ProjectPresenter.isWordsCloudAvailable,
      },
      {
        name: EXPORTABLES.WORDS_CLOUD_EDUCATION,
        header: 'Education',
        subOptions: [],
        isAvailable: ProjectPresenter.isWordsCloudAvailable,
      },
      {
        name: EXPORTABLES.WORDS_CLOUD_INTERESTS,
        header: 'Interests',
        subOptions: [],
        isAvailable: ProjectPresenter.isWordsCloudAvailable,
      },
      {
        name: EXPORTABLES.WORDS_CLOUD_INCLUDE_EXECUTIVES,
        header: 'Include Common Threads with My Executives',
        subOptions: [],
        isAvailable: ProjectPresenter.isWordsCloudAvailable,
      },
    ],
  },
];

export default class MasterExportForm extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  constructor(props) {
    super(props);

    const { currentUser } = this.props;
    const defaultRecipientEmail = buildRecipientEmail(CurrentUserPresenter.email(currentUser));
    const exportItems = EXPORT_ITEMS.reduce((acc, item) => [...acc, ...this.availableExportItemNames(item)], []);
    const form = Form.defaultAttributes({
      recipientEmails: [defaultRecipientEmail],
      params: { exportItems },
    });
    this.state = { form, errors: null };
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(Form.attributesToSubmit(form));
  };

  handleToggleExportKind =
    (exportItem) =>
    ({ target: { checked } }) => {
      const { form } = this.state;
      const { params } = form;
      const changedItems = this.availableExportItemNames(exportItem);
      const exportItems = checked
        ? uniq([...params.exportItems, ...changedItems])
        : reject(includes(__, changedItems), params.exportItems);

      if (!checked) {
        logUncheckProjectPageShareFiles(exportItem.header);
      }

      this.setState({ ...this.state, form: { ...form, params: { ...params, exportItems } } });
    };

  handleChangeSelect = (fieldName) => (value) => {
    const { form } = this.state;

    this.setState({ ...this.state, form: { ...form, [fieldName]: value } });
  };

  handleAddNewRecipientEmail = (value) => {
    const { form } = this.state;

    if (form.recipientEmails.some(propEq(value, 'value'))) {
      return false;
    }

    this.handleChangeSelect('recipientEmails')([...form.recipientEmails, buildRecipientEmail(value)]);

    return true;
  };

  availableExportItemNames = (exportItem) => {
    const { project } = this.props;

    if (!exportItem.isAvailable(project)) {
      return [];
    }

    if (exportItem.subOptions.length === 0) {
      return [exportItem.name];
    }

    return exportItem.subOptions.reduce((acc, item) => [...acc, ...this.availableExportItemNames(item)], []);
  };

  isChecked = (exportItem) => {
    const { form } = this.state;
    const { params } = form;
    const { exportItems } = params;

    if (exportItem.subOptions.length === 0) {
      return includes(exportItem.name, exportItems);
    }

    return all(this.isChecked, exportItem.subOptions);
  };

  isIndeterminate = (exportItem) => {
    if (exportItem.subOptions.length === 0) {
      return false;
    }

    const checkedSubOptions = exportItem.subOptions.filter(this.isChecked);

    return checkedSubOptions.length > 0 && checkedSubOptions.length < exportItem.subOptions.length;
  };

  isAnyExportItemChecked = () => {
    const { form } = this.state;
    const { params } = form;

    return params.exportItems.length !== 0;
  };

  renderExportItem = (exportItem) => {
    const { project } = this.props;
    const { name, header, subOptions } = exportItem;
    const isDisabled = !exportItem.isAvailable(project);

    return (
      <li key={name}>
        <Checkbox
          checked={this.isChecked(exportItem)}
          indeterminate={this.isIndeterminate(exportItem)}
          label={header}
          onChange={this.handleToggleExportKind(exportItem)}
          disabled={isDisabled}
        />
        {subOptions.length > 0 && this.renderExportItems(subOptions)}
      </li>
    );
  };

  renderExportItems = (exportItems) => <ul>{exportItems.map(this.renderExportItem)}</ul>;

  render() {
    const { form, errors } = this.state;

    return (
      <>
        <Content>
          <div className={styles.selectBox}>
            <div className={styles.subTitle}>Share these files with:</div>
            <MultiplyCreatableSelect
              components={{ Control: SelectControl }}
              errors={errors && errors.recipientEmails}
              inlinePlaceholder
              placeholder="Add people"
              onChange={this.handleChangeSelect('recipientEmails')}
              onCreateNewValue={this.handleAddNewRecipientEmail}
              value={form.recipientEmails}
            />
          </div>
          <div className={styles.subTitle}>Select the files you would like to share:</div>
          <div className={styles.checkBoxes}>{this.renderExportItems(EXPORT_ITEMS)}</div>
        </Content>

        <Actions>
          <Button primary onClick={this.handleSubmit} label="Send Now" disabled={!this.isAnyExportItemChecked()} />
        </Actions>
      </>
    );
  }
}
