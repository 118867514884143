import React, { useEffect } from 'react';
import { parseTargetItemCriterias } from 'utils/Project/TargetItemHelper';

import Spinner from 'components/Spinner';

import { humanFilterName, humanFilterOptionName } from 'forms/ProfileFilteredSearchForm';

import LimitedAccessPresenter from 'presenters/Company/Department/LimitedAccessPresenter';
import useLimitedAccess from 'hooks/client/useLimitedAccess';

import styles from './LimitedAccessCriterias.module.css';

const LimitedAccessCriterias = () => {
  const { loadLimitedAccess, limitedAccess, isLoading } = useLimitedAccess();
  const criterias = parseTargetItemCriterias(LimitedAccessPresenter.criterias(limitedAccess));

  useEffect(() => {
    loadLimitedAccess();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className={styles.title}>LIMITED ACCESS</div>
      <div className={styles.container}>
        {Object.keys(criterias).map((type) => (
          <div className={styles.criteria} key={type}>
            <div className={styles.criteriaType}>{humanFilterName(type)}</div>
            <div className={styles.criteriaValue}>
              {criterias[type].map((value) => humanFilterOptionName(type, value)).join(', ')}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LimitedAccessCriterias;
