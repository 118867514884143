import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isBlank, isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './Guide.module.css';

const TOP_POSITION = 'top';
const LEFT_POSITION = 'left';
const RIGHT_POSITION = 'right';
const BOTTOM_POSITION = 'bottom';
const BOTTOM_RIGHT_POSITION = 'bottom-right';

class Guide extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    selector: PropTypes.string.isRequired,
    position: PropTypes.oneOf([TOP_POSITION, LEFT_POSITION, RIGHT_POSITION, BOTTOM_POSITION, BOTTOM_RIGHT_POSITION])
      .isRequired,
    children: PropTypes.node,
  };

  state = { showHighlight: true };

  componentDidMount() {
    const highlight = this.highlightedItem();

    if (isPresent(highlight)) {
      this.handleScrollPageToHighlightedElement(highlight);
      this.handleShowHighlight();
    }
  }

  getClientDomNode() {
    return document.querySelector('.client');
  }

  handleShowHighlight() {
    this.setState({ showHighlight: true });
  }

  handleScrollPageToHighlightedElement(highlight) {
    const client = this.getClientDomNode();
    const maxYPos = highlight.y + highlight.height;
    const bottomMargin = 30;

    if (maxYPos + bottomMargin >= window.innerHeight || maxYPos < 0) {
      client.scrollTop += maxYPos + bottomMargin - window.innerHeight;
    }
  }

  highlightedItem = () => {
    const { selector } = this.props;
    const node = document.querySelector(selector);

    if (isBlank(node)) {
      return null;
    }

    const { left: x, top: y, width, height } = node.getBoundingClientRect();

    return {
      x,
      width,
      height,
      y,
    };
  };

  render() {
    const { children, onClose, position } = this.props;
    const { showHighlight } = this.state;
    const highlight = this.highlightedItem();
    const guideClassName = cn(styles.guide, [styles[position]]);

    if (!showHighlight || isBlank(highlight)) {
      return null;
    }

    const highlightPosition = {
      top: highlight.y,
      left: highlight.x,
      height: highlight.height,
      width: highlight.width,
    };

    return (
      <div className={styles.overlay}>
        <div key={highlight.id} className={styles.highlight} style={highlightPosition}>
          <div className={guideClassName}>
            <div>
              <div className={styles.description}>{children}</div>
              <Button className={styles.button} onClick={onClose}>
                Got It
              </Button>
            </div>
            <div className={styles.arrows}>
              <Icon name="guideArrow" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Guide;
