import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { decamelize } from 'utils/keysConverter';
import WordsCloudConstants from './WordsCloudConstants';
import WordsRepository from 'repositories/CurrentUser/Project/WordsRepository';

export function loadWords(projectId, filters) {
  return (dispatch) => {
    dispatch({ type: WordsCloudConstants.LOAD_PROJECT_WORDS_START, filters });

    const { search, ...restFilters } = filters;
    const params = decamelize({ ...restFilters, ...buildFilterParams(search) });

    return WordsRepository.index(projectId, params).then(({ items }) => {
      dispatch({
        type: WordsCloudConstants.LOAD_PROJECT_WORDS_SUCCESS,
        filters,
        words: items,
      });

      return items;
    });
  };
}
