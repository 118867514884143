import { pick, defaultTo } from 'ramda';

import LimitedAccessPresenter from 'presenters/Company/Department/LimitedAccessPresenter';

const attributesForCriterion = ({ type, value }) => ({ type, value: value || [] });

export default {
  defaultAttributes(limitedAccess) {
    return {
      ...limitedAccess,
      criterias: defaultTo([], LimitedAccessPresenter.criterias(limitedAccess)),
    };
  },

  attributesToSubmit(attributes) {
    const propsToSend = ['id', 'criterias', 'stateEvent'];

    return pick(propsToSend, {
      ...attributesForCriterion,
      criterias: attributes.criterias.map(this.attributesForCriterion),
    });
  },
};
