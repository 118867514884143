import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import ProjectsRepository from 'repositories/CurrentUser/Company/Dashboard/ProjectsRepository';

const restSlice = createRestSlice({
  resource: 'project',
  repository: ProjectsRepository,
  slices: ['loadProjects', 'loadMoreProjects'],
});

const initialState = {
  ...restSlice.initialState,
};

const projectsSlice = createSlice({
  name: 'currentUserCompanyDashboardProjects',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const currentUserCompanyDashboardProjectsReducer = projectsSlice.reducer;

export default projectsSlice;

export const currentUserCompanyDashboardProjectsRestHooks = restSlice.hooks(
  projectsSlice,
  'currentUserCompanyDashboardProjectsReducer',
);
