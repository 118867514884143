import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, debounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Table from 'components/Table';
import CountryRowCells from './components/CountryRowCells';
import Search from 'components/SearchInput';
import NewCountryModal from './components/NewCountryModal';

import CountryPolicy from 'policies/CountryPolicy';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import CountryPresenter from 'presenters/CountryPresenter';

import Routes from 'routes';

const MAX_SEARCH_LENGTH = 40;

export default class CountriesListPage extends Component {
  static propTypes = {
    bulkDestroyCountries: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape(),
    createCountry: PropTypes.func.isRequired,
    countries: PropTypes.arrayOf(CountryPresenter.shape()).isRequired,
    loading: PropTypes.bool.isRequired,
    loadCountries: PropTypes.func.isRequired,
    filters: PropTypes.shape().isRequired,
    filterCountries: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    totalCount: PropTypes.number,
  };

  state = { isAddPopupOpened: false };

  componentDidMount() {
    this.props.filterCountries({ nameCont: '', sortFields: [] });
  }

  tableColumns = [
    {
      name: 'Id',
      field: 'id',
    },
    {
      name: 'Name',
      field: 'name',
    },
    {
      name: 'World Region',
      field: 'worldRegion',
    },
    {
      name: 'ISO 3166-1 (alpha2)',
      field: 'alpha2',
    },
    {
      name: 'GDPR',
      field: 'isGdpr',
    },
  ];

  isAllCountriesLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { nextPage, loadCountries, loading, filters } = this.props;

    if (!loading && !this.isAllCountriesLoaded()) {
      loadCountries({ page: nextPage, filters });
    }
  };

  handleSort = (sortFields) => {
    const { filters, filterCountries } = this.props;

    filterCountries({ ...filters, sortFields });
  };

  handleFilter = (fieldName, value) => {
    const { filters, filterCountries } = this.props;
    const newFilters = { ...filters, [fieldName]: value };

    filterCountries(newFilters);
  };

  handleFilterDebounced = debounce(this.handleFilter);

  handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFilterDebounced(fieldName, value);

  handleCreateNewCountry = () => this.setState({ ...this.state, isAddPopupOpened: true });

  handleCloseAddNewCountryPopup = () => this.setState({ ...this.state, isAddPopupOpened: false });

  handleCountryCreate = (country) => {
    const { createCountry, filters, filterCountries } = this.props;

    return createCountry(country).then(() => {
      this.handleCloseAddNewCountryPopup();
      filterCountries(filters);
    });
  };

  handleDelete = (ids) => {
    const { filters, filterCountries, bulkDestroyCountries } = this.props;

    bulkDestroyCountries({ ids }).then(() => filterCountries(filters));
  };

  handleCountryClick = (countryId) => {
    const { currentUser } = this.props;

    if (CountryPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminCountryPath(countryId));
    }
  };

  render() {
    const { currentUser, countries, filters, totalCount } = this.props;
    const { isAddPopupOpened } = this.state;

    return (
      <Box margin="none" full="horizontal">
        <PageHeader title="Countries List" />

        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box width="medium" margin={{ right: 'small' }}>
            <Search placeholder="Search" onChange={this.handleFilterChange('nameCont')} maxLength={MAX_SEARCH_LENGTH} />
          </Box>
          {CountryPolicy.canAddElement(currentUser) && (
            <Box>
              <Button primary label="Add Country" onClick={this.handleCreateNewCountry} />
            </Box>
          )}
        </Box>

        <Table
          columns={this.tableColumns}
          sorts={filters.sortFields}
          rows={countries}
          rowCellsComponent={<CountryRowCells />}
          rowsDeletable={CountryPolicy.canDestroyElement(currentUser)}
          hasMore={!this.isAllCountriesLoaded()}
          onMore={this.handleLoadMore}
          onRowClick={this.handleCountryClick}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
          totalRows={totalCount}
        />

        {isAddPopupOpened && (
          <NewCountryModal onClose={this.handleCloseAddNewCountryPopup} onSave={this.handleCountryCreate} />
        )}
      </Box>
    );
  }
}
