import TopJobFunctionsConstants from './TopJobFunctionsConstants';

const initialState = {
  loading: false,
  jobFunctions: [],
};

function TopJobFunctionsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TopJobFunctionsConstants.LOAD_PROJECT_TOP_JOB_FUNCTIONS_START: {
      return { ...state, loading: true };
    }
    case TopJobFunctionsConstants.LOAD_PROJECT_TOP_JOB_FUNCTIONS_SUCCESS: {
      const { jobFunctions } = action;

      return { ...state, jobFunctions, loading: false };
    }
    default:
      return state;
  }
}

export default TopJobFunctionsReducer;
