import PropTypes from 'prop-types';

import Presenter from '../utils/PropTypesPresenter';

export const STATE_EVENTS = {
  ENABLE: 'enable',
  DISABLE: 'disable',
};

export const STATES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const STATE_OPTIONS = [
  { value: STATES.ENABLED, label: 'Enabled' },
  { value: STATES.DISABLED, label: 'Disabled' },
];

export default new Presenter(
  {
    id: PropTypes.number,
    cert: PropTypes.string,
    siteUrl: PropTypes.string,
    emailAttributeName: PropTypes.string,
    firstNameAttributeName: PropTypes.string,
    lastNameAttributeName: PropTypes.string,
    type: PropTypes.string,
    state: PropTypes.string,
    isAvailable: PropTypes.bool,
    spEntityId: PropTypes.string,
    assertionConsumerServiceUrl: PropTypes.string,
  },
  {
    isEnabled(sso) {
      return this.state(sso) === STATES.ENABLED;
    },
  },
);
