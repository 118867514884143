import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { isBlank, debounce } from 'utils/HelperMethods';
import { logSearchInCustomerRequestHistoryTable } from 'utils/amplitude';

import Table from 'components/Table';
import SearchInput from 'components/SearchInput/SearchInput';

import RequestRowCells from './components/RequestRowCells';

import RequestPresenter from 'presenters/RequestPresenter';

import styles from './RequestHistory.module.css';

const tableColumns = [
  { name: 'Request Date', field: 'createdAt' },
  { name: 'Full Name', field: 'subjectFullName' },
  { name: 'Title', field: 'subjectJobTitle' },
  { name: 'Company', field: 'subjectOrganizationName' },
  { name: 'Request Type', field: 'requestType' },
  { name: 'Status', field: 'state' },
];

const defaultSortFields = [{ name: 'Request Date', field: 'createdAt', value: 'desc' }];

class RequestsHistory extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    requests: PropTypes.arrayOf(RequestPresenter.shape()).isRequired,
    meta: PropTypes.shape(),
    onLoad: PropTypes.func,
    filterRequest: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    title: PropTypes.node,
    searchable: PropTypes.bool,
  };

  static defaultProps = {
    searchable: false,
  };

  componentDidMount() {
    const { filterRequest } = this.props;

    filterRequest({ sortFields: defaultSortFields });
  }

  isAllRequestsLoaded = () => {
    const { nextPage } = this.props.meta;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { onLoad, filters } = this.props;
    const { loading, nextPage } = this.props.meta;

    if (!loading && !this.isAllRequestsLoaded()) {
      onLoad(nextPage, filters);
    }
  };

  handleSort = (sortFields) => {
    const { filters, filterRequest } = this.props;
    filterRequest({ ...filters, sortFields });
  };

  handleSearch = ({ target: { value } }) => {
    const { filters, filterRequest } = this.props;

    this.logSearchInCustomerRequestHistoryTableDebounced();
    filterRequest({ ...filters, search_by_executives_cont: value });
  };

  handleSearchDebounced = debounce(this.handleSearch);

  logSearchInCustomerRequestHistoryTableDebounced = debounce(logSearchInCustomerRequestHistoryTable, 5000);

  render() {
    const { requests, filters, onLoad, title, searchable } = this.props;
    return (
      <>
        <div className={styles.header}>
          {title}
          {searchable && (
            <Box width="medium">
              <SearchInput
                onChange={this.handleSearchDebounced}
                responsive={false}
                placeholder="Search for executives and companies"
              />
            </Box>
          )}
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.content}>
            <Table
              columns={tableColumns}
              sorts={filters.sortFields}
              rows={requests}
              rowCellsComponent={<RequestRowCells />}
              rowsDeletable={false}
              hasMore={onLoad ? !this.isAllRequestsLoaded() : undefined}
              onMore={onLoad ? this.handleLoadMore : undefined}
              onRowClick={() => {}}
              onSort={this.handleSort}
              displayActionsHeader={false}
            />
          </div>
        </div>
      </>
    );
  }
}

export default RequestsHistory;
