import { equals } from 'ramda';
import LanguagesListPageConstants from './LanguagesListPageConstants';

const initialState = {
  languages: [],
  loading: false,
  filters: {
    nameCont: '',
    sortFields: [],
  },
  nextPage: null,
  totalCount: null,
  saving: false,
};

function LanguagesListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LanguagesListPageConstants.LOAD_LANGUAGES_START: {
      return { ...state, loading: true };
    }
    case LanguagesListPageConstants.LOAD_LANGUAGES_SUCCESS: {
      const { languages, nextPage, totalCount } = action;

      return { ...state, languages: [...state.languages, ...languages], nextPage, totalCount, loading: false };
    }
    case LanguagesListPageConstants.FILTER_LANGUAGES_START: {
      const { filters } = action;

      return { ...state, loading: true, filters };
    }
    case LanguagesListPageConstants.FILTER_LANGUAGES_SUCCESS: {
      const { languages, filters, nextPage, totalCount } = action;

      if (equals(filters, state.filters)) {
        return { ...state, loading: false, filters, languages, nextPage, totalCount };
      }

      return state;
    }
    case LanguagesListPageConstants.CREATE_LANGUAGE_START: {
      return { ...state, saving: true };
    }
    case LanguagesListPageConstants.CREATE_LANGUAGE_SUCCESS: {
      return { ...state, saving: false };
    }
    case LanguagesListPageConstants.CREATE_LANGUAGE_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default LanguagesListPageReducer;
