import React from 'react';
import PropTypes from 'prop-types';

import Alias from './components/Alias';
import AliasForm from './components/AliasForm';
import { Box } from 'grommet';
import Card from 'components/Card';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import AliasPresenter from 'presenters/AliasPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

const Aliases = (props) => {
  const { aliases, editable, isChanged, onReviewChanges, saving, onDelete, onCreate, organization } = props;

  const handleAliasDelete = (alias) => {
    const aliasId = AliasPresenter.id(alias);
    const organizationId = OrganizationPresenter.id(organization);

    return onDelete(organizationId, aliasId);
  };

  const handleAliasCreate = (alias) => {
    const organizationId = OrganizationPresenter.id(organization);

    return onCreate(organizationId, alias);
  };

  return (
    <Card>
      <HeadlineWithReviewChangesLink
        strong
        size="small"
        margin="none"
        title="Organization Name Aliases"
        isChanged={isChanged}
        onReviewChanges={onReviewChanges}
      />

      <Box margin={{ bottom: 'small' }}>
        {aliases.map((alias) => (
          <Alias
            key={AliasPresenter.id(alias)}
            alias={alias}
            editable={editable}
            onDelete={handleAliasDelete}
            saving={saving}
          />
        ))}
      </Box>

      <AliasForm editable={editable} onSubmit={handleAliasCreate} saving={saving} />
    </Card>
  );
};

Aliases.propTypes = {
  aliases: PropTypes.arrayOf(AliasPresenter.shape()).isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onReviewChanges: PropTypes.func.isRequired,
  organization: OrganizationPresenter.shape().isRequired,
  saving: PropTypes.bool.isRequired,
};

export default Aliases;
