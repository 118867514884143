import { pick } from 'ramda';

export default {
  defaultAttributes(request = {}) {
    return {
      subjectAttributes: {
        firstName: '',
        lastName: '',
        organizationName: '',
        jobTitle: '',
      },
      recipientEmails: [],
      source: null,
      ...request,
    };
  },

  attributesToSubmit(request) {
    const propsToSend = ['subjectAttributes', 'recipientEmails', 'source'];

    return pick(propsToSend, request);
  },
};
