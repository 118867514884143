import React, { useEffect, useState } from 'react';
import { assocPath, pathOr } from 'ramda';
import { buildRansackSortings, parseRansackSortings } from 'utils/ransackUtils';
import { useHistory } from 'react-router-polyfill';
import { useDebounce } from 'utils/debounceUtils';

import { useAlert } from 'hooks';
import { useAdminCompanies, useAdminCompanyDepartments, useAdminCompanyDepartmentsStatistics } from 'hooks/admin';

import Routes from 'routes';

import Button from 'components/v2/Button';
import SearchInput from 'components/v2/SearchInput';
import Typography from 'components/v2/Typography';

import NewDepartmentFormModal from './components/NewDepartmentFormModal';
import DepartmentsTable from './components/DepartmentsTable';

import styles from './DepartmentList.module.css';

const DepartmentList = () => {
  const alert = useAlert();
  const history = useHistory();
  const [isNewDepartmentFormModalOpen, setIsNewDepartmentFormModalOpen] = useState(false);
  const { company } = useAdminCompanies();
  const {
    companyDepartments,
    companyDepartmentsLoadingMoreStatus,
    companyDepartmentsLoadingStatus,
    companyDepartmentsMeta,
    createCompanyDepartment,
    loadCompanyDepartments,
    loadMoreCompanyDepartments,
  } = useAdminCompanyDepartments();

  const { companyDepartmentsStatistic, loadCompanyDepartmentsStatistic, companyDepartmentsStatisticLoadingStatus } =
    useAdminCompanyDepartmentsStatistics();
  const [filters, setFilters] = useState({ q: { nameCont: '', s: ['name asc'] } });
  const sortings = parseRansackSortings(pathOr([], ['q', 's'], filters));

  const handleCompanyDepartmentsFilter = useDebounce((newFilters) => {
    loadCompanyDepartments(company.id, newFilters);
    loadCompanyDepartmentsStatistic(company.id, newFilters);
  });

  const handleCompanyDepartmentsFiltersChange = (newFilters) => {
    setFilters(newFilters);
    handleCompanyDepartmentsFilter(newFilters);
  };
  const handleCompanyDepartmentsFilterChange = (fieldPath) => (value) => {
    const newFilters = assocPath(fieldPath, value, filters);
    handleCompanyDepartmentsFiltersChange(newFilters);
  };
  const handleCompanyDepartmentsLoad = () =>
    loadMoreCompanyDepartments(company.id, { ...filters, page: companyDepartmentsMeta.nextPage });
  const handleCompanyDepartmentsSort = (newSortings) => {
    handleCompanyDepartmentsFiltersChange({ ...filters, q: { ...filters.q, s: buildRansackSortings(newSortings) } });
    handleCompanyDepartmentsFilter.flush();
  };
  const handleNewDepartmentFormModalOpen = () => setIsNewDepartmentFormModalOpen(true);
  const handleNewDepartmentFormModalClose = () => setIsNewDepartmentFormModalOpen(false);
  const handleDepartmentCreate = async (attributes) => {
    const { item: department } = await createCompanyDepartment(company.id, attributes);
    history.push(Routes.editAdminCompanyDepartmentPath(company.id, department.id));

    alert.successAction('Company Department was successfully created.');
  };

  useEffect(() => {
    handleCompanyDepartmentsFilter(filters);
  }, []);

  return (
    <div>
      <Typography className={styles.header} variant="h3">
        Departments
      </Typography>
      <div className={styles.filters}>
        <SearchInput
          onChange={handleCompanyDepartmentsFilterChange(['q', 'nameCont'])}
          placeholder="Search"
          value={filters.q.nameCont}
        />
        <Button onClick={handleNewDepartmentFormModalOpen}>Add Department</Button>
      </div>
      <DepartmentsTable
        company={company}
        companyDepartments={companyDepartments}
        companyDepartmentsStatistic={companyDepartmentsStatistic}
        companyDepartmentsStatisticLoadingStatus={companyDepartmentsStatisticLoadingStatus}
        loadingStatus={companyDepartmentsLoadingStatus}
        loadingMoreStatus={companyDepartmentsLoadingMoreStatus}
        meta={companyDepartmentsMeta}
        onLoadMore={handleCompanyDepartmentsLoad}
        onSortingsChange={handleCompanyDepartmentsSort}
        sortings={sortings}
      />
      <NewDepartmentFormModal
        isOpen={isNewDepartmentFormModalOpen}
        onClose={handleNewDepartmentFormModalClose}
        onDepartmentCreate={handleDepartmentCreate}
      />
    </div>
  );
};

export default DepartmentList;
