import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomizedTooltip.module.css';

const CustomizedTooltip = (props) => {
  const { x, y, requestorName, companyName, totalCount, barWidth, scrollOffset } = props;
  const newX = x - scrollOffset + barWidth / 2;

  return (
    <div className={styles.root} style={{ top: y, left: newX }}>
      <div className={styles.tooltip}>
        <div className={styles.countInfo}>
          <div>
            <div>{requestorName}</div>
            <div className={styles.companyName}>{companyName}</div>
          </div>
          <div className={styles.totalCount}>{totalCount}</div>
        </div>
        <div className={styles.arrow} />
      </div>
    </div>
  );
};

CustomizedTooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  barWidth: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  requestorName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default CustomizedTooltip;
