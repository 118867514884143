import * as normalize from 'normalize-url';
import qs from 'qs';
import { propEq } from 'ramda';
import { match } from 'path-to-regexp';

import { isPresent } from 'utils/HelperMethods';

export const SORT_PARAM_KEY = 'sort';

export function normalizeUrl(string) {
  return normalize(string, { stripWWW: false });
}

export function sortFieldsToParameters(sortFields, sortParamKey = SORT_PARAM_KEY) {
  const parameterValue = sortFields.map(({ name, value }) => `${name} ${value}`);

  return { [sortParamKey]: parameterValue };
}

export function extractSortFields(values, tableColumns) {
  return values
    .map((value) => {
      const concatenationSymbolIndex = value.lastIndexOf(' ');
      const name = value.substring(0, concatenationSymbolIndex);
      const order = value.substring(concatenationSymbolIndex + 1);
      const column = tableColumns.find(propEq(name, 'name'));

      return isPresent(column) ? { field: column.field, value: order } : null;
    })
    .filter(isPresent);
}

export function buildParams(query) {
  return qs.stringify(query, { arrayFormat: 'brackets' });
}

export function buildUrl(path, query) {
  return query ? `${path}?${buildParams(query)}` : path;
}

export function parseLocationToParams(location) {
  return qs.parse(location.search, { ignoreQueryPrefix: true, arrayFormat: 'brackets' });
}

export function locationWithoutQuery() {
  return window.location.href.split('?')[0];
}

export function isProfilePath(pathname) {
  const result = match('/profiles/:slug')(pathname);
  return Boolean(result);
}
