import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'ramda';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

import { Button } from 'grommet';
import Icon from 'components/Icon';
import ProfileAvatar from 'components/ProfileAvatar';

import styles from './StickyHeader.module.css';

class StickyHeader extends Component {
  render() {
    const { onScrollTop, profile } = this.props;
    const currentWorks = ProfilePresenter.currentWorks(profile);
    const currentOrganizations = currentWorks.map(WorkPresenter.organization);
    const currentOrganizationNames = uniq(currentOrganizations.map(OrganizationPresenter.name)).join(', ');

    return (
      <div className={styles.container}>
        <div className={styles.userInfo}>
          <ProfileAvatar className={styles.avatar} size={40} src={ProfilePresenter.photoUrl(profile)} />
          <div className={styles.commonInfo}>
            <strong className={styles.stickyName} title={ProfilePresenter.fullName(profile)}>
              {ProfilePresenter.fullName(profile)}
            </strong>
            {currentOrganizations.length > 0 && (
              <div className={styles.stickyWork} title={currentOrganizationNames}>
                {currentOrganizationNames}
              </div>
            )}
          </div>
        </div>
        <div>
          <Button onClick={onScrollTop} plain className={styles.scroll}>
            <span className={styles.scrollContent}>
              <span className={styles.text}>To the top </span>
              <span className={styles.arrow}>
                <Icon name="arrowUp" />
              </span>
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

StickyHeader.propTypes = {
  onScrollTop: PropTypes.func.isRequired,
  profile: ProfilePresenter.shape().isRequired,
};

export default StickyHeader;
