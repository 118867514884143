import PropTypes from 'prop-types';
import React, { useEffect, useState, Children, useMemo } from 'react';
import { take } from 'ramda';
import { Redirect, Switch } from 'react-router-dom';
import { noop } from 'utils/HelperMethods';
import { useHistory } from 'reactRouterPolyfill';

import Context from './Context';

const Wizard = (props) => {
  const { children, onWizardStepChange } = props;
  const wizardSteps = Children.toArray(children);

  const history = useHistory();
  const [currentWizardStepIndex, setCurrentWizardStepIndex] = useState(0);

  useEffect(() => {
    onWizardStepChange();
  }, [currentWizardStepIndex]);

  const availableWizardSteps = take(currentWizardStepIndex + 1, wizardSteps);
  const firstWizardStepPath = wizardSteps[0]?.props?.path;
  const context = useMemo(
    () => ({
      currentWizardStepIndex,
      navigateNextWizardStep: () => {
        setCurrentWizardStepIndex(currentWizardStepIndex + 1);
        history.push(wizardSteps[currentWizardStepIndex + 1].props.path);
      },
      navigatePreviousWizardStep: () => {
        history.goBack();
        setCurrentWizardStepIndex(currentWizardStepIndex - 1);
      },
      hasNextWizardStep: currentWizardStepIndex + 1 < wizardSteps.length,
      hasPreviousWizardStep: currentWizardStepIndex - 1 >= 0,
    }),
    [currentWizardStepIndex, wizardSteps],
  );

  return (
    <Context.Provider value={context}>
      <Switch>
        {availableWizardSteps.map((wizardStep) => wizardStep)}
        <Redirect to={firstWizardStepPath} />
      </Switch>
    </Context.Provider>
  );
};

Wizard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  onWizardStepChange: PropTypes.func,
};

Wizard.defaultProps = {
  onWizardStepChange: noop,
};

export default Wizard;
