import ProfileRelationshipsToMyExecutivesListConstants from './ProfileRelationshipsToMyExecutivesListConstants';
import RelationshipsRepository from 'repositories/CurrentUser/Project/AvailableProfilesAndColleagues/RelationshipsRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function initializeProfileRelationships(profileId) {
  return {
    type: ProfileRelationshipsToMyExecutivesListConstants.INITIALIZE_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES,
    profileId,
  };
}

export function loadProfileRelationships(projectId, profileId, profilesFilters, { page, per }) {
  return (dispatch) => {
    dispatch({
      type: ProfileRelationshipsToMyExecutivesListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES_START,
    });
    const params = { page, per, ...buildFilterParams(profilesFilters) };

    return RelationshipsRepository.index(projectId, profileId, params).then(({ items, meta }) => {
      dispatch({
        type: ProfileRelationshipsToMyExecutivesListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES_SUCCESS,
        relationships: items,
        meta,
        profileId,
      });
    });
  };
}
