import PropTypes from 'prop-types';
import React from 'react';
import config from 'config';
import { concat, isEmpty, isNil, take, pathOr, pipe, update, without } from 'ramda';
import { equalValues } from 'utils/HelperMethods';
import { pluralize } from 'utils/pluralizer';

import RequestPresenter from 'presenters/RequestPresenter';

import Link from 'components/v2/Link';
import RawHtml from 'components/RawHtml';
import Table, { TableHead, TableBody } from 'components/v2/Table';
import Typography from 'components/v2/Typography';
import { Checkbox } from 'components/v2/Form';

import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';

import styles from './RequestsReviewTable.module.css';

const RequestsReviewTable = (props) => {
  const {
    errors,
    onRequestsChange,
    onSelectedRequestIndexesChange,
    requests,
    remainingRequestsNumber,
    selectedRequestIndexes,
  } = props;

  const requestIndexes = requests.map((_, index) => index);
  const isAllRemainingRequestsAllocated = remainingRequestsNumber <= selectedRequestIndexes.length;
  const isAllRequestSelected =
    (!isEmpty(requestIndexes) && equalValues(requestIndexes, selectedRequestIndexes)) ||
    isAllRemainingRequestsAllocated;

  const handleRequestChange = (index) => (request) => {
    const newRequests = update(index, request, requests);

    onRequestsChange(newRequests);
  };
  const handleRequestsSelectionToggle = () => {
    if (isAllRequestSelected) {
      onSelectedRequestIndexesChange([]);
    } else {
      const newSelectedRequestIndexes = pipe(
        without(selectedRequestIndexes),
        take(remainingRequestsNumber - selectedRequestIndexes.length),
        concat(selectedRequestIndexes),
      )(requestIndexes);

      onSelectedRequestIndexesChange(newSelectedRequestIndexes);
    }
  };
  const handleRequestSelectionChange = (index) => (checked) => {
    const newSelectedRequestIndexes = checked
      ? [...selectedRequestIndexes, index]
      : without([index], selectedRequestIndexes);

    onSelectedRequestIndexesChange(newSelectedRequestIndexes);
  };

  return (
    <div>
      <div className={styles.details}>
        <Typography variant="body1">
          Credits Remaining: <span className={styles.number}>{remainingRequestsNumber}</span>
        </Typography>
        <Typography variant="body1">
          Requested Executives: <span className={styles.number}>{selectedRequestIndexes.length}</span>
        </Typography>
      </div>
      {!isNil(errors?.base) && (
        <div className={styles.baseError}>
          <RawHtml component={Typography} color="orange" variant="body1" innerHTML={errors.base} />
        </div>
      )}
      <div className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableHeader />
          </TableHead>
          <TableBody>
            <tr>
              <td>
                <Checkbox checked={isAllRequestSelected} onChange={handleRequestsSelectionToggle} />
              </td>
              <td colSpan={5}>
                <Typography color="orange" variant="body1">
                  {pluralize('executive', selectedRequestIndexes.length, true)} will be requested. You now have{' '}
                  {remainingRequestsNumber} credits. To request more credits, please reach out via our live chat or by
                  emailing{' '}
                  <Link color="primary" href={`mailto:${config.app.customerSupportEmail}`}>
                    {config.app.customerSupportEmail}
                  </Link>
                </Typography>
              </td>
            </tr>
            {requests.map((request, index) => (
              <TableRow
                key={`${index}-${requests.length}`}
                errors={pathOr({}, ['requests', index], errors)}
                canRequestSelectionChange={selectedRequestIndexes.includes(index) || !isAllRemainingRequestsAllocated}
                isRequestSelected={selectedRequestIndexes.includes(index)}
                onRequestChange={handleRequestChange(index)}
                onRequestSelectionChange={handleRequestSelectionChange(index)}
                request={request}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

RequestsReviewTable.propTypes = {
  errors: PropTypes.shape(),
  onRequestsChange: PropTypes.func.isRequired,
  onSelectedRequestIndexesChange: PropTypes.func.isRequired,
  remainingRequestsNumber: PropTypes.number.isRequired,
  requests: PropTypes.arrayOf(RequestPresenter.shape()).isRequired,
  selectedRequestIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

RequestPresenter.defaultProps = {
  errors: null,
};

export default RequestsReviewTable;
