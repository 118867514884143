import React from 'react';
import PropTypes from 'prop-types';

import CompanyPresenter from 'presenters/CompanyPresenter';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';
import Link from 'components/v2/Link';
import Wysiwyg from 'components/Wysiwyg';
import { Field, Input } from 'components/v2/Form';

import Routes from 'routes';

import styles from './SelfRegistrationFormContentFields.module.css';

const SelfRegistrationFormContentFields = (props) => {
  const { errors, companyValues, isSubmitting, onFieldChange, onSubmit } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div>
      <Typography className={styles.title} variant="h3">
        Right Column
      </Typography>
      <Wysiwyg
        editableContent={companyValues.registrationFormWysiwyg}
        onChange={handleChange('registrationFormWysiwyg')}
      />
      <Field title="YouTube Video Embed Code:" error={errors.registrationFormVideoCode} variant="outlined">
        <Input onChange={handleChange('registrationFormVideoCode')} value={companyValues.registrationFormVideoCode} />
      </Field>
      <Button component={Link} target="_blank" href={Routes.newCompanyUserPath(companyValues.id)} variant="outlined">
        Open Self Registration Form
      </Button>
      <Button className={styles.submitButton} disabled={isSubmitting} onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
};

SelfRegistrationFormContentFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  companyValues: CompanyPresenter.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SelfRegistrationFormContentFields;
