import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(({ children, className }) => <div className={className}>{children}</div>);

DragHandle.propTypes = {
  children: PropTypes.node,
};

export default DragHandle;
