/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';
import { extractResourceData } from './utils';

export default function updateResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        updatingStatus: FETCH_STATUSES.idle,
        error: null,
      },
    },
    reducers: {
      updateResourceStart(state) {
        state[resource].updatingStatus = FETCH_STATUSES.pending;
        state[resource].error = null;
      },
      updateResourceSuccess(state, { payload }) {
        const item = extractResourceData(payload);
        state[resource].item = item;
        state[resource].updatingStatus = FETCH_STATUSES.fulfilled;
      },
      updateResourceFail(state, { payload: { error } }) {
        state[resource].updatingStatus = FETCH_STATUSES.failed;
        state[resource].error = error;
      },
    },
    actionCreators(restDispatch) {
      return {
        updateResource: async (...params) => {
          restDispatch('updateResourceStart');

          try {
            const data = await repository.update(...params);
            restDispatch('updateResourceSuccess', data);
          } catch (error) {
            restDispatch('updateResourceFail', { error });
            throw error;
          }
        },
      };
    },
    abstractSelector: (state) => ({ resource: state[resource] }),
  };
}
