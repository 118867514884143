import { useAlert as useOriginalAlert } from 'react-alert';

import { VARIANTS } from 'components/v2/Alerts';

const useAlert = () => {
  const alert = useOriginalAlert();

  const showAlert = (variant, data, options) => alert.show({ variant, data }, options);

  return {
    successAction: (message, options) => showAlert(VARIANTS.ACTION, { status: 'ok', message }, options),
    failedAction: (message, options) => showAlert(VARIANTS.ACTION, { status: 'critical', message }, options),
    showModal: (modalElementType, modalProps, options) =>
      showAlert(VARIANTS.MODAL, { modalElementType, modalProps }, { timeout: 0, ...options }),
  };
};

export default useAlert;
