import React from 'react';

import ProfilePresenter from 'presenters/ProfilePresenter';

import RawHtml from 'components/RawHtml';

import InterestsList from './components/InterestsList';
import SocialNetworks, { isAnySocialNetworksPresent } from './components/SocialNetworks';

import styles from './Sidebar.module.css';

export function isSidebarInformationPresent(profile) {
  return (
    ProfilePresenter.interests(profile)?.length > 0 ||
    ProfilePresenter.newsLinks(profile) ||
    isAnySocialNetworksPresent(profile)
  );
}

const Sidebar = (props) => {
  const { profile } = props;
  const interests = ProfilePresenter.interests(profile);
  const newsLinks = ProfilePresenter.newsLinks(profile);

  return (
    <div className={styles.root}>
      {interests?.length > 0 && <InterestsList interests={interests} />}

      {newsLinks && (
        <>
          <h5>News and Interviews</h5>
          <RawHtml component="p" innerHTML={newsLinks} />
        </>
      )}

      {isAnySocialNetworksPresent(profile) && <SocialNetworks profile={profile} />}
    </div>
  );
};

Sidebar.propTypes = {
  profile: ProfilePresenter.shape().isRequired,
};

export default Sidebar;
