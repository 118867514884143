import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import SuggestionsRepository from 'repositories/Request/SuggestionsRepository';

const restSlice = createRestSlice({
  resource: 'requestSuggestion',
  repository: SuggestionsRepository,
  slices: ['loadRequestSuggestions'],
});

const initialState = {
  ...restSlice.initialState,
};

const requestSuggestionsSlice = createSlice({
  name: 'clientRequestSuggestions',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientRequestSuggestionsReducer = requestSuggestionsSlice.reducer;

export default requestSuggestionsSlice;

export const clientRequestSuggestionsRestHooks = restSlice.hooks(
  requestSuggestionsSlice,
  'clientRequestSuggestionsReducer',
);
