import React from 'react';
import Routes from 'routes';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import { useAdminCompanyDepartments } from 'hooks/admin';
import { useAdminCompanyUsersMatches } from 'hooks/admin';
import { useAdminCompanyUsers } from 'hooks/admin';

import UsersMatchPresenter from 'presenters/Company/UsersMatchPresenter';

import UsersBulkCreateForm from 'forms/company/UsersBulkCreateForm';

import Form from 'components/v2/Form';
import UserWizard, { WizardStep, WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import DepartmentSelectionWizardStep from './components/DepartmentSelectionWizardStep';
import UploadUsersCsvWizardStep from './components/UploadUsersCsvWizardStep';
import MatchUsersWizardStep from './components/MatchUsersWizardStep';
import CreateUsersWizardStep from './components/CreateUsersWizardStep';

import styles from './CorporateAccountImportUsersWizard.module.css';

const buildBreadcrumbs = (companyId) => [
  { name: 'Company', to: Routes.editAdminCompanyPath(companyId) },
  { name: 'Import Users From.CSV' },
];

const SELECT_DEPARTMENT_STEP = 'select-department';
const SELECT_FILE_STEP = 'select-file';
const MATCH_USERS_STEP = 'match-users';
const CREATE_USERS_STEP = 'create-users';

const CorporateAccountImportUsersWizard = (props) => {
  const {
    history,
    match: {
      params: { id: companyId },
    },
  } = props;

  const { loadCompanyDepartments } = useAdminCompanyDepartments();
  const { createUsersMatch, usersMatches, usersMatchCreatingStatus } = useAdminCompanyUsersMatches();
  const { bulkCreateUsers, usersBulkCreatingStatus } = useAdminCompanyUsers();
  const commonProps = { WizardTitle, WizardNavigation };

  const handleMatchUsersCreate = (source) => () => createUsersMatch(companyId, source);

  const handleDepartmentSearch = (value) => {
    const params = buildFilterParams({ nameCont: value });

    return loadCompanyDepartments(companyId, params).then(({ items }) => items);
  };

  const handleWizardClose = () => {
    history.push(Routes.editAdminCompanyPath(companyId));
  };

  const handleUsersCreate = (attributes) => {
    const users = UsersMatchPresenter.validMissingUsers(usersMatches);
    const usersAttributes = UsersBulkCreateForm.attributesToSubmit({ ...attributes, users });

    bulkCreateUsers(companyId, usersAttributes);
  };

  return (
    <div className={styles.root}>
      <Form initialValues={UsersBulkCreateForm.defaultAttributes()} onSubmit={handleUsersCreate}>
        {({ values, onFieldChange, onSubmit }) => (
          <UserWizard breadcrumbs={buildBreadcrumbs(companyId)} onWizardClose={handleWizardClose}>
            <WizardStep path={Routes.newAdminCompanyUsersImportWizardStepPath(companyId, SELECT_DEPARTMENT_STEP)}>
              <DepartmentSelectionWizardStep
                {...commonProps}
                onDepartmentChange={(department) => onFieldChange('department', department)}
                onDepartmentSearch={handleDepartmentSearch}
                department={values.department}
              />
            </WizardStep>

            <WizardStep path={Routes.newAdminCompanyUsersImportWizardStepPath(companyId, SELECT_FILE_STEP)}>
              <UploadUsersCsvWizardStep
                {...commonProps}
                companyId={companyId}
                file={values.source.file}
                onFileChange={(file, delimiter) => onFieldChange('source', { file, delimiter })}
              />
            </WizardStep>

            <WizardStep path={Routes.newAdminCompanyUsersImportWizardStepPath(companyId, MATCH_USERS_STEP)}>
              <MatchUsersWizardStep
                {...commonProps}
                usersMatches={usersMatches}
                isLoading={usersMatchCreatingStatus.isPending}
                onLoad={handleMatchUsersCreate(values.source)}
                onSelectedUserIndicesChange={(indices) => onFieldChange('selectedUserIndices', indices)}
                selectedUserIndices={values.selectedUserIndices}
              />
            </WizardStep>

            <WizardStep path={Routes.newAdminCompanyUsersImportWizardStepPath(companyId, CREATE_USERS_STEP)}>
              <CreateUsersWizardStep
                {...commonProps}
                companyId={companyId}
                onCreateUsers={onSubmit}
                usersBulkCreatingStatus={usersBulkCreatingStatus}
              />
            </WizardStep>
          </UserWizard>
        )}
      </Form>
    </div>
  );
};

export default CorporateAccountImportUsersWizard;
