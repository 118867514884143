import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';

export const PROFILE_GUIDE_NAME = 'profile_guide';

export default new Presenter(
  {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    state: PropTypes.string,
    isImpersonating: PropTypes.bool,
    createdAt: PropTypes.string,
    subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    remainingRequestsNumber: PropTypes.number,
    remainingProfileViewsNumber: PropTypes.number,
    remainingProfileDownloadsNumber: PropTypes.number,
    corporateViewAvailable: PropTypes.bool,
    exportSearchResultInCsvAvailable: PropTypes.bool,
    projectsAvailable: PropTypes.bool,
    projectsSharingAvailable: PropTypes.bool,
    databaseOnly: PropTypes.bool,
    canRequestProfile: PropTypes.bool,
    hasLimitedAccess: PropTypes.bool,
  },
  {
    fullName(u) {
      return `${this.firstName(u) || ''} ${this.lastName(u) || ''}`;
    },
  },
);
