import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(profileId) {
    const url = Routes.apiV1CustomerProfileRelationshipsMappingPath(profileId);

    return FetchHelpers.get(url);
  },
};
