import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import Spinner from 'components/Spinner';
import ProjectPage from './containers/ProjectPage';
import NewProjectSourceWizard from './containers/NewProjectSourceWizard';
import WordsCloud from './containers/WordsCloud';
import TargetingSourceEditPage from './containers/TargetingSourceEditPage';
import CsvSourceEditPage from './containers/CsvSourceEditPage';
import BusinessPriorityAnalysis from './containers/BusinessPriorityAnalysis';
import AudienceInsights from './containers/AudienceInsights';
import RelationshipsMapping from './containers/RelationshipsMapping';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import Routes from 'routes';

export default class Project extends Component {
  static propTypes = {
    backgroundExport: PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape(),
    destroyProjectShare: PropTypes.func.isRequired,
    destroySubscription: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    loadProject: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    project: ProjectPresenter.shape(),
    projectId: PropTypes.string,
    reloadProject: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { projectId, loadProject } = this.props;

    loadProject(projectId);
  }

  handleProjectReload = () => {
    const { projectId, reloadProject } = this.props;

    reloadProject(projectId);
  };

  handleProjectBackgroundExport = (params) => {
    const { backgroundExport, project } = this.props;
    const projectId = ProjectPresenter.id(project);

    return backgroundExport({ ...params, exportableId: projectId, exportableType: 'Project' });
  };

  handleCreateSubscription = () => {
    const { createSubscription, project } = this.props;
    const params = {
      kind: 'email_alert',
      projectId: ProjectPresenter.id(project),
    };

    return createSubscription(params).then(this.handleProjectReload);
  };

  handleDestroySubscription = () => {
    const { destroySubscription, project } = this.props;
    const subscriptionId = ProjectPresenter.subscriptionId(project);

    return destroySubscription(subscriptionId).then(this.handleProjectReload);
  };

  handleDestroyShare = () => {
    const { destroyProjectShare, history, project } = this.props;
    const shareId = ProjectPresenter.shareId(project);

    return destroyProjectShare(project.id, shareId).then(() => {
      history.push(Routes.projectsPath());
    });
  };

  renderNestedRoutes() {
    const { currentUser, project } = this.props;
    const sharedContext = {
      currentUser,
      onDestroyShare: this.handleDestroyShare,
      onProjectDataExport: this.handleProjectBackgroundExport,
      onProjectReload: this.handleProjectReload,
      onSubscribe: this.handleCreateSubscription,
      onUnsubscribe: this.handleDestroySubscription,
      project,
    };

    return (
      <AppSwitch sharedContext={sharedContext}>
        <AppRoute exact path={Routes.projectPath(':projectId')} component={ProjectPage} />

        <AppRoute path={Routes.newProjectSourcePath(':projectId')} component={NewProjectSourceWizard} />

        <AppRoute
          exact
          path={Routes.editProjectTargetingSourcePath(':projectId', ':sourceId')}
          component={TargetingSourceEditPage}
        />

        <AppRoute
          exact
          path={Routes.editProjectCsvSourcePath(':projectId', ':sourceId')}
          component={CsvSourceEditPage}
        />

        <AppRoute exact path={Routes.projectRelationshipsMappingPath(':projectId')} component={RelationshipsMapping} />

        <AppRoute exact path={Routes.projectWordsCloudPath(':projectId')} component={WordsCloud} />

        <AppRoute exact path={Routes.projectAudienceInsightsPath(':projectId')} component={AudienceInsights} />

        <AppRoute
          exact
          path={Routes.projectBusinessPriorityAnalysisPath(':projectId')}
          component={BusinessPriorityAnalysis}
        />
      </AppSwitch>
    );
  }

  render() {
    const { loading, project, projectId } = this.props;

    if (loading || isBlank(project) || ProjectPresenter.id(project) !== Number(projectId)) {
      return <Spinner />;
    }

    return this.renderNestedRoutes();
  }
}
