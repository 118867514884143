import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, TextInput } from 'grommet';

import { LUXON_YEAR_FORMAT } from 'utils/DateTime';

import { Field, DatePicker } from 'components/v2/Form';
import Button from 'components/Button';
import Modal from 'components/Modal';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import OrganizationSelector from 'containers/AdminPanel/containers/OrganizationSelector';

import InstitutionPresenter from 'presenters/InstitutionPresenter';
import ProfileInstitutionForm from 'forms/profile/InstitutionForm';

import styles from './InstitutionForm.module.css';

export default class InstitutionForm extends Component {
  constructor(props) {
    super(props);

    const { institution } = props;

    this.state = {
      errors: {},
      fields: ProfileInstitutionForm.defaultAttributes(institution),
    };
  }

  handleDOMChange = (fieldName) => (event) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: event.target.value } });
  };

  handleDateChange = (fieldName) => (value) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: value } });
  };

  handleSubmit = () => {
    const params = ProfileInstitutionForm.attributesToSubmit(this.state.fields);

    this.props.onSubmit(params).catch(this.handleErrors);
  };

  handleErrors = ({ errors = {} }) => {
    this.setState({ errors });
  };

  handleInstitutionSelect = (organization) => {
    this.setState({ fields: { ...this.state.fields, organization } });
  };

  errorFor(fieldName) {
    const {
      errors: { [fieldName]: error },
    } = this.state;

    return error;
  }

  renderDateTimeField(field, label) {
    const {
      fields: { [field]: value },
    } = this.state;

    return (
      <div className={styles.dateRow}>
        <div className={styles.dateLabel}>{label}</div>
        <div className={styles.dateInput}>
          <Field error={this.errorFor(field)}>
            <DatePicker
              value={value}
              onChange={this.handleDateChange(field)}
              showYearPicker
              dateFormat={LUXON_YEAR_FORMAT}
            />
          </Field>
        </div>
      </div>
    );
  }

  render() {
    const { fields } = this.state;
    const { saving, title } = this.props;

    return (
      <Modal
        onClose={this.props.onCancel}
        header={title}
        margin="medium"
        size="medium"
        disableOverlayClose
        disableCloseByEsc
      >
        <Box margin={{ bottom: 'medium' }}>
          <Box margin={{ bottom: 'small' }} className={styles.title}>
            <OrganizationSelector
              errors={this.errorFor('organization')}
              placeholder="Institution"
              value={fields.organization}
              onChange={this.handleInstitutionSelect}
              industryName="Education"
              setDefaultIndustry
            />
          </Box>
          <VerticalFormFieldWithBottomErrors errors={this.errorFor('degree')} label="Degree" htmlFor="degree">
            <TextInput value={fields.degree} onChange={this.handleDOMChange('degree')} id="degree" />
          </VerticalFormFieldWithBottomErrors>
          <Box margin={{ bottom: 'small' }}>
            Date
            {this.renderDateTimeField('from', 'From')}
            {this.renderDateTimeField('to', 'To')}
          </Box>
        </Box>

        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}

InstitutionForm.propTypes = {
  title: PropTypes.string.isRequired,
  institution: InstitutionPresenter.shape(),
  saving: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
