import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Tabs as ReactTabs } from 'react-tabs';

import Context from './Context';

export const VARIANTS = {
  DEFAULT: 'default',
  BUTTONS_GROUP: 'buttonsGroup',
};

const Tabs = (props) => {
  const { variant, ...rest } = props;
  const context = useMemo(() => ({ variant }), [variant]);

  return (
    <Context.Provider value={context}>
      <ReactTabs {...rest} />
    </Context.Provider>
  );
};

Tabs.propTypes = {
  variant: PropTypes.string,
};

Tabs.defaultProps = {
  variant: VARIANTS.DEFAULT,
};

export default Tabs;
