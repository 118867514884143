import React, { Component } from 'react';
import PropTypes from 'prop-types';

const textStyles = {
  transform: 'translate(-4px, -12px)',
  color: '#838588',
  fontSize: '10px',
  letterSpacing: '1.2px',
};

export default class CustomizedYAxisTick extends Component {
  static propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.shape(),
  };

  render() {
    const {
      x,
      y,
      payload: { value },
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" style={textStyles}>
          {value}
        </text>
      </g>
    );
  }
}
