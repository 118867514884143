import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { isPresent } from 'utils/HelperMethods';
import { logClickOnProjectPageSendNow, logClickOnProjectPageShareLiveVersion } from 'utils/amplitude';

import Modal, { Header } from 'containers/UserPanel/components/Modal';
import { SuccessModal } from 'components/BackgroundExportResultNotifier';
import MasterExportForm from './components/MasterExportForm';
import ShareForm from './components/ShareForm';
import Tabs, { Tab } from 'components/Tabs';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';
import SharePresenter from 'presenters/Project/SharePresenter';

import styles from './ShareModal.module.css';

const DEFAULT_BASE_ERROR_MESSAGE = 'One or more fields have an error. Please check and try again.';

const DESCRIPTIONS_FOR_TABS = {
  0: (project) =>
    ProjectPolicy.canShareToNewCompanyMembers(project) ? 'Invite your colleagues to view your project.' : null,
  1: () => 'Please select the items you would like to share and download.',
};

export default class ShareModal extends Component {
  static propTypes = {
    batchUpdateProjectShares: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape().isRequired,
    filterProjectShares: PropTypes.func.isRequired,
    isSharesLoading: PropTypes.bool.isRequired,
    isSharesSaving: PropTypes.bool.isRequired,
    loadProjectShares: PropTypes.func.isRequired,
    loadProjectSharesSuggestions: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
    shares: PropTypes.arrayOf(SharePresenter.shape()).isRequired,
    sharesNextPage: PropTypes.number,
  };

  constructor(...props) {
    super(...props);

    const { project } = this.props;
    const activeTabIndex = this.canShare(project) ? 0 : 1;
    this.state = { activeTabIndex, isPersisted: false, errors: null };
  }

  canShare(project) {
    return ProjectPolicy.canShare(project);
  }

  handleExport = (attributes) => {
    const { onExport } = this.props;

    logClickOnProjectPageSendNow();

    return onExport(attributes).then(this.handleSuccess).catch(this.handleErrors);
  };

  handleUpdateShares = (attributes) => {
    const { batchUpdateProjectShares, onClose, project } = this.props;

    logClickOnProjectPageShareLiveVersion();

    return batchUpdateProjectShares(project.id, attributes)
      .then(onClose)
      .catch((error) => {
        this.handleErrors(error);

        throw error;
      });
  };

  handleSuccess = () => {
    this.setState({ ...this.state, isPersisted: true });
  };

  handleErrors = ({ errors }) => {
    this.setState({ ...this.state, errors });
  };

  handleLoadShareSuggestions = (filters) => {
    const { loadProjectSharesSuggestions, project } = this.props;

    return loadProjectSharesSuggestions(project.id, filters);
  };

  handleLoadShares = (filters) => {
    const { loadProjectShares, project } = this.props;

    return loadProjectShares(project.id, filters);
  };

  handleFilterShares = (filters) => {
    const { filterProjectShares, project } = this.props;

    return filterProjectShares(project.id, filters);
  };

  handleChangeActiveTabIndex = (activeTabIndex) => this.setState({ activeTabIndex });

  renderForm() {
    const { currentUser, isSharesLoading, isSharesSaving, project, shares, sharesNextPage } = this.props;
    const { activeTabIndex, errors } = this.state;

    if (!this.canShare(project)) {
      return (
        <MasterExportForm currentUser={currentUser} errors={errors} onSubmit={this.handleExport} project={project} />
      );
    }

    return (
      <Tabs activeIndex={activeTabIndex} onActive={this.handleChangeActiveTabIndex} className={styles.tabs}>
        <Tab title="Share Live Version">
          <ShareForm
            isLoading={isSharesLoading}
            isSaving={isSharesSaving}
            nextPage={sharesNextPage}
            onFilterShares={this.handleFilterShares}
            onLoadShareSuggestions={this.handleLoadShareSuggestions}
            onLoadShares={this.handleLoadShares}
            onSubmit={this.handleUpdateShares}
            shares={shares}
            shareWithUnregisteredUser={ProjectPolicy.canShareToNewCompanyMembers(project)}
          />
        </Tab>
        <Tab title="Share Files">
          <MasterExportForm currentUser={currentUser} errors={errors} onSubmit={this.handleExport} project={project} />
        </Tab>
      </Tabs>
    );
  }

  render() {
    const { onClose, project } = this.props;
    const { activeTabIndex, isPersisted, errors } = this.state;
    const baseErrorMessage = isPresent(errors) ? propOr(DEFAULT_BASE_ERROR_MESSAGE, 'base')(errors) : null;
    const description = DESCRIPTIONS_FOR_TABS[activeTabIndex](project);

    if (isPersisted) {
      return <SuccessModal onClose={onClose} />;
    }

    return (
      <Modal className={styles.modal} onClose={onClose}>
        <Header title="Share Your BI Pro Analytics." description={description} error={baseErrorMessage} />
        {this.renderForm()}
      </Modal>
    );
  }
}
