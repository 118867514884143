import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { equals, reject } from 'ramda';

import Button from 'components/Button';
import ChooseFiltersModal from './components/ChooseFiltersModal';
import { Filter as FilterIcon } from 'grommet-icons';
import Search from 'components/SearchInput';
import SelectedState from './components/SelectedState';
import { isEmpty } from 'ramda';

import styles from './FiltersForm.module.css';

export default class FiltersForm extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      searchFieldCont: PropTypes.string.isRequired,
      stateIn: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = { isChooseFilterModalOpened: false };

  handleFiltersChange = (fieldName, value) => {
    const { filters, onChange } = this.props;

    onChange({ ...filters, [fieldName]: value });
  };

  handleSearchChange = ({ target: { value } }) => this.handleFiltersChange('searchFieldCont', value);

  handleOpenChooseFilterModal = () => this.setState({ ...this.state, isChooseFilterModalOpened: true });

  handleCloseChooseFilterModal = () => this.setState({ ...this.state, isChooseFilterModalOpened: false });

  handleSubmitChooseFilterModal = (stateIn) => {
    this.handleFiltersChange('stateIn', stateIn);
    this.handleCloseChooseFilterModal();
  };

  handleRemoveState = (state) => {
    const { filters } = this.props;

    this.handleFiltersChange('stateIn', reject(equals(state), filters.stateIn));
  };

  handleFiltersReset = () => {
    const { filters, onChange } = this.props;

    onChange({ ...filters, stateIn: [], searchFieldCont: '' });
  };

  render() {
    const { isChooseFilterModalOpened } = this.state;
    const { filters } = this.props;
    const { stateIn } = filters;
    return (
      <>
        {isChooseFilterModalOpened && (
          <ChooseFiltersModal
            checkedStates={stateIn}
            onClose={this.handleCloseChooseFilterModal}
            onSubmit={this.handleSubmitChooseFilterModal}
          />
        )}
        <div className={styles.actions}>
          <Search
            onChange={this.handleSearchChange}
            placeholder="Start typing Name, Title or Company"
            value={filters.searchFieldCont}
          />
          <div className={styles.buttons}>
            <Button className={styles.clearButton} label="Clear All" onClick={this.handleFiltersReset} plain />
            <Button icon={<FilterIcon />} label="Choose Filter" onClick={this.handleOpenChooseFilterModal} />
          </div>
        </div>
        <div className={styles.stateTags}>
          {isEmpty(filters.stateIn) ? (
            <div className={styles.noFilterText}>No filters checked </div>
          ) : (
            filters.stateIn.map((state) => (
              <SelectedState key={state} onRemove={this.handleRemoveState} state={state} />
            ))
          )}
        </div>
      </>
    );
  }
}
