import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';

import FramedWizard, { WizardStep } from 'components/v2/FramedWizard';
import SourceTypeSelection from 'containers/UserPanel/components/ProjectSourceTypeSelectionWizardStep';
import Targeting from 'containers/UserPanel/components/ProjectTargetingWizardStep';
import UploadExecutivesCsvForm from 'containers/UserPanel/components/ProjectUploadExecutivesCsvWizardStep';
import UploadOrganizationsCsvForm from 'containers/UserPanel/components/ProjectUploadOrganizationsCsvWizardStep';
import MatchedOrganizations from 'containers/UserPanel/containers/ProjectMatchedOrganizationsWizardStep';
import MatchedExecutives from 'containers/UserPanel/components/ProjectMatchedExecutivesWizardStep';
import RequestExecutives from 'containers/UserPanel/components/ProjectRequestExecutivesWizardStep';
import CompleteSourceCreation from './components/CompleteSourceCreation';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import SourcePresenter, { DATABASE_SEARCH_KIND, TARGETING_SOURCE_TYPE } from 'presenters/Project/SourcePresenter';
import { INCLUSION_KIND } from 'presenters/Project/TargetItemPresenter';

import SourceForm from 'forms/project/SourceForm';
import TargetItemForm from 'forms/project/TargetItemForm';

const SELECT_TYPE_STEP = 'select-type';
const TARGETING_STEP = 'targeting';
const UPLOAD_LIST_STEP = 'upload-list';
const MATCHED_ORGANIZATIONS = 'matched-organizations';
const CONFIRM_EXECUTIVES_STEP = 'confirm-executives';
const REQUEST_EXECUTIVES_STEP = 'request-executives';
const COMPLETE_STEP = 'complete';

class NewProjectSourceWizard extends Component {
  componentDidMount() {
    const { initializeWizard, project } = this.props;
    const recommendedProfilesCount = ProjectPresenter.recommendedProfilesCount(project);
    const targetItem = TargetItemForm.defaultAttributes({ kind: INCLUSION_KIND });
    const source = SourceForm.defaultAttributes({
      recommendedProfilesCount,
      kind: DATABASE_SEARCH_KIND,
      type: TARGETING_SOURCE_TYPE,
      targetItemsAttributes: [targetItem],
    });

    initializeWizard(source);
  }

  handleSourceUpdate = (source) => {
    const { changeForm } = this.props;

    changeForm(source);
  };

  handleWizardClose = () => {
    const { history, project } = this.props;
    const projectId = ProjectPresenter.id(project);

    history.push(Routes.projectPath(projectId));
  };

  breadcrumbs() {
    const { project } = this.props;

    return [
      { name: 'Projects', to: Routes.projectsPath() },
      { to: Routes.projectPath(ProjectPresenter.id(project)), name: ProjectPresenter.name(project) },
      { name: 'Add List' },
    ];
  }

  render() {
    const { currentUser, createProjectSource, onProjectReload, project, saved, source, loading } = this.props;
    const projectId = ProjectPresenter.id(project);

    return (
      <FramedWizard breadcrumbs={this.breadcrumbs()} onWizardClose={this.handleWizardClose}>
        {!CurrentUserPresenter.hasLimitedAccess(currentUser) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, SELECT_TYPE_STEP)}>
            <SourceTypeSelection source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isDatabaseSearchKind(source) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, TARGETING_STEP)}>
            <Targeting source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isOrganizationsListKind(source) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, UPLOAD_LIST_STEP)}>
            <UploadOrganizationsCsvForm source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isOrganizationsListKind(source) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, MATCHED_ORGANIZATIONS)}>
            <MatchedOrganizations source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isExecutivesListKind(source) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, UPLOAD_LIST_STEP)}>
            <UploadExecutivesCsvForm source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isExecutivesListKind(source) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, CONFIRM_EXECUTIVES_STEP)}>
            <MatchedExecutives source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        {SourcePresenter.isExecutivesListKind(source) && (
          <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, REQUEST_EXECUTIVES_STEP)}>
            <RequestExecutives currentUser={currentUser} source={source} updateSource={this.handleSourceUpdate} />
          </WizardStep>
        )}

        <WizardStep path={Routes.newProjectSourceWizardStepPath(projectId, COMPLETE_STEP)}>
          <CompleteSourceCreation
            createProjectSource={createProjectSource}
            form={source}
            onProjectReload={onProjectReload}
            project={project}
            saved={saved}
            loading={loading}
          />
        </WizardStep>
      </FramedWizard>
    );
  }
}

NewProjectSourceWizard.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  changeForm: PropTypes.func.isRequired,
  createProjectSource: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  project: ProjectPresenter.shape(),
  initializeWizard: PropTypes.func.isRequired,
  saved: PropTypes.bool.isRequired,
  source: SourcePresenter.shape().isRequired,
  onProjectReload: PropTypes.func.isRequired,
};

export default NewProjectSourceWizard;
