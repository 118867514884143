import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import InfiniteScroll from 'react-infinite-scroller';
import { Spinner as Spinning } from 'grommet';
import Profile from './components/Profile';

import ProjectPresenter from 'presenters/ProjectPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';

import styles from './ProjectProfilesList.module.css';

export default class ProjectProfilesList extends Component {
  static propTypes = {
    nextPage: PropTypes.number,
    filterProjectAvailableProfiles: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadProjectAvailableProfiles: PropTypes.func.isRequired,
    profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
    project: ProjectPresenter.shape().isRequired,
    filters: PropTypes.shape(),
    profilePathQuery: PropTypes.shape({}),
  };

  componentDidMount() {
    const { project, filterProjectAvailableProfiles, filters } = this.props;

    filterProjectAvailableProfiles(ProjectPresenter.id(project), filters);
  }

  isAllProfilesLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { project, loading, loadProjectAvailableProfiles, nextPage, filters } = this.props;

    if (!loading && !this.isAllProfilesLoaded()) {
      loadProjectAvailableProfiles(ProjectPresenter.id(project), { ...filters, page: nextPage });
    }
  };

  renderLoader() {
    return (
      <div key="loader" className={styles.loaderBox}>
        <Spinning />
      </div>
    );
  }

  render() {
    const { profiles, profilePathQuery } = this.props;

    return (
      <div className={styles.list}>
        <InfiniteScroll
          loadMore={this.handleLoadMore}
          hasMore={!this.isAllProfilesLoaded()}
          loader={this.renderLoader()}
          useWindow={false}
          initialLoad={false}
        >
          {profiles.map((profile) => (
            <Profile key={ProfilePresenter.id(profile)} profile={profile} profilePathQuery={profilePathQuery} />
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}
