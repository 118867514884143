import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { formattedDate } from 'utils/DateTime';

const POPULATING_WAS_NOT_RUN = 'was_not_run';
const POPULATING_COMPLETED = 'completed';
const POPULATING_FAILED = 'failed';
const POPULATING_RUNNED = 'runned';

export const POPULATING_STATES = [
  {
    key: POPULATING_WAS_NOT_RUN,
    title: 'Wasn`t run',
  },
  {
    key: POPULATING_COMPLETED,
    title: 'Completed',
  },
  {
    key: POPULATING_FAILED,
    title: 'Failed',
  },
  {
    key: POPULATING_RUNNED,
    title: 'Runned',
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    title: PropTypes.string,
    fullTitle: PropTypes.string,
    populatingState: PropTypes.string,
    lastPopulatingCompletedAt: PropTypes.string,
    lastPopulatingStartedAt: PropTypes.string,
  },
  {
    parentTitle(tag) {
      return isBlank(tag.parent) ? null : tag.parent.title;
    },
    parentFullTitle(tag) {
      return isBlank(tag.parent) ? null : tag.parent.fullTitle;
    },
    parentId(tag) {
      return isBlank(tag.parent) ? null : tag.parent.id;
    },
    isPopulatingWasNotRun(tag) {
      const populatingState = this.populatingState(tag);

      return populatingState === POPULATING_WAS_NOT_RUN;
    },
    isPopulatingCompleted(tag) {
      const populatingState = this.populatingState(tag);

      return populatingState === POPULATING_COMPLETED;
    },
    isPopulatingFailed(tag) {
      const populatingState = this.populatingState(tag);

      return populatingState === POPULATING_FAILED;
    },
    isPopulatingRunned(tag) {
      const populatingState = this.populatingState(tag);

      return populatingState === POPULATING_RUNNED;
    },
    humanPopulatingStateName(tag) {
      const populatingState = this.populatingState(tag);
      const state = POPULATING_STATES.find(({ key }) => key === populatingState);

      return isPresent(state) ? state.title : null;
    },
    formattedLastPopulatingCompletedAt(tag) {
      return formattedDate(this.lastPopulatingCompletedAt(tag));
    },
  },
);
