import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrganizationListPage from './OrganizationListPage';
import * as actions from './OrganizationListPageActions';

const mapStateToProps = (state) => ({
  filters: state.OrganizationListPageReducer.filters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationListPage);
