import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(kind, subscriptionConfiguration = {}) {
    const url = Routes.apiV1CurrentUserSubscriptionConfigurationPath(kind);

    return FetchHelpers.patch(url, { subscriptionConfiguration });
  },
  show(kind) {
    const url = Routes.apiV1CurrentUserSubscriptionConfigurationPath(kind);

    return FetchHelpers.get(url);
  },
};
