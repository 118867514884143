/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import RequestsRepository from 'repositories/RequestsRepository';

const initialState = {
  requests: {
    bulkCreatingStatus: FETCH_STATUSES.idle,
    error: null,
  },
};

const requestsSlice = createSlice({
  name: 'clientRequestsSlice',
  initialState,
  reducers: {
    bulkCreateResourceStart(state) {
      state.requests.bulkCreatingStatus = FETCH_STATUSES.pending;
      state.requests.error = null;
    },
    bulkCreateResourceSuccess(state) {
      state.requests.bulkCreatingStatus = FETCH_STATUSES.fulfilled;
    },
    bulkCreateResourceFail(state, { payload: { error } }) {
      state.requests.bulkCreatingStatus = FETCH_STATUSES.failed;
      state.requests.error = error;
    },
  },
});

const { actions } = requestsSlice;

export const useClientRequestsActions = () => {
  const dispatch = useDispatch();

  return {
    bulkCreateRequests: async (params) => {
      dispatch(actions.bulkCreateResourceStart());

      try {
        const data = await RequestsRepository.batchCreate(params);
        dispatch(actions.bulkCreateResourceSuccess());

        return data;
      } catch (error) {
        dispatch(actions.bulkCreateResourceFail({ error }));
        throw error;
      }
    },
  };
};

export const useClientRequestsSelector = () => useSelector((state) => state.clientRequestsReducer);

export const clientRequestsReducer = requestsSlice.reducer;

export default requestsSlice;
/* eslint-enable no-param-reassign  */
