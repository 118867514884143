import React from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import SampleProjectPresenter from 'presenters/SampleProjectPresenter';

import ProjectTile from 'components/admin/ProjectTile';

import styles from './SampleProjectItem.module.css';

const SampleProjectItem = (props) => {
  const { sampleProject, updatingStatus, onMarkAsSample, onUnmarkAsSample } = props;

  const sampleProjectId = SampleProjectPresenter.id(sampleProject);
  const project = SampleProjectPresenter.project(sampleProject);

  if (isBlank(project)) {
    return (
      <div key={sampleProjectId} className={styles.sampleProjectMissing}>
        No Sample Project
      </div>
    );
  }

  return (
    <div className={styles.project}>
      <ProjectTile
        key={sampleProjectId}
        project={project}
        updatingStatus={updatingStatus}
        onMarkAsSample={onMarkAsSample}
        onUnmarkAsSample={onUnmarkAsSample}
        hasEmptySampleProject={false}
        isSample
      />
    </div>
  );
};

SampleProjectItem.propTypes = {
  sampleProject: SampleProjectPresenter.shape(),
  updatingStatus: PropTypes.shape(),
  onMarkAsSample: PropTypes.func.isRequired,
  onUnmarkAsSample: PropTypes.func.isRequired,
};

export default SampleProjectItem;
