import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Box } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import Button from 'components/Button';

import styles from './AvatarUploadForm.module.css';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const getPixelCrop = (image, percentCrop) => {
  const { naturalWidth, naturalHeight } = image;

  const x = Math.round(naturalWidth * (percentCrop.x / 100));
  const y = Math.round(naturalHeight * (percentCrop.y / 100));
  const width = Math.round(naturalWidth * (percentCrop.width / 100));
  const height = Math.round(naturalHeight * (percentCrop.height / 100));

  return {
    x,
    y,
    // Clamp width and height so rounding doesn't cause the crop to exceed bounds.
    width: clamp(width, 0, naturalWidth - x),
    height: clamp(height, 0, naturalHeight - y),
  };
};

class AvatarUploadForm extends Component {
  static propTypes = {
    aspect: PropTypes.number,
    circularCrop: PropTypes.bool,
    updatedAt: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.dropzoneRef = null;

    this.state = {
      src: null,
      crop: {
        x: 0,
        y: 0,
      },
      aspect: props.aspect,
      pixelCrop: null,
      file: null,
      fileAsBase64: null,
      image: null,
    };
  }

  onImageLoaded = (e) => {
    const { aspect } = this.props;
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget;

    if (aspect) {
      const minSideName = width > height ? 'height' : 'width';
      const crop = makeAspectCrop({ [minSideName]: 80, unit: '%' }, aspect, width, height);
      crop.x = (100 - crop.width) / 2;
      crop.y = (100 - crop.height) / 2;
      const pixelCrop = {
        ...getPixelCrop(e.currentTarget, crop),
        fullWidth: naturalWidth,
        fullHeight: naturalHeight,
      };

      this.setState({ crop, pixelCrop, image: e.currentTarget });
    } else {
      const crop = { x: 10, y: 10, width: 80, height: 80, unit: '%' };
      const pixelCrop = {
        ...getPixelCrop(e.currentTarget, crop),
        fullWidth: naturalWidth,
        fullHeight: naturalHeight,
      };

      this.setState({ crop, pixelCrop, image: e.currentTarget });
    }
  };

  onCropComplete = (crop, pixelCrop) => {
    this.setState({ crop, pixelCrop: getPixelCrop(this.state.image, pixelCrop) });
  };

  onCropChange = (crop, pixelCrop) => {
    this.setState({ crop, pixelCrop: getPixelCrop(this.state.image, pixelCrop) });
  };

  assignDropzoneRef = (node) => {
    this.dropzoneRef = node;
  };

  handleImageBrowse = () => {
    this.dropzoneRef.open();
  };

  handleDrop = (acceptedFiles) => {
    const [file] = acceptedFiles;

    if (isBlank(file)) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = this.handleImageReaded;
    fileReader.readAsDataURL(file);

    this.setState({ ...this.state, file });
  };

  handleImageReaded = (event) => {
    this.setState({ ...this.state, fileAsBase64: event.target.result });
  };

  handleSubmit = () => {
    const { file, pixelCrop } = this.state;

    this.props.onSubmit(file, pixelCrop);
  };

  render() {
    const { updatedAt, circularCrop, errors, saving } = this.props;
    const { fileAsBase64 } = this.state;

    return (
      <div>
        <Box direction="row" justify="center" margin={{ bottom: 'medium' }}>
          {updatedAt && `Last Updated: ${updatedAt}`}
        </Box>
        <Box direction="row" justify="center" margin={{ bottom: 'medium' }}>
          <Dropzone accept={{ 'image/*': ['.jpeg', '.png'] }} onDrop={this.handleDrop} ref={this.assignDropzoneRef}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({ onClick: (evt) => evt.stopPropagation() })}
                className={clsx(styles.dropWrapper, styles.dropzone)}
              >
                <input {...getInputProps()} />
                <div>
                  {fileAsBase64 ? (
                    <ReactCrop
                      crop={this.state.crop}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      keepSelection
                      circularCrop={circularCrop}
                    >
                      <img src={fileAsBase64} onLoad={this.onImageLoaded} alt="" />
                    </ReactCrop>
                  ) : (
                    <span className={styles.text}>
                      Drag and drop <br />
                      new image here
                    </span>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </Box>
        {errors.length > 0 && (
          <Box className={styles.errors} direction="row" justify="center" margin={{ vertical: 'medium' }}>
            {errors.map((error) => (
              <span key={error}> {error} </span>
            ))}
          </Box>
        )}
        <Box direction="row" justify="center" margin={{ vertical: 'medium' }}>
          <Button onClick={this.handleImageBrowse} label="Upload an image" className={styles.upload} />
        </Box>
        <Box direction="row" justify="center">
          <Button label="Save" primary onClick={this.handleSubmit} disabled={saving} />
        </Box>
      </div>
    );
  }
}

export default AvatarUploadForm;
