import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show() {
    const url = Routes.apiV1CurrentUserPath();

    return FetchHelpers.get(url);
  },

  update(currentUser) {
    const url = Routes.apiV1CurrentUserPath();

    return FetchHelpers.put(url, { currentUser });
  },
};
