import React, { Component } from 'react';

import Button from 'components/Button';
import Tooltip from 'rc-tooltip';

import styles from './QuestionMarkButton.module.css';

class QuestionMarkButton extends Component {
  render() {
    return (
      <Tooltip placement="right" overlay="What's New?">
        <Button className={styles.button} {...this.props}>
          ?
        </Button>
      </Tooltip>
    );
  }
}

export default QuestionMarkButton;
