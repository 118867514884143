import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { pick } from 'ramda';

import { withAuthenticator } from 'containers/Authenticator';

import UserEditForm from './components/UserEditForm';
import LimitedAccessCriterias from './components/LimitedAccessCriterias';
import Spinner from 'components/Spinner';

import styles from './UserAccountPage.module.css';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';
import UserPolicy from 'policies/UserPolicy';

const UserAccountPageComponent = (props) => {
  const {
    isCurrentUserReloading,
    currentUser,
    isCurrentUserSaving,
    isPhoneNumberSaving,
    confirmCurrentUserPhoneNumber,
    resendCurrentUserPhoneNumberConfirmation,
    updateCurrentUser,
    updateCurrentUserPhoneNumber,
    reloadCurrentUser,
    history,
  } = props;

  const redirectToSignInIfNeeded = (newCurrentUser) => {
    if (!newCurrentUser) {
      history.push(Routes.signInPath());
    }
  };

  useEffect(() => {
    reloadCurrentUser().then(redirectToSignInIfNeeded);
  }, []);

  if (isCurrentUserReloading || !currentUser) {
    return <Spinner />;
  }

  return (
    <>
      <div className={styles.common}>
        <div className={styles.userName}>{UserPresenter.fullName(currentUser)}</div>
        <div className={styles.list}>
          {UserPresenter.isDownloadLimited(currentUser) && (
            <>
              <div className={styles.counter}>
                <div className={styles.message}>Profile Views Credits Remaining</div>
                <div className={styles.number}>{CurrentUserPresenter.remainingProfileViewsNumber(currentUser)}</div>
              </div>
              <div className={styles.counter}>
                <div className={styles.message}>Profile Download Credits Remaining</div>
                <div className={styles.number}>{CurrentUserPresenter.remainingProfileDownloadsNumber(currentUser)}</div>
              </div>
            </>
          )}
          {UserPolicy.canSeeRemainingRequestsNumber(currentUser) && (
            <div className={styles.counter}>
              <div className={styles.message}>Profile Requests Credits Remaining</div>
              <div className={styles.number}>{CurrentUserPresenter.remainingRequestsNumber(currentUser)}</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.main}>
        <UserEditForm
          currentUser={currentUser}
          isSaving={isCurrentUserSaving}
          isPhoneNumberSaving={isPhoneNumberSaving}
          onUpdate={updateCurrentUser}
          onConfirmPhoneNumber={confirmCurrentUserPhoneNumber}
          onResendPhoneNumberConfirmation={resendCurrentUserPhoneNumberConfirmation}
          onUpdatePhoneNumber={updateCurrentUserPhoneNumber}
        />
        {CurrentUserPresenter.hasLimitedAccess(currentUser) && (
          <div className={styles.limitedAccess}>
            <LimitedAccessCriterias />
          </div>
        )}
      </div>
    </>
  );
};

UserAccountPageComponent.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  confirmCurrentUserPhoneNumber: PropTypes.func.isRequired,
  isCurrentUserReloading: PropTypes.bool.isRequired,
  isCurrentUserSaving: PropTypes.bool.isRequired,
  isPhoneNumberSaving: PropTypes.bool.isRequired,
  reloadCurrentUser: PropTypes.func.isRequired,
  resendCurrentUserPhoneNumberConfirmation: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  updateCurrentUserPhoneNumber: PropTypes.func.isRequired,
};

const UserAccountPage = withAuthenticator(
  UserAccountPageComponent,
  pick([
    'confirmCurrentUserPhoneNumber',
    'reloadCurrentUser',
    'resendCurrentUserPhoneNumberConfirmation',
    'updateCurrentUser',
    'updateCurrentUserPhoneNumber',
  ]),
);

export default UserAccountPage;
