import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show() {
    const url = Routes.apiV1CsrfTokenPath();

    return FetchHelpers.get(url);
  },
};
