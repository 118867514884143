import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomizedLegend.module.css';

const CustomizedLegend = (props) => {
  const { titles } = props;

  return (
    <div className={styles.root}>
      {titles.map(({ text, color }, index) => (
        <div className={styles.item} key={`item-${index}`}>
          <div className={styles.colorMark} style={{ backgroundColor: color }} />
          <span className={styles.text}>{text}</span>
        </div>
      ))}
    </div>
  );
};

CustomizedLegend.propTypes = {
  titles: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
};

export default CustomizedLegend;
