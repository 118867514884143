import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CsvItemsTable from './CsvItemsTable';
import * as actions from './CsvItemsTableActions';

const mapStateToProps = (state) => ({
  csvItems: state.CsvItemsTableReducer.csvItems,
  loading: state.CsvItemsTableReducer.loading,
  totalCount: state.CsvItemsTableReducer.totalCount,
  currentPage: state.CsvItemsTableReducer.currentPage,
  remainingRequestsCount: state.CsvItemsTableReducer.remainingRequestsCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CsvItemsTable);
