import AuthenticatorConstants from './AuthenticatorConstants';
import { PhoneNumberConstants } from './containers/PhoneNumber';
import { SessionConstants } from './containers/Session';
import { CompanyConstants } from './containers/Company';

const initialState = {
  isCurrentUserLoading: true,
  isCurrentUserReloading: false,
  isCurrentUserSaving: false,
  isPhoneNumberSaving: false,
  isCompanyLoading: false,
  currentUser: null,
  company: null,
};

function AuthenticatorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AuthenticatorConstants.LOAD_CURRENT_USER_START: {
      return { ...state, isCurrentUserLoading: true };
    }
    case AuthenticatorConstants.LOAD_CURRENT_USER_SUCCESS: {
      return { ...state, isCurrentUserLoading: false, currentUser: action.user };
    }
    case AuthenticatorConstants.LOAD_CURRENT_USER_ERROR: {
      return { ...state, isCurrentUserLoading: false };
    }
    case AuthenticatorConstants.RELOAD_CURRENT_USER_START: {
      return { ...state, isCurrentUserReloading: true };
    }
    case AuthenticatorConstants.RELOAD_CURRENT_USER_SUCCESS: {
      return { ...state, isCurrentUserReloading: false, currentUser: action.user };
    }
    case AuthenticatorConstants.RELOAD_CURRENT_USER_ERROR: {
      return { ...state, isCurrentUserReloading: false };
    }
    case AuthenticatorConstants.UPDATE_CURRENT_USER_START:
      return { ...state, isCurrentUserSaving: true };
    case AuthenticatorConstants.UPDATE_CURRENT_USER_SUCCESS:
      return { ...state, isCurrentUserSaving: false, currentUser: action.user };
    case AuthenticatorConstants.UPDATE_CURRENT_USER_ERROR:
      return { ...state, isCurrentUserSaving: false };
    case PhoneNumberConstants.UPDATE_CURREN_USER_PHONE_NUMBER_START:
      return { ...state, isPhoneNumberSaving: true };
    case PhoneNumberConstants.UPDATE_CURREN_USER_PHONE_NUMBER_SUCCESS: {
      return { ...state, isPhoneNumberSaving: false };
    }
    case PhoneNumberConstants.UPDATE_CURREN_USER_PHONE_NUMBER_ERROR:
      return { ...state, isPhoneNumberSaving: false };
    case PhoneNumberConstants.CONFIRM_CURRENT_USER_PHONE_NUMBER_START:
      return { ...state, isPhoneNumberSaving: true };
    case PhoneNumberConstants.CONFIRM_CURRENT_USER_PHONE_NUMBER_SUCCESS: {
      const { user } = action;
      const currentUser = { ...state.currentUser, phone: user.phone };

      return { ...state, isPhoneNumberSaving: false, currentUser };
    }
    case PhoneNumberConstants.CONFIRM_CURRENT_USER_PHONE_NUMBER_ERROR:
      return { ...state, isPhoneNumberSaving: false };
    case SessionConstants.SIGN_OUT_START: {
      return { ...state, isCurrentUserLoading: true };
    }
    case SessionConstants.SIGN_OUT_ERROR: {
      return { ...state, isCurrentUserLoading: false };
    }
    case SessionConstants.SIGN_OUT_SUCCESS: {
      return { ...state, isCurrentUserLoading: false, currentUser: null };
    }
    case CompanyConstants.LOAD_COMPANY_START: {
      return { ...state, isCompanyLoading: true };
    }
    case CompanyConstants.LOAD_COMPANY_SUCCESS: {
      return { ...state, isCompanyLoading: false, company: action.company };
    }
    case CompanyConstants.LOAD_COMPANY_ERROR: {
      return { ...state, isCompanyLoading: false };
    }
    default: {
      return state;
    }
  }
}

export default AuthenticatorReducer;
