import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(companyId, departmentId) {
    const url = Routes.apiV1CompanyDepartmentPath(companyId, departmentId);

    return FetchHelpers.get(url);
  },
};
