import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, CartesianGrid, YAxis, Cell, LabelList } from 'recharts';
import Routes from 'routes';

import CustomizedXAxisTick from './components/CustomizedXAxisTick';
import CustomizedXAxisCompanyNameTick from './components/CustomizedXAxisCompanyNameTick';
import CustomizedYAxisTick from './components/CustomizedYAxisTick';
import CustomizedTooltip from './components/CustomizedTooltip';
import CustomizedXAxisCompanyDividerTick from './components/CustomizedXAxisCompanyDividerTick';

import styles from './Chart.module.css';

const MAX_BAR_SIZE = 64;
const BAR_PADDING = 30;
const BAR_WIDTH_WITH_PADDING = MAX_BAR_SIZE + BAR_PADDING * 2;
const CHART_HEIGHT = 400;
const BAR_CHART_MARGIN_TOP = 30;
const BAR_CHART_SCROLL_HEIGH = 30;
const BAR_CHART_RIGHT_PADDING = 30;
const LABEL_MARGIN_BOTTOM = 10;

const getFilters = (entry) => {
  const { passDue } = entry;

  if (passDue > 0) return { passDueTrue: true };
  return {};
};

const getColor = (entry, colors) => {
  const { passDue, totalCount } = entry;
  const { inQueue, inQueuePastDueItems, inQueueMoreThan10Requests } = colors;

  if (totalCount > 10) return inQueueMoreThan10Requests;
  if (passDue > 0) return inQueuePastDueItems;
  return inQueue;
};

const Chart = (props) => {
  const { data, parentWidth, colors } = props;
  const [tooltip, setTooltip] = useState({ show: false });
  const chartContainerEl = useRef(null);
  const scrollOffset = chartContainerEl?.current?.scrollLeft;
  const onMouseEnter = (keyName) => (barProps) => {
    setTooltip({ show: true, keyName, ...barProps });
  };

  const handleBarClick = (currentData) => {
    const { requestorId: requestorIdEq } = currentData;
    const baseFilters = getFilters(currentData);
    const filters = { ...baseFilters, requestorIdEq };

    window.open(Routes.adminProfileRequestsPath({ q: filters }));
  };

  const onMouseLeave = (barProps) => {
    setTooltip({ show: false, ...barProps });
  };

  const selfChartWidth = BAR_WIDTH_WITH_PADDING * data.length;
  const chartWidth = selfChartWidth < parentWidth ? parentWidth : selfChartWidth;

  const renderLabel = (labelProps) => {
    const { x, y, width, value } = labelProps;
    const xPosition = x + width / 2;
    const yPosition = y - LABEL_MARGIN_BOTTOM;

    return (
      <g>
        <text x={xPosition} y={yPosition} fill="#444444" textAnchor="middle" dominantBaseline="bottom">
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={styles.container} style={{ height: CHART_HEIGHT + BAR_CHART_SCROLL_HEIGH }}>
      {tooltip.show && <CustomizedTooltip {...tooltip} scrollOffset={scrollOffset} barWidth={MAX_BAR_SIZE} />}
      <div className={styles.chartContainer} ref={chartContainerEl}>
        <BarChart
          data={data}
          height={CHART_HEIGHT}
          width={chartWidth - BAR_CHART_RIGHT_PADDING}
          margin={{ top: BAR_CHART_MARGIN_TOP, right: 0, bottom: 0, left: -35 }}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <YAxis interval={1} tick={<CustomizedYAxisTick />} axisLine={false} tickSize={0} />
          <XAxis
            dataKey="requestorName"
            tick={<CustomizedXAxisTick barWidth={MAX_BAR_SIZE} data={data} />}
            height={60}
            interval={0}
            xAxisId={0}
          />
          <XAxis
            tick={<CustomizedXAxisCompanyNameTick barWidth={MAX_BAR_SIZE} data={data} />}
            interval={0}
            axisLine={false}
            tickLine={false}
            xAxisId={1}
            height={100}
          />
          <XAxis
            tick={
              <CustomizedXAxisCompanyDividerTick
                data={data}
                barWidth={MAX_BAR_SIZE}
                offsetTop={BAR_CHART_MARGIN_TOP}
                height={CHART_HEIGHT}
              />
            }
            axisLine={false}
            tickLine={false}
            interval={0}
            height={1}
            scale="band"
            xAxisId={2}
          />
          <Bar
            dataKey="totalCount"
            maxBarSize={MAX_BAR_SIZE}
            onClick={handleBarClick}
            className={styles.bar}
            onMouseEnter={onMouseEnter('totalCount')}
            onMouseLeave={onMouseLeave}
          >
            {data.map((entry, i) => (
              <Cell key={`cell-${i}`} fill={getColor(entry, colors)} />
            ))}
            <LabelList dataKey="totalCount" content={renderLabel} position="top" />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  parentWidth: PropTypes.number.isRequired,
  colors: PropTypes.shape().isRequired,
};

export default Chart;
