import React, { Component } from 'react';
import { logClickOnUserPanelProfileSocial } from 'utils/amplitude';

import { Anchor } from 'grommet';
import Icon from 'components/Icon';

import styles from './SocialNetworks.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';

export function isAnySocialNetworksPresent(profile) {
  return !!(
    ProfilePresenter.facebookUrl(profile) ||
    ProfilePresenter.linkedinUrl(profile) ||
    ProfilePresenter.twitterUrl(profile) ||
    ProfilePresenter.blogUrl(profile)
  );
}

class SocialNetworks extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape().isRequired,
  };

  handleLogSocialClick = (social) => () => logClickOnUserPanelProfileSocial(social);

  render() {
    const { profile } = this.props;
    const facebookUrl = ProfilePresenter.facebookUrl(profile);
    const linkedinUrl = ProfilePresenter.linkedinUrl(profile);
    const twitterUrl = ProfilePresenter.twitterUrl(profile);
    const blogUrl = ProfilePresenter.blogUrl(profile);

    return (
      <>
        <h5>Social Networks</h5>

        {facebookUrl && (
          <Anchor
            icon={<Icon name="facebook" />}
            animateIcon={false}
            className={styles.socialLink}
            href={facebookUrl}
            target="_blank"
            onClick={this.handleLogSocialClick('FACEBOOK')}
          />
        )}
        {linkedinUrl && (
          <Anchor
            icon={<Icon name="linkedin" />}
            animateIcon={false}
            className={styles.socialLink}
            href={linkedinUrl}
            target="_blank"
            onClick={this.handleLogSocialClick('LINKEDIN')}
          />
        )}
        {twitterUrl && (
          <Anchor
            icon={<Icon name="twitter" />}
            animateIcon={false}
            className={styles.socialLink}
            href={twitterUrl}
            target="_blank"
            onClick={this.handleLogSocialClick('TWITTER')}
          />
        )}
        {blogUrl && (
          <Anchor
            icon={<Icon name="rss" />}
            animateIcon={false}
            className={styles.socialLink}
            href={blogUrl}
            target="_blank"
          />
        )}
      </>
    );
  }
}

export default SocialNetworks;
