import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';

import { Anchor } from 'grommet';
import Button from 'components/Button';
import EditForm from './components/EditForm';
import Icon from 'components/Icon';

import CsvItemPresenter, {
  REQUEST_PROFILE_STATE_EVENT,
  ADD_TO_PROJECT_STATE_EVENT,
  REMOVE_FROM_PROJECT_STATE_EVENT,
} from 'presenters/Project/CsvItemPresenter';

import styles from './CsvItem.module.css';

export default class CsvItem extends Component {
  static propTypes = {
    csvItem: CsvItemPresenter.shape().isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  state = { isRequesting: false };

  handleUpdate = (attributes) => {
    const { csvItem, onUpdate } = this.props;

    this.setState({ ...this.state, isSaving: true });

    return onUpdate({ ...csvItem, ...attributes }).finally(() => this.setState({ ...this.state, isSaving: false }));
  };

  handleRequest = () => this.setState({ ...this.state, isRequesting: true });

  handleRequestCancel = () => this.setState({ ...this.state, isRequesting: false });

  handleRequestSubmit = (attributes) =>
    this.handleUpdate({ ...attributes, stateEvent: REQUEST_PROFILE_STATE_EVENT }).then(() =>
      this.setState({ ...this.state, isRequesting: false }),
    );

  handleAddToProject = () => this.handleUpdate({ stateEvent: ADD_TO_PROJECT_STATE_EVENT });

  handleRemoveFromProject = () => this.handleUpdate({ stateEvent: REMOVE_FROM_PROJECT_STATE_EVENT });

  render() {
    const { isRequesting, isSaving } = this.state;
    const { csvItem } = this.props;

    const trStyles = cn({ fieldEditing: isRequesting });
    const statusClass = cn(styles.status, {
      [styles.pending]: CsvItemPresenter.isPending(csvItem),
      [styles.delivered]: CsvItemPresenter.isInProject(csvItem),
      [styles.notRequested]: CsvItemPresenter.isNotRequested(csvItem),
      [styles.canceled]: CsvItemPresenter.isCanceled(csvItem),
      [styles.notIncluded]: CsvItemPresenter.isNotInProject(csvItem),
    });

    return (
      <>
        <tr className={trStyles}>
          <td className={statusClass} />
          <td>{CsvItemPresenter.firstName(csvItem)}</td>
          <td>{CsvItemPresenter.lastName(csvItem)}</td>
          <td>{CsvItemPresenter.jobTitle(csvItem)}</td>
          <td>{CsvItemPresenter.organizationName(csvItem)}</td>
          <td>
            {CsvItemPresenter.canAttachToProject(csvItem) && (
              <Anchor
                className={cn([styles.anchor, styles.addToProject])}
                disabled={isSaving}
                icon={<Icon name="person" />}
                label="Add To Project"
                onClick={this.handleAddToProject}
              />
            )}
            {CsvItemPresenter.canRemoveFromProject(csvItem) && (
              <Anchor
                className={cn([styles.anchor, styles.removeFromProject])}
                icon={<Icon name="person" />}
                disabled={isSaving}
                label="Remove From Project"
                onClick={this.handleRemoveFromProject}
              />
            )}
          </td>
          <td>
            {CsvItemPresenter.canRequest(csvItem) && (
              <Button label="Request a Profile" onClick={this.handleRequest} className={styles.edit} />
            )}
          </td>
        </tr>
        {isRequesting && (
          <tr>
            <td colSpan={7} className={styles.editCell}>
              <EditForm
                csvItem={csvItem}
                onSubmit={this.handleRequestSubmit}
                onCancel={this.handleRequestCancel}
                isSaving={isSaving}
              />
            </td>
          </tr>
        )}
      </>
    );
  }
}
