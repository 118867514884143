import React, { Component } from 'react';

import { Anchor, Box } from 'grommet';
import styles from './EventRow.module.css';

import TREventPresenter from 'presenters/TREventPresenter';

import Routes from 'routes';

class EventRow extends Component {
  static propTypes = {
    event: TREventPresenter.shape(),
  };

  render() {
    const { event } = this.props;
    const documentId = TREventPresenter.documentId(event);
    const path = documentId ? Routes.pdfV1AdminThomsonReutersDocumentPath(documentId) : null;

    return (
      <Box className={styles.eventRow} margin={{ bottom: 'small' }}>
        <Anchor
          className={styles.document}
          disabled={!documentId}
          label={TREventPresenter.name(event)}
          href={path}
          target="_blank"
        />
      </Box>
    );
  }
}

export default EventRow;
