import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { View as ViewIcon } from 'grommet-icons';

import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPolicy from 'policies/UserPolicy';

class UserRowCells extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    row: UserPresenter.shape(),
    onImpersonate: PropTypes.func.isRequired,
  };

  handleImpersonate = (e) => {
    e.preventDefault();

    const { row: user, onImpersonate } = this.props;

    onImpersonate(user.id);
  };

  stopPropagation = (event) => event.stopPropagation();

  render() {
    const { currentUser, row: user } = this.props;
    const company = UserPresenter.company(user);

    return (
      <>
        <td>{UserPresenter.id(user)}</td>
        <td title={UserPresenter.email(user)}>{UserPresenter.email(user)}</td>
        <td>{UserPresenter.fullName(user)}</td>
        <td>{company && CompanyPresenter.name(company)}</td>
        <td>{UserPresenter.roleTitle(user)}</td>
        <td>{UserPresenter.isActiveSubscription(user) ? 'Active' : 'Disabled'}</td>
        <td>{UserPresenter.stateName(user)}</td>
        <td>{UserPresenter.formattedCreatedAt(user)}</td>
        {/* eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role */}
        <td onClick={this.stopPropagation} role="presentation">
          {UserPolicy.canImpersonate(currentUser) && (
            <Button icon={<ViewIcon />} onClick={this.handleImpersonate} href="#" title="Impersonate this user" plain />
          )}
        </td>
      </>
    );
  }
}

export default UserRowCells;
