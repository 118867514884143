import React, { useState } from 'react';
import cn from 'clsx';

import { fromISOtoFormattedDate } from 'utils/DateTime';

import { Edit as EditIcon, Trash as TrashIcon } from 'grommet-icons';
import Button from 'components/Button';

import EditorWorkPresenter from 'presenters/EditorWorkPresenter';

import styles from './EditorWork.module.css';

const DATE_FORMAT = 'MM/dd/yyyy hh:mma';

const EditorWork = (props) => {
  const { editorWork, onUpdate, onDestroy, isDestroying } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleHide = () => setIsCollapsed(true);

  const handleShow = () => setIsCollapsed(false);

  const handleDestroy = () => onDestroy(EditorWorkPresenter.id(editorWork));

  const handleUpdate = () => onUpdate(editorWork);

  const descriptionStyles = cn(styles.description, { [styles.collapsedDescription]: isCollapsed });

  const formattedDate = fromISOtoFormattedDate(EditorWorkPresenter.createdAt(editorWork), DATE_FORMAT);

  return (
    <>
      <div className={styles.infoBlock}>
        <div>{formattedDate}</div>
        {EditorWorkPresenter.isNew(editorWork) && <div className={styles.isNewLabel}>New</div>}
      </div>
      <div className={styles.container}>
        <div className={styles.contentAndActions}>
          <div>
            <div>{EditorWorkPresenter.taskTypeTitle(editorWork)}</div>
            <div>{EditorWorkPresenter.hours(editorWork)}</div>
            <div className={descriptionStyles}>{EditorWorkPresenter.description(editorWork)}</div>
          </div>
          {EditorWorkPresenter.canEdit(editorWork) && (
            <div className={styles.editActions}>
              <Button
                icon={<EditIcon />}
                className={styles.editActionButton}
                onClick={handleUpdate}
                disabled={isDestroying}
                plain
              />
              <Button
                icon={<TrashIcon />}
                className={styles.editActionButton}
                onClick={handleDestroy}
                disabled={isDestroying}
                plain
              />
            </div>
          )}
        </div>
        <div className={styles.collapseControl}>
          {isCollapsed ? (
            <button className={styles.collapseButton} onClick={handleShow}>
              Show
            </button>
          ) : (
            <button className={styles.collapseButton} onClick={handleHide}>
              Hide
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default EditorWork;
