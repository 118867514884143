import config from 'config';
import { buildUrl } from 'utils/UrlHelper';
import { isPresent } from 'utils/HelperMethods';

import Projects from './Projects';
import Requests from './Requests';

const dashboardPath = () => '/dashboard';

export default {
  withOrigin(path, ...args) {
    return `${config.host}${this[path](...args)}`;
  },
  companySubscriptionExpiredErrorPath: () => '/company-subscription-expired',

  corporateAccountPath: () => '/corporate-account',
  corporateAccountDashboardPath: () => '/corporate-account/dashboard',
  corporateAccountAccountUsersPath: () => '/corporate-account/account-users',

  dashboardPath,
  downloadPath: () => '/download',
  errorPath: (code) => `/${code}-error`,
  wafAccessDenied: () => `/waf-access-denied`,
  logOutPath: () => config.logOutPath,
  logoUrl: dashboardPath,
  newCompanyDepartmentUserPath: (companyId, departmentId) =>
    `/companies/${companyId}/departments/${departmentId}/users/new`,
  newCompanyUserPath: (companyId) => `/companies/${companyId}/users/new`,
  newPasswordPath: () => '/new-password',
  notAnActiveUserErrorPath: () => '/not-an-active-user',
  profilePath: (slug, query) => {
    const path = `/profiles/${slug}`;
    return isPresent(query) ? buildUrl(path, query) : path;
  },
  profileRelationshipsMappingPath: (profileSlug) => `/profiles/${profileSlug}/relationship-mapping`,
  profileRelationshipsMappingRelationshipPath: (profileSlug, anotherProfileSlug) =>
    `/profiles/${profileSlug}/relationship-mapping/relationships/${anotherProfileSlug}`,
  resetPasswordPath: () => '/reset-password',
  runOutOfCreditsPath: (type) => `/credits/${type}`,
  salesforceErrorPath: () => '/salesforce-error',
  sampleProfilePath: (slug) => `/sample-profiles/${slug}`,
  sampleProfilesPath: () => '/sample-profiles',
  savedSearchPath: (id, query) => {
    const path = `/saved-searches/${id}`;
    return isPresent(query) ? buildUrl(path, query) : path;
  },
  savedSearchesPath: () => '/saved-searches',
  searchPath: (query) => buildUrl('/search', query),
  signInPath: (query = {}) => {
    const path = '/signin';
    return isPresent(query) ? buildUrl(path, query) : path;
  },
  singleSignOnErrorPath: () => '/single-sign-on-error',
  skinnyProfilePath: (slug) => `/skinny-profiles/${slug}`,
  ssoSignInPath: (slug) => `/signin/${slug}`,
  userAccountPath: () => '/my-account',
  userAlertsPath: () => '/alerts',

  ...Projects,
  ...Requests,
};
