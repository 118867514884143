import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from 'containers/UserPanel/containers/Project/components/Header';
import InputField from './components/InputField';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import styles from './EditableHeader.module.css';

export default class EditableHeader extends Component {
  static propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, to: PropTypes.string })),
    currentUser: CurrentUserPresenter.shape().isRequired,
    isEditing: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  renderEditField() {
    const { isSaving, onCancel, onUpdate, title } = this.props;

    return <InputField isSaving={isSaving} onCancel={onCancel} onUpdate={onUpdate} title={title} />;
  }

  renderTitle() {
    const { title } = this.props;

    return (
      <div className={styles.editableHeader}>
        <div className={styles.header}>{title}</div>
      </div>
    );
  }

  render() {
    const { breadcrumbs, currentUser, children, isEditing, project } = this.props;
    const content = isEditing ? this.renderEditField() : this.renderTitle();

    return (
      <Header currentUser={currentUser} title={content} breadcrumbs={breadcrumbs} project={project}>
        {children}
      </Header>
    );
  }
}
