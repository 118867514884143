import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewOrganizationModal from './NewOrganizationModal';
import * as actions from './NewOrganizationModalActions';

const mapStateToProps = (state) => ({
  saving: state.NewOrganizationModalReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewOrganizationModal);
