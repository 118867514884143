import keyMirror from 'keymirror';

const OrganizationListConstants = keyMirror({
  LOAD_ORGANIZATIONS_START: null,
  LOAD_ORGANIZATIONS_SUCCESS: null,
  LOAD_ORGANIZATIONS_ERROR: null,

  FILTER_ORGANIZATIONS_START: null,
  FILTER_ORGANIZATIONS_SUCCESS: null,
  FILTER_ORGANIZATIONS_ERROR: null,

  BULK_DESTROY_ORGANIZATIONS_SUCCESS: null,
});

export default OrganizationListConstants;
