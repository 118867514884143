import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { equals, find, not, isNil, reject, pipe } from 'ramda';
import { Box, TextInput, CheckBox, Heading } from 'grommet';

import { reactSelectDebounce } from 'utils/HelperMethods';
import { LUXON_MONTH_YEAR_FORMAT } from 'utils/DateTime';

import { Field, DatePicker } from 'components/v2/Form';
import Button from 'components/Button';
import Card from 'components/Card';
import AsyncSelect from 'components/AsyncSelect';
import Modal from 'components/Modal';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import OrganizationSelector from 'containers/AdminPanel/containers/OrganizationSelector';

import WorkPresenter from 'presenters/WorkPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import StandardizedJobTitlePresenter from 'presenters/StandardizedJobTitlePresenter';

import ProfileWorkForm from 'forms/profile/WorkForm';

import styles from './WorkForm.module.css';

export default class WorkForm extends Component {
  constructor(props) {
    super(props);

    const { work } = props;

    this.state = {
      errors: {},
      fields: ProfileWorkForm.defaultAttributes(work),
    };
  }

  handleDOMChange = (fieldName) => (event) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: event.target.value } });
  };

  handleDateChange = (fieldName) => (value) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: value } });
  };

  handleToPresentTimeChange = ({ target }) => {
    this.setState({ fields: { ...this.state.fields, toPresentTime: target.checked, to: '' } });
  };

  handleSubmit = () => {
    const params = ProfileWorkForm.attributesToSubmit(this.state.fields);

    this.props.onSubmit(params).catch(this.handleErrors);
  };

  handleErrors = ({ errors = {} }) => {
    this.setState({ errors });
  };

  handleJobFunctionChange =
    (jobFunctionId) =>
    ({ target }) => {
      const {
        fields: { jobFunctionIds },
      } = this.state;
      const newJobFunctionIds = target.checked
        ? [...jobFunctionIds, jobFunctionId]
        : reject(equals(jobFunctionId), jobFunctionIds);

      this.setState({ fields: { ...this.state.fields, jobFunctionIds: newJobFunctionIds } });
    };

  handleOrganizationSelect = (organization) => {
    this.setState({ fields: { ...this.state.fields, organization } });
  };

  handleStandardizedJobTitleChange = (standardizedJobTitle) => {
    this.setState({ fields: { ...this.state.fields, standardizedJobTitle } });
  };

  handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      this.setState({ fields: { ...this.state.fields, [fieldName]: checked } });

  isJobFunctionSelected(jobFunction) {
    return pipe(find(equals(jobFunction.id)), isNil, not)(this.state.fields.jobFunctionIds);
  }

  organizationValue() {
    const {
      fields: { organizationId, organizationName },
    } = this.state;

    return { value: `${organizationId}`, label: organizationName };
  }

  errorFor(fieldName) {
    const {
      errors: { [fieldName]: error },
    } = this.state;

    return error && error.join(', ');
  }

  handleStandardizedJobTitleSearch = reactSelectDebounce(this.props.onStandardizedJobTitleSearch);

  renderDateTimeField(field, label) {
    const {
      fields: { [field]: value },
    } = this.state;

    return (
      <div className={styles.dateRow}>
        <div className={styles.dateLabel}>{label}</div>
        <div className={styles.dateInput}>
          <Field error={this.errorFor(field)}>
            <DatePicker
              value={value}
              onChange={this.handleDateChange(field)}
              showMonthYearPicker
              dateFormat={LUXON_MONTH_YEAR_FORMAT}
            />
          </Field>
        </div>
      </div>
    );
  }

  render() {
    const { fields } = this.state;
    const { saving, title, jobFunctions } = this.props;

    return (
      <Modal
        onClose={this.props.onCancel}
        header={title}
        margin="medium"
        size="medium"
        disableOverlayClose
        disableCloseByEsc
      >
        <Box margin={{ bottom: 'medium' }}>
          <Box margin={{ bottom: 'small' }} className={styles.title}>
            <OrganizationSelector
              errors={this.errorFor('organization')}
              placeholder="Organization"
              value={fields.organization}
              onChange={this.handleOrganizationSelect}
            />
          </Box>
          <Box margin={{ bottom: 'small' }}>
            <VerticalFormFieldWithBottomErrors error={this.errorFor('jobTitle')} label="Title" htmlFor="jobTitle">
              <TextInput value={fields.jobTitle} onChange={this.handleDOMChange('jobTitle')} id="jobTitle" />
            </VerticalFormFieldWithBottomErrors>
          </Box>
          <Box margin={{ bottom: 'small' }} className={styles.title}>
            <AsyncSelect
              errors={this.errorFor('standardizedJobTitle')}
              placeholder="Standardized Title"
              loadOptions={this.handleStandardizedJobTitleSearch}
              defaultOptions
              isClearable
              value={fields.standardizedJobTitle}
              onChange={this.handleStandardizedJobTitleChange}
              getOptionValue={StandardizedJobTitlePresenter.id}
              getOptionLabel={StandardizedJobTitlePresenter.jobTitle}
            />
          </Box>
          <Box margin={{ bottom: 'small' }}>
            Date
            {this.renderDateTimeField('from', 'From')}
            {not(fields.toPresentTime) && this.renderDateTimeField('to', 'To')}
            <div className={styles.dateRow}>
              <div className={styles.dateLabel} />
              <div className={styles.dateInput}>
                <CheckBox label="To Present" checked={fields.toPresentTime} onChange={this.handleToPresentTimeChange} />
              </div>
            </div>
          </Box>
          <VerticalFormFieldWithBottomErrors checkbox>
            <CheckBox label="Retired" toggle checked={fields.retired} onChange={this.handleCheckboxChange('retired')} />
          </VerticalFormFieldWithBottomErrors>
          <Box margin={{ bottom: 'small' }}>
            <Card>
              <Heading weight="bold" level="4" margin={{ bottom: 'small', top: 'none' }}>
                Job Functions
              </Heading>
              {jobFunctions.map((jobFunction) => (
                <Box key={jobFunction.id} margin={{ bottom: 'small' }}>
                  <CheckBox
                    label={JobFunctionPresenter.name(jobFunction)}
                    checked={this.isJobFunctionSelected(jobFunction)}
                    onChange={this.handleJobFunctionChange(jobFunction.id)}
                  />
                </Box>
              ))}
            </Card>
          </Box>
        </Box>

        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}

WorkForm.propTypes = {
  jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()),
  title: PropTypes.string.isRequired,
  work: WorkPresenter.shape(),
  saving: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStandardizedJobTitleSearch: PropTypes.func.isRequired,
};
