/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import EditorNoteRepository from 'repositories/admin/Organization/EditorNoteRepository';

const initialState = {
  isSaving: false,
  isDestroying: false,
  isLoading: false,
  editorNotes: [],
  meta: {
    nextPage: 1,
  },
};

const adminOrganizationEditorNotes = createSlice({
  name: 'adminOrganizationEditorNotes',
  initialState,
  reducers: {
    createEditorNoteStart(state) {
      state.isSaving = true;
    },
    createEditorNoteSuccess(state) {
      state.isSaving = false;
    },
    createEditorNoteError(state) {
      state.isSaving = false;
    },
    updateEditorNoteStart(state) {
      state.isSaving = true;
    },
    updateEditorNoteSuccess(state) {
      state.isSaving = false;
    },
    updateEditorNoteError(state) {
      state.isSaving = false;
    },
    destroyEditorNoteStart(state) {
      state.isDestroying = true;
    },
    destroyEditorNoteSuccess(state) {
      state.isDestroying = false;
    },
    destroyEditorNoteError(state) {
      state.isDestroying = false;
    },
    loadEditorNoteStart(state) {
      state.isLoading = true;
    },
    loadEditorNoteSuccess(state, { payload }) {
      const { editorNotes, meta } = payload;

      state.isLoading = false;
      state.editorNotes = [...state.editorNotes, ...editorNotes];
      state.meta = meta;
    },
    loadEditorNoteError(state) {
      state.isLoading = false;
    },
    filterEditorNoteStart(state) {
      state.isLoading = true;
    },
    filterEditorNoteSuccess(state, { payload }) {
      const { editorNotes, meta } = payload;

      state.isLoading = false;
      state.editorNotes = editorNotes;
      state.meta = meta;
    },
    filterEditorNoteError(state) {
      state.isLoading = false;
    },
  },
});

const { actions } = adminOrganizationEditorNotes;

function filterEditorNotes(organizationId, { per, filters }) {
  return (dispatch) => {
    dispatch(actions.filterEditorNoteStart());

    const query = buildFilterParams(filters);

    return EditorNoteRepository.index(organizationId, { ...query, per })
      .then(({ editorNotes, meta }) => {
        dispatch(actions.filterEditorNoteSuccess({ editorNotes, meta }));
      })
      .catch((errors) => {
        dispatch(actions.filterEditorNoteError());

        throw errors;
      });
  };
}

function loadEditorNotes(organizationId, { page, per, filters }) {
  return (dispatch) => {
    dispatch(actions.loadEditorNoteStart());

    const query = buildFilterParams(filters);

    return EditorNoteRepository.index(organizationId, { ...query, page, per })
      .then(({ editorNotes, meta }) => {
        dispatch(actions.loadEditorNoteSuccess({ editorNotes, meta }));
      })
      .catch((errors) => {
        dispatch(actions.loadEditorNoteError());

        throw errors;
      });
  };
}

function createEditorNote(organizationId, params) {
  return (dispatch) => {
    dispatch(actions.createEditorNoteStart());

    return EditorNoteRepository.create(organizationId, params)
      .then(({ editorNote }) => {
        dispatch(actions.createEditorNoteSuccess());

        return editorNote;
      })
      .catch((errors) => {
        dispatch(actions.createEditorNoteError());

        throw errors;
      });
  };
}

function updateEditorNote(organizationId, editorNoteId, params) {
  return (dispatch) => {
    dispatch(actions.updateEditorNoteStart());

    return EditorNoteRepository.update(organizationId, editorNoteId, params)
      .then(({ editorNote }) => {
        dispatch(actions.updateEditorNoteSuccess());

        return editorNote;
      })
      .catch((errors) => {
        dispatch(actions.updateEditorNoteError());

        throw errors;
      });
  };
}

function destroyEditorNote(organizationId, editorNoteId) {
  return (dispatch) => {
    dispatch(actions.destroyEditorNoteStart());

    return EditorNoteRepository.destroy(organizationId, editorNoteId)
      .then(() => {
        dispatch(actions.destroyEditorNoteSuccess());

        return editorNoteId;
      })
      .catch((errors) => {
        dispatch(actions.destroyEditorNoteError());

        throw errors;
      });
  };
}

export const useAdminOrganizationEditorNotesActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      createEditorNote,
      updateEditorNote,
      destroyEditorNote,
      loadEditorNotes,
      filterEditorNotes,
    },
    dispatch,
  );
};

export default adminOrganizationEditorNotes.reducer;
