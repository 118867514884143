import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { compose } from 'ramda';

import config from 'config';

import { debounce } from 'utils/HelperMethods';
import { logSearchUserPanelQuickSearch } from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';
import Icon from 'components/Icon';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import Option from './components/Option';
import OrganizationOption from './components/OrganizationOption';

import Routes from 'routes';
import styles from './QuickSearch.module.css';

class QuickSearch extends Component {
  state = {
    query: '',
    isShowProfiles: false,
  };

  logSearchUserPanelQuickSearchDebounced = debounce(logSearchUserPanelQuickSearch, 5000);

  componentDidMount() {
    this.props.initialize();
  }

  search = (query) => {
    const { search } = this.props;

    this.logSearchUserPanelQuickSearchDebounced();

    search(query).then(() => {
      this.setState({ isShowProfiles: true });
    });
  };

  loadProfilesDebounced = debounce(this.search, 150);

  hideMenu = () => {
    this.setState({ query: '', isShowProfiles: false });
  };

  handleChangeSearch = (e) => {
    const query = e.target.value.substring(0, config.search.textValueMaxLength);

    this.setState({ query });
    this.loadProfilesDebounced(query);
  };

  handleClickOutside = () => {
    this.hideMenu();
  };

  handleItemSelect = () => {
    this.hideMenu();
    this.props.onSelect();
    this.props.initialize();
  };

  render() {
    const { profiles, organization } = this.props;
    const { query, isShowProfiles } = this.state;

    return (
      <div className={styles.search}>
        <div className={styles.searchPlace}>
          <input
            onChange={this.handleChangeSearch}
            value={query}
            autoComplete="off"
            placeholder="Quick Search"
            className={styles.input}
            maxLength={config.search.textValueMaxLength}
          />
          <Icon name="search" />
        </div>

        {isShowProfiles && (
          <div className={styles.suggestContainer}>
            <div className={styles.suggest}>
              {organization && <OrganizationOption organization={organization} onClick={this.handleItemSelect} />}
              {profiles &&
                profiles.map((profile) => (
                  <Option key={ProfilePresenter.id(profile)} profile={profile} onClick={this.handleItemSelect} />
                ))}

              {profiles && profiles.length > 0 && (
                <div className={styles.linkToAllProfiles}>
                  <AnchorLink
                    to={Routes.searchPath({ fullName: [query] })}
                    className={styles.results}
                    label={`See all profiles with name "${query}"`}
                  />
                </div>
              )}

              {profiles && profiles.length === 0 && <span className={styles.results}>No profiles found</span>}
            </div>
          </div>
        )}
      </div>
    );
  }
}

QuickSearch.propTypes = {
  profiles: PropTypes.arrayOf(ProfilePresenter.shape()),
  organization: OrganizationPresenter.shape(),
  search: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

export default compose(onClickOutside)(QuickSearch);
