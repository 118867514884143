import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Edit as EditIcon, Trash as TrashIcon } from 'grommet-icons';

import { logClickOnProjectPageEditList, logClickOnProjectPageDeleteList } from 'utils/amplitude';

import Routes from 'routes';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Statistics from './components/Statistics';

import styles from './Item.module.css';

import SourcePresenter from 'presenters/Project/SourcePresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

export default class Item extends Component {
  static propTypes = {
    source: SourcePresenter.shape().isRequired,
    saving: PropTypes.bool.isRequired,
    onDestroy: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  handleDestroy = () => {
    const { onDestroy, source } = this.props;

    logClickOnProjectPageDeleteList();
    return onDestroy(source);
  };

  sourcePath = () => {
    const { project, source } = this.props;

    if (SourcePresenter.isTargetSource(source)) {
      return Routes.editProjectTargetingSourcePath(ProjectPresenter.id(project), SourcePresenter.id(source));
    }

    if (SourcePresenter.isCSVSource(source)) {
      return Routes.editProjectCsvSourcePath(ProjectPresenter.id(project), SourcePresenter.id(source));
    }

    return null;
  };

  renderSourceIcon() {
    const { source } = this.props;

    if (SourcePresenter.isDatabaseSearchKind(source)) {
      return <Icon name="target" />;
    }

    if (SourcePresenter.isExecutivesListKind(source)) {
      return <Icon name="profiles" />;
    }

    if (SourcePresenter.isOrganizationsListKind(source)) {
      return <Icon name="company" />;
    }

    return null;
  }

  render() {
    const { project, saving, source } = this.props;
    const name = SourcePresenter.name(source);

    return (
      <div className={styles.item}>
        <div className={styles.icon}>
          <div className={styles.icon}>{this.renderSourceIcon()}</div>
        </div>
        <div className={styles.general}>
          <div className={styles.info}>
            <div className={styles.header}>
              <div className={styles.name} title={name}>
                {name}
              </div>
              <div className={styles.description}>
                {!SourcePresenter.isCSVSource(source) && (
                  <>
                    Profiles count: {SourcePresenter.profilesCount(source)}. <br />
                  </>
                )}
                {`Created at ${SourcePresenter.formattedCreatedAt(source)}`}
              </div>
            </div>
            <div className={styles.actions}>
              {ProjectPolicy.canEditSource(project, source) && (
                <Button
                  href={this.sourcePath()}
                  disabled={saving}
                  icon={<EditIcon />}
                  className={styles.actionButton}
                  onClick={logClickOnProjectPageEditList}
                  plain
                />
              )}
              {ProjectPolicy.canDestroySource(project, source) && (
                <Button
                  disabled={saving}
                  icon={<TrashIcon />}
                  onClick={this.handleDestroy}
                  className={styles.actionButton}
                  plain
                />
              )}
            </div>
          </div>
          {SourcePresenter.isCSVSource(source) && (
            <div className={styles.description}>
              <Statistics source={source} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
