import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import SingleSignOnPresenter, { STATE_EVENTS } from 'presenters/SingleSignOnPresenter';

import { Field, Input, Switch } from 'components/v2/Form';
import Typography from 'components/v2/Typography';

import styles from './GeneralSettingsFields.module.css';

const GeneralSettingsFields = (props) => {
  const { errors, initialSsoValues, onFieldChange, ssoValues } = props;

  const isInitiallyEnabled = SingleSignOnPresenter.isEnabled(initialSsoValues);
  const isActivateStateEvent = ssoValues.stateEvent === STATE_EVENTS.ENABLE;
  const isEnabled = isNil(ssoValues.stateEvent) ? isInitiallyEnabled : isActivateStateEvent;

  const handleStateEventChange = (value) => {
    let stateEvent = null;

    if (isInitiallyEnabled !== value) {
      stateEvent = value ? STATE_EVENTS.ENABLE : STATE_EVENTS.DISABLE;
    }

    onFieldChange('stateEvent', stateEvent);
  };

  return (
    <div className={styles.fields}>
      <Typography variant="h3">General Settings</Typography>
      <Field title="Enable SSO" error={errors.state} variant="outlined">
        <Switch checked={isEnabled} onChange={handleStateEventChange} />
      </Field>
      <Field title="Company Sign On URL:" error={errors.name} variant="outlined">
        <Input value={ssoValues.ssoUrl} disabled />
      </Field>
    </div>
  );
};

GeneralSettingsFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  initialSsoValues: SingleSignOnPresenter.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  ssoValues: SingleSignOnPresenter.shape().isRequired,
};

export default GeneralSettingsFields;
