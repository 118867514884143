import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Select from 'components/Select';
import { Field, DatePicker } from 'components/v2/Form';

import { ACCOUNT_TYPES } from 'presenters/Company/DepartmentPresenter';

import styles from './Form.module.css';

const ACCOUNT_TYPE_OPTIONS = ACCOUNT_TYPES.map((type) => ({ label: type.title, value: type.key }));

export default class Form extends Component {
  static propTypes = {
    form: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      accountType: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { form } = this.props;
    this.state = { form };
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(form);
  };

  handleFieldChange = (fieldName) => (value) => {
    const { form } = this.state;

    this.setState({ form: { ...form, [fieldName]: value } });
  };

  handleChangeAccountType = (value) => this.handleFieldChange('accountType')(value);

  handleDateFieldChange = (fieldName) => (value) => this.handleFieldChange(fieldName)(value);

  renderDateInput(title, fieldName) {
    const {
      form: { [fieldName]: value },
    } = this.state;

    return (
      <div className={styles.horizontalField}>
        <Field title={title}>
          <DatePicker value={value} onChange={this.handleDateFieldChange(fieldName)} />
        </Field>
      </div>
    );
  }

  renderAccountTypeInput() {
    const {
      form: { accountType: value },
    } = this.state;

    return (
      <div className={styles.horizontalField}>
        <div className={styles.label}>Account Type</div>
        <Select
          className={styles.select}
          isClearable
          selectedOptionValue={value}
          onValueChange={this.handleChangeAccountType}
          options={ACCOUNT_TYPE_OPTIONS}
          noBottom
        />
      </div>
    );
  }

  render() {
    return (
      <div className={styles.horizontalForm}>
        {this.renderDateInput('From', 'from')}
        {this.renderDateInput('To', 'to')}
        {this.renderAccountTypeInput()}
        <div className={styles.controls}>
          <Button className={styles.button} label="Submit" onClick={this.handleSubmit} primary />
        </div>
      </div>
    );
  }
}
