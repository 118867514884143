import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RelationshipsList from 'containers/UserPanel/components/RelationshipsList';
import * as actions from './ProfileRelationshipsListActions';

const mapStateToProps = (state) => ({
  loading: state.ProjectProfileRelationshipsListReducer.loading,
  relationships: state.ProjectProfileRelationshipsListReducer.relationships,
  nextPage: state.ProjectProfileRelationshipsListReducer.nextPage,
  totalCount: state.ProjectProfileRelationshipsListReducer.totalCount,
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      initializeRelationships: () =>
        actions.initializeProfileRelationships(props.profileId, props.filters, props.profilesFilters),
      loadRelationships: (params) =>
        actions.loadProfileRelationships(
          props.projectId,
          props.profileId,
          props.filters,
          props.profilesFilters,
          params,
        ),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipsList);
