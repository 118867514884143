import { adminCompanyDepartmentLimitedAccessRestHooks } from 'slices/admin/company/department/limitedAccessSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useLimitedAccess = () => {
  const { limitedAccess, loadLimitedAccess, updateLimitedAccess } = adminCompanyDepartmentLimitedAccessRestHooks.use();

  return {
    limitedAccess: limitedAccess.item,
    limitedAccessLoadingStatus: useFetchStatus(limitedAccess.loadingStatus),
    limitedAccessUpdatingStatus: useFetchStatus(limitedAccess.updatingStatus),
    loadLimitedAccess,
    updateLimitedAccess,
  };
};

export default useLimitedAccess;
