import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import AnchorLink from 'components/grommet/AnchorLink';
import ProfileAvatar from 'components/ProfileAvatar';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import WorkSearchPresenter from 'presenters/WorkSearchPresenter';

import Routes from 'routes';

import styles from './UserCards.module.css';

class UserCardsComponent extends Component {
  static propTypes = {
    createMostViewedRequest: PropTypes.func.isRequired,
    history: PropTypes.shape({}),
    profiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
    saving: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    loadPriorityUpdates: PropTypes.func,
  };

  componentDidMount() {
    this.props.loadPriorityUpdates();
  }

  handleCreateMostViewedRequest = (profile) => () => {
    const { createMostViewedRequest, history } = this.props;
    const profileId = ProfileSearchPresenter.id(profile);

    return createMostViewedRequest(profileId).then(() => history.push(Routes.editAdminProfilePath(profileId)));
  };

  renderUserCurrentWork = (currentWork) => (
    <div key={WorkSearchPresenter.id(currentWork)}>
      <div className={styles.position}>{WorkSearchPresenter.jobTitle(currentWork)}</div>
      <div className={styles.company}>{WorkSearchPresenter.organizationName(currentWork)}</div>
    </div>
  );

  renderUser = (profile) => {
    const { saving } = this.props;
    const currentWorks = ProfileSearchPresenter.currentWorks(profile);
    const id = ProfileSearchPresenter.id(profile);

    return (
      <div className={styles.item} key={id}>
        <AnchorLink to={Routes.editAdminProfilePath(id)} animateIcon={false} className={styles.user}>
          <ProfileAvatar src={ProfileSearchPresenter.photoUrl(profile)} size="60" />
          <div className={styles.info}>
            <div className={styles.name}>{ProfileSearchPresenter.fullName(profile)}</div>
            {currentWorks.map(this.renderUserCurrentWork)}
          </div>
        </AnchorLink>
        <Button
          className={styles.claim}
          disabled={saving}
          onClick={this.handleCreateMostViewedRequest(profile)}
          label="Claim"
        />
      </div>
    );
  };

  render() {
    const { loading, profiles } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return <>{profiles.map(this.renderUser)}</>;
  }
}

const UserCards = withRouter(UserCardsComponent);

export default UserCards;
