import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { equals, map, flatten, pipe, prop, propEq, isNil } from 'ramda';
import { isBlank, isPresent } from 'utils/HelperMethods';

import KanbanBoard from 'components/KanbanBoard';
import Modal from 'components/Modal';
import KanbanHeader from './components/RequestsKanbanHeader';
import KanbanCard from './components/RequestsKanbanCard';
import RequestForm from '../../../RequestForm';
import RequestDetailsPopup from '../../components/RequestDetailsPopup';
import MoveRequestPopups from '../../../../components/MoveRequestPopups';

import styles from './RequestsKanbanBoard.module.css';

import UserPresenter, { ADMIN_ROLES } from 'presenters/UserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';
import RequestPolicy from 'policies/RequestPolicy';
import RequestMoveForm from 'forms/RequestMoveForm';

const SORT_FIELDS = [
  { field: 'complexPriority', value: 'desc' },
  { field: 'createdAt', value: 'desc' },
];

class RequestsKanbanBoard extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()),
    currentUser: UserPresenter.shape(),
    initializeKanbanBoard: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    filterRequests: PropTypes.func.isRequired,
    loadRequests: PropTypes.func.isRequired,
    moveRequest: PropTypes.func.isRequired,
    setDroppableColumns: PropTypes.func.isRequired,
    updateRequest: PropTypes.func.isRequired,
    updateRequestData: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    searchUser: PropTypes.func.isRequired,
  };

  state = { openedRequest: null, moveRequestPopup: null, isEditorWorkModalOpen: false };

  componentDidMount() {
    const { currentUser, initializeKanbanBoard, filters } = this.props;
    const columns = RequestPolicy.allowedStates(currentUser).map((state) => ({
      id: state.name,
      filters: { ...filters, stateEq: state.name, sortFields: SORT_FIELDS },
      nextPage: 1,
    }));

    initializeKanbanBoard(columns);
  }

  componentDidUpdate(prevProps) {
    const { columns, filters, filterRequests } = this.props;

    if (!equals(prevProps.filters, filters)) {
      columns.forEach(({ id, filters: columnFilter }) =>
        filterRequests(id, { ...filters, sortFields: columnFilter.sortFields, stateEq: columnFilter.stateEq }),
      );
    }
  }

  handleLoadMore = (column) => {
    const { loadRequests } = this.props;
    const { loading, id, filters, nextPage } = column;

    if (!loading && !isNil(nextPage)) {
      loadRequests(id, filters, nextPage);
    }
  };

  handleDragStart = ({ draggableId: requestId }) => {
    const { currentUser, setDroppableColumns } = this.props;
    const request = this.request(Number(requestId));
    const droppableColumnIds = RequestPolicy.allowedTransitions(currentUser, request).map(prop('to'));

    setDroppableColumns(droppableColumnIds);
  };

  handleDragEnd = ({ draggableId: requestId, destination, source }) => {
    const { currentUser, setDroppableColumns } = this.props;

    setDroppableColumns([]);

    if (isNil(destination)) {
      return;
    }

    const { droppableId: fromState, index: fromPosition } = source;
    const { droppableId: toState, index: toPosition } = destination;

    if (fromState === toState) {
      return;
    }

    const request = this.request(Number(requestId));
    const transition = RequestPolicy.allowedTransitions(currentUser, request).find(propEq(toState, 'to'));

    if (isNil(transition)) {
      return;
    }

    this.handleRequestMove(request, transition.event, { fromPosition, toPosition });
  };

  handleCardClick = (request) => {
    this.setState({ openedRequest: request });
  };

  handleRequestClose = () => {
    this.setState({ openedRequest: null });
  };

  handleEditorWorkModal = (value) => {
    this.setState({ isEditorWorkModalOpen: value });
  };

  handleRequestUpdate = (request) => {
    const { updateRequest } = this.props;

    return updateRequest(request.id, request);
  };

  handleRequestMove = (request, stateEvent, params = {}) => {
    const { currentUser, moveRequest } = this.props;
    const fromState = request.state;
    const fromPosition = params.fromPosition || this.requestCurrentPosition(request);
    const { to: toState } = RequestPolicy.allowedTransitions(currentUser, request).find(propEq(stateEvent, 'event'));
    const toPosition = params.toPosition || 0;
    const requestForm = RequestMoveForm.defaultAttributes({ ...request, stateEvent }, currentUser);

    moveRequest(request.id, fromState, toState, toPosition);

    if (RequestPolicy.needMovePopup(currentUser, request, stateEvent)) {
      this.setState({
        openedRequest: null,
        moveRequestPopup: {
          request: requestForm,
          from: { state: fromState, position: fromPosition },
          to: { state: toState, position: toPosition },
        },
      });

      return undefined;
    }

    return this.handleRequestUpdate(RequestMoveForm.attributesToSubmit(requestForm)).catch(() => {
      moveRequest(request.id, toState, fromState, fromPosition);
    });
  };

  handleRequestChange = (request) => {
    const { moveRequest, updateRequestData } = this.props;
    const oldRequest = this.request(request.id);

    if (isBlank(oldRequest)) {
      return;
    }

    if (oldRequest.state !== request.state) {
      moveRequest(request.id, oldRequest.state, request.state, 0);
    }

    updateRequestData(request);
  };

  request = (requestId) => {
    const { columns } = this.props;
    const requests = pipe(map(prop('cards')), flatten)(columns);

    return requests.find(propEq(requestId, 'id'));
  };

  requestCurrentPosition = (request) => {
    const { columns } = this.props;
    const { cards } = columns.find(propEq(request.state, 'id'));

    return cards.findIndex(propEq(request.id, 'id'));
  };

  handleMoveRequestPopupClose = () => {
    const { moveRequest } = this.props;
    const {
      moveRequestPopup: { request, to, from },
    } = this.state;

    moveRequest(request.id, to.state, from.state, from.position);
    this.setState({ moveRequestPopup: null });
  };

  handleMoveRequestPopupSubmit = (request) =>
    this.handleRequestUpdate(RequestMoveForm.attributesToSubmit(request)).then(() =>
      this.setState({ moveRequestPopup: null }),
    );

  handleUserSearch = (value) => {
    const { searchUser } = this.props;
    const filter = {
      fullNameCont: value,
      roleIn: ADMIN_ROLES,
    };

    return searchUser(filter);
  };

  render() {
    const { columns, currentUser, saving } = this.props;
    const { openedRequest, moveRequestPopup, isEditorWorkModalOpen } = this.state;
    const canViewEditorWorks = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);

    return (
      <>
        <KanbanBoard
          columns={columns}
          cardComponent={KanbanCard}
          cardClassName={styles.card}
          headerComponent={KanbanHeader}
          onCardClick={this.handleCardClick}
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
          onLoadMore={this.handleLoadMore}
        />
        {isPresent(openedRequest) && (
          <RequestForm
            currentUser={currentUser}
            request={openedRequest}
            onClose={this.handleRequestClose}
            onChange={this.handleRequestChange}
            view={RequestDetailsPopup}
            title={RequestPresenter.title(openedRequest)}
            refreshRequestOnMount
            onOpenEditorWorkModal={() => this.handleEditorWorkModal(true)}
            needEditorWork={canViewEditorWorks}
          />
        )}
        {moveRequestPopup && (
          <MoveRequestPopups
            onClose={this.handleMoveRequestPopupClose}
            onSubmit={this.handleMoveRequestPopupSubmit}
            request={moveRequestPopup.request}
            saving={saving}
            searchUser={this.handleUserSearch}
          />
        )}
        {isEditorWorkModalOpen && (
          <Modal
            header="Have you entered your effort tracking data?"
            onClose={() => this.handleEditorWorkModal(false)}
            size="large"
            overflow
          >
            If not, please fill out the "Editor's Works" form in this profile to get credit for your work.
          </Modal>
        )}
      </>
    );
  }
}

export default RequestsKanbanBoard;
