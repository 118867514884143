import SampleProfilesPageConstants from './SampleProfilesPageConstants';
import SampleProfilesRepository from 'repositories/SampleProfilesRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function loadSampleProfiles(filters = {}) {
  return (dispatch) => {
    dispatch({ type: SampleProfilesPageConstants.LOAD_CUSTOMER_SAMPLE_PROFILES_START });

    const params = buildFilterParams(filters);
    return SampleProfilesRepository.index(params).then(({ items }) => {
      dispatch({ type: SampleProfilesPageConstants.LOAD_CUSTOMER_SAMPLE_PROFILES_SUCCESS, sampleProfiles: items });

      return items;
    });
  };
}
