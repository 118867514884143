import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { equals, prop, propEq, reject, propOr, any } from 'ramda';

import { imagePath } from 'utils/AssetsHelper';
import { isPresent, isBlank } from 'utils/HelperMethods';
import { buildTargetItemCriterias, parseTargetItemCriterias } from 'utils/Project/TargetItemHelper';
import {
  toggleProjectCompaniesSearchIncludeExecutives,
  toggleProjectCompaniesSearchIncludeBoardOfDirectors,
  toggleProjectCompaniesSearchIncludeBoardOfDirectorsFtse100,
} from 'utils/amplitude';

import SourcePresenter from 'presenters/Project/SourcePresenter';
import {
  INCLUSION_KIND,
  EXECUTIVES_SCOPE,
  DIRECTORS_BOARD_SCOPE,
  DIRECTORS_BOARD_FTSE100_SCOPE,
} from 'presenters/Project/TargetItemPresenter';

import TargetItemForm from 'forms/project/TargetItemForm';
import { FILTERS } from 'forms/ProfileFilteredSearchForm';

import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import MatchesList from './components/MatchesList';

import styles from './ProjectMatchedOrganizationsWizardStep.module.css';

const findTargetItem = (targetItems, kind, scope) =>
  targetItems.filter(propEq(kind, 'kind')).find(propEq(scope, 'scope'));

const findOrInitializeTargetItem = (targetItems, kind, scope) => {
  const targetItem = findTargetItem(targetItems, kind, scope);

  return isPresent(targetItem) ? targetItem : TargetItemForm.defaultAttributes({ kind, scope });
};

const scopeCriteriaMapping = {
  [EXECUTIVES_SCOPE]: FILTERS.CURRENT_ORGANIZATION_NAME_FILTER,
  [DIRECTORS_BOARD_SCOPE]: FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FILTER,
  [DIRECTORS_BOARD_FTSE100_SCOPE]: FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FTSE100_FILTER,
};

const updateOrganizationNamesOfTargetItems = (targetItems, organizationNames) =>
  targetItems.map((targetItem) => {
    const criteriaName = scopeCriteriaMapping[targetItem.scope];
    const criterias = buildTargetItemCriterias({ [criteriaName]: organizationNames });

    return { ...targetItem, criterias };
  });

const getCriterias = (targetItems) => {
  const targetItem = findTargetItem(targetItems, INCLUSION_KIND, EXECUTIVES_SCOPE);

  return propOr([], 'criterias')(targetItem);
};

const ProjectMatchedOrganizationsWizardStep = (props) => {
  const {
    source,
    updateSource,
    matchOrganizationForm,
    matchOrganizations,
    matchedCount,
    loading,
    organizations,
    notMatchedCount,
  } = props;

  const { targetItemsAttributes } = source;
  const criterias = getCriterias(targetItemsAttributes);
  const anyIncludedTargetItem = any(prop('included'))(targetItemsAttributes);

  const handleChangeConfirmedOrganizations = (organizationNames) => {
    const targetItems = isPresent(targetItemsAttributes)
      ? targetItemsAttributes
      : [
          findOrInitializeTargetItem(targetItemsAttributes, INCLUSION_KIND, EXECUTIVES_SCOPE),
          findOrInitializeTargetItem(targetItemsAttributes, INCLUSION_KIND, DIRECTORS_BOARD_SCOPE),
          findOrInitializeTargetItem(targetItemsAttributes, INCLUSION_KIND, DIRECTORS_BOARD_FTSE100_SCOPE),
        ];

    return updateSource({
      ...source,
      targetItemsAttributes: updateOrganizationNamesOfTargetItems(targetItems, organizationNames),
    });
  };

  const handleMatchedOrganizations = (newOrganizations) => {
    const names = newOrganizations.map(prop('organization')).filter(isPresent).map(prop('name'));

    handleChangeConfirmedOrganizations(names);
  };

  useEffect(() => {
    const { file, delimiter } = source;
    const form = { file, delimiter };

    if (!equals(form, matchOrganizationForm)) {
      matchOrganizations(form).then(handleMatchedOrganizations);
    }
  }, []);

  const namesOfMatchedOrganizations = propOr([], 'currentOrganizationName')(parseTargetItemCriterias(criterias));

  const subtitle = loading
    ? 'We are looking for companies from your CSV file in our database.'
    : `We found ${matchedCount} companies from your list in our database.`;

  const isIncludedTargetItem = (scope) => {
    const targetItem = findTargetItem(targetItemsAttributes, INCLUSION_KIND, scope);

    return isPresent(targetItem) && targetItem.included;
  };

  const handleTargetItemIncludeChange = (scope) => (checked) => {
    const targetItem = findOrInitializeTargetItem(targetItemsAttributes, INCLUSION_KIND, scope);
    const newTargetItem = { ...targetItem, included: checked };
    const newTargetItems = [...reject(propEq(scope, 'scope'), targetItemsAttributes), newTargetItem];

    if (scope === EXECUTIVES_SCOPE) toggleProjectCompaniesSearchIncludeExecutives(checked);
    if (scope === DIRECTORS_BOARD_SCOPE) toggleProjectCompaniesSearchIncludeBoardOfDirectors(checked);
    if (scope === DIRECTORS_BOARD_FTSE100_SCOPE) toggleProjectCompaniesSearchIncludeBoardOfDirectorsFtse100(checked);

    return updateSource({
      ...source,
      targetItemsAttributes: updateOrganizationNamesOfTargetItems(newTargetItems, namesOfMatchedOrganizations),
    });
  };

  return (
    <>
      <WizardTitle subtitle={subtitle} />
      {loading ? (
        <div className={styles.loader}>
          <img className={styles.loaderImg} src={imagePath('project-match-executives-loader.gif')} alt="loading" />
        </div>
      ) : (
        <MatchesList
          confirmedMatchesNames={namesOfMatchedOrganizations}
          matches={organizations}
          matchedCount={matchedCount}
          notMatchedCount={notMatchedCount}
          onConfirmedMatchesNamesChange={handleChangeConfirmedOrganizations}
          isIncludedExecutives={isIncludedTargetItem(EXECUTIVES_SCOPE)}
          isIncludedDirectorsBoard={isIncludedTargetItem(DIRECTORS_BOARD_SCOPE)}
          isIncludedDirectorsBoardFtse100={isIncludedTargetItem(DIRECTORS_BOARD_FTSE100_SCOPE)}
          onIncludeExecutivesFilterChange={handleTargetItemIncludeChange(EXECUTIVES_SCOPE)}
          onIncludeDirectorsBoardFilterChange={handleTargetItemIncludeChange(DIRECTORS_BOARD_SCOPE)}
          onIncludeDirectorsBoardFtse100FilterChange={handleTargetItemIncludeChange(DIRECTORS_BOARD_FTSE100_SCOPE)}
        />
      )}

      <WizardNavigation
        isNextButtonDisabled={loading || isBlank(namesOfMatchedOrganizations) || !anyIncludedTargetItem}
      />
    </>
  );
};

ProjectMatchedOrganizationsWizardStep.propTypes = {
  source: SourcePresenter.shape().isRequired,
  matchOrganizationForm: PropTypes.shape(),
  matchOrganizations: PropTypes.func.isRequired,
  matchedCount: PropTypes.number,
  notMatchedCount: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSource: PropTypes.func,
};

export default ProjectMatchedOrganizationsWizardStep;
