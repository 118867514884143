import React from 'react';

import UserPresenter from 'presenters/UserPresenter';

import UserAvatar from 'components/UserAvatar';

import styles from './UserItemWithAvatar.module.css';

const UserItemWithAvatar = (props) => {
  const { user } = props;

  return (
    <div className={styles.user}>
      <div className={styles.userContent}>
        <div className={styles.userSelector}>
          <UserAvatar src={UserPresenter.avatarUrl(user)} size={100} />
        </div>
        <div className={styles.userInfo}>
          <div className={styles.userInfoItem}>
            <div className={styles.userInfoItemLabel}>First Name</div>
            <div className={styles.userInfoItemContent}>{UserPresenter.firstName(user)}</div>
          </div>

          <div className={styles.userInfoItem}>
            <div className={styles.userInfoItemLabel}>Last Name</div>
            <div className={styles.userInfoItemContent}>{UserPresenter.lastName(user)}</div>
          </div>

          <div className={styles.userInfoItem}>
            <div className={styles.userInfoItemLabel}>Email</div>
            <div className={styles.userInfoItemContent}>{UserPresenter.email(user)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserItemWithAvatar.propTypes = {
  user: UserPresenter.shape(),
};

export default UserItemWithAvatar;
