import React, { Component } from 'react';
import { logClickOnUserPanelDashboardSavedSearch } from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import styles from './SavedSearch.module.css';

import Routes from 'routes';

class SavedSearch extends Component {
  static propTypes = {
    savedSearch: SavedSearchPresenter.shape().isRequired,
  };

  render() {
    const { savedSearch } = this.props;

    return (
      <AnchorLink
        className={styles.root}
        to={Routes.savedSearchPath(savedSearch.id)}
        onClick={logClickOnUserPanelDashboardSavedSearch}
      >
        {SavedSearchPresenter.isSample(savedSearch) && <div className={styles.sample}>sample</div>}
        <div className={styles.title}>{SavedSearchPresenter.name(savedSearch)}</div>
        <div className={styles.info}>{SavedSearchPresenter.filtersCount(savedSearch)} filters</div>
      </AnchorLink>
    );
  }
}

export default SavedSearch;
