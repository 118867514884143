import React, { useState } from 'react';
import { isBlank, isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Headline from 'components/grommet/Headline';
import Card from 'components/Card';
import Anchor from 'components/Anchor';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'components/InfiniteScroll';
import EditorWork from './components/EditorWork';
import EditorWorkModal from './components/EditorWorkModal';

import EditorWorkPresenter from 'presenters/EditorWorkPresenter';

import styles from './EditorWorks.module.css';

const PERMANENT_SORT_OPTION = { field: 'updatedAt', value: 'desc' };
const PER_PAGE = 2;

const EditorWorks = (props) => {
  const {
    onCreate,
    onUpdate,
    onDestroy,
    onLoad,
    onFilter,
    isSaving,
    isLoading,
    isDestroying,
    editorWorks,
    meta,
    kind,
    isModalOpen,
    onCloseModal,
  } = props;

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEditable, setCurrentEditable] = useState(null);
  const [destroyId, setDestroyId] = useState(null);
  const filters = { sortFields: [PERMANENT_SORT_OPTION] };

  const handleDestroy = (editorWorkId) => {
    setDestroyId(editorWorkId);

    onDestroy(editorWorkId).then(() => {
      setDestroyId(null);
      onFilter({ filters, per: PER_PAGE });
    });
  };

  const handleCreate = (params) =>
    onCreate(params).then(() => {
      onFilter({ filters, per: PER_PAGE });
    });

  const handleNewModalOpen = () => {
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
    onCloseModal();
  };

  const handleEditModalOpen = (editable) => {
    setIsEditModalOpen(true);
    setCurrentEditable(editable);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setCurrentEditable(null);
  };

  const isAllLoaded = () => isBlank(meta.nextPage);

  const handleLoadMore = () => {
    if (!isLoading && !isAllLoaded()) {
      onLoad({ filters, page: meta.nextPage, per: PER_PAGE });
    }
  };

  const handleUpdate = (params) =>
    onUpdate(EditorWorkPresenter.id(currentEditable), params).then(() => {
      onFilter({ filters, per: PER_PAGE });
    });

  const renderSpinner = () => (
    <div className={styles.spinner} key="loader">
      <Spinner />
    </div>
  );

  const scrollContainerStyles = cn(styles.scrollContainer, {
    [styles.filledScrollContainer]: isPresent(editorWorks),
  });

  return (
    <>
      <Card>
        <div className={styles.headLine}>
          <Headline strong size="small" margin="none">
            Editor's Works
          </Headline>
          <Anchor label="+ Add New Work" onClick={handleNewModalOpen} className={styles.add} />
        </div>

        <div className={scrollContainerStyles}>
          <InfiniteScroll
            hasMore={!isAllLoaded()}
            loadMore={handleLoadMore}
            loader={renderSpinner()}
            useWindow={false}
            threshold={5}
          >
            {editorWorks.map((editorWork) => (
              <div key={EditorWorkPresenter.id(editorWork)} className={styles.editorWork}>
                <EditorWork
                  editorWork={editorWork}
                  onUpdate={handleEditModalOpen}
                  onDestroy={handleDestroy}
                  isDestroying={isDestroying && EditorWorkPresenter.id(editorWork) === destroyId}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </Card>

      {(isNewModalOpen || isModalOpen) && (
        <EditorWorkModal
          title="New Work"
          onClose={handleNewModalClose}
          onSubmit={handleCreate}
          saving={isSaving}
          kind={kind}
        />
      )}

      {isEditModalOpen && (
        <EditorWorkModal
          title="Edit Work"
          onClose={handleEditModalClose}
          onSubmit={handleUpdate}
          saving={isSaving}
          kind={kind}
          editorWork={currentEditable}
        />
      )}
    </>
  );
};

export default EditorWorks;
