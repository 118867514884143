import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'react-sortable-hoc';
import { Box, Anchor } from 'grommet';
import { SubtractCircle } from 'grommet-icons';

import { reactSelectDebounce } from 'utils/HelperMethods';

import Icon from 'components/Icon';
import Button from 'components/Button';
import AsyncSelect from 'components/AsyncSelect';
import NewInterestModal from 'containers/AdminPanel/containers/NewInterestModal';
import SortableList, { SortableItem, DragHandle } from 'components/SortableList';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import ProfilePresenter from 'presenters/ProfilePresenter';
import InterestPresenter from 'presenters/InterestPresenter';
import withConfirmation from 'hoc/withConfirmation';
import Routes from 'routes';

import styles from './PersonalInterests.module.css';

import useAdminProfileDraftInterest from 'hooks/admin/profile/useDraftInterest';

const PersonalInterestsComponent = (props) => {
  const { editable, onSortEnd, interests, saving, isChanged, onReviewChanges, profile } = props;

  const { searchInterests, createProfileInterest, destroyProfileInterest } = useAdminProfileDraftInterest();
  const [showCreateInterestPopup, setShowCreateInterestPopup] = useState(false);
  const [currentInterest, setCurrentInterest] = useState(null);
  const [newInterestName, setNewInterestName] = useState('');

  const handleCreateNewInterest = (interestName) => () => {
    setShowCreateInterestPopup(true);
    setNewInterestName(interestName);
  };

  const handleCancelNewInterest = () => {
    setShowCreateInterestPopup(false);
    setNewInterestName('');
  };

  const handleInterestSelect = (newCurrentInterest) => setCurrentInterest(newCurrentInterest);

  const handleSubmitNewInterest = (interest) => {
    setShowCreateInterestPopup(false);
    setNewInterestName('');
    handleInterestSelect(interest);
  };

  const handleProfileInterestCreate = () => {
    setCurrentInterest(null);
    return createProfileInterest(profile.id, { interestId: currentInterest.id });
  };

  const handleProfileInterestDestroy = (interestId) => () => destroyProfileInterest(profile.id, interestId);

  const handleInterestSearch = (searchText) => {
    const filters = { stateEq: 'active', titleCont: searchText };

    return searchInterests(filters);
  };

  const handleInterestSearchDebounced = reactSelectDebounce(handleInterestSearch);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedInterests = arrayMove(interests, oldIndex, newIndex);

    onSortEnd(reorderedInterests);
  };

  const renderNoOptions = ({ inputValue }) => (
    <div>
      <p>The suitable interest was not found.</p>
      <Button primary label="Add Interest" onClick={handleCreateNewInterest(inputValue)} />
    </div>
  );

  return (
    <>
      <Box margin={{ bottom: 'small' }}>
        <HeadlineWithReviewChangesLink
          strong
          size="small"
          title="Personal Interests"
          isChanged={isChanged}
          onReviewChanges={onReviewChanges}
        />
      </Box>

      <Box margin={{ bottom: 'small' }} className={styles.list}>
        <SortableList onSortEnd={handleSortEnd} useDragHandle>
          {interests.map((profileInterest, index) => (
            <SortableItem key={profileInterest.id} index={index} disabled={!editable}>
              <Box margin={{ bottom: 'small' }} className={styles.draggableCard}>
                <div className={styles.dragItem}>
                  {editable && (
                    <DragHandle className={styles.icon}>
                      <Icon name="drag" />
                    </DragHandle>
                  )}
                  <div className={styles.text}>
                    <Anchor
                      label={InterestPresenter.title(profileInterest.interest)}
                      href={Routes.editAdminInterestPath(profileInterest.interestId)}
                      target="_blank"
                    />
                  </div>
                  <Button
                    className={styles.action}
                    disabled={!editable || saving}
                    icon={<SubtractCircle />}
                    onClick={handleProfileInterestDestroy(profileInterest.id)}
                    plain
                  />
                </div>
              </Box>
            </SortableItem>
          ))}
        </SortableList>
      </Box>

      <div className={styles.options}>
        <div className={styles.field}>
          <AsyncSelect
            disabled={!editable || saving}
            placeholder="Add a new interest"
            loadOptions={handleInterestSearchDebounced}
            defaultOptions
            value={currentInterest}
            onChange={handleInterestSelect}
            getOptionValue={InterestPresenter.id}
            getOptionLabel={InterestPresenter.title}
            noBottom
            noOptionsMessage={renderNoOptions}
          />
        </div>

        <Button
          label="Add"
          primary
          className={styles.button}
          onClick={handleProfileInterestCreate}
          disabled={!editable || !currentInterest || saving}
        />
      </div>

      {showCreateInterestPopup && (
        <NewInterestModal
          interestName={newInterestName}
          onClose={handleCancelNewInterest}
          onCreateSuccess={handleSubmitNewInterest}
        />
      )}
    </>
  );
};

PersonalInterestsComponent.propTypes = {
  profile: ProfilePresenter.shape(),
  editable: PropTypes.bool,
  saving: PropTypes.bool,
  interests: PropTypes.arrayOf(InterestPresenter.shape()).isRequired,
  onSortEnd: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onReviewChanges: PropTypes.func.isRequired,
};

const PersonalInterests = withConfirmation(PersonalInterestsComponent, {
  destroyProfileInterest: 'Are you sure want to delete?',
});

export default PersonalInterests;
