import React from 'react';
import cn from 'clsx';

import styles from './PasswordStrengthRating.module.css';

const PasswordStrengthRating = (props) => {
  const { isValidPassword } = props;
  const classNames = cn({
    [styles.passwordStrong]: isValidPassword,
    [styles.passwordWeak]: !isValidPassword,
  });

  return (
    <div>
      Password Strength: <span className={classNames}>{isValidPassword ? 'Strong' : 'Weak'}</span>
    </div>
  );
};

export default PasswordStrengthRating;
