const CLASS_NAME = 'photo';

function mount(nodeGroup, props, clipPathId) {
  return nodeGroup
    .append('svg:image')
    .attr('xlink:href', (d) => d.photoUrl)
    .attr('class', CLASS_NAME)
    .attr('clip-path', (d) => `url(#${clipPathId(d)})`)
    .attr('x', -props.node.avatarRadius)
    .attr('y', -props.node.avatarRadius)
    .attr('width', props.node.avatarRadius * 2)
    .attr('height', props.node.avatarRadius * 2);
}

export default { mount };
