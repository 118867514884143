import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TagListPage from './TagListPage';
import * as actions from './TagListPageActions';

const mapStateToProps = (state) => ({
  loading: state.TagListPageReducer.loading,
  tags: state.TagListPageReducer.tags,
  nextPage: state.TagListPageReducer.nextPage,
  totalCount: state.TagListPageReducer.totalCount,
  filters: state.TagListPageReducer.filters,
  populatingTagId: state.TagListPageReducer.populatingTagId,
  bulkPopulatingTags: state.TagListPageReducer.bulkPopulatingTags,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagListPage);
