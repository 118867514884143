import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextInput, Box, CheckBox } from 'grommet';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import Select from 'components/Select';

import CountryEditForm from 'forms/CountryEditForm';
import CountryPresenter, { WORLD_REGIONS } from 'presenters/CountryPresenter';

const WORLD_REGION_OPTIONS = WORLD_REGIONS.map((r) => ({ label: r.title, value: r.key }));

class EditForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    country: CountryPresenter.shape(),
  };

  constructor(...args) {
    super(...args);

    this.state = {
      country: CountryEditForm.defaultAttributes(this.props.country),
      errors: null,
    };
  }

  handleSubmit = () => {
    const { country } = this.state;
    this.props.onSubmit(CountryEditForm.attributesToSubmit(country, this.props.country)).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  updateField(fieldName, value) {
    this.setState({ country: { ...this.state.country, [fieldName]: value } });
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleCheckBoxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      this.updateField(fieldName, checked);

  handleSelectInputChange = (fieldName) => (value) => this.updateField(fieldName, value);

  renderTextInput(label, fieldName) {
    const { country, errors } = this.state;
    const { [fieldName]: value } = country;

    return (
      <VerticalFormFieldWithBottomErrors label={label} errors={errors && errors[fieldName]} htmlFor={fieldName}>
        <TextInput value={value} onChange={this.handleTextFieldChange(fieldName)} id={fieldName} />
      </VerticalFormFieldWithBottomErrors>
    );
  }

  renderSelectInput(label, fieldName, options) {
    const {
      country: { [fieldName]: value },
      errors,
    } = this.state;

    return (
      <Select
        errors={errors && errors[fieldName]}
        selectedOptionValue={value}
        onValueChange={this.handleSelectInputChange(fieldName)}
        options={options}
        placeholder="World Region"
      />
    );
  }

  renderCheckBoxInput(label, fieldName) {
    const {
      country: { [fieldName]: value },
    } = this.state;

    return (
      <VerticalFormFieldWithBottomErrors checkbox>
        <CheckBox label="GDPR" toggle checked={value} onChange={this.handleCheckBoxChange('isGdpr')} />
      </VerticalFormFieldWithBottomErrors>
    );
  }

  render() {
    const { saving } = this.props;

    return (
      <div>
        {this.renderTextInput('Name:', 'name')}
        {this.renderTextInput('ISO 3166-1 (alpha2):', 'alpha2')}
        {this.renderSelectInput('World Region', 'worldRegion', WORLD_REGION_OPTIONS)}
        {this.renderCheckBoxInput('GDPR', 'isGdpr')}

        <Box direction="row" margin={{ top: 'medium' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default EditForm;
