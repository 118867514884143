import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { all, isEmpty } from 'ramda';
import Routes from 'routes';
import { formattedDate } from 'utils/DateTime';
import { pluralize } from 'utils/pluralizer';

import ProjectPresenter from 'presenters/ProjectPresenter';

import Button from 'components/v2/Button';
import MenuIcon from 'icons/MenuIcon';
import Menu, { MenuItem } from 'containers/UserPanel/components/Menu';

import Statistics from './components/Statistics';

import styles from './ProjectTile.module.css';

const ProjectTile = (props) => {
  const { project, updatingStatus, isSample, hasEmptySampleProject, onMarkAsSample, onUnmarkAsSample } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMarkAsSample = () => onMarkAsSample(project);

  const handleUnmarkAsSample = () => onUnmarkAsSample(project);

  const handleOpenMenu = () => setIsMenuOpen(true);

  const handleCloseMenu = () => setIsMenuOpen(false);

  const id = ProjectPresenter.id(project);
  const name = ProjectPresenter.name(project);
  const total = ProjectPresenter.totalProfilesCount(project);
  const significantlyUpdatedProfiles = ProjectPresenter.significantlyUpdatedProfiles(project);
  const addedProfiles = ProjectPresenter.addedProfiles(project);
  const newToRoleProfiles = ProjectPresenter.newToRoleProfiles(project);
  const isStatisticsBlank = all(isEmpty, [significantlyUpdatedProfiles, addedProfiles, newToRoleProfiles]);
  const statisticsSince = ProjectPresenter.statisticsSince(project);

  return (
    <div className={styles.item} title={name}>
      {isSample && <div className={styles.sample}>sample</div>}
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.title}>
            <div className={styles.name}>{name}</div>
          </div>

          <div className={styles.details}>
            <span>{total} profiles</span>
            &emsp;|&emsp;
            <span>Last Modified: {ProjectPresenter.formattedLastModifiedAt(project)}</span>
          </div>
        </div>

        <div className={styles.actions}>
          <Button onClick={handleOpenMenu} variant="raw">
            <MenuIcon className={styles.menuIcon} color="primary" />
          </Button>

          <Menu className={styles.menu} isOpen={isMenuOpen} onClose={handleCloseMenu}>
            {hasEmptySampleProject && !isSample && (
              <MenuItem
                component={Button}
                disabled={updatingStatus.isPending}
                onClick={handleMarkAsSample}
                variant="raw"
              >
                Mark as Sample
              </MenuItem>
            )}

            {isSample && (
              <MenuItem
                component={Button}
                disabled={updatingStatus.isPending}
                onClick={handleUnmarkAsSample}
                variant="raw"
              >
                Unmark as Sample
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>

      <div className={styles.statistics}>
        {isStatisticsBlank && (
          <div className={styles.noStatistics}>Nothing changed since {formattedDate(statisticsSince)}</div>
        )}

        {significantlyUpdatedProfiles.length > 0 && (
          <Statistics
            description={pluralize(
              'updated profile',
              ProjectPresenter.significantlyUpdatedProfilesTotalCount(project),
              true,
            )}
            path={Routes.projectPath(id, { significantlyUpdatedGte: statisticsSince })}
            profiles={significantlyUpdatedProfiles}
            profilesTotalCount={ProjectPresenter.significantlyUpdatedProfilesTotalCount(project)}
            title="Updated Profiles"
            updatedAt={ProjectPresenter.significantlyUpdatedProfilesUpdatedAt(project)}
          />
        )}

        {addedProfiles.length > 0 && (
          <Statistics
            description={pluralize('new profile', ProjectPresenter.addedProfilesTotalCount(project), true)}
            path={Routes.projectPath(id, { profilesAddedGte: statisticsSince })}
            profiles={addedProfiles}
            profilesTotalCount={ProjectPresenter.addedProfilesTotalCount(project)}
            title="New To Project"
            updatedAt={ProjectPresenter.addedProfilesUpdatedAt(project)}
          />
        )}

        {newToRoleProfiles.length > 0 && (
          <Statistics
            description={pluralize('executive change', ProjectPresenter.newToRoleProfilesTotalCount(project), true)}
            path={Routes.projectPath(id, { recentCurrentWorkStartedGte: statisticsSince })}
            profiles={newToRoleProfiles}
            profilesTotalCount={ProjectPresenter.newToRoleProfilesTotalCount(project)}
            title="New To Role"
            updatedAt={ProjectPresenter.newToRoleProfilesUpdatedAt(project)}
          />
        )}
      </div>
    </div>
  );
};

ProjectTile.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  isSample: PropTypes.bool.isRequired,
  hasEmptySampleProject: PropTypes.bool.isRequired,
  onMarkAsSample: PropTypes.func.isRequired,
  onUnmarkAsSample: PropTypes.func.isRequired,
  updatingStatus: PropTypes.shape(),
};

export default ProjectTile;
