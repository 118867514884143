import { buildFilterParams } from 'utils/FilterParamsBuilder';
import NewOrganizationModalConstants from './NewOrganizationModalConstants';
import OrganizationsRepository from 'repositories/admin/OrganizationsRepository';
import IndustriesRepository from 'repositories/admin/IndustriesRepository';
import NaicsIndustryCodesRepository from 'repositories/admin/NaicsIndustryCodesRepository';

export function createOrganization(params) {
  return (dispatch) => {
    dispatch({ type: NewOrganizationModalConstants.CREATE_ORGANIZATION_START });

    return OrganizationsRepository.create(params)
      .then(({ organization }) => {
        dispatch({ type: NewOrganizationModalConstants.CREATE_ORGANIZATION_SUCCESS, organization });

        return organization;
      })
      .catch((error) => {
        dispatch({ type: NewOrganizationModalConstants.CREATE_ORGANIZATION_ERROR });

        throw error;
      });
  };
}

export function searchIndustry(filters) {
  const params = buildFilterParams(filters);

  return () => IndustriesRepository.index(params).then(({ items }) => items);
}

export function searchNaicsIndustryCode(filters) {
  const params = buildFilterParams(filters);

  return () => NaicsIndustryCodesRepository.index(params).then(({ items }) => items);
}
