import { pick } from 'ramda';

export default {
  defaultAttributes({ ids }) {
    return {
      ids,
      assignee: null,
    };
  },

  attributesToSubmit(params) {
    const propsToSend = ['ids', 'assigneeId'];
    const { assignee } = params;

    return {
      ...pick(propsToSend, { ...params, assigneeId: assignee && assignee.id }),
    };
  },
};
