import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnchorLink from 'components/grommet/AnchorLink';

import styles from './Breadcrumbs.module.css';

export default class Breadcrumbs extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  renderItems() {
    const { items } = this.props;

    return (
      <div className={styles.breadcrumbs}>
        {items.map(({ name, ...hrefProps }) => (
          <AnchorLink key={name} className={styles.breadcrumb} {...hrefProps}>
            {name}
          </AnchorLink>
        ))}
      </div>
    );
  }

  render() {
    return this.renderItems();
  }
}
