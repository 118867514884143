export default {
  adminCompaniesPath: () => '/admin/companies',

  adminCompanyDashboardPath: (id) => `/admin/companies/${id}/dashboard`,
  adminCompanyPath: (id) => `/admin/companies/${id}`,
  adminCompanyUsersPath: (id) => `/admin/companies/${id}/users`,
  editAdminCompanyPath: (id) => `/admin/companies/${id}/edit`,
  editAdminCompanySingleSignOnSettingsPath: (id) => `/admin/companies/${id}/sso-settings/edit`,
  editAdminCompanyLegalSettingsPath: (id) => `/admin/companies/${id}/legal-settings/edit`,
  editAdminCompanySubscriptionSettingsPath: (id) => `/admin/companies/${id}/subscription-settings/edit`,
  newAdminCompanyUsersImportPath: (id) => `/admin/companies/${id}/users/import/new`,
  newAdminCompanyUsersImportWizardStepPath: (id, step) => `/admin/companies/${id}/users/import/new/${step}`,

  adminCompanyDepartmentPath: (companyId, departmentId) => `/admin/companies/${companyId}/departments/${departmentId}`,
  editAdminCompanyDepartmentPath: (companyId, departmentId) =>
    `/admin/companies/${companyId}/departments/${departmentId}/edit`,
  editAdminCompanyDepartmentSelfRegistrationFormPath: (companyId, departmentId) =>
    `/admin/companies/${companyId}/departments/${departmentId}/self-registration-form/edit`,
  editAdminCompanyDepartmentLimitedAccessPath: (companyId, departmentId) =>
    `/admin/companies/${companyId}/departments/${departmentId}/limited-access/edit`,
  editAdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath: (companyId, departmentId) =>
    `/admin/companies/${companyId}/departments/${departmentId}/explicitly-granted-profiles/edit`,
  editAdminCompanyDepartmentApiV2AccessSettingsPath: (companyId, departmentId) =>
    `/admin/companies/${companyId}/departments/${departmentId}/api-v2-access-settings/edit`,
};
