import React, { useEffect, useState } from 'react';

import RawHtml from 'components/RawHtml';

import { getAccessDeniedPageContent, removeAccessDeniedPageContent } from './content';

const AccessDeniedPage = () => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const content = getAccessDeniedPageContent();

    setHtml(content || '');

    removeAccessDeniedPageContent();
  }, []);

  return <RawHtml style={{ height: '100%', width: '100%' }} additionalWhitelistedTags={['iframe']} innerHTML={html} />;
};

export default AccessDeniedPage;
