import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import HelpRequestRepository from 'repositories/customer/HelpRequestRepository';

const restSlice = createRestSlice({
  resource: 'requestsHelpRequest',
  repository: HelpRequestRepository,
  slices: ['createRequestsHelpRequest', 'destroyRequestsHelpRequest'],
});

const initialState = {
  ...restSlice.initialState,
};

const requestsHelpRequestSlice = createSlice({
  name: 'clientRequestsHelpRequest',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientRequestsHelpRequestReducer = requestsHelpRequestSlice.reducer;

export default requestsHelpRequestSlice;

export const clientRequestsHelpRequestRestHooks = restSlice.hooks(
  requestsHelpRequestSlice,
  'clientRequestsHelpRequestReducer',
);
