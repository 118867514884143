import { AdminDashboardReducers } from './containers/CompanyRoutes/components/CompanyEditPage/containers/AdminDashboard';
import { AdminUserListReducers } from './containers/CompanyRoutes/components/CompanyEditPage/containers/AdminUserList';

import { ProfileListPageReducers } from './containers/ProfileListPage';
import { InterestPageReducers } from './containers/InterestPage';
import { InterestListPageReducers } from './containers/InterestListPage';
import { NewInterestModalReducers } from './containers/NewInterestModal';
import { UserListPageReducers } from './containers/UserListPage';
import { UserPageReducers } from './containers/UserPage';
import { OrganizationListReducers } from './containers/OrganizationList';
import { OrganizationListPageReducers } from './containers/OrganizationListPage';
import { CompaniesListPageReducers } from './containers/CompaniesListPage';
import { RequestsPageReducers } from './containers/RequestsPage';
import { RequestFormReducers } from './containers/RequestForm';
import { NewOrganizationModalReducers } from './containers/NewOrganizationModal';
import { FullProfileRequestListPageReducers } from './containers/FullProfileRequestListPage';
import { TREventsBlockReducers } from './containers/TREventsBlock';
import { ProfileListReducers } from './containers/ProfileList';
import { TagListPageReducers } from './containers/TagListPage';
import { TagPageReducers } from './containers/TagPage';
import { DashboardPageReducers } from './containers/DashboardPage';
import { LanguagesListPageReducers } from './containers/LanguagesListPage';
import { LanguagePageReducers } from './containers/LanguagePage';
import { CountriesListPageReducers } from './containers/CountriesListPage';
import { CountryPageReducers } from './containers/CountryPage';
import { SamplesPageReducers } from './containers/SamplesPage';

export default from './AdminPanel';

export const AdminPanelReducers = {
  ...AdminDashboardReducers,
  ...AdminUserListReducers,
  ...ProfileListPageReducers,
  ...InterestListPageReducers,
  ...NewInterestModalReducers,
  ...InterestPageReducers,
  ...UserListPageReducers,
  ...UserPageReducers,
  ...OrganizationListReducers,
  ...OrganizationListPageReducers,
  ...CompaniesListPageReducers,
  ...RequestsPageReducers,
  ...RequestFormReducers,
  ...NewOrganizationModalReducers,
  ...FullProfileRequestListPageReducers,
  ...TREventsBlockReducers,
  ...ProfileListReducers,
  ...TagListPageReducers,
  ...TagPageReducers,
  ...DashboardPageReducers,
  ...LanguagesListPageReducers,
  ...LanguagePageReducers,
  ...CountriesListPageReducers,
  ...CountryPageReducers,
  ...SamplesPageReducers,
};
