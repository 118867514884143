import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/FormDataFetchHelpers';

export default {
  create(matchExecutives) {
    const url = Routes.apiV1CurrentUserMatchExecutivesPath();

    return FetchHelpers.post(url, { matchExecutives });
  },

  validate(matchExecutives) {
    const url = Routes.validateApiV1CurrentUserMatchExecutivesPath();

    return FetchHelpers.post(url, { matchExecutives });
  },
};
