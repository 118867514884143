import keyMirror from 'keymirror';

const LanguagePageConstants = keyMirror({
  LOAD_LANGUAGE_START: null,
  LOAD_LANGUAGE_SUCCESS: null,
  LOAD_LANGUAGE_ERROR: null,

  UPDATE_LANGUAGE_START: null,
  UPDATE_LANGUAGE_SUCCESS: null,
  UPDATE_LANGUAGE_ERROR: null,
});

export default LanguagePageConstants;
