import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProjectItem from 'containers/UserPanel/components/ProjectItem';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import styles from './ChangedProjects.module.css';

class ChangedProjects extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  };

  render() {
    const { currentUser, projects } = this.props;

    return (
      <div className={styles.projects}>
        {projects.map((project) => (
          <ProjectItem key={ProjectPresenter.id(project)} currentUser={currentUser} project={project} />
        ))}
      </div>
    );
  }
}

export default ChangedProjects;
