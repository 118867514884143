import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(companyId, departmentId, user) {
    const url = Routes.apiV1CompanyDepartmentUsersPath(companyId, departmentId);

    return FetchHelpers.post(url, { user });
  },
};
