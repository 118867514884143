import { TopTagsReducers } from './containers/TopTags';
import { RolesReducers } from './containers/Roles';
import { TopJobFunctionsReducers } from './containers/TopJobFunctions';

export default from './BusinessPriorityAnalysis';
export const BusinessPriorityAnalysisReducers = {
  ...TopTagsReducers,
  ...RolesReducers,
  ...TopJobFunctionsReducers,
};
