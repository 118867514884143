export const ID = 'blur';

function mount(selection) {
  selection
    .append('filter')
    .attr('id', ID)
    .append('feGaussianBlur')
    .attr('in', 'SourceGraphic')
    .attr('stdDeviation', '4');
}

export default { mount };
