import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styles from './Tag.module.css';

import TagPresenter from 'presenters/TagPresenter';

export default class Tag extends Component {
  static propTypes = {
    tag: TagPresenter.shape().isRequired,
    onDelete: PropTypes.func.isRequired,
    deletable: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  handleClick = () => {
    const { onDelete, tag } = this.props;

    onDelete(tag);
  };

  render() {
    const { tag, deletable, saving } = this.props;

    return (
      <div className={styles.root}>
        {TagPresenter.fullTitle(tag)}
        {deletable && (
          <button disabled={saving} onClick={this.handleClick}>
            <Icon name="close" style={{ width: 8, height: 8 }} />
          </button>
        )}
      </div>
    );
  }
}
