import NotificationsConstants from './NotificationsConstants';

const initialState = {
  items: [],
};

function NotificationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NotificationsConstants.NOTIFICATION_ADDED: {
      return {
        ...state,
        items: [...state.items, action.notification],
      };
    }

    case NotificationsConstants.NOTIFICATION_CLOSED: {
      return {
        ...state,
        items: state.items.filter((n) => n.id !== action.notificationId),
      };
    }

    default:
      return state;
  }
}

export default NotificationsReducer;
