import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextInput, Box } from 'grommet';
import Button from 'components/Button';

import ProfilePresenter from 'presenters/ProfilePresenter';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import ProfileForm from 'forms/ProfileForm';

class NewProfileForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    profile: ProfilePresenter.shape(),
    saving: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    profile: {},
  };

  constructor(props) {
    super(props);

    this.state = { profile: ProfileForm.defaultAttributes(this.props.profile) };
  }

  handleSubmit = () => {
    this.props.onSubmit(ProfileForm.attributesToSubmit(this.state.profile));
  };

  handleFieldChange =
    (fieldName) =>
    ({ target }) => {
      this.setState({ ...this.state, profile: { ...this.state.profile, [fieldName]: target.value } });
    };

  renderField = (name, label) => {
    const { profile } = this.state;

    return (
      <VerticalFormFieldWithBottomErrors label={label} htmlFor={name}>
        <TextInput value={profile[name]} onChange={this.handleFieldChange(name)} id={name} />
      </VerticalFormFieldWithBottomErrors>
    );
  };

  render() {
    const { saving } = this.props;

    return (
      <div>
        {this.renderField('firstName', 'First Name:')}
        {this.renderField('middleName', 'Middle Name: ')}
        {this.renderField('lastName', 'Last Name:')}
        {this.renderField('email', 'Email:')}
        <Box direction="row" margin={{ top: 'small' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default NewProfileForm;
