import React, { Component, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { intersection, isNil, equals, find, reject, prop, propEq, length } from 'ramda';

import { Table as GrommetTable, InfiniteScroll, Spinner, Box } from 'grommet';

import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import DeleteRowTableAction from './components/DeleteRowTableAction';
import withConfirmation from 'hoc/withConfirmation';

import styles from './Table.module.css';
import cn from 'clsx';

import { isSameValues } from 'utils/ArrayHelpers';

const checkboxColumn = 1;

class TableComponent extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()),
    onDelete: PropTypes.func,
    onMore: PropTypes.func,
    onRowClick: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    sorts: PropTypes.arrayOf(PropTypes.shape()),
    hasMore: PropTypes.bool,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    rowCellsComponent: PropTypes.node.isRequired,
    totalRows: PropTypes.number,
    rowsDeletable: PropTypes.bool.isRequired,
    canDeleteSelectedRows: PropTypes.func,
    customActions: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    displayActionsHeader: PropTypes.bool,
  };

  static defaultProps = {
    customActions: [],
    canDeleteSelectedRows: () => true,
    displayActionsHeader: true,
  };

  state = { selectedRowIds: [] };

  componentDidUpdate() {
    const { rows } = this.props;
    const { selectedRowIds } = this.state;
    const rowIds = rows.map(prop('id'));
    const newSelectedRowIds = intersection(rowIds, selectedRowIds);

    if (!isSameValues(selectedRowIds, newSelectedRowIds)) {
      this.updateSelectedRowIds(newSelectedRowIds);
    }
  }

  updateSelectedRowIds(selectedRowIds) {
    this.setState({ selectedRowIds });
  }

  isRowSelected(row) {
    const { selectedRowIds } = this.state;

    return selectedRowIds.includes(row.id);
  }

  isAllRowSelected() {
    const { selectedRowIds } = this.state;
    const { rows } = this.props;

    return selectedRowIds.length > 0 && isSameValues(selectedRowIds, rows.map(prop('id')));
  }

  anyRowSelected() {
    const { selectedRowIds } = this.state;

    return selectedRowIds.length > 0;
  }

  columnsWithSortOrder() {
    const { columns, sorts = [] } = this.props;

    return columns.map((column) => {
      const sorting = find(propEq(column.field, 'field'), sorts);
      const sort = isNil(sorting) ? null : sorting.value;

      return { ...column, sort };
    });
  }

  handleRowSelect = (rowId, checked) => {
    const { selectedRowIds } = this.state;
    if (checked) {
      this.setState({ selectedRowIds: [...selectedRowIds, rowId] });
    } else {
      this.setState({ selectedRowIds: reject(equals(rowId), selectedRowIds) });
    }
  };

  handleAllSeletctionChange = (checked) => {
    const { rows } = this.props;

    if (checked) {
      this.setState({ selectedRowIds: rows.map(prop('id')) });
    } else {
      this.setState({ selectedRowIds: [] });
    }
  };

  handleSort = (columnsWithSortOrder) => {
    const { onSort } = this.props;
    const sorts = columnsWithSortOrder
      .filter(prop('sort'))
      .map(({ name, field, sort }) => ({ name, field, value: sort }));

    onSort(sorts);
  };

  actions() {
    const { canDeleteSelectedRows, customActions, onDelete, rowsDeletable } = this.props;
    const { selectedRowIds } = this.state;
    const isDeleteDisabled = !canDeleteSelectedRows(selectedRowIds);

    return (
      <>
        {rowsDeletable && (
          <DeleteRowTableAction deleteRows={onDelete} isDisabled={isDeleteDisabled} selectedRowIds={selectedRowIds} />
        )}
        {customActions.map((action, index) => cloneElement(action, { key: index, selectedRowIds }))}
      </>
    );
  }

  render() {
    const {
      onMore,
      onRowClick,
      hasMore,
      rows,
      rowCellsComponent,
      customActions,
      totalRows,
      rowsDeletable,
      className,
      displayActionsHeader,
      columns,
    } = this.props;
    const selectable = rowsDeletable || customActions.length > 0;
    const rootStyles = cn([styles.table], className);
    const colSpanLoading = selectable ? length(columns) + checkboxColumn : length(columns);
    return (
      <GrommetTable className={rootStyles}>
        <TableHeader
          allSelected={this.isAllRowSelected()}
          displayActions={this.anyRowSelected()}
          onSort={this.handleSort}
          onAllSelectionChange={this.handleAllSeletctionChange}
          labels={this.columnsWithSortOrder()}
          totalRows={totalRows}
          rowsSelectable={selectable}
          actions={this.actions()}
          displayActionsHeader={displayActionsHeader}
        />

        <tbody>
          <InfiniteScroll
            onMore={hasMore ? onMore : undefined}
            items={rows}
            step={10}
            renderMarker={() => (
              <tr>
                <td colSpan={colSpanLoading}>
                  <Box align="center">
                    <Spinner />
                  </Box>
                </td>
              </tr>
            )}
          >
            {(row) => (
              <TableRow
                key={row.id}
                row={row}
                onClick={onRowClick}
                onSelect={this.handleRowSelect}
                isSelected={this.isRowSelected(row)}
                selectable={selectable}
              >
                {cloneElement(rowCellsComponent, { row })}
              </TableRow>
            )}
          </InfiniteScroll>
        </tbody>
      </GrommetTable>
    );
  }
}

const Table = withConfirmation(TableComponent, { onDelete: 'Are you sure want to delete?' });
export default Table;
