import { keys, has } from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import styles from './AbstractIcon.module.css';

const COLORS = {
  mercury: styles.mercuryColor,
  orange: styles.orangeColor,
  primary: styles.primaryColor,
  salmon: styles.salmonColor,
  mineShaft: styles.mineShaftColor,
  malachite: styles.malachiteColor,
  doveGray: styles.doveGrayColor,
};

const AbstractIcon = (props) => {
  const { className, color, width, height, renderIcon } = props;
  const svgClassName = clsx(className, { [COLORS[color]]: has(color, COLORS) });

  return <>{renderIcon({ className: svgClassName, width, height })}</>;
};

AbstractIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(keys(COLORS)),
  height: PropTypes.number,
  width: PropTypes.number,
  renderIcon: PropTypes.func,
};

AbstractIcon.defaultProps = {
  className: null,
  color: null,
  height: 24,
  width: 24,
  renderIcon: () => {},
};

export default AbstractIcon;
