import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import { Box } from 'grommet';
import { Edit as EditIcon, Trash as TrashIcon, Pin as PinIcon } from 'grommet-icons';
import Modal from 'components/Modal';
import TopicForm from '../TopicForm';

import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import TopicPresenter from 'presenters/TopicPresenter';
import styles from './Topic.module.css';

class Topic extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    formTitle: PropTypes.string.isRequired,
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()).isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    topic: TopicPresenter.shape().isRequired,
    displayTags: PropTypes.bool.isRequired,
  };

  state = { edit: false };

  handleEdit = () => {
    const { editable } = this.props;

    this.setState({ edit: editable });
  };

  handleStopEdit = () => this.setState({ edit: false });

  handleSubmit = (topic) => this.props.onUpdate(topic).then(this.handleStopEdit);

  handleDestroy = () => {
    const { editable, onDelete, topic } = this.props;

    if (!editable) {
      return;
    }
    onDelete(topic.id);
  };

  render() {
    const { jobFunctions, saving, formTitle, topic, onTagSearch, displayTags } = this.props;
    const { edit } = this.state;

    return (
      <>
        <Box direction="column" className={styles.root} margin={{ bottom: 'small' }}>
          <Box direction="row" justify="between" className={styles.controls} margin="none">
            {TopicPresenter.pinned(topic) && (
              <div className={styles.pinIcon}>
                <PinIcon size="14px" />
              </div>
            )}
            <div className={styles.text}> {TopicPresenter.title(topic)} </div>
            <div>
              <Box direction="row">
                <Box margin={{ horizontal: 'small' }}>
                  <EditIcon onClick={this.handleEdit} size="14px" />
                </Box>
                <TrashIcon onClick={this.handleDestroy} size="14px" />
              </Box>
            </div>
          </Box>
          {isPresent(TopicPresenter.topicUpdatedAt(topic)) && (
            <Box margin="none" alignSelf="start" className={styles.updatedAt}>
              Updated at: {TopicPresenter.formattedTopicUpdatedAt(topic)}
            </Box>
          )}
        </Box>
        {edit && (
          <Modal onClose={this.handleStopEdit} size="large" header={formTitle} disableOverlayClose disableCloseByEsc>
            <TopicForm
              jobFunctions={jobFunctions}
              onSubmit={this.handleSubmit}
              onTagSearch={onTagSearch}
              topic={topic}
              saving={saving}
              displayTags={displayTags}
            />
          </Modal>
        )}
      </>
    );
  }
}

export default Topic;
