import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Routes from 'routes/App';
import { Box } from 'grommet';
import Spinner from 'components/Spinner';

import UserPresenter from 'presenters/UserPresenter';
import EditForm from './components/EditForm';
import PageHeader from 'components/PageHeader';

class UserPage extends Component {
  static propTypes = {
    isResetPasswordEmailSending: PropTypes.bool.isRequired,
    sendResetPasswordEmail: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
    user: UserPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    loadUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    searchCompanies: PropTypes.func.isRequired,
    searchCompanyDepartments: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadUser, userId } = this.props;

    loadUser({ id: userId });
  }

  handleCompanySearch = (value) => {
    const { searchCompanies } = this.props;

    return searchCompanies({ searchFieldCont: value });
  };

  handleCompanyDepartmentSearch = (companyId, value) => {
    const { searchCompanyDepartments } = this.props;

    return searchCompanyDepartments(companyId, { nameCont: value });
  };

  handleSubmit = (user) => {
    const { updateUser, userId } = this.props;

    return updateUser(userId, user);
  };

  handleSendResetPasswordEmail = () => {
    const { user, sendResetPasswordEmail } = this.props;

    return sendResetPasswordEmail({ email: user.email });
  };

  render() {
    const { isResetPasswordEmailSending, user, loading, saving } = this.props;

    if (loading || !user) {
      return <Spinner />;
    }

    return (
      <Box margin="none">
        <PageHeader title="Edit User" backTo={Routes.adminUsersPath()} />
        <Box width="medium">
          <EditForm
            isResetPasswordEmailSending={isResetPasswordEmailSending}
            user={user}
            saving={saving}
            onCompanySearch={this.handleCompanySearch}
            onCompanyDepartmentSearch={this.handleCompanyDepartmentSearch}
            onSubmit={this.handleSubmit}
            onSendResetPasswordEmail={this.handleSendResetPasswordEmail}
          />
        </Box>
      </Box>
    );
  }
}

export default UserPage;
