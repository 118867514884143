import CsvItemsTableConstants from './CsvItemsTableConstants';
import CsvItemsRepository from 'repositories/CurrentUser/Project/CsvSource/CsvItemsRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function loadCsvItems(projectId, sourceId, { page, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: CsvItemsTableConstants.LOAD_PROJECT_CSV_SOURCE_CSV_ITEMS_START, page, filters });

    const params = { page, per, ...buildFilterParams(filters) };

    return CsvItemsRepository.index(projectId, sourceId, params).then(({ items, meta }) => {
      dispatch({
        type: CsvItemsTableConstants.LOAD_PROJECT_CSV_SOURCE_CSV_ITEMS_SUCCESS,
        csvItems: items,
        meta,
        filters,
      });

      return items;
    });
  };
}

export function updateCsvItem(projectId, sourceId, id, attributes) {
  return (dispatch) =>
    CsvItemsRepository.update(projectId, sourceId, id, attributes).then(({ csvItem, meta }) => {
      dispatch({ type: CsvItemsTableConstants.UPDATE_PROJECT_CSV_SOURCE_CSV_ITEM_SUCCESS, csvItem });

      return { csvItem, meta };
    });
}
