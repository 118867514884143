import React, { useEffect, useState } from 'react';
import { useDebounce } from 'utils/debounceUtils';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { isNil, isEmpty, filter, pipe, not } from 'ramda';

import { useAdminSampleProjects, useAdminProjects } from 'hooks/admin';

import SampleProjectPresenter from 'presenters/SampleProjectPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import Spinner from 'components/Spinner';

import Projects from './components/Projects';
import SampleProjectItem from './components/SampleProjectItem';

import styles from './SampleProjectsTab.module.css';

const SampleProjectsTab = () => {
  const {
    sampleProjects,
    loadSampleProjects,
    updateSampleProject,
    sampleProjectsLoadingStatus,
    sampleProjectsUpdatingStatus,
  } = useAdminSampleProjects();

  const { projects, loadProjects, loadMoreProjects, projectsLoadingStatus, projectsLoadingMoreStatus, projectsMeta } =
    useAdminProjects();

  const [projectsFilters, setProjectsFilters] = useState({ q: { canBeSamples: true } });
  const hasEmptySampleProject = pipe(filter(SampleProjectPresenter.isEmpty), isEmpty, not)(sampleProjects);
  const takenSampleProjects = sampleProjects.filter(SampleProjectPresenter.isTaken);
  const sampleProjectIds = takenSampleProjects.map(SampleProjectPresenter.projectId);

  useEffect(() => {
    loadSampleProjects();
    loadProjects(projectsFilters);
  }, []);

  const handleProjectsFilter = useDebounce((newFilters) => loadProjects(newFilters));

  const handleProjectsFiltersChange = (newFilters) => {
    setProjectsFilters(newFilters);
    handleProjectsFilter(newFilters);
  };

  const handleSampleProjectUpdate = (id, params) => {
    updateSampleProject(id, params).then(() => {
      loadSampleProjects();
      loadProjects();
    });
  };

  const handleLoadMoreProjects = () => {
    loadMoreProjects({ page: projectsMeta.nextPage, ...buildFilterParams(projectsFilters) });
  };

  const handleMarkAsSample = (project) => {
    const sampleProject = sampleProjects.find(SampleProjectPresenter.isEmpty);
    const projectId = ProjectPresenter.id(project);

    handleSampleProjectUpdate(sampleProject.id, { ...sampleProject, projectId });
  };

  const handleUnmarkAsSample = (project) => {
    const projectId = ProjectPresenter.id(project);
    const sampleProject = sampleProjects
      .filter(SampleProjectPresenter.isTaken)
      .find((sample) => SampleProjectPresenter.projectId(sample) === projectId);

    handleSampleProjectUpdate(sampleProject.id, { ...sampleProject, projectId: null });
  };

  return (
    <>
      <div className={styles.sampleProjects}>
        {sampleProjectsLoadingStatus.isPending ? (
          <Spinner />
        ) : (
          sampleProjects.map((sampleProject) => (
            <SampleProjectItem
              key={sampleProject.id}
              sampleProject={sampleProject}
              updatingStatus={sampleProjectsUpdatingStatus}
              onMarkAsSample={handleMarkAsSample}
              onUnmarkAsSample={handleUnmarkAsSample}
            />
          ))
        )}
      </div>
      <Projects
        hasEmptySampleProject={hasEmptySampleProject}
        onMarkAsSample={handleMarkAsSample}
        onUnmarkAsSample={handleUnmarkAsSample}
        sampleProjectIds={sampleProjectIds}
        updatingStatus={sampleProjectsUpdatingStatus}
        onFilterChange={handleProjectsFiltersChange}
        filters={projectsFilters}
        hasMore={!isNil(projectsMeta.nextPage)}
        loadingMoreStatus={projectsLoadingMoreStatus}
        loadingStatus={projectsLoadingStatus}
        projects={projects}
        onLoadMore={handleLoadMoreProjects}
      />
    </>
  );
};

export default SampleProjectsTab;
