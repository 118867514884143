import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import RawHtml from 'components/RawHtml';
import Spinner from 'components/Spinner';
import UserRegistrationForm from './components/UserRegistrationForm';

import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import Routes from 'routes';

import styles from './CompanyDepartmentUserRegistration.module.css';

class CompanyDepartmentUserRegistration extends Component {
  componentDidMount() {
    const { companyId, departmentId, loadCompanyDepartment } = this.props;

    loadCompanyDepartment(companyId, departmentId);
  }

  handleSubmit = (params) => {
    const { companyId, companyDepartment, createCompanyDepartmentUser, history } = this.props;

    return createCompanyDepartmentUser(companyId, companyDepartment.id, params).then(({ meta }) => {
      history.push(Routes.signInPath({ twoFactorAuthentication: meta.twoFactorAuthentication }));
    });
  };

  renderRegistrationFormVideoCode() {
    const { companyDepartment } = this.props;

    const registrationFormVideoCode = CompanyDepartmentPresenter.registrationFormVideoCode(companyDepartment);

    if (isBlank(registrationFormVideoCode)) {
      return null;
    }

    return (
      <>
        <Heading level="2" className={styles.header}>
          Watch Our Video
        </Heading>
        <div className={styles.video}>
          <RawHtml component="div" innerHTML={registrationFormVideoCode} additionalWhitelistedTags={['iframe']} />
        </div>
      </>
    );
  }

  render() {
    const { companyDepartment, loading, saving } = this.props;

    if (loading || !companyDepartment) {
      return <Spinner />;
    }

    if (CompanyDepartmentPresenter.remainingSeatsNumber(companyDepartment) <= 0) {
      return (
        <div className={styles.self}>
          <div className={styles.media}>
            <Heading level="2" className={styles.notification}>
              Sorry, all the seat licenses for your company have been filled.
              <br />
              Please contact{' '}
              <a href="mailto:customerservice@boardroominsiders.com">customerservice@boardroominsiders.com</a> for more
              information.
            </Heading>
          </div>
        </div>
      );
    }

    const registrationFormWysiwyg = CompanyDepartmentPresenter.registrationFormWysiwyg(companyDepartment);

    return (
      <div className={styles.self}>
        <div className={styles.media}>
          {registrationFormWysiwyg && (
            <div className={styles.text}>
              <RawHtml component="p" innerHTML={registrationFormWysiwyg} />
            </div>
          )}
          {this.renderRegistrationFormVideoCode()}
        </div>
        <div className={styles.form}>
          <Heading level="2" className={styles.header}>
            {CompanyDepartmentPresenter.registrationFormHeader(companyDepartment)}
          </Heading>
          <UserRegistrationForm onSubmit={this.handleSubmit} saving={saving} />
        </div>
      </div>
    );
  }
}

CompanyDepartmentUserRegistration.propTypes = {
  companyDepartment: CompanyDepartmentPresenter.shape(),
  companyId: PropTypes.string.isRequired,
  departmentId: PropTypes.string.isRequired,
  createCompanyDepartmentUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadCompanyDepartment: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
};

export default CompanyDepartmentUserRegistration;
