import keyMirror from 'keymirror';

const ProjectConstants = keyMirror({
  LOAD_PROJECT_START: null,
  LOAD_PROJECT_SUCCESS: null,

  CLONE_PROJECT_START: null,
  CLONE_PROJECT_SUCCESS: null,
  CLONE_PROJECT_ERROR: null,

  DESTROY_PROJECT_START: null,
  DESTROY_PROJECT_SUCCESS: null,
  DESTROY_PROJECT_ERROR: null,

  CREATE_PROJECT_SOURCE_START: null,
  CREATE_PROJECT_SOURCE_SUCCESS: null,
  CREATE_PROJECT_SOURCE_ERROR: null,

  DESTROY_PROJECT_SOURCE_START: null,
  DESTROY_PROJECT_SOURCE_SUCCESS: null,
  DESTROY_PROJECT_SOURCE_ERROR: null,

  UPDATE_PROJECT_START: null,
  UPDATE_PROJECT_SUCCESS: null,
  UPDATE_PROJECT_ERROR: null,

  RELOAD_PROJECT_START: null,
  RELOAD_PROJECT_SUCCESS: null,
});

export default ProjectConstants;
