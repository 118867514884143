import keyMirror from 'keymirror';

const FullProfileRequestListPageConstants = keyMirror({
  INITIALIZE_FULL_PROFILE_REQUEST_LIST_SUCCESS: null,

  LOAD_FULL_PROFILE_REQUESTS_START: null,
  LOAD_FULL_PROFILE_REQUESTS_SUCCESS: null,

  FILTER_FULL_PROFILE_REQUESTS_START: null,
  FILTER_FULL_PROFILE_REQUESTS_SUCCESS: null,
});

export default FullProfileRequestListPageConstants;
