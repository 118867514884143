import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserList from 'components/CorporateAccountUserList';
import * as actions from './AdminUserListActions';

const mapStateToProps = (state) => ({
  company: state.AdminUserListReducer.company,
  loading: state.AdminUserListReducer.loading,
  nextPage: state.AdminUserListReducer.nextPage,
  filters: state.AdminUserListReducer.filters,
  saving: state.AdminUserListReducer.saving,
  totalCount: state.AdminUserListReducer.totalCount,
  users: state.AdminUserListReducer.users,
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      loadUsers: (...attrs) => actions.loadUsers(props.companyId, ...attrs),
      filterUsers: (...attrs) => actions.filterUsers(props.companyId, ...attrs),
      createUser: (...attrs) => actions.createUser(props.companyId, ...attrs),
      bulkDestroyUsers: (...attrs) => actions.bulkDestroyUsers(props.companyId, ...attrs),
      onImpersonate: actions.impersonate,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
