import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { any, equals, isEmpty } from 'ramda';

import { FormCheckmark as FormCheckMarkIcon } from 'grommet-icons';

import {
  validatePassword,
  PASSWORD_CONTAIN_NUMBER_OF_CHARACTERS,
  PASSWORD_CONTAIN_A_DIGIT,
  PASSWORD_CONTAIN_A_LOWER_CASE_CHARACTER,
  PASSWORD_CONTAIN_A_LOWER_UPPER_CHARACTER,
  PASSWORD_CONTAIN_A_SPECIAL_CHARACTER,
} from 'utils/passwordHelper';

import styles from './PasswordValidator.module.css';

const VALIDATE_OPTIONS = [
  PASSWORD_CONTAIN_NUMBER_OF_CHARACTERS,
  PASSWORD_CONTAIN_A_LOWER_CASE_CHARACTER,
  PASSWORD_CONTAIN_A_LOWER_UPPER_CHARACTER,
  PASSWORD_CONTAIN_A_DIGIT,
  PASSWORD_CONTAIN_A_SPECIAL_CHARACTER,
];

const PasswordValidator = (props) => {
  const { password, onValidation, className } = props;
  const passwordErrors = validatePassword(password, { list: true });

  const hasPasswordError = (key) => any(equals(key), passwordErrors);
  const isPasswordStrong = isEmpty(passwordErrors);
  const rootClassNames = cn(styles.root, className);

  useEffect(() => {
    onValidation(isPasswordStrong);
  }, [password]);

  return (
    <div className={rootClassNames}>
      {VALIDATE_OPTIONS.map(({ label, key }) => (
        <div key={key} className={styles.criteria}>
          <div className={cn(styles.checkIcon, { [styles.checkIconDisabled]: hasPasswordError(key) })}>
            <FormCheckMarkIcon className={styles.checkMark} color="light-1" />
          </div>
          <div>{label}</div>
        </div>
      ))}
    </div>
  );
};

PasswordValidator.propTypes = {
  password: PropTypes.string.isRequired,
  onValidation: PropTypes.func,
};

PasswordValidator.defaultProps = {
  onValidation: () => {},
};

export default PasswordValidator;
