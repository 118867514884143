import { pick } from 'ramda';

export default {
  defaultAttributes() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      emailConfirmation: '',
      companyDepartmentId: null,
    };
  },

  attributesToSubmit(user) {
    const propsToSend = ['firstName', 'lastName', 'email', 'emailConfirmation', 'companyDepartmentId'];

    return pick(propsToSend, user);
  },
};
