import keyMirror from 'keymirror';

const SampleSavedSearchesConstants = keyMirror({
  LOAD_ADMIN_SAMPLE_SAVED_SEARCHES_START: null,
  LOAD_ADMIN_SAMPLE_SAVED_SEARCHES_SUCCESS: null,

  UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_START: null,
  UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_SUCCESS: null,
  UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_ERROR: null,
});

export default SampleSavedSearchesConstants;
