import React, { useEffect } from 'react';
import { useParams } from 'react-router-polyfill';

import { useAlert } from 'hooks';
import { useAdminCompanyDepartmentApiV2AccessSettings } from 'hooks/admin';

import Form from 'components/v2/Form';
import Loader from 'components/v2/Loader';

import EditForm from './components/EditForm';

import ApiV2AccessSettingsForm from 'forms/company/department/ApiV2AccessSettingsForm';

const ApiV2AccessSettingsTab = () => {
  const alert = useAlert();
  const { companyId, companyDepartmentId } = useParams();
  const {
    apiV2AccessSettings,
    apiV2AccessSettingsLoadingStatus,
    loadApiV2AccessSettings,
    updateApiV2AccessSettings,
    resetApiV2AccessSettingsSecret,
  } = useAdminCompanyDepartmentApiV2AccessSettings();

  useEffect(() => {
    loadApiV2AccessSettings(companyId, companyDepartmentId);
  }, []);

  const isValidApiV2AccessSettings = apiV2AccessSettings?.companyDepartmentId !== Number(companyDepartmentId);

  const handleApiV2AccessSettingsUpdate = async (attributes) => {
    await updateApiV2AccessSettings(
      companyId,
      companyDepartmentId,
      ApiV2AccessSettingsForm.attributesToSubmit(attributes),
    );

    alert.successAction('API V2 Access Settings was successfully saved');
  };

  const handleApiV2AccessSettingsSecretReset = async () => {
    await resetApiV2AccessSettingsSecret(companyId, companyDepartmentId);

    alert.successAction('API V2 Access Secret was successfully updated');
  };

  if (apiV2AccessSettingsLoadingStatus.isPending || isValidApiV2AccessSettings) {
    return <Loader />;
  }

  return (
    <Form
      enableReinitialize
      initialValues={ApiV2AccessSettingsForm.defaultAttributes(apiV2AccessSettings)}
      onSubmit={handleApiV2AccessSettingsUpdate}
    >
      {(formProps) => <EditForm {...formProps} onSecretReset={handleApiV2AccessSettingsSecretReset} />}
    </Form>
  );
};

export default ApiV2AccessSettingsTab;
