import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { propEq, propOr, reject, values, prop, pipe, toPairs, reduce, find, any, without, anyPass, isNil } from 'ramda';
import { Alert as AlertIcon } from 'grommet-icons';

import { pluralize } from 'utils/pluralizer';
import { goToTop } from 'utils/DOMHelpers';
import { isPresent, isBlank } from 'utils/HelperMethods';
import { buildTargetItemCriterias, parseTargetItemCriterias } from 'utils/Project/TargetItemHelper';
import {
  toggleProjectDatabaseSearchIncludeExecutives,
  toggleProjectDatabaseSearchIncludeBoardOfDirectors,
  toggleProjectDatabaseSearchIncludeBoardOfDirectorsFtse100,
} from 'utils/amplitude';

import {
  useClientTargetingSearch,
  useClientTargetingSearchExecutives,
  useClientTargetingSearchDirectorBoards,
} from 'hooks/client';

import NumberPresenter from 'presenters/NumberPresenter';
import TargetItemPresenter, {
  INCLUSION_KIND,
  EXCLUSION_KIND,
  EXECUTIVES_SCOPE,
  DIRECTORS_BOARD_SCOPE,
  DIRECTORS_BOARD_FTSE100_SCOPE,
} from 'presenters/Project/TargetItemPresenter';

import { FILTERS } from 'forms/ProfileFilteredSearchForm';
import TargetItemForm from 'forms/project/TargetItemForm';

import Tabs, { TabList, Tab, TabPanel } from 'components/v2/Tabs';
import { Checkbox } from 'components/v2/Form';
import Button from 'components/Button';
import Filters from 'containers/UserPanel/components/SearchFilters';
import SortSelect, { DEFAULT_SORT_OPTION } from 'containers/UserPanel/components/SearchSortSelect';
import Loader from 'components/v2/Loader';
import ProfilesList from 'containers/UserPanel/components/SearchProfilesList';
import QuestionMarkButton from 'components/QuestionMarkButton';

import styles from './TargetingSearchForm.module.css';

const PER_PAGE = 6;
const MAX_PAGE = 1600;
const EXCLUSION_KIND_DISABLED_FILTERS = values(FILTERS).filter((filter) => filter !== FILTERS.FULL_NAME_FILTER);
const SORTED_SCOPES = [EXECUTIVES_SCOPE, DIRECTORS_BOARD_SCOPE, DIRECTORS_BOARD_FTSE100_SCOPE, null];

const MAP_SEARCH_PARAM_KEY_TO_SCOPE_SEARCH_PARAM_KEY = {
  [EXECUTIVES_SCOPE]: {
    [FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FILTER]: FILTERS.CURRENT_ORGANIZATION_NAME_FILTER,
    [FILTERS.DIRECTOR_BOARD_ORGANIZATION_RANKING]: FILTERS.CURRENT_ORGANIZATION_RANK_LTE,
  },
  [DIRECTORS_BOARD_SCOPE]: {
    [FILTERS.CURRENT_ORGANIZATION_NAME_FILTER]: FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FILTER,
    [FILTERS.CURRENT_ORGANIZATION_RANK_LTE]: FILTERS.DIRECTOR_BOARD_ORGANIZATION_RANKING,
  },
  [DIRECTORS_BOARD_FTSE100_SCOPE]: {
    [FILTERS.CURRENT_ORGANIZATION_NAME_FILTER]: FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FTSE100_FILTER,
    [FILTERS.CURRENT_ORGANIZATION_RANK_LTE]: FILTERS.DIRECTOR_BOARD_ORGANIZATION_RANKING,
  },
};

const FILTERS_FOR_SHOW_DIRECTORS_BOARD_TAB = [
  FILTERS.CURRENT_ORGANIZATION_NAME_FILTER,
  FILTERS.DIRECTORS_BOARD_ORGANIZATION_NAME_FTSE100_FILTER,
  FILTERS.CURRENT_ORGANIZATION_RANK_LTE,
];

const findTargetItem = (targetItems, kind, scope) =>
  targetItems.filter(propEq(kind, 'kind')).find(propEq(scope, 'scope'));

const findOrInitializeTargetItem = (targetItems, kind, scope, params) => {
  const sortedScopes = isPresent(scope) ? [scope] : SORTED_SCOPES;
  const targetItem = sortedScopes
    .map((s) => find(propEq(s, 'scope'), targetItems))
    .filter(isPresent)
    .find((t) => t.kind === kind);

  return isPresent(targetItem) ? targetItem : TargetItemForm.defaultAttributes({ kind, scope, ...params });
};

const buildSearchParamsForScope = (searchParams, scope) => {
  if (![EXECUTIVES_SCOPE, DIRECTORS_BOARD_SCOPE, DIRECTORS_BOARD_FTSE100_SCOPE].includes(scope)) return searchParams;

  const reducer = (acc, [k, v]) => {
    const newKey = propOr(k, k)(MAP_SEARCH_PARAM_KEY_TO_SCOPE_SEARCH_PARAM_KEY[scope]);

    return { ...acc, [newKey]: v };
  };

  return pipe(toPairs, reduce(reducer, {}))(searchParams);
};

const getSearchParams = (targetItems, kind, scope) => {
  const targetItem = findOrInitializeTargetItem(targetItems, kind, scope);
  const criterias = propOr([], 'criterias', targetItem);
  const searchParamsFromCriterias = parseTargetItemCriterias(criterias);

  return buildSearchParamsForScope(searchParamsFromCriterias, EXECUTIVES_SCOPE);
};

const isSearchParamsExists = (targetItems, criteriaTypes, kind) => {
  const criterias = getSearchParams(targetItems, kind);
  const isCriteriaTypePresent = (criteriaType) => pipe(prop(criteriaType), isPresent)(criterias);

  return any(isCriteriaTypePresent)(criteriaTypes);
};

const isShowDirectorsBoardTab = (targetItems) =>
  isSearchParamsExists(targetItems, FILTERS_FOR_SHOW_DIRECTORS_BOARD_TAB, INCLUSION_KIND);

const isIncludedTargetItem = (targetItems, kind, scope) => {
  const targetItem = findTargetItem(targetItems, kind, scope);

  return isPresent(targetItem) && targetItem.included;
};

const changeDirectorsBoardStateIncluded = (newTargetItems, included) =>
  newTargetItems.map((item) => {
    if (item.kind === EXCLUSION_KIND) {
      return item;
    }

    const isBoardScope = item.scope === DIRECTORS_BOARD_SCOPE || item.scope === DIRECTORS_BOARD_FTSE100_SCOPE;

    return isBoardScope ? { ...item, included } : { ...item, included: true };
  });

const buildDefaultTargetItems = (targetItems) => {
  const directorsBoardTargetItem = findOrInitializeTargetItem(targetItems, INCLUSION_KIND, DIRECTORS_BOARD_SCOPE, {
    included: false,
  });
  const directorsBoardFtse100TargetItem = findOrInitializeTargetItem(
    targetItems,
    INCLUSION_KIND,
    DIRECTORS_BOARD_FTSE100_SCOPE,
    {
      included: false,
    },
  );
  const executivesTargetItem = findOrInitializeTargetItem(targetItems, INCLUSION_KIND, EXECUTIVES_SCOPE, {
    included: false,
  });
  const exclusionTargetItem = findTargetItem(targetItems, EXCLUSION_KIND, null);

  const searchParams = getSearchParams(targetItems, INCLUSION_KIND);
  const directorsBoardSearchParams = buildSearchParamsForScope(searchParams, DIRECTORS_BOARD_SCOPE);
  const directorsBoardFtse100SearchParams = buildSearchParamsForScope(searchParams, DIRECTORS_BOARD_FTSE100_SCOPE);
  const executiveSearchParams = buildSearchParamsForScope(searchParams, EXECUTIVES_SCOPE);

  return [
    {
      ...directorsBoardTargetItem,
      criterias: buildTargetItemCriterias(directorsBoardSearchParams),
    },
    {
      ...directorsBoardFtse100TargetItem,
      criterias: buildTargetItemCriterias(directorsBoardFtse100SearchParams),
    },
    {
      ...executivesTargetItem,
      criterias: buildTargetItemCriterias(executiveSearchParams),
    },
    exclusionTargetItem,
  ].filter(isPresent);
};

const TargetingSearchForm = (props) => {
  const {
    targetItems,
    onTargetItemsChange,
    recommendedProfilesCount,
    onChangeNumberProfiles,
    onChangeProfilesLoadingFlag,
  } = props;

  const {
    targetingSearchExecutives,
    loadTargetingSearchExecutives,
    targetingSearchExecutivesLoadingStatus,
    targetingSearchExecutivesMeta,
  } = useClientTargetingSearchExecutives();

  const {
    targetingSearchDirectorBoards,
    loadTargetingSearchDirectorBoards,
    targetingSearchDirectorBoardsLoadingStatus,
    targetingSearchDirectorBoardsMeta,
  } = useClientTargetingSearchDirectorBoards();

  const { loadTargetingSearches, targetingSearchesLoadingStatus, targetingSearchesMeta, autocomplete } =
    useClientTargetingSearch();

  const {
    currentPage: currentPageExecutives,
    highlights: highlightsExecutives,
    highlightedKeywords: highlightedKeywordsExecutives,
  } = targetingSearchExecutivesMeta;

  const {
    totalCount: totalCountDirectorBoards,
    currentPage: currentPageDirectorBoards,
    highlights: highlightsDirectorBoards,
    highlightedKeywords: highlightedKeywordsDirectorBoards,
  } = targetingSearchDirectorBoardsMeta;

  const { totalCount: totalCountProfiles } = targetingSearchesMeta;

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [autocompleteParams, setAutocompleteParams] = useState({});
  const [searchKind, setSearchKind] = useState(INCLUSION_KIND);
  const [sort, setSort] = useState(DEFAULT_SORT_OPTION.value);
  const isSearchKind = (kind) => searchKind === kind;
  const [totalCountExecutives, setTotalCountExecutives] = useState(0);

  const searchParams = getSearchParams(targetItems, searchKind);
  const autocompleteBySearchKind = autocompleteParams[searchKind] || {};
  const disabledFilters = isSearchKind(EXCLUSION_KIND) ? EXCLUSION_KIND_DISABLED_FILTERS : [];
  const isIncludedExecutives = isIncludedTargetItem(targetItems, INCLUSION_KIND, EXECUTIVES_SCOPE);
  const isIncludedDirectorsBoard = isIncludedTargetItem(targetItems, INCLUSION_KIND, DIRECTORS_BOARD_SCOPE);
  const isIncludedDirectorsBoardFtse100 = isIncludedTargetItem(
    targetItems,
    INCLUSION_KIND,
    DIRECTORS_BOARD_FTSE100_SCOPE,
  );
  const numberSelectedProfiles =
    isIncludedExecutives || isIncludedDirectorsBoard || isIncludedDirectorsBoardFtse100 ? totalCountProfiles : 0;

  const handleLoadTargetingSearch = (newTargetItems) => {
    if (
      !isIncludedTargetItem(newTargetItems, INCLUSION_KIND, EXECUTIVES_SCOPE) &&
      !isIncludedTargetItem(newTargetItems, INCLUSION_KIND, DIRECTORS_BOARD_SCOPE) &&
      !isIncludedTargetItem(newTargetItems, INCLUSION_KIND, DIRECTORS_BOARD_FTSE100_SCOPE)
    ) {
      onChangeNumberProfiles(0);
      return;
    }

    const includedTargetItems = newTargetItems.filter((item) => item.included !== false);

    onChangeProfilesLoadingFlag(true);
    loadTargetingSearches({ targetItems: includedTargetItems, page: 1, per: 1 })
      .then((data) => {
        onChangeNumberProfiles(data.meta.totalCount);
      })
      .finally(() => {
        onChangeProfilesLoadingFlag(false);
      });
  };

  const handleLoadExecutives = (params) => {
    const { targetItems: newTargetItems, ...rest } = params;
    const rejectConditions = [propEq(DIRECTORS_BOARD_SCOPE, 'scope'), propEq(DIRECTORS_BOARD_FTSE100_SCOPE, 'scope')];
    const loadableTargetItems = reject(anyPass(rejectConditions), newTargetItems);

    return loadTargetingSearchExecutives({ targetItems: loadableTargetItems, ...rest, per: PER_PAGE });
  };

  const handleLoadDirectorsBoard = (params) => {
    const { targetItems: newTargetItems, ...rest } = params;
    const { [FILTERS.CURRENT_ORGANIZATION_RANK_LTE]: currentOrganizationRankLteFilterValue } = searchParams;
    const isFtse =
      !isNil(currentOrganizationRankLteFilterValue) && currentOrganizationRankLteFilterValue[0].includes('ftse');
    const isFortune =
      !isNil(currentOrganizationRankLteFilterValue) && currentOrganizationRankLteFilterValue[0].includes('fortune');

    const rejectConditions = [propEq(EXECUTIVES_SCOPE, 'scope')];
    if (isFtse) {
      rejectConditions.push(propEq(DIRECTORS_BOARD_SCOPE, 'scope'));
    }
    if (isFortune) {
      rejectConditions.push(propEq(DIRECTORS_BOARD_FTSE100_SCOPE, 'scope'));
    }
    const loadableTargetItems = reject(anyPass(rejectConditions), newTargetItems);

    loadTargetingSearchDirectorBoards({ targetItems: loadableTargetItems, ...rest, per: PER_PAGE });
  };

  const handleLoadProfiles = (params) => {
    const { targetItems: newTargetItems } = params;

    if (isShowDirectorsBoardTab(newTargetItems)) handleLoadDirectorsBoard(params);

    handleLoadTargetingSearch(newTargetItems);
    handleLoadExecutives(params).then((data) => {
      setAutocompleteParams(data.meta.autocomplete);
      setTotalCountExecutives(data.meta.totalCount);
    });
  };

  useEffect(() => {
    const defaultTargetItems = buildDefaultTargetItems(targetItems);
    handleLoadProfiles({ targetItems: defaultTargetItems, sort, page: 1 });
    onTargetItemsChange(defaultTargetItems);
  }, []);

  const updateTargetItems = (newSearchParams) => {
    const existsTargetItems = targetItems.filter(({ kind }) => kind === searchKind);
    const currentTargetItems =
      existsTargetItems.length === 0 ? [findOrInitializeTargetItem(targetItems, searchKind)] : existsTargetItems;

    const targetItemsWithNewCriterias = currentTargetItems.map((targetItem) => {
      const searchParamsForTargetItem = buildSearchParamsForScope(newSearchParams, targetItem.scope);
      const criterias = buildTargetItemCriterias(searchParamsForTargetItem);

      return { ...targetItem, criterias };
    });
    const updatedTargetItems = [...reject(propEq(searchKind, 'kind'), targetItems), ...targetItemsWithNewCriterias];

    onTargetItemsChange(updatedTargetItems);

    return updatedTargetItems;
  };

  const targetItemCriteriasCount = (kind) => {
    const { criterias } = findOrInitializeTargetItem(targetItems, kind);

    return criterias.length;
  };

  const targetItemLabel = (label, kind) => {
    const criteriasCount = targetItemCriteriasCount(kind);

    if (criteriasCount > 0) {
      return `${label} (${criteriasCount})`;
    }

    return label;
  };

  const handleSearchKindChange = (newSearchKind) => () => setSearchKind(newSearchKind);

  const handleResetFilters = () => {
    const newSort = DEFAULT_SORT_OPTION.value;
    const updatedTargetItems = updateTargetItems({});

    onTargetItemsChange(changeDirectorsBoardStateIncluded(updatedTargetItems, false));
    handleLoadProfiles({ targetItems: updatedTargetItems, sort: newSort, page: 1 });
  };

  const handleCloseFilter = () => setIsShowFilter(false);

  const shouldExcludeDirectorsBoardScope = (filter, value) => {
    if (!FILTERS_FOR_SHOW_DIRECTORS_BOARD_TAB.includes(filter)) return false;

    const restFiltersForShowDirectorsBoardTab = without(filter, FILTERS_FOR_SHOW_DIRECTORS_BOARD_TAB);
    if (isSearchParamsExists(targetItems, restFiltersForShowDirectorsBoardTab, INCLUSION_KIND)) return false;

    return isBlank(value);
  };

  const shouldIncludeDirectorsBoardScope = (filter, value) => {
    if (isSearchParamsExists(targetItems, FILTERS_FOR_SHOW_DIRECTORS_BOARD_TAB, INCLUSION_KIND)) return false;

    return FILTERS_FOR_SHOW_DIRECTORS_BOARD_TAB.includes(filter) && isPresent(value);
  };

  const handleChangeFilter = (filter) => (value) => {
    const updatedTargetItems = updateTargetItems({ ...searchParams, [filter]: value });
    const shouldChangeDirectorsBoardStateIncludedState =
      shouldExcludeDirectorsBoardScope(filter, value) || shouldIncludeDirectorsBoardScope(filter, value);
    const newTargetItems = shouldChangeDirectorsBoardStateIncludedState
      ? changeDirectorsBoardStateIncluded(updatedTargetItems, shouldIncludeDirectorsBoardScope(filter, value))
      : updatedTargetItems;

    onTargetItemsChange(newTargetItems);
    handleLoadProfiles({ targetItems: newTargetItems, page: 1 });
  };

  const handleSortChange = ({ value }) => {
    setSort(value);

    handleLoadProfiles({ targetItems, sort: value, page: 1 });
  };

  const handleLoadPage = (scope) => (newPage) => {
    const exclusionTargetItem = findTargetItem(targetItems, EXCLUSION_KIND, null);
    const inclusionTargetItem = findTargetItem(targetItems, INCLUSION_KIND, scope);

    const targetItemsForPage = [exclusionTargetItem, inclusionTargetItem].filter(isPresent);
    const queryParams = { targetItems: targetItemsForPage, page: newPage };

    if (scope === DIRECTORS_BOARD_SCOPE || scope === DIRECTORS_BOARD_FTSE100_SCOPE)
      handleLoadDirectorsBoard(queryParams);
    if (scope === EXECUTIVES_SCOPE) handleLoadExecutives(queryParams);

    goToTop();
  };

  const loadOptions = (filter) => (value) => {
    const { [filter]: filterValue, ...restSearchParams } = searchParams;
    const criterias = buildTargetItemCriterias(restSearchParams);
    const targetItem = TargetItemForm.defaultAttributes({ kind: searchKind, criterias });
    const targetItemAttributes = TargetItemForm.attributesToSubmit(targetItem);

    return autocomplete(filter, value, targetItemAttributes).then((response) => response[filter] || []);
  };

  const handleToggleTargetItem = (scope) => (checked) => {
    const targetItem = findTargetItem(targetItems, INCLUSION_KIND, scope);
    const newTargetItems = [...reject(propEq(scope, 'scope'), targetItems), { ...targetItem, included: checked }];

    handleLoadTargetingSearch(newTargetItems);
    onTargetItemsChange(newTargetItems);

    if (scope === EXECUTIVES_SCOPE) toggleProjectDatabaseSearchIncludeExecutives(checked);
    if (scope === DIRECTORS_BOARD_SCOPE) toggleProjectDatabaseSearchIncludeBoardOfDirectors(checked);
    if (scope === DIRECTORS_BOARD_FTSE100_SCOPE) toggleProjectDatabaseSearchIncludeBoardOfDirectorsFtse100(checked);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.side}>
          <div className={styles.searchKinds}>
            <Button
              label={targetItemLabel('Include', INCLUSION_KIND)}
              primary={isSearchKind(INCLUSION_KIND)}
              onClick={handleSearchKindChange(INCLUSION_KIND)}
            />
            <Button
              label={targetItemLabel('Exclude', EXCLUSION_KIND)}
              primary={isSearchKind(EXCLUSION_KIND)}
              onClick={handleSearchKindChange(EXCLUSION_KIND)}
              disabled={targetItemCriteriasCount(INCLUSION_KIND) === 0}
            />
          </div>

          <div className={styles.wrapper}>
            <Filters
              key={`${searchKind}-${totalCountExecutives}`}
              autocomplete={autocompleteBySearchKind}
              disabledFilters={disabledFilters}
              isLoading={targetingSearchExecutivesLoadingStatus.isPending}
              isShown={isShowFilter}
              onClose={handleCloseFilter}
              onFilterChange={handleChangeFilter}
              onLoadOptions={loadOptions}
              onSortChange={handleSortChange}
              searchParams={searchParams}
              sort={sort}
            />
          </div>
        </div>

        <div className={styles.main}>
          <div className={styles.search}>
            <h3 className={styles.header}>
              Selected:&nbsp;
              {targetingSearchesLoadingStatus.isPending ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : (
                <span className={styles.counter}>
                  {NumberPresenter.withDelim(numberSelectedProfiles)}
                  {` `}
                  {pluralize('profile', numberSelectedProfiles)}
                </span>
              )}
            </h3>

            <div>
              <Button className={styles.resetFilters} onClick={handleResetFilters} label="Reset Filters" />
            </div>

            <div className={styles.sortField}>
              <SortSelect placeholder="Sort by" sort={sort} onChange={handleSortChange} />
            </div>
          </div>

          {isPresent(totalCountExecutives) && totalCountExecutives > recommendedProfilesCount && (
            <div className={styles.infoAlert}>
              <h4>
                <AlertIcon />
                For best results limit your filters to {recommendedProfilesCount} executives.
              </h4>
            </div>
          )}

          {isShowDirectorsBoardTab(targetItems) && (
            <div className={styles.actions}>
              <div className={styles.action}>
                <Checkbox onChange={handleToggleTargetItem(EXECUTIVES_SCOPE)} checked={isIncludedExecutives} />
                <div className={styles.checkboxLabel}>Include Executives</div>
              </div>

              <div className={styles.action}>
                <Checkbox onChange={handleToggleTargetItem(DIRECTORS_BOARD_SCOPE)} checked={isIncludedDirectorsBoard} />
                <div className={styles.checkboxLabel}>Include Board of Directors (Fortune 100)</div>
              </div>

              <div className={styles.action}>
                <Checkbox
                  onChange={handleToggleTargetItem(DIRECTORS_BOARD_FTSE100_SCOPE)}
                  checked={isIncludedDirectorsBoardFtse100}
                />
                <div className={styles.checkboxLabel}>Include Board of Directors (FTSE 100)</div>
              </div>

              <div className={styles.action}>
                <QuestionMarkButton
                  onClick={() => {}}
                  text="We have up-to-date profiles on board members at all Fortune 500 and FTSE 100 companies. Use the check-boxes to include or exclude them from your project"
                  placement="top"
                  overlayClassName={styles.tooltip}
                />
              </div>
            </div>
          )}

          <Tabs>
            <TabList>
              <Tab className={styles.tab}>
                <div>Executives</div>
                {targetingSearchExecutivesLoadingStatus.isPending ? (
                  <div className={styles.loader}>
                    <Loader />
                  </div>
                ) : (
                  <div className={styles.numberProfiles}>({totalCountExecutives})</div>
                )}
              </Tab>

              {isShowDirectorsBoardTab(targetItems) && (
                <Tab className={styles.tab}>
                  <div>Board of Directors</div>
                  {targetingSearchDirectorBoardsLoadingStatus.isPending ? (
                    <div className={styles.loader}>
                      <Loader />
                    </div>
                  ) : (
                    <div className={styles.numberProfiles}>({totalCountDirectorBoards})</div>
                  )}
                </Tab>
              )}
            </TabList>

            <TabPanel>
              <ProfilesList
                currentPage={currentPageExecutives}
                isSelectable={false}
                maxPages={MAX_PAGE}
                highlights={highlightsExecutives}
                highlightedKeywords={highlightedKeywordsExecutives}
                onLoadPage={handleLoadPage(EXECUTIVES_SCOPE)}
                perPage={PER_PAGE}
                profiles={targetingSearchExecutives}
                totalCount={totalCountExecutives}
              />
            </TabPanel>

            {isShowDirectorsBoardTab(targetItems) && (
              <TabPanel>
                <ProfilesList
                  currentPage={currentPageDirectorBoards}
                  isSelectable={false}
                  maxPages={MAX_PAGE}
                  highlights={highlightsDirectorBoards}
                  highlightedKeywords={highlightedKeywordsDirectorBoards}
                  onLoadPage={handleLoadPage(DIRECTORS_BOARD_SCOPE)}
                  perPage={PER_PAGE}
                  profiles={targetingSearchDirectorBoards}
                  totalCount={totalCountDirectorBoards}
                />
              </TabPanel>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

TargetingSearchForm.propTypes = {
  targetItems: PropTypes.arrayOf(TargetItemPresenter.shape()).isRequired,
  recommendedProfilesCount: PropTypes.number.isRequired,
  onTargetItemsChange: PropTypes.func.isRequired,
  onChangeNumberProfiles: PropTypes.func.isRequired,
  onChangeProfilesLoadingFlag: PropTypes.func.isRequired,
};

export default TargetingSearchForm;
