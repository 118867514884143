import keyMirror from 'keymirror';

const NewProjectWizardConstants = keyMirror({
  INITIALIZE_NEW_PROJECT_WIZARD: null,

  CHANGE_NEW_PROJECT_FORM: null,

  UPDATE_NEW_PROJECT_SOURCE_ATTRIBUTES: null,

  CREATE_NEW_PROJECT_START: null,
  CREATE_NEW_PROJECT_SUCCESS: null,
  CREATE_NEW_PROJECT_ERROR: null,

  MATCH_NEW_PROJECT_ORGANIZATIONS_START: null,
  MATCH_NEW_PROJECT_ORGANIZATIONS_SUCCESS: null,
  MATCH_NEW_PROJECT_ORGANIZATIONS_ERROR: null,
});

export default NewProjectWizardConstants;
