import { filter, flatten, map, not, pipe, prop } from 'ramda';
import { isBlank } from 'utils/HelperMethods';
import { decamelize, decamelizeString } from 'utils/keysConverter';

const FILTER_PARAM_KEY = 'q';
const SORT_PARAM_KEY = 's';

function parseSortParams({ field, value, preventDecamilization }) {
  return field.split(',').map((f) => {
    const name = preventDecamilization ? f : decamelizeString(f);

    return `${name} ${value}`;
  });
}

function buildSortParams(params) {
  const isValuePresent = pipe(prop('value'), isBlank, not);

  return pipe(filter(isValuePresent), map(parseSortParams), flatten)(params);
}

export function buildFilterParams(params, filterParamKey = FILTER_PARAM_KEY) {
  if (isBlank(params)) {
    return {};
  }

  const { sortFields, ...filterParams } = params;
  const sortParams = isBlank(sortFields) ? {} : { [SORT_PARAM_KEY]: buildSortParams(sortFields) };

  return { [decamelizeString(filterParamKey)]: { ...decamelize(filterParams), ...sortParams } };
}
