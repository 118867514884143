import { equals, isNil, isEmpty } from 'ramda';
import lodashDebounce from 'lodash.debounce';

const DEBOUNCE_TIME = 400;

export function isBlank(value) {
  return isNil(value) || isEmpty(value);
}

export function isPresent(value) {
  return !isBlank(value);
}

export const itself = (value) => value;

export const noop = () => {};

export function parametrize(value) {
  return value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
}

export const equalValues = (firstArray, secondArray) => equals(new Set(firstArray), new Set(secondArray));

// DEPRECATED
export function debounce(func, time = DEBOUNCE_TIME) {
  return lodashDebounce(func, time);
}

// DEPRECATED
export function reactSelectDebounce(func, time = DEBOUNCE_TIME) {
  const wrapFunc = (inputValue, callback) => {
    func(inputValue, callback).then(callback);
  };

  return debounce(wrapFunc, time);
}

export const isBoardexValid = ({ source, url }) =>
  source && url && source.toLowerCase() === 'boardex' && new URL(url).hostname.includes('boardroominsiders.com');
