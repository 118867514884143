import { adminCompanyDepartmentApiV2AccessSettingsRestHooks } from 'slices/admin/company/department/apiV2AccessSettingsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useApiV2AccessSettings = () => {
  const { apiV2AccessSetting, loadApiV2AccessSetting, updateApiV2AccessSetting } =
    adminCompanyDepartmentApiV2AccessSettingsRestHooks.use();

  const resetApiV2AccessSettingsSecret = (companyId, companyDepartmentId) =>
    updateApiV2AccessSetting(companyId, companyDepartmentId, { renewSecret: true });

  return {
    apiV2AccessSettings: apiV2AccessSetting.item,
    apiV2AccessSettingsLoadingStatus: useFetchStatus(apiV2AccessSetting.loadingStatus),
    apiV2AccessSettingsUpdatingStatus: useFetchStatus(apiV2AccessSetting.updatingStatus),
    loadApiV2AccessSettings: loadApiV2AccessSetting,
    resetApiV2AccessSettingsSecret,
    updateApiV2AccessSettings: updateApiV2AccessSetting,
  };
};

export default useApiV2AccessSettings;
