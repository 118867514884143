import keyMirror from 'keymirror';

const ProfileListConstants = keyMirror({
  PROFILES_LOAD: null,
  PROFILES_LOAD_SUCCESS: null,
  PROFILES_LOAD_ERROR: null,

  PROFILES_FILTER: null,
  PROFILES_FILTER_SUCCESS: null,
  PROFILES_FILTER_ERROR: null,

  PROFILES_BULK_DESTROY: null,
  PROFILES_BULK_DESTROY_SUCCESS: null,
  PROFILES_BULK_DESTROY_ERROR: null,
});

export default ProfileListConstants;
