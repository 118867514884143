import SubscriptionsContainerConstants from './SubscriptionsContainerConstants';

const initialState = {
  isSaving: false,
};

function SubscriptionsContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SubscriptionsContainerConstants.CREATE_CURRENT_USER_SUBSCRIPTION_START: {
      return { ...state, isSaving: true };
    }
    case SubscriptionsContainerConstants.CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS: {
      return { ...state, isSaving: false };
    }
    case SubscriptionsContainerConstants.CREATE_CURRENT_USER_SUBSCRIPTION_ERROR: {
      return { ...state, isSaving: true };
    }
    case SubscriptionsContainerConstants.DESTROY_CURRENT_USER_SUBSCRIPTION_START: {
      return { ...state, isSaving: true };
    }
    case SubscriptionsContainerConstants.DESTROY_CURRENT_USER_SUBSCRIPTION_SUCCESS: {
      return { ...state, isSaving: false };
    }
    case SubscriptionsContainerConstants.DESTROY_CURRENT_USER_SUBSCRIPTION_ERROR: {
      return { ...state, isSaving: true };
    }
    default:
      return state;
  }
}

export default SubscriptionsContainerReducer;
