import ViewSkinnyProfileConstants from './ViewSkinnyProfileConstants';
import SkinnyProfilesRepository from 'repositories/SkinnyProfilesRepository';

export function loadPublishedProfile(slug, params = {}) {
  return (dispatch) => {
    dispatch({ type: ViewSkinnyProfileConstants.LOAD_SKINNY_PROFILE_START });

    return SkinnyProfilesRepository.show(slug, params).then(({ profile, meta }) => {
      dispatch({
        type: ViewSkinnyProfileConstants.LOAD_SKINNY_PROFILE_SUCCESS,
        profile,
        ...meta,
      });

      return profile;
    });
  };
}
