import keyMirror from 'keymirror';

const TagListPageConstants = keyMirror({
  INITIALIZE_TAGS_LIST_SUCCESS: null,

  LOAD_TAGS_START: null,
  LOAD_TAGS_SUCCESS: null,
  LOAD_TAGS_ERROR: null,

  CREATE_TAG_START: null,
  CREATE_TAG_SUCCESS: null,
  CREATE_TAG_ERROR: null,

  BULK_DESTROY_TAGS_START: null,
  BULK_DESTROY_TAGS_SUCCESS: null,

  FILTER_TAGS_START: null,
  FILTER_TAGS_SUCCESS: null,

  POPULATE_TAG_START: null,
  POPULATE_TAG_SUCCESS: null,
  POPULATE_TAG_ERROR: null,

  BULK_POPULATE_TAGS_START: null,
  BULK_POPULATE_TAGS_SUCCESS: null,
});

export default TagListPageConstants;
