import React, { useState, useEffect } from 'react';
import { Interval } from 'luxon';
import { isNil } from 'ramda';
import { fromISO, currentDateTime } from 'utils/DateTime';

import { useAdminEditorialDashboardRemainingRequestNumbersStatistics } from 'hooks/admin';

import CompanyPresenter from 'presenters/CompanyPresenter';
import { DAY_TIME_PERIOD_VALUE, MONTH_TIME_PERIOD_VALUE } from 'presenters/TimePeriodPresenter';

import Spinner from 'components/Spinner';

import FiltersForm from './components/FiltersForm';
import Chart from './components/Chart';

import styles from './RemainingRequestNumbersStatisticTab.module.css';

const DEFAULT_START_DATE = currentDateTime();

const DEFAULT_FILTERS = {
  from: DEFAULT_START_DATE.toString(),
  to: DEFAULT_START_DATE.plus({ year: 1 }).toString(),
  accountType: null,
  company: null,
  period: MONTH_TIME_PERIOD_VALUE,
};

const findPeriod = (from, to) => {
  const interval = Interval.fromDateTimes(fromISO(from), fromISO(to));

  return interval.length('month') <= 3 ? DAY_TIME_PERIOD_VALUE : MONTH_TIME_PERIOD_VALUE;
};

const RemainingRequestNumbersStatisticTab = () => {
  const {
    remainingRequestNumbersStatistics,
    loadRemainingRequestNumbersStatistics,
    remainingRequestNumbersStatisticsLoadingStatus,
  } = useAdminEditorialDashboardRemainingRequestNumbersStatistics();

  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [chartData, setChartData] = useState(null);
  const remainingCredits = remainingRequestNumbersStatistics[0]?.totalRemainingRequestsNumber || 0;
  const isShowChart = !isNil(chartData) && remainingRequestNumbersStatisticsLoadingStatus.isFulfilled;

  const handleFiltersChange = () => {
    const { from, to, accountType, company } = filters;
    const newPeriod = findPeriod(from, to);
    const newFilters = { from, to, accountType, companyId: CompanyPresenter.id(company) };

    loadRemainingRequestNumbersStatistics(newFilters).then(({ items }) => {
      const data = items.map((item) => ({ ...item, time: fromISO(item.date).toSeconds() }));

      setChartData({ data, period: newPeriod, fromDate: from, toDate: to });
    });
  };

  useEffect(() => {
    handleFiltersChange();
  }, []);

  return (
    <div className={styles.root}>
      <FiltersForm filters={filters} onFiltersChange={setFilters} onSubmit={handleFiltersChange} />
      <div className={styles.info}>
        Remaining Credits: <span className={styles.markedInfo}>{remainingCredits}</span>
      </div>

      <div className={styles.chart}>
        {remainingRequestNumbersStatisticsLoadingStatus.isPending && <Spinner />}
        {isShowChart && <Chart {...chartData} />}
      </div>
    </div>
  );
};

export default RemainingRequestNumbersStatisticTab;
