import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { compose, sum, map, prop, values, filter, is } from 'ramda';
import { formattedDateFromSeconds } from 'utils/DateTime';

import PointPresenter from 'presenters/EditorialDashboard/CustomerUsageDashboard/PointPresenter';
import CustomizedTooltip from './components/CustomizedTooltip';
import CustomizedLegend from './components/CustomizedLegend';
import Spinner from 'components/Spinner';

import styles from './Chart.module.css';

const DATE_FORMAT = 'dd MMM yyyy';

const PERIOD_TO_LUXON_FORMAT = {
  day: DATE_FORMAT,
  month: 'MMM yyyy',
  year: 'yyyy',
};

const LEGEND_OPTIONS = {
  count: {
    label: 'Current Time Range',
  },
  previousCount: {
    label: 'Previous Time Range',
  },
};

const Chart = (props) => {
  const { title, loading, period, data } = props;

  const totalValue = () => compose(sum, filter(is(Number)), map(prop('count')))(data);

  const getDateFormat = () => PERIOD_TO_LUXON_FORMAT[period];

  const tickFormatter = (tick) => {
    const format = getDateFormat();

    return formattedDateFromSeconds(tick, format);
  };

  const ticks = () => {
    const times = data.map(prop('time')).reduce((acc, value) => {
      acc[tickFormatter(value)] = value;
      return acc;
    }, {});

    return values(times);
  };

  const renderLineChart = () => (
    <LineChart width={600} height={200} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
      <Legend content={<CustomizedLegend options={LEGEND_OPTIONS} />} />
      <CartesianGrid strokeDasharray="0" vertical={false} stroke="#ececec" />
      <XAxis
        dataKey="time"
        scale="time"
        type="number"
        domain={['dataMin', 'dataMax']}
        ticks={ticks()}
        tickFormatter={tickFormatter}
      />
      <YAxis />
      <Tooltip content={<CustomizedTooltip dateFormat={getDateFormat()} />} />
      <Line connectNulls type="monotone" dataKey="count" stroke="#00A0CE" fill="#00A0CE" strokeWidth={3} />
      <Line
        connectNulls
        type="monotone"
        strokeDasharray="5 5"
        dataKey="previousCount"
        stroke="#00A0CE"
        fill="#00A0CE"
        strokeWidth={3}
        dot={{ strokeDasharray: '0' }}
      />
    </LineChart>
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
        <span className={styles.value}>{totalValue()}</span>
      </div>
      <div style={{ overflow: 'hidden' }}>
        <ResponsiveContainer width="100%" height={300}>
          {loading ? <Spinner /> : renderLineChart()}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

Chart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PointPresenter.shape()),
  loading: PropTypes.bool.isRequired,
  period: PropTypes.string,
};

export default Chart;
