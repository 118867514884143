import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v2/Button';
import { useWizardContext } from 'components/v2/Wizard';

import styles from './WizardNavigation.module.css';

const WizardNavigation = (props) => {
  const { isNextButtonDisabled, labelNext } = props;
  const { hasPreviousWizardStep, hasNextWizardStep, navigateNextWizardStep, navigatePreviousWizardStep } =
    useWizardContext();

  return (
    <div className={styles.navigation}>
      {hasPreviousWizardStep && (
        <Button onClick={navigatePreviousWizardStep} variant="outlined">
          Back
        </Button>
      )}
      {hasNextWizardStep && (
        <Button onClick={navigateNextWizardStep} disabled={isNextButtonDisabled}>
          {labelNext}
        </Button>
      )}
    </div>
  );
};

WizardNavigation.propTypes = {
  isNextButtonDisabled: PropTypes.bool,
  labelNext: PropTypes.string,
};

WizardNavigation.defaultProps = {
  isNextButtonDisabled: false,
  labelNext: 'Next',
};

export default WizardNavigation;
