import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(organizationId, query = {}) {
    const url = Routes.apiV1AdminOrganizationEditorWorksPath(organizationId, query);
    return FetchHelpers.get(url);
  },

  update(organizationId, editorWorkId, editorWork) {
    const url = Routes.apiV1AdminOrganizationEditorWorkPath(organizationId, editorWorkId);
    return FetchHelpers.patch(url, { editorWork });
  },

  create(organizationId, editorWork) {
    const url = Routes.apiV1AdminOrganizationEditorWorksPath(organizationId);
    return FetchHelpers.post(url, { editorWork });
  },

  destroy(organizationId, editorWorkId) {
    const url = Routes.apiV1AdminOrganizationEditorWorkPath(organizationId, editorWorkId);
    return FetchHelpers.delete(url);
  },
};
