import React from 'react';
import styles from './KeyChallengesStyleGuide.module.css';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Summarize any obstacles or challenges (either at the company level or relevant to their current role) that could
        keep the executive and their company from succeeding.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <div className={styles.indent}>
        <p>
          <strong>Industry Headwinds</strong>
        </p>
        <p>
          The retail industry is facing massive disruption due to the rapid migration of consumers from brick-and-mortar
          stores to ecommerce. Best Buy is attempting to address this disruptive shift by offering personalized,
          high-touch service to customers who shop both in its stores or online.
        </p>
        <p>
          <strong>Passed Over?</strong>
        </p>
        <p>
          Industry reports in July 2018 speculate that Jones may leave the company now that that it has been announced
          that her main rival to replace John Jingles as CEO has been given the job. On July 6<sup>th</sup> the company
          announced that Roy Joy—not Jones—had been named incoming CEO of ACME.
        </p>
        <p>
          <strong>Trade Regulation</strong>
        </p>
        <p>
          On a July 2018 earnings call, incoming CEO Roy Joy criticized the Trump administration for enacting steel
          tariffs, calling them a threat to many of the industries that use ACME’s components. He also warned analysts
          that the unintended consequences of these tariffs could negatively impact ACME’s business in 2019.
        </p>
      </div>
      <p>
        <strong>Important Note: </strong>
        Some of the above items will live at the “Company” level in our new system and thus will automatically be pulled
        into the profiles. For example, the “Industry Headwinds” and “Trade Regulation” items should live at the company
        level and be assigned to “Business/Strategy” “Sales” and “Finance” roles.
      </p>
      <p>
        <strong>Current Focus &amp; Key Challenges Style Guidelines</strong>
      </p>
      <ul>
        <li>
          In the top field write a headline for the item. It should be brief and all words should be capitalized except
          for prepositions and articles as in the example above. Avoid using vague titles such as “CEO’s Perspective.”
        </li>
        <li>
          You may use bullets (as in the “four priorities” example above.) but do not use paragraph breaks in these
          items.
        </li>
        <li>
          Use last name only when talking about the person (unless you are working at the company record level, in which
          case you need to use the person’s full name and title, since that item will be pulled into other executives’
          profiles). When referencing the words of another executive (e.g., the CEO speaking on an earnings call),
          always include their full name and title, so the reader knows who you are talking about.
        </li>
        <ul>
          <li>e.g., “In April 2018 CEO Hubert Joly said...”</li>
        </ul>
        <li>Make sure every item has a date referenced somewhere so we can determine the date of the item.</li>
        <li>Use “in their own words” whenever possible, from interviews. Cite sources in text and link to them.</li>
        <ul>
          <li>
            e.g., Barry is going to be investing in automation technologies, she told the New York Times in April
            2018...
          </li>
        </ul>
        <li>
          Avoid anything too timely or anything that is written in a way that the timeliness cannot be determined; the
          goal is for this section to be relevant for at least 6 to 12 months.
        </li>
        <ul>
          <li>e.g., Do not write, “Next month the company will roll out a promotion for the holidays...”</li>
          <li>e.g., Do not include financial results from the previous quarter, etc.</li>
        </ul>
        <li>If quoting another executive in the profile, confirm that they are still in the role mentioned.</li>
        <li>
          Avoid beginning bullet points with items that refer to other bullet points such as “He also...” The order of
          the focus items may change over time and the wording may not make sense.
        </li>
        <li>Avoid anything controversial and sensational unless it relevant and well-documented</li>
        <ul>
          <li>
            e.g., we don’t need to know about a sexual harassment scandal or a discrimination lawsuit unless it is
            broadly impacting the company&#39;s reputation or performance, or unless it resulted in the firing of a very
            high-powered exec—or the exec who is being profiled.
          </li>
        </ul>
        <li>
          Avoid being too “wordy.” If the scroll bar shows up when you are typing your item, it is probably too long.
          (Exception - creating a bulleted list with brief bulleted items)
        </li>
        <li>
          Avoid copying entire articles. Find the sections applicable to the executive about whom you are writing and
          include only the relevant sections (with proper source credit)
        </li>
      </ul>
      <p>
        <strong>Sources and Search Tips for This Section</strong>
      </p>
      <ul>
        <li>
          Reading the latest earnings call is a must. These are usually accessible from the profile and company records.
          Search the call transcript for key words that are relevant to the executive you are profiling. E.g., if the
          executive is a CMO, search the transcript for “marketing” “digital” and “advertising.”
        </li>
        <li>As always search for the name of the person, the company name and the current year.</li>
        <li>
          Search for the company name alongside key words from the person’s title. Add the year to your search weed out
          old content. E.g. “Johnson & Johnson” “supply chain” 2019
        </li>
      </ul>
    </div>
  );
}
