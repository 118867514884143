import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { flatten, values, pipe, uniq, join } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import LogInForm from 'forms/LogInForm';

import Typography from 'components/v2/Typography';
import Form, { Field, Input, Checkbox } from 'components/v2/Form';
import Button from 'components/v2/Button';
import Link from 'components/v2/Link';

import styles from './LoginForm.module.css';

const LoginForm = (props) => {
  const { onSubmit } = props;

  const handleSubmit = (form) => {
    const submitAttributes = LogInForm.attributesToSubmit(form);

    return onSubmit({ user: submitAttributes });
  };

  return (
    <div className={styles.root}>
      <Typography variant="h2" component="h1">
        Sign In
      </Typography>

      <Typography variant="body1" color="boulder" className={styles.secondaryText}>
        The content you are trying to access requires.
      </Typography>

      <Form initialValues={LogInForm.defaultAttributes()} onSubmit={handleSubmit}>
        {({ onSubmit: onLogIn, isSubmitting, onFieldChange, values: formValues, errors }) => (
          <>
            <Field title={<span className={styles.label}>Email</span>} className={styles.field}>
              <Input
                className={styles.input}
                type="email"
                value={formValues.email}
                onChange={(value) => onFieldChange('email', value)}
              />
            </Field>

            <Field title={<span className={styles.label}>Password</span>} className={styles.field}>
              <Input
                className={styles.input}
                type="password"
                value={formValues.password}
                onChange={(value) => onFieldChange('password', value)}
              />
            </Field>

            {isPresent(errors) && (
              <Typography variant="body1" color="orange" className={styles.errors}>
                <span dangerouslySetInnerHTML={{ __html: pipe(values, flatten, uniq, join(', '))(errors) }} />
              </Typography>
            )}

            <div className={styles.rememberMeBlock}>
              <Checkbox checked={formValues.remember} onChange={(value) => onFieldChange('remember', value)} />
              <div className={styles.rememberMeLabel}>
                <Typography variant="body1">Remember me</Typography>
              </div>
            </div>

            <Button className={styles.submit} onClick={onLogIn} disabled={isSubmitting}>
              Log In
            </Button>

            <Link href={Routes.resetPasswordPath()}>Forgot password?</Link>
          </>
        )}
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginForm;
