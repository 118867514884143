import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, propEq, propOr } from 'ramda';

import { isPresent } from 'utils/HelperMethods';
import { dateToISO } from 'utils/DateTime';
import { logClickOnUserPanelSearchProfilesScopeFilter } from 'utils/amplitude';

import { SCOPE_FILTER_OPTIONS } from 'forms/ProfileFilteredSearchForm';

import SelectWithDatePicker from 'containers/UserPanel/components/SelectWithDatePicker';

class SearchScopeFilter extends Component {
  static propTypes = {
    date: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  handleChange = (newDate, newValue) => {
    const { date, value, onChange } = this.props;
    const isScopeChanged = (date !== newDate && isPresent(newValue)) || (value !== newValue && isPresent(newDate));

    onChange(newDate, newValue, isScopeChanged);
    this.logFilterChange(newValue);
  };

  logFilterChange = (value) => {
    const findedFilter = find(propEq(value, 'value'))(SCOPE_FILTER_OPTIONS);
    const humanFilterName = propOr('empty', 'label')(findedFilter);

    logClickOnUserPanelSearchProfilesScopeFilter(humanFilterName);
  };

  handleDateChange = (newValue) => this.handleChange(dateToISO(newValue), this.props.value);

  handleValueChange = (newValue) => this.handleChange(this.props.date, newValue);

  render() {
    const { date, value } = this.props;
    const option = SCOPE_FILTER_OPTIONS.find((item) => item.value === value) || null;

    return (
      <SelectWithDatePicker
        datepickerValue={date}
        onDatepickerChange={this.handleDateChange}
        isClearable
        options={SCOPE_FILTER_OPTIONS}
        noBottom
        value={option}
        onValueChange={this.handleValueChange}
      />
    );
  }
}

export default SearchScopeFilter;
