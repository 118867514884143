import React, { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';

export default class StyleGuideModal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    modalContent: PropTypes.func,
  };

  state = {
    isModalVisible: false,
  };

  handleModalShow = () => {
    this.setState({ isModalVisible: true });
  };

  handleModalHide = (e) => {
    e.stopPropagation();
    this.setState({ isModalVisible: false });
  };

  render() {
    const { modalContent, children } = this.props;
    const { isModalVisible } = this.state;

    return (
      <div onClick={this.handleModalShow} role="presentation">
        {children}
        {isModalVisible && (
          <Modal header="Style Guide" onClose={this.handleModalHide} size="large" collapseContentMargins>
            {createElement(modalContent)}
          </Modal>
        )}
      </div>
    );
  }
}
