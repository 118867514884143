import Presenter from 'utils/PropTypesPresenter';

import StatisticsPresenter from './AgingStatistics/StatisticsPresenter';

export default new Presenter(
  {
    editorialAgingStatistics: StatisticsPresenter.shape(),
    customerAgingStatistics: StatisticsPresenter.shape(),
  },
  {},
);
