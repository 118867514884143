import UserPageConstants from './UserPageConstants';

const initialState = {
  loading: false,
  saving: false,
  user: null,
  isResetPasswordEmailSending: false,
};

function UserPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UserPageConstants.LOAD_USER_START: {
      return { ...state, loading: true };
    }
    case UserPageConstants.LOAD_USER_SUCCESS: {
      return { ...state, user: action.user, loading: false };
    }
    case UserPageConstants.UPDATE_USER_START: {
      return { ...state, saving: true };
    }
    case UserPageConstants.UPDATE_USER_SUCCESS: {
      return { ...state, user: action.user, saving: false };
    }
    case UserPageConstants.UPDATE_USER_ERROR: {
      return { ...state, saving: false };
    }
    case UserPageConstants.SEND_USER_RESET_PASSWORD_EMAIL_START: {
      return { ...state, isResetPasswordEmailSending: true };
    }
    case UserPageConstants.SEND_USER_RESET_PASSWORD_EMAIL_SUCCESS: {
      return { ...state, isResetPasswordEmailSending: false };
    }
    case UserPageConstants.SEND_USER_RESET_PASSWORD_EMAIL_ERROR: {
      return { ...state, isResetPasswordEmailSending: false };
    }
    default:
      return state;
  }
}

export default UserPageReducer;
