import React from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { formattedDateFromSeconds } from 'utils/DateTime';
import cn from 'clsx';

import PointPresenter from 'presenters/Company/Dashboard/PointPresenter';

import styles from './CustomizedTooltip.module.css';

const TOOLTIP_OPTIONS = {
  count: {
    label: 'Current',
    dateKey: 'time',
  },
  previousCount: {
    label: 'Previous',
    dateKey: 'previousTime',
  },
};

const generateLineStyles = (strokeDasharray, color) => {
  const classes = cn(styles.line, { [styles.dashed]: isPresent(strokeDasharray) });
  const inlineStyles = { borderColor: color };

  return [classes, inlineStyles];
};

const timeFormatter = (time, dateFormat) => formattedDateFromSeconds(time, dateFormat);

const CustomizedTooltip = (props) => {
  const { active, payload: points, dateFormat } = props;

  const renderPointTooltip = (point, index) => {
    const { color, value, dataKey, strokeDasharray, payload } = point;
    const [classes, inlineStyles] = generateLineStyles(strokeDasharray, color);
    const option = TOOLTIP_OPTIONS[dataKey];
    const time = PointPresenter[option.dateKey](payload);

    return (
      <div key={index} className={styles.legendItem}>
        <div className={styles.legendItemLabel}>
          <strong>{option.label}</strong>
          <hr className={classes} style={inlineStyles} />
        </div>
        <div>Date: {timeFormatter(time, dateFormat)}</div>
        <div>Count: {value}</div>
      </div>
    );
  };

  if (!active || isBlank(points)) {
    return null;
  }

  return <div className={styles.customTooltip}>{points.map((point, index) => renderPointTooltip(point, index))}</div>;
};

CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PointPresenter.shape({})),
  dateFormat: PropTypes.string,
};

export default CustomizedTooltip;
