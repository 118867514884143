import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'containers/UserPanel/components/ExportButton';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner as Spinning } from 'grommet';
import ProfileWithContexts from './components/ProfileWithContexts';

import styles from './ProfilesList.module.css';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import WordPresenter from 'presenters/Project/WordPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

export default class ProfilesList extends Component {
  componentDidMount() {
    const { filters, filterProfilesWithContexts } = this.props;

    filterProfilesWithContexts(filters);
  }

  isAllProfilesLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { project, loading, loadProfilesWithContexts, nextPage, filters } = this.props;

    if (!loading && !this.isAllProfilesLoaded()) {
      loadProfilesWithContexts(ProjectPresenter.id(project), {
        ...filters,
        page: nextPage,
      });
    }
  };

  renderLoader() {
    return (
      <div key="loader" className={styles.loaderBox}>
        <Spinning />
      </div>
    );
  }

  render() {
    const { onExport, totalCount, profilesWithContexts, project, word } = this.props;

    return (
      <div className={styles.container}>
        <h2 className={styles.title} title={WordPresenter.title(word)}>
          {WordPresenter.title(word)}
        </h2>
        <div className={styles.actions}>
          <div className={styles.profiles}>
            Executives: <span>{totalCount}</span>
          </div>
          <BackgroundExportResultNotifier
            className={styles.export}
            component={ExportButton}
            onExport={onExport}
            onExportPropName="onClick"
            disabled={!ProjectPolicy.canExport(project)}
            label="Share"
          />
        </div>
        <div className={styles.list}>
          <InfiniteScroll
            loadMore={this.handleLoadMore}
            hasMore={!this.isAllProfilesLoaded()}
            loader={this.renderLoader()}
            useWindow={false}
          >
            {profilesWithContexts.map(({ profile, contexts }) => (
              <ProfileWithContexts
                key={ProfilePresenter.id(profile)}
                profile={profile}
                contexts={contexts}
                word={word}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

ProfilesList.propTypes = {
  filterProfilesWithContexts: PropTypes.func.isRequired,
  loadProfilesWithContexts: PropTypes.func.isRequired,
  profilesWithContexts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  project: ProfilePresenter.shape().isRequired,
  nextPage: PropTypes.number,
  filters: PropTypes.shape().isRequired,
  onExport: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  word: WordPresenter.shape().isRequired,
};
