import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent, reactSelectDebounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';
import AsyncSelect from 'components/AsyncSelect';

import UserPresenter from 'presenters/UserPresenter';

export default class AssignToEditorModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUserSearch: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  state = { form: { assignee: null }, errors: null };

  handleUserSearch = (value) => {
    const { onUserSearch } = this.props;

    return onUserSearch({ fullNameCont: value, roleIn: ['editor', 'admin'] });
  };

  handleUserSearchDebounced = reactSelectDebounce(this.handleUserSearch);

  handleUserAssign = (assignee) => this.setState({ ...this.state, form: { ...this.state.form, assignee } });

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;
    const { assignee } = form;
    const assigneeId = isPresent(assignee) ? assignee.id : null;

    onSubmit({ assigneeId }).catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  render() {
    const { onClose, saving } = this.props;
    const { form, errors } = this.state;

    return (
      <Modal header="Assignee to Editor" onClose={onClose} size="small" overflow>
        <Box margin={{ bottom: 'small' }}>
          <AsyncSelect
            placeholder="Assignee"
            errors={errors && errors.assignee}
            loadOptions={this.handleUserSearchDebounced}
            defaultOptions
            value={form.assignee}
            onChange={this.handleUserAssign}
            getOptionValue={UserPresenter.id}
            getOptionLabel={UserPresenter.fullName}
          />
        </Box>
        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}
