import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(id) {
    const url = Routes.apiV1AdminProfilePath(id);
    return FetchHelpers.get(url);
  },

  create(params) {
    const url = Routes.apiV1AdminProfilesPath();
    return FetchHelpers.post(url, params);
  },

  index(params = {}) {
    const url = Routes.apiV1AdminProfilesPath(params);
    return FetchHelpers.get(url);
  },

  bulkDestroy(params) {
    const url = Routes.bulkDestroyApiV1AdminProfilesPath(params);
    return FetchHelpers.delete(url);
  },

  update(id, params = {}) {
    const url = Routes.apiV1AdminProfilePath(id);
    return FetchHelpers.put(url, params);
  },

  optOut(id) {
    const url = Routes.optOutApiV1AdminProfilePath(id);
    return FetchHelpers.put(url);
  },
};
