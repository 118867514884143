import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { TabList as ReactTabList } from 'react-tabs';

import { VARIANTS } from 'components/v2/Tabs/Tabs';
import { useTabsContext } from 'components/v2/Tabs/Context';

import styles from './TabList.module.css';

const VARIANT_TAB_LIST_CLASS_NAME = {
  [VARIANTS.DEFAULT]: styles.default,
  [VARIANTS.BUTTONS_GROUP]: styles.buttonsGroup,
};

const TabList = (props) => {
  const { className } = props;

  const { variant } = useTabsContext();

  const variantClassName = VARIANT_TAB_LIST_CLASS_NAME[variant];
  const tabListClassName = clsx(styles.tabList, variantClassName, className);

  return <ReactTabList {...props} className={tabListClassName} />;
};

TabList.tabsRole = 'TabList';

TabList.propTypes = {
  className: PropTypes.string,
};

TabList.defaultProps = {
  className: null,
};

export default TabList;
