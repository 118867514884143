import keyMirror from 'keymirror';

const SavedSearchesContainerConstants = keyMirror({
  LOAD_CURRENT_USER_SAVED_SEARCHES_START: null,
  LOAD_CURRENT_USER_SAVED_SEARCHES_SUCCESS: null,
  LOAD_CURRENT_USER_SAVED_SEARCHES_ERROR: null,

  FILTER_CURRENT_USER_SAVED_SEARCHES_START: null,
  FILTER_CURRENT_USER_SAVED_SEARCHES_SUCCESS: null,
  FILTER_CURRENT_USER_SAVED_SEARCHES_ERROR: null,

  CREATE_CURRENT_USER_SAVED_SEARCH_START: null,
  CREATE_CURRENT_USER_SAVED_SEARCH_SUCCESS: null,
  CREATE_CURRENT_USER_SAVED_SEARCH_ERROR: null,

  UPDATE_CURRENT_USER_SAVED_SEARCH_START: null,
  UPDATE_CURRENT_USER_SAVED_SEARCH_SUCCESS: null,
  UPDATE_CURRENT_USER_SAVED_SEARCH_ERROR: null,

  DELETE_CURRENT_USER_SAVED_SEARCH_START: null,
  DELETE_CURRENT_USER_SAVED_SEARCH_SUCCESS: null,
  DELETE_CURRENT_USER_SAVED_SEARCH_ERROR: null,

  CHANGE_CURRENT_USER_SAVED_SEARCH: null,
});

export default SavedSearchesContainerConstants;
