import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Button from 'components/Button';
import Headline from 'components/grommet/Headline';
import Spinner from 'components/Spinner';
import RequestAssigneeBlock from '../RequestAssigneeBlock';
import RequestPrioritySelect from 'containers/AdminPanel/components/RequestPrioritySelect';

import RequestPresenter from 'presenters/RequestPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import styles from './RequestBlock.module.css';

class RequestBlock extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    request: RequestPresenter.shape(),
    saving: PropTypes.bool.isRequired,
    searchUser: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
  };

  handleRequestUpdateField = (field) => (value) => {
    const { onUpdate, request } = this.props;
    const params = { ...request, [field]: value };

    return onUpdate(params);
  };

  handleRequestMove = (stateEvent) => {
    const { onMove, request } = this.props;
    const params = { ...request, stateEvent };

    onMove(params);
  };

  handleUserAssign = (user) => this.handleRequestUpdateField('assigneeId')(user ? user.id : null);

  handleRequestStateUpdate = (value) => () => this.handleRequestMove(value);

  renderEventButton({ key, title }) {
    const { saving } = this.props;

    return (
      <Box key={key} margin={{ right: 'small', bottom: 'small' }}>
        <Button disabled={saving} label={title} onClick={this.handleRequestStateUpdate(key)} className={styles[key]} />
      </Box>
    );
  }

  render() {
    const { currentUser, loading, request, saving, searchUser } = this.props;

    if (loading || !request) {
      return <Spinner />;
    }

    return (
      <Box className={styles.root}>
        <Box direction="row" align="center" justify="between" margin={{ bottom: 'medium' }}>
          <div className={styles.header}>
            <Headline strong size="small" margin="none" className={styles.headline}>
              Update Request
            </Headline>
            <Box className={styles.stateName}> {RequestPresenter.stateName(request)} </Box>
          </div>
        </Box>
        <Box direction="row" wrap margin={{ bottom: 'small' }}>
          {RequestPresenter.humanStateEvents(request).map((stateEvent) => this.renderEventButton(stateEvent))}
        </Box>
        <Box className={styles.assignee}>
          <RequestAssigneeBlock
            currentUser={currentUser}
            onUserChange={this.handleUserAssign}
            onUserSearch={searchUser}
            request={request}
            saving={saving}
          />
        </Box>
        <Box>
          <RequestPrioritySelect
            currentUser={currentUser}
            isDisabled={saving}
            onChange={this.handleRequestUpdateField('priority')}
            request={request}
            value={RequestPresenter.priority(request)}
          />
        </Box>
      </Box>
    );
  }
}

export default RequestBlock;
