import SavedSearchPresenter from 'presenters/SavedSearchPresenter';
import { isBlank, isPresent } from 'utils/HelperMethods';

export default {
  canEdit(savedSearch) {
    return SavedSearchPresenter.canUpdate(savedSearch);
  },

  canDestroy(savedSearch) {
    return SavedSearchPresenter.canDestroy(savedSearch);
  },

  canSubscribe(savedSearch) {
    const subscriptionId = SavedSearchPresenter.subscriptionId(savedSearch);

    return SavedSearchPresenter.canSubscribe(savedSearch) && isBlank(subscriptionId);
  },

  canUnsubscribe(savedSearch) {
    const subscriptionId = SavedSearchPresenter.subscriptionId(savedSearch);

    return SavedSearchPresenter.canSubscribe(savedSearch) && isPresent(subscriptionId);
  },
};
