import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { Anchor } from 'grommet';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';

import { logClickOnRowOfCustomerRequestHistoryTable } from 'utils/amplitude';

import RequestPresenter from 'presenters/RequestPresenter';

import styles from './RequestRowCells.module.css';

import Routes from 'routes';

class RequestRowCells extends Component {
  static propTypes = {
    row: PropTypes.shape(),
  };

  renderStateName = (request) => {
    if (RequestPresenter.isDelivered(request) || RequestPresenter.isCanceled(request)) {
      return RequestPresenter.stateName(request);
    }

    return 'In Process';
  };

  render() {
    const { row: request } = this.props;
    const isPresentProfile = RequestPresenter.isDelivered(request) && RequestPresenter.slug(request);

    return (
      <>
        <td>{RequestPresenter.formattedCreatedAt(request)}</td>
        <td>
          {isPresentProfile && (
            <Anchor
              className={styles.linkToProfile}
              onClick={logClickOnRowOfCustomerRequestHistoryTable}
              href={Routes.profilePath(RequestPresenter.slug(request))}
              target="_blank"
            >
              {RequestPresenter.subjectFullName(request)}
            </Anchor>
          )}
          {!isPresentProfile && RequestPresenter.subjectFullName(request)}
        </td>
        <td>{RequestPresenter.subjectJobTitle(request)}</td>
        <td>{RequestPresenter.subjectOrganizationName(request)}</td>
        <td>{RequestPresenter.humanRequestType(request)}</td>
        <td>
          <div className={styles.statusWrapper}>
            <span className={styles.statusName}>{this.renderStateName(request)}</span>
            {RequestPresenter.isCanceled(request) && (
              <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={<span>{RequestPresenter.humanCancelationReason(request)}</span>}
                overlayStyle={{ width: 150, wordBreak: 'break-word' }}
              >
                <CircleInformationIcon size="12px" className={styles.statusIcon} />
              </Tooltip>
            )}
          </div>
        </td>
      </>
    );
  }
}

export default RequestRowCells;
