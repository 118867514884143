import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from 'grommet';

import {
  logClickOnCommonThreadsShare,
  logToggleCommonThreadsFilter,
  logToggleCommonThreadsIncludeMyExecutives,
  logClickOnCommonThreadsDetails,
  logClickOnCommonThreadsDetailsShare,
} from 'utils/amplitude';

import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import Cloud from './components/Cloud';
import Sidebar from 'containers/UserPanel/components/Sidebar';
import ProfilesList from './containers/ProfilesList';
import Spinner from 'components/Spinner';
import ExportButton from 'containers/UserPanel/components/ExportButton';
import Header from 'containers/UserPanel/containers/Project/components/Header';
import PageOverlay from 'containers/UserPanel/components/PageOverlay';

import styles from './WordsCloud.module.css';

import WordPresenter from 'presenters/Project/WordPresenter';
import ProjectPresenter, {
  PROJECT_WORDS_CLOUD_PDF_EXPORT,
  PROJECT_WORD_PROFILES_CSV_EXPORT,
} from 'presenters/ProjectPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import Routes from 'routes';

const WORD_SUBTYPES = [
  { value: 'Work', label: 'Companies' },
  { value: 'Board', label: 'Boards' },
  { value: 'Institution', label: 'Education' },
  { value: 'Interest', label: 'Interests' },
];

class WordsCloud extends Component {
  state = {
    isSidebarOpen: false,
    word: null,
  };

  componentDidMount() {
    this.handleLoadWords({ search: { word: { type: 'Work' } }, includeExecutives: false });
  }

  handleLoadWords = (filters) => {
    const { loadWords, project } = this.props;

    loadWords(ProjectPresenter.id(project), filters);
  };

  handleWordsTypesChange = (value) => () => {
    const { filters } = this.props;
    const { search } = filters;

    logToggleCommonThreadsFilter();
    this.handleLoadWords({ ...filters, search: { ...search, word: { type: value } } });
  };

  handleIncludeExecutivesChange = ({ target: { checked } }) => {
    const { filters } = this.props;

    logToggleCommonThreadsIncludeMyExecutives();
    this.handleLoadWords({ ...filters, includeExecutives: checked });
  };

  handleOpenSidebar = (word) => {
    logClickOnCommonThreadsDetails();
    this.setState({ isSidebarOpen: true, word });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebarOpen: false, word: null });
  };

  handleExport = () => {
    const { filters: params, onProjectDataExport } = this.props;

    logClickOnCommonThreadsShare();

    return onProjectDataExport({ type: PROJECT_WORDS_CLOUD_PDF_EXPORT, params });
  };

  handleSidebarExport = () => {
    const { onProjectDataExport } = this.props;
    const parameters = { type: PROJECT_WORD_PROFILES_CSV_EXPORT, params: this.buildSidebarFilters() };

    logClickOnCommonThreadsDetailsShare();
    return onProjectDataExport(parameters);
  };

  isWordTypeSelected = (value) => {
    const { filters } = this.props;
    const { search } = filters;

    return value === search.word?.type;
  };

  breadcrumbs() {
    const { project } = this.props;

    return [
      { name: 'Projects', to: Routes.projectsPath() },
      { name: ProjectPresenter.name(project), to: Routes.projectPath(ProjectPresenter.id(project)) },
      { name: 'Common Threads' },
    ];
  }

  buildSidebarFilters = () => {
    const { word } = this.state;
    const { filters } = this.props;
    const wordTitle = WordPresenter.title(word);

    return { word: wordTitle, ...filters };
  };

  renderCloud() {
    const { project, words } = this.props;
    const { isSidebarOpen, word } = this.state;

    return (
      <>
        <Cloud words={words} onClick={this.handleOpenSidebar} />
        {isSidebarOpen && (
          <PageOverlay>
            <Sidebar onClose={this.handleCloseSidebar}>
              <ProfilesList
                project={project}
                word={word}
                filters={this.buildSidebarFilters()}
                onExport={this.handleSidebarExport}
              />
            </Sidebar>
          </PageOverlay>
        )}
      </>
    );
  }

  renderFilter = (label, value, onClick) => (
    <div key={label} className={styles.control}>
      {label}
      <CheckBox className={styles.filterCheckbox} checked={value} reverse toggle onClick={onClick} />
    </div>
  );

  renderFilters() {
    return (
      <div className={styles.controls}>
        {this.renderFilter(
          'Include My Executives',
          this.props.filters.includeExecutives,
          this.handleIncludeExecutivesChange,
        )}
        <h4>
          <b> Filter by: </b>
        </h4>
        {WORD_SUBTYPES.map((wordSubtype) =>
          this.renderFilter(
            wordSubtype.label,
            this.isWordTypeSelected(wordSubtype.value),
            this.handleWordsTypesChange(wordSubtype.value),
          ),
        )}
      </div>
    );
  }

  render() {
    const { currentUser, loading, project } = this.props;

    return (
      <div className={styles.root}>
        <Header
          currentUser={currentUser}
          title={`${ProjectPresenter.name(project)}: Common Threads`}
          breadcrumbs={this.breadcrumbs()}
          project={project}
        >
          <BackgroundExportResultNotifier
            component={ExportButton}
            onExport={this.handleExport}
            onExportPropName="onClick"
            disabled={!ProjectPolicy.canExport(project)}
            label="Share"
          />
        </Header>
        <div className={styles.content}>
          {this.renderFilters()}
          <div className={styles.words}> {loading ? <Spinner /> : this.renderCloud()} </div>
        </div>
      </div>
    );
  }
}

WordsCloud.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  loadWords: PropTypes.func.isRequired,
  words: PropTypes.arrayOf(WordPresenter.shape()).isRequired,
  filters: PropTypes.shape().isRequired,
  project: ProjectPresenter.shape(),
  onProjectDataExport: PropTypes.func.isRequired,
};

export default WordsCloud;
