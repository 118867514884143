import { pick } from 'ramda';

export default {
  defaultAttributes(profile = {}) {
    const defaultAttributes = { firstName: '', middleName: '', lastName: '', email: '' };

    return {
      ...defaultAttributes,
      ...profile,
    };
  },

  attributesToSubmit(profile) {
    const propsToSend = ['firstName', 'middleName', 'lastName', 'email'];

    return {
      ...pick(propsToSend, profile),
    };
  },
};
