import { useClientRequestsActions, useClientRequestsSelector } from 'slices/client/requestsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export default () => {
  const { bulkCreateRequests } = useClientRequestsActions();
  const { requests } = useClientRequestsSelector();

  return {
    bulkCreateRequests,
    requestsBulkCreatingStatus: useFetchStatus(requests.bulkCreatingStatus),
  };
};
