import { adminEditorialDashboardFortune500ProfilesStatisticsRestHooks } from 'slices/admin/editorialDashboard/databaseAnalytic/fortune500ProfilesStatisticsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useFortune500ProfilesStatistics = () => {
  const { editorialDashboardFortune500ProfilesStatistic, loadEditorialDashboardFortune500ProfilesStatistic } =
    adminEditorialDashboardFortune500ProfilesStatisticsRestHooks.use();

  return {
    editorialDashboardFortune500ProfilesStatistic: editorialDashboardFortune500ProfilesStatistic.item,
    editorialDashboardFortune500ProfilesStatisticLoadingStatus: useFetchStatus(
      editorialDashboardFortune500ProfilesStatistic.loadingStatus,
    ),
    loadEditorialDashboardFortune500ProfilesStatistic,
  };
};

export default useFortune500ProfilesStatistics;
