import { equals } from 'ramda';
import UserListConstants from './UserListConstants';

const initialState = {
  saving: false,
  loading: false,
  users: [],
  nextPage: 1,
  filters: { sortFields: [] },
  totalCount: null,
};

function UserListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UserListConstants.LOAD_COMPANY_USER_START: {
      return { ...state, loading: true };
    }
    case UserListConstants.LOAD_COMPANY_USER_SUCCESS: {
      const { nextPage, totalCount } = action;
      const users = [...state.users, ...action.users];

      return { ...state, users, nextPage, totalCount, loading: false };
    }
    case UserListConstants.FILTER_COMPANY_USER_START: {
      return { ...initialState, loading: true, filters: action.filters };
    }
    case UserListConstants.FILTER_COMPANY_USER_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { nextPage, totalCount, users } = action;

      return { ...state, users, nextPage, totalCount, loading: false };
    }
    case UserListConstants.CREATE_NEW_COMPANY_USER_START: {
      return { ...state, saving: true };
    }
    case UserListConstants.CREATE_NEW_COMPANY_USER_SUCCESS: {
      return { ...state, saving: false };
    }
    case UserListConstants.CREATE_NEW_COMPANY_USER_ERROR: {
      return { ...state, saving: false };
    }
    case UserListConstants.BULK_DESTROY_COMPANY_USER_SUCCESS: {
      return { ...initialState, filters: state.filters };
    }
    default:
      return state;
  }
}

export default UserListReducer;
