import { DatabaseAnalyticsReducers } from './containers/DatabaseAnalytics';
import { TeamProductivityReducers } from './containers/TeamProductivity';
import { CustomerUsageDashboardReducers } from './containers/CustomerUsageDashboard';

export default from './EditorialDashboardPage';

export const EditorialDashboardPageReducers = {
  ...DatabaseAnalyticsReducers,
  ...TeamProductivityReducers,
  ...CustomerUsageDashboardReducers,
};
