import keyMirror from 'keymirror';

const SavedSearchSubscriptionsListContainerConstants = keyMirror({
  LOAD_SAVED_SEARCH_SUBSCRIPTION_START: null,
  LOAD_SAVED_SEARCH_SUBSCRIPTION_SUCCESS: null,
  LOAD_SAVED_SEARCH_SUBSCRIPTION_ERROR: null,

  DELETE_SAVED_SEARCH_SUBSCRIPTION_START: null,
  DELETE_SAVED_SEARCH_SUBSCRIPTION_SUCCESS: null,
  DELETE_SAVED_SEARCH_SUBSCRIPTION_ERROR: null,

  FILTER_SAVED_SEARCH_SUBSCRIPTION_START: null,
  FILTER_SAVED_SEARCH_SUBSCRIPTION_SUCCESS: null,
  FILTER_SAVED_SEARCH_SUBSCRIPTION_ERROR: null,
});

export default SavedSearchSubscriptionsListContainerConstants;
