import { equals, prop } from 'ramda';
import OrganizationListConstants from './OrganizationListConstants';

const initialState = {
  loading: false,
  organizations: [],
  nextPage: 1,
  filters: {
    sortFields: [],
  },
  totalCount: null,
};

function OrganizationListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OrganizationListConstants.LOAD_ORGANIZATIONS_START: {
      return { ...state, loading: true };
    }
    case OrganizationListConstants.LOAD_ORGANIZATIONS_SUCCESS: {
      const { nextPage, totalCount } = action;
      const organizations = [...state.organizations, ...action.organizations.map(prop('draft'))];

      return { ...state, organizations, nextPage, totalCount, loading: false };
    }
    case OrganizationListConstants.LOAD_ORGANIZATIONS_ERROR: {
      return { ...state, loading: false };
    }
    case OrganizationListConstants.FILTER_ORGANIZATIONS_START: {
      return {
        ...initialState,
        loading: true,
        filters: action.filters,
      };
    }
    case OrganizationListConstants.FILTER_ORGANIZATIONS_ERROR: {
      return { ...state, loading: false };
    }
    case OrganizationListConstants.FILTER_ORGANIZATIONS_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { nextPage, totalCount } = action;
      const organizations = action.organizations.map(prop('draft'));

      return { ...state, organizations, nextPage, totalCount, loading: false };
    }
    case OrganizationListConstants.BULK_DESTROY_ORGANIZATIONS_SUCCESS: {
      return { ...state, organizations: [], nextPage: 1, totalCount: null };
    }
    default:
      return state;
  }
}

export default OrganizationListReducer;
