import defaultStyles from './default';

export default (options) => {
  const { container: defaultContainer, control: defaultControl, ...rest } = defaultStyles(options);

  return {
    container: (base) => ({
      ...defaultContainer(base),
      position: 'relative',
      border: '1px solid #E7E7E7',
      height: '40px',
    }),
    control: (base) => ({
      ...defaultControl(base),
      minHeight: 'auto',
      height: '100%',
      paddingLeft: 10,
    }),
    ...rest,
  };
};
