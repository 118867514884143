import { clientCustomerResourceCenterCategoriesReducer } from './client/customer/resourceCenter/categoriesSlice';
import { clientCustomerResourceCenterResourcesReducer } from './client/customer/resourceCenter/resourcesSlice';
import { clientRequestSuggestionsReducer } from './client/requestSuggestionsSlice';
import { clientRequestsHelpRequestReducer } from './client/requestsHelpRequestSlice';
import { clientRequestsReducer } from './client/requestsSlice';
import { clientTargetingSearchExecutivesReducer } from './client/targetingSearch/executivesSlice';
import { clientTargetingSearchDirectorBoardsReducer } from './client/targetingSearch/directorBoardsSlice';
import { clientTargetingSearchReducer } from './client/targetingSearchSlice';

import { currentUserRequestsReducer } from './currentUser/requestsSlice';
import { currentUserExecutivesMatchesReducer } from './currentUser/executivesMatchesSlice';

import { adminCompaniesReducer } from './admin/companiesSlice';
import { adminCompanyDepartmentApiV2AccessSettingsReducer } from './admin/company/department/apiV2AccessSettingsSlice';
import { adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesReducer } from './admin/company/department/limitedAccess/explicitlyGrantedProfilesSlice';
import { adminCompanyDepartmentLimitedAccessReducer } from './admin/company/department/limitedAccessSlice';
import { adminCompanyDepartmentsReducer } from './admin/company/departmentsSlice';
import { adminCompanySingleSignOnReducer } from './admin/company/singleSignOnSlice';
import { adminCompanyLegalSettingReducer } from './admin/company/legalSettingSlice';
import { adminCompanyUsersMatchReducer } from './admin/company/usersMatchSlice';
import { adminCompaniesUsersReducer } from './admin/company/usersSlice';
import { adminCompanyDepartmentsStatisticsReducer } from './admin/company/departmentsStatisticsSlice';
import { adminEditorialDashboardRemainingRequestNumbersStatisticsReducer } from './admin/editorialDashboard/remainingRequestNumbersStatisticsSlice';
import { adminEditorialDashboardFortune500ProfilesStatisticsReducer } from './admin/editorialDashboard/databaseAnalytic/fortune500ProfilesStatisticsSlice';
import { adminEditorialDashboardFtse100ProfilesStatisticsReducer } from './admin/editorialDashboard/databaseAnalytic/ftse100ProfilesStatisticsSlice';
import { adminAttributeSafeCompaniesReducer } from './admin/attributeSafeCompaniesSlice';
import { adminCompanyDashboardRequestsReducer } from './admin/company/dashboard/requestsSlice';
import { adminCompanyDashboardProjectsReducer } from './admin/company/dashboard/projectsSlice';
import { adminProjectsReducer } from './admin/projectsSlice';
import { adminSampleProjectsReducer } from './admin/sampleProjectsSlice';

import { currentUserCompanyDashboardRequestsReducer } from './currentUser/company/dashboard/requestsSlice';
import { currentUserCompanyDashboardProjectsReducer } from './currentUser/company/dashboard/projectsSlice';

export const reducers = {
  clientCustomerResourceCenterCategoriesReducer,
  clientCustomerResourceCenterResourcesReducer,
  clientRequestSuggestionsReducer,
  clientRequestsHelpRequestReducer,
  clientRequestsReducer,
  clientTargetingSearchExecutivesReducer,
  clientTargetingSearchDirectorBoardsReducer,
  clientTargetingSearchReducer,

  currentUserRequestsReducer,
  currentUserExecutivesMatchesReducer,

  adminCompaniesReducer,
  adminCompanyDepartmentApiV2AccessSettingsReducer,
  adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesReducer,
  adminCompanyDepartmentLimitedAccessReducer,
  adminCompanyDepartmentsReducer,
  adminCompanySingleSignOnReducer,
  adminCompanyLegalSettingReducer,
  adminCompanyUsersMatchReducer,
  adminCompaniesUsersReducer,
  adminCompanyDepartmentsStatisticsReducer,
  adminEditorialDashboardRemainingRequestNumbersStatisticsReducer,
  adminEditorialDashboardFortune500ProfilesStatisticsReducer,
  adminEditorialDashboardFtse100ProfilesStatisticsReducer,
  adminAttributeSafeCompaniesReducer,
  adminCompanyDashboardRequestsReducer,
  adminCompanyDashboardProjectsReducer,
  adminProjectsReducer,
  adminSampleProjectsReducer,

  currentUserCompanyDashboardRequestsReducer,
  currentUserCompanyDashboardProjectsReducer,
};
