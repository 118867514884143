import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';

import Breadcrumbs from 'components/Breadcrumbs';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import styles from './Header.module.css';

export default class Header extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    breadcrumbs: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired, path: PropTypes.string })),
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    project: ProjectPresenter.shape().isRequired,
  };

  render() {
    const { currentUser, children, breadcrumbs, project, title } = this.props;
    const ownerFullName = ProjectPresenter.visibleOwnerFullName(project);
    const ownerNameStyle = cn({ [styles.notOwner]: !ProjectPresenter.isCurrentUserOwns(project) });

    return (
      <>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs items={breadcrumbs} />
        </div>
        <div className={styles.headline}>
          <div className={styles.headlineTitle}>{title}</div>
          {children}
        </div>
        <div className={styles.owner}>
          {CurrentUserPresenter.projectsSharingAvailable(currentUser) && (
            <>
              <span>
                Project owner: <span className={ownerNameStyle}>{ownerFullName}</span>
              </span>
              &emsp;|&emsp;
            </>
          )}
          <span>Last Modified: {ProjectPresenter.formattedLastModifiedAt(project)}</span>
        </div>
      </>
    );
  }
}
