import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import QuickSearch from './QuickSearch';
import * as actions from './QuickSearchActions';

const mapStateToProps = (state) => ({
  loading: state.QuickSearchReducer.loading,
  profiles: state.QuickSearchReducer.profiles,
  organization: state.QuickSearchReducer.organization,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);
