import * as d3 from 'd3';
import * as forces from './GraphVisualizerForces';
import { includes, prop } from 'ramda';

import Node, { AVATAR_RADIUS, HEIGHT as NODE_HEIGHT } from './Node';
import Link from './Link';
import BlurFilter, { ID as BLUR_ID } from './BlurFilter';

const STRONG_RELATIONSHIP_THRESHOLD = 0.8;
const STRONG_RELATIONSHIP_COLOR = '#00CE34';
const WEAK_RELATIONOSHIPS_COLOR = '#aaa';
const CUSTOMER_EXECUTIVE_COLOR = '#00A0CE';
const CUSTOMER_BOARD_MEMBER_COLOR = '#FA652C';
const ZOOM_PADDING = 50;

function addDefaultZoomPadding({ x, y, width, height }) {
  const padding = ZOOM_PADDING;

  return {
    x: x + padding,
    y: y + padding,
    width: width - padding,
    height: height - padding,
  };
}

export default class GraphVisualizer {
  constructor(svg, graph, props = {}) {
    const { width, height } = svg.getBoundingClientRect();

    this.graph = graph;
    this.props = props;

    this.svgBounds = { x: 0, y: 0, width, height };
    this.trackMouseEvents = true;

    this.rootContainer = d3.select(svg);
    this.zoomableGroup = this.rootContainer.append('g');
    this.linksGroup = this.zoomableGroup.append('g').attr('class', 'links');
    this.nodesGroup = this.zoomableGroup.append('g').attr('class', 'nodes');
    this.defs = this.renderDefs();

    this.simulation = this.runSimulation(graph, width, height);

    this.zoom = d3
      .zoom()
      .scaleExtent([0.1, 5])
      .on('zoom', (event) => this.zoomableGroup.attr('transform', event.transform));
    this.rootContainer.call(this.zoom);
  }

  runSimulation(graph, width, height) {
    return d3
      .forceSimulation(graph.nodes)
      .force('charge', forces.chargeForce())
      .force('center', forces.centerForce(width, height))
      .force('xAxis', forces.xAxisForce(width))
      .force('yAxis', forces.yAxisForce(height))
      .force('repelForce', forces.repelForce(AVATAR_RADIUS))
      .force('collide', forces.collideForce(AVATAR_RADIUS))
      .force('link', forces.linkForce(graph.links, AVATAR_RADIUS))
      .on('tick', () => this.onSimulationTick(graph));
  }

  stopSimulation() {
    this.simulation.stop();
  }

  onSimulationEnd(onEnd) {
    this.simulation.on('end', onEnd);
  }

  onSimulationTick(graph) {
    const props = {
      strongRelationshipThreshold: STRONG_RELATIONSHIP_THRESHOLD,
      strongRelationshipColor: STRONG_RELATIONSHIP_COLOR,
      weakRelationoshipsColor: WEAK_RELATIONOSHIPS_COLOR,
      customerExecutiveColor: CUSTOMER_EXECUTIVE_COLOR,
      customerBoardMemberColor: CUSTOMER_BOARD_MEMBER_COLOR,
      blurFilderId: BLUR_ID,
      ...this.props,
    };

    this.renderLinks(graph.links, props);
    this.renderNodes(graph.nodes, props);
  }

  onNodeClick(onClick) {
    this.onNodeClickEvent = onClick;
  }

  handleNodeClick = (_, node) => {
    if (this.onNodeClickEvent && this.trackMouseEvents) {
      this.onNodeClickEvent(node);
    }
  };

  handleNodeFocus = (_, node) => {
    if (!this.trackMouseEvents) {
      return;
    }

    Link.selectAll(this.linksGroup).style('opacity', (link) =>
      link.source.id === node?.id || link.target.id === node?.id ? 1 : 0.1,
    );
    Node.selectAll(this.nodesGroup).style('opacity', (anotherNode) =>
      this.graph.neigh(node?.id, anotherNode.id) ? 1 : 0.1,
    );
  };

  handleNodeBlur = () => {
    if (!this.trackMouseEvents) {
      return;
    }

    Link.selectAll(this.linksGroup).style('opacity', 1);
    Node.selectAll(this.nodesGroup).style('opacity', 1);
  };

  handleNodeDragStart = (event, node) => {
    event.sourceEvent.stopPropagation();

    if (!event.active) {
      this.simulation.alphaTarget(0.3).restart();
    }

    const { x, y } = node;

    node.fx = x; // eslint-disable-line no-param-reassign
    node.fy = y; // eslint-disable-line no-param-reassign
  };

  handleNodeDrag = (event, node) => {
    node.fx = event.x; // eslint-disable-line no-param-reassign
    node.fy = event.y; // eslint-disable-line no-param-reassign
  };

  handleNodeDragEnd = (event, node) => {
    if (!event.active) {
      this.simulation.alphaTarget(0);
    }

    node.fx = null; // eslint-disable-line no-param-reassign
    node.fy = null; // eslint-disable-line no-param-reassign
  };

  handleNodeDragEvents() {
    return d3
      .drag()
      .on('start', this.handleNodeDragStart)
      .on('drag', this.handleNodeDrag)
      .on('end', this.handleNodeDragEnd);
  }

  renderDefs() {
    return this.rootContainer.append('defs').call(BlurFilter.mount);
  }

  renderLinks(links, props) {
    Link.selectAll(this.linksGroup)
      .data(links)
      .join(
        (enterSelection) => Link.mount(enterSelection, props),
        (updateSelection) => Link.move(updateSelection, props),
        (exitSelection) => exitSelection.remove(),
      );
  }

  renderNodes(nodes, props) {
    Node.selectAll(this.nodesGroup)
      .data(nodes, prop('id'))
      .join(
        (enterSelection) =>
          Node.mount(enterSelection, props)
            .on('mouseover', this.handleNodeFocus)
            .on('mouseout', this.handleNodeBlur)
            .on('click', this.handleNodeClick)
            .call(this.handleNodeDragEvents()),
        (updateSelection) => Node.move(updateSelection, props),
        (exitSelection) => exitSelection.remove(),
      );
  }

  zoomAreaInBounds = (area, bounds) => {
    const { x, y, width, height } = area;
    const { x: boundsX, y: boundsY, width: boundsWidth, height: boundsHeight } = addDefaultZoomPadding(bounds);
    const boundsCenterX = boundsX + boundsWidth / 2;
    const boundsCenterY = boundsY + boundsHeight / 2;
    const areaCenterX = x + width / 2;
    const areaCenterY = y + height / 2;

    const xRatio = boundsWidth / width;
    const yRatio = boundsHeight / height;
    const minRatio = Math.min(xRatio, yRatio);

    const offsetX = boundsCenterX - areaCenterX * minRatio;
    const offsetY = boundsCenterY - areaCenterY * minRatio;

    this.rootContainer
      .transition()
      .call(this.zoom.transform, d3.zoomIdentity.translate(offsetX, offsetY).scale(minRatio));
  };

  fitGraphInContainer = () => {
    const area = this.zoomableGroup.node().getBBox();
    const bounds = this.svgBounds;

    this.zoomAreaInBounds(area, bounds);
  };

  zoomInNode = (nodeId) => {
    const neighNodesIds = this.graph.getNeighNodesIds(nodeId);
    const nodesIds = [nodeId, ...neighNodesIds];
    const nodes = this.graph.nodes.filter(({ id }) => includes(id)(nodesIds));
    const nodesXs = nodes.map(prop('x'));
    const nodesYs = nodes.map(prop('y'));
    const x = Math.min.apply(null, nodesXs) - AVATAR_RADIUS;
    const y = Math.min.apply(null, nodesYs) - AVATAR_RADIUS;
    const width = Math.abs(Math.max.apply(null, nodesXs) - x) + AVATAR_RADIUS;
    const height = Math.abs(Math.max.apply(null, nodesYs) - y) + NODE_HEIGHT;
    const area = { x, y, width, height };
    const bounds = {
      ...this.svgBounds,
      width: this.svgBounds.width / 2,
    };

    this.zoomAreaInBounds(area, bounds);
  };
}
