import React from 'react';

import ArrowUpIcon from 'icons/ArrowUpIcon';

import styles from './RelationshipsMappingLegend.module.css';

const RelationshipsMappingLegend = () => (
  <div className={styles.root}>
    <div className={styles.connectionStrength}>
      <div>Strongest connection</div>

      <div className={styles.graduation}>
        <div className={styles.cols}>
          <div className={styles.colItem} />
          <div className={styles.colItem} />
          <div className={styles.colItem} />
          <div className={styles.colItem} />
          <div className={styles.colItem} />
          <div className={styles.colItem} />
        </div>

        <div className={styles.arrow}>
          <ArrowUpIcon />
        </div>
      </div>
      <div>Weakest connection</div>
    </div>
  </div>
);

export default RelationshipsMappingLegend;
