import PropTypes from 'prop-types';
import React from 'react';
import { keys, propOr } from 'ramda';

import { defaultStyles, outlinedStyles } from './styles';

const VARIANTS = {
  outlined: outlinedStyles,
  raw: defaultStyles,
};

const StyledReactSelect = (props) => {
  const {
    isDropdownIndicatorVisible,
    isFieldChild,
    reactSelectType: Select,
    variant,
    styles: customStyles,
    ...rest
  } = props;

  const styles = propOr(VARIANTS.outlined, variant)(VARIANTS)({
    isDropdownIndicatorVisible,
    isFieldChild,
    ...customStyles,
  });

  return <Select {...rest} styles={styles} />;
};

StyledReactSelect.propTypes = {
  isDropdownIndicatorVisible: PropTypes.bool,
  isFieldChild: PropTypes.bool,
  reactSelectType: PropTypes.elementType.isRequired,
  variant: PropTypes.oneOf(keys(VARIANTS)),
  styles: PropTypes.shape(),
};

StyledReactSelect.defaultProps = {
  isDropdownIndicatorVisible: true,
  isFieldChild: false,
  variant: 'outlined',
};

export default StyledReactSelect;
