import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formatNumber } from 'utils/NumbersHelper';

export default new Presenter(
  {
    companyName: PropTypes.string,
    profilesNumber: PropTypes.number,
  },
  {
    formattedProfilesNumber(c) {
      return formatNumber(this.profilesNumber(c));
    },
  },
);
