import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Project from './Project';
import * as actions from './ProjectActions';
import { actions as subscriptionsActions } from 'containers/UserPanel/containers/SubscriptionsContainer'; // eslint-disable-line import/named
import { actions as sharesActions } from 'containers/UserPanel/containers/Project/containers/SharesContainer'; // eslint-disable-line import/named

const mapStateToProps = (state, props) => ({
  loading: state.ProjectReducer.loading,
  project: state.ProjectReducer.project,
  projectId: props.match.params.projectId,
});

const { createSubscription, destroySubscription } = subscriptionsActions;
const { destroyProjectShare } = sharesActions;
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createSubscription, destroySubscription, destroyProjectShare, ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Project);
