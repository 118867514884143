import DashboardPageConstants from './DashboardPageConstants';
import DashboardRepository from 'repositories/CurrentUser/DashboardRepository';
import CurrentUserRequestsRepository from 'repositories/CurrentUser/RequestsRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function loadDashboard() {
  return (dispatch) => {
    dispatch({ type: DashboardPageConstants.LOAD_CUSTOMER_DASHBOARD_START });

    return DashboardRepository.show()
      .then(({ dashboard, meta }) => {
        dispatch({ type: DashboardPageConstants.LOAD_CUSTOMER_DASHBOARD_SUCCESS, dashboard });

        return { dashboard, meta };
      })
      .catch((error) => {
        dispatch({ type: DashboardPageConstants.LOAD_CUSTOMER_DASHBOARD_ERROR });

        throw error;
      });
  };
}

export function filterRequestHistory(filters) {
  return (dispatch) => {
    dispatch({ type: DashboardPageConstants.FILTER_REQUEST_HISTORY_START, filters });

    const query = buildFilterParams(filters);

    return CurrentUserRequestsRepository.index(query)
      .then(({ items, meta }) => {
        dispatch({ type: DashboardPageConstants.FILTER_REQUEST_HISTORY_SUCCESS, items, meta });

        return items;
      })
      .catch((error) => {
        dispatch({ type: DashboardPageConstants.FILTER_REQUEST_HISTORY_ERROR });

        throw error;
      });
  };
}
