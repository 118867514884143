import keyMirror from 'keymirror';

const UserCardsConstants = keyMirror({
  LOAD_DATABASE_ANALYTIC_PRIORITY_UPDATES_START: null,
  LOAD_DATABASE_ANALYTIC_PRIORITY_UPDATES_SUCCESS: null,

  CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_START: null,
  CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_SUCCESS: null,
  CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_ERROR: null,
});

export default UserCardsConstants;
