import React from 'react';
import PropTypes from 'prop-types';
import { isNil, has } from 'ramda';
import { matchPath, withRouter } from 'react-router-dom';

import { Tab } from 'components/v2/Tabs';

const RoutedTab = (props) => {
  const { location, history, match, path, staticContext, ...restProps } = props;
  const pathname = has('pathname', path) ? path.pathname : path;
  const selected = !isNil(matchPath(location.pathname, { path: pathname }));

  const handleClick = () => history.push(path);

  return <Tab {...restProps} onClick={handleClick} selected={selected} />;
};

RoutedTab.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  path: PropTypes.oneOfType([PropTypes.shape({ pathname: PropTypes.string }), PropTypes.string]).isRequired,
};

export default withRouter(RoutedTab);
