import DashboardPageConstants from './DashboardPageConstants';

const initialState = {
  dashboard: null,
  isLoading: false,
  loadingCurrenUserRequestsHistory: false,
  currenUserRequestsHistory: [],
  currenUserRequestsHistoryMeta: {},
  currenUserRequestsHistoryFilters: {
    sortFields: [],
  },
};

function DashboardPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case DashboardPageConstants.LOAD_CUSTOMER_DASHBOARD_START: {
      return { ...state, isLoading: true };
    }
    case DashboardPageConstants.LOAD_CUSTOMER_DASHBOARD_SUCCESS: {
      const { dashboard } = action;

      return { ...state, isLoading: false, dashboard };
    }
    case DashboardPageConstants.LOAD_CUSTOMER_DASHBOARD_ERROR: {
      return { ...state, isLoading: false };
    }
    case DashboardPageConstants.FILTER_REQUEST_HISTORY_START: {
      return {
        ...state,
        loadingCurrenUserRequestsHistory: true,
        currenUserRequestsHistoryFilters: action.filters,
        currenUserRequestsHistory: [],
        currenUserRequestsHistoryMeta: {},
      };
    }
    case DashboardPageConstants.FILTER_REQUEST_HISTORY_SUCCESS: {
      const { items, meta } = action;
      return {
        ...state,
        loadingCurrenUserRequestsHistory: false,
        currenUserRequestsHistory: items,
        currenUserRequestsHistoryMeta: meta,
      };
    }
    case DashboardPageConstants.FILTER_REQUEST_HISTORY_ERROR: {
      return { ...state, loadingCurrenUserRequestsHistory: false };
    }
    default:
      return state;
  }
}

export default DashboardPageReducer;
