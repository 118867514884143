import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LineChart, Line, CartesianGrid, Tooltip, ResponsiveContainer, XAxis } from 'recharts';
import CustomizedTooltip from './components/CustomizedTooltip';

import StatisticPresenter from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';

import styles from './Chart.module.css';

export default class Chart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(StatisticPresenter.shape()).isRequired,
    statisticValue: PropTypes.string.isRequired,
  };

  render() {
    const { data, statisticValue } = this.props;

    return (
      <div className={styles.root}>
        <ResponsiveContainer width="100%" height={180}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="0" horizontalPoints={[90]} verticalPoints={[450]} />
            <Tooltip content={<CustomizedTooltip statisticValue={statisticValue} />} />
            <Line type="monotone" dataKey="value" stroke="#00A0CE" dot={false} activeDot={{ r: 5 }} />
            <Line
              type="monotone"
              dataKey="previousValue"
              stroke="#00A0CE"
              dot={false}
              strokeDasharray="3 4 5 2"
              activeDot={{ r: 5 }}
            />
            <XAxis dataKey="textDate" interval="preserveStartEnd" axisLine={false} tickLine={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
