import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { decamelize } from 'utils/keysConverter';

export default {
  show(companyId, query) {
    const url = Routes.apiV1AdminCompanyDepartmentsStatisticsPath(companyId, decamelize(query));

    return FetchHelpers.get(url);
  },
};
