import { AgingStatisticsReducers } from './containers/AgingStatistics';
import { ExecutivesChangesStatisticsReducers } from './containers/ExecutivesChangesStatistics';
import { ProfilesUpdatesStatisticsReducers } from './containers/ProfilesUpdatesStatistics';
import { NewProfilesStatisticsReducers } from './containers/NewProfilesStatistics';
import { UserCardsReducers } from './containers/UserCards';
import { OrganizationUpdatesReducers } from './containers/OrganizationUpdates';
import { PopularOrganizationTranscriptsReducers } from './containers/PopularOrganizationTranscripts';

export default from './DatabaseAnalytics';

export const DatabaseAnalyticsReducers = {
  ...AgingStatisticsReducers,
  ...ExecutivesChangesStatisticsReducers,
  ...ProfilesUpdatesStatisticsReducers,
  ...NewProfilesStatisticsReducers,
  ...UserCardsReducers,
  ...OrganizationUpdatesReducers,
  ...PopularOrganizationTranscriptsReducers,
};
