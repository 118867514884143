import DOMPurify from 'dompurify';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { isEmpty, isNil } from 'ramda';

const convertHtmlToRaw = (html) => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);
  const content = editorState.getCurrentContent();
  return convertToRaw(content);
};

const convertRawToHtml = (raw) => draftToHtml(raw);

export const trimExtraEmptyLinesFromHTML = (html) => {
  if (isEmpty(html) || isNil(html)) {
    return html;
  }

  const raw = convertHtmlToRaw(html);

  raw.blocks = raw.blocks
    .reverse()
    .reduce((acc, e) => (e.text === '' && acc.length === 0 ? acc : [...acc, e]), [])
    .reverse();

  return convertRawToHtml(raw);
};

export const sanitizeHtml = (html, { additionalWhitelistedTags = [] } = {}) =>
  DOMPurify.sanitize(html, { USE_PROFILES: { html: true }, ADD_TAGS: additionalWhitelistedTags, ADD_ATTR: ['target'] });
