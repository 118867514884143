import { pick } from 'ramda';
import InterestPresenter from 'presenters/InterestPresenter';

export default {
  defaultAttributes(interest) {
    return {
      title: '',
      ...interest,
      isActive: InterestPresenter.isActive(interest),
    };
  },

  attributesToSubmit(newInterest, oldInterest) {
    let propsToSend = ['title'];

    if (newInterest.isActive !== InterestPresenter.isActive(oldInterest)) {
      propsToSend = [...propsToSend, 'stateEvent'];
    }

    return {
      ...pick(propsToSend, {
        ...newInterest,
        stateEvent: newInterest.isActive ? 'activate' : 'disable',
      }),
    };
  },
};
