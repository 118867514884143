import { buildFilterParams } from 'utils/FilterParamsBuilder';
import CustomerUsageDashboardProjectsConstants from './CustomerUsageDashboardProjectsConstants';
import ProjectsRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/ProjectsRepository';

export function loadProjects({ page, per, from, to, ...filters }) {
  return (dispatch) => {
    dispatch({ type: CustomerUsageDashboardProjectsConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_START });

    const params = { page, per, from, to, ...buildFilterParams(filters) };
    return ProjectsRepository.index(params).then(({ items, meta }) => {
      dispatch({
        type: CustomerUsageDashboardProjectsConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_SUCCESS,
        projects: items,
        ...meta,
      });
    });
  };
}

export function filterProjects({ from, to, ...filters }) {
  return (dispatch) => {
    dispatch({
      type: CustomerUsageDashboardProjectsConstants.FILTER_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_START,
      filters,
    });

    const params = { from, to, ...buildFilterParams(filters) };
    return ProjectsRepository.index(params).then(({ items, meta }) => {
      dispatch({
        type: CustomerUsageDashboardProjectsConstants.FILTER_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_SUCCESS,
        projects: items,
        filters,
        ...meta,
      });
    });
  };
}
