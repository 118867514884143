import React, { Component } from 'react';
import { isBlank } from 'utils/HelperMethods';

import CompanyPresenter from 'presenters/CompanyPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter from 'presenters/UserPresenter';
import RequestPolicy from 'policies/RequestPolicy';

import styles from './RequestRowCells.module.css';

class RequestRowCells extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    row: RequestPresenter.shape(),
  };

  deliveredDate(request) {
    const deliveredDate = RequestPresenter.formattedDeliveredAt(request);

    if (deliveredDate) {
      return deliveredDate;
    }

    return RequestPresenter.isCanceled(request) ? 'Canceled' : 'Pending';
  }

  renderCell(content, field) {
    const { currentUser } = this.props;

    if (!RequestPolicy.isColumnPermitted(currentUser, field)) {
      return null;
    }

    return <td id={field}>{content}</td>;
  }

  renderPriority() {
    const { row: request } = this.props;
    const priority = RequestPresenter.priority(request);

    if (isBlank(priority)) {
      return null;
    }

    const backgroundColor = RequestPresenter.priorityColor(request);
    const priorityName = RequestPresenter.priorityName(request);

    return (
      <div className={styles.priority}>
        <div className={styles.priorityColor} style={{ backgroundColor }} />
        <div>{priorityName}</div>
      </div>
    );
  }

  render() {
    const { row: request } = this.props;
    const requestor = RequestPresenter.requestor(request);
    const company = RequestPresenter.company(request);
    const assignee = RequestPresenter.assignee(request);

    return (
      <>
        {this.renderCell(this.renderPriority(), 'complexPriority')}
        {this.renderCell(RequestPresenter.stateName(request), 'state')}
        {this.renderCell(RequestPresenter.humanRequestType(request), 'requestType')}
        {this.renderCell(requestor && UserPresenter.fullName(requestor), 'requestorFullName')}
        {this.renderCell(company && CompanyPresenter.name(company), 'companyName')}
        {this.renderCell(RequestPresenter.subjectFullName(request), 'subjectFullName')}
        {this.renderCell(RequestPresenter.subjectJobTitle(request), 'subjectJobTitle')}
        {this.renderCell(RequestPresenter.subjectOrganizationName(request), 'subjectOrganizationName')}
        {this.renderCell(assignee && UserPresenter.fullName(assignee), 'assigneeFullName')}
        {this.renderCell(RequestPresenter.formattedCreatedAt(request), 'createdAt')}
        {this.renderCell(RequestPresenter.formattedDueDate(request), 'dueDate')}
        {this.renderCell(this.deliveredDate(request), 'deliveredAt')}
      </>
    );
  }
}

export default RequestRowCells;
