import React from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import RelationshipsMappingPresenter from 'presenters/RelationshipsMappingPresenter';

import Loader from 'components/v2/Loader';
import RelationshipsMappingGraph from 'containers/UserPanel/components/RelationshipsMappingGraph';

import styles from './Graph.module.css';

const Graph = (props) => {
  const { loading, profile, relationshipsMapping, onProfileRelationshipsSidebarOpen, isAllProjectFiltersDisabled } =
    props;

  if (loading || isBlank(relationshipsMapping)) {
    return (
      <div className={styles.centeredContainer}>
        <Loader />
      </div>
    );
  }

  if (isAllProjectFiltersDisabled) {
    return (
      <div className={styles.centeredContainer}>
        <p className={styles.notificationTitle}>
          No connections found. Please check that Executives or Board of Directors is selected under My Project.
        </p>
      </div>
    );
  }

  if (RelationshipsMappingPresenter.links(relationshipsMapping).length === 0) {
    return (
      <div className={styles.centeredContainer}>
        <p className={styles.notificationTitle}>
          No tangible connections between your chosen executives have been found. Consider adding additional executives
          to your project.
        </p>
      </div>
    );
  }

  return (
    <RelationshipsMappingGraph
      onNodeClick={onProfileRelationshipsSidebarOpen}
      mapping={relationshipsMapping}
      zoomedNodeId={profile && profile.id}
    />
  );
};

Graph.propTypes = {
  loading: PropTypes.bool.isRequired,
  relationshipsMapping: RelationshipsMappingPresenter.shape(),
  profile: PropTypes.shape(),
  onProfileRelationshipsSidebarOpen: PropTypes.func.isRequired,
  isAllProjectFiltersDisabled: PropTypes.bool.isRequired,
};

export default Graph;
