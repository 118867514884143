import ExecutivesChangesStatisticsConstants from './ExecutivesChangesStatisticsConstants';
import ExecutivesChangesStatisticsRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/ExecutivesChangesStatisticsRepository';

export function loadExecutivesChangesStatistics() {
  return (dispatch) => {
    dispatch({
      type: ExecutivesChangesStatisticsConstants.LOAD_DATABASE_ANALYTICS_EXECUTIVES_CHANGES_STATISTICS_START,
    });

    return ExecutivesChangesStatisticsRepository.show()
      .then(({ executivesChangesStatistics }) => {
        dispatch({
          type: ExecutivesChangesStatisticsConstants.LOAD_DATABASE_ANALYTICS_EXECUTIVES_CHANGES_STATISTICS_SUCCESS,
          executivesChangesStatistics,
        });

        return executivesChangesStatistics;
      })
      .catch((error) => {
        dispatch({
          type: ExecutivesChangesStatisticsConstants.LOAD_DATABASE_ANALYTICS_EXECUTIVES_CHANGES_STATISTICS_ERROR,
          error,
        });

        throw error;
      });
  };
}
