/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function resetResourceSlice(options) {
  const { pluralResource } = options;

  return {
    reducers: {
      resetResource(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.idle;
        state[pluralResource].creatingStatus = FETCH_STATUSES.idle;
        state[pluralResource].deleteStatus = FETCH_STATUSES.idle;
        state[pluralResource].updatingStatus = FETCH_STATUSES.idle;
        state[pluralResource].item = null;
        state[pluralResource].error = null;
      },
    },
    actionCreators(restDispatch) {
      return {
        resetResource: () => {
          restDispatch('resetResource');
        },
      };
    },
    abstractSelector: (state) => ({ resource: state[pluralResource] }),
  };
}
