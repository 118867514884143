import PropTypes from 'prop-types';

import { isEmpty, isNil, filter, propEq, sortBy } from 'ramda';
import { formattedDate } from 'utils/DateTime';
import { isPresent } from 'utils/HelperMethods';
import Presenter from 'utils/PropTypesPresenter';

import IndustryPresenter from 'presenters/IndustryPresenter';
import NaicsIndustryCodePresenter from 'presenters/NaicsIndustryCodePresenter';
import Topic from './TopicPresenter';
import CountryPresenter from './CountryPresenter';
import StatePresenter from './StatePresenter';
import TagPresenter from 'presenters/TagPresenter';
import AliasPresenter from 'presenters/AliasPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    industry: IndustryPresenter.shape(),
    naicsIndustryCode: NaicsIndustryCodePresenter.shape(),
    fortuneRank: PropTypes.number,
    ftseRank: PropTypes.number,
    corporateDuns: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: StatePresenter.shape(),
    zip: PropTypes.string,
    country: CountryPresenter.shape(),
    phone: PropTypes.string,
    fax: PropTypes.string,
    emailNameConvention: PropTypes.string,
    url: PropTypes.string,
    topics: PropTypes.arrayOf(Topic.shape()),
    logoUpdatedAt: PropTypes.string,
    logoUrl: PropTypes.string,
    ticker: PropTypes.string,
    ricCode: PropTypes.string,
    publishedAt: PropTypes.string,
    request: PropTypes.shape(),
    organizationUpdatedAt: PropTypes.string,
    assotiatedProfilesCount: PropTypes.number,
    organizationInformationDigest: PropTypes.string,
    contactInformationDigest: PropTypes.string,
    sources: PropTypes.string,
    hasPublishedVersion: PropTypes.bool,
    boardOfDirectorsListUrl: PropTypes.string,
    tags: PropTypes.arrayOf(TagPresenter.shape()),
    highlights: PropTypes.arrayOf(PropTypes.shape()),
    aliases: PropTypes.arrayOf(AliasPresenter.shape()),
    children: PropTypes.arrayOf(PropTypes.shape()),
    parent: PropTypes.shape({}),
    companyLeadershipPage: PropTypes.string,
    global2000Rank: PropTypes.number,
  },
  {
    isDraft(organization) {
      return this.status(organization) === 'draft';
    },
    isOutdated(organization) {
      return this.status(organization) === 'outdated';
    },
    currentFocusTopics(organization) {
      return filter(propEq('current_focus', 'kind'), this.topics(organization));
    },

    keyChallengesTopics(organization) {
      return filter(propEq('key_challenges', 'kind'), this.topics(organization));
    },

    formattedLogoUpdatedAt(organization) {
      return formattedDate(this.logoUpdatedAt(organization));
    },

    formattedOrganizationUpdatedAt(p) {
      return formattedDate(this.organizationUpdatedAt(p));
    },

    isLogoPresent(organization) {
      return !isNil(this.logoUrl(organization));
    },
    formattedPublishedAt(p) {
      return formattedDate(this.publishedAt(p));
    },
    formattedUrl(p) {
      return this.url(p);
    },
    statusName(organization) {
      if (this.isDraft(organization)) {
        return `unpublished`;
      }

      if (this.isOutdated(organization)) {
        return `deleted`;
      }

      return null;
    },
    nameWithState(organization) {
      const statusName = this.statusName(organization);

      return isPresent(statusName) ? `${this.name(organization)} (${statusName})` : this.name(organization);
    },
    nameWithStateAndCount(organization) {
      return `${this.nameWithState(organization)} [${this.assotiatedProfilesCount(organization)}]`;
    },
    countryName(organization) {
      const country = this.country(organization);

      return country ? CountryPresenter.name(country) : null;
    },
    stateName(organization) {
      const state = this.state(organization);

      return state ? StatePresenter.name(state) : null;
    },
    cityWithStateAndZipCode(organization) {
      const address = [this.city(organization), this.stateName(organization), this.zip(organization)];
      const filtered = address.filter((word) => !(isEmpty(word) || isNil(word)));

      return filtered.join(', ');
    },
    industryName(organization) {
      const industry = this.industry(organization);

      return industry ? IndustryPresenter.name(industry) : null;
    },
    naicsIndustryCodeName(organization) {
      const naicsIndustryCode = this.naicsIndustryCode(organization);

      return naicsIndustryCode ? NaicsIndustryCodePresenter.name(naicsIndustryCode) : null;
    },
    orderedAliases(organization) {
      const aliases = this.aliases(organization);

      return sortBy(AliasPresenter.title, aliases);
    },
    parentId(organization) {
      const parent = this.parent(organization);

      return isPresent(parent) ? parent.id : null;
    },
    isMemberFortune500(organization) {
      return this.fortuneRank(organization) > 0 && this.fortuneRank(organization) < 500;
    },
    isMemberFortune1000(organization) {
      return this.fortuneRank(organization) > 0 && this.fortuneRank(organization) < 1000;
    },
    isMemberFtse100(organization) {
      return this.ftseRank(organization) > 0 && this.ftseRank(organization) <= 100;
    },
  },
);
