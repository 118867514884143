import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    time: PropTypes.number,
    count: PropTypes.number,
    previousCount: PropTypes.number,
    textTime: PropTypes.string,
    previousTextTime: PropTypes.string,
    previousTime: PropTypes.number,
  },
  {},
);
