import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { head } from 'ramda';
import { TextInput, Box } from 'grommet';
import { PasswordInput } from 'grommet-controls';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Select from 'components/Select';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import PasswordValidator from 'components/PasswordValidator';
import PasswordStrengthRating from 'components/PasswordStrengthRating';

import { ROLES } from 'presenters/UserPresenter';

import styles from './NewUserForm.module.css';

const ROLE_OPTIONS = ROLES.map((r) => ({ label: r.title, value: r.key }));

const NewUserForm = (props) => {
  const { onSubmit, saving } = props;

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    role: head(ROLES).key,
  });
  const [errors, setErrors] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const handleSubmit = () => {
    setErrors(null);

    onSubmit({ user }).catch((data) => {
      setErrors(data.errors);
    });
  };

  const updateField = (fieldName, value) => {
    setUser({ ...user, [fieldName]: value });
  };

  const handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      updateField(fieldName, value);

  const handleSelectChange = (fieldName) => (value) => updateField(fieldName, value);

  const handleValidationPassword = (isValid) => setIsValidPassword(isValid);

  return (
    <div>
      <VerticalFormFieldWithBottomErrors label="First Name:" errors={errors && errors.firstName} htmlFor="firstName">
        <TextInput value={user.firstName} onChange={handleTextFieldChange('firstName')} id="firstName" />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="Last Name:" errors={errors && errors.lastName} htmlFor="lastName">
        <TextInput value={user.lastName} onChange={handleTextFieldChange('lastName')} id="lastName" />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="Email:" errors={errors && errors.email} htmlFor="email">
        <TextInput value={user.email} onChange={handleTextFieldChange('email')} id="email" />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="Password:" errors={errors && errors.password} htmlFor="password">
        <PasswordInput value={user.password} onChange={handleTextFieldChange('password')} id="password" />
      </VerticalFormFieldWithBottomErrors>

      {isPresent(user.password) && (
        <>
          <div className={styles.passwordStrengthRating}>
            <PasswordStrengthRating isValidPassword={isValidPassword} />
          </div>
          <div className={styles.passwordValidator}>
            <PasswordValidator password={user.password} onValidation={handleValidationPassword} />
          </div>
        </>
      )}

      <VerticalFormFieldWithBottomErrors
        label="Password Confirmation:"
        errors={errors && errors.passwordConfirmation}
        htmlFor="passwordConfirmation"
      >
        <PasswordInput
          value={user.passwordConfirmation}
          onChange={handleTextFieldChange('passwordConfirmation')}
          id="passwordConfirmation"
        />
      </VerticalFormFieldWithBottomErrors>
      <Select
        placeholder="Account Type"
        selectedOptionValue={user.role}
        options={ROLE_OPTIONS}
        onValueChange={handleSelectChange('role')}
      />
      <Box direction="row" margin={{ top: 'small' }} width="medium">
        <Button label="Save" disabled={saving} primary onClick={handleSubmit} />
      </Box>
    </div>
  );
};

NewUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default NewUserForm;
