import React from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Button from 'components/Button';
import OrganizationAvatar from 'components/OrganizationAvatar';

import styles from './MatchItem.module.css';

const MatchItem = (props) => {
  const { item, isIncluded, onInclusionChange } = props;
  const { organizationName, organization, executivesCount, directorsBoardsCount } = item;
  const organizatioonLogoUrl = isPresent(organization) ? organization.logoUrl : null;
  const buttonLabel = isIncluded ? 'Exclude' : 'Include';
  const handleInclusionChange = () => onInclusionChange(item);

  return (
    <div className={styles.root}>
      <div className={styles.avatar}>
        <OrganizationAvatar src={organizatioonLogoUrl} width="100" height="100" />
      </div>

      <div className={styles.info}>
        <div className={styles.row}>
          <div className={styles.title}> Looked For: </div>
          <div className={styles.content}>{organizationName}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}> Found: </div>
          <div className={cn(styles.content, styles.founded)}>{isPresent(organization) && organization.name}</div>
        </div>
        {isPresent(organization) && (
          <>
            <div className={styles.divider} />

            <div className={styles.profilesStatistics}>
              <span className={styles.profilesCount}>Executives ({executivesCount})</span>
              <span className={styles.profilesCount}>Board of Directors ({directorsBoardsCount})</span>
            </div>

            <Button
              box
              className={styles.button}
              label={buttonLabel}
              onClick={handleInclusionChange}
              primary={!isIncluded}
            />
          </>
        )}
      </div>
    </div>
  );
};

MatchItem.propTypes = {
  item: PropTypes.shape().isRequired,
  isIncluded: PropTypes.bool.isRequired,
  onInclusionChange: PropTypes.func.isRequired,
};

export default MatchItem;
