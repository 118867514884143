import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, prop, propOr, not, test, take } from 'ramda';
import { Anchor, CheckBox } from 'grommet';
import { DocumentCsv as DocumentCsvIcon, Download } from 'grommet-icons';

import { isSameValues } from 'utils/ArrayHelpers';
import { pluralize } from 'utils/pluralizer';
import { logClickOnUserPanelSearchProfilesDownloadProfile } from 'utils/amplitude';

import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import DownloadAnchor from 'components/DownloadAnchor';
import ProfilesExportResultNotifier from 'containers/UserPanel/components/ProfilesExportResultNotifier';

import { FILTERS } from 'forms/ProfileFilteredSearchForm';

import UserPolicy from 'policies/UserPolicy';
import ProfilePolicy from 'policies/ProfilePolicy';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import {
  FILTERED_SEARCH_PROFILES_CSV_EXPORT_JOB_TYPE,
  PROFILES_PDF_EXPORT_JOB_TYPE,
} from 'presenters/ProfilePresenter';

import ProfileFilteredSearchForm from 'forms/ProfileFilteredSearchForm';

import styles from './Actions.module.css';

const EXPORTABLE_COLUMNS = {
  company_name: () => true,
  first_name: () => true,
  last_name: () => true,
  title: () => true,
  phone: (_filters, currentUser) => {
    if (not(UserPolicy.canSeePhone(currentUser))) {
      return false;
    }
    return true;
  },
  email: (_filters, currentUser) => {
    if (not(UserPolicy.canSeeEmail(currentUser))) {
      return false;
    }
    return true;
  },
  origin_country: () => true,
  work_address: (_filters, currentUser) => {
    if (not(UserPolicy.canSeeAddress(currentUser))) {
      return false;
    }
    return true;
  },
  twitter_url: () => true,
  facebook_url: () => true,
  linkedin_url: () => true,
  blog_url: () => true,
  industry_name: () => true,
  industry_code: () => true,
  corporate_duns: () => true,
  pdf_url: () => true,
  profile_url: () => true,
  fortune_rank: (filters) => {
    const value = filters[FILTERS.CURRENT_ORGANIZATION_RANK_LTE];
    const isFortuneValue = test(/fortune/, take(1, value));

    return !isNil(value) && !isEmpty(value) && isFortuneValue;
  },
  ftse_rank: (filters) => {
    const value = filters[FILTERS.CURRENT_ORGANIZATION_RANK_LTE];
    const isFtseValue = test(/ftse/, take(1, value));

    return !isNil(value) && !isEmpty(value) && isFtseValue;
  },
};

const prepareExportCSVParams = (ids, filters, keywords, currentUser) => {
  const search = propOr({}, 'q', ProfileFilteredSearchForm.attributesToSubmit(filters));
  const columnNames = Object.keys(EXPORTABLE_COLUMNS).filter((columnName) =>
    EXPORTABLE_COLUMNS[columnName](filters, currentUser),
  );

  if (ids.length > 0) {
    return { columnNames, keywords, ids };
  }

  return { columnNames, search, keywords };
};

const Actions = (props) => {
  const {
    currentUser,
    filters,
    keywords,
    onBackgroundExport,
    onSelectedProfileIdsChange,
    profiles,
    selectedProfileIds,
  } = props;

  const profileIds = profiles.map(prop('id'));
  const isAnyProfileSelected = selectedProfileIds.length > 0;
  const isAllProfilesSelected = profiles.length > 0 && isSameValues(profileIds, selectedProfileIds);

  const handleAllProfileSelection = () =>
    isAllProfilesSelected ? onSelectedProfileIdsChange([]) : onSelectedProfileIdsChange(profileIds);
  const handlePDFExport = () => {
    logClickOnUserPanelSearchProfilesDownloadProfile();
    return onBackgroundExport({
      type: PROFILES_PDF_EXPORT_JOB_TYPE,
      params: { ids: selectedProfileIds, keywords, currentUserId: CurrentUserPresenter.id(currentUser) },
    });
  };
  const handleCSVExport = () =>
    onBackgroundExport({
      type: FILTERED_SEARCH_PROFILES_CSV_EXPORT_JOB_TYPE,
      params: prepareExportCSVParams(selectedProfileIds, filters, keywords, currentUser),
    });

  const renderDownloadText = (text, unselectedText) => {
    const countSelectedFields = selectedProfileIds.length;

    if (selectedProfileIds.length > 0) {
      return `${text} ${pluralize('Profiles', countSelectedFields, true)}`;
    }

    return unselectedText;
  };

  return (
    <div className={styles.options}>
      <CheckBox checked={isAllProfilesSelected} onChange={handleAllProfileSelection} />
      <div>
        {ProfilePolicy.canSeeDownloadButton(currentUser) && (
          <ProfilesExportResultNotifier
            disabled={!isAnyProfileSelected}
            className={styles.download}
            component={DownloadAnchor}
            onExport={handlePDFExport}
            onExportPropName="onClick"
            icon={<Download size="14px" />}
            label={renderDownloadText('Download', 'Select Profiles to Download')}
          />
        )}
        {UserPolicy.canExportSearchResultInCsv(currentUser) && (
          <BackgroundExportResultNotifier
            className={styles.download}
            component={Anchor}
            onExport={handleCSVExport}
            onExportPropName="onClick"
            icon={<DocumentCsvIcon size="14px" />}
            label={renderDownloadText('Export in CSV', 'Export All Profiles in CSV')}
          />
        )}
      </div>
    </div>
  );
};

Actions.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  onBackgroundExport: PropTypes.func.isRequired,
  onSelectedProfileIdsChange: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
  selectedProfileIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Actions;
