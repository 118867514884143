import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import { Spinner as Spinning } from 'grommet';

import styles from './ExportButton.module.css';

export default class ExportButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  state = { isLoading: false };

  handleClick = (event) => {
    const { onClick } = this.props;

    this.setState({ isLoading: true });
    onClick(event).finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const { disabled, label, className: customClassName, ...props } = this.props;
    const { isLoading } = this.state;
    const isDisabled = isLoading || disabled;
    const className = cx(styles.button, {
      [styles.disabled]: isDisabled,
      [customClassName]: isPresent(customClassName),
    });

    return (
      <button className={className} disabled={isDisabled} {...props} onClick={this.handleClick}>
        {isLoading && (
          <div className={styles.spinning}>
            <Spinning size="small" />
          </div>
        )}
        {!isLoading && label}
      </button>
    );
  }
}
