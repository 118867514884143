import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import PointPresenter from 'presenters/Company/Dashboard/PointPresenter';

export const POINTS_TYPE = PropTypes.arrayOf(PointPresenter.shape());
export const STAT_TYPE = PropTypes.arrayOf(
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
);

export default new Presenter({
  publicApi: POINTS_TYPE,
  search: POINTS_TYPE,
  signIn: POINTS_TYPE,
  viewsAndDownloads: POINTS_TYPE,
  popularOrganizations: STAT_TYPE,
  powerUsers: STAT_TYPE,
  purchasedProfileNumber: PropTypes.number,
  deliveredProfileNumber: PropTypes.number,
  pendingProfileNumber: PropTypes.number,
  period: PropTypes.string,
  activeProjectsCount: PropTypes.number,
  projectsCount: PropTypes.number,
});
