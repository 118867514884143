import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { isPresent } from 'utils/HelperMethods';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    savedSearch: SavedSearchPresenter.shape(),
  },
  {
    isTaken(sample) {
      return isPresent(this.savedSearch(sample));
    },

    isEmpty(sample) {
      return !this.isTaken(sample);
    },

    savedSearchId(sample) {
      return SavedSearchPresenter.id(this.savedSearch(sample));
    },
  },
);
