import ProjectListPageConstants from './ProjectListPageConstants';
import u from 'updeep';
import { equals, propEq } from 'ramda';

const initialState = {
  loading: true,
  saving: false,
  projects: [],
  meta: {
    nextPage: 1,
    totalCount: 0,
    canCreateProject: false,
  },
  filters: {},
};

function ProjectListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProjectListPageConstants.LOAD_CURRENT_USER_PROJECTS_START: {
      return { ...state, loading: true };
    }
    case ProjectListPageConstants.LOAD_CURRENT_USER_PROJECTS_SUCCESS: {
      const { items, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      const projects = [...state.projects, ...items];

      return { ...state, loading: false, projects, meta };
    }
    case ProjectListPageConstants.LOAD_CURRENT_USER_PROJECTS_ERROR: {
      return { ...state, loading: false };
    }
    case ProjectListPageConstants.FILTER_CURRENT_USER_PROJECTS_START: {
      const { filters } = action;

      return { ...initialState, loading: true, filters };
    }
    case ProjectListPageConstants.FILTER_CURRENT_USER_PROJECTS_SUCCESS: {
      const { items, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      return { ...state, loading: false, projects: items, meta };
    }
    case ProjectListPageConstants.FILTER_CURRENT_USER_PROJECTS_ERROR: {
      return { ...state, loading: false };
    }
    case ProjectListPageConstants.DESTROY_CURRENT_USER_PROJECT_START: {
      return { ...state, saving: true };
    }
    case ProjectListPageConstants.DESTROY_CURRENT_USER_PROJECT_SUCCESS: {
      const { projectId } = action;
      const projects = state.projects.filter(({ id }) => id !== projectId);
      const meta = { ...state.meta, totalCount: state.meta.totalCount - 1 };

      return { ...state, saving: false, projects, meta };
    }
    case ProjectListPageConstants.DESTROY_CURRENT_USER_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    case ProjectListPageConstants.CLONE_CURRENT_USER_PROJECT_START: {
      return { ...state, saving: true };
    }
    case ProjectListPageConstants.CLONE_CURRENT_USER_PROJECT_SUCCESS: {
      return { ...state, saving: false };
    }
    case ProjectListPageConstants.CLONE_CURRENT_USER_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    case ProjectListPageConstants.CHANGE_CURRENT_USER_PROJECT: {
      const { project } = action;
      const projects = u.map(u.if(propEq(project.id, 'id'), project), state.projects);

      return { ...state, projects };
    }
    default:
      return state;
  }
}

export default ProjectListPageReducer;
