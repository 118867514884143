import { isNil } from 'ramda';
import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';

export default new Presenter(
  {
    index: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    jobTitle: PropTypes.string,
    profile: ProfileSearchPresenter.shape(),
    strongMatch: PropTypes.bool,
  },
  {
    isMatchedToProfile(executiveMatch) {
      return !isNil(this.profile(executiveMatch));
    },

    isStrongProfileMatch(executiveMatch) {
      return this.strongMatch(executiveMatch);
    },

    buildRequestSubjectAttributes(executiveMatch) {
      return {
        firstName: this.firstName(executiveMatch),
        lastName: this.lastName(executiveMatch),
        organizationName: this.organizationName(executiveMatch),
        jobTitle: this.jobTitle(executiveMatch),
      };
    },
  },
);
