import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfileList from './ProfileList';
import * as actions from './ProfileListActions';

const mapStateToProps = (state) => ({
  loading: state.ProfileListReducer.loading,
  profiles: state.ProfileListReducer.profiles,
  nextPage: state.ProfileListReducer.nextPage,
  totalCount: state.ProfileListReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
