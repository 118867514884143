import PropTypes from 'prop-types';
import React from 'react';
import Routes from 'routes';

import RequestPresenter from 'presenters/RequestPresenter';

import Button from 'components/v2/Button';
import Link from 'components/v2/Link';
import RawHtml from 'components/RawHtml';
import Typography from 'components/v2/Typography';

import RequestFields from './components/RequestFields';

import styles from './RequestsFields.module.css';

const RequestsFields = (props) => {
  const {
    collectionErrors: requestsErrors,
    collectionItems: requests,
    defaultRequestValues,
    form,
    itemKey,
    name,
    onItemAdd,
    onItemRemove,
    onRequestSuggestionsLoad,
    persistedRequestsCount,
    remainingRequestsNumber,
  } = props;

  const handleRequestChange = (itemIndex) => (fieldName, value) =>
    form.onFieldChange(`${name}[${itemIndex}].${fieldName}`, value);
  const handleRequestAdd = () => onItemAdd(defaultRequestValues);
  const handleRequestRemove = (index) => () => onItemRemove(index);

  return (
    <div>
      <Typography className={styles.creditsRemaining} variant="body1">
        Credits Remaining: <span className={styles.creditsRemainingNumber}>{remainingRequestsNumber}</span>
      </Typography>
      {persistedRequestsCount > 0 && (
        <Typography className={styles.formMessage} color="mantis" variant="body1">
          Successfully ordered {persistedRequestsCount} executive profiles
        </Typography>
      )}
      {form.errors.base && (
        <RawHtml
          component={Typography}
          className={styles.formMessage}
          color="salmon"
          variant="body1"
          innerHTML={form.errors.base?.join(', ')}
        />
      )}
      <div>
        {requests.map((request, index) => (
          <RequestFields
            key={itemKey(index)}
            errors={requestsErrors[index] || {}}
            request={request}
            onRequestFieldChange={handleRequestChange(index)}
            onRequestRemove={handleRequestRemove(index)}
            onRequestSuggestionsLoad={onRequestSuggestionsLoad}
          />
        ))}
      </div>
      <Button className={styles.addRequestButton} onClick={handleRequestAdd} variant="link">
        + Add more
      </Button>
      <div className={styles.actions}>
        <Button className={styles.submitFormButton} disabled={form.isSubmitting} onClick={form.onSubmit}>
          Submit
        </Button>
        <Button component={Link} to={Routes.importRequestsWizardPath()} variant="outlined">
          Upload CSV File
        </Button>
      </div>
    </div>
  );
};

RequestsFields.propTypes = {
  collectionErrors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  collectionItems: PropTypes.arrayOf(RequestPresenter.shape()).isRequired,
  defaultRequestValues: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({ base: PropTypes.arrayOf(PropTypes.string) }),
    isSubmitting: PropTypes.bool.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }).isRequired,
  itemKey: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onItemAdd: PropTypes.func.isRequired,
  onItemRemove: PropTypes.func.isRequired,
  onRequestSuggestionsLoad: PropTypes.func.isRequired,
  persistedRequestsCount: PropTypes.number.isRequired,
  remainingRequestsNumber: PropTypes.number.isRequired,
};

export default RequestsFields;
