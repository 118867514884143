import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnUserPanelProfileClickHere } from 'utils/amplitude';

import { Anchor } from 'grommet';

import styles from './UpdateRequestNotice.module.css';

class UpdateRequestNotice extends Component {
  static propTypes = {
    isSaving: PropTypes.bool.isRequired,
    onRequestProfileUpdate: PropTypes.func.isRequired,
  };

  render() {
    const { isSaving, onRequestProfileUpdate } = this.props;

    return (
      <div className={styles.infoAlert}>
        This profile looks like it has not been updated lately.
        <Anchor
          label=" Click here "
          disabled={isSaving}
          onClick={() => {
            onRequestProfileUpdate();
            logClickOnUserPanelProfileClickHere();
          }}
        />
        and we will update it free of charge.
      </div>
    );
  }
}

export default UpdateRequestNotice;
