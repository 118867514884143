import PropTypes from 'prop-types';
import { find, join, map, filter, pipe, propEq } from 'ramda';
import Presenter from '../utils/PropTypesPresenter';
import { formattedYear, formattedMonthYear, timeInterval } from 'utils/DateTime';
import { isPresent } from 'utils/HelperMethods';

import OrganizationPresenter from './OrganizationPresenter';
import JobFunctionPresenter from './JobFunctionPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    jobTitle: PropTypes.string,
    organization: OrganizationPresenter.shape(),
    from: PropTypes.string,
    to: PropTypes.string,
    jobFunctionIds: PropTypes.arrayOf(PropTypes.number),
    toPresentTime: PropTypes.bool,
    isMasked: PropTypes.bool,
    isCurrentWork: PropTypes.bool,
    retired: PropTypes.bool,
    isRecentlyUpdated: PropTypes.bool,
    skinnyJobTitle: PropTypes.string,
  },
  {
    jobFunctions(work, jobFunctions) {
      const findJobFunction = (jobFunctionId) => find(propEq(jobFunctionId, 'id'), jobFunctions);

      return pipe(map(findJobFunction), filter(isPresent))(this.jobFunctionIds(work));
    },
    jobFunctionsStr(work, jobFunctions) {
      return pipe(map(JobFunctionPresenter.name), join(', '))(this.jobFunctions(work, jobFunctions));
    },

    fullJobTitle(work) {
      if (this.retired(work)) {
        return `Former ${this.jobTitle(work)}`;
      }

      return this.jobTitle(work);
    },

    title(work) {
      const organization = this.organization(work);
      const jobTitle = this.fullJobTitle(work);

      if (isPresent(jobTitle)) {
        return `${jobTitle}, ${OrganizationPresenter.name(organization)}`;
      }

      return OrganizationPresenter.name(organization);
    },

    organizationName(work) {
      const organization = this.organization(work);

      return OrganizationPresenter.name(organization);
    },

    organizationNameWithState(work) {
      const organization = this.organization(work);

      return OrganizationPresenter.nameWithState(organization);
    },

    fromYear(work) {
      const from = this.from(work);

      return formattedYear(from);
    },

    toYear(work) {
      const to = this.to(work);

      return formattedYear(to);
    },

    fromMonthYear(work) {
      const from = this.from(work);

      return formattedMonthYear(from);
    },

    toMonthYear(work) {
      const to = this.to(work);

      return formattedMonthYear(to);
    },

    timeInterval(board) {
      const from = this.fromYear(board);
      const to = this.toPresentTime(board) ? 'Present' : this.toYear(board);

      return timeInterval(from, to);
    },

    timeIntervalWithMonth(work) {
      const from = this.fromMonthYear(work);
      const to = this.toPresentTime(work) ? 'Present' : this.toMonthYear(work);

      return timeInterval(from, to);
    },
  },
);
