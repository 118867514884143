import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v2/Button';

import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import GeneralInformationFields from './components/GeneralInformationFields';
import SalesforceSettingsFields from './components/SalesforceSettingsFields';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const {
    errors,
    isDestroying,
    isSubmitting,
    onDestroy,
    onFieldChange,
    onFieldsChange,
    onReset,
    onSalesforceTokenReset,
    onSubmit,
    values: companyDepartment,
  } = props;

  return (
    <div className={styles.form}>
      <div className={styles.sections}>
        <GeneralInformationFields
          companyDepartment={companyDepartment}
          errors={errors}
          onFieldsChange={onFieldsChange}
          onFieldChange={onFieldChange}
        />
        <SalesforceSettingsFields
          companyDepartment={companyDepartment}
          errors={errors}
          onFieldsChange={onFieldsChange}
          onFieldChange={onFieldChange}
          onSalesforceTokenReset={onSalesforceTokenReset}
        />
      </div>
      <div className={styles.actions}>
        <Button color="orange" disabled={isSubmitting || isDestroying} onClick={onDestroy} variant="outlined">
          Delete
        </Button>
        <Button disabled={isSubmitting || isDestroying} onClick={onReset} variant="outlined">
          Cancel
        </Button>
        <Button disabled={isSubmitting || isDestroying} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  isDestroying: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSalesforceTokenReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: CompanyDepartmentPresenter.shape().isRequired,
};

export default EditForm;
