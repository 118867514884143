import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import AppRoute from 'components/AppRoute';
import ErrorPage from 'components/ErrorPage';

const AppSwitch = (props) => {
  const { children, layout, sharedContext } = props;

  return (
    <Switch>
      {React.Children.map(
        children,
        (element) => element && cloneElement(element, { layout, sharedContext, ...element.props }),
      )}

      <AppRoute
        layout={layout}
        sharedContext={sharedContext}
        render={() => <ErrorPage code="404" text="Page not found" />}
      />
    </Switch>
  );
};

AppSwitch.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  layout: PropTypes.elementType,
  sharedContext: PropTypes.shape({}),
};

AppSwitch.defaultProps = {
  sharedContext: {},
};

export default AppSwitch;
