import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export const STATE_EVENTS = {
  ENABLE: 'enable',
  DISABLE: 'disable',
};

export default new Presenter(
  {
    id: PropTypes.number,
    criterias: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    state: PropTypes.string,
  },
  {
    isEnabled(limitedAccess) {
      return this.state(limitedAccess) === 'enabled';
    },
  },
);
