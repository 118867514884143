import React from 'react';

import ProjectPresenter from 'presenters/Company/Dashboard/ProjectPresenter';

const Row = (props) => {
  const { project } = props;

  return (
    <tr>
      <td>{ProjectPresenter.status(project)}</td>
      <td title={ProjectPresenter.name(project)}>{ProjectPresenter.name(project)}</td>
      <td>{ProjectPresenter.creatorFullName(project) || '-'}</td>
      <td>{ProjectPresenter.formattedCreatedAt(project) || '-'}</td>
      <td>{ProjectPresenter.formattedDeletedAt(project) || '-'}</td>
      <td>{ProjectPresenter.formattedProfilesCount(project) || '-'}</td>
      <td>{ProjectPresenter.sharesCount(project)}</td>
    </tr>
  );
};

Row.propTypes = {
  project: ProjectPresenter.shape(),
};

export default Row;
