import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(params = {}) {
    const url = Routes.apiV1AdminEditorialDashboardDatabaseAnalyticProfilesUpdatesStatisticsPath(params);

    return FetchHelpers.get(url);
  },
};
