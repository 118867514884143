import QuickSearchConstants from './QuickSearchConstants';

const initialState = {
  loading: false,
  profiles: null,
  organization: null,
};

function QuickSearchReducer(state = initialState, action = {}) {
  switch (action.type) {
    case QuickSearchConstants.INITIALIZE_QUICK_SEARCH: {
      return { ...state, profiles: null, organization: null };
    }
    case QuickSearchConstants.LOAD_QUICK_SEARCH_START: {
      return { ...state, loading: true };
    }
    case QuickSearchConstants.LOAD_QUICK_SEARCH_SUCCESS: {
      const { profiles, organization } = action;

      return { ...state, loading: false, profiles, organization };
    }
    default:
      return state;
  }
}

export default QuickSearchReducer;
