import React from 'react';
import { isBlank } from 'utils/HelperMethods';

import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import { AsyncSelect, Field } from 'components/v2/Form';

import styles from './DepartmentSelectionWizardStep.module.css';

const DepartmentSelectionWizardStep = (props) => {
  const {
    onNextStep,
    onPreviousStep,
    WizardTitle,
    WizardNavigation,
    onDepartmentChange,
    onDepartmentSearch,
    department,
  } = props;

  return (
    <>
      <WizardTitle subtitle="Choose Department" />
      <div className={styles.selector}>
        <Field title="Department">
          <AsyncSelect
            className={styles.fieldSelect}
            loadOptions={onDepartmentSearch}
            defaultOptions
            value={department}
            onChange={onDepartmentChange}
            getOptionValue={DepartmentPresenter.id}
            getOptionLabel={DepartmentPresenter.name}
            isClearable
            noBottom
          />
        </Field>
      </div>
      <WizardNavigation
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        isNextButtonDisabled={isBlank(department)}
      />
    </>
  );
};

export default DepartmentSelectionWizardStep;
