import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Spoiler from 'components/Spoiler';

import BoardPresenter from 'presenters/BoardPresenter';

class ReviewBoardsList extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(BoardPresenter.shape()).isRequired,
  };

  render() {
    const { value } = this.props;

    return (
      <section>
        <Spoiler>
          <ul>
            {value.map((board) => (
              <li key={board.id}>
                {BoardPresenter.description(board)}, {BoardPresenter.organizationName(board)}
                {` ${BoardPresenter.timeInterval(board)}`}
              </li>
            ))}
          </ul>
        </Spoiler>
      </section>
    );
  }
}

export default ReviewBoardsList;
