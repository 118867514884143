import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(companyId, params = {}) {
    const url = Routes.apiV1AdminCompanyDashboardRequestsPath(companyId, { ...params });

    return FetchHelpers.get(url);
  },
};
