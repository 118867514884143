import React, { Component } from 'react';
import cx from 'clsx';

import IndustryPresenter from 'presenters/IndustryPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './OrganizationRowCells.module.css';

class OrganizationRowCells extends Component {
  static propTypes = {
    row: OrganizationPresenter.shape(),
  };

  render() {
    const { row: organization } = this.props;
    const industry = OrganizationPresenter.industry(organization);
    const hasPublishedVersion = OrganizationPresenter.hasPublishedVersion(organization);
    const statusClass = cx([styles.status], {
      [styles.draft]: !hasPublishedVersion,
      [styles.published]: hasPublishedVersion,
    });

    return (
      <>
        <td>{OrganizationPresenter.id(organization)}</td>
        <td className={statusClass}> {hasPublishedVersion ? 'Has Published Version' : 'Only Draft'} </td>
        <td>{OrganizationPresenter.name(organization)}</td>
        <td>{industry && IndustryPresenter.name(industry)}</td>
        <td>{OrganizationPresenter.ticker(organization)}</td>
        <td>{OrganizationPresenter.assotiatedProfilesCount(organization)}</td>
        <td>{OrganizationPresenter.isLogoPresent(organization) ? 'YES' : 'NO'}</td>
      </>
    );
  }
}

export default OrganizationRowCells;
