import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Paragraph, Anchor } from 'grommet';
import EditableContent from 'components/EditableContent';
import Modal from 'components/Modal';
import EditForm from './components/EditForm';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';
import styles from './ContactInformation.module.css';

class ContactInformation extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCountrySearch: PropTypes.func.isRequired,
    onStateSearch: PropTypes.func.isRequired,
    organization: OrganizationPresenter.shape().isRequired,
    saving: PropTypes.bool.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
  };

  state = { edit: false };

  handleEdit = () => this.setState({ edit: true });

  handleStopEdit = () => this.setState({ edit: false });

  handleSubmit = (params) => this.props.onUpdate(params).then(this.handleStopEdit);

  render() {
    const { editable, organization, onCountrySearch, onStateSearch, saving, isChanged, onReviewChanges } = this.props;
    const { edit } = this.state;

    return (
      <>
        <EditableContent
          editable={editable}
          onEdit={this.handleEdit}
          tooltipComponent={StyleGuides.HQContactInformation}
        >
          <HeadlineWithReviewChangesLink
            strong
            size="small"
            margin="small"
            title="HQ Contact Information"
            isChanged={isChanged}
            onReviewChanges={onReviewChanges}
          />
          <Box className={styles.info}>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.address(organization)}{' '}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.address2(organization)}{' '}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.city(organization)}{' '}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.stateName(organization)}{' '}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.zip(organization)}{' '}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.countryName(organization)}{' '}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {OrganizationPresenter.phone(organization)}{' '}
            </Paragraph>
            <Anchor href={OrganizationPresenter.formattedUrl(organization)} className={styles.nowrap} target="_blank">
              {OrganizationPresenter.formattedUrl(organization)}
            </Anchor>
          </Box>
        </EditableContent>
        {edit && (
          <Modal
            onClose={this.handleStopEdit}
            size="medium"
            header="Contact Information"
            disableOverlayClose
            disableCloseByEsc
          >
            <EditForm
              organization={organization}
              onSubmit={this.handleSubmit}
              onCountrySearch={onCountrySearch}
              onStateSearch={onStateSearch}
              saving={saving}
            />
          </Modal>
        )}
      </>
    );
  }
}

export default ContactInformation;
