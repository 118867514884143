/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import { extractResourceData } from './utils';

export default function loadResourcesSlice(options) {
  const { pluralResource, repository } = options;

  return {
    initialState: {
      [pluralResource]: {
        loadingStatus: FETCH_STATUSES.idle,
        items: [],
        meta: {},
        error: null,
      },
    },
    reducers: {
      loadResourcesStart(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.pending;
        state[pluralResource].items = [];
        state[pluralResource].error = null;
        state[pluralResource].meta = {};
      },
      loadResourcesSuccess(state, { payload }) {
        const items = extractResourceData(payload);
        state[pluralResource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[pluralResource].items = items;
        state[pluralResource].meta = payload?.meta;
      },
      loadResourcesFail(state, { payload: { error } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
    },
    actionCreators(restDispatch) {
      return {
        loadResources: async (...params) => {
          restDispatch('loadResourcesStart');

          try {
            const data = await repository.index(...params);
            restDispatch('loadResourcesSuccess', data);

            return data;
          } catch (error) {
            restDispatch('loadResourcesFail', { error });
            throw error;
          }
        },
      };
    },
    abstractSelector: (state) => ({ resources: state[pluralResource] }),
  };
}
