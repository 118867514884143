import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';

import { logClickOnAudienceInsightsShareMostConnectedExecutive } from 'utils/amplitude';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import WorkSearchPresenter from 'presenters/WorkSearchPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import ProfileAvatar from 'components/ProfileAvatar';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';
import Sidebar from 'containers/UserPanel/components/Sidebar';
import ProfileRelationshipsList from 'containers/UserPanel/containers/Project/containers/ProfileRelationshipsList';
import PageOverlay from 'containers/UserPanel/components/PageOverlay';

import styles from './ProfileItem.module.css';

const RELATIONSHIPS_FILTERS = { entityTypes: ['organization', 'interest'] };

const ProfileItem = (props) => {
  const { project, profile, onRelationshipsExport } = props;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleOpenSidebar = () => setIsSidebarOpen(true);

  const handleCloseSidebar = () => setIsSidebarOpen(false);

  const handleRelationshipsExport = () => {
    logClickOnAudienceInsightsShareMostConnectedExecutive();

    return onRelationshipsExport(profile, RELATIONSHIPS_FILTERS);
  };

  return (
    <>
      <Anchor animateIcon={false} onClick={handleOpenSidebar} className={styles.profile}>
        <ProfileAvatar src={ProfileSearchPresenter.photoUrl(profile)} size="60" />
        <div className={styles.info}>
          <div className={styles.header}>
            <div className={styles.name}>{ProfileSearchPresenter.fullName(profile)}</div>
          </div>

          {ProfileSearchPresenter.currentWorks(profile).map((currentWork) => (
            <div key={WorkSearchPresenter.id(currentWork)} className={styles.work}>
              <div className={styles.position}>{WorkSearchPresenter.jobTitle(currentWork)}</div>
              <div className={styles.company}>{WorkSearchPresenter.organizationName(currentWork)}</div>
            </div>
          ))}

          <div className={styles.profileLabels}>
            {ProfilePresenter.isNewToRole(profile) && (
              <div>
                <NewToRoleBadge />
              </div>
            )}

            {ProfilePresenter.isMemberFortune500(profile) && (
              <div>
                <Fortune500BoardMemberBadge />
              </div>
            )}
            {ProfilePresenter.isMemberFtse100(profile) && (
              <div>
                <Ftse100BoardMemberBadge />
              </div>
            )}
          </div>
        </div>
      </Anchor>

      {isSidebarOpen && (
        <PageOverlay>
          <Sidebar onClose={handleCloseSidebar}>
            <ProfileRelationshipsList
              onExport={handleRelationshipsExport}
              isExportDisabled={!ProjectPolicy.canExport(project)}
              filters={RELATIONSHIPS_FILTERS}
              projectId={ProjectPresenter.id(project)}
              profileId={ProfileSearchPresenter.id(profile)}
              showTotalCount
              title={ProfileSearchPresenter.fullName(profile)}
            />
          </Sidebar>
        </PageOverlay>
      )}
    </>
  );
};

ProfileItem.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  profile: ProfileSearchPresenter.shape().isRequired,
  onRelationshipsExport: PropTypes.func.isRequired,
};

export default ProfileItem;
