import React from 'react';
import { useParams } from 'react-router-polyfill';

import { useAlert } from 'hooks';
import { useAdminCompanyDepartments } from 'hooks/admin';

import Form from 'components/v2/Form';
import EditForm from './components/EditForm';

import SelfRegistrationEditForm from 'forms/company/department/SelfRegistrationEditForm';

const EditSelfRegistrationTab = () => {
  const alert = useAlert();
  const { companyId } = useParams();
  const { companyDepartment, updateCompanyDepartment } = useAdminCompanyDepartments();

  const handleCompanyDepartmentUpdate = async (attributes) => {
    await updateCompanyDepartment(
      companyId,
      companyDepartment.id,
      SelfRegistrationEditForm.attributesToSubmit(attributes),
    );

    alert.successAction('Company department successfully saved');
  };

  return (
    <Form
      component={EditForm}
      enableReinitialize
      initialValues={SelfRegistrationEditForm.defaultAttributes(companyDepartment)}
      onSubmit={handleCompanyDepartmentUpdate}
    />
  );
};

export default EditSelfRegistrationTab;
