import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Anchor, CheckBox } from 'grommet';

import Routes from 'routes';
import { isBlank } from 'utils/HelperMethods';

import TranscriptPresenter from 'presenters/TranscriptPresenter';

import Spinner from 'components/Spinner';

import styles from './PopularOrganizationTranscripts.module.css';

class PopularOrganizationTranscripts extends Component {
  static propTypes = {
    transcripts: PropTypes.arrayOf(TranscriptPresenter.shape()),
    loadPopularOrganisationTranscripts: PropTypes.func.isRequired,
    checkPopularOrganizationTranscript: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  state = {
    selectedOrganizationId: null,
  };

  componentDidMount() {
    const { loadPopularOrganisationTranscripts } = this.props;

    loadPopularOrganisationTranscripts();
  }

  selectOrganizationTranscript = (organizationId) => () => {
    this.setState({ selectedOrganizationId: organizationId });
  };

  resetOrganizationTranscript = () => {
    this.setState({ selectedOrganizationId: null });
  };

  checkTranscript = (transcriptId) => () => {
    const { checkPopularOrganizationTranscript } = this.props;

    checkPopularOrganizationTranscript(transcriptId);
  };

  renderTranscript = (transcript) => {
    const { selectedOrganizationId } = this.state;
    const transcriptId = TranscriptPresenter.id(transcript);
    const documentId = TranscriptPresenter.documentId(transcript);
    const organizationId = TranscriptPresenter.organizationId(transcript);
    const documentPath = documentId ? Routes.pdfV1AdminThomsonReutersDocumentPath(documentId) : null;
    const organizationPath = Routes.editAdminOrganizationPath(organizationId);
    const eventDate = TranscriptPresenter.formattedEventDate(transcript);
    const isCheckedTranscript = TranscriptPresenter.isChecked(transcript);
    const isSelectedTranscript = organizationId === selectedOrganizationId;
    const transcriptLinkStyle = cx([styles.content], { [styles.disabled]: isBlank(documentId) });
    const transcriptRowStyle = cx([styles.transcriptRow], {
      [styles.selectedTranscript]: isSelectedTranscript,
      [styles.checkedTranscript]: isCheckedTranscript,
    });

    return (
      <div
        key={organizationId}
        className={transcriptRowStyle}
        onMouseOver={this.selectOrganizationTranscript(organizationId)}
        onFocus={this.selectOrganizationTranscript(organizationId)}
        onMouseOut={this.resetOrganizationTranscript}
        onBlur={this.resetOrganizationTranscript}
      >
        <div className={styles.checkboxWrapper}>
          <CheckBox
            className={styles.checkbox}
            disabled={isCheckedTranscript}
            checked={isCheckedTranscript}
            onChange={this.checkTranscript(transcriptId)}
          />
        </div>
        <div className={styles.info}>
          <Anchor href={organizationPath} target="_blank">
            <div className={styles.header}>{TranscriptPresenter.organizationName(transcript)}</div>
          </Anchor>
          <div className={transcriptLinkStyle}>
            <Anchor disabled={isBlank(documentId)} href={documentPath} target="_blank">
              <div>{TranscriptPresenter.name(transcript)}</div>
            </Anchor>
            <div className={styles.date}>{eventDate}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { transcripts, loading } = this.props;

    if (loading) {
      return <Spinner />;
    }

    if (isBlank(transcripts)) {
      return <div>No Items</div>;
    }

    return <>{transcripts.map(this.renderTranscript)}</>;
  }
}

export default PopularOrganizationTranscripts;
