import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import OrganizationUpdatesPresenter from 'presenters/EditorialDashboard/OrganizationUpdatesPresenter';

import styles from './OrganizationUpdates.module.css';

export default class OrganizationUpdates extends Component {
  static propTypes = {
    organizationUpdates: OrganizationUpdatesPresenter.shape(),
    loadOrganizationUpdates: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.loadOrganizationUpdates();
  }

  renderItem = (title, value) => (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div className={styles.number}>{value}</div>
    </div>
  );

  renderStatistics() {
    const { organizationUpdates } = this.props;

    return (
      <div>
        {this.renderItem('This week:', OrganizationUpdatesPresenter.formattedThisWeek(organizationUpdates))}
        {this.renderItem('This Month:', OrganizationUpdatesPresenter.formattedThisMonth(organizationUpdates))}
        {this.renderItem('Last 3 Months:', OrganizationUpdatesPresenter.formattedLastThreeMonths(organizationUpdates))}
        {this.renderItem('Last 12 Months:', OrganizationUpdatesPresenter.formattedLastYear(organizationUpdates))}
      </div>
    );
  }

  render() {
    const { loading } = this.props;

    return (
      <div className={styles.counter}>
        <h3 className={styles.header}>Organization Updates</h3>
        {loading ? <Spinner /> : this.renderStatistics()}
      </div>
    );
  }
}
