import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dashboard from 'components/CorporateAccountDashboard';
import * as actions from './DashboardActions';

const mapStateToProps = (state) => ({
  loading: state.DashboardReducer.loading,
  dashboard: state.DashboardReducer.dashboard,
  form: state.DashboardReducer.form,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
