import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { Redirect, matchPath } from 'react-router-dom';
import { keys } from 'ramda';
import { currentDateTime, fromISO } from 'utils/DateTime';
import { isBlank, isPresent } from 'utils/HelperMethods';

import { useCurrentUserCompanyDashboardRequests, useCurrentUserCompanyDashboardProjects } from 'hooks/currentUser';

import CompanyPresenter from 'presenters/CompanyPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import { COMPANY_EVENTS_CSV_EXPORT_JOB, COMPANY_USER_EVENTS_CSV_EXPORT_JOB } from 'presenters/ExportJobPresenter';

import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import RoutedTabs, { RoutedTab } from 'components/v2/RoutedTabs';
import Spinner from 'components/Spinner';

import Dashboard from './containers/Dashboard';
import UserList from './containers/UserList';

import styles from './CorporateAccount.module.css';

const TAB_TITLES = {
  [Routes.corporateAccountDashboardPath()]: 'Dashboard',
  [Routes.corporateAccountAccountUsersPath()]: 'Account Users',
};

const getTabTitle = (location) => {
  const uri = keys(TAB_TITLES).find((path) => isPresent(matchPath(location.pathname, { path })));

  return TAB_TITLES[uri];
};

const CorporateAccount = (props) => {
  const { company, currentUser, loading, loadCorporateAccount, reloadCorporateAccount, location } = props;

  const { requests, requestsLoadingMoreStatus, requestsLoadingStatus, requestsMeta, loadRequests, loadMoreRequests } =
    useCurrentUserCompanyDashboardRequests();

  const { projects, projectsLoadingMoreStatus, projectsLoadingStatus, projectsMeta, loadProjects, loadMoreProjects } =
    useCurrentUserCompanyDashboardProjects();

  useEffect(() => {
    loadCorporateAccount();
  }, []);

  const handleCompanyReload = () => reloadCorporateAccount();

  const dashboardToDate = () => {
    const subscriptionExpireAt = fromISO(CompanyPresenter.subscriptionExpireAt(company));
    const subscriptionStartsAt = fromISO(CompanyPresenter.subscriptionStartsAt(company));
    const isSubscriptionStartedInCurrentMonth = currentDateTime().month === subscriptionStartsAt?.month;
    const endOfMonth = isSubscriptionStartedInCurrentMonth
      ? subscriptionStartsAt.endOf('month')
      : currentDateTime().minus({ months: 1 }).endOf('month');

    if (isBlank(subscriptionExpireAt)) {
      return endOfMonth.toISO();
    }

    return endOfMonth < subscriptionExpireAt ? endOfMonth.toISO() : subscriptionExpireAt.toISO();
  };

  if (loading || !company) {
    return <Spinner />;
  }

  return (
    <>
      <div className={styles.common}>
        <div className={styles.userName}>{CompanyPresenter.name(company)}</div>
        <div className={styles.list}>
          <div className={styles.counter}>
            <div className={styles.message}>Seat Licenses</div>
            <div className={styles.number}>{CompanyPresenter.seatsNumber(company)}</div>
          </div>

          <div className={styles.counter}>
            <div className={styles.message}>Active Seats</div>
            <div className={styles.number}>{CompanyPresenter.activeSeatsNumber(company)}</div>
          </div>

          {CompanyPresenter.subscriptionExpireAt(company) && (
            <div className={styles.counter}>
              <div className={styles.message}>Renewal Date</div>
              <div className={styles.number}>{CompanyPresenter.formattedSubscriptionExpireAt(company)}</div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.main}>
        <div className={styles.tabsHeader}>
          <div className={styles.tabTitle}>{getTabTitle(location)}</div>
        </div>

        <div className={styles.tabs}>
          <RoutedTabs>
            <RoutedTab path={Routes.corporateAccountDashboardPath()}>Dashboard</RoutedTab>
            <RoutedTab path={Routes.corporateAccountAccountUsersPath()}>Account Users</RoutedTab>
          </RoutedTabs>
        </div>

        <div className={styles.content}>
          <AppSwitch>
            <AppRoute
              exact
              path={Routes.corporateAccountDashboardPath()}
              render={(routeProps) => (
                <Dashboard
                  {...routeProps}
                  companyId={CompanyPresenter.id(company)}
                  companyDepartments={CompanyPresenter.departments(company)}
                  from={CompanyPresenter.subscriptionStartsAt(company)}
                  to={dashboardToDate()}
                  showPreviousIntervalData={CompanyPresenter.showPreviousIntervalData(company)}
                  exportType={COMPANY_EVENTS_CSV_EXPORT_JOB}
                  requests={requests}
                  requestsLoadingMoreStatus={requestsLoadingMoreStatus}
                  requestsLoadingStatus={requestsLoadingStatus}
                  requestsMeta={requestsMeta}
                  onLoadRequests={loadRequests}
                  onLoadMoreRequests={loadMoreRequests}
                  projects={projects}
                  projectsLoadingMoreStatus={projectsLoadingMoreStatus}
                  projectsLoadingStatus={projectsLoadingStatus}
                  projectsMeta={projectsMeta}
                  loadProjects={loadProjects}
                  loadMoreProjects={loadMoreProjects}
                />
              )}
            />

            <AppRoute
              exact
              path={Routes.corporateAccountAccountUsersPath()}
              render={(routeProps) => (
                <UserList
                  {...routeProps}
                  onUsersChange={handleCompanyReload}
                  currentUser={currentUser}
                  companyId={CompanyPresenter.id(company)}
                  companyDepartments={CompanyPresenter.departments(company)}
                  userEventsExportType={COMPANY_USER_EVENTS_CSV_EXPORT_JOB}
                />
              )}
            />

            <Redirect from={Routes.corporateAccountPath()} to={Routes.corporateAccountDashboardPath()} />
          </AppSwitch>
        </div>
      </div>
    </>
  );
};

CorporateAccount.propTypes = {
  loading: PropTypes.bool.isRequired,
  company: CompanyPresenter.shape(),
  loadCorporateAccount: PropTypes.func.isRequired,
  reloadCorporateAccount: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
  location: PropTypes.shape(),
};

export default CorporateAccount;
