import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { SettingsOption } from 'grommet-icons';

import Button from 'components/Button';

import style from './UpdateStatusRequestTableAction.module.css';

const UpdateStatusRequestTableAction = (props) => {
  const { selectedRowIds, onClick } = props;

  const handleClick = () => {
    onClick(selectedRowIds);
  };

  return (
    <Button
      plain
      className={clsx('grommet_legacy-button', style.button)}
      icon={<SettingsOption className="grommet_legacy-control-icon" />}
      label={`Change Status (${selectedRowIds.length})`}
      onClick={handleClick}
    />
  );
};

UpdateStatusRequestTableAction.propTypes = {
  selectedRowIds: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func.isRequired,
};

export default UpdateStatusRequestTableAction;
