import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TeamProductivity from './TeamProductivity';
import * as actions from './TeamProductivityActions';

const mapStateToProps = (state) => ({
  loading: state.TeamProductivityReducer.loading,
  usersProductivities: state.TeamProductivityReducer.usersProductivities,
  filters: state.TeamProductivityReducer.filters,
  exportJob: state.TeamProductivityReducer.exportJob,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeamProductivity);
