import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import RequestsRepository from 'repositories/CurrentUser/Company/Dashboard/RequestsRepository';

const restSlice = createRestSlice({
  resource: 'request',
  repository: RequestsRepository,
  slices: ['loadRequests', 'loadMoreRequests'],
});

const initialState = {
  ...restSlice.initialState,
};

const requestsSlice = createSlice({
  name: 'currentUserCompanyDashboardRequests',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const currentUserCompanyDashboardRequestsReducer = requestsSlice.reducer;

export default requestsSlice;

export const currentUserCompanyDashboardRequestsRestHooks = restSlice.hooks(
  requestsSlice,
  'currentUserCompanyDashboardRequestsReducer',
);
