import keyMirror from 'keymirror';

const ProfileSubscriptionsListContainerConstants = keyMirror({
  LOAD_PROFILE_SUBSCRIPTIONS_START: null,
  LOAD_PROFILE_SUBSCRIPTIONS_SUCCESS: null,
  LOAD_PROFILE_SUBSCRIPTIONS_ERROR: null,

  FILTER_PROFILE_SUBSCRIPTIONS_START: null,
  FILTER_PROFILE_SUBSCRIPTIONS_SUCCESS: null,
  FILTER_PROFILE_SUBSCRIPTIONS_ERROR: null,

  DELETE_PROFILE_SUBSCRIPTION_START: null,
  DELETE_PROFILE_SUBSCRIPTION_SUCCESS: null,
  DELETE_PROFILE_SUBSCRIPTION_ERROR: null,

  UNDO_DELETE_PROFILE_SUBSCRIPTION_START: null,
  UNDO_DELETE_PROFILE_SUBSCRIPTION_SUCCESS: null,
  UNDO_DELETE_PROFILE_SUBSCRIPTION_ERROR: null,
});

export default ProfileSubscriptionsListContainerConstants;
