import { clientTargetingSearchExecutivesRestHooks } from 'slices/client/targetingSearch/executivesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useExecutives = () => {
  const { targetingSearchExecutives, loadTargetingSearchExecutives } = clientTargetingSearchExecutivesRestHooks.use();

  return {
    targetingSearchExecutives: targetingSearchExecutives.items,
    targetingSearchExecutivesLoadingStatus: useFetchStatus(targetingSearchExecutives.loadingStatus),
    targetingSearchExecutivesMeta: targetingSearchExecutives.meta,
    loadTargetingSearchExecutives,
  };
};

export default useExecutives;
