import keyMirror from 'keymirror';

const ProfileRelationshipsListConstants = keyMirror({
  INITIALIZE_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_START: null,

  LOAD_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_START: null,
  LOAD_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_SUCCESS: null,
});

export default ProfileRelationshipsListConstants;
