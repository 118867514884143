import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import RawHtml from 'components/RawHtml';
import Spoiler from 'components/Spoiler';

import TopicPresenter from 'presenters/TopicPresenter';

import styles from './TopicsList.module.css';

const TopicsList = (props) => {
  const { header, topics } = props;

  return (
    <section>
      <Spoiler header={header}>
        {topics.map((topic) => (
          <Fragment key={topic.id}>
            <RawHtml component="h4" className={styles.strong} innerHTML={TopicPresenter.title(topic)} />
            <RawHtml className={styles.wysiwyg} innerHTML={TopicPresenter.description(topic)} />
          </Fragment>
        ))}
      </Spoiler>
    </section>
  );
};

TopicsList.propTypes = {
  header: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(TopicPresenter.shape()).isRequired,
};

export default TopicsList;
