import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { __, all, includes, equals, prop, propEq, propOr, reject, splitEvery } from 'ramda';
import { CheckBox } from 'grommet';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Modal, { Header, Content, Actions } from 'containers/UserPanel/components/Modal';
import MultiplyCreatableSelect from 'components/MultiplyCreatableSelect';
import { SuccessModal } from 'components/BackgroundExportResultNotifier';

import ProjectPresenter from 'presenters/ProjectPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import CustomExportForm, { getExportableColumns, buildRecipientEmail } from 'forms/project/CustomExportForm';

import styles from './CustomExportModal.module.css';

const DEFAULT_BASE_ERROR_MESSAGE = 'One or more fields have an error. Please check and try again.';

export default class CustomExportModal extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
    filters: PropTypes.shape({}),
  };

  constructor(props) {
    super(props);

    const { currentUser } = this.props;
    const defaultRecipientEmail = buildRecipientEmail(CurrentUserPresenter.email(currentUser));

    this.state = {
      form: CustomExportForm.defaultAttributes({
        recipientEmails: [defaultRecipientEmail],
        params: {
          search: this.props.filters,
        },
      }),
      errors: null,
      isPersisted: false,
    };
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(CustomExportForm.attributesToSubmit(form)).then(this.handleSuccess).catch(this.handleErrors);
  };

  handleSuccess = () => {
    this.setState({ ...this.state, isPersisted: true });
  };

  handleErrors = (error) => {
    this.setState({ ...this.state, errors: error.errors });
  };

  handleUpdateParamsField = (fieldName, value) => {
    const { form } = this.state;
    const { params } = form;

    this.setState({ ...this.state, form: { ...form, params: { ...params, [fieldName]: value } } });
  };

  handleColumnNameToggle =
    (columnName) =>
    ({ target: { checked } }) => {
      const { form } = this.state;
      const { params } = form;
      const columnNames = checked
        ? [...params.columnNames, columnName]
        : reject(equals(columnName), params.columnNames);

      this.handleUpdateParamsField('columnNames', columnNames);
    };

  handleIncludePDFChange = () => {
    const { form } = this.state;
    const { params } = form;

    this.handleUpdateParamsField('includePdf', !params.includePdf);
  };

  handleSelectAllColumnNamesChange = ({ target: { checked } }) => {
    const { currentUser } = this.props;
    const exportableColumns = getExportableColumns(currentUser);
    const columnNames = checked ? exportableColumns.map(prop('name')) : [];

    this.handleUpdateParamsField('columnNames', columnNames);
  };

  handleChangeSelect = (fieldName) => (value) => {
    const { form } = this.state;

    this.setState({ ...this.state, form: { ...form, [fieldName]: value } });
  };

  handleAddNewRecipientEmail = (value) => {
    const { form } = this.state;

    if (form.recipientEmails.some(propEq(value, 'value'))) {
      return false;
    }

    this.handleChangeSelect('recipientEmails')([...form.recipientEmails, buildRecipientEmail(value)]);

    return true;
  };

  isAllExportableColumnNamesSelected = () => {
    const { form } = this.state;
    const { params } = form;
    const { columnNames } = params;
    const { currentUser } = this.props;
    const exportableColumns = getExportableColumns(currentUser);
    const exportableColumnNames = exportableColumns.map(prop('name'));

    return all(includes(__, columnNames), exportableColumnNames);
  };

  renderColumnCheckbox = (column) => {
    const {
      form: {
        params: { columnNames },
      },
    } = this.state;
    const isChecked = includes(column.name, columnNames);

    return (
      <CheckBox
        key={column.name}
        label={column.header}
        checked={isChecked}
        onChange={this.handleColumnNameToggle(column.name)}
        className={styles.checkbox}
      />
    );
  };

  renderIncludePDFToggle() {
    const { currentUser, project } = this.props;
    const {
      form: {
        params: { includePdf },
      },
    } = this.state;

    return (
      <CheckBox
        disabled={!ProjectPolicy.canExportProfiles(currentUser, project)}
        toggle
        label="include PDF files in export"
        checked={includePdf}
        onChange={this.handleIncludePDFChange}
        className={styles.radio}
      />
    );
  }

  renderSelectAll() {
    return (
      <CheckBox
        toggle
        label="Select All"
        checked={this.isAllExportableColumnNamesSelected()}
        onChange={this.handleSelectAllColumnNamesChange}
        className={styles.radio}
      />
    );
  }

  renderForm() {
    const { onClose, currentUser } = this.props;
    const exportableColumns = getExportableColumns(currentUser);
    const columnSize = Math.round(exportableColumns.length / 2);
    const columns = splitEvery(columnSize, exportableColumns);
    const { form, errors } = this.state;
    const baseErrorMessage = isPresent(errors) ? propOr(DEFAULT_BASE_ERROR_MESSAGE, 'base')(errors) : null;

    return (
      <Modal onClose={onClose}>
        <Header title="Custom Export" description="Select what you would like to export." error={baseErrorMessage} />
        <Content>
          <div className={styles.selectBox}>
            <div className={styles.subTitle}>Share with:</div>
            <MultiplyCreatableSelect
              inlinePlaceholder
              errors={errors && errors.recipientEmails}
              value={form.recipientEmails}
              onCreateNewValue={this.handleAddNewRecipientEmail}
              onChange={this.handleChangeSelect('recipientEmails')}
            />
          </div>
          <div className={styles.subTitle}>Select what you would like to export:</div>
          <div className={styles.standaloneOptions}>
            <div className={styles.option}>{this.renderSelectAll()}</div>
            <div className={styles.option}>{this.renderIncludePDFToggle()}</div>
          </div>

          <div className={styles.checkBoxes}>
            {columns.map((checkboxes, index) => (
              <div key={index} className={styles.checkBoxesColumn}>
                {checkboxes.map(this.renderColumnCheckbox)}
              </div>
            ))}
          </div>
        </Content>
        <Actions>
          <Button primary onClick={this.handleSubmit} label="Export" />
        </Actions>
      </Modal>
    );
  }

  render() {
    const { onClose } = this.props;
    const { isPersisted } = this.state;

    if (isPersisted) {
      return <SuccessModal onClose={onClose} />;
    }

    return this.renderForm();
  }
}
