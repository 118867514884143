import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export const COMPANY_EVENTS_CSV_EXPORT_JOB = 'ExportJob::Company::EventsCsvExportJob';
export const COMPANY_USER_EVENTS_CSV_EXPORT_JOB = 'ExportJob::Company::User::EventsCsvExportJob';
export const ADMIN_COMPANY_EVENTS_CSV_EXPORT_JOB = 'ExportJob::Admin::Company::EventsCsvExportJob';
export const ADMIN_COMPANY_USER_EVENTS_CSV_EXPORT_JOB = 'ExportJob::Admin::Company::User::EventsCsvExportJob';

export const NOTIFICATION_METHODS = {
  NONE: 'none',
  EMAIL: 'email',
};

export default new Presenter(
  {
    id: PropTypes.string,
    status: PropTypes.string,
    fileUrl: PropTypes.string,
  },
  {
    isCompleted(exportJob) {
      return this.status(exportJob) === 'completed';
    },

    isQueued(exportJob) {
      return this.status(exportJob) === 'queued';
    },
  },
);
