import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(projectId, params = {}) {
    const url = Routes.apiV1CurrentUserProjectAvailableProfilesPath(projectId, params);

    return FetchHelpers.get(url);
  },
  destroy(projectId, profileId) {
    const url = Routes.apiV1CurrentUserProjectAvailableProfilePath(projectId, profileId);

    return FetchHelpers.delete(url);
  },
};
