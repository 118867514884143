import { buildFilterParams } from 'utils/FilterParamsBuilder';
import UserListPageConstants from './UserListPageConstants';
import UsersRepository from 'repositories/admin/UsersRepository';
import ImpersonalizationRepository from 'repositories/admin/ImpersonalizationRepository';
import CompaniesRepository from 'repositories/admin/CompaniesRepository';
import DepartmentsRepository from 'repositories/admin/Companies/DepartmentsRepository';

import Routes from 'routes/App';

export function initializeUserList() {
  return { type: UserListPageConstants.INITIALIZE_USER_LIST_SUCCESS };
}

export function impersonate(userId) {
  return () =>
    ImpersonalizationRepository.create(userId).then(() => {
      window.location = Routes.rootPath();
    });
}

export function loadUsers({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: UserListPageConstants.LOAD_USERS_START });

    const query = { page, ...buildFilterParams(filters) };
    return UsersRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: UserListPageConstants.LOAD_USERS_SUCCESS,
        users: items,
        ...meta,
      });
    });
  };
}

export function createUser(userParams) {
  return (dispatch) => {
    dispatch({ type: UserListPageConstants.CREATE_USER_START });

    return UsersRepository.create(userParams)
      .then(({ user }) => {
        dispatch({ type: UserListPageConstants.CREATE_USER_SUCCESS, user });

        return user;
      })
      .catch((json) => {
        dispatch({ type: UserListPageConstants.CREATE_USER_ERROR });
        throw json;
      });
  };
}

export function filterUsers(filters) {
  return (dispatch) => {
    dispatch({ type: UserListPageConstants.FILTER_USERS_START, filters });

    const query = buildFilterParams(filters);
    return UsersRepository.index(query).then(({ items, meta }) => {
      dispatch({ type: UserListPageConstants.FILTER_USERS_SUCCESS, users: items, filters, ...meta });
    });
  };
}

export function bulkDestroyUsers(query) {
  return (dispatch) => {
    dispatch({ type: UserListPageConstants.BULK_DESTROY_USERS_START });

    return UsersRepository.bulkDestroy(query).then(() => {
      dispatch({ type: UserListPageConstants.BULK_DESTROY_USERS_SUCCESS });
    });
  };
}

export function onCompanySearch(filters) {
  const query = buildFilterParams(filters);

  return () => CompaniesRepository.index(query).then(({ items }) => items);
}

export function onDepartmentSearch(companyId, filters) {
  const query = buildFilterParams(filters);

  return () => DepartmentsRepository.index(companyId, query).then(({ items }) => items);
}
