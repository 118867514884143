import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RelationshipsList from 'containers/UserPanel/components/RelationshipsList';
import * as actions from './ProfileRelationshipsToMyExecutivesListActions';

const mapStateToProps = (state) => ({
  loading: state.ProjectProfileRelationshipsToMyExecutivesListReducer.loading,
  relationships: state.ProjectProfileRelationshipsToMyExecutivesListReducer.relationships,
  nextPage: state.ProjectProfileRelationshipsToMyExecutivesListReducer.nextPage,
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      initializeRelationships: () => actions.initializeProfileRelationships(props.profileId),
      loadRelationships: (params) =>
        actions.loadProfileRelationships(props.projectId, props.profileId, props.profilesFilters, params),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipsList);
