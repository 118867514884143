import u from 'updeep';
import { propEq } from 'ramda';
import SampleSavedSearchesConstants from './SampleSavedSearchesConstants';

const initialState = {
  isLoading: false,
  isSaving: false,
  sampleSavedSearches: [],
};

function SampleSavedSearchesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SampleSavedSearchesConstants.LOAD_ADMIN_SAMPLE_SAVED_SEARCHES_START: {
      return { ...initialState, isLoading: true };
    }
    case SampleSavedSearchesConstants.LOAD_ADMIN_SAMPLE_SAVED_SEARCHES_SUCCESS: {
      const { items: sampleSavedSearches } = action;

      return { ...state, isLoading: false, sampleSavedSearches };
    }
    case SampleSavedSearchesConstants.UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_START: {
      return { ...state, isSaving: true };
    }
    case SampleSavedSearchesConstants.UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_SUCCESS: {
      const { sampleSavedSearch } = action;
      const sampleSavedSearches = u.map(
        u.if(propEq(sampleSavedSearch.id, 'id'), sampleSavedSearch),
        state.sampleSavedSearches,
      );

      return { ...state, sampleSavedSearches, isSaving: false };
    }
    case SampleSavedSearchesConstants.UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_ERROR: {
      return { ...state, isSaving: false };
    }
    default:
      return state;
  }
}

export default SampleSavedSearchesReducer;
