import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tag from './components/Tag';
import styles from './Tags.module.css';

export default class Tags extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onDelete: PropTypes.func,
    deletable: PropTypes.bool,
    saving: PropTypes.bool,
  };

  static defaultProps = {
    deletable: false,
    saving: false,
    onDelete: () => {},
  };

  onDelete = (tag) => {
    this.props.onDelete(tag);
  };

  renderTags = () => {
    const { items, deletable, saving } = this.props;
    return items.map((tag) => (
      <div className={styles.item} key={tag.id}>
        <Tag onDelete={this.onDelete} tag={tag} deletable={deletable} saving={saving} />
      </div>
    ));
  };

  render() {
    return <div className={styles.root}>{this.renderTags()}</div>;
  }
}
