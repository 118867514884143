import React from 'react';
import Routes from 'routes';

import { useCurrentUser } from 'hooks';
import { useAdminCompanies } from 'hooks/admin';

import UserPolicy from 'policies/UserPolicy';

import { ADMIN_COMPANY_USER_EVENTS_CSV_EXPORT_JOB } from 'presenters/ExportJobPresenter';

import UserList from 'containers/AdminPanel/containers/CompanyRoutes/components/CompanyEditPage/containers/AdminUserList';

const UserListTab = () => {
  const { company } = useAdminCompanies();
  const { currentUser } = useCurrentUser();

  const handleUserClick = (userId) => {
    if (UserPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminUserPath(userId));
    }
  };

  return (
    <UserList
      companyId={company.id}
      onUserClick={handleUserClick}
      currentUser={currentUser}
      companyDepartments={company.departments}
      userEventsExportType={ADMIN_COMPANY_USER_EVENTS_CSV_EXPORT_JOB}
      isUsersImportVisible
    />
  );
};

export default UserListTab;
