import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import { Alert as AlertIcon } from 'grommet-icons';
import Button from 'components/Button';
import Modal from 'components/UserModal';

import styles from './ResultModal.module.css';

export default class ResultModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    exportJob: PropTypes.shape({}).isRequired,
  };

  renderSuccessMessage() {
    return (
      <div className={styles.success}>
        <h4>Your email is being sent now. You should receive in momentarilly.</h4>
      </div>
    );
  }

  renderErrorMessage() {
    return (
      <div className={styles.error}>
        <h4>
          <AlertIcon />
          Sorry Something Went Wrong, Please try again.
        </h4>
        If this error persists, please contact{' '}
        <a href="mailto:customerservice@boardroominsiders.com">customerservice@boardroominsiders.com</a>.
      </div>
    );
  }

  render() {
    const { onClose, exportJob } = this.props;

    return (
      <Modal size="large" onClose={onClose}>
        <div className={styles.root}>
          {isPresent(exportJob) ? this.renderSuccessMessage() : this.renderErrorMessage()}
          <Button onClick={onClose} primary label="OK" />
        </div>
      </Modal>
    );
  }
}
