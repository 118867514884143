import keyMirror from 'keymirror';

const TagPageConstants = keyMirror({
  LOAD_TAG_START: null,
  LOAD_TAG_SUCCESS: null,
  LOAD_TAG_ERROR: null,

  UPDATE_TAG_START: null,
  UPDATE_TAG_SUCCESS: null,
  UPDATE_TAG_ERROR: null,

  LOAD_TAGS_START: null,
  LOAD_TAGS_SUCCESS: null,
  LOAD_TAGS_ERROR: null,
});

export default TagPageConstants;
