import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Button from 'components/Button';
import { FormClose as FormCloseIcon } from 'grommet-icons';

import styles from './Sample.module.css';

class Sample extends Component {
  renderSampleHeader() {
    const { isSaving, onUnmarkAsSample } = this.props;

    return (
      <div className={styles.header}>
        Sample Saved Search
        <Button className={styles.close} disabled={isSaving} onClick={onUnmarkAsSample}>
          <FormCloseIcon />
        </Button>
      </div>
    );
  }

  renderMarkAsSampleButton() {
    const { canMarkAsSample, isSaving, onMarkAsSample } = this.props;

    return (
      <div className={styles.footer}>
        <Button disabled={isSaving || !canMarkAsSample} onClick={onMarkAsSample} primary>
          Mark as Sample Saved Search
        </Button>
      </div>
    );
  }

  renderNoChildren() {
    return <div className={styles.empty}>No Sample Saved Search</div>;
  }

  render() {
    const { children, isSample } = this.props;
    const className = cn(styles.root, { [styles.sample]: isSample });

    return (
      <div className={className}>
        {isPresent(children) && isSample && this.renderSampleHeader()}
        {isPresent(children) ? children : this.renderNoChildren()}
        {isPresent(children) && !isSample && this.renderMarkAsSampleButton()}
      </div>
    );
  }
}

Sample.propTypes = {
  canMarkAsSample: PropTypes.bool,
  children: PropTypes.node,
  isSample: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onMarkAsSample: PropTypes.func,
  onUnmarkAsSample: PropTypes.func,
};

Sample.defaultProps = {
  canMarkAsSample: false,
  onMarkAsSample: null,
  onUnmarkAsSample: null,
};

export default Sample;
