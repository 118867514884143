import { currentUserRequestsRestHooks } from 'slices/currentUser/requestsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useRequests = () => {
  const { requests, loadRequests, loadMoreRequests } = currentUserRequestsRestHooks.use();

  return {
    loadRequests,
    loadMoreRequests,
    requests: requests.items,
    requestsLoadingMoreStatus: useFetchStatus(requests.moreLoadingStatus),
    requestsLoadingStatus: useFetchStatus(requests.loadingStatus),
    requestsMeta: requests.meta,
  };
};

export default useRequests;
