import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SampleSavedSearchesTab from './SampleSavedSearchesTab';
import * as actions from './SampleSavedSearchesActions';

const mapStateToProps = (state) => ({
  isLoading: state.SampleSavedSearchesReducer.isLoading,
  isSaving: state.SampleSavedSearchesReducer.isSaving,
  sampleSavedSearches: state.SampleSavedSearchesReducer.sampleSavedSearches,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SampleSavedSearchesTab);
