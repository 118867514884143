import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { Button } from 'grommet';

import { camelize } from 'utils/keysConverter';

import Link from 'components/v2/Link';
import Logotype from 'components/v2/Logotype';

import styles from './SalesForceErrorPage.module.css';

import Routes from 'routes';

const ERROR_CODES = {
  missingRequiredParams: 0,
  credentialInvalid: 1,
  userNotRegistred: 2,
  userAutoregistrationFailed: 3,
};

class SalesForceErrorPage extends Component {
  static propTypes = {
    location: PropTypes.shape(),
  };

  missingRequiredParamsError(errors) {
    return (
      <>
        <h1 className={styles.text}>
          Required params are missing.
          <br />
          Please contact your Salesforce admin. -- Thank you
        </h1>
        {errors.join(', ')}
      </>
    );
  }

  credentialInvalidError() {
    return (
      <h1 className={styles.text}>
        The API key provided with your credentials does not match the key we have on record.
        <br />
        Please contact your Salesforce admin. -- Thank you
      </h1>
    );
  }

  userNotRegistred(companyId, companyDepartmentId, companyName) {
    return (
      <>
        <h1 className={styles.text}>
          You are not registered yet with Boardroom Insiders.
          <br />
          Don’t worry, {companyName} is a subscriber and you do have access.
        </h1>
        <h2>
          <Button
            label="Click to Register "
            href={Routes.newCompanyDepartmentUserPath(companyId, companyDepartmentId)}
            reverse
          />
        </h2>
      </>
    );
  }

  userAutoregistrationFailedError(errors) {
    return (
      <>
        <h1 className={styles.text}>
          Auto Registration was unsuccessful
          <br />
          Please contact your Salesforce admin. -- Thank you
        </h1>
        {errors.join(', ')}
      </>
    );
  }

  errorMessage() {
    const { errorCode, authenticationErrors, companyId, companyDepartmentId, companyName, userErrors } = camelize(
      parse(this.props.location.search, {
        ignoreQueryPrefix: true,
      }),
    );

    switch (+errorCode) {
      case ERROR_CODES.missingRequiredParams: {
        return this.missingRequiredParamsError(authenticationErrors);
      }
      case ERROR_CODES.credentialInvalid: {
        return this.credentialInvalidError();
      }
      case ERROR_CODES.userNotRegistred: {
        return this.userNotRegistred(companyId, companyDepartmentId, companyName);
      }
      case ERROR_CODES.userAutoregistrationFailed: {
        return this.userAutoregistrationFailedError(userErrors);
      }
      default:
        return 'Something went wrong. Please contact your Salesforce admin.  -- Thank you';
    }
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <Link href="/">
            <Logotype />
          </Link>
        </div>
        <div className={styles.main}> {this.errorMessage()} </div>
      </div>
    );
  }
}

export default SalesForceErrorPage;
