import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    value: PropTypes.number,
    previousValue: PropTypes.number,
    previousTextDate: PropTypes.string,
    textDate: PropTypes.string,
    time: PropTypes.number,
  },
  {},
);
