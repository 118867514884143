import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnProjectPageShare } from 'utils/amplitude';

import { Anchor } from 'grommet';
import ShareModal from './containers/ShareModal';

import ProjectPresenter from 'presenters/ProjectPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import styles from './ShareLink.module.css';

export default class ShareLink extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
    onExport: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  state = { isShareModalOpened: false };

  handleOpenShareModal = () => {
    logClickOnProjectPageShare();
    this.setState({ ...this.state, isShareModalOpened: true });
  };

  handleCloseShareModal = () => this.setState({ ...this.state, isShareModalOpened: false });

  render() {
    const { currentUser, disabled, onExport, project } = this.props;
    const { isShareModalOpened } = this.state;

    return (
      <>
        {isShareModalOpened && (
          <ShareModal
            currentUser={currentUser}
            onClose={this.handleCloseShareModal}
            onExport={onExport}
            project={project}
          />
        )}
        <Anchor disabled={disabled} className={styles.shareLink} label="Share" onClick={this.handleOpenShareModal} />
      </>
    );
  }
}
