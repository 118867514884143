import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { all, isEmpty } from 'ramda';
import cn from 'clsx';
import { Menu as MenuIcon } from 'grommet-icons';

import { isPresent } from 'utils/HelperMethods';
import { formattedDate } from 'utils/DateTime';
import { pluralize } from 'utils/pluralizer';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import Routes from 'routes';

import Button from 'components/Button';
import ButtonWithConfirmation from 'containers/UserPanel/components/ButtonWithConfirmation';
import AnchorLink from 'components/grommet/AnchorLink';
import Statistics from './components/Statistics';
import Menu, { MenuItem } from 'containers/UserPanel/components/Menu';

import styles from './ProjectItem.module.css';

import ProjectPolicy from 'policies/ProjectPolicy';

class ProjectItem extends Component {
  state = { isMenuOpen: false, isShareDestroying: false, isSubscriptionSaving: false };

  handleClone = () => {
    const { onClone, project } = this.props;

    return onClone(project);
  };

  handleDelete = () => {
    const { onDelete, project } = this.props;

    return onDelete(project);
  };

  handleSubscribe = () => {
    const { onCreateSubscription, project } = this.props;

    this.setState({ isSubscriptionSaving: true });

    return onCreateSubscription(project).finally(() => this.setState({ isSubscriptionSaving: false }));
  };

  handleUnsubscribe = () => {
    const { onDestroySubscription, project } = this.props;

    this.setState({ isSubscriptionSaving: true });

    return onDestroySubscription(project).finally(() => this.setState({ isSubscriptionSaving: false }));
  };

  handleDestroyShare = () => {
    const { onDestroyShare, project } = this.props;

    this.setState({ isShareDestroying: true });

    return onDestroyShare(project).finally(() => this.setState({ isShareDestroying: false }));
  };

  handlePreventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleOpenMenu = () => this.setState({ isMenuOpen: true });

  handleCloseMenu = () => this.setState({ isMenuOpen: false });

  canClone = () => {
    const { onClone } = this.props;

    return isPresent(onClone);
  };

  canDestroy = () => {
    const { onDelete, project } = this.props;

    return isPresent(onDelete) && ProjectPolicy.canDestroy(project);
  };

  canSubscribe = () => {
    const { onCreateSubscription, project } = this.props;

    return isPresent(onCreateSubscription) && ProjectPolicy.canSubscribe(project);
  };

  canUnsubscribe = () => {
    const { onDestroySubscription, project } = this.props;

    return isPresent(onDestroySubscription) && ProjectPolicy.canUnsubscribe(project);
  };

  canDestroyShare = () => {
    const { onDestroyShare, project } = this.props;

    return isPresent(onDestroyShare) && ProjectPolicy.canDestroyShare(project);
  };

  isMenuAvailable = () => this.canClone() || this.canDestroy() || this.canSubscribe() || this.canUnsubscribe();

  render() {
    const { currentUser, project, saving } = this.props;
    const { isMenuOpen, isShareDestroying, isSubscriptionSaving } = this.state;

    const id = ProjectPresenter.id(project);
    const name = ProjectPresenter.name(project);
    const total = ProjectPresenter.totalProfilesCount(project);
    const isSample = ProjectPresenter.isSampleProject(project);
    const significantlyUpdatedProfiles = ProjectPresenter.significantlyUpdatedProfiles(project);
    const addedProfiles = ProjectPresenter.addedProfiles(project);
    const newToRoleProfiles = ProjectPresenter.newToRoleProfiles(project);
    const isStatisticsBlank = all(isEmpty, [significantlyUpdatedProfiles, addedProfiles, newToRoleProfiles]);
    const statisticsSince = ProjectPresenter.statisticsSince(project);
    const ownerFullName = ProjectPresenter.visibleOwnerFullName(project);
    const ownerNameStyle = cn({ [styles.notOwner]: !ProjectPresenter.isCurrentUserOwns(project) });

    return (
      <AnchorLink to={Routes.projectPath(id)} className={styles.item} title={name}>
        {isSample && <div className={styles.sample}>sample</div>}
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.title}>
              <div className={styles.name}>{name}</div>
            </div>
            <div className={styles.details}>
              <span>{total} profiles</span>
              &emsp;|&emsp;
              {CurrentUserPresenter.projectsSharingAvailable(currentUser) && (
                <>
                  <span>
                    Project owner: <span className={ownerNameStyle}>{ownerFullName}</span>
                  </span>
                  &emsp;|&emsp;
                </>
              )}
              <span>Last Modified: {ProjectPresenter.formattedLastModifiedAt(project)}</span>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className={styles.actions} onClick={this.handlePreventDefault}>
            {this.isMenuAvailable() && (
              <Button className={styles.icon} onClick={this.handleOpenMenu}>
                <MenuIcon />
              </Button>
            )}
            <Menu className={styles.menu} isOpen={isMenuOpen} onClose={this.handleCloseMenu}>
              {this.canSubscribe() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  confirmationMessage="Are you sure you want to get email alerts for this project?"
                  disabled={saving || isSubscriptionSaving}
                  onClick={this.handleSubscribe}
                >
                  Add to Alerts
                </MenuItem>
              )}
              {this.canUnsubscribe() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  confirmationMessage="Are you sure you want to remove email alerts for this Project?"
                  disabled={saving || isSubscriptionSaving}
                  onClick={this.handleUnsubscribe}
                >
                  Remove from Alerts
                </MenuItem>
              )}
              {this.canClone() && (
                <MenuItem disabled={saving} onClick={this.handleClone}>
                  Clone the Project
                </MenuItem>
              )}
              {this.canDestroyShare() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  confirmationMessage="Are you sure want to remove this Project? "
                  disabled={saving || isShareDestroying}
                  onClick={this.handleDestroyShare}
                >
                  Remove
                </MenuItem>
              )}
              {this.canDestroy() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  confirmationMessage="Are you sure want to delete this Project? "
                  disabled={saving || isSubscriptionSaving}
                  onClick={this.handleDelete}
                >
                  Delete
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
        <div className={styles.statistics}>
          {isStatisticsBlank && (
            <div className={styles.noStatistics}>Nothing changed since {formattedDate(statisticsSince)}</div>
          )}
          {significantlyUpdatedProfiles.length > 0 && (
            <Statistics
              description={pluralize(
                'updated profile',
                ProjectPresenter.significantlyUpdatedProfilesTotalCount(project),
                true,
              )}
              path={Routes.projectPath(id, { significantlyUpdatedGte: statisticsSince })}
              profiles={significantlyUpdatedProfiles}
              profilesTotalCount={ProjectPresenter.significantlyUpdatedProfilesTotalCount(project)}
              title="Updated Profiles"
              updatedAt={ProjectPresenter.significantlyUpdatedProfilesUpdatedAt(project)}
            />
          )}
          {addedProfiles.length > 0 && (
            <Statistics
              description={pluralize('new profile', ProjectPresenter.addedProfilesTotalCount(project), true)}
              path={Routes.projectPath(id, { profilesAddedGte: statisticsSince })}
              profiles={addedProfiles}
              profilesTotalCount={ProjectPresenter.addedProfilesTotalCount(project)}
              title="New To Project"
              updatedAt={ProjectPresenter.addedProfilesUpdatedAt(project)}
            />
          )}
          {newToRoleProfiles.length > 0 && (
            <Statistics
              description={pluralize('executive change', ProjectPresenter.newToRoleProfilesTotalCount(project), true)}
              path={Routes.projectPath(id, { recentCurrentWorkStartedGte: statisticsSince })}
              profiles={newToRoleProfiles}
              profilesTotalCount={ProjectPresenter.newToRoleProfilesTotalCount(project)}
              title="New To Role"
              updatedAt={ProjectPresenter.newToRoleProfilesUpdatedAt(project)}
            />
          )}
        </div>
      </AnchorLink>
    );
  }
}

ProjectItem.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  project: ProjectPresenter.shape().isRequired,
  onClone: PropTypes.func,
  onCreateSubscription: PropTypes.func,
  onDelete: PropTypes.func,
  onDestroySubscription: PropTypes.func,
  onDestroyShare: PropTypes.func,
  saving: PropTypes.bool,
};

export default ProjectItem;
