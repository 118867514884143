import * as d3 from 'd3';

export function chargeForce() {
  return d3.forceManyBody().strength(-3000);
}

export function centerForce(width, height) {
  return d3.forceCenter(width / 2, height / 2);
}

export function xAxisForce(width) {
  return d3.forceX(width / 2).strength(0.7);
}

export function yAxisForce(height) {
  return d3.forceY(height / 2).strength(0.7);
}

export function repelForce(radius) {
  return d3
    .forceManyBody()
    .strength(-300)
    .distanceMax(radius * 2)
    .distanceMin(radius);
}

export function collideForce(radius) {
  return d3
    .forceCollide()
    .radius(radius * 3)
    .iterations(50)
    .strength(2);
}

export function linkForce(links, radius) {
  return d3
    .forceLink(links)
    .id((d) => d.id)
    .distance((d) => {
      const q = d.source.group === d.target.group ? radius * d.strength : radius * (1 + d.strength);
      return q * 1.25;
    })
    .strength(2);
}
