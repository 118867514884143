import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    canEdit: PropTypes.bool,
    isNew: PropTypes.bool,
  },
  {},
);
