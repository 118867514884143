import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { pick, values } from 'ramda';

import { isPresent } from 'utils/HelperMethods';
import { logClickOnUserPanelSearchProfilesSavedSearchTab } from 'utils/amplitude';

import FilterBadge from './components/FilterBadge';
import Filters from 'containers/UserPanel/components/SearchFilters';
import Icon from 'components/Icon';
import Overlay from 'components/Overlay';
import SaveSearchPopUp from './components/SaveSearchPopUp';
import SavedSearchBadge from './components/SavedSearchBadge';
import SavedSearchesList from './components/SavedSearchesList';
import Tabs, { Tab } from 'components/Tabs';
import UserBasedFilters from './components/UserBasedFilters';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import NumberPresenter from 'presenters/NumberPresenter';
import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import { FILTERS, USER_BASED_FILTERS } from 'forms/ProfileFilteredSearchForm';

import useSavedSearches from 'hooks/client/useSavedSearches';
import useSubscriptions from 'hooks/client/useSubscriptions';

import styles from './Sidebar.module.css';

export const FILTERS_TAB_INDEX = 0;
export const SAVED_SEARCHES_TAB_INDEX = 1;
const CLOSE_TAB_INDEX = 2;

const Sidebar = (props) => {
  const {
    activeScopeFilter,
    activeTabIndex,
    currentUser,
    filters,
    filtersAutocomplete,
    isLoading,
    isOpen,
    isProjectSaving,
    onClose,
    onFilterChange,
    onFilterOptionsLoad,
    onFiltersReset,
    onOpenedSavedSearchClose,
    onProjectCreate,
    onSavedSearchOpen,
    onScopeFilterChange,
    onSortChange,
    onTabChange,
    openedSavedSearch,
    totalCount,
  } = props;

  const {
    savedSearches,
    meta: savedSearchesMeta,
    isCreating: isSavedSearchCreating,
    isUpdating: isSavedSearchUpdating,
    isDeleting: isSavedSearchDeleting,
    isLoading: isSavedSearchesLoading,
    createSavedSearch,
    changeSavedSearch,
    updateSavedSearch,
    deleteSavedSearch,
    loadSavedSearches,
    filterSavedSearches,
  } = useSavedSearches();

  const { createSubscription, destroySubscription } = useSubscriptions();

  const { totalCount: savedSearchesTotalCount, nextPage: savedSearchesNextPage } = savedSearchesMeta;
  const { scopeFilterDate, scopeFilterValue, sort } = filters;

  const activeFiltersKeys = values(FILTERS).filter((key) => isPresent(filters[key]));
  const activeSearchFilters = pick(activeFiltersKeys, filters);

  const activeUserBasedFiltersKeys = values(USER_BASED_FILTERS).filter((key) => filters[key]);
  const activeUserBasedFilters = pick(activeUserBasedFiltersKeys, filters);

  const activeFilters = { ...activeSearchFilters, ...activeUserBasedFilters, ...activeScopeFilter };

  const [isSaveSearchPopUpVisible, setSaveSearchPopUpVisibility] = useState(false);

  const handleSaveSearchPopUpOpen = () => setSaveSearchPopUpVisibility(true);
  const handleSaveSearchPopUpClose = () => setSaveSearchPopUpVisibility(false);
  const handleSavedSearchesLoad = (page) => loadSavedSearches({ page });
  const handleSavedSearchesFilter = () => filterSavedSearches();
  const handleSavedSearchCreate = (params) =>
    createSavedSearch(params).then(() => {
      handleSavedSearchesFilter();
      handleSaveSearchPopUpClose();
      onFiltersReset();
      onTabChange(SAVED_SEARCHES_TAB_INDEX);
    });
  const handleSavedSearchDelete = (id) => deleteSavedSearch(id).then(handleSavedSearchesFilter);
  const handleTabChange = (index) => {
    if (index === CLOSE_TAB_INDEX) {
      onClose();
    } else if (index === SAVED_SEARCHES_TAB_INDEX && isPresent(openedSavedSearch)) {
      onOpenedSavedSearchClose();
    } else {
      onTabChange(index);
    }
  };

  useEffect(() => {
    handleSavedSearchesFilter();
  }, []);

  return (
    <>
      <Overlay isShow={isOpen} className={styles.overlay}>
        <Tabs className={styles.tabs} activeIndex={activeTabIndex} onActive={handleTabChange} fullWidth>
          <Tab title="Filters">
            {Object.keys(activeFilters).length > 0 && (
              <FilterBadge
                activeFilters={activeFilters}
                onSaveFilters={handleSaveSearchPopUpOpen}
                onResetFilters={onFiltersReset}
                canSaveFilters={Object.keys(activeSearchFilters).length > 0}
                totalCount={NumberPresenter.withDelim(totalCount)}
              />
            )}
            <div className={styles.wrapper}>
              <Filters
                key={totalCount}
                autocomplete={filtersAutocomplete}
                isLoading={isLoading}
                onFilterChange={onFilterChange}
                onLoadOptions={onFilterOptionsLoad}
                onSortChange={onSortChange}
                searchParams={filters}
                sort={sort}
                scopeFilterDate={scopeFilterDate}
                scopeFilterValue={scopeFilterValue}
                onScopeFilterChange={onScopeFilterChange}
              />
              <UserBasedFilters isLoading={isLoading} onFilterChange={onFilterChange} searchParams={filters} />
            </div>
          </Tab>
          <Tab
            title={`Saved Searches (${savedSearchesTotalCount})`}
            onClick={logClickOnUserPanelSearchProfilesSavedSearchTab}
          >
            {isPresent(openedSavedSearch) ? (
              <>
                <SavedSearchBadge
                  savedSearch={openedSavedSearch}
                  onResetSavedSearch={onOpenedSavedSearchClose}
                  profilesTotalCount={totalCount}
                />
                <div className={styles.wrapper}>
                  <Filters
                    key={totalCount}
                    autocomplete={filtersAutocomplete}
                    isLoading={isLoading}
                    onFilterChange={onFilterChange}
                    onLoadOptions={onFilterOptionsLoad}
                    onSortChange={onSortChange}
                    searchParams={filters}
                    sort={sort}
                    scopeFilterDate={scopeFilterDate}
                    scopeFilterValue={scopeFilterValue}
                    onScopeFilterChange={onScopeFilterChange}
                  />
                </div>
              </>
            ) : (
              <SavedSearchesList
                key={`${isOpen}-${activeTabIndex}`}
                currentUser={currentUser}
                savedSearches={savedSearches}
                isLoading={isSavedSearchesLoading}
                onChangeSavedSearch={changeSavedSearch}
                onCreateSubscription={createSubscription}
                onDestroySubscription={destroySubscription}
                onOpenSavedSearch={onSavedSearchOpen}
                onDeleteSavedSearch={handleSavedSearchDelete}
                onCreateProject={onProjectCreate}
                onUpdateSavedSearch={updateSavedSearch}
                loadNext={handleSavedSearchesLoad}
                nextPage={savedSearchesNextPage}
                isDeleting={isSavedSearchDeleting}
                isUpdating={isSavedSearchUpdating}
                isProjectSaving={isProjectSaving}
              />
            )}
          </Tab>
          <Tab className={styles.close} title={<Icon name="close" />} />
        </Tabs>
      </Overlay>
      {isSaveSearchPopUpVisible && (
        <SaveSearchPopUp
          filters={activeSearchFilters}
          onClose={handleSaveSearchPopUpClose}
          onSave={handleSavedSearchCreate}
          isCreating={isSavedSearchCreating}
        />
      )}
    </>
  );
};

Sidebar.propTypes = {
  activeScopeFilter: PropTypes.shape().isRequired,
  activeTabIndex: PropTypes.number.isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  filtersAutocomplete: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isProjectSaving: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterOptionsLoad: PropTypes.func.isRequired,
  onFiltersReset: PropTypes.func.isRequired,
  onOpenedSavedSearchClose: PropTypes.func.isRequired,
  onProjectCreate: PropTypes.func.isRequired,
  onSavedSearchOpen: PropTypes.func.isRequired,
  onScopeFilterChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  openedSavedSearch: SavedSearchPresenter.shape(),
  totalCount: PropTypes.number,
};

export default Sidebar;
