import React from 'react';
import PropTypes from 'prop-types';
import { fromISO } from 'utils/DateTime';

const isStartWeek = (index, data) => {
  if (index === 0) {
    return false;
  }

  const currentDayWeekNumber = fromISO(data[index].day).weekNumber;
  const prevDayWeekNumber = fromISO(data[index - 1].day).weekNumber;

  return currentDayWeekNumber !== prevDayWeekNumber;
};

const CustomizedXAxisWeekDivider = (props) => {
  const {
    x,
    offsetTop,
    height,
    data,
    payload: { index },
  } = props;

  if (isStartWeek(index, data)) {
    const pathX = Math.floor(x);

    return <path d={`M${pathX},${offsetTop}v${height}`} stroke="#DEE0E3" />;
  }
  return null;
};

CustomizedXAxisWeekDivider.propTypes = {
  x: PropTypes.number,
  offsetTop: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default CustomizedXAxisWeekDivider;
