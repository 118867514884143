import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Spinner from 'components/Spinner';
import ProfileBlock from './components/ProfileBlock';

import styles from './SampleProfilesPage.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';

class SampleProfilesPage extends Component {
  componentDidMount() {
    const { loadSampleProfiles } = this.props;

    loadSampleProfiles({ sortFields: ['position ASC'] });
  }

  render() {
    const { loading, sampleProfiles } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return (
      <Box className={styles.sampleProfilesBlock}>
        <div className={styles.header}>
          <h1 className={styles.title}>Full Executive Profile Samples</h1>
        </div>

        <div className={styles.container}>
          {sampleProfiles.map((profile, index) => (
            <ProfileBlock key={index} profile={profile} />
          ))}
        </div>
      </Box>
    );
  }
}

SampleProfilesPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadSampleProfiles: PropTypes.func.isRequired,
  sampleProfiles: PropTypes.arrayOf(ProfilePresenter.shape()),
};

export default SampleProfilesPage;
