import keyMirror from 'keymirror';

const OrganizationSubscriptionsListContainerConstants = keyMirror({
  LOAD_ORGANIZATION_SUBSCRIPTION_START: null,
  LOAD_ORGANIZATION_SUBSCRIPTION_SUCCESS: null,
  LOAD_ORGANIZATION_SUBSCRIPTION_ERROR: null,

  DELETE_ORGANIZATION_SUBSCRIPTION_START: null,
  DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS: null,
  DELETE_ORGANIZATION_SUBSCRIPTION_ERROR: null,

  UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_START: null,
  UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS: null,
  UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_ERROR: null,

  FILTER_ORGANIZATION_SUBSCRIPTION_START: null,
  FILTER_ORGANIZATION_SUBSCRIPTION_SUCCESS: null,
  FILTER_ORGANIZATION_SUBSCRIPTION_ERROR: null,
});

export default OrganizationSubscriptionsListContainerConstants;
