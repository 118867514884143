import React from 'react';
import PropTypes from 'prop-types';

import RawHtml from 'components/RawHtml';

const ReviewWysiwyg = (props) => {
  const { value } = props;

  return <RawHtml innerHTML={value} />;
};

ReviewWysiwyg.propTypes = {
  value: PropTypes.node,
};

export default ReviewWysiwyg;
