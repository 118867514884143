import PropTypes from 'prop-types';
import React from 'react';

import Table, { TableBody } from 'components/v2/Table';

import styles from './ExecutiveInformation.module.css';

const ExecutiveInformation = (props) => {
  const { firstName, lastName, jobTitle, organizationName } = props;

  return (
    <div className={styles.executiveInformation}>
      <Table className={styles.table}>
        <TableBody>
          <tr>
            <td>Full name</td>
            <td>{firstName}</td>
          </tr>
          <tr>
            <td>Last name</td>
            <td>{lastName}</td>
          </tr>
          <tr>
            <td>Title</td>
            <td>{jobTitle}</td>
          </tr>
          <tr>
            <td>Company</td>
            <td>{organizationName}</td>
          </tr>
        </TableBody>
      </Table>
    </div>
  );
};

ExecutiveInformation.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
};

export default ExecutiveInformation;
