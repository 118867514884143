import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { isPresent, isBlank } from 'utils/HelperMethods';
import cn from 'clsx';

import FileStatus from './components/FileStatus';

import styles from './CsvDropzone.module.css';

const CsvDropzone = (props) => {
  const { onLoad, isValidating, file, errors } = props;

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    const newFile = isPresent(acceptedFiles[0]) ? acceptedFiles[0] : rejectedFiles[0];

    onLoad(newFile);
  };

  const dropStyles = cn([styles.dropWrapper], {
    [styles.dropWrapperError]: isPresent(file) && !isValidating && isPresent(errors),
  });

  return (
    <div>
      <Dropzone accept={{ 'text/*': ['.csv'] }} onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={dropStyles}>
            <input {...getInputProps()} />
            {isBlank(file) ? (
              <div className={styles.noFile}>
                <div className={styles.drop}>Drag & drop CSV-file here</div>
                <div className={styles.or}>or</div>
                <div className={styles.file}>Choose file</div>
              </div>
            ) : (
              <FileStatus errors={errors} isValidating={isValidating} />
            )}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

CsvDropzone.propTypes = {
  onLoad: PropTypes.func.isRequired,
  file: PropTypes.shape(),
  isValidating: PropTypes.bool,
};

export default CsvDropzone;
