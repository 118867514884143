import { isEmpty, isNil, pathOr, propEq, propOr, uniq } from 'ramda';
import RequestForm from 'forms/customer/RequestForm';
import { SOURCES } from 'presenters/RequestPresenter';

export default {
  defaultAttributes(attributes = {}) {
    return {
      matchedExecutives: { items: [], meta: null },
      helpRequest: { message: '' },
      recipientEmails: [],
      requests: [],
      selectedRequestExecutiveMatchIndexes: [],
      source: { file: null, delimiter: null, meta: null },
      ...attributes,
    };
  },

  attributesToSubmit(attributes) {
    const { recipientEmails, requests, selectedRequestExecutiveMatchIndexes } = attributes;
    const helpRequestMessage = attributes.helpRequest.message;

    const selectedRequests = requests.filter(({ executiveMatchIndex }) =>
      selectedRequestExecutiveMatchIndexes.includes(executiveMatchIndex),
    );

    return {
      requests: selectedRequests.map((request) =>
        RequestForm.attributesToSubmit({ ...request, recipientEmails, source: SOURCES.REQUEST_IMPORT }),
      ),
      helpRequest: {
        message: isEmpty(helpRequestMessage) || isNil(helpRequestMessage) ? 'No message provided' : helpRequestMessage,
      },
    };
  },

  transformError(attributes, { errors }) {
    const { requests, selectedRequestExecutiveMatchIndexes } = attributes;

    const selectedRequests = requests.filter(({ executiveMatchIndex }) =>
      selectedRequestExecutiveMatchIndexes.includes(executiveMatchIndex),
    );

    const requestsErrors = requests.map(({ executiveMatchIndex }) => {
      const requestErrorsIndex = selectedRequests.findIndex(propEq(executiveMatchIndex, 'executiveMatchIndex'));

      return requestErrorsIndex >= 0 ? pathOr(null, ['requests', requestErrorsIndex], errors) : null;
    });

    return {
      errors: {
        ...errors,
        requests: requestsErrors,
        recipientEmails: uniq(propOr([], 'requests', errors).flatMap(propOr([], 'recipientEmails'))),
      },
    };
  },
};
