import React, { useEffect, useState, useCallback } from 'react';
import { isBlank } from 'utils/HelperMethods';

import Chart from './components/Chart';
import ProfilesDueChart from './components/ProfilesDueChart';
import CustomizedLegend from './components/CustomizedLegend';
import Spinner from 'components/Spinner';
import { humanRequestTypeName } from 'presenters/RequestPresenter';

import useAdminEditorialDashboardProduction from 'hooks/admin/useAdminEditorialDashboardProduction';

import styles from './Production.module.css';

const REQUEST_TYPES_COLORS = {
  new_profile_request: {
    inQueue: '#00A0CE',
    inQueuePastDueItems: '#EA6E40',
    inQueueMoreThan10Requests: '#BF2CFA',
  },
  update_request: {
    inQueue: '#00A0CE',
    inQueuePastDueItems: '#00A0CE',
    inQueueMoreThan10Requests: '#00A0CE',
  },
};

const LEGEND = [
  { text: 'In queue', color: '#00A0CE' },
  { text: 'In queue (past due items)', color: '#EA6E40' },
  { text: 'In queue (more than 10 requests)', color: '#BF2CFA' },
];

const Production = () => {
  const { loadProduction, loading, requestTypesStatistics, requestsStatisticsByDays } =
    useAdminEditorialDashboardProduction();
  const [rootWidth, setRootWidth] = useState(null);

  useEffect(() => {
    loadProduction();
  }, []);

  const rootRef = useCallback((node) => {
    if (node !== null) {
      setRootWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const renderChart = ({ requestType, statistics }, index) => (
    <div className={styles.chart} key={`${requestType}${index}`}>
      <h3 className={styles.title}>{humanRequestTypeName(requestType)}</h3>
      {isBlank(statistics) ? (
        <div className={styles.noData}>Doesn’t have any Update requests in the queue</div>
      ) : (
        <>
          <Chart data={statistics} parentWidth={rootWidth} colors={REQUEST_TYPES_COLORS[requestType]} />
          {index === 0 && <CustomizedLegend titles={LEGEND} />}
        </>
      )}
    </div>
  );

  if (loading || isBlank(requestTypesStatistics)) {
    return <Spinner />;
  }

  return (
    <div ref={rootRef}>
      {rootWidth && requestTypesStatistics.map(renderChart)}
      {isBlank(requestsStatisticsByDays) ? (
        <div className={styles.noData}>Doesn’t have any Update requests in the queue</div>
      ) : (
        <>
          <h3 className={styles.title}>Profiles Due</h3>
          <ProfilesDueChart data={requestsStatisticsByDays} parentWidth={rootWidth} />
        </>
      )}
    </div>
  );
};

export default Production;
