import keyMirror from 'keymirror';

const TREventsBlockConstants = keyMirror({
  INITIALIZE_THOMSON_REUTERS_EVENTS: null,

  LOAD_THOMSON_REUTERS_EVENTS_START: null,
  LOAD_THOMSON_REUTERS_EVENTS_SUCCESS: null,
  LOAD_THOMSON_REUTERS_EVENTS_ERROR: null,
});

export default TREventsBlockConstants;
