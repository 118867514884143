import { defaultTo, pick, propOr } from 'ramda';

export default {
  defaultAttributes(work = {}) {
    return {
      jobFunctionIds: [],
      jobTitle: '',
      toPresentTime: false,
      ...work,
      display: defaultTo(true, work.display),
      retired: defaultTo(false, work.retired),
    };
  },

  attributesToSubmit(work) {
    const propsToSend = ['id', 'jobTitle', 'jobFunctionIds', 'toPresentTime', 'to', 'from', 'display', 'retired'];
    const { organization, standardizedJobTitle } = work;

    return {
      ...pick(propsToSend, work),
      organizationId: propOr(null, 'id', organization),
      standardizedJobTitleId: propOr(null, 'id', standardizedJobTitle),
    };
  },
};
