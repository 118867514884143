import React from 'react';
import PropTypes from 'prop-types';

import dictionary from './dictionary';

const Icon = (props) => {
  const { name, className, style } = props;
  const Svg = dictionary[name];

  return <Svg className={className} style={style} />;
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

export default Icon;
