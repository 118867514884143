import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, equals, prop, reject } from 'ramda';
import { Anchor } from 'grommet';

import Button from 'components/Button';
import Checkbox from 'containers/UserPanel/components/Checkbox';
import UserModal from 'components/UserModal';

import { STATES, humanStateName, stateColor } from 'presenters/Project/CsvItemPresenter';

import styles from './ChooseFiltersModal.module.css';

const STATES_NAMES = STATES.map(prop('key'));

export default class ChooseFiltersModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    checkedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);

    const { checkedStates } = this.props;
    this.state = { checkedStates };
  }

  handleChange =
    (state) =>
    ({ target: { checked } }) => {
      const { checkedStates } = this.state;

      if (checked) {
        this.setState({ ...this.state, checkedStates: [...checkedStates, state] });
      } else {
        this.setState({ ...this.state, checkedStates: reject(equals(state), checkedStates) });
      }
    };

  handleCheckAllStates = () => {
    this.setState({ ...this.state, checkedStates: STATES_NAMES });
  };

  handleUncheckAllStates = () => {
    this.setState({ ...this.state, checkedStates: [] });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { checkedStates } = this.state;

    onSubmit(checkedStates);
  };

  isAllStatesChecked = () => {
    const { checkedStates } = this.state;

    return equals(checkedStates.sort(), [...STATES_NAMES].sort());
  };

  isAnyStatesChecked = () => {
    const { checkedStates } = this.state;

    return checkedStates.length > 0;
  };

  renderCheckboxLabel = (state) => (
    <div className={styles.checkboxLabel}>
      <div className={styles.legend} style={{ backgroundColor: stateColor(state) }} />
      {humanStateName(state)}
    </div>
  );

  renderCheckbox = (state) => {
    const { checkedStates } = this.state;
    const isChecked = includes(state, checkedStates);

    return (
      <Checkbox
        key={state}
        className={styles.checkbox}
        checked={isChecked}
        label={this.renderCheckboxLabel(state)}
        onChange={this.handleChange(state)}
      />
    );
  };

  render() {
    const { onClose } = this.props;

    return (
      <UserModal disableOverlayClose onClose={onClose} size="large">
        <div className={styles.root}>
          <h3 className={styles.header}>Choose Type to Search</h3>
          <div className={styles.actions}>
            <Anchor
              label="Check All"
              disabled={this.isAllStatesChecked()}
              onClick={this.handleCheckAllStates}
              className={styles.item}
            />
            <Anchor
              label="Uncheck All"
              disabled={!this.isAnyStatesChecked()}
              onClick={this.handleUncheckAllStates}
              className={styles.item}
            />
          </div>

          <div className={styles.checkboxes}>{STATES_NAMES.map(this.renderCheckbox)}</div>

          <Button label="Save Filter" onClick={this.handleSubmit} primary />
        </div>
      </UserModal>
    );
  }
}
