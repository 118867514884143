import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import EditorialDashboardDatabaseAnalyticFortune500ProfilesStatisticsRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/Fortune500ProfilesStatisticsRepository';

const restSlice = createRestSlice({
  resource: 'editorialDashboardFortune500ProfilesStatistic',
  repository: EditorialDashboardDatabaseAnalyticFortune500ProfilesStatisticsRepository,
  slices: ['loadEditorialDashboardFortune500ProfilesStatistic'],
});

const initialState = {
  ...restSlice.initialState,
};

const fortune500ProfilesStatisticsSlice = createSlice({
  name: 'adminEditorialDashboardFortune500ProfilesStatistics',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminEditorialDashboardFortune500ProfilesStatisticsReducer = fortune500ProfilesStatisticsSlice.reducer;

export default fortune500ProfilesStatisticsSlice;

export const adminEditorialDashboardFortune500ProfilesStatisticsRestHooks = restSlice.hooks(
  fortune500ProfilesStatisticsSlice,
  'adminEditorialDashboardFortune500ProfilesStatisticsReducer',
);
