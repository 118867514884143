import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import CountryPresenter from './CountryPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    abbreviation: PropTypes.string,
    countryAlpha2: PropTypes.string,
    country: CountryPresenter.shape(),
  },
  {
    nameWithCountry(state) {
      const country = this.country(state);

      return country ? `${this.name(state)} (${CountryPresenter.name(country)})` : this.name(state);
    },
  },
);
