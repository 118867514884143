import React, { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { isBlank, isPresent } from 'utils/HelperMethods';

import Modal from 'components/Modal';

import styles from './ReviewChanges.module.css';

const ReviewChanges = (props) => {
  const { wrappedComponent, useAdminSubject, currentValueKey, previousValueKey, getter, ...rest } = props;
  const dataAndActions = useAdminSubject();
  const { [currentValueKey]: currentValue, [previousValueKey]: previousValue } = dataAndActions;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviewTitle, setReviewTitle] = useState(null);
  const [reviewKey, setReviewKey] = useState(null);
  const [reviewComponent, setReviewComponent] = useState(null);
  const [additionalProps, setAdditionalProps] = useState(null);

  const handleOpenReviewChangesModal = (newReviewComponent, newReviewTitle, newReviewKey, newAdditionalProps) => () => {
    setIsModalOpen(true);
    setReviewTitle(newReviewTitle);
    setReviewComponent({ newReviewComponent });
    setReviewKey(newReviewKey);
    setAdditionalProps(newAdditionalProps);
  };

  const handleCloseReviewChangesModal = () => {
    setIsModalOpen(false);
    setReviewTitle(null);
    setReviewComponent(null);
    setReviewKey(null);
    setAdditionalProps(null);
  };

  const getCurrentValue = (key) => {
    if (!key) {
      return currentValue;
    }

    return getter[key](currentValue);
  };

  const getPreviousValue = (key) => {
    if (!key) {
      return previousValue;
    }

    return getter[key](previousValue);
  };

  const isCollectionChanged = (key, digestKey) => {
    if (isBlank(getPreviousValue())) {
      return false;
    }

    const currentCollection = getCurrentValue(key);
    const previousCollection = getPreviousValue(key);
    const getDigest = prop(digestKey || 'digest');

    return currentCollection.map(getDigest).toString() !== previousCollection.map(getDigest).toString();
  };

  const isChanged = (key) => isPresent(getPreviousValue()) && getCurrentValue(key) !== getPreviousValue(key);

  const hasFirstlyAddedContent = (key) => isBlank(getPreviousValue()) && isPresent(getCurrentValue(key));

  return (
    <>
      {createElement(wrappedComponent, {
        ...rest,
        ...dataAndActions,
        isChanged,
        isCollectionChanged,
        reviewChanges: handleOpenReviewChangesModal,
        hasFirstlyAddedContent,
      })}
      {isModalOpen && (
        <Modal
          header={reviewTitle}
          inlineSubheader="changes"
          onClose={handleCloseReviewChangesModal}
          size="large"
          disableOverlayClose
          disableCloseByEsc
        >
          <div className={styles.compareBox}>
            <div className={styles.previousValueBox}>
              {createElement(reviewComponent.newReviewComponent, {
                ...additionalProps,
                value: getPreviousValue(reviewKey),
              })}
            </div>
            <div className={styles.currentValueBox}>
              {createElement(reviewComponent.newReviewComponent, {
                ...additionalProps,
                value: getCurrentValue(reviewKey),
              })}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

ReviewChanges.propTypes = {
  wrappedComponent: PropTypes.func.isRequired,
  currentValueKey: PropTypes.string.isRequired,
  previousValueKey: PropTypes.string.isRequired,
  getter: PropTypes.shape({}).isRequired,
};

export default ReviewChanges;
