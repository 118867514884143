/* eslint-disable no-param-reassign  */
import { createSlice, current } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import u from 'updeep';
import { propEq } from 'ramda';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import RequestsRepository from 'repositories/admin/RequestsRepository';

const initialState = {
  nextPage: 1,
  loading: false,
  requests: [],
  sortFields: [{ field: 'createdAt', value: 'desc' }],
  totalCount: null,
  saving: false,
};

const adminRequestsPageRequestsTable = createSlice({
  name: 'adminRequestsPageRequestsTable',
  initialState,
  reducers: {
    /* eslint-disable no-unused-vars  */
    initializeRequestsTable(state) {
      state = initialState;
    },
    filterTableRequestsStart(state, { payload }) {
      const {
        filters: { sortFields },
      } = payload;

      state.requests = [];
      state.totalCount = null;
      state.nextPage = 1;
      state.loading = true;
      state.sortFields = sortFields;
    },
    filterTableRequestsSuccess(state, { payload }) {
      const { requests, totalCount, nextPage } = payload;

      state.loading = false;
      state.requests = requests;
      state.totalCount = totalCount;
      state.nextPage = nextPage;
    },
    filterTableRequestsError(state) {
      state.loading = false;
    },
    loadTableRequestsStart(state) {
      state.loading = true;
    },
    loadTableRequestsSuccess(state, { payload }) {
      const { requests, totalCount, nextPage } = payload;

      state.loading = false;
      state.requests = [...state.requests, ...requests];
      state.totalCount = totalCount;
      state.nextPage = nextPage;
    },
    loadTableRequestsError(state) {
      state.loading = false;
    },
    updateTableRequestsData(state, { payload }) {
      const { request } = payload;
      const { requests } = current(state);
      const newRequests = u.map(u.if(propEq(request.id, 'id'), request))(requests);

      state.requests = newRequests;
    },
    bulkDestroyRequestsSuccess(state) {
      state.requests = [];
      state.nextPage = 1;
      state.totalCount = null;
    },
    bulkAssignRequestsStart(state) {
      state.saving = true;
    },
    bulkAssignRequestsSuccess(state) {
      state.saving = false;
      state.requests = [];
      state.nextPage = 1;
      state.totalCount = null;
    },
    bulkUpdateStatusRequestsStart(state) {
      state.saving = true;
    },
    bulkUpdateStatusRequestsSuccess(state) {
      state.saving = false;
      state.requests = [];
      state.nextPage = 1;
      state.totalCount = null;
    },
    bulkUpdateStatusRequestsError(state) {
      state.saving = false;
      state.requests = [];
      state.nextPage = 1;
      state.totalCount = null;
    },
  },
});

const { actions } = adminRequestsPageRequestsTable;

export function initializeRequestsTable() {
  return actions.initializeRequestsTable();
}

export function loadRequests({ page, filters }) {
  return (dispatch) => {
    dispatch(actions.loadTableRequestsStart());

    const query = { page, ...buildFilterParams(filters) };

    return RequestsRepository.index(query)
      .then(({ items, meta }) => {
        dispatch(actions.loadTableRequestsSuccess({ requests: items, ...meta }));
      })
      .catch((error) => {
        dispatch(actions.loadTableRequestsError());

        throw error;
      });
  };
}

export function filterRequests(filters) {
  return (dispatch) => {
    dispatch(actions.filterTableRequestsStart({ filters }));

    const query = buildFilterParams(filters);
    return RequestsRepository.index(query)
      .then(({ items, meta }) => {
        dispatch(actions.filterTableRequestsSuccess({ requests: items, filters, ...meta }));
      })
      .catch((error) => {
        dispatch(actions.filterTableRequestsError());

        throw error;
      });
  };
}

export function updateRequestData(request) {
  return actions.updateTableRequestsData({ request });
}

export function bulkDestroyRequests(params) {
  return (dispatch) =>
    RequestsRepository.bulkDestroy(params).then(() => {
      dispatch(actions.bulkDestroyRequestsSuccess());
    });
}

export function bulkAssignRequests(params) {
  return (dispatch) => {
    dispatch(actions.bulkAssignRequestsStart());

    return RequestsRepository.bulkAssign(params).then(() => {
      dispatch(actions.bulkAssignRequestsSuccess());
    });
  };
}

export function bulkUpdateStatusRequests(params) {
  return (dispatch) => {
    dispatch(actions.bulkUpdateStatusRequestsStart());

    return RequestsRepository.bulkUpdateStatus(params)
      .then(() => {
        dispatch(actions.bulkUpdateStatusRequestsSuccess());
      })
      .catch((errors) => {
        dispatch(actions.bulkUpdateStatusRequestsError());

        throw errors;
      });
  };
}

export const useAdminRequestsPageRequestsTableActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      initializeRequestsTable,
      filterRequests,
      loadRequests,
      bulkDestroyRequests,
      bulkAssignRequests,
      bulkUpdateStatusRequests,
      updateRequestData,
    },
    dispatch,
  );
};

export default adminRequestsPageRequestsTable.reducer;
