import React from 'react';
import cn from 'clsx';
import PropTypes from 'prop-types';

import ProfilePresenter from 'presenters/ProfilePresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import QuestionMarkButton from 'components/QuestionMarkButton';

import UserPolicy from 'policies/UserPolicy';

import Work from './components/Work';
import Fortune500Boards from './components/Fortune500Boards';
import Ftse100Boards from './components/Ftse100Boards';

import styles from './ProfileInformation.module.css';

const ProfileInformation = (props) => {
  const { profile, onOpenGuide, currentUser } = props;
  const currentWorks = ProfilePresenter.currentWorks(profile);
  const isAddressPresent = ProfilePresenter.bothAddresses(profile) || ProfilePresenter.cityWithStateAndZipCode(profile);
  const fortune500Boards = ProfilePresenter.fortune500Boards(profile);
  const ftse100Boards = ProfilePresenter.ftse100Boards(profile);

  const classNames = cn({
    [styles.info]: true,
    maskedText: ProfilePresenter.isMasked(profile),
  });

  return (
    <>
      <div className={styles.fullName}>
        <h1>{ProfilePresenter.fullName(profile)}</h1>
        {ProfilePresenter.isMasked(profile) && (
          <div className={styles.questionMark}>
            <QuestionMarkButton onClick={onOpenGuide} text="Click for details" />
          </div>
        )}
      </div>

      <div>
        {currentWorks.map((work) => (
          <Work key={work.id} work={work} />
        ))}
      </div>

      {fortune500Boards.length > 0 && <Fortune500Boards fortune500Boards={fortune500Boards} />}
      {ftse100Boards.length > 0 && <Ftse100Boards ftse100Boards={ftse100Boards} />}

      <div className={classNames}>
        {ProfilePresenter.emailWithFallback(profile) && UserPolicy.canSeeEmail(currentUser) && (
          <div className={styles.item}>
            <div className={styles.value}>
              <a href={`mailto:${ProfilePresenter.emailWithFallback(profile)}`}>
                {ProfilePresenter.emailWithFallback(profile)}
              </a>
            </div>
          </div>
        )}
        {(isAddressPresent || ProfilePresenter.countryName(profile)) && UserPolicy.canSeeAddress(currentUser) && (
          <div className={styles.item}>
            <div className={styles.value}>
              {isAddressPresent && (
                <>
                  <div>{ProfilePresenter.address1WithFallback(profile)}</div>
                  <div>{ProfilePresenter.address2WithFallback(profile)}</div>
                  {ProfilePresenter.cityWithStateAndZipCode(profile)}
                </>
              )}
              {ProfilePresenter.countryName(profile) && <div>{ProfilePresenter.countryName(profile)}</div>}
            </div>
          </div>
        )}
        {ProfilePresenter.phoneWithFallback(profile) && UserPolicy.canSeePhone(currentUser) && (
          <div className={styles.item}>
            <div className={styles.value}>{ProfilePresenter.phoneWithFallback(profile)}</div>
          </div>
        )}
      </div>
    </>
  );
};

ProfileInformation.propTypes = {
  profile: ProfilePresenter.shape().isRequired,
  onOpenGuide: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
};

export default ProfileInformation;
