import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil, omit, filter } from 'ramda';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { debounce, reactSelectDebounce, isPresent } from 'utils/HelperMethods';

import Table from 'components/Table';
import Button from 'components/Button';
import { Box } from 'grommet';
import Search from 'components/SearchInput';
import UserRowCells from './components/UserRowCells';
import Modal from 'components/Modal';
import NewUserForm from './components/NewUserForm';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import AsyncSelect from 'components/AsyncSelect';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'components/ExportButton';

import Routes from 'routes';
import UserPresenter, { ADMIN_USER_CSV_EXPORT, ROLES, STATES } from 'presenters/UserPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';
import UserPolicy from 'policies/UserPolicy';
import CompanyPolicy from 'policies/CompanyPolicy';

import useBackgroundExportJobs from 'hooks/useBackgroundExportJobs';

import styles from './UserListPage.module.css';

const MAX_SEARCH_LENGTH = 40;
const ROLE_OPTIONS = ROLES.map((r) => ({ label: r.title, value: r.key }));
const STATE_OPTIONS = STATES.map((s) => ({ label: s.title, value: s.key }));
const SUBSCRIPTION_STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Disabled', value: 'disabled' },
];
const PROJECTS_AVAILABILITY_OPTIONS = [
  { label: 'Available', value: 'true' },
  { label: 'Unavailable', value: 'false' },
];
const FILTER_COMPANY_EQ = 'companyIdEq';
const FILTER_COMPANY_DEPARTMENT_EQ = 'companyDepartmentIdEq';
const TABLE_COLUMNS = [
  {
    name: 'id',
    field: 'id',
    width: '125',
  },
  {
    name: 'email',
    field: 'email',
    width: '250px',
  },
  {
    name: 'name',
    field: 'firstName,lastName',
  },
  {
    name: 'Company',
    field: 'companyName',
  },
  {
    name: 'Account Type',
    field: 'role',
  },
  {
    name: 'Account Status',
    field: 'subscriptionStatus',
  },
  {
    name: 'User Status',
    field: 'state',
  },
  {
    name: 'Created At',
    field: 'createdAt',
  },
  {
    name: 'Actions',
    field: '',
  },
];

const UserListPage = (props) => {
  const {
    initializeUserList,
    currentUser,
    filters,
    saving,
    users,
    totalCount,
    impersonate,
    createUser,
    loadUsers,
    onCompanySearch,
    onDepartmentSearch,
    nextPage,
    loading,
    filterUsers,
  } = props;

  const { createBackgroundExportJob, showBackgroundExportJob } = useBackgroundExportJobs();
  const [isNewUser, setNewUserFlag] = useState(false);
  const companyId = filters[FILTER_COMPANY_EQ];

  useEffect(() => {
    initializeUserList();
    loadUsers({ page: 1, filters });
  }, []);

  const handleCreateNewUser = () => setNewUserFlag(true);

  const handleCancelNewUser = () => setNewUserFlag(false);

  const handleSubmitNewUser = (params) =>
    createUser(params)
      .then(() => {
        initializeUserList();
        return loadUsers(1, filters);
      })
      .then(() => setNewUserFlag(false));

  const handleCompanySearch = (value) => onCompanySearch({ searchFieldCont: value });

  const handleDeparmentSearch = (value) => onDepartmentSearch(companyId, { nameCont: value });

  const handleCompanySearchDebounced = reactSelectDebounce(handleCompanySearch);

  const handleDepartmentSearchDebounced = reactSelectDebounce(handleDeparmentSearch);

  const isAllUsersLoaded = () => isNil(nextPage);

  const handleLoadMore = () => {
    if (!loading && !isAllUsersLoaded()) {
      loadUsers({ page: nextPage, filters });
    }
  };

  const updateFilters = (newFilters) => {
    const presentFilters = filter(isPresent, newFilters);

    filterUsers(presentFilters);
  };

  const updateFiltersDebounced = debounce(updateFilters);

  const updateFilter = (fieldName, value) => {
    updateFilters({ ...filters, [fieldName]: value });
  };

  const updateFilterDebounced = debounce(updateFilter);

  const handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      updateFilterDebounced(fieldName, value);

  const handleFilterSelectChange = (fieldName) => (value) => updateFilterDebounced(fieldName, value);

  const handleCompanyIdChange = (fieldName) => (value) => {
    const newFilters = omit([FILTER_COMPANY_DEPARTMENT_EQ], filters);

    updateFiltersDebounced({ ...newFilters, [fieldName]: value });
  };

  const handleDelete = (ids) => {
    const { bulkDestroyUsers } = props;

    bulkDestroyUsers({ ids }).then(() => filterUsers(filters));
  };

  const handleSort = (sortFields) => {
    filterUsers({ ...filters, sortFields });
  };

  const handleUserClick = (userId) => {
    if (UserPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminUserPath(userId));
    }
  };

  const isVisibleDepartmentFilter = () => {
    const isCompanyFilterFilled = isPresent(filters[FILTER_COMPANY_EQ]);

    return CompanyPolicy.canSeeList(currentUser) && isCompanyFilterFilled;
  };

  const handleCSVExport = () =>
    createBackgroundExportJob({
      type: ADMIN_USER_CSV_EXPORT,
      params: buildFilterParams(filters, 'search'),
    });

  return (
    <Box margin="none" full="horizontal">
      <PageHeader title="Users" />

      <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
        <Box width="medium" margin={{ right: 'small' }}>
          <Search placeholder="Search" onChange={handleFilterChange('searchFieldCont')} maxLength={MAX_SEARCH_LENGTH} />
        </Box>
        {UserPolicy.canAddElement(currentUser) && (
          <Box margin={{ right: 'small' }}>
            <Button primary label="Add User" onClick={handleCreateNewUser} />
          </Box>
        )}
        {UserPolicy.canExportList(currentUser) && (
          <Box margin={{ right: 'small' }}>
            <BackgroundExportResultNotifier
              component={ExportButton}
              onExport={handleCSVExport}
              onPoll={showBackgroundExportJob}
              onExportPropName="onClick"
              label="Export"
              shouldOpenOnCompletion
              className={styles.exportButton}
            />
          </Box>
        )}
      </Box>
      <Box direction="row" margin={{ bottom: 'medium' }}>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange('roleIn')}
            options={ROLE_OPTIONS}
            selectedOptionValue={filters.roleIn}
            isClearable
            noBottom
            placeholder="Account Type"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange('subscriptionStatusEq')}
            options={SUBSCRIPTION_STATUS_OPTIONS}
            selectedOptionValue={filters.subscriptionStatusEq}
            isClearable
            noBottom
            placeholder="Account Status"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange('stateEq')}
            options={STATE_OPTIONS}
            selectedOptionValue={filters.stateEq}
            isClearable
            noBottom
            placeholder="User Status"
          />
        </Box>
        {CompanyPolicy.canSeeList(currentUser) && (
          <Box width="small" margin={{ right: 'small' }}>
            <AsyncSelect
              placeholder="Company"
              loadOptions={handleCompanySearchDebounced}
              defaultOptions
              onValueChange={handleCompanyIdChange(FILTER_COMPANY_EQ)}
              getOptionValue={CompanyPresenter.id}
              getOptionLabel={CompanyPresenter.name}
              isClearable
              noBottom
            />
          </Box>
        )}
        {isVisibleDepartmentFilter() && (
          <Box width="small" margin={{ right: 'small' }}>
            <AsyncSelect
              key={companyId}
              placeholder="Department"
              loadOptions={handleDepartmentSearchDebounced}
              defaultOptions
              onValueChange={handleFilterSelectChange(FILTER_COMPANY_DEPARTMENT_EQ)}
              getOptionValue={DepartmentPresenter.id}
              getOptionLabel={DepartmentPresenter.name}
              isClearable
              noBottom
            />
          </Box>
        )}
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange('projectsAvailableTrue')}
            options={PROJECTS_AVAILABILITY_OPTIONS}
            selectedOptionValue={filters.projectsAvailableTrue}
            isClearable
            noBottom
            placeholder="Projects"
          />
        </Box>
      </Box>
      <Table
        columns={TABLE_COLUMNS}
        sorts={filters.sortFields}
        onDelete={handleDelete}
        onMore={handleLoadMore}
        onRowClick={handleUserClick}
        onSort={handleSort}
        hasMore={!isAllUsersLoaded()}
        rows={users}
        rowCellsComponent={<UserRowCells currentUser={currentUser} onImpersonate={impersonate} />}
        totalRows={totalCount}
        rowsDeletable={UserPolicy.canDestroyElement(currentUser)}
      />
      {isNewUser && (
        <Modal onClose={handleCancelNewUser} size="medium" header="New User" overflow>
          <NewUserForm onSubmit={handleSubmitNewUser} saving={saving} />
        </Modal>
      )}
    </Box>
  );
};

UserListPage.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  saving: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  loadUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  bulkDestroyUsers: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
  filterUsers: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  initializeUserList: PropTypes.func.isRequired,
  impersonate: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  onCompanySearch: PropTypes.func.isRequired,
  onDepartmentSearch: PropTypes.func.isRequired,
};

export default UserListPage;
