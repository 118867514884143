import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(helpRequest) {
    const url = Routes.apiV1CustomerHelpRequestPath();
    return FetchHelpers.post(url, helpRequest);
  },

  destroy() {
    const url = Routes.apiV1CustomerHelpRequestPath();
    return FetchHelpers.delete(url);
  },
};
