import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Button from 'components/Button';
import { SubtractCircle } from 'grommet-icons';

import AliasPresenter from 'presenters/AliasPresenter';

import styles from './Alias.module.css';

const Alias = (props) => {
  const { alias, onDelete, editable, saving } = props;

  const handleDelete = () => onDelete(alias);

  return (
    <Box margin={{ bottom: 'small' }}>
      <div className={styles.item}>
        <div className={styles.text}>{AliasPresenter.title(alias)}</div>
        <Button disabled={!editable || saving} icon={<SubtractCircle />} onClick={handleDelete} plain />
      </div>
    </Box>
  );
};

Alias.propTypes = {
  alias: AliasPresenter.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Alias;
