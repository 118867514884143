import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import styles from './SuccessStatus.module.css';

const SuccessStatus = (props) => {
  const { icon, message } = props;

  return (
    <div className={styles.successStatus}>
      {icon}
      <Typography variant="h3">{message}</Typography>
      <Button variant="link">
        <Typography color="primary" variant="h3">
          Upload another file
        </Typography>
      </Button>
    </div>
  );
};

SuccessStatus.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
};

SuccessStatus.defaultProps = {
  icon: null,
};

export default SuccessStatus;
