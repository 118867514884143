import { adminSampleProjectsRestHooks } from 'slices/admin/sampleProjectsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useSampleProjects = () => {
  const { sampleProject, sampleProjects, loadSampleProjects, updateSampleProject } = adminSampleProjectsRestHooks.use();

  return {
    sampleProjects: sampleProjects.items,
    sampleProjectsLoadingStatus: useFetchStatus(sampleProjects.loadingStatus),
    sampleProjectsUpdatingStatus: useFetchStatus(sampleProject.updatingStatus),
    loadSampleProjects,
    updateSampleProject,
  };
};

export default useSampleProjects;
