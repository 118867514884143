import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { FormPreviousLink as FormPreviousLinkIcon } from 'grommet-icons';
import { Link } from 'react-router-dom';
import styles from './PageHeader.module.css';

export default class PageHeader extends Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    backTo: PropTypes.string,
  };

  render() {
    const { title, backTo } = this.props;

    return (
      <Box margin={{ bottom: 'none' }}>
        <div className={styles.header}>
          {backTo ? (
            <Link to={backTo}>
              <FormPreviousLinkIcon size="medium" />
            </Link>
          ) : (
            ''
          )}
          {title}
        </div>
      </Box>
    );
  }
}
