import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, TextInput } from 'grommet';

import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import UserForm from 'forms/company/UserRegistrationForm';

import styles from './UserRegistrationForm.module.css';

class UserRegistrationForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      user: UserForm.defaultAttributes(),
      errors: null,
    };
  }

  updateField = (fieldName, value) => {
    const { user } = this.state;

    this.setState({ user: { ...user, [fieldName]: value } });
  };

  handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleSelectChange = (fieldName) => (value) => this.updateField(fieldName, value);

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { user } = this.state;

    return onSubmit(UserForm.attributesToSubmit(user)).catch(({ errors }) => this.setState({ errors }));
  };

  render() {
    const { saving } = this.props;
    const { user, errors } = this.state;

    return (
      <Box>
        {errors && errors.company && <div className={styles.danger}>{errors.company.join(', ')}</div>}
        <VerticalFormFieldWithBottomErrors label="FIRST NAME" errors={errors && errors.firstName} htmlFor="firstName">
          <TextInput value={user.firstName} onChange={this.handleFieldChange('firstName')} id="firstName" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors label="LAST NAME" errors={errors && errors.lastName} htmlFor="lastName">
          <TextInput value={user.lastName} onChange={this.handleFieldChange('lastName')} id="lastName" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors label="EMAIL ADDRESS" errors={errors && errors.email} htmlFor="email">
          <TextInput value={user.email} onChange={this.handleFieldChange('email')} id="email" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors
          label="CONFIRM EMAIL ADDRESS"
          errors={errors && errors.emailConfirmation}
          htmlFor="emailConfirmation"
        >
          <TextInput
            value={user.emailConfirmation}
            onChange={this.handleFieldChange('emailConfirmation')}
            id="emailConfirmation"
          />
        </VerticalFormFieldWithBottomErrors>
        <Box direction="row" margin={{ top: 'small' }}>
          <Button label="Submit" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </Box>
    );
  }
}

export default UserRegistrationForm;
