import ProfileRelationshipMappingAdvertisementConstants from './ProfileRelationshipMappingAdvertisementConstants';
import ProfileRelationshipsRepository from 'repositories/Profile/RelationshipsMapping/RelationshipsRepository';

export function loadProfileRelationships(profileId, { per }) {
  return (dispatch) => {
    dispatch({ type: ProfileRelationshipMappingAdvertisementConstants.LOAD_PROFILE_RELATIONSHIPS_START, profileId });

    return ProfileRelationshipsRepository.index(profileId, profileId, { per })
      .then(({ items }) => {
        dispatch({
          type: ProfileRelationshipMappingAdvertisementConstants.LOAD_PROFILE_RELATIONSHIPS_SUCCESS,
          profileId,
          relationships: items,
        });

        return items;
      })
      .catch(() => {
        dispatch({
          type: ProfileRelationshipMappingAdvertisementConstants.LOAD_PROFILE_RELATIONSHIPS_ERROR,
          profileId,
          relationships: [],
        });

        return [];
      });
  };
}

export function clearProfileRelationships() {
  return { type: ProfileRelationshipMappingAdvertisementConstants.CLEAR_PROFILE_RELATIONSHIPS };
}
