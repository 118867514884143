import React from 'react';
import Routes from 'routes';

import Button from 'components/v2/Button';
import Link from 'components/v2/Link';
import Typography from 'components/v2/Typography';

import styles from './SuccessMessageWizardStep.module.css';

const SuccessMessageWizardStep = () => (
  <div className={styles.root}>
    <Typography className={styles.title} variant="h1">
      Your request has been sent
    </Typography>
    <Typography className={styles.subtitle} variant="h2">
      Requests of 10 or less profiles will be delivered within 2 business days.
    </Typography>
    <Button component={Link} to={Routes.newRequestPath()}>
      Back to Request a Profile
    </Button>
  </div>
);

export default SuccessMessageWizardStep;
