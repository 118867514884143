import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { decamelize } from 'utils/keysConverter';

export default {
  create(companyId, user) {
    const url = Routes.apiV1AdminCompanyUsersPath(companyId);
    return FetchHelpers.post(url, { user });
  },

  index(companyId, query) {
    const url = Routes.apiV1AdminCompanyUsersPath(companyId, decamelize(query));
    return FetchHelpers.get(url);
  },

  bulkDestroy(companyId, query) {
    const url = Routes.bulkDestroyApiV1AdminCompanyUsersPath(companyId, query);
    return FetchHelpers.delete(url);
  },

  bulkCreate(companyId, users) {
    const url = Routes.bulkCreateApiV1AdminCompanyUsersPath(companyId);
    return FetchHelpers.post(url, { users });
  },
};
