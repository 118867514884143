import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import styles from './IdleStatus.module.css';

const IdleStatus = (props) => {
  const { message } = props;

  return (
    <div className={styles.idleStatus}>
      <Typography variant="h3">{message}</Typography>
      <Typography variant="body1">or</Typography>
      <Button variant="link">
        <Typography color="primary" variant="h3">
          Choose file
        </Typography>
      </Button>
    </div>
  );
};

IdleStatus.propTypes = {
  message: PropTypes.string.isRequired,
};

export default IdleStatus;
