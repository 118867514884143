import PropTypes from 'prop-types';
import React from 'react';
import Routes from 'routes';
import { isNil } from 'ramda';

import SingleSignOnPresenter from 'presenters/SingleSignOnPresenter';

import Button from 'components/v2/Button';
import Link from 'components/v2/Link';
import Typography from 'components/v2/Typography';
import { Field, Input } from 'components/v2/Form';

import styles from './ServiceProviderInformationFields.module.css';

const ServiceProviderInformationFields = (props) => {
  const { errors, onFieldChange, ssoValues } = props;

  const isNewRecord = isNil(ssoValues.id);

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Service Provider Information</Typography>
      <Field title="Assertion Consumer Service Url:" variant="outlined">
        <Input value={ssoValues.assertionConsumerServiceUrl} disabled />
      </Field>
      <Field title="Entity ID:" variant="outlined">
        <Input value={ssoValues.spEntityId} disabled />
      </Field>
      <Field title="First Name Attribute Name:" error={errors.firstNameAttributeName} variant="outlined">
        <Input onChange={handleChange('firstNameAttributeName')} value={ssoValues.firstNameAttributeName} />
      </Field>
      <Field title="Last Name Attribute Name:" error={errors.lastNameAttributeName} variant="outlined">
        <Input onChange={handleChange('lastNameAttributeName')} value={ssoValues.lastNameAttributeName} />
      </Field>
      <Field title="Email Attribute Name:" error={errors.emailAttributeName} variant="outlined">
        <Input onChange={handleChange('emailAttributeName')} value={ssoValues.emailAttributeName} />
      </Field>
      <Button
        className={styles.downloadMetaButton}
        component={Link}
        color="secondary"
        href={isNewRecord ? '' : Routes.downloadSamlMetadataApiV1AdminSingleSignOnPath(ssoValues.id)}
        disabled={isNewRecord}
        variant="outlined"
      >
        Download Meta
      </Button>
    </div>
  );
};

ServiceProviderInformationFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  ssoValues: SingleSignOnPresenter.shape().isRequired,
};

export default ServiceProviderInformationFields;
