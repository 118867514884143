import React from 'react';
import { useHistory } from 'react-router-polyfill';
import Routes from 'routes';

import { useAlert } from 'hooks';
import { useAdminCompanyDepartments } from 'hooks/admin';

import Form from 'components/v2/Form';

import CompanyDepartmentForm from 'forms/company/DepartmentForm';

import EditForm from './components/EditForm';

const EditTab = () => {
  const alert = useAlert();
  const history = useHistory();
  const {
    companyDepartment,
    companyDepartmentDestroyingStatus,
    destroyCompanyDepartment,
    resetCompanyDepartmentSalesforceToken,
    updateCompanyDepartment,
  } = useAdminCompanyDepartments();

  const handleCompanyDepartmentUpdate = async (attributes) => {
    await updateCompanyDepartment(
      companyDepartment.company.id,
      companyDepartment.id,
      CompanyDepartmentForm.attributesToSubmit(attributes),
    );

    alert.successAction('Company department was successfully saved');
  };

  const handleCompanyDepartmentDestroy = async () => {
    try {
      await destroyCompanyDepartment(companyDepartment.company.id, companyDepartment.id);
      alert.successAction('Company department was successfully deleted');
      history.push(Routes.adminCompanyPath(companyDepartment.company.id));
    } catch (error) {
      alert.failedAction('Company department wasn\t deleted');
    }
  };

  const handleCompanyDepartmentSalesforceTokenReset = async () => {
    await resetCompanyDepartmentSalesforceToken(companyDepartment.company.id, companyDepartment.id);

    alert.successAction('Company department Salesforce Token was successfully updated');
  };

  return (
    <Form
      enableReinitialize
      initialValues={CompanyDepartmentForm.defaultAttributes(companyDepartment)}
      onSubmit={handleCompanyDepartmentUpdate}
    >
      {(formProps) => (
        <EditForm
          {...formProps}
          onDestroy={handleCompanyDepartmentDestroy}
          isDestroying={companyDepartmentDestroyingStatus.isPending}
          onSalesforceTokenReset={handleCompanyDepartmentSalesforceTokenReset}
        />
      )}
    </Form>
  );
};

export default EditTab;
