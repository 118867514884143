import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'containers/UserPanel/containers/Project/containers/AvailableProfilesContainer'; // eslint-disable-line import/named
import Component from 'containers/UserPanel/containers/Project/containers/RelationshipsMapping/components/ExcludeProfilesForm';

const mapStateToProps = (state) => ({
  loading: state.AvailableProfilesContainerReducer.loading,
  profiles: state.AvailableProfilesContainerReducer.profiles,
  totalCount: state.AvailableProfilesContainerReducer.totalCount,
  nextPage: state.AvailableProfilesContainerReducer.nextPage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      filterProjectProfiles: actions.filterProjectAvailableProfiles,
      loadProjectProfiles: actions.loadProjectAvailableProfiles,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Component);
