import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import qs from 'qs';
import { uniq } from 'ramda';
import { goToTop, goToElement } from 'utils/DOMHelpers';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';
import TopicPresenter from 'presenters/TopicPresenter';
import BoardPresenter from 'presenters/BoardPresenter';
import InstitutionPresenter from 'presenters/InstitutionPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import { Button, Anchor } from 'grommet';
import Icon from 'components/Icon';
import ProfileMeta from 'components/ProfileMeta';
import RawHtml from 'components/RawHtml';
import Spoiler from 'components/Spoiler';
import Loader from 'components/v2/Loader';
import VisibilitySensor from 'react-visibility-sensor';

import styles from './ViewSkinnyProfile.module.css';

const getKeywords = (location) => {
  const { keywords } = qs.parse(location.search, { ignoreQueryPrefix: true, arrayFormat: 'bracket' });

  return keywords || [];
};

const ViewSkinnyProfile = (props) => {
  const { loading, profile, canSeeFullProfileRequestForm, loadPublishedProfile, profileSlug, location } = props;

  const formRef = useRef(null);

  const [isStickyVisible, setIsStickyVisible] = useState(false);

  const slug = ProfilePresenter.slug(profile);
  const pardotUrl = `https://info2.boardroominsiders.com/l/311771/2023-01-19/y8mxc?Requested_Profile=${slug}`;
  const currentWorks = ProfilePresenter.currentWorks(profile) || [];
  const currentOrganizations = currentWorks.map(WorkPresenter.organization);
  const currentOrganizationNames = uniq(currentOrganizations.map(OrganizationPresenter.name)).join(', ');
  const stickyStyles = cn([styles.sticky], { [styles.visible]: isStickyVisible });
  const rootStyles = cn({ [styles.withoutSidebar]: !canSeeFullProfileRequestForm });
  const isAddressPresent = ProfilePresenter.bothAddresses(profile) || ProfilePresenter.cityWithStateAndZipCode(profile);
  const infoStyles = cn([styles.info], [styles.maskedText]);

  useEffect(() => {
    loadPublishedProfile(profileSlug, { keywords: getKeywords(location) });
  }, []);

  const onCommonSectionChange = (visible) => {
    setIsStickyVisible(!visible);
  };

  const handleDownloadProfile = () => {
    goToElement(formRef.current);
  };

  const handleScrollTop = () => goToTop();

  const renderEmbedForm = () =>
    canSeeFullProfileRequestForm && (
      <div className={styles.side} ref={formRef}>
        <iframe
          data-attributer-iframe
          src={pardotUrl}
          width="100%"
          height="500"
          type="text/html"
          frameBorder="0"
          style={{ border: 0 }}
          title="form"
        />
      </div>
    );

  const renderCurrentWork = (currentWork) => (
    <div key={WorkPresenter.id(currentWork)} className={styles.work}>
      <strong>{WorkPresenter.fullJobTitle(currentWork)}</strong>
      <br />
      <strong>{WorkPresenter.organizationName(currentWork)}</strong>
    </div>
  );

  if (loading || !profile) {
    return <Loader />;
  }

  return (
    <div className={rootStyles}>
      <ProfileMeta profile={profile} indexable canonicalPath="skinnyProfilePath" />
      <VisibilitySensor partialVisibility onChange={onCommonSectionChange}>
        <div className={styles.common}>
          <div className={styles.left}>
            {ProfilePresenter.photoUrl(profile) ? (
              <div className={styles.userpicContainer}>
                <img src={ProfilePresenter.photoUrl(profile)} alt="userpic" className={styles.userpic} />
              </div>
            ) : (
              <div className={styles.userpicContainer}>
                <Icon name="avatarPlaceholder" />
              </div>
            )}
          </div>
          <div className={styles.center}>
            <h1>{ProfilePresenter.fullName(profile)}</h1>
            <div>{currentWorks.map(renderCurrentWork)}</div>
            <div className={infoStyles}>
              {ProfilePresenter.emailWithFallback(profile) && (
                <div className={styles.item}>
                  <div className={styles.value}>
                    <a href={`mailto:${ProfilePresenter.emailWithFallback(profile)}`}>
                      {ProfilePresenter.emailWithFallback(profile)}
                    </a>
                  </div>
                </div>
              )}
              {(isAddressPresent || ProfilePresenter.countryName(profile)) && (
                <div className={styles.item}>
                  <div className={styles.value}>
                    {isAddressPresent && (
                      <>
                        <div>{ProfilePresenter.address1WithFallback(profile)}</div>
                        <div>{ProfilePresenter.address2WithFallback(profile)}</div>
                        {ProfilePresenter.cityWithStateAndZipCode(profile)}
                      </>
                    )}
                    {ProfilePresenter.countryName(profile) && <div>{ProfilePresenter.countryName(profile)}</div>}
                  </div>
                </div>
              )}
              {ProfilePresenter.phoneWithFallback(profile) && (
                <div className={styles.item}>
                  <div className={styles.value}>{ProfilePresenter.phoneWithFallback(profile)}</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.date}>Last Updated: {ProfilePresenter.formattedLastUpdatedAt(profile)}</div>
            {canSeeFullProfileRequestForm && (
              <Anchor className={styles.link} label="Download Profile" onClick={handleDownloadProfile} />
            )}
          </div>
        </div>
      </VisibilitySensor>
      <div className={stickyStyles}>
        <div className={styles.container}>
          <div className={styles.userInfo}>
            <div className={styles.avatar}>
              {ProfilePresenter.photoUrl(profile) ? (
                <img src={ProfilePresenter.photoUrl(profile)} alt="userpic" />
              ) : (
                <Icon name="avatarPlaceholder" />
              )}
            </div>
            <div className={styles.commonInfo}>
              <strong className={styles.stickyName} title={ProfilePresenter.fullName(profile)}>
                {ProfilePresenter.fullName(profile)}
              </strong>
              {currentOrganizations.length > 0 && (
                <div className={styles.stickyWork} title={currentOrganizationNames}>
                  {currentOrganizationNames}
                </div>
              )}
            </div>
          </div>
          <div className={styles.action}>
            <Button onClick={handleScrollTop} plain className={styles.scroll}>
              <span className={styles.scrollContent}>
                <span className={styles.text}>To the top </span>
                <span className={styles.arrow}>
                  <Icon name="arrowUp" />
                </span>
              </span>
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.personInfo}>
        {renderEmbedForm()}
        <div className={styles.summary}>
          <div className={styles.wrapper}>
            {ProfilePresenter.briefBiography(profile) && (
              <section>
                <Spoiler header="Executive Summary">
                  <RawHtml component="p" innerHTML={ProfilePresenter.briefBiography(profile)} />
                </Spoiler>
              </section>
            )}

            {ProfilePresenter.publicPersonInformation(profile) && (
              <section>
                <Spoiler header="Personal Attributes and Interests">
                  <RawHtml component="p" innerHTML={ProfilePresenter.publicPersonInformation(profile)} />
                </Spoiler>
              </section>
            )}

            {ProfilePresenter.currentFocusTopics(profile).length > 0 && (
              <section>
                <Spoiler header="Current Focus">
                  {ProfilePresenter.currentFocusTopics(profile).map((topic) => (
                    <Fragment key={topic.id}>
                      <RawHtml component="h4" className={styles.strong} innerHTML={TopicPresenter.title(topic)} />
                      <RawHtml className={styles.wysiwyg} innerHTML={TopicPresenter.description(topic)} />
                    </Fragment>
                  ))}
                </Spoiler>
              </section>
            )}

            {ProfilePresenter.keyChallengesTopics(profile).length > 0 && (
              <section>
                <Spoiler header="Key Challenges">
                  {ProfilePresenter.keyChallengesTopics(profile).map((topic) => (
                    <Fragment key={topic.id}>
                      <RawHtml component="h4" className={styles.strong} innerHTML={TopicPresenter.title(topic)} />
                      <RawHtml className={styles.wysiwyg} innerHTML={TopicPresenter.description(topic)} />
                    </Fragment>
                  ))}
                </Spoiler>
              </section>
            )}

            {ProfilePresenter.works(profile).length > 0 && (
              <section>
                <Spoiler header="Employment History">
                  <ul>
                    {ProfilePresenter.works(profile).map((work) => (
                      <li key={work.id} className={cn({ [styles.maskedText]: WorkPresenter.isMasked(work) })}>
                        <RawHtml component="span" innerHTML={WorkPresenter.skinnyJobTitle(work)} />
                      </li>
                    ))}
                  </ul>
                </Spoiler>
              </section>
            )}

            {ProfilePresenter.boards(profile).length > 0 && (
              <section>
                <Spoiler header="Other Boards and Organizations">
                  <ul>
                    {ProfilePresenter.boards(profile).map((board) => (
                      <li key={board.id} className={cn({ [styles.maskedText]: BoardPresenter.isMasked(board) })}>
                        {BoardPresenter.description(board)}, {BoardPresenter.organizationName(board)}
                        {` ${BoardPresenter.timeInterval(board)}`}
                      </li>
                    ))}
                  </ul>
                </Spoiler>
              </section>
            )}

            {ProfilePresenter.institutions(profile).length > 0 && (
              <section>
                <Spoiler header="Education History">
                  <ul>
                    {ProfilePresenter.institutions(profile).map((institution) => (
                      <li
                        key={institution.id}
                        className={cn({ [styles.maskedText]: InstitutionPresenter.isMasked(institution) })}
                      >
                        <RawHtml component="span" innerHTML={InstitutionPresenter.skinnyDegree(institution)} />
                      </li>
                    ))}
                  </ul>
                </Spoiler>
              </section>
            )}
          </div>
        </div>
      </div>
      <p className={styles.importantText}>All quarterly earnings call content is licensed from Refinitiv.</p>
    </div>
  );
};

ViewSkinnyProfile.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadPublishedProfile: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
  profile: ProfilePresenter.shape(),
  profileSlug: PropTypes.string.isRequired,
  canSeeFullProfileRequestForm: PropTypes.bool.isRequired,
};

export default ViewSkinnyProfile;
