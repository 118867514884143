import React from 'react';

import Loader from 'components/v2/Loader';

import styles from './TableLoaderRow.module.css';

const TableLoaderRow = (props) => (
  <tr className={styles.loaderRow}>
    <td {...props}>
      <Loader className={styles.loader} />
    </td>
  </tr>
);

export default TableLoaderRow;
