import { adminCompanySingleSignOnRestHooks } from 'slices/admin/company/singleSignOnSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useSingleSignOn = () => {
  const { singleSignOn, loadSingleSignOn, updateSingleSignOn } = adminCompanySingleSignOnRestHooks.use();

  return {
    singleSignOn: singleSignOn.item,
    singleSignOnLoadingStatus: useFetchStatus(singleSignOn.loadingStatus),
    singleSignOnUpdatingStatus: useFetchStatus(singleSignOn.updatingStatus),
    loadSingleSignOn,
    updateSingleSignOn,
  };
};

export default useSingleSignOn;
