import { DateTime, Duration } from 'luxon';
import { isBlank, isPresent } from 'utils/HelperMethods';

export const GROMMET_DATE_FORMAT = 'MM/DD/YYYY';
export const GROMMET_YEAR_FORMAT = 'YYYY';
export const GROMMET_MONTH_YEAR_FORMAT = 'MM/YYYY';
export const LUXON_DATETIME_FORM = 'dd-MM-yyyy hh:mm:ss';

export const LUXON_DATE_FORMAT = 'MM/dd/yyyy';
export const LUXON_YEAR_FORMAT = 'yyyy';
export const LUXON_MONTH_YEAR_FORMAT = 'MM/yyyy';
const LUXON_LOCALIZED_FORMAT = 'DDD';
const LUXON_LOCALIZED_NUMERIC_DATE = 'D';

export function timeInterval(from, to) {
  if (from && to) {
    return `(from ${from} to ${to})`;
  }

  if (from) {
    return `(from ${from})`;
  }

  if (to) {
    return `(to ${to})`;
  }

  return '';
}

export function parseISO8601(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string);
}

export function dateToISO(string) {
  return DateTime.fromISO(string).toISODate();
}

export function fromJSDate(date) {
  if (isBlank(date)) {
    return null;
  }

  return DateTime.fromJSDate(date);
}

export function fromISOtoGrommetDate(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string).toFormat(LUXON_DATE_FORMAT);
}

export function fromISOtoGrommetYear(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string).toFormat(LUXON_YEAR_FORMAT);
}

export function fromISOtoGrommetMonthYear(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string).toFormat(LUXON_MONTH_YEAR_FORMAT);
}

export function fromGrommetDate(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromFormat(string, LUXON_DATE_FORMAT);
}

export function fromGrommetDateToISO(string) {
  const date = fromGrommetDate(string);

  return isBlank(date) ? null : date.toISODate();
}

export function fromGrommetYear(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromFormat(string, LUXON_YEAR_FORMAT);
}

export function fromGrommetMonthYear(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromFormat(string, LUXON_MONTH_YEAR_FORMAT);
}

export function fromGrommetYearToISO(string) {
  const year = fromGrommetYear(string);

  return isBlank(year) ? null : year.toISODate();
}

export function fromGrommetMonthYearToISO(string) {
  const year = fromGrommetMonthYear(string);

  return isBlank(year) ? null : year.toISODate();
}

export function formattedDate(string, zone = 'local') {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string, { zone }).setLocale('en-US').toFormat(LUXON_LOCALIZED_FORMAT);
}

export function formattedNumericDate(string, zone = 'local') {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string, { zone }).setLocale('en-US').toFormat(LUXON_LOCALIZED_NUMERIC_DATE);
}

export function currentDateTime() {
  return DateTime.local();
}

export function currentIsoDateTime() {
  return DateTime.local().toISO();
}

export function formattedYear(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string).toFormat(LUXON_YEAR_FORMAT);
}

export function formattedMonthYear(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string).toFormat(LUXON_MONTH_YEAR_FORMAT);
}

export function currentYear() {
  return DateTime.local().toFormat('yyyy');
}

export function formattedDateFromSeconds(seconds, format) {
  return DateTime.fromSeconds(seconds).toFormat(format);
}

export function fromISO(string) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string);
}

export function formattedDurationFromSeconds(seconds) {
  const duration = Duration.fromMillis(seconds * 1000).shiftTo(
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  );

  if (duration.days > 0) {
    return duration.toFormat("d 'days' hh 'hours' mm 'minutes'");
  }

  if (duration.hours > 0) {
    return duration.toFormat("h 'hours' mm 'minutes'");
  }

  return duration.toFormat("m 'minutes'");
}

export function maxDate(...args) {
  const dates = args.map(fromISO).filter(isPresent);

  if (dates.length === 0) {
    return null;
  }

  return DateTime.max(...dates).toISODate();
}

export function toRelativeCalendar(string) {
  const date = fromISO(string);

  return isPresent(date) ? date.toRelativeCalendar() : null;
}

export function fromISOtoFormattedDate(string, format) {
  if (isBlank(string)) {
    return null;
  }

  return DateTime.fromISO(string).toFormat(format);
}
