import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { not } from 'ramda';
import { Box, CheckBox, TextInput } from 'grommet';

import { LUXON_YEAR_FORMAT } from 'utils/DateTime';

import { Field, DatePicker } from 'components/v2/Form';
import Button from 'components/Button';
import Modal from 'components/Modal';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import OrganizationSelector from 'containers/AdminPanel/containers/OrganizationSelector';

import BoardPresenter from 'presenters/BoardPresenter';
import ProfileBoardForm from 'forms/profile/BoardForm';

import styles from './BoardForm.module.css';

export default class BoardForm extends Component {
  constructor(props) {
    super(props);

    const { board } = props;

    this.state = {
      errors: {},
      fields: ProfileBoardForm.defaultAttributes(board),
    };
  }

  handleDOMChange = (fieldName) => (event) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: event.target.value } });
  };

  handleDateChange = (fieldName) => (value) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: value } });
  };

  handleToPresentTimeChange = ({ target }) => {
    this.setState({ fields: { ...this.state.fields, toPresentTime: target.checked, to: '' } });
  };

  handleRelevantChange = ({ target }) => {
    this.setState({ fields: { ...this.state.fields, relevant: target.checked } });
  };

  handleSubmit = () => {
    const params = ProfileBoardForm.attributesToSubmit(this.state.fields);

    this.props.onSubmit(params).catch(this.handleErrors);
  };

  handleErrors = ({ errors = {} }) => {
    this.setState({ errors });
  };

  handleOrganizationSelect = (organization) => {
    this.setState({ fields: { ...this.state.fields, organization } });
  };

  handleDisplayChange = ({ target: { checked } }) =>
    this.setState({ fields: { ...this.state.fields, display: checked } });

  errorFor(fieldName) {
    const {
      errors: { [fieldName]: error },
    } = this.state;

    return error;
  }

  renderDateTimeField(field, label) {
    const {
      fields: { [field]: value },
    } = this.state;

    return (
      <div className={styles.dateRow}>
        <div className={styles.dateLabel}>{label}</div>
        <div className={styles.dateInput}>
          <Field error={this.errorFor(field)}>
            <DatePicker
              value={value}
              onChange={this.handleDateChange(field)}
              showYearPicker
              dateFormat={LUXON_YEAR_FORMAT}
            />
          </Field>
        </div>
      </div>
    );
  }

  render() {
    const { fields } = this.state;
    const { saving, title } = this.props;

    return (
      <Modal
        onClose={this.props.onCancel}
        header={title}
        margin="medium"
        size="medium"
        disableOverlayClose
        disableCloseByEsc
      >
        <Box margin={{ bottom: 'medium' }}>
          <VerticalFormFieldWithBottomErrors checkbox>
            <CheckBox label="Display" toggle checked={fields.display} onChange={this.handleDisplayChange} />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors
            errors={this.errorFor('description')}
            label="Description"
            htmlFor="description"
          >
            <TextInput value={fields.description} onChange={this.handleDOMChange('description')} id="description" />
          </VerticalFormFieldWithBottomErrors>
          <Box margin={{ bottom: 'small' }} className={styles.title}>
            <OrganizationSelector
              errors={this.errorFor('organization')}
              placeholder="Organization"
              value={fields.organization}
              onChange={this.handleOrganizationSelect}
            />
          </Box>
          <Box margin={{ bottom: 'small' }}>
            Date
            {this.renderDateTimeField('from', 'From')}
            {not(fields.toPresentTime) && this.renderDateTimeField('to', 'To')}
            <div className={styles.dateRow}>
              <div className={styles.dateLabel} />
              <div className={styles.dateInput}>
                <CheckBox label="To Present" checked={fields.toPresentTime} onChange={this.handleToPresentTimeChange} />
              </div>
            </div>
            <div className={styles.dateRow}>
              <div className={styles.dateLabel} />
              <div className={styles.dateInput}>
                <CheckBox label="Relevant board" checked={fields.relevant} onChange={this.handleRelevantChange} />
              </div>
            </div>
          </Box>
        </Box>

        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}

BoardForm.propTypes = {
  title: PropTypes.string.isRequired,
  board: BoardPresenter.shape(),
  saving: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
