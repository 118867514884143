import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';
import { Close as CloseIcon, Edit as EditIcon, CircleInformation as CircleInformationIcon } from 'grommet-icons';

import Card from 'components/Card';
import Headline from 'components/grommet/Headline';
import StyleGuideModal from 'components/StyleGuideModal';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';

import InstitutionForm from '../InstitutionForm';

import InstitutionPresenter from 'presenters/InstitutionPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import Routes from 'routes';

import styles from './Institution.module.css';

export default class Institution extends Component {
  state = { edit: false };

  handleCancelEdit = () => this.setState({ edit: false });

  handleSubmit = ({ id, ...params }) => this.props.onUpdate(id, params).then(this.handleCancelEdit);

  handleEdit = () => this.setState({ edit: true });

  handleDestroy = () => {
    const { onDestroy, institution } = this.props;

    onDestroy(institution.id);
  };

  render() {
    const { edit } = this.state;
    const { editable, saving, institution } = this.props;
    const organization = InstitutionPresenter.organization(institution);
    const fromYear = InstitutionPresenter.fromYear(institution);
    const toYear = InstitutionPresenter.toYear(institution);

    return (
      <Card className={styles.root}>
        <Headline size="small" margin="none" className={styles.title}>
          <Anchor
            href={Routes.editAdminOrganizationPath(organization.id)}
            label={OrganizationPresenter.nameWithState(organization)}
            target="_blank"
          />
        </Headline>
        <div className={styles.description}>{InstitutionPresenter.degree(institution)}</div>
        <div className={styles.dates}>
          {fromYear && `From: ${fromYear}`}
          {toYear && ` To: ${toYear}`}
        </div>
        {editable && (
          <div className={styles.panel}>
            <div className={styles.action}>
              <EditIcon color="light-1" onClick={this.handleEdit} />
            </div>
            <div className={styles.action}>
              <CloseIcon color="light-1" onClick={this.handleDestroy} />
            </div>
            <div className={styles.action}>
              <StyleGuideModal modalContent={StyleGuides.EducationHistoryStyleGuide}>
                <CircleInformationIcon color="light-1" />
              </StyleGuideModal>
            </div>
          </div>
        )}
        {edit && (
          <InstitutionForm
            title="Edit Education Item"
            institution={institution}
            saving={saving}
            onCancel={this.handleCancelEdit}
            onSubmit={this.handleSubmit}
          />
        )}
      </Card>
    );
  }
}

Institution.propTypes = {
  editable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  institution: InstitutionPresenter.shape(),
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
