import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import Button from 'components/Button';
import Modal, { Header, Actions, Content } from 'containers/UserPanel/components/Modal';
import RawHtml from 'components/RawHtml';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import styles from './CloneFormModal.module.css';

import ProjectPresenter from 'presenters/ProjectPresenter';

const DEFAULT_BASE_ERROR_MESSAGE = 'One or more fields have an error. Please check and try again.';

class CloneFormModal extends Component {
  constructor(...args) {
    super(...args);

    const { project } = this.props;
    this.state = { errors: null, form: { name: project.name } };
  }

  handleErrors = ({ errors }) => this.setState({ ...this.state, errors });

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    return onSubmit(form).catch(this.handleErrors);
  };

  handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) => {
      this.setState({ ...this.state, form: { ...this.state.form, [fieldName]: value } });
    };

  renderError() {
    const { errors } = this.state;

    if (isBlank(errors)) {
      return null;
    }

    const errorMessage = errors.base || DEFAULT_BASE_ERROR_MESSAGE;

    return <RawHtml component="span" innerHTML={errorMessage} />;
  }

  render() {
    const { isSaving, onClose } = this.props;
    const { form, errors } = this.state;

    return (
      <Modal onClose={onClose}>
        <Header
          title="Clone Project"
          description="Please select the new name for clone of the project"
          error={this.renderError()}
        />
        <Content>
          <div className={styles.fieldBox}>
            <div className={styles.fieldTitle}>Project name:</div>
            <VerticalFormFieldWithBottomErrors errors={errors && errors.name} htmlFor="name">
              <TextInput value={form.name} onChange={this.handleFieldChange('name')} id="name" />
            </VerticalFormFieldWithBottomErrors>
          </div>
        </Content>
        <Actions>
          <Button disabled={isSaving} primary onClick={this.handleSubmit} label="Clone" />
        </Actions>
      </Modal>
    );
  }
}

CloneFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  project: ProjectPresenter.shape().isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default CloneFormModal;
