import React from 'react';
import PropTypes from 'prop-types';
import { positions, Provider as AlertProvider } from 'react-alert';

import Alert from './components/Alert';

const CONTAINER_STYLE = { alignItems: 'normal', zIndex: 100, right: 0, width: 'auto' };

const Alerts = (props) => {
  const { children } = props;

  return (
    <AlertProvider
      offset="0px"
      containerStyle={CONTAINER_STYLE}
      position={positions.TOP_LEFT}
      template={Alert}
      timeout={5000}
    >
      {children}
    </AlertProvider>
  );
};

Alerts.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Alerts;
