import { pick } from 'ramda';

export default {
  defaultAttributes(attributes) {
    return { name: '', ...attributes };
  },

  attributesToSubmit(project) {
    const propsToSend = ['name'];

    return pick(propsToSend, project);
  },
};
