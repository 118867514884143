import React from 'react';
import { isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Icon from 'components/Icon';

import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter from 'presenters/UserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';

import styles from './RequestsKanbanCard.module.css';

const RequestsKanbanCard = (props) => {
  const { card: request } = props;

  const assignee = RequestPresenter.assignee(request);
  const priority = RequestPresenter.priority(request);
  const subject = RequestPresenter.subject(request);
  const isProfileHidden = RequestPresenter.isSubjectProfile(request) && ProfilePresenter.isHidden(subject);

  const renderIcon = () => {
    if (RequestPresenter.isSubjectProfile(request)) {
      return <Icon name="profile" />;
    }

    if (RequestPresenter.isSubjectOrganization(request)) {
      return <Icon name="organization" />;
    }

    return null;
  };

  const isShowFortune500Label =
    RequestPresenter.isSubjectProfile(request) && ProfilePresenter.hasFortune500BoardMemberOrRelevantBoard(subject);
  const isShowFtse100Label =
    RequestPresenter.isSubjectProfile(request) && ProfilePresenter.hasFtse100BoardMember(subject);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.icon}>{renderIcon()}</div>
        <div className={styles.number}>{`# ${RequestPresenter.id(request)}`}</div>
      </div>
      <div className={styles.title}>{RequestPresenter.title(request)}</div>

      <div className={styles.info}>
        {assignee && (
          <div className={styles.infoRow}>
            <div className={styles.infoKey}>Assignee</div>
            <div className={styles.infoVal}>{UserPresenter.fullName(assignee)}</div>
          </div>
        )}
        <div className={styles.infoRow}>
          <div className={styles.infoKey}>Type</div>
          <div className={styles.infoVal}>{RequestPresenter.humanRequestType(request)}</div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoKey}>Request Date</div>
          <div className={styles.infoVal}>{RequestPresenter.formattedCreatedAt(request)}</div>
        </div>
      </div>
      <div className={styles.badges}>
        {isPresent(priority) && (
          <div
            className={styles.badge}
            style={{
              backgroundColor: RequestPresenter.priorityColor(request),
              color: RequestPresenter.priorityTextColor(request),
            }}
          >
            {RequestPresenter.priorityName(request)}
          </div>
        )}
        {isProfileHidden && <div className={cn(styles.badge, styles.profileHiddenBadge)}>Hidden</div>}
        {isShowFortune500Label && <div className={cn(styles.badge, styles.profileF500Badge)}>F500</div>}
        {isShowFtse100Label && <div className={cn(styles.badge, styles.profileF500Badge)}>FTSE 100</div>}
      </div>
    </div>
  );
};

RequestsKanbanCard.propTypes = {
  card: RequestPresenter.shape(),
};

export default RequestsKanbanCard;
