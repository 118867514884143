import { defaultTo, forEach, pipe, toPairs } from 'ramda';
import FetchHelpers from './FetchHelpers';
import JSONFetchHelpers from './JSONFetchHelpers';
import { decamelizeString } from 'utils/keysConverter';

function formDataHeaders() {
  return {
    Accept: 'application/json',
  };
}

export default {
  toFormData(params) {
    const formData = new FormData();
    const appendToFormData = ([name, value], ancestors = []) => {
      const decamelizedName = decamelizeString(name);

      if (Array.isArray(value)) {
        value.forEach((arrayValue) => appendToFormData(['', arrayValue], [...ancestors, decamelizedName]));
      } else if (typeof value === 'object' && !(value instanceof File)) {
        toPairs(value).forEach((keyValuePair) => appendToFormData(keyValuePair, [...ancestors, decamelizedName]));
      } else {
        const [rootName, ...names] = [...ancestors, decamelizedName];
        const fieldName = `${rootName}${names.map((n) => `[${n}]`).join('')}`;

        formData.append(fieldName, value);
      }
    };

    pipe(defaultTo({}), toPairs, forEach(appendToFormData))(params);

    return formData;
  },

  put(url, params) {
    const body = this.toFormData(params);

    return FetchHelpers.put(url, body, formDataHeaders())
      .catch(JSONFetchHelpers.catchNetworkError)
      .then(JSONFetchHelpers.toJSON);
  },

  post(url, params) {
    const body = this.toFormData(params);

    return FetchHelpers.post(url, body, formDataHeaders())
      .catch(JSONFetchHelpers.catchNetworkError)
      .then(JSONFetchHelpers.toJSON);
  },
};
