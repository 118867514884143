import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FullProfileRequestListPage from './FullProfileRequestListPage';
import * as actions from './FullProfileRequestListPageActions';

const mapStateToProps = (state) => ({
  loading: state.FullProfileRequestListPageReducer.loading,
  fullProfileRequests: state.FullProfileRequestListPageReducer.fullProfileRequests,
  nextPage: state.FullProfileRequestListPageReducer.nextPage,
  filters: state.FullProfileRequestListPageReducer.filters,
  totalCount: state.FullProfileRequestListPageReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FullProfileRequestListPage);
