import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Organization Name: </strong>
        This should be the formal name of the organization that it uses on its website, in earnings calls, press
        releases, etc. If it commonly uses an abbreviation, then that should be included. For example, in our database
        we have “IBM Corporation (International Business Machines)” and “GE Company (General Electric Co.)” Use your own
        judgement and do a lot of research to see how they refer to themselves.
      </p>
      <p>
        <strong>Ticker: </strong>
        If they are a public company they have this. If not, leave it blank.
      </p>
      <p>
        <strong>Industry/NAICS Industry Code/Corporate DUNS #: </strong>
        Most organizations will have these pretty standard. Note that colleges and universities are “Education”
        industry.
      </p>
      <p>
        <strong>Fortune Rank: </strong>
        Fortune ranks companies up to 2,000. Research its Fortune Rank if it is a public company—note this changes year
        to year and will need to be updated.
      </p>
    </div>
  );
}
