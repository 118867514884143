import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'grommet';
import Headline from 'components/grommet/Headline';

import styles from './HeadlineWithReviewChangesLink.module.css';

class HeadlineWithReviewChangesLink extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
  };

  render() {
    const { title, isChanged, onReviewChanges, ...rest } = this.props;

    return (
      <Headline className={styles.header} {...rest}>
        <span className={styles.title}>{title}</span>
        {isChanged && (
          <Anchor onClick={onReviewChanges} className={styles.reviewTitle}>
            Review&nbsp;Changes
          </Anchor>
        )}
      </Headline>
    );
  }
}

export default HeadlineWithReviewChangesLink;
