import { decamelize } from 'utils/keysConverter';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import ProfilesListConstants from './ProfilesListConstants';
import AvailableProfilesRepository from 'repositories/CurrentUser/Project/Words/ProfilesRepository';

export function filterProfilesWithContexts(filters) {
  return { type: ProfilesListConstants.FILTER_PROJECT_WORD_PROFILES, filters };
}

export function loadProfilesWithContexts(projectId, { page, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: ProfilesListConstants.LOAD_PROJECT_WORD_PROFILES_START });

    const { search, ...restFilters } = filters;
    const params = decamelize({ ...buildFilterParams(search), ...restFilters, page, per });

    return AvailableProfilesRepository.index(projectId, params).then(({ items, meta }) => {
      dispatch({
        type: ProfilesListConstants.LOAD_PROJECT_WORD_PROFILES_SUCCESS,
        profilesWithContexts: items,
        meta,
        filters,
      });
    });
  };
}
