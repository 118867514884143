import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserList from 'components/CorporateAccountUserList';
import * as actions from './UserListActions';

const mapStateToProps = (state) => ({
  company: state.UserListReducer.company,
  loading: state.UserListReducer.loading,
  nextPage: state.UserListReducer.nextPage,
  filters: state.UserListReducer.filters,
  saving: state.UserListReducer.saving,
  totalCount: state.UserListReducer.totalCount,
  users: state.UserListReducer.users,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
