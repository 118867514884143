import keyMirror from 'keymirror';

const CsvSourceEditPageConstants = keyMirror({
  LOAD_PROJECT_CSV_SOURCE_START: null,
  LOAD_PROJECT_CSV_SOURCE_SUCCESS: null,

  CHANGE_PROJECT_CSV_SOURCE_REMAINING_REQUESTS_COUNT: null,
});

export default CsvSourceEditPageConstants;
