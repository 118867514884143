import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(query = {}) {
    const url = Routes.apiV1AdminLanguagesPath(query);

    return FetchHelpers.get(url);
  },

  create(language) {
    const url = Routes.apiV1AdminLanguagesPath();

    return FetchHelpers.post(url, { language });
  },

  show(id) {
    const url = Routes.apiV1AdminLanguagePath(id);

    return FetchHelpers.get(url);
  },

  update(id, language) {
    const url = Routes.apiV1AdminLanguagePath(id);

    return FetchHelpers.put(url, { language });
  },

  bulkDestroy(query) {
    const url = Routes.bulkDestroyApiV1AdminLanguagesPath(query);

    return FetchHelpers.delete(url);
  },
};
