import { isNil } from 'ramda';

const CLIENT_SCROLLABLE_CONTAINER_CLASS = '.client';

export function setBodyOverflow() {
  document.body.classList.add('overflowHidden');
}
export function removeBodyOverflow() {
  document.body.classList.remove('overflowHidden');
}

export const getClientScrollableContainerClass = () => document.querySelector(CLIENT_SCROLLABLE_CONTAINER_CLASS);

export function goToTop() {
  const element = getClientScrollableContainerClass();

  if (isNil(element)) {
    return;
  }

  element.scrollTop = 0;
}

export function goToElement(element) {
  element.scrollIntoView();
}
