import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Notifications from './Notifications';
import { closeNotification } from './NotificationsActions';

const mapStateToProps = (state) => ({
  notifications: state.NotificationsReducer.items,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ onCloseNotification: closeNotification }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
