import NewProfilesStatisticsConstants from './NewProfilesStatisticsConstants';
import NewProfilesStatisticsRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/NewProfilesStatisticsRepository';

export function loadNewProfilesStatistics() {
  return (dispatch) => {
    dispatch({
      type: NewProfilesStatisticsConstants.LOAD_DATABASE_ANALYTICS_NEW_PROFILES_STATISTICS_START,
    });

    return NewProfilesStatisticsRepository.show()
      .then(({ newProfilesStatistics }) => {
        dispatch({
          type: NewProfilesStatisticsConstants.LOAD_DATABASE_ANALYTICS_NEW_PROFILES_STATISTICS_SUCCESS,
          newProfilesStatistics,
        });

        return newProfilesStatistics;
      })
      .catch((error) => {
        dispatch({
          type: NewProfilesStatisticsConstants.LOAD_DATABASE_ANALYTICS_NEW_PROFILES_STATISTICS_ERROR,
          error,
        });

        throw error;
      });
  };
}
