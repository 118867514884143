import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import User from './UserPage';
import * as actions from './UserPageActions';

const mapStateToProps = (state, props) => ({
  user: state.UserPageReducer.user,
  loading: state.UserPageReducer.loading,
  saving: state.UserPageReducer.saving,
  userId: props.match.params.id,
  isResetPasswordEmailSending: state.UserPageReducer.isResetPasswordEmailSending,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
