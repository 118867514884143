import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TopRelationships from './TopRelationships';
import * as actions from './TopRelationshipsActions';

const mapStateToProps = (state) => ({
  loading: state.TopRelationshipsReducer.loading,
  relationshipsMapping: state.TopRelationshipsReducer.relationshipsMapping,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopRelationships);
