import UserDetailStatisticConstants from './UserDetailStatisticConstants';

const initialState = {
  loading: false,
  statistics: null,
  filters: {},
};

function UserDetailStatisticReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UserDetailStatisticConstants.LOAD_USER_PRODUCTIVITY_START: {
      const { filters } = action;

      return { ...state, filters, loading: true };
    }
    case UserDetailStatisticConstants.LOAD_USER_PRODUCTIVITY_SUCCESS: {
      const { statistics } = action;

      return { ...state, loading: false, statistics };
    }
    default:
      return state;
  }
}

export default UserDetailStatisticReducer;
