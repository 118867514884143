import RequestFormConstants from './RequestFormConstants';

const initialState = {
  request: null,
  saving: false,
  loading: false,
};

function RequestFormReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RequestFormConstants.INITIALIZE_REQUEST_FORM: {
      return { ...initialState, request: action.request };
    }
    case RequestFormConstants.RESET_REQUEST_FORM: {
      return initialState;
    }
    case RequestFormConstants.LOAD_REQUEST_START: {
      return { ...state, loading: true };
    }
    case RequestFormConstants.LOAD_REQUEST_SUCCESS: {
      const { request } = action;

      return { ...state, loading: false, request };
    }
    case RequestFormConstants.LOAD_REQUEST_ERROR: {
      return { ...state, loading: false };
    }
    case RequestFormConstants.UPDATE_REQUEST_START: {
      return { ...state, saving: true };
    }
    case RequestFormConstants.UPDATE_REQUEST_SUCCESS: {
      const { request } = action;

      return { ...state, saving: false, request };
    }
    case RequestFormConstants.UPDATE_REQUEST_ERROR: {
      return { ...state, saving: false };
    }
    case RequestFormConstants.ASSIGN_EDITOR_TO_REQUEST_START: {
      return { ...state, Save: true };
    }
    case RequestFormConstants.ASSIGN_EDITOR_TO_REQUEST_SUCCESS: {
      const { request } = action;

      return { ...state, saving: false, request };
    }
    case RequestFormConstants.ASSIGN_EDITOR_TO_REQUEST_ERROR: {
      return { ...state, saving: false };
    }
    case RequestFormConstants.UPDATE_PROFILE_REQUEST_ERROR: {
      return { ...state, saving: false };
    }
    case RequestFormConstants.CREATE_REQUEST_PROFILE_START: {
      return { ...state, saving: true };
    }
    case RequestFormConstants.CREATE_REQUEST_PROFILE_SUCCESS: {
      const { profile, request } = action;
      const attributes = {
        subject: profile,
        subjectType: 'Profile',
        subjectId: profile.id,
        versionId: profile.versionId,
      };

      return { ...state, saving: false, request: { ...state.request, ...request, ...attributes } };
    }
    case RequestFormConstants.CREATE_REQUEST_PROFILE_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default RequestFormReducer;
