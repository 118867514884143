import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InputField from './components/InputField';

import styles from './EditableTitle.module.css';

class EditableTitle extends Component {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  handleStopPropagation = (e) => e.stopPropagation();

  renderInput() {
    const { isSaving, onCancel, onUpdate, title } = this.props;

    return <InputField isSaving={isSaving} onCancel={onCancel} onUpdate={onUpdate} title={title} />;
  }

  renderTitle() {
    const { title } = this.props;

    return <div className={styles.name}>{title}</div>;
  }

  render() {
    const { isEditing } = this.props;
    const onClick = isEditing ? this.handleStopPropagation : null;

    return (
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
      <div className={styles.root} onClick={onClick}>
        {isEditing ? this.renderInput() : this.renderTitle()}
      </div>
    );
  }
}

export default EditableTitle;
