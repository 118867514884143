import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import TargetItemPresenter from './TargetItemPresenter';
import CsvItemPresenter from './CsvItemPresenter';

import { formattedDate } from 'utils/DateTime';

export const CSV_SOURCE_TYPE = 'Project::CsvSource';
export const TARGETING_SOURCE_TYPE = 'Project::TargetSource';

export const EXECUTIVES_LIST_KIND = 'executives_list';
export const ORGANIZATIONS_LIST_KIND = 'organizations_list';
export const DATABASE_SEARCH_KIND = 'database_search';

export default new Presenter(
  {
    id: PropTypes.number,
    type: PropTypes.string,
    kind: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    profilesCount: PropTypes.number,
    recommendedProfilesCount: PropTypes.number,
    targetItems: PropTypes.arrayOf(TargetItemPresenter.shape()),
    csvItems: PropTypes.arrayOf(CsvItemPresenter.shape()),
    pendingProfilesCount: PropTypes.number,
    notRequestedProfilesCount: PropTypes.number,
    canceledProfilesCount: PropTypes.number,
    notIncludedProfilesCount: PropTypes.number,
  },
  {
    formattedCreatedAt(s) {
      return formattedDate(this.createdAt(s));
    },

    isCSVSource(s) {
      return this.type(s) === CSV_SOURCE_TYPE;
    },

    isTargetSource(s) {
      return this.type(s) === TARGETING_SOURCE_TYPE;
    },

    totalProfilesCount(s) {
      return (
        this.pendingProfilesCount(s) +
        this.notRequestedProfilesCount(s) +
        this.canceledProfilesCount(s) +
        this.notIncludedProfilesCount(s) +
        this.profilesCount(s)
      );
    },

    isExecutivesListKind(s) {
      return this.kind(s) === EXECUTIVES_LIST_KIND;
    },

    isDatabaseSearchKind(s) {
      return this.kind(s) === DATABASE_SEARCH_KIND;
    },

    isOrganizationsListKind(s) {
      return this.kind(s) === ORGANIZATIONS_LIST_KIND;
    },
  },
);
