import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    documentId: PropTypes.string,
    organizationId: PropTypes.number,
    organizationName: PropTypes.string,
    eventDate: PropTypes.string,
    isChecked: PropTypes.bool,
  },
  {
    formattedEventDate(t) {
      return formattedDate(this.eventDate(t), 'utc');
    },
  },
);
