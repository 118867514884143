import u from 'updeep';
import { equals, propEq } from 'ramda';

import CsvItemsTableConstants from './CsvItemsTableConstants';

const initialState = {
  loading: false,
  csvItems: [],
  currentPage: 1,
  totalCount: 0,
  remainingRequestsCount: null,
  filters: { stateIn: [], searchFieldCont: '' },
};

function CsvItemsTableReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CsvItemsTableConstants.LOAD_PROJECT_CSV_SOURCE_CSV_ITEMS_START: {
      const { page: currentPage, filters } = action;

      return { ...state, currentPage, filters, loading: true, csvItems: [] };
    }
    case CsvItemsTableConstants.LOAD_PROJECT_CSV_SOURCE_CSV_ITEMS_SUCCESS: {
      const { csvItems, meta, filters } = action;

      if (meta.currentPage !== state.currentPage || !equals(filters, state.filters)) {
        return state;
      }

      return { ...state, csvItems, loading: false, ...meta };
    }
    case CsvItemsTableConstants.UPDATE_PROJECT_CSV_SOURCE_CSV_ITEM_SUCCESS: {
      const { csvItem } = action;
      const csvItems = u.map(u.if(propEq(csvItem.id, 'id'), csvItem), state.csvItems);

      return { ...state, csvItems };
    }
    default:
      return state;
  }
}

export default CsvItemsTableReducer;
