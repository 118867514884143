import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'grommet';
import Chart from './components/Chart';

import TagPresenter from 'presenters/Project/BPA/TagPresenter';

import styles from './BarChart.module.css';

export default class BarChart extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    profilesCount: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(TagPresenter.shape()).isRequired,
    onTitleClick: PropTypes.func.isRequired,
    onSubtitleClick: PropTypes.func.isRequired,
  };

  handleClickTitle = () => {
    const { title, onTitleClick } = this.props;

    onTitleClick(title);
  };

  handleSubtitleClick = (subtitle) => {
    const { title, onSubtitleClick } = this.props;

    onSubtitleClick(title, subtitle);
  };

  render() {
    const { title, profilesCount, tags } = this.props;

    return (
      <div className={styles.barChart}>
        <div className={styles.info}>
          <div className={styles.name}>
            <Anchor onClick={this.handleClickTitle} label={title} />
          </div>
          <div className={styles.counter}>({profilesCount})</div>
        </div>
        <Chart tags={tags} onClick={this.handleSubtitleClick} />
      </div>
    );
  }
}
