import { defaultTo, pipe } from 'ramda';
import FetchHelpers from './FetchHelpers';
import { camelize, decamelize } from 'utils/keysConverter';
import { parseErrors } from 'utils/errorsParser';
import { REQUEST_ENTITY_TOO_LARGE_ERROR_CODE } from 'utils/ErrorsHelper';
import ApiNetworkError from './ApiNetworkError';

const NOT_JSON_RESPONSE_STATUSES = [204, 202];
const SERVER_ERROR_STATUSES = [401, 403, 404, 414, 500, 502, 503, 504, REQUEST_ENTITY_TOO_LARGE_ERROR_CODE];
const ERROR_RESPONE_STATUSES = [422];

export function jsonHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

export default {
  toJSONParams: pipe(defaultTo({}), decamelize),

  toJSON(response) {
    if (NOT_JSON_RESPONSE_STATUSES.includes(response.status)) {
      return response;
    }

    if (SERVER_ERROR_STATUSES.includes(response.status)) {
      throw response;
    }

    if (ERROR_RESPONE_STATUSES.includes(response.status)) {
      return response.json().then((json) => {
        throw camelize({ ...json, errors: parseErrors(json.errors || {}) });
      });
    }

    return response.json().then(camelize);
  },

  catchNetworkError(error) {
    throw new ApiNetworkError(error);
  },

  post(url, json, options = {}) {
    const body = JSON.stringify(this.toJSONParams(json));

    return FetchHelpers.post(url, body, jsonHeaders(), options).catch(this.catchNetworkError).then(this.toJSON);
  },

  put(url, json) {
    const body = JSON.stringify(this.toJSONParams(json));

    return FetchHelpers.put(url, body, jsonHeaders()).catch(this.catchNetworkError).then(this.toJSON);
  },

  patch(url, json) {
    const body = JSON.stringify(this.toJSONParams(json));

    return FetchHelpers.patch(url, body, jsonHeaders()).catch(this.catchNetworkError).then(this.toJSON);
  },

  get(url) {
    return FetchHelpers.get(url, jsonHeaders()).catch(this.catchNetworkError).then(this.toJSON);
  },

  delete(url, json = {}) {
    const body = JSON.stringify(this.toJSONParams(json));

    return FetchHelpers.delete(url, body, jsonHeaders()).catch(this.catchNetworkError).then(this.toJSON);
  },
};
