import React, { Component } from 'react';
import styles from './ContentWrapper.module.css';
import PropTypes from 'prop-types';
import cn from 'clsx';

class ContentWrapper extends Component {
  render() {
    const { children, className } = this.props;
    const rootStyles = cn([styles.contentWrapper], className);
    return <div className={rootStyles}>{children}</div>;
  }
}

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ContentWrapper;
