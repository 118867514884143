import NewProjectWizardConstants from './NewProjectWizardConstants';
import ProjectsRepository from 'repositories/CurrentUser/ProjectsRepository';

export function initializeWizard(form) {
  return { type: NewProjectWizardConstants.INITIALIZE_NEW_PROJECT_WIZARD, form };
}

export function changeForm(form) {
  return { type: NewProjectWizardConstants.CHANGE_NEW_PROJECT_FORM, form };
}

export function createProject(params) {
  return (dispatch) => {
    dispatch({ type: NewProjectWizardConstants.CREATE_NEW_PROJECT_START });

    return ProjectsRepository.create(params)
      .then(({ project }) => {
        dispatch({ type: NewProjectWizardConstants.CREATE_NEW_PROJECT_SUCCESS, project });

        return project;
      })
      .catch((errors) => {
        dispatch({ type: NewProjectWizardConstants.CREATE_NEW_PROJECT_ERROR });

        throw errors;
      });
  };
}
