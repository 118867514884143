import { adminCompanyDashboardProjectsRestHooks } from 'slices/admin/company/dashboard/projectsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useProjects = () => {
  const { projects, loadProjects, loadMoreProjects } = adminCompanyDashboardProjectsRestHooks.use();

  return {
    projects: projects.items,
    projectsLoadingMoreStatus: useFetchStatus(projects.moreLoadingStatus),
    projectsLoadingStatus: useFetchStatus(projects.loadingStatus),
    projectsMeta: projects.meta,
    loadProjects,
    loadMoreProjects,
  };
};

export default useProjects;
