import { AlertsPageReducers } from './containers/AlertsPage';
import { BackendNotificationsReducers } from './containers/BackendNotifications';
import { CompanyDepartmentUserRegistrationReducers } from './containers/CompanyDepartmentUserRegistration';
import { CompanyUserRegistrationReducers } from './containers/CompanyUserRegistration';
import { CorporateAccountReducers } from './containers/CorporateAccount';
import { DashboardPageReducers } from './containers/DashboardPage';
import { NewPasswordReducers } from './containers/NewPassword';
import { NewProjectWizardReducers } from './containers/NewProjectWizard';
import { ProfileContainerReducers } from './containers/ProfileContainer';
import { ProfileRelationshipMappingAdvertisementReducers } from './containers/ProfileRelationshipMappingAdvertisement';
import { ProfileScopeReducers } from './containers/ProfileScope';
import { ProjectListPageReducers } from './containers/ProjectListPage';
import { ProjectMatchedOrganizationsWizardStepReducers } from './containers/ProjectMatchedOrganizationsWizardStep';
import { ProjectReducers } from './containers/Project';
import { QuickSearchReducers } from './containers/QuickSearch';
import { ResetPasswordReducers } from './containers/ResetPassword';
import { SampleProfilesPageReducers } from './containers/SampleProfilesPage';
import { SavedSearchesContainerReducers } from './containers/SavedSearchesContainer';
import { SubscriptionsContainerReducers } from './containers/SubscriptionsContainer';
import { TargetingSearchFormReducers } from './containers/TargetingSearchForm';
import { ViewSkinnyProfileReducers } from './containers/ViewSkinnyProfile';

export default from './UserPanelContainer';
export const UserPanelReducers = {
  ...AlertsPageReducers,
  ...BackendNotificationsReducers,
  ...CompanyDepartmentUserRegistrationReducers,
  ...CompanyUserRegistrationReducers,
  ...CorporateAccountReducers,
  ...DashboardPageReducers,
  ...NewPasswordReducers,
  ...NewProjectWizardReducers,
  ...ProfileContainerReducers,
  ...ProfileRelationshipMappingAdvertisementReducers,
  ...ProfileScopeReducers,
  ...ProjectListPageReducers,
  ...ProjectMatchedOrganizationsWizardStepReducers,
  ...ProjectReducers,
  ...QuickSearchReducers,
  ...ResetPasswordReducers,
  ...SampleProfilesPageReducers,
  ...SavedSearchesContainerReducers,
  ...SubscriptionsContainerReducers,
  ...TargetingSearchFormReducers,
  ...ViewSkinnyProfileReducers,
};
