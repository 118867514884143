import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Summarize the executive’s personal background, interests, professional quirks, family life, views on leadership
        and awards. Quote them directly whenever possible
      </p>
      <p>
        <strong>Example:</strong>
      </p>
      <ul>
        <li>
          Moore is married to Eric. The couple has two children. [Note: You may name spouses for non-EU profiles but do
          not name children in any profiles unless they are adult children or the executive has spoken of his/her
          children by name publicly]
        </li>
        <li>Moore is based at BofA&#39;s Charlotte headquarters but travels to the Bay Area at least once a month.</li>
        <li>
          In an interview with American Banker in June 2017, she said, “...as a mother everything I do revolves around
          [the kids]. They have baseball three nights a week and Tae kwon do two nights a week...running marathons is
          what I do for me. I ran 2001, 2002, took some time off. Then I ran the Chicago Marathon in 2013. My claim to
          fame is that I broke my foot at mile 17. I felt something snap, thought it was weird, but didn&#39;t twist it.
          By mile 20, I was in a lot of pain, but didn&#39;t want to give up because I thought, ‘What if it is something
          silly? I&#39;ll never forgive myself if I give up.’ I walked/ran the rest of it. Still finished in under five
          hours. I was on crutches for eight weeks, but got back up again. I ran it again in 2014 and 2015. I took 2016
          off, but this is the 40th anniversary of the race and I thought it was just too cool not to run it. This will
          be my sixth marathon.” One of her goals is running the marathon again and taking her sons, she said.
          “They&#39;ve never been. When I&#39;m out running, I envision seeing them at the finish line and it makes me
          cry knowing they are going to be there. I&#39;m making sure I&#39;m spending more time with them,” Moore said.
        </li>
        <li>
          Commenting on guiding principle in her job, Moore said, “I just want to make sure whatever we do today is
          better than what we did the day before. I go home and reflect on it. Was I a good manager today? Were my
          interactions good? You can tell when you have not done things well. I&#39;m also one of those people who
          writes out my goals for the year and by the end of year I&#39;ve usually accomplished them. I like progress.
          I&#39;m very focused and intense that way. I think that is why I like single-person sports. Marathons are me
          against myself. I also golf. That&#39;s me against myself. My friends say it is about having fun, but I&#39;m
          about winning.”
        </li>
      </ul>
      <p>
        <strong>Personal Attributes and Interests Style Guidelines:</strong>
      </p>
      <ul>
        <li>Use last name only when talking about the person</li>
        <li>Format in a bulleted list</li>
        <li>
          Make this section as consecutive as possible. So if you have information about their birthplace and childhood,
          that should come first; college activities next, then marriage and kids, then quotes about leadership style or
          personal interests, and then lastly list their awards, also in consecutive order.
        </li>
        <li>Use “in their own words” whenever possible, from interviews. Cite sources in text and link to them.</li>
        <ul>
          <li>
            e.g., Moore’s go-to resource for blah blah is blah blah, she told the <u>New York Times in April 2018</u>...
          </li>
        </ul>
        <li>
          Avoid anything too timely that will change quickly; the goal is for this section to be relatively evergreen
        </li>
        <ul>
          <li>e.g., In 2011 she said her favorite device was her Blackberry (this seems outdated really fast)</li>
        </ul>
        <li>
          If the person has received a bunch of awards and you want to list them, group these together and use
          sub-bullets:
        </li>
        <ul>
          <li>E.g. Moore has received the following honors and awards over the course of her career:</li>
          <ul>
            <li>CIO of the Year, Forbes (2014)</li>
            <li>Junior Achievement Volunteer of the Year (2015)</li>
            <li>Honorary degree from Acme College (2016)</li>
            <li>Forbes Most Powerful Women List (2017)</li>
          </ul>
        </ul>
        <li>Avoid anything controversial unless it is relevant and well-documented</li>
        <ul>
          <li>
            e.g., we don’t need to know about DUIs, that her daughter was arrested, or that she sold a bunch of stock,
            or that she bought a $4 million house or how much money she makes
          </li>
          <li>
            On the other hand, if she has a big farm and likes to tend cattle and raise horses there, that would be
            relevant; of if her daughter is autistic and she raises money for the cause and speaks about it interviews,
            that would be relevant; or if during the recession she reduced her annual salary to $1 that would be
            relevant
          </li>
          <li>
            Be sure and “tag” hobbies using drop down menu in the “Personal Interests” section in the right-hand column
            of the profile editor; look for hobbies that already exist in the menu and avoid adding new and duplicative
            ones whenever possible. e.g., we have “skiing” so we don’t want you to add “downhill skiing”
          </li>
        </ul>
        <li>Please do not do the following:</li>
        <ul>
          <li>Paste in entire articles--edit down to the most interesting bits/quotes</li>
          <li>
            Include a lot of boring “fluff” and platitudes when you have other better content that can stand alone. E.g.
            “She says you should always believe in yourself and listen to your inner voice.” If you have nothing else on
            the person except a quote like this, you can include it--but if you have other quotes and content about
            them, please do not include it.
          </li>
        </ul>
      </ul>
      <p>
        <strong>EU/UK Exceptions:</strong>
        <p>
          For EU/UK profiles, avoid personal information that could violate the EU&#39;s GDPR law. This includes
          personal information such as birthdate, birthplace, place of residence, ethnicity, spousal/parental status,
          sexual orientation, political ideology, and charitable giving (outside of professional role). Stick to ‘in
          their own words’ content from interviews on non-personal topics or general hobbies. For example, it would be
          OK to quote them on their personal leadership philosophy. When in doubt, leave this section blank and link to
          any articles that quote them in the “News and Interviews” section, which is at the bottom of the profile
          editor.
        </p>
      </p>
      <p>
        <strong>Sources and Search Tips for This Section</strong>
      </p>
      <ul>
        <li>Search for LinkedIn profile and corporate biographies on corporate web pages</li>
        <li>Search for “Christine McCarthy” Disney 2020 and “Chris McCarthy” Disney 2020</li>
      </ul>
    </div>
  );
}
