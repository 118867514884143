import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import RCTooltip from 'rc-tooltip';
import { Anchor, Box } from 'grommet';
import { Edit as EditIcon, FormCheckmark as FormCheckMarkIcon, FormClose as FormCloseIcon } from 'grommet-icons';

import { isPresent, isBlank, reactSelectDebounce } from 'utils/HelperMethods';

import AsyncSelect from 'components/AsyncSelect';
import Button from 'components/Button';
import Modal from 'components/Modal';
import NewProfileForm from 'containers/AdminPanel/components/NewProfileForm';

import Routes from 'routes';

import RequestPresenter from 'presenters/RequestPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';
import RequestPolicy from 'policies/RequestPolicy';

import styles from './SubjectAttachmentForm.module.css';

class SubjectAttachmentForm extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    onSearchSubject: PropTypes.func.isRequired,
    onSubjectCreate: PropTypes.func.isRequired,
    onSubjectUpdate: PropTypes.func.isRequired,
    request: RequestPresenter.shape().isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { request } = this.props;
    const subject = RequestPresenter.subject(request);

    this.state = {
      subject,
      isSubjectFormOpened: false,
      isChangeSubjectFormOpened: isBlank(subject),
    };
  }

  getSubjectOptionValue = ({ id }) => id;

  getSubjectOptionLabel = (subject) =>
    RequestPresenter.isSubjectProfile(this.props.request)
      ? ProfilePresenter.fullName(subject)
      : OrganizationPresenter.name(subject);

  handleSubjectFormOpen = () => this.setState({ isSubjectFormOpened: true });

  handleSubjectFormClose = () => this.setState({ isSubjectFormOpened: false });

  handleSubjectCreate = (subject) => {
    const { onSubjectCreate } = this.props;

    return onSubjectCreate(subject).then(this.handleSubjectFormClose).then(this.handleCloseChangeSubjectForm);
  };

  handleOpenChangeSubjectForm = () => {
    const { request } = this.props;
    const subject = RequestPresenter.subject(request);

    this.setState({ isChangeSubjectFormOpened: true, subject });
  };

  handleCloseChangeSubjectForm = () => this.setState({ isChangeSubjectFormOpened: false, subject: null });

  handleSubjectChange = (subject) => this.setState({ subject });

  handleSearchSubject = (value) => {
    const { onSearchSubject } = this.props;

    return onSearchSubject(value, { includes: 'draft.request.assignee' });
  };

  handleSearchSubjectDebounced = reactSelectDebounce(this.handleSearchSubject);

  handleSubjectUpdate = () => {
    const { onSubjectUpdate } = this.props;
    const { subject } = this.state;

    if (isPresent(subject)) {
      return onSubjectUpdate(subject.id).then(this.handleCloseChangeSubjectForm);
    }

    return onSubjectUpdate(null);
  };

  isSubjectOptionDisabled = (option) => isPresent(option.request);

  subjectEditPath(request) {
    const subjectId = RequestPresenter.subjectId(request);

    if (RequestPresenter.isSubjectProfile(request)) {
      return Routes.editAdminProfilePath(subjectId);
    }

    if (RequestPresenter.isSubjectOrganization(request)) {
      return Routes.editAdminOrganizationPath(subjectId);
    }

    return null;
  }

  renderSubjectForm() {
    const { request, saving } = this.props;
    const header = `New ${RequestPresenter.subjectType(request)}`;

    return (
      <Modal onClose={this.handleSubjectFormClose} size="medium" header={header} overflow>
        {RequestPresenter.isSubjectProfile(request) && (
          <NewProfileForm
            onSubmit={this.handleSubjectCreate}
            profile={RequestPresenter.subjectAttributes(request)}
            saving={saving}
          />
        )}
      </Modal>
    );
  }

  renderSubjectLink() {
    const { request } = this.props;
    const subject = RequestPresenter.subject(request);
    const label = `#${subject.id} ${RequestPresenter.title(request)}`;

    return (
      <>
        <div className={styles.label}>Associated Profile</div>
        <Box direction="row" margin={{ vertical: 'small' }}>
          <Anchor label={label} href={this.subjectEditPath(request)} target="_blank" />
          <Anchor
            onClick={this.handleOpenChangeSubjectForm}
            className={styles.editSubjectIcon}
            icon={<EditIcon color="dark-1" size="small" />}
          />
        </Box>
      </>
    );
  }

  renderOptionTooltip = (request) => {
    if (isBlank(request)) {
      return null;
    }

    const requestor = RequestPresenter.requestor(request);
    const assignee = RequestPresenter.assignee(request);

    return (
      <>
        <Box margin={{ vertical: 'small' }} className={styles.item}>
          <div className={styles.label}>Type</div>
          <Box> {RequestPresenter.humanRequestType(request)} </Box>
        </Box>
        {assignee && (
          <Box margin={{ vertical: 'small' }} className={styles.item}>
            <div className={styles.label}>Assignee</div>
            <Box>{UserPresenter.fullName(assignee)}</Box>
          </Box>
        )}
        <Box margin={{ vertical: 'small' }} className={styles.item}>
          <div className={styles.label}>Status</div>
          <Box>{RequestPresenter.stateName(request)} </Box>
        </Box>
        {requestor && (
          <Box margin={{ vertical: 'small' }} className={styles.item}>
            <div className={styles.label}>Requestor</div>
            <Box>{UserPresenter.fullName(requestor)}</Box>
          </Box>
        )}
        <Box margin={{ vertical: 'small' }} className={styles.item}>
          <div className={styles.label}>Request Date</div>
          <Box>{RequestPresenter.formattedCreatedAt(request)}</Box>
        </Box>
        {RequestPresenter.formattedDueDate(request) && (
          <Box margin={{ vertical: 'small' }}>
            <div className={styles.label}>Due Date</div>
            <Box>{RequestPresenter.formattedDueDate(request)}</Box>
          </Box>
        )}
      </>
    );
  };

  renderOption = (args) => {
    const { label, data, innerProps, isDisabled } = args;
    const { request } = data;
    const assignee = isPresent(request) ? RequestPresenter.assignee(request) : null;

    const className = cx([styles.subjectOption], { [styles.subjectOptionDisabled]: isDisabled });

    return (
      <div {...innerProps} className={className}>
        <div> {label} </div>
        {isPresent(request) && (
          <div className={styles.request}>
            Request
            <RCTooltip placement="bottom" trigger={['hover']} overlay={this.renderOptionTooltip(request)}>
              <Anchor className={styles.requestLink} label={`#${RequestPresenter.id(request)}`} />
            </RCTooltip>
            {isPresent(assignee) && `assigned to ${UserPresenter.fullName(assignee)}`}
          </div>
        )}
      </div>
    );
  };

  renderAttachmetSelect() {
    const { currentUser, request, saving } = this.props;
    const { subject } = this.state;
    const isDisabled = !RequestPolicy.canEdit(currentUser, request) || saving;

    return (
      <Box direction="column" className={styles.noProfile}>
        {isBlank(RequestPresenter.subject(request)) && (
          <Box className={styles.label} margin={{ bottom: 'small' }}>
            {`No ${RequestPresenter.subjectType(request).toLowerCase()} associated`}
          </Box>
        )}
        <Box direction="row" align="center">
          <Box margin={{ right: 'small' }}>
            <Button
              label="Create New"
              disabled={isDisabled || !RequestPresenter.isSubjectProfile(request)}
              onClick={this.handleSubjectFormOpen}
              primary
            />
          </Box>
          <Box className={styles.search}>
            <AsyncSelect
              components={{ Option: this.renderOption }}
              placeholder="Search"
              loadOptions={this.handleSearchSubjectDebounced}
              defaultOptions
              isClearable
              value={subject}
              isDisabled={isDisabled}
              onChange={this.handleSubjectChange}
              getOptionValue={this.getSubjectOptionValue}
              getOptionLabel={this.getSubjectOptionLabel}
              isOptionDisabled={this.isSubjectOptionDisabled}
              noBottom
            />
          </Box>
          <Anchor
            className={styles.action}
            label="Save"
            icon={<FormCheckMarkIcon color="dark-1" />}
            disabled={isDisabled}
            onClick={this.handleSubjectUpdate}
          />
          {RequestPresenter.subject(request) && (
            <Anchor
              className={styles.action}
              label="Cancel"
              icon={<FormCloseIcon color="dark-1" />}
              disabled={isDisabled}
              onClick={this.handleCloseChangeSubjectForm}
            />
          )}
        </Box>
      </Box>
    );
  }

  render() {
    const { isSubjectFormOpened, isChangeSubjectFormOpened } = this.state;

    if (isSubjectFormOpened) {
      return this.renderSubjectForm();
    }

    if (!isChangeSubjectFormOpened) {
      return this.renderSubjectLink();
    }

    return this.renderAttachmetSelect();
  }
}

export default SubjectAttachmentForm;
