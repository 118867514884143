import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box } from 'grommet';

import Spinner from 'components/Spinner';
import SampleProfile from './components/SampleProfile';

import styles from './SampleProfilesTab.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';

import Routes from 'routes';

class SampleProfilesTab extends Component {
  componentDidMount() {
    const { loadSampleProfiles } = this.props;

    loadSampleProfiles({ sortFields: ['position ASC'] });
  }

  handleSubmit = (sampleProfile, profile) => {
    const { updateSampleProfile } = this.props;
    const profileId = profile && profile.id;

    return updateSampleProfile(sampleProfile.id, { ...sampleProfile, profileId });
  };

  isSaving(sampleProfile) {
    const { savingSampleProfileIds } = this.props;

    return savingSampleProfileIds.includes(sampleProfile.id);
  }

  render() {
    const { loading, sampleProfiles, searchProfile } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return (
      <Box margin="none" full="horizontal">
        <Anchor animateIcon={false} href={Routes.sampleProfilesPath()} target="_blank" className={styles.link}>
          View on Site
        </Anchor>

        <Box className={styles.sampleProfilesBlock} direction="row">
          {sampleProfiles.map((sampleProfile, index) => (
            <SampleProfile
              key={index}
              sampleProfile={sampleProfile}
              onProfileSearch={searchProfile}
              onSubmit={this.handleSubmit}
              saving={this.isSaving(sampleProfile)}
            />
          ))}
        </Box>
      </Box>
    );
  }
}

SampleProfilesTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadSampleProfiles: PropTypes.func.isRequired,
  savingSampleProfileIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sampleProfiles: PropTypes.arrayOf(ProfilePresenter.shape()),
  searchProfile: PropTypes.func.isRequired,
  updateSampleProfile: PropTypes.func.isRequired,
};

export default SampleProfilesTab;
