import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/FormDataFetchHelpers';

export default {
  create(matchOrganizations) {
    const url = Routes.apiV1CurrentUserMatchOrganizationsPath();

    return FetchHelpers.post(url, { matchOrganizations });
  },
};
