import React from 'react';
import Routes from 'routes';
import { goToTop } from 'utils/DOMHelpers';

import { useCurrentUser } from 'hooks';
import { useClientRequests } from 'hooks/client';

import ImportRequestsForm from 'forms/customer/ImportRequestsForm';

import Form from 'components/v2/Form';
import Wizard, { WizardStep } from 'components/v2/Wizard';

import UploadCSVFileWizardStep from './components/UploadCSVFileWizardStep';
import MatchExecutivesWizardStep from './components/MatchExecutivesWizardStep';
import ReviewRequestsWizardStep from './components/ReviewRequestsWizardStep';
import SuccessMessageWizardStep from './components/SuccessMessageWizardStep';

import styles from './ImportRequestsWizardRoutes.module.css';

const ImportRequestsWizardRoutes = () => {
  const { currentUser } = useCurrentUser();
  const { bulkCreateRequests } = useClientRequests();

  const initialValues = ImportRequestsForm.defaultAttributes({ recipientEmails: [currentUser.email] });

  const handleRequestsCreate = async (attributes) => {
    const attributesToSubmit = ImportRequestsForm.attributesToSubmit(attributes);

    try {
      await bulkCreateRequests(attributesToSubmit);
    } catch (error) {
      throw ImportRequestsForm.transformError(attributes, error);
    }
  };

  return (
    <div className={styles.wizard}>
      <Form initialValues={initialValues} onSubmit={handleRequestsCreate}>
        <Wizard onStepChange={goToTop}>
          <WizardStep path={Routes.importRequestsWizardStepPath('upload-csv')}>
            <UploadCSVFileWizardStep />
          </WizardStep>
          <WizardStep path={Routes.importRequestsWizardStepPath('match-executives')}>
            <MatchExecutivesWizardStep />
          </WizardStep>
          <WizardStep path={Routes.importRequestsWizardStepPath('review-requests')}>
            <ReviewRequestsWizardStep />
          </WizardStep>
          <WizardStep path={Routes.importRequestsWizardStepPath('submit-requests')}>
            <SuccessMessageWizardStep />
          </WizardStep>
        </Wizard>
      </Form>
    </div>
  );
};

export default ImportRequestsWizardRoutes;
