import TREventsBlockConstants from './TREventsBlockConstants';

const initialState = {
  loading: false,
  events: [],
  nextPage: 1,
  errorStatus: null,
};

function TREventsBlockReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TREventsBlockConstants.INITIALIZE_THOMSON_REUTERS_EVENTS: {
      return initialState;
    }
    case TREventsBlockConstants.LOAD_THOMSON_REUTERS_EVENTS_START: {
      return { ...state, loading: true, errorStatus: null };
    }
    case TREventsBlockConstants.LOAD_THOMSON_REUTERS_EVENTS_SUCCESS: {
      const { nextPage } = action;
      const events = [...state.events, ...action.events];

      return { ...state, events, nextPage, loading: false };
    }
    case TREventsBlockConstants.LOAD_THOMSON_REUTERS_EVENTS_ERROR: {
      const { error } = action;
      const errorStatus = error.status || 500;

      return { ...state, loading: false, errorStatus };
    }
    default:
      return state;
  }
}

export default TREventsBlockReducer;
