import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfilesUpdatesStatistics from './ProfilesUpdatesStatistics';
import * as actions from './ProfilesUpdatesStatisticsActions';

const mapStateToProps = (state) => ({
  loading: state.ProfilesUpdatesStatisticsReducer.loading,
  profilesUpdatesStatistics: state.ProfilesUpdatesStatisticsReducer.profilesUpdatesStatistics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesUpdatesStatistics);
