import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Close as CloseIcon } from 'grommet-icons';

import AnchorLink from 'components/grommet/AnchorLink';
import Button from 'components/Button';

import NotificationPresenter from 'presenters/NotificationPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import UserPresenter from 'presenters/UserPresenter';

import Routes from 'routes';

import styles from './ProjectSharedNotification.module.css';

class ProjectSharedNotification extends Component {
  static propTypes = {
    notification: NotificationPresenter.shape().isRequired,
    onClose: PropTypes.func.isRequired,
  };

  handleClose = () => {
    const { onClose, notification } = this.props;

    onClose(notification);
  };

  render() {
    const { notification } = this.props;
    const sender = NotificationPresenter.sender(notification);
    const project = NotificationPresenter.subject(notification);

    return (
      <div className={styles.root}>
        <div className={styles.title}>
          {NotificationPresenter.kindHumanName(notification)}
          <Button className={styles.closeButton} onClick={this.handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <div>
          <span className={styles.userName}>{UserPresenter.fullName(sender)}</span> shared the project{' '}
          <AnchorLink className={styles.projectLink} to={Routes.projectPath(project.id)} onClick={this.handleClose}>
            {ProjectPresenter.name(project)}
          </AnchorLink>{' '}
          with you
        </div>
      </div>
    );
  }
}

export default ProjectSharedNotification;
