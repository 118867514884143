import ProfileRelationshipsListConstants from './ProfileRelationshipsListConstants';
import RelationshipsRepository from 'repositories/CurrentUser/Project/AvailableProfiles/RelationshipsRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function initializeProfileRelationships(profileId, relationshipsFilters, profilesFilters) {
  return {
    type: ProfileRelationshipsListConstants.INITIALIZE_PROJECT_PROFILE_RELATIONSHIPS,
    profileId,
    relationshipsFilters,
    profilesFilters,
  };
}

export function loadProfileRelationships(projectId, profileId, relationshipsFilters, profilesFilters, { page, per }) {
  return (dispatch) => {
    dispatch({ type: ProfileRelationshipsListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_START });
    const params = {
      page,
      per,
      ...buildFilterParams(relationshipsFilters),
      ...buildFilterParams(profilesFilters, 'profilesQ'),
    };

    return RelationshipsRepository.index(projectId, profileId, params).then(({ items, meta }) => {
      dispatch({
        type: ProfileRelationshipsListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_SUCCESS,
        relationships: items,
        meta,
        profileId,
        relationshipsFilters,
        profilesFilters,
      });
    });
  };
}
