import React from 'react';
import PropTypes from 'prop-types';

import CompanyDepartmentForm from 'forms/company/DepartmentForm';

import AdminModal, { Header } from 'components/v2/AdminModal';
import Form from 'components/v2/Form';

import NewForm from './components/NewForm';

const NewDepartmentFormModal = (props) => {
  const { isOpen, onClose, onDepartmentCreate } = props;

  const handleSubmit = (attributes) => onDepartmentCreate(CompanyDepartmentForm.attributesToSubmit(attributes));

  return (
    <AdminModal isOpen={isOpen} onClose={onClose}>
      <Header onClose={onClose}>New Department</Header>
      <Form component={NewForm} initialValues={CompanyDepartmentForm.defaultAttributes({})} onSubmit={handleSubmit} />
    </AdminModal>
  );
};

NewDepartmentFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDepartmentCreate: PropTypes.func.isRequired,
};

export default NewDepartmentFormModal;
