import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProjectMatchedOrganizationsWizardStep from './ProjectMatchedOrganizationsWizardStep';
import * as actions from './ProjectMatchedOrganizationsWizardStepActions';

const mapStateToProps = (state) => ({
  isDetailsVisible: state.ProjectMatchedOrganizationsWizardStepReducer.isDetailsVisible,
  loading: state.ProjectMatchedOrganizationsWizardStepReducer.loading,
  matchOrganizationForm: state.ProjectMatchedOrganizationsWizardStepReducer.matchOrganizationForm,
  organizations: state.ProjectMatchedOrganizationsWizardStepReducer.organizations,
  matchedCount: state.ProjectMatchedOrganizationsWizardStepReducer.matchedCount,
  notMatchedCount: state.ProjectMatchedOrganizationsWizardStepReducer.notMatchedCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMatchedOrganizationsWizardStep);
