import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';
import { Checkbox, Field, Input } from 'components/v2/Form';

import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import styles from './SalesforceSettingsFields.module.css';

const SalesforceSettingsFields = (props) => {
  const { companyDepartment, errors, onFieldChange, onSalesforceTokenReset } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Salesforce/API V1 Settings</Typography>
      <Field title="API key" error={errors.salesforceToken} variant="outlined">
        <Input value={companyDepartment.salesforceToken} />
      </Field>
      <Button className={styles.resetButton} onClick={onSalesforceTokenReset} variant="link">
        Reset
      </Button>
      <Typography color="silver" variant="body2">
        Check this box to allow auto account creation from salesforce
      </Typography>
      <Field title="Allow SF auto reg" error={errors.salesforceAutoRegistration} variant="outlined">
        <Checkbox
          checked={companyDepartment.salesforceAutoRegistration}
          onChange={handleChange('salesforceAutoRegistration')}
        />
      </Field>
    </div>
  );
};

SalesforceSettingsFields.propTypes = {
  companyDepartment: CompanyDepartmentPresenter.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSalesforceTokenReset: PropTypes.func.isRequired,
};

export default SalesforceSettingsFields;
