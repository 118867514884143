import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { Close as CloseIcon } from 'grommet-icons';

import { humanStateName, stateColor } from 'presenters/Project/CsvItemPresenter';

import styles from './SelectedState.module.css';

export default class SelectedState extends Component {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    state: PropTypes.string.isRequired,
  };

  handleRemove = () => {
    const { onRemove, state } = this.props;

    onRemove(state);
  };

  render() {
    const { state } = this.props;

    return (
      <div className={styles.item} style={{ borderColor: stateColor(state) }}>
        <span className={styles.name}>{humanStateName(state)}</span>
        <Button
          onClick={this.handleRemove}
          icon={<CloseIcon className={styles.closeIcon} />}
          plain
          className={styles.close}
        />
      </div>
    );
  }
}
