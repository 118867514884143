import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import papaparse from 'papaparse';
import { isExceedMaxSize, isMatchFileExt } from 'utils/fileUtils';
import { isPresent, isBlank } from 'utils/HelperMethods';
import { readLine } from 'utils/FileReader';

import { useAdminCompanyUsersMatches } from 'hooks/admin';

import Link from 'components/v2/Link';

import CsvDropzone from './components/CsvDropzone';
import CsvHeader from './components/CsvHeader';

import styles from './UploadUsersCsvWizardStep.module.css';

import example from './images/example.png';

const CSV_HEADERS = [
  { title: 'First Name', csvTitle: 'first_name', required: true },
  { title: 'Last Name', csvTitle: 'last_name', required: true },
  { title: 'Email', csvTitle: 'email', required: true },
];

const UploadUsersCsvWizardStep = (props) => {
  const { onNextStep, onPreviousStep, WizardTitle, WizardNavigation, onFileChange, companyId, file } = props;
  const [errors, setErrors] = useState(null);
  const [isValidating, setIsValidating] = useState(null);
  const { validateUsersMatchAttributes } = useAdminCompanyUsersMatches();

  const validateFile = (newFile) => {
    if (isExceedMaxSize(newFile)) {
      return setErrors({ fileProps: 'File size should be less than 4 megabytes' });
    }

    if (!isMatchFileExt(newFile, 'csv')) {
      return setErrors({ fileProps: 'Wrong file type. Please upload a .CSV' });
    }

    setIsValidating(true);

    return readLine(newFile)
      .then(papaparse.parse)
      .then(({ meta: { delimiter } }) => {
        onFileChange(newFile, delimiter);
        return validateUsersMatchAttributes(companyId, { file: newFile, delimiter });
      })
      .then(() => {
        setIsValidating(false);
        setErrors(null);
      })
      .catch((err) => {
        setErrors(err);
        setIsValidating(false);
      });
  };

  useEffect(() => {
    if (isPresent(file)) {
      validateFile(file);
    }
  }, []);

  const handleFileChange = (newFile) => {
    validateFile(newFile);
    onFileChange(newFile);
  };

  return (
    <>
      <WizardTitle subtitle="Upload your list of users." />
      <div className={styles.fileUploadContainer}>
        <div className={styles.mainSide}>
          <div className={styles.title}>CSV file format requirements:</div>
          <div className={styles.description}>
            Column headers are REQUIRED in Row 1 of the CSV file and MUST <br /> be in the following order:
          </div>
          <div className={styles.list}>
            {CSV_HEADERS.map((header, index) => (
              <CsvHeader key={index} {...header} errors={errors} file={file} isValidating={isValidating} />
            ))}
          </div>

          <div className={styles.info}>
            Please download this{' '}
            <Link href="/static/csv/users-list-example.csv" variant="underscored" color="primary">
              CSV template
            </Link>{' '}
            to get started.
            <br />
            Need help?{' '}
            <Link href="mailto:customerservice@boardroominsiders.com" variant="underscored" color="primary">
              customerservice@boardroominsiders.com
            </Link>
          </div>
          <img src={example} className={styles.example} alt="example" />
        </div>
        <div className={styles.chooseFileSide}>
          <div className={styles.dropContainer}>
            <CsvDropzone onLoad={handleFileChange} file={file} errors={errors} isValidating={isValidating} />
          </div>
        </div>
      </div>
      <WizardNavigation
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        isNextButtonDisabled={isBlank(file) || isPresent(errors) || isValidating}
      />
    </>
  );
};

UploadUsersCsvWizardStep.propTypes = {
  companyId: PropTypes.string.isRequired,
  file: PropTypes.shape(),
  onFileChange: PropTypes.func.isRequired,
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  WizardTitle: PropTypes.func.isRequired,
  WizardNavigation: PropTypes.func.isRequired,
};

export default UploadUsersCsvWizardStep;
