import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Routes from 'routes/App';
import { Box } from 'grommet';
import Spinner from 'components/Spinner';

import LanguagePresenter from 'presenters/LanguagePresenter';
import EditForm from './components/EditForm';
import PageHeader from 'components/PageHeader';

class LanguagePage extends Component {
  static propTypes = {
    history: PropTypes.shape(),
    languageId: PropTypes.string.isRequired,
    language: LanguagePresenter.shape(),
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    loadLanguage: PropTypes.func.isRequired,
    updateLanguage: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadLanguage, languageId } = this.props;

    loadLanguage({ id: languageId });
  }

  handleSubmit = (language) => {
    const { updateLanguage, history, languageId } = this.props;

    return updateLanguage(languageId, language).then(() => {
      history.push(Routes.languagesPath());
    });
  };

  render() {
    const { language, loading, saving } = this.props;

    if (loading || !language) {
      return <Spinner />;
    }

    return (
      <Box margin="none">
        <PageHeader title="Edit Language" backTo={Routes.adminLanguagesPath()} />
        <Box width="medium">
          <EditForm language={language} saving={saving} onSubmit={this.handleSubmit} />
        </Box>
      </Box>
    );
  }
}

export default LanguagePage;
