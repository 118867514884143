import keyMirror from 'keymirror';

const RequestsKanbanBoardConstants = keyMirror({
  INITIALIZE_KANBAN_BOARD: null,

  UPDATE_KANBAN_REQUEST_START: null,
  UPDATE_KANBAN_REQUEST_SUCCESS: null,
  UPDATE_KANBAN_REQUEST_ERROR: null,

  UPDATE_KANBAN_REQUEST_DATA: null,

  FILTER_REQUESTS_START: null,
  FILTER_REQUESTS_SUCCESS: null,
  FILTER_REQUESTS_ERROR: null,

  LOAD_REQUESTS_START: null,
  LOAD_REQUESTS_SUCCESS: null,
  LOAD_REQUESTS_ERROR: null,

  SET_DROPPABLE_COLUMNS: null,

  MOVE_REQUEST: null,
});

export default RequestsKanbanBoardConstants;
