import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(projectId, sourceId, params = {}) {
    const url = Routes.apiV1CurrentUserProjectCsvSourceCsvItemsPath(projectId, sourceId, params);

    return JsonFetchHelpers.get(url);
  },

  update(projectId, sourceId, id, csvItem) {
    const url = Routes.apiV1CurrentUserProjectCsvSourceCsvItemPath(projectId, sourceId, id);

    return JsonFetchHelpers.patch(url, { csvItem });
  },
};
