import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import CompanyDepartmentLimitedAccessPresenter from 'presenters/Company/Department/LimitedAccessPresenter';
import CompanyDepartmentApiV2AccessSettingsPresenter from 'presenters/Company/Department/ApiV2AccessSettingsPresenter';

export const ACCOUNT_TYPES = [
  {
    key: 'custom_subscriber', // DEPRECATED please use value instead
    title: 'Customer Account', // DEPRECATED please use label instead
    value: 'custom_subscriber',
    label: 'Customer Account',
  },
  {
    key: 'pilot_account', // DEPRECATED please use value instead
    title: 'Pilot Account', // DEPRECATED please use label instead
    value: 'pilot_account',
    label: 'Pilot Account',
  },
  {
    key: 'download_limited', // DEPRECATED please use value instead
    title: 'Trial Account', // DEPRECATED please use label instead
    value: 'download_limited',
    label: 'Trial Account',
  },
  {
    key: 'demo_account', // DEPRECATED please use value instead
    title: 'Demo Account', // DEPRECATED please use label instead
    value: 'demo_account',
    label: 'Demo Account',
  },
  {
    key: 'individual_account', // DEPRECATED please use value instead
    title: 'Individual Account', // DEPRECATED please use label instead
    value: 'individual_account',
    label: 'Individual Account',
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    accountType: PropTypes.string,
    seatsNumber: PropTypes.number,
    activeSeatsNumber: PropTypes.number,
    remainingSeatsNumber: PropTypes.number,
    requestsNumber: PropTypes.number,
    purchasedRequestsNumber: PropTypes.number,
    profileViewsNumber: PropTypes.number,
    purchasedProfileViewsNumber: PropTypes.number,
    profileDownloadsNumber: PropTypes.number,
    purchasedProfileDownloadsNumber: PropTypes.number,
    projectsAvailable: PropTypes.bool,
    projectsExportEnabled: PropTypes.bool,
    projectsNumber: PropTypes.number,
    purchasedProjectsNumber: PropTypes.number,
    registrationFormHeader: PropTypes.string,
    registrationFormVideoCode: PropTypes.string,
    registrationFormWysiwyg: PropTypes.string,
    salesforceToken: PropTypes.string,
    salesforceAutoRegistration: PropTypes.bool,
    databaseOnly: PropTypes.bool,
    isDeleted: PropTypes.bool,
    limitedAccess: CompanyDepartmentLimitedAccessPresenter.shape(),
    apiV2AccessSettings: CompanyDepartmentApiV2AccessSettingsPresenter.shape(),
  },
  {
    humanAccoutType(c) {
      const accountType = this.accountType(c);

      return ACCOUNT_TYPES.find((r) => r.value === accountType)?.title;
    },
    isDownloadLimitedAccountType(c) {
      return this.accountType(c) === 'download_limited';
    },
    isCustomerAccountAccountType(c) {
      return this.accountType(c) === 'custom_subscriber';
    },
    isPilotAccountAccountType(c) {
      return this.accountType(c) === 'pilot_account';
    },
    isDemoAccountAccountType(c) {
      return this.accountType(c) === 'demo_account';
    },
    isIndividualAccountAccountType(c) {
      return this.accountType(c) === 'individual_account';
    },
    nameWithState(department) {
      return this.isDeleted(department) ? `${this.name(department)} (deleted)` : this.name(department);
    },
  },
);
