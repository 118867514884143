import { OrganizationSubscriptionsListContainerReducers } from './containers/OrganizationSubscriptionsListContainer';
import { ProfileSubscriptionsListContaierReducers } from './containers/ProfileSubscriptionsListContainer';
import { ProjectSubscriptionsListContainerReducers } from './containers/ProjectSubscriptionsListContainer';
import { SavedSearchSubscriptionsListContainerReducers } from './containers/SavedSearchSubscriptionsListContainer';
import { SubscriptionConfigurationContainerReducers } from './containers/SubscriptionConfigurationContainer';

export default from './AlertsPageContainer';
export const AlertsPageReducers = {
  ...OrganizationSubscriptionsListContainerReducers,
  ...ProfileSubscriptionsListContaierReducers,
  ...ProjectSubscriptionsListContainerReducers,
  ...SavedSearchSubscriptionsListContainerReducers,
  ...SubscriptionConfigurationContainerReducers,
};
