import NewProfilesStatisticsConstants from './NewProfilesStatisticsConstants';

const initialState = {
  loading: false,
  newProfilesStatistics: null,
};

function NewProfilesStatisticsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NewProfilesStatisticsConstants.LOAD_DATABASE_ANALYTICS_NEW_PROFILES_STATISTICS_START: {
      return { ...state, loading: true, newProfilesStatistics: null };
    }
    case NewProfilesStatisticsConstants.LOAD_DATABASE_ANALYTICS_NEW_PROFILES_STATISTICS_SUCCESS: {
      const { newProfilesStatistics } = action;

      return { ...state, loading: false, newProfilesStatistics };
    }
    case NewProfilesStatisticsConstants.LOAD_DATABASE_ANALYTICS_NEW_PROFILES_STATISTICS_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}

export default NewProfilesStatisticsReducer;
