import OrganizationUpdatesConstants from './OrganizationUpdatesConstants';
import OrganizationUpdatesRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/OrganizationUpdatesRepository';

export function loadOrganizationUpdates() {
  return (dispatch) => {
    dispatch({ type: OrganizationUpdatesConstants.LOAD_DATABASE_ANALYTIC_ORGANIZATION_UPDATES_START });

    return OrganizationUpdatesRepository.index().then(({ organizationUpdates }) => {
      dispatch({
        type: OrganizationUpdatesConstants.LOAD_DATABASE_ANALYTIC_ORGANIZATION_UPDATES_SUCCESS,
        organizationUpdates,
      });
    });
  };
}
