import { buildFilterParams } from 'utils/FilterParamsBuilder';
import RequestStatisticBlockConstants from './RequestStatisticBlockConstants';
import RequestStatisticRepository from 'repositories/RequestStatisticRepository';

export function loadRequestStatistic(filters = {}) {
  const params = buildFilterParams(filters);

  return (dispatch) => {
    dispatch({ type: RequestStatisticBlockConstants.LOAD_REQUEST_STATISTIC_START });

    return RequestStatisticRepository.show(params).then(({ statistic }) =>
      dispatch({ type: RequestStatisticBlockConstants.LOAD_REQUEST_STATISTIC_SUCCESS, statistic }),
    );
  };
}
