import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Button from 'components/Button';
import Card from 'components/Card';
import Topic from './components/Topic';
import Modal from 'components/Modal';
import TopicForm from './components/TopicForm';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';
import withConfirmation from 'hoc/withConfirmation';

import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import TopicPresenter from 'presenters/TopicPresenter';
import styles from './TopicsList.module.css';

class TopicsListComponent extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()),
    kind: PropTypes.string.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(TopicPresenter.shape()).isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
    displayTags: PropTypes.bool.isRequired,
  };

  state = { newTopic: false };

  handleAddNewTopic = () => {
    const { editable } = this.props;

    this.setState({ newTopic: editable });
  };

  handleCancelNewTopic = () => this.setState({ newTopic: false });

  handleTopicCreate = (topic) => this.props.onCreate(topic).then(this.handleCancelNewTopic);

  handleTopicUpdate = ({ id, ...topic }) => this.props.onUpdate(id, topic);

  handleTopicDestroy = (id) => this.props.onDelete(id);

  render() {
    const { editable, jobFunctions, saving, title, topics, isChanged, onReviewChanges, onTagSearch, displayTags } =
      this.props;
    const { newTopic } = this.state;

    return (
      <>
        <Card>
          <Box direction="row" justify="between" margin={{ bottom: 'small' }}>
            <HeadlineWithReviewChangesLink
              strong
              size="small"
              margin="small"
              title={title}
              isChanged={isChanged}
              onReviewChanges={onReviewChanges}
            />
          </Box>
          {topics.map((topic) => (
            <Topic
              editable={editable}
              jobFunctions={jobFunctions}
              key={topic.id}
              onDelete={this.handleTopicDestroy}
              onUpdate={this.handleTopicUpdate}
              onTagSearch={onTagSearch}
              saving={saving}
              formTitle={title}
              topic={topic}
              displayTags={displayTags}
            />
          ))}
          <Button label="Add New" disabled={!editable} onClick={this.handleAddNewTopic} className={styles.button} />
        </Card>
        {newTopic && (
          <Modal onClose={this.handleCancelNewTopic} size="large" header={title} disableOverlayClose disableCloseByEsc>
            <TopicForm
              jobFunctions={jobFunctions}
              onSubmit={this.handleTopicCreate}
              onTagSearch={onTagSearch}
              topic={{ kind: this.props.kind }}
              saving={saving}
              displayTags={displayTags}
            />
          </Modal>
        )}
      </>
    );
  }
}

const TopicsList = withConfirmation(TopicsListComponent, { onDelete: 'Are you sure want to delete?' });

export default TopicsList;
