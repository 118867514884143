import { combineReducers } from 'redux';

import { AuthenticatorReducers } from './containers/Authenticator';
import { AdminPanelReducers } from './containers/AdminPanel';
import { UserPanelReducers } from './containers/UserPanel';
import { NotificationsReducers } from './containers/Notifications';
import clientSearchReducer from 'slices/client/searchSlice';
import ProductivityStatisticsReducer from 'slices/client/ProductivityStatisticsSlice';
import adminEditorialDashboardProductionReducer from 'slices/admin/editorialDashboard/productionSlice';
import currentUserLimitedAccessReducer from 'slices/currentUser/limitedAccessSlice';
import exportJobUrlReducer from 'slices/client/exportJobUrlSlice';
import adminProfileReducer from 'slices/admin/profileSlice';
import adminOrganizationReducer from 'slices/admin/organizationSlice';
import adminProfileEditorNotesReducer from 'slices/admin/profile/editorNotesSlice';
import adminOrganizationEditorNotesReducer from 'slices/admin/organization/editorNotesSlice';
import adminProfileEditorWorksReducer from 'slices/admin/profile/editorWorksSlice';
import adminOrganizationEditorWorksReducer from 'slices/admin/organization/editorWorksSlice';
import adminRequestsPageRequestsTableReducer from 'slices/admin/requestsPage/requestsTableSlice';

import { reducers as slicesReducers } from 'slices';

const reducers = combineReducers({
  ...AuthenticatorReducers,
  ...AdminPanelReducers,
  ...UserPanelReducers,
  ...NotificationsReducers,
  ...slicesReducers,
  clientSearchReducer,
  ProductivityStatisticsReducer,
  currentUserLimitedAccessReducer,
  adminEditorialDashboardProductionReducer,
  exportJobUrlReducer,
  adminProfileReducer,
  adminOrganizationReducer,
  adminProfileEditorNotesReducer,
  adminOrganizationEditorNotesReducer,
  adminProfileEditorWorksReducer,
  adminOrganizationEditorWorksReducer,
  adminRequestsPageRequestsTableReducer,
  ...slicesReducers,
});

export default reducers;
