import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';
import { detectCsvFileDelimiter } from 'utils/fileUtils';

import { useCurrentUserExecutivesMatches } from 'hooks/currentUser';

import ProjectPresenter, { RECOMMENDED_PROFILES_COUNT } from 'presenters/ProjectPresenter';
import SourcePresenter from 'presenters/Project/SourcePresenter';

import AlertIcon from 'icons/AlertIcon';
import CsvIcon from 'icons/CsvIcon';

import Dropzone, { FileStatus } from 'components/v2/Dropzone';
import Typography from 'components/v2/Typography';
import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import Instruction from './components/Instruction';

import styles from './ProjectUploadExecutivesCsvWizardStep.module.css';

const ProjectUploadExecutivesCsvWizardStep = (props) => {
  const { project, source, updateSource } = props;

  const { matchExecutivesParamsValidationStatus, validateMatchExecutiveParams } = useCurrentUserExecutivesMatches();

  const handleFileChange = async (file) => {
    const attributes = {
      ...source,
      file: null,
      delimiter: null,
      csvItemAttributes: [],
      isFileProcessed: false,
      fileValidationResult: null,
    };

    if (isNil(file)) {
      updateSource(attributes);
      return;
    }

    const delimiter = await detectCsvFileDelimiter(file);

    updateSource({ ...attributes, file, delimiter });

    const { validationResult } = await validateMatchExecutiveParams({ file, delimiter });

    updateSource({ ...attributes, file, delimiter, fileValidationResult: validationResult });
  };

  const fileErrors = source.fileValidationResult?.errors?.file || [];
  const isFileValid = !isNil(source.file) && matchExecutivesParamsValidationStatus.isFulfilled && isEmpty(fileErrors);
  const recommendedProfilesCount = isNil(project)
    ? RECOMMENDED_PROFILES_COUNT
    : ProjectPresenter.recommendedProfilesCount(project);

  return (
    <>
      <WizardTitle subtitle="Upload your list of target companies and executives." />
      <div className={styles.content}>
        <div className={styles.alert}>
          <AlertIcon color="orange" width={24} height={24} />
          <Typography className={styles.alertMessage} color="orange" variant="h3">
            For best results limit your list to {recommendedProfilesCount} executives.
          </Typography>
        </div>
        <div className={styles.details}>
          <Instruction meta={source.fileValidationResult} />
          <div className={styles.dropzone}>
            <Dropzone acceptanceContentType={{ 'text/*': ['.csv'] }} onFileChange={handleFileChange}>
              <FileStatus
                errors={fileErrors}
                file={source.file}
                validationStatus={matchExecutivesParamsValidationStatus}
                uploadMessage="Drag & drop CSV-file here"
                successIcon={<CsvIcon width={54} height={54} />}
              />
            </Dropzone>
          </div>
        </div>
      </div>
      <WizardNavigation isNextButtonDisabled={!isFileValid} />
    </>
  );
};

ProjectUploadExecutivesCsvWizardStep.propTypes = {
  project: ProjectPresenter.shape(),
  source: SourcePresenter.shape().isRequired,
  updateSource: PropTypes.func.isRequired,
};

export default ProjectUploadExecutivesCsvWizardStep;
