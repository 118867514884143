import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isPresent } from 'utils/HelperMethods';
import config from 'config';

import ProfilePresenter from 'presenters/ProfilePresenter';

import { profileMetaTitle, profileMetaDesciption } from 'utils/MetaHelper';

import Routes from 'routes';

class ProfileMeta extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape(),
    indexable: PropTypes.bool,
    canonicalPath: PropTypes.string,
  };

  buildStructuredData() {
    const { profile } = this.props;
    const photoUrl = ProfilePresenter.photoUrl(profile);
    const publisherStructuredData = {
      '@type': 'Organization',
      name: 'Boardroom Insiders, Inc.',
      logo: { '@type': 'ImageObject', url: `${config.assetsHost}/static/images/structured-data-logo.png` },
    };
    const profileStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      author: publisherStructuredData,
      publisher: publisherStructuredData,
      dateModified: ProfilePresenter.profileUpdatedAt(profile),
      datePublished: ProfilePresenter.profileUpdatedAt(profile),
      headline: profileMetaTitle(profile),
      description: profileMetaDesciption(profile),
      mainEntityOfPage: { '@type': 'WebPage', '@id': window.location.href },
    };

    if (isPresent(photoUrl)) {
      profileStructuredData.image = [photoUrl];
    }

    return JSON.stringify(profileStructuredData);
  }

  renderTitle() {
    const { profile } = this.props;

    return profileMetaTitle(profile);
  }

  renderDesciption() {
    const { profile } = this.props;

    return profileMetaDesciption(profile);
  }

  render() {
    const { profile, indexable, canonicalPath } = this.props;
    return (
      <>
        <Helmet>
          <title>{this.renderTitle()}</title>
          <meta name="Description" content={this.renderDesciption()} />
          {!indexable && <meta name="robots" content="noindex" />}
          <link rel="canonical" href={Routes.withOrigin(canonicalPath, ProfilePresenter.slug(profile))} />
        </Helmet>
        <Helmet script={[{ type: 'application/ld+json', innerHTML: this.buildStructuredData() }]} />
      </>
    );
  }
}

export default ProfileMeta;
