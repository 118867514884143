import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formatNumber } from 'utils/NumbersHelper';

import TopFtse100OrganizationsPresenter from './Ftse100ProfilesStatistics/TopFtse100OrganizationsPresenter';

export default new Presenter(
  {
    totalProfilesNumber: PropTypes.number,
    avgProfilesNumberPerCompany: PropTypes.number,
    totalBoardMembersNumber: PropTypes.number,
    topFtse100Organizations: PropTypes.arrayOf(TopFtse100OrganizationsPresenter.shape()),
  },
  {
    formattedTotalProfilesNumber(s) {
      return formatNumber(this.totalProfilesNumber(s));
    },

    formattedAvgProfilesNumberPerCompany(s) {
      return formatNumber(this.avgProfilesNumberPerCompany(s));
    },

    formattedTotalBoardMembersNumber(s) {
      return formatNumber(this.totalBoardMembersNumber(s));
    },
  },
);
