import ContactInformationStyleGuide from './components/ContactInformationStyleGuide';
import ExecutiveSummaryStyleGuide from './components/ExecutiveSummaryStyleGuide';
import PersonalAttributesAndInterestsStyleGuide from './components/PersonalAttributesAndInterestsStyleGuide';
import NewsAndInterviewsStyleGuide from './components/NewsAndInterviewsStyleGuide';
import CurrentFocusStyleGuide from './components/CurrentFocusStyleGuide';
import KeyChallengesStyleGuide from './components/KeyChallengesStyleGuide';
import SourcesStyleGuide from './components/SourcesStyleGuide';
import BioSummaryStyleGuide from './components/BioSummaryStyleGuide';
import PersonalInformationStyleGuide from './components/PersonalInformationStyleGuide';
import EducationHistoryStyleGuide from './components/EducationHistoryStyleGuide';
import EmploymentHistoryStyleGuide from './components/EmploymentHistoryStyleGuide';
import OtherBoardAndOrganizationStyleGuide from './components/OtherBoardAndOrganizationStyleGuide';
import OrganizationInformationStyleGuide from './components/OrganizationInformationStyleGuide';
import HQContactInformation from './components/HQContactInformation';
import PersonalInterests from './components/PersonalInterests';
import Languages from './components/Languages';
import OriginCountry from './components/OriginCountry';

export default {
  PersonalInformationStyleGuide,
  ContactInformationStyleGuide,
  ExecutiveSummaryStyleGuide,
  PersonalAttributesAndInterestsStyleGuide,
  NewsAndInterviewsStyleGuide,
  CurrentFocusStyleGuide,
  KeyChallengesStyleGuide,
  SourcesStyleGuide,
  BioSummaryStyleGuide,
  EducationHistoryStyleGuide,
  EmploymentHistoryStyleGuide,
  OtherBoardAndOrganizationStyleGuide,
  OrganizationInformationStyleGuide,
  HQContactInformation,
  PersonalInterests,
  Languages,
  OriginCountry,
};
