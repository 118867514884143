import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import TargetingSearchRepository from 'repositories/CurrentUser/TargetingSearchRepository';

const restSlice = createRestSlice({
  resource: 'targetingSearchExecutive',
  repository: TargetingSearchRepository,
  slices: ['loadTargetingSearchExecutives'],
});

const initialState = {
  ...restSlice.initialState,
};

const executivesSlice = createSlice({
  name: 'clientTargetingSearchExecutives',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientTargetingSearchExecutivesReducer = executivesSlice.reducer;

export default executivesSlice;

export const clientTargetingSearchExecutivesRestHooks = restSlice.hooks(
  executivesSlice,
  'clientTargetingSearchExecutivesReducer',
);
