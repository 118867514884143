import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniq, values } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import { CheckBox as GrommetCheckBox } from 'grommet';

import styles from './Checkbox.module.css';

class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    errors: PropTypes.shape(),
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  renderErrors(errors) {
    const message = uniq(values(errors)).join(', ');

    return <div className={styles.errors}>{message}</div>;
  }

  render() {
    const { checked, errors, label, onChange } = this.props;
    const hasErrors = isPresent(errors);

    return (
      <div className={styles.root}>
        <GrommetCheckBox className={styles.checkbox} label={label} onChange={onChange} checked={checked} />
        {hasErrors && this.renderErrors(errors)}
      </div>
    );
  }
}

export default Checkbox;
