import AdminSavedSearchesConstants from './AdminSavedSearchesConstants';

const initialState = {
  isLoading: false,
  savedSearches: [],
};

function AdminSavedSearchesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AdminSavedSearchesConstants.FILTER_ADMIN_SAVED_SEARCHES_START: {
      return { ...initialState, isLoading: true };
    }
    case AdminSavedSearchesConstants.FILTER_ADMIN_SAVED_SEARCHES_SUCCESS: {
      const { items, meta } = action;

      return { ...state, isLoading: false, savedSearches: items, meta };
    }
    case AdminSavedSearchesConstants.LOAD_ADMIN_SAVED_SEARCHES_START: {
      return { ...state, isLoading: true };
    }
    case AdminSavedSearchesConstants.LOAD_ADMIN_SAVED_SEARCHES_SUCCESS: {
      const { items, meta } = action;
      const savedSearches = [...state.savedSearches, ...items];

      return { ...state, savedSearches, meta };
    }
    default:
      return state;
  }
}

export default AdminSavedSearchesReducer;
