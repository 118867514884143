import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewPassword from './NewPassword';
import * as actions from './NewPasswordActions';

const mapStateToProps = (state) => ({
  errors: state.NewPasswordReducer.errors,
  form: state.NewPasswordReducer.form,
  isPasswordChanged: state.NewPasswordReducer.isPasswordChanged,
  saving: state.NewPasswordReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
