import PropTypes from 'prop-types';
import { propEq } from 'ramda';
import { formattedDate } from 'utils/DateTime';
import Presenter from '../utils/PropTypesPresenter';

import UserPresenter from 'presenters/UserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

export const SOURCES = {
  REQUEST_FORM: 'request_form',
  REQUEST_IMPORT: 'request_import',
};

export const PRIORITIES = [
  {
    key: 'high',
    title: 'High',
    color: '#E86545',
    textColor: '#FFF',
  },
];

export const STATES = [
  {
    key: 'requested',
    title: 'Requested',
  },
  {
    key: 'in_research',
    title: 'In Research',
  },
  {
    key: 'in_editorial',
    title: 'In Editorial',
  },
  {
    key: 'completed',
    title: 'Completed',
  },
  {
    key: 'delivered',
    title: 'Delivered',
  },
  {
    key: 'canceled',
    title: 'Canceled',
  },
];

export const STATE_EVENTS = [
  {
    key: 'to_research',
    title: 'To Research',
  },
  {
    key: 'return_to_research',
    title: 'To Research',
  },
  {
    key: 'to_editorial',
    title: 'To Editorial',
  },
  {
    key: 'return_to_editorial',
    title: 'To Editorial',
  },
  {
    key: 'complete',
    title: 'Complete',
  },
  {
    key: 'deliver',
    title: 'Deliver',
  },
  {
    key: 'cancel',
    title: 'Cancel',
  },
];

export const REQUEST_TYPES = [
  {
    key: 'new_profile_request',
    title: 'New Profile Request',
  },
  {
    key: 'update_request',
    title: 'Update Request',
  },
  {
    key: 'news_update',
    title: 'News Update',
  },
  {
    key: 'most_viewed_update',
    title: 'Most Viewed Update',
  },
];

export const CANCELATION_REASONS = [
  {
    key: 'no_longer_in_this_role_or_organization',
    title: 'This person is no longer in this role/with this company',
  },
  {
    key: 'could_not_find_anyone',
    title: 'We could not find anyone with this name/title/company',
  },
  {
    key: 'not_enough_verifable_information',
    title: 'We could not find enough verifiable information on this person to create a full profile',
  },
  {
    key: 'was_opted_out_from_database',
    title: 'This person has opted out of appearing in our database',
  },
  {
    key: 'other',
    title: 'Other',
  },
];

export function humanPriorityName(priority) {
  return PRIORITIES.find(propEq(priority, 'key')).title;
}

export function priorityColor(priority) {
  return PRIORITIES.find(propEq(priority, 'key')).color;
}

export function priorityTextColor(priority) {
  return PRIORITIES.find(propEq(priority, 'key')).textColor;
}

export function humanRequestStateName(state) {
  return STATES.find(propEq(state, 'key')).title;
}

export function humanRequestTypeName(state) {
  return REQUEST_TYPES.find(propEq(state, 'key')).title;
}

export default new Presenter(
  {
    id: PropTypes.number,
    cancelationReason: PropTypes.string,
    cancelationConfirmation: PropTypes.string,
    createdAt: PropTypes.string,
    state: PropTypes.string,
    stateEvents: PropTypes.arrayOf(PropTypes.string),
    subjectType: PropTypes.string,
    requestType: PropTypes.string,
    subjectId: PropTypes.number,
    versionId: PropTypes.number,
    assignee: UserPresenter.shape(),
    previousAssignee: UserPresenter.shape(),
    requestor: UserPresenter.shape(),
    subject: PropTypes.shape(),
    dueDate: PropTypes.string,
    deliveredAt: PropTypes.string,
    subjectAttributes: PropTypes.shape(),
    company: CompanyPresenter.shape(),
    canEdit: PropTypes.bool,
    canClaimAssignee: PropTypes.bool,
    priority: PropTypes.string,
  },
  {
    stateName(r) {
      const state = this.state(r);

      return humanRequestStateName(state);
    },
    humanStateEvents(r) {
      const stateEvents = this.stateEvents(r);

      return stateEvents.map((stateEvent) => STATE_EVENTS.find(propEq(stateEvent, 'key')));
    },
    humanRequestType(r) {
      const requestType = this.requestType(r);

      return REQUEST_TYPES.find(propEq(requestType, 'key')).title;
    },
    humanCancelationReason(r) {
      const cancelationReason = this.cancelationReason(r);

      return CANCELATION_REASONS.find(propEq(cancelationReason, 'key')).title;
    },
    isRequested(r) {
      return this.state(r) === 'requested';
    },
    isInResearch(r) {
      return this.state(r) === 'in_research';
    },
    isInEditorial(r) {
      return this.state(r) === 'in_editorial';
    },
    isCompleted(r) {
      return this.state(r) === 'completed';
    },
    isDelivered(r) {
      return this.state(r) === 'delivered';
    },
    isCanceled(r) {
      return this.state(r) === 'canceled';
    },
    formattedCreatedAt(r) {
      return formattedDate(this.createdAt(r));
    },
    formattedDueDate(r) {
      return formattedDate(this.dueDate(r));
    },
    isSubjectProfile(r) {
      return this.subjectType(r) === 'Profile';
    },
    isSubjectOrganization(r) {
      return this.subjectType(r) === 'Organization';
    },
    titleFromSubjectAttributes(r) {
      const subjectAttributes = this.subjectAttributes(r) || {};

      if (this.isSubjectProfile(r)) {
        return `${subjectAttributes.firstName} ${subjectAttributes.lastName}`;
      }

      if (this.isSubjectOrganization(r)) {
        return subjectAttributes.name;
      }

      return null;
    },
    title(r) {
      const subject = this.subject(r);

      if (!subject) {
        return this.titleFromSubjectAttributes(r);
      }

      if (this.isSubjectProfile(r)) {
        return ProfilePresenter.firstNameWithLastName(subject);
      }

      if (this.isSubjectOrganization(r)) {
        return OrganizationPresenter.name(subject);
      }

      return '';
    },
    subtitle(r) {
      if (this.isSubjectProfile(r)) {
        const subject = this.subject(r);

        if (subject) {
          return ProfilePresenter.primaryCurrentWorkTitle(subject);
        }

        const { organizationName, jobTitle } = this.subjectAttributes(r) || {};

        if (organizationName && jobTitle) {
          return `${organizationName}, ${jobTitle}`;
        }

        if (organizationName) {
          return organizationName;
        }

        if (jobTitle) {
          return jobTitle;
        }
      }

      return null;
    },
    formattedDeliveredAt(r) {
      return formattedDate(this.deliveredAt(r));
    },
    subjectFullName(r) {
      const subjectAttributes = this.subjectAttributes(r) || {};

      if (subjectAttributes.firstName && subjectAttributes.lastName) {
        return `${subjectAttributes.firstName} ${subjectAttributes.lastName}`;
      }

      return subjectAttributes.firstName || subjectAttributes.lastName;
    },
    subjectJobTitle(r) {
      const subjectAttributes = this.subjectAttributes(r) || {};

      return subjectAttributes.jobTitle;
    },
    subjectOrganizationName(r) {
      const subjectAttributes = this.subjectAttributes(r) || {};

      return subjectAttributes.organizationName || subjectAttributes.name;
    },
    priorityName(r) {
      const priority = this.priority(r);

      return humanPriorityName(priority);
    },
    priorityColor(r) {
      const priority = this.priority(r);

      return priorityColor(priority);
    },
    priorityTextColor(r) {
      const priority = this.priority(r);

      return priorityTextColor(priority);
    },
    slug(r) {
      const subject = this.subject(r);

      if (this.isSubjectProfile(r)) {
        return ProfilePresenter.slug(subject);
      }

      return '';
    },
  },
);
