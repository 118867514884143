import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

import ProfilePresenter from 'presenters/ProfilePresenter';

export default new Presenter({
  id: PropTypes.number,
  position: PropTypes.number,
  publishedProfile: ProfilePresenter.shape(),
});
