import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import SavedSearch from './components/SavedSearch';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import Routes from 'routes';

import styles from './SavedSearches.module.css';

class SavedSearches extends Component {
  static propTypes = {
    savedSearches: PropTypes.arrayOf(SavedSearchPresenter.shape()).isRequired,
  };

  render() {
    const { savedSearches } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.savedSearches}>
          {savedSearches.map((savedSearch) => (
            <SavedSearch key={SavedSearchPresenter.id(savedSearch)} savedSearch={savedSearch} />
          ))}
        </div>
        <div className={styles.actions}>
          <Button className={styles.button} label="See All Saved Searches" href={Routes.savedSearchesPath()} />
        </div>
      </div>
    );
  }
}

export default SavedSearches;
