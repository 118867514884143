import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import CompanyPresenter, { STATE_EVENTS } from 'presenters/CompanyPresenter';

import { Field, Input, Switch } from 'components/v2/Form';
import Typography from 'components/v2/Typography';

import styles from './GeneralInformationFields.module.css';

const GeneralInformationFields = (props) => {
  const { errors, companyValues, initialCompanyValues, onFieldChange } = props;

  const isInitiallyActive = CompanyPresenter.isActive(initialCompanyValues);
  const isActivateStateEvent = companyValues.stateEvent === STATE_EVENTS.ACTIVATE;
  const isActive = isNil(companyValues.stateEvent) ? isInitiallyActive : isActivateStateEvent;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);
  const handleStateEventChange = (value) => {
    let stateEvent = null;

    if (isInitiallyActive !== value) {
      stateEvent = value ? STATE_EVENTS.ACTIVATE : STATE_EVENTS.DISABLE;
    }

    onFieldChange('stateEvent', stateEvent);
  };

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Company Information</Typography>
      <Field title="Active" error={errors.state} variant="outlined">
        <Switch checked={isActive} onChange={handleStateEventChange} />
      </Field>
      <Field title="Company Name:" error={errors.name} variant="outlined">
        <Input onChange={handleChange('name')} value={companyValues.name} />
      </Field>
      <Field title="Custom BX Domain:" error={errors.customBxDomain} variant="outlined">
        <Input onChange={handleChange('customBxDomain')} value={companyValues.customBxDomain} />
      </Field>
    </div>
  );
};

GeneralInformationFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  companyValues: CompanyPresenter.shape().isRequired,
  initialCompanyValues: CompanyPresenter.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default GeneralInformationFields;
