import React from 'react';
import { difference, equals, isNil, map, pipe, propEq, reject, toPairs } from 'ramda';
import { imagePath } from 'utils/AssetsHelper';

import { useCurrentUser, useCurrentUserRemainingRequestsNumber } from 'hooks';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';
import { useFormContext } from 'components/v2/Form';
import { useWizardContext } from 'components/v2/Wizard';

import RequestsReviewTable from 'components/client/RequestsReviewTable';

import CommonFields from './components/CommonFields';

import styles from './ReviewRequestsWizardStep.module.css';

const ReviewRequestsWizardStep = () => {
  const { currentUser } = useCurrentUser();
  const { errors, isSubmitting, onFieldChange, onSubmit, values } = useFormContext();
  const { navigateNextWizardStep, navigatePreviousWizardStep } = useWizardContext();
  const [remainingRequestsNumber, refreshRemainingRequestNumber] = useCurrentUserRemainingRequestsNumber(
    currentUser.remainingRequestsNumber,
  );

  const { requests, selectedRequestExecutiveMatchIndexes } = values;
  const selectedRequestIndexes = pipe(
    map((executiveMatchIndex) => requests.findIndex(propEq(executiveMatchIndex, 'executiveMatchIndex'))),
    reject(equals(-1)),
  )(selectedRequestExecutiveMatchIndexes);

  const handleRequestsChange = (newRequests) => {
    requests.forEach((request, index) => {
      const newRequest = newRequests.find(propEq(request.executiveMatchIndex, 'executiveMatchIndex'));
      const changes = difference(toPairs(newRequest.subjectAttributes), toPairs(request.subjectAttributes));

      changes.forEach(([fieldName, value]) =>
        onFieldChange(`requests[${index}].subjectAttributes.${fieldName}`, value),
      );
    });
  };
  const handleSelectedRequestExecutiveMatchIndexesChange = (requestIndexes) => {
    const newSelectedRequestExecutiveMatchIndexes = pipe(
      map((requestIndex) => requests[requestIndex]?.executiveMatchIndex),
      reject(isNil),
    )(requestIndexes);

    onFieldChange('selectedRequestExecutiveMatchIndexes', newSelectedRequestExecutiveMatchIndexes);
  };
  const handleSubmit = async () => {
    const isSuccess = await onSubmit();

    if (isSuccess) {
      navigateNextWizardStep();
    } else {
      refreshRemainingRequestNumber();
    }
  };

  return (
    <div>
      <Typography variant="h1">Upload CSV File</Typography>
      <Typography className={styles.subtitle} variant="h2">
        Matched Profiles
      </Typography>
      {isSubmitting && (
        <div className={styles.loader}>
          <img src={imagePath('project-creation-loader.gif')} alt="loading" />
        </div>
      )}
      {!isSubmitting && (
        <>
          <RequestsReviewTable
            errors={errors}
            requests={requests}
            onRequestsChange={handleRequestsChange}
            onSelectedRequestIndexesChange={handleSelectedRequestExecutiveMatchIndexesChange}
            remainingRequestsNumber={remainingRequestsNumber}
            selectedRequestIndexes={selectedRequestIndexes}
          />
          <CommonFields errors={errors} values={values} onFieldChange={onFieldChange} />
          <div className={styles.actions}>
            <Button disabled={isSubmitting} onClick={navigatePreviousWizardStep} variant="outlined">
              Back
            </Button>
            <Button disabled={isSubmitting || selectedRequestIndexes.length === 0} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewRequestsWizardStep;
