import TeamProductivityConstants from './TeamProductivityConstants';

const initialState = {
  loading: false,
  usersProductivities: [],
  filters: { compare: false },
};

function TeamProductivityReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TeamProductivityConstants.LOAD_TEAM_PRODUCTIVITY_START: {
      const { filters } = action;

      return { ...state, filters, loading: true };
    }
    case TeamProductivityConstants.LOAD_TEAM_PRODUCTIVITY_SUCCESS: {
      const { usersProductivities } = action;

      return { ...state, loading: false, usersProductivities };
    }
    default:
      return state;
  }
}

export default TeamProductivityReducer;
