import ProjectMatchedOrganizationsWizardStepConstants from './ProjectMatchedOrganizationsWizardStepConstants';
import MatchOrganizationsRepository from 'repositories/CurrentUser/MatchOrganizationsRepository';

export function matchOrganizations(form) {
  return (dispatch) => {
    dispatch({
      type: ProjectMatchedOrganizationsWizardStepConstants.MATCH_PROJECT_ORGANIZATIONS_START,
      form,
    });

    return MatchOrganizationsRepository.create(form)
      .then(({ items, meta }) => {
        dispatch({
          type: ProjectMatchedOrganizationsWizardStepConstants.MATCH_PROJECT_ORGANIZATIONS_SUCCESS,
          organizations: items,
          ...meta,
        });

        return items;
      })
      .catch((error) => {
        dispatch({
          type: ProjectMatchedOrganizationsWizardStepConstants.MATCH_PROJECT_ORGANIZATIONS_ERROR,
        });

        throw error;
      });
  };
}

export function toggleDetailsView() {
  return { type: ProjectMatchedOrganizationsWizardStepConstants.TOGGLE_PROJECT_ORGANIZATIONS_DETAILS_VIEW };
}
