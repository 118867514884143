import { pick } from 'ramda';

export default {
  defaultAttributes(apiV2AccessSettings) {
    return apiV2AccessSettings;
  },

  attributesToSubmit(attributes) {
    const propsToSend = ['renewSecret'];

    return pick(propsToSend, attributes);
  },
};
