import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import SingleSignOnPresenter, { STATE_EVENTS } from 'presenters/SingleSignOnPresenter';

import AlertIcon from 'icons/AlertIcon';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import GeneralSettingsFields from './components/GeneralSettingsFields';
import IdentityProviderSettingsFields from './components/IdentityProviderSettingsFields';
import ServiceProviderInformationFields from './components/ServiceProviderInformationFields';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const { errors, onFieldChange, onSubmit, initialValues, isSubmitting, values } = props;

  const isSsoInitiallyEnabled = SingleSignOnPresenter.isEnabled(initialValues);
  const isSsoActivateStateEvent = values.stateEvent === STATE_EVENTS.ENABLE;
  const isSsoEnabled = isNil(values.stateEvent) ? isSsoInitiallyEnabled : isSsoActivateStateEvent;

  return (
    <div>
      <div className={styles.row}>
        <GeneralSettingsFields
          errors={errors}
          initialSsoValues={initialValues}
          onFieldChange={onFieldChange}
          ssoValues={values}
        />

        {isSsoEnabled && (isNil(values.siteUrl) || isNil(values.cert)) && (
          <div className={styles.alertBox}>
            <Typography className={styles.alertTitle} color="salmon" variant="h3">
              <AlertIcon />
              &nbsp;Single Sign On Is Not Available!
            </Typography>
            {isNil(values.siteUrl) && (
              <Typography color="salmon" variant="body1">
                Identity Provider &quot;Single Sign On Url&quot; is missing!
              </Typography>
            )}
            {isNil(values.cert) && (
              <Typography color="salmon" variant="body1">
                Identity Provider &quot;Certificate&quot; is missing!
              </Typography>
            )}
          </div>
        )}
      </div>
      <div className={styles.row}>
        <IdentityProviderSettingsFields ssoValues={values} onFieldChange={onFieldChange} errors={errors} />
        <ServiceProviderInformationFields ssoValues={values} onFieldChange={onFieldChange} errors={errors} />
      </div>
      <Button disabled={isSubmitting} onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: SingleSignOnPresenter.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: SingleSignOnPresenter.shape().isRequired,
};

export default EditForm;
