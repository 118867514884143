import { prop } from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/v2/Button';
import Label from 'components/v2/Label';
import Loader from 'components/v2/Loader';

import styles from './TagsSelector.module.css';

const TagsSelector = (props) => {
  const { getOptionLabel, getOptionValue, isLoading, onChange, options, value } = props;

  const handleChange = (option) => () => {
    const optionValue = getOptionValue(option);

    return value === optionValue ? onChange(null) : onChange(optionValue);
  };

  return (
    <div className={styles.root}>
      {options.map((option) => (
        <Button key={getOptionValue(option)} onClick={handleChange(option)} variant="raw">
          <Label onClick={handleChange} variant={getOptionValue(option) === value ? 'default' : 'outlined'}>
            {getOptionLabel(option)}
          </Label>
        </Button>
      ))}
      {isLoading && <Loader className={styles.loader} />}
    </div>
  );
};

TagsSelector.propTypes = {
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TagsSelector.defaultProps = {
  getOptionLabel: prop('label'),
  getOptionValue: prop('value'),
  isLoading: false,
  value: null,
  onChange: () => {},
};

export default TagsSelector;
