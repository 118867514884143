import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ViewSkinnyProfile from './ViewSkinnyProfile';
import * as actions from './ViewSkinnyProfileActions';

const mapStateToProps = (state, props) => ({
  loading: state.ViewSkinnyProfileReducer.loading,
  profile: state.ViewSkinnyProfileReducer.profile,
  profileSlug: props.match.params.slug,
  isGdprCustomer: state.ViewSkinnyProfileReducer.isGdprCustomer,
  canSeeFullProfileRequestForm: state.ViewSkinnyProfileReducer.canSeeFullProfileRequestForm,
  canRequestFullProfile: state.ViewSkinnyProfileReducer.canRequestFullProfile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewSkinnyProfile);
