import { adminCompaniesRestHooks } from 'slices/admin/companiesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useCompanies = () => {
  const { company, companies, loadCompany, loadCompanies, updateCompany } = adminCompaniesRestHooks.use();

  return {
    company: company.item,
    companies: companies.items,
    companyLoadingStatus: useFetchStatus(company.loadingStatus),
    companiesLoadingStatus: useFetchStatus(companies.loadingStatus),
    companyUpdatingStatus: useFetchStatus(company.updatingStatus),
    loadCompany,
    loadCompanies,
    updateCompany,
  };
};

export default useCompanies;
