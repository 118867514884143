import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v2/Button';
import Link from 'components/v2/Link';
import Typography from 'components/v2/Typography';
import Wysiwyg from 'components/Wysiwyg';
import { Field, Input } from 'components/v2/Form';

import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import Routes from 'routes';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const { errors, isSubmitting, onFieldChange, onSubmit, values: companyDepartmentForm } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div className={styles.form}>
      <Typography className={styles.formSectionTitle} variant="h3">
        Self Registration Form
      </Typography>

      <Field title="Header" variant="outlined" error={errors.registrationFormHeader}>
        <Input value={companyDepartmentForm.registrationFormHeader} onChange={handleChange('registrationFormHeader')} />
      </Field>

      <Typography className={styles.formSectionTitle} variant="h3">
        Right Column
      </Typography>

      <Wysiwyg
        editableContent={companyDepartmentForm.registrationFormWysiwyg}
        onChange={handleChange('registrationFormWysiwyg')}
      />

      <Field title="YouTube Video Embed Code" variant="outlined" error={errors.registrationFormVideoCode}>
        <Input
          value={companyDepartmentForm.registrationFormVideoCode}
          onChange={handleChange('registrationFormVideoCode')}
        />
      </Field>

      <div className={styles.formActions}>
        <Button
          component={Link}
          target="_blank"
          href={Routes.newCompanyDepartmentUserPath(companyDepartmentForm.company.id, companyDepartmentForm.id)}
          variant="outlined"
        >
          Open Self Registration Form
        </Button>
        <Button disabled={isSubmitting} variant="primary" onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: CompanyDepartmentPresenter.shape().isRequired,
};

export default EditForm;
