import { prop } from 'ramda';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import RequestFormConstants from './RequestFormConstants';
import RequestsRepository from 'repositories/admin/RequestsRepository';
import AttributeSafeUsersRepository from 'repositories/admin/AttributeSafeUsersRepository';
import RequestProfileRepository from 'repositories/admin/Request/ProfileRepository';
import ProfilesRepository from 'repositories/admin/ProfilesRepository';
import OrganizationsRepository from 'repositories/admin/OrganizationsRepository';
import IndustriesRepository from 'repositories/admin/IndustriesRepository';
import NaicsIndustryCodesRepository from 'repositories/admin/NaicsIndustryCodesRepository';

export function searchUser(filters) {
  return () => {
    const params = buildFilterParams(filters);

    return AttributeSafeUsersRepository.index(params).then(({ items }) => items);
  };
}

export function initializeRequestForm(request) {
  return { type: RequestFormConstants.INITIALIZE_REQUEST_FORM, request };
}

export function loadRequest(requestId) {
  return (dispatch) => {
    dispatch({ type: RequestFormConstants.LOAD_REQUEST_START });

    return RequestsRepository.show(requestId)
      .then(({ request }) => {
        dispatch({ type: RequestFormConstants.LOAD_REQUEST_SUCCESS, request });

        return request;
      })
      .catch((error) => {
        dispatch({ type: RequestFormConstants.LOAD_REQUEST_ERROR });

        throw error;
      });
  };
}

export function updateRequest(requestId, params) {
  return (dispatch) => {
    dispatch({ type: RequestFormConstants.UPDATE_REQUEST_START });

    return RequestsRepository.update(requestId, params)
      .then(({ request }) => {
        dispatch({ type: RequestFormConstants.UPDATE_REQUEST_SUCCESS, request });

        return request;
      })
      .catch((error) => {
        dispatch({ type: RequestFormConstants.UPDATE_REQUEST_ERROR });

        throw error;
      });
  };
}

export function assignEditorForRequest(requestId, params) {
  return (dispatch) => {
    dispatch({ type: RequestFormConstants.ASSIGN_EDITOR_TO_REQUEST_START });

    return RequestsRepository.assignEditor(requestId, params)
      .then(({ request }) => {
        dispatch({ type: RequestFormConstants.ASSIGN_EDITOR_TO_REQUEST_SUCCESS, request });

        return request;
      })
      .catch((error) => {
        dispatch({ type: RequestFormConstants.ASSIGN_EDITOR_TO_REQUEST_ERROR });

        throw error;
      });
  };
}

export function createRequestProfile(requestId, params) {
  return (dispatch) => {
    dispatch({ type: RequestFormConstants.CREATE_REQUEST_PROFILE_START });

    return RequestProfileRepository.create(requestId, params)
      .then(({ profile, meta }) => {
        dispatch({
          type: RequestFormConstants.CREATE_REQUEST_PROFILE_SUCCESS,
          profile: profile.draft,
          requestId,
          request: meta.request,
        });

        return profile.draft;
      })
      .catch((error) => {
        dispatch({ type: RequestFormConstants.CREATE_REQUEST_PROFILE_ERROR });

        throw error;
      });
  };
}

export function searchOrganization(filters, options = {}) {
  return () => {
    const params = { ...options, ...buildFilterParams(filters) };

    return OrganizationsRepository.index(params).then(({ items }) => items.map(prop('draft')));
  };
}

export function searchProfile(filters, options = {}) {
  return () => {
    const params = { ...options, ...buildFilterParams(filters) };

    return ProfilesRepository.index(params).then(({ items }) => items.map(prop('draft')));
  };
}

export function resetRequestForm() {
  return { type: RequestFormConstants.RESET_REQUEST_FORM };
}

export function searchIndustry(filters) {
  const params = buildFilterParams(filters);

  return () => IndustriesRepository.index(params).then(({ items }) => items);
}

export function searchNaicsIndustryCode(filters) {
  const params = buildFilterParams(filters);

  return () => NaicsIndustryCodesRepository.index(params).then(({ items }) => items);
}
