import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import WorkPresenter from 'presenters/WorkSearchPresenter';

import AnchorLink from 'components/grommet/AnchorLink';
import ProfileAvatar from 'components/ProfileAvatar';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';

import styles from './Option.module.css';

const Option = (props) => {
  const { profile, onClick } = props;

  return (
    <AnchorLink to={Routes.profilePath(ProfilePresenter.slug(profile))} className={styles.root} onClick={onClick}>
      <ProfileAvatar size="28" src={ProfilePresenter.photoUrl(profile)} />
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.header}>
            <strong className={styles.name} title={ProfilePresenter.fullName(profile)}>
              {ProfilePresenter.fullName(profile)}
            </strong>

            {ProfilePresenter.isNewToRole(profile) && <NewToRoleBadge />}
            {ProfilePresenter.isMemberFortune500(profile) && <Fortune500BoardMemberBadge />}
            {ProfilePresenter.isMemberFtse100(profile) && <Ftse100BoardMemberBadge />}
          </div>
          {ProfilePresenter.currentWorks(profile).map((currentWork) => (
            <span key={WorkPresenter.id(currentWork)} className={styles.work} title={WorkPresenter.title(currentWork)}>
              {WorkPresenter.title(currentWork)}
            </span>
          ))}
        </div>
      </div>
    </AnchorLink>
  );
};

Option.propTypes = {
  profile: ProfilePresenter.shape(),
  onClick: PropTypes.func.isRequired,
};

export default Option;
