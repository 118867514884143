import React from 'react';
import PropTypes from 'prop-types';

import DepartmentForm from 'forms/company/DepartmentForm';

import Button from 'components/v2/Button';
import { Field, Input, Select, Switch } from 'components/v2/Form';

import CompanyDepartmentPresenter, { ACCOUNT_TYPES } from 'presenters/Company/DepartmentPresenter';

import styles from './NewForm.module.css';

const NewForm = (props) => {
  const { errors, isSubmitting, onFieldChange, onFieldsChange, onSubmit, values: companyDepartment } = props;

  const purchasedRequestsNumber = Number(companyDepartment.requestsNumber);
  const purchasedProfileViewsNumber = Number(companyDepartment.profileViewsNumber);
  const purchasedProfileDownloadsNumber = Number(companyDepartment.profileDownloadsNumber);
  const purchasedProjectsNumber = Number(companyDepartment.projectsNumber);
  const isCustomRequestsAllowed =
    CompanyDepartmentPresenter.isCustomerAccountAccountType(companyDepartment) ||
    CompanyDepartmentPresenter.isDemoAccountAccountType(companyDepartment) ||
    CompanyDepartmentPresenter.isPilotAccountAccountType(companyDepartment) ||
    CompanyDepartmentPresenter.isDownloadLimitedAccountType(companyDepartment) ||
    CompanyDepartmentPresenter.isIndividualAccountAccountType(companyDepartment);

  const isDownloadProfilesLimited =
    CompanyDepartmentPresenter.isDownloadLimitedAccountType(companyDepartment) ||
    CompanyDepartmentPresenter.isIndividualAccountAccountType(companyDepartment);

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  const handleAccountTypeChange = (accountType) => {
    if (accountType === companyDepartment.accountType) {
      return;
    }

    const newParams = DepartmentForm.reinitilizeForAccountType({ ...companyDepartment, accountType });

    onFieldsChange(newParams);
  };

  const handleDatabaseOnlyChange = (databaseOnly) => onFieldsChange({ requestsNumber: 0, databaseOnly });

  const handleProjectsAvailabilityChange = (projectsAvailable) =>
    onFieldsChange({ projectsNumber: 0, projectsExportEnabled: false, projectsAvailable });

  return (
    <div className={styles.fields}>
      <Field title="Name of Department" error={errors.name} variant="outlined">
        <Input value={companyDepartment.name} onChange={handleChange('name')} />
      </Field>
      <Field title="Number of Seats" error={errors.seatsNumber} variant="outlined">
        <Input
          value={companyDepartment.seatsNumber}
          onChange={handleChange('seatsNumber')}
          type="number"
          min="0"
          step="1"
        />
      </Field>
      <Field title="Account Type" error={errors.accountType} variant="outlined">
        <Select onChange={handleAccountTypeChange} options={ACCOUNT_TYPES} value={companyDepartment.accountType} />
      </Field>
      {isCustomRequestsAllowed && (
        <Field title="Database Only" error={errors.databaseOnly} variant="outlined">
          <Switch checked={companyDepartment.databaseOnly} onChange={handleDatabaseOnlyChange} />
        </Field>
      )}
      {isCustomRequestsAllowed && !companyDepartment.databaseOnly && (
        <Field title="Number of Profile Request" error={errors.requestsNumber} variant="outlined">
          <Input
            onChange={handleChange('requestsNumber')}
            value={companyDepartment.requestsNumber}
            suffix={<span className={styles.inputTotalSuffix}>{`of ${purchasedRequestsNumber}`}</span>}
            type="number"
            min="0"
            step="1"
          />
        </Field>
      )}

      {CompanyDepartmentPresenter.isDownloadLimitedAccountType(companyDepartment) && (
        <Field title="Number of Profile Views" error={errors.profileViewsNumber} variant="outlined">
          <Input
            onChange={handleChange('profileViewsNumber')}
            value={companyDepartment.profileViewsNumber}
            suffix={<span className={styles.inputTotalSuffix}>{`of ${purchasedProfileViewsNumber}`}</span>}
            type="number"
            min="0"
            step="1"
          />
        </Field>
      )}

      {isDownloadProfilesLimited && (
        <Field title="Number of Profile Downloads" error={errors.profileDownloadsNumber} variant="outlined">
          <Input
            onChange={handleChange('profileDownloadsNumber')}
            value={companyDepartment.profileDownloadsNumber}
            suffix={<span className={styles.inputTotalSuffix}>{`of ${purchasedProfileDownloadsNumber}`}</span>}
            type="number"
            min="0"
            step="1"
          />
        </Field>
      )}

      <Field title="Projects" error={errors.projectsAvailable} variant="outlined">
        <Switch checked={companyDepartment.projectsAvailable} onChange={handleProjectsAvailabilityChange} />
      </Field>
      {companyDepartment.projectsAvailable && (
        <>
          <Field title="Number of Projects" error={errors.projectsNumber} variant="outlined">
            <Input
              onChange={handleChange('projectsNumber')}
              value={companyDepartment.projectsNumber}
              suffix={<span className={styles.inputTotalSuffix}>{`of ${purchasedProjectsNumber}`}</span>}
              type="number"
              min="0"
              step="1"
            />
          </Field>
          <Field title="Export On Projects" error={errors.projectsExportEnabled} variant="outlined">
            <Switch
              checked={companyDepartment.projectsExportEnabled}
              onChange={handleChange('projectsExportEnabled')}
            />
          </Field>
        </>
      )}
      <Button disabled={isSubmitting} onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
};

NewForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: CompanyDepartmentPresenter.shape().isRequired,
};

export default NewForm;
