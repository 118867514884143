import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Anchor, Box, Heading } from 'grommet';
import { ChapterAdd as AddChapterIcon } from 'grommet-icons';

import { isPresent, isBlank } from 'utils/HelperMethods';

import Icon from 'components/Icon';

import RequestPresenter from 'presenters/RequestPresenter';

import styles from './VersionStatusBlock.module.css';

class VersionStatusBlock extends Component {
  static propTypes = {
    object: PropTypes.shape(),
    presenter: PropTypes.shape(),
    publishedVersionPath: PropTypes.func,
    request: RequestPresenter.shape(),
    onRequestCreate: PropTypes.func,
    saving: PropTypes.bool,
    canCreateRequest: PropTypes.bool,
  };

  renderPublishedStatus() {
    const { object, presenter, publishedVersionPath } = this.props;
    const publishedAt = presenter.formattedPublishedAt(object);
    const publishedPath = isPresent(publishedVersionPath) ? publishedVersionPath(object) : null;
    const rootClassNames = cx([styles.root, styles.published]);

    return (
      <Box direction="row" className={rootClassNames} justify="between" align="center">
        <Box direction="row" align="center" className={styles.text}>
          <Box margin={{ right: 'small' }}>
            <Heading weight="bold" level="4" margin="none">
              Live version
            </Heading>
          </Box>
          {publishedAt}
        </Box>
        {isPresent(publishedPath) && (
          <Anchor label="View on site" href={publishedPath} reverse icon={<Icon name="external" />} target="_blank" />
        )}
      </Box>
    );
  }

  renderUnpublishedStatus() {
    const { canCreateRequest, onRequestCreate, request, saving } = this.props;
    const rootClassNames = cx([styles.root, styles.unpublished]);
    const handleCreateRequest = isBlank(request) ? onRequestCreate : null;

    return (
      <Box direction="row" className={rootClassNames} justify="between" align="center">
        <Box direction="row" align="center" className={styles.text}>
          <Box margin={{ right: 'small' }}>
            <Heading weight="bold" level="4" margin="none">
              Doesn&#39;t have Live version
            </Heading>
          </Box>
        </Box>
        {isBlank(request) && (
          <Anchor
            label="Create Request"
            className={styles.createRequestLink}
            disabled={!canCreateRequest || saving}
            onClick={handleCreateRequest}
            reverse
            icon={<AddChapterIcon size="small" />}
            target="_blank"
          />
        )}
      </Box>
    );
  }

  render() {
    const { object } = this.props;

    return isPresent(object) ? this.renderPublishedStatus() : this.renderUnpublishedStatus();
  }
}

export default VersionStatusBlock;
