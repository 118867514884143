import { pick } from 'ramda';

export default {
  defaultAttributes(user) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      ...user,
    };
  },

  attributesToSubmit(user) {
    const propsToSend = ['firstName', 'lastName', 'email', 'password', 'passwordConfirmation'];

    return pick(propsToSend, user);
  },
};
