import React from 'react';
import PropTypes from 'prop-types';
import { fromISOtoFormattedDate } from 'utils/DateTime';

import styles from './CustomizedXAxisTick.module.css';

const DATE_FORMAT = 'ccc L/d';

const CustomizedXAxisTick = (props) => {
  const {
    x,
    y,
    payload: { value },
    height,
    barWidth,
    width: chartWidth,
    data,
  } = props;

  const barWidthWithPadding = chartWidth / data.length;
  const barPaddingWidth = (barWidthWithPadding - barWidth) / 2;
  const formattedDate = fromISOtoFormattedDate(value, DATE_FORMAT);

  return (
    <foreignObject x={x - Math.round(barWidth / 2) - barPaddingWidth} y={y} width={barWidthWithPadding} height={height}>
      <div className={styles.root}>{formattedDate}</div>
    </foreignObject>
  );
};

CustomizedXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default CustomizedXAxisTick;
