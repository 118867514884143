import React, { useState } from 'react';
import cn from 'clsx';

import { fromISOtoFormattedDate } from 'utils/DateTime';

import { Edit as EditIcon, Trash as TrashIcon } from 'grommet-icons';
import Button from 'components/Button';

import EditorNotePresenter from 'presenters/EditorNotePresenter';

import styles from './EditorNote.module.css';

const DATE_FORMAT = 'MM/dd/yyyy hh:mma';

const EditorNote = (props) => {
  const { editorNote, onUpdate, onDestroy, isDestroying } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleHide = () => setIsCollapsed(true);

  const handleShow = () => setIsCollapsed(false);

  const handleDestroy = () => onDestroy(EditorNotePresenter.id(editorNote));

  const handleUpdate = () => onUpdate(editorNote);

  const descriptionStyles = cn(styles.description, { [styles.collapsedDescription]: isCollapsed });

  const formattedDate = fromISOtoFormattedDate(EditorNotePresenter.createdAt(editorNote), DATE_FORMAT);

  return (
    <>
      <div className={styles.infoBlock}>
        <div>{formattedDate}</div>
        {EditorNotePresenter.isNew(editorNote) && <div className={styles.isNewLabel}>New</div>}
      </div>
      <div className={styles.container}>
        <div className={styles.contentAndActions}>
          <div className={descriptionStyles}>{EditorNotePresenter.description(editorNote)}</div>
          {EditorNotePresenter.canEdit(editorNote) && (
            <div className={styles.editActions}>
              <Button
                icon={<EditIcon />}
                className={styles.editActionButton}
                onClick={handleUpdate}
                disabled={isDestroying}
                plain
              />
              <Button
                icon={<TrashIcon />}
                className={styles.editActionButton}
                onClick={handleDestroy}
                disabled={isDestroying}
                plain
              />
            </div>
          )}
        </div>
        <div className={styles.collapseControl}>
          {isCollapsed ? (
            <button className={styles.collapseButton} onClick={handleShow}>
              Show
            </button>
          ) : (
            <button className={styles.collapseButton} onClick={handleHide}>
              Hide
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default EditorNote;
