import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Spinner from 'components/Spinner';

import ProfilesUpdatesStatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/ProfilesUpdatesStatisticsPresenter';

import styles from './ProfilesUpdatesStatistics.module.css';

export default class ProfilesUpdatesStatistics extends Component {
  static propTypes = {
    profilesUpdatesStatistics: ProfilesUpdatesStatisticsPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    loadProfilesUpdatesStatistics: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadProfilesUpdatesStatistics } = this.props;

    loadProfilesUpdatesStatistics();
  }

  renderStatistics() {
    const { profilesUpdatesStatistics } = this.props;

    return (
      <>
        <div className={styles.item}>
          <div className={styles.title}>This week:</div>
          <div className={styles.number}>
            {ProfilesUpdatesStatisticsPresenter.formattedThisWeek(profilesUpdatesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>This Month:</div>
          <div className={styles.number}>
            {ProfilesUpdatesStatisticsPresenter.formattedThisMonth(profilesUpdatesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Last 3 Months:</div>
          <div className={styles.number}>
            {ProfilesUpdatesStatisticsPresenter.formattedLastThreeMonths(profilesUpdatesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Last 12 Months:</div>
          <div className={styles.number}>
            {ProfilesUpdatesStatisticsPresenter.formattedLastYear(profilesUpdatesStatistics)}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { loading, profilesUpdatesStatistics } = this.props;

    return (
      <div>
        <h3 className={styles.header}>Number of Profile Updates</h3>
        {loading || isBlank(profilesUpdatesStatistics) ? <Spinner /> : this.renderStatistics()}
      </div>
    );
  }
}
