import { buildFilterParams } from 'utils/FilterParamsBuilder';
import AdminUserListConstants from './AdminUserListConstants';
import UsersRepository from 'repositories/admin/Companies/UsersRepository';
import ImpersonalizationRepository from 'repositories/admin/ImpersonalizationRepository';
import { addNotification } from 'containers/Notifications';

import Routes from 'routes';

export function loadUsers(companyId, page, filters) {
  return (dispatch) => {
    dispatch({ type: AdminUserListConstants.LOAD_ADMIN_COMPANY_USER_START });

    const params = { page, ...buildFilterParams(filters) };
    return UsersRepository.index(companyId, params).then(({ items, meta }) => {
      dispatch({
        type: AdminUserListConstants.LOAD_ADMIN_COMPANY_USER_SUCCESS,
        users: items,
        ...meta,
      });
    });
  };
}

export function filterUsers(companyId, filters) {
  return (dispatch) => {
    dispatch({ type: AdminUserListConstants.FILTER_ADMIN_COMPANY_USER_START, filters });

    const params = buildFilterParams(filters);
    return UsersRepository.index(companyId, params).then(({ items, meta }) => {
      dispatch({ type: AdminUserListConstants.FILTER_ADMIN_COMPANY_USER_SUCCESS, users: items, filters, ...meta });
    });
  };
}

export function createUser(companyId, params) {
  return (dispatch) => {
    dispatch({ type: AdminUserListConstants.CREATE_NEW_ADMIN_COMPANY_USER_START });

    return UsersRepository.create(companyId, params)
      .then(({ user }) => {
        dispatch({ type: AdminUserListConstants.CREATE_NEW_ADMIN_COMPANY_USER_SUCCESS, user });
        addNotification('User was successfully added', 'normal')(dispatch);
        return user;
      })
      .catch((errors) => {
        dispatch({ type: AdminUserListConstants.CREATE_NEW_ADMIN_COMPANY_USER_ERROR, errors });

        throw errors;
      });
  };
}

export function bulkDestroyUsers(companyId, params) {
  return (dispatch) =>
    UsersRepository.bulkDestroy(companyId, params).then(() => {
      dispatch({ type: AdminUserListConstants.BULK_DESTROY_ADMIN_COMPANY_USER_SUCCESS });
    });
}

export function impersonate(userId) {
  return () =>
    ImpersonalizationRepository.create(userId).then(() => {
      window.location = Routes.rootPath();
    });
}
