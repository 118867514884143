import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RelationshipsList from './ProfileRelationshipsList';
import * as actions from './ProfileRelationshipsListActions';

const mapStateToProps = (state) => ({
  loading: state.ProfileRelatioonshipsMappingRelationshipsListReducer.loading,
  relationships: state.ProfileRelatioonshipsMappingRelationshipsListReducer.relationships,
  nextPage: state.ProfileRelatioonshipsMappingRelationshipsListReducer.nextPage,
  totalCount: state.ProfileRelatioonshipsMappingRelationshipsListReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipsList);
