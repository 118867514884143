import { prop } from 'ramda';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import ProfileListPageConstants from './ProfileListPageConstants';
import ProfilesRepository from 'repositories/admin/ProfilesRepository';
import OrganizationsRepository from 'repositories/admin/OrganizationsRepository';

export function initializeProfileListPage(filters) {
  return { type: ProfileListPageConstants.INITIALIZE_PROFILE_LIST_PAGE, filters };
}

export function createProfile(params) {
  return (dispatch) => {
    dispatch({ type: ProfileListPageConstants.PROFILE_CREATE });

    return ProfilesRepository.create(params).then(({ profile }) => {
      dispatch({ type: ProfileListPageConstants.PROFILE_CREATE_SUCCESS, profile });

      return profile;
    });
  };
}

export function changeFilters(filters) {
  return { type: ProfileListPageConstants.PROFILES_CHANGE_FILTERS, filters };
}

export function searchOrganizations(filters) {
  const params = { ...buildFilterParams(filters), per: 30 };

  return () => OrganizationsRepository.index(params).then(({ items }) => items.map(prop('draft')));
}
