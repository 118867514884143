import { pick } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import { ADD_TO_PROJECT_STATE_EVENT } from 'presenters/Project/CsvItemPresenter';

export default {
  defaultAttributes(attributes) {
    return { ...attributes };
  },

  attributesToSubmit(csvItem) {
    const propsToSend = ['index', 'firstName', 'lastName', 'jobTitle', 'organizationName', 'stateEvent', 'profileId'];
    const profileId =
      csvItem.stateEvent === ADD_TO_PROJECT_STATE_EVENT && isPresent(csvItem.profile) ? csvItem.profile.id : null;

    return pick(propsToSend, { ...csvItem, profileId });
  },
};
