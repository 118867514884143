import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'ramda';
import { FormField, TextInput } from 'grommet';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import RawHtml from 'components/RawHtml';

import CsvItemPresenter from 'presenters/Project/CsvItemPresenter';

import styles from './EditForm.module.css';

class EditForm extends Component {
  constructor(props) {
    super(props);

    const { csvItem } = this.props;
    this.state = {
      form: pick(['firstName', 'lastName', 'jobTitle', 'organizationName'], csvItem),
      errors: {},
    };
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) => {
      this.setState({ form: { ...this.state.form, [fieldName]: value } });
    };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(form).catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  renderTextInput(fieldName, errorFieldName) {
    const {
      form: { [fieldName]: value },
      errors: { [errorFieldName]: error },
    } = this.state;

    return (
      <FormField error={isPresent(error) && error.join(', ')}>
        <TextInput value={value} onChange={this.handleTextFieldChange(fieldName)} />
      </FormField>
    );
  }

  render() {
    const { onCancel, isSaving } = this.props;
    const { errors } = this.state;

    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ width: '55px' }} />
            <th style={{ width: '140px' }} />
            <th style={{ width: '140px' }} />
            <th style={{ width: '140px' }} />
            <th style={{ width: '140px' }} />
            <th style={{ width: '140px' }} />
            <th style={{ width: '140px' }} />
          </tr>
        </thead>
        {isPresent(errors.base) && (
          <tr className={styles.danger}>
            <td />
            <RawHtml component="td" colSpan={6} innerHTML={errors.base.join(', ')} />
          </tr>
        )}
        <tr>
          <td />
          <td className={styles.input}>{this.renderTextInput('firstName', 'subjectFirstName')}</td>
          <td className={styles.input}>{this.renderTextInput('lastName', 'subjectLastName')}</td>
          <td className={styles.input}>{this.renderTextInput('jobTitle', 'subjectJobTitle')}</td>
          <td colSpan={3} className={styles.input}>
            <div className={styles.controls}>
              {this.renderTextInput('organizationName', 'subjectOrganizationName')}
              <Button disabled={isSaving} className={styles.button} label="Request" onClick={this.handleSubmit} />
              <Button disabled={isSaving} className={styles.button} label="Cancel" onClick={onCancel} />
            </div>
          </td>
        </tr>
      </table>
    );
  }
}

EditForm.propTypes = {
  csvItem: CsvItemPresenter.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default EditForm;
