import keyMirror from 'keymirror';

const CompanyUserRegistrationConstants = keyMirror({
  LOAD_REGISTRATION_COMPANY_START: null,
  LOAD_REGISTRATION_COMPANY_SUCCESS: null,
  LOAD_REGISTRATION_COMPANY_ERROR: null,

  CREATE_COMPANY_USER_START: null,
  CREATE_COMPANY_USER_SUCCESS: null,
  CREATE_COMPANY_USER_ERROR: null,
});

export default CompanyUserRegistrationConstants;
