import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProjectListPage from './ProjectListPage';
import * as actions from './ProjectListPageActions';
import { actions as backendNotificationsActions } from 'containers/UserPanel/containers/BackendNotifications'; // eslint-disable-line import/named
import { actions as subscriptionsActions } from 'containers/UserPanel/containers/SubscriptionsContainer'; // eslint-disable-line import/named
import { actions as sharesActions } from 'containers/UserPanel/containers/Project/containers/SharesContainer'; // eslint-disable-line import/named

const mapStateToProps = (state) => ({
  loading: state.ProjectListPageReducer.loading,
  projects: state.ProjectListPageReducer.projects,
  totalCount: state.ProjectListPageReducer.meta.totalCount,
  nextPage: state.ProjectListPageReducer.meta.nextPage,
  saving: state.ProjectListPageReducer.saving,
  canCreateProject: state.ProjectListPageReducer.meta.canCreateProject,
  filters: state.ProjectListPageReducer.filters,

  isSubscriptionSaving: state.SubscriptionsContainerReducer.isSaving,
});

const { markProjectsViewed } = backendNotificationsActions;
const { createSubscription, destroySubscription } = subscriptionsActions;
const { destroyProjectShare } = sharesActions;
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { markProjectsViewed, createSubscription, destroySubscription, destroyProjectShare, ...actions },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListPage);
