import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(twoFactorAuthentication) {
    const url = Routes.apiV1TwoFactorAuthenticationsPath();

    return FetchHelpers.post(url, { twoFactorAuthentication });
  },

  resendConfirmation(twoFactorAuthentication) {
    const url = Routes.resendConfirmationApiV1TwoFactorAuthenticationsPath();

    return FetchHelpers.post(url, { twoFactorAuthentication });
  },
};
