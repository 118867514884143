import {
  adminCompanyUsersMatchRestHooks,
  useAdminCompanyUsersMatchActions,
} from 'slices/admin/company/usersMatchSlice';
import { useFetchStatus } from 'utils/fetchStatusUtils';

const useUsersMatches = () => {
  const { usersMatch, createUsersMatch } = adminCompanyUsersMatchRestHooks.use();
  const actions = useAdminCompanyUsersMatchActions();

  return {
    usersMatches: usersMatch.item,
    usersMatchErrors: usersMatch.error?.errors,
    usersMatchCreatingStatus: useFetchStatus(usersMatch.creatingStatus),
    createUsersMatch,
    ...actions,
  };
};

export default useUsersMatches;
