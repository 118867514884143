import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import config from 'config';

import Spoiler from 'components/Spoiler';

import WorkPresenter from 'presenters/WorkPresenter';

const WorksList = (props) => {
  const { works } = props;

  const renderWork = (work) => {
    const className = cn({
      [config.changesHighlightsClassName]: WorkPresenter.isRecentlyUpdated(work),
      maskedText: WorkPresenter.isMasked(work),
    });

    return (
      <li className={className} key={work.id}>
        {WorkPresenter.fullJobTitle(work)}, {WorkPresenter.organizationName(work)}
        {` ${WorkPresenter.timeInterval(work)}`}
      </li>
    );
  };

  return (
    <section>
      <Spoiler header="Employment History">
        <ul>{works.map(renderWork)} </ul>
      </Spoiler>
    </section>
  );
};

WorksList.propTypes = {
  works: PropTypes.arrayOf(WorkPresenter.shape()).isRequired,
};

export default WorksList;
