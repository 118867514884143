import keyMirror from 'keymirror';

const SubscriptionConfigurationContainerConstants = keyMirror({
  UPDATE_SUBSCRIPTION_CONFIGURATION_START: null,
  UPDATE_SUBSCRIPTION_CONFIGURATION_SUCCESS: null,
  UPDATE_SUBSCRIPTION_CONFIGURATION_ERROR: null,

  LOAD_SUBSCRIPTION_CONFIGURATION_START: null,
  LOAD_SUBSCRIPTION_CONFIGURATION_SUCCESS: null,
  LOAD_SUBSCRIPTION_CONFIGURATION_ERROR: null,
});

export default SubscriptionConfigurationContainerConstants;
