import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import Routes from 'routes';
import withConfirmation from 'hoc/withConfirmation';
import { isBlank } from 'utils/HelperMethods';
import { useDebounce } from 'utils/debounceUtils';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import TagPresenter from 'presenters/TagPresenter.js';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import TagPolicy from 'policies/TagPolicy';

import PageHeader from 'components/PageHeader';
import Button from 'components/v2/Button';
import Link from 'components/v2/Link';
import Table from 'components/Table';
import Search from 'components/SearchInput';

import TagsRowCells from './components/TagsRowCells';
import NewTagModal from './components/NewTagModal';

import styles from './TagListPage.module.css';

const MAX_SEARCH_LENGTH = 40;

const TABLE_COLUMNS = [
  {
    name: 'tag',
    field: 'title',
  },
  {
    name: 'parent tag',
    field: 'parent',
  },
  {
    name: 'Populating Status',
    field: 'populating_state',
    isVisible: TagPolicy.canPopulateWithTag,
  },
  {
    name: 'Last Populating Completed At',
    field: 'lastPopulatingCompletedAt',
    isVisible: TagPolicy.canPopulateWithTag,
  },
  {
    name: 'Actions',
    field: '',
    isVisible: TagPolicy.canPopulateWithTag,
  },
];

const TagListPageComponent = (props) => {
  const {
    createTag,
    initializeTagsList,
    filters,
    searchTags,
    currentUser,
    filterTags,
    bulkDestroyTags,
    nextPage,
    loadTags,
    loading,
    populateTag,
    bulkPopulateTags,
    tags,
    populatingTagId,
    bulkPopulatingTags,
  } = props;

  const [showCreateTagPopup, setShowCreateTagPopup] = useState(false);
  const isAllTagsLoaded = isBlank(nextPage);

  useEffect(() => {
    initializeTagsList();
    loadTags({ page: 1, filters });
  }, []);

  const handleCreateNewTag = () => setShowCreateTagPopup(true);

  const handleCancelNewTag = () => setShowCreateTagPopup(false);

  const handleSave = (tag) =>
    createTag(tag).then(() => {
      handleCancelNewTag();
      initializeTagsList();
      loadTags({ page: 1, filters });
    });

  const handleTagSearch = (title) => searchTags({ titleCont: title, filters });

  const visibleTableColumns = () =>
    TABLE_COLUMNS.filter(({ isVisible }) => isBlank(isVisible) || isVisible(currentUser));

  const handleSort = (sortFields) => {
    filterTags({ ...filters, sortFields });
  };

  const handleFilter = (fieldName, value) => {
    filterTags({ ...filters, [fieldName]: value });
  };

  const handleFilterDebounced = useDebounce(handleFilter);

  const handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      handleFilterDebounced(fieldName, value);

  const handleDelete = (ids) => {
    bulkDestroyTags({ ids }).then(() => filterTags(filters));
  };

  const handleLoadMore = () => {
    if (!loading && !isAllTagsLoaded) {
      loadTags({ page: nextPage, filters });
    }
  };

  const handleTagClick = (tagId) => {
    if (TagPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminTagPath(tagId));
    }
  };

  const handlePopulateTag = (tag) => populateTag(TagPresenter.id(tag));

  const handleBulkPopulateTag = () =>
    bulkPopulateTags().then(() => {
      filterTags(filters);
    });

  return (
    <div>
      <PageHeader title="Tag List" />
      <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
        <Box width="medium" margin={{ right: 'small' }}>
          <Search placeholder="Search" onChange={handleFilterChange('TitleCont')} maxLength={MAX_SEARCH_LENGTH} />
        </Box>
        {TagPolicy.canAddElement(currentUser) && (
          <Box margin={{ right: 'small' }}>
            <Button onClick={handleCreateNewTag}>Add Tag</Button>
          </Box>
        )}
        {TagPolicy.canExportList(currentUser) && (
          <Box margin={{ right: 'small' }}>
            <Button
              component={Link}
              target="_blank"
              href={Routes.csvV1AdminTagsPath(buildFilterParams(filters))}
              variant="outlined"
            >
              <span className={styles.labelExportButton}>Export</span>
            </Button>
          </Box>
        )}
        {TagPolicy.canPopulateWithTag(currentUser) && (
          <Box margin={{ right: 'small' }}>
            <Button onClick={handleBulkPopulateTag} disabled={bulkPopulatingTags} variant="outlined">
              Populate Automatically All Tags
            </Button>
          </Box>
        )}
      </Box>

      <Table
        columns={visibleTableColumns()}
        sorts={filters.sortFields}
        rows={tags}
        rowCellsComponent={
          <TagsRowCells currentUser={currentUser} onPopulate={handlePopulateTag} populatingTagId={populatingTagId} />
        }
        rowsDeletable={TagPolicy.canDestroyElement(currentUser)}
        hasMore={!isAllTagsLoaded}
        onMore={handleLoadMore}
        onRowClick={handleTagClick}
        onDelete={handleDelete}
        onSort={handleSort}
      />

      {showCreateTagPopup && (
        <NewTagModal
          onClose={handleCancelNewTag}
          onSort={handleSort}
          onSave={handleSave}
          onTagSearch={handleTagSearch}
        />
      )}
    </div>
  );
};

TagListPageComponent.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  createTag: PropTypes.func.isRequired,
  loadTags: PropTypes.func.isRequired,
  searchTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(TagPresenter.shape()).isRequired,
  nextPage: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  bulkDestroyTags: PropTypes.func.isRequired,
  initializeTagsList: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
  filterTags: PropTypes.func.isRequired,
  populateTag: PropTypes.func.isRequired,
  populatingTagId: PropTypes.number,
  bulkPopulateTags: PropTypes.func.isRequired,
  bulkPopulatingTags: PropTypes.bool.isRequired,
};

const TagListPage = withConfirmation(TagListPageComponent, {
  populateTag: 'Are you sure want to populate tag automatically?',
});

export default TagListPage;
