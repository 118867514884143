import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

if (!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: (className) => this.className.baseVal.split(' ').indexOf(className) !== -1,
      };
    },
  });
}
