import SampleProfilesPageConstants from './SampleProfilesPageConstants';

const initialState = {
  loading: false,
  sampleProfiles: [],
};

function SampleProfilesPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SampleProfilesPageConstants.LOAD_CUSTOMER_SAMPLE_PROFILES_START: {
      return { ...state, loading: true };
    }
    case SampleProfilesPageConstants.LOAD_CUSTOMER_SAMPLE_PROFILES_SUCCESS: {
      const { sampleProfiles } = action;

      return { ...state, loading: false, sampleProfiles };
    }
    default:
      return state;
  }
}

export default SampleProfilesPageReducer;
