import React from 'react';
import cn from 'clsx';
import { Anchor as GrommetAnchor } from 'grommet';

import { isPresent } from 'utils/HelperMethods';

import styles from './Anchor.module.css';

const Anchor = (props) => {
  const { className, ...rest } = props;
  const classNames = cn({ [styles.root]: !isPresent(className), [className]: isPresent(className) });

  return <GrommetAnchor {...rest} className={classNames} />;
};

export default Anchor;
