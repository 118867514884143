import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Button from 'components/Button';
import { Close as CloseIcon, Checkmark as CheckmarkIcon } from 'grommet-icons';
import { Spinner as Spinning } from 'grommet';

import styles from './InputField.module.css';

class InputField extends Component {
  static propTypes = {
    title: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(...props) {
    super(...props);

    const { title } = this.props;
    this.state = { form: { title }, errors: null };
  }

  handleTitleSave = () => {
    const { onCancel, onUpdate } = this.props;
    const { form } = this.state;
    const { title } = form;

    onUpdate(title)
      .then(onCancel)
      .catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  handleFormFieldChange =
    (formField) =>
    ({ target: { value } }) =>
      this.setState({ ...this.state, form: { ...this.state.form, [formField]: value } });

  renderEditControls() {
    const { isSaving, onCancel } = this.props;

    if (isSaving) {
      return (
        <div className={styles.icon}>
          <Spinning size="small" />
        </div>
      );
    }

    return (
      <>
        <Button className={styles.icon} onClick={this.handleTitleSave}>
          <CheckmarkIcon size="small" />
        </Button>
        <Button className={styles.icon} onClick={onCancel}>
          <CloseIcon size="small" />
        </Button>
      </>
    );
  }

  render() {
    const { isSaving } = this.props;
    const { form, errors } = this.state;
    const className = cn(styles.field, { [styles.errors]: isPresent(errors) });

    return (
      <>
        <div className={styles.editableHeader}>
          <input
            className={className}
            disabled={isSaving}
            type="text"
            value={form.title}
            onChange={this.handleFormFieldChange('title')}
            size={form.title.length}
          />
          {this.renderEditControls()}
        </div>
        {isPresent(errors) && isPresent(errors.name) && <div className={styles.error}>{errors.name.join(', ')}</div>}
      </>
    );
  }
}

export default InputField;
