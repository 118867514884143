import React from 'react';
import PropTypes from 'prop-types';

import { Box, TextInput } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Select from 'components/Select';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import RequestPresenter, { CANCELATION_REASONS } from 'presenters/RequestPresenter';
import RequestPolicy from 'policies/RequestPolicy';

const CANCELATION_REASON_OPTIONS = CANCELATION_REASONS.map(({ key, title }) => ({ label: title, value: key }));

export default class CancelRequestPopup extends React.Component {
  state = { form: { cancelationReason: '' }, errors: null };

  handlecancelationReasonChange = (value) => {
    const { form } = this.state;

    this.setState({ form: { ...form, cancelationReason: value, cancelationConfirmation: '' } });
  };

  handleFieldChange =
    (field) =>
    ({ target: { value } }) => {
      const { form } = this.state;

      this.setState({ form: { ...form, [field]: value } });
    };

  handleSubmit = () => {
    const { onSubmit, request } = this.props;
    const {
      form: { cancelationReason, cancelationConfirmation },
    } = this.state;

    onSubmit({ ...request, stateEvent: 'cancel', cancelationReason, cancelationConfirmation }).catch(({ errors }) =>
      this.setState({ errors }),
    );
  };

  render() {
    const { onClose, saving } = this.props;
    const { form, errors } = this.state;

    return (
      <Modal header="Cancel request" onClose={onClose} size="small" overflow>
        <Select
          placeholder="Cancellation Reason"
          errors={errors && errors.cancelationReason}
          selectedOptionValue={form.cancelationReason}
          options={CANCELATION_REASON_OPTIONS}
          onValueChange={this.handlecancelationReasonChange}
        />

        {RequestPolicy.needCancelationConfirmationField(form) && (
          <VerticalFormFieldWithBottomErrors
            label="Add additional Details"
            errors={errors && errors.cancelationConfirmation}
            htmlFor="cancelationConfirmation"
          >
            <TextInput
              value={form.cancelationConfirmation}
              onChange={this.handleFieldChange('cancelationConfirmation')}
              id="cancelationConfirmation"
            />
          </VerticalFormFieldWithBottomErrors>
        )}

        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}

CancelRequestPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  request: RequestPresenter.shape(),
  saving: PropTypes.bool.isRequired,
};
