import React, { useEffect } from 'react';

import { useAlert } from 'hooks';
import { useAdminCompanies, useAdminCompanyLegalSetting } from 'hooks/admin';

import LegalSettingForm from 'forms/company/LegalSettingForm';

import Spinner from 'components/Spinner';
import Form from 'components/v2/Form';

import EditForm from './components/EditForm';

const LegalSettingsTab = () => {
  const alert = useAlert();
  const { company } = useAdminCompanies();
  const { legalSettingLoadingStatus, loadLegalSetting, updateLegalSetting, legalSetting } =
    useAdminCompanyLegalSetting();

  const handleLegalSettingUpdate = async (attributes) => {
    await updateLegalSetting(company.id, LegalSettingForm.attributesToSubmit(attributes));

    alert.successAction('Legal Settings successfully updated');
  };

  useEffect(() => {
    loadLegalSetting(company.id);
  }, []);

  if (legalSettingLoadingStatus.isPending) {
    return <Spinner />;
  }

  return (
    <Form
      component={EditForm}
      enableReinitialize
      initialValues={LegalSettingForm.defaultAttributes(legalSetting)}
      onSubmit={handleLegalSettingUpdate}
    />
  );
};

export default LegalSettingsTab;
