import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import config from 'config';

import CompanyPresenter from 'presenters/CompanyPresenter';
import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';

import { formattedDate } from 'utils/DateTime';

export const ADMIN_COMPANY_USER_CSV_EXPORT = 'ExportJob::Admin::Company::UsersCsvExportJob';
export const ADMIN_USER_CSV_EXPORT = 'ExportJob::Admin::UsersCsvExportJob';

export const ADMIN_ROLES = ['admin', 'editor', 'researcher', 'research_editor', 'research_manager'];

export const ROLES = [
  {
    key: 'custom_subscriber',
    title: 'Customer Account',
  },
  {
    key: 'demo_account',
    title: 'Demo Account',
  },
  {
    key: 'pilot_account',
    title: 'Pilot Account',
  },
  {
    key: 'download_limited',
    title: 'Trial Account',
  },
  {
    key: 'research_manager',
    title: 'Research Manager',
  },
  {
    key: 'research_editor',
    title: 'Freelance Contractor',
  },
  {
    key: 'researcher',
    title: 'Researcher',
  },
  {
    key: 'editor',
    title: 'Editor',
  },
  {
    key: 'admin',
    title: 'Super Admin',
  },
  {
    key: 'individual_account',
    title: 'Individual Account',
  },
];

export const STATES = [
  {
    key: 'active',
    title: 'Active',
    event: 'activate',
  },
  {
    key: 'disabled',
    title: 'Disabled',
    event: 'disable',
  },
  {
    key: 'locked',
    title: 'Locked',
    event: 'lockup',
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    state: PropTypes.string,
    createdAt: PropTypes.string,
    company: CompanyPresenter.shape(),
    subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    corporateViewAvailable: PropTypes.bool,
    exportSearchResultInCsvAvailable: PropTypes.bool,
    viewAndDownloadEventsCount: PropTypes.number,
    publishedRequestEventsCount: PropTypes.number,
    signInEventsCount: PropTypes.number,
    projectsAvailable: PropTypes.bool,
    projectsSharingAvailable: PropTypes.bool,
    isActiveSubscription: PropTypes.bool,
    teamProductivityViewAvailable: PropTypes.bool,
    companyDepartment: CompanyDepartmentPresenter.shape(),
    avatarUrl: PropTypes.string,
    databaseOnly: PropTypes.bool,
    canRequestProfile: PropTypes.bool,
    stateEvents: PropTypes.arrayOf(PropTypes.string),
    remainingRequestsNumber: PropTypes.number,
  },
  {
    fullName(u) {
      return `${this.firstName(u) || ''} ${this.lastName(u) || ''}`;
    },
    stateName(u) {
      const state = this.state(u);
      return STATES.find((r) => r.key === state).title;
    },
    roleTitle(u) {
      const role = this.role(u);
      return ROLES.find((r) => r.key === role).title;
    },
    formattedCreatedAt(u) {
      return formattedDate(this.createdAt(u));
    },
    isActive(u) {
      return this.state(u) === 'active';
    },
    isAdmin(u) {
      return this.role(u) === 'admin';
    },
    isEditor(u) {
      return this.role(u) === 'editor';
    },
    isResearcher(u) {
      return this.role(u) === 'researcher';
    },
    isResearchEditor(u) {
      return this.role(u) === 'research_editor';
    },
    isResearchManager(u) {
      return this.role(u) === 'research_manager';
    },
    isDownloadLimited(u) {
      return this.role(u) === 'download_limited';
    },
    isCustomerAccount(u) {
      return this.role(u) === 'custom_subscriber';
    },
    isPilotAccount(u) {
      return this.role(u) === 'pilot_account';
    },
    isDemoAccount(u) {
      return this.role(u) === 'demo_account';
    },
    isIndividualAccount(u) {
      return this.role(u) === 'individual_account';
    },
    managerEmail(u) {
      const company = this.company(u);
      const manager = CompanyPresenter.manager(company);
      return this.email(manager) || config.app.defaultAccountManagerEmail;
    },
  },
);
