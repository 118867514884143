import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import GeographyPresenter from './AudienceInsights/GeographyPresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';

const TOP_LIST_ITEM_SHAPE = PropTypes.shape({
  title: PropTypes.string,
  id: PropTypes.number,
});

export default new Presenter(
  {
    organizationsCount: PropTypes.number,
    profilesCount: PropTypes.number,
    genders: PropTypes.arrayOf(
      PropTypes.shape({
        percentOfTotal: PropTypes.number,
        profilesCount: PropTypes.number,
        title: PropTypes.string,
      }),
    ),
    workGeography: GeographyPresenter.shape(),
    originGeography: GeographyPresenter.shape(),
    tops: PropTypes.shape({
      interests: PropTypes.arrayOf(TOP_LIST_ITEM_SHAPE),
      organizations: PropTypes.arrayOf(TOP_LIST_ITEM_SHAPE),
      tags: PropTypes.arrayOf(TOP_LIST_ITEM_SHAPE),
    }),
    mostConnectedExecutives: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
  },
  {},
);
