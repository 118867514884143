import { keys, propOr } from 'ramda';

export const isFunction = (func) => typeof func === 'function';

function getValuePath(fieldName) {
  return fieldName.replace(/\[(\d+)\]/g, '.$1').split('.');
}

function getErrorPath(fieldName) {
  return this.getValuePath(fieldName).map((itemPathPart) =>
    this.api ? itemPathPart.replace(/Attributes$/, '') : itemPathPart,
  );
}

function clearFieldError(fieldName) {
  this.changeFieldError(fieldName, null);
}

function changeFieldError(fieldName, error) {
  const errorsPath = this.getErrorPath(fieldName).join('.');
  this.setFieldError(errorsPath, error);
}

function nestedCollection(fieldName) {
  return propOr([], this.getValuePath(fieldName), this.values);
}

function nestedCollectionErrors(fieldName) {
  return propOr([], this.getErrorPath(fieldName), this.errors);
}

function onFieldChange(fieldName, value) {
  this.setFieldValue(fieldName, value);
  this.clearFieldError(fieldName);
}

function onFieldsChange(newValues) {
  this.setValues({ ...this.values, ...newValues });
  keys(newValues).forEach(this.clearFieldError);
}

async function onSubmit() {
  try {
    await this.submitForm();

    return true;
  } catch ({ errors }) {
    this.setErrors(errors || {});

    return false;
  }
}

export const extendFormProps = (api, formProps) => {
  const form = { api, ...formProps };

  form.onSubmit = onSubmit.bind(form);
  form.onReset = formProps.handleReset;
  form.getValuePath = getValuePath.bind(form);
  form.getErrorPath = getErrorPath.bind(form);
  form.clearFieldError = clearFieldError.bind(form);
  form.changeFieldError = changeFieldError.bind(form);
  form.nestedCollection = nestedCollection.bind(form);
  form.nestedCollectionErrors = nestedCollectionErrors.bind(form);
  form.onFieldChange = onFieldChange.bind(form);
  form.onFieldsChange = onFieldsChange.bind(form);

  return form;
};
