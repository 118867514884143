import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { values } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import { FILTERS } from 'forms/ProfileFilteredSearchForm';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    filters: PropTypes.shape({}),
    significantlyUpdatedProfilesTotalCount: PropTypes.number,
    significantlyUpdatedProfilesUpdatedAt: PropTypes.string,
    newToRoleProfilesTotalCount: PropTypes.number,
    newToRoleProfilesUpdatedAt: PropTypes.string,
    addedProfilesTotalCount: PropTypes.number,
    addedProfilesUpdatedAt: PropTypes.string,
    canDestroy: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    isSample: PropTypes.bool,
    subscriptionId: PropTypes.number,
  },
  {
    filtersCount(savedSearch) {
      const filters = this.filters(savedSearch);
      const activeFiltersKeys = values(FILTERS).filter((key) => isPresent(filters[key]));

      return activeFiltersKeys.length;
    },
  },
);
