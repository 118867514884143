import keyMirror from 'keymirror';

const AuthenticatorConstants = keyMirror({
  LOAD_CURRENT_USER_START: null,
  LOAD_CURRENT_USER_SUCCESS: null,
  LOAD_CURRENT_USER_ERROR: null,

  RELOAD_CURRENT_USER_START: null,
  RELOAD_CURRENT_USER_SUCCESS: null,
  RELOAD_CURRENT_USER_ERROR: null,

  UPDATE_CURRENT_USER_START: null,
  UPDATE_CURRENT_USER_SUCCESS: null,
  UPDATE_CURRENT_USER_ERROR: null,
});

export default AuthenticatorConstants;
