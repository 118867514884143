import CountryPageConstants from './CountryPageConstants';

const initialState = {
  loading: false,
  saving: false,
  country: null,
};

function CountryPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CountryPageConstants.LOAD_COUNTRY_START: {
      return { ...state, loading: true };
    }
    case CountryPageConstants.LOAD_COUNTRY_SUCCESS: {
      return { ...state, country: action.country, loading: false };
    }
    case CountryPageConstants.UPDATE_COUNTRY_START: {
      return { ...state, saving: true };
    }
    case CountryPageConstants.UPDATE_COUNTRY_SUCCESS: {
      return { ...state, country: action.country, saving: false };
    }
    case CountryPageConstants.UPDATE_COUNTRY_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default CountryPageReducer;
