import { clientTargetingSearchDirectorBoardsRestHooks } from 'slices/client/targetingSearch/directorBoardsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useDirectorBoards = () => {
  const { targetingSearchDirectorBoards, loadTargetingSearchDirectorBoards } =
    clientTargetingSearchDirectorBoardsRestHooks.use();

  return {
    targetingSearchDirectorBoards: targetingSearchDirectorBoards.items,
    targetingSearchDirectorBoardsLoadingStatus: useFetchStatus(targetingSearchDirectorBoards.loadingStatus),
    targetingSearchDirectorBoardsMeta: targetingSearchDirectorBoards.meta,
    loadTargetingSearchDirectorBoards,
  };
};

export default useDirectorBoards;
