import { pick, propOr, defaultTo } from 'ramda';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

export default {
  defaultAttributes(organization = {}) {
    const defaultAttributes = {
      name: '',
      ticker: '',
      fortuneRank: '',
      ftseRank: '',
      corporateDuns: '',
      boardOfDirectorsListUrl: '',
      companyLeadershipPage: '',
      global2000Rank: '',
    };

    return {
      ...defaultAttributes,
      ...organization,
      name: defaultTo('', organization.name),
      companyLeadershipPage: defaultTo('', organization.companyLeadershipPage),
      boardOfDirectorsListUrl: defaultTo('', organization.boardOfDirectorsListUrl),
    };
  },

  attributesToSubmit(organization) {
    const propsToSend = [
      'name',
      'ticker',
      'ricCode',
      'passwordConfirmation',
      'fortuneRank',
      'ftseRank',
      'corporateDuns',
      'emailDomains',
      'boardOfDirectorsListUrl',
      'companyLeadershipPage',
      'global2000Rank',
    ];
    const industry = OrganizationPresenter.industry(organization);
    const naicsIndustryCode = OrganizationPresenter.naicsIndustryCode(organization);

    return {
      ...pick(propsToSend, organization),
      industryId: propOr(null, 'id', industry),
      naicsIndustryCodeId: propOr(null, 'id', naicsIndustryCode),
    };
  },
};
