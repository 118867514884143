import React, { useEffect, useState } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose, isEmpty, isNil } from 'ramda';

import { isBlank, isPresent } from 'utils/HelperMethods';
import { buildParams, parseLocationToParams } from 'utils/UrlHelper';
import { logChangeUserPanelSearchProfilesFilter } from 'utils/amplitude';

import Tooltip from 'components/Tooltip';
import BaseFilters from './components/BaseFilters';
import Profiles from './components/Profiles';
import AnchorLink from 'components/grommet/AnchorLink';
import Sidebar, { FILTERS_TAB_INDEX, SAVED_SEARCHES_TAB_INDEX } from './components/Sidebar';
import { DEFAULT_SORT_OPTION } from 'containers/UserPanel/components/SearchSortSelect';

import Spinner from 'components/Spinner';
import BoardexPowered from 'components/v2/BoardexPowered';

import NumberPresenter from 'presenters/NumberPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import SavedSearchPresenter from 'presenters/SavedSearchPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import UserPresenter from 'presenters/UserPresenter';

import FilterForm, { humanFilterName } from 'forms/ProfileFilteredSearchForm';
import { FILTERS } from 'forms/ProfileFilteredSearchForm';

import styles from './Search.module.css';

import Routes from 'routes';

import useClientSearch from 'hooks/client/useSearch';

const PER_PAGE = 100;
const MAX_PAGE = 100;

const getFiltersFromQueryString = (location) => {
  const queryParams = parseLocationToParams(location);

  // handle breaking change fortune values
  const { [FILTERS.CURRENT_ORGANIZATION_FORTUNE_RANK_LTE]: currentOrganizationFortuneRankLteFilterValue } = queryParams;
  const oldFortuneValues = ['100', '250', '500'];
  if (isEmpty(currentOrganizationFortuneRankLteFilterValue) || isNil(currentOrganizationFortuneRankLteFilterValue)) {
    return FilterForm.defaultAttributes({ sort: DEFAULT_SORT_OPTION.value, ...(queryParams || {}), per: PER_PAGE });
  }

  const value = currentOrganizationFortuneRankLteFilterValue[0];
  const isOldValue = oldFortuneValues.includes(value);
  if (isOldValue) {
    queryParams[FILTERS.CURRENT_ORGANIZATION_RANK_LTE] = [`fortune_${value}`];
  }

  return FilterForm.defaultAttributes({ sort: DEFAULT_SORT_OPTION.value, ...(queryParams || {}), per: PER_PAGE });
};

const getActiveTabIndex = (pathname) => {
  const savedSearchesPathMatch = matchPath(pathname, { path: Routes.savedSearchesPath() });

  return !savedSearchesPathMatch ? FILTERS_TAB_INDEX : SAVED_SEARCHES_TAB_INDEX;
};

const buildTabLocation = (tabIndex, filters, openedSavedSearchId) => {
  if (SAVED_SEARCHES_TAB_INDEX === tabIndex) {
    if (isPresent(openedSavedSearchId)) {
      return { pathname: Routes.savedSearchPath(openedSavedSearchId) };
    }

    return { pathname: Routes.savedSearchesPath() };
  }

  const search = buildParams(FilterForm.queryParams(filters));

  return { pathname: Routes.searchPath(), search };
};

const Search = (props) => {
  const { backgroundExport, currentUser, history, match } = props;
  const { location } = history;
  const { savedSearchId } = match.params;

  const {
    isExecutivesLoading,
    executives,
    executivesMeta,

    isBoardMembersLoading,
    boardMembers,
    boardMembersMeta,

    isSavedSearchLoading,
    isProjectSaving,
    createProject,
    filterBoardMembers,
    filterExecutives,
    loadSavedSearch,
    loadFilterOptions,

    relsci,
    loadIdentifiers,
  } = useClientSearch();

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [filtersAutocomplete, setFiltersAutocomplete] = useState({});
  const [openedSavedSearch, setOpenedSavedSearch] = useState();
  const [filters, setFilters] = useState(getFiltersFromQueryString(location));

  const activeTabIndex = getActiveTabIndex(location.pathname);
  const isSavedSearchOpened = isPresent(savedSearchId);

  const company = UserPresenter.company(currentUser);
  const customBxDomain = CompanyPresenter.customBxDomain(company);
  const bxLink = Routes.boardexSearchUrl(customBxDomain);

  const handleSidebarClose = () => setSidebarOpen(false);

  const handleSidebarOpen = () => setSidebarOpen(true);

  const handleProfilesFilter = (newFilters) => {
    setFilters(newFilters);

    return Promise.all([filterExecutives(newFilters), filterBoardMembers({ ...newFilters, page: 1 })]).then(
      ([{ autocomplete, ids }]) => {
        setFiltersAutocomplete(autocomplete);
        return loadIdentifiers({ ids, content: 'search' });
      },
    );
  };

  const handleFilterOptionsLoad = (filter) => (value) =>
    loadFilterOptions(filter, value, filters).then((response) => response[filter] || []);

  const handleFiltersReset = () => {
    const newFilters = FilterForm.defaultAttributes({ sort: DEFAULT_SORT_OPTION.value, page: 1 });

    handleProfilesFilter(newFilters);
  };

  const handleUpdateLocation = (tabIndex, newFilters, newSavedSearchId) => {
    const newLocation = buildTabLocation(tabIndex, newFilters, newSavedSearchId);

    history.push(newLocation);
  };

  const handleSortChange = ({ value: sort }) => {
    const newFilters = { ...filters, sort, page: 1 };

    handleProfilesFilter(newFilters);
    handleUpdateLocation(activeTabIndex, newFilters, savedSearchId);
  };

  const handleSavedSearchOpen = (savedSearch, additonalFilters = {}) => {
    const newFilters = FilterForm.defaultAttributes({
      ...SavedSearchPresenter.filters(savedSearch),
      ...additonalFilters,
      sort: DEFAULT_SORT_OPTION.value,
      per: PER_PAGE,
    });

    handleUpdateLocation(SAVED_SEARCHES_TAB_INDEX, newFilters, savedSearch.id);
    setOpenedSavedSearch(savedSearch);
    handleProfilesFilter(newFilters);
  };

  const handleOpenedSavedSearchClose = () => {
    handleFiltersReset();
    handleUpdateLocation(activeTabIndex, {}, null);
    setOpenedSavedSearch(null);
  };

  const handleScopeFilterChange = (scopeFilterDate, scopeFilterValue, isChanged) => {
    const newFilters = { ...filters, scopeFilterDate, scopeFilterValue, page: isChanged ? 1 : filters.page };

    if (isChanged) {
      handleProfilesFilter(newFilters);
    } else {
      setFilters(newFilters);
    }

    handleUpdateLocation(activeTabIndex, newFilters, savedSearchId);
  };

  const handleBoardMembersPageLoad = (page) =>
    filterBoardMembers({ ...filters, page }).then(({ ids }) => loadIdentifiers({ ids, content: 'search' }));

  const handleExecutivesPageLoad = (page) => {
    const newFilters = { ...filters, page };

    setFilters(newFilters);
    handleUpdateLocation(activeTabIndex, newFilters, savedSearchId);
    return filterExecutives(newFilters).then(({ autocomplete, ids }) => {
      setFiltersAutocomplete(autocomplete);
      return loadIdentifiers({ ids, content: 'search' });
    });
  };

  const handleFilterChange = (filter) => (value) => {
    const newFilters = { ...filters, [filter]: value, page: 1 };

    logChangeUserPanelSearchProfilesFilter(humanFilterName(filter));
    setOpenedSavedSearch(null);
    handleProfilesFilter(newFilters);
    handleUpdateLocation(FILTERS_TAB_INDEX, newFilters, null);
  };

  const handleSidebarTabChange = (tabIndex) => handleUpdateLocation(tabIndex, filters, savedSearchId);

  useEffect(() => {
    if (isSavedSearchOpened) {
      loadSavedSearch(savedSearchId).then((savedSearch) =>
        handleSavedSearchOpen(savedSearch, FilterForm.activeScopeFilter(filters)),
      );
    } else {
      handleProfilesFilter(filters);
    }
  }, []);

  useEffect(() => {
    if (isPresent(openedSavedSearch) && !isSavedSearchOpened) {
      handleOpenedSavedSearchClose();
    }
  }, [savedSearchId]);

  const { totalCount: filteredProfilesTotalCount, availableProfilesTotalCount } = executivesMeta;
  const availableProfilesCount = CurrentUserPresenter.hasLimitedAccess(currentUser)
    ? availableProfilesTotalCount
    : filteredProfilesTotalCount;

  const tooltipText = () => (
    <span>
      Your limited access subscription gives you access to a subset of our database.{' '}
      <AnchorLink to={Routes.userAccountPath()} className={styles.tooltipAnchor}>
        View your access criteria here.
      </AnchorLink>
    </span>
  );

  if (isSavedSearchOpened && (isSavedSearchLoading || isBlank(openedSavedSearch))) {
    return <Spinner />;
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.side}>
          <Sidebar
            activeScopeFilter={FilterForm.activeScopeFilter(filters)}
            activeTabIndex={activeTabIndex}
            filtersAutocomplete={filtersAutocomplete}
            currentUser={currentUser}
            isLoading={isExecutivesLoading || isBoardMembersLoading}
            isOpen={isSidebarOpen}
            isProjectSaving={isProjectSaving}
            isSavedSearchLoading={isSavedSearchLoading}
            onClose={handleSidebarClose}
            onFilterChange={handleFilterChange}
            onFilterOptionsLoad={handleFilterOptionsLoad}
            onFiltersReset={handleFiltersReset}
            onOpenedSavedSearchClose={handleOpenedSavedSearchClose}
            onProjectCreate={createProject}
            onSavedSearchOpen={handleSavedSearchOpen}
            onScopeFilterChange={handleScopeFilterChange}
            onSortChange={handleSortChange}
            onTabChange={handleSidebarTabChange}
            openedSavedSearch={openedSavedSearch}
            filters={filters}
            totalCount={executivesMeta.totalCount}
          />
        </div>
        <div className={styles.main}>
          <div className={styles.header}>
            <h3 className={styles.title}>
              Profiles{' '}
              {!isExecutivesLoading && (
                <span className={styles.counter}>{NumberPresenter.withDelim(availableProfilesCount)}</span>
              )}
            </h3>
            <div className={styles.boardexPowered}>
              <BoardexPowered link={bxLink} text="Uncover more executives that match your search" />
            </div>
          </div>
          {CurrentUserPresenter.hasLimitedAccess(currentUser) && (
            <div className={styles.totalAvailableProfilesBlock}>
              <div className={styles.totalAvailableProfilesText}>
                Total available{' '}
                {!isExecutivesLoading && (
                  <strong className={styles.counter}>{NumberPresenter.withDelim(filteredProfilesTotalCount)}</strong>
                )}{' '}
                profiles
              </div>
              <Tooltip text={tooltipText} overlayStyle={{ width: 200, wordBreak: 'break-word' }}>
                <span className={styles.toolTipName}>Learn more</span>
              </Tooltip>
            </div>
          )}

          <BaseFilters
            filters={filters}
            onSearchQueryChange={handleFilterChange('query')}
            onSortChange={handleSortChange}
            onScopeFilterChange={handleScopeFilterChange}
            onSidebarOpen={handleSidebarOpen}
            bxLink={bxLink}
          />

          <Profiles
            boardMembers={boardMembers}
            boardMembersMeta={boardMembersMeta}
            currentUser={currentUser}
            executives={executives}
            executivesMeta={executivesMeta}
            filters={filters}
            isBoardMembersLoading={isBoardMembersLoading}
            isExecutivesLoading={isExecutivesLoading}
            maxPage={MAX_PAGE}
            onBackgroundExport={backgroundExport}
            onBoardMembersPageLoad={handleBoardMembersPageLoad}
            onExecutivesPageLoad={handleExecutivesPageLoad}
            perPage={PER_PAGE}
            relsci={relsci}
          />
        </div>
      </div>
    </div>
  );
};

Search.propTypes = {
  backgroundExport: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
  history: PropTypes.shape(),
  match: PropTypes.shape({ params: PropTypes.shape({ savedSearchId: PropTypes.string }).isRequired }).isRequired,
};

export default compose(withRouter)(Search);
