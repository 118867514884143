import InterestPageConstants from './InterestPageConstants';

const initialState = {
  loading: false,
  saving: false,
  interest: null,
};

function InterestPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case InterestPageConstants.LOAD_INTEREST_START: {
      return { ...state, loading: true };
    }
    case InterestPageConstants.LOAD_INTEREST_SUCCESS: {
      return { ...state, interest: action.interest, loading: false };
    }
    case InterestPageConstants.UPDATE_INTEREST_START: {
      return { ...state, saving: true };
    }
    case InterestPageConstants.UPDATE_INTEREST_SUCCESS: {
      return { ...state, interest: action.interest, saving: false };
    }
    case InterestPageConstants.UPDATE_INTEREST_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default InterestPageReducer;
