import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'grommet';

import styles from './MappingInformation.module.css';

export default class MappingInformation extends Component {
  static propTypes = {
    information: PropTypes.shape().isRequired,
    onProjectCreate: PropTypes.func.isRequired,
  };

  handleWindowClose = () => window.close();

  render() {
    const { information, onProjectCreate } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>This sample relationship map shows:</div>
        <ul className={styles.description}>
          {information.colleagueProfilesCount > 0 && (
            <li>
              Connections to <b>{information.colleagueProfilesCount} of your colleagues</b>
            </li>
          )}
          {information.recentlyViewedProfilesCount > 0 && (
            <li>
              Connections to <b>{information.recentlyViewedProfilesCount} of your recent searches</b>
            </li>
          )}
          {information.fromTopOrganizationsProfilesCount > 0 && (
            <li>
              Connections to{' '}
              <b>{information.fromTopOrganizationsProfilesCount} Fortune 500 executives in similar roles</b>
            </li>
          )}
        </ul>
        <div>
          To continue,{' '}
          <Anchor
            className={styles.link}
            onClick={onProjectCreate}
            target="_blank"
            label="save this group as a project"
          />{' '}
          or <Anchor className={styles.link} onClick={this.handleWindowClose} label="close window" />.
        </div>
      </div>
    );
  }
}
