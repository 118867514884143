import React from 'react';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

const ProfileRowCells = (props) => {
  const { row: profile } = props;
  const primaryCurrentWork = ProfilePresenter.primaryCurrentWork(profile);

  return (
    <>
      <td>{ProfilePresenter.lastNameWithFirstName(profile)}</td>
      <td>{primaryCurrentWork && WorkPresenter.organizationNameWithState(primaryCurrentWork)}</td>
      <td>{primaryCurrentWork && WorkPresenter.fullJobTitle(primaryCurrentWork)}</td>
    </>
  );
};

ProfileRowCells.propTypes = {
  row: ProfilePresenter.shape(),
};

export default ProfileRowCells;
