import React from 'react';
import Routes from 'routes';

import RelationshipPresenter from 'presenters/RelationshipPresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import WorkSearchPresenter from 'presenters/WorkSearchPresenter';
import ProfilePresenter from 'presenters/ProfileSearchPresenter';

import { Anchor } from 'grommet';
import ProfileAvatar from 'components/ProfileAvatar';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';

import Fact from './components/Fact';

import styles from './Relationship.module.css';

const GREEN_RGB_COLOR = '0, 206, 52';
const GRAY_COLOR = '#DEE0E3';

const Relationship = (props) => {
  const { relationship } = props;

  const strength = RelationshipPresenter.strength(relationship);

  const itemWidth = (value) => value * 50;

  const colorScale = (value) => {
    switch (true) {
      case value === 0:
        return GRAY_COLOR;

      case value <= 0.2:
        return `rgba(${GREEN_RGB_COLOR}, 0.2)`;

      default:
        return `rgba(${GREEN_RGB_COLOR}, ${value})`;
    }
  };

  const renderProfilePhoto = (profile) => (
    <div key={ProfileSearchPresenter.id(profile)} className={styles.item} style={{ width: `${itemWidth(strength)}%` }}>
      <Anchor animateIcon={false} href={Routes.profilePath(ProfileSearchPresenter.slug(profile))} target="_blank">
        <ProfileAvatar
          size="50"
          src={ProfileSearchPresenter.photoUrl(profile)}
          style={{ borderColor: colorScale(strength) }}
        />
      </Anchor>
    </div>
  );

  const renderProfileCurrentWork = (currentWork) => (
    <div key={WorkSearchPresenter.id(currentWork)} className={styles.jobTitle}>
      <span>{WorkSearchPresenter.jobTitle(currentWork)}</span>
      <span>{WorkSearchPresenter.organizationName(currentWork)}</span>
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.userPhotos}>
        <div className={styles.line} style={{ backgroundColor: colorScale(strength) }} />
        <div> {RelationshipPresenter.profiles(relationship).map(renderProfilePhoto)} </div>
      </div>

      <div className={styles.names}>
        {RelationshipPresenter.profiles(relationship).map((profile) => (
          <div
            key={ProfileSearchPresenter.id(profile)}
            className={styles.name}
            title={ProfileSearchPresenter.fullName(profile)}
          >
            <Anchor animateIcon={false} href={Routes.profilePath(ProfileSearchPresenter.slug(profile))} target="_blank">
              {ProfileSearchPresenter.fullName(profile)}
            </Anchor>
            {ProfileSearchPresenter.currentWorks(profile).map(renderProfileCurrentWork)}
            <div className={styles.profileLabels}>
              {ProfilePresenter.isNewToRole(profile) && (
                <div>
                  <NewToRoleBadge />
                </div>
              )}

              {ProfilePresenter.isMemberFortune500(profile) && (
                <div>
                  <Fortune500BoardMemberBadge />
                </div>
              )}
              {ProfilePresenter.isMemberFtse100(profile) && (
                <div>
                  <Ftse100BoardMemberBadge />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {RelationshipPresenter.facts(relationship).map((fact, index, facts) => (
        <Fact key={index} fact={fact} index={index} facts={facts} />
      ))}
    </div>
  );
};

Relationship.propTypes = {
  relationship: RelationshipPresenter.shape().isRequired,
};

export default Relationship;
