import React, { useState } from 'react';
import { CheckBox } from 'grommet';

import { Field, DatePicker } from 'components/v2/Form';
import Select from 'components/Select';
import { TIME_PERIODS, DAY_TIME_PERIOD_VALUE } from 'presenters/TimePeriodPresenter';
import { currentDateTime, currentIsoDateTime } from 'utils/DateTime';
import ProductivityStatistics from './containers/ProductivityStatistics';

import styles from './Productivity.module.css';

const DEFAULT_DATE_FROM = currentDateTime().minus({ months: 1 }).toISO();
const DEFAULT_DATE_TO = currentIsoDateTime();

const Productivity = () => {
  const [filters, setFilters] = useState({
    timePeriod: DAY_TIME_PERIOD_VALUE,
    from: DEFAULT_DATE_FROM,
    to: DEFAULT_DATE_TO,
    compare: false,
  });

  const handleUpdateFilter = (fieldName, value) => {
    setFilters({ ...filters, [fieldName]: value });
  };

  const handleFiltersFieldChange = (fieldName) => (value) => handleUpdateFilter(fieldName, value);

  const handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      handleUpdateFilter(fieldName, checked);

  const handleUpdateDate = (fieldName) => (value) => handleUpdateFilter(fieldName, value);

  return (
    <div className={styles.root}>
      <div className={styles.side}>
        <div className={styles.filters}>
          <div className={styles.filter}>
            <div className={styles.title}>Period</div>
            <div className={styles.select}>
              <Select
                options={TIME_PERIODS}
                onValueChange={handleFiltersFieldChange('timePeriod')}
                selectedOptionValue={filters.timePeriod}
              />
            </div>
          </div>
          <div className={styles.filter}>
            <Field title="From">
              <DatePicker value={filters.from} onChange={handleUpdateDate('from')} />
            </Field>
          </div>
          <div className={styles.filter}>
            <Field title="To">
              <DatePicker value={filters.to} onChange={handleUpdateDate('to')} />
            </Field>
          </div>
          <div className={styles.checkbox}>
            <CheckBox
              label={
                <>
                  Compare with <br />
                  previous timeframe
                </>
              }
              onChange={handleCheckboxChange('compare')}
              checked={filters.compare}
              reverse
            />
          </div>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.item}>
          <ProductivityStatistics
            filters={filters}
            chartTitle="Update Requests"
            requestType="update_request"
            name="updateRequest"
          />
        </div>
        <div className={styles.item}>
          <ProductivityStatistics
            filters={filters}
            chartTitle="New Profile Requests (Delivered)"
            requestType="new_profile_request"
            name="newProfileRequests"
          />
        </div>
      </div>
    </div>
  );
};

export default Productivity;
