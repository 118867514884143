import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { propEq, find, pipe, isNil, not } from 'ramda';

export const INCLUSION_KIND = 'inclusion';
export const EXCLUSION_KIND = 'exclusion';
export const EXECUTIVES_SCOPE = 'executives';
export const DIRECTORS_BOARD_SCOPE = 'directors_board';
export const DIRECTORS_BOARD_FTSE100_SCOPE = 'directors_board_ftse100';

export default new Presenter(
  {
    id: PropTypes.number,
    kind: PropTypes.string,
    scope: PropTypes.string,
    criterias: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    profilesCount: PropTypes.number,
  },
  {
    isIncludsionKind(targetItem) {
      return this.kind(targetItem) === INCLUSION_KIND;
    },

    isExclusionKind(targetItem) {
      return this.kind(targetItem) === EXCLUSION_KIND;
    },

    isDirectorsBoardScope(targetItem) {
      return (
        this.isIncludsionKind(targetItem) &&
        pipe(find(propEq(DIRECTORS_BOARD_SCOPE, 'type')), isNil, not)(this.criterias(targetItem))
      );
    },

    isDirectorsBoardFtse100Scope(targetItem) {
      return (
        this.isIncludsionKind(targetItem) &&
        pipe(find(propEq(DIRECTORS_BOARD_FTSE100_SCOPE, 'type')), isNil, not)(this.criterias(targetItem))
      );
    },

    isExecutivesScope(targetItem) {
      return (
        this.isIncludsionKind(targetItem) &&
        !this.isDirectorsBoardScope(targetItem) &&
        !this.isDirectorsBoardFtse100Scope(targetItem)
      );
    },
  },
);
