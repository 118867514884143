import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Spoiler from 'components/Spoiler';

import AliasPresenter from 'presenters/AliasPresenter';

import styles from './ReviewAliases.module.css';

class ReviewAliases extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(AliasPresenter.shape()).isRequired,
  };

  render() {
    const { value } = this.props;

    return (
      <Spoiler>
        {value.map((alias) => (
          <Fragment key={alias.id}>
            <h4 className={styles.strong}>{AliasPresenter.title(alias)}</h4>
          </Fragment>
        ))}
      </Spoiler>
    );
  }
}

export default ReviewAliases;
