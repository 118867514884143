import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'grommet';
import cn from 'clsx';
import styles from './VerticalFormFieldWithBottomErrors.module.css';

const VerticalFormFieldWithBottomErrors = (props) => {
  const { label, children, errors, className, help, htmlFor, checkbox } = props;
  const showErrors = errors && errors.length > 0;
  const rootStyles = cn(
    { [styles.VerticalFormFieldError]: showErrors, [styles.checkbox]: checkbox },
    [styles.VerticalFormField],
    className,
  );
  const formFieldStyles = cn({ [styles.checkbox]: checkbox }, [styles.CustomFormField]);

  return (
    <div className={rootStyles}>
      <FormField label={label} size="medium" help={help} className={formFieldStyles} htmlFor={htmlFor}>
        {children}
      </FormField>
      {showErrors && (
        <div className={styles.errors}>
          {errors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
};

VerticalFormFieldWithBottomErrors.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  help: PropTypes.string,
  htmlFor: PropTypes.string,
  checkbox: PropTypes.bool,
};

export default VerticalFormFieldWithBottomErrors;
