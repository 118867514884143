import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withContext from 'components/withContext';
import { compose, forEachObjIndexed } from 'ramda';

import { getSelectionCoord, setSelectionEmpty, getSelectedText } from 'utils/SelectionHelpers/SelectionHelpers';

import Portal from 'components/Portal';
import Popover from './components/Popover';
import styles from './TextSelection.module.css';

class TextSelection extends Component {
  static propTypes = {
    children: PropTypes.node,
    node: PropTypes.shape(),
    onAddHighlight: PropTypes.func,
    handleLoadTags: PropTypes.func,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.childrenRef = React.createRef();
  }

  state = {
    isMouseDown: false,
    popup: false,
    left: 0,
    top: 0,
    selectedText: null,
    canvasWidth: 0,
    canvasHeight: 0,
  };

  getCanvasSize() {
    const { width, height } = this.childrenRef.current.getBoundingClientRect();

    return { canvasWidth: width, canvasHeight: height };
  }

  handleAddHighlight = (tagId) => {
    const { selectedText } = this.state;
    return this.props.onAddHighlight({ selectedText, tagId });
  };

  handleClose = () => {
    this.setState({ popup: false, selectedText: null });
    this.clearSelection();
  };

  handleOnMouseDown = () => {
    this.setState({ isMouseDown: true, popup: false, selectedText: null });
    this.clearSelection();
  };

  handleOnMouseUp = () => {
    const isTextSelected = this.state.isMouseDown && !!getSelectedText();

    if (!isTextSelected) {
      return;
    }

    const { node } = this.props;

    const { left, top } = getSelectionCoord(node.current);

    const canvasSize = this.getCanvasSize();

    this.setState({ popup: true, left, top, selectedText: getSelectedText(), ...canvasSize }, () => {
      this.drawSelection();
      setSelectionEmpty();
    });
  };

  clearSelection() {
    const ctx = this.canvasRef.current.getContext('2d');

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  drawSelection() {
    if (!this.canvasRef.current) {
      return;
    }

    const sel = window.getSelection();
    const range = sel.getRangeAt(0);
    const rects = range.getClientRects();
    const { x, y } = this.canvasRef.current.getBoundingClientRect();
    const ctx = this.canvasRef.current.getContext('2d');

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = '#3399FF';

    forEachObjIndexed((rect) => {
      ctx.fillRect(rect.x - x, rect.y - y, rect.width, rect.height);
    })(rects);

    ctx.fill();
  }

  render() {
    const { children, node, saving, handleLoadTags } = this.props;
    const { popup, left, top, canvasWidth, canvasHeight } = this.state;

    return (
      <>
        {popup && (
          <Portal node={node}>
            <Popover
              left={left}
              top={top}
              onClose={this.handleClose}
              onAddTag={this.handleAddHighlight}
              handleLoadTags={handleLoadTags}
              onFocus={this.handlePopoverFocus}
              saving={saving}
            />
          </Portal>
        )}

        <div
          role="presentation"
          onMouseDown={this.handleOnMouseDown}
          onMouseUp={this.handleOnMouseUp}
          className={styles.container}
        >
          <div ref={this.childrenRef} className={styles.children}>
            <div className={styles.overlay}>
              <div className={styles.canvas}>
                <canvas ref={this.canvasRef} width={canvasWidth} height={canvasHeight} />
              </div>
            </div>
            {children}
          </div>
        </div>
      </>
    );
  }
}

export default compose(withContext)(TextSelection);
