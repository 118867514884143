import { isEmpty, omit, pick, pickBy, pipe, prop, propEq, values } from 'ramda';
import { isPresent } from 'utils/HelperMethods';
import { fromISOtoGrommetDate } from 'utils/DateTime';
import { propOr } from 'ramda';

export const TIME_IN_ROLE_FOR_MONTH_OPTION = { key: 'for_month', label: '<30 Days', value: 'now-1M/M' };
export const TIME_IN_ROLE_FOR_THREE_MONTHS_OPTION = {
  key: 'for_three_month',
  label: '<90 Days',
  value: 'now-3M/M',
};

export const TIME_IN_CURRENT_WORK_FILTER_OPTIONS = [
  TIME_IN_ROLE_FOR_MONTH_OPTION,
  TIME_IN_ROLE_FOR_THREE_MONTHS_OPTION,
  { key: 'for_half_year', label: '<180 Days', value: 'now-6M/M' },
  { key: 'for_year', label: '<1 Year', value: 'now-1y/M' },
];

export const CURRENT_ORGANIZATION_RANK_LTE_FILTER_OPTIONS = [
  { key: 'fortune_100', label: 'Fortune 100', value: 'fortune_100' },
  { key: 'fortune_250', label: 'Fortune 250', value: 'fortune_250' },
  { key: 'fortune_500', label: 'Fortune 500', value: 'fortune_500' },
  { key: 'ftse_100', label: 'FTSE 100', value: 'ftse_100' },
];

export const USER_BASED_FILTERS = {
  ONLY_MY_ALERTS: 'onlyMyAlerts',
};

export const SCOPE_FILTER_VALUES = {
  SIGNIFICANTLY_UPDATED_GTE: 'significantlyUpdatedGte',
  RECENT_CURRENT_WORK_STARTED_GTE: 'recentCurrentWorkStartedGte',
  PROFILES_ADDED_GTE: 'profilesAddedGte',
};

export const FILTERS = {
  CURRENT_ORGANIZATION_NAME_FILTER: 'currentOrganizationName',
  DIRECTORS_BOARD_ORGANIZATION_NAME_FILTER: 'directorBoardOrganizationName',
  DIRECTORS_BOARD_ORGANIZATION_NAME_FTSE100_FILTER: 'directorBoardOrganizationNameFtse100',
  FULL_NAME_FILTER: 'fullName',
  JOB_FUNCTIONS_FILTER: 'jobFunctions',
  JOB_TITLES_FILTER: 'jobTitles',
  TIME_IN_CURRENT_WORK_FILTER: 'timeInCurrentWork',
  KEYWORDS_FILTER: 'keywords',
  WORLD_REGION_FILTER: 'worldRegion',
  COUNTRY_FILTER: 'country',
  COUNTRY_REGION_FILTER: 'countryRegion',
  STATE_FILTER: 'state',
  CURRENT_ORGANIZATION_INDUSTRY_FILTER: 'currentOrganizationIndustry',
  CURRENT_ORGANIZATION_SUBINDUSTRY_FILTER: 'currentOrganizationSubIndustry',
  INTERESTS_FILTER: 'interests',
  BOARDS_FILTER: 'boards',
  GENDER_FILTER: 'gender',
  CURRENT_ORGANIZATION_FORTUNE_RANK_LTE: 'currentOrganizationFortuneRankLte',
  CURRENT_ORGANIZATION_RANK_LTE: 'currentOrganizationRankLte',
  CURRENT_ORGANIZATION_INDUSTRY_OR_SUB_INDUSTRY: 'currentOrganizationIndustryOrSubIndustry',
  DIRECTOR_BOARD_ORGANIZATION_RANKING: 'directorBoardOrganizationRanking',
};

const FILTER_NAMES = {
  [FILTERS.CURRENT_ORGANIZATION_NAME_FILTER]: 'Company',
  [FILTERS.BOARD_ORGANIZATION_NAME_FILTER]: 'Company-B',
  [FILTERS.FULL_NAME_FILTER]: 'Executive Name',
  [FILTERS.JOB_FUNCTIONS_FILTER]: 'Function',
  [FILTERS.JOB_TITLES_FILTER]: 'Title',
  [FILTERS.KEYWORDS_FILTER]: 'Keywords',
  [FILTERS.WORLD_REGION_FILTER]: 'Region',
  [FILTERS.COUNTRY_FILTER]: 'Country',
  [FILTERS.COUNTRY_REGION_FILTER]: 'Sub-region',
  [FILTERS.STATE_FILTER]: 'State or Province',
  [FILTERS.CURRENT_ORGANIZATION_INDUSTRY_FILTER]: 'Industry',
  [FILTERS.CURRENT_ORGANIZATION_SUBINDUSTRY_FILTER]: 'Sub-industry',
  [FILTERS.INTERESTS_FILTER]: 'Interests',
  [FILTERS.BOARDS_FILTER]: 'Boards',
  [FILTERS.GENDER_FILTER]: 'Gender',
  [FILTERS.TIME_IN_CURRENT_WORK_FILTER]: 'Time In Role',
  [FILTERS.CURRENT_ORGANIZATION_RANK_LTE]: 'Ranking/Index',
  [FILTERS.CURRENT_ORGANIZATION_INDUSTRY_OR_SUB_INDUSTRY]: 'Industry',
  [USER_BASED_FILTERS.ONLY_MY_ALERTS]: 'Show only my alerts',
  [SCOPE_FILTER_VALUES.SIGNIFICANTLY_UPDATED_GTE]: 'Updated Profiles',
  [SCOPE_FILTER_VALUES.RECENT_CURRENT_WORK_STARTED_GTE]: 'New To Role Profiles',
  [SCOPE_FILTER_VALUES.PROFILES_ADDED_GTE]: 'New Profiles',
};

const DEFAULT_ATTRIBUTES = {
  currentOrganizationName: [],
  directorBoardOrganizationName: [],
  directorBoardOrganizationNameFtse100: [],
  fullName: [],
  jobFunctions: [],
  jobTitles: [],
  keywords: [],
  worldRegion: [],
  country: [],
  countryRegion: [],
  state: [],
  currentOrganizationIndustry: [],
  currentOrganizationSubIndustry: [],
  currentOrganizationIndustryOrSubIndustry: [],
  currentOrganizationRankLte: [],
  interests: [],
  boards: [],
  gender: [],
  timeInCurrentWork: [],
  onlyMyAlerts: false,
  significantlyUpdatedGte: null,
  recentCurrentWorkStartedGte: null,
  profilesAddedGte: null,
  directorBoardOrganizationRanking: [],
};

export function humanFilterName(filter) {
  return propOr(filter, filter)(FILTER_NAMES);
}

export const SCOPE_FILTER_OPTIONS = values(SCOPE_FILTER_VALUES).map((value) => ({
  value,
  label: humanFilterName(value),
}));

export function humanFilterOptionName(filter, value) {
  switch (filter) {
    case FILTERS.TIME_IN_CURRENT_WORK_FILTER: {
      const option = TIME_IN_CURRENT_WORK_FILTER_OPTIONS.find(propEq(value, 'value'));

      return isPresent(option) ? option.label : null;
    }
    case FILTERS.CURRENT_ORGANIZATION_RANK_LTE: {
      const option = CURRENT_ORGANIZATION_RANK_LTE_FILTER_OPTIONS.find(propEq(value, 'value'));

      return isPresent(option) ? option.label : null;
    }
    case SCOPE_FILTER_VALUES.SIGNIFICANTLY_UPDATED_GTE:
    case SCOPE_FILTER_VALUES.PROFILES_ADDED_GTE:
    case SCOPE_FILTER_VALUES.RECENT_CURRENT_WORK_STARTED_GTE: {
      return `Since ${fromISOtoGrommetDate(value)}`;
    }
    default: {
      return value;
    }
  }
}

export function humanFilterValue(filter, value) {
  const defaultValue = DEFAULT_ATTRIBUTES[filter];

  if (Array.isArray(defaultValue)) {
    return value.map((optionValue) => humanFilterOptionName(filter, optionValue)).join(', ');
  }

  return humanFilterOptionName(filter, value);
}

function toBoolean(value) {
  return value === true || value === 'true';
}

export default {
  defaultAttributes(attributes) {
    const scopeFilterAttributeNames = SCOPE_FILTER_OPTIONS.map(prop('value'));
    const attributesWithoutProfileScopeAttributes = omit(scopeFilterAttributeNames, attributes);
    const [scopeFilterValue] = scopeFilterAttributeNames.filter((attributeName) =>
      isPresent(attributes[attributeName]),
    );
    const scopeFilterDate = isPresent(scopeFilterValue) ? attributes[scopeFilterValue] : null;

    return {
      ...DEFAULT_ATTRIBUTES,
      ...attributesWithoutProfileScopeAttributes,
      scopeFilterValue,
      scopeFilterDate,
      onlyRecentlyAdded: toBoolean(attributesWithoutProfileScopeAttributes.onlyRecentlyAdded),
      onlyMyAlerts: toBoolean(attributesWithoutProfileScopeAttributes.onlyMyAlerts),
      page: attributes.page || 1,
    };
  },

  activeScopeFilter({ scopeFilterValue, scopeFilterDate }) {
    if (isPresent(scopeFilterValue) && isPresent(scopeFilterDate)) {
      return { [scopeFilterValue]: scopeFilterDate };
    }

    return {};
  },

  selectPermittedPresentFilters({ scopeFilterValue, scopeFilterDate, ...attributes }) {
    return pipe(
      pick(Object.keys(DEFAULT_ATTRIBUTES)),
      pickBy((val, key) => !isEmpty(key)),
    )({ ...attributes, ...this.activeScopeFilter({ scopeFilterValue, scopeFilterDate }) });
  },

  queryParams(attributes) {
    return this.selectPermittedPresentFilters(attributes);
  },

  attributesToSubmit({ page, per, sort, query, ...attributes }) {
    const filters = this.selectPermittedPresentFilters(attributes);

    return {
      q: { filters, query, sort },
      page,
      per,
    };
  },
};
