import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

import UserPresenter from 'presenters/UserPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    kind: PropTypes.string,
    kindHumanName: PropTypes.string,
    sender: UserPresenter.shape(),
    subject: PropTypes.shape(),
  },
  {},
);
