import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Spoiler from 'components/Spoiler';

import InstitutionPresenter from 'presenters/InstitutionPresenter';

class ReviewInstitutionsList extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(InstitutionPresenter.shape()).isRequired,
  };

  render() {
    const { value } = this.props;

    return (
      <section>
        <Spoiler>
          <ul>
            {value.map((institution) => (
              <li key={institution.id}>
                {` ${InstitutionPresenter.degree(institution)}`},{InstitutionPresenter.organizationName(institution)}
                {` ${InstitutionPresenter.timeInterval(institution)}`}
              </li>
            ))}
          </ul>
        </Spoiler>
      </section>
    );
  }
}

export default ReviewInstitutionsList;
