export const STATES = [
  {
    name: 'requested',
    transitions: [
      { to: 'in_research', event: 'to_research' },
      { to: 'in_editorial', event: 'to_editorial' },
      { to: 'canceled', event: 'cancel' },
    ],
  },
  {
    name: 'in_research',
    transitions: [
      { to: 'in_editorial', event: 'to_editorial' },
      { to: 'canceled', event: 'cancel' },
    ],
  },
  {
    name: 'in_editorial',
    transitions: [
      { to: 'in_research', event: 'return_to_research' },
      { to: 'completed', event: 'complete' },
      { to: 'canceled', event: 'cancel' },
      { to: 'delivered', event: 'deliver' },
    ],
  },
  {
    name: 'completed',
    transitions: [
      { to: 'in_research', event: 'return_to_research' },
      { to: 'in_editorial', event: 'return_to_editorial' },
      { to: 'delivered', event: 'deliver' },
      { to: 'canceled', event: 'cancel' },
    ],
  },
  {
    name: 'delivered',
    transitions: [],
  },
  {
    name: 'canceled',
    transitions: [],
  },
];
