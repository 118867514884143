import React from 'react';

import { TableHeadCell } from 'components/v2/Table';

import styles from './Header.module.css';

const Header = () => (
  <tr className={styles.header}>
    <TableHeadCell width="100%">Status</TableHeadCell>
    <TableHeadCell width="100%">Project Name</TableHeadCell>
    <TableHeadCell width="100%">Full Name</TableHeadCell>
    <TableHeadCell width="100%">Created Date</TableHeadCell>
    <TableHeadCell width="100%">Deleted Date</TableHeadCell>
    <TableHeadCell width="100%">Profile Count</TableHeadCell>
    <TableHeadCell width="100%">Shares Count</TableHeadCell>
  </tr>
);

export default Header;
