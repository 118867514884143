import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'formik';
import { isNil } from 'ramda';

import { extendNestedCollectionProps } from './utils';

const NestedCollection = (props) => {
  const { api, children, component: Component, name } = props;

  return (
    <FieldArray name={name}>
      {(nestedCollectionProps) => {
        const extendedNestedCollectionProps = extendNestedCollectionProps(api, nestedCollectionProps);

        return isNil(Component) ? (
          children(extendedNestedCollectionProps)
        ) : (
          <Component {...extendedNestedCollectionProps} />
        );
      }}
    </FieldArray>
  );
};

NestedCollection.propTypes = {
  api: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  component: PropTypes.elementType,
  name: PropTypes.string.isRequired,
};

NestedCollection.defaultProps = {
  api: false,
  children: null,
  component: null,
};

export default NestedCollection;
