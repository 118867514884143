import UserPresenter from 'presenters/UserPresenter';
import { isPresent } from 'utils/HelperMethods';

export default {
  canSeeList(user) {
    return UserPresenter.isAdmin(user);
  },
  canSeeElement(user) {
    return UserPresenter.isAdmin(user);
  },
  canDestroyElement(user) {
    return UserPresenter.isAdmin(user);
  },
  canAddElement(user) {
    return UserPresenter.isAdmin(user);
  },
  canSeeTagBlocks(user) {
    return isPresent(user) && (UserPresenter.isAdmin(user) || UserPresenter.isEditor(user));
  },
  canPopulateWithTag(user) {
    return isPresent(user) && UserPresenter.isAdmin(user);
  },
  canExportList(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchManager(user);
  },
};
