import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ModalAlert = (props) => {
  const { onClose, value } = props;
  const { modalElementType: Modal, modalProps } = value;

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return isOpen ? <Modal {...modalProps} onClose={handleClose} /> : null;
};

ModalAlert.propTypes = {
  onClose: PropTypes.func.isRequired,
  value: PropTypes.shape({
    modalElementType: PropTypes.elementType.isRequired,
    modalProps: PropTypes.string,
  }).isRequired,
};

export default ModalAlert;
