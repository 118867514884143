import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';

import Typography from 'components/v2/Typography';
import Button from 'components/v2/Button';
import Link from 'components/v2/Link';

import styles from './CreationSuccess.module.css';

const CreationSuccess = ({ companyId }) => (
  <div>
    <Typography variant="h1" className={styles.message}>
      Users has been successfully imported.
    </Typography>
    <div className={styles.actions}>
      <Button component={Link} href={Routes.editAdminCompanyPath(companyId)}>
        Go to Company
      </Button>
    </div>
  </div>
);

CreationSuccess.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CreationSuccess;
