import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Button from 'components/Button';
import { isPresent } from 'utils/HelperMethods';

import Work from './components/Work';
import WorkForm from './components/WorkForm';
import ProfilePresenter from 'presenters/ProfilePresenter';
import withConfirmation from 'hoc/withConfirmation';

import styles from './WorksList.module.css';

import WorkPresenter from 'presenters/WorkPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';

import useAdminProfileDraftWork from 'hooks/admin/profile/useDraftWork';

const WorksListComponent = (props) => {
  const { editable, profile, primaryCurrentWork, saving, works, jobFunctions } = props;
  const { createWork, updateWork, destroyWork, searchStandardizedJobTitle } = useAdminProfileDraftWork();
  const [isAddWork, setIsAddWork] = useState(false);

  const handleAddNewWork = () => {
    setIsAddWork(editable);
  };

  const handleCloseModal = () => setIsAddWork(false);

  const handleWorkCreate = (work) => createWork(profile.id, work).then(handleCloseModal);

  const handleWorkUpdate = (workId, work) => updateWork(profile.id, workId, work).then(handleCloseModal);

  const handleWorkDestroy = (workId) => destroyWork(profile.id, workId);

  const handleStandardizedJobTitleSearch = (searchText) => {
    const filters = { jobTitleCont: searchText };

    return searchStandardizedJobTitle(filters);
  };

  const isPrimaryCurrentWork = (work) =>
    isPresent(primaryCurrentWork) && WorkPresenter.id(primaryCurrentWork) === WorkPresenter.id(work);

  return (
    <>
      {works.map((work) => (
        <Box key={work.id} direction="row" margin={{ bottom: 'small' }} className={styles.box}>
          <Work
            key={work.id}
            editable={editable}
            work={work}
            isPrimaryCurrentWork={isPrimaryCurrentWork(work)}
            jobFunctions={jobFunctions}
            saving={saving}
            onUpdate={handleWorkUpdate}
            onDestroy={handleWorkDestroy}
            onStandardizedJobTitleSearch={handleStandardizedJobTitleSearch}
          />
        </Box>
      ))}

      <Box direction="row" margin={{ top: 'medium' }}>
        <Button label="Add New" primary disabled={!editable} onClick={handleAddNewWork} />
      </Box>

      {isAddWork && (
        <WorkForm
          title="Add New Work"
          jobFunctions={jobFunctions}
          work={{}}
          saving={saving}
          onCancel={handleCloseModal}
          onSubmit={handleWorkCreate}
          onStandardizedJobTitleSearch={handleStandardizedJobTitleSearch}
        />
      )}
    </>
  );
};

WorksListComponent.propTypes = {
  profile: ProfilePresenter.shape(),
  editable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  primaryCurrentWork: WorkPresenter.shape(),
  works: PropTypes.arrayOf(WorkPresenter.shape()).isRequired,
  jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()).isRequired,
};

const WorksList = withConfirmation(WorksListComponent, { destroyWork: 'Are you sure want to delete?' });
export default WorksList;
