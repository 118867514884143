import ExecutivesChangesStatisticsConstants from './ExecutivesChangesStatisticsConstants';

const initialState = {
  loading: false,
  executivesChangesStatistics: null,
};

function ExecutivesChangesStatisticsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ExecutivesChangesStatisticsConstants.LOAD_DATABASE_ANALYTICS_EXECUTIVES_CHANGES_STATISTICS_START: {
      return { ...state, loading: true, executivesChangesStatistics: null };
    }
    case ExecutivesChangesStatisticsConstants.LOAD_DATABASE_ANALYTICS_EXECUTIVES_CHANGES_STATISTICS_SUCCESS: {
      const { executivesChangesStatistics } = action;

      return { ...state, loading: false, executivesChangesStatistics };
    }
    case ExecutivesChangesStatisticsConstants.LOAD_DATABASE_ANALYTICS_EXECUTIVES_CHANGES_STATISTICS_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}

export default ExecutivesChangesStatisticsReducer;
