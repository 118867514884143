import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CustomizedTick extends Component {
  static propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.shape({}),
  };

  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={20} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  }
}
