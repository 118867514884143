import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Spinner from 'components/Spinner';

import NewProfilesStatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/NewProfilesStatisticsPresenter';

import styles from './NewProfilesStatistics.module.css';

export default class NewProfilesStatistics extends Component {
  static propTypes = {
    newProfilesStatistics: NewProfilesStatisticsPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    loadNewProfilesStatistics: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadNewProfilesStatistics } = this.props;

    loadNewProfilesStatistics();
  }

  renderStatistics() {
    const { newProfilesStatistics } = this.props;

    return (
      <>
        <div className={styles.item}>
          <div className={styles.title}>This week:</div>
          <div className={styles.number}>{NewProfilesStatisticsPresenter.formattedThisWeek(newProfilesStatistics)}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>This Month:</div>
          <div className={styles.number}>
            {NewProfilesStatisticsPresenter.formattedThisMonth(newProfilesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Last 3 Months:</div>
          <div className={styles.number}>
            {NewProfilesStatisticsPresenter.formattedLastThreeMonths(newProfilesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Last 12 Months:</div>
          <div className={styles.number}>{NewProfilesStatisticsPresenter.formattedLastYear(newProfilesStatistics)}</div>
        </div>
      </>
    );
  }

  render() {
    const { newProfilesStatistics, loading } = this.props;

    return (
      <div>
        <h3 className={styles.header}>New Profiles Published</h3>
        {loading || isBlank(newProfilesStatistics) ? <Spinner /> : this.renderStatistics()}
      </div>
    );
  }
}
