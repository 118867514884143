import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { any } from 'ramda';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';

export const FILTERS = {
  EXCLUDE_IDS: 'excludeIds',
  MUST_BE_EXECUTIVE: 'mustBeExecutive',
  MUST_BE_BOARD_OF_DIRECTORS: 'mustBeBoardOfDirectors',
  MUST_BE_BOARD_OF_DIRECTORS_FTSE100: 'mustBeBoardOfDirectorsFtse100',
  COMPANY_MEMBER_MUST_BE_EXECUTIVE: 'companyMemberMustBeExecutive',
  COMPANY_MEMBER_MUST_BE_BOARD_MEMBER: 'companyMemberMustBeBoardMember',
};

export default new Presenter(
  {
    nodes: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        strength: PropTypes.number.isRequired,
        source: PropTypes.number.isRequired,
        target: PropTypes.number.isRequired,
      }),
    ),
  },
  {
    hasMemberFortune500(relationshipsMapping) {
      const nodes = this.nodes(relationshipsMapping) || [];

      return any(ProfileSearchPresenter.isMemberFortune500)(nodes);
    },
    hasMemberFtse100(relationshipsMapping) {
      const nodes = this.nodes(relationshipsMapping) || [];

      return any(ProfileSearchPresenter.isMemberFtse100)(nodes);
    },
  },
);
