import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import Button from 'components/Button';

import Board from './components/Board';
import BoardForm from './components/BoardForm';
import ProfilePresenter from 'presenters/ProfilePresenter';

import styles from './BoardsList.module.css';

import BoardPresenter from 'presenters/BoardPresenter';
import withConfirmation from 'hoc/withConfirmation';

import useAdminProfileDraftBoard from 'hooks/admin/profile/useDraftBoard';

const BoardsListComponent = (props) => {
  const { editable, profile, saving, boards } = props;
  const { createBoard, updateBoard, destroyBoard } = useAdminProfileDraftBoard();
  const [isAddBoard, setIsAddBoard] = useState(false);

  const handleAddNewBoard = () => setIsAddBoard(editable);

  const handleCloseModal = () => setIsAddBoard(false);

  const handleBoardCreate = (board) => createBoard(profile.id, board).then(handleCloseModal);

  const handleBoardUpdate = (boardId, board) => updateBoard(profile.id, boardId, board).then(handleCloseModal);

  const handleBoardDestroy = (boardId) => destroyBoard(profile.id, boardId);

  return (
    <>
      {boards.map((board) => (
        <Box key={board.id} direction="row" margin={{ bottom: 'small' }} className={styles.box}>
          <Board
            editable={editable}
            board={board}
            saving={saving}
            onUpdate={handleBoardUpdate}
            onDestroy={handleBoardDestroy}
          />
        </Box>
      ))}

      <Box direction="row" margin={{ top: 'medium' }}>
        <Button label="Add New" primary disabled={!editable} onClick={handleAddNewBoard} />
      </Box>

      {isAddBoard && (
        <BoardForm
          title="Add New Board"
          board={{}}
          saving={saving}
          onCancel={handleCloseModal}
          onSubmit={handleBoardCreate}
        />
      )}
    </>
  );
};

BoardsListComponent.propTypes = {
  profile: ProfilePresenter.shape(),
  editable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  boards: PropTypes.arrayOf(BoardPresenter.shape()).isRequired,
};

const BoardsList = withConfirmation(BoardsListComponent, { destroyBoard: 'Are you sure want to delete?' });

export default BoardsList;
