import { defaultTo, pick } from 'ramda';

export default {
  defaultAttributes(profile = {}) {
    return {
      ...profile,
      address1: defaultTo('', profile.address1),
      address2: defaultTo('', profile.address2),
      city: defaultTo('', profile.city),
      zip: defaultTo('', profile.zip),
      phone: defaultTo('', profile.phone),
      email: defaultTo('', profile.email),
      twitterUrl: defaultTo('', profile.twitterUrl),
      facebookUrl: defaultTo('', profile.facebookUrl),
      linkedinUrl: defaultTo('', profile.linkedinUrl),
      blogUrl: defaultTo('', profile.blogUrl),
    };
  },

  attributesToSubmit(profile) {
    const propsToSend = [
      'address1',
      'address2',
      'city',
      'zip',
      'phone',
      'email',
      'twitterUrl',
      'facebookUrl',
      'linkedinUrl',
      'blogUrl',
      'stateId',
      'countryId',
    ];

    const { state, country } = profile;

    return pick(propsToSend, {
      ...profile,
      stateId: state && state.id,
      countryId: country && country.id,
    });
  },
};
