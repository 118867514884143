import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { propEq, propOr, prop } from 'ramda';
import { isPresent } from 'utils/HelperMethods';
import config from 'config';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { components as ReactSelectComponents } from 'react-select';

import CreatableSelect from 'components/CreatableSelect';
import CreatableWithSuggestionSelect from './components/CreatableWithSuggestionSelect';
import AsyncSelect from 'components/AsyncSelect';
import SortSelect from 'containers/UserPanel/components/SearchSortSelect';
import ScopeFilter from 'containers/UserPanel/components/SearchScopeFilter';

import {
  humanFilterName,
  FILTERS,
  CURRENT_ORGANIZATION_RANK_LTE_FILTER_OPTIONS,
  TIME_IN_CURRENT_WORK_FILTER_OPTIONS,
} from 'forms/ProfileFilteredSearchForm';

import styles from './Filters.module.css';

class Filters extends Component {
  state = { filterInputs: {} };

  getOptionStastic = (filter, key, optionName) => {
    const { autocomplete, isLoading } = this.props;
    const statistics = isLoading ? {} : autocomplete;
    const filterStatistics = this.flattenOptions(propOr([], filter, statistics));
    const filterOptionStastic = filterStatistics.find(propEq(key, 'key'));

    return propOr(null, optionName, filterOptionStastic);
  };

  getDocCount = (filter, key) => this.getOptionStastic(filter, key, 'docCount');

  getDocCountTotal = (filter, key) => this.getOptionStastic(filter, key, 'docCountTotal');

  flattenOptions = (options) =>
    options.reduce((acc, { options: subOptions, ...option }) => [...acc, option, ...(subOptions || [])], []);

  handleMultipleSelectChange = (filter) => (options) => {
    const { onFilterChange } = this.props;
    const value = options.map((v) => v.key);

    onFilterChange(filter)(value);
  };

  handleSelectValueChange = (filter) => (value) => {
    const { onFilterChange } = this.props;
    const values = isPresent(value) ? [value] : [];

    onFilterChange(filter)(values);
  };

  handleKeyPress = (filter) => (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.handleAddKeyword(filter)();
        event.preventDefault();
        break;
      default:
    }
  };

  handleAddKeyword = (filter) => () => {
    const { onFilterChange, searchParams } = this.props;
    const { filterInputs } = this.state;
    const key = filterInputs[filter];

    if (!key) {
      return;
    }

    const value = [...(searchParams[filter] || []), key];
    onFilterChange(filter)(value);
    this.setState({ filterInputs: { ...this.state.filterInputs, [filter]: '' } });
  };

  handleFilterInputChange = (filter) => (value) => {
    const filterInputs = {
      ...this.state.filterInputs,
      [filter]: value.substring(0, config.search.textValueMaxLength),
    };

    this.setState({ filterInputs });
  };

  isValidNewOption(inputValue, selectValue, selectOptions) {
    return !(
      !inputValue ||
      selectValue.some(({ key }) => key.toLowerCase() === inputValue.toLowerCase()) ||
      selectOptions.some(({ key }) => key.toLowerCase() === inputValue.toLowerCase())
    );
  }

  renderNoOptions = () => null;

  renderOptionLabel =
    (labelKey = 'key') =>
    (option) => {
      const label = option[labelKey];
      const { docCount, docCountTotal } = option;
      const availableDocCount = isPresent(docCountTotal) ? `/${docCountTotal} available` : '';

      return `${label}${isPresent(docCount) ? ` (${docCount}${availableDocCount})` : ''}`;
    };

  renderReactSelectInput(props) {
    return <ReactSelectComponents.Input {...props} maxLength={config.search.textValueMaxLength} />;
  }

  renderReactSelectGroupOption(props) {
    const { data } = props;

    if (data.group) {
      return <ReactSelectComponents.Option {...props} className={styles.groupHeading} />;
    }

    return <ReactSelectComponents.Option {...props} className={styles.groupOption} />;
  }

  renderMultiSelectFilter(filter, openMenuOnClick) {
    const { disabledFilters, isLoading, onLoadOptions, searchParams } = this.props;

    const isDisabled = disabledFilters.includes(filter);
    const value = (searchParams[filter] || []).map((key) => {
      const docCount = this.getDocCount(filter, key);
      const docCountTotal = this.getDocCountTotal(filter, key);

      return { key, docCount, docCountTotal };
    });

    return (
      <div className={styles.partition}>
        <div className={styles.title}>{humanFilterName(filter)}</div>

        <AsyncSelect
          components={{ Input: this.renderReactSelectInput }}
          isDisabled={isDisabled || isLoading}
          openMenuOnClick={openMenuOnClick}
          value={value}
          loadOptions={reactSelectDebounce(onLoadOptions(filter))}
          isClearable
          isMulti
          filterOption={() => true}
          getOptionValue={(o) => o.key}
          getOptionLabel={this.renderOptionLabel()}
          classNamePrefix="react-select"
          onChange={this.handleMultipleSelectChange(filter)}
          noOptionsMessage={this.renderNoOptions}
          shouldLoadOptionsAfterMenuOpen={!isDisabled && openMenuOnClick}
        />
      </div>
    );
  }

  renderGroupMultiSelectFilter(filter, openMenuOnClick) {
    const { disabledFilters, isLoading, onLoadOptions, searchParams } = this.props;

    const isDisabled = disabledFilters.includes(filter);
    const value = (searchParams[filter] || []).map((key) => {
      const docCount = this.getDocCount(filter, key);
      const docCountTotal = this.getDocCountTotal(filter, key);

      return { key, docCount, docCountTotal };
    });
    const handleLoadOptions = (...args) => onLoadOptions(filter)(...args).then(this.flattenOptions);

    return (
      <div className={styles.partition}>
        <div className={styles.title}>{humanFilterName(filter)}</div>

        <AsyncSelect
          components={{ Input: this.renderReactSelectInput, Option: this.renderReactSelectGroupOption }}
          isDisabled={isDisabled || isLoading}
          openMenuOnClick={openMenuOnClick}
          value={value}
          loadOptions={reactSelectDebounce(handleLoadOptions)}
          isClearable
          isMulti
          filterOption={() => true}
          getOptionValue={(o) => o.key}
          getOptionLabel={this.renderOptionLabel()}
          classNamePrefix="react-select"
          onChange={this.handleMultipleSelectChange(filter)}
          hideSelectedOptions={false}
          noOptionsMessage={this.renderNoOptions}
          shouldLoadOptionsAfterMenuOpen={!isDisabled && openMenuOnClick}
        />
      </div>
    );
  }

  renderSelectFilter(filter, filterOptions) {
    const { disabledFilters, isLoading, onLoadOptions, searchParams } = this.props;

    const isDisabled = disabledFilters.includes(filter);
    const handleLoadOptions = (value) =>
      onLoadOptions(filter)(value).then((options) =>
        filterOptions.map((option) => {
          const docCount = propOr(0, 'docCount', options.find(propEq(option.key, 'key')));
          const docCountTotal = prop('docCountTotal', options.find(propEq(option.key, 'key')));

          return { ...option, docCount, docCountTotal };
        }),
      );
    const values = searchParams[filter] || [];
    const [selectedOption] = values.map((value) => {
      const option = filterOptions.find(propEq(value, 'value'));
      const docCount = this.getDocCount(filter, option.key);
      const docCountTotal = this.getDocCountTotal(filter, option.key);

      return { ...option, docCount, docCountTotal };
    });

    return (
      <div className={styles.partition}>
        <div className={styles.title}>{humanFilterName(filter)}</div>

        <AsyncSelect
          components={{ Input: this.renderReactSelectInput }}
          isDisabled={isDisabled || isLoading}
          openMenuOnClick
          value={selectedOption || null}
          loadOptions={reactSelectDebounce(handleLoadOptions)}
          isClearable
          filterOption={() => true}
          getOptionValue={(o) => o.value}
          getOptionLabel={this.renderOptionLabel('label')}
          classNamePrefix="react-select"
          onValueChange={this.handleSelectValueChange(filter)}
          noOptionsMessage={this.renderNoOptions}
          shouldLoadOptionsAfterMenuOpen={!isDisabled}
        />
      </div>
    );
  }

  renderTextSuggestionFilter(filter) {
    const { disabledFilters, isLoading, onLoadOptions, searchParams } = this.props;

    const isDisabled = disabledFilters.includes(filter);
    const value = (searchParams[filter] || []).map((key) => ({ key }));

    return (
      <div className={styles.partition}>
        <div className={styles.title}>{humanFilterName(filter)}</div>

        <CreatableWithSuggestionSelect
          components={{ Input: this.renderReactSelectInput }}
          isDisabled={isDisabled || isLoading}
          value={value}
          isClearable
          isMulti
          loadOptions={reactSelectDebounce(onLoadOptions(filter))}
          getOptionValue={(o) => o.key}
          getOptionLabel={(o) => o.key}
          classNamePrefix="react-select"
          onChange={this.handleMultipleSelectChange(filter)}
          noOptionsMessage={this.renderNoOptions}
          getNewOptionData={() => null}
          isValidNewOption={this.isValidNewOption}
          shouldLoadOptionsAfterMenuOpen={!isDisabled}
        />
      </div>
    );
  }

  renderTextSelectFilter(filter) {
    const { disabledFilters, isLoading, searchParams } = this.props;
    const { filterInputs } = this.state;

    const isDisabled = disabledFilters.includes(filter);
    const value = (searchParams[filter] || []).map((key) => ({ key }));

    return (
      <div className={styles.partition}>
        <div className={styles.title}>{humanFilterName(filter)}</div>

        <CreatableSelect
          components={{ Input: this.renderReactSelectInput }}
          isDisabled={isDisabled || isLoading}
          openMenuOnClick={false}
          value={value}
          inputValue={filterInputs[filter]}
          isClearable
          isMulti
          filterOption={() => true}
          menuIsOpen={false}
          getOptionValue={(o) => o.key}
          getOptionLabel={(o) => o.key}
          classNamePrefix="react-select"
          onChange={this.handleMultipleSelectChange(filter)}
          onInputChange={this.handleFilterInputChange(filter)}
          noOptionsMessage={this.renderNoOptions}
          onBlur={this.handleAddKeyword(filter)}
          onKeyDown={this.handleKeyPress(filter)}
          isValidNewOption={this.isValidNewOption}
        />
      </div>
    );
  }

  render() {
    const { autocomplete, onSortChange, searchParams, sort, scopeFilterDate, scopeFilterValue, onScopeFilterChange } =
      this.props;
    const { country, state, worldRegion, countryRegion } = searchParams;
    const sortStyles = cn(styles.partition, styles.mobileSort);
    const countryRegions = autocomplete.countryRegion || [];
    const states = autocomplete.state || [];

    const anyCountryRegionsExists = countryRegions.length > 0;
    const anyStatesExists = states.length > 0;

    const isWorldRegionSelected = worldRegion && worldRegion.length > 0;
    const isCountrySelected = country && country.length > 0;
    const isCountryRegionSelected = countryRegion && countryRegion.length > 0;
    const isStateSelected = state && state.length > 0;

    return (
      <>
        {isPresent(onScopeFilterChange) && (
          <div className={sortStyles}>
            <div className={styles.title}>Scope</div>
            <ScopeFilter date={scopeFilterDate} value={scopeFilterValue} onChange={onScopeFilterChange} />
          </div>
        )}
        <div className={sortStyles}>
          <div className={styles.title}>Sort by</div>
          <SortSelect placeholder="" sort={sort} onChange={onSortChange} />
        </div>
        {this.renderMultiSelectFilter(FILTERS.CURRENT_ORGANIZATION_NAME_FILTER, false)}
        {this.renderTextSuggestionFilter(FILTERS.FULL_NAME_FILTER, false)}
        {this.renderMultiSelectFilter(FILTERS.JOB_FUNCTIONS_FILTER, true)}
        {this.renderTextSelectFilter(FILTERS.JOB_TITLES_FILTER, false)}
        {this.renderSelectFilter(FILTERS.TIME_IN_CURRENT_WORK_FILTER, TIME_IN_CURRENT_WORK_FILTER_OPTIONS)}
        {this.renderTextSelectFilter(FILTERS.KEYWORDS_FILTER)}
        {this.renderSelectFilter(FILTERS.CURRENT_ORGANIZATION_RANK_LTE, CURRENT_ORGANIZATION_RANK_LTE_FILTER_OPTIONS)}
        {this.renderMultiSelectFilter(FILTERS.WORLD_REGION_FILTER, true)}
        {(isWorldRegionSelected || isCountrySelected) && this.renderMultiSelectFilter(FILTERS.COUNTRY_FILTER, true)}
        {((anyCountryRegionsExists && isCountrySelected) || isCountryRegionSelected) &&
          this.renderMultiSelectFilter(FILTERS.COUNTRY_REGION_FILTER, true)}
        {((anyStatesExists && anyCountryRegionsExists && isCountryRegionSelected) ||
          (anyStatesExists && !anyCountryRegionsExists && isCountrySelected) ||
          isStateSelected) &&
          this.renderMultiSelectFilter(FILTERS.STATE_FILTER, true)}
        {this.renderGroupMultiSelectFilter(FILTERS.CURRENT_ORGANIZATION_INDUSTRY_OR_SUB_INDUSTRY, true)}
        {this.renderMultiSelectFilter(FILTERS.INTERESTS_FILTER, false)}
        {this.renderMultiSelectFilter(FILTERS.BOARDS_FILTER, false)}
        {this.renderMultiSelectFilter(FILTERS.GENDER_FILTER, true)}
      </>
    );
  }
}

Filters.propTypes = {
  autocomplete: PropTypes.shape().isRequired,
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onLoadOptions: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  searchParams: PropTypes.shape().isRequired,
  sort: PropTypes.string,
  scopeFilterDate: PropTypes.string,
  scopeFilterValue: PropTypes.string,
  onScopeFilterChange: PropTypes.func,
};

Filters.defaultProps = {
  disabledFilters: [],
};

export default Filters;
