import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  hide(guideName) {
    const url = Routes.hideApiV1CurrentUserGuidePath(guideName);

    return FetchHelpers.post(url);
  },

  reveal(guideName) {
    const url = Routes.revealApiV1CurrentUserGuidePath(guideName);

    return FetchHelpers.post(url);
  },
};
