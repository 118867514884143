import AdminSavedSearchesConstants from './AdminSavedSearchesConstants';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import SavedSearchesRepository from 'repositories/admin/SavedSearchesRepository';

export function filterSavedSearches({ per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: AdminSavedSearchesConstants.FILTER_ADMIN_SAVED_SEARCHES_START });
    const params = { per, ...buildFilterParams(filters) };

    return SavedSearchesRepository.index(params).then(({ items, meta }) => {
      dispatch({ type: AdminSavedSearchesConstants.FILTER_ADMIN_SAVED_SEARCHES_SUCCESS, items, meta });

      return items;
    });
  };
}

export function loadSavedSearches({ page, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: AdminSavedSearchesConstants.LOAD_ADMIN_SAVED_SEARCHES_START });
    const params = { per, ...buildFilterParams(filters) };

    return SavedSearchesRepository.index(params).then(({ items, meta }) => {
      dispatch({ type: AdminSavedSearchesConstants.LOAD_ADMIN_SAVED_SEARCHES_SUCCESS, items, meta });

      return items;
    });
  };
}
