import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { assocPath, isNil } from 'ramda';

import { useDebounce } from 'utils/debounceUtils';

import ProjectPresenter from 'presenters/Company/Dashboard/ProjectPresenter';

import InfiniteScroll from 'components/v2/InfiniteScroll';
import Table, { TableBody, TableHead, TableLoaderRow } from 'components/v2/Table';
import SearchInput from 'components/SearchInput/SearchInput';

import Header from './components/Header';
import Row from './components/Row';

import styles from './ProjectsUsage.module.css';

const ProjectsUsage = (props) => {
  const { nextPage, onLoadMore, onLoad, activeProjectsCount, projects, loadingMoreStatus, loadingStatus } = props;

  const [filters, setFilters] = useState({});

  useEffect(() => {
    onLoad(filters);
  }, []);

  const handleProjectsFilter = useDebounce((newFilters) => onLoad(newFilters));

  const handleFiltersChange = (newFilters) => {
    handleProjectsFilter(newFilters);
    setFilters(newFilters);
  };

  const handleLoadMore = () => onLoadMore(filters);

  const handleSearch = ({ target: { value } }) => handleFiltersChange(assocPath(['q', 'name_cont'], value, filters));

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div>
          <span className={styles.title}>Projects</span>
          <span className={styles.value}>{activeProjectsCount}</span>
        </div>
        <div className={styles.search}>
          <SearchInput onChange={handleSearch} placeholder="Search for projects" responsive={false} />
        </div>
      </div>

      <div className={styles.content}>
        <InfiniteScroll
          hasMore={!isNil(nextPage)}
          loadingMoreStatus={loadingMoreStatus}
          onLoadMore={handleLoadMore}
          getScrollParent={false}
        >
          <Table>
            <TableHead>
              <Header />
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <Row key={project.id} project={project} />
              ))}
              {(loadingStatus.isPending || loadingMoreStatus.isPending) && <TableLoaderRow colSpan={10} />}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

ProjectsUsage.propTypes = {
  projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  activeProjectsCount: PropTypes.number,
  onLoad: PropTypes.func,
  loadingMoreStatus: PropTypes.shape(),
  loadingStatus: PropTypes.shape(),
};

export default ProjectsUsage;
