import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce, isBlank } from 'utils/HelperMethods';

import { Box } from 'grommet';
import InfiniteScroll from 'components/InfiniteScroll';
import Sample from 'containers/AdminPanel/containers/SamplesPage/containers/SampleSavedSearchesTab/components/Sample';
import SavedSearch from 'components/SavedSearch';
import Search from 'components/SearchInput';
import Spinner from 'components/Spinner';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import styles from './AdminSavedSearches.module.css';

export default class AdminSavedSearches extends Component {
  static propTypes = {
    canMarkAsSample: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    loadSavedSearches: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    filters: PropTypes.shape(),
    filterSavedSearches: PropTypes.func.isRequired,
    onMarkAsSample: PropTypes.func.isRequired,
    onUnmarkAsSample: PropTypes.func.isRequired,
    sampleSavedSearchIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    savedSearches: PropTypes.arrayOf(SavedSearchPresenter.shape()).isRequired,
  };

  componentDidMount() {
    const { filterSavedSearches } = this.props;

    filterSavedSearches({ canBeSamples: true });
  }

  isAllSavedSearchesLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { isLoading, loadSavedSearches, nextPage, filters } = this.props;

    if (!isLoading && !this.isAllSavedSearchesLoaded()) {
      loadSavedSearches({ page: nextPage, ...filters });
    }
  };

  handleSearchChange = ({ target: { value } }) => {
    const { filters, filterSavedSearches } = this.props;

    return filterSavedSearches({ ...filters, nameCont: value });
  };

  handleSearchChangeDebounced = debounce(this.handleSearchChange);

  handleMarkAsSample = (savedSearch) => () => {
    const { onMarkAsSample } = this.props;

    return onMarkAsSample(savedSearch);
  };

  handleUnmarkAsSample = (savedSearch) => () => {
    const { onUnmarkAsSample } = this.props;

    return onUnmarkAsSample(savedSearch);
  };

  renderLoader = () => (
    <div key="loader" className={styles.spinner}>
      <Spinner />
    </div>
  );

  renderSavedSearch = (savedSearch) => {
    const { canMarkAsSample, isSaving, sampleSavedSearchIds } = this.props;
    const savedSearchId = SavedSearchPresenter.id(savedSearch);
    const isSample = sampleSavedSearchIds.includes(savedSearchId);

    return (
      <Sample
        key={savedSearchId}
        canMarkAsSample={canMarkAsSample}
        isSample={isSample}
        isSaving={isSaving}
        onMarkAsSample={this.handleMarkAsSample(savedSearch)}
        onUnmarkAsSample={this.handleUnmarkAsSample(savedSearch)}
      >
        <SavedSearch className={styles.savedSearch} savedSearch={savedSearch} filterNames={{}} />
      </Sample>
    );
  };

  render() {
    const { savedSearches } = this.props;

    return (
      <div className={styles.root}>
        <Box width="medium" margin={{ right: 'small', bottom: 'medium' }}>
          <Search placeholder="Search" onChange={this.handleSearchChangeDebounced} />
        </Box>
        <div className={styles.gridList}>
          <InfiniteScroll
            hasMore={!this.isAllSavedSearchesLoaded()}
            loadMore={this.handleLoadMore}
            loader={this.renderLoader()}
            useWindow={false}
            researchForScrollParent
          >
            {savedSearches.map(this.renderSavedSearch)}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
