import { pick, prop, intersection, reject, propEq, not } from 'ramda';

import { PROJECT_CUSTOM_EXPORT } from 'presenters/ProjectPresenter';
import UserPolicy from 'policies/UserPolicy';

export const EXPORTABLE_COLUMNS = [
  { name: 'company_name', header: 'Company Name' },
  { name: 'first_name', header: 'First Name' },
  { name: 'last_name', header: 'Last Name' },
  { name: 'title', header: 'Title' },
  { name: 'phone', header: 'Phone' },
  { name: 'email', header: 'Email' },
  { name: 'origin_country', header: 'Country of Origin' },
  { name: 'work_address', header: 'Work Address' },
  { name: 'twitter_url', header: 'Twitter Url' },
  { name: 'facebook_url', header: 'Facebook Url' },
  { name: 'linkedin_url', header: 'Linkedin Url' },
  { name: 'blog_url', header: 'Blog Url' },
  { name: 'industry_name', header: 'Industry Name' },
  { name: 'industry_code', header: 'Industry Code' },
  { name: 'corporate_duns', header: 'DUNS#' },
  { name: 'pdf_url', header: 'PDF Url' },
  { name: 'profile_url', header: 'Profile Url' },
  { name: 'interests', header: 'Interest' },
  { name: 'works', header: 'Employment History' },
  { name: 'boards', header: 'Boards' },
  { name: 'institutions', header: 'Universities and Colleges' },
  { name: 'job_functions', header: 'Job Functions' },
  { name: 'last_updated_at', header: 'Last Updated Date' },
];

const COLUMN_NAMES_WHICH_NEED_SPECIAL_COLUMNS = ['works', 'institutions', 'boards'];

const SPECIAL_COLUMNS = ['from_year', 'to_year', 'details'];

export function getExportableColumns(user) {
  let columns = EXPORTABLE_COLUMNS;

  if (not(UserPolicy.canSeeEmail(user))) {
    columns = reject(propEq('email', 'name'), columns);
  }

  if (not(UserPolicy.canSeePhone(user))) {
    columns = reject(propEq('phone', 'name'), columns);
  }

  if (not(UserPolicy.canSeeAddress(user))) {
    columns = reject(propEq('work_address', 'name'), columns);
  }

  return columns;
}

export function buildRecipientEmail(value) {
  return { label: value, value };
}

function recipientEmailValue(option) {
  return option.value;
}

export default {
  defaultAttributes(attributes) {
    return {
      type: PROJECT_CUSTOM_EXPORT,
      recipientEmails: [],
      ...attributes,
      params: {
        columnNames: EXPORTABLE_COLUMNS.map(prop('name')),
        includePdf: false,
        ...attributes.params,
      },
    };
  },

  attributesToSubmit(attributes) {
    const { params } = attributes;
    const propsToSend = ['type', 'recipientEmails', 'params'];
    const paramsPropsToSend = ['columnNames', 'includePdf', 'search'];
    const shouldAddSpecialColumns =
      intersection(COLUMN_NAMES_WHICH_NEED_SPECIAL_COLUMNS, params.columnNames).length > 0;
    const columnNames = shouldAddSpecialColumns ? [...params.columnNames, ...SPECIAL_COLUMNS] : params.columnNames;
    const recipientEmails = attributes.recipientEmails.map(recipientEmailValue);
    const permittedParams = pick(paramsPropsToSend, { ...params, columnNames });

    return pick(propsToSend, { ...attributes, recipientEmails, params: permittedParams });
  },
};
