import React, { Component } from 'react';

import SourcePresenter from 'presenters/Project/SourcePresenter';
import {
  NOT_IN_PROJECT_STATE,
  PENDING_STATE,
  CANCELED_STATE,
  NOT_REQUESTED_STATE,
  IN_PROJECT_STATE,
  humanStateName,
  stateColor,
} from 'presenters/Project/CsvItemPresenter';

import styles from './Statistics.module.css';

export default class Statistics extends Component {
  static propTypes = {
    source: SourcePresenter.shape().isRequired,
  };

  states() {
    const { source } = this.props;

    return [
      {
        state: IN_PROJECT_STATE,
        profilesCount: SourcePresenter.profilesCount(source),
      },
      {
        state: PENDING_STATE,
        profilesCount: SourcePresenter.pendingProfilesCount(source),
      },
      {
        state: CANCELED_STATE,
        profilesCount: SourcePresenter.canceledProfilesCount(source),
      },
      {
        state: NOT_REQUESTED_STATE,
        profilesCount: SourcePresenter.notRequestedProfilesCount(source),
      },
      {
        state: NOT_IN_PROJECT_STATE,
        profilesCount: SourcePresenter.notIncludedProfilesCount(source),
      },
    ];
  }

  renderListItem = ({ state, profilesCount }) => (
    <div key={state} className={styles.listItem}>
      <div className={styles.legend} style={{ backgroundColor: stateColor(state) }} />
      <div className={styles.itemName}>{humanStateName(state)}:</div>
      <div className={styles.itemCount}>{profilesCount}</div>
    </div>
  );

  renderChartItem = ({ state, profilesCount }) => {
    const { source } = this.props;
    const width = (profilesCount * 100) / SourcePresenter.totalProfilesCount(source);

    return (
      <div
        key={state}
        style={{ width: `${width}%`, backgroundColor: stateColor(state) }}
        title={humanStateName(state)}
        className={styles.chartItem}
      />
    );
  };

  render() {
    const { source } = this.props;

    return (
      <div>
        <div className={styles.stats}>
          <div className={styles.list}>{this.states().map(this.renderListItem)}</div>
          <div className={styles.chart}>{this.states().map(this.renderChartItem)}</div>
        </div>
        <div className={styles.total}>
          <div className={styles.text}>Total Profiles</div>
          <div className={styles.number}>{SourcePresenter.totalProfilesCount(source)}</div>
        </div>
      </div>
    );
  }
}
