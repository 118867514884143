import NotificationsRepository from 'repositories/CurrentUser/NotificationsRepository';
import NotificationsSummaryRepository from 'repositories/CurrentUser/NotificationsSummaryRepository';
import BackendNotificationsConstants from './BackendNotificationsConstants';
import { isPresent } from 'utils/HelperMethods';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

const POLLING_TIMEOUT = 90 * 1000;
let pollingTimeoutId = null;

function loadNotifications(time) {
  return (dispatch) => {
    dispatch({ type: BackendNotificationsConstants.LOAD_CURRENT_USER_NOTIFICATIONS_START });
    const filters = buildFilterParams({ stateEq: 'opened', createdAtGt: time });

    return NotificationsRepository.index(filters)
      .then(({ items, meta }) => {
        dispatch({
          type: BackendNotificationsConstants.LOAD_CURRENT_USER_NOTIFICATIONS_SUCCESS,
          notifications: items,
          ...meta,
        });

        return true;
      })
      .catch(() => {
        dispatch({ type: BackendNotificationsConstants.LOAD_CURRENT_USER_NOTIFICATIONS_ERROR });

        return false;
      });
  };
}

function updateNotificationsSummary(params) {
  return (dispatch) => {
    dispatch({ type: BackendNotificationsConstants.UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_START });

    return NotificationsSummaryRepository.update(params)
      .then(({ notificationsSummary }) => {
        dispatch({
          type: BackendNotificationsConstants.UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_SUCCESS,
          summary: notificationsSummary,
        });

        return notificationsSummary;
      })
      .catch(() => {
        dispatch({ type: BackendNotificationsConstants.UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_ERROR });

        return null;
      });
  };
}

export function markProjectsViewed() {
  return updateNotificationsSummary({ projectsViewed: true });
}

export function markDashboardViewed() {
  return updateNotificationsSummary({ projectsViewed: true, savedSearchesViewed: true, dashboardViewed: true });
}

export function stopPollingNotifications() {
  if (isPresent(pollingTimeoutId)) {
    clearTimeout(pollingTimeoutId);
  }

  return { type: BackendNotificationsConstants.POLLING_CURRENT_USER_NOTIFICATIONS_STOP };
}

function pollingNoftifications(dispatch, getState) {
  const { time } = getState().BackendNotificationsReducer;

  return dispatch(loadNotifications(time)).then((isSuccessfullyLoaded) => {
    if (isSuccessfullyLoaded && getState().BackendNotificationsReducer.isPolling) {
      pollingTimeoutId = setTimeout(() => pollingNoftifications(dispatch, getState), POLLING_TIMEOUT);
    } else {
      dispatch(stopPollingNotifications());
    }
  });
}

export function startPollingNotifications() {
  return (dispatch, getState) => {
    if (getState().BackendNotificationsReducer.isPolling) {
      return Promise.reject();
    }

    dispatch({ type: BackendNotificationsConstants.POLLING_CURRENT_USER_NOTIFICATIONS_START });

    return pollingNoftifications(dispatch, getState);
  };
}

export function closeNotification(id) {
  return (dispatch) => {
    dispatch({ type: BackendNotificationsConstants.DESTROY_CURRENT_USER_NOTIFICATION_START, id });

    return NotificationsRepository.destroy(id).catch(() => {});
  };
}
