const SORTING_REGEXP = /^(.*)\s(asc|desc)$/i;

export const parseRansackSortings = (ransackSotrings) => {
  const validRansackSotrings = ransackSotrings.filter((sorting) => sorting.match(SORTING_REGEXP));

  return validRansackSotrings.map((sorting) => {
    const [, field, direction] = sorting.match(SORTING_REGEXP);

    return { field, direction: direction.toLowerCase() };
  });
};

export const buildRansackSortings = (sortings) => sortings.map(({ field, direction }) => `${field} ${direction}`);
