import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CorporateAccount from './CorporateAccount';
import * as actions from './CorporateAccountActions';

const mapStateToProps = (state) => ({
  loading: state.CorporateAccountReducer.loading,
  company: state.CorporateAccountReducer.company,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccount);
