import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { isPresent, isBlank } from 'utils/HelperMethods';
import { isExceedMaxSize, isMatchFileExt } from 'utils/fileUtils';
import cn from 'clsx';

import { Box, Spinner as Spinning } from 'grommet';
import Icon from 'components/Icon';

import styles from './CsvDropzone.module.css';

export default class CsvDropzone extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    file: PropTypes.shape(),
    isValidating: PropTypes.bool,
    isFileValid: PropTypes.bool,
  };

  handleDrop = (acceptedFiles, rejectedFiles) => {
    const { onLoad } = this.props;
    const file = isPresent(acceptedFiles[0]) ? acceptedFiles[0] : rejectedFiles[0];

    onLoad(file);
  };

  renderFileStatus() {
    const { isValidating, isFileValid, file } = this.props;

    if (isValidating) {
      return (
        <div className={styles.validating}>
          <Spinning size="large" />
          <div className={styles.message}>File is validating</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    if (isExceedMaxSize(file)) {
      return (
        <div className={styles.error}>
          <Icon name="notAllowed" />
          <div className={styles.message}>File size should be less than 4 megabytes</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    if (isFileValid) {
      return (
        <div className={styles.success}>
          <Icon name="csvIcon" />
          <div className={styles.message}>File successfully uploaded</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    if (!isMatchFileExt(file, 'csv')) {
      return (
        <div className={styles.error}>
          <Icon name="notAllowed" />
          <div className={styles.message}>Wrong file type. Please upload a .CSV</div>
          <div className={styles.anotherFile}>Upload another file</div>
        </div>
      );
    }

    return (
      <div className={styles.error}>
        <Icon name="notAllowed" />
        <div className={styles.message}>Incorrect file</div>
        <div className={styles.anotherFile}>Upload another file</div>
      </div>
    );
  }

  renderFileUploadSuggestion() {
    return (
      <div className={styles.noFile}>
        <div className={styles.drop}>Drag & drop CSV-file here</div>
        <div className={styles.or}>or</div>
        <div className={styles.file}>Choose file</div>
      </div>
    );
  }

  render() {
    const { file, isValidating, isFileValid } = this.props;
    const dropStyles = cn([styles.dropWrapper], {
      [styles.dropWrapperError]: isPresent(file) && !isValidating && !isFileValid,
    });

    return (
      <Box direction="row" justify="center" margin={{ bottom: 'medium' }}>
        <Dropzone accept={{ 'text/*': ['.csv'] }} onDrop={this.handleDrop} ref={this.assignDropzoneRef}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={dropStyles}>
              <input {...getInputProps()} />
              {isBlank(file) ? this.renderFileUploadSuggestion() : this.renderFileStatus()}
            </div>
          )}
        </Dropzone>
      </Box>
    );
  }
}
