import React, { Component } from 'react';
import { Anchor } from 'grommet';

import { logClickOnAudienceInsightsTop } from 'utils/amplitude';

import Sidebar from 'containers/UserPanel/containers/Project/containers/ProjectProfilesListSidebar';

import AudienceInsightPresenter from 'presenters/Project/AudienceInsightPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import styles from './Toplists.module.css';

export default class Toplists extends Component {
  static propTypes = {
    audienceInsight: AudienceInsightPresenter.shape().isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  state = { isSidebarOpened: false, sidebarTitle: null, sidebarFilters: {}, sidebarProfilePathQuery: {} };

  handleOpenSidebar = (sidebarTitle, sidebarFilters, sidebarProfilePathQuery) => (topListName) => () => {
    logClickOnAudienceInsightsTop(topListName);
    this.setState({ ...this.state, isSidebarOpened: true, sidebarFilters, sidebarTitle, sidebarProfilePathQuery });
  };

  handleCloseSidebar = () =>
    this.setState({
      ...this.state,
      isSidebarOpened: false,
      sidebarTitle: null,
      sidebarFilters: {},
      sidebarProfilePathQuery: {},
    });

  handleClickInterest = (interest) => this.handleOpenSidebar(interest.title, { interest: interest.title })('HOBBY');

  handleClickOrganization = (organization) =>
    this.handleOpenSidebar(organization.title, { organization: organization.title })('ORGANIZATION');

  handleClickTag = (tag) => this.handleOpenSidebar(tag.title, { tagId: tag.id }, { tags: [tag.id] })('PRIORITY');

  handleClickLanguage = (language) => this.handleOpenSidebar(language.title, { language: language.title })('LANGUAGE');

  renderList(title, items, handleClick) {
    return (
      <div className={styles.list}>
        <div className={styles.title}>{title}</div>
        <div className={styles.items}>
          {items.map((item, index) => (
            <div className={styles.item} key={`${item}${index}`}>
              <Anchor onClick={handleClick(item)} label={item.title} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { audienceInsight, project } = this.props;
    const { interests, organizations, tags, languages } = AudienceInsightPresenter.tops(audienceInsight);

    const { isSidebarOpened, sidebarTitle, sidebarFilters, sidebarProfilePathQuery } = this.state;

    return (
      <>
        {isSidebarOpened && (
          <Sidebar
            project={project}
            onClose={this.handleCloseSidebar}
            title={sidebarTitle}
            filters={sidebarFilters}
            profilePathQuery={sidebarProfilePathQuery}
          />
        )}
        <div className={styles.lists}>
          {this.renderList('Top 5 Hobbies', interests, this.handleClickInterest)}
          {this.renderList('Top 5 Organizations', organizations, this.handleClickOrganization)}
          {this.renderList('Top 5 Priorities', tags, this.handleClickTag)}
          {this.renderList('Top 5 Languages', languages, this.handleClickLanguage)}
        </div>
      </>
    );
  }
}
