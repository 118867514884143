import AgingStatisticsConstants from './AgingStatisticsConstants';

const initialState = {
  loading: false,
  agingStatistics: null,
};

function AgingStatisticsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AgingStatisticsConstants.LOAD_DATABASE_ANALYTICS_AGING_STATISTICS_START: {
      return { ...state, loading: true, agingStatistics: null };
    }
    case AgingStatisticsConstants.LOAD_DATABASE_ANALYTICS_AGING_STATISTICS_SUCCESS: {
      const { agingStatistics } = action;

      return { ...state, loading: false, agingStatistics };
    }
    case AgingStatisticsConstants.LOAD_DATABASE_ANALYTICS_AGING_STATISTICS_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}

export default AgingStatisticsReducer;
