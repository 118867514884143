import QuickSearchConstants from './QuickSearchConstants';
import QuickSearchRepository from 'repositories/QuickSearchRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function initialize() {
  return { type: QuickSearchConstants.INITIALIZE_QUICK_SEARCH };
}

export function search(searchQuery) {
  return (dispatch) => {
    dispatch({ type: QuickSearchConstants.LOAD_QUICK_SEARCH_START });

    const params = { per: 5, ...buildFilterParams({ fullName: searchQuery, currentOrganizationName: searchQuery }) };
    return QuickSearchRepository.index(params).then(({ items, meta: { organization } }) => {
      dispatch({ type: QuickSearchConstants.LOAD_QUICK_SEARCH_SUCCESS, profiles: items, organization });
    });
  };
}
