import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CountryPage from './CountryPage';
import * as actions from './CountryPageActions';

const mapStateToProps = (state, props) => ({
  country: state.CountryPageReducer.country,
  loading: state.CountryPageReducer.loading,
  saving: state.CountryPageReducer.saving,
  countryId: props.match.params.id,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CountryPage);
