import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from './OrganizationAvatar.module.css';

export default class OrganizationAvatar extends Component {
  static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
  };

  render() {
    const { width, height, src } = this.props;
    const image = <img src={src} className={styles.image} alt="logo" />;
    const placeholder = <Icon name="organizationPlaceholder" style={{ width, height }} />;

    return (
      <div
        className={styles.logo}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {src ? image : placeholder}
      </div>
    );
  }
}
