import { reject, propEq } from 'ramda';
import BackendNotificationsConstants from './BackendNotificationsConstants';

const initialState = {
  notifications: [],
  time: null,
  summary: { updatedProjectsCount: 0, updatedSavedSearchesCount: 0 },
  isLoading: false,
  isPolling: false,
  isUpdating: false,
};

function BackendNotificationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case BackendNotificationsConstants.POLLING_CURRENT_USER_NOTIFICATIONS_START: {
      return { ...state, isPolling: true };
    }
    case BackendNotificationsConstants.POLLING_CURRENT_USER_NOTIFICATIONS_STOP: {
      return { ...state, isPolling: false };
    }
    case BackendNotificationsConstants.LOAD_CURRENT_USER_NOTIFICATIONS_START: {
      return { ...state, isLoading: true };
    }
    case BackendNotificationsConstants.LOAD_CURRENT_USER_NOTIFICATIONS_SUCCESS: {
      const { summary, time } = action;
      const notifications = [...state.notifications, ...action.notifications];

      return { ...state, notifications, isLoading: false, summary, time };
    }
    case BackendNotificationsConstants.LOAD_CURRENT_USER_NOTIFICATIONS_ERROR: {
      return { ...state, isLoading: false };
    }
    case BackendNotificationsConstants.UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_START: {
      return { ...state, isUpdating: true };
    }
    case BackendNotificationsConstants.UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_SUCCESS: {
      const { summary } = action;

      return { ...state, isUpdating: false, summary };
    }
    case BackendNotificationsConstants.UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_ERROR: {
      return { ...state, isUpdating: false };
    }
    case BackendNotificationsConstants.DESTROY_CURRENT_USER_NOTIFICATION_START: {
      const { id } = action;
      const notifications = reject(propEq(id, 'id'), state.notifications);

      return { ...state, notifications };
    }
    default:
      return state;
  }
}

export default BackendNotificationsReducer;
