import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, CartesianGrid, YAxis, LabelList } from 'recharts';

import CustomizedXAxisTick from './components/CustomizedXAxisTick';
import CustomizedYAxisTick from './components/CustomizedYAxisTick';
import CustomizedTooltip from './components/CustomizedTooltip';
import CustomizedXAxisWeekDivider from './components/CustomizedXAxisWeekDivider';

import styles from './ProfilesDueChart.module.css';

const MAX_BAR_SIZE = 64;
const BAR_PADDING = 30;
const BAR_WIDTH_WITH_PADDING = MAX_BAR_SIZE + BAR_PADDING * 2;
const CHART_HEIGHT = 400;
const X_AXIS_HEIGHT = 60;
const BAR_CHART_MARGIN_TOP = 30;
const BAR_CHART_SCROLL_HEIGH = 30;
const BAR_CHART_RIGHT_PADDING = 30;
const LABEL_MARGIN_BOTTOM = 10;

const ProfilesDueChart = (props) => {
  const { data, parentWidth } = props;
  const chartContainerRef = useRef(null);
  const scrollOffset = chartContainerRef?.current?.scrollLeft;
  const [tooltip, setTooltip] = useState({ show: false });

  const onMouseEnter = (keyNames) => (barProps) => {
    setTooltip({ show: true, ...barProps, ...keyNames });
  };

  const onMouseLeave = (barProps) => {
    setTooltip({ show: false, ...barProps });
  };

  const selfChartWidth = BAR_WIDTH_WITH_PADDING * data.length;
  const chartWidth = selfChartWidth < parentWidth ? parentWidth : selfChartWidth;

  const renderLabel = (labelProps) => {
    const { x, y, width, index } = labelProps;
    const { totalPassDueCount, totalPendingCount } = data[index];
    const value = totalPendingCount + totalPassDueCount;
    const xPosition = x + width / 2;
    const yPosition = y - LABEL_MARGIN_BOTTOM;

    return (
      <g>
        <text x={xPosition} y={yPosition} fill="#444444" textAnchor="middle" dominantBaseline="bottom">
          {value}
        </text>
      </g>
    );
  };

  return (
    <div className={styles.container} style={{ height: CHART_HEIGHT + BAR_CHART_SCROLL_HEIGH }}>
      {tooltip.show && <CustomizedTooltip {...tooltip} scrollOffset={scrollOffset} barWidth={MAX_BAR_SIZE} />}
      <div className={styles.chartContainer} ref={chartContainerRef}>
        <BarChart
          data={data}
          height={CHART_HEIGHT}
          width={chartWidth - BAR_CHART_RIGHT_PADDING}
          margin={{ top: BAR_CHART_MARGIN_TOP, right: 0, bottom: 0, left: -35 }}
        >
          <CartesianGrid strokeDasharray="0" vertical={false} />
          <YAxis interval={1} tick={<CustomizedYAxisTick />} axisLine={false} tickSize={0} />
          <XAxis
            dataKey="day"
            tick={<CustomizedXAxisTick barWidth={MAX_BAR_SIZE} data={data} />}
            height={X_AXIS_HEIGHT}
            interval={0}
          />
          <XAxis
            dataKey="day"
            tick={
              <CustomizedXAxisWeekDivider
                data={data}
                offsetTop={BAR_CHART_MARGIN_TOP}
                height={CHART_HEIGHT - X_AXIS_HEIGHT}
              />
            }
            axisLine={false}
            tickLine={false}
            interval={0}
            height={1}
            scale="band"
            xAxisId="id2"
          />
          <Bar
            dataKey="totalPassDueCount"
            maxBarSize={MAX_BAR_SIZE}
            className={styles.bar}
            stackId="day"
            onMouseEnter={onMouseEnter({ totalCountKeyName: 'passDueCount' })}
            onMouseLeave={onMouseLeave}
            fill="#EA6E40"
          />
          <Bar
            dataKey="totalPendingCount"
            maxBarSize={MAX_BAR_SIZE}
            className={styles.bar}
            stackId="day"
            onMouseEnter={onMouseEnter({ totalCountKeyName: 'pendingCount' })}
            onMouseLeave={onMouseLeave}
            fill="#00A0CE"
          >
            <LabelList content={renderLabel} position="top" />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

ProfilesDueChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default ProfilesDueChart;
