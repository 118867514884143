import React from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import ContextPresenter from 'presenters/Relationship/ContextPresenter';
import Typography from 'components/v2/Typography';

import styles from './Context.module.css';

const Context = (props) => {
  const { context, index, highlightTitle } = props;

  return (
    <div key={`${ContextPresenter.description(context)}-${index}`} className={styles.root}>
      {highlightTitle && (
        <Typography variant="body2" className={styles.title}>
          {ContextPresenter.title(context)}
        </Typography>
      )}
      <Typography variant="body1" className={styles.description}>
        {ContextPresenter.description(context)}, {ContextPresenter.title(context)}
      </Typography>
      {['jobFunctionsStr', 'timeInterval'].map((field) => {
        const value = ContextPresenter[field](context);

        if (isBlank(value)) {
          return null;
        }

        return (
          <Typography variant="body2" color="boulder" key={value}>
            {value}
          </Typography>
        );
      })}
    </div>
  );
};

Context.propTypes = {
  context: ContextPresenter.shape(),
  index: PropTypes.number,
  highlightTitle: PropTypes.bool.isRequired,
};

export default Context;
