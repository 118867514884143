import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { locationWithoutQuery } from 'utils/UrlHelper';

export default {
  index(params = {}) {
    const url = Routes.apiV1CurrentUserTargetingSearchIndexPath();
    return FetchHelpers.post(url, params, { referrer: locationWithoutQuery() });
  },

  autocomplete(params = {}) {
    const url = Routes.autocompleteApiV1CurrentUserTargetingSearchIndexPath();
    return FetchHelpers.post(url, params, { referrer: locationWithoutQuery() });
  },
};
