import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { decamelize } from 'utils/keysConverter';

export default {
  index(params = {}) {
    const url = Routes.apiV1AdminEditorialDashboardRemainingRequestNumbersStatisticsPath(decamelize(params));

    return FetchHelpers.get(url);
  },
};
