import { pluralize } from 'utils/pluralizer';
import { fromISO } from 'utils/DateTime';

export const buildTimeAxisTicks = (from, to, periodName, periodLength = 1) => {
  let startDate = fromISO(from).startOf('day');
  const endDate = fromISO(to).startOf(periodName);
  const ticks = [startDate.toSeconds()];

  while (startDate < endDate) {
    startDate = startDate.plus({ [pluralize(periodName)]: periodLength });
    ticks.push(startDate.toSeconds());
  }

  ticks.push(fromISO(to).toSeconds());
  return ticks;
};
