import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(query) {
    const url = Routes.apiV1AdminPopularOrganizationTranscriptsPath(query);
    return FetchHelpers.get(url);
  },

  update(transcriptId) {
    const url = Routes.apiV1AdminPopularOrganizationTranscriptPath(transcriptId);
    return FetchHelpers.put(url);
  },
};
