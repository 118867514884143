import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactSelect from 'react-select/creatable';
import { isNil, isEmpty } from 'ramda';
import { noop } from 'utils/HelperMethods';

import { useFieldContext } from 'components/v2/Form/components/Field';
import StyledReactSelect from 'components/v2/StyledReactSelect';

const FIELD_VARIANT_TO_INPUT_VARIANT = {
  outlined: 'raw',
  default: 'outlined',
};

const CreatableSelect = (props) => {
  const {
    createOptionKeys,
    createOptionOnBlur,
    getOptionValue,
    isMulti,
    isValidNewOption,
    onBlur,
    onChange,
    onCreateOption,
    value,
    variant,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState('');
  const fieldContext = useFieldContext();
  const selectVariant = variant || FIELD_VARIANT_TO_INPUT_VARIANT[fieldContext?.variant];

  const handleCreateOption = () => {
    if (isNil(onCreateOption)) {
      const newOption = getOptionValue(inputValue);
      const newValue = isMulti ? [...value, newOption] : newOption;

      onChange(newValue);
    } else {
      onCreateOption(inputValue);
    }

    setInputValue('');
  };

  const handleBlur = (event) => {
    onBlur(event);

    if (createOptionOnBlur && isValidNewOption(inputValue)) {
      handleCreateOption();
    }
  };

  const handleKeyDown = (event) => {
    if (createOptionKeys.includes(event.key) && isValidNewOption(inputValue)) {
      handleCreateOption();

      event.preventDefault();
    }
  };

  return (
    <StyledReactSelect
      isFieldChild={!isNil(fieldContext)}
      {...rest}
      getOptionValue={getOptionValue}
      inputValue={inputValue}
      isMulti={isMulti}
      isValidNewOption={isValidNewOption}
      onBlur={handleBlur}
      onCreateOption={handleCreateOption}
      onChange={onChange}
      onInputChange={setInputValue}
      onKeyDown={handleKeyDown}
      reactSelectType={ReactSelect}
      value={value}
      variant={selectVariant}
    />
  );
};

CreatableSelect.propTypes = {
  createOptionKeys: PropTypes.arrayOf(PropTypes.string),
  createOptionOnBlur: PropTypes.bool,
  getOptionValue: PropTypes.func,
  isClearable: PropTypes.bool,
  isDropdownIndicatorVisible: PropTypes.bool,
  isMulti: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onCreateOption: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.anyn)]).isRequired,
  variant: PropTypes.string,
};

CreatableSelect.defaultProps = {
  createOptionKeys: ['Enter', 'Tab'],
  createOptionOnBlur: false,
  getOptionValue: (inputValue) => ({ label: inputValue, value: inputValue }),
  isClearable: false,
  isDropdownIndicatorVisible: false,
  isMulti: false,
  isValidNewOption: (inputValue) => !isEmpty(inputValue),
  onBlur: noop,
  onCreateOption: undefined,
};

export default CreatableSelect;
