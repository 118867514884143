import { map } from 'ramda';
import { camelize } from 'utils/keysConverter';
import CsrfTokenRepository from 'repositories/CsrfTokenRepository';

const INVALID_AUTHENTICITY_TOKEN_RESPONSE_STATUS = 422;
const INVALID_AUTHENTICITY_TOKEN_REASON = 'invalid_authenticity_token';

class InvalidAuthenticityTokenError {
  constructor() {
    this.message = 'InvalidAuthenticityToken';
    this.name = 'InvalidAuthenticityTokenError';
  }
}

export function storeCsrfToken(token) {
  window.csrfToken = token;
}

function refreshCsrfToken() {
  return CsrfTokenRepository.show().then(({ csrfToken }) => storeCsrfToken(csrfToken));
}

function verifyAuthenticationToken(response) {
  if (response instanceof Response && response.status === INVALID_AUTHENTICITY_TOKEN_RESPONSE_STATUS) {
    return response
      .clone()
      .json()
      .catch(() => response)
      .then(camelize)
      .then(({ reason }) => {
        if (reason === INVALID_AUTHENTICITY_TOKEN_REASON) {
          throw new InvalidAuthenticityTokenError();
        }

        return response;
      });
  }

  return response;
}

function retrieveCsrfToken() {
  return window.csrfToken;
}

function wrapRequestInCsrfRetry(func) {
  return (...args) =>
    func(...args)
      .then(verifyAuthenticationToken)
      .catch((error) => {
        if (error instanceof InvalidAuthenticityTokenError) {
          return refreshCsrfToken().then(() => func(...args));
        }

        throw error;
      });
}

function defaultHeaders() {
  return {
    'X-CSRF-TOKEN': retrieveCsrfToken(),
  };
}

const rawMethods = {
  post(url, body, headers, options) {
    return fetch(url, {
      method: 'post',
      credentials: 'same-origin',
      headers: { ...defaultHeaders(), ...headers },
      body,
      ...options,
    });
  },

  get(url, headers) {
    return fetch(url, {
      method: 'get',
      credentials: 'same-origin',
      headers: { ...defaultHeaders(), ...headers },
    });
  },

  put(url, body, headers) {
    return fetch(url, {
      method: 'put',
      credentials: 'same-origin',
      headers: { ...defaultHeaders(), ...headers },
      body,
    });
  },

  patch(url, body, headers) {
    return fetch(url, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: { ...defaultHeaders(), ...headers },
      body,
    });
  },

  delete(url, body, headers) {
    return fetch(url, {
      method: 'delete',
      credentials: 'same-origin',
      headers: { ...defaultHeaders(), ...headers },
      body,
    });
  },
};

const FetchHelpers = map(wrapRequestInCsrfRetry, rawMethods);

export default FetchHelpers;
