import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { isBlank, isPresent } from 'utils/HelperMethods';

import Chart from './components/Chart';
import FiltersForm from './components/FiltersForm';
import Spinner from 'components/Spinner';

import UserPresenter from 'presenters/UserPresenter';
import StatisticPresenter from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';

import styles from './UserDetailStatistic.module.css';

export default class UserDetailStatistic extends Component {
  static propTypes = {
    user: UserPresenter.shape().isRequired,
    loadUserProductivities: PropTypes.func.isRequired,
    initialFilters: PropTypes.shape({}).isRequired,
    filters: PropTypes.shape({}).isRequired,
    statistics: StatisticPresenter.shape(),
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { initialFilters } = this.props;

    this.handleLoadUserProductivities(initialFilters);
  }

  handleLoadUserProductivities = (filters) => {
    const { user, loadUserProductivities } = this.props;

    return loadUserProductivities({ ...filters, userId: user.id });
  };

  handleFilterChange = (filterName) => (value) => {
    const { filters } = this.props;

    return this.handleLoadUserProductivities({ ...filters, [filterName]: value });
  };

  render() {
    const { user, filters, statistics, loading } = this.props;

    const triangleClassNames = cx(styles.triangle, {
      [styles.negative]: isPresent(statistics) && StatisticPresenter.totalValue(statistics) < 0,
    });

    return (
      <div className={styles.root}>
        <div className={styles.headline}>
          <div>{UserPresenter.fullName(user)}</div>
          <div className={styles.count}>
            <span>
              {isPresent(statistics) &&
                StatisticPresenter.formattedTotalValue(statistics, filters.statisticValue, filters.compare)}
            </span>
            {filters.compare && <span className={triangleClassNames} />}
          </div>
        </div>
        {loading || isBlank(statistics) ? (
          <Spinner />
        ) : (
          <>
            <FiltersForm onFilterChange={this.handleFilterChange} filters={filters} />
            <div className={styles.chart}>
              <Chart data={StatisticPresenter.data(statistics)} statisticValue={filters.statisticValue} />
            </div>
          </>
        )}
      </div>
    );
  }
}
