import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from 'grommet';

import Select from 'components/Select';
import Button from 'components/Button';

import { TIME_PERIODS } from 'presenters/TimePeriodPresenter';
import { TIMEFRAMES } from 'presenters/TimeframePresenter';
import { REQUEST_TYPES, STATES } from 'presenters/RequestPresenter';
import { VALUE_OPTIONS, STATISTIC_OPTIONS } from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';

import styles from './FiltersForm.module.css';

const REQUEST_TYPE_OPTIONS = REQUEST_TYPES.map(({ key, title }) => ({ label: title, value: key }));
const REQUEST_STATES_OPTIONS = STATES.map(({ key, title }) => ({ label: title, value: key }));

export default class FiltersForm extends Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
    onFilterChange: PropTypes.func.isRequired,
    exportStatistics: PropTypes.func.isRequired,
  };

  handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) => {
      const { onFilterChange } = this.props;

      onFilterChange(fieldName)(checked);
    };

  handleExportStatistics = () => {
    const { exportStatistics } = this.props;

    exportStatistics();
  };

  render() {
    const { filters, onFilterChange } = this.props;

    return (
      <div className={styles.side}>
        <Button
          className={styles.exportButton}
          label="Export Statistics"
          primary
          onClick={this.handleExportStatistics}
        />
        <div className={styles.filters}>
          <div className={styles.filter}>
            <div className={styles.title}>Request Type</div>
            <div className={styles.select}>
              <Select
                options={REQUEST_TYPE_OPTIONS}
                onValueChange={onFilterChange('requestType')}
                selectedOptionValue={filters.requestType}
                classNamePrefix="filter"
              />
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.title}>Request Final State</div>
            <div className={styles.select}>
              <Select
                options={REQUEST_STATES_OPTIONS}
                onValueChange={onFilterChange('finalState')}
                selectedOptionValue={filters.finalState}
                classNamePrefix="filter"
              />
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.title}>Value</div>
            <div className={styles.select}>
              <Select
                options={VALUE_OPTIONS}
                onValueChange={onFilterChange('statisticValue')}
                selectedOptionValue={filters.statisticValue}
                classNamePrefix="filter"
              />
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.title}>Statistics</div>
            <div className={styles.select}>
              <Select
                options={STATISTIC_OPTIONS}
                onValueChange={onFilterChange('statistic')}
                selectedOptionValue={filters.statistic}
                classNamePrefix="filter"
              />
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.title}>Period</div>
            <div className={styles.select}>
              <Select
                options={TIME_PERIODS}
                onValueChange={onFilterChange('period')}
                selectedOptionValue={filters.period}
                classNamePrefix="filter"
              />
            </div>
          </div>
          <div className={styles.filter}>
            <div className={styles.title}>Timeframe</div>
            <div className={styles.select}>
              <Select
                options={TIMEFRAMES}
                onValueChange={onFilterChange('timeframe')}
                selectedOptionValue={filters.timeframe}
                classNamePrefix="filter"
              />
            </div>
          </div>
          <div className={styles.checkbox}>
            <CheckBox
              label={
                <>
                  Compare with <br />
                  previous timeframe
                </>
              }
              onChange={this.handleCheckboxChange('compare')}
              checked={filters.compare}
              reverse
            />
          </div>
        </div>
      </div>
    );
  }
}
