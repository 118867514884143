import React from 'react';
import PropTypes from 'prop-types';

import Routes from 'routes/App';
import { Box, Tabs, Tab } from 'grommet';
import Spinner from 'components/Spinner';

import EditForm from './components/EditForm';
import PageHeader from 'components/PageHeader';
import ProfileList from 'containers/AdminPanel/containers/ProfileList';
import OrganizationList from 'containers/AdminPanel/containers/OrganizationList';

import TagPresenter from 'presenters/TagPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import styles from './TagPage.module.css';

const TABLE_COLUMNS = [
  {
    name: 'id',
    field: 'id',
    width: '125',
  },
  {
    name: 'status',
    field: 'hasPublished',
  },
  {
    name: 'name',
    field: 'draftVersionLastName,draftVersionFirstName',
  },
  {
    name: 'organization',
    field: 'draftVersionPrimaryCurrentWorkOrganizationActualName',
  },
  {
    name: 'title',
    field: 'draftVersionPrimaryCurrentWorkJobTitleStr',
  },
  {
    name: 'Last Update Date',
    field: 'draftVersionProfileUpdatedAt',
  },
];

export default class TagPage extends React.Component {
  static propTypes = {
    tag: TagPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    loadTag: PropTypes.func.isRequired,
    updateTag: PropTypes.func.isRequired,
    tagId: PropTypes.string.isRequired,
    searchTags: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape(),
  };

  constructor(props) {
    super(props);

    const { tagId } = this.props;
    this.state = {
      profileListFilters: { associatedTagIdsInclude: tagId, sortFields: [] },
      organizationListFilters: { associatedTagIdsInclude: tagId, sortFields: [] },
    };
  }

  componentDidMount() {
    const { loadTag, tagId } = this.props;
    loadTag({ id: tagId });
  }

  handleTagSearch = (title) => {
    const {
      searchTags,
      tag: { id },
    } = this.props;

    return searchTags({ titleCont: title }).then((tags) => tags.filter((item) => item.id !== id));
  };

  handleSubmit = (tag) => {
    const { updateTag, tagId } = this.props;
    return updateTag(tagId, tag);
  };

  handleSortChange = (list) => (sortFields) => {
    this.setState({ ...this.state, [list]: { ...this.state[list], sortFields } });
  };

  render() {
    const { currentUser, tag, loading, saving } = this.props;
    const { profileListFilters, organizationListFilters } = this.state;

    if (loading || !tag) {
      return <Spinner />;
    }

    return (
      <Box margin="none" className={styles.root}>
        <PageHeader title="Edit Tag" backTo={Routes.adminTagListPath()} />
        <div className={styles.tabs}>
          <Tabs justify="start">
            <Tab title="Edit">
              <Box width="medium">
                <EditForm tag={tag} saving={saving} onSubmit={this.handleSubmit} onTagSearch={this.handleTagSearch} />
              </Box>
            </Tab>
            <Tab title="Associated Profiles">
              <ProfileList
                currentUser={currentUser}
                filters={profileListFilters}
                onSortChange={this.handleSortChange('profileListFilters')}
                tableColumns={TABLE_COLUMNS}
              />
            </Tab>
            <Tab title="Associated Organizations">
              <OrganizationList
                currentUser={currentUser}
                filters={organizationListFilters}
                onSortChange={this.handleSortChange('organizationListFilters')}
              />
            </Tab>
          </Tabs>
        </div>
      </Box>
    );
  }
}
