import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { isPresent } from 'utils/HelperMethods';

export default new Presenter(
  {
    id: PropTypes.number,
    jobTitle: PropTypes.string,
    standardizedJobTitle: PropTypes.string,
    organizationName: PropTypes.string,
    organizationIndustry: PropTypes.string,
    jobFunctions: PropTypes.arrayOf(PropTypes.string),
  },
  {
    title(work) {
      const jobTitle = this.jobTitle(work);

      if (isPresent(jobTitle)) {
        return `${jobTitle}, ${this.organizationName(work)}`;
      }

      return this.organizationName(work);
    },
  },
);
