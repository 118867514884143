import TopRelationshipsConstants from './TopRelationshipsConstants';

const initialState = {
  loading: false,
  relationshipsMapping: null,
};

function TopRelationshipsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TopRelationshipsConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_START: {
      return { ...state, loading: true };
    }
    case TopRelationshipsConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_SUCCESS: {
      const { relationshipsMapping } = action;

      return { ...state, relationshipsMapping, loading: false };
    }
    default:
      return state;
  }
}

export default TopRelationshipsReducer;
