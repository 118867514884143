import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckBox, TableRow as GrommetTableRow } from 'grommet';

import styles from './TableRow.module.css';

class TableRow extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    row: PropTypes.shape(),
    isSelected: PropTypes.bool.isRequired,
    children: PropTypes.shape(),
    selectable: PropTypes.bool.isRequired,
  };

  handleClick = () => {
    const { onClick, row } = this.props;

    if (window.getSelection().toString().length === 0) {
      onClick(row.id);
    }
  };

  handleSelect = () => {
    const { row, isSelected, onSelect } = this.props;

    onSelect(row.id, !isSelected);
  };

  stopPropagation = (event) => event.stopPropagation();

  render() {
    const { children, isSelected, row, selectable } = this.props;

    return (
      <GrommetTableRow key={row.id} onClick={this.handleClick}>
        {selectable && (
          // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
          <td onClick={this.stopPropagation} role="presentation" className={styles.checkbox}>
            <CheckBox checked={isSelected} onChange={this.handleSelect} />
          </td>
        )}
        {children}
      </GrommetTableRow>
    );
  }
}

export default TableRow;
