import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent, reactSelectDebounce } from 'utils/HelperMethods';

import { TextInput, Box } from 'grommet';
import AsyncSelect from 'components/AsyncSelect';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import ProfilePresenter from 'presenters/ProfilePresenter';
import CountryPresenter from 'presenters/CountryPresenter';
import StatePresenter from 'presenters/StatePresenter';
import ContactInfoForm from 'forms/profile/ContactInfoForm';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './EditForm.module.css';

class EditForm extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape().isRequired,
    onCountrySearch: PropTypes.func.isRequired,
    onStateSearch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { fields: ContactInfoForm.defaultAttributes(props.profile), errors: null };
  }

  updateField = (fieldName, value) => {
    this.setState({ fields: { ...this.state.fields, [fieldName]: value } });
  };

  handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleStateChange = (state) => {
    const attributes = { state };

    if (state) {
      attributes.country = StatePresenter.country(state);
    }

    this.setState({ fields: { ...this.state.fields, ...attributes } });
  };

  handleCountryChange = (country) => {
    const { fields } = this.state;
    const attributes = { country };

    const stateCountry = fields.state && StatePresenter.country(fields.state);

    if (country && stateCountry && country.id !== stateCountry.id) {
      attributes.state = null;
    }

    this.setState({ fields: { ...fields, ...attributes } });
  };

  handleSubmit = () => {
    this.props
      .onSubmit(ContactInfoForm.attributesToSubmit(this.state.fields))
      .catch(({ errors }) => this.setState({ errors }));
  };

  handleCountrySearch = reactSelectDebounce(this.props.onCountrySearch);

  handleStateSearch = reactSelectDebounce(this.props.onStateSearch);

  organizationValue(fieldName) {
    const { profile } = this.props;
    const organization = ProfilePresenter.currentOrganization(profile);

    return isPresent(organization) ? OrganizationPresenter[fieldName](organization) : null;
  }

  renderOrganizationValue(label, value) {
    return (
      <div className={styles.organization}>
        <div className={styles.organizationPlaceholder}> {label} </div>
        <div>{value}</div>
      </div>
    );
  }

  renderTextFieldWithOrganizationFallback(label, fieldName, organizationFieldName) {
    const {
      fields: { [fieldName]: value },
      errors,
    } = this.state;
    const { [fieldName]: fieldErrors } = errors || {};
    const organizationValue = this.organizationValue(organizationFieldName);

    return (
      <div>
        <VerticalFormFieldWithBottomErrors
          className={styles.formField}
          label={label}
          errors={fieldErrors}
          htmlFor={fieldName}
        >
          <TextInput value={value} onChange={this.handleFieldChange(fieldName)} id={fieldName} />
        </VerticalFormFieldWithBottomErrors>
        {this.renderOrganizationValue(`Organization ${label}`, organizationValue)}
      </div>
    );
  }

  render() {
    const { saving } = this.props;
    const { fields, errors } = this.state;
    const organizationState = this.organizationValue('state');
    const organizationCountry = this.organizationValue('country');

    return (
      <div>
        {this.renderTextFieldWithOrganizationFallback('Address 1:', 'address1', 'address')}
        {this.renderTextFieldWithOrganizationFallback('Address 2:', 'address2', 'address2')}
        {this.renderTextFieldWithOrganizationFallback('City:', 'city', 'city')}
        <div>
          <AsyncSelect
            placeholder="State:"
            loadOptions={this.handleStateSearch}
            defaultOptions
            isClearable
            onChange={this.handleStateChange}
            value={fields.state}
            getOptionValue={StatePresenter.id}
            getOptionLabel={StatePresenter.nameWithCountry}
            noBottom
          />
          {this.renderOrganizationValue('Organization State:', organizationState && organizationState.name)}
        </div>
        {this.renderTextFieldWithOrganizationFallback('Zip:', 'zip', 'zip')}
        <div>
          <AsyncSelect
            placeholder="Country:"
            loadOptions={this.handleCountrySearch}
            defaultOptions
            isClearable
            onChange={this.handleCountryChange}
            value={fields.country}
            getOptionValue={CountryPresenter.id}
            getOptionLabel={CountryPresenter.name}
            noBottom
          />
          {this.renderOrganizationValue('Organization Country:', organizationCountry && organizationCountry.name)}
        </div>
        <VerticalFormFieldWithBottomErrors label="Direct Telephone:" errors={errors && errors.phone} htmlFor="phone">
          <TextInput value={fields.phone} onChange={this.handleFieldChange('phone')} id="phone" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors label="E-mail Address:" errors={errors && errors.email} htmlFor="email">
          <TextInput value={fields.email} onChange={this.handleFieldChange('email')} id="email" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors
          label="Twitter URL:"
          errors={errors && errors.twitterUrl}
          htmlFor="twitterUrl"
        >
          <TextInput
            placeholder="http:// or https://"
            value={fields.twitterUrl}
            onChange={this.handleFieldChange('twitterUrl')}
            id="twitterUrl"
          />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors
          label="Facebook URL:"
          errors={errors && errors.facebookUrl}
          htmlFor="facebookUrl"
        >
          <TextInput
            placeholder="http:// or https://"
            value={fields.facebookUrl}
            onChange={this.handleFieldChange('facebookUrl')}
            id="facebookUrl"
          />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors
          label="LinkedIn URL:"
          errors={errors && errors.linkedinUrl}
          htmlFor="linkedinUrl"
        >
          <TextInput
            placeholder="http:// or https://"
            value={fields.linkedinUrl}
            onChange={this.handleFieldChange('linkedinUrl')}
            id="linkedinUrl"
          />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors label="Blog URL:" errors={errors && errors.blogUrl} htmlFor="blogUrl">
          <TextInput
            placeholder="http:// or https://"
            value={fields.blogUrl}
            onChange={this.handleFieldChange('blogUrl')}
            id="blogUrl"
          />
        </VerticalFormFieldWithBottomErrors>
        <Box direction="row" margin={{ top: 'small' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default EditForm;
