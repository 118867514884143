import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import ApiV2AccessSettingsRepository from 'repositories/admin/Companies/Departments/ApiV2AccessSettingsRepository';

const restSlice = createRestSlice({
  resource: 'apiV2AccessSetting',
  repository: ApiV2AccessSettingsRepository,
  slices: ['loadApiV2AccessSetting', 'updateApiV2AccessSetting'],
});

const initialState = {
  ...restSlice.initialState,
};

const apiV2AccessSettingsSlice = createSlice({
  name: 'adminCompanyDepartmentApiV2AccessSettings',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDepartmentApiV2AccessSettingsReducer = apiV2AccessSettingsSlice.reducer;

export default apiV2AccessSettingsSlice;

export const adminCompanyDepartmentApiV2AccessSettingsRestHooks = restSlice.hooks(
  apiV2AccessSettingsSlice,
  'adminCompanyDepartmentApiV2AccessSettingsReducer',
);
