import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formatNumber } from 'utils/NumbersHelper';

export default new Presenter(
  {
    lessThanSixMonths: PropTypes.number,
    betweenSixMonthsAndYear: PropTypes.number,
    overOneYear: PropTypes.number,
  },
  {
    formattedLessThanSixMonths(r) {
      return formatNumber(this.lessThanSixMonths(r));
    },
    formattedBetweenSixMonthsAndYear(r) {
      return formatNumber(this.betweenSixMonthsAndYear(r));
    },
    formattedOverOneYear(r) {
      return formatNumber(this.overOneYear(r));
    },
  },
);
