import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import { Paragraph, Box, Anchor } from 'grommet';
import {
  Blog as BlogIcon,
  Linkedin as SocialLinkedin,
  Facebook as SocialFacebook,
  Twitter as SocialTwitter,
} from 'grommet-icons';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';

import Modal from 'components/Modal';
import EditableContent from 'components/EditableContent';
import EditForm from './components/EditForm';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import styles from './ContactInfo.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';

class ContactInfo extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    profile: ProfilePresenter.shape(),
    onUpdate: PropTypes.func.isRequired,
    onCountrySearch: PropTypes.func.isRequired,
    onStateSearch: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
    isCurrentOrganizationChanged: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { edit: false };
  }

  handleEdit = () => this.setState({ edit: true });

  handleCloseModal = () => this.setState({ edit: false });

  handleSubmit = (profile) => this.props.onUpdate(profile).then(this.handleCloseModal);

  render() {
    const {
      editable,
      onCountrySearch,
      onStateSearch,
      profile,
      saving,
      isChanged,
      isCurrentOrganizationChanged,
      onReviewChanges,
    } = this.props;

    const editableContentClassName = cx({ [styles.warningFrame]: isCurrentOrganizationChanged });

    return (
      <div className={styles.root}>
        <EditableContent
          className={editableContentClassName}
          editable={editable}
          onEdit={this.handleEdit}
          tooltipComponent={StyleGuides.ContactInformationStyleGuide}
        >
          <HeadlineWithReviewChangesLink
            strong
            size="small"
            margin="none"
            className={styles.headline}
            title="Contact Information"
            isChanged={isChanged}
            onReviewChanges={onReviewChanges}
          />
          <Box margin={{ vertical: 'medium' }} flex="grow" align="start">
            <Paragraph margin="none" size="16px">
              {ProfilePresenter.address1WithFallback(profile)} <br /> {ProfilePresenter.address2WithFallback(profile)}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {ProfilePresenter.cityWithStateAndZipCode(profile)}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {ProfilePresenter.countryName(profile)}
            </Paragraph>
            <Paragraph margin="none" size="16px">
              {ProfilePresenter.phoneWithFallback(profile)}
            </Paragraph>
            <Anchor
              animateIcon={false}
              href={`mailto:${ProfilePresenter.emailWithFallback(profile)}`}
              className={styles.email}
            >
              {ProfilePresenter.emailWithFallback(profile)}
            </Anchor>
          </Box>
          <Box direction="row" className={styles.socialLinks}>
            {ProfilePresenter.linkedinUrl(profile) && (
              <Anchor
                animateIcon={false}
                href={ProfilePresenter.linkedinUrl(profile)}
                icon={<SocialLinkedin color="plain" />}
                target="_blank"
              />
            )}
            {ProfilePresenter.facebookUrl(profile) && (
              <Anchor
                animateIcon={false}
                href={ProfilePresenter.facebookUrl(profile)}
                icon={<SocialFacebook color="plain" />}
                target="_blank"
              />
            )}
            {ProfilePresenter.twitterUrl(profile) && (
              <Anchor
                animateIcon={false}
                href={ProfilePresenter.twitterUrl(profile)}
                icon={<SocialTwitter color="plain" />}
                target="_blank"
              />
            )}
            {ProfilePresenter.blogUrl(profile) && (
              <Anchor href={ProfilePresenter.blogUrl(profile)} icon={<BlogIcon color="plain" />} target="_blank" />
            )}
          </Box>
        </EditableContent>
        {this.state.edit ? (
          <Modal
            onClose={this.handleCloseModal}
            size="medium"
            header="Contact information"
            disableOverlayClose
            disableCloseByEsc
          >
            <EditForm
              profile={profile}
              onCountrySearch={onCountrySearch}
              onStateSearch={onStateSearch}
              onSubmit={this.handleSubmit}
              saving={saving}
            />
          </Modal>
        ) : null}
        {isCurrentOrganizationChanged && (
          <div className={styles.warningBlock}>
            Current organization have changed, please check correctness of the address
          </div>
        )}
      </div>
    );
  }
}

export default ContactInfo;
