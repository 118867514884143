import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import { any, map, flatten, pipe, uniq } from 'ramda';
import Presenter from '../utils/PropTypesPresenter';
import WorkPresenter from './WorkPresenter';
import TopicPresenter from './TopicPresenter';
import BoardPresenter from './BoardPresenter';
import InterestPresenter from './InterestPresenter';
import InstitutionPresenter from './InstitutionPresenter';
import CountryPresenter from './CountryPresenter';
import StatePresenter from './StatePresenter';
import OrganizationPresenter from './OrganizationPresenter';
import TagPresenter from './TagPresenter';
import JobFunctionPresenter from './JobFunctionPresenter';
import LanguagePresenter from './LanguagePresenter';
import { formattedDate } from 'utils/DateTime';

export const GENDERS = [
  { key: 'male', title: 'Male' },
  { key: 'female', title: 'Female' },
];

export const PROFILES_PDF_EXPORT_JOB_TYPE = 'ExportJob::ProfilesPdfExportJob';
export const PROFILE_PDF_EXPORT_JOB_TYPE = 'ExportJob::ProfilePdfExportJob';
export const FILTERED_SEARCH_PROFILES_CSV_EXPORT_JOB_TYPE = 'ExportJob::FilteredSearchProfilesCsvExportJob';
export const ADMIN_PROFILES_CSV_EXPORT_JOB_TYPE = 'ExportJob::Admin::ProfilesCsvExportJob';

function withFallbackToOrganization(field, fallbackField) {
  return function (p) {
    const value = this[field](p);
    const primaryCurrentWork = this.primaryCurrentWork(p);

    if (isPresent(primaryCurrentWork) && WorkPresenter.retired(primaryCurrentWork)) {
      return null;
    }

    if (this.isAnyAddressFieldsPresent(p)) {
      return value;
    }

    const organization = this.currentOrganization(p);

    return isPresent(organization) ? organization[fallbackField] : null;
  };
}

export default new Presenter(
  {
    id: PropTypes.number,
    slug: PropTypes.string,
    primaryCurrentWork: WorkPresenter.shape(),
    currentWorks: PropTypes.arrayOf(WorkPresenter.shape()),
    works: PropTypes.arrayOf(WorkPresenter.shape()),
    currentFocusTopics: PropTypes.arrayOf(TopicPresenter.shape()),
    keyChallengesTopics: PropTypes.arrayOf(TopicPresenter.shape()),
    birthday: PropTypes.string,
    sources: PropTypes.string,
    profileUpdatedAt: PropTypes.string,
    fullName: PropTypes.string,
    orgName: PropTypes.string,
    briefBiography: PropTypes.string,
    publicPersonInformation: PropTypes.string,
    biography: PropTypes.string,
    biohighlights: PropTypes.string,
    personalInterests: PropTypes.arrayOf(PropTypes.shape()),
    gender: PropTypes.string,
    deceased: PropTypes.bool,
    salutation: PropTypes.string,
    commonName: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: StatePresenter.shape(),
    zip: PropTypes.string,
    country: CountryPresenter.shape(),
    phone: PropTypes.string,
    email: PropTypes.string,
    twitterUrl: PropTypes.string,
    blogUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    profileUrl: PropTypes.string,
    newsLinks: PropTypes.string,
    photoUrl: PropTypes.string,
    photoUpdatedAt: PropTypes.string,
    request: PropTypes.shape(),
    publishedAt: PropTypes.string,
    boards: PropTypes.arrayOf(BoardPresenter.shape()),
    fortune500Boards: PropTypes.arrayOf(BoardPresenter.shape()),
    ftse100Boards: PropTypes.arrayOf(BoardPresenter.shape()),
    institutions: PropTypes.arrayOf(InstitutionPresenter.shape()),
    isUpdateRequestAvailable: PropTypes.bool,
    profileInterests: PropTypes.arrayOf(InterestPresenter.shape()),
    interests: PropTypes.arrayOf(InterestPresenter.shape()),
    optedOut: PropTypes.bool,
    optedOutAt: PropTypes.string,
    hasPublishedVersion: PropTypes.bool,
    visibilityStatus: PropTypes.string,
    personalInformationDigest: PropTypes.string,
    contactInformationDigest: PropTypes.string,
    tags: PropTypes.arrayOf(TagPresenter.shape()),
    highlights: PropTypes.arrayOf(PropTypes.shape()),
    lastUpdatedAt: PropTypes.string,
    languages: PropTypes.arrayOf(LanguagePresenter.shape()),
    originCountry: CountryPresenter.shape(),
    isNewToRole: PropTypes.bool,
    isBriefBiographyRecentlyUpdated: PropTypes.bool,
    isPublicPersonInformationRecentlyUpdated: PropTypes.bool,
    isMasked: PropTypes.bool,
    shouldShowGuide: PropTypes.bool,
    hasFortune500BoardMemberOrRelevantBoard: PropTypes.bool,
    hasFtse100BoardMember: PropTypes.bool,
    currentFocusHiddenUuids: PropTypes.arrayOf(PropTypes.string),
    keyChallengesHiddenUuids: PropTypes.arrayOf(PropTypes.string),
  },
  {
    fullNameWithSalutation(p) {
      return this.salutation(p) ? `${this.salutation(p)} ${this.fullName(p)}` : this.fullName(p);
    },

    lastNameWithFirstName(p) {
      return `${this.lastName(p)}, ${this.firstName(p)}`;
    },

    firstNameWithLastName(p) {
      return `${this.firstName(p)} ${this.lastName(p)}`;
    },

    currentOrganization(p) {
      const primaryCurrentWork = this.primaryCurrentWork(p);

      return isPresent(primaryCurrentWork) ? WorkPresenter.organization(primaryCurrentWork) : null;
    },

    currentOrganizationId(p) {
      const currentOrganization = this.currentOrganization(p);

      return isPresent(currentOrganization) ? OrganizationPresenter.id(currentOrganization) : null;
    },

    primaryCurrentWorkTitle(p) {
      const primaryCurrentWork = this.primaryCurrentWork(p);

      return primaryCurrentWork ? WorkPresenter.title(primaryCurrentWork) : '';
    },

    bothAddresses(p) {
      const checkNull = (adr) => (adr === null ? '' : adr);
      return `${checkNull(this.address1WithFallback(p))} ${checkNull(this.address2WithFallback(p))}`;
    },

    cityWithStateAndZipCode(p) {
      const state = this.stateWithFallback(p);
      const stateName = state ? StatePresenter.name(state) : null;
      const address = [this.cityWithFallback(p), stateName, this.zipWithFallback(p)];
      const filtered = address.filter(isPresent);

      return filtered.join(', ');
    },

    formattedPhotoUpdatedAt(p) {
      return formattedDate(this.photoUpdatedAt(p));
    },

    formattedProfileUpdatedAt(p) {
      return formattedDate(this.profileUpdatedAt(p));
    },

    formattedLastUpdatedAt(p) {
      return formattedDate(this.lastUpdatedAt(p));
    },

    formattedPublishedAt(p) {
      return formattedDate(this.publishedAt(p));
    },

    countryName(p) {
      const country = this.countryWithFallback(p);

      return country ? CountryPresenter.name(country) : null;
    },

    originCountryId(p) {
      const originCountry = this.originCountry(p);

      return originCountry ? CountryPresenter.id(originCountry) : null;
    },

    isHidden(p) {
      const visibilityStatus = this.visibilityStatus(p);

      return visibilityStatus === 'hidden';
    },

    isAnyAddressFieldsPresent(p) {
      const addressFields = ['address1', 'address2', 'city', 'state', 'zip', 'country'];
      const addressValues = addressFields.map((field) => this[field](p));

      return any(isPresent, addressValues);
    },

    emailWithFallback(p) {
      const primaryCurrentWork = this.primaryCurrentWork(p);

      if (isPresent(primaryCurrentWork) && WorkPresenter.retired(primaryCurrentWork)) {
        return null;
      }

      return this.email(p);
    },

    phoneWithFallback(p) {
      const primaryCurrentWork = this.primaryCurrentWork(p);

      if (isPresent(primaryCurrentWork) && WorkPresenter.retired(primaryCurrentWork)) {
        return null;
      }

      return this.phone(p);
    },

    address1WithFallback: withFallbackToOrganization('address1', 'address'),
    address2WithFallback: withFallbackToOrganization('address2', 'address2'),
    cityWithFallback: withFallbackToOrganization('city', 'city'),
    stateWithFallback: withFallbackToOrganization('state', 'state'),
    zipWithFallback: withFallbackToOrganization('zip', 'zip'),
    countryWithFallback: withFallbackToOrganization('country', 'country'),

    currentWorksJobFunctions(p, allJobFunctions) {
      const currentWorks = this.currentWorks(p);

      return pipe(
        map((work) => WorkPresenter.jobFunctions(work, allJobFunctions)),
        flatten,
        uniq,
      )(currentWorks);
    },

    currentWorksJobFunctionsStr(p, jobFunctions) {
      return this.currentWorksJobFunctions(p, jobFunctions).map(JobFunctionPresenter.name).join(', ');
    },

    isCurrentFocusTopicsRecentlyUpdated(p) {
      const currentFocusTopics = this.currentFocusTopics(p);

      return any(TopicPresenter.isRecentlyUpdated, currentFocusTopics);
    },

    isKeyChallengesTopicsRecentlyUpdated(p) {
      const keyChallengesTopics = this.keyChallengesTopics(p);

      return any(TopicPresenter.isRecentlyUpdated, keyChallengesTopics);
    },

    isWorksRecentlyUpdated(p) {
      const works = this.works(p);

      return any(WorkPresenter.isRecentlyUpdated, works);
    },

    isBoardsRecentlyUpdated(p) {
      const boards = this.boards(p);

      return any(BoardPresenter.isRecentlyUpdated, boards);
    },

    isInstitutionsRecentlyUpdated(p) {
      const institutions = this.institutions(p);

      return any(InterestPresenter.isRecentlyUpdated, institutions);
    },

    isGdprProtected(p) {
      const country = this.country(p);

      return CountryPresenter.isGdpr(country);
    },
  },
);
