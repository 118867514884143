import React from 'react';
import PropTypes from 'prop-types';
import { propEq, propOr } from 'ramda';

import DownIcon from 'icons/DownIcon';
import UpIcon from 'icons/UpIcon';

import Button from 'components/v2/Button';

import styles from './TableHeadCell.module.css';

const NEXT_DIRECTION_MAPPING = {
  asc: 'desc',
  desc: 'asc',
  none: 'asc',
};

const SORT_DIRECTION_ICON_MAPPING = {
  asc: UpIcon,
  desc: DownIcon,
};

const TableHeadCell = (props) => {
  const { children, field, sortable, sortings, onSortingChange, ...rest } = props;

  const sort = sortings.find(propEq(field, 'field'));
  const direction = propOr('none', 'direction', sort);
  const Icon = propOr('div', direction, SORT_DIRECTION_ICON_MAPPING);

  const handleSortChange = () => onSortingChange({ field, direction: NEXT_DIRECTION_MAPPING[direction] });

  return (
    <th {...rest} className={styles.tableHeadCell}>
      <Button className={styles.tableHeadCellButton} disabled={!sortable} onClick={handleSortChange} variant="icon">
        {children}
        <Icon className={styles.icon} />
      </Button>
    </th>
  );
};

TableHeadCell.propTypes = {
  children: PropTypes.node,
  field: PropTypes.string,
  sortable: PropTypes.bool,
  sortings: PropTypes.arrayOf(
    PropTypes.shape({ field: PropTypes.string.isRequired, direction: PropTypes.oneOf(['desc', 'asc']) }),
  ),
  onSortingChange: PropTypes.func,
};

TableHeadCell.defaultProps = {
  field: null,
  sortable: false,
  sortings: [],
  onSortingChange: () => {},
};

export default TableHeadCell;
