import React, { useEffect } from 'react';
import { isPresent } from 'utils/HelperMethods';

import { useAdminEditorialDashboardDatabaseAnalyticFtse100ProfilesStatistics } from 'hooks/admin';

import Spinner from 'components/Spinner';

import Ftse100ProfilesStatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/Ftse100ProfilesStatisticsPresenter';
import TopFtse100OrganizationsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/Ftse100ProfilesStatistics/TopFtse100OrganizationsPresenter';

import styles from './Ftse100ProfilesStatistics.module.css';

const Ftse100ProfilesStatistics = () => {
  const {
    editorialDashboardFtse100ProfilesStatistic: statistics,
    editorialDashboardFtse100ProfilesStatisticLoadingStatus: loadingStatus,
    loadEditorialDashboardFtse100ProfilesStatistic,
  } = useAdminEditorialDashboardDatabaseAnalyticFtse100ProfilesStatistics();

  const topFtse100Organizations = Ftse100ProfilesStatisticsPresenter.topFtse100Organizations(statistics);

  const isShowStatistic = loadingStatus.isFulfilled || isPresent(statistics);

  useEffect(() => {
    loadEditorialDashboardFtse100ProfilesStatistic();
  }, []);

  return (
    <div>
      <h3 className={styles.header}>FTSE 100 Profiles</h3>
      {isShowStatistic ? (
        <>
          <div className={styles.item}>
            <div className={styles.title}>Total Profiles:</div>
            <div className={styles.number}>
              {Ftse100ProfilesStatisticsPresenter.formattedTotalProfilesNumber(statistics)}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Avg. Profiles Per Company:</div>
            <div className={styles.number}>
              {Ftse100ProfilesStatisticsPresenter.formattedAvgProfilesNumberPerCompany(statistics)}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Total Board Members:</div>
            <div className={styles.number}>
              {Ftse100ProfilesStatisticsPresenter.formattedTotalBoardMembersNumber(statistics)}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.mostProfilesRoot}>
              <div className={styles.title}>Most Profiles:</div>
              {topFtse100Organizations.map((organization) => (
                <div
                  key={TopFtse100OrganizationsPresenter.companyName(organization)}
                  className={styles.mostProfilesItem}
                >
                  <div className={styles.mostProfileName}>
                    {TopFtse100OrganizationsPresenter.companyName(organization)}:
                  </div>
                  <div className={styles.number}>
                    {TopFtse100OrganizationsPresenter.formattedProfilesNumber(organization)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Ftse100ProfilesStatistics;
