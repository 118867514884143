import { useState, useEffect } from 'react';
import CurrentUserRepository from 'repositories/CurrentUserRepository';

const useCurrentUserRemainingRequestsNumber = (initialValue = 0) => {
  const [requestNumber, setRequestsNumber] = useState(initialValue);

  const refreshRemainingRequestNumber = () =>
    CurrentUserRepository.show().then(({ user }) => {
      setRequestsNumber(user?.remainingRequestsNumber || initialValue);
    });

  useEffect(() => {
    refreshRemainingRequestNumber();
  }, []);

  return [requestNumber, refreshRemainingRequestNumber];
};

export default useCurrentUserRemainingRequestsNumber;
