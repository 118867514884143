import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserDetailStatistic from './UserDetailStatistic';
import * as actions from './UserDetailStatisticActions';

const mapStateToProps = (state) => ({
  loading: state.UserDetailStatisticReducer.loading,
  statistics: state.UserDetailStatisticReducer.statistics,
  filters: state.UserDetailStatisticReducer.filters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailStatistic);
