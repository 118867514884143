import keyMirror from 'keymirror';

const RequestFormConstants = keyMirror({
  INITIALIZE_REQUEST_FORM: null,
  RESET_REQUEST_FORM: null,

  LOAD_REQUEST_START: null,
  LOAD_REQUEST_SUCCESS: null,
  LOAD_REQUEST_ERROR: null,

  UPDATE_REQUEST_START: null,
  UPDATE_REQUEST_SUCCESS: null,
  UPDATE_REQUEST_ERROR: null,

  ASSIGN_EDITOR_TO_REQUEST_START: null,
  ASSIGN_EDITOR_TO_REQUEST_SUCCESS: null,
  ASSIGN_EDITOR_TO_REQUEST_ERROR: null,

  CREATE_REQUEST_PROFILE_START: null,
  CREATE_REQUEST_PROFILE_SUCCESS: null,
  CREATE_REQUEST_PROFILE_ERROR: null,
});

export default RequestFormConstants;
