import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import { compose } from 'ramda';
import { Button } from 'grommet';

import styles from './ErrorPage.module.css';

const DEFAULT_ERROR_DESCRIPTION = 'Something Went Wrong';

const ERRORS_DESCRIPTIONS = {
  401: 'Forbidden',
  403: 'Forbidden',
  404: 'Page not found',
  414: 'Request URI Too Long',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable Error',
  504: 'Gateway Timeout Error',
};

class ErrorPage extends Component {
  static propTypes = {
    history: PropTypes.shape(),
    code: PropTypes.string,
    text: PropTypes.string,
  };

  errorMessage = () => {
    const { code, text } = this.props;

    if (isPresent(text)) {
      return text;
    }

    return ERRORS_DESCRIPTIONS[code] || DEFAULT_ERROR_DESCRIPTION;
  };

  render() {
    const { code, history } = this.props;
    const errorMessage = this.errorMessage();

    return (
      <div className={styles.content}>
        <div className={styles.main}>
          <h3 className={styles.number}>{code}</h3>
          <h1 className={styles.text}>{errorMessage}</h1>
          <Button label="Back" primary className={styles.button} onClick={() => history.goBack()} />
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(ErrorPage);
