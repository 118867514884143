import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AgingStatistics from './AgingStatistics';
import * as actions from './AgingStatisticsActions';

const mapStateToProps = (state) => ({
  loading: state.AgingStatisticsReducer.loading,
  agingStatistics: state.AgingStatisticsReducer.agingStatistics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgingStatistics);
