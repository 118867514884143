import keyMirror from 'keymirror';

const ProfileListPageConstants = keyMirror({
  INITIALIZE_PROFILE_LIST_PAGE: null,

  PROFILE_CREATE: null,
  PROFILE_CREATE_SUCCESS: null,
  PROFILE_CREATE_ERROR: null,

  PROFILES_CHANGE_FILTERS: null,
});

export default ProfileListPageConstants;
