import React, { Component } from 'react';
import Routes from 'routes';
import { Anchor } from 'grommet';

import styles from './Menu.module.css';

export default class Menu extends Component {
  render() {
    return (
      <>
        <Anchor animateIcon={false} className={styles.item} href={Routes.productsUrl()} target="_blank">
          <span>Products</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.guidesUrl()} target="_blank">
          <span>Guides</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.articlesUrl()} target="_blank">
          <span>Articles</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.contactUsUrl()} target="_blank">
          <span>Contact Us</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.sampleProfilesPath()} target="_blank">
          <span>View Sample Profile</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.privacyUrl()} target="_blank">
          <span>Privacy Policy</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.termsOfUseUrl()} target="_blank">
          <span>Terms and Conditions</span>
        </Anchor>
        <Anchor animateIcon={false} className={styles.item} href={Routes.slaveryStatementUrl()} target="_blank">
          <span>Modern Slavery Statement</span>
        </Anchor>
      </>
    );
  }
}
