import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text } from 'grommet';

import styles from './RequestStatisticBlock.module.css';

const REQUEST_TYPES = [
  { key: 'newProfileRequestCount', title: 'New Profile Request' },
  { key: 'updateRequestCount', title: 'Update Requests' },
  { key: 'newsUpdateCount', title: 'News Updates' },
  { key: 'mostViewedUpdateCount', title: 'Most Viewed Updates' },
];

export default class RequestStatisticBlock extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadRequestStatistic: PropTypes.func.isRequired,
    statistic: PropTypes.shape(),
  };

  componentDidMount() {
    const { loadRequestStatistic } = this.props;

    loadRequestStatistic({});
  }

  renderRequestsInfo = ({ key, title }) => {
    const { loading, statistic } = this.props;
    const value = loading || !statistic ? '?' : statistic[key];

    return (
      <Box key={key} direction="column" align="start" margin={{ right: 'large' }}>
        <Heading level="5" className={styles.valuesText} margin="none">
          {title}
        </Heading>
        <Box align="center" className={styles.value}>
          <Text size="large">
            <strong>{value}</strong>
          </Text>
        </Box>
      </Box>
    );
  };

  render() {
    return (
      <Box direction="row" margin={{ bottom: 'large' }}>
        {REQUEST_TYPES.map(this.renderRequestsInfo)}
      </Box>
    );
  }
}
