import { buildFilterParams } from 'utils/FilterParamsBuilder';
import UserPageConstants from './UserPageConstants';
import UsersRepository from 'repositories/admin/UsersRepository';
import CompaniesRepository from 'repositories/admin/CompaniesRepository';
import DepartmentsRepository from 'repositories/admin/Companies/DepartmentsRepository';
import ResetPasswordRepository from 'repositories/ResetPasswordRepository';
import { addNotification } from 'containers/Notifications';

export function loadUser({ id }) {
  return (dispatch) => {
    dispatch({ type: UserPageConstants.LOAD_USER_START });

    return UsersRepository.show(id).then(({ user }) => {
      dispatch({ type: UserPageConstants.LOAD_USER_SUCCESS, user });
    });
  };
}

export function updateUser(id, params) {
  return (dispatch) => {
    dispatch({ type: UserPageConstants.UPDATE_USER_START });

    return UsersRepository.update(id, params)
      .then(({ user }) => {
        dispatch({ type: UserPageConstants.UPDATE_USER_SUCCESS, user });
        addNotification('User was successfully updated', 'normal')(dispatch);

        return user;
      })
      .catch((json) => {
        dispatch({ type: UserPageConstants.UPDATE_USER_ERROR });
        throw json;
      });
  };
}

export function searchCompanies(filters) {
  return () => {
    const query = buildFilterParams(filters);

    return CompaniesRepository.index(query).then(({ items }) => items);
  };
}

export function searchCompanyDepartments(companyId, filters) {
  const query = buildFilterParams(filters);

  return () => DepartmentsRepository.index(companyId, query).then(({ items }) => items);
}

export function sendResetPasswordEmail(params) {
  return (dispatch) => {
    dispatch({ type: UserPageConstants.SEND_USER_RESET_PASSWORD_EMAIL_START });

    return ResetPasswordRepository.create(params)
      .then(() => {
        dispatch({ type: UserPageConstants.SEND_USER_RESET_PASSWORD_EMAIL_SUCCESS });

        addNotification('Reset Password Email was successfully sent', 'normal')(dispatch);
      })
      .catch((error) => {
        dispatch({ type: UserPageConstants.SEND_USER_RESET_PASSWORD_EMAIL_ERROR });

        addNotification('Some errors appeared during the sending of the Reset Password Email', 'critical')(dispatch);

        throw error;
      });
  };
}
