import React, { Component } from 'react';
import Icon from 'components/Icon';
import ContentWrapper from 'components/ContentWrapper';
import { Anchor } from 'grommet';
import { currentYear } from 'utils/DateTime.js';
import Routes from 'routes';
import styles from './Footer.module.css';
import Menu from './components/Menu';

import cn from 'clsx';

class Footer extends Component {
  render() {
    const footerStyles = cn([styles.footer], 'footerPadding');
    return (
      <footer className={footerStyles}>
        <ContentWrapper>
          <div className={styles.footerContent}>
            <div>
              <div className={styles.nav}>
                <Menu />
              </div>
              <div className={styles.socialIcons}>
                <Anchor
                  icon={<Icon name="twitter" />}
                  animateIcon={false}
                  className={styles.socialIcon}
                  href={Routes.twitterUrl()}
                  target="_blank"
                />
                <Anchor
                  icon={<Icon name="linkedin" />}
                  animateIcon={false}
                  className={styles.socialIcon}
                  href={Routes.linkedinUrl()}
                  target="_blank"
                />
              </div>
            </div>
            <div className={styles.copyright}>
              © {currentYear()} Boardroom Insiders, Inc, a part of Delinian Limited. All rights reserved <br />
              All trademarks are the property of their respective owners
            </div>
          </div>
        </ContentWrapper>
      </footer>
    );
  }
}

export default Footer;
