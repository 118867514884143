/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import LimitedAccessRepository from 'repositories/CurrentUser/LimitedAccessRepository';

const initialState = {
  isLoading: true,
  limitedAccess: {
    criterias: [],
  },
};

const limitedAccessSlice = createSlice({
  name: 'limitedAccess',
  initialState,
  reducers: {
    loadLimitedAccessStart(state) {
      state.isLoading = true;
    },
    loadLimitedAccessSuccess(state, { payload }) {
      state.limitedAccess = payload;
      state.isLoading = false;
    },
  },
});

const { actions } = limitedAccessSlice;

export function loadLimitedAccess() {
  return (dispatch) => {
    dispatch(actions.loadLimitedAccessStart());

    return LimitedAccessRepository.show().then(({ limitedAccess }) => {
      dispatch(actions.loadLimitedAccessSuccess(limitedAccess));
    });
  };
}

export const useCurrentUserLimitedAccessActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      loadLimitedAccess,
    },
    dispatch,
  );
};

export default limitedAccessSlice.reducer;
