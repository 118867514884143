import React from 'react';
import { imagePath } from 'utils/AssetsHelper';

import styles from './CreationInProgress.module.css';

const CreationInProgress = () => (
  <div className={styles.loader}>
    <img className={styles.loaderImg} src={imagePath('project-match-executives-loader.gif')} alt="loading" />
  </div>
);

export default CreationInProgress;
