import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Tooltip from 'rc-tooltip';
import { Trash as TrashIcon } from 'grommet-icons';

class DeleteRowTableAction extends Component {
  static propTypes = {
    selectedRowIds: PropTypes.arrayOf(PropTypes.number),
    deleteRows: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  };

  handleDelete = () => {
    const { deleteRows, selectedRowIds } = this.props;

    deleteRows(selectedRowIds);
  };

  tooltip = () => <div> One or few selected items can&#39;t be deleted</div>;

  render() {
    const { isDisabled, selectedRowIds } = this.props;

    return (
      <Tooltip overlay={this.tooltip} trigger={isDisabled ? ['hover'] : []} destroyTooltipOnHide>
        <Button
          disabled={isDisabled}
          plain
          icon={<TrashIcon className="grommet_legacy-control-icon-trash" />}
          label={`Delete (${selectedRowIds.length})`}
          onClick={this.handleDelete}
          className="grommet_legacy-button-trash"
        />
      </Tooltip>
    );
  }
}

export default DeleteRowTableAction;
