import React from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import { Anchor } from 'grommet';
import { View as ViewIcon, DocumentCsv as CsvIcon } from 'grommet-icons';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'components/ExportButton';

import UserPresenter from 'presenters/UserPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import UserPolicy from 'policies/UserPolicy';

import styles from './UserRowCells.module.css';

const UserRowCells = (props) => {
  const { row: user, currentUser, onImpersonate, onExport, onPoll } = props;
  const companyDepartment = UserPresenter.companyDepartment(user);

  const stopPropagation = (event) => event.stopPropagation();

  const handleImpersonate = (event) => {
    stopPropagation(event);
    event.preventDefault();

    onImpersonate(user.id);
  };

  const handleExport = (event) => {
    stopPropagation(event);

    return onExport(user);
  };

  return (
    <>
      <td>{UserPresenter.fullName(user)}</td>
      <td>{UserPresenter.email(user)}</td>
      <td>{isPresent(companyDepartment) && CompanyDepartmentPresenter.name(companyDepartment)}</td>
      <td>{UserPresenter.signInEventsCount(user)}</td>
      <td>{UserPresenter.viewAndDownloadEventsCount(user)}</td>
      <td>{UserPresenter.publishedRequestEventsCount(user)}</td>
      <td>{UserPresenter.remainingRequestsNumber(user)}</td>
      <td>
        <div className={styles.actions}>
          <BackgroundExportResultNotifier
            component={ExportButton}
            onExport={handleExport}
            onPoll={onPoll}
            onExportPropName="onClick"
            shouldOpenOnCompletion
            icon={<CsvIcon color="dark-1" />}
            plain
          />
          {isPresent(onImpersonate) && UserPolicy.canImpersonate(currentUser) && (
            <Anchor icon={<ViewIcon color="dark-1" />} onClick={handleImpersonate} title="Impersonate this user" />
          )}
        </div>
      </td>
    </>
  );
};

UserRowCells.propTypes = {
  row: UserPresenter.shape(),
  onImpersonate: PropTypes.func,
  currentUser: CurrentUserPresenter.shape().isRequired,
  onExport: PropTypes.func.isRequired,
  onPoll: PropTypes.func.isRequired,
};

export default UserRowCells;
