import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isNil } from 'ramda';
import { paginate } from 'utils/PaginationHelper';

import ExecutiveMatchPresenter from 'presenters/ExecutiveMatchPresenter';

import Pagination from 'containers/UserPanel/components/Pagination';

import ExecutiveMatch from './components/ExecutiveMatch';

import styles from './ExecutivesMatches.module.css';

const PER_PAGE = 10;

const ExecutivesMatches = (props) => {
  const {
    executivesMatches,
    incorrectExecutiveMatchIndexes,
    onExecutiveMatchMarkCorrect,
    onExecutiveMatchMarkIncorrect,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const totalCount = executivesMatches.length;
  const executivesMatchesOnPage = paginate(executivesMatches, currentPage, PER_PAGE);

  return (
    <div className={styles.executivesMatches}>
      {executivesMatchesOnPage.map((executiveMatch) => (
        <ExecutiveMatch
          key={executiveMatch.index}
          executiveMatch={executiveMatch}
          isCorrect={!incorrectExecutiveMatchIndexes.includes(executiveMatch.index)}
          onMarkCorrect={onExecutiveMatchMarkCorrect}
          onMarkIncorrect={onExecutiveMatchMarkIncorrect}
        />
      ))}
      {!isNil(totalCount) && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={currentPage}
            perPage={PER_PAGE}
            totalCount={totalCount}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

ExecutivesMatches.propTypes = {
  executivesMatches: PropTypes.arrayOf(ExecutiveMatchPresenter.shape()).isRequired,
  incorrectExecutiveMatchIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
  onExecutiveMatchMarkCorrect: PropTypes.func.isRequired,
  onExecutiveMatchMarkIncorrect: PropTypes.func.isRequired,
};

export default ExecutivesMatches;
