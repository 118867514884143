import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1AdminSampleProjectsPath(params);

    return FetchHelpers.get(url);
  },

  update(sampleProjectId, sampleProject) {
    const url = Routes.apiV1AdminSampleProjectPath(sampleProjectId);

    return FetchHelpers.patch(url, { sampleProject });
  },
};
