export default {
  container: (base) => ({
    ...base,
    position: 'static',
  }),

  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'transparent' : 'transparent',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingBottom: '2px',
    marginTop: '-8px',
    minHeight: '32px',
    fontSize: '14px',
    position: 'static',
    '&:hover': {
      borderColor: 'transparent',
    },
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),

  clearIndicator: (base) => ({
    ...base,
    backgroundImage:
      'url("data:image/svg+xml, %3Csvg%20width%3D%2210%22%20height%3D%2210%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%201l8%208M1%209l8-8%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.2%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    padding: '6px',
    marginRight: '8px',
    width: '8px',
    height: '8px',
    '> svg': {
      display: 'none',
    },
  }),

  indicatorsContainer: (base) => ({
    ...base,
    marginRight: '16px',
    position: 'absolute',
    right: 0,
    height: '100%',
    top: 0,
  }),

  dropdownIndicator: () => ({
    display: 'none',
  }),

  menu: (base) => ({
    ...base,
    borderRadius: 0,
    margin: 0,
    boxShadow: 'none',
    border: 'none',
    outline: '1px solid #dee0e3',
    zIndex: '2',
  }),

  menuList: (base) => ({
    ...base,
    padding: 0,
  }),

  noOptionsMessage: (base) => ({
    ...base,
    borderRadius: 0,
  }),

  option: (base, state) => ({
    ...base,
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: state.isFocused ? '#f4f5f7' : 'transparent',
    color: '#000',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#f4f5f7',
    },
  }),

  valueContainer: (base) => ({
    ...base,
    marginTop: '2px',
    position: 'static',
  }),

  singleValue: (base) => ({
    ...base,
    top: 'auto',
    transform: 'none',
    position: 'static',
  }),
};
