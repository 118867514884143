import keyMirror from 'keymirror';

const AdminUserListConstants = keyMirror({
  INITIALIZE_ADMIN_COMPANY_USER_LIST: null,

  LOAD_ADMIN_COMPANY_USER_START: null,
  LOAD_ADMIN_COMPANY_USER_SUCCESS: null,

  FILTER_ADMIN_COMPANY_USER_START: null,
  FILTER_ADMIN_COMPANY_USER_SUCCESS: null,

  BULK_DESTROY_ADMIN_COMPANY_USER_SUCCESS: null,

  CREATE_NEW_ADMIN_COMPANY_USER_START: null,
  CREATE_NEW_ADMIN_COMPANY_USER_SUCCESS: null,
  CREATE_NEW_ADMIN_COMPANY_USER_ERROR: null,
});

export default AdminUserListConstants;
