/* eslint-disable no-param-reassign  */
/* eslint-disable filenames/match-exported  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createRestSlice } from 'utils/RestSlice';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import UsersMatchesRepository from 'repositories/admin/Companies/UsersMatchesRepository';

const restSlice = createRestSlice({
  resource: 'usersMatch',
  repository: UsersMatchesRepository,
  slices: ['createUsersMatch'],
});

const validateReducers = {
  validateUsersMatchAttributesStart(state) {
    state.validate.validatingStatus = FETCH_STATUSES.pending;
    state.validate.error = null;
  },
  validateUsersMatchAttributesSuccess(state) {
    state.validate.validatingStatus = FETCH_STATUSES.fulfilled;
  },
  validateUsersMatchAttributesFail(state, { payload: { error } }) {
    state.validate.validatingStatus = FETCH_STATUSES.failed;
    state.validate.error = error;
  },
};

const initialState = {
  ...restSlice.initialState,
  validate: {
    validatingStatus: FETCH_STATUSES.idle,
    error: null,
  },
};

const adminCompanyUsersMatchSlice = createSlice({
  name: 'adminCompanyUsersMatchSlice',
  initialState,
  reducers: { ...restSlice.reducers, ...validateReducers },
});

const { actions } = adminCompanyUsersMatchSlice;

export const useAdminCompanyUsersMatchActions = () => {
  const dispatch = useDispatch();

  return {
    validateUsersMatchAttributes: async (companyId, params) => {
      dispatch(actions.validateUsersMatchAttributesStart());

      try {
        await UsersMatchesRepository.validate(companyId, params);
        dispatch(actions.validateUsersMatchAttributesSuccess());
      } catch (error) {
        dispatch(actions.validateUsersMatchAttributesFail({ error }));
        throw error;
      }
    },
  };
};

export const adminCompanyUsersMatchReducer = adminCompanyUsersMatchSlice.reducer;

export const adminCompanyUsersMatchRestHooks = restSlice.hooks(
  adminCompanyUsersMatchSlice,
  'adminCompanyUsersMatchReducer',
);

export default adminCompanyUsersMatchSlice;
