import { prop } from 'ramda';
import CountriesListPageConstants from './CountriesListPageConstants';
import CountriesRepository from 'repositories/admin/CountriesRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';
import { isPresent } from 'utils/HelperMethods';

export function loadCountries({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: CountriesListPageConstants.LOAD_COUNTRIES_START });
    const query = { page, ...buildFilterParams(filters) };

    return CountriesRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: CountriesListPageConstants.LOAD_COUNTRIES_SUCCESS,
        countries: items,
        ...meta,
      });
    });
  };
}

export function filterCountries(filters) {
  return (dispatch) => {
    dispatch({ type: CountriesListPageConstants.FILTER_COUNTRIES_START, filters });

    const query = buildFilterParams(filters);

    return CountriesRepository.index(query).then(({ items, meta }) => {
      dispatch({ type: CountriesListPageConstants.FILTER_COUNTRIES_SUCCESS, countries: items, filters, ...meta });
    });
  };
}

export function createCountry(country) {
  return (dispatch) => {
    dispatch({ type: CountriesListPageConstants.CREATE_COUNTRY_START });

    return CountriesRepository.create(country)
      .then(() => {
        dispatch({
          type: CountriesListPageConstants.CREATE_COUNTRY_SUCCESS,
        });

        addNotification('Country was successfully added', 'normal')(dispatch);
      })
      .catch((error) => {
        dispatch({ type: CountriesListPageConstants.CREATE_COUNTRY_ERROR });

        throw error;
      });
  };
}

export function bulkDestroyCountries(query) {
  return (dispatch) => {
    dispatch({ type: CountriesListPageConstants.BULK_DESTROY_COUNTRIES_START });

    return CountriesRepository.bulkDestroy(query).then(({ errors }) => {
      if (isPresent(errors)) {
        const countryIds = errors.map(prop('id')).join(', ');
        addNotification(`Countries with IDs ${countryIds} weren't deleted`, 'critical')(dispatch);
      }

      dispatch({ type: CountriesListPageConstants.BULK_DESTROY_COUNTRIES_SUCCESS });
    });
  };
}
