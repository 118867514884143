import keyMirror from 'keymirror';

const BackendNotificationsConstants = keyMirror({
  LOAD_CURRENT_USER_NOTIFICATIONS_START: null,
  LOAD_CURRENT_USER_NOTIFICATIONS_SUCCESS: null,
  LOAD_CURRENT_USER_NOTIFICATIONS_ERROR: null,

  UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_START: null,
  UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_SUCCESS: null,
  UPDATE_CURRENT_USER_NOTIFICATIONS_SUMMARY_ERROR: null,

  DESTROY_CURRENT_USER_NOTIFICATION_START: null,

  POLLING_CURRENT_USER_NOTIFICATIONS_START: null,
  POLLING_CURRENT_USER_NOTIFICATIONS_STOP: null,
});

export default BackendNotificationsConstants;
