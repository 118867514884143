import NewProjectSourceWizardConstants from './NewProjectSourceWizardConstants';
// FIXME was cycle with ProjectConstants
import ProjectConstants from 'containers/UserPanel/containers/Project/ProjectConstants';
import SourceForm from 'forms/project/SourceForm';

const initialState = {
  form: SourceForm.defaultAttributes(),
  saved: false,
  loading: false,
};

function NewProjectSourceWizardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NewProjectSourceWizardConstants.INITIALIZE_PROJECT_NEW_SOURCE_WIZARD: {
      const { form } = action;

      return { ...state, form, saved: false };
    }
    case NewProjectSourceWizardConstants.CHANGE_PROJECT_NEW_SOURCE_FORM: {
      const { form } = action;

      return { ...state, form };
    }
    case ProjectConstants.CREATE_PROJECT_SOURCE_START: {
      return { ...state, loading: true };
    }
    case ProjectConstants.CREATE_PROJECT_SOURCE_SUCCESS: {
      return { ...state, loading: false, saved: true };
    }
    case ProjectConstants.CREATE_PROJECT_SOURCE_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}

export default NewProjectSourceWizardReducer;
