import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RelationshipsMappingPage from './RelationshipsMappingPage';
import * as actions from './RelationshipsMappingPageActions';

const mapStateToProps = (state) => ({
  information: state.ProfileRelationshipsMappingPageReducer.information,
  loading: state.ProfileRelationshipsMappingPageReducer.loading,
  relationshipsMapping: state.ProfileRelationshipsMappingPageReducer.relationshipsMapping,
  saving: state.ProfileRelationshipsMappingPageReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipsMappingPage);
