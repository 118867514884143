const FETCH_STATUSES = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  failed: 'failed',
};

const useFetchStatus = (status) => {
  const isIdle = status === FETCH_STATUSES.idle;
  const isPending = status === FETCH_STATUSES.pending;
  const isFulfilled = status === FETCH_STATUSES.fulfilled;
  const isFailed = status === FETCH_STATUSES.failed;

  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
  };
};

export { FETCH_STATUSES, useFetchStatus };
