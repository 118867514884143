import keyMirror from 'keymirror';

const NewPasswordConstants = keyMirror({
  INITIALIZE_NEW_PASSWORD_FORM: null,

  CHANGE_NEW_PASSWORD_FORM: null,

  UPDATE_NEW_PASSWORD_START: null,
  UPDATE_NEW_PASSWORD_SUCCESS: null,
  UPDATE_NEW_PASSWORD_ERROR: null,
});

export default NewPasswordConstants;
