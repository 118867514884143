import { buildFilterParams } from 'utils/FilterParamsBuilder';
import CompaniesListPageConstants from './CompaniesListPageConstants';
import CompaniesRepository from 'repositories/admin/CompaniesRepository';
import UsersRepository from 'repositories/admin/UsersRepository';
import ExportsRepository from 'repositories/CurrentUser/ExportsRepository';

export function initializeCompaniesList() {
  return { type: CompaniesListPageConstants.INITIALIZE_COMPANIES_LIST };
}

export function loadCompanies({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: CompaniesListPageConstants.LOAD_COMPANIES_START });

    const query = { page, ...buildFilterParams(filters) };
    return CompaniesRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: CompaniesListPageConstants.LOAD_COMPANIES_SUCCESS,
        companies: items,
        ...meta,
      });

      return items;
    });
  };
}

export function filterCompanies(filters) {
  return (dispatch) => {
    dispatch({ type: CompaniesListPageConstants.FILTER_COMPANIES_START, filters });

    const query = buildFilterParams(filters);
    return CompaniesRepository.index(query).then(({ items, meta }) => {
      dispatch({ type: CompaniesListPageConstants.FILTER_COMPANIES_SUCCESS, companies: items, filters, ...meta });

      return items;
    });
  };
}

export function bulkDestroyCompanies(query) {
  return (dispatch) =>
    CompaniesRepository.bulkDestroy(query).then(() => {
      dispatch({ type: CompaniesListPageConstants.BULK_DESTROY_COMPANIES_SUCCESS });
    });
}

export function createCompany(companyParams) {
  return (dispatch) => {
    dispatch({ type: CompaniesListPageConstants.CREATE_COMPANY_START });

    return CompaniesRepository.create(companyParams)
      .then(({ company }) => {
        dispatch({ type: CompaniesListPageConstants.CREATE_COMPANY_SUCCESS, company });

        return company;
      })
      .catch((error) => {
        dispatch({ type: CompaniesListPageConstants.CREATE_COMPANY_ERROR });

        throw error;
      });
  };
}

export function searchUser(filters) {
  const params = buildFilterParams(filters);

  return () => UsersRepository.index({ ...params, per: 10 }).then(({ items }) => items);
}

export function createExportCompaniesToCsv(filters, exportParams) {
  return () =>
    ExportsRepository.create({ ...exportParams, params: buildFilterParams(filters) }).then(
      ({ exportJob }) => exportJob,
    );
}

export function pollExportCompaniesToCsv(id) {
  return () => ExportsRepository.show(id).then(({ exportJob }) => exportJob);
}
