import { pick } from 'ramda';

export default {
  defaultAttributes(language) {
    return {
      name: '',
      alpha2: '',
      ...language,
    };
  },

  attributesToSubmit(language) {
    const propsToSend = ['name', 'nativeName', 'alpha2'];

    return pick(propsToSend, language);
  },
};
