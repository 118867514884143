import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { isBlank, isPresent } from 'utils/HelperMethods';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import SourcePresenter from 'presenters/Project/SourcePresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import TargetItemPresenter from 'presenters/Project/TargetItemPresenter';

import SourceForm from 'forms/project/SourceForm';
import TargetItemForm from 'forms/project/TargetItemForm';

import Button from 'components/Button';
import TargetingSearchForm from 'containers/UserPanel/containers/TargetingSearchForm';
import Loader from 'components/v2/Loader';
import Header from 'containers/UserPanel/containers/Project/components/Header';

import styles from './TargetingSourceEditPage.module.css';

const isInclusionCriteriasExist = (targetItemsAttributes = []) => {
  const inclusionTargetItems = targetItemsAttributes
    .filter(TargetItemPresenter.isIncludsionKind)
    .filter(({ criterias }) => isPresent(criterias));

  return isPresent(inclusionTargetItems);
};

const breadcrumbs = (project) => [
  { name: 'Projects', to: Routes.projectsPath() },
  { to: Routes.projectPath(ProjectPresenter.id(project)), name: ProjectPresenter.name(project) },
  { name: 'Edit List' },
];

const TargetingSourceEditPage = (props) => {
  const {
    loadTargetingSource,
    project,
    sourceId,
    currentUser,
    loading,
    source,
    changeSource,
    history,
    updateTargetingSource,
    onProjectReload,
    saving,
  } = props;

  const [totalProfiles, setTotalProfiles] = useState(0);
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const targetItems = SourcePresenter.targetItems(source) || [];
  const targetItemsAttributes = isPresent(targetItems) ? targetItems.map(TargetItemForm.defaultAttributes) : [];
  const isSaveDisabled = saving || isProfilesLoading || totalProfiles === 0 || !isInclusionCriteriasExist(targetItems);

  useEffect(() => {
    loadTargetingSource(ProjectPresenter.id(project), sourceId);
  }, []);

  const handleTargetItemsChange = (newTargetItems) => {
    changeSource({ ...source, targetItems: newTargetItems });
  };

  const handleSourceUpdate = () => {
    const attributes = SourceForm.attributesToSubmit({ ...source, targetItemsAttributes: source.targetItems });

    return updateTargetingSource(ProjectPresenter.id(project), SourcePresenter.id(source), attributes).then(() => {
      onProjectReload();
      history.push(Routes.projectPath(ProjectPresenter.id(project)));
    });
  };

  return (
    <div className={styles.root}>
      <Header
        currentUser={currentUser}
        title={ProjectPresenter.name(project)}
        breadcrumbs={breadcrumbs(project)}
        project={project}
      />

      <div className={styles.general}>
        <div className={styles.main}>
          {loading || isBlank(source) || SourcePresenter.id(source) !== parseInt(sourceId, 10) ? (
            <Loader />
          ) : (
            <>
              <TargetingSearchForm
                onTargetItemsChange={handleTargetItemsChange}
                targetItems={targetItemsAttributes}
                recommendedProfilesCount={SourcePresenter.recommendedProfilesCount(source)}
                onChangeNumberProfiles={setTotalProfiles}
                onChangeProfilesLoadingFlag={setIsProfilesLoading}
              />

              <div className={styles.navigation}>
                <Button label="Save" disabled={isSaveDisabled} onClick={handleSourceUpdate} primary />
                <Button label="Cancel" href={Routes.projectPath(ProjectPresenter.id(project))} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

TargetingSourceEditPage.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  project: ProjectPresenter.shape().isRequired,
  loadTargetingSource: PropTypes.func.isRequired,
  updateTargetingSource: PropTypes.func.isRequired,
  sourceId: PropTypes.string.isRequired,
  source: SourcePresenter.shape(),
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  onProjectReload: PropTypes.func.isRequired,
  changeSource: PropTypes.func.isRequired,
};

export default TargetingSourceEditPage;
