import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { keys, propOr } from 'ramda';

import { useFieldContext } from 'components/v2/Form/components/Field';

import styles from './Textarea.module.css';

const VARIANTS = {
  outlined: styles.outlined,
  default: styles.default,
};

const FIELD_VARIANT_TO_TEXTAREA_VARIANT = {
  outlined: 'default',
  default: 'outlined',
};

const Textarea = (props) => {
  const { className, onChange, value, variant, ...rest } = props;

  const fieldContext = useFieldContext();

  const textareaVariant = variant || FIELD_VARIANT_TO_TEXTAREA_VARIANT[fieldContext?.variant];
  const variantClassName = propOr(VARIANTS.default, textareaVariant)(VARIANTS);
  const textareaClassName = clsx(styles.textarea, variantClassName, className);

  const handleChange = (event) => onChange(event.target.value, event);

  return <textarea className={textareaClassName} onChange={handleChange} value={value} {...rest} />;
};

Textarea.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(keys(VARIANTS)),
};

Textarea.defaultProps = {
  className: null,
  onChange: () => {},
  value: undefined,
  variant: undefined,
};

export default Textarea;
