import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1CurrentUserSavedSearchesPath(params);

    return FetchHelpers.get(url);
  },

  show(id) {
    const url = Routes.apiV1CurrentUserSavedSearchPath(id);

    return FetchHelpers.get(url);
  },

  destroy(id) {
    const url = Routes.apiV1CurrentUserSavedSearchPath(id);

    return FetchHelpers.delete(url);
  },

  create(savedSearch = {}) {
    const url = Routes.apiV1CurrentUserSavedSearchesPath();

    return FetchHelpers.post(url, { savedSearch });
  },

  update(id, savedSearch = {}) {
    const url = Routes.apiV1CurrentUserSavedSearchPath(id);

    return FetchHelpers.patch(url, { savedSearch });
  },
};
