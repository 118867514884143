import ProfileRelationshipsListConstants from './ProfileRelationshipsListConstants';

const initialState = {
  loading: false,
  relationships: [],
  nextPage: null,
  totalCount: null,
  currentPage: null,
  profileId: null,
};

function ProfileRelationshipsListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileRelationshipsListConstants.INITIALIZE_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_START: {
      const { profileId } = action;

      return { ...state, loading: false, relationships: [], nextPage: 1, profileId };
    }
    case ProfileRelationshipsListConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_START: {
      return { ...state, loading: true };
    }
    case ProfileRelationshipsListConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_SUCCESS: {
      const { relationships, meta, profileId } = action;

      if (state.profileId !== profileId) {
        return state;
      }

      return { ...state, relationships: [...state.relationships, ...relationships], loading: false, ...meta };
    }
    default:
      return state;
  }
}

export default ProfileRelationshipsListReducer;
