import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'grommet';

import Button from 'components/Button';

import Modal from 'containers/UserPanel/components/Modal';
import FiltersList from 'containers/UserPanel/containers/Search/components/FiltersList';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import styles from './SaveSearchPopUp.module.css';

class SaveSearchPopUp extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isCreating: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: { name: '' },
      errors: [],
    };
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ fields: { name: value } });
  };

  handleSubmit = () => {
    const { onSave, filters } = this.props;
    const {
      fields: { name },
    } = this.state;
    onSave({ name, filters }).catch(this.handleErrors);
  };

  handleErrors = ({ errors = {} }) => {
    this.setState({ errors });
  };

  render() {
    const { onClose: handleOnClose, filters, isCreating } = this.props;
    const {
      errors: { name: nameFieldErrors },
    } = this.state;

    return (
      <Modal className={styles.modal} onClose={handleOnClose}>
        <div className={styles.header}>Save Filters</div>
        <div className={styles.content}>
          <div className={styles.inputLabel}> Filter Name</div>
          <VerticalFormFieldWithBottomErrors errors={nameFieldErrors} htmlFor="name">
            <TextInput className={styles.input} onChange={this.handleChange} id="name" />
          </VerticalFormFieldWithBottomErrors>
          <FiltersList filters={filters} />
        </div>
        <div className={styles.actions}>
          <Button className={styles.save} label="Save" disabled={isCreating} primary onClick={this.handleSubmit} />
        </div>
      </Modal>
    );
  }
}

export default SaveSearchPopUp;
