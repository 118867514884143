import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { locationWithoutQuery } from 'utils/UrlHelper';

export default {
  show(id, params = {}) {
    const url = Routes.apiV1CustomerProfilePath(id, params);

    return FetchHelpers.get(url);
  },

  index(params = {}) {
    const url = Routes.apiV1CustomerProfilesPath();
    return FetchHelpers.post(url, params, { referrer: locationWithoutQuery() });
  },

  autocomplete(filters = {}) {
    const url = Routes.autocompleteApiV1CustomerProfilesPath();
    return FetchHelpers.post(url, filters, { referrer: locationWithoutQuery() });
  },
};
