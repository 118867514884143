import { pick } from 'ramda';

export default {
  defaultAttributes(legalSetting) {
    return {
      hideEmail: false,
      hidePhone: false,
      hideAddress: false,
      ...legalSetting,
    };
  },

  attributesToSubmit(legalSetting) {
    const propsToSend = ['hideEmail', 'hidePhone', 'hideAddress'];

    return pick(propsToSend, legalSetting);
  },
};
