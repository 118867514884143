import React from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import ContextPresenter from 'presenters/Relationship/ContextPresenter';
import WordPresenter from 'presenters/Project/WordPresenter';

import styles from './Contexts.module.css';

const Contexts = (props) => {
  const { contexts, word } = props;

  const notEmptyContexts = contexts.filter(
    (context) => isPresent(ContextPresenter.description(context)) || isPresent(ContextPresenter.timeInterval(context)),
  );

  if (notEmptyContexts.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={styles.title}>{WordPresenter.title(word)}</div>
      {notEmptyContexts.map((context) => {
        const description = `${ContextPresenter.description(context)} ${ContextPresenter.timeInterval(context)}`;

        return (
          <div key={description} className={styles.listItem}>
            <div className={styles.content}>
              <span title={description}> {description} </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Contexts.propTypes = {
  contexts: PropTypes.arrayOf(ContextPresenter.shape()).isRequired,
  word: WordPresenter.shape().isRequired,
};
export default Contexts;
