import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import AsyncSelect from 'components/AsyncSelect';

export default class UsersSelect extends Component {
  static propTypes = {
    defaultValue: PropTypes.shape(),
    defaultOptionValue: PropTypes.number,
    loadDefaultValue: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  state = { value: null };

  componentDidMount() {
    const { loadDefaultValue, defaultValue, defaultOptionValue } = this.props;

    if (isPresent(defaultValue)) {
      this.onChange(defaultValue);
    } else if (isPresent(defaultOptionValue)) {
      loadDefaultValue(defaultOptionValue).then((value) => this.setState({ ...this.state, value }));
    }
  }

  onChange = (value) => {
    const { onChange } = this.props;

    this.setState({ ...this.state, value });

    if (isPresent(onChange)) {
      onChange(value);
    }
  };

  render() {
    const { defaultOptionValue, ...selectProps } = this.props;
    const { value } = this.state;

    return <AsyncSelect value={value} {...selectProps} onChange={this.onChange} />;
  }
}
