/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function destroyResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        destroyingStatus: FETCH_STATUSES.idle,
        error: null,
      },
    },
    reducers: {
      destroyResourceStart(state) {
        state[resource].destroyingStatus = FETCH_STATUSES.pending;
        state[resource].error = null;
      },
      destroyResourceSuccess(state) {
        state[resource].destroyingStatus = FETCH_STATUSES.fulfilled;
      },
      destroyResourceFail(state, { payload: { error } }) {
        state[resource].destroyingStatus = FETCH_STATUSES.failed;
        state[resource].error = error;
      },
    },
    actionCreators(restDispatch) {
      return {
        destroyResource: async (...params) => {
          restDispatch('destroyResourceStart');

          try {
            const { data } = await repository.destroy(...params);
            restDispatch('destroyResourceSuccess', data);
          } catch (error) {
            restDispatch('destroyResourceFail', { error });
            throw error;
          }
        },
      };
    },
    abstractSelector: (state) => ({ resource: state[resource] }),
  };
}
