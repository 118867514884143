import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import NewOrganizationForm from './components/NewOrganizationForm';

const NewOrganizationModal = (props) => {
  const {
    loadDefaultIndustry,
    industryName,
    searchIndustry,
    createOrganization,
    onCreateSuccess,
    saving,
    searchNaicsIndustryCode,
    organizationName,
    onClose,
  } = props;

  const [defaultIndustry, setDefaultIndustry] = useState(null);

  const handleLoadDefaultIndustry = () => {
    const filters = { nameCont: industryName };

    searchIndustry(filters).then(([item]) => {
      setDefaultIndustry(item);
    });
  };

  useEffect(() => {
    if (loadDefaultIndustry) {
      handleLoadDefaultIndustry();
    }
  }, []);

  const handleSubmitNewOrganization = (params) =>
    createOrganization(params).then((organization) => {
      onCreateSuccess(organization);
    });

  return (
    <Modal onClose={onClose} size="medium" header="New Organization" overflow>
      {loadDefaultIndustry && isBlank(defaultIndustry) ? (
        <Spinner />
      ) : (
        <NewOrganizationForm
          organization={{ name: organizationName, industry: defaultIndustry, industryName }}
          onIndustrySearch={searchIndustry}
          onNaicsIndustryCodeSearch={searchNaicsIndustryCode}
          onSubmit={handleSubmitNewOrganization}
          saving={saving}
        />
      )}
    </Modal>
  );
};

NewOrganizationModal.propTypes = {
  saving: PropTypes.bool.isRequired,
  createOrganization: PropTypes.func.isRequired,
  searchNaicsIndustryCode: PropTypes.func.isRequired,
  searchIndustry: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  industryName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  loadDefaultIndustry: PropTypes.bool,
};

export default NewOrganizationModal;
