import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { Box } from 'grommet';

import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import Modal from 'components/Modal';

import styles from './EditorNoteModal.module.css';

const EditorNoteModal = (props) => {
  const { onSubmit, onClose, saving, title, description } = props;
  const [newDescription, setDescription] = useState(description);
  const [errors, setErrors] = useState(null);

  const handleTextChange = ({ target: { value } }) => setDescription(value);

  const handleSubmit = () => {
    onSubmit({ description: newDescription })
      .then(() => {
        onClose();
      })
      .catch((data) => {
        setErrors(data.errors);
      });
  };

  return (
    <Modal onClose={onClose} size="medium" header={title} overflow>
      <div>
        <VerticalFormFieldWithBottomErrors label="Text:" errors={errors && errors.description} htmlFor="description">
          <TextareaAutosize
            onChange={handleTextChange}
            value={newDescription}
            className={styles.textInput}
            maxRows={15}
            id="description"
          />
        </VerticalFormFieldWithBottomErrors>

        <Box direction="row" margin={{ top: 'small' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={handleSubmit} />
        </Box>
      </div>
    </Modal>
  );
};

EditorNoteModal.propTypes = {
  saving: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

EditorNoteModal.defaultProps = {
  description: '',
};

export default EditorNoteModal;
