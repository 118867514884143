import keyMirror from 'keymirror';

const ResetPasswordConstants = keyMirror({
  CHANGE_RESET_PASSWORD_FORM: null,

  SEND_RESET_PASSWORD_EMAIL_START: null,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS: null,
  SEND_RESET_PASSWORD_EMAIL_ERROR: null,

  INITIALIZE_RESET_PASSWORD_FORM: null,
});

export default ResetPasswordConstants;
