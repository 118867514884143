import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils/NumbersHelper';
import {
  logClickOnProjectPageActionAudienceInsights,
  logClickOnProjectPageActionBusinessPriorityAnalysis,
  logClickOnProjectPageActionRelationshipMapping,
  logClickOnProjectPageActionCommonsThreads,
} from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';
import Alert from './components/Alert';
import Icon from 'components/Icon';
import DeliverableItem from './components/DeliverableItem';
import ShareLink from './containers/ShareLink';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter, { RECOMMENDED_PROFILES_COUNT } from 'presenters/ProjectPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import Routes from 'routes';

import styles from './Deliverables.module.css';

export default class Deliverables extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    onExport: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  isAnyAnalyticsDisabled = () => {
    const { project } = this.props;

    return (
      !ProjectPresenter.isAudienceInsightsAvailable(project) ||
      !ProjectPresenter.isBusinessPriorityAnalysisAvailable(project) ||
      !ProjectPresenter.isRelationshipsMappingAvailable(project) ||
      !ProjectPresenter.isWordsCloudAvailable(project)
    );
  };

  render() {
    const { currentUser, onExport, project } = this.props;
    const isUserCanShareProjects = CurrentUserPresenter.projectsSharingAvailable(currentUser);
    const isCurrentUserOwnsProject = ProjectPresenter.isCurrentUserOwns(project);

    return (
      <div className={styles.actions}>
        {(this.isAnyAnalyticsDisabled() || (isUserCanShareProjects && !isCurrentUserOwnsProject)) && (
          <Alert>
            {this.isAnyAnalyticsDisabled() && (
              <p>
                We have disabled some of the actions because your project includes too many executives. For best results
                limit your project to {formatNumber(RECOMMENDED_PROFILES_COUNT)} executives.
              </p>
            )}
            {isUserCanShareProjects && !isCurrentUserOwnsProject && (
              <p>
                To edit or share a live version of this project, please use the top right menu icon or the{' '}
                <AnchorLink to={Routes.projectsPath()} className={styles.alertProjectsPageLink}>
                  My Projects
                </AnchorLink>{' '}
                page to clone it.
              </p>
            )}
          </Alert>
        )}
        <div className={styles.title}>
          <span>Actions</span>
          <ShareLink
            currentUser={currentUser}
            disabled={!ProjectPolicy.canExport(project) && !ProjectPolicy.canShare(project)}
            onExport={onExport}
            project={project}
          />
        </div>
        <div className={styles.actionsList}>
          <DeliverableItem
            icon={<Icon name="lamp" />}
            title="Audience Insights"
            description="See an aggregate view of your audience including common hobbies, interests, demographics, and more."
            path={Routes.projectAudienceInsightsPath(ProjectPresenter.id(project))}
            disabled={!ProjectPresenter.isAudienceInsightsAvailable(project)}
            onClick={logClickOnProjectPageActionAudienceInsights}
          />
          <DeliverableItem
            icon={<Icon name="chart" />}
            title="Business Priority Analysis"
            description="Discover trending business priorities most discussed by your target executives."
            path={Routes.projectBusinessPriorityAnalysisPath(ProjectPresenter.id(project))}
            disabled={!ProjectPresenter.isBusinessPriorityAnalysisAvailable(project)}
            onClick={logClickOnProjectPageActionBusinessPriorityAnalysis}
          />
          <DeliverableItem
            icon={<Icon name="molecule" />}
            title="Relationship Mapping"
            description="Discover how executives in your project are connected."
            path={Routes.projectRelationshipsMappingPath(ProjectPresenter.id(project))}
            disabled={!ProjectPresenter.isRelationshipsMappingAvailable(project)}
            onClick={logClickOnProjectPageActionRelationshipMapping}
          />
          <DeliverableItem
            icon={<Icon name="cloud" />}
            title="Common Threads"
            description="See the most common employers, boards, alma maters, and interests across your group"
            path={Routes.projectWordsCloudPath(ProjectPresenter.id(project))}
            disabled={!ProjectPresenter.isWordsCloudAvailable(project)}
            onClick={logClickOnProjectPageActionCommonsThreads}
          />
        </div>
      </div>
    );
  }
}
