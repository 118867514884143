import React from 'react';
import PropTypes from 'prop-types';
import { logToggleUserPanelProfileWhatNew, logClickOnUserPanelProfileDownloadProfile } from 'utils/amplitude';

import { CheckBox } from 'grommet';
import DownloadAnchor from 'components/DownloadAnchor';
import ProfileAvatar from 'components/ProfileAvatar';
import BoardexPowered from 'components/v2/BoardexPowered';
import ProfileInformation from './components/ProfileInformation';
import RecentlyChangedSections, { recentlyChangedSections } from './components/RecentlyChangedSections';
import Tooltip from 'rc-tooltip';

import styles from './Header.module.css';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter, { PROFILE_PDF_EXPORT_JOB_TYPE } from 'presenters/ProfilePresenter';
import ProfilesExportResultNotifier from 'containers/UserPanel/components/ProfilesExportResultNotifier';

import { NOTIFICATION_METHODS } from 'presenters/ExportJobPresenter';

import ProfilePolicy from 'policies/ProfilePolicy';

const Header = (props) => {
  const {
    onHiglightChanges,
    shouldHighlightChanges,
    highlightsParameters,
    currentUser,
    profile,
    onExport,
    onOpenGuide,
    boardexProfileUrl,
  } = props;

  const handleExportPdf = () => {
    const params = {
      ...highlightsParameters,
      profileId: profile.id,
      highlightChanges: shouldHighlightChanges,
      currentUserId: CurrentUserPresenter.id(currentUser),
    };

    logClickOnUserPanelProfileDownloadProfile();
    return onExport({ type: PROFILE_PDF_EXPORT_JOB_TYPE, notificationMethod: NOTIFICATION_METHODS.NONE, params });
  };

  const handleHiglightChange = () => {
    onHiglightChanges(!shouldHighlightChanges);
    logToggleUserPanelProfileWhatNew();
  };

  return (
    <div className={styles.common}>
      <div className={styles.left}>
        <ProfileAvatar className={styles.avatar} src={ProfilePresenter.photoUrl(profile)} />
      </div>
      <div className={styles.center}>
        <ProfileInformation profile={profile} onOpenGuide={onOpenGuide} currentUser={currentUser} />
      </div>
      <div className={styles.right}>
        <div className={styles.rightTop}>
          <div className={styles.date}>Last Updated: {ProfilePresenter.formattedLastUpdatedAt(profile)}</div>
          {ProfilePolicy.canSeeEditorialUpdateDate(currentUser) && (
            <div className={styles.editorialDate}>
              Last Editorial Update: {ProfilePresenter.formattedProfileUpdatedAt(profile)}
            </div>
          )}
          <div className={styles.controls}>
            {ProfilePolicy.canSeeDownloadButton(currentUser, profile) && (
              <ProfilesExportResultNotifier
                disabled={!ProfilePolicy.canDownload(currentUser, profile) || ProfilePresenter.isMasked(profile)}
                className={styles.link}
                component={DownloadAnchor}
                onExport={handleExportPdf}
                onExportPropName="onClick"
                label="Download Profile"
                shouldOpenOnCompletion
              />
            )}
            {recentlyChangedSections(profile).length > 0 && (
              <Tooltip placement="bottom" overlay={<RecentlyChangedSections profile={profile} />}>
                <div>
                  <CheckBox
                    className={styles.highlightsToggle}
                    checked={shouldHighlightChanges}
                    onChange={handleHiglightChange}
                    label="What's New?"
                    reverse
                    toggle
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        {boardexProfileUrl && (
          <BoardexPowered link={boardexProfileUrl} text="Access more information on this individual" />
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  highlightsParameters: PropTypes.shape(),
  onExport: PropTypes.func.isRequired,
  onHiglightChanges: PropTypes.func.isRequired,
  profile: ProfilePresenter.shape().isRequired,
  shouldHighlightChanges: PropTypes.bool.isRequired,
  onOpenGuide: PropTypes.func.isRequired,
  boardexProfileUrl: PropTypes.string,
};

export default Header;
