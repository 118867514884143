import keyMirror from 'keymirror';

const CountryPageConstants = keyMirror({
  LOAD_COUNTRY_START: null,
  LOAD_COUNTRY_SUCCESS: null,
  LOAD_COUNTRY_ERROR: null,

  UPDATE_COUNTRY_START: null,
  UPDATE_COUNTRY_SUCCESS: null,
  UPDATE_COUNTRY_ERROR: null,
});

export default CountryPageConstants;
