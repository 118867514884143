import PropTypes from 'prop-types';
import React from 'react';

import CheckmarkIcon from 'icons/CheckmarkIcon';
import CrossIcon from 'icons/CrossIcon';

import Typography from 'components/v2/Typography';

import styles from './CsvColumnInformation.module.css';

const VARIANTS = {
  IDLE: 'idle',
  MISSING: 'missing',
  EXISTS: 'exists',
};

const CsvColumnInformation = (props) => {
  const { name, isRequired, variant } = props;

  return (
    <div className={styles.columnInformation}>
      <Typography className={styles.name} variant="body1">
        {name}
        {isRequired ? ` (Required)` : ` (Optional)`}
      </Typography>
      {variant === VARIANTS.EXISTS && <CheckmarkIcon color="malachite" width={12} height={12} />}
      {variant === VARIANTS.MISSING && <CrossIcon color="orange" width={12} height={12} />}
    </div>
  );
};

CsvColumnInformation.propTypes = {
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

CsvColumnInformation.defaultProps = {
  isRequired: false,
  variant: VARIANTS.IDLE,
};

export default CsvColumnInformation;
