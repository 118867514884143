import PropTypes from 'prop-types';
import React from 'react';

import { Field, Textarea } from 'components/v2/Form';
import Button from 'components/v2/Button';

import styles from './RequestHelpForm.module.css';

const RequestHelpForm = (props) => {
  const { errors, isSubmitting, onFieldChange, onSubmit, values } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <>
      <Field className={styles.field} error={errors.dataMessage} variant="outlined">
        <Textarea
          className={styles.textarea}
          onChange={handleChange('data.message')}
          placeholder="Enter message"
          value={values.data.message}
        />
      </Field>

      <Button disabled={isSubmitting} onClick={onSubmit}>
        Send
      </Button>
    </>
  );
};

RequestHelpForm.propTypes = {
  errors: PropTypes.shape({ dataMessage: PropTypes.arrayOf(PropTypes.string) }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({ data: PropTypes.shape({ message: PropTypes.string }) }).isRequired,
};

export default RequestHelpForm;
