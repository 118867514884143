import SharesRepository from 'repositories/CurrentUser/Project/SharesRepository';
import SharesContainerConstants from './SharesContainerConstants';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function filterProjectShares(projectId, filters) {
  return (dispatch) => {
    dispatch({ type: SharesContainerConstants.FILTER_PROJECT_SHARES_START, filters });

    return SharesRepository.index(projectId, buildFilterParams(filters)).then(({ items, meta }) => {
      dispatch({ type: SharesContainerConstants.FILTER_PROJECT_SHARES_SUCCESS, shares: items, meta });

      return items;
    });
  };
}

export function loadProjectShares(projectId, { page, ...filters }) {
  return (dispatch) => {
    dispatch({ type: SharesContainerConstants.LOAD_PROJECT_SHARES_START, filters });
    const query = { ...buildFilterParams(filters), page };

    return SharesRepository.index(projectId, query).then(({ items, meta }) => {
      dispatch({ type: SharesContainerConstants.LOAD_PROJECT_SHARES_SUCCESS, shares: items, meta });

      return items;
    });
  };
}

export function loadProjectSharesSuggestions(projectId, { per, ...filters }) {
  return () => {
    const query = { per, ...buildFilterParams(filters) };

    return SharesRepository.suggestions(projectId, query);
  };
}

export function batchUpdateProjectShares(projectId, attributes) {
  return (dispatch) => {
    dispatch({ type: SharesContainerConstants.BATCH_UPDATE_PROJECT_SHARES_START });

    return SharesRepository.batchUpdate(projectId, attributes)
      .then((data) => {
        dispatch({ type: SharesContainerConstants.BATCH_UPDATE_PROJECT_SHARES_SUCCESS });

        return data;
      })
      .catch((error) => {
        dispatch({ type: SharesContainerConstants.BATCH_UPDATE_PROJECT_SHARES_ERROR });

        throw error;
      });
  };
}

export function destroyProjectShare(projectId, shareId) {
  return (dispatch) => {
    dispatch({ type: SharesContainerConstants.DESTROY_PROJECT_SHARE_START });

    return SharesRepository.destroy(projectId, shareId)
      .then(() => {
        dispatch({ type: SharesContainerConstants.DESTROY_PROJECT_SHARE_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: SharesContainerConstants.DESTROY_PROJECT_SHARE_ERROR });

        throw error;
      });
  };
}
