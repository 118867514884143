import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import CompanyLegalSettingPresenter from 'presenters/Company/LegalSettingPresenter';
import { isPresent } from 'utils/HelperMethods';

export default {
  canSeeList(user) {
    return UserPresenter.isAdmin(user);
  },

  canSeeElement(user) {
    return UserPresenter.isAdmin(user);
  },

  canDestroyElement(user) {
    return UserPresenter.isAdmin(user);
  },

  canImpersonate(user) {
    return UserPresenter.isAdmin(user);
  },

  canAddElement(user) {
    return UserPresenter.isAdmin(user);
  },

  canRequestProfile(user) {
    return UserPresenter.canRequestProfile(user);
  },

  isHaveAdminAccess(user) {
    if (!user) {
      return false;
    }

    return (
      UserPresenter.isAdmin(user) ||
      UserPresenter.isEditor(user) ||
      UserPresenter.isResearchEditor(user) ||
      UserPresenter.isResearcher(user) ||
      UserPresenter.isResearchManager(user)
    );
  },

  canSeeRemainingRequestsNumber(user) {
    if (!user) {
      return false;
    }

    return (
      UserPresenter.isCustomerAccount(user) ||
      UserPresenter.isDemoAccount(user) ||
      UserPresenter.isDownloadLimited(user) ||
      UserPresenter.isPilotAccount(user)
    );
  },

  canSeeCorporateAccount(user) {
    if (!user) {
      return false;
    }

    return UserPresenter.corporateViewAvailable(user) && UserPresenter.company(user);
  },

  canExportSearchResultInCsv(user) {
    return isPresent(user) && UserPresenter.exportSearchResultInCsvAvailable(user);
  },
  canSeeProjects(user) {
    return isPresent(user) && UserPresenter.projectsAvailable(user);
  },
  canExportList(user) {
    return isPresent(user) && UserPresenter.isAdmin(user);
  },
  canSeeEmail(user) {
    if (
      isPresent(user) &&
      CompanyLegalSettingPresenter.hideEmail(CompanyPresenter.legalSetting(UserPresenter.company(user)))
    ) {
      return false;
    }

    return true;
  },
  canSeePhone(user) {
    if (
      isPresent(user) &&
      CompanyLegalSettingPresenter.hidePhone(CompanyPresenter.legalSetting(UserPresenter.company(user)))
    ) {
      return false;
    }

    return true;
  },
  canSeeAddress(user) {
    if (
      isPresent(user) &&
      CompanyLegalSettingPresenter.hideAddress(CompanyPresenter.legalSetting(UserPresenter.company(user)))
    ) {
      return false;
    }

    return true;
  },
};
