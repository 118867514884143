import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pick, prop, propEq, find, propOr } from 'ramda';
import { Anchor, CheckBox } from 'grommet';

import config from 'config';

import { getCollectionCurrentPage } from 'utils/PaginationHelper';
import { debounce } from 'utils/HelperMethods';
import { isPresent } from 'utils/HelperMethods';
import { buildParams, parseLocationToParams } from 'utils/UrlHelper';
import { dateToISO } from 'utils/DateTime';
import { logClickOnProjectPageSortBy, logClickOnProjectPageFilter } from 'utils/amplitude';

import CustomExportModal from './components/CustomExportModal';
import Icon from 'components/Icon';
import Profile from './components/Profile';
import Pagination from 'containers/UserPanel/components/Pagination';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import Guide from 'components/Guide';
import SelectWithDatePicker from 'containers/UserPanel/components/SelectWithDatePicker';

import ProjectPresenter from 'presenters/ProjectPresenter';
import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import styles from './AvailableProfilesList.module.css';

import Routes from 'routes';

const SORT_OPTIONS = [
  { label: 'Recently Updated', value: 'by_significantly_updated' },
  { label: 'Most Viewed', value: 'by_views' },
  { label: 'Executives A - Z', value: 'by_full_name' },
  { label: 'Companies A - Z', value: 'by_organization_name' },
];
const FILTER_BY_OPTIONS = [
  { label: 'Updated Profiles', value: 'significantlyUpdatedGte' },
  { label: 'New To Role Profiles', value: 'recentCurrentWorkStartedGte' },
  { label: 'New To Project', value: 'profilesAddedGte' },
];

const DEFAULT_SORT = SORT_OPTIONS[0];

const PER_PAGE = 10;

const INITIAL_FILTERS = { searchField: '', sort: DEFAULT_SORT.value };

export default class AvailableProfilesList extends Component {
  static propTypes = {
    onCloseGuide: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    filterProjectAvailableProfiles: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
    removeProjectAvailableProfile: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    currentUser: CurrentUserPresenter.shape().isRequired,
    saving: PropTypes.bool.isRequired,
    project: ProjectPresenter.shape().isRequired,
    history: PropTypes.shape(),
  };

  constructor(...args) {
    super(...args);

    const filters = this.getFiltersFromQueryString();

    this.state = {
      filters,
      isCustomExportModalOpened: false,
      showGuide: this.shouldShowGuide(filters),
      hideGuideForever: false,
    };
  }

  componentDidMount() {
    this.handlePageChange(1);
  }

  getFiltersFromQueryString() {
    const { history, project } = this.props;
    const params = parseLocationToParams(history.location);
    const [filterBy] = FILTER_BY_OPTIONS.map(prop('value')).filter((value) => isPresent(params[value]));
    const statisticsSince = isPresent(filterBy) ? params[filterBy] : ProjectPresenter.statisticsSince(project);

    return { ...INITIAL_FILTERS, ...pick(INITIAL_FILTERS, params), filterBy, statisticsSince };
  }

  setQueryString = (params) => {
    const { history, project } = this.props;
    const projectId = ProfilePresenter.id(project);
    const pathname = Routes.projectPath(projectId);
    const search = buildParams(params);

    history.push({ pathname, search });
  };

  shouldShowGuide(filters) {
    const { currentUser, project } = this.props;
    const isRealUser = !CurrentUserPresenter.isImpersonating(currentUser);

    return isRealUser && ProjectPresenter.shouldShowGuide(project) && isPresent(filters.filterBy);
  }

  handleProfileRemove = (profile) => {
    const { profiles, project, removeProjectAvailableProfile, currentPage } = this.props;
    const projectId = ProjectPresenter.id(project);
    const profileId = ProfilePresenter.id(profile);

    return removeProjectAvailableProfile(projectId, profileId).then(() => {
      const profilesWithoutDeleted = profiles.filter(({ id }) => id !== profileId);
      const newCurrentPage = getCollectionCurrentPage(profilesWithoutDeleted, currentPage);

      this.handlePageChange(newCurrentPage);
    });
  };

  handlePageChange = (page) => {
    const { filters } = this.state;

    return this.handleLoadAvailableProfilesDebounced({ page, ...filters });
  };

  handleLoadAvailableProfiles = (params) => {
    const { filterProjectAvailableProfiles, project } = this.props;
    const buildedParams = this.buildFilterParams(params);
    this.setQueryString(buildedParams);
    return filterProjectAvailableProfiles(ProjectPresenter.id(project), buildedParams);
  };

  buildFilterParams = ({ page, per = PER_PAGE, filterBy, statisticsSince, ...filters }) => {
    const params = { page, per, statisticsSince, ...filters };

    if (isPresent(filterBy) && isPresent(statisticsSince)) {
      params[filterBy] = statisticsSince;
    }

    return params;
  };

  handleLoadAvailableProfilesDebounced = debounce(this.handleLoadAvailableProfiles);

  handleFiltersChange = (filters) => {
    this.setState({ ...this.state, filters });
  };

  handleFiltersFieldChange = (fieldName) => (value) => {
    const filters = { ...this.state.filters, [fieldName]: value };

    this.handleFiltersChange(filters);
    this.handleLoadAvailableProfilesDebounced({ page: 1, ...filters });
  };

  handleSearchChange = ({ target: { value } }) => this.handleFiltersFieldChange('searchField')(value);

  handleStatisticsSinceChange = (value) => {
    const { filters } = this.state;
    const newFilters = { ...filters, statisticsSince: dateToISO(value) };

    this.handleFiltersChange(newFilters);

    if (value !== filters.statisticsSince && isPresent(newFilters.filterBy)) {
      this.handleLoadAvailableProfilesDebounced({ page: 1, ...newFilters });
    }
  };

  handleFilterBySelectChange = (value) => {
    const { filters } = this.state;
    const newFilters = { ...filters, filterBy: value };

    this.handleFiltersChange(newFilters);
    this.logFilterChange(value);

    if (value !== filters.filterBy && isPresent(newFilters.statisticsSince)) {
      this.handleLoadAvailableProfilesDebounced({ page: 1, ...newFilters });
    }
  };

  logFilterChange = (value) => {
    const findedFilter = find(propEq(value, 'value'))(FILTER_BY_OPTIONS);
    const humanFilterName = propOr('empty', 'label')(findedFilter);

    logClickOnProjectPageFilter(humanFilterName);
  };

  handleOpenCustomExportModal = () => this.setState({ ...this.state, isCustomExportModalOpened: true });

  handleChangeGuideShowAgainFlag = ({ target: { checked } }) =>
    this.setState({ ...this.state, hideGuideForever: checked });

  handleCloseCustomExportModal = () => this.setState({ ...this.state, isCustomExportModalOpened: false });

  handleCloseGuide = () => {
    const { onCloseGuide, project } = this.props;
    const { hideGuideForever } = this.state;

    if (hideGuideForever) {
      onCloseGuide(ProjectPresenter.id(project));
    }

    this.setState({ ...this.state, showGuide: false });
  };

  renderProfiles() {
    const { currentUser, loading, onExport, profiles, project, saving, totalCount } = this.props;

    if (loading) {
      return <Spinner />;
    }

    if (totalCount === 0) {
      return 'No Items';
    }

    return (
      <div className={styles.profiles}>
        {profiles.map((profile) => (
          <Profile
            key={ProfilePresenter.id(profile)}
            profile={profile}
            currentUser={currentUser}
            onExport={onExport}
            onRemove={this.handleProfileRemove}
            saving={saving}
            project={project}
          />
        ))}
      </div>
    );
  }

  render() {
    const { currentPage, currentUser, onExport, totalCount, project } = this.props;
    const { showGuide, hideGuideForever, filters, isCustomExportModalOpened } = this.state;
    const { filterBy, statisticsSince, searchField, sort } = filters;
    const title =
      isPresent(statisticsSince) && isPresent(filterBy)
        ? FILTER_BY_OPTIONS.find(propEq(filterBy, 'value')).label
        : 'Available';

    return (
      <>
        {isCustomExportModalOpened && (
          <CustomExportModal
            currentUser={currentUser}
            onClose={this.handleCloseCustomExportModal}
            onSubmit={onExport}
            project={project}
            filters={this.buildFilterParams(this.state.filters)}
          />
        )}
        <div className={styles.filters}>
          <div className={styles.searchArea}>
            <input
              onChange={this.handleSearchChange}
              value={searchField}
              autoComplete="off"
              placeholder="Start typing Name, Title or Company Name"
              className={styles.searchInput}
              maxLength={config.search.textValueMaxLength}
            />
            <Icon name="search" style={{ width: '14', height: '14' }} />
          </div>
          <div className={styles.selects}>
            <div className={styles.select}>
              <SelectWithDatePicker
                datepickerValue={statisticsSince}
                onDatepickerChange={this.handleStatisticsSinceChange}
                isClearable
                options={FILTER_BY_OPTIONS}
                noBottom
                selectedOptionValue={filterBy}
                onValueChange={this.handleFilterBySelectChange}
              />
            </div>
            <div className={styles.select}>
              <Select
                options={SORT_OPTIONS}
                placeholder="Sort By"
                noBottom
                selectedOptionValue={sort}
                onValueChange={(value) => {
                  this.handleFiltersFieldChange('sort')(value);
                  logClickOnProjectPageSortBy();
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.title}>
            {' '}
            {title} ({totalCount}){' '}
          </div>
          <div className={styles.export}>
            <Anchor
              className={styles.share}
              disabled={!ProjectPolicy.canExport(project)}
              label="Export"
              onClick={this.handleOpenCustomExportModal}
            />
          </div>
        </div>
        {this.renderProfiles()}
        <Pagination
          currentPage={currentPage}
          perPage={PER_PAGE}
          totalCount={totalCount}
          onPageChange={this.handlePageChange}
        />
        {showGuide && (
          <Guide onClose={this.handleCloseGuide} selector={`.${styles.selects}`} position="bottom">
            <div className={styles.guide}>
              We&#39;ve tracked all of the new and updated profiles
              <br />
              in your project since you last logged in.
              <br />
              Use these filters to explore them
              <CheckBox
                className={styles.guideCheckbox}
                checked={hideGuideForever}
                onChange={this.handleChangeGuideShowAgainFlag}
                label="Don't show this again."
              />
            </div>
          </Guide>
        )}
      </>
    );
  }
}
