import ProfileContainerConstants from './ProfileContainerConstants';

import SubscriptionPresenter from 'presenters/SubscriptionPresenter';

const initialState = {
  loading: false,
  profile: null,
  isUpdateRequestSaving: false,
  subscriptions: [],
  isSubscriptionsSaving: false,
  relsci: null,
};

function ProfileContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileContainerConstants.LOAD_RELSCI_PROFILE_SUCCESS: {
      const { data } = action;

      return { ...state, relsci: data };
    }
    case ProfileContainerConstants.LOAD_CUSTOMER_PROFILE_START: {
      return { ...initialState, loading: true };
    }
    case ProfileContainerConstants.LOAD_CUSTOMER_PROFILE_SUCCESS: {
      const {
        profile,
        meta: { subscriptions },
      } = action;

      return { ...state, profile, loading: false, subscriptions };
    }
    case ProfileContainerConstants.REQUEST_CUSTOMER_PROFILE_UPDATE_START: {
      return { ...state, isUpdateRequestSaving: true };
    }
    case ProfileContainerConstants.REQUEST_CUSTOMER_PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        profile: { ...state.profile, isUpdateRequestAvailable: false },
        isUpdateRequestAvailable: false,
      };
    }
    case ProfileContainerConstants.REQUEST_CUSTOMER_PROFILE_UPDATE_ERROR: {
      return {
        ...state,
        profile: { ...state.profile, isUpdateRequestAvailable: false },
        isUpdateRequestAvailable: false,
      };
    }
    case ProfileContainerConstants.UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_START: {
      return { ...state, isSubscriptionsSaving: true };
    }
    case ProfileContainerConstants.UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_SUCCESS: {
      const {
        subscriptions,
        meta: { deletedSubscriptionsIds },
      } = action;
      const updatedSubscriptionsIds = subscriptions.map(SubscriptionPresenter.id);
      const changedSubscriptionsIds = [...updatedSubscriptionsIds, ...deletedSubscriptionsIds];
      const notChangedSubscriptions = state.subscriptions.filter(({ id }) => !changedSubscriptionsIds.includes(id));

      return {
        ...state,
        isSubscriptionsSaving: false,
        subscriptions: [...notChangedSubscriptions, ...subscriptions],
      };
    }
    case ProfileContainerConstants.UPDATE_CURRENT_USER_BATCH_SUBSCRIPTIONS_ERROR: {
      return { ...state, isSubscriptionsSaving: false };
    }
    default:
      return state;
  }
}

export default ProfileContainerReducer;
