import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Select from 'components/Select';
import ProfileSubscription from './components/ProfileSubscription';
import Icon from 'components/Icon';
import GearsIcon from 'icons/GearsIcon';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/v2/Loader';
import Button from 'components/Button';
import SubscriptionConfigurationPopUp from '../SubscriptionConfigurationPopUp';

import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import { PROFILE_SUBSCRIPTION_CONFIGURATION } from 'presenters/SubscriptionConfigurationPresenter';

import styles from './ProfileSubscriptionsList.module.css';

const SORT_OPTIONS = [
  {
    label: 'Recently Updated',
    field: 'updated_at',
    value: 'asc',
  },
  {
    label: 'Executives A-Z',
    field: 'subject_of_Profile_type_published_version_full_name',
    value: 'asc',
    preventDecamilization: true,
  },
  {
    label: 'Executives Z-A',
    field: 'subject_of_Profile_type_published_version_full_name',
    value: 'desc',
    preventDecamilization: true,
  },
];

export const INITIAL_FILTERS = { profileSearchFieldCont: '', sortFields: [SORT_OPTIONS[0]] };

class ProfileSubscriptionsList extends Component {
  state = {
    isSubscriptionConfigurationPopUpOpen: false,
  };

  isAllProfleSubscriptionsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleSearchFieldChange = ({ target: { value } }) => {
    const { filters } = this.props;

    this.props.onFiltersChange({ ...filters, profileSearchFieldCont: value });
  };

  handleSortChange = (option) => {
    const { filters } = this.props;

    this.props.onFiltersChange({ ...filters, sortFields: [option] });
  };

  handleLoadMore = () => {
    const { isLoading, nextPage, loadSubscriptions, filters } = this.props;

    if (!isLoading && !this.isAllProfleSubscriptionsLoaded()) {
      loadSubscriptions({ page: nextPage, ...filters });
    }
  };

  handleOpenSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: true });
  };

  handleCloseSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: false });
  };

  render() {
    const {
      filters,
      onSubscriptionUndoDelete,
      onSubscriptionDelete,
      subscriptions,
      totalCount,
      isLoading,
      subscriptionConfigurationLoading,
      updateSubscriptionConfiguration,
      loadSubscriptionConfiguration,
    } = this.props;
    const { profileSearchFieldCont, sortFields } = filters;
    const [sort] = sortFields;
    const isSubscriptionsEmpty = totalCount === 0 && profileSearchFieldCont === '' && subscriptions.length === 0;
    const { isSubscriptionConfigurationPopUpOpen } = this.state;

    if (isSubscriptionsEmpty && !isLoading) {
      return (
        <div className={styles.info}>
          <div className={styles.noticeHeader}>You have not set up any executives subscriptions alerts yet</div>
          <div className={styles.notice}>
            You may subscribe to alerts on specific executives on their pages. Look for the blue "Get Alert" button
          </div>
        </div>
      );
    }

    return (
      <div>
        {isSubscriptionConfigurationPopUpOpen && (
          <SubscriptionConfigurationPopUp
            loading={subscriptionConfigurationLoading}
            onSave={updateSubscriptionConfiguration}
            onClose={this.handleCloseSubscriptionConfiguration}
            kind={PROFILE_SUBSCRIPTION_CONFIGURATION}
            onLoadConfiguration={loadSubscriptionConfiguration}
          />
        )}
        <div className={styles.filters}>
          <div className={styles.searchArea}>
            <input
              value={profileSearchFieldCont}
              autoComplete="off"
              placeholder="Start typing Name, Title or Company Name"
              className={styles.searchInput}
              onChange={this.handleSearchFieldChange}
            />
            <Icon name="search" style={{ width: 14, height: 14 }} />
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.configurationButton}
              label={<GearsIcon color="doveGray" />}
              onClick={this.handleOpenSubscriptionConfiguration}
            />
            <Select
              className={styles.filterBySelect}
              placeholder="Sort By"
              options={SORT_OPTIONS}
              value={sort}
              onChange={this.handleSortChange}
              noBottom
            />
          </div>
        </div>
        <div className={styles.subscriptions}>
          <InfiniteScroll
            loadMore={this.handleLoadMore}
            hasMore={!this.isAllProfleSubscriptionsLoaded()}
            loader={<Loader />}
            useWindow={false}
            researchForScrollParent
          >
            {subscriptions.map((subscription) => (
              <ProfileSubscription
                key={SubscriptionPresenter.id(subscription)}
                subscription={subscription}
                onDelete={onSubscriptionDelete}
                onUndoDelete={onSubscriptionUndoDelete}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

ProfileSubscriptionsList.propTypes = {
  subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()).isRequired,
  loadSubscriptions: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  onSubscriptionUndoDelete: PropTypes.func.isRequired,
  onSubscriptionDelete: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  subscriptionConfigurationLoading: PropTypes.bool,
  updateSubscriptionConfiguration: PropTypes.func,
  loadSubscriptionConfiguration: PropTypes.func,
};

export default ProfileSubscriptionsList;
