import React, { Component } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { isNil, includes } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import cn from 'clsx';
import styles from './Select.module.css';
import customStyles from './styles.js';

const disableRootContainerScroll = () => {
  const client = document.querySelector('.client');

  if (client) {
    client.style.overflow = 'hidden';
  }
};

const enableRootContainerScroll = () => {
  const client = document.querySelector('.client');

  if (client) {
    client.style.removeProperty('overflow');
  }
};

class Select extends Component {
  getOptionValue = (option) => {
    const { getOptionValue } = this.props;

    if (option === null) {
      return null;
    }

    return getOptionValue ? getOptionValue(option) : option.value;
  };

  getCurrentLabel = () => {
    const { getOptionLabel, value } = this.props;

    if (isNil(value)) {
      return null;
    }

    return isNil(getOptionLabel) ? value.label : getOptionLabel(value);
  };

  handleChange = (option, { action }) => {
    const { isMulti, onChange, onValueChange } = this.props;

    if (action === 'clear') {
      enableRootContainerScroll();
    }

    if (onChange) {
      onChange(option);
    }

    if (onValueChange) {
      const value = isMulti ? option.map(this.getOptionValue) : this.getOptionValue(option);

      onValueChange(value);
    }
  };

  handleMenuOpen = (...args) => {
    const { onMenuOpen, menuIsOpen } = this.props;

    if (isNil(menuIsOpen) || menuIsOpen) {
      disableRootContainerScroll();
    }

    if (onMenuOpen) {
      onMenuOpen(...args);
    }
  };

  handleMenuClose = (...args) => {
    const { onMenuClose } = this.props;

    enableRootContainerScroll();

    if (onMenuClose) {
      onMenuClose(...args);
    }
  };

  rootStyles = () => {
    const { className, isDisabled, errors, noBottom } = this.props;

    return cn([styles.root], {
      [className]: isPresent(className),
      [styles.disabled]: isDisabled,
      [styles.error]: errors,
      [styles.noBottom]: noBottom,
    });
  };

  customStyles = () => {
    const { openMenuOnClick } = this.props;
    const noIdicatior = () => ({
      display: 'none',
    });

    return {
      ...customStyles,
      dropdownIndicator: openMenuOnClick ? customStyles.dropdownIndicator : noIdicatior,
    };
  };

  value() {
    const { options, selectedOptionValue, value, isMulti } = this.props;

    if (selectedOptionValue || selectedOptionValue === null) {
      if (isMulti) {
        const res = options.filter((option) => includes(this.getOptionValue(option), selectedOptionValue));
        return res;
      }

      return options.find((option) => this.getOptionValue(option) === selectedOptionValue) || '';
    }

    return value;
  }

  render() {
    const { placeholder, errors, placeholder: innerPlaceholder, ...rest } = this.props;
    const errorsText = Array.isArray(errors) ? errors.join(', ') : errors;

    return (
      <div className={this.rootStyles()} title={this.getCurrentLabel()}>
        {(isPresent(placeholder) || isPresent(errorsText)) && (
          <div className={styles.info}>
            <div className={styles.placeholder}>{placeholder}</div>
            <div className={styles.errorText}>{errorsText}</div>
          </div>
        )}
        <ReactSelect
          {...rest}
          innerPlaceholder={innerPlaceholder}
          errors={errors}
          styles={this.customStyles()}
          value={this.value()}
          onChange={this.handleChange}
          placeholder=""
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
        />
      </div>
    );
  }
}

Select.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabled: PropTypes.bool,
  openMenuOnClick: PropTypes.bool,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  getOptionValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  selectedOptionValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  noBottom: PropTypes.bool,
  isMulti: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  getOptionLabel: PropTypes.func,
};

Select.defaultProps = {
  openMenuOnClick: true,
};

export default Select;
