import React from 'react';
import cn from 'clsx';
import config from 'config';

import WorkPresenter from 'presenters/WorkPresenter';

import styles from './Work.module.css';

const Work = (props) => {
  const { work } = props;
  const className = cn({ [config.changesHighlightsClassName]: WorkPresenter.isRecentlyUpdated(work) });

  return (
    <div key={WorkPresenter.id(work)} className={styles.work}>
      <strong className={className}>{WorkPresenter.fullJobTitle(work)}</strong>
      <br />
      <strong className={className}>{WorkPresenter.organizationName(work)}</strong>
    </div>
  );
};

Work.propTypes = {
  work: WorkPresenter.shape().isRequired,
};

export default Work;
