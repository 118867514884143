import { equals } from 'ramda';
import FullProfileRequestListPageConstants from './FullProfileRequestListPageConstants';

const initialState = {
  saving: false,
  loading: false,
  fullProfileRequests: [],
  nextPage: 1,
  filters: {
    searchFieldCont: '',
    sortFields: [{ field: 'createdAt', value: 'desc' }],
  },
  totalCount: null,
};

function FullProfileRequestListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FullProfileRequestListPageConstants.INITIALIZE_FULL_PROFILE_REQUEST_LIST_SUCCESS: {
      return initialState;
    }
    case FullProfileRequestListPageConstants.LOAD_FULL_PROFILE_REQUESTS_START: {
      return { ...state, loading: true };
    }
    case FullProfileRequestListPageConstants.LOAD_FULL_PROFILE_REQUESTS_SUCCESS: {
      const { nextPage, totalCount } = action;
      const fullProfileRequests = [...state.fullProfileRequests, ...action.fullProfileRequests];

      return { ...state, fullProfileRequests, nextPage, totalCount, loading: false };
    }
    case FullProfileRequestListPageConstants.FILTER_FULL_PROFILE_REQUESTS_START: {
      return {
        ...state,
        fullProfileRequests: [],
        selectedFullProfileRequestIds: [],
        loading: true,
        nextPage: 1,
        totalCount: null,
        filters: action.filters,
      };
    }
    case FullProfileRequestListPageConstants.FILTER_FULL_PROFILE_REQUESTS_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { fullProfileRequests, nextPage, totalCount } = action;
      return { ...state, fullProfileRequests, nextPage, totalCount, loading: false };
    }
    default:
      return state;
  }
}

export default FullProfileRequestListPageReducer;
