import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { components as ReactSelectComponents } from 'react-select';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { Anchor } from 'grommet';
import Button from 'components/Button';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import AsyncSelect from 'components/AsyncSelect';
import NewOrganizationModal from 'containers/AdminPanel/containers/NewOrganizationModal';

import Routes from 'routes';

import styles from './OrganizationSelector.module.css';

export default class OrganizationSelector extends Component {
  static propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    value: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    searchOrganizations: PropTypes.func.isRequired,
    industryName: PropTypes.string,
    setDefaultIndustry: PropTypes.bool,
  };

  state = {
    showCreateOrganizationPopup: false,
    newOrganizationName: '',
  };

  handleCreateNewOrganization = (newOrganizationName) => () =>
    this.setState({ ...this.state, showCreateOrganizationPopup: true, newOrganizationName });

  handleCancelNewOrganization = () =>
    this.setState({ ...this.state, showCreateOrganizationPopup: false, newOrganizationName: '' });

  handleOrganizationSearch = (searchText) => {
    const { searchOrganizations, industryName } = this.props;
    const defaultFilters = { searchFieldCont: searchText, s: ['organization_assotiated_profiles_count desc'] };
    const filters = industryName ? { ...defaultFilters, industryNameEq: industryName } : defaultFilters;

    return searchOrganizations(filters);
  };

  handleOrganizationSearchDebounced = reactSelectDebounce(this.handleOrganizationSearch);

  handleOrganizationCreate = (organization) => {
    this.setState({ showCreateOrganizationPopup: false, newOrganizationName: '' });
    this.props.onChange(organization.draft);
  };

  preventDefault = (event) => event.preventDefault();

  stopPropagation = (event) => event.stopPropagation();

  renderReactSelectInput = (props) => <ReactSelectComponents.Input {...props} onPaste={this.preventDefault} />;

  renderTitle = (organization) => {
    const profilesCount = OrganizationPresenter.assotiatedProfilesCount(organization);
    const filters = { associatedWithOrganizationIdsInclude: organization.id };

    return (
      <div>
        {OrganizationPresenter.nameWithState(organization)}
        <span className={styles.profileCountLink}>
          [
          <Anchor
            onClick={this.stopPropagation}
            href={Routes.adminProfilesPath({ q: filters })}
            label={profilesCount}
            target="_blank"
          />
          ]
        </span>
      </div>
    );
  };

  renderNoOptions = ({ inputValue }) => (
    <div>
      <p>The suitable organization was not found.</p>
      <Button primary label="Add Organization" onClick={this.handleCreateNewOrganization(inputValue)} />
    </div>
  );

  render() {
    const { errors, placeholder, value, onChange, industryName, setDefaultIndustry } = this.props;
    const { showCreateOrganizationPopup, newOrganizationName } = this.state;

    return (
      <>
        <AsyncSelect
          components={{ Input: this.renderReactSelectInput }}
          defaultOptions
          loadOptions={this.handleOrganizationSearchDebounced}
          errors={errors}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          getOptionValue={OrganizationPresenter.id}
          getOptionLabel={this.renderTitle}
          noOptionsMessage={this.renderNoOptions}
        />

        {showCreateOrganizationPopup && (
          <NewOrganizationModal
            onClose={this.handleCancelNewOrganization}
            organizationName={newOrganizationName}
            industryName={industryName}
            onCreateSuccess={this.handleOrganizationCreate}
            loadDefaultIndustry={setDefaultIndustry}
          />
        )}
      </>
    );
  }
}
