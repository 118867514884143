import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from 'components/Select';
import { CheckBox, Text as Label } from 'grommet';

import { TIMEFRAMES } from 'presenters/TimeframePresenter';
import { TIME_PERIODS } from 'presenters/TimePeriodPresenter';
import { REQUEST_TYPES, STATES } from 'presenters/RequestPresenter';
import { VALUE_OPTIONS, STATISTIC_OPTIONS } from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';

import styles from './FiltersForm.module.css';

const REQUEST_TYPE_OPTIONS = REQUEST_TYPES.map(({ key, title }) => ({ label: title, value: key }));
const REQUEST_STATES_OPTIONS = STATES.map(({ key, title }) => ({ label: title, value: key }));

export default class FiltersForm extends Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
    onFilterChange: PropTypes.func.isRequired,
  };

  handleCheckboxChange =
    (filterName) =>
    ({ target: { checked } }) => {
      const { onFilterChange } = this.props;

      return onFilterChange(filterName)(checked);
    };

  render() {
    const { filters, onFilterChange } = this.props;

    return (
      <div className={styles.filters}>
        <div className={styles.filter}>
          <div className={styles.title}>Request Type</div>
          <div className={styles.select}>
            <Select
              options={REQUEST_TYPE_OPTIONS}
              onValueChange={onFilterChange('requestType')}
              selectedOptionValue={filters.requestType}
              noBottom
              classNamePrefix="filter"
            />
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>Final State</div>
          <div className={styles.select}>
            <Select
              options={REQUEST_STATES_OPTIONS}
              onValueChange={onFilterChange('finalState')}
              selectedOptionValue={filters.finalState}
              noBottom
              classNamePrefix="filter"
            />
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>Value</div>
          <div className={styles.select}>
            <Select
              options={VALUE_OPTIONS}
              onValueChange={onFilterChange('statisticValue')}
              selectedOptionValue={filters.statisticValue}
              noBottom
              classNamePrefix="filter"
            />
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>Statistic</div>
          <div className={styles.select}>
            <Select
              options={STATISTIC_OPTIONS}
              onValueChange={onFilterChange('statistic')}
              selectedOptionValue={filters.statistic}
              noBottom
              classNamePrefix="filter"
            />
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>Period</div>
          <div className={styles.select}>
            <Select
              options={TIME_PERIODS}
              onValueChange={onFilterChange('period')}
              selectedOptionValue={filters.period}
              noBottom
              classNamePrefix="filter"
            />
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.title}>Timeframe</div>
          <div className={styles.select}>
            <Select
              options={TIMEFRAMES}
              onValueChange={onFilterChange('timeframe')}
              selectedOptionValue={filters.timeframe}
              noBottom
              classNamePrefix="filter"
            />
          </div>
        </div>
        <div className={styles.compare}>
          <Label labelFor="checkbox" className={styles.label}>
            <div className={styles.description}>
              <span>
                Compare with <br /> previous timeframe
              </span>
              <div className={styles.checkbox}>
                <CheckBox onChange={this.handleCheckboxChange('compare')} checked={filters.compare} id="checkbox" />
              </div>
            </div>
          </Label>
        </div>
      </div>
    );
  }
}
