import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(profileId, institutionId, params) {
    const url = Routes.apiV1AdminProfileDraftInstitutionPath(profileId, institutionId);

    return FetchHelpers.patch(url, params);
  },

  create(profileId, params) {
    const url = Routes.apiV1AdminProfileDraftInstitutionsPath(profileId);

    return FetchHelpers.post(url, params);
  },

  destroy(profileId, institutionId) {
    const url = Routes.apiV1AdminProfileDraftInstitutionPath(profileId, institutionId);

    return FetchHelpers.delete(url);
  },
};
