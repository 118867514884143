import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Checkbox.module.css';

export const CHECKBOX_ROLE = 'CHECKBOX';

const Checkbox = (props) => {
  const { checked, className, onChange, disabled } = props;
  const checkboxClassName = clsx(styles.checkbox, className);

  const handleChange = ({ target }) => onChange(target.checked);

  return (
    <div className={styles.root}>
      <input className={styles.input} type="checkbox" checked={checked} onChange={handleChange} disabled={disabled} />
      <span className={checkboxClassName}>
        <svg className={styles.checkmark} viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <path fill="none" d="M6,11.3 L10.3,16 L18,6.2" />
        </svg>
      </span>
    </div>
  );
};

Checkbox.fieldRole = CHECKBOX_ROLE;

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: null,
  onChange: () => {},
  disabled: false,
};

export default Checkbox;
