import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';
import { isNil } from 'ramda';

import WorkPresenter from './WorkSearchPresenter';

export const GENDERS = [
  { key: 'male', title: 'Male' },
  { key: 'female', title: 'Female' },
];

export default new Presenter(
  {
    id: PropTypes.number,
    slug: PropTypes.string,
    fullName: PropTypes.string,
    interests: PropTypes.arrayOf(PropTypes.string),
    gender: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    blogUrl: PropTypes.string,
    twitterUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    updatedAt: PropTypes.string,
    photoUrl: PropTypes.string,
    boards: PropTypes.arrayOf(PropTypes.string),
    country: PropTypes.string,
    state: PropTypes.string,
    profileTopics: PropTypes.arrayOf(PropTypes.string),
    briefBiography: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    publicPersonInformation: PropTypes.string,
    currentWorks: PropTypes.arrayOf(WorkPresenter.shape()),
    isNewToRole: PropTypes.bool,
    significantlyUpdatedAt: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
    recentCurrentWorkStartedAt: PropTypes.string,
    isAvailableProfile: PropTypes.bool,
    isMemberFortune500: PropTypes.bool,
    isMemberFtse100: PropTypes.bool,
  },
  {
    formattedUpdatedAt(p) {
      return formattedDate(this.updatedAt(p));
    },

    isMasked(p) {
      return !isNil(this.isAvailableProfile(p)) && !this.isAvailableProfile(p);
    },
  },
);
