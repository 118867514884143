import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ExecutivesChangesStatistics from './ExecutivesChangesStatistics';
import * as actions from './ExecutivesChangesStatisticsActions';

const mapStateToProps = (state) => ({
  loading: state.ExecutivesChangesStatisticsReducer.loading,
  executivesChangesStatistics: state.ExecutivesChangesStatisticsReducer.executivesChangesStatistics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExecutivesChangesStatistics);
