import {
  currentUserExecutivesMatchesRestHooks,
  useCurrentUserExecutivesMatchesSelector,
  useCurrentUserExecutivesMatchesActions,
} from 'slices/currentUser/executivesMatchesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useExecutivesMatches = () => {
  const { executivesMatch, createExecutivesMatch } = currentUserExecutivesMatchesRestHooks.use();
  const { validateMatchExecutiveParams } = useCurrentUserExecutivesMatchesActions();
  const { matchExecutivesParams } = useCurrentUserExecutivesMatchesSelector();

  return {
    executivesMatches: executivesMatch.item,
    executivesMatchesMeta: executivesMatch.meta,
    matchExecutives: createExecutivesMatch,
    matchExecutivesParamsValidationResult: matchExecutivesParams.validationResult,
    matchExecutivesParamsValidationStatus: useFetchStatus(matchExecutivesParams.validationStatus),
    matchExecutivesStatus: useFetchStatus(executivesMatch.creatingStatus),
    validateMatchExecutiveParams,
  };
};

export default useExecutivesMatches;
