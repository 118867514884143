import React from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';

import { fromISO } from 'utils/DateTime';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import { useAdminAttributeSafeCompanies } from 'hooks/admin';

import CompanyPresenter from 'presenters/CompanyPresenter';

import { AsyncSelect, Field, Select, DatePicker } from 'components/v2/Form';
import Button from 'components/v2/Button';

import { ACCOUNT_TYPES } from 'presenters/Company/DepartmentPresenter';

import styles from './FiltersForm.module.css';

const FiltersForm = (props) => {
  const { onFiltersChange, onSubmit, filters } = props;

  const { loadAttributeSafeCompanies } = useAdminAttributeSafeCompanies();

  const handleCompanySearch = (value) => {
    const params = buildFilterParams({ nameCont: value });

    return loadAttributeSafeCompanies(params).then(({ items }) => items);
  };

  const handleDateFromChange = (from) => {
    const dateFrom = fromISO(from);
    const dateTo = fromISO(filters.to);
    const newFilters = { ...filters, from };

    if (dateTo <= dateFrom) {
      newFilters.to = dateFrom.plus({ days: 1 }).toISO();
    }

    onFiltersChange(newFilters);
  };

  const handleFieldChange = (fieldName) => (value) => onFiltersChange({ ...filters, [fieldName]: value });

  return (
    <div className={styles.root}>
      <Field title="From" className={styles.field}>
        <DatePicker value={filters.from} onChange={handleDateFromChange} minDate={new Date()} />
      </Field>

      <Field title="To" className={styles.field}>
        <DatePicker
          value={filters.to}
          onChange={handleFieldChange('to')}
          minDate={addDays(new Date(filters.from), 1)}
        />
      </Field>

      <Field title="Account Type" className={styles.field}>
        <Select
          className={styles.fieldSelect}
          options={ACCOUNT_TYPES}
          onChange={handleFieldChange('accountType')}
          isClearable
        />
      </Field>

      <Field title="Company Name" className={styles.field}>
        <AsyncSelect
          className={styles.fieldSelect}
          loadOptions={handleCompanySearch}
          defaultOptions
          value={filters.company}
          onChange={handleFieldChange('company')}
          getOptionValue={CompanyPresenter.id}
          getOptionLabel={CompanyPresenter.name}
          isClearable
          noBottom
        />
      </Field>

      <div className={styles.btn}>
        <Button className={styles.button} onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

FiltersForm.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
};

export default FiltersForm;
