import OrganizationListPageConstants from './OrganizationListPageConstants';

const initialState = {
  filters: {
    searchFieldCont: '',
    sortFields: [],
  },
};

function OrganizationListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OrganizationListPageConstants.CHANGE_ORGANIZATIONS_FILTERS: {
      const { filters } = action;

      return { ...state, filters };
    }
    default:
      return state;
  }
}

export default OrganizationListPageReducer;
