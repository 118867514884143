import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { logClickOnBusinessPriorityAnalysisTabDetails } from 'utils/amplitude';

import BarChart from 'containers/UserPanel/containers/Project/containers/BusinessPriorityAnalysis/components/BarChart';
import Spinner from 'components/Spinner';
import Sidebar from 'containers/UserPanel/containers/Project/containers/ProjectProfilesListSidebar';

import ProjectPresenter, { PROJECT_TAG_PROFILES_CSV } from 'presenters/ProjectPresenter';
import RolePresenter from 'presenters/Project/BPA/RolePresenter';
import TagPresenter from 'presenters/Project/BPA/TagPresenter';

import styles from './Roles.module.css';

export default class Roles extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadTopRoles: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
    roles: PropTypes.arrayOf(RolePresenter.shape()).isRequired,
    onExport: PropTypes.func.isRequired,
  };

  state = { isSidebarOpened: false, sidebarProps: {} };

  componentDidMount() {
    const { loadTopRoles, project } = this.props;

    return loadTopRoles(ProjectPresenter.id(project));
  }

  handleProfilesExport = (params) => () => {
    const { onExport } = this.props;

    return onExport({ type: PROJECT_TAG_PROFILES_CSV, params });
  };

  handleOpenSidebar = (sidebarProps) => this.setState({ ...this.state, isSidebarOpened: true, sidebarProps });

  handleCloseSidebar = () => this.setState({ ...this.state, isSidebarOpened: false, sidebarProps: {} });

  handleClickTitle = (title) => {
    const sidebarProps = {
      title,
      filters: { jobTitle: title },
    };

    logClickOnBusinessPriorityAnalysisTabDetails('By Role', title);

    this.handleOpenSidebar(sidebarProps);
  };

  handleClickSubtitle = (title, rootTag) => {
    const rootTagId = TagPresenter.id(rootTag);
    const sidebarProps = {
      title: `${title}: ${TagPresenter.title(rootTag)}`,
      filters: { jobTitle: title, rootTagId },
      profilePathQuery: { tags: [rootTagId] },
      onExport: this.handleProfilesExport({ search: { jobTitle: title, rootTagId }, tagId: rootTagId }),
    };

    logClickOnBusinessPriorityAnalysisTabDetails('By Role', title, TagPresenter.title(rootTag));

    this.handleOpenSidebar(sidebarProps);
  };

  render() {
    const { loading, roles, project } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const { isSidebarOpened, sidebarProps } = this.state;

    return (
      <>
        {isSidebarOpened && <Sidebar project={project} onClose={this.handleCloseSidebar} {...sidebarProps} />}
        <div className={styles.roles}>
          {roles.map((role) => (
            <BarChart
              key={RolePresenter.title(role)}
              title={RolePresenter.title(role)}
              profilesCount={RolePresenter.profilesCount(role)}
              tags={RolePresenter.tags(role)}
              onTitleClick={this.handleClickTitle}
              onSubtitleClick={this.handleClickSubtitle}
            />
          ))}
        </div>
      </>
    );
  }
}
