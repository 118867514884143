import PropTypes from 'prop-types';
import React from 'react';

import RequestPresenter from 'presenters/RequestPresenter';

import Button from 'components/v2/Button';
import { Field, Input } from 'components/v2/Form';

import styles from './EditFormRow.module.css';

const EditFormRow = (props) => {
  const { onFieldChange, onReset, onSubmit, values: request } = props;

  const handleFieldChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <tr className={styles.editFormRow}>
      <td />
      <td>
        <Field>
          <Input
            onChange={handleFieldChange('subjectAttributes.firstName')}
            value={request.subjectAttributes.firstName}
          />
        </Field>
      </td>
      <td>
        <Field>
          <Input
            onChange={handleFieldChange('subjectAttributes.lastName')}
            value={request.subjectAttributes.lastName}
          />
        </Field>
      </td>
      <td>
        <Field>
          <Input
            onChange={handleFieldChange('subjectAttributes.jobTitle')}
            value={request.subjectAttributes.jobTitle}
          />
        </Field>
      </td>
      <td>
        <Field>
          <Input
            onChange={handleFieldChange('subjectAttributes.organizationName')}
            value={request.subjectAttributes.organizationName}
          />
        </Field>
      </td>
      <td>
        <div className={styles.actions}>
          <Button className={styles.button} onClick={onSubmit} variant="outlined">
            Save
          </Button>
          <Button className={styles.button} onClick={onReset} variant="outlined">
            Cancel
          </Button>
        </div>
      </td>
    </tr>
  );
};

EditFormRow.propTypes = {
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: RequestPresenter.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default EditFormRow;
