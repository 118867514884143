import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  },
  {},
);
