import PropTypes from 'prop-types';
import React from 'react';
import Tabs, { TabList } from 'components/v2/Tabs';

const RoutedTabs = (props) => {
  const { className, children } = props;

  return (
    <Tabs>
      <TabList className={className}>{children}</TabList>
    </Tabs>
  );
};

RoutedTabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RoutedTabs.defaultProps = {
  className: null,
};

export default RoutedTabs;
