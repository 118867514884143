import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Box } from 'grommet';
import Button from 'components/Button';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';

import Modal from 'components/Modal';
import EditableContent from 'components/EditableContent';
import Avatar from './components/Avatar';
import EditForm from './components/EditForm';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import styles from './PersonalInfo.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';

import Routes from 'routes';

class PersonalInfo extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    profile: ProfilePresenter.shape(),
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()),
    onUpdate: PropTypes.func.isRequired,
    onPhotoUpdate: PropTypes.func.isRequired,
    onPhotoDelete: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { edit: false };
  }

  handleEdit = () => this.setState({ edit: true });

  handleCloseModal = () => this.setState({ edit: false });

  handleSubmit = (profile) => this.props.onUpdate(profile).then(this.handleCloseModal);

  renderCurrentWork = (currentWork) => (
    <Paragraph key={WorkPresenter.id(currentWork)} margin="none" className={styles.organization}>
      {WorkPresenter.fullJobTitle(currentWork)}
      <br />
      {WorkPresenter.organizationNameWithState(currentWork)}
      <br />
    </Paragraph>
  );

  render() {
    const { editable, profile, jobFunctions, onPhotoUpdate, onPhotoDelete, saving, isChanged, onReviewChanges } =
      this.props;
    const currentWorks = ProfilePresenter.currentWorks(profile);

    return (
      <div className={styles.root}>
        <EditableContent
          editable={editable}
          onEdit={this.handleEdit}
          tooltipComponent={StyleGuides.PersonalInformationStyleGuide}
        >
          <div className={styles.profile}>
            <Box margin={{ right: 'large' }} responsive={false}>
              <Avatar
                editable={editable}
                photoUrl={ProfilePresenter.photoUrl(profile)}
                photoUpdatedAt={ProfilePresenter.formattedPhotoUpdatedAt(profile)}
                alt={ProfilePresenter.fullNameWithSalutation(profile)}
                onUpdate={onPhotoUpdate}
                onDelete={onPhotoDelete}
                saving={saving}
              />
            </Box>
            <Box className={styles.personalInfo}>
              <HeadlineWithReviewChangesLink
                strong
                size="small"
                margin="none"
                className={styles.fullName}
                title={ProfilePresenter.fullNameWithSalutation(profile)}
                isChanged={isChanged}
                onReviewChanges={onReviewChanges}
              />
              {currentWorks.map(this.renderCurrentWork)}
              <Paragraph margin="none" className={styles.date}>
                Last updated: {ProfilePresenter.formattedLastUpdatedAt(profile)}
              </Paragraph>
              <Paragraph margin="none" className={styles.editorialDate}>
                Last Editorial Update: {ProfilePresenter.formattedProfileUpdatedAt(profile)}
              </Paragraph>
              <Paragraph margin="none" className={styles.functions}>
                Job Functions: {ProfilePresenter.currentWorksJobFunctionsStr(profile, this.props.jobFunctions)}
              </Paragraph>
              <Box direction="row" margin={{ top: 'small' }}>
                <Button
                  label="Download"
                  href={Routes.pdfV1AdminProfilePath(profile.id)}
                  target="_blank"
                  primary
                  className="button"
                />
              </Box>
            </Box>
          </div>
        </EditableContent>
        {this.state.edit ? (
          <Modal
            onClose={this.handleCloseModal}
            size="medium"
            header="Personal Information"
            disableOverlayClose
            disableCloseByEsc
          >
            <EditForm profile={profile} jobFunctions={jobFunctions} onSubmit={this.handleSubmit} saving={saving} />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default PersonalInfo;
