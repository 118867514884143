import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { UserAdd as UserAddIcon } from 'grommet-icons';

import Button from 'components/Button';

import style from './AssignUserTableAction.module.css';

class AssignUserTableAction extends Component {
  static propTypes = {
    selectedRowIds: PropTypes.arrayOf(PropTypes.number),
    onClick: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { onClick, selectedRowIds } = this.props;

    onClick(selectedRowIds);
  };

  render() {
    const { selectedRowIds } = this.props;

    return (
      <Button
        plain
        className={clsx('grommet_legacy-button', style.button)}
        icon={<UserAddIcon className="grommet_legacy-control-icon" />}
        label={`Assign (${selectedRowIds.length})`}
        onClick={this.handleClick}
      />
    );
  }
}

export default AssignUserTableAction;
