import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';

import ProfileSubscriptionConstants from './ProfileSubscriptionsListContainerConstants';
import SubscriptionsRepository from 'repositories/CurrentUser/SubscriptionsRepository';

const BASE_FILTERS = { subjectTypeEq: 'Profile' };

export function loadProfilesSubscriptions({ page = 1, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: ProfileSubscriptionConstants.LOAD_PROFILE_SUBSCRIPTIONS_START });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: ProfileSubscriptionConstants.LOAD_PROFILE_SUBSCRIPTIONS_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: ProfileSubscriptionConstants.LOAD_PROFILE_SUBSCRIPTIONS_ERROR });

        throw errors;
      });
  };
}

export function filterProfileSubscriptions({ per, ...filters }) {
  const searchParams = { ...buildFilterParams({ ...filters, ...BASE_FILTERS }), page: 1, per };

  return (dispatch) => {
    dispatch({ type: ProfileSubscriptionConstants.FILTER_PROFILE_SUBSCRIPTIONS_START, filters });

    return SubscriptionsRepository.index(searchParams)
      .then(({ items, meta }) => {
        dispatch({
          type: ProfileSubscriptionConstants.FILTER_PROFILE_SUBSCRIPTIONS_SUCCESS,
          items,
          meta,
          filters,
        });

        return items;
      })
      .catch((errors) => {
        dispatch({ type: ProfileSubscriptionConstants.FILTER_PROFILE_SUBSCRIPTIONS_ERROR });

        throw errors;
      });
  };
}

export function deleteProfileSubscription(id) {
  return (dispatch) => {
    dispatch({ type: ProfileSubscriptionConstants.DELETE_PROFILE_SUBSCRIPTION_START });

    return SubscriptionsRepository.destroy(id)
      .then(({ subscription }) => {
        dispatch({ type: ProfileSubscriptionConstants.DELETE_PROFILE_SUBSCRIPTION_SUCCESS, id });

        return subscription;
      })
      .catch((errors) => {
        dispatch({ type: ProfileSubscriptionConstants.DELETE_PROFILE_SUBSCRIPTION_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw errors;
      });
  };
}

export function undoDeleteProfileSubscription({ id, kind, subjectId }) {
  const params = { kind, profileId: subjectId };

  return (dispatch) => {
    dispatch({ type: ProfileSubscriptionConstants.UNDO_DELETE_PROFILE_SUBSCRIPTION_START });

    return SubscriptionsRepository.create(params)
      .then(({ subscription }) => {
        dispatch({ type: ProfileSubscriptionConstants.UNDO_DELETE_PROFILE_SUBSCRIPTION_SUCCESS, subscription, id });

        return subscription;
      })
      .catch((errors) => {
        dispatch({ type: ProfileSubscriptionConstants.UNDO_DELETE_PROFILE_SUBSCRIPTION_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw errors;
      });
  };
}
