import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, toPairs, pipe, map, join } from 'ramda';
import cn from 'clsx';

import Anchor from 'components/Anchor';

import useCurrentUserRemainingRequestsNumber from 'hooks/useCurrentUserRemainingRequestsNumber';

import styles from './GrantAccessNotice.module.css';

const GrantAccessNotice = (props) => {
  const { onCreate } = props;
  const [isCreating, setCreatingFlag] = useState(false);
  const [error, setError] = useState(null);
  const [remainingRequestsNumber, refreshRemainingRequestsNumber] = useCurrentUserRemainingRequestsNumber(0);

  const buildError = (errors) => pipe(toPairs, map(join(' ')), join(', '))(errors);

  const handleCreate = () => {
    setCreatingFlag(true);
    onCreate()
      .catch(({ errors }) => {
        refreshRemainingRequestsNumber();
        setError(buildError(errors));
      })
      .finally(() => {
        setCreatingFlag(false);
      });
  };

  if (!isNil(error)) {
    return (
      <div className={cn([styles.info, styles.errorAlert])}>
        {remainingRequestsNumber <= 0 ? (
          <>
            You have used all of your credits. To purchase more credits, please contact{' '}
            <Anchor href="mailto:customerservice@boardroominsiders.com">customerservice@boardroominsiders.com</Anchor>
          </>
        ) : (
          error
        )}
      </div>
    );
  }

  return (
    <div className={cn([styles.info, styles.infoAlert])}>
      <Anchor className={styles.linkAction} label=" Click here " disabled={isCreating} onClick={handleCreate} />
      for instant access to this profile in exchange for 1 profile credit. Credits remaining:{' '}
      <b>{remainingRequestsNumber}</b>
    </div>
  );
};

GrantAccessNotice.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default GrantAccessNotice;
