import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(organizationId, topicId, topic) {
    const url = Routes.apiV1AdminOrganizationDraftTopicPath(organizationId, topicId);
    return FetchHelpers.patch(url, { topic });
  },

  create(organizationId, topic) {
    const url = Routes.apiV1AdminOrganizationDraftTopicsPath(organizationId);
    return FetchHelpers.post(url, { topic });
  },

  destroy(organizationId, topicId) {
    const url = Routes.apiV1AdminOrganizationDraftTopicPath(organizationId, topicId);
    return FetchHelpers.delete(url);
  },
};
