import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(organizationId, alias) {
    const url = Routes.apiV1AdminOrganizationDraftAliasesPath(organizationId);

    return FetchHelpers.post(url, { alias });
  },

  destroy(organizationId, topicId) {
    const url = Routes.apiV1AdminOrganizationDraftAliasPath(organizationId, topicId);

    return FetchHelpers.delete(url);
  },
};
