import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TargetingSourceEditPage from './TargetingSourceEditPage';
import * as actions from './TargetingSourceEditPageActions';

const mapStateToProps = (state, props) => ({
  sourceId: props.match.params.sourceId,
  source: state.TargetingSourceEditPageReducer.source,
  loading: state.TargetingSourceEditPageReducer.loading,
  saving: state.TargetingSourceEditPageReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TargetingSourceEditPage);
