import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserPanel from './UserPanel';
import { backgroundExport } from './containers/BackgroundExport';

const mapStateToProps = (state) => ({
  notificationsSummary: state.BackendNotificationsReducer.summary,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ backgroundExport }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
