import { equals, intersection, length, prop, propEq, reject, sortBy, uniq } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

export default class Graph {
  constructor(nodes, links) {
    this.links = links;
    this.nodes = nodes;
    this.adjList = this.buildAdjList();
    this.neighNodes = this.buildNeighNodesHash();
    this.groupsCount = 0;
    this.nodes = this.addMaxLinkStrengthToNodes();
  }

  addMaxLinkStrengthToNodes() {
    return this.nodes.map((node) => {
      const maxStrength = this.nodeMaxLinkStrength(node);

      return { ...node, maxStrength };
    });
  }

  addGroupToNodes() {
    const interconnectedNodes = sortBy(length, this.aggregateInterconnectedNodes())
      .filter((nodes) => nodes.length >= 2)
      .reverse();

    const nodeToGroup = interconnectedNodes.reduce((acc, nodes, i) => {
      nodes.forEach((node) => {
        acc[node] = i;
      });

      return acc;
    }, {});

    this.nodes = this.nodes.map((node) => ({ ...node, group: nodeToGroup[node.id] || -1 }));
  }

  aggregateInterconnectedNodes = () => {
    const interconnectedNodes = this.nodes.map((node) => {
      const neighNodesIds = this.getNeighNodesIds(node.id);

      return neighNodesIds
        .reduce(
          (acc, neighVertexId) => {
            if (intersection(this.getNeighNodesIds(neighVertexId), acc).length + 1 < acc.length) {
              return reject(equals(neighVertexId), acc);
            }

            return acc;
          },
          [...neighNodesIds, node.id],
        )
        .sort();
    });

    return uniq(interconnectedNodes);
  };

  buildNeighNodesHash = () =>
    this.links.reduce((acc, { source, target }) => {
      const b = acc[source] || [];
      const m = acc[target] || [];
      acc[source] = [...b, target];
      acc[target] = [...m, source];

      return acc;
    }, {});

  buildAdjList = () =>
    this.links.reduce((acc, link) => {
      acc[`${link.source}-${link.target}`] = link.strength;
      acc[`${link.target}-${link.source}`] = link.strength;

      return acc;
    }, {});

  linkStrength = (firstVertexId, secondVertexId) => this.adjList[`${firstVertexId}-${secondVertexId}`];

  neigh = (firstVertexId, secondVertexId) =>
    firstVertexId === secondVertexId || isPresent(this.adjList[`${firstVertexId}-${secondVertexId}`]);

  getNeighNodesIds = (nodeId) => this.neighNodes[nodeId] || [];

  nodeMaxLinkStrength = (node) => {
    const nodeCurrentOrganizationIds = node.currentWorks.map(prop('organizationId'));

    return this.getNeighNodesIds(node.id).reduce((acc, neighNodeId) => {
      const neighNode = this.nodes.find(propEq(neighNodeId, 'id'));
      const neighNodeCurrentOrganizationIds = neighNode.currentWorks.map(prop('organizationId'));

      if (intersection(nodeCurrentOrganizationIds, neighNodeCurrentOrganizationIds).length > 0) {
        return acc;
      }

      const strength = this.linkStrength(node.id, neighNodeId);

      return acc < strength ? strength : acc;
    }, -1);
  };
}
