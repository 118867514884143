import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';
import onClickOutside from 'react-onclickoutside';
import { all, equals, isEmpty, isNil } from 'ramda';

import styles from './Menu.module.css';

class MenuComponent extends Component {
  handleClickOutside = () => {
    const { isOpen, onClose } = this.props;

    if (isOpen) {
      setTimeout(onClose, 15);
    }
  };

  render() {
    const { className, children, isOpen } = this.props;
    const rootClassName = cn(styles.root, { [styles.hidden]: !isOpen, [className]: isPresent(className) });
    const isEmptyChildren = all((child) => equals(false, child) || isEmpty(child) || isNil(child))(children);

    if (isEmptyChildren) {
      return null;
    }

    return <div className={rootClassName}>{children}</div>;
  }
}

const Menu = onClickOutside(MenuComponent);

MenuComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

MenuComponent.defaultProps = {
  className: null,
};

export default Menu;
