import React from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import { isNil } from 'ramda';
import 'rc-tooltip/assets/bootstrap.css';
import './Tooltip.module.css';

class Tooltip extends React.Component {
  constructor(...args) {
    super(...args);

    const { visible } = this.props;
    this.state = { isVisible: visible };
  }

  handleVisibleChange = (isVisible) => {
    const { visible } = this.props;

    if (!isNil(visible)) {
      return;
    }

    this.setState({
      isVisible,
    });
  };

  componentDidUpdate(prevProps) {
    const { visible } = this.props;

    if (prevProps.visible !== visible) {
      this.setState({ isVisible: visible });
    }
  }

  render() {
    const { text, children, ...restProps } = this.props;
    const { isVisible } = this.state;
    return (
      <RCTooltip
        visible={isVisible}
        placement="right"
        overlay={text}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        onVisibleChange={this.handleVisibleChange}
        {...restProps}
      >
        {children}
      </RCTooltip>
    );
  }
}

Tooltip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
};

export default Tooltip;
