import keyMirror from 'keymirror';

const TargetingSourceEditPageConstants = keyMirror({
  LOAD_PROJECT_TARGET_SOURCE_START: null,
  LOAD_PROJECT_TARGET_SOURCE_SUCCESS: null,

  UPDATE_PROJECT_TARGET_SOURCE_START: null,
  UPDATE_PROJECT_TARGET_SOURCE_SUCCESS: null,
  UPDATE_PROJECT_TARGET_SOURCE_ERROR: null,

  CHANGE_PROJECT_TARGET_SOURCE: null,
});

export default TargetingSourceEditPageConstants;
