import React from 'react';
import PropTypes from 'prop-types';

import CompanyPresenter from 'presenters/CompanyPresenter';

import GeneralInformationFields from './components/GeneralInformationFields';
import SelfRegistrationFormFields from './components/SelfRegistrationFormFields';
import SelfRegistrationFormContentFields from './components/SelfRegistrationFormContentFields';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const { errors, onFieldChange, onSubmit, initialValues, isSubmitting, values } = props;

  return (
    <div className={styles.form}>
      <div className={styles.leftColumn}>
        <GeneralInformationFields
          errors={errors}
          onFieldChange={onFieldChange}
          initialCompanyValues={initialValues}
          companyValues={values}
        />
        <SelfRegistrationFormFields errors={errors} onFieldChange={onFieldChange} companyValues={values} />
      </div>
      <div className={styles.rightColumn}>
        <SelfRegistrationFormContentFields
          companyValues={values}
          errors={errors}
          isSubmitting={isSubmitting}
          onFieldChange={onFieldChange}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: CompanyPresenter.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: CompanyPresenter.shape().isRequired,
};

export default EditForm;
