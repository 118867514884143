import ProfileRelationshipsToMyExecutivesListConstants from './ProfileRelationshipsToMyExecutivesListConstants';

const initialState = {
  loading: false,
  relationships: [],
  nextPage: null,
  currentPage: null,
  profileId: null,
};

function ProfileRelationshipsToMyExecutivesListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileRelationshipsToMyExecutivesListConstants.INITIALIZE_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES: {
      const { profileId } = action;

      return { ...state, loading: false, relationships: [], nextPage: 1, profileId };
    }
    case ProfileRelationshipsToMyExecutivesListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES_START: {
      return { ...state, loading: true };
    }
    case ProfileRelationshipsToMyExecutivesListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES_SUCCESS: {
      const { relationships, meta, profileId } = action;

      if (state.profileId !== profileId) {
        return state;
      }

      return { ...state, relationships: [...state.relationships, ...relationships], loading: false, ...meta };
    }
    default:
      return state;
  }
}

export default ProfileRelationshipsToMyExecutivesListReducer;
