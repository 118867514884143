import { pipe, split, last, defaultTo } from 'ramda';
import papaparse from 'papaparse';
import { readLine } from 'utils/FileReader';
import { isBlank } from 'utils/HelperMethods';

const MAX_FILE_SIZE = 4 * 1024 * 1024;

export const isExceedMaxSize = (file) => {
  if (isBlank(file)) {
    return false;
  }

  return MAX_FILE_SIZE <= file.size;
};

export const isMatchFileExt = (file, ext) => {
  const fileExt = pipe(split('.'), last, defaultTo(''))(file.name);

  return ext.toLowerCase() === fileExt.toLocaleLowerCase();
};

export const detectCsvFileDelimiter = (file) =>
  readLine(file)
    .then(papaparse.parse)
    .then(({ meta: { delimiter } }) => delimiter);
