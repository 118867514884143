import RequestStatisticBlockConstants from './RequestStatisticBlockConstants';

const initialState = {
  loading: false,
  statistic: null,
};

function RequestStatisticBlockReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RequestStatisticBlockConstants.LOAD_REQUEST_STATISTIC_START: {
      return { ...state, loading: true };
    }
    case RequestStatisticBlockConstants.LOAD_REQUEST_STATISTIC_SUCCESS: {
      return { ...state, loading: false, statistic: action.statistic };
    }
    default:
      return state;
  }
}

export default RequestStatisticBlockReducer;
