import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TopTags from './TopTags';
import * as actions from './TopTagsActions';

const mapStateToProps = (state) => ({
  loading: state.TopTagsReducer.loading,
  tags: state.TopTagsReducer.tags,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopTags);
