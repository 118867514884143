import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(backgroundExport = {}) {
    const url = Routes.apiV1CurrentUserExportsPath();

    return JsonFetchHelpers.post(url, { export: backgroundExport });
  },

  show(id) {
    const url = Routes.apiV1CurrentUserExportPath(id);

    return JsonFetchHelpers.get(url);
  },
};
