import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Edit as EditIcon, CircleInformation as CircleInformationIcon } from 'grommet-icons';

import { isPresent } from 'utils/HelperMethods';

import Card from 'components/Card';
import StyleGuideModal from 'components/StyleGuideModal';

import styles from './EditableContent.module.css';

export default class EditableContent extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    tooltipComponent: PropTypes.func.isRequired,
  };

  handleEdit = () => {
    this.props.onEdit();
  };

  render() {
    const { children, className, editable, tooltipComponent } = this.props;

    const rootClassNames = cx([styles.root], { [className]: isPresent(className) });

    return (
      <Card className={rootClassNames} fullHeight>
        <div className={styles.children}>{children}</div>
        <div className={styles.panel}>
          {editable && (
            <div className={styles.action}>
              <EditIcon color="light-1" onClick={this.handleEdit} />
            </div>
          )}
          <div className={styles.action}>
            <StyleGuideModal modalContent={tooltipComponent}>
              <CircleInformationIcon color="light-1" />
            </StyleGuideModal>
          </div>
        </div>
      </Card>
    );
  }
}

EditableContent.propTypes = {
  onEdit: PropTypes.func,
};
