import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isBlank } from 'utils/HelperMethods';
import { logClickOnAlertsRemoveFromAlerts } from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';
import Button from 'components/Button';
import Select from 'components/Select';
import GearsIcon from 'icons/GearsIcon';
import Icon from 'components/Icon';
import ProjectItem from 'containers/UserPanel/components/ProjectItem';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/v2/Loader';
import MenuIcon from 'icons/MenuIcon';
import SubscriptionConfigurationPopUp from '../SubscriptionConfigurationPopUp';

import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import { PROJECT_SUBSCRIPTION_CONFIGURATION } from 'presenters/SubscriptionConfigurationPresenter';

import Routes from 'routes';

import styles from './ProjectSubscriptionsList.module.css';

const SORT_OPTIONS = [
  {
    label: 'Recently Updated',
    field: 'subject_of_Project_type_target_scope_updated_at',
    value: 'desc',
    preventDecamilization: true,
  },
  {
    label: 'Name A-Z',
    field: 'subject_of_Project_type_name',
    value: 'asc',
    preventDecamilization: true,
  },
  {
    label: 'Name Z-A',
    field: 'subject_of_Project_type_name',
    value: 'desc',
    preventDecamilization: true,
  },
];

export const INITIAL_FILTERS = { per: 4, projectSearchFieldCont: '', sortFields: [SORT_OPTIONS[0]] };

class ProjectSubscriptionsList extends Component {
  state = {
    isSubscriptionConfigurationPopUpOpen: false,
  };

  isAllSubscriptionsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleSearchFieldChange = ({ target: { value } }) => {
    const { filters, onFiltersChange } = this.props;

    onFiltersChange({ ...filters, projectSearchFieldCont: value });
  };

  handleSortChange = (option) => {
    const { filters, onFiltersChange } = this.props;

    onFiltersChange({ ...filters, sortFields: [option] });
  };

  handleLoadMore = () => {
    const { isLoading, nextPage, loadSubscriptions, filters } = this.props;

    if (!isLoading && !this.isAllSubscriptionsLoaded()) {
      loadSubscriptions({ page: nextPage, ...filters });
    }
  };

  handleSubscriptionDelete = (subscription) => () => {
    const { filters, onSubscriptionDelete, onFiltersChange } = this.props;

    logClickOnAlertsRemoveFromAlerts();

    return onSubscriptionDelete(subscription.id).then(() => {
      onFiltersChange(filters);
    });
  };

  handleOpenSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: true });
  };

  handleCloseSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: false });
  };

  render() {
    const {
      subscriptions,
      filters,
      totalCount,
      isLoading,
      subscriptionConfigurationLoading,
      updateSubscriptionConfiguration,
      loadSubscriptionConfiguration,
    } = this.props;

    const { isSubscriptionConfigurationPopUpOpen } = this.state;

    const { projectSearchFieldCont, sortFields } = filters;
    const [sort] = sortFields;
    const isSubscriptionsEmpty = totalCount === 0 && projectSearchFieldCont === '' && !isLoading;

    if (isSubscriptionsEmpty) {
      return (
        <div className={styles.info}>
          <div className={styles.noticeHeader}>You have not added any Projects to your alerts yet</div>
          <div className={styles.notice}>
            To add a Project to your alerts, click on a
            <MenuIcon className={styles.menuIcon} color="primary" />
            icon next to one of your projects on the
            <AnchorLink to={Routes.projectsPath()} label="My Projects page" className={styles.linkProjects} />
            and click "Add to Alerts"
          </div>
        </div>
      );
    }

    return (
      <div>
        {isSubscriptionConfigurationPopUpOpen && (
          <SubscriptionConfigurationPopUp
            loading={subscriptionConfigurationLoading}
            onSave={updateSubscriptionConfiguration}
            onClose={this.handleCloseSubscriptionConfiguration}
            onLoadConfiguration={loadSubscriptionConfiguration}
            kind={PROJECT_SUBSCRIPTION_CONFIGURATION}
          />
        )}
        <div className={styles.filters}>
          <div className={styles.searchArea}>
            <input
              value={projectSearchFieldCont}
              autoComplete="off"
              placeholder="Start typing Name, Title or Company Name"
              className={styles.searchInput}
              onChange={this.handleSearchFieldChange}
            />
            <Icon name="search" style={{ width: 14, height: 14 }} />
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.configurationButton}
              label={<GearsIcon color="doveGray" />}
              onClick={this.handleOpenSubscriptionConfiguration}
            />
            <Select
              className={styles.filterBySelect}
              placeholder="Sort By"
              options={SORT_OPTIONS}
              value={sort}
              onChange={this.handleSortChange}
              noBottom
            />
          </div>
        </div>
        <div className={styles.projects}>
          <InfiniteScroll
            loadMore={this.handleLoadMore}
            hasMore={!this.isAllSubscriptionsLoaded()}
            loader={<Loader />}
            useWindow={false}
            researchForScrollParent
          >
            {subscriptions.map((subscription) => (
              <ProjectItem
                key={SubscriptionPresenter.id(subscription)}
                project={subscription.subject}
                onDestroySubscription={this.handleSubscriptionDelete(subscription)}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

ProjectSubscriptionsList.propTypes = {
  subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()).isRequired,
  filters: PropTypes.shape().isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
  loadSubscriptions: PropTypes.func.isRequired,
  onSubscriptionDelete: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  subscriptionConfigurationLoading: PropTypes.bool,
  updateSubscriptionConfiguration: PropTypes.func,
  loadSubscriptionConfiguration: PropTypes.func,
};

export default ProjectSubscriptionsList;
