import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propEq } from 'ramda';
import { isPresent } from 'utils/HelperMethods';
import cx from 'clsx';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

import styles from './ProfileRowCells.module.css';

class ProfileRowCells extends Component {
  static propTypes = {
    row: ProfilePresenter.shape(),
    columns: PropTypes.arrayOf(PropTypes.shape()),
  };

  isColumnPresent(column) {
    const { columns } = this.props;

    return isPresent(columns.find(propEq(column, 'name')));
  }

  render() {
    const { row: profile } = this.props;
    const primaryCurrentWork = ProfilePresenter.primaryCurrentWork(profile);
    const hasPublishedVersion = ProfilePresenter.hasPublishedVersion(profile);
    const isHidden = ProfilePresenter.isHidden(profile);
    const isOptedOut = ProfilePresenter.optedOut(profile);
    const statusClass = cx([styles.status], {
      [styles.draft]: !hasPublishedVersion,
      [styles.published]: hasPublishedVersion,
      [styles.hidden]: isHidden || isOptedOut,
    });

    return (
      <>
        {this.isColumnPresent('id') && <td>{ProfilePresenter.id(profile)}</td>}
        {this.isColumnPresent('status') && (
          <td className={statusClass}>
            {isHidden && <div className={styles.hiddenLabel}> hidden </div>}
            {isOptedOut && <div className={styles.hiddenLabel}> opted-out </div>}
            {hasPublishedVersion ? 'Has Published Version' : 'Only Draft'}
          </td>
        )}
        {this.isColumnPresent('name') && <td>{ProfilePresenter.lastNameWithFirstName(profile)}</td>}
        {this.isColumnPresent('organization') && (
          <td>{primaryCurrentWork && WorkPresenter.organizationNameWithState(primaryCurrentWork)}</td>
        )}
        {this.isColumnPresent('title') && (
          <td>{primaryCurrentWork && WorkPresenter.fullJobTitle(primaryCurrentWork)}</td>
        )}
        {this.isColumnPresent('Last Update Date') && <td>{ProfilePresenter.formattedProfileUpdatedAt(profile)}</td>}
      </>
    );
  }
}

export default ProfileRowCells;
