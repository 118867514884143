import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { head, has, pick, keys, filter, pipe } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Select from 'components/Select';
import MoveRequestPopups from 'containers/AdminPanel/components/MoveRequestPopups';

import RequestPolicy from 'policies/RequestPolicy';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import { STATE_EVENTS } from 'presenters/RequestPresenter';
import { ADMIN_ROLES } from 'presenters/UserPresenter';

const DEFAULT_PARAMS = { cancelationReason: null, cancelationConfirmation: null, assigneeId: null };

const getStateEventOptions = (stateEvents) =>
  STATE_EVENTS.filter(({ key }) => stateEvents.includes(key)).map((e) => ({ label: e.title, value: e.key }));

const BulkUpdateStatusRequestsModal = (props) => {
  const { requestIds, onUpdateStatusRequests, onClose, saving, stateEvents, currentUser, onUserSearch } = props;
  const [stateEvent, setSelectedEvent] = useState(null);
  const [isOpenAdditionalModal, setIsOpenAdditionalModal] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSelect = (newStateEvent) => {
    setSelectedEvent(newStateEvent);
  };

  const handleUpdateRequests = (params = {}) => onUpdateStatusRequests({ ...params, ids: requestIds, stateEvent });

  const handleSubmit = () => {
    if (stateEvent && RequestPolicy.needMovePopup(currentUser, null, stateEvent)) {
      setIsOpenAdditionalModal(true);
      return;
    }

    handleUpdateRequests(DEFAULT_PARAMS).catch((data) => {
      const error = head(data.errors);
      setErrors(error);
    });
  };

  const handleSubmitAdditionalModal = (params) => {
    const filledParams = pipe(pick(keys(DEFAULT_PARAMS)), filter(isPresent))(params);

    return handleUpdateRequests({ ...DEFAULT_PARAMS, ...filledParams }).catch((data) => {
      const error = head(data.errors);

      if (has('stateEvent', error) || has('subject', error)) {
        setIsOpenAdditionalModal(false);
        setErrors(head(error));
        return;
      }

      const newErr = new Error();
      newErr.errors = error;

      throw newErr;
    });
  };

  const handleUserSearch = (value) => onUserSearch({ fullNameCont: value, roleIn: ADMIN_ROLES, stateEq: 'active' });

  if (isOpenAdditionalModal) {
    return (
      <MoveRequestPopups
        onClose={onClose}
        onSubmit={handleSubmitAdditionalModal}
        saving={saving}
        searchUser={handleUserSearch}
        request={{ stateEvent }}
      />
    );
  }

  return (
    <Modal header="Change Status" size="small" onClose={onClose} overflow>
      <Box margin={{ bottom: 'small' }}>
        <Select
          placeholder="State Event"
          selectedOptionValue={stateEvent}
          options={getStateEventOptions(stateEvents)}
          onValueChange={handleSelect}
          errors={errors && (errors.stateEvent || errors.subject)}
        />
      </Box>
      <Box direction="row">
        <Button primary label="Change" disabled={saving} onClick={handleSubmit} />
      </Box>
    </Modal>
  );
};

BulkUpdateStatusRequestsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUserSearch: PropTypes.func.isRequired,
  onUpdateStatusRequests: PropTypes.func.isRequired,
  requestIds: PropTypes.arrayOf(PropTypes.number),
  saving: PropTypes.bool.isRequired,
  stateEvents: PropTypes.arrayOf(PropTypes.string),
  currentUser: CurrentUserPresenter.shape(),
};

export default BulkUpdateStatusRequestsModal;
