import React from 'react';
import PropTypes from 'prop-types';

import boardexLogo from 'images/BoardEx_Logo.png';

import styles from './BoardexPowered.module.css';

const BoardexPowered = (props) => {
  const { link, text } = props;

  return (
    <div className={styles.boardexBlock}>
      <a className={styles.boardexLink} href={link} target="_blank" rel="noreferrer">
        {text}
      </a>
      <div className={styles.boardexPowered}>
        Powered by
        <img className={styles.boardexImage} src={boardexLogo} alt="BoardEx an Altrata company" />
      </div>
    </div>
  );
};

BoardexPowered.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default BoardexPowered;
