import React from 'react';

import { Table, TableHeader, TableBody, TableRow, TableCell } from 'grommet';

import CompanyDashboardPresenter from 'presenters/Company/DashboardPresenter';
import styles from './TopViewed.module.css';

export default class TopViewed extends React.Component {
  static propTypes = {
    dashboard: CompanyDashboardPresenter.shape(),
  };

  renderPowerUserRow([fullName, viewsCount]) {
    return (
      <TableRow key={fullName}>
        <TableCell> {fullName} </TableCell>
        <TableCell> {viewsCount} </TableCell>
      </TableRow>
    );
  }

  render() {
    const { dashboard } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>Top Viewed</div>
        <section>
          <div className={styles.subheader}>Power Users</div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col">Full Name</TableCell>
                <TableCell scope="col">Views & Downloads</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>{CompanyDashboardPresenter.powerUsers(dashboard).map(this.renderPowerUserRow)}</TableBody>
          </Table>
        </section>
        <section>
          <div className={styles.subheader}>Companies</div>
          <div className={styles.organizations}>
            {CompanyDashboardPresenter.popularOrganizations(dashboard).map(([title, url]) => (
              <img key={`${url}-${title}`} alt={title} src={url} className={styles.item} />
            ))}
          </div>
        </section>
      </div>
    );
  }
}
