import React, { Component } from 'react';
import cn from 'clsx';

import AnchorLink from 'components/grommet/AnchorLink';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import styles from './Project.module.css';

import Routes from 'routes';

class Project extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    project: ProjectPresenter.shape().isRequired,
  };

  render() {
    const { currentUser, project } = this.props;
    const ownerFullName = ProjectPresenter.visibleOwnerFullName(project);
    const ownerNameStyle = cn({ [styles.notOwner]: !ProjectPresenter.isCurrentUserOwns(project) });

    return (
      <AnchorLink className={styles.root} to={Routes.projectPath(project.id)}>
        {ProjectPresenter.isSampleProject(project) && <div className={styles.sample}>sample</div>}
        <div className={styles.title}>{ProjectPresenter.name(project)}</div>
        <div className={styles.info}>
          <span>{ProjectPresenter.totalProfilesCount(project)} profiles</span>
          &emsp;|&emsp;
          {CurrentUserPresenter.projectsSharingAvailable(currentUser) && (
            <>
              <span>
                Project owner: <span className={ownerNameStyle}>{ownerFullName}</span>
              </span>
              &emsp;|&emsp;
            </>
          )}
          <span className={styles.lastModified}>
            Last Modified: {ProjectPresenter.formattedLastModifiedAt(project)}
          </span>
        </div>
      </AnchorLink>
    );
  }
}

export default Project;
