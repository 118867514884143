import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import HighlightsRepository from 'repositories/admin/HighlightsRepository';
import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';

import { actions } from 'slices/admin/profileSlice';

function addHighlight({ profileId, params }) {
  return (dispatch) => {
    dispatch(actions.addHighlightStart());

    return HighlightsRepository.create(profileId, params)
      .then(({ highlight, meta }) => {
        dispatch(actions.addHighlightSuccess({ highlight }));
        addNotification('The highlight was successfully added', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return highlight;
      })
      .catch((error) => {
        dispatch(actions.addHighlightError());

        throw error;
      });
  };
}

function destroyHighlight(profileId, highlightId) {
  return (dispatch) => {
    dispatch(actions.destroyHighlightStart());

    return HighlightsRepository.destroy(profileId, highlightId)
      .then(({ meta }) => {
        dispatch(actions.destroyHighlightSuccess({ highlightId }));

        addNotification('The highlight was successfully deleted', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));
      })
      .catch((error) => {
        dispatch(actions.destroyHighlightError());

        throw error;
      });
  };
}

export const useAdminProfileDraftHighLiteActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      addHighlight,
      destroyHighlight,
    },
    dispatch,
  );
};
