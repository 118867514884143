import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(projectId) {
    const url = Routes.apiV1CurrentUserProjectTopTagsPath(projectId);

    return JsonFetchHelpers.get(url);
  },
};
