import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CompanyUserRegistration from './CompanyUserRegistration';
import * as actions from './CompanyUserRegistrationActions';

const mapStateToProps = (state, props) => ({
  loading: state.CompanyUserRegistrationReducer.loading,
  company: state.CompanyUserRegistrationReducer.company,
  saving: state.CompanyUserRegistrationReducer.saving,
  companyId: props.match.params.companyId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUserRegistration);
