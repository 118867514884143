import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { isBlank, isPresent } from 'utils/HelperMethods';

export default new Presenter(
  {
    email: PropTypes.string,
    id: PropTypes.number,
    isAvailable: PropTypes.boolean,
    userId: PropTypes.number,
  },
  {
    isPersisted(share) {
      const id = this.id(share);

      return isPresent(id);
    },

    isNewRecord(share) {
      return !this.isPersisted(share);
    },

    withUnregisteredUser(share) {
      const userId = this.userId(share);

      return isBlank(userId);
    },

    isUnavailable(share) {
      return !this.isAvailable(share);
    },
  },
);
