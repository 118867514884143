import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { Tab as ReactTab } from 'react-tabs';

import { VARIANTS } from 'components/v2/Tabs/Tabs';
import { useTabsContext } from 'components/v2/Tabs/Context';

import styles from './Tab.module.css';

const VARIANT_TAB_CLASS_NAME = {
  [VARIANTS.DEFAULT]: styles.default,
  [VARIANTS.BUTTONS_GROUP]: styles.buttonsGroup,
};

const Tab = (props) => {
  const { selectedClassName, className, ...rest } = props;

  const { variant } = useTabsContext();

  const variantClassName = VARIANT_TAB_CLASS_NAME[variant];
  const tabClassName = clsx(styles.tab, variantClassName, className);
  const activeTabClassName = clsx(styles.activeTab, selectedClassName);

  return (
    <ReactTab
      {...rest}
      className={tabClassName}
      disabledClassName={styles.disabled}
      selectedClassName={activeTabClassName}
    />
  );
};

Tab.tabsRole = 'Tab';

Tab.propTypes = {
  selectedClassName: PropTypes.string,
  className: PropTypes.string,
};

Tab.defaultProps = {
  selectedClassName: null,
  className: null,
};

export default Tab;
