import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';
import InfiniteScroll from 'react-infinite-scroller';

import { Box, Spinner as Spinning } from 'grommet';
import Button from 'components/Button';
import Card from 'components/Card';
import Headline from 'components/grommet/Headline';
import EventRow from './components/EventRow';
import { Refresh as RefreshIcon } from 'grommet-icons';

import styles from './TREventsBlock.module.css';

import TREventPresenter from 'presenters/TREventPresenter';

class TREventsBlock extends Component {
  static propTypes = {
    errorStatus: PropTypes.number,
    events: PropTypes.arrayOf(TREventPresenter.shape()),
    initializeThomsonReutersEvents: PropTypes.func.isRequired,
    loadThomsonReutersEvents: PropTypes.func.isRequired,
    organizationId: PropTypes.number.isRequired,
    nextPage: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    ricCode: PropTypes.string,
  };

  componentDidMount() {
    const { initializeThomsonReutersEvents } = this.props;

    initializeThomsonReutersEvents();
  }

  componentDidUpdate(prevProps) {
    const { initializeThomsonReutersEvents, organizationId, ricCode } = this.props;

    if (organizationId !== prevProps.organizationId || ricCode !== prevProps.ricCode) {
      initializeThomsonReutersEvents();
    }
  }

  isAllEventsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { organizationId, loading, loadThomsonReutersEvents, nextPage } = this.props;

    if (!loading && !this.isAllEventsLoaded()) {
      loadThomsonReutersEvents(organizationId, { page: nextPage });
    }
  };

  renderLoader() {
    return (
      <div key="loader" className={styles.loaderBox}>
        <Spinning />
      </div>
    );
  }

  renderError() {
    const { errorStatus } = this.props;

    return (
      <div className={styles.error}>
        Error {errorStatus || 500}{' '}
        <Button className={styles.errorButton} onClick={this.handleLoadMore}>
          <RefreshIcon />
          Retry
        </Button>
      </div>
    );
  }

  render() {
    const { errorStatus, events } = this.props;

    return (
      <Box margin={{ bottom: 'medium' }}>
        <Card>
          <Headline strong size="small" margin="none" className={styles.header}>
            Transcripts
          </Headline>
          <div className={styles.infiniteScroll}>
            <InfiniteScroll
              loadMore={this.handleLoadMore}
              hasMore={isBlank(errorStatus) && !this.isAllEventsLoaded()}
              loader={this.renderLoader()}
              useWindow={false}
            >
              {events.map((event) => (
                <EventRow key={TREventPresenter.id(event)} event={event} />
              ))}
              {isPresent(errorStatus) && this.renderError()}
            </InfiniteScroll>
          </div>
        </Card>
      </Box>
    );
  }
}

export default TREventsBlock;
