import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import { Button, Spinner as Spinning } from 'grommet';

import styles from './ExportButton.module.css';

const ExportButton = (props) => {
  const { label, isLoading, onClick, className, icon, ...rest } = props;
  const classNames = cn({ [className]: isPresent(className), [styles.root]: !isPresent(className) });

  return (
    <Button
      label={label}
      icon={isLoading ? <Spinning /> : icon}
      className={classNames}
      onClick={!isLoading ? onClick : null}
      {...rest}
    />
  );
};

ExportButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

ExportButton.defaultProps = {
  icon: null,
};

export default ExportButton;
