import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import cx from 'clsx';
import { Box } from 'grommet';
import { CircleInformation as CircleInformationIcon, Edit as EditIcon } from 'grommet-icons';

import Card from 'components/Card';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';
import RawHtml from 'components/RawHtml';
import StyleGuideModal from 'components/StyleGuideModal';

import Modal from 'components/Modal';
import WysiwygForm from './WysiwygForm';
import TextSelection from 'components/TextSelection';

import styles from './EditableCard.module.css';

const EditableCard = (props) => {
  const {
    editable,
    title,
    body,
    htmlBody,
    saving,
    tooltipComponent,
    isChanged,
    isWarning,
    onReviewChanges,
    enableHighlight,
    popoverNode,
    handleAddHighlight,
    handleLoadTags,
    warningText,
    onUpdate,
    footerText,
  } = props;

  const [isEdit, setIsEdit] = useState(false);

  const handleCancelEdit = () => setIsEdit(false);

  const handleSubmit = (value) => {
    onUpdate(value).then(() => {
      setIsEdit(false);
    });
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const decodeHtmlBody = (newHtmlBody) => {
    const htmlValue = isNil(newHtmlBody) ? '' : newHtmlBody;
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<!doctype html><body>${htmlValue}`, 'text/html');

    return dom.body.innerHTML;
  };

  const renderBody = () => (htmlBody ? <RawHtml className={styles.text} innerHTML={htmlBody} /> : null);

  const rootClassName = cx(styles.root, { [styles.warningFrame]: isWarning });

  return (
    <Card className={rootClassName}>
      <Box className={styles.headerBox}>
        <HeadlineWithReviewChangesLink
          strong
          size="small"
          margin="none"
          className={styles.headline}
          title={title}
          isChanged={isChanged}
          onReviewChanges={onReviewChanges}
        />
      </Box>
      {body}

      {enableHighlight ? (
        <TextSelection
          node={popoverNode}
          onAddHighlight={handleAddHighlight}
          handleLoadTags={handleLoadTags}
          saving={saving}
        >
          {renderBody()}
        </TextSelection>
      ) : (
        renderBody()
      )}

      <div className={styles.panel}>
        {editable && (
          <div className={styles.action}>
            <EditIcon color="light-1" onClick={handleEdit} />
          </div>
        )}
        <div className={styles.action}>
          <StyleGuideModal modalContent={tooltipComponent}>
            <CircleInformationIcon color="light-1" />
          </StyleGuideModal>
        </div>
      </div>
      <div className={styles.footerText}>{footerText}</div>

      {isEdit ? (
        <Modal header={title} onClose={handleCancelEdit} size="large" disableOverlayClose disableCloseByEsc>
          <WysiwygForm saving={saving} value={decodeHtmlBody(htmlBody)} onSubmit={handleSubmit} />
        </Modal>
      ) : null}

      {isWarning && <div className={styles.warningBlock}>{warningText}</div>}
    </Card>
  );
};

EditableCard.propTypes = {
  editable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  body: PropTypes.node,
  htmlBody: PropTypes.node,
  footerText: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  tooltipComponent: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onReviewChanges: PropTypes.func.isRequired,
  enableHighlight: PropTypes.bool,
  popoverNode: PropTypes.shape(),
  handleAddHighlight: PropTypes.func,
  handleLoadTags: PropTypes.func,
  isWarning: PropTypes.bool,
  warningText: PropTypes.string,
};

EditableCard.defaultProps = {
  isWarning: false,
  warningText: '',
};

export default EditableCard;
