import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnBusinessPriorityAnalysisTabDetails } from 'utils/amplitude';

import BarChart from 'containers/UserPanel/containers/Project/containers/BusinessPriorityAnalysis/components/BarChart';
import Spinner from 'components/Spinner';
import Sidebar from 'containers/UserPanel/containers/Project/containers/ProjectProfilesListSidebar';

import ProjectPresenter, { PROJECT_TAG_PROFILES_CSV } from 'presenters/ProjectPresenter';
import JobFunctionPresenter from 'presenters/Project/BPA/JobFunctionPresenter';
import TagPresenter from 'presenters/Project/BPA/TagPresenter';

import styles from './TopJobFunctions.module.css';

export default class TopJobFunctions extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadTopJobFunctions: PropTypes.func.isRequired,
    project: ProjectPresenter.shape().isRequired,
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()).isRequired,
    onExport: PropTypes.func.isRequired,
  };

  state = { isSidebarOpened: false, sidebarProps: {} };

  componentDidMount() {
    const { loadTopJobFunctions, project } = this.props;

    return loadTopJobFunctions(ProjectPresenter.id(project));
  }

  handleProfilesExport = (params) => () => {
    const { onExport } = this.props;

    return onExport({ type: PROJECT_TAG_PROFILES_CSV, params });
  };

  handleOpenSidebar = (sidebarProps) => this.setState({ ...this.state, isSidebarOpened: true, sidebarProps });

  handleCloseSidebar = () => this.setState({ ...this.state, isSidebarOpened: false, sidebarProps: {} });

  handleClickTitle = (title) => {
    const sidebarProps = {
      title,
      filters: { jobFunction: title },
    };

    logClickOnBusinessPriorityAnalysisTabDetails('By Job Functions', title);

    this.handleOpenSidebar(sidebarProps);
  };

  handleClickSubtitle = (title, rootTag) => {
    const rootTagId = TagPresenter.id(rootTag);
    const sidebarProps = {
      title: `${title}: ${TagPresenter.title(rootTag)}`,
      filters: { jobFunction: title, rootTagId },
      profilePathQuery: { tags: [rootTagId] },
      onExport: this.handleProfilesExport({ search: { jobFunction: title, rootTagId }, tagId: rootTagId }),
    };

    logClickOnBusinessPriorityAnalysisTabDetails('By Job Functions', title, TagPresenter.title(rootTag));

    this.handleOpenSidebar(sidebarProps);
  };

  render() {
    const { loading, jobFunctions, project } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const { isSidebarOpened, sidebarProps } = this.state;

    return (
      <>
        {isSidebarOpened && <Sidebar project={project} onClose={this.handleCloseSidebar} {...sidebarProps} />}
        <div className={styles.jobFunctions}>
          {jobFunctions.map((jobFunction) => (
            <BarChart
              key={JobFunctionPresenter.title(jobFunction)}
              title={JobFunctionPresenter.title(jobFunction)}
              profilesCount={JobFunctionPresenter.profilesCount(jobFunction)}
              tags={JobFunctionPresenter.tags(jobFunction)}
              onTitleClick={this.handleClickTitle}
              onSubtitleClick={this.handleClickSubtitle}
            />
          ))}
        </div>
      </>
    );
  }
}
