import React from 'react';
import PropTypes from 'prop-types';

import { Field, Input } from 'components/v2/Form';

import styles from './FiltersForm.module.css';

const FiltersForm = (props) => {
  const { onFilterChange, filters } = props;

  const handleFilterChange = (field) => (value) => onFilterChange(field, value);

  return (
    <>
      <div className={styles.header}>Search By</div>
      <div className={styles.filterFields}>
        <div className={styles.filterField}>
          <Field title="First Name">
            <Input onChange={handleFilterChange('firstName')} value={filters?.firstName || ''} />
          </Field>
        </div>
        <div className={styles.filterField}>
          <Field title="Last Name">
            <Input onChange={handleFilterChange('lastName')} value={filters?.lastName || ''} />
          </Field>
        </div>
        <div className={styles.filterField}>
          <Field title="Email">
            <Input onChange={handleFilterChange('email')} value={filters?.email || ''} />
          </Field>
        </div>
      </div>
    </>
  );
};

FiltersForm.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default FiltersForm;
