import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(profileId, anotherProfileId, params = {}) {
    const url = Routes.apiV1CustomerProfileRelationshipsMappingProfileRelationshipsPath(
      profileId,
      anotherProfileId,
      params,
    );

    return FetchHelpers.get(url);
  },
};
