import keyMirror from 'keymirror';

const UserListPageConstants = keyMirror({
  INITIALIZE_USER_LIST_SUCCESS: null,

  LOAD_USERS_START: null,
  LOAD_USERS_SUCCESS: null,

  CREATE_USER_START: null,
  CREATE_USER_SUCCESS: null,
  CREATE_USER_ERROR: null,

  FILTER_USERS_START: null,
  FILTER_USERS_SUCCESS: null,

  BULK_DESTROY_USERS_START: null,
  BULK_DESTROY_USERS_SUCCESS: null,
});

export default UserListPageConstants;
