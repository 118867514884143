import React, { useState } from 'react';

import { useAlert, useCurrentUser, useCurrentUserRemainingRequestsNumber } from 'hooks';
import { useClientRequests, useClientRequestSuggestions } from 'hooks/client';
import { parseLocationToParams } from 'utils/UrlHelper';
import { useHistory } from 'reactRouterPolyfill';
import { isPresent } from 'utils/HelperMethods';

import RequestForm from 'forms/customer/RequestForm';
import RequestsBatchCreateForm from 'forms/customer/RequestsBatchCreateForm';

import RequestsHelpSuggestionModal from 'components/client/RequestsHelpSuggestionModal';
import Form, { NestedCollection } from 'components/v2/Form';

import Instruction from './components/Instruction';
import RequestsFields from './components/RequestsFields';

const NewRequestsForm = () => {
  const alert = useAlert();
  const history = useHistory();
  const [persistedRequestsCount, setPersistedRequestsCount] = useState(0);
  const { bulkCreateRequests } = useClientRequests();
  const { loadRequestSuggestions } = useClientRequestSuggestions();
  const { currentUser } = useCurrentUser();
  const [remainingRequestsNumber, refreshRemainingRequestNumber] = useCurrentUserRemainingRequestsNumber(
    currentUser.remainingRequestsNumber,
  );

  const defaultRequestValues = RequestForm.defaultAttributes({
    recipientEmails: [currentUser.email],
  });

  let firstRequest = defaultRequestValues;

  const { source, profile } = parseLocationToParams(history.location);
  if (source && source.toLowerCase() === 'boardex' && profile && isPresent(currentUser)) {
    const { first_name: firstName, last_name: lastName, company: organizationName, job_title: jobTitle } = profile;
    const attrs = { firstName, lastName, organizationName, jobTitle };

    firstRequest = { ...defaultRequestValues, subjectAttributes: attrs };
  }

  const initialValues = RequestsBatchCreateForm.defaultAttributes({
    requests: [firstRequest],
  });

  const handleRequestsBulkCreate = async (attributes, form) => {
    const attributesToSubmit = RequestsBatchCreateForm.attributesToSubmit(attributes);
    const { requests, shouldSuggestHelp } = await bulkCreateRequests(attributesToSubmit);

    form.resetForm();
    setPersistedRequestsCount(requests.length);
    refreshRemainingRequestNumber();

    if (shouldSuggestHelp) {
      alert.showModal(RequestsHelpSuggestionModal);
    }
  };

  const handleRequestSuggestionsLoad = async ({ subjectAttributes }) => {
    const {
      firstName,
      lastName,
      organizationName: currentOrganizationName,
      jobTitle: currentTitle,
    } = subjectAttributes;

    return loadRequestSuggestions({ q: { firstName, lastName, currentOrganizationName, currentTitle }, per: 4 });
  };

  return (
    <div>
      <Instruction />
      <Form initialValues={initialValues} onSubmit={handleRequestsBulkCreate}>
        <NestedCollection name="requests">
          {(nestedCollectionProps) => (
            <RequestsFields
              defaultRequestValues={defaultRequestValues}
              onRequestSuggestionsLoad={handleRequestSuggestionsLoad}
              persistedRequestsCount={persistedRequestsCount}
              remainingRequestsNumber={remainingRequestsNumber}
              {...nestedCollectionProps}
            />
          )}
        </NestedCollection>
      </Form>
    </div>
  );
};

export default NewRequestsForm;
