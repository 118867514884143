import PropTypes from 'prop-types';
import React, { useState } from 'react';

import RequestPresenter from 'presenters/RequestPresenter';

import PencilIcon from 'icons/PencilIcon';

import Button from 'components/v2/Button';
import Form from 'components/v2/Form';
import Typography from 'components/v2/Typography';
import { Field, Checkbox } from 'components/v2/Form';

import EditFormRow from './components/EditFormRow';

import styles from './TableRow.module.css';

const TableRow = (props) => {
  const { canRequestSelectionChange, errors, isRequestSelected, onRequestChange, onRequestSelectionChange, request } =
    props;

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const handleEditFormOpen = () => setIsEditFormOpen(true);
  const handleEditFormClose = () => setIsEditFormOpen(false);
  const handleRequestChange = (attributes) => {
    onRequestChange(attributes);
    handleEditFormClose();
  };

  const { firstName, lastName, jobTitle, organizationName } = RequestPresenter.subjectAttributes(request);

  return (
    <>
      <tr className={styles.row}>
        <td>
          <Checkbox
            checked={isRequestSelected}
            onChange={onRequestSelectionChange}
            disabled={!canRequestSelectionChange}
          />
        </td>
        <td>
          <Field error={errors?.subjectAttributes?.firstName}>
            <Typography className={styles.text} variant="body2">
              {firstName}
            </Typography>
          </Field>
        </td>
        <td>
          <Field error={errors?.subjectAttributes?.lastName}>
            <Typography className={styles.text} variant="body2">
              {lastName}
            </Typography>
          </Field>
        </td>
        <td>
          <Field error={errors?.subjectAttributes?.jobTitle}>
            <Typography className={styles.text} variant="body2">
              {jobTitle}
            </Typography>
          </Field>
        </td>
        <td>
          <Field error={errors?.subjectAttributes?.organizationName}>
            <Typography className={styles.text} variant="body2">
              {organizationName}
            </Typography>
          </Field>
        </td>
        <td className={styles.actions}>
          <Button onClick={isEditFormOpen ? handleEditFormClose : handleEditFormOpen} variant="outlinedIcon">
            <PencilIcon width={10} height={10} />
          </Button>
        </td>
      </tr>
      {isEditFormOpen && <tr style={{ display: 'none' }} /> /* fake row to fix stripes */}
      {isEditFormOpen && (
        <Form
          component={EditFormRow}
          initialValues={request}
          onReset={handleEditFormClose}
          onSubmit={handleRequestChange}
        />
      )}
    </>
  );
};

TableRow.propTypes = {
  canRequestSelectionChange: PropTypes.bool.isRequired,
  errors: PropTypes.shape(),
  isRequestSelected: PropTypes.bool.isRequired,
  onRequestChange: PropTypes.func.isRequired,
  onRequestSelectionChange: PropTypes.func.isRequired,
  request: RequestPresenter.shape().isRequired,
};

TableRow.defaultProps = {
  errors: null,
};

export default TableRow;
