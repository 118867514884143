import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu as MenuIcon } from 'grommet-icons';

import { logClickOnProjectPageHamburgerAction } from 'utils/amplitude';

import Button from 'components/Button';
import ButtonWithConfirmation from 'containers/UserPanel/components/ButtonWithConfirmation';
import Deliverables from './containers/Deliverables';
import Lists from './components/Lists';
import styles from './ProjectPage.module.css';
import AvailableProfilesList from './containers/AvailableProfilesList';
import EditableHeader from './components/EditableHeader';
import Menu, { MenuItem } from 'containers/UserPanel/components/Menu';
import ProjectCloneFormModal from 'containers/UserPanel/components/ProjectCloneFormModal';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import EditProjectForm from 'forms/EditProjectForm';

import Routes from 'routes';

export default class ProjectPage extends Component {
  static propTypes = {
    cloneProject: PropTypes.func.isRequired,
    closeGuide: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape().isRequired,
    destroyProject: PropTypes.func.isRequired,
    destroyProjectSource: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    onDestroyShare: PropTypes.func.isRequired,
    onProjectDataExport: PropTypes.func.isRequired,
    onSubscribe: PropTypes.func.isRequired,
    onUnsubscribe: PropTypes.func.isRequired,
    project: ProjectPresenter.shape(),
    saving: PropTypes.bool.isRequired,
    updateProject: PropTypes.func.isRequired,
  };

  state = { isCloneModalOpened: false, isEditingTitle: false, isMenuOpened: false };

  handleOpenMenu = () => this.setState({ isMenuOpened: true });

  handleCloseMenu = () => this.setState({ isMenuOpened: false });

  handleOpenCloneModal = () => this.setState({ isCloneModalOpened: true });

  handleCloseCloneModal = () => this.setState({ isCloneModalOpened: false });

  handleStartEditTitle = () => this.setState({ isEditingTitle: true });

  handleStopEditTitle = () => this.setState({ isEditingTitle: false });

  handleProjectTitleUpdate = (name) => {
    const { project, updateProject } = this.props;
    const projectId = ProjectPresenter.id(project);
    const params = EditProjectForm.attributesToSubmit({ ...project, name });

    return updateProject(projectId, params);
  };

  handleProjectDestroy = () => {
    const { history, project, destroyProject } = this.props;
    const projectId = ProjectPresenter.id(project);

    return destroyProject(projectId).then(() => {
      const path = Routes.projectsPath();

      history.push(path);
    });
  };

  handleProjectClone = (params) => {
    const { project, cloneProject, history } = this.props;
    const projectId = ProjectPresenter.id(project);
    const attributes = EditProjectForm.attributesToSubmit(params);

    return cloneProject(projectId, attributes).then((clonedProject) => {
      const clonedProjectId = ProjectPresenter.id(clonedProject);
      const path = Routes.projectPath(clonedProjectId);

      history.push(path);

      return clonedProject;
    });
  };

  render() {
    const {
      closeGuide,
      currentUser,
      destroyProjectSource,
      history,
      onProjectDataExport,
      onSubscribe,
      onUnsubscribe,
      onDestroyShare,
      project,
      saving,
    } = this.props;
    const { isCloneModalOpened, isEditingTitle, isMenuOpened } = this.state;
    const sources = ProjectPresenter.sources(project);

    const breadcrumbsItems = [
      { name: 'Projects', to: Routes.projectsPath() },
      { name: ProjectPresenter.name(project) },
    ];

    return (
      <>
        {isCloneModalOpened && (
          <ProjectCloneFormModal
            onClose={this.handleCloseCloneModal}
            onSubmit={this.handleProjectClone}
            project={project}
            isSaving={saving}
          />
        )}
        <div className={styles.root}>
          <EditableHeader
            breadcrumbs={breadcrumbsItems}
            currentUser={currentUser}
            isEditing={isEditingTitle}
            isSaving={saving}
            title={ProjectPresenter.name(project)}
            onCancel={this.handleStopEditTitle}
            onUpdate={this.handleProjectTitleUpdate}
            project={project}
          >
            <div className={styles.actions}>
              <Button className={styles.icon} onClick={this.handleOpenMenu}>
                <MenuIcon />
              </Button>
              <Menu className={styles.menu} isOpen={isMenuOpened} onClose={this.handleCloseMenu}>
                {ProjectPolicy.canEdit(project) && (
                  <MenuItem
                    onClick={() => {
                      logClickOnProjectPageHamburgerAction('EDIT_NAME');
                      this.handleStartEditTitle();
                    }}
                    disabled={saving || isEditingTitle}
                  >
                    Edit Name
                  </MenuItem>
                )}
                {ProjectPolicy.canSubscribe(project) && (
                  <MenuItem
                    component={ButtonWithConfirmation}
                    confirmationMessage="Are you sure you want to get email alerts for this project?"
                    disabled={saving}
                    onClick={() => {
                      logClickOnProjectPageHamburgerAction('ADD_TO_ALERTS');
                      onSubscribe();
                    }}
                  >
                    Add to Alerts
                  </MenuItem>
                )}
                {ProjectPolicy.canUnsubscribe(project) && (
                  <MenuItem
                    component={ButtonWithConfirmation}
                    confirmationMessage="Are you sure you want to remove email alerts for this Project?"
                    disabled={saving}
                    onClick={() => {
                      logClickOnProjectPageHamburgerAction('REMOVE_FROM_ALERTS');
                      onUnsubscribe();
                    }}
                  >
                    Remove from Alerts
                  </MenuItem>
                )}
                {ProjectPolicy.canDestroyShare(project) && (
                  <MenuItem
                    component={ButtonWithConfirmation}
                    confirmationMessage="Are you sure you want to remove this Project?"
                    disabled={saving}
                    onClick={onDestroyShare}
                  >
                    Remove
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    logClickOnProjectPageHamburgerAction('CLONE');
                    this.handleOpenCloneModal();
                  }}
                  disabled={saving}
                >
                  Clone
                </MenuItem>
                {ProjectPolicy.canDestroy(project) && (
                  <MenuItem
                    component={ButtonWithConfirmation}
                    confirmationMessage="Are you sure want to delete?"
                    onClick={() => {
                      logClickOnProjectPageHamburgerAction('DELETE');
                      this.handleProjectDestroy();
                    }}
                    disabled={saving}
                  >
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
          </EditableHeader>
          <div className={styles.general}>
            <div className={styles.main}>
              <AvailableProfilesList
                key={sources.length}
                onCloseGuide={closeGuide}
                currentUser={currentUser}
                onExport={onProjectDataExport}
                project={project}
                history={history}
              />
            </div>
            <div className={styles.side}>
              <Deliverables currentUser={currentUser} project={project} onExport={onProjectDataExport} />
              <Lists onDestroy={destroyProjectSource} project={project} saving={saving} sources={sources} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
