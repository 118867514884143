import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import styles from './TableBody.module.css';

const TableBody = (props) => {
  const { className, ...rest } = props;

  const tbodyClassName = clsx(styles.tableBody, className);

  return <tbody {...rest} className={tbodyClassName} />;
};

TableBody.propTypes = {
  className: PropTypes.string,
};

TableBody.defaultProps = {
  className: null,
};

export default TableBody;
