import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { sanitizeHtml } from 'utils/HTMLHelpers';

import bold from './images/b.svg';
import italic from './images/i.svg';
import underline from './images/u.svg';

import left from './images/left.svg';
import center from './images/center.svg';
import right from './images/right.svg';
import justify from './images/justify.svg';
import ordered from './images/number.svg';
import unordered from './images/marker.svg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './Wysiwyg.module.css';

const DEFAULT_TOOLBAR_PARAMS = {
  options: ['blockType', 'inline', 'textAlign', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: bold },
    italic: { icon: italic },
    underline: { icon: underline },
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
  },
  list: {
    options: ['ordered', 'unordered', 'indent', 'outdent'],
    unordered: { icon: unordered },
    ordered: { icon: ordered },
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: left },
    center: { icon: center },
    right: { icon: right },
    justify: { icon: justify },
  },
  link: {
    defaultTargetOption: '_blank',
  },
};

const buildEditorState = (editableContent) => {
  const contentBlock = htmlToDraft(editableContent);
  const editorState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

  return EditorState.createWithContent(editorState);
};

const Wysiwyg = (props) => {
  const { editableContent, onChange } = props;
  const [editorState, setEditorState] = useState(null);
  useEffect(() => {
    setEditorState(buildEditorState(editableContent));
  }, []);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);

    if (onChange) {
      const contentState = newEditorState.getCurrentContent();
      const html = contentState.hasText() ? sanitizeHtml(draftToHtml(convertToRaw(contentState))) : null;

      onChange(html);
    }
  };

  return (
    <Editor
      toolbar={DEFAULT_TOOLBAR_PARAMS}
      editorState={editorState}
      editorClassName={styles.editor}
      onEditorStateChange={onEditorStateChange}
      stripPastedStyles
      spellCheck
    />
  );
};

Wysiwyg.propTypes = {
  editableContent: PropTypes.string,
  onChange: PropTypes.func,
};

export default Wysiwyg;
