import { equals } from 'ramda';
import WordsCloudConstants from './WordsCloudConstants';

const initialState = {
  loading: false,
  words: [],
  filters: { search: { word: { type: null } }, includeExecutives: false },
};

function WordsCloudReducer(state = initialState, action = {}) {
  switch (action.type) {
    case WordsCloudConstants.LOAD_PROJECT_WORDS_START: {
      const { filters } = action;

      return { ...state, loading: true, filters };
    }
    case WordsCloudConstants.LOAD_PROJECT_WORDS_SUCCESS: {
      const { words, filters } = action;

      if (!equals(state.filters, filters)) {
        return state;
      }

      return { ...state, words, loading: false };
    }
    default:
      return state;
  }
}

export default WordsCloudReducer;
