import { propEq } from 'ramda';
import UserPresenter from 'presenters/UserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';
import UserPolicy from 'policies/UserPolicy';

import { STATES } from 'stateMachines/RequestStateMachine';

const EDITOR_REQUEST_FIELDS = [
  'complexPriority',
  'requestType',
  'requestorFullName',
  'state',
  'companyName',
  'subjectFullName',
  'subjectJobTitle',
  'subjectOrganizationName',
  'assigneeFullName',
  'deliveredAt',
  'createdAt',
  'dueDate',
];
const RESEARCH_EDITOR_REQUEST_FIELDS = [
  'complexPriority',
  'state',
  'subjectFullName',
  'subjectJobTitle',
  'subjectOrganizationName',
  'assigneeFullName',
  'createdAt',
];
const RESEARCHER_REQUEST_FIELDS = [
  'complexPriority',
  'state',
  'subjectFullName',
  'subjectJobTitle',
  'subjectOrganizationName',
  'assigneeFullName',
  'createdAt',
];

const RESEARCH_MANAGER_REQUEST_FIELDS = [
  'complexPriority',
  'state',
  'subjectFullName',
  'subjectJobTitle',
  'subjectOrganizationName',
  'assigneeFullName',
  'createdAt',
];

export default {
  canSeeList(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },

  isColumnPermitted(user, field) {
    if (UserPresenter.isAdmin(user)) {
      return true;
    }

    if (UserPresenter.isEditor(user)) {
      return EDITOR_REQUEST_FIELDS.includes(field);
    }

    if (UserPresenter.isResearchEditor(user)) {
      return RESEARCH_EDITOR_REQUEST_FIELDS.includes(field);
    }

    if (UserPresenter.isResearcher(user)) {
      return RESEARCHER_REQUEST_FIELDS.includes(field);
    }

    if (UserPresenter.isResearchManager(user)) {
      return RESEARCH_MANAGER_REQUEST_FIELDS.includes(field);
    }

    return false;
  },

  permittedTableColumns(user, tableColumns) {
    if (UserPresenter.isAdmin(user)) {
      return tableColumns;
    }

    if (UserPresenter.isEditor(user)) {
      return tableColumns.filter(({ field }) => EDITOR_REQUEST_FIELDS.includes(field));
    }

    if (UserPresenter.isResearchEditor(user)) {
      return tableColumns.filter(({ field }) => RESEARCH_EDITOR_REQUEST_FIELDS.includes(field));
    }

    if (UserPresenter.isResearcher(user)) {
      return tableColumns.filter(({ field }) => RESEARCHER_REQUEST_FIELDS.includes(field));
    }

    if (UserPresenter.isResearchManager(user)) {
      return tableColumns.filter(({ field }) => RESEARCH_MANAGER_REQUEST_FIELDS.includes(field));
    }

    return [];
  },

  isResultState(user, request) {
    if (UserPresenter.isAdmin(user) || UserPresenter.isEditor(user)) {
      return RequestPresenter.isDelivered(request) || RequestPresenter.isCanceled(request);
    }

    if (UserPresenter.isResearchEditor(user)) {
      return (
        RequestPresenter.isCompleted(request) ||
        RequestPresenter.isCanceled(request) ||
        RequestPresenter.isDelivered(request)
      );
    }

    if (UserPresenter.isResearcher(user)) {
      return RequestPresenter.isInEditorial(request) || RequestPresenter.isCanceled(request);
    }

    return false;
  },

  needCancelPopup() {
    return true;
  },

  needCancelationConfirmationField(request) {
    return (
      RequestPresenter.cancelationReason(request) === 'no_longer_in_this_role_or_organization' ||
      RequestPresenter.cancelationReason(request) === 'other'
    );
  },

  needMovePopup(user, request, stateEvent) {
    if (stateEvent === 'cancel') {
      return this.needCancelPopup(user, request);
    }

    return (
      (UserPresenter.isAdmin(user) || UserPresenter.isEditor(user)) &&
      ['to_research', 'to_editorial'].includes(stateEvent)
    );
  },

  allowedStates(user) {
    return STATES.filter(({ name }) => user.allowedRequestStates.includes(name));
  },

  allowedTransitions(user, request) {
    const allowedStateEvents = RequestPresenter.stateEvents(request);
    const state = STATES.find(propEq(RequestPresenter.state(request), 'name'));

    return state.transitions.filter((transition) => allowedStateEvents.includes(transition.event));
  },
  canEdit(user, request) {
    return RequestPresenter.canEdit(request);
  },
  canChangeAssignee(user, request) {
    return this.canEdit(user, request) && (UserPresenter.isAdmin(user) || UserPresenter.isEditor(user));
  },
  canClaimAssignee(user, request) {
    if (RequestPresenter.assignee(request)) {
      return false;
    }

    return RequestPresenter.canClaimAssignee(request);
  },
  canAssignEditor(user, request) {
    const assignee = RequestPresenter.assignee(request);

    return (
      this.canEdit(user, request) &&
      UserPresenter.isResearchManager(user) &&
      !UserPresenter.isEditor(assignee) &&
      !UserPresenter.isAdmin(assignee)
    );
  },
  canBulkDelete(user) {
    return UserPresenter.isAdmin(user);
  },

  canBulkAssign(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user);
  },
  canBulkUpdateStatusRequests(user) {
    return UserPresenter.isAdmin(user);
  },
  canChangePriority(user, request) {
    return this.canEdit(user, request);
  },
};
