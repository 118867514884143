import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GeographyPresenter from 'presenters/Project/AudienceInsights/GeographyPresenter';

import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps';
import { isNil, find, propEq } from 'ramda';

import styles from './LocationMap.module.css';

const COLORS = ['#79CDE5', '#00A0CE', '#0084AB', '#065F7B'];
const defaultColor = '#DEE0E3';
const colorsLength = COLORS.length;

export default class LocationMap extends Component {
  static propTypes = {
    data: GeographyPresenter.shape(),
    map: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  state = {
    x: 0,
    y: 0,
    isTootipVisible: false,
    name: '',
    profilesCount: 0,
  };

  getColor = (number) => {
    if (number === 0) {
      return defaultColor;
    }

    return COLORS[Math.floor(number / this.calcRange())];
  };

  handleMove = (geography, evt) => {
    const x = evt.clientX;
    const y = evt.clientY + window.pageYOffset;

    const profilesCount = this.findData(geography.properties);

    this.setState({ x, y, isTootipVisible: true, name: geography.properties.name, profilesCount });
  };

  handleLeave = () => {
    this.setState({ isTootipVisible: false, name: '', profilesCount: 0 });
  };

  calcRange = () => {
    const { data } = this.props;
    const maxProfilesCountInGeo = GeographyPresenter.maxProfilesCountInGeo(data);

    return Math.floor(maxProfilesCountInGeo / colorsLength + 1);
  };

  findData = (value) => {
    const { data } = this.props;
    const countries = GeographyPresenter.countries(data);
    const states = GeographyPresenter.states(data);

    const findByPropEQ = (propName, propValue, array) => find(propEq(propValue, propName))(array);
    const hasProfilesCount = (geo) => (isNil(geo) ? 0 : geo.profilesCount);

    if (value.type === 'State') {
      const state = findByPropEQ('abbreviation', value.abbreviation, states);
      return hasProfilesCount(state);
    }

    const country = findByPropEQ('alpha2', value.alpha2, countries);
    return hasProfilesCount(country);
  };

  renderCount = () => {
    const { data } = this.props;
    const maxProfilesCountInGeo = GeographyPresenter.maxProfilesCountInGeo(data);

    if (maxProfilesCountInGeo === 0) {
      return false;
    }

    const items = COLORS.map((item, index) => {
      const top = (index + 1) * this.calcRange();
      const bottom = index * this.calcRange() + 1;
      return (
        <div className={styles.colorRange} key={`${bottom}${top}`}>
          <div className={styles.color} style={{ backgroundColor: item }} />
          <div className={styles.range}>
            {bottom}-{top}
          </div>
        </div>
      );
    });

    return (
      <>
        <div className={styles.title}>Executives count</div>
        <div>{items.reverse()}</div>
      </>
    );
  };

  render() {
    const { map, title } = this.props;
    const { x, y, isTootipVisible, name, profilesCount } = this.state;
    return (
      <div className={styles.root}>
        <h2 className={styles.mapHeader}>{title}</h2>
        <div className={styles.map}>
          <ComposableMap
            projectionConfig={{
              scale: 205,
              rotation: [-11, 0, 0],
            }}
            width={980}
            height={551}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <ZoomableGroup center={[0, 20]} disablePanning>
              <Geographies geography={map}>
                {(geographies, projection) =>
                  geographies.map((geography, i) => (
                    <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      onMouseMove={this.handleMove}
                      onMouseLeave={this.handleLeave}
                      style={{
                        default: {
                          fill: this.getColor(this.findData(geography.properties)),
                          stroke: '#fff',
                          strokeWidth: 0.5,
                          outline: 'none',
                        },
                        hover: {
                          fill: this.getColor(this.findData(geography.properties)),
                          stroke: '#fff',
                          strokeWidth: 0.5,
                          outline: 'none',
                          opacity: 0.5,
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
        </div>
        <div
          className={styles.tooltip}
          style={{ marginTop: y, marginLeft: x, display: isTootipVisible ? 'block' : 'none' }}
        >
          <span className={styles.name}>
            {name}: {profilesCount} profiles
          </span>
          <span className={styles.arrow} />
        </div>
        <div className={styles.legend}>{this.renderCount()}</div>
      </div>
    );
  }
}
