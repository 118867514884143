import React from 'react';
import { isPresent } from 'utils/HelperMethods';

import CsvIcon from 'icons/CsvIcon';
import NotAllowedIcon from 'icons/NotAllowedIcon';
import Loader from 'components/v2/Loader';

import styles from './FileStatus.module.css';

const FileStatus = (props) => {
  const { errors, isValidating } = props;

  if (isValidating) {
    return (
      <div className={styles.validating}>
        <Loader />
        <div className={styles.message}>File is validating</div>
        <div className={styles.anotherFile}>Upload another file</div>
      </div>
    );
  }

  if (isPresent(errors?.fileProps)) {
    return (
      <div className={styles.error}>
        <NotAllowedIcon width={60} height={60} />
        <div className={styles.message}>{errors.fileProps}</div>
        <div className={styles.anotherFile}>Upload another file</div>
      </div>
    );
  }

  if (isPresent(errors)) {
    return (
      <div className={styles.error}>
        <NotAllowedIcon width={60} height={60} />
        <div className={styles.message}>Incorrect file</div>
        <div className={styles.anotherFile}>Upload another file</div>
      </div>
    );
  }

  return (
    <div className={styles.success}>
      <CsvIcon width={60} height={60} color="mercury" />
      <div className={styles.message}>File successfully uploaded</div>
      <div className={styles.anotherFile}>Upload another file</div>
    </div>
  );
};

export default FileStatus;
