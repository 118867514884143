import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnUserPanelProfileGetAlerts } from 'utils/amplitude';

import Button from 'components/Button';
import ChangeAlertsPreferencesFormModal from './components/ChangeAlertsPreferencesFormModal';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';

import styles from './ProfileAlerts.module.css';

class ProfileAlerts extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape(),
    isSubscriptionsSaving: PropTypes.bool.isRequired,
    onSubscriptionsUpdate: PropTypes.func.isRequired,
    subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()).isRequired,
  };

  constructor(...props) {
    super(...props);
    this.state = {
      changeAlertPreferencesShow: false,
    };
  }

  handleClickAlertsPreference = () => this.setState({ ...this.state, changeAlertPreferencesShow: true });

  handleCanselAlertsPreference = () => this.setState({ ...this.state, changeAlertPreferencesShow: false });

  handleSubmitAlertPreferences = (subscriptionsParams) => {
    const { onSubscriptionsUpdate } = this.props;

    return onSubscriptionsUpdate(subscriptionsParams).then(() => {
      this.handleCanselAlertsPreference();
    });
  };

  render() {
    const { profile, subscriptions, isSubscriptionsSaving } = this.props;
    const { changeAlertPreferencesShow } = this.state;

    const organizations = profile.currentWorks.map(WorkPresenter.organization);
    const hasAnyActiveSubscription = subscriptions.length > 0;

    return (
      <div className={styles.root}>
        {hasAnyActiveSubscription ? (
          <Button
            className={styles.removeAlertsButton}
            label="Remove from Alerts"
            onClick={this.handleClickAlertsPreference}
            disabled={ProfilePresenter.isMasked(profile)}
          />
        ) : (
          <Button
            className={styles.getAlertsButton}
            label="Get Alerts"
            onClick={() => {
              this.handleClickAlertsPreference();
              logClickOnUserPanelProfileGetAlerts();
            }}
            primary
            disabled={ProfilePresenter.isMasked(profile)}
          />
        )}
        {changeAlertPreferencesShow && (
          <ChangeAlertsPreferencesFormModal
            onSubmit={this.handleSubmitAlertPreferences}
            subscriptions={subscriptions}
            loading={isSubscriptionsSaving}
            profile={profile}
            organizations={organizations}
            onClose={this.handleCanselAlertsPreference}
          />
        )}
      </div>
    );
  }
}

export default ProfileAlerts;
