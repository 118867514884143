import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reactSelectDebounce } from 'utils/HelperMethods';

import AsyncSelect from 'components/AsyncSelect';

import UserPresenter from 'presenters/UserPresenter';

const AccountManagersSelect = (props) => {
  const [currentAccountManager, setAccountManager] = useState(null);

  const handleAccountManagerSearch = (value) => {
    const { onSearchAccountManager } = props;

    return onSearchAccountManager({
      subscriptionStatusEq: 'active',
      stateEq: 'active',
      roleEq: 'admin',
      fullNameCont: value,
    });
  };

  const handleAccountManagerSearchDebounced = reactSelectDebounce(handleAccountManagerSearch);

  const handleAccountManagerChange = (accountManager) => {
    const { onAccountManagerIdChange } = props;
    setAccountManager(accountManager);
    onAccountManagerIdChange(accountManager?.id);
  };

  return (
    <AsyncSelect
      placeholder="Account Manager"
      loadOptions={handleAccountManagerSearchDebounced}
      defaultOptions
      value={currentAccountManager}
      onChange={handleAccountManagerChange}
      getOptionValue={UserPresenter.id}
      getOptionLabel={UserPresenter.fullName}
      isClearable
      noBottom
    />
  );
};

AccountManagersSelect.propTypes = {
  onSearchAccountManager: PropTypes.func.isRequired,
  onAccountManagerIdChange: PropTypes.func.isRequired,
};

export default AccountManagersSelect;
