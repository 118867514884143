import { pick, propOr } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import { RECOMMENDED_PROFILES_COUNT } from 'presenters/ProjectPresenter';
import { TARGETING_SOURCE_TYPE, DATABASE_SEARCH_KIND } from 'presenters/Project/SourcePresenter';

import TargetItemForm from 'forms/project/TargetItemForm';
import CsvItemForm from 'forms/project/CsvItemForm';

export default {
  defaultAttributes(attributes) {
    return {
      type: TARGETING_SOURCE_TYPE,
      kind: DATABASE_SEARCH_KIND,
      file: null,
      csvItemsAttributes: [],
      profilesCount: 0,
      recommendedProfilesCount: RECOMMENDED_PROFILES_COUNT,
      targetItemsAttributes: [],
      ...attributes,
    };
  },

  attributesToSubmit(source) {
    const propsToSend = ['type', 'kind', 'file', 'csvItemsAttributes', 'targetItemsAttributes'];
    const csvItemsAttributes = propOr([], 'csvItemsAttributes', source).map((item) =>
      CsvItemForm.attributesToSubmit(item),
    );
    const targetItemsAttributes = propOr([], 'targetItemsAttributes', source)
      .map((item) => TargetItemForm.attributesToSubmit(item))
      .filter(isPresent);

    return pick(propsToSend, { ...source, csvItemsAttributes, targetItemsAttributes });
  },
};
