import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { getClientScrollableContainerClass } from 'utils/DOMHelpers';

import ReactInfiniteScroll from 'react-infinite-scroller';

const InfiniteScroll = (props) => {
  const { children, hasMore, loadingMoreStatus, onLoadMore, threshold, getScrollParent } = props;

  const handleLoadMore = () => {
    if (hasMore && (loadingMoreStatus.isIdle || loadingMoreStatus.isFulfilled)) {
      onLoadMore();
    }
  };

  const handleGetScrollParent = () => {
    if (getScrollParent === false) {
      return null;
    }

    if (isNil(getScrollParent)) {
      return getClientScrollableContainerClass;
    }

    return getScrollParent;
  };

  return (
    <ReactInfiniteScroll
      getScrollParent={handleGetScrollParent()}
      hasMore={hasMore}
      loadMore={handleLoadMore}
      threshold={threshold}
      useWindow={false}
    >
      {children}
    </ReactInfiniteScroll>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadingMoreStatus: PropTypes.shape().isRequired,
  onLoadMore: PropTypes.func.isRequired,
  threshold: PropTypes.number,
  getScrollParent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

InfiniteScroll.defaultProps = {
  threshold: 250,
};

export default InfiniteScroll;
