import DashboardConstants from './AdminDashboardConstants';
import DashboardRepository from 'repositories/admin/Companies/DashboardsRepository';
import { decamelize } from 'utils/keysConverter';

export function loadDashboard(id, params) {
  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_START, form: params });

    return DashboardRepository.show(id, decamelize(params))
      .then(({ dashboard }) => {
        dispatch({ type: DashboardConstants.LOAD_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_SUCCESS, dashboard });

        return dashboard;
      })
      .catch((error) => {
        dispatch({ type: DashboardConstants.LOAD_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_ERROR, error });

        throw error;
      });
  };
}

export function changeDashboardForm(form) {
  return { type: DashboardConstants.CHANGE_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_FORM, form };
}
