import ProjectMatchedOrganizationsWizardStepConstants from './ProjectMatchedOrganizationsWizardStepConstants';

const initialState = {
  organizations: [],
  matchOrganizationForm: null,
  matchedCount: null,
  notMatchedCount: null,
  possibleMatchedCount: null,
  loading: false,
  isDetailsVisible: false,
};

function ProjectMatchedOrganizationsWizardStepReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProjectMatchedOrganizationsWizardStepConstants.MATCH_PROJECT_ORGANIZATIONS_START: {
      const { form } = action;

      return { ...initialState, matchOrganizationForm: form, loading: true };
    }
    case ProjectMatchedOrganizationsWizardStepConstants.MATCH_PROJECT_ORGANIZATIONS_SUCCESS: {
      const { matchedCount, notMatchedCount, possibleMatchedCount, organizations } = action;

      return {
        ...state,
        organizations,
        matchedCount,
        notMatchedCount,
        possibleMatchedCount,
        loading: false,
      };
    }
    case ProjectMatchedOrganizationsWizardStepConstants.TOGGLE_PROJECT_ORGANIZATIONS_DETAILS_VIEW: {
      return { ...state, isDetailsVisible: !state.isDetailsVisible };
    }
    default:
      return state;
  }
}

export default ProjectMatchedOrganizationsWizardStepReducer;
