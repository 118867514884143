import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewInterestModal from './NewInterestModal';
import * as actions from './NewInterestModalActions';

const mapStateToProps = (state) => ({
  saving: state.NewInterestModalReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewInterestModal);
