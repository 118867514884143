import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Routes from 'routes/App';
import { Box } from 'grommet';
import Spinner from 'components/Spinner';

import InterestPresenter from 'presenters/InterestPresenter';
import EditForm from './components/EditForm';
import PageHeader from 'components/PageHeader';

class InterestPage extends Component {
  static propTypes = {
    history: PropTypes.shape(),
    interestId: PropTypes.string.isRequired,
    interest: InterestPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    loadInterest: PropTypes.func.isRequired,
    updateInterest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadInterest, interestId } = this.props;

    loadInterest({ id: interestId });
  }

  handleSubmit = (interest) => {
    const { updateInterest, history, interestId } = this.props;
    return updateInterest(interestId, interest).then(() => {
      history.push(Routes.interestsPath());
    });
  };

  render() {
    const { interest, loading, saving } = this.props;

    if (loading || !interest) {
      return <Spinner />;
    }

    return (
      <Box margin="none">
        <PageHeader title="Edit Interest" backTo={Routes.adminInterestsPath()} />
        <Box width="medium">
          <EditForm interest={interest} saving={saving} onSubmit={this.handleSubmit} />
        </Box>
      </Box>
    );
  }
}

export default InterestPage;
