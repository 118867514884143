import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  passthrough(id, params = {}) {
    const url = Routes.passthroughApiV1SingleSignOnPath(id, params);

    return FetchHelpers.get(url);
  },
};
