import ProjectSubscriptionsListContainerConstants from './ProjectSubscriptionsListContainerConstants';
import SubscriptionsRepository from 'repositories/CurrentUser/SubscriptionsRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';

const BASE_FILTERS = { subjectTypeEq: 'Project' };

export function loadProjectSubscriptions({ page = 1, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: ProjectSubscriptionsListContainerConstants.LOAD_PROJECT_SUBSCRIPTION_START });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: ProjectSubscriptionsListContainerConstants.LOAD_PROJECT_SUBSCRIPTION_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: ProjectSubscriptionsListContainerConstants.LOAD_PROJECT_SUBSCRIPTION_ERROR });

        throw errors;
      });
  };
}

export function filterProjectSubscriptions({ per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: ProjectSubscriptionsListContainerConstants.FILTER_PROJECT_SUBSCRIPTION_START, filters });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page: 1,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: ProjectSubscriptionsListContainerConstants.FILTER_PROJECT_SUBSCRIPTION_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: ProjectSubscriptionsListContainerConstants.FILTER_PROJECT_SUBSCRIPTION_ERROR });

        throw errors;
      });
  };
}

export function deleteProjectSubscription(id) {
  return (dispatch) => {
    dispatch({ type: ProjectSubscriptionsListContainerConstants.DELETE_PROJECT_SUBSCRIPTION_START });

    return SubscriptionsRepository.destroy(id)
      .then(({ subscription }) => {
        dispatch({
          type: ProjectSubscriptionsListContainerConstants.DELETE_PROJECT_SUBSCRIPTION_SUCCESS,
          id,
        });

        return subscription;
      })
      .catch((errors) => {
        dispatch({
          type: ProjectSubscriptionsListContainerConstants.DELETE_PROJECT_SUBSCRIPTION_ERROR,
        });

        throw errors;
      });
  };
}
