import React from 'react';

import UserPresenter from 'presenters/UserPresenter';

import Typography from 'components/v2/Typography';
import UserAvatar from 'components/UserAvatar';

import styles from './UserOption.module.css';

const UserOption = (props) => {
  const { data: user } = props;

  return (
    <div className={styles.option}>
      <UserAvatar src={UserPresenter.avatarUrl(user)} size={24} />
      <Typography variant="body1">{UserPresenter.fullName(user)}</Typography>
    </div>
  );
};

UserOption.propTypes = {
  data: UserPresenter.shape().isRequired,
};

export default UserOption;
