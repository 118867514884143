import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(profileId, interestId, params) {
    const url = Routes.apiV1AdminProfileDraftInterestPath(profileId, interestId);
    return FetchHelpers.patch(url, params);
  },

  create(profileId, params) {
    const url = Routes.apiV1AdminProfileDraftInterestsPath(profileId);
    return FetchHelpers.post(url, params);
  },

  destroy(profileId, interestId) {
    const url = Routes.apiV1AdminProfileDraftInterestPath(profileId, interestId);
    return FetchHelpers.delete(url);
  },
};
