/* eslint-disable no-param-reassign  */
/* eslint-disable filenames/match-exported  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { pick, keys } from 'ramda';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import UserRepository from 'repositories/admin/Companies/UsersRepository';

const pickRamda = pick;
const keysRamda = keys;

const bulkCreateReducers = {
  bulkCreateStart(state) {
    state.bulkCreate.bulkCreatingStatus = FETCH_STATUSES.pending;
    state.bulkCreate.error = null;
  },
  bulkCreateSuccess(state) {
    state.bulkCreate.bulkCreatingStatus = FETCH_STATUSES.fulfilled;
  },
  bulkCreateFail(state, { payload: { error } }) {
    state.bulkCreate.bulkCreatingStatus = FETCH_STATUSES.failed;
    state.bulkCreate.error = error;
  },
};

const initialState = {
  bulkCreate: {
    bulkCreatingStatus: FETCH_STATUSES.idle,
    error: null,
  },
};

const adminCompaniesUsersSlice = createSlice({
  name: 'adminCompaniesUsersSlice',
  initialState,
  reducers: { ...bulkCreateReducers },
});

const { actions } = adminCompaniesUsersSlice;

export const useAdminCompaniesUsersActions = () => {
  const dispatch = useDispatch();

  return {
    bulkCreateUsers: async (companyId, params) => {
      dispatch(actions.bulkCreateStart());

      try {
        await UserRepository.bulkCreate(companyId, params);
        dispatch(actions.bulkCreateSuccess());
      } catch (error) {
        dispatch(actions.bulkCreateFail({ error }));
        throw error;
      }
    },
  };
};

export const adminCompaniesUsersReducer = adminCompaniesUsersSlice.reducer;

export const adminCompaniesUsersSelector = () =>
  useSelector((state) => pickRamda(keysRamda(initialState), state.adminCompaniesUsersReducer));

export default adminCompaniesUsersSlice;
