import { dropLast, isNil, last } from 'ramda';

export const getData = (response, collectionName) => {
  const { [collectionName]: pageItems, meta: pageMeta } = response;

  return { pageItems, pageMeta };
};

export const loadUntil = async (load, args, condition, collectionName) => {
  const queryParams = last(args);
  const urlParams = dropLast(1, args);
  const loadPage = async (page) => {
    const response = await load(...urlParams, { ...queryParams, page });

    return getData(response, collectionName);
  };

  let { pageItems: items, pageMeta: meta } = await loadPage();

  /* eslint-disable no-await-in-loop */
  while (condition({ items, meta }) && !isNil(meta.nextPage)) {
    const { pageItems, pageMeta } = await loadPage(meta.nextPage);

    items = [...items, ...pageItems];
    meta = pageMeta;
  }
  /* eslint-enable no-await-in-loop */

  return { items, meta };
};

export const loadAll = async (load, args, collectionName) => loadUntil(load, args, () => true, collectionName);
