import React, { Component } from 'react';
import Select from 'react-select/async';
import PropTypes from 'prop-types';
import { isNil, is } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import cn from 'clsx';
import styles from './AsyncSelect.module.css';
import customStyles from './styles.js';

const disableRootContainerScroll = () => {
  const client = document.querySelector('.client');

  if (client) {
    client.style.overflow = 'hidden';
  }
};

const enableRootContainerScroll = () => {
  const client = document.querySelector('.client');

  if (client) {
    client.style.removeProperty('overflow');
  }
};

class AsyncSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultOptions: null,
      isLoading: null,
    };
  }

  getOptionValue(option) {
    const { getOptionValue } = this.props;

    if (option === null) {
      return null;
    }

    return getOptionValue ? getOptionValue(option) : option.value;
  }

  getCurrentLabel = () => {
    const { value, isMulti } = this.props;

    if (isNil(value)) {
      return null;
    }

    return isMulti ? value.map(this.getOptionLabel).join(', ') : this.getOptionLabel(value);
  };

  getOptionLabel = (value) => {
    const { getOptionLabel } = this.props;

    return isNil(getOptionLabel) ? value.label : getOptionLabel(value);
  };

  rootStyles = () => {
    const { isDisabled, errors, noBottom } = this.props;
    return cn([styles.root], { [styles.disabled]: isDisabled, [styles.error]: errors, [styles.noBottom]: noBottom });
  };

  customStyles = () => {
    const { openMenuOnClick } = this.props;
    const noIdicatior = () => ({
      display: 'none',
    });

    return {
      ...customStyles,
      dropdownIndicator: openMenuOnClick ? customStyles.dropdownIndicator : noIdicatior,
    };
  };

  handleChange = (option, { action }) => {
    const { onChange, onValueChange } = this.props;

    if (action === 'clear') {
      enableRootContainerScroll();
    }

    if (onChange) {
      onChange(option);
    }

    if (onValueChange) {
      const value = this.getOptionValue(option);
      onValueChange(value);
    }
  };

  handleLoadOptionsAfterMenuOpen = () => {
    const { loadOptions } = this.props;
    const cb = (options) => {
      this.setState({ defaultOptions: options, isLoading: false });
    };

    this.setState({ isLoading: true });

    const options = loadOptions('', cb);

    if (is(Promise, options)) {
      options.then(cb);
    }
  };

  handleMenuOpen = (...args) => {
    const { onMenuOpen, menuIsOpen, shouldLoadOptionsAfterMenuOpen } = this.props;

    if (isNil(menuIsOpen) || menuIsOpen) {
      disableRootContainerScroll();
    }

    if (shouldLoadOptionsAfterMenuOpen) {
      this.handleLoadOptionsAfterMenuOpen();
    }

    if (onMenuOpen) {
      onMenuOpen(...args);
    }
  };

  handleMenuClose = (...args) => {
    const { onMenuClose } = this.props;

    enableRootContainerScroll();

    if (onMenuClose) {
      onMenuClose(...args);
    }
  };

  render() {
    const { placeholder, inlinePlaceholder, errors, placeholder: innerPlaceholder, ...rest } = this.props;
    const errorsText = Array.isArray(errors) ? errors.join(', ') : errors;

    return (
      <div className={this.rootStyles()} title={this.getCurrentLabel()}>
        <div className={styles.info}>
          <div className={styles.placeholder}>{!inlinePlaceholder && placeholder}</div>
          <div className={styles.errorText}>{errorsText}</div>
        </div>
        <Select
          defaultOptions={this.state.defaultOptions}
          isLoading={this.state.isLoading}
          {...rest}
          innerPlaceholder={innerPlaceholder}
          errors={errors}
          styles={this.customStyles()}
          onChange={this.handleChange}
          placeholder={inlinePlaceholder && isPresent(placeholder) ? placeholder : ''}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
        />
      </div>
    );
  }
}

AsyncSelect.propTypes = {
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  getOptionValue: PropTypes.func,
  placeholder: PropTypes.string,
  inlinePlaceholder: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  noBottom: PropTypes.bool,
  openMenuOnClick: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  loadOptions: PropTypes.func,
  shouldLoadOptionsAfterMenuOpen: PropTypes.bool,
};

AsyncSelect.defaultProps = {
  openMenuOnClick: true,
  inlinePlaceholder: false,
  isMulti: false,
};

export default AsyncSelect;
