import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import AttributeSafeCompaniesRepository from 'repositories/admin/AttributeSafeCompaniesRepository';

const restSlice = createRestSlice({
  resource: 'attributeSafeCompany',
  repository: AttributeSafeCompaniesRepository,
  slices: ['loadAttributeSafeCompanies'],
});

const initialState = {
  ...restSlice.initialState,
};

const attributeSafeCompaniesSlice = createSlice({
  name: 'adminAttributeSafeCompanies',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminAttributeSafeCompaniesReducer = attributeSafeCompaniesSlice.reducer;

export default attributeSafeCompaniesSlice;

export const adminAttributeSafeCompaniesRestHooks = restSlice.hooks(
  attributeSafeCompaniesSlice,
  'adminAttributeSafeCompaniesReducer',
);
