import TargetingSourceEditPageConstants from './TargetingSourceEditPageConstants';
import TargetingSourcesRepository from 'repositories/CurrentUser/Project/TargetingSourcesRepository';

export function loadTargetingSource(projectId, sourceId) {
  return (dispatch) => {
    dispatch({ type: TargetingSourceEditPageConstants.LOAD_PROJECT_TARGET_SOURCE_START });

    return TargetingSourcesRepository.show(projectId, sourceId).then(({ source }) => {
      dispatch({ type: TargetingSourceEditPageConstants.LOAD_PROJECT_TARGET_SOURCE_SUCCESS, source });

      return source;
    });
  };
}

export function updateTargetingSource(projectId, sourceId, attributes) {
  return (dispatch) => {
    dispatch({ type: TargetingSourceEditPageConstants.UPDATE_PROJECT_TARGET_SOURCE_START });

    return TargetingSourcesRepository.update(projectId, sourceId, attributes)
      .then(({ source }) => {
        dispatch({ type: TargetingSourceEditPageConstants.UPDATE_PROJECT_TARGET_SOURCE_SUCCESS, source });

        return source;
      })
      .catch((error) => {
        dispatch({ type: TargetingSourceEditPageConstants.UPDATE_PROJECT_TARGET_SOURCE_ERROR });

        throw error;
      });
  };
}

export function changeSource(source) {
  return { type: TargetingSourceEditPageConstants.CHANGE_PROJECT_TARGET_SOURCE, source };
}
