import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import Axis from './components/Axis';
import Chart from './components/Chart';

import StatisticPresenter from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';
import UserPresenter from 'presenters/UserPresenter';
import { humanRequestTypeName } from 'presenters/RequestPresenter';

import styles from './UserInfo.module.css';

export default class UserInfo extends Component {
  static propTypes = {
    user: UserPresenter.shape().isRequired,
    statistics: StatisticPresenter.shape().isRequired,
    onClick: PropTypes.func.isRequired,
    requestType: PropTypes.string.isRequired,
    statisticType: PropTypes.string.isRequired,
    statisticValue: PropTypes.string.isRequired,
    compare: PropTypes.bool.isRequired,
  };

  handleClick = () => {
    const { user, statistics, onClick } = this.props;

    onClick(user, statistics);
  };

  totalValueTitle() {
    const { statisticType, statisticValue, requestType } = this.props;

    if (statisticValue === 'spent_time') {
      return statisticType === 'avg'
        ? `Avg. time per ${humanRequestTypeName(requestType)}`
        : `Time Spent on "${humanRequestTypeName(requestType)}"`;
    }

    return `${humanRequestTypeName(requestType)} Processed`;
  }

  render() {
    const { user, statistics, statisticValue, compare } = this.props;
    const triangleClassNames = cx(styles.triangle, {
      [styles.negative]: StatisticPresenter.totalValue(statistics) < 0,
    });

    return (
      <div className={styles.root} onClick={this.handleClick} role="presentation">
        <div className={styles.title}>{UserPresenter.fullName(user)}</div>
        <div className={styles.main}>
          <div className={styles.chart}>
            <div className={styles.container}>
              <Chart data={StatisticPresenter.data(statistics)} height={92} />
            </div>
            <Axis data={StatisticPresenter.data(statistics)} height={30} />
          </div>
          <div className={styles.info}>
            <div className={styles.counter}>
              <div className={styles.header}>{this.totalValueTitle()}</div>
              <div className={styles.count}>
                <span>{StatisticPresenter.formattedTotalValue(statistics, statisticValue, compare)}</span>
                {compare && <span className={triangleClassNames} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
