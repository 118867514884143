import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(twoFactorAuthentication) {
    const url = Routes.apiV1TwoFactorAuthenticationsPhoneNumbersPath();

    return FetchHelpers.put(url, { twoFactorAuthentication });
  },
};
