import SavedSearchSubscriptionsListContainerConstants from './SavedSearchSubscriptionsListContainerConstants';
import SubscriptionsRepository from 'repositories/CurrentUser/SubscriptionsRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';

const BASE_FILTERS = { subjectTypeEq: 'SavedSearch' };

export function loadSavedSearchSubscriptions({ page = 1, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: SavedSearchSubscriptionsListContainerConstants.LOAD_SAVED_SEARCH_SUBSCRIPTION_START });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: SavedSearchSubscriptionsListContainerConstants.LOAD_SAVED_SEARCH_SUBSCRIPTION_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchSubscriptionsListContainerConstants.LOAD_SAVED_SEARCH_SUBSCRIPTION_ERROR });

        throw errors;
      });
  };
}

export function filterSavedSearchSubscriptions({ per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: SavedSearchSubscriptionsListContainerConstants.FILTER_SAVED_SEARCH_SUBSCRIPTION_START, filters });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page: 1,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: SavedSearchSubscriptionsListContainerConstants.FILTER_SAVED_SEARCH_SUBSCRIPTION_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchSubscriptionsListContainerConstants.FILTER_SAVED_SEARCH_SUBSCRIPTION_ERROR });

        throw errors;
      });
  };
}

export function deleteSavedSearchSubscription(id) {
  return (dispatch) => {
    dispatch({ type: SavedSearchSubscriptionsListContainerConstants.DELETE_SAVED_SEARCH_SUBSCRIPTION_START });

    return SubscriptionsRepository.destroy(id)
      .then(({ subscription }) => {
        dispatch({
          type: SavedSearchSubscriptionsListContainerConstants.DELETE_SAVED_SEARCH_SUBSCRIPTION_SUCCESS,
          id,
        });

        return subscription;
      })
      .catch((errors) => {
        dispatch({
          type: SavedSearchSubscriptionsListContainerConstants.DELETE_SAVED_SEARCH_SUBSCRIPTION_ERROR,
        });

        throw errors;
      });
  };
}
