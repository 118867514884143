import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import EditableContent from 'components/EditableContent';
import Modal from 'components/Modal';
import Anchor from 'components/Anchor';
import Logo from './components/Logo';
import EditForm from './components/EditForm';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import IndustryPresenter from 'presenters/IndustryPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import NaicsIndustryCodePresenter from 'presenters/NaicsIndustryCodePresenter';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';
import styles from './OrganizationInformation.module.css';

const OrganizationInformation = (props) => {
  const {
    editable,
    onLogoUpdate,
    onLogoDelete,
    organization,
    onIndustrySearch,
    onNaicsIndustryCodeSearch,
    saving,
    isChanged,
    onReviewChanges,
    onRicCodeUpdate,
  } = props;
  const [isEditing, setEditingFlag] = useState(false);
  const industry = OrganizationPresenter.industry(organization);
  const naicsIndustryCode = OrganizationPresenter.naicsIndustryCode(organization);
  const companyLeadershipPage = OrganizationPresenter.companyLeadershipPage(organization);
  const boardOfDirectorsListUrl = OrganizationPresenter.boardOfDirectorsListUrl(organization);

  const handleEdit = () => setEditingFlag(true);

  const handleStopEdit = () => setEditingFlag(false);

  const handleSubmit = (params) => props.onUpdate(params).then(handleStopEdit);

  const renderDl = (label, value) => (
    <dl className={styles.dl}>
      <dt className={styles.dt}>{label} </dt>
      <dd className={styles.dd}>{value}</dd>
    </dl>
  );

  return (
    <>
      <EditableContent
        editable={editable}
        onEdit={handleEdit}
        tooltipComponent={StyleGuides.OrganizationInformationStyleGuide}
      >
        <Box direction="column">
          <Box direction="row" margin={{ bottom: 'medium' }}>
            <Box margin={{ right: 'medium' }}>
              <Logo
                alt={OrganizationPresenter.name(organization)}
                editable={editable}
                onUpdate={onLogoUpdate}
                onDelete={onLogoDelete}
                logoUrl={OrganizationPresenter.logoUrl(organization)}
                logoUpdatedAt={OrganizationPresenter.formattedLogoUpdatedAt(organization)}
                saving={saving}
              />
            </Box>
            <Box direction="column">
              <HeadlineWithReviewChangesLink
                strong
                size="small"
                margin="small"
                title={OrganizationPresenter.name(organization)}
                isChanged={isChanged}
                onReviewChanges={onReviewChanges}
              />
              <Box direction="row">
                <Box margin={{ right: 'small' }}>
                  <Heading level="4" weight="bold">
                    {OrganizationPresenter.ticker(organization)}
                  </Heading>
                </Box>
                <Heading level="4" strong className={styles.organizationID}>
                  ID: {OrganizationPresenter.id(organization)}
                </Heading>
              </Box>
            </Box>
          </Box>
          <Box direction="column">
            {renderDl('Industry:', industry && IndustryPresenter.name(industry))}
            {renderDl('NAICS Industry Code:', naicsIndustryCode && NaicsIndustryCodePresenter.title(naicsIndustryCode))}
            {renderDl('Fortune Rank:', OrganizationPresenter.fortuneRank(organization))}
            {renderDl('FTSE Rank:', OrganizationPresenter.ftseRank(organization))}
            {renderDl('Global 2000 Rank:', OrganizationPresenter.global2000Rank(organization))}
            {renderDl('corporate DUNS#:', OrganizationPresenter.corporateDuns(organization))}
            {renderDl(
              'Link to the Board members list:',
              <Anchor href={boardOfDirectorsListUrl} target="_blank">
                {boardOfDirectorsListUrl}
              </Anchor>,
            )}
            {renderDl(
              'Company leadership page:',
              <Anchor href={companyLeadershipPage} target="_blank">
                {companyLeadershipPage}
              </Anchor>,
            )}
            {renderDl('RIC code:', OrganizationPresenter.ricCode(organization))}
          </Box>
        </Box>
      </EditableContent>
      {isEditing && (
        <Modal
          onClose={handleStopEdit}
          size="medium"
          header="Organization Information"
          disableOverlayClose
          disableCloseByEsc
        >
          <EditForm
            organization={organization}
            saving={saving}
            onIndustrySearch={onIndustrySearch}
            onNaicsIndustryCodeSearch={onNaicsIndustryCodeSearch}
            onRicCodeUpdate={onRicCodeUpdate}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}
    </>
  );
};

OrganizationInformation.propTypes = {
  editable: PropTypes.bool.isRequired,
  onLogoUpdate: PropTypes.func.isRequired,
  onLogoDelete: PropTypes.func.isRequired,
  onIndustrySearch: PropTypes.func.isRequired,
  onNaicsIndustryCodeSearch: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRicCodeUpdate: PropTypes.func.isRequired,
  organization: OrganizationPresenter.shape().isRequired,
  saving: PropTypes.bool.isRequired,
  isChanged: PropTypes.bool.isRequired,
  onReviewChanges: PropTypes.func.isRequired,
};

export default OrganizationInformation;
