import { isNil } from 'ramda';

export default {
  container: (base) => ({
    ...base,
    position: 'static',
  }),

  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? 'transparent' : 'transparent',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingBottom: '0',
    border: 'none',
    height: 'auto',
    minHeight: 'auto',
    fontSize: '14px',
    position: 'static',
    '&:hover': {
      borderColor: 'transparent',
    },
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),

  clearIndicator: (base) => ({
    ...base,
    backgroundImage:
      'url("data:image/svg+xml, %3Csvg%20width%3D%2210%22%20height%3D%2210%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%201l8%208M1%209l8-8%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.2%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    padding: '6px',
    marginRight: '8px',
    width: '8px',
    height: '8px',
    '> svg': {
      display: 'none',
    },
  }),

  indicatorsContainer: (base, state) => {
    const {
      selectProps: { innerPlaceholder, errors },
    } = state;
    const alignSelfValue = isNil(innerPlaceholder) && isNil(errors);
    return {
      ...base,
      marginRight: '16px',
      right: 0,
      height: '100%',
      top: 0,
      alignSelf: alignSelfValue ? 'center' : 'flex-start',
    };
  },

  dropdownIndicator: (base) => ({
    ...base,
    backgroundImage:
      'url("data:image/svg+xml, %3Csvg%20width%3D%2210%22%20height%3D%226%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M9%201L5%205%201%201%22%20stroke%3D%22%23444%22%20stroke-width%3D%221.2%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22/%3E%3C/svg%3E")',
    backgroundRepeat: 'no-repeat',
    padding: '4px',
    width: '10px',
    height: '4px',
    '> svg': {
      display: 'none',
    },
  }),

  menu: (base) => ({
    ...base,
    borderRadius: 0,
    margin: 0,
    boxShadow: 'none',
    border: 'none',
    outline: '1px solid #dee0e3',
    zIndex: '10',
  }),

  menuList: (base) => ({
    ...base,
    padding: 0,
  }),

  noOptionsMessage: (base) => ({
    ...base,
    borderRadius: 0,
  }),

  option: (base, state) => {
    const common = {
      ...base,
      paddingTop: '20px',
      paddingBottom: '20px',
      color: '#000',
      fontSize: '14px',
      backgroundColor: state.isFocused ? '#f4f5f7' : 'transparent',
      '&:hover': {
        backgroundColor: '#f4f5f7',
      },
    };

    if (state.isDisabled) {
      return {
        ...base,
        ...common,
        color: 'var(--oslo-gray)',
        '&:hover': {
          cursor: 'not-allowed',
        },
      };
    }

    return {
      ...base,
      ...common,
    };
  },

  valueContainer: (base, state) => {
    const { isMulti } = state;
    const wrapValue = isMulti ? 'wrap' : 'nowrap';
    return {
      ...base,
      paddingTop: '0',
      paddingBottom: '0',
      lineHeight: '1',
      position: 'static',
      flexWrap: wrapValue,
    };
  },

  singleValue: (base) => ({
    ...base,
    top: 'auto',
    transform: 'none',
    position: 'static',
    lineHeight: 'initial',
  }),
};
