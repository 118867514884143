import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './App.scss';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { isNil, isEmpty } from 'ramda';
import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { v1 } from 'grommet-theme-v1';

import Spinner from 'components/Spinner';

import Alerts from 'components/v2/Alerts';
import AppSwitch from 'components/AppSwitch';
import AppRoute from 'components/AppRoute';
import AdminPanel from 'containers/AdminPanel';
import CompanySubscriptionExpiredErrorPage from 'components/CompanySubscriptionExpiredErrorPage';
import NotAnActiveUserErrorPage from 'components/NotAnActiveUserErrorPage';
import RunOutOfCreditsErrorPage from 'components/RunOutOfCreditsErrorPage';
import SignInPage from 'components/SignInPage';
import SalesForceErrorPage from 'components/SalesForceErrorPage';
import SingleSignOnErrorPage from 'components/SingleSignOnErrorPage';
import UserPanel from 'containers/UserPanel';

import Routes from 'routes';
import UserPolicy from 'policies/UserPolicy';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import { withAuthenticator } from 'containers/Authenticator';

const themeV1 = deepMerge(v1, {
  global: {
    font: {
      family: "'Source Sans Pro', sans-serif",
      size: '16px',
    },
  },
  anchor: {
    color: 'var(--primary-color)',
  },
  button: {
    default: {
      color: 'var(--mine-shaft)',
      background: {
        color: '#fff',
      },
      extend: () => `
          border: 2px solid var(--primary-color);
          border-radius: 4px !important;
          font-size: 14px;
          font-weight: 300 !important;
          max-width: 384px;
          min-width: 120px;
        `,
    },
    primary: {
      color: '#fff',
      background: {
        color: 'var(--primary-color)',
      },
      extend: () => `
          border: 2px solid var(--primary-color);
          border-radius: 4px !important;
          font-size: 14px;
          font-weight: 300 !important;
          max-width: 384px;
          min-width: 120px;
        `,
    },
  },
  checkBox: {
    color: 'var(--primary-color)',
    border: {
      color: 'var(--iron) !important',
    },
    check: {
      extend: () => `
          border: 2px solid #000;
          border-radius: 0;
        `,
    },
    hover: {
      extend: (params) => {
        if (params.toggle) {
          return `
            border: 2px solid #000 !important;
            border-radius: 24px;
          `;
        }
        return `
          border: 2px solid #000 !important;
          border-radius: 0;
        `;
      },
    },
  },
  spinner: {
    container: {
      color: 'var(--primary-color)',
    },
  },
  formField: {
    border: {
      side: 'none',
    },
  },
  borderSize: {
    xsmall: '0px',
    small: '2px',
    medium: '4px',
    large: '12px',
    xlarge: '24px',
  },
});

const AppWithoutAuthenticator = (props) => {
  const { loadCurrentUser, currentUser, isCurrentUserLoading, signOut } = props;

  useEffect(() => {
    loadCurrentUser();
  }, []);

  useEffect(() => {
    if (!isNil(currentUser) && !isEmpty(currentUser)) {
      const company = UserPresenter.company(currentUser);
      const userId = UserPresenter.id(currentUser);
      const clientId = CompanyPresenter.id(company);

      // to make this script work https://gist.githubusercontent.com/smhmic/82a180529a036c1cb0fd37c41d06f7ce/raw/7c1fd8fe0439c3ee6ddda0ce21cacb45ae5e6606/view%2520gtm%2520data%2520layer%2520state%2520.js
      window.dataLayer.push({ biuid: userId, bicid: clientId });
    }
  }, [currentUser]);

  if (isCurrentUserLoading) {
    return <Spinner color="#00a0ce" />;
  }

  return (
    <BrowserRouter>
      <Alerts>
        <Grommet full theme={themeV1} className="grommet">
          <AppSwitch>
            {UserPolicy.isHaveAdminAccess(currentUser) && (
              <AppRoute path={Routes.adminRootPath()} component={AdminPanel} />
            )}
            {!currentUser && <Redirect from={Routes.adminRootPath()} to={Routes.signInPath()} />}
            {!currentUser && <AppRoute path={Routes.ssoSignInPath(':companySlug')} component={SignInPage} />}
            {!currentUser && <AppRoute path={Routes.signInPath()} component={SignInPage} />}
            {currentUser && <Redirect from={Routes.signInPath()} to={Routes.rootPath()} />}
            <AppRoute
              path={Routes.companySubscriptionExpiredErrorPath()}
              render={() => <CompanySubscriptionExpiredErrorPage onSignOut={signOut} />}
            />
            <AppRoute
              path={Routes.notAnActiveUserErrorPath()}
              render={() => <NotAnActiveUserErrorPage onSignOut={signOut} />}
            />
            <AppRoute path={Routes.runOutOfCreditsPath(':type')} component={RunOutOfCreditsErrorPage} />

            <AppRoute path={Routes.salesforceErrorPath()} component={SalesForceErrorPage} />
            <AppRoute path={Routes.singleSignOnErrorPath()} component={SingleSignOnErrorPage} />
            <AppRoute path={Routes.rootPath()} component={UserPanel} />
          </AppSwitch>
        </Grommet>
      </Alerts>
    </BrowserRouter>
  );
};

const App = withAuthenticator(AppWithoutAuthenticator, ({ loadCurrentUser, signOut }) => ({
  loadCurrentUser,
  signOut,
}));

AppWithoutAuthenticator.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  loadCurrentUser: PropTypes.func.isRequired,
  isCurrentUserLoading: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default App;
