import React, { Component } from 'react';

import Routes from 'routes';
import AnchorLink from 'components/grommet/AnchorLink';
import FullProfileRequestPresenter from 'presenters/FullProfileRequestPresenter';

class FullProfileRequestRowCells extends Component {
  static propTypes = {
    row: FullProfileRequestPresenter.shape(),
  };

  stopPropagation = (event) => event.stopPropagation();

  dataStoreAgreement() {
    const { row: request } = this.props;

    if (FullProfileRequestPresenter.gdprCustomer(request)) {
      return FullProfileRequestPresenter.dataStoreAgreement(request) ? 'ACCEPTED' : 'DECLINED';
    }

    return null;
  }

  render() {
    const { row: request } = this.props;

    return (
      <>
        <td>{FullProfileRequestPresenter.id(request)}</td>
        <td>{FullProfileRequestPresenter.ipAddress(request)}</td>
        <td>{FullProfileRequestPresenter.formattedCreatedAt(request)}</td>
        <td>{FullProfileRequestPresenter.fullName(request)}</td>
        <td>{FullProfileRequestPresenter.email(request)}</td>
        <td>{FullProfileRequestPresenter.sentFullProfilePdf(request) ? 'YES' : 'NO'}</td>
        <td>{FullProfileRequestPresenter.countryName(request)}</td>
        <td>{FullProfileRequestPresenter.gdprCustomer(request) ? 'YES' : 'NO'}</td>
        <td>{this.dataStoreAgreement()}</td>
        <td>
          <AnchorLink to={Routes.editAdminProfilePath(FullProfileRequestPresenter.profileId(request))}>
            {FullProfileRequestPresenter.profileId(request)}
          </AnchorLink>
        </td>
      </>
    );
  }
}

export default FullProfileRequestRowCells;
