import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Anchor, Spinner as Spinning } from 'grommet';

class DownloadAnchor extends Component {
  static propTypes = {
    icon: PropTypes.node,
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { icon, isLoading, ...props } = this.props;

    return <Anchor {...props} icon={isLoading ? <Spinning /> : icon} />;
  }
}

export default DownloadAnchor;
