import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { debounce } from 'utils/HelperMethods';

import Table from 'components/Table';
import { Box } from 'grommet';
import Search from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import FullProfileRequestRowCells from './components/FullProfileRequestRowCells';

import FullProfileRequestPresenter from 'presenters/FullProfileRequestPresenter';

const MAX_SEARCH_LENGTH = 40;

class FullProfileRequestListPage extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    fullProfileRequests: PropTypes.arrayOf(FullProfileRequestPresenter.shape()).isRequired,
    loadFullProfileRequests: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    filterFullProfileRequests: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    initializeFullProfileRequestList: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
  };

  componentDidMount() {
    this.props.initializeFullProfileRequestList();
    this.props.loadFullProfileRequests({ page: 1, filters: this.props.filters });
  }

  tableColumns = [
    {
      name: 'id',
      field: 'id',
      width: '125',
    },
    {
      name: 'IP address',
      field: 'ipAddress',
    },
    {
      name: 'Created At',
      field: 'createdAt',
    },
    {
      name: 'Full Name',
      field: 'firstName,lastName',
    },
    {
      name: 'Email',
      field: 'email',
    },
    {
      name: 'Sent pdf',
      field: 'needSendPdf',
    },
    {
      name: 'Country',
      field: 'countryName',
    },
    {
      name: 'GDPR',
      field: 'gdprCustomer',
    },
    {
      name: 'Data Store Agreement',
      field: 'dataStoreAgreement',
    },
    {
      name: 'Profile ID',
      field: 'profileId',
    },
  ];

  isAllFullProfileRequestsLoaded = () => {
    const { nextPage } = this.props;

    return isNil(nextPage);
  };

  handleLoadMore = () => {
    const { loading, nextPage, loadFullProfileRequests, filters } = this.props;

    if (!loading && !this.isAllFullProfileRequestsLoaded()) {
      loadFullProfileRequests({ page: nextPage, filters });
    }
  };

  handeFilter = (fieldName, value) => {
    const { filters, filterFullProfileRequests } = this.props;

    filterFullProfileRequests({ ...filters, [fieldName]: value });
  };

  handeFilterDebounced = debounce(this.handeFilter);

  handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handeFilterDebounced(fieldName, value);

  handleSort = (sortFields) => {
    const { filters, filterFullProfileRequests } = this.props;

    filterFullProfileRequests({ ...filters, sortFields });
  };

  handleRowClick = () => {};

  render() {
    const { filters, fullProfileRequests, totalCount } = this.props;

    return (
      <Box margin="none" full="horizontal">
        <PageHeader title="Full Profile Requests" />

        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box width="medium" margin={{ right: 'small' }}>
            <Search
              placeholder="Search"
              onChange={this.handleFilterChange('searchFieldCont')}
              maxLength={MAX_SEARCH_LENGTH}
            />
          </Box>
        </Box>

        <Table
          columns={this.tableColumns}
          sorts={filters.sortFields}
          onMore={this.handleLoadMore}
          onSort={this.handleSort}
          onRowClick={this.handleRowClick}
          hasMore={!this.isAllFullProfileRequestsLoaded()}
          rows={fullProfileRequests}
          rowCellsComponent={<FullProfileRequestRowCells />}
          totalRows={totalCount}
          rowsDeletable={false}
        />
      </Box>
    );
  }
}

export default FullProfileRequestListPage;
