import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LineChart, ResponsiveContainer, XAxis } from 'recharts';
import CustomizedTick from './components/CustomizedTick';

export default class Axis extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    height: PropTypes.number,
  };

  render() {
    const { data, height } = this.props;
    return (
      <ResponsiveContainer width="100%" height={height}>
        <LineChart data={data}>
          <XAxis
            dataKey="textDate"
            interval="preserveStartEnd"
            axisLine={false}
            tickLine={false}
            tick={<CustomizedTick />}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
