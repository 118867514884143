import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Provide a list of all boards and organizations the executive is currently involved with in an official capacity.
        Include titles and years served, when that information is available.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <p>
        Junior Achievement
        <br />
        Member, National Board of Directors
        <br />
        2017-Present
      </p>
      <p>
        <strong>Important Note: </strong>
        If the organization you need to enter does not come up in the menu, you need to go to the “Organizations” module
        and add your organization.
      </p>
      <p>
        <strong>Style Tips:</strong>
      </p>
      <ul>
        <li>
          We are not looking for volunteer positions here. Those would go into the “Personal Interests and Attributes”
          section.
        </li>
        <li>
          This section is reserved for Board of Directors positions, positions on industry organization boards,
          University Board of Trustees, Advisory Boards, etc.
        </li>
        <li>
          Always select an existing organization from the menu if it exists. Do not create dupes!!! e.g., “General
          Motors” and “General Motors, Inc.”
        </li>
        <li>Titles should be capitalized, as in the example above</li>
        <li>Include years served. If they are still on the board, select “Present”.</li>
      </ul>
    </div>
  );
}
