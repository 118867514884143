import { isBlank } from 'utils/HelperMethods';
import UserPolicy from 'policies/UserPolicy';
import RequestPolicy from 'policies/RequestPolicy';
import UserPresenter from 'presenters/UserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';

export default {
  canSeeList(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },
  canSeeElement(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },
  canDestroyElement(user) {
    return (
      UserPresenter.isAdmin(user) ||
      UserPresenter.isEditor(user) ||
      UserPresenter.isResearcher(user) ||
      UserPresenter.isResearchManager(user)
    );
  },
  canAddElement(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },
  canEdit(user, organization, request) {
    if (isBlank(request)) {
      return (
        UserPresenter.isAdmin(user) ||
        UserPresenter.isEditor(user) ||
        UserPresenter.isResearcher(user) ||
        UserPresenter.isResearchEditor(user) ||
        UserPresenter.isResearchManager(user)
      );
    }

    const assignee = RequestPresenter.assignee(request);

    return (
      RequestPolicy.canEdit(user, request) &&
      (isBlank(assignee) || UserPresenter.id(assignee) === UserPresenter.id(user))
    );
  },
  canExportList(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchManager(user);
  },
};
