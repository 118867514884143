import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import SingleSignOnRepository from 'repositories/admin/Companies/SingleSignOnRepository';

const restSlice = createRestSlice({
  resource: 'singleSignOn',
  repository: SingleSignOnRepository,
  slices: ['loadSingleSignOn', 'updateSingleSignOn'],
});

const initialState = {
  ...restSlice.initialState,
};

const singleSignOnSlice = createSlice({
  name: 'adminCompanySingleSignOn',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanySingleSignOnReducer = singleSignOnSlice.reducer;

export default singleSignOnSlice;

export const adminCompanySingleSignOnRestHooks = restSlice.hooks(singleSignOnSlice, 'adminCompanySingleSignOnReducer');
