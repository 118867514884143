import React from 'react';
import Routes from 'routes';
import { Redirect } from 'react-router-dom';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import RoutedTabs, { RoutedTab } from 'components/v2/RoutedTabs';
import AppSwitch from 'components/AppSwitch';
import AppRoute from 'components/AppRoute';
import PageHeader from 'components/PageHeader';

import SampleProfilesTab from './containers/SampleProfilesTab';
import SampleProjectsTab from './containers/SampleProjectsTab';
import SampleSavedSearchesTab from './containers/SampleSavedSearchesTab';

import SampleProfilePolicy from 'policies/SampleProfilePolicy';
import SampleProjectPolicy from 'policies/SampleProjectPolicy';
import SampleSavedSearchPolicy from 'policies/SampleSavedSearchPolicy';

import styles from './SamplesPage.module.css';

const SamplesPage = (props) => {
  const { currentUser } = props;

  return (
    <div className={styles.root}>
      <PageHeader title="Samples" />
      <div className={styles.tabs}>
        <RoutedTabs>
          {SampleProfilePolicy.canSeeList(currentUser) && (
            <RoutedTab path={Routes.adminSamplesProfilesPath()}>Sample Profiles</RoutedTab>
          )}

          {SampleProjectPolicy.canSeeList(currentUser) && (
            <RoutedTab path={Routes.adminSamplesProjectsPath()}>Sample Projects</RoutedTab>
          )}

          {SampleSavedSearchPolicy.canSeeList(currentUser) && (
            <RoutedTab path={Routes.adminSamplesSavedSearchesPath()}>Sample Saved Searches</RoutedTab>
          )}
        </RoutedTabs>

        <div className={styles.component}>
          <AppSwitch>
            <AppRoute exact path={Routes.adminSamplesProfilesPath()} component={SampleProfilesTab} />
            <AppRoute exact path={Routes.adminSamplesProjectsPath()} component={SampleProjectsTab} />
            <AppRoute exact path={Routes.adminSamplesSavedSearchesPath()} component={SampleSavedSearchesTab} />
            <Redirect to={Routes.adminSamplesProfilesPath()} />
          </AppSwitch>
        </div>
      </div>
    </div>
  );
};

SamplesPage.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
};

export default SamplesPage;
