import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(companyId) {
    const url = Routes.apiV1AdminCompanyLegalSettingPath(companyId);

    return FetchHelpers.get(url);
  },

  update(companyId, legalSetting) {
    const url = Routes.apiV1AdminCompanyLegalSettingPath(companyId);

    return FetchHelpers.put(url, { legalSetting });
  },
};
