import { adminEditorialDashboardRemainingRequestNumbersStatisticsRestHooks } from 'slices/admin/editorialDashboard/remainingRequestNumbersStatisticsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useRemainingRequestNumbersStatistics = () => {
  const { remainingRequestNumbersStatistics, loadRemainingRequestNumbersStatistics } =
    adminEditorialDashboardRemainingRequestNumbersStatisticsRestHooks.use();

  return {
    remainingRequestNumbersStatistics: remainingRequestNumbersStatistics.items,
    remainingRequestNumbersStatisticsLoadingStatus: useFetchStatus(remainingRequestNumbersStatistics.loadingStatus),
    loadRemainingRequestNumbersStatistics,
  };
};

export default useRemainingRequestNumbersStatistics;
