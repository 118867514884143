import React from 'react';
import Routes from 'routes';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import WorkSearchPresenter from 'presenters/WorkSearchPresenter';

import Link from 'components/v2/Link';
import Typography from 'components/v2/Typography';
import ProfileAvatar from 'components/ProfileAvatar';

import styles from './ProfileInformation.module.css';

const ProfileInformation = (props) => {
  const { profile } = props;
  const profilePath = Routes.profilePath(profile.id);
  const currentWorks = ProfileSearchPresenter.currentWorks(profile);

  return (
    <div className={styles.information}>
      <Link target="_blank" href={profilePath}>
        <ProfileAvatar size={100} src={ProfileSearchPresenter.photoUrl(profile)} />
      </Link>
      <div className={styles.details}>
        <Link color="primary" className={styles.profileFullName} target="_blank" href={profilePath}>
          <Typography color="primary" variant="h3">
            {ProfileSearchPresenter.fullName(profile)}
          </Typography>
        </Link>
        {currentWorks.map((currentWork) => (
          <div key={currentWork.id} className={styles.work}>
            <Typography className={styles.info} variant="body2">
              {WorkSearchPresenter.jobTitle(currentWork)}
            </Typography>
            <Typography className={styles.info} variant="body2">
              {WorkSearchPresenter.organizationName(currentWork)}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

ProfileInformation.propTypes = {
  profile: ProfileSearchPresenter.shape().isRequired,
};

export default ProfileInformation;
