import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CountryPresenter from 'presenters/CountryPresenter.js';

class CountryRowCells extends Component {
  static propTypes = {
    row: PropTypes.shape(),
  };

  render() {
    const { row: country } = this.props;

    return (
      <>
        <td>{CountryPresenter.id(country)}</td>
        <td>{CountryPresenter.name(country)}</td>
        <td>{CountryPresenter.humanWorldRegion(country)}</td>
        <td>{CountryPresenter.alpha2(country)}</td>
        <td>{CountryPresenter.isGdpr(country) ? 'YES' : 'NO'}</td>
      </>
    );
  }
}

export default CountryRowCells;
