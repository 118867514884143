import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import cn from 'clsx';
import Routes from 'routes';
import { matchPath, withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import { logClickOnMyAccount } from 'utils/amplitude';
import { setBodyOverflow, removeBodyOverflow } from 'utils/DOMHelpers';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import NotificationsSummaryPresenter from 'presenters/NotificationsSummaryPresenter';

import Logotype from 'components/v2/Logotype';
import Link from 'components/v2/Link';
import CrossIcon from 'icons/CrossIcon';
import MenuIcon from 'icons/MenuIcon';
import HeaderProfileIcon from 'icons/HeaderProfileIcon';
import DownIcon from 'icons/DownIcon';
import Button from 'components/v2/Button';
import QuickSearch from 'containers/UserPanel/containers/QuickSearch';

import AuthorizedMenu from './components/AuthorizedMenu';
import UnauthorizedMenu from './components/UnauthorizedMenu';

import UserPolicy from 'policies/UserPolicy';
import ProfilePolicy from 'policies/ProfilePolicy';

import styles from './Header.module.css';

class HeaderWithoutRouter extends Component {
  state = {
    isProfileMenuOpened: false,
    isMenuOpened: false,
  };

  handleClick = () => {
    const { isProfileMenuOpened } = this.state;
    this.setState({ isProfileMenuOpened: !isProfileMenuOpened });
  };

  handleClickOutside = () => {
    this.setState({ isProfileMenuOpened: false });
  };

  handleMenuOpen = () => {
    setBodyOverflow();
    this.setState({ isMenuOpened: true });
  };

  handleMenuClose = () => {
    removeBodyOverflow();
    this.setState({ isMenuOpened: false });
  };

  notSearchPage = () => {
    const {
      history: { location },
    } = this.props;

    const isSavedSearchesPath = !!matchPath(location.pathname, { path: Routes.savedSearchesPath() });
    const isSearchPath = matchPath(location.pathname, { path: Routes.searchPath(), exact: true });

    return !isSearchPath && !isSavedSearchesPath;
  };

  handleLogOut = () => {
    const { onSignOut } = this.props;
    onSignOut();
  };

  renderUserMenu() {
    const { onStopImpersonate, currentUser, notificationsSummary } = this.props;
    const hideItemOnMobile = cn([styles.item], [styles.hideOnMobile]);
    return (
      <ul>
        <li className={styles.item}>
          <span>Hello, {CurrentUserPresenter.fullName(currentUser)}</span>
        </li>
        <li className={styles.item} onClick={this.handleMenuClose} role="presentation">
          <Link to={Routes.dashboardPath()} variant="noneDecoration">
            Dashboard
            {NotificationsSummaryPresenter.isDashboardUpdated(notificationsSummary) && (
              <div className={styles.notificationBadge} />
            )}
          </Link>
        </li>
        <li className={styles.item} onClick={this.handleMenuClose} role="presentation">
          <Link to={Routes.userAccountPath()} onClick={logClickOnMyAccount} variant="noneDecoration">
            My Account
          </Link>
        </li>
        {UserPolicy.canSeeCorporateAccount(currentUser) && (
          <li className={styles.item} onClick={this.handleMenuClose} role="presentation">
            <Link to={Routes.corporateAccountPath()} variant="noneDecoration">
              Corporate Account
            </Link>
          </li>
        )}
        {UserPolicy.isHaveAdminAccess(currentUser) && (
          <li className={hideItemOnMobile} onClick={this.handleMenuClose} role="presentation">
            <Link to={Routes.adminRootPath()} variant="noneDecoration">
              Admin
            </Link>
          </li>
        )}
        <li className={styles.item} onClick={this.handleMenuClose} role="presentation">
          <Link to={Routes.userAlertsPath()} variant="noneDecoration">
            Alerts
          </Link>
        </li>

        {CurrentUserPresenter.isImpersonating(currentUser) ? (
          <li className={styles.item} onClick={this.handleMenuClose} role="presentation">
            <Button onClick={onStopImpersonate} className={styles.menuButton} variant="raw">
              Stop Impersonate
            </Button>
          </li>
        ) : null}

        <li className={styles.item} onClick={this.handleMenuClose} role="presentation">
          <Button onClick={this.handleLogOut} className={styles.menuButton} variant="raw">
            Log out
          </Button>
        </li>
      </ul>
    );
  }

  render() {
    const { isProfileMenuOpened, isMenuOpened } = this.state;
    const { currentUser, notificationsSummary } = this.props;
    const iconWrapper = cn([styles.arrowBox], { [styles.up]: isProfileMenuOpened });
    const headerStyles = cn(
      [styles.header],
      { [styles.menuOpened]: isMenuOpened },
      { [styles.profileMenuOpened]: isProfileMenuOpened },
    );

    return (
      <div className={styles.wrapper}>
        <div className={headerStyles}>
          <div className={styles.logo}>
            <Link href={Routes.logoUrl()}>
              <Logotype />
            </Link>
            {isMenuOpened ? (
              <Button onClick={this.handleMenuClose} className={styles.menuSwitcher} variant="outlined">
                <CrossIcon color="primary" width={28} height={28} className={styles.crossIcon} />
              </Button>
            ) : (
              <Button
                onClick={this.handleMenuOpen}
                className={cn(styles.menuSwitcher, styles.menuHamburger)}
                variant="outlined"
              >
                <MenuIcon className={styles.hamburgerIcon} width={24} />
              </Button>
            )}
          </div>
          <div className={styles.menu}>
            <div className={styles.search}>
              {currentUser && this.notSearchPage() && ProfilePolicy.canSearch(currentUser) && (
                <QuickSearch onSelect={this.handleMenuClose} />
              )}
            </div>
            {currentUser ? (
              <AuthorizedMenu
                currentUser={currentUser}
                onCloseMenu={this.handleMenuClose}
                notificationsSummary={notificationsSummary}
              />
            ) : (
              <UnauthorizedMenu onCloseMenu={this.handleMenuClose} />
            )}
            {currentUser ? (
              <div className={styles.profile} onClick={this.handleClick} role="presentation">
                <div className={styles.switch}>
                  <div className={styles.icon}>
                    {NotificationsSummaryPresenter.isDashboardUpdated(notificationsSummary) && (
                      <div className={styles.notificationBadge} />
                    )}
                    <HeaderProfileIcon width={22} height={22} />
                  </div>
                  <div className={iconWrapper}>
                    <DownIcon width={14} height={14} />
                  </div>
                </div>
                {isProfileMenuOpened && <div className={styles.dropdownMenu}>{this.renderUserMenu()}</div>}
              </div>
            ) : (
              <div className={styles.profile}>
                <Link to={Routes.signInPath()} variant="noneDecoration">
                  Sign In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

HeaderWithoutRouter.propTypes = {
  history: PropTypes.shape(),
  currentUser: CurrentUserPresenter.shape(),
  onSignOut: PropTypes.func.isRequired,
  onStopImpersonate: PropTypes.func.isRequired,
  notificationsSummary: PropTypes.shape(),
};

export default compose(withRouter, onClickOutside)(HeaderWithoutRouter);
