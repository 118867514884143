import React from 'react';
import PropTypes from 'prop-types';

const isFirstTickOfGroup = (index, data) => {
  if (index === 0) {
    return false;
  }

  return data[index - 1].commonKey !== data[index].commonKey;
};

const CustomizedXAxisCompanyDividerTick = (props) => {
  const {
    x,
    offsetTop,
    height,
    data,
    payload: { index },
  } = props;

  if (isFirstTickOfGroup(index, data)) {
    const xPosition = Math.floor(x);

    return <path d={`M${xPosition},${offsetTop}v${height}`} stroke="#DEE0E3" />;
  }
  return null;
};

CustomizedXAxisCompanyDividerTick.propTypes = {
  x: PropTypes.number,
  offsetTop: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default CustomizedXAxisCompanyDividerTick;
