import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import PointPresenter from 'presenters/Company/Dashboard/PointPresenter';

import styles from './CustomizedTooltip.module.css';

export default class CustomizedTooltip extends Component {
  static propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PointPresenter.shape({})).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  generateLineStyles = (strokeDasharray, color) => {
    const classes = cn(styles.line, { [styles.dashed]: isPresent(strokeDasharray) });
    const inlineStyles = { borderColor: color };

    return [classes, inlineStyles];
  };

  renderPointTooltip(point, index, options) {
    const { color, value, dataKey, strokeDasharray, payload } = point;
    const [classes, inlineStyles] = this.generateLineStyles(strokeDasharray, color);
    const option = options[dataKey];

    return (
      <div key={index} className={styles.legendItem}>
        <div className={styles.legendItemLabel}>
          <strong>{option.label}</strong>
          <hr className={classes} style={inlineStyles} />
        </div>
        <div>Date: {PointPresenter[option.dateKey](payload)}</div>
        <div>Count: {value}</div>
      </div>
    );
  }

  render() {
    const { active, payload, options } = this.props;

    if (!active || isBlank(payload)) {
      return null;
    }

    return (
      <div className={styles.customTooltip}>
        {payload.map((point, index) => this.renderPointTooltip(point, index, options))}
      </div>
    );
  }
}
