import Presenter from 'utils/PropTypesPresenter';

import UserPresenter from 'presenters/UserPresenter';
import StatisticPresenter from './UserProductivity/StatisticPresenter';

export default new Presenter(
  {
    statistics: StatisticPresenter.shape(),
    user: UserPresenter.shape(),
  },
  {
    userId(userProductivity) {
      const user = this.user(userProductivity);

      return UserPresenter.id(user);
    },
  },
);
