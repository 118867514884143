import ViewSkinnyProfileConstants from './ViewSkinnyProfileConstants';

const initialState = {
  loading: false,
  profile: null,
  isGdprCustomer: false,
  canRequestFullProfile: false,
  canSeeFullProfileRequestForm: false,
};

function ViewSkinnyProfileReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ViewSkinnyProfileConstants.LOAD_SKINNY_PROFILE_START: {
      return { ...initialState, loading: true };
    }
    case ViewSkinnyProfileConstants.LOAD_SKINNY_PROFILE_SUCCESS: {
      const { profile, isGdprCustomer, canSeeFullProfileRequestForm, canRequestFullProfile } = action;

      return {
        ...state,
        profile,
        isGdprCustomer,
        canRequestFullProfile,
        canSeeFullProfileRequestForm,
        loading: false,
      };
    }
    default:
      return state;
  }
}

export default ViewSkinnyProfileReducer;
