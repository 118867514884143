import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ConfirmationModal from 'containers/UserPanel/components/ConfirmationModal';
import { ConfirmationWrapper } from 'hoc/withConfirmation';

class ButtonWithConfirmation extends Component {
  static propTypes = {
    confirmationMessage: PropTypes.string.isRequired,
  };

  render() {
    const { confirmationMessage, ...props } = this.props;

    return (
      <ConfirmationWrapper
        targetFunctions={{ onClick: confirmationMessage }}
        Modal={ConfirmationModal}
        WrappedComponent={Button}
        {...props}
      />
    );
  }
}

export default ButtonWithConfirmation;
