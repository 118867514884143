import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

export default class KanbanCard extends Component {
  static propTypes = {
    card: PropTypes.shape(),
    component: PropTypes.func,
    className: PropTypes.string,
    index: PropTypes.number,
    onClick: PropTypes.func,
  };

  handleClick = () => {
    const { card, onClick } = this.props;

    onClick(card);
  };

  renderCard = (provided) => {
    const { card, component, className, index } = this.props;

    return (
      <div
        className={className}
        onClick={this.handleClick}
        onKeyDown={this.handleClick}
        role="button"
        tabIndex={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {createElement(component, { index, card })}
      </div>
    );
  };

  render() {
    const { card, index } = this.props;

    return (
      <Draggable key={card.id} draggableId={`${card.id}`} index={index}>
        {(provided) => this.renderCard(provided)}
      </Draggable>
    );
  }
}
