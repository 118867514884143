import keyMirror from 'keymirror';

const UserListConstants = keyMirror({
  INITIALIZE_COMPANY_USER_LIST: null,

  LOAD_COMPANY_USER_START: null,
  LOAD_COMPANY_USER_SUCCESS: null,

  FILTER_COMPANY_USER_START: null,
  FILTER_COMPANY_USER_SUCCESS: null,

  BULK_DESTROY_COMPANY_USER_SUCCESS: null,

  CREATE_NEW_COMPANY_USER_START: null,
  CREATE_NEW_COMPANY_USER_SUCCESS: null,
  CREATE_NEW_COMPANY_USER_ERROR: null,
});

export default UserListConstants;
