const CLASS_NAME = 'colorBorder';
const CIRCLE_WIDTH = 2;

function borderColor(data, props) {
  const { isCustomerExecutive, isCustomerBoardMember } = data;
  const { customerExecutiveColor, strongRelationshipColor, customerBoardMemberColor } = props;

  if (isCustomerBoardMember) {
    return customerBoardMemberColor;
  }

  if (isCustomerExecutive) {
    return customerExecutiveColor;
  }

  return strongRelationshipColor;
}

function mount(nodeGroup, props) {
  nodeGroup
    .append('circle')
    .attr('class', CLASS_NAME)
    .attr('r', props.node.avatarRadius + CIRCLE_WIDTH * 2)
    .style('fill', (d) => borderColor(d, props));
}

export default { mount };
