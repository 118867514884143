import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { imagePath } from 'utils/AssetsHelper';
import { isNil, propEq, update } from 'ramda';

import { useCurrentUserExecutivesMatches } from 'hooks/currentUser';

import { RECOMMENDED_PROFILES_COUNT } from 'presenters/ProjectPresenter';
import ExecutiveMatchPresenter from 'presenters/ExecutiveMatchPresenter';
import SourcePresenter from 'presenters/Project/SourcePresenter';
import { ADD_TO_PROJECT_STATE_EVENT, DO_NOT_REQUEST_STATE_EVENT } from 'presenters/Project/CsvItemPresenter';

import AlertIcon from 'icons/AlertIcon';

import Typography from 'components/v2/Typography';
import Button from 'components/v2/Button';
import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import ExecutivesMatchesReview from 'components/client/ExecutivesMatchesReview';

import styles from './ProjectMatchedExecutivesWizardStep.module.css';

const buildSubtitle = (isLoading, meta) => {
  if (isLoading) {
    return 'We are looking for executives from your CSV file in our database.';
  }

  return (
    <>
      {`We found definite matches for ${meta?.matchedCount} executives from your list. We found possible matches for another ${meta?.possibleMatchedCount} executives.`}
      <br />
      All executives with correct matches will be added to the project.
    </>
  );
};

const ProjectMatchedExecutivesWizardStep = (props) => {
  const { source, updateSource } = props;
  const { csvItemsAttributes } = source;

  const incorrectExecutiveMatchIndexes = csvItemsAttributes
    .map(({ index, stateEvent }) => (stateEvent === ADD_TO_PROJECT_STATE_EVENT ? null : index))
    .filter((index) => !isNil(index));

  const { executivesMatchesMeta, matchExecutives, matchExecutivesStatus } = useCurrentUserExecutivesMatches();
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleDetailsShow = () => setIsDetailsVisible(true);
  const handleDetailsHide = () => setIsDetailsVisible(false);
  const handleExecutivesMatch = async () => {
    const { item: items } = await matchExecutives({ file: source.file, delimiter: source.delimiter });
    const newCsvItemsAttributes = items.map((executivesMatches) => {
      const isMatched = ExecutiveMatchPresenter.isMatchedToProfile(executivesMatches);
      const stateEvent = isMatched ? ADD_TO_PROJECT_STATE_EVENT : DO_NOT_REQUEST_STATE_EVENT;

      return { ...executivesMatches, stateEvent };
    });

    updateSource({ ...source, csvItemsAttributes: newCsvItemsAttributes, isFileProcessed: true });
  };
  const handleExecutiveMatchMarkCorrect = (executive) => {
    const executiveIndex = csvItemsAttributes.findIndex(propEq(executive.index, 'index'));
    const newCsvItemsAttributes = update(
      executiveIndex,
      { ...executive, stateEvent: ADD_TO_PROJECT_STATE_EVENT },
      csvItemsAttributes,
    );

    updateSource({ ...source, csvItemsAttributes: newCsvItemsAttributes });
  };
  const handleExecutiveMatchMarkIncorrect = (executive) => {
    const executiveIndex = csvItemsAttributes.findIndex(propEq(executive.index, 'index'));
    const newCsvItemsAttributes = update(
      executiveIndex,
      { ...executive, stateEvent: DO_NOT_REQUEST_STATE_EVENT },
      csvItemsAttributes,
    );

    updateSource({ ...source, csvItemsAttributes: newCsvItemsAttributes });
  };

  useEffect(() => {
    if (!source.isFileProcessed) {
      handleExecutivesMatch();
    }
  }, []);

  return (
    <div className={styles.root}>
      <WizardTitle subtitle={buildSubtitle(matchExecutivesStatus.isPending, executivesMatchesMeta)} />
      {matchExecutivesStatus.isPending && (
        <div className={styles.loader}>
          <img src={imagePath('project-match-executives-loader.gif')} alt="loading" />
        </div>
      )}
      {matchExecutivesStatus.isFailed && (
        <div className={styles.alert}>
          <AlertIcon color="orange" width={24} height={24} />
          <Typography className={styles.alertMessage} color="orange" variant="h3">
            Please limit your list to {RECOMMENDED_PROFILES_COUNT} records and try again.
          </Typography>
        </div>
      )}
      {matchExecutivesStatus.isFulfilled && (
        <div className={styles.viewDetails}>
          <Button onClick={isDetailsVisible ? handleDetailsHide : handleDetailsShow} variant="link">
            {isDetailsVisible ? 'Hide Details' : 'View Details'}
          </Button>
        </div>
      )}
      {matchExecutivesStatus.isFulfilled && !isNil(executivesMatchesMeta) && isDetailsVisible && (
        <ExecutivesMatchesReview
          executivesMatches={csvItemsAttributes}
          incorrectExecutiveMatchIndexes={incorrectExecutiveMatchIndexes}
          meta={executivesMatchesMeta}
          onExecutiveMatchMarkCorrect={handleExecutiveMatchMarkCorrect}
          onExecutiveMatchMarkIncorrect={handleExecutiveMatchMarkIncorrect}
        />
      )}
      <WizardNavigation isNextButtonDisabled={matchExecutivesStatus.isPending} />
    </div>
  );
};

ProjectMatchedExecutivesWizardStep.propTypes = {
  source: SourcePresenter.shape().isRequired,
  updateSource: PropTypes.func.isRequired,
};

export default ProjectMatchedExecutivesWizardStep;
