import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BackendNotifications from './BackendNotifications';
import * as actions from './BackendNotificationsActions';

const mapStateToProps = (state) => ({
  isPolling: state.BackendNotificationsReducer.isPolling,
  notifications: state.BackendNotificationsReducer.notifications,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BackendNotifications);
