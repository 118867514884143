import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RequestStatisticBlock from './RequestStatisticBlock';
import * as actions from './RequestStatisticBlockActions';

const mapStateToProps = (state) => ({
  loading: state.RequestStatisticBlockReducer.loading,
  statistic: state.RequestStatisticBlockReducer.statistic,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestStatisticBlock);
