import u from 'updeep';
import { findIndex, propEq } from 'ramda';
import PopularOrganizationTranscriptsConstants from './PopularOrganizationTranscriptsConstants';

const initialState = {
  transcripts: [],
  loading: false,
};

function PopularOrganizationTranscriptsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case PopularOrganizationTranscriptsConstants.LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_START: {
      return { ...state, loading: true };
    }

    case PopularOrganizationTranscriptsConstants.LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_SUCCESS: {
      const { items } = action;
      return { ...state, loading: false, transcripts: items };
    }

    case PopularOrganizationTranscriptsConstants.LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_FAILURE: {
      return { ...state, loading: false };
    }

    case PopularOrganizationTranscriptsConstants.CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_START: {
      return { ...state, loading: true };
    }

    case PopularOrganizationTranscriptsConstants.CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_SUCCESS: {
      const { transcript } = action;
      const updatedTranscriptIndex = findIndex(propEq(transcript.id, 'id'), state.transcripts);
      return u({ transcripts: { [updatedTranscriptIndex]: transcript }, loading: false }, state);
    }

    case PopularOrganizationTranscriptsConstants.CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_FAILURE: {
      return { ...state, loading: false };
    }

    default:
      return state;
  }
}

export default PopularOrganizationTranscriptsReducer;
