import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { is } from 'ramda';
import { camelize } from 'utils/keysConverter';

import Link from 'components/v2/Link';
import Logotype from 'components/v2/Logotype';

import styles from './SingleSignOnErrorPage.module.css';

const SingleSignOnErrorPage = (props) => {
  const { location } = props;

  const errorMessage = () => {
    const { errors } = camelize(
      parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    );

    return is(String, errors) ? errors : errors.join(', ');
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Link href="/">
          <Logotype />
        </Link>
      </div>
      <div className={styles.main}>
        <h1 className={styles.text}>Single Sign On Error</h1>
        {errorMessage()}{' '}
      </div>
    </div>
  );
};

SingleSignOnErrorPage.propTypes = {
  location: PropTypes.shape(),
};

export default SingleSignOnErrorPage;
