import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { reject, propEq } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

export const ORGANIZATION_SUBSCRIPTION_CONFIGURATION = 'organization_subscription_configuration';
export const PROFILE_SUBSCRIPTION_CONFIGURATION = 'profile_subscription_configuration';
export const SAVED_SEARCH_SUBSCRIPTION_CONFIGURATION = 'saved_search_subscription_configuration';
export const PROJECT_SUBSCRIPTION_CONFIGURATION = 'project_subscription_configuration';

const FREQUENCY_SELECT_OPTIONS = [
  { value: 'immediately', label: 'Immediately' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
];

const WEEKLY_DAY_SELECT_OPTIONS = [
  { value: 'sunday', label: 'Sunday' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
];

export function getDayOptions(frequency) {
  switch (frequency) {
    case 'weekly':
      return WEEKLY_DAY_SELECT_OPTIONS;
    default:
      return [];
  }
}

export function getDefaultDay(frequency) {
  const [option] = getDayOptions(frequency);

  return isPresent(option) ? option.value : null;
}

export function getFrequencyOptions(kind) {
  if (kind === SAVED_SEARCH_SUBSCRIPTION_CONFIGURATION || kind === PROJECT_SUBSCRIPTION_CONFIGURATION) {
    return reject(propEq('immediately', 'value'), FREQUENCY_SELECT_OPTIONS);
  }

  return FREQUENCY_SELECT_OPTIONS;
}

export default new Presenter(
  {
    kind: PropTypes.string,
    frequency: PropTypes.string,
    day: PropTypes.string,
  },
  {},
);
