import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

const TagPresenter = new Presenter(
  {
    id: PropTypes.number,
    title: PropTypes.string,
    profilesCount: PropTypes.number,
    nestedTags: PropTypes.arrayOf(() => TagPresenter.shape()),
  },
  {},
);

export default TagPresenter;
