import React from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { formattedDateFromSeconds } from 'utils/DateTime';
import { buildTimeAxisTicks } from 'utils/ChartHelper';

import { DAY_TIME_PERIOD_VALUE, MONTH_TIME_PERIOD_VALUE } from 'presenters/TimePeriodPresenter';

import CustomizedTooltip from './components/CustomizedTooltip';

const DOT_COLOR = '#00A0CE';

const DATE_FORMATS = {
  [DAY_TIME_PERIOD_VALUE]: 'MM/dd/yy',
  [MONTH_TIME_PERIOD_VALUE]: 'MMM yyyy',
};

const Chart = (props) => {
  const { data, period, fromDate, toDate } = props;

  const renderDot = ({ cx, cy }) => <circle r={6} stroke="#fff" fill={DOT_COLOR} strokeWidth={2} cx={cx} cy={cy} />;

  const tickFormatter = (tick) => formattedDateFromSeconds(tick, DATE_FORMATS[period]);

  return (
    <ResponsiveContainer height={300}>
      <AreaChart data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="time"
          tickLine={false}
          tickSize={24}
          height={50}
          ticks={buildTimeAxisTicks(fromDate, toDate, period)}
          scale="time"
          domain={['dataMin', 'dataMax']}
          type="number"
          tickFormatter={tickFormatter}
          minTickGap={20}
        />
        <YAxis tickLine={false} tickSize={24} />
        <YAxis yAxisId="right" orientation="right" stroke="#cccccc" />
        <Area
          type="stepAfter"
          dataKey="totalRemainingRequestsNumber"
          stroke="#278A18"
          fill="#ecfbe9"
          activeDot={renderDot}
          isAnimationActive={false}
        />
        <Tooltip cursor={{ stroke: DOT_COLOR }} content={<CustomizedTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  period: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
};

export default Chart;
