import { currentUserCompanyDashboardRequestsRestHooks } from 'slices/currentUser/company/dashboard/requestsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useRequests = () => {
  const { requests, loadRequests, loadMoreRequests } = currentUserCompanyDashboardRequestsRestHooks.use();

  return {
    requests: requests.items,
    requestsLoadingMoreStatus: useFetchStatus(requests.moreLoadingStatus),
    requestsLoadingStatus: useFetchStatus(requests.loadingStatus),
    requestsMeta: requests.meta,
    loadRequests,
    loadMoreRequests,
  };
};

export default useRequests;
