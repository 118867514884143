import keyMirror from 'keymirror';

const CsvItemsTableConstants = keyMirror({
  LOAD_PROJECT_CSV_SOURCE_CSV_ITEMS_START: null,
  LOAD_PROJECT_CSV_SOURCE_CSV_ITEMS_SUCCESS: null,

  UPDATE_PROJECT_CSV_SOURCE_CSV_ITEM_SUCCESS: null,
});

export default CsvItemsTableConstants;
