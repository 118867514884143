import DashboardConstants from './CustomerUsageDashboardConstants';
import DashboardRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboardRepository';
import PublicApiStatisticsRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/PublicApiStatisticsRepository';
import SearchProfilesStatisticsRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/SearchProfilesStatisticsRepository';
import SignInStatisticsRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/SignInStatisticsRepository';
import ViewsAndDownloadsStatisticsRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/ViewsAndDownloadsStatisticsRepository';
import PowerUsersRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/PowerUsersRepository';
import PopularOrganizationsRepository from 'repositories/admin/EditorialDashboard/CustomerUsageDashboard/PopularOrganizationsRepository';
import { decamelize } from 'utils/keysConverter';

export function loadDashboard(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_START, form });

    return DashboardRepository.index(params)
      .then(({ dashboard }) => {
        dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_SUCCESS, dashboard });

        return dashboard;
      })
      .catch((error) => {
        dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_ERROR, error });

        throw error;
      });
  };
}

export function loadPublicApiStatistics(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_PUBLIC_API_STATISTICS_START });

    return PublicApiStatisticsRepository.show(params).then(({ statistics }) => {
      dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_PUBLIC_API_STATISTICS_SUCCESS, statistics, form });

      return statistics;
    });
  };
}

export function loadSearchProfilesStatistics(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SEARCH_PROFILES_STATISTICS_START });

    return SearchProfilesStatisticsRepository.show(params).then(({ statistics }) => {
      dispatch({
        type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SEARCH_PROFILES_STATISTICS_SUCCESS,
        statistics,
        form,
      });

      return statistics;
    });
  };
}

export function loadSignInStatistics(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SIGN_IN_STATISTICS_START });

    return SignInStatisticsRepository.show(params).then(({ statistics }) => {
      dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SIGN_IN_STATISTICS_SUCCESS, statistics, form });

      return statistics;
    });
  };
}

export function loadViewsAndDownloadsStatistics(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_VIEWS_AND_DOWNLOADS_STATISTICS_START });

    return ViewsAndDownloadsStatisticsRepository.show(params).then(({ statistics }) => {
      dispatch({
        type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_VIEWS_AND_DOWNLOADS_STATISTICS_SUCCESS,
        statistics,
        form,
      });

      return statistics;
    });
  };
}

export function loadPowerUsers(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POWER_USERS_START });

    return PowerUsersRepository.index(params).then(({ items }) => {
      dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POWER_USERS_SUCCESS, powerUsers: items });

      return items;
    });
  };
}

export function loadPopularOrganizations(form) {
  const params = decamelize(form);

  return (dispatch) => {
    dispatch({ type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POPULAR_ORGANIZATIONS_START });

    return PopularOrganizationsRepository.index(params).then(({ items }) => {
      dispatch({
        type: DashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POPULAR_ORGANIZATIONS_SUCCESS,
        popularOrganizations: items,
      });

      return items;
    });
  };
}
