import TagPageConstants from './TagPageConstants';
import TagRepository from 'repositories/admin/TagRepository';
import { addNotification } from 'containers/Notifications';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function searchTags(value) {
  return () => {
    const query = buildFilterParams(value);
    return TagRepository.index(query).then((result) => result.items);
  };
}

export function loadTag({ id }) {
  return (dispatch) => {
    dispatch({ type: TagPageConstants.LOAD_TAG_START });

    return TagRepository.show(id)
      .then(({ tag }) => {
        dispatch({ type: TagPageConstants.LOAD_TAG_SUCCESS, tag });
      })
      .catch((response) => {
        dispatch({ type: TagPageConstants.LOAD_TAG_ERROR });
        throw response;
      });
  };
}

export function updateTag(id, params) {
  return (dispatch) => {
    dispatch({ type: TagPageConstants.UPDATE_TAG_START });

    return TagRepository.update(id, params)
      .then(({ tag }) => {
        dispatch({ type: TagPageConstants.UPDATE_TAG_SUCCESS, tag });
        addNotification('Tag was successfully saved', 'normal')(dispatch);
      })
      .catch((json) => {
        dispatch({ type: TagPageConstants.UPDATE_TAG_ERROR });
        throw json;
      });
  };
}
