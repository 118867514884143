import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import SampleProjectsRepository from 'repositories/admin/SampleProjectsRepository';

const restSlice = createRestSlice({
  resource: 'sampleProject',
  repository: SampleProjectsRepository,
  slices: ['loadSampleProjects', 'updateSampleProject'],
});

const initialState = {
  ...restSlice.initialState,
};

const sampleProjectsSlice = createSlice({
  name: 'adminSampleProjects',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminSampleProjectsReducer = sampleProjectsSlice.reducer;

export default sampleProjectsSlice;

export const adminSampleProjectsRestHooks = restSlice.hooks(sampleProjectsSlice, 'adminSampleProjectsReducer');
