import Admin from './Admin';
import Client from './Client';
import External from './External';

export default {
  rootPath: () => '/',

  ...Admin,
  ...Client,
  ...External,
};
