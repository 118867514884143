import { useCallback } from 'react';
import lodashDebounce from 'lodash.debounce';
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_TIME = 400;

function asyncDebounce(func, time = DEBOUNCE_TIME) {
  const wrapFunc = (inputValue, callback) => {
    func(inputValue, callback).then(callback);
  };

  return lodashDebounce(wrapFunc, time);
}

export const useDebounce = (callback, debounceTime = DEBOUNCE_TIME) => useDebouncedCallback(callback, debounceTime);

export const useAsyncDebounce = (func, dependencies, timeout) =>
  useCallback(asyncDebounce(func, timeout), dependencies);
