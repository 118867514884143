function readChunk(file, chunkSize, offset) {
  return new Promise((resolve, reject) => {
    const endOffset = offset + chunkSize > file.size - 1 ? file.size - 1 : offset + chunkSize;
    const reader = new FileReader();

    reader.onloadend = (event) => {
      if (event.target.readyState === FileReader.DONE) {
        const meta = { chunkSize, endOffset, startOffset: offset, result: event.target.result };

        resolve({ data: event.target.result, meta });
      } else {
        reject();
      }
    };

    const blob = file.slice(offset, endOffset);
    reader.readAsText(blob);
  });
}

function readByChunks(file, isCompleted, accamulator, offset = 0) {
  return new Promise((resolve, reject) => {
    readChunk(file, 320, offset)
      .then(({ data, meta }) => {
        if (meta.endOffset >= file.size - 1 || isCompleted(accamulator + data)) {
          return resolve(accamulator + data);
        }

        return readByChunks(file, isCompleted, accamulator + data, meta.endOffset)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });
}

function includeLineBreak(text) {
  const matchLineBreak = /\r|\n/.exec(text);

  return matchLineBreak;
}

export function readLine(file) {
  return readByChunks(file, includeLineBreak, '').then((line) => line.split(/\r\n|\r|\n/g)[0]);
}
