import { buildFilterParams } from 'utils/FilterParamsBuilder';
import TeamProductivityConstants from './TeamProductivityConstants';
import UsersProductivitiesRepository from 'repositories/admin/EditorialDashboard/UsersProductivitiesRepository';

export function loadUsersProductivities(filters) {
  const { requestType, userRole, ...stasticsFilter } = filters;
  const usersFilters = { roleEq: userRole };
  const requestsFilters = { requestTypeEq: requestType };

  return (dispatch) => {
    dispatch({ type: TeamProductivityConstants.LOAD_TEAM_PRODUCTIVITY_START, filters });

    const params = {
      ...buildFilterParams(stasticsFilter, 'q').q,
      ...buildFilterParams(requestsFilters, 'request_q'),
      ...buildFilterParams(usersFilters, 'user_q'),
    };

    return UsersProductivitiesRepository.index(params).then(({ items }) => {
      dispatch({ type: TeamProductivityConstants.LOAD_TEAM_PRODUCTIVITY_SUCCESS, usersProductivities: items, filters });

      return items;
    });
  };
}
