import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propEq } from 'ramda';

import FiltersForm from './components/FiltersForm';
import Select from 'components/Select';
import UserInfo from './components/UserInfo';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';
import ResultModal from './components/ResultModal';
import UserDetailStatistic from './containers/UserDetailStatistic';
import ExportsRepository from 'repositories/CurrentUser/ExportsRepository';

import UserProductivityPresenter from 'presenters/EditorialDashboard/UserProductivityPresenter';
import { ROLES, ADMIN_ROLES } from 'presenters/UserPresenter';
import { REQUESTS_COUNT_VALUE, SUM_STATISTIC } from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';
import { THIS_YEAR_TIMEFRAME_VALUE } from 'presenters/TimeframePresenter';
import { MONTH_TIME_PERIOD_VALUE } from 'presenters/TimePeriodPresenter';

import styles from './TeamProductivity.module.css';

const DEFAULT_FILTERS = {
  userRole: 'editor',
  requestType: 'update_request',
  finalState: 'completed',
  statisticValue: REQUESTS_COUNT_VALUE,
  statistic: SUM_STATISTIC,
  period: MONTH_TIME_PERIOD_VALUE,
  timeframe: THIS_YEAR_TIMEFRAME_VALUE,
  compare: false,
};

const ADMIN_ROLE_OPITONS = ADMIN_ROLES.map((adminRole) => {
  const role = ROLES.find(propEq(adminRole, 'key'));

  return { label: role.title, value: role.key };
});

export default class TeamProductivity extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    loadUsersProductivities: PropTypes.func.isRequired,
    filters: PropTypes.shape({}).isRequired,
    usersProductivities: PropTypes.arrayOf(UserProductivityPresenter.shape()).isRequired,
  };

  state = {
    isUserDetailStatisticModalOpen: false,
    isBackgroundExportResultPopupOpened: false,
    user: null,
    errors: null,
    exportJob: null,
  };

  componentDidMount() {
    this.handleLoadUsersProductivities(DEFAULT_FILTERS);
  }

  handleLoadUsersProductivities = (filters) => {
    const { loadUsersProductivities } = this.props;

    loadUsersProductivities(filters);
  };

  handleFilterChange = (filterName) => (value) => {
    const { filters } = this.props;

    this.handleLoadUsersProductivities({ ...filters, [filterName]: value });
  };

  handleUserDetailStatisticModalOpen = (user) => this.setState({ isUserDetailStatisticModalOpen: true, user });

  handleUserDetailStatisticModalClose = () => this.setState({ isUserDetailStatisticModalOpen: false, user: null });

  handleClosePopup = () => this.setState({ isBackgroundExportResultPopupOpened: false });

  handleExportStatistics = () => {
    const params = {
      type: 'ExportJob::AggregatedUsersProductivitiesStatisticsExportJob',
    };

    ExportsRepository.create(params)
      .then(({ exportJob }) => this.setState({ isBackgroundExportResultPopupOpened: true, exportJob }))
      .catch((errors) => this.setState({ isBackgroundExportResultPopupOpened: true, errors }));
  };

  renderUsersInfo() {
    const { filters, usersProductivities } = this.props;

    return (
      <div className={styles.chartsGrid}>
        {usersProductivities.map((userProductivity) => (
          <div key={UserProductivityPresenter.userId(userProductivity)} className={styles.item}>
            <UserInfo
              user={UserProductivityPresenter.user(userProductivity)}
              statistics={UserProductivityPresenter.statistics(userProductivity)}
              onClick={this.handleUserDetailStatisticModalOpen}
              statisticType={filters.statistic}
              statisticValue={filters.statisticValue}
              requestType={filters.requestType}
              compare={filters.compare}
            />
          </div>
        ))}
      </div>
    );
  }

  renderUserDetailStatisticModal() {
    const { filters } = this.props;
    const { user } = this.state;

    return (
      <Modal
        disableCloseIcon
        disableHeader
        overflow
        size="large"
        className={styles.modal}
        onClose={this.handleUserDetailStatisticModalClose}
      >
        <UserDetailStatistic user={user} initialFilters={filters} />
      </Modal>
    );
  }

  render() {
    const { filters, loading } = this.props;
    const { isUserDetailStatisticModalOpen, isBackgroundExportResultPopupOpened, errors, exportJob } = this.state;

    return (
      <>
        {isBackgroundExportResultPopupOpened && (
          <ResultModal exportJob={exportJob} errors={errors} onClose={this.handleClosePopup} />
        )}
        <div className={styles.root}>
          <FiltersForm
            filters={filters}
            onFilterChange={this.handleFilterChange}
            exportStatistics={this.handleExportStatistics}
          />
          <div className={styles.main}>
            <div className={styles.selectContainer}>
              <div className={styles.teamTitle}>Team</div>
              <div className={styles.teamSelect}>
                <Select
                  options={ADMIN_ROLE_OPITONS}
                  onValueChange={this.handleFilterChange('userRole')}
                  selectedOptionValue={filters.userRole}
                  noBottom
                  classNamePrefix="filter"
                />
              </div>
            </div>
            {loading ? <Spinner /> : this.renderUsersInfo()}
          </div>
        </div>

        {isUserDetailStatisticModalOpen && this.renderUserDetailStatisticModal()}
      </>
    );
  }
}
