import AgingStatisticsConstants from './AgingStatisticsConstants';
import AgingStatisticsRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/AgingStatisticsRepository';

export function loadAgingStatistics() {
  return (dispatch) => {
    dispatch({ type: AgingStatisticsConstants.LOAD_DATABASE_ANALYTICS_AGING_STATISTICS_START });

    return AgingStatisticsRepository.show()
      .then(({ agingStatistics }) => {
        dispatch({ type: AgingStatisticsConstants.LOAD_DATABASE_ANALYTICS_AGING_STATISTICS_SUCCESS, agingStatistics });

        return agingStatistics;
      })
      .catch((error) => {
        dispatch({ type: AgingStatisticsConstants.LOAD_DATABASE_ANALYTICS_AGING_STATISTICS_ERROR, error });

        throw error;
      });
  };
}
