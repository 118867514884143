import React, { Component } from 'react';

import EditorialDashboardPage from './containers/EditorialDashboardPage';
import PageHeader from 'components/PageHeader';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import EditorialDashboardPolicy from 'policies/EditorialDashboardPolicy';

class DashboardPage extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
  };

  render() {
    const { currentUser } = this.props;

    return EditorialDashboardPolicy.canSeeEditorialDashboard(currentUser) ? (
      <EditorialDashboardPage currentUser={currentUser} />
    ) : (
      <PageHeader title={`Hello, ${CurrentUserPresenter.fullName(currentUser)}`} />
    );
  }
}

export default DashboardPage;
