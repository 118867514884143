import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(params) {
    const url = Routes.apiV1ResetPasswordPath();

    return FetchHelpers.post(url, params);
  },
  update(params) {
    const url = Routes.apiV1ResetPasswordPath();

    return FetchHelpers.patch(url, params);
  },
};
