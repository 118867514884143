import { pick } from 'ramda';

export default {
  defaultAttributes() {
    return {
      email: '',
      password: '',
      remember: false,
    };
  },

  attributesToSubmit(params) {
    const propsToSend = ['email', 'password', 'remember'];

    return pick(propsToSend, params);
  },
};
