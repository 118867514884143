import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LanguagePage from './LanguagePage';
import * as actions from './LanguagePageActions';

const mapStateToProps = (state, props) => ({
  language: state.LanguagePageReducer.language,
  loading: state.LanguagePageReducer.loading,
  saving: state.LanguagePageReducer.saving,
  languageId: props.match.params.id,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePage);
