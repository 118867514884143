import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfilesList from './ProfilesList';
import * as actions from './ProfilesListActions';

const mapStateToProps = (state) => ({
  loading: state.ProfilesListReducer.loading,
  profilesWithContexts: state.ProfilesListReducer.profilesWithContexts,
  nextPage: state.ProfilesListReducer.nextPage,
  totalCount: state.ProfilesListReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesList);
