import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export const DAY_TIME_PERIOD_VALUE = 'day';
export const WEEK_TIME_PERIOD_VALUE = 'week';
export const MONTH_TIME_PERIOD_VALUE = 'month';
export const QUARTER_TIME_PERIOD_VALUE = 'quarter';
export const YEAR_TIME_PERIOD_VALUE = 'year';

export const TIME_PERIODS = [
  { value: DAY_TIME_PERIOD_VALUE, label: 'Day' },
  { value: WEEK_TIME_PERIOD_VALUE, label: 'Week' },
  { value: MONTH_TIME_PERIOD_VALUE, label: 'Month' },
  { value: QUARTER_TIME_PERIOD_VALUE, label: 'Quarter' },
  { value: YEAR_TIME_PERIOD_VALUE, label: 'Year' },
];

export default new Presenter(
  {
    value: PropTypes.string,
    label: PropTypes.string,
  },
  {},
);
