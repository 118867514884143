import SubscriptionConfigurationContainerConstants from './SubscriptionConfigurationContainerConstants';
import SubscriptionConfigurationRepository from 'repositories/CurrentUser/SubscriptionConfigurationRepository';

export function loadSubscriptionConfiguration(kind) {
  return (dispatch) => {
    dispatch({ type: SubscriptionConfigurationContainerConstants.LOAD_SUBSCRIPTION_CONFIGURATION_START, kind });

    return SubscriptionConfigurationRepository.show(kind)
      .then(({ subscriptionConfiguration }) => {
        dispatch({
          type: SubscriptionConfigurationContainerConstants.LOAD_SUBSCRIPTION_CONFIGURATION_SUCCESS,
          subscriptionConfiguration,
        });

        return subscriptionConfiguration;
      })
      .catch((errors) => {
        dispatch({
          type: SubscriptionConfigurationContainerConstants.LOAD_SUBSCRIPTION_CONFIGURATION_ERROR,
        });

        throw errors;
      });
  };
}

export function updateSubscriptionConfiguration(kind, configuration) {
  return (dispatch) => {
    dispatch({
      type: SubscriptionConfigurationContainerConstants.UPDATE_SUBSCRIPTION_CONFIGURATION_START,
    });

    return SubscriptionConfigurationRepository.update(kind, {
      ...configuration,
      kind,
    })
      .then(({ subscriptionConfiguration }) => {
        dispatch({
          type: SubscriptionConfigurationContainerConstants.UPDATE_SUBSCRIPTION_CONFIGURATION_SUCCESS,
          subscriptionConfiguration,
        });

        return subscriptionConfiguration;
      })
      .catch((errors) => {
        dispatch({
          type: SubscriptionConfigurationContainerConstants.UPDATE_SUBSCRIPTION_CONFIGURATION_ERROR,
        });

        throw errors;
      });
  };
}
