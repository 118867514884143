import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';
import { isNil, propEq } from 'ramda';

import { useFieldContext } from 'components/v2/Form/components/Field';
import StyledReactSelect from 'components/v2/StyledReactSelect';

const FIELD_VARIANT_TO_INPUT_VARIANT = {
  outlined: 'raw',
  default: 'outlined',
};

const Select = (props) => {
  const { onChange, options, value, variant, ...rest } = props;

  const fieldContext = useFieldContext();
  const selectVariant = variant || FIELD_VARIANT_TO_INPUT_VARIANT[fieldContext?.variant];
  const option = options.find(propEq(value, 'value'));

  const handleChange = (selectedOption) => onChange(selectedOption?.value);

  return (
    <StyledReactSelect
      isFieldChild={!isNil(fieldContext)}
      {...rest}
      onChange={handleChange}
      options={options}
      reactSelectType={ReactSelect}
      value={option}
      variant={selectVariant}
    />
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
};

Select.defaultProps = {
  onChange: () => {},
  value: undefined,
};

export default Select;
