import keyMirror from 'keymirror';

const CustomerUsageDashboardProjectsConstants = keyMirror({
  LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_START: null,
  LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_SUCCESS: null,

  FILTER_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_START: null,
  FILTER_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_SUCCESS: null,
});

export default CustomerUsageDashboardProjectsConstants;
