import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';

import { Anchor } from 'grommet';
import AssignForm from './components/AssignForm';
import Button from 'components/Button';
import { Edit as EditIcon, Trash as TrashIcon } from 'grommet-icons';

import withConfirmation from 'hoc/withConfirmation';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

import Routes from 'routes';

import styles from './Parent.module.css';

class ParentComponent extends Component {
  static propTypes = {
    isEditable: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onParentAssign: PropTypes.func.isRequired,
    onParentUnassign: PropTypes.func.isRequired,
    parent: OrganizationPresenter.shape(),
  };

  state = { isFormOpened: false };

  handleCloseAssignForm = () => this.setState({ isFormOpened: false });

  handleOpenAssignForm = () => this.setState({ isFormOpened: true });

  handleSubmitAssignForm = ({ parent }) => {
    const { onParentAssign } = this.props;

    return onParentAssign(parent).then(this.handleCloseAssignForm);
  };

  handleUnassignParent = () => {
    const { onParentUnassign } = this.props;

    return onParentUnassign();
  };

  renderParent() {
    const { isEditable, isSaving, parent } = this.props;
    const organizationId = OrganizationPresenter.id(parent);
    const organizationName = OrganizationPresenter.nameWithState(parent);

    return (
      <div className={styles.parent}>
        <Anchor
          className={styles.parentLink}
          disabled={OrganizationPresenter.isOutdated(parent)}
          label={organizationName}
          href={Routes.editAdminOrganizationPath(organizationId)}
          target="_blank"
        />
        {isEditable && (
          <>
            <Button className={styles.action} disabled={!isEditable || isSaving} onClick={this.handleOpenAssignForm}>
              <EditIcon />
            </Button>
            <Button className={styles.action} disabled={!isEditable || isSaving} onClick={this.handleUnassignParent}>
              <TrashIcon />
            </Button>
          </>
        )}
      </div>
    );
  }

  render() {
    const { isEditable, isSaving, parent } = this.props;
    const { isFormOpened } = this.state;

    return (
      <div className={styles.root}>
        <h4 className={styles.title}>Organization Parent</h4>
        <div className={styles.info}>
          {isEditable && isBlank(parent) && (
            <Button disabled={isSaving} label="Add Parent" onClick={this.handleOpenAssignForm} primary />
          )}
          {!isEditable && isBlank(parent) && <span className={styles.missing}>No parent have been added yet.</span>}
          {isPresent(parent) && this.renderParent()}
          {isFormOpened && (
            <AssignForm
              form={{ parent }}
              onClose={this.handleCloseAssignForm}
              onSubmit={this.handleSubmitAssignForm}
              isSaving={isSaving}
            />
          )}
        </div>
      </div>
    );
  }
}

const Parent = withConfirmation(ParentComponent, {
  onParentUnassign: 'Are you sure want to unassign parent?',
});

export default Parent;
