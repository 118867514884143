import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Button from 'components/Button';
import { Field, Input } from 'components/v2/Form';
import AsyncSelect from 'components/AsyncSelect';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import CountryPresenter from 'presenters/CountryPresenter';
import StatePresenter from 'presenters/StatePresenter';
import ContactInformationForm from 'forms/organization/ContactInformationForm';
import styles from './EditForm.module.css';

class EditForm extends Component {
  static propTypes = {
    organization: OrganizationPresenter.shape().isRequired,
    onCountrySearch: PropTypes.func.isRequired,
    onStateSearch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { form: ContactInformationForm.defaultAttributes(props.organization), errors: {} };
  }

  handleFieldChange = (field) => (_, event) => {
    const {
      target: { value },
    } = event;

    this.setState({ form: { ...this.state.form, [field]: value } });
  };

  handleStateChange = (state) => {
    const attributes = { state };

    if (state) {
      attributes.country = StatePresenter.country(state);
    }

    this.setState({ form: { ...this.state.form, ...attributes } });
  };

  handleCountryChange = (country) => {
    const { form } = this.state;
    const attributes = { country };

    const stateCountry = form.state && StatePresenter.country(form.state);

    if (country && stateCountry && country.id !== stateCountry.id) {
      attributes.state = null;
    }

    this.setState({ form: { ...form, ...attributes } });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(ContactInformationForm.attributesToSubmit(form)).catch(({ errors }) => this.setState({ errors }));
  };

  handleCountrySearch = reactSelectDebounce(this.props.onCountrySearch);

  handleStateSearch = reactSelectDebounce(this.props.onStateSearch);

  renderTextInput(field, label, placeholder = null) {
    const {
      form: { [field]: value },
      errors,
    } = this.state;
    const error = errors && errors[field];

    return (
      <div className={styles.field}>
        <Field variant="outlined" title={`${label}:`} error={error}>
          <Input value={value} onChange={this.handleFieldChange(field)} placeholder={placeholder} />
        </Field>
      </div>
    );
  }

  render() {
    const { saving } = this.props;
    const { form } = this.state;

    return (
      <>
        <Box margin={{ bottom: 'medium' }} className={styles.inputGroup}>
          {this.renderTextInput('address', 'Address 1')}
          {this.renderTextInput('address2', 'Address 2')}
          {this.renderTextInput('city', 'City')}
          <AsyncSelect
            placeholder="State:"
            loadOptions={this.handleStateSearch}
            defaultOptions
            isClearable
            onChange={this.handleStateChange}
            value={form.state}
            getOptionValue={StatePresenter.id}
            getOptionLabel={StatePresenter.nameWithCountry}
          />
          {this.renderTextInput('zip', 'Zip')}
          <AsyncSelect
            placeholder="Country:"
            loadOptions={this.handleCountrySearch}
            defaultOptions
            isClearable
            onChange={this.handleCountryChange}
            value={form.country}
            getOptionValue={CountryPresenter.id}
            getOptionLabel={CountryPresenter.name}
          />
          {this.renderTextInput('phone', 'Telephone')}
          {this.renderTextInput('url', 'URL', 'http:// or https://')}
        </Box>
        <Box direction="row" width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </>
    );
  }
}

export default EditForm;
