import PhoneNumberConstants from './PhoneNumberConstants';
import PhoneNumberRepository from 'repositories/CurrentUser/PhoneNumberRepository';
import { addNotification } from 'containers/Notifications';

export function updateCurrentUserPhoneNumber(attributes) {
  return (dispatch) => {
    dispatch({ type: PhoneNumberConstants.UPDATE_CURREN_USER_PHONE_NUMBER_START });

    return PhoneNumberRepository.update(attributes)
      .then((data) => {
        dispatch({ type: PhoneNumberConstants.UPDATE_CURREN_USER_PHONE_NUMBER_SUCCESS });

        return data;
      })
      .catch((error) => {
        dispatch({ type: PhoneNumberConstants.UPDATE_CURREN_USER_PHONE_NUMBER_ERROR, error });

        throw error;
      });
  };
}

export function confirmCurrentUserPhoneNumber(attributes) {
  return (dispatch) => {
    dispatch({ type: PhoneNumberConstants.CONFIRM_CURRENT_USER_PHONE_NUMBER_START });

    return PhoneNumberRepository.confirm(attributes)
      .then(({ user }) => {
        dispatch({ type: PhoneNumberConstants.CONFIRM_CURRENT_USER_PHONE_NUMBER_SUCCESS, user });
        addNotification('Phone was successfully updated', 'normal')(dispatch);

        return user;
      })
      .catch((error) => {
        dispatch({ type: PhoneNumberConstants.CONFIRM_CURRENT_USER_PHONE_NUMBER_ERROR, error });

        throw error;
      });
  };
}

export function resendCurrentUserPhoneNumberConfirmation() {
  return () => PhoneNumberRepository.resendConfirmation();
}
