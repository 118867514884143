import CustomerUsageDashboardConstants from './CustomerUsageDashboardConstants';

const initialState = {
  loading: false,
  dashboard: null,
  publicApiStatisticsLoading: false,
  publicApiStatistics: [],
  searchProfilesStatisticsLoading: false,
  searchProfilesStatistics: [],
  signInStatisticsLoading: false,
  signInStatistics: [],
  viewsAndDownloadsStatisticsLoading: false,
  viewsAndDownloadsStatistics: [],
  powerUsersLoading: false,
  powerUsers: [],
  popularOrganizationsLoading: false,
  popularOrganizations: [],
  form: { from: null, to: null, accountType: null },
};

function CustomerUsageDashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_START: {
      const { form } = action;

      return { ...state, loading: true, dashboard: null, form };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_SUCCESS: {
      return { ...state, loading: false, dashboard: action.dashboard };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_ERROR: {
      return { ...state, loading: false };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_PUBLIC_API_STATISTICS_START: {
      return { ...state, publicApiStatisticsLoading: true };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_PUBLIC_API_STATISTICS_SUCCESS: {
      const { statistics } = action;

      return { ...state, publicApiStatisticsLoading: false, publicApiStatistics: statistics };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SEARCH_PROFILES_STATISTICS_START: {
      return { ...state, searchProfilesStatisticsLoading: true };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SEARCH_PROFILES_STATISTICS_SUCCESS: {
      const { statistics } = action;

      return { ...state, searchProfilesStatisticsLoading: false, searchProfilesStatistics: statistics };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SIGN_IN_STATISTICS_START: {
      return { ...state, signInStatisticsLoading: true };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_SIGN_IN_STATISTICS_SUCCESS: {
      const { statistics } = action;

      return { ...state, signInStatisticsLoading: false, signInStatistics: statistics };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_VIEWS_AND_DOWNLOADS_STATISTICS_START: {
      return { ...state, viewsAndDownloadsStatisticsLoading: true };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_VIEWS_AND_DOWNLOADS_STATISTICS_SUCCESS: {
      const { statistics } = action;

      return { ...state, viewsAndDownloadsStatisticsLoading: false, viewsAndDownloadsStatistics: statistics };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POWER_USERS_START: {
      return { ...state, powerUsersLoading: true };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POWER_USERS_SUCCESS: {
      const { powerUsers } = action;

      return { ...state, powerUsersLoading: false, powerUsers };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POPULAR_ORGANIZATIONS_START: {
      return { ...state, popularOrganizationsLoading: true };
    }
    case CustomerUsageDashboardConstants.LOAD_ADMIN_CUSTOMER_USAGE_POPULAR_ORGANIZATIONS_SUCCESS: {
      const { popularOrganizations } = action;

      return { ...state, popularOrganizationsLoading: false, popularOrganizations };
    }
    default:
      return state;
  }
}

export default CustomerUsageDashboardReducer;
