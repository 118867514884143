import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import * as actions from './ResetPasswordActions';

const mapStateToProps = (state) => ({
  form: state.ResetPasswordReducer.form,
  errors: state.ResetPasswordReducer.errors,
  isResetPasswordEmailSent: state.ResetPasswordReducer.isResetPasswordEmailSent,
  saving: state.ResetPasswordReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
