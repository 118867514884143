import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Box, CheckBox } from 'grommet';

import Modal from 'components/Modal';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import CountryEditForm from 'forms/CountryEditForm';
import Select from 'components/Select';

import { WORLD_REGIONS } from 'presenters/CountryPresenter';

const WORLD_REGION_OPTIONS = WORLD_REGIONS.map((r) => ({ label: r.title, value: r.key }));

class NewCountryModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    fields: CountryEditForm.defaultAttributes({}),
    errors: null,
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { fields } = this.state;

    onSave(CountryEditForm.attributesToSubmit(fields)).catch((response) => {
      if (response.errors) {
        this.setState({ errors: response.errors });
      }
    });
  };

  updateField(fieldName, value) {
    this.setState({ fields: { ...this.state.fields, [fieldName]: value } });
  }

  handleChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleCheckBoxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      this.updateField(fieldName, checked);

  handleSelectInputChange = (fieldName) => (value) => this.updateField(fieldName, value);

  renderTextInput(label, fieldName) {
    const { errors } = this.state;

    return (
      <VerticalFormFieldWithBottomErrors label={label} errors={errors && errors[fieldName]} htmlFor={fieldName}>
        <TextInput onChange={this.handleChange(fieldName)} id={fieldName} />
      </VerticalFormFieldWithBottomErrors>
    );
  }

  renderSelectInput(label, fieldName, options) {
    const {
      fields: { [fieldName]: value },
      errors,
    } = this.state;

    return (
      <Select
        errors={errors && errors[fieldName]}
        selectedOptionValue={value}
        onValueChange={this.handleSelectInputChange(fieldName)}
        options={options}
        placeholder="World Region"
      />
    );
  }

  renderCheckBoxInput(label, fieldName) {
    const {
      fields: { [fieldName]: value },
    } = this.state;

    return (
      <VerticalFormFieldWithBottomErrors checkbox>
        <CheckBox label="GDPR" toggle checked={value} onChange={this.handleCheckBoxChange('isGdpr')} />
      </VerticalFormFieldWithBottomErrors>
    );
  }

  render() {
    const { onClose } = this.props;

    return (
      <Modal onClose={onClose} size="medium" header="New Country" overflow>
        {this.renderTextInput('Name:', 'name')}
        {this.renderTextInput('ISO 3166-1 (alpha2)', 'alpha2')}
        {this.renderSelectInput('World Region', 'worldRegion', WORLD_REGION_OPTIONS)}
        {this.renderCheckBoxInput('GDPR', 'isGdpr')}

        <Box direction="row" margin={{ top: 'small' }} justify="center">
          <Button label="Save" onClick={this.handleSave} primary />
        </Box>
      </Modal>
    );
  }
}

export default NewCountryModal;
