import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(projectId, sourceId) {
    const url = Routes.apiV1CurrentUserProjectTargetingSourcePath(projectId, sourceId);

    return JsonFetchHelpers.get(url);
  },

  update(projectId, sourceId, targetingSource) {
    const url = Routes.apiV1CurrentUserProjectTargetingSourcePath(projectId, sourceId);

    return JsonFetchHelpers.patch(url, { targetingSource });
  },
};
