import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isNil } from 'ramda';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';

import Typography from 'components/v2/Typography';

import ProfileSuggestion from './components/ProfileSuggestion';

import styles from './ProfileSuggestions.module.css';

const ProfileSuggestions = (props) => {
  const { children, suggestions } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = ({ currentTarget, relatedTarget }) => {
    const isNestedElementReceiveFocus = !isNil(relatedTarget) && currentTarget.contains(relatedTarget);

    setIsFocused(isNestedElementReceiveFocus);
  };

  const hasSuggestions = suggestions.length > 0;

  return (
    <div onFocus={handleFocus} onBlur={handleBlur}>
      {children}

      {isFocused && hasSuggestions && (
        <div className={styles.profileSuggestions}>
          <Typography className={styles.profileSuggestionsTitle} variant="h4">
            Are you looking for:
          </Typography>
          {suggestions.map((suggestion) => (
            <ProfileSuggestion key={suggestion.id} profile={suggestion} />
          ))}
        </div>
      )}
    </div>
  );
};

ProfileSuggestions.propTypes = {
  children: PropTypes.node.isRequired,
  suggestions: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
};

export default ProfileSuggestions;
