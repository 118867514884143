import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { ReactRouterContextProvider } from 'react-router-polyfill';
import { isBlank } from 'utils/HelperMethods';

import useDetectMobile from 'hooks/useDetectMobile';

const AppRoute = (props) => {
  const {
    component: ChildComponent,
    layout: Layout,
    render,
    sharedContext,
    desktopOnly,
    dummyComponent: DummyComponent,
    ...rest
  } = props;

  const isMobile = useDetectMobile();

  const getChild = (componentProps) => {
    if (desktopOnly && isMobile) {
      return <DummyComponent />;
    }

    if (isBlank(ChildComponent)) {
      return render({ ...componentProps, ...sharedContext });
    }

    return <ChildComponent {...sharedContext} {...componentProps} />;
  };

  const renderRouteChildren = (componentProps) => {
    const child = getChild(componentProps);

    if (isBlank(Layout)) {
      return <ReactRouterContextProvider>{child}</ReactRouterContextProvider>;
    }

    return (
      <Layout {...sharedContext}>
        <ReactRouterContextProvider>{child}</ReactRouterContextProvider>
      </Layout>
    );
  };

  return <Route {...rest} render={renderRouteChildren} />;
};

AppRoute.propTypes = {
  component: PropTypes.elementType,
  exact: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  render: PropTypes.func,
  sensitive: PropTypes.bool,
  strict: PropTypes.bool,
  layout: PropTypes.elementType,
  sharedContext: PropTypes.shape(),
  desktopOnly: PropTypes.bool,
  dummyComponent: PropTypes.elementType,
};

AppRoute.defaultProps = {
  desktopOnly: false,
};

export default AppRoute;
