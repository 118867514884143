import SavedSearchesContainerConstants from './SavedSearchesContainerConstants';
import SavedSearchesRepository from 'repositories/CurrentUser/SavedSearchesRepository';

import { addNotification } from 'containers/Notifications';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

const SAVED_SEARCHES_DEFAULT_FILTERS = { sortFields: [{ field: 'id', value: 'desc' }] };

export function filterSavedSearches({ per, ...filters } = {}) {
  return (dispatch) => {
    dispatch({ type: SavedSearchesContainerConstants.FILTER_CURRENT_USER_SAVED_SEARCHES_START, filters });
    const params = { ...buildFilterParams({ ...SAVED_SEARCHES_DEFAULT_FILTERS, ...filters }), per };

    return SavedSearchesRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: SavedSearchesContainerConstants.FILTER_CURRENT_USER_SAVED_SEARCHES_SUCCESS,
          items,
          meta,
          filters,
        });

        return items;
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchesContainerConstants.FILTER_CURRENT_USER_SAVED_SEARCHES_ERROR });

        throw errors;
      });
  };
}

export function loadSavedSearches({ page, per, ...filters } = {}) {
  return (dispatch) => {
    dispatch({ type: SavedSearchesContainerConstants.LOAD_CURRENT_USER_SAVED_SEARCHES_START });
    const params = { page, per, ...buildFilterParams({ ...SAVED_SEARCHES_DEFAULT_FILTERS, ...filters }) };

    return SavedSearchesRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: SavedSearchesContainerConstants.LOAD_CURRENT_USER_SAVED_SEARCHES_SUCCESS,
          items,
          meta,
          filters,
        });

        return items;
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchesContainerConstants.LOAD_CURRENT_USER_SAVED_SEARCHES_ERROR });

        throw errors;
      });
  };
}

export function deleteSavedSearch(id) {
  return (dispatch) => {
    dispatch({ type: SavedSearchesContainerConstants.DELETE_CURRENT_USER_SAVED_SEARCH_START });

    return SavedSearchesRepository.destroy(id)
      .then(() => {
        dispatch({ type: SavedSearchesContainerConstants.DELETE_CURRENT_USER_SAVED_SEARCH_SUCCESS, id });
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchesContainerConstants.DELETE_CURRENT_USER_SAVED_SEARCH_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw errors;
      });
  };
}

export function createSavedSearch(params) {
  return (dispatch) => {
    dispatch({ type: SavedSearchesContainerConstants.CREATE_CURRENT_USER_SAVED_SEARCH_START });
    return SavedSearchesRepository.create(params)
      .then(({ savedSearch }) => {
        dispatch({ type: SavedSearchesContainerConstants.CREATE_CURRENT_USER_SAVED_SEARCH_SUCCESS });

        return savedSearch;
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchesContainerConstants.CREATE_CURRENT_USER_SAVED_SEARCH_ERROR });

        throw errors;
      });
  };
}

export function updateSavedSearch(id, params) {
  return (dispatch) => {
    dispatch({ type: SavedSearchesContainerConstants.UPDATE_CURRENT_USER_SAVED_SEARCH_START });
    return SavedSearchesRepository.update(id, params)
      .then(({ savedSearch }) => {
        dispatch({ type: SavedSearchesContainerConstants.UPDATE_CURRENT_USER_SAVED_SEARCH_SUCCESS, savedSearch });

        return savedSearch;
      })
      .catch((errors) => {
        dispatch({ type: SavedSearchesContainerConstants.UPDATE_CURRENT_USER_SAVED_SEARCH_ERROR });

        throw errors;
      });
  };
}

export function changeSavedSearch(savedSearch) {
  return { type: SavedSearchesContainerConstants.CHANGE_CURRENT_USER_SAVED_SEARCH, savedSearch };
}
