import RequestForm from 'forms/customer/RequestForm';
import { SOURCES } from 'presenters/RequestPresenter';

export default {
  defaultAttributes(attributes = {}) {
    return {
      requests: [],
      ...attributes,
    };
  },

  attributesToSubmit(attributes) {
    const { requests } = attributes;

    return {
      requests: requests.map((request) => RequestForm.attributesToSubmit({ ...request, source: SOURCES.REQUEST_FORM })),
    };
  },
};
