import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import { Tabs as GTabs } from 'grommet';

import styles from './Tabs.module.css';

const Tabs = (props) => {
  const { className, fullWidth, ...rest } = props;
  const classNames = cn(styles.tabs, { [className]: isPresent(className), [styles.fullWidth]: fullWidth });

  return <GTabs className={classNames} {...rest} />;
};

Tabs.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Tabs.defaultProps = {
  className: null,
  fullWidth: false,
};

export default Tabs;
