import { equals } from 'ramda';
import CountriesListPageConstants from './CountriesListPageConstants';

const initialState = {
  countries: [],
  loading: false,
  filters: {
    nameCont: '',
    sortFields: [],
  },
  nextPage: null,
  totalCount: null,
  saving: false,
};

function CountriesListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CountriesListPageConstants.LOAD_COUNTRIES_START: {
      return { ...state, loading: true };
    }
    case CountriesListPageConstants.LOAD_COUNTRIES_SUCCESS: {
      const { countries, nextPage, totalCount } = action;

      return { ...state, countries: [...state.countries, ...countries], nextPage, totalCount, loading: false };
    }
    case CountriesListPageConstants.FILTER_COUNTRIES_START: {
      const { filters } = action;

      return { ...state, loading: true, filters };
    }
    case CountriesListPageConstants.FILTER_COUNTRIES_SUCCESS: {
      const { countries, filters, nextPage, totalCount } = action;

      if (equals(filters, state.filters)) {
        return { ...state, loading: false, filters, countries, nextPage, totalCount };
      }

      return state;
    }
    case CountriesListPageConstants.CREATE_COUNTRY_START: {
      return { ...state, saving: true };
    }
    case CountriesListPageConstants.CREATE_COUNTRY_SUCCESS: {
      return { ...state, saving: false };
    }
    case CountriesListPageConstants.CREATE_COUNTRY_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default CountriesListPageReducer;
