import NewInterestModalConstants from './NewInterestModalConstants';

const initialState = {
  saving: false,
};

function NewInterestModalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NewInterestModalConstants.CREATE_INTEREST_START: {
      return { ...state, saving: true };
    }
    case NewInterestModalConstants.CREATE_INTEREST_SUCCESS: {
      return { ...state, saving: false };
    }
    case NewInterestModalConstants.CREATE_INTEREST_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default NewInterestModalReducer;
