import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RequestsKanbanBoard from './RequestsKanbanBoard';
import * as actions from './RequestsKanbanBoardActions';

const mapStateToProps = (state) => ({
  columns: state.RequestsKanbanBoardReducer.columns,
  saving: state.RequestsKanbanBoardReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestsKanbanBoard);
