import { includes, propEq } from 'ramda';
import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';

export const NOT_IN_PROJECT_STATE = 'not_in_project';
export const PENDING_STATE = 'pending';
export const CANCELED_STATE = 'canceled';
export const NOT_REQUESTED_STATE = 'not_requested';
export const IN_PROJECT_STATE = 'in_project';

export const STATES = [
  { key: IN_PROJECT_STATE, title: 'Included', color: '#00CE34' },
  { key: PENDING_STATE, title: 'Pending', color: '#F8E71B' },
  { key: CANCELED_STATE, title: 'Canceled', color: '#7ECFE2' },
  { key: NOT_REQUESTED_STATE, title: 'Not requested', color: '#FA652B' },
  { key: NOT_IN_PROJECT_STATE, title: 'Not included', color: '#DBA9EC' },
];

export const ADD_TO_PROJECT_STATE_EVENT = 'add_to_project';
export const REMOVE_FROM_PROJECT_STATE_EVENT = 'remove_from_project';
export const REQUEST_PROFILE_STATE_EVENT = 'request_profile';
export const DO_NOT_REQUEST_STATE_EVENT = 'do_not_request';

export function humanStateName(state) {
  return STATES.find(propEq(state, 'key')).title;
}

export function stateColor(state) {
  return STATES.find(propEq(state, 'key')).color;
}

export default new Presenter(
  {
    id: PropTypes.number,
    index: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    jobTitle: PropTypes.string,
    state: PropTypes.string,
    stateEvents: PropTypes.arrayOf(PropTypes.string),
    profile: PropTypes.oneOfType([ProfileSearchPresenter.shape(), ProfilePresenter.shape()]),
    strongMatch: PropTypes.bool,
  },
  {
    isPending(csvItem) {
      return this.state(csvItem) === PENDING_STATE;
    },

    isCanceled(csvItem) {
      return this.state(csvItem) === CANCELED_STATE;
    },

    isNotRequested(csvItem) {
      return this.state(csvItem) === NOT_REQUESTED_STATE;
    },

    isInProject(csvItem) {
      return this.state(csvItem) === IN_PROJECT_STATE;
    },

    isNotInProject(csvItem) {
      return this.state(csvItem) === NOT_IN_PROJECT_STATE;
    },

    canAttachToProject(csvItem) {
      return includes(ADD_TO_PROJECT_STATE_EVENT, this.stateEvents(csvItem));
    },

    canRemoveFromProject(csvItem) {
      return includes(REMOVE_FROM_PROJECT_STATE_EVENT, this.stateEvents(csvItem));
    },

    canRequest(csvItem) {
      return includes(REQUEST_PROFILE_STATE_EVENT, this.stateEvents(csvItem));
    },
  },
);
