import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Headline.module.css';

const CLASS_ROOT = 'grommetux-headline';

const Headline = (props) => {
  const { align, children, className, margin, size, strong, ...rest } = props;
  const classes = clsx(
    styles[CLASS_ROOT],
    styles[`${CLASS_ROOT}--${size}`],
    styles[`${CLASS_ROOT}--align-${align}`],
    styles[`${CLASS_ROOT}--margin-${margin}`],
    styles[`${CLASS_ROOT}--strong`],
    className,
  );

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  );
};

Headline.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  margin: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  strong: PropTypes.bool,
};

export default Headline;
