import { pick } from 'ramda';
import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter from 'presenters/UserPresenter';

export default {
  needToKeepAssignee(request, user) {
    return (
      !['deliver', 'cancel'].includes(request.stateEvent) &&
      RequestPresenter.isSubjectOrganization(request) &&
      UserPresenter.isResearchEditor(user)
    );
  },

  defaultAttributes(request, user) {
    const assigneeId = this.needToKeepAssignee(request, user) ? request.assignee && request.assignee.id : '';

    return {
      ...request,
      assigneeId,
      assignee: null,
      cancelationConfirmation: '',
    };
  },

  attributesToSubmit(request) {
    const propsToSend = [
      'id',
      'stateEvent',
      'assigneeId',
      'subjectId',
      'cancelationReason',
      'cancelationConfirmation',
      'priority',
    ];

    return pick(propsToSend, request);
  },
};
