import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { compose, sum, map, prop, values, filter, is } from 'ramda';
import { formattedDateFromSeconds } from 'utils/DateTime';

import styles from './Chart.module.css';
import { POINTS_TYPE } from 'presenters/Company/DashboardPresenter';
import CustomizedTooltip from './components/CustomizedTooltip';
import CustomizedLegend from './components/CustomizedLegend';

const DATE_FORMAT = 'dd MMM yyyy';

const PERIOD_TO_LUXON_FORMAT = {
  day: DATE_FORMAT,
  month: 'MMM yyyy',
  year: 'yyyy',
};

const LEGEND_OPTIONS = {
  count: {
    label: 'Current Time Range',
  },
  previousCount: {
    label: 'Previous Time Range',
  },
};

const TOOLTIP_OPTIONS = {
  count: {
    label: 'Current',
    dateKey: 'textTime',
  },
  previousCount: {
    label: 'Previous',
    dateKey: 'previousTextTime',
  },
};

export default class Chart extends Component {
  static propTypes = {
    title: PropTypes.string,
    data: POINTS_TYPE,
    period: PropTypes.string,
    showPreviousIntervalData: PropTypes.bool,
  };

  totalValue() {
    const { data } = this.props;

    return compose(sum, filter(is(Number)), map(prop('count')))(data);
  }

  tickFormatter = (tick) => {
    const { period } = this.props;
    const format = PERIOD_TO_LUXON_FORMAT[period];

    return formattedDateFromSeconds(tick, format);
  };

  ticks() {
    const { data } = this.props;
    const ticks = data.map(prop('time')).reduce((acc, value) => {
      acc[this.tickFormatter(value)] = value;
      return acc;
    }, {});

    return values(ticks);
  }

  render() {
    const { data, title, showPreviousIntervalData } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <span className={styles.value}>{this.totalValue()}</span>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart width={600} height={200} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <Legend content={<CustomizedLegend options={LEGEND_OPTIONS} />} />
              <CartesianGrid strokeDasharray="0" vertical={false} stroke="#ececec" />
              <XAxis
                dataKey="time"
                scale="time"
                type="number"
                domain={['dataMin', 'dataMax']}
                ticks={this.ticks()}
                tickFormatter={this.tickFormatter}
              />
              <YAxis />
              <Tooltip content={<CustomizedTooltip options={TOOLTIP_OPTIONS} />} />
              <Line connectNulls type="monotone" dataKey="count" stroke="#00A0CE" fill="#00A0CE" strokeWidth={3} />
              {showPreviousIntervalData && (
                <Line
                  connectNulls
                  type="monotone"
                  strokeDasharray="5 5"
                  dataKey="previousCount"
                  stroke="#00A0CE"
                  fill="#00A0CE"
                  strokeWidth={3}
                  dot={{ strokeDasharray: '0' }}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
