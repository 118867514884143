import EventsRepository from 'repositories/admin/Organization/EventsRepository';
import TREventsBlockConstants from './TREventsBlockConstants';
import { isPresent } from 'utils/HelperMethods';

export function initializeThomsonReutersEvents() {
  return { type: TREventsBlockConstants.INITIALIZE_THOMSON_REUTERS_EVENTS };
}

export function loadThomsonReutersEvents(organizationId, { page }) {
  return (dispatch) => {
    dispatch({ type: TREventsBlockConstants.LOAD_THOMSON_REUTERS_EVENTS_START });

    const params = { page };

    return EventsRepository.index(organizationId, params)
      .then(({ items, meta }) =>
        dispatch({ type: TREventsBlockConstants.LOAD_THOMSON_REUTERS_EVENTS_SUCCESS, events: items, ...meta }),
      )
      .catch((error) => {
        if (error.isApiNetworkError || isPresent(error.status)) {
          dispatch({ type: TREventsBlockConstants.LOAD_THOMSON_REUTERS_EVENTS_ERROR, error });
          return;
        }

        throw error;
      });
  };
}
