import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Routes from 'routes/App';
import { Box } from 'grommet';
import Spinner from 'components/Spinner';

import CountryPresenter from 'presenters/CountryPresenter';
import EditForm from './components/EditForm';
import PageHeader from 'components/PageHeader';

class CountryPage extends Component {
  static propTypes = {
    history: PropTypes.shape(),
    countryId: PropTypes.string.isRequired,
    country: CountryPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    loadCountry: PropTypes.func.isRequired,
    updateCountry: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadCountry, countryId } = this.props;

    loadCountry({ id: countryId });
  }

  handleSubmit = (country) => {
    const { updateCountry, history, countryId } = this.props;

    return updateCountry(countryId, country).then(() => {
      history.push(Routes.countriesPath());
    });
  };

  render() {
    const { country, loading, saving } = this.props;

    if (loading || !country) {
      return <Spinner />;
    }

    return (
      <Box margin="none">
        <PageHeader title="Edit Country" backTo={Routes.adminCountriesPath()} />
        <Box width="medium">
          <EditForm country={country} saving={saving} onSubmit={this.handleSubmit} />
        </Box>
      </Box>
    );
  }
}

export default CountryPage;
