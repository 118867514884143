import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, pluck, not, isEmpty } from 'ramda';
import { Box } from 'grommet';
import { arrayMove } from 'react-sortable-hoc';

import Button from 'components/Button';
import SortableList, { SortableItem, DragHandle } from 'components/SortableList';
import Icon from 'components/Icon';

import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import TopicPresenter from 'presenters/TopicPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';

import ProfileTopicCard from './components/ProfileTopicCard';
import ProfileTopicFormPopup from './components/ProfileTopicFormPopup';
import OrganizationTopicCard from './components/OrganizationTopicCard';

import styles from './TopicsList.module.css';

class TopicsList extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    topicKind: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(TopicPresenter.shape()).isRequired,
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()),
    formTitle: PropTypes.string.isRequired,
    onSortEnd: PropTypes.func.isRequired,
    onTopicUpdate: PropTypes.func.isRequired,
    onTopicCreate: PropTypes.func.isRequired,
    onTopicDestroy: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    tooltipComponent: PropTypes.func.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
    popoverNode: PropTypes.shape(),
    handleAddHighlight: PropTypes.func,
    displayTags: PropTypes.bool.isRequired,
    canDragPinnedTopic: PropTypes.bool.isRequired,
    onTopicVisibilityUpdate: PropTypes.func.isRequired,
    hiddenUuids: PropTypes.arrayOf(PropTypes.string),
  };

  state = { addNew: false, openedItems: [] };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { topics, onSortEnd } = this.props;
    const reorderedTopics = arrayMove(topics, oldIndex, newIndex);

    onSortEnd(reorderedTopics);
  };

  handleTopicUpdate = (id, topic) => this.props.onTopicUpdate(id, topic);

  handleTopicDestroy = (topic) => this.props.onTopicDestroy(topic.id);

  handleAddNewTopic = () => {
    const { editable } = this.props;

    this.setState({ addNew: editable });
  };

  handleCancelNewTopic = () => {
    this.setState({ addNew: false });
  };

  handleSubmitNewTopic = (topic) => this.props.onTopicCreate(topic).then(() => this.setState({ addNew: false }));

  handleCardShow = (id) => {
    const { openedItems } = this.state;
    this.setState({ openedItems: [...openedItems, id] });
  };

  handleCardHide = (id) => {
    const { openedItems } = this.state;
    const result = openedItems.filter((currentId) => currentId !== id);
    this.setState({ openedItems: result });
  };

  handleShowAll = () => {
    const { topics } = this.props;
    const allIds = pluck('id', topics);

    this.setState({ openedItems: allIds });
  };

  handleHideAll = () => {
    this.setState({ openedItems: [] });
  };

  renderTopicCard(topic, isTopicHidden) {
    const {
      editable,
      jobFunctions,
      formTitle,
      saving,
      tooltipComponent,
      onTagSearch,
      popoverNode,
      handleAddHighlight,
      displayTags,
      onTopicVisibilityUpdate,
    } = this.props;
    const { openedItems } = this.state;

    const collapsed = not(includes(topic.id, openedItems));

    if (TopicPresenter.isProfileTopic(topic)) {
      return (
        <ProfileTopicCard
          editable={editable}
          key={topic.id}
          formTitle={formTitle}
          topic={topic}
          onUpdate={this.handleTopicUpdate}
          onDestroy={this.handleTopicDestroy}
          onTagSearch={onTagSearch}
          saving={saving}
          tooltipComponent={tooltipComponent}
          onCardShow={this.handleCardShow}
          onCardHide={this.handleCardHide}
          collapsed={collapsed}
          enableHighlight={displayTags && editable}
          popoverNode={popoverNode}
          handleAddHighlight={handleAddHighlight}
          displayTags={displayTags}
          onTopicVisibilityUpdate={onTopicVisibilityUpdate}
          hidden={isTopicHidden}
        />
      );
    }

    return (
      <OrganizationTopicCard
        key={topic.id}
        topic={topic}
        jobFunctions={jobFunctions}
        collapsed={collapsed}
        onCardShow={this.handleCardShow}
        onCardHide={this.handleCardHide}
        displayTags={displayTags}
        editable={editable}
        onTopicVisibilityUpdate={onTopicVisibilityUpdate}
        hidden={isTopicHidden}
        saving={saving}
      />
    );
  }

  renderHideAndShowButtons = () => {
    const { topics } = this.props;

    if (isEmpty(topics)) {
      return null;
    }

    return (
      <Box direction="row">
        <Box margin={{ right: 'small' }}>
          <Button label="Show all" onClick={this.handleShowAll} />
        </Box>
        <Box>
          <Button label="Hide all" onClick={this.handleHideAll} />
        </Box>
      </Box>
    );
  };

  isDraggableTopic = (topic) => {
    const { canDragPinnedTopic, editable } = this.props;

    if (TopicPresenter.pinned(topic)) {
      return canDragPinnedTopic;
    }

    return editable;
  };

  render() {
    const {
      editable,
      formTitle,
      saving,
      isChanged,
      onReviewChanges,
      topics,
      topicKind,
      onTagSearch,
      displayTags,
      hiddenUuids,
    } = this.props;

    const { addNew } = this.state;

    return (
      <>
        <Box direction="row" justify="between" margin={{ bottom: 'medium' }}>
          <HeadlineWithReviewChangesLink
            strong
            size="small"
            margin="none"
            title={formTitle}
            isChanged={isChanged}
            onReviewChanges={onReviewChanges}
          />
          {this.renderHideAndShowButtons()}
        </Box>
        <div className={styles.list}>
          <SortableList onSortEnd={this.handleSortEnd} useDragHandle>
            {topics.map((topic, index) => {
              const isTopicHidden = TopicPresenter.isHidden(topic, hiddenUuids);

              return (
                <SortableItem key={topic.id} index={index} disabled={!editable || isTopicHidden}>
                  <Box direction="row" margin={{ bottom: 'small' }} className={styles.box}>
                    {this.renderTopicCard(topic, isTopicHidden)}
                    {this.isDraggableTopic(topic) && (
                      <DragHandle className={styles.dragArea}>
                        <Icon name="drag" style={{ opacity: isTopicHidden ? 0.5 : 1 }} />
                      </DragHandle>
                    )}
                  </Box>
                </SortableItem>
              );
            })}
          </SortableList>
        </div>

        <Box direction="row">
          <Button label="Add New" primary disabled={!editable} onClick={this.handleAddNewTopic} />
        </Box>
        {addNew && (
          <ProfileTopicFormPopup
            topic={{ kind: topicKind }}
            title={formTitle}
            onSubmit={this.handleSubmitNewTopic}
            onTagSearch={onTagSearch}
            onCancel={this.handleCancelNewTopic}
            saving={saving}
            displayTags={displayTags}
          />
        )}
      </>
    );
  }
}

export default TopicsList;
