import { clientRequestSuggestionsRestHooks } from 'slices/client/requestSuggestionsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useRequestSuggestions = () => {
  const { requestSuggestions, loadRequestSuggestions } = clientRequestSuggestionsRestHooks.use();

  return {
    requestSuggestions: requestSuggestions.items,
    requestSuggestionsLoadingStatus: useFetchStatus(requestSuggestions.loadingStatus),
    requestSuggestionsMeta: requestSuggestions.meta,
    loadRequestSuggestions,
  };
};

export default useRequestSuggestions;
