import CompanyUserRegistrationConstants from './CompanyUserRegistrationConstants';
import CompanyRepository from 'repositories/CompanyRepository';
import CompanyDepartmentUsersRepository from 'repositories/Company/Department/UsersRepository';

export function loadCompany(companyId) {
  return (dispatch) => {
    dispatch({ type: CompanyUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_START });

    return CompanyRepository.show(companyId)
      .then(({ company }) => {
        dispatch({
          type: CompanyUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_SUCCESS,
          company,
        });

        return company;
      })
      .catch((error) => {
        dispatch({ type: CompanyUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_ERROR });

        throw error;
      });
  };
}

export function createCompanyDepartmentUser(companyId, departmentId, params) {
  return (dispatch) => {
    dispatch({ type: CompanyUserRegistrationConstants.CREATE_COMPANY_USER_START });

    return CompanyDepartmentUsersRepository.create(companyId, departmentId, params)
      .then(({ user, meta }) => {
        dispatch({ type: CompanyUserRegistrationConstants.CREATE_COMPANY_USER_SUCCESS, user });

        return { user, meta };
      })
      .catch((error) => {
        dispatch({ type: CompanyUserRegistrationConstants.CREATE_COMPANY_USER_ERROR });

        throw error;
      });
  };
}
