import AudienceInsightsConstants from './AudienceInsightsConstants';
import AudienceInsightsRepository from 'repositories/AudienceInsightsRepository';

export function loadAudienceInsights(projectId, params = {}) {
  return (dispatch) => {
    dispatch({ type: AudienceInsightsConstants.LOAD_PROJECT_AUDIENCE_INSIGHTS_START });

    return AudienceInsightsRepository.show(projectId, params).then(({ audienceInsight }) => {
      dispatch({
        type: AudienceInsightsConstants.LOAD_PROJECT_AUDIENCE_INSIGHTS_SUCCESS,
        audienceInsight,
      });

      return audienceInsight;
    });
  };
}
