import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent, debounce } from 'utils/HelperMethods';

import CsvItem from './components/CsvItem';
import FiltersForm from './components/FiltersForm';
import Pagination from 'containers/UserPanel/components/Pagination';
import Spinner from 'components/Spinner';

import ProjectPresenter from 'presenters/ProjectPresenter';
import SourcePresenter from 'presenters/Project/SourcePresenter';
import CsvItemPresenter from 'presenters/Project/CsvItemPresenter';

import styles from './CsvItemsTable.module.css';

const PER_PAGE = 10;

export default class CsvItemsTable extends Component {
  static propTypes = {
    project: ProjectPresenter.shape().isRequired,
    source: SourcePresenter.shape().isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    csvItems: PropTypes.arrayOf(CsvItemPresenter.shape()).isRequired,
    loading: PropTypes.bool.isRequired,
    loadCsvItems: PropTypes.func.isRequired,
    updateCsvItem: PropTypes.func.isRequired,
    onRemainingRequestsCountChange: PropTypes.func.isRequired,
  };

  state = { filters: { stateIn: [], searchFieldCont: '' } };

  componentDidMount() {
    this.handlePageChange(1);
  }

  handleLoadCsvItems = ({ per = PER_PAGE, ...params }) => {
    const { project, source, loadCsvItems } = this.props;

    loadCsvItems(ProjectPresenter.id(project), SourcePresenter.id(source), { per, ...params });
  };

  handleLoadCsvItemsDebounced = debounce(this.handleLoadCsvItems);

  handlePageChange = (page) => {
    const { filters } = this.state;

    this.handleLoadCsvItemsDebounced({ page, ...filters });
  };

  handleCsvItemUpdate = (csvItem) => {
    const { updateCsvItem, project, source, onRemainingRequestsCountChange } = this.props;

    return updateCsvItem(ProjectPresenter.id(project), SourcePresenter.id(source), csvItem.id, csvItem).then(
      ({ meta: { remainingRequestsCount } }) => onRemainingRequestsCountChange(remainingRequestsCount),
    );
  };

  handleChangeFilters = (filters) => {
    this.setState({ ...this.state, filters });

    this.handleLoadCsvItemsDebounced({ page: 1, ...filters });
  };

  render() {
    const { csvItems, currentPage, totalCount, loading } = this.props;
    const { filters } = this.state;

    return (
      <div>
        <FiltersForm filters={filters} onChange={this.handleChangeFilters} />
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '55px' }} />
              <th style={{ width: '140px', paddingRight: '40px' }}>First name</th>
              <th style={{ width: '140px', paddingRight: '40px' }}>Last name</th>
              <th style={{ width: '140px', paddingRight: '40px' }}>Title</th>
              <th style={{ width: '140px', paddingRight: '40px' }}>Company</th>
              <th style={{ width: '140px' }}>Group Member</th>
              <th style={{ width: '140px' }}>Request</th>
            </tr>
          </thead>
          <tbody>
            {csvItems.map((csvItem) => (
              <CsvItem key={CsvItemPresenter.id(csvItem)} csvItem={csvItem} onUpdate={this.handleCsvItemUpdate} />
            ))}
            {loading && (
              <tr>
                <td colSpan={7}>
                  <Spinner />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isPresent(currentPage) && isPresent(totalCount) && (
          <div className={styles.pagination}>
            <Pagination
              currentPage={currentPage}
              perPage={PER_PAGE}
              totalCount={totalCount}
              onPageChange={this.handlePageChange}
            />
          </div>
        )}
      </div>
    );
  }
}
