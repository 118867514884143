import PropTypes from 'prop-types';

import Presenter from '../utils/PropTypesPresenter';

export default new Presenter({
  id: PropTypes.number,
  hideEmail: PropTypes.bool,
  hidePhone: PropTypes.bool,
  hideAddress: PropTypes.bool,
});
