import PropTypes from 'prop-types';
import React from 'react';
import { propOr } from 'ramda';
import config from 'config';

import CsvColumnInformation from 'components/v2/CsvColumnInformation';
import Link from 'components/v2/Link';
import Typography from 'components/v2/Typography';

import exampleImg from './images/example.png';

import styles from './Instruction.module.css';

const Instruction = (props) => {
  const { meta } = props;
  const existedInFileColumnTitles = propOr([], 'existedInFileColumns', meta);
  const missedInFileRequiredColumnTitles = propOr([], 'missedInFileRequiredColumns', meta);

  const columnVariant = (columnName) => {
    if (missedInFileRequiredColumnTitles.includes(columnName)) {
      return 'missing';
    }

    if (existedInFileColumnTitles.includes(columnName)) {
      return 'exists';
    }

    return 'idle';
  };

  return (
    <div>
      <Typography className={styles.header} variant="h2">
        CSV file format requirements:
      </Typography>
      <div className={styles.information}>
        <div>
          <Typography className={styles.details} variant="h3">
            Column headers are REQUIRED in Row 1 of the CSV file and MUST be in the following order:
          </Typography>
          <ul className={styles.fileColumnsInformation}>
            <li>
              <CsvColumnInformation name="First Name" isRequired variant={columnVariant('first_name')} />
            </li>
            <li>
              <CsvColumnInformation name="Last Name" isRequired variant={columnVariant('last_name')} />
            </li>
            <li>
              <CsvColumnInformation name="Title" isRequired variant={columnVariant('title')} />
            </li>
            <li>
              <CsvColumnInformation name="Company Name" isRequired variant={columnVariant('company_name')} />
            </li>
          </ul>
          <div>
            Please download this{' '}
            <Link color="primary" href="/static/csv/example.csv">
              CSV template
            </Link>{' '}
            to get started. <br /> Need help?{' '}
            <Link color="primary" href={`mailto:${config.app.customerSupportEmail}`}>
              {config.app.customerSupportEmail}
            </Link>
          </div>
        </div>
        <div>
          <div className={styles.example}>
            <Typography className={styles.exampleTitle} variant="h3">
              Example of Your File
            </Typography>
            <img alt="example" src={exampleImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

Instruction.propTypes = {
  meta: PropTypes.shape(),
};

Instruction.defaultProps = {
  meta: null,
};

export default Instruction;
