import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { sanitizeHtml } from 'utils/HTMLHelpers';

const RawHtml = (props) => {
  const { additionalWhitelistedTags, component: Component, innerHTML, ...restProps } = props;

  const sanitizedHtml = useMemo(() => sanitizeHtml(innerHTML, { additionalWhitelistedTags }), [innerHTML]);

  return <Component {...restProps} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

RawHtml.propTypes = {
  component: PropTypes.elementType,
  innerHTML: PropTypes.string.isRequired,
  additionalWhitelistedTags: PropTypes.arrayOf(PropTypes.string),
};

RawHtml.defaultProps = {
  component: 'div',
  additionalWhitelistedTags: [],
};

export default RawHtml;
