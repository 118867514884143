import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { Anchor, Box, TextInput } from 'grommet';

import { isPresent } from 'utils/HelperMethods';
import { fromISO } from 'utils/DateTime';
import { formatPhoneNumberIntl } from 'utils/Phone';

import Modal from 'containers/UserPanel/components/Modal';
import Button from 'components/Button';
import PhoneInput from 'components/PhoneInput';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import styles from './ChangePhoneNumberModal.module.css';

const VERIFICATION_CODE_TIMEOUT = 60 * 1000;

export default class ChangePhoneNumberModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onResendConfirmation: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  state = { form: { unconfirmedPhone: '+1', verificationCode: '' }, errors: null, verificationMeta: null };

  handleFieldChange = (fieldName) => (value) =>
    this.setState({ ...this.state, form: { ...this.state.form, [fieldName]: value } });

  handleInputChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFieldChange(fieldName)(value);

  handleUpdate = () => {
    const { onUpdate } = this.props;
    const { form } = this.state;

    onUpdate(form)
      .then(({ verificationCode }) => {
        this.setState({ ...this.state, verificationMeta: verificationCode, errors: null });
      })
      .catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    const { form } = this.state;

    onConfirm(form).catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  handleResendVerificationCode = () => {
    const { onResendConfirmation } = this.props;

    onResendConfirmation().then(({ verificationCode }) =>
      this.setState({ ...this.state, verificationMeta: verificationCode }),
    );
  };

  renderNewPhoneNumberStep() {
    const { form, errors } = this.state;
    const { saving } = this.props;

    return (
      <>
        <div className={styles.description}>
          <p> Enter your new phone number and we&#39;ll text you a verification code.</p>
        </div>
        <VerticalFormFieldWithBottomErrors
          label="New Phone Number:"
          errors={errors && errors.unconfirmedPhone}
          htmlFor="unconfirmedPhone"
        >
          <PhoneInput
            defaultCountry="US"
            value={form.unconfirmedPhone}
            onChange={this.handleFieldChange('unconfirmedPhone')}
            id="unconfirmedPhone"
          />
        </VerticalFormFieldWithBottomErrors>
        <Box direction="row" justify="center" className={styles.action}>
          <Button label="Send Verification Code" onClick={this.handleUpdate} primary disabled={saving} />
        </Box>
      </>
    );
  }

  renderCountdown = ({ seconds, completed }) => {
    if (completed) {
      return (
        <Box margin="small">
          <span>
            {`Click `}
            <Anchor
              className={styles.resendVerificationCodeLink}
              label="here"
              onClick={this.handleResendVerificationCode}
            />
            {` to request new verification code`}
          </span>
        </Box>
      );
    }

    return (
      <Box margin="small">
        <span>{`Did not recived the verification code? You will be able to request new in a ${seconds} seconds`}</span>
      </Box>
    );
  };

  renderConfirmPhoneNumberStep() {
    const { form, errors, verificationMeta } = this.state;
    const { saving } = this.props;

    return (
      <>
        <div className={styles.description}>
          <p>
            A text message with a 6-digit verification code was just sent to
            <span className={styles.phoneNumber}>{formatPhoneNumberIntl(form.unconfirmedPhone)}</span>
          </p>
        </div>
        <VerticalFormFieldWithBottomErrors
          label="Verification Code:"
          errors={errors && errors.verificationCode}
          htmlFor="verificationCode"
        >
          <TextInput
            value={form.verificationCode}
            onChange={this.handleInputChange('verificationCode')}
            id="verificationCode"
          />
        </VerticalFormFieldWithBottomErrors>
        <Countdown
          key={verificationMeta.codeSentAt}
          date={fromISO(verificationMeta.codeSentAt) + VERIFICATION_CODE_TIMEOUT}
          renderer={this.renderCountdown}
        />
        <Box direction="row" justify="center" className={styles.action}>
          <Button label="Confirm Phone Number" onClick={this.handleConfirm} primary disabled={saving} />
        </Box>
      </>
    );
  }

  render() {
    const { onClose } = this.props;
    const { verificationMeta } = this.state;

    return (
      <Modal className={styles.modal} onClose={onClose}>
        <div className={styles.content}>
          <h3 className={styles.title}>Change Phone Number</h3>
          {isPresent(verificationMeta) ? this.renderConfirmPhoneNumberStep() : this.renderNewPhoneNumberStep()}
        </div>
      </Modal>
    );
  }
}
