import * as d3 from 'd3';
import { fixna } from 'utils/d3Utils';
import { intersection, prop } from 'ramda';

const LINK_CLASS_NAME = 'link';

const lineThinkness = d3.scaleLinear([0, 1], [1, 10]);
const lineColorScale = (strongRelationshipThreshold, weakRelationoshipsColor, strongRelationshipColor) =>
  d3.scaleThreshold([strongRelationshipThreshold], [weakRelationoshipsColor, strongRelationshipColor]);

function lineColor(data, { strongRelationshipThreshold, weakRelationoshipsColor, strongRelationshipColor }) {
  const { strength, source, target } = data;
  const sourceCurrentOrganizationIds = source.currentWorks.map(prop('organizationId'));
  const targetCurrentOrganizationIds = target.currentWorks.map(prop('organizationId'));

  if (intersection(sourceCurrentOrganizationIds, targetCurrentOrganizationIds).length > 0) {
    return weakRelationoshipsColor;
  }

  return lineColorScale(strongRelationshipThreshold, weakRelationoshipsColor, strongRelationshipColor)(strength);
}

function selectAll(linksGroupSelection) {
  return linksGroupSelection.selectAll(`line.${LINK_CLASS_NAME}`);
}

function mount(selection, props) {
  return selection
    .append('line')
    .attr('class', LINK_CLASS_NAME)
    .attr('stroke', (data) => lineColor(data, props))
    .attr('stroke-width', ({ strength }) => lineThinkness(strength))
    .attr('filter', (d) => (d.source.isVisible && d.target.isVisible ? null : 'url(#blur)'));
}

function move(selection) {
  selection
    .attr('x1', (d) => fixna(d.source.x))
    .attr('y1', (d) => fixna(d.source.y))
    .attr('x2', (d) => fixna(d.target.x))
    .attr('y2', (d) => fixna(d.target.y));
}

export default { mount, move, selectAll };
