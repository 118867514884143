import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CountriesListPage from './CountriesListPage';
import * as actions from './CountriesListPageActions';

const mapStateToProps = (state) => ({
  loading: state.CountriesListPageReducer.loading,
  filters: state.CountriesListPageReducer.filters,
  nextPage: state.CountriesListPageReducer.nextPage,
  totalCount: state.CountriesListPageReducer.totalCount,
  countries: state.CountriesListPageReducer.countries,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CountriesListPage);
