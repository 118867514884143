import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';
import { Field, Input } from 'components/v2/Form';

import ApiV2AccessSettingsPresenter from 'presenters/Company/Department/ApiV2AccessSettingsPresenter';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const { errors, onSubmit, isSubmitting, onSecretReset, values: apiV2AccessSettings } = props;

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Credentials</Typography>
      <Field title="Client ID" variant="outlined" error={errors.uid}>
        <Input disabled value={apiV2AccessSettings.uid} />
      </Field>
      <Field title="Secret" variant="outlined" error={errors.secret}>
        <Input disabled value={apiV2AccessSettings.secret} />
      </Field>
      <Button
        className={styles.resetButton}
        disabled={!apiV2AccessSettings.isPersisted}
        onClick={onSecretReset}
        variant="link"
      >
        Reset
      </Button>
      <Button disabled={isSubmitting} onClick={onSubmit}>
        {apiV2AccessSettings.isPersisted ? 'Save' : 'Create'}
      </Button>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSecretReset: PropTypes.func.isRequired,
  values: ApiV2AccessSettingsPresenter.shape().isRequired,
};

export default EditForm;
