import keyMirror from 'keymirror';

const AvailableProfilesAndColleaguesContainerConstants = keyMirror({
  FILTER_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_START: null,
  FILTER_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_SUCCESS: null,

  LOAD_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_START: null,
  LOAD_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_SUCCESS: null,
});

export default AvailableProfilesAndColleaguesContainerConstants;
