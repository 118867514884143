import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { matchPath, withRouter } from 'react-router-dom';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { buildTargetItemCriterias } from 'utils/Project/TargetItemHelper';

import RequestDemo from './components/RequestDemo';
import MappingInformation from './components/MappingInformation';
import Header from './components/Header';
import RelationshipsMappingLegend from 'containers/UserPanel/components/RelationshipsMappingLegend';
import RelationshipsMappingGraph from 'containers/UserPanel/components/RelationshipsMappingGraph';
import PageOverlay from 'containers/UserPanel/components/PageOverlay';
import Promotion from './components/Promotion';
import ProfileRelationshipsList from './containers/ProfileRelationshipsList';
import ProjectCreateFormModal from 'containers/UserPanel/components/DatabaseSearchProjectCreateFormModal';
import Sidebar from 'containers/UserPanel/components/Sidebar';
import Loader from 'components/v2/Loader';

import styles from './RelationshipsMappingPage.module.css';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import RelationshipsMappingPresenter from 'presenters/RelationshipsMappingPresenter';
import { INCLUSION_KIND } from 'presenters/Project/TargetItemPresenter';
import { TARGETING_SOURCE_TYPE, DATABASE_SEARCH_KIND } from 'presenters/Project/SourcePresenter';

import ProfilePolicy from 'policies/ProfilePolicy';

import TargetItemForm from 'forms/project/TargetItemForm';
import SourceForm from 'forms/project/SourceForm';
import NewProjectForm from 'forms/NewProjectForm';

import Routes from 'routes';

class RelationshipsMappingPageComponent extends Component {
  constructor(...args) {
    super(...args);

    const { location } = this.props;
    const match = matchPath(location.pathname, {
      path: Routes.profileRelationshipsMappingRelationshipPath(':slug', ':anotherProfileId'),
      exact: true,
    });

    this.state = {
      isSidebarOpened: isPresent(match),
      isPromotionVisible: true,
      isProjectCreateFormModalOpened: false,
      anotherProfileId: match && Number(match.params.anotherProfileId),
      sidebarTitle: null,
    };
  }

  componentDidMount() {
    const { profile, loadRelationshipsMapping } = this.props;
    const profileId = ProfilePresenter.id(profile);

    loadRelationshipsMapping(profileId);
  }

  breadcrumbs() {
    const { profile } = this.props;
    const profileFullName = ProfilePresenter.fullName(profile);
    const profileSlug = ProfilePresenter.slug(profile);

    return [{ name: profileFullName, to: Routes.profilePath(profileSlug) }];
  }

  handleSidebarOpen = (anotherProfile) => {
    if (!anotherProfile.isVisible) {
      return;
    }

    const { profile, history } = this.props;
    const profileSlug = ProfilePresenter.slug(profile);
    const anotherProfileId = ProfileSearchPresenter.id(anotherProfile);
    const sidebarTitle = ProfileSearchPresenter.fullName(anotherProfile);
    const path = Routes.profileRelationshipsMappingRelationshipPath(profileSlug, anotherProfileId);

    this.setState({ isSidebarOpened: true, anotherProfileId, sidebarTitle });
    history.push(path);
  };

  handleSidebarClose = () => {
    const { profile, history } = this.props;
    const profileSlug = ProfilePresenter.slug(profile);
    const path = Routes.profileRelationshipsMappingPath(profileSlug);

    this.setState({ isSidebarOpened: false, anotherProfileId: null, sidebarTitle: null });
    history.push(path);
  };

  handlePromotionClose = () => this.setState({ ...this.state, isPromotionVisible: false });

  handleProjectCreateFormModalOpen = () => this.setState({ ...this.state, isProjectCreateFormModalOpened: true });

  handleProjectCreateFormModalClose = () => this.setState({ ...this.state, isProjectCreateFormModalOpened: false });

  handleProjectCreate = (params) => {
    const { createProject, history } = this.props;
    const attributes = NewProjectForm.attributesToSubmit(params);

    return createProject(attributes).then((project) => {
      const path = Routes.projectPath(project.id);

      history.push(path);
    });
  };

  buildProjectAttributes = () => {
    const { relationshipsMapping, profile } = this.props;
    const { nodes } = relationshipsMapping;
    const criterias = buildTargetItemCriterias({ fullName: nodes.map(ProfileSearchPresenter.fullName) });
    const targetItem = TargetItemForm.defaultAttributes({ kind: INCLUSION_KIND, criterias });
    const sourceItem = SourceForm.defaultAttributes({
      kind: DATABASE_SEARCH_KIND,
      type: TARGETING_SOURCE_TYPE,
      targetItemsAttributes: [targetItem],
    });

    return NewProjectForm.defaultAttributes({
      name: `${ProfilePresenter.fullName(profile)} connections`,
      sourcesAttributes: [sourceItem],
    });
  };

  renderRelationshipsMapping() {
    const { loading, relationshipsMapping } = this.props;
    const { anotherProfileId } = this.state;

    if (loading || isBlank(relationshipsMapping)) {
      return <Loader />;
    }

    return (
      <div className={styles.main}>
        <div className={styles.sidebarLegend}>
          <RelationshipsMappingLegend />
          <div className={styles.separator} />
          <div className={styles.callToAction}>Click on the map for details</div>
        </div>

        <div className={styles.graph}>
          <RelationshipsMappingGraph
            onNodeClick={this.handleSidebarOpen}
            mapping={relationshipsMapping}
            showCallToActionLegend
            showOrganizationName
            zoomedNodeId={anotherProfileId}
          />
        </div>
      </div>
    );
  }

  renderPromotion() {
    const { currentUser, information, relationshipsMapping } = this.props;

    if (ProfilePolicy.canSeeRelationshipsMappingPromotionBanner(currentUser)) {
      return (
        <Promotion onClose={this.handlePromotionClose}>
          <RequestDemo />
        </Promotion>
      );
    }

    if (isPresent(relationshipsMapping) && ProfilePolicy.canSeeRelationshipsMappingInfo(currentUser)) {
      return (
        <Promotion onClose={this.handlePromotionClose}>
          <MappingInformation information={information} onProjectCreate={this.handleProjectCreateFormModalOpen} />
        </Promotion>
      );
    }

    return null;
  }

  render() {
    const { profile, saving } = this.props;
    const { anotherProfileId, isPromotionVisible, isProjectCreateFormModalOpened, isSidebarOpened, sidebarTitle } =
      this.state;

    const profileId = ProfilePresenter.id(profile);
    const pageOverlayClassName = cn(styles.pageOverlay, { [styles.hidden]: isProjectCreateFormModalOpened });

    return (
      <div className={styles.root}>
        <Header breadcrumbs={this.breadcrumbs()} title="Relationship Mapping" />
        {this.renderRelationshipsMapping()}
        {isSidebarOpened && (
          <PageOverlay className={pageOverlayClassName} dark>
            {isPromotionVisible && this.renderPromotion()}
            <Sidebar onClose={this.handleSidebarClose}>
              <ProfileRelationshipsList
                key={anotherProfileId}
                profileId={profileId}
                anotherProfileId={anotherProfileId}
                title={sidebarTitle}
              />
            </Sidebar>
          </PageOverlay>
        )}
        {isProjectCreateFormModalOpened && (
          <ProjectCreateFormModal
            onClose={this.handleProjectCreateFormModalClose}
            onSubmit={this.handleProjectCreate}
            isSaving={saving}
            defaultAttributes={this.buildProjectAttributes()}
          />
        )}
      </div>
    );
  }
}

const RelationshipsMappingPage = withRouter(RelationshipsMappingPageComponent);

RelationshipsMappingPageComponent.propTypes = {
  createProject: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape(),
  information: PropTypes.shape(),
  profile: ProfilePresenter.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  loadRelationshipsMapping: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  relationshipsMapping: RelationshipsMappingPresenter.shape(),
  saving: PropTypes.bool.isRequired,
};

export default RelationshipsMappingPage;
