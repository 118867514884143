import { pick, isNil, defaultTo } from 'ramda';

import { INCLUSION_KIND, EXCLUSION_KIND, EXECUTIVES_SCOPE } from 'presenters/Project/TargetItemPresenter';

let targetItemId = Date.now();

const buildTargetItemScope = (attributes) => {
  const { kind, scope } = attributes;

  if (kind === EXCLUSION_KIND) {
    return null;
  }

  if (kind === INCLUSION_KIND && isNil(scope)) {
    return EXECUTIVES_SCOPE;
  }

  return scope;
};

export default {
  defaultAttributes(attributes) {
    targetItemId += 1;

    return {
      id: targetItemId,
      kind: INCLUSION_KIND,
      included: true,
      profilesCount: 0,
      isPersisted: false,
      ...attributes,
      scope: buildTargetItemScope(attributes),
      criterias: defaultTo([], attributes.criterias),
    };
  },

  attributesForCriterion({ type, value }) {
    return { type, value: value || [] };
  },

  attributesToSubmit(targetItem) {
    let propsToSend = ['kind', 'scope', 'criterias', '_destroy'];
    if (targetItem.isPersisted) {
      propsToSend = ['id', ...propsToSend];
    }
    const criterias = targetItem.criterias.map(this.attributesForCriterion);
    const isIncluded = targetItem.included !== false;
    const isDestroyed = !isIncluded && targetItem.isPersisted;

    if (!isIncluded && !isDestroyed) {
      return null;
    }

    return pick(propsToSend, { ...targetItem, criterias, _destroy: isDestroyed });
  },
};
