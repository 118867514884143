import { equals } from 'ramda';
import AvailableProfilesAndColleaguesContainerConstants from './AvailableProfilesAndColleaguesContainerConstants';

const initialState = {
  loading: false,
  profiles: [],
  totalCount: null,
  nextPage: 1,
  filters: null,
};

function AvailableProfilesAndColleaguesContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AvailableProfilesAndColleaguesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_START: {
      const { filters } = action;

      return { ...state, ...initialState, filters, loading: true };
    }
    case AvailableProfilesAndColleaguesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_SUCCESS: {
      const { profiles, meta, filters } = action;

      if (!equals(state.filters, filters)) {
        return state;
      }

      return { ...state, loading: false, profiles, ...meta };
    }
    case AvailableProfilesAndColleaguesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_START: {
      const { filters } = action;

      return { ...state, filters, loading: true };
    }
    case AvailableProfilesAndColleaguesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_SUCCESS: {
      const { profiles, meta, filters } = action;

      if (!equals(state.filters, filters)) {
        return state;
      }

      return { ...state, loading: false, profiles: [...state.profiles, ...profiles], ...meta };
    }
    default:
      return state;
  }
}

export default AvailableProfilesAndColleaguesContainerReducer;
