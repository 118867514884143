import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(params) {
    const url = Routes.apiV1SessionPath();

    return FetchHelpers.post(url, params);
  },

  destroy() {
    const url = Routes.apiV1SessionPath();

    return FetchHelpers.delete(url);
  },
};
