import { pick } from 'ramda';

export default {
  defaultAttributes(country) {
    return {
      name: '',
      alpha2: '',
      worldRegion: '',
      isGdpr: false,
      ...country,
    };
  },

  attributesToSubmit(country) {
    const propsToSend = ['name', 'alpha2', 'worldRegion', 'isGdpr'];

    return pick(propsToSend, country);
  },
};
