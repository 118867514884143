import { buildFilterParams } from 'utils/FilterParamsBuilder';
import UserListConstants from './UserListConstants';
import UsersRepository from 'repositories/CurrentUser/Company/UsersRepository';
import { addNotification } from 'containers/Notifications';

export function loadUsers(page, filters) {
  return (dispatch) => {
    dispatch({ type: UserListConstants.LOAD_COMPANY_USER_START });

    const params = { page, ...buildFilterParams(filters) };
    return UsersRepository.index(params).then(({ items, meta }) => {
      dispatch({
        type: UserListConstants.LOAD_COMPANY_USER_SUCCESS,
        users: items,
        ...meta,
      });
    });
  };
}

export function filterUsers(filters) {
  return (dispatch) => {
    dispatch({ type: UserListConstants.FILTER_COMPANY_USER_START, filters });

    const params = buildFilterParams(filters);
    return UsersRepository.index(params).then(({ items, meta }) => {
      dispatch({ type: UserListConstants.FILTER_COMPANY_USER_SUCCESS, users: items, filters, ...meta });
    });
  };
}

export function createUser(params) {
  return (dispatch) => {
    dispatch({ type: UserListConstants.CREATE_NEW_COMPANY_USER_START });

    return UsersRepository.create(params)
      .then(({ user }) => {
        dispatch({ type: UserListConstants.CREATE_NEW_COMPANY_USER_SUCCESS, user });
        addNotification('User was successfully added', 'normal')(dispatch);
        return user;
      })
      .catch((errors) => {
        dispatch({ type: UserListConstants.CREATE_NEW_COMPANY_USER_ERROR, errors });

        throw errors;
      });
  };
}

export function bulkDestroyUsers(params) {
  return (dispatch) =>
    UsersRepository.bulkDestroy(params).then(() => {
      dispatch({ type: UserListConstants.BULK_DESTROY_COMPANY_USER_SUCCESS });
    });
}
