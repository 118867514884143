import { useProductivityStatisticsActions } from 'slices/client/ProductivityStatisticsSlice';
import { useSelector } from 'react-redux';
import { map } from 'ramda';

import { getPartialState } from 'slices/client/ProductivityStatisticsSlice';

export default (name) => {
  const data = useSelector((state) => getPartialState(state.ProductivityStatisticsReducer, name));

  const func =
    (action) =>
    (...arg) =>
      action(name, ...arg);

  const actions = map(func, useProductivityStatisticsActions());

  return { ...data, ...actions };
};
