import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  destroy(projectId) {
    const url = Routes.apiV1CurrentUserProjectGuidePath(projectId);

    return JsonFetchHelpers.delete(url);
  },
};
