import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnUserPanelDashboardDatabaseStatisticsAlert } from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';
import ProfileAvatar from 'components/ProfileAvatar';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';

import Routes from 'routes';

import styles from './DatabaseStatistics.module.css';

class DatabaseStatistics extends Component {
  static propTypes = {
    database: PropTypes.shape().isRequired,
  };

  renderProfileAvatar(profile) {
    return (
      <div key={ProfilePresenter.id(profile)} className={styles.profile}>
        <div className={styles.avatarBox}>
          <ProfileAvatar className={styles.avatar} src={ProfilePresenter.photoUrl(profile)} size="40" />
        </div>
      </div>
    );
  }

  renderStatistics(title, totalCount, totalCountInAlerts, profiles, params) {
    return (
      <div className={styles.statistics}>
        <AnchorLink className={styles.baseLink} to={Routes.searchPath(params)}>
          <div className={styles.totalCount}>{totalCount}</div>
          <div className={styles.title}>{title}</div>
        </AnchorLink>
        <AnchorLink
          className={styles.alertsLink}
          to={Routes.searchPath({ ...params, onlyMyAlerts: true })}
          onClick={logClickOnUserPanelDashboardDatabaseStatisticsAlert}
        >
          {`${totalCountInAlerts} in your alerts`}
        </AnchorLink>
        {profiles.length > 0 && <div className={styles.profiles}>{profiles.map(this.renderProfileAvatar)}</div>}
      </div>
    );
  }

  render() {
    const { database } = this.props;
    const {
      statisticsSince,
      addedProfiles,
      addedProfilesInAlertsTotalCount,
      addedProfilesTotalCount,
      newToRoleProfiles,
      newToRoleProfilesInAlertsTotalCount,
      newToRoleProfilesTotalCount,
      significantlyUpdatedProfiles,
      significantlyUpdatedProfilesInAlertsTotalCount,
      significantlyUpdatedProfilesTotalCount,
    } = database;

    return (
      <div className={styles.root}>
        {this.renderStatistics(
          'Executive Changes',
          newToRoleProfilesTotalCount,
          newToRoleProfilesInAlertsTotalCount,
          newToRoleProfiles,
          { recentCurrentWorkStartedGte: statisticsSince },
        )}
        {this.renderStatistics(
          'New Profiles',
          addedProfilesTotalCount,
          addedProfilesInAlertsTotalCount,
          addedProfiles,
          { profilesAddedGte: statisticsSince },
        )}
        {this.renderStatistics(
          'Updated Profiles',
          significantlyUpdatedProfilesTotalCount,
          significantlyUpdatedProfilesInAlertsTotalCount,
          significantlyUpdatedProfiles,
          { significantlyUpdatedGte: statisticsSince },
        )}
      </div>
    );
  }
}

export default DatabaseStatistics;
