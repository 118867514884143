import OrganizationsRepository from 'repositories/admin/CurrentOrganizationsRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function searchOrganizations(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters), per: 30 };

    return OrganizationsRepository.index(params).then(({ items }) => items);
  };
}
