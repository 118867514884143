import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(companyId, companyDepartmentId) {
    const url = Routes.apiV1AdminCompanyDepartmentLimitedAccessPath(companyId, companyDepartmentId);

    return FetchHelpers.get(url);
  },

  update(companyId, companyDepartmentId, limitedAccess) {
    const url = Routes.apiV1AdminCompanyDepartmentLimitedAccessPath(companyId, companyDepartmentId);

    return FetchHelpers.put(url, { limitedAccess });
  },
};
