import React from 'react';
import PropTypes from 'prop-types';

import LanguagePresenter from 'presenters/LanguagePresenter';

const ReviewLanguagesList = (props) => {
  const { value } = props;

  return (
    <>
      <h5>Languages</h5>
      <ul>
        {value.map((language) => (
          <li key={LanguagePresenter.id(language)}>{LanguagePresenter.name(language)}</li>
        ))}
      </ul>
    </>
  );
};

ReviewLanguagesList.propTypes = {
  value: PropTypes.arrayOf(LanguagePresenter.shape()).isRequired,
};

export default ReviewLanguagesList;
