import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { Pin as PinIcon } from 'grommet-icons';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Headline from 'components/grommet/Headline';
import RawHtml from 'components/RawHtml';
import Icon from 'components/Icon';

import TopicPresenter from 'presenters/TopicPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';

import Tags from 'containers/AdminPanel/components/Tags';

import styles from './OrganizationTopicCard.module.css';

const LONG_TEXT_LENGTH = 350;

const OrganizationTopicCard = (props) => {
  const {
    topic,
    jobFunctions,
    collapsed,
    displayTags,
    onCardShow,
    onCardHide,
    editable,
    hidden,
    onTopicVisibilityUpdate,
    saving,
  } = props;

  const handleShow = () => {
    onCardShow(TopicPresenter.id(topic));
  };

  const handleHide = () => {
    onCardHide(TopicPresenter.id(topic));
  };

  const isTextLong = () => {
    const description = TopicPresenter.description(topic);

    return isPresent(description) && description.length > LONG_TEXT_LENGTH;
  };

  const toggleHide = () => {
    onTopicVisibilityUpdate(TopicPresenter.sortUuid(topic));
  };

  const renderCollapseControl = () => (
    <div className={cn(styles.collapseControl, { [styles.hidden]: hidden })}>
      {collapsed ? (
        <button className={styles.collapseButton} onClick={handleShow}>
          Show
        </button>
      ) : (
        <button className={styles.collapseButton} onClick={handleHide}>
          Hide
        </button>
      )}
    </div>
  );

  const rootStyles = cn([styles.root], {
    [styles.collapsed]: isTextLong() && collapsed,
  });

  return (
    <div className={rootStyles}>
      <div className={styles.content}>
        <Headline
          align="start"
          strong
          size="small"
          margin="none"
          className={cn(styles.title, { [styles.hidden]: hidden })}
        >
          {TopicPresenter.pinned(topic) && (
            <div className={styles.pinIcon}>
              <PinIcon size="small" />
            </div>
          )}
          <div className={styles.titleText}> {TopicPresenter.title(topic)} </div>
        </Headline>
        {displayTags && (
          <div className={cn(styles.tags, { [styles.hidden]: hidden })}>
            <Tags items={TopicPresenter.tags(topic)} />
          </div>
        )}
        <RawHtml
          className={cn(styles.text, { [styles.hidden]: hidden })}
          innerHTML={TopicPresenter.description(topic)}
        />

        {hidden && (
          <div className={styles.visibilityLabel}>
            <Icon name="closeEye" />
          </div>
        )}

        <div className={styles.panel}>
          {editable && (
            <div className={styles.action}>
              <Button
                disabled={saving}
                icon={<Icon name={hidden ? 'closeEye' : 'openEye'} />}
                onClick={toggleHide}
                plain
              />
            </div>
          )}
        </div>

        <div className={cn(styles.infoText, { [styles.hidden]: hidden })}>
          {TopicPresenter.jobFunctions(topic, jobFunctions)}
        </div>
      </div>
      {isTextLong() ? renderCollapseControl() : null}
    </div>
  );
};

OrganizationTopicCard.propTypes = {
  topic: TopicPresenter.shape(),
  jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()),
  onCardShow: PropTypes.func,
  onCardHide: PropTypes.func,
  collapsed: PropTypes.bool,
  displayTags: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  onTopicVisibilityUpdate: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default OrganizationTopicCard;
