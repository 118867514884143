import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { splitAt } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import ProfileItem from './components/ProfileItem';

import ProjectPresenter from 'presenters/ProjectPresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';

import styles from './MostConnectedExecutives.module.css';

export default class MostConnectedExecutives extends Component {
  static propTypes = {
    project: ProjectPresenter.shape().isRequired,
    profiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()).isRequired,
    onRelationshipsExport: PropTypes.func.isRequired,
  };

  render() {
    const { project, profiles, onRelationshipsExport } = this.props;
    const columnSize = Math.ceil(profiles.length / 2);
    const [firstColumn, secondColumn] = splitAt(columnSize, profiles);
    const reorderedProfiles = firstColumn.reduce((acc, profile, index) => {
      const secondProfile = secondColumn[index];

      return isPresent(secondProfile) ? [...acc, profile, secondProfile] : [...acc, profile];
    }, []);

    return (
      <div className={styles.root}>
        <h2 className={styles.header}> Top 10 Most Connected Executives </h2>
        <div className={styles.container}>
          {reorderedProfiles.map((profile) => (
            <ProfileItem
              key={ProfileSearchPresenter.id(profile)}
              project={project}
              profile={profile}
              onRelationshipsExport={onRelationshipsExport}
            />
          ))}
        </div>
      </div>
    );
  }
}
