import React, { useEffect } from 'react';
import { isNil, prop, propEq, reject, without } from 'ramda';
import { imagePath } from 'utils/AssetsHelper';

import { useCurrentUserExecutivesMatches } from 'hooks/currentUser';

import ExecutiveMatchPresenter from 'presenters/ExecutiveMatchPresenter';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';
import { useFormContext } from 'components/v2/Form';
import { useWizardContext } from 'components/v2/Wizard';

import ExecutivesMatchesReview from 'components/client/ExecutivesMatchesReview';

import styles from './MatchExecutivesWizardStep.module.css';

const buildRequestFromExecutiveMatch = (executiveMatch) => ({
  executiveMatchIndex: executiveMatch.index,
  recipientEmails: [],
  subjectAttributes: ExecutiveMatchPresenter.buildRequestSubjectAttributes(executiveMatch),
});

const MatchExecutivesWizardStep = () => {
  const { matchExecutives, matchExecutivesStatus } = useCurrentUserExecutivesMatches();
  const { onFieldChange, values } = useFormContext();
  const { hasNextWizardStep, navigateNextWizardStep, navigatePreviousWizardStep } = useWizardContext();

  const incorrectExecutiveMatchIndexes = values.requests.map(prop('executiveMatchIndex'));

  const handleMatchExecutives = async () => {
    const { item: items, meta } = await matchExecutives(values.source);
    const requests = reject(ExecutiveMatchPresenter.isMatchedToProfile, items).map(buildRequestFromExecutiveMatch);

    onFieldChange('matchedExecutives', { items, meta });
    onFieldChange('requests', requests);
  };
  const handleExecutiveMatchMarkCorrect = (executiveMatch) => {
    onFieldChange('requests', reject(propEq(executiveMatch.index, 'executiveMatchIndex'), values.requests));
    onFieldChange(
      'selectedRequestExecutiveMatchIndexes',
      without([executiveMatch.index], values.selectedRequestExecutiveMatchIndexes),
    );
  };
  const handleExecutiveMatchMarkIncorrect = (executiveMatch) => {
    const request = buildRequestFromExecutiveMatch(executiveMatch);

    onFieldChange('requests', [...values.requests, request]);
  };

  useEffect(() => {
    if (isNil(values.matchedExecutives.meta)) {
      handleMatchExecutives();
    }
  }, [values.source]);

  return (
    <div>
      <Typography variant="h1">Upload CSV File</Typography>
      <Typography className={styles.subtitle} variant="h2">
        Matched Profiles
      </Typography>
      {matchExecutivesStatus.isPending && (
        <div className={styles.loader}>
          <img src={imagePath('project-match-executives-loader.gif')} alt="loading" />
        </div>
      )}
      {matchExecutivesStatus.isFailed && <div>Sorry something went wrong</div>}
      {matchExecutivesStatus.isFulfilled && !isNil(values.matchedExecutives.meta) && (
        <>
          <Typography className={styles.description} variant="body1">
            Perhaps some of the requested profiles are already in our database. You can specify executives you want to
            request anyway.
          </Typography>
          <ExecutivesMatchesReview
            executivesMatches={values.matchedExecutives.items}
            incorrectExecutiveMatchIndexes={incorrectExecutiveMatchIndexes}
            meta={values.matchedExecutives.meta}
            onExecutiveMatchMarkCorrect={handleExecutiveMatchMarkCorrect}
            onExecutiveMatchMarkIncorrect={handleExecutiveMatchMarkIncorrect}
          />
          <div className={styles.actions}>
            <Button onClick={navigatePreviousWizardStep} variant="outlined">
              Back
            </Button>
            <Button
              disabled={!hasNextWizardStep || !matchExecutivesStatus.isFulfilled}
              onClick={navigateNextWizardStep}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default MatchExecutivesWizardStep;
