import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';
import Routes from 'routes';
import { isPresent } from 'utils/HelperMethods';

import MoveRequestPopups from '../../components/MoveRequestPopups';

import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter, { ADMIN_ROLES } from 'presenters/UserPresenter';
import RequestPolicy from 'policies/RequestPolicy';
import RequestMoveForm from 'forms/RequestMoveForm';

class RequestForm extends Component {
  state = { requestForm: null };

  componentDidMount() {
    const { loadRequest, request, refreshRequestOnMount, initializeRequestForm } = this.props;

    if (refreshRequestOnMount) {
      loadRequest(RequestPresenter.id(request));
    } else {
      initializeRequestForm(request);
    }
  }

  componentWillUnmount() {
    const { resetRequestForm } = this.props;

    resetRequestForm();
  }

  handleSubjectCreate = (subject) => {
    const { createRequestProfile, request } = this.props;

    if (RequestPresenter.isSubjectProfile(request)) {
      return createRequestProfile(request.id, subject);
    }

    return Promise.reject();
  };

  handleUserSearch = (value) => {
    const { searchUser } = this.props;

    const filter =
      typeof value === 'object' && isPresent(value)
        ? { roleIn: ADMIN_ROLES, ...value }
        : { fullNameCont: value, roleIn: ADMIN_ROLES };

    return searchUser(filter);
  };

  handleSearchSubject = (value, options = {}) => {
    const { request, searchOrganization, searchProfile } = this.props;
    const filter = {
      searchFieldCont: value,
    };

    if (RequestPresenter.isSubjectProfile(request)) {
      return searchProfile(filter, options);
    }

    return searchOrganization(filter, options);
  };

  handleRequestUpdateCallbacks = (request) => {
    const { onDeliver, onCanceled, onChange } = this.props;

    if (onChange) {
      onChange(request);
    }

    if (onDeliver && RequestPresenter.isDelivered(request)) {
      onDeliver(request);
    }

    if (onCanceled && RequestPresenter.isCanceled(request)) {
      onCanceled(request);
    }

    return request;
  };

  handleRequestUpdate = (request) => {
    const { updateRequest } = this.props;

    return updateRequest(request.id, RequestMoveForm.attributesToSubmit(request))
      .then((data) => {
        this.setState({ requestForm: null });

        return data;
      })
      .then(this.handleRequestUpdateCallbacks);
  };

  handleAssignEditorForRequest = (request, params) => {
    const { assignEditorForRequest } = this.props;

    return assignEditorForRequest(request.id, params).then(this.handleRequestUpdateCallbacks);
  };

  redirectIfFinalState = (request) => {
    const { currentUser, history } = this.props;

    if (RequestPolicy.isResultState(currentUser, request)) {
      history.push(Routes.adminProfileRequestsPath());
    }

    return request;
  };

  handleRequestMove = (request) => {
    const { currentUser, onOpenEditorWorkModal, needEditorWork } = this.props;
    const requestForm = RequestMoveForm.defaultAttributes(request);
    const { stateEvent } = requestForm;

    if (stateEvent && RequestPolicy.needMovePopup(currentUser, request, stateEvent)) {
      this.setState({ requestForm });

      return Promise.resolve();
    }

    if (stateEvent && stateEvent === 'complete' && needEditorWork) {
      onOpenEditorWorkModal();

      return this.handleRequestUpdate(requestForm).then(this.redirectIfFinalState);
    }

    return this.handleRequestUpdate(requestForm).then(this.redirectIfFinalState);
  };

  handleMoveRequestPopupClose = () => this.setState({ requestForm: null });

  handleMoveRequestPopupSubmit = (request) =>
    this.handleRequestUpdate(RequestMoveForm.attributesToSubmit(request)).then(this.redirectIfFinalState);

  render() {
    const {
      view,
      onDeliver,
      onCanceled,
      onChange,
      updateRequest,
      createRequestProfile,
      searchOrganization,
      searchProfile,
      searchUser,
      ...otherProps
    } = this.props;
    const { saving } = this.props;
    const { requestForm } = this.state;

    return (
      <>
        {createElement(view, {
          ...otherProps,
          onSubjectCreate: this.handleSubjectCreate,
          onUpdate: this.handleRequestUpdate,
          onMove: this.handleRequestMove,
          onAssignEditor: this.handleAssignEditorForRequest,
          searchSubject: this.handleSearchSubject,
          searchUser: this.handleUserSearch,
          moveRequestPopupOpened: !!requestForm,
        })}
        {requestForm && (
          <MoveRequestPopups
            onClose={this.handleMoveRequestPopupClose}
            onSubmit={this.handleMoveRequestPopupSubmit}
            request={requestForm}
            saving={saving}
            searchUser={this.handleUserSearch}
          />
        )}
      </>
    );
  }
}

RequestForm.propTypes = {
  history: PropTypes.shape(),
  view: PropTypes.func.isRequired,
  assignEditorForRequest: PropTypes.func.isRequired,
  currentUser: UserPresenter.shape(),
  onDeliver: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onCanceled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  saving: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadRequest: PropTypes.func.isRequired,
  request: RequestPresenter.shape().isRequired,
  updateRequest: PropTypes.func.isRequired,
  createRequestProfile: PropTypes.func.isRequired,
  searchOrganization: PropTypes.func.isRequired,
  searchIndustry: PropTypes.func.isRequired,
  searchNaicsIndustryCode: PropTypes.func.isRequired,
  searchProfile: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  resetRequestForm: PropTypes.func.isRequired,
  refreshRequestOnMount: PropTypes.bool,
  initializeRequestForm: PropTypes.func.isRequired,
  onOpenEditorWorkModal: PropTypes.func.isRequired,
  needEditorWork: PropTypes.bool.isRequired,
};

export default compose(withRouter)(RequestForm);
