import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { CircleInformation as CircleInformationIcon } from 'grommet-icons';

import { isPresent } from 'utils/HelperMethods';

import Card from 'components/Card';
import Headline from 'components/grommet/Headline';
import Anchor from 'components/Anchor';

import BoardPresenter from 'presenters/BoardPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './CurrentFortune500.module.css';

const CurrentFortune500 = (props) => {
  const { boards } = props;

  const renderBoard = (board) => {
    const organization = BoardPresenter.organization(board);
    const organizationName = OrganizationPresenter.name(organization);
    const boardOfDirectorsListUrl = OrganizationPresenter.boardOfDirectorsListUrl(organization);

    return (
      <div key={BoardPresenter.id(board)} className={styles.boardItem}>
        {isPresent(boardOfDirectorsListUrl) && (
          <Anchor
            href={OrganizationPresenter.boardOfDirectorsListUrl(organization)}
            label={organizationName}
            target="_blank"
          />
        )}
        {!isPresent(boardOfDirectorsListUrl) && <div>{organizationName}</div>}
        {BoardPresenter.isMemberFortune500(board) && <div className={styles.fortune500}>F500</div>}
        {BoardPresenter.isRelevant(board) && <CircleInformationIcon className={styles.relevantIcon} type="status" />}
      </div>
    );
  };

  return (
    <Box margin={{ bottom: 'small' }}>
      <Card>
        <Headline strong size="small" margin="small">
          Current Fortune 500 Boards Membership
        </Headline>
        <div>{boards.map(renderBoard)}</div>
      </Card>
    </Box>
  );
};

CurrentFortune500.propTypes = {
  boards: PropTypes.arrayOf(BoardPresenter.shape()).isRequired,
};

export default CurrentFortune500;
