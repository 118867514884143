import PropTypes from 'prop-types';
import React from 'react';

import ExecutiveMatchPresenter from 'presenters/ExecutiveMatchPresenter';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import ExecutiveInformation from './components/ExecutiveInformation';
import ProfileInformation from './components/ProfileInformation';

import styles from './ExecutiveMatch.module.css';

const ExecutiveMatch = (props) => {
  const { executiveMatch, isCorrect, onMarkCorrect, onMarkIncorrect } = props;
  const isStrongProfileMatch = ExecutiveMatchPresenter.isStrongProfileMatch(executiveMatch);

  const handleMarkCorrect = () => onMarkCorrect(executiveMatch);
  const handleMarkIncorrect = () => onMarkIncorrect(executiveMatch);

  return (
    <div className={styles.executiveMatch}>
      <ExecutiveInformation
        firstName={ExecutiveMatchPresenter.firstName(executiveMatch)}
        lastName={ExecutiveMatchPresenter.lastName(executiveMatch)}
        jobTitle={ExecutiveMatchPresenter.jobTitle(executiveMatch)}
        organizationName={ExecutiveMatchPresenter.organizationName(executiveMatch)}
      />
      <div className={styles.controls}>
        <Typography variant="h3">{isStrongProfileMatch ? 'Match' : 'Possible'}</Typography>
        <Button
          onClick={isCorrect ? handleMarkIncorrect : handleMarkCorrect}
          variant={isCorrect ? 'outlined' : 'default'}
        >
          {isCorrect ? 'Not correct?' : 'Will be requested'}
        </Button>
      </div>
      <ProfileInformation profile={ExecutiveMatchPresenter.profile(executiveMatch)} />
    </div>
  );
};

ExecutiveMatch.propTypes = {
  executiveMatch: ExecutiveMatchPresenter.shape().isRequired,
  isCorrect: PropTypes.bool.isRequired,
  onMarkCorrect: PropTypes.func.isRequired,
  onMarkIncorrect: PropTypes.func.isRequired,
};

export default ExecutiveMatch;
