import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { isBlank, isPresent } from 'utils/HelperMethods';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RelationshipsMappingPresenter from 'presenters/RelationshipsMappingPresenter';

import UserPolicy from 'policies/UserPolicy';

import Button from 'components/Button';
import Loader from 'components/v2/Loader';

import RelationshipsMappingGraph from 'containers/UserPanel/components/RelationshipsMappingGraph';

import styles from './Graph.module.css';

const Graph = (props) => {
  const {
    loading,
    profile,
    currentUser,
    relationshipsMapping,
    userColleaguesExists,
    userBoardMembersExists,
    onProfileRelationshipsSidebarOpen,
    isAllProjectFiltersDisabled,
    isAllCompanyFiltersDisabled,
  } = props;

  const renderErrorMessage = () => {
    if (isAllProjectFiltersDisabled) {
      return 'No connections found. Please check that Executives or Board of Directors is selected under My Project.';
    }

    if (isAllCompanyFiltersDisabled) {
      return 'No connections found. Please check that My Executives or My Board Members is selected under My Company.';
    }

    if (RelationshipsMappingPresenter.links(relationshipsMapping).length === 0) {
      return 'No tangible connections between your chosen executives have been found. Consider adding additional executives to your project.';
    }

    return null;
  };

  if (loading || isBlank(relationshipsMapping)) {
    return (
      <div className={styles.centeredContainer}>
        <Loader />
      </div>
    );
  }

  if (!userColleaguesExists && !userBoardMembersExists) {
    return (
      <div className={styles.centeredContainer}>
        <p className={styles.notificationTitle}> Requires that executives are profiled in our database.</p>

        {UserPolicy.canRequestProfile(currentUser) && (
          <>
            <p className={styles.subTitle}> You can order profiles of your executive team here:</p>
            <Button primary label="Request profiles" href={Routes.newRequestPath()} />
          </>
        )}
      </div>
    );
  }

  if (isPresent(renderErrorMessage())) {
    return (
      <div className={styles.centeredContainer}>
        <p className={styles.notificationTitle}>{renderErrorMessage()}</p>
      </div>
    );
  }

  return (
    <RelationshipsMappingGraph
      onNodeClick={onProfileRelationshipsSidebarOpen}
      mapping={relationshipsMapping}
      zoomedNodeId={profile && profile.id}
    />
  );
};

Graph.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  relationshipsMapping: RelationshipsMappingPresenter.shape(),
  userColleaguesExists: PropTypes.bool.isRequired,
  userBoardMembersExists: PropTypes.bool.isRequired,
  profile: PropTypes.shape(),
  onProfileRelationshipsSidebarOpen: PropTypes.func.isRequired,
  isAllProjectFiltersDisabled: PropTypes.bool.isRequired,
  isAllCompanyFiltersDisabled: PropTypes.bool.isRequired,
};

export default Graph;
