import * as Routes from 'routes/Api';
import FormDataFetchHelpers from 'utils/FetchHelpers/FormDataFetchHelpers';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(projectId, source) {
    const url = Routes.apiV1CurrentUserProjectSourcesPath(projectId);

    return FormDataFetchHelpers.post(url, { source });
  },

  index(projectId, params = {}) {
    const url = Routes.apiV1CurrentUserProjectSourcesPath(projectId, params);

    return JsonFetchHelpers.get(url);
  },

  destroy(projectId, sourceId) {
    const url = Routes.apiV1CurrentUserProjectSourcePath(projectId, sourceId);

    return JsonFetchHelpers.delete(url);
  },
};
