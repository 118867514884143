import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'grommet';
import Link from 'components/v2/Link';
import Logotype from 'components/v2/Logotype';
import styles from './NotAnActiveUserErrorPage.module.css';

import Routes from 'routes';

class NotAnActiveUserErrorPage extends Component {
  static propTypes = {
    onSignOut: PropTypes.func.isRequired,
  };

  handleLogOut = () => {
    const { onSignOut } = this.props;

    onSignOut().then(() => {
      window.location = Routes.adminRootPath();
    });
  };

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <Link href="/">
            <Logotype />
          </Link>
        </div>
        <div className={styles.main}>
          <h1 className={styles.text}>
            Your account is not active
            <br />
            Please contact your account executive.
          </h1>
          <Button label="Log out" primary className={styles.button} onClick={this.handleLogOut} />
        </div>
      </div>
    );
  }
}

export default NotAnActiveUserErrorPage;
