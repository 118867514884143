import keyMirror from 'keymirror';

const PopularOrganizationTranscriptsConstants = keyMirror({
  LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_START: null,
  LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_SUCCESS: null,
  LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_FAILURE: null,

  CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_START: null,
  CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_SUCCESS: null,
  CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_FAILURE: null,
});

export default PopularOrganizationTranscriptsConstants;
