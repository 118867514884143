import ResetPasswordConstants from './ResetPasswordConstants';

const initialState = {
  form: { email: '' },
  isResetPasswordEmailSent: false,
  errors: null,
  saving: false,
};

function ResetPasswordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ResetPasswordConstants.INITIALIZE_RESET_PASSWORD_FORM: {
      return initialState;
    }
    case ResetPasswordConstants.CHANGE_RESET_PASSWORD_FORM:
      return { ...state, form: action.form };
    case ResetPasswordConstants.SEND_RESET_PASSWORD_EMAIL_START: {
      return { ...state, saving: true };
    }
    case ResetPasswordConstants.SEND_RESET_PASSWORD_EMAIL_SUCCESS: {
      return { ...state, saving: false, isResetPasswordEmailSent: true };
    }
    case ResetPasswordConstants.SEND_RESET_PASSWORD_EMAIL_ERROR: {
      return { ...state, saving: false, errors: action.errors };
    }
    default:
      return state;
  }
}

export default ResetPasswordReducer;
