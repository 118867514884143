import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box } from 'grommet';

import Card from 'components/Card';
import Headline from 'components/grommet/Headline';

import styles from './AutoSearchLinks.module.css';

class AutoSearchLinks extends Component {
  static propTypes = {
    links: PropTypes.arrayOf(
      PropTypes.shape({ title: PropTypes.string.isRequired, href: PropTypes.string.isRequired }),
    ),
  };

  render() {
    const { links } = this.props;

    return (
      <Box>
        <Card>
          <Headline strong size="small" margin="none" className={styles.header}>
            Auto-search links
          </Headline>
          <div className={styles.links}>
            {links.map(({ title, href }) => (
              <Anchor key={title} href={href} target="_blank" label={title} />
            ))}
          </div>
        </Card>
      </Box>
    );
  }
}

export default AutoSearchLinks;
