import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';
import OrganizationSelector from 'containers/AdminPanel/containers/OrganizationSelector';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

export default class AssignForm extends Component {
  static propTypes = {
    isSaving: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.shape({ parent: OrganizationPresenter.shape() }).isRequired,
  };

  constructor(props) {
    super(props);

    const { form } = this.props;
    this.state = { form, errors: null };
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { form } = this.state;

    onSubmit(form).catch(({ errors }) => this.setState({ errors }));
  };

  handleParentChange = (parent) => this.setState({ ...this.state, form: { ...this.state.form, parent } });

  errorFor = (field) => {
    const { errors } = this.state;

    if (isBlank(errors)) {
      return undefined;
    }

    return errors[field];
  };

  render() {
    const { onClose, isSaving } = this.props;
    const { form } = this.state;

    return (
      <Modal onClose={onClose} size="medium" header="Assign Parent" disableOverlayClose disableCloseByEsc>
        <Box margin={{ bottom: 'medium' }}>
          <OrganizationSelector
            placeholder="Organization"
            errors={this.errorFor('parentOrganizationId')}
            value={form.parent}
            onChange={this.handleParentChange}
          />
        </Box>

        <Box direction="row">
          <Button primary label="Assign Parent" disabled={isSaving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}
