import LanguagePageConstants from './LanguagePageConstants';

const initialState = {
  loading: false,
  saving: false,
  language: null,
};

function LanguagePageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LanguagePageConstants.LOAD_LANGUAGE_START: {
      return { ...state, loading: true };
    }
    case LanguagePageConstants.LOAD_LANGUAGE_SUCCESS: {
      return { ...state, language: action.language, loading: false };
    }
    case LanguagePageConstants.UPDATE_LANGUAGE_START: {
      return { ...state, saving: true };
    }
    case LanguagePageConstants.UPDATE_LANGUAGE_SUCCESS: {
      return { ...state, language: action.language, saving: false };
    }
    case LanguagePageConstants.UPDATE_LANGUAGE_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default LanguagePageReducer;
