import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import RequestsRepository from 'repositories/admin/Companies/Dashboard/RequestsRepository';

const restSlice = createRestSlice({
  resource: 'request',
  repository: RequestsRepository,
  slices: ['loadRequests', 'loadMoreRequests'],
});

const initialState = {
  ...restSlice.initialState,
};

const requestsSlice = createSlice({
  name: 'adminCompanyDashboardRequests',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDashboardRequestsReducer = requestsSlice.reducer;

export default requestsSlice;

export const adminCompanyDashboardRequestsRestHooks = restSlice.hooks(
  requestsSlice,
  'adminCompanyDashboardRequestsReducer',
);
