import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'containers/UserPanel/containers/SavedSearchesContainer'; // eslint-disable-line import/named

export default () => {
  const dispatch = useDispatch();
  const { savedSearches, isLoading, meta, isDeleting, isCreating, isUpdating } = useSelector(
    (state) => state.SavedSearchesContainerReducer,
  );

  return {
    savedSearches,
    isLoading,
    meta,
    isDeleting,
    isCreating,
    isUpdating,
    ...bindActionCreators(actions, dispatch),
  };
};
