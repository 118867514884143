import CompanyUserRegistrationConstants from './CompanyUserRegistrationConstants';

const initialState = {
  company: null,
  loading: false,
  saving: false,
};

function CompanyUserRegistrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CompanyUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_START: {
      return { ...initialState, loading: true };
    }
    case CompanyUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_SUCCESS: {
      const { company } = action;

      return { ...state, company, loading: false };
    }
    case CompanyUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_ERROR: {
      return { ...state, loading: false };
    }
    case CompanyUserRegistrationConstants.CREATE_COMPANY_USER_START: {
      return { ...state, saving: true };
    }
    case CompanyUserRegistrationConstants.CREATE_COMPANY_USER_SUCCESS: {
      return { ...state, saving: false };
    }
    case CompanyUserRegistrationConstants.CREATE_COMPANY_USER_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default CompanyUserRegistrationReducer;
