import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, debounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import Table from 'components/Table';
import LanguageRowCells from './components/LanguageRowCells';
import Search from 'components/SearchInput';
import NewLanguageModal from './components/NewLanguageModal';

import LanguagePolicy from 'policies/LanguagePolicy';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import LanguagePresenter from 'presenters/LanguagePresenter';

import Routes from 'routes';

const MAX_SEARCH_LENGTH = 40;

export default class LanguagesListPage extends Component {
  static propTypes = {
    bulkDestroyLanguages: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape(),
    createLanguage: PropTypes.func.isRequired,
    languages: PropTypes.arrayOf(LanguagePresenter.shape()).isRequired,
    loading: PropTypes.bool.isRequired,
    loadLanguages: PropTypes.func.isRequired,
    filters: PropTypes.shape().isRequired,
    filterLanguages: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    totalCount: PropTypes.number,
  };

  state = { isAddPopupOpened: false };

  componentDidMount() {
    this.props.filterLanguages({ nameCont: '', sortFields: [] });
  }

  tableColumns = [
    {
      name: 'Id',
      field: 'id',
    },
    {
      name: 'Name',
      field: 'name',
    },
    {
      name: 'Native Name',
      field: 'nativeName',
    },
    {
      name: 'ISO-639-1',
      field: 'alpha2',
    },
  ];

  isAllLanguagesLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { nextPage, loadLanguages, loading, filters } = this.props;

    if (!loading && !this.isAllLanguagesLoaded()) {
      loadLanguages({ page: nextPage, filters });
    }
  };

  handleSort = (sortFields) => {
    const { filters, filterLanguages } = this.props;

    filterLanguages({ ...filters, sortFields });
  };

  handleFilter = (fieldName, value) => {
    const { filters, filterLanguages } = this.props;
    const newFilters = { ...filters, [fieldName]: value };

    filterLanguages(newFilters);
  };

  handleFilterDebounced = debounce(this.handleFilter);

  handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFilterDebounced(fieldName, value);

  handleCreateNewLanguage = () => this.setState({ ...this.state, isAddPopupOpened: true });

  handleCloseAddNewLanguagePopup = () => this.setState({ ...this.state, isAddPopupOpened: false });

  handleLanguageCreate = (language) => {
    const { createLanguage, filters, filterLanguages } = this.props;

    return createLanguage(language).then(() => {
      this.handleCloseAddNewLanguagePopup();
      filterLanguages(filters);
    });
  };

  handleDelete = (ids) => {
    const { filters, filterLanguages, bulkDestroyLanguages } = this.props;

    bulkDestroyLanguages({ ids }).then(() => filterLanguages(filters));
  };

  handleLanguageClick = (tagId) => {
    const { currentUser } = this.props;

    if (LanguagePolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminLanguagePath(tagId));
    }
  };

  render() {
    const { currentUser, languages, filters, totalCount } = this.props;
    const { isAddPopupOpened } = this.state;

    return (
      <Box margin="none" full="horizontal">
        <PageHeader title="Languages List" />

        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box width="medium" margin={{ right: 'small' }}>
            <Search placeholder="Search" onChange={this.handleFilterChange('nameCont')} maxLength={MAX_SEARCH_LENGTH} />
          </Box>
          {LanguagePolicy.canAddElement(currentUser) && (
            <Box>
              <Button primary label="Add Language" onClick={this.handleCreateNewLanguage} />
            </Box>
          )}
        </Box>

        <Table
          columns={this.tableColumns}
          sorts={filters.sortFields}
          rows={languages}
          rowCellsComponent={<LanguageRowCells />}
          rowsDeletable={LanguagePolicy.canDestroyElement(currentUser)}
          hasMore={!this.isAllLanguagesLoaded()}
          onMore={this.handleLoadMore}
          onRowClick={this.handleLanguageClick}
          onDelete={this.handleDelete}
          onSort={this.handleSort}
          totalRows={totalCount}
        />

        {isAddPopupOpened && (
          <NewLanguageModal onClose={this.handleCloseAddNewLanguagePopup} onSave={this.handleLanguageCreate} />
        )}
      </Box>
    );
  }
}
