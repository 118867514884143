import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { formatNumber } from 'utils/NumbersHelper';
import { formattedDurationFromSeconds } from 'utils/DateTime';

import { SPENT_TIME_VALUE } from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';
import StatisticItemPresenter from 'presenters/EditorialDashboard/UserProductivity/StatisticItemPresenter';

import styles from './CustomizedTooltip.module.css';

export default class CustomizedTooltip extends Component {
  static propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(StatisticItemPresenter.shape()),
    statisticValue: PropTypes.string.isRequired,
  };

  renderValue(value) {
    const { statisticValue } = this.props;

    if (statisticValue === SPENT_TIME_VALUE) {
      return `Time Spent: ${formattedDurationFromSeconds(value)}`;
    }

    return `Requests: ${formatNumber(value)}`;
  }

  render() {
    const { active, payload } = this.props;

    if (!active || isBlank(payload)) {
      return null;
    }

    const [{ payload: statistic }] = payload;

    return (
      <div className={styles.customTooltip}>
        <strong>Current</strong>
        <div>Date: {StatisticItemPresenter.textDate(statistic)}</div>
        <div>{this.renderValue(StatisticItemPresenter.value(statistic))}</div>
        {isPresent(StatisticItemPresenter.previousTextDate(statistic)) && (
          <>
            <strong>Previous</strong>
            <div>Date: {StatisticItemPresenter.previousTextDate(statistic)}</div>
            <div>{this.renderValue(StatisticItemPresenter.previousValue(statistic))}</div>
          </>
        )}
      </div>
    );
  }
}
