import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import UserPresenter from 'presenters/UserPresenter';

export default new Presenter(
  {
    existingUsers: PropTypes.arrayOf(UserPresenter.shape()),
    invalidMissingUsers: PropTypes.arrayOf(UserPresenter.shape()),
    validMissingUsers: PropTypes.arrayOf(UserPresenter.shape()),
  },
  {},
);
