import { buildFilterParams } from 'utils/FilterParamsBuilder';
import FullProfileRequestListPageConstants from './FullProfileRequestListPageConstants';
import FullProfileRequestsRepository from 'repositories/admin/FullProfileRequestsRepository';

export function initializeFullProfileRequestList() {
  return { type: FullProfileRequestListPageConstants.INITIALIZE_FULL_PROFILE_REQUEST_LIST_SUCCESS };
}

export function loadFullProfileRequests({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: FullProfileRequestListPageConstants.LOAD_FULL_PROFILE_REQUESTS_START });

    const query = { page, ...buildFilterParams(filters) };
    return FullProfileRequestsRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: FullProfileRequestListPageConstants.LOAD_FULL_PROFILE_REQUESTS_SUCCESS,
        fullProfileRequests: items,
        ...meta,
      });
    });
  };
}

export function filterFullProfileRequests(filters) {
  return (dispatch) => {
    dispatch({ type: FullProfileRequestListPageConstants.FILTER_FULL_PROFILE_REQUESTS_START, filters });

    const query = buildFilterParams(filters);
    return FullProfileRequestsRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: FullProfileRequestListPageConstants.FILTER_FULL_PROFILE_REQUESTS_SUCCESS,
        fullProfileRequests: items,
        filters,
        ...meta,
      });
    });
  };
}
