import React from 'react';
import PropTypes from 'prop-types';

import CrossIcon from 'icons/CrossIcon';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import styles from './Header.module.css';

const Header = (props) => {
  const { children, onClose } = props;

  return (
    <div className={styles.header}>
      <Typography variant="h2">{children}</Typography>
      <Button className={styles.closeButton} color="secondary" onClick={onClose} variant="icon">
        <CrossIcon width={16} height={16} />
      </Button>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
