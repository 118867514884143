import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SourcePresenter from 'presenters/Project/SourcePresenter';
import TargetItemPresenter from 'presenters/Project/TargetItemPresenter';

import TargetingSearchForm from 'containers/UserPanel/containers/TargetingSearchForm';

import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

const isInclusionCriteriasExist = (targetItemsAttributes = []) => {
  const inclusionTargetItems = targetItemsAttributes
    .filter(TargetItemPresenter.isIncludsionKind)
    .filter(({ criterias }) => criterias.length !== 0);

  return inclusionTargetItems.length > 0;
};

const ProjectTargetingWizardStep = (props) => {
  const { source, updateSource } = props;
  const { targetItemsAttributes, profilesCount, recommendedProfilesCount } = source;

  const [totalProfiles, setTotalProfiles] = useState(0);
  const [isProfilesLoading, setIsProfilesLoading] = useState(false);
  const isNextDisabled = isProfilesLoading || totalProfiles === 0 || !isInclusionCriteriasExist(targetItemsAttributes);

  const handleTargetItemsChange = (newTargetItemsAttributes) =>
    updateSource({ ...source, targetItemsAttributes: newTargetItemsAttributes });

  return (
    <>
      <WizardTitle subtitle="Create your list from our database." />
      <TargetingSearchForm
        targetItems={targetItemsAttributes}
        initialProfilesCount={profilesCount}
        recommendedProfilesCount={recommendedProfilesCount}
        onTargetItemsChange={handleTargetItemsChange}
        onChangeNumberProfiles={setTotalProfiles}
        onChangeProfilesLoadingFlag={setIsProfilesLoading}
      />
      <WizardNavigation isNextButtonDisabled={isNextDisabled} />
    </>
  );
};

ProjectTargetingWizardStep.propTypes = {
  source: SourcePresenter.shape().isRequired,
  updateSource: PropTypes.func.isRequired,
};

export default ProjectTargetingWizardStep;
