import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { decamelize } from 'utils/keysConverter';

export default {
  index(companyId, departmentId, params) {
    const url = Routes.apiV1AdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath(
      companyId,
      departmentId,
      decamelize(params),
    );

    return FetchHelpers.get(url);
  },

  bulkDestroy(companyId, departmentId, params) {
    const url = Routes.bulkDestroyApiV1AdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath(
      companyId,
      departmentId,
      decamelize(params),
    );

    return FetchHelpers.delete(url);
  },
};
