import { pipe, reject, isNil, join } from 'ramda';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

export function profileMetaTitle(profile) {
  const currentWorks = ProfilePresenter.currentWorks(profile);
  const title = [
    'Executive Profile',
    ...currentWorks.map(WorkPresenter.organizationName),
    ProfilePresenter.fullName(profile),
  ];
  return pipe(reject(isNil), join(' | '))(title);
}

function workMetaDescription(profile, work) {
  const description = [
    work && WorkPresenter.organizationName(work),
    work && WorkPresenter.fullJobTitle(work),
    ProfilePresenter.fullName(profile),
  ];

  return `In-depth executive profile of ${pipe(reject(isNil), join(', '))(description)}`;
}

export function profileMetaDesciption(profile) {
  const currentWorks = ProfilePresenter.currentWorks(profile);

  if (currentWorks.length > 0) {
    return currentWorks.map((work) => workMetaDescription(profile, work)).join(' | ');
  }

  return workMetaDescription(profile, null);
}
