import { pick, propOr, defaultTo } from 'ramda';

export default {
  defaultAttributes(board = {}) {
    return {
      ...board,
      description: defaultTo('', board.description),
      display: defaultTo(true, board.display),
    };
  },

  attributesToSubmit(board) {
    const propsToSend = ['id', 'description', 'to', 'from', 'toPresentTime', 'display', 'relevant'];
    const { organization } = board;

    return {
      ...pick(propsToSend, board),
      organizationId: propOr(null, 'id', organization),
    };
  },
};
