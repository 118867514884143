import React, { Component } from 'react';

import { Paragraph, Box, Anchor } from 'grommet';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

export default class ReviewContactInformation extends Component {
  static propTypes = {
    value: OrganizationPresenter.shape(),
  };

  render() {
    const { value: organization } = this.props;
    return (
      <>
        <Box margin={{ vertical: 'medium' }} flex="grow" align="start">
          <Paragraph margin="none"> {OrganizationPresenter.address(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.address2(organization)} </Paragraph>
          <Paragraph margin="none">{OrganizationPresenter.cityWithStateAndZipCode(organization)}</Paragraph>
          <Paragraph margin="none">{OrganizationPresenter.countryName(organization)}</Paragraph>
          <Paragraph margin="none">{OrganizationPresenter.phone(organization)}</Paragraph>
        </Box>
        <Box direction="row">
          {OrganizationPresenter.url(organization) && (
            <Anchor
              animateIcon={false}
              href={OrganizationPresenter.url(organization)}
              label={OrganizationPresenter.url(organization)}
              target="_blank"
            />
          )}
        </Box>
      </>
    );
  }
}
