import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/FormDataFetchHelpers';

export default {
  update(profileId, params) {
    const url = Routes.apiV1AdminProfileDraftPhotoPath(profileId);

    return FetchHelpers.put(url, params);
  },
};
