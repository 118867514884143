import { equals } from 'ramda';
import UserListPageConstants from './UserListPageConstants';

const initialState = {
  saving: false,
  loading: false,
  users: [],
  nextPage: 1,
  filters: {
    searchFieldCont: '',
    sortFields: [],
    projectsAvailableTrue: null,
  },
  totalCount: null,
};

function UserListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UserListPageConstants.INITIALIZE_USER_LIST_SUCCESS: {
      return initialState;
    }
    case UserListPageConstants.LOAD_USERS_START: {
      return { ...state, loading: true };
    }
    case UserListPageConstants.LOAD_USERS_SUCCESS: {
      const { nextPage, totalCount } = action;
      const users = [...state.users, ...action.users];

      return { ...state, users, nextPage, totalCount, loading: false };
    }
    case UserListPageConstants.CREATE_USER_START: {
      return { ...state, saving: true };
    }
    case UserListPageConstants.CREATE_USER_SUCCESS: {
      return { ...state, saving: false };
    }
    case UserListPageConstants.CREATE_USER_ERROR: {
      return { ...state, saving: false };
    }
    case UserListPageConstants.FILTER_USERS_START: {
      return {
        ...state,
        users: [],
        loading: true,
        nextPage: 1,
        totalCount: null,
        filters: action.filters,
      };
    }
    case UserListPageConstants.FILTER_USERS_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { users, nextPage, totalCount } = action;

      return { ...state, users, nextPage, totalCount, loading: false };
    }
    case UserListPageConstants.BULK_DESTROY_USERS_SUCCESS: {
      return { ...state, nextPage: 1, users: [], totalCount: null };
    }
    default:
      return state;
  }
}

export default UserListPageReducer;
