import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserListPage from './UserListPage';
import * as actions from './UserListPageActions';

const mapStateToProps = (state) => ({
  saving: state.UserListPageReducer.saving,
  loading: state.UserListPageReducer.loading,
  users: state.UserListPageReducer.users,
  nextPage: state.UserListPageReducer.nextPage,
  filters: state.UserListPageReducer.filters,
  totalCount: state.UserListPageReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserListPage);
