/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';
import { isExceedMaxSize, isMatchFileExt } from 'utils/fileUtils';

import { createRestSlice } from 'utils/RestSlice';
import MatchExecutivesRepository from 'repositories/CurrentUser/MatchExecutivesRepository';

const restSlice = createRestSlice({
  resource: 'executivesMatch',
  repository: MatchExecutivesRepository,
  slices: ['createExecutivesMatch'],
});

const initialState = {
  ...restSlice.initialState,
  matchExecutivesParams: {
    validationStatus: FETCH_STATUSES.idle,
    validationResult: null,
    error: null,
  },
};

const executivesMatchesSlice = createSlice({
  name: 'currentUserExecutivesMatches',
  initialState,
  reducers: {
    ...restSlice.reducers,

    validateMatchExecutiveParamsStart(state) {
      state.matchExecutivesParams.validationStatus = FETCH_STATUSES.pending;
      state.matchExecutivesParams.validationResult = null;
    },
    validateMatchExecutiveParamsSuccess(state, { payload: { validationResult } }) {
      state.matchExecutivesParams.validationStatus = FETCH_STATUSES.fulfilled;
      state.matchExecutivesParams.validationResult = validationResult;
    },
    validateMatchExecutiveParamsFail(state, { payload: { error } }) {
      state.matchExecutivesParams.validationStatus = FETCH_STATUSES.failed;
      state.matchExecutivesParams.error = error;
    },
  },
});

const { actions } = executivesMatchesSlice;

export const useCurrentUserExecutivesMatchesActions = () => {
  const dispatch = useDispatch();

  return {
    validateMatchExecutiveParams: async (params) => {
      dispatch(actions.validateMatchExecutiveParamsStart());

      try {
        if (!isMatchFileExt(params.file, 'csv')) {
          const data = { validationResult: { errors: { file: ['Wrong file type. Please upload a .CSV'] } } };
          dispatch(actions.validateMatchExecutiveParamsSuccess(data));

          return data;
        }

        if (isExceedMaxSize(params.file)) {
          const data = { validationResult: { errors: { file: ['File size should be less than 4 megabytes'] } } };
          dispatch(actions.validateMatchExecutiveParamsSuccess(data));

          return data;
        }

        const data = await MatchExecutivesRepository.validate(params);
        dispatch(actions.validateMatchExecutiveParamsSuccess(data));

        return data;
      } catch (error) {
        dispatch(actions.validateMatchExecutiveParamsFail({ error }));
        throw error;
      }
    },
  };
};

export const useCurrentUserExecutivesMatchesSelector = () =>
  useSelector((state) => state.currentUserExecutivesMatchesReducer);

export const currentUserExecutivesMatchesReducer = executivesMatchesSlice.reducer;

export default executivesMatchesSlice;

export const currentUserExecutivesMatchesRestHooks = restSlice.hooks(
  executivesMatchesSlice,
  'currentUserExecutivesMatchesReducer',
);
/* eslint-enable no-param-reassign  */
