import React from 'react';

import { useAdminCompanies } from 'hooks/admin';
import { useAdminCompanyDashboardRequests, useAdminCompanyDashboardProjects } from 'hooks/admin';

import CompanyPresenter from 'presenters/CompanyPresenter';
import { ADMIN_COMPANY_EVENTS_CSV_EXPORT_JOB } from 'presenters/ExportJobPresenter';

import Dashboard from 'containers/AdminPanel/containers/CompanyRoutes/components/CompanyEditPage/containers/AdminDashboard';

const DashboardTab = () => {
  const { company } = useAdminCompanies();

  const { requests, requestsLoadingMoreStatus, requestsLoadingStatus, requestsMeta, loadRequests, loadMoreRequests } =
    useAdminCompanyDashboardRequests();

  const { projects, projectsLoadingMoreStatus, projectsLoadingStatus, projectsMeta, loadProjects, loadMoreProjects } =
    useAdminCompanyDashboardProjects();

  return (
    <Dashboard
      companyId={company.id}
      companyDepartments={company.departments}
      from={CompanyPresenter.subscriptionStartsAt(company)}
      to={CompanyPresenter.subscriptionExpireAt(company)}
      showPreviousIntervalData={CompanyPresenter.showPreviousIntervalData(company)}
      exportType={ADMIN_COMPANY_EVENTS_CSV_EXPORT_JOB}
      requests={requests}
      requestsLoadingMoreStatus={requestsLoadingMoreStatus}
      requestsLoadingStatus={requestsLoadingStatus}
      requestsMeta={requestsMeta}
      onLoadRequests={(params) => loadRequests(company.id, params)}
      onLoadMoreRequests={(params) => loadMoreRequests(company.id, params)}
      projects={projects}
      projectsLoadingMoreStatus={projectsLoadingMoreStatus}
      projectsLoadingStatus={projectsLoadingStatus}
      projectsMeta={projectsMeta}
      loadProjects={(params) => loadProjects(company.id, params)}
      loadMoreProjects={(params) => loadMoreProjects(company.id, params)}
    />
  );
};

export default DashboardTab;
