import { prop } from 'ramda';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import InterestListPageConstants from './InterestListPageConstants';
import InterestsRepository from 'repositories/admin/InterestsRepository';
import { addNotification } from 'containers/Notifications';
import { isPresent } from 'utils/HelperMethods';

export function initializeInterestList() {
  return { type: InterestListPageConstants.INITIALIZE_INTEREST_LIST_SUCCESS };
}

export function loadInterests({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: InterestListPageConstants.LOAD_INTERESTS_START });

    const query = { page, ...buildFilterParams(filters) };
    return InterestsRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: InterestListPageConstants.LOAD_INTERESTS_SUCCESS,
        interests: items,
        ...meta,
      });
    });
  };
}

export function filterInterests(filters) {
  return (dispatch) => {
    dispatch({ type: InterestListPageConstants.FILTER_INTERESTS_START, filters });

    const query = buildFilterParams(filters);
    return InterestsRepository.index(query).then(({ items, meta }) => {
      dispatch({ type: InterestListPageConstants.FILTER_INTERESTS_SUCCESS, interests: items, filters, ...meta });
    });
  };
}

export function bulkDestroyInterests(query) {
  return (dispatch) => {
    dispatch({ type: InterestListPageConstants.BULK_DESTROY_INTERESTS_START });

    return InterestsRepository.bulkDestroy(query).then(({ errors }) => {
      if (isPresent(errors)) {
        const interestIds = errors.map(prop('id')).join(', ');
        addNotification(`Interests with IDs ${interestIds} weren't deleted`, 'critical')(dispatch);
      }

      dispatch({ type: InterestListPageConstants.BULK_DESTROY_INTERESTS_SUCCESS });
    });
  };
}
