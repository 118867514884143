import u from 'updeep';
import { equals, propEq, reject } from 'ramda';
import SampleProfilesConstants from './SampleProfilesConstants';

const initialState = {
  loading: false,
  savingSampleProfileIds: [],
  sampleProfiles: [],
};

function SampleProfilesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SampleProfilesConstants.CHANGE_SAMPLE_PROFILE: {
      const { sampleProfile } = action;
      const sampleProfiles = u.map(u.if(propEq(sampleProfile.id, 'id'), sampleProfile), state.sampleProfiles);

      return { ...state, sampleProfiles };
    }
    case SampleProfilesConstants.LOAD_SAMPLE_PROFILES_START: {
      return { ...state, loading: true };
    }
    case SampleProfilesConstants.LOAD_SAMPLE_PROFILES_SUCCESS: {
      const { sampleProfiles } = action;

      return { ...state, loading: false, sampleProfiles };
    }
    case SampleProfilesConstants.UPDATE_SAMPLE_PROFILE_START: {
      const { sampleProfileId } = action;

      return { ...state, savingSampleProfileIds: [...state.savingSampleProfileIds, sampleProfileId] };
    }
    case SampleProfilesConstants.UPDATE_SAMPLE_PROFILE_SUCCESS: {
      const { sampleProfile } = action;
      const sampleProfiles = u.map(u.if(propEq(sampleProfile.id, 'id'), sampleProfile), state.sampleProfiles);
      const savingSampleProfileIds = reject(equals(sampleProfile.id), state.savingSampleProfileIds);

      return { ...state, savingSampleProfileIds, sampleProfiles };
    }
    case SampleProfilesConstants.UPDATE_SAMPLE_PROFILE_ERROR: {
      const { sampleProfileId } = action;
      const savingSampleProfileIds = reject(equals(sampleProfileId), state.savingSampleProfileIds);

      return { ...state, savingSampleProfileIds };
    }
    default:
      return state;
  }
}

export default SampleProfilesReducer;
