import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Countries of Origin Tags:</strong>
      </p>
      <ul>
        <li>
          USA is assumed for most of these executives. Tag the USA but only if you confirm their birthplace. For many
          people, we will not know where they are born, so do not tag the USA.
        </li>
        <li>
          Do not assume anything. Just because they have an Indian name and look Indian does not mean they are from
          India. You must confirm before you tag.
        </li>
      </ul>
    </div>
  );
}
