import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from 'config';

import CreatableSelect from 'components/CreatableSelect';

class CreatableWithSuggestionSelect extends Component {
  state = { inputValue: '', isMenuOpen: false };

  handleAddKeyword = () => {
    const { value, onChange } = this.props;
    const { inputValue } = this.state;

    if (!inputValue) {
      return;
    }

    const newValue = { key: inputValue };

    this.setState({ inputValue: '' });
    onChange([...value, newValue]);
  };

  handleMenuToggle = () => this.setState({ isMenuOpen: !this.state.isMenuOpen });

  handleInputValueChange = (value) =>
    this.setState({ inputValue: value.substring(0, config.search.textValueMaxLength) });

  handleKeyPress = (event) => {
    const { isMenuOpen } = this.state;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (!isMenuOpen) {
          this.handleAddKeyword();
          event.preventDefault();
        }
        break;
      default:
    }
  };

  render() {
    const { ...props } = this.props;
    const { inputValue } = this.state;

    return (
      <CreatableSelect
        {...props}
        inputValue={inputValue}
        onInputChange={this.handleInputValueChange}
        onBlur={this.handleAddKeyword}
        onMenuOpen={this.handleMenuToggle}
        onMenuClose={this.handleMenuToggle}
        onKeyDown={this.handleKeyPress}
      />
    );
  }
}

CreatableWithSuggestionSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
};

export default CreatableWithSuggestionSelect;
