import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { isPresent } from 'utils/HelperMethods';

import ProjectPresenter from 'presenters/ProjectPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    project: ProjectPresenter.shape(),
  },
  {
    isTaken(sample) {
      return isPresent(this.project(sample));
    },

    isEmpty(sample) {
      return !this.isTaken(sample);
    },

    projectId(sample) {
      return ProjectPresenter.id(this.project(sample));
    },
  },
);
