import PropTypes from 'prop-types';
import React from 'react';
import ReactAsyncSelect from 'react-select/async';
import { isNil } from 'ramda';

import { useFieldContext } from 'components/v2/Form/components/Field';
import StyledReactSelect from 'components/v2/StyledReactSelect';

const FIELD_VARIANT_TO_INPUT_VARIANT = {
  outlined: 'raw',
  default: 'outlined',
};

const AsyncSelect = (props) => {
  const { variant, ...rest } = props;

  const fieldContext = useFieldContext();
  const selectVariant = variant || FIELD_VARIANT_TO_INPUT_VARIANT[fieldContext?.variant];

  return (
    <StyledReactSelect
      isFieldChild={!isNil(fieldContext)}
      {...rest}
      reactSelectType={ReactAsyncSelect}
      variant={selectVariant}
    />
  );
};

AsyncSelect.propTypes = {
  onChange: PropTypes.func,
  variant: PropTypes.string,
};

AsyncSelect.defaultProps = {
  onChange: () => {},
};

export default AsyncSelect;
