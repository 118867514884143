import keyMirror from 'keymirror';

const SampleProfilesConstants = keyMirror({
  CHANGE_SAMPLE_PROFILE: null,

  LOAD_SAMPLE_PROFILES_START: null,
  LOAD_SAMPLE_PROFILES_SUCCESS: null,

  UPDATE_SAMPLE_PROFILE_START: null,
  UPDATE_SAMPLE_PROFILE_SUCCESS: null,
  UPDATE_SAMPLE_PROFILE_ERROR: null,
});

export default SampleProfilesConstants;
