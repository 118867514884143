import React from 'react';
import { Spinner as Spinning } from 'grommet';
import styles from './Spinner.module.css';

const Spinner = (props) => (
  <div className={styles.root}>
    <Spinning size="medium" {...props} />
  </div>
);

export default Spinner;
