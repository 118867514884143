import React from 'react';
import PropTypes from 'prop-types';

import LegalSettingPresenter from 'presenters/LegalSettingPresenter';

import { Field, Switch } from 'components/v2/Form';
import Typography from 'components/v2/Typography';

import styles from './CommonSettingsFields.module.css';

const CommonSettingsFields = (props) => {
  const { errors, onFieldChange, values } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Common Settings</Typography>
      <Typography variant="body1">
        Hide sensitive fields from profiles to stay complaint with company's legal policies
      </Typography>
      <Field title="Hide Email" error={errors.hideEmail} variant="outlined">
        <Switch checked={values.hideEmail} onChange={handleChange('hideEmail')} />
      </Field>
      <Field title="Hide Phone" error={errors.hidePhone} variant="outlined">
        <Switch checked={values.hidePhone} onChange={handleChange('hidePhone')} />
      </Field>
      <Field title="Hide Address" error={errors.hideAddress} variant="outlined">
        <Switch checked={values.hideAddress} onChange={handleChange('hideAddress')} />
      </Field>
    </div>
  );
};

CommonSettingsFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  values: LegalSettingPresenter.shape().isRequired,
};

export default CommonSettingsFields;
