import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

export default new Presenter(
  {
    changedProjects: PropTypes.arrayOf(ProjectPresenter.shape()),
    changedSavedSearches: PropTypes.arrayOf(SavedSearchPresenter.shape()),
    database: PropTypes.shape({
      addedProfiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
      addedProfilesInAlertsTotalCount: PropTypes.number,
      addedProfilesTotalCount: PropTypes.number,
      newToRoleProfiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
      newToRoleProfilesInAlertsTotalCount: PropTypes.number,
      newToRoleProfilesTotalCount: PropTypes.number,
      significantlyUpdatedProfiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
      significantlyUpdatedProfilesInAlertsTotalCount: PropTypes.number,
      significantlyUpdatedProfilesTotalCount: PropTypes.number,
    }),
    projects: PropTypes.arrayOf(ProjectPresenter.shape()),
    savedSearches: PropTypes.arrayOf(SavedSearchPresenter.shape()),
    statisticsStartedAt: PropTypes.string,
  },
  {},
);
