import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckBox, TextInput, Box } from 'grommet';

import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import InterestEditForm from 'forms/InterestEditForm';
import InterestPresenter from 'presenters/InterestPresenter';

class EditForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    interest: InterestPresenter.shape(),
  };

  constructor(...args) {
    super(...args);

    this.state = {
      interest: InterestEditForm.defaultAttributes(this.props.interest),
      errors: null,
    };
  }

  handleSubmit = () => {
    const { interest } = this.state;
    this.props.onSubmit(InterestEditForm.attributesToSubmit(interest, this.props.interest)).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  updateField(fieldName, value) {
    this.setState({ interest: { ...this.state.interest, [fieldName]: value } });
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleActiveChange = ({ target: { checked } }) => this.updateField('isActive', checked);

  render() {
    const { saving } = this.props;
    const { interest, errors } = this.state;

    return (
      <div>
        <VerticalFormFieldWithBottomErrors label="Title:" errors={errors && errors.title} htmlFor="title">
          <TextInput value={interest.title} onChange={this.handleTextFieldChange('title')} id="title" />
        </VerticalFormFieldWithBottomErrors>

        <VerticalFormFieldWithBottomErrors checkbox>
          <CheckBox label="Active" toggle checked={interest.isActive} onChange={this.handleActiveChange} />
        </VerticalFormFieldWithBottomErrors>

        <Box direction="row" margin={{ top: 'medium' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default EditForm;
