import PropTypes from 'prop-types';
import React from 'react';

import { useClientRequestsHelpRequest } from 'hooks/client';

import CrossIcon from 'icons/CrossIcon';

import Modal from 'components/v2/Modal';
import Form from 'components/v2/Form';
import Typography from 'components/v2/Typography';
import Button from 'components/v2/Button';

import RequestHelpForm from './components/RequestHelpForm';

import styles from './RequestsHelpSuggestionModal.module.css';

const RequestsHelpSuggestionModal = (props) => {
  const { onClose } = props;

  const {
    createRequestsHelpRequest,
    destroyRequestsHelpRequest,
    requestsHelpRequestCreatingStatus,
    requestsHelpRequestDestroyingStatus,
  } = useClientRequestsHelpRequest();

  const handleHelpSuggestionAccept = async (attributes) => {
    await createRequestsHelpRequest(attributes);
    onClose();
  };

  const handleHelpSuggestionReject = () => {
    destroyRequestsHelpRequest();
    onClose();
  };

  return (
    <Modal className={styles.modal} isOpen onClose={handleHelpSuggestionReject}>
      <Button className={styles.closeButton} color="secondary" onClick={handleHelpSuggestionReject} variant="icon">
        <CrossIcon width={24} height={24} />
      </Button>
      <Typography className={styles.header} color="tundora" variant="h2">
        Need Help?
      </Typography>
      <Typography className={styles.description} color="tundora" variant="body1">
        We noticed you have a high volume of requests. If you have a deadline or want us to prioritize certain
        accounts/requests, let us know and we will do our best to meet your needs.
      </Typography>
      <Form
        component={RequestHelpForm}
        initialValues={{ data: { message: '' } }}
        onSubmit={handleHelpSuggestionAccept}
      />
      <Button
        className={styles.rejectHelpButton}
        disabled={requestsHelpRequestCreatingStatus.isPending || requestsHelpRequestDestroyingStatus.isPending}
        onClick={handleHelpSuggestionReject}
        variant="link"
      >
        No thanks. I don’t have special requests so just deliver my profiles as usual.
      </Button>
    </Modal>
  );
};

RequestsHelpSuggestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RequestsHelpSuggestionModal;
