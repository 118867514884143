import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CrossIcon from 'icons/CrossIcon';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';

import styles from './ActionAlert.module.css';

const ICON_VARIANTS = {
  critical: styles.critical,
  ok: styles.ok,
};

const ActionAlert = (props) => {
  const { meta, onClose, value } = props;
  const { style } = meta;
  const { status, message } = value;
  const iconClassName = clsx(styles.icon, ICON_VARIANTS[status]);

  return (
    <div className={styles.alert} style={style}>
      <span className={iconClassName} />
      <Typography className={styles.message} variant="body1">
        {message}
      </Typography>
      <Button color="secondary" variant="icon" onClick={onClose}>
        <CrossIcon width={20} height={20} />
      </Button>
    </div>
  );
};

ActionAlert.propTypes = {
  meta: PropTypes.shape({ style: PropTypes.shape().isRequired }).isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.shape({
    status: PropTypes.oneOf(['ok', 'critical']).isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default ActionAlert;
