import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import AdminSavedSearches from './containers/AdminSavedSearches';
import Sample from './components/Sample';
import SavedSearch from 'components/SavedSearch';
import Spinner from 'components/Spinner';

import SampleSavedSearchPresenter from 'presenters/SampleSavedSearchPresenter';
import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import styles from './SampleSavedSearchesTab.module.css';

export default class SampleSavedSearchesTab extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loadSampleSavedSearches: PropTypes.func.isRequired,
    sampleSavedSearches: PropTypes.arrayOf(SampleSavedSearchPresenter.shape()).isRequired,
    isSaving: PropTypes.bool.isRequired,
    updateSampleSavedSearch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadSampleSavedSearches } = this.props;

    loadSampleSavedSearches();
  }

  handleMarkAsSample = (savedSearch) => {
    const { sampleSavedSearches, updateSampleSavedSearch } = this.props;
    const sampleSavedSearch = sampleSavedSearches.find(SampleSavedSearchPresenter.isEmpty);
    const savedSearchId = SavedSearchPresenter.id(savedSearch);

    return updateSampleSavedSearch(sampleSavedSearch.id, { ...sampleSavedSearch, savedSearchId });
  };

  handleUnmarkAsSample = (savedSearch) => {
    const { sampleSavedSearches, updateSampleSavedSearch } = this.props;
    const savedSearchId = SavedSearchPresenter.id(savedSearch);
    const sampleSavedSearch = sampleSavedSearches.find(
      (sample) => SampleSavedSearchPresenter.savedSearchId(sample) === savedSearchId,
    );

    return updateSampleSavedSearch(sampleSavedSearch.id, { ...sampleSavedSearch, savedSearchId: null });
  };

  renderSampleSavedSearch = (sampleSavedSearch) => {
    const { isSaving } = this.props;
    const sampleSavedSearchId = SampleSavedSearchPresenter.id(sampleSavedSearch);
    const savedSearch = SampleSavedSearchPresenter.savedSearch(sampleSavedSearch);

    return (
      <Sample
        key={sampleSavedSearchId}
        isSample={SampleSavedSearchPresenter.isTaken(sampleSavedSearch)}
        isSaving={isSaving}
        onUnmarkAsSample={() => this.handleUnmarkAsSample(savedSearch)}
      >
        {isPresent(savedSearch) ? <SavedSearch className={styles.savedSearch} savedSearch={savedSearch} /> : null}
      </Sample>
    );
  };

  render() {
    const { isLoading, isSaving, sampleSavedSearches } = this.props;

    const emptySampleSavedSearches = sampleSavedSearches.filter(SampleSavedSearchPresenter.isEmpty);
    const takenSampleSavedSearches = sampleSavedSearches.filter(SampleSavedSearchPresenter.isTaken);
    const sampleSavedSearchIds = takenSampleSavedSearches.map(SampleSavedSearchPresenter.savedSearchId);

    return (
      <div className={styles.root}>
        <div className={styles.samples}>
          {isLoading ? <Spinner /> : sampleSavedSearches.map(this.renderSampleSavedSearch)}
        </div>
        <AdminSavedSearches
          canMarkAsSample={emptySampleSavedSearches.length > 0}
          isSaving={isSaving}
          onMarkAsSample={this.handleMarkAsSample}
          onUnmarkAsSample={this.handleUnmarkAsSample}
          sampleSavedSearchIds={sampleSavedSearchIds}
        />
      </div>
    );
  }
}
