import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(id) {
    const url = Routes.apiV1AdminCompanyPath(id);

    return FetchHelpers.get(url);
  },

  create(company) {
    const url = Routes.apiV1AdminCompaniesPath();

    return FetchHelpers.post(url, { company });
  },

  update(id, company) {
    const url = Routes.apiV1AdminCompanyPath(id);

    return FetchHelpers.put(url, { company });
  },

  index(query = {}) {
    const url = Routes.apiV1AdminCompaniesPath(query);

    return FetchHelpers.get(url);
  },

  bulkDestroy(query) {
    const url = Routes.bulkDestroyApiV1AdminCompaniesPath(query);

    return FetchHelpers.delete(url);
  },
};
