import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show() {
    const url = Routes.apiV1CurrentUserNotificationsSummaryPath();

    return FetchHelpers.get(url);
  },

  update(notificationsSummary = {}) {
    const url = Routes.apiV1CurrentUserNotificationsSummaryPath();

    return FetchHelpers.put(url, { notificationsSummary });
  },
};
