import TargetingSourceEditPageConstants from './TargetingSourceEditPageConstants';

const initialState = {
  source: null,
  loading: false,
  saving: false,
};

function TargetingSourceEditPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TargetingSourceEditPageConstants.LOAD_PROJECT_TARGET_SOURCE_START: {
      return { ...state, source: null, loading: true };
    }
    case TargetingSourceEditPageConstants.LOAD_PROJECT_TARGET_SOURCE_SUCCESS: {
      const { source } = action;

      return { ...state, source, loading: false };
    }
    case TargetingSourceEditPageConstants.UPDATE_PROJECT_TARGET_SOURCE_START: {
      return { ...state, saving: true };
    }
    case TargetingSourceEditPageConstants.UPDATE_PROJECT_TARGET_SOURCE_SUCCESS: {
      const { source } = action;

      return { ...state, saving: false, source };
    }
    case TargetingSourceEditPageConstants.UPDATE_PROJECT_TARGET_SOURCE_ERROR: {
      return { ...state, saving: false };
    }
    case TargetingSourceEditPageConstants.CHANGE_PROJECT_TARGET_SOURCE: {
      const { source } = action;

      return { ...state, source };
    }
    default:
      return state;
  }
}

export default TargetingSourceEditPageReducer;
