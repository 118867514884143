import React from 'react';
import PropTypes from 'prop-types';

import ProfilePresenter from 'presenters/ProfilePresenter';
import BoardPresenter from 'presenters/BoardPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import Typography from 'components/v2/Typography';

import styles from './Fortune500Boards.module.css';

const Fortune500Boards = (props) => {
  const { fortune500Boards } = props;

  return (
    <div className={styles.fortune500Boards}>
      <Typography className={styles.fortune500Title} color="sundance">
        Current Fortune 500 Boards:
      </Typography>
      <ul className={styles.fortune500BoardsList}>
        {fortune500Boards.map((board) => (
          <li key={BoardPresenter.id(board)}>
            {BoardPresenter.description(board)}, {OrganizationPresenter.name(BoardPresenter.organization(board))}
          </li>
        ))}
      </ul>
    </div>
  );
};

Fortune500Boards.propTypes = {
  fortune500Boards: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
};

export default Fortune500Boards;
