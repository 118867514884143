import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Summarize—in list format, similar to how you do it on LinkedIn—the executive’s education experience, when that
        information is available.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <p>
        Lowell High School
        <br />
        High School Diploma
        <br />
        2005-2009
      </p>

      <p>
        University of California, Berkeley
        <br />
        BS in Business Administration
        <br />
        2009-2012
      </p>

      <p>
        Haas School of Business, University of California, Berkeley
        <br />
        MBA
        <br />
        2012-2014
      </p>
      <p>
        <strong>Important Note: </strong>
        If the organization you need to enter does not come up in the menu, you need to go to the “Organizations” module
        and add your organization.
      </p>
      <p>
        <strong>Style Tips:</strong>
      </p>
      <ul>
        <li>
          You may not have all of this information. You may be missing high school information and years and degree
          info. This is OK—just fill in what you have.
        </li>
        <li>
          Always select an existing organization from the menu if it exists. Do not create dupes!!! e.g., “UC Berkeley”
          and “University of California, Berkeley”
        </li>
        <li>Degrees should be capitalized, as in the examples above.</li>
      </ul>
    </div>
  );
}
