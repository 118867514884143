import { defaultTo, pick } from 'ramda';

import SingleSignOnPresenter from 'presenters/SingleSignOnPresenter';

export default {
  defaultAttributes(singleSignOn) {
    return {
      ...singleSignOn,
      cert: defaultTo('', SingleSignOnPresenter.cert(singleSignOn)),
      siteUrl: defaultTo('', SingleSignOnPresenter.siteUrl(singleSignOn)),
      emailAttributeName: singleSignOn.emailAttributeName || 'email',
      firstNameAttributeName: singleSignOn.firstNameAttributeName || 'first_name',
      lastNameAttributeName: singleSignOn.lastNameAttributeName || 'last_name',
    };
  },

  attributesToSubmit(singleSignOn) {
    const propsToSend = [
      'cert',
      'siteUrl',
      'emailAttributeName',
      'firstNameAttributeName',
      'lastNameAttributeName',
      'stateEvent',
    ];

    return pick(propsToSend, singleSignOn);
  },
};
