import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  buildParams,
  parseLocationToParams,
  sortFieldsToParameters,
  extractSortFields,
  SORT_PARAM_KEY,
} from 'utils/UrlHelper';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { debounce } from 'utils/HelperMethods';
import { assocPath } from 'ramda';

import { Box, CheckBox } from 'grommet';
import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import Search from 'components/SearchInput';
import Modal from 'components/Modal';
import Select from 'components/Select';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'components/ExportButton';
import ProfileList from 'containers/AdminPanel/containers/ProfileList';
import OrganizationsSelect from './components/OrganizationsSelect';

import Routes from 'routes';
import NewProfileForm from 'containers/AdminPanel/components/NewProfileForm';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import { ADMIN_PROFILES_CSV_EXPORT_JOB_TYPE } from 'presenters/ProfilePresenter';
import ProfilePolicy from 'policies/ProfilePolicy';

import useBackgroundExportJobs from 'hooks/useBackgroundExportJobs';

const MAX_SEARCH_LENGTH = 40;

const PROFILE_STATUS_FILTER_OPTIONS = [
  { label: 'Has a published version', value: 'true' },
  { label: "Doesn't has a Published", value: 'false' },
];

const PROFILE_VISIBILITY_FILTER_OPTIONS = [
  { label: 'Visible', value: 'visible' },
  { label: 'Hidden', value: 'hidden' },
  { label: 'Opted-Out', value: 'opted_out' },
];

const TABLE_COLUMNS = [
  {
    name: 'id',
    field: 'id',
    width: '125',
  },
  {
    name: 'status',
    field: 'hasPublished',
  },
  {
    name: 'name',
    field: 'draftVersionLastName,draftVersionFirstName',
  },
  {
    name: 'organization',
    field: 'draftVersionPrimaryCurrentWorkOrganizationActualName',
  },
  {
    name: 'title',
    field: 'draftVersionPrimaryCurrentWorkJobTitleStr',
  },
  {
    name: 'Last Update Date',
    field: 'draftVersionProfileUpdatedAt',
  },
];

const PROFILE_BOARD_MEMBERSHIP_OPTIONS = [
  { label: 'Fortune 500', value: 'fortune500_board' },
  { label: 'Relevant Board', value: 'relevant_board' },
  { label: 'Fortune 500 Or Relevant Board', value: 'fortune500_or_relevant_board' },
  { label: 'FTSE 100', value: 'ftse100_board' },
];

const parseQueryString = (query) => {
  const params = parseLocationToParams(query);
  const { [SORT_PARAM_KEY]: sorts, ...filters } = params.q || {};
  const sortFields = extractSortFields(sorts || [], TABLE_COLUMNS);

  return { ...filters, sortFields };
};

const ProfileListPage = (props) => {
  const {
    currentUser,
    filters,
    saving,
    searchOrganizations,
    history,
    initializeProfileListPage,
    createProfile,
    changeFilters,
  } = props;

  const defaultFilters = parseQueryString(history.location);
  const { associatedWithOrganizationIdsInclude } = defaultFilters;
  const draftVersionBoardsQ = defaultFilters.draftVersionBoardsQ || {};
  const defaultOrganizationId = parseInt(associatedWithOrganizationIdsInclude, 10);
  const defaultBoardOrganizationId = parseInt(draftVersionBoardsQ.organizationIdEq, 10);
  const defaultIsToPresentTimeBoards = draftVersionBoardsQ.toPresentTimeTrue === 'true';
  const [isAddNewProfileModalOpen, setIsAddNewProfileModalOpen] = useState(false);
  const { createBackgroundExportJob, showBackgroundExportJob } = useBackgroundExportJobs();

  useEffect(() => {
    initializeProfileListPage({ searchFieldCont: '', sortFields: [], ...defaultFilters });
  }, []);

  const setQueryString = ({ sortFields, ...newFilters }) => {
    const search = buildParams({ q: { ...newFilters, ...sortFieldsToParameters(sortFields) } });

    history.push({ pathname: Routes.adminProfilesPath(), search });
  };

  const handleCreateNewProfile = () => setIsAddNewProfileModalOpen(true);

  const handleCancelNewProfile = () => setIsAddNewProfileModalOpen(false);

  const handleSubmitNewProfile = (params) => {
    createProfile(params).then((profile) => {
      setIsAddNewProfileModalOpen(false);
      window.open(Routes.editAdminProfilePath(profile.id));
    });
  };

  const updateFilters = (changedFields) => {
    const newFilters = { ...filters, ...changedFields };

    changeFilters(newFilters);
    setQueryString(newFilters);
  };

  const handleSortChange = (sortFields) => updateFilters({ sortFields });

  const updateFilter = (fieldPath, value) => updateFilters(assocPath(fieldPath, value, filters));

  const updateFilterDebounced = debounce(updateFilter);

  const findOption = (selectedValue, options) => options.find(({ value }) => value === selectedValue);

  const handleFilterChange =
    (fieldPath) =>
    ({ target: { value } }) =>
      updateFilterDebounced(fieldPath, value);

  const handleFilterSelectChange = (fieldPath) => (value) => updateFilterDebounced(fieldPath, value);

  const handleCSVExport = () =>
    createBackgroundExportJob({
      type: ADMIN_PROFILES_CSV_EXPORT_JOB_TYPE,
      params: buildFilterParams(filters, 'search'),
    });

  const handleToPresentTimeChange = ({ target: { checked } }) => {
    updateFilters({
      ...filters,
      draftVersionBoardsQ: { ...filters.draftVersionBoardsQ, toPresentTimeTrue: checked ? true : null },
    });
  };

  return (
    <Box margin="none" full="horizontal">
      <PageHeader title="Profiles List" />

      <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
        <Box width="medium" margin={{ right: 'small' }}>
          <Search
            placeholder="Search"
            onChange={handleFilterChange(['searchFieldCont'])}
            maxLength={MAX_SEARCH_LENGTH}
          />
        </Box>
        {ProfilePolicy.canAddElement(currentUser) && (
          <Box margin={{ right: 'small' }}>
            <Button primary label="Add Profile" onClick={handleCreateNewProfile} />
          </Box>
        )}
        {ProfilePolicy.canExportList(currentUser) && (
          <BackgroundExportResultNotifier
            component={ExportButton}
            onExport={handleCSVExport}
            onPoll={showBackgroundExportJob}
            onExportPropName="onClick"
            label="Export"
            shouldOpenOnCompletion
          />
        )}
      </Box>
      <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange(['hasPublishedTrue'])}
            options={PROFILE_STATUS_FILTER_OPTIONS}
            value={findOption(filters.hasPublishedTrue, PROFILE_STATUS_FILTER_OPTIONS)}
            isClearable
            noBottom
            placeholder="Status"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange(['stateEq'])}
            options={PROFILE_VISIBILITY_FILTER_OPTIONS}
            value={findOption(filters.stateEq, PROFILE_VISIBILITY_FILTER_OPTIONS)}
            isClearable
            noBottom
            placeholder="Visibility"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <OrganizationsSelect
            defaultOrganizationId={defaultOrganizationId}
            onOrganizationIdChange={handleFilterSelectChange(['associatedWithOrganizationIdsInclude'])}
            onSearchOrganizations={searchOrganizations}
            placeholder="Associated With Organization"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <OrganizationsSelect
            defaultOrganizationId={defaultBoardOrganizationId}
            onOrganizationIdChange={handleFilterSelectChange(['draftVersionBoardsQ', 'organizationIdEq'])}
            onSearchOrganizations={searchOrganizations}
            placeholder="Associated With Board"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            placeholder="Board Membership"
            selectedOptionValue={draftVersionBoardsQ.collection}
            onValueChange={handleFilterSelectChange(['draftVersionBoardsQ', 'collection'])}
            options={PROFILE_BOARD_MEMBERSHIP_OPTIONS}
            isClearable
            noBottom
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <CheckBox
            label="to Present Time Boards"
            checked={defaultIsToPresentTimeBoards}
            onChange={handleToPresentTimeChange}
          />
        </Box>
      </Box>
      <ProfileList
        currentUser={currentUser}
        filters={filters}
        onSortChange={handleSortChange}
        tableColumns={TABLE_COLUMNS}
      />
      {isAddNewProfileModalOpen && (
        <Modal onClose={handleCancelNewProfile} size="medium" header="New Profile">
          <NewProfileForm onSubmit={handleSubmitNewProfile} saving={saving} />
        </Modal>
      )}
    </Box>
  );
};

ProfileListPage.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  saving: PropTypes.bool.isRequired,
  createProfile: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
  changeFilters: PropTypes.func.isRequired,
  history: PropTypes.shape(),
  initializeProfileListPage: PropTypes.func.isRequired,
  searchOrganizations: PropTypes.func.isRequired,
};

export default ProfileListPage;
