import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormField, TextInput } from 'grommet';

import Button from 'components/Button';
import Select from 'components/Select';

import styles from './NewUserForm.module.css';

import UserForm from 'forms/company/NewUserForm';

import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';

class NewUserForm extends Component {
  static propTypes = {
    companyDepartments: PropTypes.arrayOf(DepartmentPresenter.shape()).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      user: UserForm.defaultAttributes(),
      errors: {},
    };
  }

  handleFieldChange = (fieldName) => (value) =>
    this.setState({ ...this.state, user: { ...this.state.user, [fieldName]: value } });

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFieldChange(fieldName)(value);

  handleSubmit = () => {
    const attributes = UserForm.attributesToSubmit(this.state.user);

    this.props.onSubmit(attributes).catch(({ errors }) => this.setState({ errors }));
  };

  renderTextInput(title, fieldName, errorName) {
    const {
      user: { [fieldName]: value },
      errors: { [errorName]: errors },
    } = this.state;

    return (
      <div className={styles.horizontalField}>
        <div className={styles.label}>{title}</div>
        <FormField className={styles.formField} error={errors && []}>
          <TextInput value={value} onChange={this.handleTextFieldChange(fieldName)} />
        </FormField>
        <span className={styles.error}>{errors && errors.join(', ')}</span>
      </div>
    );
  }

  renderCompanyDepartmentSelect() {
    const { companyDepartments } = this.props;
    const {
      errors: { companyDepartment: errors },
      user,
    } = this.state;

    return (
      <div className={styles.horizontalField}>
        <div className={styles.label}>DEPARTMENT</div>
        <Select
          className={styles.select}
          errors={errors && []}
          onValueChange={this.handleFieldChange('companyDepartmentId')}
          options={companyDepartments}
          getOptionValue={DepartmentPresenter.id}
          getOptionLabel={DepartmentPresenter.name}
          selectedOptionValue={user.companyDepartmentId}
          noBottom
        />
        <span className={styles.error}>{errors && errors.join(', ')}</span>
      </div>
    );
  }

  render() {
    const { onCancel } = this.props;
    const { errors } = this.state;

    return (
      <>
        {errors.company && <div className={styles.danger}>{errors.company.join(', ')}</div>}
        <div className={styles.horizontalForm}>
          <div className={styles.inputs}>
            {this.renderTextInput('FIRST NAME', 'firstName', 'firstName')}
            {this.renderTextInput('LAST NAME', 'lastName', 'lastName')}
            {this.renderTextInput('EMAIL ADDRESS', 'email', 'email')}
            {this.renderTextInput('CONFIRM EMAIL ADDRESS', 'emailConfirmation', 'emailConfirmation')}
            {this.renderCompanyDepartmentSelect()}
          </div>
          <div className={styles.controls}>
            <Button className={styles.button} label="Save" onClick={this.handleSubmit} primary />
            <Button className={styles.button} label="Cancel" onClick={onCancel} />
          </div>
        </div>
      </>
    );
  }
}

export default NewUserForm;
