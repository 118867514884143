import TopTagsConstants from './TopTagsConstants';

const initialState = {
  loading: false,
  tags: [],
};

function TopTagsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TopTagsConstants.LOAD_PROJECT_TOP_TAGS_START: {
      return { ...state, loading: true };
    }
    case TopTagsConstants.LOAD_PROJECT_TOP_TAGS_SUCCESS: {
      const { tags } = action;

      return { ...state, tags, loading: false };
    }
    default:
      return state;
  }
}

export default TopTagsReducer;
