import React, { Component } from 'react';
import PropTypes from 'prop-types';
import papaparse from 'papaparse';
import { isPresent, parametrize } from 'utils/HelperMethods';
import { readLine } from 'utils/FileReader';
import { prop, all } from 'ramda';

import { Alert as AlertIcon } from 'grommet-icons';
import CsvDropzone from './components/CsvDropzone';
import Icon from 'components/Icon';

import styles from './UploadCsvFileForm.module.css';

export default class UploadCsvFileForm extends Component {
  static propTypes = {
    csvHeaders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    file: PropTypes.shape(),
    onFileChange: PropTypes.func.isRequired,
    recommendedProfilesCount: PropTypes.number,
    templateImagePath: PropTypes.string,
    templateCsvPath: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const { file } = this.props;
    this.state = { file, delimiter: null, headers: [], isValidating: false };
  }

  componentDidMount() {
    const { file } = this.state;

    if (isPresent(file)) {
      this.validateFile(file);
    }
  }

  validateFile = (file) => {
    const { onFileChange } = this.props;

    readLine(file)
      .then(papaparse.parse)
      .then(({ data: [fileHeaders], meta: { delimiter } }) => {
        if (this.state.file === file) {
          const headers = fileHeaders.map(parametrize);
          this.setState({ ...this.state, headers, isValidating: false });

          if (this.isFileValid(headers)) {
            onFileChange(file, delimiter);
          }
        }
      })
      .catch(() => {
        if (this.state.file === file) {
          this.setState({ isValidating: false });
        }
      });
  };

  isFileValid = (headers) => {
    const { csvHeaders } = this.props;

    return all(({ csvTitle }) => headers.includes(csvTitle), csvHeaders.filter(prop('required')));
  };

  handleFileChange = (file) => {
    this.setState(
      { ...this.state, file, headers: [], isValidating: isPresent(file) },
      () => isPresent(file) && this.validateFile(file),
    );
  };

  renderHeader = (header) => {
    const { file, headers } = this.state;
    const isTitlePresentInCsv = headers.includes(header.csvTitle);

    return (
      <div key={header.title} className={styles.csvTitle}>
        {header.title}
        {header.required ? ` (Required)` : ` (Optional)`}
        <div className={styles.checkIcon}>
          {isPresent(file) &&
            (header.required || isTitlePresentInCsv) &&
            (isTitlePresentInCsv ? (
              <div className={styles.correctIcon}>
                <Icon name="correct" />
              </div>
            ) : (
              <div className={styles.wrongIcon}>
                <Icon name="wrong" />
              </div>
            ))}
        </div>
      </div>
    );
  };

  render() {
    const { csvHeaders, recommendedProfilesCount, templateCsvPath, templateImagePath } = this.props;
    const { file, headers, isValidating } = this.state;
    const isFileValid = this.isFileValid(headers);

    return (
      <div className={styles.root}>
        {isPresent(recommendedProfilesCount) && (
          <div className={styles.infoAlert}>
            <h4>
              <AlertIcon />
              For best results limit your list to {recommendedProfilesCount} executives.
            </h4>
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.title}>CSV file format requirements:</div>
            <div className={styles.description}>
              Column headers are REQUIRED in Row 1 of the CSV file and MUST <br /> be in the following order:
            </div>
            <div className={styles.list}>{csvHeaders.map(this.renderHeader)}</div>

            <div className={styles.info}>
              Please download this <a href={templateCsvPath}>CSV template</a> to get started. <br /> Need help?{' '}
              <a href="mailto:customerservice@boardroominsiders.com">customerservice@boardroominsiders.com</a>
            </div>
            <img src={templateImagePath} className={styles.example} width="554" height="87" alt="example" />
          </div>
          <div className={styles.side}>
            <div className={styles.dropContainer}>
              <CsvDropzone
                onLoad={this.handleFileChange}
                file={file}
                isValidating={isValidating}
                isFileValid={isFileValid}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
