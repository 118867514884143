import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Notification, Text } from 'grommet';

import './Notifications.module.css';

class Notifications extends Component {
  static propTypes = {
    onCloseNotification: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        body: PropTypes.node.isRequired,
        status: PropTypes.string,
      }),
    ).isRequired,
  };

  handleClose = (id) => () => {
    this.props.onCloseNotification(id);
  };

  render() {
    const { notifications } = this.props;

    return (
      <>
        {notifications.map((n) => (
          <Notification
            toast
            global
            key={n.id}
            status={n.status}
            onClose={this.handleClose(n.id)}
            width="xxlarge"
            message={
              <Text size="large" weight="bold">
                {n.body}
              </Text>
            }
          />
        ))}
      </>
    );
  }
}

export default Notifications;
