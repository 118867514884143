import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isBlank } from 'utils/HelperMethods';
import { formatNumber, formatNumberAsPercentages } from 'utils/NumbersHelper';
import { fromISO } from 'utils/DateTime';
import { isNil } from 'ramda';

import Chart from './components/Chart';
import TopRequestors from './components/TopRequestors';
import Spinner from 'components/Spinner';

import RequestsStatisticsPresenter from 'presenters/EditorialDashboard/Productivity/RequestsStatisticsPresenter';
import useProductivityStatistics from 'hooks/client/useProductivityStatistics';

import styles from './ProductivityStatistics.module.css';

const isValidDate = (date) => {
  const isoDate = fromISO(date);
  return !isNil(isoDate) && isoDate.isValid;
};

const ProductivityStatistics = (props) => {
  const { chartTitle, filters, requestType, name } = props;

  const { requestsStatistics, topRequestors, loading, loadProductivityTopRequestors, loadProductivity } =
    useProductivityStatistics(name);

  const { timePeriod, from, to, compare } = filters;

  const handleLoadProductivityTopRequestors = () => {
    const filter = { period: timePeriod, from, to, compare };
    const type = { requestsRequestTypeEq: requestType };

    loadProductivityTopRequestors(filter, type);
  };

  const handleLoadProductivity = () => {
    const filter = { period: timePeriod, from, to, compare };
    const type = { requestTypeEq: requestType };

    loadProductivity(filter, type);
  };

  useEffect(() => {
    if (isValidDate(from) && isValidDate(to)) {
      handleLoadProductivity();
      handleLoadProductivityTopRequestors();
    }
  }, [filters]);

  const formatTotalCount = (value) => (compare ? formatNumberAsPercentages(value) : formatNumber(value));

  const renderDynamics = () => {
    const style = cn([styles.triangle], {
      [styles.negative]: RequestsStatisticsPresenter.totalCount(requestsStatistics) < 0,
    });

    return <div className={style} />;
  };

  const renderTotalCount = () => (
    <div className={styles.totalCount}>
      {compare ? renderDynamics() : null}
      {formatTotalCount(RequestsStatisticsPresenter.totalCount(requestsStatistics))}
    </div>
  );

  if (loading || isBlank(requestsStatistics)) {
    return <Spinner />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <div className={styles.chartHeader}>
          <div className={styles.chartTitle}>{chartTitle}</div>
          {renderTotalCount()}
        </div>
        <Chart data={RequestsStatisticsPresenter.data(requestsStatistics)} />
      </div>
      <div>
        <TopRequestors items={topRequestors} />
      </div>
    </div>
  );
};

ProductivityStatistics.propTypes = {
  chartTitle: PropTypes.string,
  requestType: PropTypes.string,
  name: PropTypes.string,
  filters: PropTypes.shape().isRequired,
};

export default ProductivityStatistics;
