import OrganizationSubscriptionsListContainerConstants from './OrganizationSubscriptionsListContainerConstants';
import { propEq, update, equals } from 'ramda';

const initialState = {
  loading: false,
  subscriptions: [],
  meta: {
    totalCount: 0,
    nextPage: 1,
  },
  filters: {},
};

function OrganizationSubscriptionsListContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OrganizationSubscriptionsListContainerConstants.LOAD_ORGANIZATION_SUBSCRIPTION_START: {
      return { ...state, loading: true };
    }
    case OrganizationSubscriptionsListContainerConstants.LOAD_ORGANIZATION_SUBSCRIPTION_SUCCESS: {
      const { filters, meta } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      const subscriptions = [...state.subscriptions, ...action.subscriptions];

      return { ...state, loading: false, subscriptions, meta };
    }
    case OrganizationSubscriptionsListContainerConstants.LOAD_ORGANIZATION_SUBSCRIPTION_ERROR: {
      return { ...state, loading: false };
    }
    case OrganizationSubscriptionsListContainerConstants.FILTER_ORGANIZATION_SUBSCRIPTION_START: {
      const { filters } = action;

      return { ...state, loading: true, filters };
    }
    case OrganizationSubscriptionsListContainerConstants.FILTER_ORGANIZATION_SUBSCRIPTION_SUCCESS: {
      const { subscriptions, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      return { ...state, loading: false, subscriptions, meta };
    }
    case OrganizationSubscriptionsListContainerConstants.FILTER_ORGANIZATION_SUBSCRIPTION_ERROR: {
      return { ...state, loading: false };
    }
    case OrganizationSubscriptionsListContainerConstants.DELETE_ORGANIZATION_SUBSCRIPTION_START: {
      return { ...state, loading: true };
    }
    case OrganizationSubscriptionsListContainerConstants.DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS: {
      const { id } = action;
      const subscriptionIndex = state.subscriptions.findIndex(propEq(id, 'id'));
      const subscription = state.subscriptions[subscriptionIndex];
      const subscriptions = update(subscriptionIndex, { ...subscription, isDestroyed: true }, state.subscriptions);
      const meta = { ...state.meta, totalCount: state.meta.totalCount - 1 };

      return { ...state, loading: false, subscriptions, meta };
    }
    case OrganizationSubscriptionsListContainerConstants.DELETE_ORGANIZATION_SUBSCRIPTION_ERROR: {
      return { ...state, loading: true };
    }
    case OrganizationSubscriptionsListContainerConstants.UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_START: {
      return { ...state, loading: true };
    }
    case OrganizationSubscriptionsListContainerConstants.UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS: {
      const { id, subscription } = action;
      const subscriptionIndex = state.subscriptions.findIndex(propEq(id, 'id'));
      const subscriptions = update(subscriptionIndex, subscription, state.subscriptions);
      const meta = { ...state.meta, totalCount: state.meta.totalCount + 1 };

      return { ...state, loading: false, subscriptions, meta };
    }
    case OrganizationSubscriptionsListContainerConstants.UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_ERROR: {
      return { ...state, loading: true };
    }
    default:
      return state;
  }
}

export default OrganizationSubscriptionsListContainerReducer;
