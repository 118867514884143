import React, { useEffect } from 'react';
import { isPresent } from 'utils/HelperMethods';

import { useAdminEditorialDashboardDatabaseAnalyticFortune500ProfilesStatistics } from 'hooks/admin';

import Spinner from 'components/Spinner';

import Fortune500ProfilesStatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/Fortune500ProfilesStatisticsPresenter';
import TopFortune500OrganizationsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/Fortune500ProfilesStatistics/TopFortune500OrganizationsPresenter';

import styles from './Fortune500ProfilesStatistics.module.css';

const Fortune500ProfilesStatistics = () => {
  const {
    editorialDashboardFortune500ProfilesStatistic: statistics,
    editorialDashboardFortune500ProfilesStatisticLoadingStatus: loadingStatus,
    loadEditorialDashboardFortune500ProfilesStatistic,
  } = useAdminEditorialDashboardDatabaseAnalyticFortune500ProfilesStatistics();

  const topFortune500Organizations = Fortune500ProfilesStatisticsPresenter.topFortune500Organizations(statistics);

  const isShowStatistic = loadingStatus.isFulfilled || isPresent(statistics);

  useEffect(() => {
    loadEditorialDashboardFortune500ProfilesStatistic();
  }, []);

  return (
    <div>
      <h3 className={styles.header}>Fortune 500 Profiles</h3>
      {isShowStatistic ? (
        <>
          <div className={styles.item}>
            <div className={styles.title}>Total Profiles:</div>
            <div className={styles.number}>
              {Fortune500ProfilesStatisticsPresenter.formattedTotalProfilesNumber(statistics)}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Avg. Profiles Per Company:</div>
            <div className={styles.number}>
              {Fortune500ProfilesStatisticsPresenter.formattedAvgProfilesNumberPerCompany(statistics)}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Total Board Members:</div>
            <div className={styles.number}>
              {Fortune500ProfilesStatisticsPresenter.formattedTotalBoardMembersNumber(statistics)}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.mostProfilesRoot}>
              <div className={styles.title}>Most Profiles:</div>
              {topFortune500Organizations.map((organization) => (
                <div
                  key={TopFortune500OrganizationsPresenter.companyName(organization)}
                  className={styles.mostProfilesItem}
                >
                  <div className={styles.mostProfileName}>
                    {TopFortune500OrganizationsPresenter.companyName(organization)}:
                  </div>
                  <div className={styles.number}>
                    {TopFortune500OrganizationsPresenter.formattedProfilesNumber(organization)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Fortune500ProfilesStatistics;
