import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TopJobFunctions from './TopJobFunctions';
import * as actions from './TopJobFunctionsActions';

const mapStateToProps = (state) => ({
  loading: state.TopJobFunctionsReducer.loading,
  jobFunctions: state.TopJobFunctionsReducer.jobFunctions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopJobFunctions);
