import ProfilesUpdatesStatisticsConstants from './ProfilesUpdatesStatisticsConstants';

const initialState = {
  loading: false,
  profilesUpdatesStatistics: null,
};

function ProfilesUpdatesStatisticsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfilesUpdatesStatisticsConstants.LOAD_DATABASE_ANALYTICS_PROFILES_UPDATES_STATISTICS_START: {
      return { ...state, loading: true, profilesUpdatesStatistics: null };
    }
    case ProfilesUpdatesStatisticsConstants.LOAD_DATABASE_ANALYTICS_PROFILES_UPDATES_STATISTICS_SUCCESS: {
      const { profilesUpdatesStatistics } = action;

      return { ...state, loading: false, profilesUpdatesStatistics };
    }
    case ProfilesUpdatesStatisticsConstants.LOAD_DATABASE_ANALYTICS_PROFILES_UPDATES_STATISTICS_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}

export default ProfilesUpdatesStatisticsReducer;
