import ProjectReducer from './ProjectReducer';

import { AudienceInsightsReducers } from './containers/AudienceInsights';
import { AvailableProfilesAndColleaguesContainerReducers } from './containers/AvailableProfilesAndColleaguesContainer';
import { AvailableProfilesContainerReducers } from './containers/AvailableProfilesContainer';
import { BusinessPriorityAnalysisReducers } from './containers/BusinessPriorityAnalysis';
import { CsvSourceEditPageReducers } from './containers/CsvSourceEditPage';
import { NewProjectSourceWizardReducers } from './containers/NewProjectSourceWizard';
import { ProfileRelationshipsListReducers } from './containers/ProfileRelationshipsList';
import { RelationshipsMappingReducers } from './containers/RelationshipsMapping';
import { SharesContainerReducers } from './containers/SharesContainer';
import { TargetingSourceEditPageReducers } from './containers/TargetingSourceEditPage';
import { WordsCloudReducers } from './containers/WordsCloud';

export default from './ProjectContainer';

export * as actions from './ProjectActions';

export const ProjectReducers = {
  ProjectReducer,
  ...AudienceInsightsReducers,
  ...AvailableProfilesAndColleaguesContainerReducers,
  ...AvailableProfilesContainerReducers,
  ...BusinessPriorityAnalysisReducers,
  ...CsvSourceEditPageReducers,
  ...NewProjectSourceWizardReducers,
  ...ProfileRelationshipsListReducers,
  ...RelationshipsMappingReducers,
  ...SharesContainerReducers,
  ...TargetingSourceEditPageReducers,
  ...WordsCloudReducers,
};
