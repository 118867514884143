import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(organizationId, organizationVersion) {
    const url = Routes.apiV1AdminOrganizationDraftPath(organizationId);

    return FetchHelpers.put(url, { organizationVersion });
  },
};
