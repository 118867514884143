import keyMirror from 'keymirror';

const CompaniesListPageConstants = keyMirror({
  INITIALIZE_COMPANIES_LIST: null,

  LOAD_COMPANIES_START: null,
  LOAD_COMPANIES_SUCCESS: null,

  FILTER_COMPANIES_START: null,
  FILTER_COMPANIES_SUCCESS: null,

  BULK_DESTROY_COMPANIES_SUCCESS: null,

  CREATE_COMPANY_START: null,
  CREATE_COMPANY_SUCCESS: null,
  CREATE_COMPANY_ERROR: null,
});

export default CompaniesListPageConstants;
