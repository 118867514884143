import { equals } from 'ramda';
import ProfilesListConstants from './ProfilesListConstants';

const initialState = {
  loading: false,
  profilesWithContexts: [],
  nextPage: null,
  currentPage: null,
  filters: {},
};

function ProfilesListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfilesListConstants.FILTER_PROJECT_WORD_PROFILES: {
      const { filters } = action;

      return { ...state, loading: false, profilesWithContexts: [], nextPage: 1, filters };
    }
    case ProfilesListConstants.LOAD_PROJECT_WORD_PROFILES_START: {
      return { ...state, loading: true };
    }
    case ProfilesListConstants.LOAD_PROJECT_WORD_PROFILES_SUCCESS: {
      const { profilesWithContexts, meta, filters } = action;

      if (!equals(state.filters, filters)) {
        return state;
      }

      return {
        ...state,
        profilesWithContexts: [...state.profilesWithContexts, ...profilesWithContexts],
        loading: false,
        ...meta,
      };
    }
    default:
      return state;
  }
}

export default ProfilesListReducer;
