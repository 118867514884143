import { buildUrl } from 'utils/UrlHelper';
import { isPresent } from 'utils/HelperMethods';

export default {
  projectsPath() {
    return '/projects';
  },

  projectPath(projectId, query) {
    const path = `/projects/${projectId}`;

    return isPresent(query) ? buildUrl(path, query) : path;
  },

  newProjectPath() {
    return `/projects/new`;
  },

  newProjectWizardStepPath(step) {
    return `/projects/new/${step}`;
  },

  newProjectSourcePath(projectId) {
    return `/projects/${projectId}/sources/new`;
  },

  newProjectSourceWizardStepPath(projectId, step) {
    return `/projects/${projectId}/sources/new/${step}`;
  },

  editProjectTargetingSourcePath(projectId, sourceId) {
    return `/projects/${projectId}/targeting-sources/${sourceId}/edit`;
  },

  editProjectCsvSourcePath(projectId, sourceId) {
    return `/projects/${projectId}/csv-sources/${sourceId}/edit`;
  },

  projectWordsCloudPath(projectId) {
    return `/projects/${projectId}/common-threads`;
  },

  projectRelationshipsMappingPath(projectId) {
    return `/projects/${projectId}/relationship-mapping`;
  },

  projectBusinessPriorityAnalysisPath(projectId) {
    return `/projects/${projectId}/business-priority-analysis`;
  },

  projectAudienceInsightsPath(projectId) {
    return `/projects/${projectId}/audience-insights`;
  },
};
