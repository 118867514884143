import CompanyConstants from './CompanyConstants';
import CompanyRepository from 'repositories/CompanyRepository';

export function loadCompany(params) {
  return (dispatch) => {
    dispatch({ type: CompanyConstants.LOAD_COMPANY_START });

    return CompanyRepository.show(params)
      .then(({ company }) => {
        dispatch({ type: CompanyConstants.LOAD_COMPANY_SUCCESS, company });

        return company;
      })
      .catch((error) => {
        dispatch({ type: CompanyConstants.LOAD_COMPANY_ERROR, error });

        throw error;
      });
  };
}
