// import * as d3 from 'd3';
import { fixna } from 'utils/d3Utils';

import Avatar from './Avatar';
import Background from './Background';
import ColorBorder from './ColorBorder';
import Label, { HEIGHT as LABEL_HEIGHT } from './Label';
import LabelF500 from './LabelF500';
import LabelFTSE100 from './LabelFTSE100';

const NODE_CLASS_NAME = 'node';
export const AVATAR_RADIUS = 24;
const LABEL_DY = AVATAR_RADIUS * 1.5;
export const HEIGHT = AVATAR_RADIUS * 2 + LABEL_HEIGHT;

function selectAll(nodesGroupSelection) {
  return nodesGroupSelection.selectAll(`g.${NODE_CLASS_NAME}`);
}

function isNeedColorBorder({ isCustomerExecutive, isCustomerBoardMember, maxStrength }, props) {
  return isCustomerExecutive || isCustomerBoardMember || maxStrength >= props.strongRelationshipThreshold;
}

function mount(selection, props) {
  const nodeProps = { ...props, node: { avatarRadius: AVATAR_RADIUS, labelDy: LABEL_DY } };
  const nodeGroup = selection
    .append('g')
    .attr('class', NODE_CLASS_NAME)
    .attr('filter', ({ isVisible }) => (isVisible ? null : 'url(#blur)'));

  nodeGroup.filter((d) => isNeedColorBorder(d, props)).call(ColorBorder.mount, nodeProps);

  nodeGroup.call(Background.mount, nodeProps).call(Label.mount, nodeProps).call(Avatar.mount, nodeProps);

  nodeGroup.filter((d) => d.isMemberFortune500).call(LabelF500.mount);
  nodeGroup.filter((d) => d.isMemberFtse100).call(LabelFTSE100.mount);

  return nodeGroup;
}

function move(selection) {
  selection
    .attr('transform', (d) => `translate(${fixna(d.x)},${fixna(d.y)})`)
    .attr('data-translate-x', (d) => fixna(d.x))
    .attr('data-translate-y', (d) => fixna(d.y));
}

export default { mount, move, selectAll };
