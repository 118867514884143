import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import { assocPath } from 'ramda';

import Loader from 'components/v2/Loader';
import InfiniteScroll from 'components/v2/InfiniteScroll';
import ProjectTile from 'components/admin/ProjectTile';
import Search from 'components/SearchInput';

import ProjectPresenter from 'presenters/ProjectPresenter';

import styles from './Projects.module.css';

const Projects = (props) => {
  const {
    hasEmptySampleProject,
    onMarkAsSample,
    onUnmarkAsSample,
    updatingStatus,
    sampleProjectIds,
    onFilterChange,
    filters,
    hasMore,
    loadingStatus,
    loadingMoreStatus,
    projects,
    onLoadMore,
  } = props;

  const handleSearchChange =
    (fieldPath) =>
    ({ target: { value } }) => {
      const newFilters = assocPath(fieldPath, value, filters);
      onFilterChange(newFilters);
    };

  return (
    <div className={styles.root}>
      <div className={styles.search}>
        <Search placeholder="Search" onChange={handleSearchChange(['q', 'nameCont'])} />
      </div>

      <div className={styles.gridList}>
        <InfiniteScroll
          hasMore={hasMore}
          loadingMoreStatus={loadingMoreStatus}
          onLoadMore={onLoadMore}
          researchForScrollParent
        >
          {projects.map((project) => (
            <div className={styles.project} key={ProjectPresenter.id(project)}>
              <ProjectTile
                isSample={includes(ProjectPresenter.id(project), sampleProjectIds)}
                project={project}
                updatingStatus={updatingStatus}
                onMarkAsSample={onMarkAsSample}
                onUnmarkAsSample={onUnmarkAsSample}
                hasEmptySampleProject={hasEmptySampleProject}
              />
            </div>
          ))}
          {(loadingStatus.isPending || loadingMoreStatus.isPending) && <Loader />}
        </InfiniteScroll>
      </div>
    </div>
  );
};

Projects.propTypes = {
  updatingStatus: PropTypes.shape(),
  hasEmptySampleProject: PropTypes.bool.isRequired,
  onMarkAsSample: PropTypes.func.isRequired,
  onUnmarkAsSample: PropTypes.func.isRequired,
  sampleProjectIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
  hasMore: PropTypes.bool.isRequired,
  loadingStatus: PropTypes.shape(),
  loadingMoreStatus: PropTypes.shape(),
  projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
};

export default Projects;
