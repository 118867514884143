import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Badge.module.css';

const Badge = (props) => {
  const { children, className } = props;
  const rootStyles = clsx(className, styles.root);

  return (
    <div className={styles.wrapper}>
      <span className={rootStyles}>{children}</span>
    </div>
  );
};

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: null,
};

export default Badge;
