import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import { TextInput, Box } from 'grommet';
import LanguageEditForm from 'forms/LanguageEditForm';

class NewLanguageModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    fields: LanguageEditForm.defaultAttributes({}),
    errors: null,
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { fields } = this.state;

    onSave(LanguageEditForm.attributesToSubmit(fields)).catch((response) => {
      if (response.errors) {
        this.setState({ errors: response.errors });
      }
    });
  };

  handleChange =
    (fieldName) =>
    ({ target: { value } }) => {
      this.setState({ fields: { ...this.state.fields, [fieldName]: value } });
    };

  renderTextInput(label, fieldName) {
    const { errors } = this.state;

    return (
      <VerticalFormFieldWithBottomErrors label={label} errors={errors && errors[fieldName]} htmlFor={fieldName}>
        <TextInput onChange={this.handleChange(fieldName)} id={fieldName} />
      </VerticalFormFieldWithBottomErrors>
    );
  }

  render() {
    const { onClose } = this.props;

    return (
      <Modal onClose={onClose} size="medium" header="New Language" overflow>
        {this.renderTextInput('Name:', 'name')}
        {this.renderTextInput('Native Name:', 'nativeName')}
        {this.renderTextInput('ISO-639-1', 'alpha2')}

        <Box direction="row" margin={{ top: 'small' }} justify="center">
          <Button label="Save" onClick={this.handleSave} primary />
        </Box>
      </Modal>
    );
  }
}

export default NewLanguageModal;
