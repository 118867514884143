import ProjectPresenter from 'presenters/ProjectPresenter';
import UserPresenter from 'presenters/UserPresenter';
import { isBlank, isPresent } from 'utils/HelperMethods';

export default {
  canExport(project) {
    return ProjectPresenter.canExport(project);
  },

  canExportProfiles(user, project) {
    return ProjectPresenter.canExport(project) && !UserPresenter.isDownloadLimited(user);
  },

  canEdit(project) {
    return ProjectPresenter.canUpdate(project);
  },

  canDestroy(project) {
    return ProjectPresenter.canDestroy(project);
  },

  canRemoveProfile(project) {
    return ProjectPresenter.canUpdate(project);
  },

  canEditSource(project) {
    return ProjectPresenter.canUpdate(project);
  },

  canDestroySource(project) {
    return ProjectPresenter.canUpdate(project);
  },

  canAddSource(project) {
    return ProjectPresenter.canUpdate(project);
  },

  canSubscribe(project) {
    const subscriptionId = ProjectPresenter.subscriptionId(project);

    return ProjectPresenter.canSubscribe(project) && isBlank(subscriptionId);
  },

  canUnsubscribe(project) {
    const subscriptionId = ProjectPresenter.subscriptionId(project);

    return ProjectPresenter.canSubscribe(project) && isPresent(subscriptionId);
  },

  canShare(project) {
    return ProjectPresenter.canShare(project);
  },

  canShareToNewCompanyMembers(project) {
    return this.canShare(project) && ProjectPresenter.canShareToNewCompanyMembers(project);
  },

  canDestroyShare(project) {
    return isPresent(ProjectPresenter.shareId(project));
  },
};
