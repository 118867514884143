import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { maxDate, formattedDate } from 'utils/DateTime';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import SourcePresenter from './Project/SourcePresenter';
import UserPresenter from 'presenters/UserPresenter';

export const PROJECT_CUSTOM_EXPORT = 'ExportJob::ProjectCustomExportJob';
export const PROJECT_PROFILE_RELATIONSHIPS_PDF_EXPORT = 'ExportJob::ProjectProfileRelationshipsPdfExportJob';
export const PROJECT_COLLEAGUE_RELATIONSHIPS_PDF_EXPORT = 'ExportJob::ProjectColleagueRelationshipsPdfExportJob';
export const PROJECT_WORD_PROFILES_CSV_EXPORT = 'ExportJob::ProjectWordProfilesCsvExportJob';
export const PROJECT_AUDIENCE_INSIGHTS_PDF_EXPORT = 'ExportJob::ProjectAudienceInsightsPdfExportJob';
export const PROJECT_TOP_JOB_FUNCTIONS_PDF_EXPORT = 'ExportJob::ProjectTopJobFunctionsPdfExportJob';
export const PROJECT_TOP_ROLES_PDF_EXPORT = 'ExportJob::ProjectTopRolesPdfExportJob';
export const PROJECT_TOP_TAGS_PDF_EXPORT = 'ExportJob::ProjectTopTagsPdfExportJob';
export const PROJECT_WORDS_CLOUD_PDF_EXPORT = 'ExportJob::ProjectWordsCloudPdfExportJob';
export const PROJECT_TAG_PROFILES_CSV = 'ExportJob::ProjectTagProfilesCsvExportJob';
export const PROJECT_MASTER_EXPORT = 'ExportJob::ProjectMasterExportJob';
export const PROJECT_RELATIONSHIP_MAPPINGS_EXPORT = 'ExportJob::ProjectRelationshipMappingsExportJob';

export const RECOMMENDED_PROFILES_COUNT = 1000;

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    availableProfilesCount: PropTypes.number,
    pendingProfilesCount: PropTypes.number,
    totalProfilesCount: PropTypes.number,
    isAudienceInsightsAvailable: PropTypes.bool,
    isBusinessPriorityAnalysisAvailable: PropTypes.bool,
    isRelationshipsMappingAvailable: PropTypes.bool,
    isWordsCloudAvailable: PropTypes.bool,
    recommendedProfilesCount: PropTypes.number,
    canDestroy: PropTypes.bool,
    canUpdate: PropTypes.bool,
    canExport: PropTypes.bool,
    canShare: PropTypes.bool,
    canShareToNewCompanyMembers: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    isCurrentUserOwns: PropTypes.bool,
    isSampleProject: PropTypes.bool,
    sources: PropTypes.arrayOf(SourcePresenter.shape()),
    significantlyUpdatedProfiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
    significantlyUpdatedProfilesTotalCount: PropTypes.number,
    newToRoleProfiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
    newToRoleProfilesTotalCount: PropTypes.number,
    addedProfiles: PropTypes.arrayOf(ProfileSearchPresenter.shape()),
    addedProfilesTotalCount: PropTypes.number,
    statisticsSince: PropTypes.string,
    shouldShowGuide: PropTypes.bool,
    shareId: PropTypes.number,
    subscriptionId: PropTypes.number,
    user: UserPresenter.shape(),
    lastModifiedAt: PropTypes.string,
  },
  {
    addedProfilesUpdatedAt(project) {
      const profilesUpdatedAt = this.addedProfiles(project).map(ProfileSearchPresenter.significantlyUpdatedAt);

      return maxDate(...profilesUpdatedAt);
    },

    newToRoleProfilesUpdatedAt(project) {
      const profilesUpdatedAt = this.newToRoleProfiles(project).map(ProfileSearchPresenter.significantlyUpdatedAt);

      return maxDate(...profilesUpdatedAt);
    },

    significantlyUpdatedProfilesUpdatedAt(project) {
      const profilesUpdatedAt = this.significantlyUpdatedProfiles(project).map(
        ProfileSearchPresenter.significantlyUpdatedAt,
      );

      return maxDate(...profilesUpdatedAt);
    },

    ownerFullName(project) {
      const owner = this.user(project);

      if (this.isSampleProject(project)) {
        return 'John Doe';
      }

      return UserPresenter.fullName(owner);
    },

    visibleOwnerFullName(project) {
      if (this.isCurrentUserOwns(project)) {
        return 'Me';
      }

      if (this.isSampleProject(project)) {
        return 'Jane Smith';
      }

      const owner = this.user(project);

      return UserPresenter.fullName(owner);
    },

    formattedLastModifiedAt(project) {
      return formattedDate(this.lastModifiedAt(project));
    },
  },
);
