import React from 'react';

import { useAlert } from 'hooks';
import { useAdminCompanies } from 'hooks/admin';

import CompanyEditForm from 'forms/CompanyEditForm';

import Form from 'components/v2/Form';

import EditForm from './components/EditForm';

const EditTab = () => {
  const alert = useAlert();
  const { company, updateCompany } = useAdminCompanies();

  const handleCompanyUpdate = async (attributes) => {
    await updateCompany(company.id, attributes);

    alert.successAction('Company was successfully saved');
  };

  return (
    <Form
      component={EditForm}
      enableReinitialize
      initialValues={CompanyEditForm.defaultAttributes(company)}
      onSubmit={handleCompanyUpdate}
    />
  );
};

export default EditTab;
