import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from 'grommet';
import { FormDown, FormUp } from 'grommet-icons';
import { update } from 'ramda';

import Button from 'components/Button';

import styles from './TableHeader.module.css';

export default class TableHeader extends Component {
  static propTypes = {
    displayActions: PropTypes.bool,
    labels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSort: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired,
    onAllSelectionChange: PropTypes.func.isRequired,
    totalRows: PropTypes.number,
    rowsSelectable: PropTypes.bool.isRequired,
    actions: PropTypes.node,
    displayActionsHeader: PropTypes.bool,
  };

  static defaultProps = {
    displayActionsHeader: true,
  };

  getSortIcon = {
    desc: <FormDown size="xsmall" />,
    asc: <FormUp size="xsmall" />,
    null: null,
  };

  mapping = {
    null: 'desc',
    desc: 'asc',
    asc: null,
  };

  handleSort = (index, sort, name) => {
    const { labels, onSort } = this.props;

    onSort(update(index, { ...labels[index], name, sort: this.mapping[sort] }, labels));
  };

  handleAllSeletctionChange = (event) => {
    this.props.onAllSelectionChange(event.target.checked);
  };

  render() {
    const {
      allSelected,
      displayActions,
      labels,
      onSort,
      onAllSelectionChange,
      totalRows,
      rowsSelectable,
      actions,
      displayActionsHeader,
      ...props
    } = this.props;

    const cells = labels.map((label, index) => {
      const { name, sort, width, disableSort } = label;

      const content = (
        <Button
          plain
          fill
          disabled={disableSort}
          onClick={() => this.handleSort(index, sort, name)}
          className={styles.button}
        >
          <span className={styles.title}>{name}</span>
          <span className={styles.icon}>{this.getSortIcon[sort]}</span>
        </Button>
      );

      return (
        <th key={index} className={styles.th} width={width}>
          {content}
        </th>
      );
    });

    return (
      <thead {...props}>
        <tr>
          {rowsSelectable && <th className={styles.checkbox} />}
          {cells}
        </tr>
        {displayActionsHeader && (
          <tr className={styles.actionRow}>
            {rowsSelectable && (
              <td className={styles.checkBox}>
                <CheckBox checked={allSelected} onChange={this.handleAllSeletctionChange} />
              </td>
            )}
            <td colSpan={cells.length - 1} className={styles.actionCol}>
              {displayActions && actions}
            </td>
            <td className={styles.totalValue}>{totalRows && `TOTAL: ${totalRows}`}</td>
          </tr>
        )}
      </thead>
    );
  }
}
