import React, { Component } from 'react';

import InterestPresenter from 'presenters/InterestPresenter';

class InterestRowCells extends Component {
  static propTypes = {
    row: InterestPresenter.shape(),
  };

  stopPropagation = (event) => event.stopPropagation();

  render() {
    const { row: interest } = this.props;

    return (
      <>
        <td>{InterestPresenter.id(interest)}</td>
        <td>{InterestPresenter.title(interest)}</td>
        <td>{InterestPresenter.stateName(interest)}</td>
        <td>{InterestPresenter.formattedCreatedAt(interest)}</td>
      </>
    );
  }
}

export default InterestRowCells;
