import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1AdminSampleSavedSearchesPath(params);

    return FetchHelpers.get(url);
  },

  update(sampleSavedSearchId, sampleSavedSearch) {
    const url = Routes.apiV1AdminSampleSavedSearchPath(sampleSavedSearchId);

    return FetchHelpers.patch(url, { sampleSavedSearch });
  },
};
