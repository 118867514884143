import React from 'react';
import PropTypes from 'prop-types';

import SingleSignOnPresenter from 'presenters/SingleSignOnPresenter';

import { Field, Input, Textarea } from 'components/v2/Form';
import Typography from 'components/v2/Typography';

import styles from './IdentityProviderSettingsFields.module.css';

const IdentityProviderSettingsFields = (props) => {
  const { errors, onFieldChange, ssoValues } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div className={styles.fields}>
      <Typography variant="h3"> Identity Provider Settings</Typography>
      <Field title="Single Sign On Url" error={errors.siteUrl} variant="outlined">
        <Input onChange={handleChange('siteUrl')} value={ssoValues.siteUrl} />
      </Field>
      <Field title="Certificate:" error={errors.cert} variant="outlined">
        <Textarea className={styles.textarea} onChange={handleChange('cert')} value={ssoValues.cert} />
      </Field>
    </div>
  );
};

IdentityProviderSettingsFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  ssoValues: SingleSignOnPresenter.shape().isRequired,
};

export default IdentityProviderSettingsFields;
