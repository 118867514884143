import { equals, propEq, update } from 'ramda';
import SavedSearchesContainerConstants from './SavedSearchesContainerConstants';

const initialState = {
  isLoading: true,
  meta: { nextPage: 1, totalCount: 0 },
  savedSearches: [],
  isDeleting: false,
  isCreating: false,
  isUpdating: false,
  filters: {},
};

function SavedSearchesContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SavedSearchesContainerConstants.FILTER_CURRENT_USER_SAVED_SEARCHES_START: {
      const { filters } = action;

      return { ...initialState, filters, isLoading: true };
    }
    case SavedSearchesContainerConstants.FILTER_CURRENT_USER_SAVED_SEARCHES_SUCCESS: {
      const { items, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      return { ...state, isLoading: false, meta, savedSearches: items };
    }
    case SavedSearchesContainerConstants.FILTER_CURRENT_USER_SAVED_SEARCHES_ERROR: {
      return { ...state, isLoading: false };
    }
    case SavedSearchesContainerConstants.LOAD_CURRENT_USER_SAVED_SEARCHES_START: {
      return { ...state, isLoading: true };
    }
    case SavedSearchesContainerConstants.LOAD_CURRENT_USER_SAVED_SEARCHES_SUCCESS: {
      const { items, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      const savedSearches = [...state.savedSearches, ...items];

      return { ...state, isLoading: false, savedSearches, meta };
    }
    case SavedSearchesContainerConstants.LOAD_CURRENT_USER_SAVED_SEARCHES_ERROR: {
      return { ...state, isLoading: false };
    }
    case SavedSearchesContainerConstants.CREATE_CURRENT_USER_SAVED_SEARCH_START: {
      return { ...state, isCreating: true };
    }
    case SavedSearchesContainerConstants.CREATE_CURRENT_USER_SAVED_SEARCH_SUCCESS: {
      return { ...state, isCreating: false };
    }
    case SavedSearchesContainerConstants.CREATE_CURRENT_USER_SAVED_SEARCH_ERROR: {
      return { ...state, isCreating: false };
    }
    case SavedSearchesContainerConstants.UPDATE_CURRENT_USER_SAVED_SEARCH_START: {
      return { ...state, isUpdating: true };
    }
    case SavedSearchesContainerConstants.UPDATE_CURRENT_USER_SAVED_SEARCH_SUCCESS: {
      const { savedSearch } = action;
      const savedSearchIndex = state.savedSearches.findIndex(propEq(savedSearch.id, 'id'));
      const savedSearches = update(savedSearchIndex, savedSearch, state.savedSearches);

      return { ...state, savedSearches, isUpdating: false };
    }
    case SavedSearchesContainerConstants.UPDATE_CURRENT_USER_SAVED_SEARCH_ERROR: {
      return { ...state, isUpdating: false };
    }
    case SavedSearchesContainerConstants.DELETE_CURRENT_USER_SAVED_SEARCH_START: {
      return { ...state, isDeleting: true };
    }
    case SavedSearchesContainerConstants.DELETE_CURRENT_USER_SAVED_SEARCH_SUCCESS: {
      const { id } = action;
      const savedSearches = state.savedSearches.filter((savedSearch) => savedSearch.id !== id);

      return { ...state, savedSearches, isDeleting: false };
    }
    case SavedSearchesContainerConstants.DELETE_CURRENT_USER_SAVED_SEARCH_ERROR: {
      return { ...state, isDeleting: false };
    }
    case SavedSearchesContainerConstants.CHANGE_CURRENT_USER_SAVED_SEARCH: {
      const { savedSearch } = action;
      const savedSearchIndex = state.savedSearches.findIndex(propEq(savedSearch.id, 'id'));
      const savedSearches = update(savedSearchIndex, savedSearch, state.savedSearches);

      return { ...state, savedSearches };
    }
    default:
      return state;
  }
}

export default SavedSearchesContainerReducer;
