import Routes from 'routes/App';
import { isPresent } from 'utils/HelperMethods';
import { storeCsrfToken } from 'utils/FetchHelpers/FetchHelpers';
import { storeCurrentUserId } from 'utils/currentUserHelper';
import AuthenticatorConstants from './AuthenticatorConstants';
import CurrentUserRepository from 'repositories/CurrentUserRepository';
import ImpersonalizationRepository from 'repositories/admin/ImpersonalizationRepository';
import { addNotification } from 'containers/Notifications';
import { setAmplitudeUser } from 'utils/amplitude';

export function loadCurrentUser() {
  return (dispatch) => {
    dispatch({ type: AuthenticatorConstants.LOAD_CURRENT_USER_START });

    return CurrentUserRepository.show()
      .then(({ user, meta }) => {
        const csrfToken = isPresent(meta) ? meta.csrfToken : null;
        const currentUserId = isPresent(user) ? user.id : null;

        setAmplitudeUser(user);
        storeCurrentUserId(currentUserId);
        storeCsrfToken(csrfToken);

        dispatch({ type: AuthenticatorConstants.LOAD_CURRENT_USER_SUCCESS, user });
      })
      .catch((error) => {
        dispatch({ type: AuthenticatorConstants.LOAD_CURRENT_USER_ERROR });

        throw error;
      });
  };
}

export function reloadCurrentUser() {
  return (dispatch) => {
    dispatch({ type: AuthenticatorConstants.RELOAD_CURRENT_USER_START });

    return CurrentUserRepository.show()
      .then(({ user, meta }) => {
        const csrfToken = isPresent(meta) ? meta.csrfToken : null;
        const currentUserId = isPresent(user) ? user.id : null;

        storeCurrentUserId(currentUserId);
        storeCsrfToken(csrfToken);

        dispatch({ type: AuthenticatorConstants.RELOAD_CURRENT_USER_SUCCESS, user });

        return user;
      })
      .catch((error) => {
        dispatch({ type: AuthenticatorConstants.RELOAD_CURRENT_USER_ERROR });

        throw error;
      });
  };
}

export function updateCurrentUser(attributes) {
  return (dispatch) => {
    dispatch({ type: AuthenticatorConstants.UPDATE_CURRENT_USER_START });

    return CurrentUserRepository.update(attributes)
      .then(({ user }) => {
        dispatch({ type: AuthenticatorConstants.UPDATE_CURRENT_USER_SUCCESS, user });
        addNotification('User was successfully updated', 'normal')(dispatch);

        return user;
      })
      .catch((error) => {
        dispatch({ type: AuthenticatorConstants.UPDATE_CURRENT_USER_ERROR, error });

        throw error;
      });
  };
}

export function stopImpersonate() {
  return () =>
    ImpersonalizationRepository.destroy().then(() => {
      window.location = Routes.adminUsersPath();
    });
}
