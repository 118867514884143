import React, { Component } from 'react';
import { Paragraph, Anchor, Box } from 'grommet';

import { formattedDate } from 'utils/DateTime';

import Headline from 'components/grommet/Headline';
import Card from 'components/Card';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

import styles from './OptedOut.module.css';

class OptedOut extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape(),
  };

  renderCurrentWork = (currentWork) => (
    <Paragraph key={WorkPresenter.id(currentWork)} margin="none" className={styles.organization}>
      {WorkPresenter.fullJobTitle(currentWork)}
      <br />
      {WorkPresenter.organizationNameWithState(currentWork)}
    </Paragraph>
  );

  render() {
    const { profile } = this.props;
    const currentWorks = ProfilePresenter.currentWorks(profile);
    const optedOutAt = ProfilePresenter.optedOutAt(profile);

    return (
      <div className={styles.root}>
        <Box pad="small">
          <Card>
            <Headline strong size="small" margin="none" className={styles.fullName}>
              {ProfilePresenter.fullNameWithSalutation(profile)}
            </Headline>
            {currentWorks.map(this.renderCurrentWork)}
            <Paragraph margin="none" className={styles.fullWidth}>
              This profile was opted-out at {formattedDate(optedOutAt)} in accordance with recently enacted{' '}
              <Anchor href="https://gdpr-info.eu/" target="_blank">
                General Data Protection Regulations (&quot;GDPR&quot;)
              </Anchor>
            </Paragraph>
          </Card>
        </Box>
      </div>
    );
  }
}

export default OptedOut;
