import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(id) {
    const url = Routes.apiV1AdminInterestPath(id);
    return FetchHelpers.get(url);
  },

  create(params) {
    const url = Routes.apiV1AdminInterestsPath();
    return FetchHelpers.post(url, params);
  },

  update(id, interest) {
    const url = Routes.apiV1AdminInterestPath(id);
    return FetchHelpers.put(url, { interest });
  },

  index(query) {
    const url = Routes.apiV1AdminInterestsPath(query);
    return FetchHelpers.get(url);
  },

  bulkDestroy(query) {
    const url = Routes.bulkDestroyApiV1AdminInterestsPath(query);
    return FetchHelpers.delete(url);
  },
};
