import TargetingSearchFormConstants from './TargetingSearchFormConstants';
import TargetingSearchRepository from 'repositories/CurrentUser/TargetingSearchRepository';
import { decamelizeString } from 'utils/keysConverter';

export function loadProfiles({ targetItems, page, per, sort }) {
  return (dispatch) => {
    dispatch({ type: TargetingSearchFormConstants.LOAD_TARGETED_PROFILES_START });

    const params = { targetItems, sort, page: page || 1, per: per || 100 };

    return TargetingSearchRepository.index(params).then(({ items, meta }) => {
      dispatch({ type: TargetingSearchFormConstants.LOAD_TARGETED_PROFILES_SUCCESS, profiles: items, meta });

      return meta;
    });
  };
}

export function autocomplete(filter, value, targetItem) {
  return () => {
    const params = { autocomplete: { [decamelizeString(filter)]: value }, targetItems: [targetItem] };

    return TargetingSearchRepository.autocomplete(params);
  };
}
