import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(id) {
    const url = Routes.apiV1AdminOrganizationPath(id);
    return FetchHelpers.get(url);
  },

  create(params) {
    const url = Routes.apiV1AdminOrganizationsPath();
    return FetchHelpers.post(url, params);
  },

  index(params = {}) {
    const url = Routes.apiV1AdminOrganizationsPath(params);
    return FetchHelpers.get(url);
  },

  bulkDestroy(params) {
    const url = Routes.bulkDestroyApiV1AdminOrganizationsPath(params);
    return FetchHelpers.delete(url);
  },
};
