import keyMirror from 'keymirror';

const InterestPageConstants = keyMirror({
  LOAD_INTEREST_START: null,
  LOAD_INTEREST_SUCCESS: null,

  UPDATE_INTEREST_START: null,
  UPDATE_INTEREST_SUCCESS: null,
  UPDATE_INTEREST_ERROR: null,
});

export default InterestPageConstants;
