import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, useStore } from 'react-redux';
import cn from 'clsx';
import { Layer } from 'grommet';
import { Close as CloseIcon } from 'grommet-icons';

import Button from 'components/Button';

import styles from './UserModal.module.css';

const handleKeyDown = (event) => {
  if (event.keyCode === 27) {
    event.stopPropagation();
  }
};

const UserModal = (props) => {
  const { children, size, onClose, overflow, disableOverlayClose, disableCloseByEsc, disableCloseIcon } = props;

  const LayerStyles = cn([styles.modal], {
    [styles.overflow]: overflow,
  });

  const containerSize = styles[size];
  const containerStyles = cn(containerSize, [styles.container]);
  const store = useStore();

  useEffect(() => {
    if (disableCloseByEsc) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (disableCloseByEsc) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  const handleEsc = () => (disableCloseByEsc ? () => {} : onClose);
  const handleClickOutside = () => (disableOverlayClose ? () => {} : onClose);

  return (
    <Layer onEsc={handleEsc} onClickOutside={handleClickOutside} className={LayerStyles} animation="none">
      <Provider store={store}>
        <div className={containerStyles}>
          <div className={styles.containerPadding}>
            <div className={styles.close}>
              {!disableCloseIcon ? (
                <Button onClick={onClose} className={styles.close}>
                  <CloseIcon size="small" />
                </Button>
              ) : null}
            </div>
            {children}
          </div>
        </div>
      </Provider>
    </Layer>
  );
};

UserModal.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  onClose: PropTypes.func,
  overflow: PropTypes.bool,
  disableOverlayClose: PropTypes.bool,
  disableCloseByEsc: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
};

UserModal.defaultProps = {
  disableOverlayClose: false,
  disableCloseByEsc: false,
};

export default UserModal;
