import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import { Anchor, Box, Heading } from 'grommet';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import RequestAssigneeBlock from 'containers/AdminPanel/components/RequestAssigneeBlock';
import RequestPrioritySelect from 'containers/AdminPanel/components/RequestPrioritySelect';
import SubjectAttachmentForm from './components/SubjectAttachmentForm';
import AssignToEditorModal from './components/AssignToEditorModal';

import RequestPolicy from 'policies/RequestPolicy';
import RequestPresenter from 'presenters/RequestPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import styles from './RequestDetailsPopup.module.css';

const RequestDetailsPopup = (props) => {
  const {
    request,
    onUpdate,
    onMove,
    onAssignEditor,
    currentUser,
    searchUser,
    searchSubject,
    onSubjectCreate,
    onClose,
    loading,
    title,
    moveRequestPopupOpened,
    saving,
  } = props;

  const [isAssignEditorModalOpened, setIsAssignEditorModalOpened] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleRequestUpdateField = (field) => (value) => {
    const params = { ...request, [field]: value };

    return onUpdate(params);
  };

  const handleRequestMove = (stateEvent) => {
    const params = { ...request, stateEvent };

    return onMove(params);
  };

  const handleAssigneeChange = (user) => handleRequestUpdateField('assigneeId')(user ? user.id : null);

  const handleRequestStateUpdate = (value) => () =>
    handleRequestMove(value).catch((data) => {
      setErrors(data.errors);
    });

  const handleOpenAssignEditorModal = () => setIsAssignEditorModalOpened(true);

  const handleCloseAssignEditorModal = () => setIsAssignEditorModalOpened(false);

  const handleAssignEditor = (params) => onAssignEditor(request, params).then(handleCloseAssignEditorModal);

  const getSubjectLastUpdatedAt = () => {
    const subject = RequestPresenter.subject(request);

    if (RequestPresenter.isSubjectProfile(request)) {
      return ProfilePresenter.formattedProfileUpdatedAt(subject);
    }

    if (RequestPresenter.isSubjectOrganization(request)) {
      return OrganizationPresenter.formattedOrganizationUpdatedAt(subject);
    }

    return null;
  };

  const renderEventButton = ({ key, title: newTitle }) => (
    <Box key={key} margin={{ right: 'small', bottom: 'small', top: 'small' }}>
      <Button disabled={saving} label={newTitle} onClick={handleRequestStateUpdate(key)} className={styles[key]} />
    </Box>
  );

  const renderCancelationConfirmation = () => (
    <Box margin={{ vertical: 'small' }} className={styles.cancelationConfirmation}>
      <Heading weight="bold" level="4" margin="none">
        Cancellation Confirmation
      </Heading>
      <Box margin={{ vertical: 'small' }}>{RequestPresenter.cancelationConfirmation(request)}</Box>
    </Box>
  );

  const renderRequestDetails = () => {
    const subject = RequestPresenter.subject(request);
    const requestor = RequestPresenter.requestor(request);
    const subjectLastUpdatedAt = getSubjectLastUpdatedAt();
    const previousAssignee = RequestPresenter.previousAssignee(request);
    const company = RequestPresenter.company(request);
    const requestorCompanyName = CompanyPresenter.name(company);

    return (
      <>
        {RequestPresenter.isSubjectProfile(request) && (
          <Box margin={{ bottom: 'small' }}>{ProfilePresenter.primaryCurrentWorkTitle(subject)}</Box>
        )}
        <Box direction="row">
          {RequestPresenter.humanStateEvents(request).map((stateEvent) => renderEventButton(stateEvent))}
        </Box>

        {errors && errors.subject && <div className={styles.error}>{errors.subject.join(', ')}</div>}

        <Box margin={{ vertical: 'small' }}>
          <SubjectAttachmentForm
            currentUser={currentUser}
            onSearchSubject={searchSubject}
            onSubjectCreate={onSubjectCreate}
            onSubjectUpdate={handleRequestUpdateField('subjectId')}
            request={request}
            saving={saving}
          />
        </Box>
        <Box direction="row">
          <Box className={styles.column}>
            <Box margin={{ vertical: 'small' }} className={styles.item}>
              <div className={styles.label}>Type</div>
              <Box> {RequestPresenter.humanRequestType(request)} </Box>
            </Box>
            {requestor && (
              <Box margin={{ vertical: 'small' }} className={styles.item}>
                <div className={styles.label}>Requestor</div>
                <Box>{UserPresenter.fullName(requestor)}</Box>
              </Box>
            )}
            <Box margin={{ vertical: 'small' }} className={styles.item}>
              <div className={styles.label}>Request Date</div>
              <Box>{RequestPresenter.formattedCreatedAt(request)}</Box>
            </Box>
            {RequestPresenter.cancelationReason(request) && (
              <Box margin={{ vertical: 'small' }} className={styles.item}>
                <div className={styles.label}>Cancellation Reason</div>
                <Box className={styles.cancelationReason}>
                  {RequestPresenter.humanCancelationReason(request)}
                  {isPresent(RequestPresenter.cancelationConfirmation(request)) && (
                    <RCTooltip
                      placement="top"
                      mouseLeaveDelay={1}
                      trigger={['hover', 'click']}
                      overlay={renderCancelationConfirmation}
                    >
                      <Anchor className={styles.cancelationConfirmationLink} label="See Confirmation" />
                    </RCTooltip>
                  )}
                </Box>
              </Box>
            )}
            {RequestPresenter.formattedDueDate(request) && (
              <Box margin={{ vertical: 'small' }}>
                <div className={styles.label}>Due Date</div>
                <Box>{RequestPresenter.formattedDueDate(request)}</Box>
              </Box>
            )}
          </Box>
          <Box className={styles.column}>
            {subjectLastUpdatedAt && (
              <Box margin={{ vertical: 'small' }} className={styles.item}>
                <div className={styles.label}>Last Updated</div>
                <Box> {subjectLastUpdatedAt} </Box>
              </Box>
            )}
            <Box margin={{ vertical: 'small' }} className={styles.item}>
              <div className={styles.label}>Status</div>
              <Box>{RequestPresenter.stateName(request)} </Box>
            </Box>
            {requestorCompanyName && (
              <Box margin={{ vertical: 'small' }} className={styles.item}>
                <div className={styles.label}>Requestor Company</div>
                <Box>{requestorCompanyName} </Box>
              </Box>
            )}
            {previousAssignee && (
              <Box margin={{ vertical: 'small' }} className={styles.item}>
                <div className={styles.label}>Previously Assignee</div>
                <Box>{UserPresenter.fullName(previousAssignee)} </Box>
              </Box>
            )}
            <Box margin={{ vertical: 'small' }} className={styles.item}>
              <RequestAssigneeBlock
                currentUser={currentUser}
                onUserChange={handleAssigneeChange}
                onUserSearch={searchUser}
                request={request}
                saving={saving}
              />
            </Box>
            {RequestPolicy.canAssignEditor(currentUser, request) && (
              <Box margin={{ vertical: 'small' }}>
                <Button disabled={saving} label="Assign to Editor" onClick={handleOpenAssignEditorModal} />
              </Box>
            )}
            <Box>
              <RequestPrioritySelect
                currentUser={currentUser}
                isDisabled={saving}
                onChange={handleRequestUpdateField('priority')}
                request={request}
                value={RequestPresenter.priority(request)}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const subheader = request && RequestPresenter.subtitle(request);

  if (moveRequestPopupOpened) {
    return null;
  }

  if (isAssignEditorModalOpened) {
    return (
      <AssignToEditorModal
        onClose={handleCloseAssignEditorModal}
        onSubmit={handleAssignEditor}
        onUserSearch={searchUser}
        saving={saving}
      />
    );
  }

  return (
    <Modal header={title} subheader={subheader} onClose={onClose} size="large" overflow>
      <Box width="large"> {loading || !request ? <Spinner /> : renderRequestDetails()} </Box>
    </Modal>
  );
};

RequestDetailsPopup.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  saving: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  request: RequestPresenter.shape(),
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onSubjectCreate: PropTypes.func.isRequired,
  onAssignEditor: PropTypes.func.isRequired,
  searchSubject: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  title: PropTypes.string,
  moveRequestPopupOpened: PropTypes.bool.isRequired,
};

export default RequestDetailsPopup;
