import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextInput, Box } from 'grommet';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import LanguageEditForm from 'forms/LanguageEditForm';
import LanguagePresenter from 'presenters/LanguagePresenter';

class EditForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    language: LanguagePresenter.shape(),
  };

  constructor(...args) {
    super(...args);

    this.state = {
      language: LanguageEditForm.defaultAttributes(this.props.language),
      errors: null,
    };
  }

  handleSubmit = () => {
    const { language } = this.state;
    this.props.onSubmit(LanguageEditForm.attributesToSubmit(language, this.props.language)).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  updateField(fieldName, value) {
    this.setState({ language: { ...this.state.language, [fieldName]: value } });
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  renderTextInput(label, fieldName) {
    const { language, errors } = this.state;
    const { [fieldName]: value } = language;

    return (
      <VerticalFormFieldWithBottomErrors label={label} errors={errors && errors[fieldName]} htmlFor={fieldName}>
        <TextInput value={value} onChange={this.handleTextFieldChange(fieldName)} id={fieldName} />
      </VerticalFormFieldWithBottomErrors>
    );
  }

  render() {
    const { saving } = this.props;

    return (
      <div>
        {this.renderTextInput('Name:', 'name')}
        {this.renderTextInput('Native Name:', 'nativeName')}
        {this.renderTextInput('ISO-639-1:', 'alpha2')}

        <Box direction="row" margin={{ top: 'medium' }} size="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default EditForm;
