import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseLocationToParams } from 'utils/UrlHelper';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { fromISO } from 'utils/DateTime';

import { Anchor } from 'grommet';
import Spinner from 'components/Spinner';
import Logotype from 'components/v2/Logotype';

import useExportJobUrl from 'hooks/client/useExportJobUrl';

import styles from './DownloadPage.module.css';

const parseTokenFromUrl = ({ location }) => {
  const searchParams = parseLocationToParams(location);

  return isPresent(searchParams.token) ? decodeURIComponent(searchParams.token) : null;
};

const parseExportUrlFromUrl = ({ location }) => {
  const searchParams = parseLocationToParams(location);

  return isPresent(searchParams.export_url) ? decodeURIComponent(searchParams.export_url) : null;
};

const handleDownload = (url) => {
  window.location = url;
};

const isExportUrlValid = (url) => {
  if (isBlank(url)) return false;

  const location = new URL(url);
  const params = parseLocationToParams(location);
  const date = fromISO(params['X-Amz-Date']);
  const expiresIn = +params['X-Amz-Expires'];

  return isPresent(date) && isPresent(expiresIn) && Math.abs(date.diffNow('seconds').seconds) < expiresIn;
};

const DownloadPage = (props) => {
  const { history } = props;
  const token = parseTokenFromUrl(history);
  const exportUrl = parseExportUrlFromUrl(history);
  const [errors, setErrors] = useState({});
  const { isLoading, exportJobUrl, loadExportJobUrl } = useExportJobUrl();
  const showErrors = !isLoading && isPresent(errors);

  const downloadFromExportUrl = () => {
    if (!isExportUrlValid(exportUrl)) {
      setErrors({ token: ['Your access has expired.'] });
      return;
    }

    handleDownload(exportUrl);
  };

  useEffect(() => {
    if (isBlank(token) && isBlank(exportUrl)) {
      setErrors({ token: ['Sorry, something went wrong.'] });
      return;
    }

    if (isPresent(exportUrl)) {
      downloadFromExportUrl();
      return;
    }

    loadExportJobUrl(token)
      .then((url) => {
        handleDownload(url);
      })
      .catch((data) => {
        setErrors(data.errors);
      });
  }, []);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Logotype />
      </div>
      {showErrors && <div className={styles.header}>{errors.token.map((error) => error)}</div>}
      {!isLoading && isBlank(errors) && (
        <>
          <div className={styles.header}>
            These are big files we're sending you. Your download is in process. Thanks for your patience.
          </div>
          <div className={styles.note}>
            if the download doesn&#39;t automatically start, click{' '}
            <Anchor className={styles.link} onClick={() => handleDownload(exportJobUrl)}>
              here
            </Anchor>
          </div>
        </>
      )}
    </div>
  );
};

DownloadPage.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default DownloadPage;
