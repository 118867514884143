import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import ProjectPresenter from 'presenters/ProjectPresenter';

import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

import styles from './ProjectNameForm.module.css';

export default class ProjectNameForm extends Component {
  static propTypes = {
    form: ProjectPresenter.shape().isRequired,
    onNameChange: PropTypes.func.isRequired,
  };

  handleChange = ({ target: { value } }) => {
    const { onNameChange } = this.props;

    onNameChange(value);
  };

  render() {
    const { form } = this.props;

    return (
      <>
        <WizardTitle subtitle="Name your project." />
        <div className={styles.root}>
          <VerticalFormFieldWithBottomErrors label="Project name" htmlFor="name">
            <input type="text" value={form.name} onChange={this.handleChange} id="name" />
          </VerticalFormFieldWithBottomErrors>
        </div>
        <WizardNavigation isNextButtonDisabled={isBlank(form.name)} />
      </>
    );
  }
}
