import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import RequestStatisticPresenter from './RequestStatisticPresenter';

export default new Presenter(
  {
    totalCount: PropTypes.number,
    data: PropTypes.arrayOf(RequestStatisticPresenter.shape()),
  },
  {},
);
