import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrganizationList from './OrganizationList';
import * as actions from './OrganizationListActions';

const mapStateToProps = (state) => ({
  loading: state.OrganizationListReducer.loading,
  organizations: state.OrganizationListReducer.organizations,
  nextPage: state.OrganizationListReducer.nextPage,
  totalCount: state.OrganizationListReducer.totalCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
