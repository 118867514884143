import PropTypes from 'prop-types';

import Presenter from '../utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';

export const STATES = [
  {
    key: 'active',
    title: 'Active',
  },
  {
    key: 'disabled',
    title: 'Disabled',
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    title: PropTypes.string,
    state: PropTypes.string,
    createdAt: PropTypes.string,
    isRecentlyUpdated: PropTypes.bool,
  },
  {
    stateName(u) {
      const state = this.state(u);
      return STATES.find((r) => r.key === state).title;
    },
    formattedCreatedAt(u) {
      return formattedDate(this.createdAt(u));
    },
    isActive(u) {
      return this.state(u) === 'active';
    },
  },
);
