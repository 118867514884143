import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import Select from 'react-select/creatable';

import customStyles from './styles';
import styles from './MultiplyCreatableSelect.module.css';

class MultiplyCreatableSelect extends Component {
  state = { inputValue: '' };

  handleInputValueChange = (inputValue) => this.setState({ inputValue });

  handleKeyPress = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        this.handleCreateNewValue();
        event.preventDefault();
        break;
      default:
    }
  };

  handleCreateNewValue = () => {
    const { onCreateNewValue } = this.props;
    const { inputValue } = this.state;

    if (!inputValue) {
      return;
    }

    if (onCreateNewValue(inputValue)) {
      this.setState({ inputValue: '' });
    }
  };

  rootStyles = () => {
    const { errors } = this.props;
    return cn([styles.root], { [styles.error]: errors });
  };

  render() {
    const { components, onCreateNewValue, errors, inlinePlaceholder, placeholder, ...rest } = this.props;
    const { inputValue } = this.state;
    const errorsText = Array.isArray(errors) ? errors.join(', ') : errors;

    return (
      <div className={this.rootStyles()}>
        <div className={styles.info}>
          {!inlinePlaceholder && <div className={styles.placeholder}>{placeholder}</div>}
          <div className={styles.errorText}>{errorsText}</div>
        </div>
        <Select
          {...rest}
          placeholder={inlinePlaceholder && isPresent(placeholder) ? placeholder : ''}
          components={{ ...components, DropdownIndicator: null, ClearIndicator: null }}
          onKeyDown={this.handleKeyPress}
          onBlur={this.handleCreateNewValue}
          inputValue={inputValue}
          onInputChange={this.handleInputValueChange}
          isMulti
          menuIsOpen={false}
          classNamePrefix="react-select"
          styles={customStyles}
        />
      </div>
    );
  }
}

MultiplyCreatableSelect.propTypes = {
  components: PropTypes.shape({}),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  onCreateNewValue: PropTypes.func,
  placeholder: PropTypes.string,
  inlinePlaceholder: PropTypes.bool,
};

MultiplyCreatableSelect.defaultProps = {
  inlinePlaceholder: false,
};

export default MultiplyCreatableSelect;
