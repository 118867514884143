import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Box } from 'grommet';

import { reactSelectDebounce } from 'utils/HelperMethods';

import Modal from 'components/Modal';
import AsyncSelect from 'components/AsyncSelect';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import TagPresenter from 'presenters/TagPresenter';
import TagEditForm from 'forms/TagEditForm';

class NewTagModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
  };

  state = {
    fields: TagEditForm.defaultAttributes({}),
    errors: null,
  };

  handleUserChange = (parent) => {
    this.setState({ fields: { ...this.state.fields, parent } });
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { fields } = this.state;

    onSave(TagEditForm.attributesToSubmit(fields)).catch((response) => {
      if (response.errors) {
        this.setState({ errors: response.errors });
      }
    });
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ fields: { ...this.state.fields, title: value } });
  };

  handleTagSearch = reactSelectDebounce(this.props.onTagSearch);

  render() {
    const { onClose } = this.props;
    const { errors } = this.state;

    return (
      <Modal onClose={onClose} size="medium" header="New Tag" overflow>
        <VerticalFormFieldWithBottomErrors label="Title" errors={errors && errors.title} htmlFor="title">
          <TextInput onChange={this.handleChange} id="title" />
        </VerticalFormFieldWithBottomErrors>

        <AsyncSelect
          loadOptions={this.handleTagSearch}
          getOptionValue={TagPresenter.id}
          getOptionLabel={TagPresenter.fullTitle}
          defaultOptions
          onChange={this.handleUserChange}
          placeholder="Parent Tag"
          isClearable
        />

        <Box direction="row" margin={{ top: 'small' }} justify="center">
          <Button label="Save" onClick={this.handleSave} primary />
        </Box>
      </Modal>
    );
  }
}

export default NewTagModal;
