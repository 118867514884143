import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { imagePath } from 'utils/AssetsHelper';

import Button from 'components/Button/Button';

import SourceForm from 'forms/project/SourceForm';
import ProjectPresenter from 'presenters/ProjectPresenter';

import { WizardTitle } from 'components/v2/FramedWizard';

import styles from './CompleteSourceCreation.module.css';

export default class CompleteSourceCreation extends Component {
  static propTypes = {
    project: ProjectPresenter.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    form: PropTypes.shape().isRequired,
    createProjectSource: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { createProjectSource, form, project, saved } = this.props;

    if (!saved) {
      createProjectSource(ProjectPresenter.id(project), SourceForm.attributesToSubmit(form));
    }
  }

  renderLoader() {
    return (
      <div className={styles.loader}>
        <img className={styles.loaderImg} src={imagePath('project-creation-loader.gif')} alt="loading" />
      </div>
    );
  }

  renderComponent() {
    const { loading, saved, project } = this.props;

    if (!saved || loading) {
      return this.renderLoader();
    }

    return (
      <div className={styles.root}>
        <div className={styles.title}>{`Your list has been added to ${ProjectPresenter.name(project)}.`}</div>
        <Button label="Go to my Project" primary href={Routes.projectPath(ProjectPresenter.id(project))} />
      </div>
    );
  }

  render() {
    const { loading } = this.props;

    return (
      <>
        {loading && <WizardTitle title="Please wait, we are saving your list." />}
        {this.renderComponent()}
      </>
    );
  }
}
