import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SampleProfilesPage from './SampleProfilesPage';
import * as actions from './SampleProfilesPageActions';

const mapStateToProps = (state) => ({
  loading: state.SampleProfilesPageReducer.loading,
  sampleProfiles: state.SampleProfilesPageReducer.sampleProfiles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SampleProfilesPage);
