import keyMirror from 'keymirror';

const SharesContainerConstants = keyMirror({
  FILTER_PROJECT_SHARES_START: null,
  FILTER_PROJECT_SHARES_SUCCESS: null,

  LOAD_PROJECT_SHARES_START: null,
  LOAD_PROJECT_SHARES_SUCCESS: null,

  BATCH_UPDATE_PROJECT_SHARES_START: null,
  BATCH_UPDATE_PROJECT_SHARES_SUCCESS: null,
  BATCH_UPDATE_PROJECT_SHARES_ERROR: null,

  DESTROY_PROJECT_SHARE_START: null,
  DESTROY_PROJECT_SHARE_SUCCESS: null,
  DESTROY_PROJECT_SHARE_ERROR: null,
});

export default SharesContainerConstants;
