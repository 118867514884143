import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { toLower, pipe, join, map } from 'ramda';

import UserPresenter from 'presenters/UserPresenter';

import { Checkbox } from 'components/v2/Form';

import styles from './SelectableUserItem.module.css';

const formatErrors = (errors) => pipe(map(toLower), join(' | '))(errors);

const SelectableUserItem = (props) => {
  const { user, isSelectable, isSelected, onSelectionChange } = props;
  const { errors } = user;

  return (
    <div className={styles.user}>
      <div className={styles.userContent}>
        <div className={styles.userSelector}>
          {isSelectable && <Checkbox checked={isSelected} onChange={onSelectionChange} />}
        </div>
        <div className={styles.userInfo}>
          <div className={styles.userInfoItem}>
            <div className={styles.userInfoItemLabel}>Index</div>
            <div className={styles.userInfoItemContent}>{user.index}</div>
          </div>

          <div className={cn(styles.userInfoItem, { [styles.error]: errors?.firstName })}>
            <div className={styles.userInfoItemLabel}>First Name</div>
            <div>
              <div className={styles.userInfoItemContent}>{UserPresenter.firstName(user)}</div>
              {errors?.firstName && <div className={styles.userInfoItemError}>{formatErrors(errors.firstName)}</div>}
            </div>
          </div>

          <div className={cn(styles.userInfoItem, { [styles.error]: errors?.lastName })}>
            <div className={styles.userInfoItemLabel}>Last Name</div>
            <div>
              <div className={styles.userInfoItemContent}>{UserPresenter.lastName(user)}</div>
              {errors?.lastName && <div className={styles.userInfoItemError}>{formatErrors(errors.lastName)}</div>}
            </div>
          </div>

          <div className={cn(styles.userInfoItem, { [styles.error]: errors?.email })}>
            <div className={styles.userInfoItemLabel}>Email</div>
            <div>
              <div className={styles.userInfoItemContent}>{UserPresenter.email(user)}</div>
              {errors?.email && <div className={styles.userInfoItemError}>{formatErrors(errors.email)}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectableUserItem.propTypes = {
  user: UserPresenter.shape(),
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelectionChange: PropTypes.func,
};

SelectableUserItem.defaultProps = {
  isSelectable: false,
};

export default SelectableUserItem;
