import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextInput } from 'grommet';

import { debounce, isBlank } from 'utils/HelperMethods';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import Routes from 'routes';

import Button from 'components/Button';
import Select from 'components/Select';
import CsvIcon from 'icons/CsvIcon';
import Table from 'components/Table';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import NewUserForm from './components/NewUserForm';
import UserRowCells from './components/UserRowCells';
import ExportButton from 'components/ExportButton';
import Link from 'components/v2/Link';

import Icon from 'components/Icon';

import styles from './CorporateAccountUserList.module.css';

import UserPolicy from 'policies/UserPolicy';
import UserPresenter, { ADMIN_COMPANY_USER_CSV_EXPORT } from 'presenters/UserPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import useBackgroundExportJobs from 'hooks/useBackgroundExportJobs';

const TABLE_COLUMNS = [
  {
    name: 'full name',
    field: 'fullName',
  },
  {
    name: 'email',
    field: 'email',
  },
  {
    name: 'Department',
    field: 'companyDepartmentName',
  },
  {
    name: 'Logins',
    field: 'signInEventsCount',
    disableSort: true,
  },
  {
    name: 'views/dl`s',
    field: 'viewAndDownloadEventsCount',
    disableSort: true,
  },
  {
    name: 'requests',
    field: 'publishedRequestEventsCount',
    disableSort: true,
  },
  {
    name: 'Individual Remaining Requests',
    field: 'remainingRequestsNumber',
    disableSort: true,
  },
  {
    name: 'Actions',
    field: '',
  },
];

const CorporateAccountUserList = (props) => {
  const {
    companyDepartments,
    filters,
    totalCount,
    users,
    onUserClick,
    currentUser,
    onImpersonate,
    filterUsers,
    bulkDestroyUsers,
    onUsersChange,
    loading,
    nextPage,
    loadUsers,
    createUser,
    companyId,
    userEventsExportType,
    isUsersImportVisible,
  } = props;

  const { createBackgroundExportJob, showBackgroundExportJob } = useBackgroundExportJobs();

  const [isAddUserFormVisible, setAddUserFormVisiblity] = useState(false);

  useEffect(() => {
    filterUsers({
      searchFieldCont: '',
      sortFields: [{ field: 'fullName', value: 'asc' }],
    });
  }, []);

  const isAllUsersLoaded = () => isBlank(nextPage);

  const handleDelete = (ids) => bulkDestroyUsers({ ids }).then(onUsersChange);

  const handleLoadMore = () => {
    if (!loading && !isAllUsersLoaded()) {
      loadUsers(nextPage, filters);
    }
  };

  const handleSort = (sortFields) => filterUsers({ ...filters, sortFields });

  const handleFilterUsers = (newFilters) => filterUsers({ ...filters, ...newFilters });

  const handleFilterUsersDebounced = debounce(handleFilterUsers);

  const handleSearchFieldChange = ({ target: { value } }) => handleFilterUsersDebounced({ searchFieldCont: value });

  const handleCompanyDepartmentIdChange = (companyDepartmentId) =>
    handleFilterUsersDebounced({ companyDepartmentIdEq: companyDepartmentId });

  const handleShowNewUserForm = () => setAddUserFormVisiblity(true);

  const handleHideNewUserForm = () => setAddUserFormVisiblity(false);

  const handleUserSubmit = (user) =>
    createUser(user)
      .then(handleHideNewUserForm)
      .then(() => {
        filterUsers({ searchFieldCont: '', sortFields: [] });
        onUsersChange();
      });

  const handleCSVExport = () =>
    createBackgroundExportJob({
      exportableId: companyId,
      exportableType: 'Company',
      type: ADMIN_COMPANY_USER_CSV_EXPORT,
      params: buildFilterParams(filters, 'search'),
    });

  const handleUserEventsCSVExport = (user) =>
    createBackgroundExportJob({
      exportableId: user.id,
      exportableType: 'User',
      type: userEventsExportType,
    });

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <div className={styles.search}>
          <TextInput placeholder="Start typing Name" onChange={handleSearchFieldChange} />
          <Icon name="search" />
        </div>

        <Box className={styles.select} width={{ min: '250px', max: '250px' }} margin={{ right: 'medium' }}>
          <Select
            isClearable
            options={companyDepartments}
            placeholder="Company Department"
            getOptionValue={DepartmentPresenter.id}
            getOptionLabel={DepartmentPresenter.name}
            onValueChange={handleCompanyDepartmentIdChange}
            noBottom
          />
        </Box>

        <div className={styles.actions}>
          <Button primary label="Add User" onClick={handleShowNewUserForm} />
          {UserPolicy.canExportList(currentUser) && (
            <BackgroundExportResultNotifier
              component={ExportButton}
              onExport={handleCSVExport}
              onPoll={showBackgroundExportJob}
              onExportPropName="onClick"
              label="Export"
              shouldOpenOnCompletion
            />
          )}
          {UserPresenter.isAdmin(currentUser) && isUsersImportVisible && (
            <Link to={Routes.newAdminCompanyUsersImportPath(companyId)} className={styles.importUsersAction}>
              Import Users From
              <CsvIcon />
            </Link>
          )}
        </div>
      </div>

      {isAddUserFormVisible && (
        <NewUserForm
          companyDepartments={companyDepartments}
          onSubmit={handleUserSubmit}
          onCancel={handleHideNewUserForm}
        />
      )}

      <Table
        columns={TABLE_COLUMNS}
        sorts={filters.sortFields}
        onDelete={handleDelete}
        onMore={handleLoadMore}
        onRowClick={onUserClick}
        onSort={handleSort}
        hasMore={!isAllUsersLoaded()}
        rows={users}
        rowCellsComponent={
          <UserRowCells
            onExport={handleUserEventsCSVExport}
            onPoll={showBackgroundExportJob}
            currentUser={currentUser}
            onImpersonate={onImpersonate}
          />
        }
        totalRows={totalCount}
        rowsDeletable
        className={styles.table}
      />
    </div>
  );
};

CorporateAccountUserList.propTypes = {
  createUser: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
  companyDepartments: PropTypes.arrayOf(DepartmentPresenter.shape()).isRequired,
  onImpersonate: PropTypes.func,
  onUsersChange: PropTypes.func,
  onUserClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  loadUsers: PropTypes.func.isRequired,
  bulkDestroyUsers: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
  filterUsers: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  totalCount: PropTypes.number,
  users: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
  companyId: PropTypes.number,
  userEventsExportType: PropTypes.string.isRequired,
  isUsersImportVisible: PropTypes.bool,
};

CorporateAccountUserList.defaultProps = {
  onUsersChange: () => {},
  onUserClick: () => {},
  isUsersImportVisible: false,
};

export default CorporateAccountUserList;
