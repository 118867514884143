import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Button from 'components/Button';
import Select from 'components/Select';
import Modal from 'containers/UserPanel/components/Modal';
import Spinner from 'components/Spinner';

import SubscriptionConfigurationPresenter, {
  getDayOptions,
  getFrequencyOptions,
  getDefaultDay,
} from 'presenters/SubscriptionConfigurationPresenter';

import styles from './SubscriptionConfigurationPopUp.module.css';

class SubscriptionConfigurationPopUp extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    kind: PropTypes.string,
    onLoadConfiguration: PropTypes.func,
  };

  state = {
    configuration: null,
    errors: {},
  };

  componentDidMount() {
    const { onLoadConfiguration, kind } = this.props;

    onLoadConfiguration(kind).then((configuration) => {
      this.setState({ configuration });
    });
  }

  handleFrequencyFieldChange = (frequency) => {
    const day = getDefaultDay(frequency);
    const configuration = { ...this.state.configuration, frequency, day };

    this.setState({ ...this.state, configuration });
  };

  handleDayFieldChange = (day) => {
    this.setState({ ...this.state, configuration: { ...this.state.configuration, day } });
  };

  handleSubmit = () => {
    const { onSave, onClose, kind } = this.props;
    const { configuration } = this.state;

    onSave(kind, configuration)
      .then(() => {
        onClose();
      })
      .catch(({ errors }) => {
        this.setState({ errors });
      });
  };

  renderLoader() {
    return (
      <div className={styles.loader} key="loader">
        <Spinner />
      </div>
    );
  }

  renderSelect(label, onChange, options, value, errors) {
    return (
      <div className={styles.content}>
        <div className={styles.selectLabel}>{label}</div>
        <Select
          className={styles.select}
          classNamePrefix="select"
          onValueChange={onChange}
          options={options}
          selectedOptionValue={value}
          errors={errors}
          noBottom
        />
      </div>
    );
  }

  renderForm() {
    const { configuration, errors } = this.state;
    const { loading, kind } = this.props;
    const frequency = SubscriptionConfigurationPresenter.frequency(configuration);
    const day = SubscriptionConfigurationPresenter.day(configuration);
    const frequencyOptions = getFrequencyOptions(kind);
    const dayOptions = getDayOptions(frequency);

    return (
      <>
        {this.renderSelect(
          'ALERT FREQUENCY',
          this.handleFrequencyFieldChange,
          frequencyOptions,
          frequency,
          errors.frequency,
        )}
        {dayOptions.length > 0 &&
          this.renderSelect('ALERT DATE', this.handleDayFieldChange, dayOptions, day, errors.day)}
        <div className={styles.actions}>
          <Button className={styles.save} label="Save" disabled={loading} primary onClick={this.handleSubmit} />
        </div>
      </>
    );
  }

  render() {
    const { configuration } = this.state;
    const { onClose, loading } = this.props;

    return (
      <Modal className={styles.modal} onClose={onClose} overlayClassName={styles.overlay}>
        <div className={styles.header}>Your Alert Settings</div>
        {loading || isBlank(configuration) ? this.renderLoader() : this.renderForm()}
      </Modal>
    );
  }
}

export default SubscriptionConfigurationPopUp;
