import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnRelationshipMappingAdvertisment } from 'utils/amplitude';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import RelationshipPresenter from 'presenters/RelationshipPresenter';

import ProfilePolicy from 'policies/ProfilePolicy';

import Routes from 'routes';

import Button from 'components/Button';
import Relationship from './components/Relationship';

import styles from './ProfileRelationshipMappingAdvertisement.module.css';

import banner from './images/banner.png';

const RELATIONSHIPS_COUNT = 3;
const MINIMUM_RELATIONSHIPS_COUNT = 3;

export default class ProfileRelationshipMappingAdvertisement extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    clearProfileRelationships: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadProfileRelationships: PropTypes.func.isRequired,
    profile: ProfilePresenter.shape().isRequired,
    relationships: PropTypes.arrayOf(RelationshipPresenter.shape()).isRequired,
  };

  componentDidMount() {
    const { loadProfileRelationships, profile } = this.props;
    const profileId = ProfilePresenter.id(profile);

    loadProfileRelationships(profileId, { per: RELATIONSHIPS_COUNT });
  }

  componentWillUnmount() {
    const { clearProfileRelationships } = this.props;

    clearProfileRelationships();
  }

  renderRelationshipsList() {
    const { profile, relationships } = this.props;
    const currentProfileId = ProfilePresenter.id(profile);
    const currentProfileSlug = ProfilePresenter.slug(profile);

    return (
      <>
        <div className={styles.relationshipsHeader}>{ProfilePresenter.firstName(profile)} is connected to:</div>
        <div>
          {relationships.map((relationship) => (
            <Relationship
              key={RelationshipPresenter.id(relationship)}
              currentProfileId={currentProfileId}
              currentProfileSlug={currentProfileSlug}
              relationship={relationship}
            />
          ))}
        </div>
        <div className={styles.relationshipsControls}>
          <Button
            className={styles.relationshipsButton}
            label="See How"
            primary
            href={Routes.profileRelationshipsMappingPath(currentProfileSlug)}
            target="_blank"
            onClick={logClickOnRelationshipMappingAdvertisment}
          />
        </div>
      </>
    );
  }

  renderPromotionBanner() {
    const { profile } = this.props;

    return (
      <>
        <img src={banner} alt="banner" />
        <div className={styles.promotionHeader}>
          Want to know who {ProfilePresenter.firstName(profile)} is connected to?
        </div>
        <div className={styles.promotionControls}>
          <Button
            href={Routes.biProUrl()}
            target="_blank"
            className={styles.promotionButton}
            label="Check out our new Relationship Maps"
            onClick={logClickOnRelationshipMappingAdvertisment}
            primary
          />
        </div>
      </>
    );
  }

  render() {
    const { currentUser, loading, relationships } = this.props;

    if (loading || relationships.length === 0) {
      return null;
    }

    if (
      relationships.length < MINIMUM_RELATIONSHIPS_COUNT &&
      !ProfilePolicy.canSeeRelationshipsMappingPromotionBanner(currentUser)
    ) {
      return null;
    }

    return (
      <div className={styles.root}>
        {relationships.length >= MINIMUM_RELATIONSHIPS_COUNT
          ? this.renderRelationshipsList()
          : this.renderPromotionBanner()}
      </div>
    );
  }
}
