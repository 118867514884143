import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Anchor } from 'grommet';

const AnchorLink = (props) => <Anchor as={Link} {...props} />;

AnchorLink.propTypes = {
  to: PropTypes.string,
};

AnchorLink.defaultProps = {
  to: '#',
};

export default AnchorLink;
