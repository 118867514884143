import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import styles from './PageOverlay.module.css';

export default class PageOverlay extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    dark: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    dark: false,
  };

  render() {
    const { children, dark, className } = this.props;
    const classNames = cn(styles.overlay, styles.row, { [styles.dark]: dark, [className]: isPresent(className) });

    return <div className={classNames}>{children}</div>;
  }
}
