import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import styles from './SortableItem.module.css';

const SortableItem = SortableElement(({ children }) => <li className={styles.root}> {children} </li>);

SortableItem.propTypes = {
  children: PropTypes.node,
};

export default SortableItem;
