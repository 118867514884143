import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import ProjectsRepository from 'repositories/admin/Companies/Dashboard/ProjectsRepository';

const restSlice = createRestSlice({
  resource: 'project',
  repository: ProjectsRepository,
  slices: ['loadProjects', 'loadMoreProjects'],
});

const initialState = {
  ...restSlice.initialState,
};

const projectsSlice = createSlice({
  name: 'adminCompanyDashboardProjects',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDashboardProjectsReducer = projectsSlice.reducer;

export default projectsSlice;

export const adminCompanyDashboardProjectsRestHooks = restSlice.hooks(
  projectsSlice,
  'adminCompanyDashboardProjectsReducer',
);
