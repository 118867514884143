import PropTypes from 'prop-types';

const WizardStep = (props) => {
  const { children } = props;

  return children;
};

WizardStep.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default WizardStep;
