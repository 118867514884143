import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrganizationSelector from './OrganizationSelector';
import * as actions from './OrganizationSelectorActions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSelector);
