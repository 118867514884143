import PropTypes from 'prop-types';
import React from 'react';
import { filter, partition, pipe } from 'ramda';

import ExecutiveMatchPresenter from 'presenters/ExecutiveMatchPresenter';

import Tabs, { Tab, TabList, TabPanel } from 'components/v2/Tabs';

import ExecutivesMatches from './components/ExecutivesMatches';

import styles from './ExecutivesMatchesReview.module.css';

const ExecutivesMatchesReview = (props) => {
  const {
    executivesMatches,
    incorrectExecutiveMatchIndexes,
    meta,
    onExecutiveMatchMarkCorrect,
    onExecutiveMatchMarkIncorrect,
  } = props;

  const { matchedCount, notMatchedCount, possibleMatchedCount } = meta;
  const [definitelyMatchedExecutives, possiblyMatchedExecutives] = pipe(
    filter(ExecutiveMatchPresenter.isMatchedToProfile),
    partition(ExecutiveMatchPresenter.isStrongProfileMatch),
  )(executivesMatches);

  return (
    <Tabs selectedTabClassName={styles.activeTab} variant="buttonsGroup">
      <TabList>
        <Tab>
          Definite Matches:&nbsp;<span className={styles.count}>{matchedCount}</span>
        </Tab>
        <Tab>
          Possible Matches:&nbsp;<span className={styles.count}>{possibleMatchedCount}</span>
        </Tab>
        <Tab disabled>Not Found:&nbsp;{notMatchedCount}</Tab>
      </TabList>
      <TabPanel>
        <ExecutivesMatches
          executivesMatches={definitelyMatchedExecutives}
          incorrectExecutiveMatchIndexes={incorrectExecutiveMatchIndexes}
          onExecutiveMatchMarkCorrect={onExecutiveMatchMarkCorrect}
          onExecutiveMatchMarkIncorrect={onExecutiveMatchMarkIncorrect}
        />
      </TabPanel>
      <TabPanel>
        <ExecutivesMatches
          executivesMatches={possiblyMatchedExecutives}
          incorrectExecutiveMatchIndexes={incorrectExecutiveMatchIndexes}
          onExecutiveMatchMarkCorrect={onExecutiveMatchMarkCorrect}
          onExecutiveMatchMarkIncorrect={onExecutiveMatchMarkIncorrect}
        />
      </TabPanel>
      <TabPanel />
    </Tabs>
  );
};

ExecutivesMatchesReview.propTypes = {
  executivesMatches: PropTypes.arrayOf(ExecutiveMatchPresenter.shape()).isRequired,
  incorrectExecutiveMatchIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
  meta: PropTypes.shape({
    matchedCount: PropTypes.number.isRequired,
    notMatchedCount: PropTypes.number.isRequired,
    possibleMatchedCount: PropTypes.number.isRequired,
  }).isRequired,
  onExecutiveMatchMarkCorrect: PropTypes.func.isRequired,
  onExecutiveMatchMarkIncorrect: PropTypes.func.isRequired,
};

export default ExecutivesMatchesReview;
