import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(profileId, collaborationId, params) {
    const url = Routes.apiV1AdminProfileDraftBoardPath(profileId, collaborationId);

    return FetchHelpers.patch(url, params);
  },

  create(profileId, params) {
    const url = Routes.apiV1AdminProfileDraftBoardsPath(profileId);

    return FetchHelpers.post(url, params);
  },

  destroy(profileId, collaborationId) {
    const url = Routes.apiV1AdminProfileDraftBoardPath(profileId, collaborationId);

    return FetchHelpers.delete(url);
  },
};
