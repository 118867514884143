import { buildFilterParams } from 'utils/FilterParamsBuilder';
import AvailableProfilesContainerConstants from './AvailableProfilesContainerConstants';
import AvailableProfilesRepository from 'repositories/CurrentUser/Project/AvailableProfilesRepository';

export function filterProjectAvailableProfiles(projectId, { page, per, ...filters } = {}) {
  return (dispatch) => {
    dispatch({ type: AvailableProfilesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_START, filters });

    const params = { page, per, ...buildFilterParams(filters) };

    return AvailableProfilesRepository.index(projectId, params).then(({ items, meta }) => {
      dispatch({
        type: AvailableProfilesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_SUCCESS,
        profiles: items,
        meta,
        filters,
      });

      return items;
    });
  };
}

export function loadProjectAvailableProfiles(projectId, { page, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: AvailableProfilesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_START, filters });

    const params = { ...buildFilterParams(filters), page, per };

    return AvailableProfilesRepository.index(projectId, params).then(({ items, meta }) => {
      dispatch({
        type: AvailableProfilesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_SUCCESS,
        profiles: items,
        meta,
        filters,
      });

      return items;
    });
  };
}

export function removeProjectAvailableProfile(projectId, profileId) {
  return (dispatch) => {
    dispatch({ type: AvailableProfilesContainerConstants.DESTROY_PROJECT_AVAILABLE_PROFILE_START });

    return AvailableProfilesRepository.destroy(projectId, profileId)
      .then(() => {
        dispatch({ type: AvailableProfilesContainerConstants.DESTROY_PROJECT_AVAILABLE_PROFILE_SUCCESS, profileId });
      })
      .catch((error) => {
        dispatch({ type: AvailableProfilesContainerConstants.DESTROY_PROJECT_AVAILABLE_PROFILE_ERROR });

        throw error;
      });
  };
}
