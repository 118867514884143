import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import ExplicitlyGrantedProfilesRepository from 'repositories/admin/Companies/Departments/LimitedAccess/ExplicitlyGrantedProfilesRepository';

const restSlice = createRestSlice({
  resource: 'explicitlyGrantedProfile',
  repository: ExplicitlyGrantedProfilesRepository,
  slices: [
    'bulkDestroyExplicitlyGrantedProfiles',
    'loadExplicitlyGrantedProfiles',
    'loadMoreExplicitlyGrantedProfiles',
  ],
});

const initialState = {
  ...restSlice.initialState,
};

const explicitlyGrantedProfilesSlice = createSlice({
  name: 'adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfiles',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesReducer =
  explicitlyGrantedProfilesSlice.reducer;

export default explicitlyGrantedProfilesSlice;

export const adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesRestHooks = restSlice.hooks(
  explicitlyGrantedProfilesSlice,
  'adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesReducer',
);
