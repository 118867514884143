import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propEq, reject } from 'ramda';
import { Box, Anchor } from 'grommet';
import { SubtractCircle } from 'grommet-icons';

import { reactSelectDebounce } from 'utils/HelperMethods';

import Button from 'components/Button';
import AsyncSelect from 'components/AsyncSelect';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import LanguagePresenter from 'presenters/LanguagePresenter';
import withConfirmation from 'hoc/withConfirmation';
import Routes from 'routes';

import styles from './Languages.module.css';

class LanguagesComponent extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    saving: PropTypes.bool,
    languages: PropTypes.arrayOf(LanguagePresenter.shape()).isRequired,
    onLanguagesSearch: PropTypes.func.isRequired,
    onLanguagesChange: PropTypes.func.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
  };

  state = {
    currentLanguage: null,
  };

  handleLanguageAdd = () => {
    const { languages, onLanguagesChange } = this.props;
    const { currentLanguage } = this.state;

    this.handleLanguageSelect(null);
    return onLanguagesChange([...languages, currentLanguage]);
  };

  handleLanguageDestroy = (languageId) => () => {
    const { languages, onLanguagesChange } = this.props;
    const newLanguages = reject(propEq(languageId, 'id'))(languages);

    return onLanguagesChange(newLanguages);
  };

  handleLanguageSearch = (searchText) => {
    const { onLanguagesSearch } = this.props;
    const filters = { nameCont: searchText, sortFields: [{ field: 'name', value: 'ASC' }] };

    return onLanguagesSearch(filters);
  };

  handleLanguageSearchDebounced = reactSelectDebounce(this.handleLanguageSearch);

  handleLanguageSelect = (currentLanguage) => {
    this.setState({ currentLanguage });
  };

  render() {
    const { saving, editable, isChanged, onReviewChanges, languages } = this.props;
    const { currentLanguage } = this.state;

    return (
      <>
        <Box margin={{ bottom: 'small' }}>
          <HeadlineWithReviewChangesLink
            strong
            size="small"
            title="Languages"
            isChanged={isChanged}
            onReviewChanges={onReviewChanges}
          />
        </Box>

        <Box margin={{ bottom: 'small' }} className={styles.list}>
          {languages.map((language) => (
            <Box key={LanguagePresenter.id(language)} margin={{ bottom: 'small' }}>
              <div className={styles.item}>
                <div className={styles.text}>
                  <Anchor
                    label={LanguagePresenter.name(language)}
                    href={Routes.editAdminLanguagePath(LanguagePresenter.id(language))}
                    target="_blank"
                  />
                </div>
                <Button
                  className={styles.action}
                  disabled={!editable || saving}
                  icon={<SubtractCircle />}
                  onClick={this.handleLanguageDestroy(LanguagePresenter.id(language))}
                  plain
                />
              </div>
            </Box>
          ))}
        </Box>

        <div className={styles.options}>
          <div className={styles.field}>
            <AsyncSelect
              isDisabled={!editable || saving}
              placeholder="Add a new language"
              loadOptions={this.handleLanguageSearchDebounced}
              defaultOptions
              value={this.state.currentLanguage}
              onChange={this.handleLanguageSelect}
              getOptionValue={LanguagePresenter.id}
              getOptionLabel={LanguagePresenter.name}
              noBottom
            />
          </div>

          <Button
            label="Add"
            primary
            className={styles.button}
            onClick={this.handleLanguageAdd}
            disabled={!editable || !currentLanguage || saving}
          />
        </div>
      </>
    );
  }
}

const Languages = withConfirmation(LanguagesComponent, {
  removeLanguage: 'Are you sure want to delete?',
});

export default Languages;
