import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RequestForm from './RequestForm';
import * as actions from './RequestFormActions';

const mapStateToProps = (state, { request }) => ({
  loading: state.RequestFormReducer.loading,
  saving: state.RequestFormReducer.saving,
  request: state.RequestFormReducer.request || request,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
