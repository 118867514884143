import { isBlank } from 'utils/HelperMethods';

export default from './ScrollableAnchor';

export function scrollToAnchor(id) {
  const element = document.getElementById(id);

  if (isBlank(element)) {
    return;
  }

  element.scrollIntoView({ behavior: 'smooth' });
}
