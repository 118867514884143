import React from 'react';

import AgingStatistics from './containers/AgingStatistics';
import ExecutivesChangesStatistics from './containers/ExecutivesChangesStatistics';
import ProfilesUpdatesStatistics from './containers/ProfilesUpdatesStatistics';
import OrganizationUpdates from './containers/OrganizationUpdates';
import NewProfilesStatistics from './containers/NewProfilesStatistics';
import UserCards from './containers/UserCards';
import PopularOrganizationTranscripts from './containers/PopularOrganizationTranscripts';
import Fortune500ProfilesStatistics from './containers/Fortune500ProfilesStatistics';
import Ftse100ProfilesStatistics from './containers/Ftse100ProfilesStatistics';

import styles from './DatabaseAnalytics.module.css';

const DatabaseAnalytics = () => (
  <>
    <div className={styles.counters}>
      <div className={styles.row}>
        <div className={styles.column}>
          <AgingStatistics />
        </div>
        <div className={styles.column}>
          <ExecutivesChangesStatistics />
        </div>
        <div className={styles.column}>
          <ProfilesUpdatesStatistics />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <OrganizationUpdates />
        </div>
        <div className={styles.column}>
          <NewProfilesStatistics />
        </div>
        <div className={styles.column}>
          <Fortune500ProfilesStatistics />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Ftse100ProfilesStatistics />
        </div>
      </div>
    </div>
    <div className={styles.top}>
      <div>
        <h3 className={styles.header}>Top 50 Priority Updates</h3>
        <div className={styles.users}>
          <UserCards />
        </div>
      </div>
      <div className={styles.transcripts}>
        <h3 className={styles.header}>Recently Published Transcripts</h3>
        <div className={styles.users}>
          <PopularOrganizationTranscripts />
        </div>
      </div>
    </div>
  </>
);

export default DatabaseAnalytics;
