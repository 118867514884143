import { adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesRestHooks } from 'slices/admin/company/department/limitedAccess/explicitlyGrantedProfilesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useExplicitlyGrantedProfiles = () => {
  const {
    bulkDestroyExplicitlyGrantedProfiles,
    explicitlyGrantedProfiles,
    loadExplicitlyGrantedProfiles,
    loadMoreExplicitlyGrantedProfiles,
  } = adminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesRestHooks.use();

  return {
    bulkDestroyExplicitlyGrantedProfiles,
    explicitlyGrantedProfiles: explicitlyGrantedProfiles.items,
    explicitlyGrantedProfilesLoadingMoreStatus: useFetchStatus(explicitlyGrantedProfiles.moreLoadingStatus),
    explicitlyGrantedProfilesLoadingStatus: useFetchStatus(explicitlyGrantedProfiles.loadingStatus),
    explicitlyGrantedProfilesMeta: explicitlyGrantedProfiles.meta,
    loadExplicitlyGrantedProfiles,
    loadMoreExplicitlyGrantedProfiles,
  };
};

export default useExplicitlyGrantedProfiles;
