import ProjectListPageConstants from './ProjectListPageConstants';
import ProjectsRepository from 'repositories/CurrentUser/ProjectsRepository';

import { addNotification } from 'containers/Notifications';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

const PROJECTS_DEFAULT_FILTERS = { sortFields: [{ field: 'createdAt', value: 'desc' }] };

export function filterProjects({ per, ...filters }) {
  const params = { ...buildFilterParams({ ...PROJECTS_DEFAULT_FILTERS, ...filters }), page: 1, per };

  return (dispatch) => {
    dispatch({ type: ProjectListPageConstants.FILTER_CURRENT_USER_PROJECTS_START, filters });

    return ProjectsRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: ProjectListPageConstants.FILTER_CURRENT_USER_PROJECTS_SUCCESS,
          items,
          meta,
          filters,
        });

        return items;
      })
      .catch((errors) => {
        dispatch({ type: ProjectListPageConstants.FILTER_CURRENT_USER_PROJECTS_SUCCESS });

        throw errors;
      });
  };
}

export function loadProjects({ page, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: ProjectListPageConstants.LOAD_CURRENT_USER_PROJECTS_START, filters });
    const params = { ...buildFilterParams({ ...PROJECTS_DEFAULT_FILTERS, ...filters }), page, per };

    return ProjectsRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: ProjectListPageConstants.LOAD_CURRENT_USER_PROJECTS_SUCCESS,
          items,
          meta,
          filters,
        });
      })
      .catch((errors) => {
        dispatch({ type: ProjectListPageConstants.LOAD_CURRENT_USER_PROJECTS_ERROR });

        throw errors;
      });
  };
}

export function destroyProject(projectId) {
  return (dispatch) => {
    dispatch({ type: ProjectListPageConstants.DESTROY_CURRENT_USER_PROJECT_START });

    return ProjectsRepository.destroy(projectId)
      .then(() => {
        dispatch({ type: ProjectListPageConstants.DESTROY_CURRENT_USER_PROJECT_SUCCESS, projectId });
      })
      .catch((error) => {
        dispatch({ type: ProjectListPageConstants.DESTROY_CURRENT_USER_PROJECT_ERROR });

        throw error;
      });
  };
}

export function cloneProject(id, params) {
  return (dispatch) => {
    dispatch({ type: ProjectListPageConstants.CLONE_CURRENT_USER_PROJECT_START });

    return ProjectsRepository.clone(id, params)
      .then(({ project }) => {
        dispatch({ type: ProjectListPageConstants.CLONE_CURRENT_USER_PROJECT_SUCCESS });

        return project;
      })
      .catch((error) => {
        dispatch({ type: ProjectListPageConstants.CLONE_CURRENT_USER_PROJECT_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw error;
      });
  };
}

export function changeProject(project) {
  return { type: ProjectListPageConstants.CHANGE_CURRENT_USER_PROJECT, project };
}
