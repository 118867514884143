/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import RemainingRequestNumbersStatisticsRepository from 'repositories/admin/EditorialDashboard/RemainingRequestNumbersStatisticsRepository';

const restSlice = createRestSlice({
  resource: 'remainingRequestNumbersStatistic',
  repository: RemainingRequestNumbersStatisticsRepository,
  slices: ['loadRemainingRequestNumbersStatistics'],
});

const initialState = {
  ...restSlice.initialState,
};

const remainingRequestNumbersStatisticsSlice = createSlice({
  name: 'adminEditorialDashboardRemainingRequestNumbersStatistics',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminEditorialDashboardRemainingRequestNumbersStatisticsReducer =
  remainingRequestNumbersStatisticsSlice.reducer;

export default remainingRequestNumbersStatisticsSlice;

export const adminEditorialDashboardRemainingRequestNumbersStatisticsRestHooks = restSlice.hooks(
  remainingRequestNumbersStatisticsSlice,
  'adminEditorialDashboardRemainingRequestNumbersStatisticsReducer',
);
