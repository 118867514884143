import CurrentUserRepository from 'repositories/CurrentUser/GuidesRepository';

const useCurrentUserGuideVisibility = () => {
  const hideGuide = (guideName) => CurrentUserRepository.hide(guideName);
  const revealGuide = (guideName) => CurrentUserRepository.reveal(guideName);

  return { hideGuide, revealGuide };
};

export default useCurrentUserGuideVisibility;
