import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import Button from 'components/Button';

import styles from './ExcludeProfilesButton.module.css';

const ExcludeProfilesButton = (props) => {
  const { excludedProfilesCount, onClick } = props;

  const title =
    excludedProfilesCount <= 0
      ? 'Exclude Individual Profiles'
      : `Exclude Individual Profiles (${excludedProfilesCount})`;

  return (
    <div className={styles.root}>
      <Button className={styles.button} onClick={onClick}>
        {title}
      </Button>
      <Tooltip
        placement="right"
        overlay="This feature allows you to exclude selected executives from the relationship mapping."
      >
        <div className={styles.questionMark}>?</div>
      </Tooltip>
    </div>
  );
};

ExcludeProfilesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  excludedProfilesCount: PropTypes.number,
};

export default ExcludeProfilesButton;
