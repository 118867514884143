import { equals } from 'ramda';
import CustomerUsageDashboardProjectsConstants from './CustomerUsageDashboardProjectsConstants';

const initialState = {
  loading: false,
  projects: [],
  nextPage: 1,
  filters: {},
  totalCount: null,
};

function CustomerUsageDashboardProjectsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CustomerUsageDashboardProjectsConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_START: {
      return { ...state, loading: true };
    }
    case CustomerUsageDashboardProjectsConstants.LOAD_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_SUCCESS: {
      const { nextPage, totalCount } = action;
      const projects = [...state.projects, ...action.projects];

      return { ...state, projects, nextPage, totalCount, loading: false };
    }
    case CustomerUsageDashboardProjectsConstants.FILTER_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_START: {
      return { ...initialState, loading: true, filters: action.filters };
    }
    case CustomerUsageDashboardProjectsConstants.FILTER_ADMIN_CUSTOMER_USAGE_DASHBOARD_PROJECTS_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { nextPage, totalCount, projects } = action;

      return { ...state, projects, nextPage, totalCount, loading: false };
    }
    default:
      return state;
  }
}

export default CustomerUsageDashboardProjectsReducer;
