import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import CompanyDepartmentsRepository from 'repositories/admin/Companies/DepartmentsRepository';

const restSlice = createRestSlice({
  resource: 'companyDepartment',
  repository: CompanyDepartmentsRepository,
  slices: [
    'createCompanyDepartment',
    'destroyCompanyDepartment',
    'loadCompanyDepartment',
    'loadCompanyDepartments',
    'loadMoreCompanyDepartments',
    'updateCompanyDepartment',
  ],
});

const initialState = {
  ...restSlice.initialState,
};

const departmentsSlice = createSlice({
  name: 'adminCompanyDepartments',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDepartmentsReducer = departmentsSlice.reducer;

export default departmentsSlice;

export const adminCompanyDepartmentsRestHooks = restSlice.hooks(departmentsSlice, 'adminCompanyDepartmentsReducer');
