import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import ActionAlert from './components/ActionAlert';
import ModalAlert from './components/ModalAlert';

export const VARIANTS = {
  ACTION: 'action',
  MODAL: 'modal',
};

const VARIANT_COMPONENT_MAPPING = {
  [VARIANTS.ACTION]: ActionAlert,
  [VARIANTS.MODAL]: ModalAlert,
};

const Alert = (props) => {
  const { close, message, ...rest } = props;
  const { data, variant } = message;
  const Component = propOr(ActionAlert, variant)(VARIANT_COMPONENT_MAPPING);

  return <Component value={data} meta={rest} onClose={close} />;
};

Alert.propTypes = {
  message: PropTypes.shape().isRequired,
  style: PropTypes.shape().isRequired,
  options: PropTypes.shape().isRequired,
  close: PropTypes.func.isRequired,
};

export default Alert;
