import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import { debounce } from 'utils/HelperMethods';
import { logClickOnAlertsTab } from 'utils/amplitude';

import Tabs, { Tab } from 'components/Tabs';

import ProfileSubscriptionsList, {
  INITIAL_FILTERS as PROFILE_SUBSCRIPTIONS_INITIAL_FILTERS,
} from './components/ProfileSubscriptionsList';
import OrganizationSubscriptionsList, {
  INITIAL_FILTERS as ORGANIZATION_SUBSCRIPTIONS_INITIAL_FILTERS,
} from './components/OrganizationSubscriptionsList';
import SavedSearchSubscriptionsList, {
  INITIAL_FILTERS as SAVED_SEARCH_SUBSCRIPTIONS_INITIAL_FILTERS,
} from './components/SavedSearchSubscriptionsList';
import ProjectSubscriptionsList, {
  INITIAL_FILTERS as PROJECT_SUBSCRIPTIONS_INITIAL_FILTERS,
} from './components/ProjectSubscriptionsList';

import UserPresenter from 'presenters/UserPresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';

import UserPolicy from 'policies/UserPolicy';

import styles from './AlertsPage.module.css';

class AlertsPage extends Component {
  static propTypes = {
    currentUser: UserPresenter.shape().isRequired,
    loadProfilesSubscriptions: PropTypes.func.isRequired,
    filterProfileSubscriptions: PropTypes.func.isRequired,
    deleteProfileSubscription: PropTypes.func.isRequired,
    undoDeleteProfileSubscription: PropTypes.func.isRequired,
    isProfileSubscriptionLoading: PropTypes.bool,
    profileSubscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    profileSubscriptionsNextPage: PropTypes.number,
    profileSubscriptionsTotalCount: PropTypes.number,

    isOrganizationSubscriptionsLoading: PropTypes.bool,
    organizationSubscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    loadOrganizationSubscriptions: PropTypes.func.isRequired,
    organizationSubscriptionsNextPage: PropTypes.number,
    organizationSubscriptionsTotalCount: PropTypes.number,
    filterOrganizationSubscriptions: PropTypes.func.isRequired,
    deleteOrganizationSubscription: PropTypes.func.isRequired,
    undoDeleteOrganizationSubscription: PropTypes.func.isRequired,

    savedSearchSubscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    filterSavedSearchSubscriptions: PropTypes.func.isRequired,
    savedSearchSubscriptionsTotalCount: PropTypes.number,
    savedSearchSubscriptionsNextPage: PropTypes.number,
    loadSavedSearchSubscriptions: PropTypes.func.isRequired,
    isSavedSearchSubscriptionsLoading: PropTypes.bool,
    deleteSavedSearchSubscription: PropTypes.func,

    projectSubscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    filterProjectSubscriptions: PropTypes.func.isRequired,
    projectSubscriptionsTotalCount: PropTypes.number,
    projectSubscriptionsNextPage: PropTypes.number,
    loadProjectSubscriptions: PropTypes.func.isRequired,
    isProjectSubscriptionsLoading: PropTypes.bool,
    deleteProjectSubscription: PropTypes.func,

    subscriptionConfigurationLoading: PropTypes.bool,
    loadSubscriptionConfiguration: PropTypes.func.isRequired,
    updateSubscriptionConfiguration: PropTypes.func.isRequired,

    history: PropTypes.shape().isRequired,
  };

  constructor(...args) {
    super(...args);

    const { currentUser } = this.props;
    const userIdEq = currentUser.id;

    this.state = {
      profileSubscriptionsFilters: { userIdEq, ...PROFILE_SUBSCRIPTIONS_INITIAL_FILTERS },
      organizationSubscriptionsFilters: { userIdEq, ...ORGANIZATION_SUBSCRIPTIONS_INITIAL_FILTERS },
      savedSearchSubscriptionsFilters: { userIdEq, ...SAVED_SEARCH_SUBSCRIPTIONS_INITIAL_FILTERS },
      projectSubscriptionsFilters: { userIdEq, ...PROJECT_SUBSCRIPTIONS_INITIAL_FILTERS },
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    const {
      profileSubscriptionsFilters,
      organizationSubscriptionsFilters,
      savedSearchSubscriptionsFilters,
      projectSubscriptionsFilters,
    } = this.state;

    this.handleFilterOrganizationSubscriptions(organizationSubscriptionsFilters);
    this.handleFilterProfileSubscriptions(profileSubscriptionsFilters);
    this.handleFilterSavedSearchSubscriptions(savedSearchSubscriptionsFilters);
    if (UserPolicy.canSeeProjects(currentUser)) {
      this.handleFilterProjectSubscriptions(projectSubscriptionsFilters);
    }
  }

  handleFilterSavedSearchSubscriptions = (filters) => this.props.filterSavedSearchSubscriptions(filters);

  handleFilterOrganizationSubscriptions = (filters) => this.props.filterOrganizationSubscriptions(filters);

  handleFilterProfileSubscriptions = (filters) => this.props.filterProfileSubscriptions(filters);

  handleFilterProjectSubscriptions = (filters) => this.props.filterProjectSubscriptions(filters);

  handleFilterProfileSubscriptionsDebounced = debounce(this.handleFilterProfileSubscriptions, 300);

  handleFilterOrganizationSubscriptionsDebounced = debounce(this.handleFilterOrganizationSubscriptions, 300);

  handleFilterSavedSearchSubscriptionsDebounced = debounce(this.handleFilterSavedSearchSubscriptions, 300);

  handleFilterProjectSubscriptionsDebounced = debounce(this.handleFilterProjectSubscriptions, 300);

  handleSavedSearchSubscriptionsFiltersChange = (filters) => {
    this.setState({ savedSearchSubscriptionsFilters: filters });

    this.handleFilterSavedSearchSubscriptionsDebounced(filters);
  };

  handleOrganizationSubscriptionsFiltersChange = (filters) => {
    this.setState({ organizationSubscriptionsFilters: filters });

    this.handleFilterOrganizationSubscriptionsDebounced(filters);
  };

  handleProfileSubscriptionsFiltersChange = (filters) => {
    this.setState({ profileSubscriptionsFilters: filters });

    this.handleFilterProfileSubscriptionsDebounced(filters);
  };

  handleProjectSubscriptionsFiltersChange = (filters) => {
    this.setState({ projectSubscriptionsFilters: filters });

    this.handleFilterProjectSubscriptionsDebounced(filters);
  };

  render() {
    const {
      currentUser,
      history,

      loadProfilesSubscriptions,
      deleteProfileSubscription,
      undoDeleteProfileSubscription,
      isProfileSubscriptionLoading,
      profileSubscriptions,
      profileSubscriptionsNextPage,
      profileSubscriptionsTotalCount,

      organizationSubscriptions,
      isOrganizationSubscriptionsLoading,
      deleteOrganizationSubscription,
      undoDeleteOrganizationSubscription,
      loadOrganizationSubscriptions,
      organizationSubscriptionsNextPage,
      organizationSubscriptionsTotalCount,

      savedSearchSubscriptions,
      savedSearchSubscriptionsTotalCount,
      savedSearchSubscriptionsNextPage,
      isSavedSearchSubscriptionsLoading,
      loadSavedSearchSubscriptions,
      deleteSavedSearchSubscription,

      projectSubscriptions,
      projectSubscriptionsTotalCount,
      projectSubscriptionsNextPage,
      isProjectSubscriptionsLoading,
      loadProjectSubscriptions,
      deleteProjectSubscription,

      subscriptionConfigurationLoading,
      updateSubscriptionConfiguration,
      loadSubscriptionConfiguration,
    } = this.props;

    const {
      profileSubscriptionsFilters,
      organizationSubscriptionsFilters,
      savedSearchSubscriptionsFilters,
      projectSubscriptionsFilters,
    } = this.state;

    return (
      <Box className={styles.root}>
        <Heading className={styles.heading} level="2" margin={{ vertical: 'medium' }}>
          Your Alerts
        </Heading>
        <Tabs className={styles.tabs} onActive={logClickOnAlertsTab}>
          <Tab title={`Executives (${profileSubscriptionsTotalCount})`}>
            <ProfileSubscriptionsList
              subscriptions={profileSubscriptions}
              loadSubscriptions={loadProfilesSubscriptions}
              nextPage={profileSubscriptionsNextPage}
              isLoading={isProfileSubscriptionLoading}
              onSubscriptionUndoDelete={undoDeleteProfileSubscription}
              onSubscriptionDelete={deleteProfileSubscription}
              filters={profileSubscriptionsFilters}
              onFiltersChange={this.handleProfileSubscriptionsFiltersChange}
              totalCount={profileSubscriptionsTotalCount}
              subscriptionConfigurationLoading={subscriptionConfigurationLoading}
              updateSubscriptionConfiguration={updateSubscriptionConfiguration}
              loadSubscriptionConfiguration={loadSubscriptionConfiguration}
            />
          </Tab>
          <Tab title={`Companies (${organizationSubscriptionsTotalCount})`}>
            <OrganizationSubscriptionsList
              subscriptions={organizationSubscriptions}
              isLoading={isOrganizationSubscriptionsLoading}
              filters={organizationSubscriptionsFilters}
              onSubscriptionUndoDelete={undoDeleteOrganizationSubscription}
              onSubscriptionDelete={deleteOrganizationSubscription}
              onFilterChange={this.handleOrganizationSubscriptionsFiltersChange}
              loadSubscriptions={loadOrganizationSubscriptions}
              nextPage={organizationSubscriptionsNextPage}
              totalCount={organizationSubscriptionsTotalCount}
              subscriptionConfigurationLoading={subscriptionConfigurationLoading}
              updateSubscriptionConfiguration={updateSubscriptionConfiguration}
              loadSubscriptionConfiguration={loadSubscriptionConfiguration}
            />
          </Tab>
          <Tab title={`Saved Searches (${savedSearchSubscriptionsTotalCount})`}>
            <SavedSearchSubscriptionsList
              subscriptions={savedSearchSubscriptions}
              filters={savedSearchSubscriptionsFilters}
              onFiltersChange={this.handleSavedSearchSubscriptionsFiltersChange}
              nextPage={savedSearchSubscriptionsNextPage}
              isLoading={isSavedSearchSubscriptionsLoading}
              loadSubscriptions={loadSavedSearchSubscriptions}
              onSubscriptionDelete={deleteSavedSearchSubscription}
              totalCount={savedSearchSubscriptionsTotalCount}
              subscriptionConfigurationLoading={subscriptionConfigurationLoading}
              updateSubscriptionConfiguration={updateSubscriptionConfiguration}
              loadSubscriptionConfiguration={loadSubscriptionConfiguration}
              history={history}
            />
          </Tab>
          {UserPolicy.canSeeProjects(currentUser) && (
            <Tab title={`Projects (${projectSubscriptionsTotalCount})`}>
              <ProjectSubscriptionsList
                subscriptions={projectSubscriptions}
                filters={projectSubscriptionsFilters}
                onFiltersChange={this.handleProjectSubscriptionsFiltersChange}
                nextPage={projectSubscriptionsNextPage}
                isLoading={isProjectSubscriptionsLoading}
                loadSubscriptions={loadProjectSubscriptions}
                onSubscriptionDelete={deleteProjectSubscription}
                totalCount={projectSubscriptionsTotalCount}
                subscriptionConfigurationLoading={subscriptionConfigurationLoading}
                updateSubscriptionConfiguration={updateSubscriptionConfiguration}
                loadSubscriptionConfiguration={loadSubscriptionConfiguration}
              />
            </Tab>
          )}
        </Tabs>
      </Box>
    );
  }
}

export default AlertsPage;
