import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import CompaniesRepository from 'repositories/admin/CompaniesRepository';

const restSlice = createRestSlice({
  resource: 'company',
  repository: CompaniesRepository,
  slices: ['loadCompany', 'updateCompany', 'loadCompanies'],
});

const initialState = {
  ...restSlice.initialState,
};

const companiesSlice = createSlice({
  name: 'adminCompanies',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompaniesReducer = companiesSlice.reducer;

export default companiesSlice;

export const adminCompaniesRestHooks = restSlice.hooks(companiesSlice, 'adminCompaniesReducer');
