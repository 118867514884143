import AudienceInsightsConstants from './AudienceInsightsConstants';

const initialState = {
  loading: false,
  audienceInsight: null,
};

function AudienceInsightsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AudienceInsightsConstants.LOAD_PROJECT_AUDIENCE_INSIGHTS_START: {
      return { ...initialState, loading: true };
    }
    case AudienceInsightsConstants.LOAD_PROJECT_AUDIENCE_INSIGHTS_SUCCESS: {
      const { audienceInsight } = action;
      return {
        ...state,
        loading: false,
        audienceInsight,
      };
    }
    default:
      return state;
  }
}

export default AudienceInsightsReducer;
