const LABEL_FONT_SIZE = 10;
const LABEL_WIDTH = 32;
const LABEL_HEIGHT = 15;
const LABEL_FILL_COLOR = '#C6A957';
const LABEL_STROKE_COLOR = '#FFF';
const LABEL_BORDER_RADIUS = 2;
const LABEL_X_COORD = 5;
const LABEL_Y_COORD = -30;

function mount(nodeGroup) {
  nodeGroup
    .append('rect')
    .attr('rx', LABEL_BORDER_RADIUS)
    .attr('ry', LABEL_BORDER_RADIUS)
    .attr('x', LABEL_X_COORD)
    .attr('y', LABEL_Y_COORD)
    .attr('width', LABEL_WIDTH)
    .attr('height', LABEL_HEIGHT)
    .attr('fill', LABEL_FILL_COLOR)
    .attr('stroke', LABEL_STROKE_COLOR);

  nodeGroup
    .append('text')
    .text('F500')
    .attr('x', LABEL_X_COORD + 6)
    .attr('dy', LABEL_Y_COORD + 11)
    .attr('font-size', LABEL_FONT_SIZE)
    .attr('font-weight', 600)
    .attr('fill', '#FFF')
    .style('pointer-events', 'none');
}

export default { mount };
