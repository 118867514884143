import { equals } from 'ramda';
import ProfileRelationshipsListConstants from './ProfileRelationshipsListConstants';

const initialState = {
  loading: false,
  relationships: [],
  nextPage: null,
  totalCount: null,
  currentPage: null,
  profileId: null,
  relationshipsFilters: null,
  profilesFilters: null,
};

function ProfileRelationshipsListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileRelationshipsListConstants.INITIALIZE_PROJECT_PROFILE_RELATIONSHIPS: {
      const { profileId, relationshipsFilters, profilesFilters } = action;

      return {
        ...state,
        loading: false,
        relationships: [],
        nextPage: 1,
        profileId,
        relationshipsFilters,
        profilesFilters,
      };
    }
    case ProfileRelationshipsListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_START: {
      return { ...state, loading: true };
    }
    case ProfileRelationshipsListConstants.LOAD_PROJECT_PROFILE_RELATIONSHIPS_SUCCESS: {
      const { relationships, meta, profileId, relationshipsFilters, profilesFilters } = action;

      if (
        state.profileId !== profileId ||
        !equals(state.relationshipsFilters, relationshipsFilters) ||
        !equals(state.profilesFilters, profilesFilters)
      ) {
        return state;
      }

      return { ...state, relationships: [...state.relationships, ...relationships], loading: false, ...meta };
    }
    default:
      return state;
  }
}

export default ProfileRelationshipsListReducer;
