import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import Chart from './components/Chart';
import Form from './components/Form';
import Spinner from 'components/Spinner';
import ProjectsUsage from './components/ProjectsUsage';
import TopViewed from './components/TopViewed';
import Counters from './components/Counters';

import CustomerUsageDashboardPresenter from 'presenters/EditorialDashboard/CustomerUsageDashboardPresenter';
import PointPresenter from 'presenters/EditorialDashboard/CustomerUsageDashboard/PointPresenter';
import ProjectPresenter from 'presenters/Company/Dashboard/ProjectPresenter';

class CustomerUsageDashboard extends Component {
  static propTypes = {
    dashboard: CustomerUsageDashboardPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    loadDashboard: PropTypes.func.isRequired,
    loadPowerUsers: PropTypes.func.isRequired,
    loadPopularOrganizations: PropTypes.func.isRequired,
    form: PropTypes.shape(),
    projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
    projectsLoading: PropTypes.bool.isRequired,
    projectsNextPage: PropTypes.number,
    loadProjects: PropTypes.func.isRequired,
    loadPublicApiStatistics: PropTypes.func.isRequired,
    loadSearchProfilesStatistics: PropTypes.func.isRequired,
    loadSignInStatistics: PropTypes.func.isRequired,
    loadViewsAndDownloadsStatistics: PropTypes.func.isRequired,
    filterProjects: PropTypes.func.isRequired,
    popularOrganizations: PropTypes.arrayOf(PropTypes.shape({})),
    popularOrganizationsLoading: PropTypes.bool.isRequired,
    powerUsers: PropTypes.arrayOf(PropTypes.shape({})),
    powerUsersLoading: PropTypes.bool.isRequired,
    publicApiStatisticsLoading: PropTypes.bool.isRequired,
    publicApiStatistics: PropTypes.arrayOf(PointPresenter.shape()),
    searchProfilesStatisticsLoading: PropTypes.bool.isRequired,
    searchProfilesStatistics: PropTypes.arrayOf(PointPresenter.shape()),
    signInStatisticsLoading: PropTypes.bool.isRequired,
    signInStatistics: PropTypes.arrayOf(PointPresenter.shape()),
    viewsAndDownloadsStatisticsLoading: PropTypes.bool.isRequired,
    viewsAndDownloadsStatistics: PropTypes.arrayOf(PointPresenter.shape()),
  };

  componentDidMount() {
    const defaultEndDate = DateTime.local();
    const defaultStartDate = defaultEndDate.minus({ year: 1 });

    this.handleDashboardLoad({ from: defaultStartDate.toString(), to: defaultEndDate.toString() });
  }

  handleDashboardLoad = (form) => {
    const {
      loadDashboard,
      loadPopularOrganizations,
      loadPowerUsers,
      loadPublicApiStatistics,
      loadSearchProfilesStatistics,
      loadSignInStatistics,
      loadViewsAndDownloadsStatistics,
      filterProjects,
    } = this.props;

    loadDashboard(form).then(({ period }) => {
      loadPopularOrganizations(form);
      loadPowerUsers(form);
      loadPublicApiStatistics({ ...form, period });
      loadSearchProfilesStatistics({ ...form, period });
      loadSignInStatistics({ ...form, period });
      loadViewsAndDownloadsStatistics({ ...form, period });
      filterProjects(form);
    });
  };

  handleLoadProjects = (filters) => {
    const { loadProjects, form } = this.props;

    return loadProjects({ form, ...filters });
  };

  render() {
    const {
      dashboard,
      loading,
      projects,
      projectsLoading,
      projectsNextPage,
      form,
      popularOrganizationsLoading,
      popularOrganizations,
      powerUsersLoading,
      powerUsers,
      publicApiStatisticsLoading,
      publicApiStatistics,
      searchProfilesStatisticsLoading,
      searchProfilesStatistics,
      signInStatisticsLoading,
      signInStatistics,
      viewsAndDownloadsStatisticsLoading,
      viewsAndDownloadsStatistics,
    } = this.props;

    if (loading || !dashboard) {
      return <Spinner />;
    }

    return (
      <>
        <Counters dashboard={dashboard} />
        <Form form={form} onSubmit={this.handleDashboardLoad} />
        <Chart title="API" data={publicApiStatistics} loading={publicApiStatisticsLoading} period={dashboard.period} />
        <Chart
          title="Views & Downloads"
          data={viewsAndDownloadsStatistics}
          loading={viewsAndDownloadsStatisticsLoading}
          period={dashboard.period}
        />
        <Chart
          title="Searches"
          data={searchProfilesStatistics}
          loading={searchProfilesStatisticsLoading}
          period={dashboard.period}
        />
        <Chart title="Logins" data={signInStatistics} loading={signInStatisticsLoading} period={dashboard.period} />
        <TopViewed
          popularOrganizationsLoading={popularOrganizationsLoading}
          popularOrganizations={popularOrganizations}
          powerUsersLoading={powerUsersLoading}
          powerUsers={powerUsers}
        />
        {CustomerUsageDashboardPresenter.projectsCount(dashboard) > 0 && (
          <ProjectsUsage
            activeProjectsCount={CustomerUsageDashboardPresenter.activeProjectsCount(dashboard)}
            onLoad={this.handleLoadProjects}
            projects={projects}
            loading={projectsLoading}
            nextPage={projectsNextPage}
          />
        )}
      </>
    );
  }
}

export default CustomerUsageDashboard;
