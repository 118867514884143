import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { times } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import { Anchor } from 'grommet';

import cn from 'clsx';

import styles from './Pagination.module.css';

import { FormPrevious as FormPreviousIcon, FormNext as FormNextIcon } from 'grommet-icons';

const EDGE_BLOCK_LENGTH = 2;
const CURRENT_BLOCK_SIDE_LENGTH = 2;
const CURRENT_BLOCK_LENGTH = CURRENT_BLOCK_SIDE_LENGTH * 2 + 1;
const DISTANCE_FOR_SPREAD = 2;

class Pagination extends Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    maxPages: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
  };

  totalPages() {
    const { totalCount, perPage, maxPages } = this.props;
    const totalPages = Math.floor(totalCount / perPage) + (totalCount % perPage === 0 ? 0 : 1);

    return isPresent(maxPages) && totalPages > maxPages ? maxPages : totalPages;
  }

  currentBlockStartAt(totalPages) {
    const { currentPage } = this.props;

    if (currentPage + CURRENT_BLOCK_SIDE_LENGTH > totalPages) {
      return totalPages > CURRENT_BLOCK_LENGTH ? totalPages - CURRENT_BLOCK_SIDE_LENGTH * 2 - 1 : 0;
    }

    return currentPage > CURRENT_BLOCK_SIDE_LENGTH ? currentPage - CURRENT_BLOCK_SIDE_LENGTH - 1 : 0;
  }

  handlePageChange = (page) => () => this.props.onPageChange(page);

  isFirstEllipsisNeeded(currentBlockStartAt) {
    return currentBlockStartAt >= EDGE_BLOCK_LENGTH + DISTANCE_FOR_SPREAD;
  }

  isSecondEllipsisNeeded(currentBlockStartAt, currentBlockLength, totalPages) {
    return currentBlockStartAt + currentBlockLength + DISTANCE_FOR_SPREAD <= totalPages - EDGE_BLOCK_LENGTH;
  }

  renderPageLink(page) {
    const { currentPage } = this.props;
    const isCurrentPage = currentPage === page;
    const spanStyles = cn([styles.item], { [styles.active]: isCurrentPage });

    return (
      <span key={page} className={spanStyles}>
        <Anchor disabled={isCurrentPage} onClick={this.handlePageChange(page)}>
          {page}
        </Anchor>
      </span>
    );
  }

  renderEllipsis(key) {
    return <span key={key}> ... </span>;
  }

  renderPagesBlock(pagesStartFrom, length) {
    return times((index) => this.renderPageLink(pagesStartFrom + index + 1), length);
  }

  renderPagination() {
    const totalPages = this.totalPages();
    const currentBlockStartAt = this.currentBlockStartAt(totalPages);
    const currentBlockLength = Math.min(totalPages - currentBlockStartAt, CURRENT_BLOCK_LENGTH);
    const isFirstEllipsisNeeded = this.isFirstEllipsisNeeded(currentBlockStartAt);
    const isSecondEllipsisNeeded = this.isSecondEllipsisNeeded(currentBlockStartAt, currentBlockLength, totalPages);
    const startBlockLength = isFirstEllipsisNeeded ? EDGE_BLOCK_LENGTH : currentBlockStartAt;
    const endBlockLength = isSecondEllipsisNeeded
      ? EDGE_BLOCK_LENGTH
      : totalPages - currentBlockStartAt - currentBlockLength;
    const endBlockStartAt = totalPages - endBlockLength;
    const arrowStyles = (name) => cn([styles.arrow], [styles[name]]);

    return (
      <div className={styles.container}>
        <Anchor onClick={this.handlePageChange(1)} className={arrowStyles('arrowLeft')}>
          <FormPreviousIcon />
        </Anchor>
        {this.renderPagesBlock(0, startBlockLength)}
        {isFirstEllipsisNeeded && this.renderEllipsis('first-ellipsis')}
        {this.renderPagesBlock(currentBlockStartAt, currentBlockLength)}
        {isSecondEllipsisNeeded && this.renderEllipsis('second-ellipsis')}
        {this.renderPagesBlock(endBlockStartAt, endBlockLength)}
        <Anchor onClick={this.handlePageChange(totalPages)} className={arrowStyles('arrowRight')}>
          <FormNextIcon />
        </Anchor>
      </div>
    );
  }

  render() {
    const { totalCount } = this.props;

    return totalCount > 0 ? this.renderPagination() : null;
  }
}

export default Pagination;
