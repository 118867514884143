import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import styles from './Table.module.css';

const Table = (props) => {
  const { className, ...rest } = props;

  const tableClassName = clsx(styles.table, className);

  return <table {...rest} className={tableClassName} />;
};

Table.propTypes = {
  className: PropTypes.string,
};

Table.defaultProps = {
  className: null,
};

export default Table;
