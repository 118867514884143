import OrganizationUpdatesConstants from './OrganizationUpdatesConstants';

const initialState = {
  loading: false,
  organizationUpdates: {},
};

function OrganizationUpdatesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OrganizationUpdatesConstants.LOAD_DATABASE_ANALYTIC_ORGANIZATION_UPDATES_START: {
      return { ...state, loading: true };
    }
    case OrganizationUpdatesConstants.LOAD_DATABASE_ANALYTIC_ORGANIZATION_UPDATES_SUCCESS: {
      const { organizationUpdates } = action;
      return { ...state, loading: false, organizationUpdates };
    }
    default:
      return state;
  }
}

export default OrganizationUpdatesReducer;
