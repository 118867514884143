import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';

class ConfirmationModal extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { header, onCancel, onConfirm } = this.props;

    return (
      <Modal onCancel={onCancel} size="small" overflow header={header} disableCloseIcon>
        <Box direction="row" margin={{ top: 'small' }} width="medium" justify="between">
          <Button label="Ok" primary onClick={onConfirm} />
          <Button label="Cancel" onClick={onCancel} />
        </Box>
      </Modal>
    );
  }
}

export default ConfirmationModal;
