import React, { useEffect } from 'react';
import Routes from 'routes';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-polyfill';

import { useAlert } from 'hooks';
import { useAdminCompanies } from 'hooks/admin';

import CompanyPresenter from 'presenters/CompanyPresenter';

import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import Spinner from 'components/Spinner';
import PageHeader from 'components/PageHeader';
import RoutedTabs, { RoutedTab } from 'components/v2/RoutedTabs';

import DashboardTab from './components/DashboardTab';
import EditTab from './components/EditTab';
import Manager from './components/Manager';
import SingleSignOnSettingsTab from './components/SingleSignOnSettingsTab';
import LegalSettingsTab from './components/LegalSettingsTab';
import SubscriptionSettingsEditTab from './components/SubscriptionSettingsEditTab';
import UserListTab from './components/UserListTab';

import styles from './CompanyEditPage.module.css';

const CompanyEditPage = () => {
  const { successAction } = useAlert();
  const { company, companyLoadingStatus, loadCompany, updateCompany } = useAdminCompanies();
  const { companyId } = useParams();

  const handleManagerUpdate = async (manager) => {
    await updateCompany(company.id, { managerId: manager?.id });

    successAction('Account manager was successfully saved');
  };

  useEffect(() => {
    loadCompany(companyId);
  }, []);

  if (companyLoadingStatus.isPending || company?.id !== Number(companyId)) {
    return <Spinner />;
  }

  return (
    <div className={styles.root}>
      <PageHeader
        title={
          <div className={styles.header}>
            {CompanyPresenter.name(company)}
            <Manager manager={company.manager} onManagerUpdate={handleManagerUpdate} />
          </div>
        }
      />
      <div className={styles.tabs}>
        <RoutedTabs>
          <RoutedTab path={Routes.editAdminCompanyPath(company.id)}>Edit</RoutedTab>
          <RoutedTab path={Routes.editAdminCompanySubscriptionSettingsPath(company.id)}>
            Subscription Settings
          </RoutedTab>
          <RoutedTab path={Routes.editAdminCompanySingleSignOnSettingsPath(company.id)}>
            Single Sign On Settings
          </RoutedTab>
          <RoutedTab path={Routes.adminCompanyDashboardPath(company.id)}>Dashboard</RoutedTab>
          <RoutedTab path={Routes.adminCompanyUsersPath(company.id)}>Account Users</RoutedTab>
          <RoutedTab path={Routes.editAdminCompanyLegalSettingsPath(company.id)}>Legal Settings</RoutedTab>
        </RoutedTabs>
        <div>
          <AppSwitch>
            <AppRoute exact path={Routes.editAdminCompanyPath(':companyId')} component={EditTab} />
            <AppRoute
              exact
              path={Routes.editAdminCompanySubscriptionSettingsPath(':companyId')}
              component={SubscriptionSettingsEditTab}
            />
            <AppRoute
              exact
              path={Routes.editAdminCompanySingleSignOnSettingsPath(':companyId')}
              component={SingleSignOnSettingsTab}
            />
            <AppRoute
              exact
              path={Routes.editAdminCompanyLegalSettingsPath(':companyId')}
              component={LegalSettingsTab}
            />
            <AppRoute exact path={Routes.adminCompanyDashboardPath(company.id)} component={DashboardTab} />
            <AppRoute exact path={Routes.adminCompanyUsersPath(company.id)} component={UserListTab} />
            <Redirect from={Routes.adminCompanyPath(company.id)} to={Routes.editAdminCompanyPath(company.id)} />
          </AppSwitch>
        </div>
      </div>
    </div>
  );
};

export default CompanyEditPage;
