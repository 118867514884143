import { prop } from 'ramda';
import LanguagesListPageConstants from './LanguagesListPageConstants';
import LanguagesRepository from 'repositories/admin/LanguagesRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';
import { isPresent } from 'utils/HelperMethods';

export function loadLanguages({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: LanguagesListPageConstants.LOAD_LANGUAGES_START });
    const query = { page, ...buildFilterParams(filters) };

    return LanguagesRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: LanguagesListPageConstants.LOAD_LANGUAGES_SUCCESS,
        languages: items,
        ...meta,
      });
    });
  };
}

export function filterLanguages(filters) {
  return (dispatch) => {
    dispatch({ type: LanguagesListPageConstants.FILTER_LANGUAGES_START, filters });

    const query = buildFilterParams(filters);

    return LanguagesRepository.index(query).then(({ items, meta }) => {
      dispatch({ type: LanguagesListPageConstants.FILTER_LANGUAGES_SUCCESS, languages: items, filters, ...meta });
    });
  };
}

export function createLanguage(language) {
  return (dispatch) => {
    dispatch({ type: LanguagesListPageConstants.CREATE_LANGUAGE_START });

    return LanguagesRepository.create(language)
      .then(() => {
        dispatch({
          type: LanguagesListPageConstants.CREATE_LANGUAGE_SUCCESS,
        });

        addNotification('Language was successfully added', 'normal')(dispatch);
      })
      .catch((error) => {
        dispatch({ type: LanguagesListPageConstants.CREATE_LANGUAGE_ERROR });

        throw error;
      });
  };
}

export function bulkDestroyLanguages(query) {
  return (dispatch) => {
    dispatch({ type: LanguagesListPageConstants.BULK_DESTROY_LANGUAGES_START });

    return LanguagesRepository.bulkDestroy(query).then(({ errors }) => {
      if (isPresent(errors)) {
        const languageIds = errors.map(prop('id')).join(', ');
        addNotification(`Languages with IDs ${languageIds} weren't deleted`, 'critical')(dispatch);
      }

      dispatch({ type: LanguagesListPageConstants.BULK_DESTROY_LANGUAGES_SUCCESS });
    });
  };
}
