import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import InfiniteScroll from 'react-infinite-scroller';
import { isBlank } from 'utils/HelperMethods';

import ProfilePresenter from 'presenters/ProfilePresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Loader from 'components/v2/Loader';

import Profile from './components/Profile';

import styles from './ExcludeProfilesForm.module.css';

const ExcludeProfilesForm = (props) => {
  const {
    project,
    filterProjectProfiles,
    nextPage,
    loading,
    loadProjectProfiles,
    onExcludedProfileIdsChange,
    profiles,
    relationshipFilters,
  } = props;

  const [filters, setFilters] = useState({
    searchField: '',
    sort: 'by_full_name',
    ...relationshipFilters,
  });
  const [excludedProfileIds, setExcludedProfileIds] = useState(props.excludedProfileIds);
  const { searchField } = filters;
  const isAllProfilesLoaded = isBlank(nextPage);

  useEffect(() => {
    filterProjectProfiles(ProjectPresenter.id(project), filters);
  }, []);

  const handleLoadMore = () => {
    if (!loading && !isAllProfilesLoaded) {
      loadProjectProfiles(ProjectPresenter.id(project), { ...filters, page: nextPage });
    }
  };

  const handleSearchFieldChange = ({ target: { value } }) => {
    const newFilters = { ...filters, searchField: value };

    setFilters(newFilters);
    filterProjectProfiles(project.id, newFilters);
  };

  const handleProfileExclusionChange = (newProfile) => {
    const profileId = ProfilePresenter.id(newProfile);
    const newExcludedProfileIds = excludedProfileIds.includes(profileId)
      ? excludedProfileIds.filter((id) => id !== profileId)
      : [...excludedProfileIds, profileId];

    setExcludedProfileIds(newExcludedProfileIds);
  };

  const handleApply = () => onExcludedProfileIdsChange(excludedProfileIds);

  const isProfileExcluded = (newProfile) => excludedProfileIds.includes(ProfilePresenter.id(newProfile));

  return (
    <div className={styles.container}>
      <h2 className={styles.title}> Exclude Executives</h2>
      <div className={styles.search}>
        <input
          autoComplete="off"
          className={styles.searchInput}
          maxLength={config.search.textValueMaxLength}
          onChange={handleSearchFieldChange}
          placeholder="Quick Search"
          value={searchField}
        />
        <Icon name="search" style={{ width: 14, height: 14 }} />
      </div>

      <div className={styles.information}>
        Selected: <span className={styles.count}>{excludedProfileIds.length}</span>
      </div>

      <div className={styles.list}>
        <InfiniteScroll
          loadMore={handleLoadMore}
          hasMore={!isAllProfilesLoaded}
          loader={<Loader />}
          useWindow={false}
          initialLoad={false}
        >
          {profiles.map((profile) => (
            <Profile
              key={ProfilePresenter.id(profile)}
              isExcluded={isProfileExcluded(profile)}
              onExclusionChange={handleProfileExclusionChange}
              profile={profile}
            />
          ))}
        </InfiniteScroll>
      </div>

      <Button className={styles.button} onClick={handleApply} primary>
        Apply
      </Button>
    </div>
  );
};

ExcludeProfilesForm.propTypes = {
  filterProjectProfiles: PropTypes.func.isRequired,
  loadProjectProfiles: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
  onExcludedProfileIdsChange: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
  project: ProjectPresenter.shape().isRequired,
  relationshipFilters: PropTypes.shape(),
  excludedProfileIds: PropTypes.arrayOf(PropTypes.number),
};

export default ExcludeProfilesForm;
