import keyMirror from 'keymirror';

const DashboardConstants = keyMirror({
  LOAD_CORPORATE_ACCOUNT_DASHBOARD_START: null,
  LOAD_CORPORATE_ACCOUNT_DASHBOARD_SUCCESS: null,
  LOAD_CORPORATE_ACCOUNT_DASHBOARD_ERROR: null,

  CHANGE_CORPORATE_ACCOUNT_DASHBOARD_FORM: null,
});

export default DashboardConstants;
