import React from 'react';
import PropTypes from 'prop-types';

import CompanyPresenter from 'presenters/CompanyPresenter';

import Typography from 'components/v2/Typography';
import { Field, Input, NestedCollection } from 'components/v2/Form';

import EmailSuffixes from './components/EmailSuffixes';

import styles from './SelfRegistrationFormFields.module.css';

const SelfRegistrationFormFields = (props) => {
  const { errors, companyValues, onFieldChange } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Self Registration Form </Typography>
      <Field title="Header:" error={errors.registrationFormHeader} variant="outlined">
        <Input onChange={handleChange('registrationFormHeader')} value={companyValues.registrationFormHeader} />
      </Field>
      <Field title="Email domain:" variant="outlined">
        <NestedCollection api component={EmailSuffixes} name="emailSuffixesAttributes" />
      </Field>
    </div>
  );
};

SelfRegistrationFormFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  companyValues: CompanyPresenter.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default SelfRegistrationFormFields;
