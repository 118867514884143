import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { pipe, props as rProps, includes } from 'ramda';

import { logClickOnRelationshipMappingShare, logClickOnRelationshipMappingTab } from 'utils/amplitude';

import ProjectPresenter, {
  PROJECT_PROFILE_RELATIONSHIPS_PDF_EXPORT,
  PROJECT_COLLEAGUE_RELATIONSHIPS_PDF_EXPORT,
  PROJECT_RELATIONSHIP_MAPPINGS_EXPORT,
} from 'presenters/ProjectPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import { FILTERS } from 'presenters/RelationshipsMappingPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import Tabs, { Tab } from 'components/Tabs';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'containers/UserPanel/components/ExportButton';
import Header from 'containers/UserPanel/containers/Project/components/Header';

import TopRelationships from './containers/TopRelationships';
import TopRelationshipsToMyExecutives from './containers/TopRelationshipsToMyExecutives';

import styles from './RelationshipsMapping.module.css';

const hasAnyCheckedFilter = (newFilters, filterKeys) => pipe(rProps(filterKeys), includes(true))(newFilters);

const hasAnyCheckedMyProjectFilter = (newFilters) =>
  hasAnyCheckedFilter(newFilters, [
    FILTERS.MUST_BE_EXECUTIVE,
    FILTERS.MUST_BE_BOARD_OF_DIRECTORS,
    FILTERS.MUST_BE_BOARD_OF_DIRECTORS_FTSE100,
  ]);

const hasAnyCheckedMyCompanyFilter = (newFilters) =>
  hasAnyCheckedFilter(newFilters, [
    FILTERS.COMPANY_MEMBER_MUST_BE_EXECUTIVE,
    FILTERS.COMPANY_MEMBER_MUST_BE_BOARD_MEMBER,
  ]);

const RelationshipsMapping = (props) => {
  const { onProjectDataExport, project, currentUser } = props;

  const [top50RelationshipsFilters, setTop50RelationshipsFilters] = useState({
    [FILTERS.EXCLUDE_IDS]: [],
    [FILTERS.MUST_BE_EXECUTIVE]: true,
    [FILTERS.MUST_BE_BOARD_OF_DIRECTORS]: true,
    [FILTERS.MUST_BE_BOARD_OF_DIRECTORS_FTSE100]: true,
  });
  const [connectionsToMyExecutivesFilters, setConnectionsToMyExecutivesFilters] = useState({
    [FILTERS.EXCLUDE_IDS]: [],
    [FILTERS.COMPANY_MEMBER_MUST_BE_EXECUTIVE]: true,
    [FILTERS.COMPANY_MEMBER_MUST_BE_BOARD_MEMBER]: true,
    [FILTERS.MUST_BE_EXECUTIVE]: true,
    [FILTERS.MUST_BE_BOARD_OF_DIRECTORS]: true,
    [FILTERS.MUST_BE_BOARD_OF_DIRECTORS_FTSE100]: true,
  });
  const [isShowTopRelationshipsFilters, setIsShowTopRelationshipsFilters] = useState(false);
  const [isShowTopRelationshipsToMyExecutivesFilters, setIsShowTopRelationshipsToMyExecutivesFilters] = useState(false);

  const handleTopRelationshipsFilterVisibilityChange = (value) => {
    if (value) setIsShowTopRelationshipsFilters(value);
  };

  const handleTopRelationshipsToMyExecutivesFilterVisibilityChange = (value) => {
    if (value) setIsShowTopRelationshipsToMyExecutivesFilters(value);
  };

  const breadcrumbs = [
    { name: 'Projects', to: Routes.projectsPath() },
    { name: ProjectPresenter.name(project), to: Routes.projectPath(ProjectPresenter.id(project)) },
    { name: 'Relationship Mapping' },
  ];

  const handleExport = () => {
    const type = PROJECT_RELATIONSHIP_MAPPINGS_EXPORT;
    const params = {
      profilesSearch: {
        top50RelationshipsSearch: top50RelationshipsFilters,
        connectionsToMyExecutivesSearch: connectionsToMyExecutivesFilters,
      },
    };

    logClickOnRelationshipMappingShare();

    return onProjectDataExport({ type, params });
  };

  const handleRelationshipsExport =
    (type) =>
    (profile, profilesSearch = {}) => {
      const params = { type, params: { profileId: profile.id, profilesSearch } };

      return onProjectDataExport(params);
    };

  return (
    <div className={styles.root}>
      <Header
        currentUser={currentUser}
        title={`${ProjectPresenter.name(project)}: Relationship Mapping`}
        breadcrumbs={breadcrumbs}
        project={project}
      >
        <BackgroundExportResultNotifier
          component={ExportButton}
          onExport={handleExport}
          onExportPropName="onClick"
          disabled={!ProjectPolicy.canExport(project)}
          label="Share"
        />
      </Header>
      <Tabs responsive={false} justify="start" className={styles.tabs}>
        <Tab
          title="Top 50 Relationships"
          onClick={() => {
            logClickOnRelationshipMappingTab('Top 50 Relationships');
          }}
        >
          <TopRelationships
            project={project}
            onRelationshipsExport={handleRelationshipsExport(PROJECT_PROFILE_RELATIONSHIPS_PDF_EXPORT)}
            onFiltersChange={setTop50RelationshipsFilters}
            filters={top50RelationshipsFilters}
            isShowFilters={isShowTopRelationshipsFilters}
            onFilterVisibilityChange={handleTopRelationshipsFilterVisibilityChange}
            hasAnyCheckedMyProjectFilter={hasAnyCheckedMyProjectFilter}
          />
        </Tab>

        <Tab
          title="Connections to My Executives"
          onClick={() => {
            logClickOnRelationshipMappingTab('Connections to My Executives');
          }}
        >
          <TopRelationshipsToMyExecutives
            project={project}
            onRelationshipsExport={handleRelationshipsExport(PROJECT_COLLEAGUE_RELATIONSHIPS_PDF_EXPORT)}
            onFiltersChange={setConnectionsToMyExecutivesFilters}
            filters={connectionsToMyExecutivesFilters}
            isShowFilters={isShowTopRelationshipsToMyExecutivesFilters}
            onFilterVisibilityChange={handleTopRelationshipsToMyExecutivesFilterVisibilityChange}
            currentUser={currentUser}
            hasAnyCheckedMyProjectFilter={hasAnyCheckedMyProjectFilter}
            hasAnyCheckedMyCompanyFilter={hasAnyCheckedMyCompanyFilter}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

RelationshipsMapping.propTypes = {
  project: ProjectPresenter.shape().isRequired,
  currentUser: CurrentUserPresenter.shape().isRequired,
  onProjectDataExport: PropTypes.func.isRequired,
};

export default RelationshipsMapping;
