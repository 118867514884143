import keyMirror from 'keymirror';

const ProfileRelationshipsListConstants = keyMirror({
  INITIALIZE_PROJECT_PROFILE_RELATIONSHIPS: null,

  LOAD_PROJECT_PROFILE_RELATIONSHIPS_START: null,
  LOAD_PROJECT_PROFILE_RELATIONSHIPS_SUCCESS: null,
});

export default ProfileRelationshipsListConstants;
