import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propOr, pipe, filter, map, any, equals, find, propEq, pick } from 'ramda';
import { Anchor, CheckBox, TextInput, Box, Table, TableRow } from 'grommet';
import { PasswordInput, NumberInput } from 'grommet-controls';

import { reactSelectDebounce } from 'utils/HelperMethods';
import { isPresent } from 'utils/HelperMethods';

import withConfirmation from 'hoc/withConfirmation';

import Button from 'components/Button';
import Select from 'components/Select';
import AsyncSelect from 'components/AsyncSelect';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import PhoneInput from 'components/PhoneInput';
import UploadForm from 'components/AvatarUploadForm';
import UserAvatar from 'components/UserAvatar';
import Modal from 'components/Modal';
import PasswordValidator from 'components/PasswordValidator';
import PasswordStrengthRating from 'components/PasswordStrengthRating';

import styles from './EditForm.module.css';

import { ROLES, STATES } from 'presenters/UserPresenter';
import UserEditForm from 'forms/UserEditForm';
import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';
import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';

const ROLE_OPTIONS = ROLES.map((r) => ({ label: r.title, value: r.key }));

const EditFormComponent = (props) => {
  const {
    onSubmit,
    saving,
    onSendResetPasswordEmail,
    isResetPasswordEmailSending,
    onCompanyDepartmentSearch,
    user,
    onCompanySearch,
  } = props;
  const [userForm, setUserForm] = useState(UserEditForm.defaultAttributes(user));
  const [errors, setErrors] = useState(null);
  const [isAvatarModalOpened, setAvatarModalOpened] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const { company, companyDepartment } = userForm;
  const projectsAvailableInCompanyDepartment = DepartmentPresenter.projectsAvailable(companyDepartment) || false;
  const databaseOnlyInCompanyDepartment = DepartmentPresenter.databaseOnly(companyDepartment) || false;
  const projectsExportEnabledIncompanyDepartment =
    DepartmentPresenter.projectsExportEnabled(companyDepartment) || false;
  const displayedStateEvents = [...user.stateEvents, find(propEq(user.state, 'key'), STATES).event];
  const isDownloadProfilesLimited =
    UserPresenter.isDownloadLimited(userForm) || UserPresenter.isIndividualAccount(userForm);

  const getStateOptions = () =>
    pipe(
      filter((s) => any(equals(s.event), displayedStateEvents)),
      map((state) => ({ label: state.title, value: state.key })),
    )(STATES);

  const handleSubmit = () => {
    onSubmit(UserEditForm.attributesToSubmit(userForm, user))
      .then((data) => {
        setUserForm(UserEditForm.defaultAttributes(data));
      })
      .catch((data) => {
        setErrors(data.errors);
      });
  };

  const updateFields = (fields) => {
    setUserForm({ ...userForm, ...fields });
  };

  const updateField = (fieldName, value) => {
    updateFields({ [fieldName]: value });
  };

  const handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      updateField(fieldName, value);

  const handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      updateField(fieldName, checked);

  const handlePhoneFieldChange = (fieldName) => (value) => updateField(fieldName, value || null);

  const handleCompanyChange = (newCompany) => {
    if (newCompany && company && newCompany.id === company.id) {
      return;
    }

    const defaultParams = pick(
      ['companyDepartment', 'requestsNumber', 'profileViewsNumber', 'profileDownloadsNumber', 'corporateViewAvailable'],
      UserEditForm.defaultAttributes({}),
    );

    setUserForm({ ...userForm, ...defaultParams, company: newCompany });
  };

  const handleCompanyDepartmentChange = (newCompanyDepartment) => {
    const newUser = {
      ...userForm,
      companyDepartment: newCompanyDepartment,
      projectsExportEnabled: userForm.projectsAvailable,
    };

    setUserForm(newUser);
  };

  const handleAccountTypeChange = (value) => {
    const defaultParams = pick(
      ['requestsNumber', 'profileViewsNumber', 'profileDownloadsNumber', 'databaseOnly'],
      UserEditForm.defaultAttributes({}),
    );

    setUserForm({ ...userForm, ...defaultParams, role: value });
  };

  const handleProjectsAvailabilityChange = ({ target: { checked } }) => {
    const defaultParams = pick(['projectsNumber', 'projectsExportEnabled'], UserEditForm.defaultAttributes({}));

    setUserForm({ ...userForm, ...defaultParams, projectsAvailable: checked });
  };

  const handleDatabaseOnlyChange = ({ target: { checked } }) => {
    const defaultParams = pick(['requestsNumber'], UserEditForm.defaultAttributes({}));

    setUserForm({ ...userForm, ...defaultParams, databaseOnly: checked });
  };

  const handleTwoFactorAuthenticationDisableChange = ({ target: { checked } }) => {
    setUserForm({ ...userForm, twoFactorAuthenticationDisabled: checked });
  };

  const handleCompanySearch = reactSelectDebounce(onCompanySearch);

  const handleCompanyDepartmentSearch = (value) => {
    const companyId = CompanyPresenter.id(company);

    return onCompanyDepartmentSearch(companyId, value);
  };

  const handleCompanyDepartmentSearchDebounced = reactSelectDebounce(handleCompanyDepartmentSearch);

  const handleOpenAvatarModal = () => setAvatarModalOpened(true);

  const handleCloseAvatarModal = () => setAvatarModalOpened(false);

  const handleAvatarChange = (avatar, avatarCropParams) => {
    const avatarUrl = isPresent(avatar) ? URL.createObjectURL(avatar) : null;

    updateFields({ avatar, avatarCropParams, avatarUrl });
    handleCloseAvatarModal();
  };

  const handleStateChange = (fieldName) => (value) => {
    updateField(fieldName, value);
  };

  const handleValidationPassword = (isValid) => setIsValidPassword(isValid);

  return (
    <div>
      <Select
        placeholder="Account Type"
        selectedOptionValue={userForm.role}
        options={ROLE_OPTIONS}
        onValueChange={handleAccountTypeChange}
      />

      <Select
        placeholder="User State"
        selectedOptionValue={userForm.state}
        options={getStateOptions()}
        onValueChange={handleStateChange('state')}
      />

      <VerticalFormFieldWithBottomErrors label="Avatar:" errors={errors && errors.avatar}>
        <div className={styles.avatarRow}>
          <UserAvatar src={UserPresenter.avatarUrl(userForm)} size={80} crop={userForm.avatarCropParams} />
          <Anchor size="small" onClick={handleOpenAvatarModal}>
            Upload New Avatar
          </Anchor>
        </div>
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="First Name:" errors={errors && errors.firstName} htmlFor="firstName">
        <TextInput value={userForm.firstName} onChange={handleFieldChange('firstName')} id="firstName" />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="Last Name:" errors={errors && errors.lastName} htmlFor="lastName">
        <TextInput value={userForm.lastName} onChange={handleFieldChange('lastName')} id="lastName" />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="Email:" errors={errors && errors.email} htmlFor="email">
        <TextInput value={userForm.email} onChange={handleFieldChange('email')} id="email" />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors label="Phone:" errors={errors && errors.phone} htmlFor="phone">
        <PhoneInput value={userForm.phone} onChange={handlePhoneFieldChange('phone')} id="phone" />
      </VerticalFormFieldWithBottomErrors>

      <Box direction="column" margin={{ bottom: 'small' }}>
        <AsyncSelect
          errors={errors && errors.company}
          onChange={handleCompanyChange}
          loadOptions={handleCompanySearch}
          defaultOptions
          isClearable
          getOptionValue={CompanyPresenter.id}
          getOptionLabel={CompanyPresenter.name}
          value={userForm.company}
          placeholder="Company"
          noBottom
        />
      </Box>

      {isPresent(company) && (
        <Box direction="column" margin={{ bottom: 'small' }}>
          <AsyncSelect
            key={CompanyPresenter.id(company)}
            errors={errors && errors.companyDepartment}
            onChange={handleCompanyDepartmentChange}
            loadOptions={handleCompanyDepartmentSearchDebounced}
            defaultOptions
            isClearable
            getOptionValue={DepartmentPresenter.id}
            getOptionLabel={DepartmentPresenter.nameWithState}
            value={userForm.companyDepartment}
            placeholder="Company Department"
            noBottom
          />
          {(projectsAvailableInCompanyDepartment ||
            DepartmentPresenter.isCustomerAccountAccountType(companyDepartment) ||
            DepartmentPresenter.isDemoAccountAccountType(companyDepartment) ||
            DepartmentPresenter.isPilotAccountAccountType(companyDepartment) ||
            DepartmentPresenter.isDownloadLimitedAccountType(companyDepartment) ||
            DepartmentPresenter.isIndividualAccountAccountType(companyDepartment)) && (
            <VerticalFormFieldWithBottomErrors label="Company Department Limits">
              <Table className={styles.table}>
                <tbody>
                  {(DepartmentPresenter.isCustomerAccountAccountType(companyDepartment) ||
                    DepartmentPresenter.isDemoAccountAccountType(companyDepartment) ||
                    DepartmentPresenter.isPilotAccountAccountType(companyDepartment) ||
                    DepartmentPresenter.isDownloadLimitedAccountType(companyDepartment) ||
                    DepartmentPresenter.isIndividualAccountAccountType(companyDepartment)) && (
                    <TableRow>
                      <td>Number of Requests</td>
                      <td>{companyDepartment.requestsNumber}</td>
                    </TableRow>
                  )}
                  {DepartmentPresenter.isDownloadLimitedAccountType(companyDepartment) && (
                    <TableRow>
                      <td>Number of Profile Views</td>
                      <td>{companyDepartment.profileViewsNumber}</td>
                    </TableRow>
                  )}
                  {isDownloadProfilesLimited && (
                    <TableRow>
                      <td>Number of Profile Downloads</td>
                      <td>{companyDepartment.profileDownloadsNumber}</td>
                    </TableRow>
                  )}
                  {projectsAvailableInCompanyDepartment && (
                    <TableRow>
                      <td>Number of Projects</td>
                      <td>{companyDepartment.projectsNumber}</td>
                    </TableRow>
                  )}
                </tbody>
              </Table>
            </VerticalFormFieldWithBottomErrors>
          )}
        </Box>
      )}

      {(UserPresenter.isCustomerAccount(userForm) ||
        UserPresenter.isDemoAccount(userForm) ||
        UserPresenter.isPilotAccount(userForm) ||
        UserPresenter.isDownloadLimited(userForm) ||
        UserPresenter.isIndividualAccount(userForm)) && (
        <VerticalFormFieldWithBottomErrors
          help={databaseOnlyInCompanyDepartment ? 'Enabled in company department' : null}
          checkbox
        >
          <CheckBox
            label="Database Only"
            toggle
            checked={userForm.databaseOnly || databaseOnlyInCompanyDepartment}
            disabled={databaseOnlyInCompanyDepartment}
            onChange={handleDatabaseOnlyChange}
          />
        </VerticalFormFieldWithBottomErrors>
      )}

      {!(userForm.databaseOnly || databaseOnlyInCompanyDepartment) && (
        <VerticalFormFieldWithBottomErrors
          label="Number of Requests"
          errors={errors && errors.requestsNumber}
          htmlFor="requestsNumber"
        >
          <NumberInput
            value={userForm.requestsNumber}
            onChange={handleFieldChange('requestsNumber')}
            widgets={[]}
            id="requestsNumber"
          />
        </VerticalFormFieldWithBottomErrors>
      )}

      {UserPresenter.isDownloadLimited(userForm) && (
        <VerticalFormFieldWithBottomErrors
          label="Number of Profile Views"
          errors={errors && errors.profileViewsNumber}
          htmlFor="profileViewsNumber"
        >
          <NumberInput
            value={userForm.profileViewsNumber}
            onChange={handleFieldChange('profileViewsNumber')}
            widgets={[]}
            id="profileViewsNumber"
          />
        </VerticalFormFieldWithBottomErrors>
      )}

      {isDownloadProfilesLimited && (
        <VerticalFormFieldWithBottomErrors
          label="Number of Profile Downloads"
          errors={errors && errors.profileDownloadsNumber}
          htmlFor="profileDownloadsNumber"
        >
          <NumberInput
            value={userForm.profileDownloadsNumber}
            onChange={handleFieldChange('profileDownloadsNumber')}
            widgets={[]}
            id="profileDownloadsNumber"
          />
        </VerticalFormFieldWithBottomErrors>
      )}

      {company && (
        <VerticalFormFieldWithBottomErrors checkbox>
          <CheckBox
            label="Corporate Usage View"
            toggle
            checked={userForm.corporateViewAvailable}
            onChange={handleCheckboxChange('corporateViewAvailable')}
          />
        </VerticalFormFieldWithBottomErrors>
      )}

      <VerticalFormFieldWithBottomErrors checkbox>
        <CheckBox
          label="Export Search Results to CSV"
          toggle
          checked={userForm.exportSearchResultInCsvAvailable}
          onChange={handleCheckboxChange('exportSearchResultInCsvAvailable')}
        />
      </VerticalFormFieldWithBottomErrors>

      <VerticalFormFieldWithBottomErrors
        help={projectsAvailableInCompanyDepartment ? 'Enabled in company department' : null}
        checkbox
      >
        <CheckBox
          label="Projects"
          toggle
          checked={userForm.projectsAvailable || projectsAvailableInCompanyDepartment}
          disabled={projectsAvailableInCompanyDepartment}
          onChange={handleProjectsAvailabilityChange}
        />
      </VerticalFormFieldWithBottomErrors>

      {(userForm.projectsAvailable || projectsAvailableInCompanyDepartment) && (
        <>
          <VerticalFormFieldWithBottomErrors
            label="Number of Projects"
            errors={errors && errors.projectsNumber}
            htmlFor="projectsNumber"
          >
            <NumberInput
              value={userForm.projectsNumber}
              onChange={handleFieldChange('projectsNumber')}
              widgets={[]}
              id="projectsNumber"
            />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors
            help={projectsExportEnabledIncompanyDepartment ? 'Enabled in company department' : null}
            checkbox
          >
            <CheckBox
              label="Export On Projects"
              toggle
              disabled={projectsExportEnabledIncompanyDepartment}
              checked={userForm.projectsExportEnabled || projectsExportEnabledIncompanyDepartment}
              onChange={handleCheckboxChange('projectsExportEnabled')}
            />
          </VerticalFormFieldWithBottomErrors>
        </>
      )}

      {(UserPresenter.isDownloadLimited(userForm) ||
        UserPresenter.isCustomerAccount(userForm) ||
        UserPresenter.isDemoAccount(userForm) ||
        UserPresenter.isPilotAccount(userForm) ||
        UserPresenter.isIndividualAccount(userForm)) && (
        <VerticalFormFieldWithBottomErrors checkbox>
          <CheckBox
            label="Disable Two-Factor Authentication"
            toggle
            checked={userForm.twoFactorAuthenticationDisabled}
            onChange={handleTwoFactorAuthenticationDisableChange}
          />
        </VerticalFormFieldWithBottomErrors>
      )}

      <VerticalFormFieldWithBottomErrors label="Password:" errors={errors && errors.password} htmlFor="password">
        <PasswordInput
          value={userForm.password}
          onChange={handleFieldChange('password')}
          autoComplete="new-password"
          id="password"
        />
      </VerticalFormFieldWithBottomErrors>
      {isPresent(userForm.password) && (
        <Box width="medium">
          <div className={styles.passwordStrengthRating}>
            <PasswordStrengthRating isValidPassword={isValidPassword} />
          </div>
          <div className={styles.passwordValidator}>
            <PasswordValidator password={userForm.password} onValidation={handleValidationPassword} />
          </div>
        </Box>
      )}

      <VerticalFormFieldWithBottomErrors
        label="Password Confirmation:"
        errors={errors && errors.passwordConfirmation}
        htmlFor="passwordConfirmation"
      >
        <PasswordInput
          value={userForm.passwordConfirmation}
          onChange={handleFieldChange('passwordConfirmation')}
          autoComplete="new-password"
          id="passwordConfirmation"
        />
      </VerticalFormFieldWithBottomErrors>
      <Box>
        <Anchor
          className={styles.sendResetPasswordEmail}
          disabled={saving || isResetPasswordEmailSending || user.email !== userForm.email}
          href="#"
          label="Send Reset Password Email"
          onClick={onSendResetPasswordEmail}
        />
      </Box>

      <Box direction="row" margin={{ top: 'medium' }} width="medium">
        <Button primary label="Save" disabled={saving} onClick={handleSubmit} />
      </Box>

      {isAvatarModalOpened && (
        <Modal header="Upload an avatar" size="medium" onClose={handleCloseAvatarModal}>
          <UploadForm
            onSubmit={handleAvatarChange}
            aspect={1}
            circularCrop
            errors={propOr([], 'avatar', errors)}
            saving={saving}
          />
        </Modal>
      )}
    </div>
  );
};

const EditForm = withConfirmation(EditFormComponent, {
  onSendResetPasswordEmail: 'Are you sure want to send reset password email?',
});

EditFormComponent.propTypes = {
  isResetPasswordEmailSending: PropTypes.bool.isRequired,
  onCompanyDepartmentSearch: PropTypes.func.isRequired,
  onCompanySearch: PropTypes.func.isRequired,
  onSendResetPasswordEmail: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  user: UserPresenter.shape(),
};

export default EditForm;
