import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/FormDataFetchHelpers';

export default {
  create(companyId, users) {
    const url = Routes.apiV1AdminCompanyMatchUsersPath(companyId);
    return FetchHelpers.post(url, { users });
  },

  validate(companyId, users) {
    const url = Routes.validateApiV1AdminCompanyMatchUsersPath(companyId);
    return FetchHelpers.post(url, { users });
  },
};
