import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Button from 'components/Button';
import Institution from './components/Institution';
import InstitutionForm from './components/InstitutionForm';
import withConfirmation from 'hoc/withConfirmation';

import ProfilePresenter from 'presenters/ProfilePresenter';
import InstitutionPresenter from 'presenters/InstitutionPresenter';

import styles from './Institutions.module.css';

import useAdminProfileDraftInstitution from 'hooks/admin/profile/useDraftInstitution';

const InstitutionsComponent = (props) => {
  const { profile, editable, saving, institutions } = props;
  const { createInstitution, updateInstitution, destroyInstitution } = useAdminProfileDraftInstitution();

  const [isAddInstitution, setIsAddInstitution] = useState(false);

  const handleCloseModal = () => setIsAddInstitution(false);

  const handleInstitutionCreate = (institution) => createInstitution(profile.id, institution).then(handleCloseModal);

  const handleInstitutionUpdate = (institutionId, institution) =>
    updateInstitution(profile.id, institutionId, institution).then(handleCloseModal);

  const handleInstitutionDestroy = (institutionId) => destroyInstitution(profile.id, institutionId);

  const handleAddNewInstitution = () => setIsAddInstitution(editable);

  return (
    <>
      {institutions.map((institution) => (
        <Box key={institution.id} direction="row" margin={{ bottom: 'small' }} className={styles.box}>
          <Institution
            editable={editable}
            institution={institution}
            saving={saving}
            onUpdate={handleInstitutionUpdate}
            onDestroy={handleInstitutionDestroy}
          />
        </Box>
      ))}

      <Box direction="row" margin={{ top: 'medium' }}>
        <Button label="Add New" primary disabled={!editable} onClick={handleAddNewInstitution} />
      </Box>

      {isAddInstitution && (
        <InstitutionForm
          title="Add New Education Item"
          institution={{}}
          saving={saving}
          onCancel={handleCloseModal}
          onSubmit={handleInstitutionCreate}
        />
      )}
    </>
  );
};

InstitutionsComponent.propTypes = {
  profile: ProfilePresenter.shape(),
  editable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  institutions: PropTypes.arrayOf(InstitutionPresenter.shape()).isRequired,
};

const Institutions = withConfirmation(InstitutionsComponent, {
  destroyInstitution: 'Are you sure want to delete?',
});
export default Institutions;
