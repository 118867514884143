import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Project from './components/Project';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';

import Routes from 'routes';

import styles from './Projects.module.css';

class Projects extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    projects: PropTypes.arrayOf(ProjectPresenter.shape()).isRequired,
  };

  render() {
    const { currentUser, projects } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.projects}>
          {projects.map((project) => (
            <Project key={ProjectPresenter.id(project)} currentUser={currentUser} project={project} />
          ))}
        </div>
        <div className={styles.actions}>
          <Button className={styles.button} label="See All Projects" href={Routes.projectsPath()} />
        </div>
      </div>
    );
  }
}

export default Projects;
