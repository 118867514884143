import NotificationsConstants from './NotificationsConstants';

export function closeNotification(notificationId) {
  return { type: NotificationsConstants.NOTIFICATION_CLOSED, notificationId };
}

export function addNotification(body, status) {
  return (dispatch) => {
    const notification = { id: Date.now(), body, status };
    dispatch({ type: NotificationsConstants.NOTIFICATION_ADDED, notification });

    setTimeout(() => {
      dispatch(closeNotification(notification.id));
    }, 5000);
  };
}
