import React, { Component } from 'react';
import { Sidebar, Box } from 'grommet';
import {
  Inbox,
  Group,
  Organization,
  UserSettings,
  Gamepad,
  Cluster,
  DocumentUser,
  NewWindow,
  Tag,
  Language,
  Flag,
} from 'grommet-icons';

import Tooltip from 'components/Tooltip';
import AnchorLink from 'components/grommet/AnchorLink';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RequestPolicy from 'policies/RequestPolicy';
import ProfilePolicy from 'policies/ProfilePolicy';
import OrganizationPolicy from 'policies/OrganizationPolicy';
import InterestPolicy from 'policies/InterestPolicy';
import SamplesPolicy from 'policies/SamplesPolicy';
import UserPolicy from 'policies/UserPolicy';
import CompanyPolicy from 'policies/CompanyPolicy';
import FullProfileRequestPolicy from 'policies/FullProfileRequestPolicy';
import TagPolicy from 'policies/TagPolicy';
import LanguagePolicy from 'policies/LanguagePolicy';
import CountryPolicy from 'policies/CountryPolicy';

import Routes from 'routes/App';

import styles from './Navbar.module.css';

class Navbar extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
  };

  renderMenuItem(text, icon, path, policy) {
    const { currentUser } = this.props;

    if (!policy.canSeeList(currentUser)) {
      return null;
    }

    return (
      <Tooltip text={text}>
        <AnchorLink to={path} className={styles.anchor} icon={icon} />
      </Tooltip>
    );
  }

  render() {
    return (
      <Sidebar className={styles.sidebar} size="xsmall">
        <Box flex="grow" justify="start" align="center" className={styles.nav}>
          {this.renderMenuItem(
            'Request',
            <Inbox size="17px" color="#666" />,
            Routes.adminProfileRequestsPath(),
            RequestPolicy,
          )}
          {this.renderMenuItem(
            'Profiles',
            <Group size="17px" color="#666" />,
            Routes.adminProfilesPath(),
            ProfilePolicy,
          )}
          {this.renderMenuItem(
            'Organizations',
            <Organization size="17px" color="#666" />,
            Routes.adminOrganizationsPath(),
            OrganizationPolicy,
          )}
          {this.renderMenuItem(
            'Interests',
            <Gamepad size="17px" color="#666" />,
            Routes.adminInterestsPath(),
            InterestPolicy,
          )}
          {this.renderMenuItem(
            'Samples',
            <DocumentUser size="17px" color="#666" />,
            Routes.adminSamplesPath(),
            SamplesPolicy,
          )}
          {this.renderMenuItem('Users', <UserSettings size="17px" color="#666" />, Routes.adminUsersPath(), UserPolicy)}
          {this.renderMenuItem(
            'Companies',
            <Cluster size="17px" color="#666" />,
            Routes.adminCompaniesPath(),
            CompanyPolicy,
          )}
          {this.renderMenuItem(
            'Full Profile Requests',
            <NewWindow size="17px" color="#666" />,
            Routes.adminFullProfileRequestsPath(),
            FullProfileRequestPolicy,
          )}
          {this.renderMenuItem('Tags', <Tag size="17px" color="#666" />, Routes.adminTagListPath(), TagPolicy)}
          {this.renderMenuItem(
            'Languages',
            <Language size="17px" color="#666" />,
            Routes.adminLanguagesPath(),
            LanguagePolicy,
          )}
          {this.renderMenuItem(
            'Countries',
            <Flag size="17px" color="#666" />,
            Routes.adminCountriesPath(),
            CountryPolicy,
          )}
        </Box>
      </Sidebar>
    );
  }
}

export default Navbar;
