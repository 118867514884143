import CsvSourceEditPageConstants from './CsvSourceEditPageConstants';

const initialState = {
  loading: false,
  source: null,
  remainingRequestsCount: null,
};

function CsvSourceEditPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CsvSourceEditPageConstants.LOAD_PROJECT_CSV_SOURCE_START: {
      return { ...state, loading: true, source: null, remainingRequestsCount: null };
    }
    case CsvSourceEditPageConstants.LOAD_PROJECT_CSV_SOURCE_SUCCESS: {
      const { source, remainingRequestsCount } = action;

      return { ...state, loading: false, source, remainingRequestsCount };
    }
    case CsvSourceEditPageConstants.CHANGE_PROJECT_CSV_SOURCE_REMAINING_REQUESTS_COUNT: {
      const { remainingRequestsCount } = action;

      return { ...state, remainingRequestsCount };
    }
    default:
      return state;
  }
}

export default CsvSourceEditPageReducer;
