import { keys, has, propOr } from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import styles from './Typography.module.css';

const VARIANTS = {
  body2: { component: 'p', className: styles.body2 },
  body1: { component: 'p', className: styles.body1 },
  h4: { component: 'h4', className: styles.h4 },
  h3: { component: 'h3', className: styles.h3 },
  h2: { component: 'h2', className: styles.h2 },
  h1: { component: 'h1', className: styles.h1 },
};

const COLORS = {
  mercury: styles.mercuryColor,
  orange: styles.orangeColor,
  primary: styles.primaryColor,
  silver: styles.silverColor,
  salmon: styles.salmonColor,
  boulder: styles.boulderColor,
  mineShaft: styles.mineShaftColor,
  mantis: styles.mantisColor,
  tundora: styles.tundoraColor,
  sundance: styles.sundanceColor,
};

const Typography = (props) => {
  const { children, className, color, variant, ...rest } = props;
  const { component: Component, className: componentClassName } = propOr({ component: 'p' }, variant)(VARIANTS);

  const typographyClassName = clsx(styles.root, componentClassName, {
    [COLORS[color]]: has(color, COLORS),
    [className]: isPresent(className),
  });

  return (
    <Component {...rest} className={typographyClassName}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(keys(COLORS)),
  variant: PropTypes.oneOf(keys(VARIANTS)),
};

Typography.defaultProps = {
  children: null,
  className: null,
  color: null,
  variant: 'body2',
};

export default Typography;
