import keyMirror from 'keymirror';

const DashboardPageConstants = keyMirror({
  LOAD_CUSTOMER_DASHBOARD_START: null,
  LOAD_CUSTOMER_DASHBOARD_SUCCESS: null,
  LOAD_CUSTOMER_DASHBOARD_ERROR: null,

  FILTER_REQUEST_HISTORY_START: null,
  FILTER_REQUEST_HISTORY_SUCCESS: null,
  FILTER_REQUEST_HISTORY_ERROR: null,
});

export default DashboardPageConstants;
