import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from 'grommet';

import { logCheckSearchProfilesShowOnlyMyAlerts } from 'utils/amplitude';
import { USER_BASED_FILTERS, humanFilterName } from 'forms/ProfileFilteredSearchForm';

import styles from './UserBasedFilters.module.css';

class UserBasedFilters extends Component {
  handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) => {
      const { onFilterChange } = this.props;

      onFilterChange(fieldName)(checked);
    };

  handleShowOnlyMyAlerts = (event) => {
    const { checked } = event.target;

    if (checked) {
      logCheckSearchProfilesShowOnlyMyAlerts();
    }

    this.handleCheckboxChange(USER_BASED_FILTERS.ONLY_MY_ALERTS)(event);
  };

  renderFilter(filter, handleChange) {
    const { isLoading, searchParams } = this.props;
    const checked = searchParams[filter] || false;

    return (
      <CheckBox
        className={styles.checkbox}
        checked={checked}
        disabled={isLoading}
        label={humanFilterName(filter)}
        onChange={handleChange}
      />
    );
  }

  render() {
    return (
      <div className={styles.partition}>
        <div className={styles.title}>Show</div>
        {this.renderFilter(USER_BASED_FILTERS.ONLY_MY_ALERTS, this.handleShowOnlyMyAlerts)}
      </div>
    );
  }
}

UserBasedFilters.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  searchParams: PropTypes.shape().isRequired,
};

export default UserBasedFilters;
