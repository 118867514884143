import React, { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import { SuccessModal, ErrorModal } from 'components/BackgroundExportResultNotifier';
import Button from 'components/Button';
import Modal from 'components/UserModal';

import ExportJobPresenter from 'presenters/ExportJobPresenter';

import ExportsRepository from 'repositories/CurrentUser/ExportsRepository';

import styles from './ProfilesExportResultNotifier.module.css';

const INITIAL_STATE = {
  isLoading: false,
  isErrorModalOpened: false,
  isRunOutOfCreditsErrorModalOpened: false,
  isSuccessModalOpened: false,
};

class ProfilesExportResultNotifier extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onExportPropName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    shouldOpenOnCompletion: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    shouldOpenOnCompletion: false,
  };

  state = INITIAL_STATE;

  isRunOutOfCredits = (errors) =>
    isPresent(errors) && isPresent(errors.base) && errors.base.includes('run_out_of_credits');

  handleCloseAllModals = () => this.setState({ ...INITIAL_STATE });

  handleOpenSuccessModal = () => this.setState({ ...INITIAL_STATE, isSuccessModalOpened: true });

  handleOpenRunOutOfCreditsErrorModal = () =>
    this.setState({ ...INITIAL_STATE, isRunOutOfCreditsErrorModalOpened: true });

  handleOpenErrorModal = () => this.setState({ ...INITIAL_STATE, isErrorModalOpened: true });

  handleOpenFileInNewTab = (fileUrl) => window.open(fileUrl);

  handleUpdateStatus = (id, retriesCount) => {
    ExportsRepository.show(id)
      .then(({ exportJob }) => {
        if (ExportJobPresenter.isCompleted(exportJob)) {
          this.setState(INITIAL_STATE);
          this.handleOpenFileInNewTab(ExportJobPresenter.fileUrl(exportJob));
          return;
        }

        if (ExportJobPresenter.isQueued(exportJob)) {
          this.handlePolling(id, retriesCount - 1);
          return;
        }

        this.handleOpenErrorModal();
      })
      .catch((error) => {
        if ([401, 403, 404, 500, 502].includes(error.status)) {
          this.handleOpenErrorModal();
          return;
        }

        this.handlePolling(id, retriesCount - 1);
      });
  };

  handlePolling = (id, retriesCount = 200) => {
    if (retriesCount >= 0) {
      setTimeout(() => this.handleUpdateStatus(id, retriesCount), 800);
    }
  };

  handleSuccess = (exportJob) => {
    const { shouldOpenOnCompletion } = this.props;

    if (shouldOpenOnCompletion) {
      this.handlePolling(exportJob.id);
      return;
    }

    this.handleOpenSuccessModal();
  };

  handleExport = () => {
    const { onExport } = this.props;

    this.setState({ isLoading: true });

    return onExport()
      .then(this.handleSuccess)
      .catch(({ errors }) => {
        if (this.isRunOutOfCredits(errors)) {
          this.handleOpenRunOutOfCreditsErrorModal();
        } else {
          this.handleOpenErrorModal();
        }
      });
  };

  renderRunOutOfDownloadCreditsErrorModal() {
    return (
      <Modal size="large" onClose={this.handleCloseAllModals}>
        <div className={styles.main}>
          <h1 className={styles.text}>
            That’s great!
            <br />
            You’ve used all your download credits.
          </h1>
          <div className={styles.recomendation}>
            Please contact your account executive or customerservice@boardroominsiders.com to get more.
          </div>
          <Button onClick={this.handleCloseAllModals} primary label="Close" />
        </div>
      </Modal>
    );
  }

  render() {
    const { component, onExport, onExportPropName, shouldOpenOnCompletion, ...props } = this.props;
    const { isLoading, isErrorModalOpened, isRunOutOfCreditsErrorModalOpened, isSuccessModalOpened } = this.state;

    return (
      <>
        {createElement(component, {
          ...props,
          disabled: props.disabled || isLoading,
          isLoading,
          [onExportPropName]: this.handleExport,
        })}
        {isRunOutOfCreditsErrorModalOpened && this.renderRunOutOfDownloadCreditsErrorModal()}
        {isSuccessModalOpened && <SuccessModal onClose={this.handleCloseAllModals} />}
        {isErrorModalOpened && <ErrorModal onClose={this.handleCloseAllModals} />}
      </>
    );
  }
}

export default ProfilesExportResultNotifier;
