import { pipe, flatten, values, join } from 'ramda';

import { buildFilterParams } from 'utils/FilterParamsBuilder';
import RequestsKanbanBoardConstants from './RequestsKanbanBoardConstants';
import RequestsRepository from 'repositories/admin/RequestsRepository';
import AttributeSafeUsersRepository from 'repositories/admin/AttributeSafeUsersRepository';

import { addNotification } from 'containers/Notifications';

export function initializeKanbanBoard(columns) {
  return { type: RequestsKanbanBoardConstants.INITIALIZE_KANBAN_BOARD, columns };
}

export function filterRequests(columnId, filters) {
  return (dispatch) => {
    dispatch({ type: RequestsKanbanBoardConstants.FILTER_REQUESTS_START, columnId, filters });

    const params = { page: 1, ...buildFilterParams(filters) };
    return RequestsRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: RequestsKanbanBoardConstants.FILTER_REQUESTS_SUCCESS,
          columnId,
          filters,
          cards: items,
          nextPage: meta.nextPage,
          totalCount: meta.totalCount,
        });

        return items;
      })
      .catch((error) => {
        dispatch({ type: RequestsKanbanBoardConstants.FILTER_REQUESTS_ERROR, columnId, filters });

        throw error;
      });
  };
}

export function loadRequests(columnId, filters, page) {
  return (dispatch) => {
    dispatch({ type: RequestsKanbanBoardConstants.LOAD_REQUESTS_START, columnId });
    const params = { page, ...buildFilterParams(filters) };

    return RequestsRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: RequestsKanbanBoardConstants.LOAD_REQUESTS_SUCCESS,
          columnId,
          filters,
          cards: items,
          nextPage: meta.nextPage,
          totalCount: meta.totalCount,
        });

        return items;
      })
      .catch((error) => {
        dispatch({ type: RequestsKanbanBoardConstants.LOAD_REQUESTS_ERROR, columnId });
        throw error;
      });
  };
}

export function setDroppableColumns(columnIds) {
  return { type: RequestsKanbanBoardConstants.SET_DROPPABLE_COLUMNS, columnIds };
}

export function updateRequest(requestId, params) {
  return (dispatch) => {
    dispatch({ type: RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_START });

    return RequestsRepository.update(requestId, params)
      .then(({ request }) => {
        dispatch({ type: RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_SUCCESS, request });

        return request;
      })
      .catch((error) => {
        dispatch({ type: RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_ERROR });

        const textError = pipe(values, flatten, join(', '))(error.errors);
        addNotification(`Request update error: ${textError}`, 'critical')(dispatch);

        throw error;
      });
  };
}

export function moveRequest(requestId, fromColumnId, toColumnId, toPosition) {
  return {
    type: RequestsKanbanBoardConstants.MOVE_REQUEST,
    fromColumnId,
    requestId,
    toColumnId,
    toPosition,
  };
}

export function updateRequestData(request) {
  return { type: RequestsKanbanBoardConstants.UPDATE_KANBAN_REQUEST_DATA, request };
}

export function searchUser(filters) {
  return () => {
    const params = buildFilterParams(filters);

    return AttributeSafeUsersRepository.index(params).then(({ items }) => items);
  };
}
