import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';
import { decamelize } from 'utils/keysConverter';

export default {
  index(companyId, query) {
    const url = Routes.apiV1AdminCompanyDepartmentsPath(companyId, decamelize(query));

    return FetchHelpers.get(url);
  },

  show(companyId, departmentId) {
    const url = Routes.apiV1AdminCompanyDepartmentPath(companyId, departmentId);

    return FetchHelpers.get(url);
  },

  update(companyId, departmentId, companyDepartment) {
    const url = Routes.apiV1AdminCompanyDepartmentPath(companyId, departmentId);

    return FetchHelpers.put(url, { companyDepartment });
  },

  create(companyId, companyDepartment) {
    const url = Routes.apiV1AdminCompanyDepartmentsPath(companyId);

    return FetchHelpers.post(url, { companyDepartment });
  },

  destroy(companyId, departmentId) {
    const url = Routes.apiV1AdminCompanyDepartmentPath(companyId, departmentId);

    return FetchHelpers.delete(url);
  },
};
