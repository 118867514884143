import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { isBlank, isPresent, isBoardexValid } from 'utils/HelperMethods';
import { parseLocationToParams, buildUrl } from 'utils/UrlHelper';

import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import Spinner from 'components/Spinner';
import RelationshipsMappingPage from './containers/RelationshipsMappingPage';
import Profile from 'containers/UserPanel/components/Profile';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';

import Routes from 'routes';

import useCurrentUserGuideVisibility from 'hooks/client/useCurrentUserGuideVisibility';

const ProfileScope = (props) => {
  const {
    currentUser,
    backgroundExport,
    loading,
    match,
    profile,
    isUpdateRequestSaving,
    isSubscriptionsSaving,
    updateBatchSubscriptions,
    subscriptions,
    loadProfile,
    location,
    requestProfileUpdate,
    loadIdentifiers,
    relsci,
  } = props;
  const sharedContext = { backgroundExport, currentUser, profile };
  const { slug } = match.params;
  const { hideGuide, revealGuide } = useCurrentUserGuideVisibility();

  const profileHighlightsParameters = () => {
    const { keywords, tags } = qs.parse(location.search, { ignoreQueryPrefix: true, arrayFormat: 'bracket' });

    return { keywords, tags };
  };

  const handleLoadProfile = () =>
    loadProfile(slug, profileHighlightsParameters()).then((p) => loadIdentifiers({ ids: [p.id], content: 'profile' }));

  useEffect(() => {
    const { source, sign_in_url: signInUrl } = parseLocationToParams(location);
    if (isBoardexValid({ source, url: signInUrl })) {
      if (isPresent(currentUser)) {
        handleLoadProfile();
      } else {
        const url = buildUrl(signInUrl, { sign_in_url: signInUrl, source, profile, success_path: location.pathname });
        window.location = url;
      }
    } else {
      handleLoadProfile();
    }
  }, []);

  const handleProfileUpdateRequest = () => {
    const profileId = ProfilePresenter.id(profile);

    requestProfileUpdate(profileId);
  };

  const boardexProfileUrl = relsci && relsci[profile.id];

  if (loading || isBlank(profile) || (profile.slug !== slug && profile.id !== Number(slug))) {
    return <Spinner />;
  }

  return (
    <AppSwitch sharedContext={sharedContext}>
      <AppRoute
        render={(profileProps) => (
          <Profile
            {...profileProps}
            isUpdateRequestSaving={isUpdateRequestSaving}
            onProfileUpdateRequest={handleProfileUpdateRequest}
            highlightsParameters={profileHighlightsParameters()}
            isSubscriptionsSaving={isSubscriptionsSaving}
            updateBatchSubscriptions={updateBatchSubscriptions}
            subscriptions={subscriptions}
            onLoadProfile={handleLoadProfile}
            hideGuideForever={hideGuide}
            revealGuideForever={revealGuide}
            boardexProfileUrl={boardexProfileUrl}
          />
        )}
        exact
        path={Routes.profilePath(':slug')}
      />
      <AppRoute component={RelationshipsMappingPage} path={Routes.profileRelationshipsMappingPath(':slug')} />
    </AppSwitch>
  );
};

ProfileScope.propTypes = {
  backgroundExport: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape(),
  location: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  loadProfile: PropTypes.func.isRequired,
  isUpdateRequestSaving: PropTypes.bool.isRequired,
  profile: ProfilePresenter.shape(),
  requestProfileUpdate: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  isSubscriptionsSaving: PropTypes.bool.isRequired,
  updateBatchSubscriptions: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  relsci: PropTypes.shape({}),
};

export default ProfileScope;
