import keyMirror from 'keymirror';

const SessionConstants = keyMirror({
  AUTHENTICATE_START: null,

  SIGN_OUT_START: null,
  SIGN_OUT_SUCCESS: null,
  SIGN_OUT_ERROR: null,
});

export default SessionConstants;
