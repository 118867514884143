import UserCardsConstants from './UserCardsConstants';
import PriorityUpdatesRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/PriorityUpdatesRepository';
import RequestRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/PriorityUpdates/RequestRepository';

export function loadPriorityUpdates() {
  return (dispatch) => {
    dispatch({ type: UserCardsConstants.LOAD_DATABASE_ANALYTIC_PRIORITY_UPDATES_START });

    return PriorityUpdatesRepository.index().then(({ items }) => {
      dispatch({
        type: UserCardsConstants.LOAD_DATABASE_ANALYTIC_PRIORITY_UPDATES_SUCCESS,
        items,
      });
    });
  };
}

export function createMostViewedRequest(priorityUpdateId, params = {}) {
  return (dispatch) => {
    dispatch({ type: UserCardsConstants.CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_START });

    return RequestRepository.create(priorityUpdateId, params)
      .then(({ request }) => {
        dispatch({
          type: UserCardsConstants.CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_SUCCESS,
          request,
        });

        return request;
      })
      .catch((error) => {
        dispatch({
          type: UserCardsConstants.CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_ERROR,
          error,
        });

        throw error;
      });
  };
}
