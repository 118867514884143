import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-polyfill';

import { useAdminCompanyDepartments, useAdminCompanyDepartmentLimitedAccess } from 'hooks/admin';

import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import Spinner from 'components/Spinner';
import PageHeader from 'components/PageHeader';
import RoutedTabs, { RoutedTab } from 'components/v2/RoutedTabs';

import ApiV2AccessSettingsTab from './components/ApiV2AccessSettingsTab';
import EditTab from './components/EditTab';
import EditSelfRegistrationTab from './components/EditSelfRegistrationTab';
import ExplicitlyGrantedProfilesTab from './components/ExplicitlyGrantedProfilesTab';
import SetupLimitedAccessTab from './components/SetupLimitedAccessTab';

import LimitedAccessPresenter from 'presenters/Company/Department/LimitedAccessPresenter';

import Routes from 'routes';

import styles from './DepartmentPage.module.css';

const DepartmentPage = () => {
  const { companyId, companyDepartmentId } = useParams();
  const { companyDepartment, loadCompanyDepartment, companyDepartmentLoadingStatus } = useAdminCompanyDepartments();
  const { limitedAccess, loadLimitedAccess } = useAdminCompanyDepartmentLimitedAccess();

  useEffect(() => {
    loadCompanyDepartment(companyId, companyDepartmentId);
    loadLimitedAccess(companyId, companyDepartmentId);
  }, []);

  if (companyDepartmentLoadingStatus.isPending || companyDepartment?.id !== Number(companyDepartmentId)) {
    return <Spinner />;
  }

  const { company } = companyDepartment;
  const isLimitedAccessEnabled = LimitedAccessPresenter.isEnabled(limitedAccess);
  const currentCompanyPageUrl = Routes.editAdminCompanyPath(companyId);

  return (
    <div className={styles.root}>
      <PageHeader title={`${company.name}: ${companyDepartment.name}`} backTo={currentCompanyPageUrl} />
      <div className={styles.tabsContainer}>
        <RoutedTabs className={styles.tabs}>
          <RoutedTab path={Routes.editAdminCompanyDepartmentPath(companyId, companyDepartmentId)}>
            Edit <br />
            Department
          </RoutedTab>
          <RoutedTab path={Routes.editAdminCompanyDepartmentSelfRegistrationFormPath(companyId, companyDepartmentId)}>
            Edit Self <br />
            Registration Form
          </RoutedTab>
          <RoutedTab path={Routes.editAdminCompanyDepartmentLimitedAccessPath(companyId, companyDepartmentId)}>
            Setup <br />
            Limited Access
          </RoutedTab>
          {isLimitedAccessEnabled && (
            <RoutedTab
              path={Routes.editAdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath(
                companyId,
                companyDepartmentId,
              )}
            >
              Edit Explicitly <br />
              Granted Profiles
            </RoutedTab>
          )}
          <RoutedTab path={Routes.editAdminCompanyDepartmentApiV2AccessSettingsPath(companyId, companyDepartmentId)}>
            API v2 <br />
            Access Settings
          </RoutedTab>
        </RoutedTabs>
        <div className={styles.content}>
          <AppSwitch>
            <AppRoute
              exact
              path={Routes.editAdminCompanyDepartmentPath(':companyId', ':companyDepartmentId')}
              component={EditTab}
            />
            <AppRoute
              exact
              path={Routes.editAdminCompanyDepartmentSelfRegistrationFormPath(':companyId', ':companyDepartmentId')}
              component={EditSelfRegistrationTab}
            />
            <AppRoute
              exact
              path={Routes.editAdminCompanyDepartmentLimitedAccessPath(':companyId', ':companyDepartmentId')}
              component={SetupLimitedAccessTab}
            />

            {isLimitedAccessEnabled && (
              <AppRoute
                exact
                path={Routes.editAdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfilesPath(
                  ':companyId',
                  ':companyDepartmentId',
                )}
                component={ExplicitlyGrantedProfilesTab}
              />
            )}
            <AppRoute
              exact
              path={Routes.editAdminCompanyDepartmentApiV2AccessSettingsPath(':companyId', ':companyDepartmentId')}
              component={ApiV2AccessSettingsTab}
            />

            <Redirect
              from={Routes.adminCompanyDepartmentPath(companyId, companyDepartmentId)}
              to={Routes.editAdminCompanyDepartmentPath(companyId, companyDepartmentId)}
            />
          </AppSwitch>
        </div>
      </div>
    </div>
  );
};

export default DepartmentPage;
