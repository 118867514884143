import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import LegalSettingRepository from 'repositories/admin/Companies/LegalSettingRepository';

const restSlice = createRestSlice({
  resource: 'legalSetting',
  repository: LegalSettingRepository,
  slices: ['loadLegalSetting', 'updateLegalSetting'],
});

const initialState = {
  ...restSlice.initialState,
};

const legalSettingSlice = createSlice({
  name: 'adminCompanyLegalSetting',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyLegalSettingReducer = legalSettingSlice.reducer;

export default legalSettingSlice;

export const adminCompanyLegalSettingRestHooks = restSlice.hooks(legalSettingSlice, 'adminCompanyLegalSettingReducer');
