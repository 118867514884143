import React, { Component } from 'react';
import { Paragraph, Box } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import Headline from 'components/grommet/Headline';
import Icon from 'components/Icon';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './ReviewOrganizationInformation.module.css';

class ReviewOrganizationInformation extends Component {
  static propTypes = {
    value: OrganizationPresenter.shape(),
  };

  render() {
    const { value: organization } = this.props;
    const logoUrl = OrganizationPresenter.logoUrl(organization);
    const organizatinonName = OrganizationPresenter.name(organization);

    return (
      <div className={styles.root}>
        <div className={styles.avatar}>
          {isBlank(logoUrl) ? (
            <Icon name="organizationPlaceholder" />
          ) : (
            <div className={styles.container}>
              <img src={logoUrl} className={styles.logo} alt={organizatinonName} />
            </div>
          )}
        </div>
        <Box margin={{ left: 'small' }}>
          <Headline size="small" margin="none" className={styles.organizatinonName}>
            {organizatinonName}
          </Headline>
          <Paragraph margin="none"> {OrganizationPresenter.ticker(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.industryName(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.naicsIndustryCodeName(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.fortuneRank(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.ftseRank(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.global2000Rank(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.corporateDuns(organization)} </Paragraph>
          <Paragraph margin="none"> {OrganizationPresenter.ricCode(organization)} </Paragraph>
        </Box>
      </div>
    );
  }
}

export default ReviewOrganizationInformation;
