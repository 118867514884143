import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { trimExtraEmptyLinesFromHTML } from 'utils/HTMLHelpers';

import Button from 'components/Button';
import { Box, CheckBox, TextInput } from 'grommet';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import TopicTagsSelect from 'containers/AdminPanel/components/TopicTagsSelect';

import Modal from 'components/Modal';
import TopicPresenter from 'presenters/TopicPresenter';
import Wysiwyg from 'components/Wysiwyg';

import TopicEditForm from 'forms/profile/TopicEditForm';
import TagPresenter from 'presenters/TagPresenter';

import styles from './ProfileTopicFormPopup.module.css';

export default class ProfileTopicFormPopup extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    topic: TopicPresenter.shape(),
    saving: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
    displayTags: PropTypes.bool.isRequired,
  };

  constructor(...attrs) {
    super(...attrs);

    const { topic } = this.props;
    this.state = {
      topic: TopicEditForm.defaultAttributes(topic),
      errors: null,
    };
  }

  handleChange = (fieldName) => (event) => {
    this.setState({ topic: { ...this.state.topic, [fieldName]: event.target.value } });
  };

  handleWysiwygChange = (fieldName) => (data) => {
    this.setState({ topic: { ...this.state.topic, [fieldName]: data } });
  };

  handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      this.setState({ topic: { ...this.state.topic, [fieldName]: checked } });

  handleSubmit = () => {
    const attributes = TopicEditForm.attributesToSubmit(this.state.topic);
    const trimmedDescription = trimExtraEmptyLinesFromHTML(attributes.description);

    this.props
      .onSubmit({ ...attributes, description: trimmedDescription })
      .catch(({ errors }) => this.setState({ errors }));
  };

  handleTagAdd = (tag) => {
    const { topic } = this.state;

    this.setState({ topic: { ...topic, tags: [...topic.tags, tag] } });
  };

  handleTagDelete = (tagToDelete) => {
    const { topic } = this.state;
    const tags = topic.tags.filter((tag) => TagPresenter.id(tag) !== TagPresenter.id(tagToDelete));

    this.setState({ topic: { ...topic, tags } });
  };

  decodeHtmlBody(htmlBody) {
    const htmlValue = isNil(htmlBody) ? '' : htmlBody;
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<!doctype html><body>${htmlValue}`, 'text/html');

    return dom.body.innerHTML;
  }

  render() {
    const { topic, errors } = this.state;
    const { onTagSearch, saving, title, displayTags } = this.props;

    return (
      <Modal
        onClose={this.props.onCancel}
        header={title}
        margin="large"
        size="large"
        disableOverlayClose
        disableCloseByEsc
      >
        <VerticalFormFieldWithBottomErrors label="Title" errors={errors && errors.title} htmlFor="title">
          <TextInput value={topic.title} onChange={this.handleChange('title')} id="title" />
        </VerticalFormFieldWithBottomErrors>
        <div className={styles.wrapper}>
          <Wysiwyg
            editableContent={this.decodeHtmlBody(topic.description)}
            onChange={this.handleWysiwygChange('description')}
          />
        </div>

        {displayTags && (
          <TopicTagsSelect
            saving={saving}
            onTagAdd={this.handleTagAdd}
            onTagDelete={this.handleTagDelete}
            onTagSearch={onTagSearch}
            tags={topic.tags}
          />
        )}

        <VerticalFormFieldWithBottomErrors errors={errors && errors.pinned} checkbox>
          <CheckBox label="Pinned" toggle checked={!!topic.pinned} onChange={this.handleCheckboxChange('pinned')} />
        </VerticalFormFieldWithBottomErrors>

        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}
