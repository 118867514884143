import React from 'react';
import PropTypes from 'prop-types';
import { goToTop } from 'utils/DOMHelpers';

import CrossIcon from 'icons/CrossIcon';

import Breadcrumbs from 'components/Breadcrumbs';

import Wizard from 'components/v2/Wizard';
import Button from 'components/v2/Button';

import styles from './FramedWizard.module.css';

const FramedWizard = (props) => {
  const { breadcrumbs, children, onWizardClose } = props;

  return (
    <div className={styles.root}>
      <div className={styles.breadcrumbs}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div className={styles.wizard}>
        <Button className={styles.close} color="secondary" onClick={onWizardClose} variant="icon">
          <CrossIcon width={24} height={24} />
        </Button>
        <Wizard onStepChange={goToTop}>{children}</Wizard>
      </div>
    </div>
  );
};

FramedWizard.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  children: PropTypes.node.isRequired,
  onWizardClose: PropTypes.func.isRequired,
};

export default FramedWizard;
