import { useEffect, useRef, useState } from 'react';
import { propOr } from 'ramda';

const DEFAULT_TIMEOUT = 5000;

export default function usePolling(func, isRun, options = {}) {
  const timerId = useRef();
  const [cycle, setCycle] = useState(0);
  const key = propOr(null, 'key', options);

  const handleCycleIncrement = () => setCycle((value) => value + 1);
  const handlePoll = () => func().finally(handleCycleIncrement);

  const startPolling = () => {
    const timeout = propOr(DEFAULT_TIMEOUT, 'timeout', options);

    timerId.current = setTimeout(handlePoll, timeout);
  };
  const stopPolling = () => {
    clearTimeout(timerId.current);
    timerId.current = null;
  };

  useEffect(() => {
    if (isRun) {
      startPolling();
    }

    return stopPolling;
  }, [isRun, key, cycle]);
}
