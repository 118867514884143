import React from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import { withRouter } from 'react-router-dom';
import { toRelativeCalendar } from 'utils/DateTime';
import { isPresent } from 'utils/HelperMethods';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';

import Button from 'components/Button';
import ProfileAvatar from 'components/ProfileAvatar';

import styles from './Statistics.module.css';

const MAX_NUMBER_VISIBLE_PROFILES = 5;

const StatisticsComponent = (props) => {
  const { history, path, description, profiles, profilesTotalCount, title, updatedAt } = props;

  const visibleProfiles = take(MAX_NUMBER_VISIBLE_PROFILES, profiles);
  const notVisibleProfilesCount = profilesTotalCount - visibleProfiles.length;

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    history.push(path);
  };

  return (
    <Button className={styles.root} onClick={handleClick}>
      <div className={styles.title}> {title} </div>

      <div className={styles.profiles}>
        {visibleProfiles.map((profile) => (
          <div key={ProfilePresenter.id(profile)} className={styles.profile}>
            <div className={styles.avatarBox}>
              <ProfileAvatar className={styles.avatar} src={ProfilePresenter.photoUrl(profile)} size="40" />
            </div>
          </div>
        ))}

        {notVisibleProfilesCount > 0 && (
          <div className={styles.profile}>
            <div className={styles.avatarBox}>
              <div className={styles.avatar}>{notVisibleProfilesCount} +</div>
            </div>
          </div>
        )}
      </div>

      <div className={styles.info}>
        <div className={styles.count}>{description}</div>
        {isPresent(updatedAt) && <div className={styles.date}>Updated: {toRelativeCalendar(updatedAt)}</div>}
      </div>
    </Button>
  );
};

StatisticsComponent.propTypes = {
  description: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  path: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
  profilesTotalCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
};

const Statistics = withRouter(StatisticsComponent);

export default Statistics;
