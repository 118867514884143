import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TagCloud } from 'react-tagcloud';
import { sortBy } from 'ramda';

import innerStyles from './Cloud.module.css';

import WordPresenter from 'presenters/Project/WordPresenter';

const styles = [
  { fontSize: 15, color: '#005D78' },
  { fontSize: 18, color: '#005D78' },
  { fontSize: 21, color: '#005D78' },
  { fontSize: 24, color: '#005D78' },

  { fontSize: 27, color: '#0085AB' },
  { fontSize: 31, color: '#0085AB' },
  { fontSize: 34, color: '#0085AB' },
  { fontSize: 37, color: '#0085AB' },

  { fontSize: 40, color: '#79CDE5' },
  { fontSize: 43, color: '#79CDE5' },
  { fontSize: 46, color: '#79CDE5' },
  { fontSize: 49, color: '#79CDE5' },

  { fontSize: 51, color: '#00A0CE' },
  { fontSize: 54, color: '#00A0CE' },
  { fontSize: 57, color: '#00A0CE' },
  { fontSize: 60, color: '#00A0CE', fontWeight: 'bold' },
];

class Cloud extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    words: PropTypes.arrayOf(WordPresenter.shape()).isRequired,
  };

  shouldComponentUpdate() {
    return false;
  }

  words() {
    const { words } = this.props;
    const sortedWords = sortBy(WordPresenter.strength, words).reverse();

    return sortedWords.reduce((acc, word, index) => {
      const count = WordPresenter.strength(word);
      return index % 2 === 0 ? [...acc, { ...word, count }] : [{ ...word, count }, ...acc];
    }, []);
  }

  normalizedWordStrength(word) {
    const { words } = this.props;
    const wordStrength = WordPresenter.strength(word);
    const minStrength = Math.min.apply(null, words.map(WordPresenter.strength));
    const maxStrength = Math.max.apply(null, words.map(WordPresenter.strength));

    if (minStrength === maxStrength) {
      return 0;
    }

    const percentage = ((wordStrength - minStrength) / (maxStrength - minStrength)) * 100;

    return Math.round((percentage * (styles.length - 1)) / 100);
  }

  renderWord = (word) => {
    const normalizedWordStrength = this.normalizedWordStrength(word);
    const style = styles[normalizedWordStrength];

    return (
      <div key={WordPresenter.title(word)} style={style} role="presentation" className={innerStyles.tag}>
        {WordPresenter.title(word)}
      </div>
    );
  };

  render() {
    const { onClick } = this.props;

    return (
      <div className={innerStyles.container}>
        <TagCloud
          minSize={15}
          maxSize={60}
          tags={this.words()}
          className={innerStyles.tagCloud}
          renderer={this.renderWord}
          shuffle={false}
          onClick={(word) => onClick(word)}
        />
      </div>
    );
  }
}

export default Cloud;
