import React from 'react';

import ReactSwitch from 'react-switch';

import styles from './Switch.module.css';

export const SWITCH_ROLE = 'Switch';

const Switch = (props) => (
  <ReactSwitch
    activeBoxShadow=""
    boxShadow=""
    checkedHandleIcon={<div className={styles.checkedHandle} />}
    checkedIcon={false}
    className={styles.switch}
    height={24}
    offColor="#D6D6D6"
    onColor="#00A0CE"
    uncheckedHandleIcon={<div className={styles.uncheckedHandle} />}
    uncheckedIcon={false}
    {...props}
  />
);

Switch.fieldRole = SWITCH_ROLE;

export default Switch;
