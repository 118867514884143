import React, { Component } from 'react';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';
import { Box, Card, Paragraph } from 'grommet';

import { reactSelectDebounce } from 'utils/HelperMethods';

import Icon from 'components/Icon';
import AsyncSelect from 'components/AsyncSelect';
import Headline from 'components/grommet/Headline';

import styles from './SampleProfile.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';
import SampleProfilePresenter from 'presenters/SampleProfilePresenter';

class SampleProfile extends Component {
  static propTypes = {
    onProfileSearch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    sampleProfile: SampleProfilePresenter.shape(),
  };

  loadProfiles = (value) => {
    const { onProfileSearch } = this.props;
    const filters = { searchFieldCont: value };

    return onProfileSearch(filters);
  };

  handleProfileSearch = reactSelectDebounce(this.loadProfiles);

  handleProfileChange = (value) => {
    const { onSubmit, sampleProfile } = this.props;
    onSubmit(sampleProfile, value);
  };

  renderCurrentWork = (currentWork) => (
    <Paragraph key={WorkPresenter.id(currentWork)} margin="none" className={styles.organization}>
      {WorkPresenter.fullJobTitle(currentWork)}
      <br />
      {WorkPresenter.organizationName(currentWork)}
      <br />
    </Paragraph>
  );

  renderPersonalInfo() {
    const { sampleProfile } = this.props;
    const profile = SampleProfilePresenter.publishedProfile(sampleProfile);
    const photoUrl = profile && ProfilePresenter.photoUrl(profile);
    const currentWorks = ProfilePresenter.currentWorks(profile) || [];

    return (
      <div className={styles.profile}>
        <Box margin={{ right: 'large' }} className={styles.avatar}>
          <Box>
            {isNil(photoUrl) ? (
              <Icon name="avatarPlaceholder" />
            ) : (
              <img src={photoUrl} alt={ProfilePresenter.fullName(profile)} />
            )}
          </Box>
        </Box>
        <Box className={styles.info}>
          <Headline strong size="small" margin="none" className={styles.fullName}>
            {profile ? ProfilePresenter.fullNameWithSalutation(profile) : 'No Profile Selected'}
          </Headline>
          {currentWorks.map(this.renderCurrentWork)}
          {profile ? (
            <Paragraph margin="none" className={styles.date}>
              Last updated: {ProfilePresenter.formattedProfileUpdatedAt(profile)}
            </Paragraph>
          ) : null}
        </Box>
      </div>
    );
  }

  render() {
    const { saving, sampleProfile } = this.props;
    const profile = SampleProfilePresenter.publishedProfile(sampleProfile);

    return (
      <Card className={styles.sampleProfile}>
        {this.renderPersonalInfo()}
        <Box>
          <AsyncSelect
            placeholder="Select a Profile"
            loadOptions={this.handleProfileSearch}
            isClearable
            value={profile}
            onChange={this.handleProfileChange}
            getOptionValue={ProfilePresenter.id}
            getOptionLabel={ProfilePresenter.fullName}
            disabled={saving}
          />
        </Box>
      </Card>
    );
  }
}

export default SampleProfile;
