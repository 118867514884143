import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LanguagesListPage from './LanguagesListPage';
import * as actions from './LanguagesListPageActions';

const mapStateToProps = (state) => ({
  loading: state.LanguagesListPageReducer.loading,
  filters: state.LanguagesListPageReducer.filters,
  nextPage: state.LanguagesListPageReducer.nextPage,
  totalCount: state.LanguagesListPageReducer.totalCount,
  languages: state.LanguagesListPageReducer.languages,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesListPage);
