import { remove } from 'ramda';

import { extendFormProps } from 'components/v2/Form/utils';

function onItemAdd(itemAttributes) {
  return this.push({ ...itemAttributes, isNewRecord: true });
}

function onItemRemove(itemIndex) {
  const item = this.collectionItems[itemIndex] || {};

  if (!this.form.api || item.isNewRecord) {
    this.remove(itemIndex);
    this.form.changeFieldError(`${this.name}`, remove(itemIndex, 1, this.collectionErrors));

    return;
  }

  this.form.onFieldChange(`${this.name}[${itemIndex}]._destroy`, true);
}

function isItemMarkedForDestruction({ _destroy }) {
  return _destroy;
}

function itemKey(itemIndex) {
  return `${itemIndex}-${this.collectionItems.length}`;
}

export const extendNestedCollectionProps = (api, nestedCollectionProps) => {
  const { name } = nestedCollectionProps;
  const form = extendFormProps(api, nestedCollectionProps.form);
  const nested = {
    ...nestedCollectionProps,
    form,
    collectionErrors: form.nestedCollectionErrors(name),
    collectionItems: form.nestedCollection(name),
  };

  nested.onItemAdd = onItemAdd.bind(nested);
  nested.onItemRemove = onItemRemove.bind(nested);
  nested.isItemMarkedForDestruction = isItemMarkedForDestruction.bind(nested);
  nested.itemKey = itemKey.bind(nested);

  return nested;
};
