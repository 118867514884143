import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import InstitutionsRepository from 'repositories/admin/Profile/InstitutionsRepository';
import OrganizationsRepository from 'repositories/admin/OrganizationsRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';

import { actions } from 'slices/admin/profileSlice';

function searchOrganizations(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return OrganizationsRepository.index(params).then(({ items }) =>
      items.map(({ draft, published }) => published || draft),
    );
  };
}

function createInstitution(profileId, params) {
  return (dispatch) => {
    dispatch(actions.createInstitutionStart());

    return InstitutionsRepository.create(profileId, params)
      .then(({ institution, meta }) => {
        dispatch(
          actions.createInstitutionSuccess({
            institution,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta.institutionsSortOrder,
          }),
        );
        addNotification('Education History was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return institution;
      })
      .catch((error) => {
        dispatch(actions.createInstitutionError());

        throw error;
      });
  };
}

function updateInstitution(profileId, institutionId, params) {
  return (dispatch) => {
    dispatch(actions.updateInstitutionStart());

    return InstitutionsRepository.update(profileId, institutionId, params)
      .then(({ institution, meta }) => {
        dispatch(
          actions.updateInstitutionSuccess({
            institution,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta.institutionsSortOrder,
          }),
        );

        addNotification('Education History was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return institution;
      })
      .catch((error) => {
        dispatch(actions.updateInstitutionError());

        throw error;
      });
  };
}

function destroyInstitution(profileId, institutionId) {
  return (dispatch) => {
    dispatch(actions.destroyInstitutionStart());
    return InstitutionsRepository.destroy(profileId, institutionId).then(({ meta }) => {
      dispatch(actions.destroyInstitutionSuccess({ institutionId, profileUpdatedAt: meta.profileUpdatedAt }));
      addNotification('Education History was successfully updated', 'normal')(dispatch);
      dispatch(initializeRequestForm(meta.request));
    });
  };
}

export const useAdminProfileDraftInstitutionActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      searchOrganizations,
      createInstitution,
      updateInstitution,
      destroyInstitution,
    },
    dispatch,
  );
};
