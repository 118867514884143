import keyMirror from 'keymirror';

const InterestListPageConstants = keyMirror({
  INITIALIZE_INTEREST_LIST_SUCCESS: null,

  LOAD_INTERESTS_START: null,
  LOAD_INTERESTS_SUCCESS: null,

  FILTER_INTERESTS_START: null,
  FILTER_INTERESTS_SUCCESS: null,

  BULK_DESTROY_INTERESTS_START: null,
  BULK_DESTROY_INTERESTS_SUCCESS: null,

  SELECT_INTEREST_SUCCESS: null,
  UNSELECT_INTEREST_SUCCESS: null,
  SELECT_ALL_INTERESTS_SUCCESS: null,
  UNSELECT_ALL_INTERESTS_SUCCESS: null,
});

export default InterestListPageConstants;
