import PropTypes from 'prop-types';
import { isNil, not, find, join, map, filter, pipe, propEq } from 'ramda';
import Presenter from 'utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import TagPresenter from 'presenters/TagPresenter';
import { isBlank } from 'utils/HelperMethods';

export default new Presenter(
  {
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    jobFunctionIds: PropTypes.arrayOf(PropTypes.number),
    allJobFunctions: PropTypes.bool,
    sortUuid: PropTypes.string,
    tags: PropTypes.arrayOf(TagPresenter.shape()),
    pinned: PropTypes.bool,
    topicUpdatedAt: PropTypes.string,
    isRecentlyUpdated: PropTypes.bool,
  },
  {
    isProfileTopic(topic) {
      const type = this.type(topic);

      return type === 'ProfileTopic';
    },

    isOrganizationTopic(topic) {
      const type = this.type(topic);

      return type === 'OrganizationTopic';
    },

    jobFunctions(topic, jobFunctions) {
      const isNotNil = pipe(isNil, not);
      const findJobFunction = (jobFunctionId) => find(propEq(jobFunctionId, 'id'), jobFunctions);

      return pipe(
        map(findJobFunction),
        filter(isNotNil),
        map(JobFunctionPresenter.name),
        join(', '),
      )(this.jobFunctionIds(topic));
    },

    formattedTopicUpdatedAt(p) {
      return formattedDate(this.topicUpdatedAt(p));
    },

    isHidden(topic, hiddenUuids = []) {
      if (isBlank(hiddenUuids)) return false;

      return hiddenUuids.includes(this.sortUuid(topic));
    },
  },
);
