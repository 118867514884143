import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { MailOption as MailIcon } from 'grommet-icons';

import styles from './SelectControl.module.css';

const SelectControl = ({ children, ...props }) => (
  <components.Control {...props}>
    <MailIcon className={styles.mailIcon} size="14px" />
    {children}
  </components.Control>
);

SelectControl.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SelectControl;
