import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Tooltip from 'rc-tooltip';

import styles from './QuestionMarkButton.module.css';

const QuestionMarkButton = (props) => {
  const { text, placement, onClick, overlayStyle, ...rest } = props;

  return (
    <Tooltip placement={placement} overlay={text} overlayStyle={overlayStyle} {...rest}>
      <Button className={styles.button} onClick={onClick}>
        ?
      </Button>
    </Tooltip>
  );
};

QuestionMarkButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  overlayStyle: PropTypes.shape(),
};

QuestionMarkButton.defaultProps = {
  placement: 'right',
  overlayStyle: {},
};

export default QuestionMarkButton;
