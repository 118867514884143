import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHeader, TableBody, TableRow, TableCell } from 'grommet';

import Spinner from 'components/Spinner';

import PowerUserPresenter from 'presenters/EditorialDashboard/CustomerUsageDashboard/PowerUserPresenter';
import PopularOrganizationPresenter from 'presenters/EditorialDashboard/CustomerUsageDashboard/PopularOrganizationPresenter';

import styles from './TopViewed.module.css';

export default class TopViewed extends React.Component {
  static propTypes = {
    powerUsersLoading: PropTypes.bool.isRequired,
    powerUsers: PropTypes.arrayOf(PopularOrganizationPresenter.shape()).isRequired,
    popularOrganizationsLoading: PropTypes.bool.isRequired,
    popularOrganizations: PropTypes.arrayOf(PopularOrganizationPresenter.shape()).isRequired,
  };

  renderPowerUserRow(user) {
    return (
      <TableRow key={PowerUserPresenter.fullName(user)}>
        <TableCell> {PowerUserPresenter.fullName(user)} </TableCell>
        <TableCell> {PowerUserPresenter.count(user)} </TableCell>
      </TableRow>
    );
  }

  renderPopularOrganization(organization) {
    const logoUrl = PopularOrganizationPresenter.logoUrl(organization);
    const name = PopularOrganizationPresenter.name(organization);

    return <img key={`${logoUrl}-${name}`} alt={name} src={logoUrl} className={styles.item} />;
  }

  render() {
    const { popularOrganizationsLoading, popularOrganizations, powerUsersLoading, powerUsers } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>Top Viewed</div>
        <section>
          <div className={styles.subheader}>Power Users</div>
          {powerUsersLoading ? (
            <Spinner />
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell scope="col">Full Name</TableCell>
                  <TableCell scope="col">Views & Downloads</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>{powerUsers.map(this.renderPowerUserRow)}</TableBody>
            </Table>
          )}
        </section>
        <section>
          <div className={styles.subheader}>Companies</div>
          {popularOrganizationsLoading ? (
            <Spinner />
          ) : (
            <div className={styles.organizations}>{popularOrganizations.map(this.renderPopularOrganization)}</div>
          )}
        </section>
      </div>
    );
  }
}
