import React from 'react';
import PropTypes from 'prop-types';

import LegalSettingPresenter from 'presenters/LegalSettingPresenter';

import Button from 'components/v2/Button';

import CommonSettingsFields from './components/CommonSettingsFields';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const { errors, onFieldChange, onSubmit, initialValues, isSubmitting, values } = props;

  return (
    <div>
      <div className={styles.row}>
        <CommonSettingsFields
          errors={errors}
          initialValues={initialValues}
          onFieldChange={onFieldChange}
          values={values}
        />
      </div>
      <Button disabled={isSubmitting} onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: LegalSettingPresenter.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: LegalSettingPresenter.shape().isRequired,
};

export default EditForm;
