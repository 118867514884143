import UserCardsConstants from './UserCardsConstants';

const initialState = {
  saving: false,
  loading: false,
  profiles: [],
};

function UserCardsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UserCardsConstants.LOAD_DATABASE_ANALYTIC_PRIORITY_UPDATES_START: {
      return { ...state, loading: true };
    }
    case UserCardsConstants.LOAD_DATABASE_ANALYTIC_PRIORITY_UPDATES_SUCCESS: {
      const { items } = action;
      return { ...state, loading: false, profiles: items };
    }
    case UserCardsConstants.CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_START: {
      return { ...state, saving: true };
    }
    case UserCardsConstants.CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_SUCCESS: {
      return { ...state, saving: false };
    }
    case UserCardsConstants.CREATE_DATABASE_ANALYTIC_PRIORITY_UPDATE_REQUEST_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default UserCardsReducer;
