import { equals, prop } from 'ramda';
import ProfileListConstants from './ProfileListConstants';

const initialState = {
  loading: false,
  profiles: [],
  nextPage: 1,
  filters: {
    sortFields: [],
  },
  totalCount: null,
};

function ProfileListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileListConstants.PROFILES_LOAD: {
      return { ...state, loading: true };
    }
    case ProfileListConstants.PROFILES_LOAD_SUCCESS: {
      const { nextPage, totalCount } = action;
      const profiles = [...state.profiles, ...action.profiles.map(prop('draft'))];

      return { ...state, profiles, nextPage, totalCount, loading: false };
    }
    case ProfileListConstants.PROFILES_FILTER: {
      return {
        ...initialState,
        loading: true,
        filters: action.filters,
      };
    }
    case ProfileListConstants.PROFILES_FILTER_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { nextPage, totalCount } = action;
      const profiles = action.profiles.map(prop('draft'));

      return { ...state, profiles, nextPage, totalCount, loading: false };
    }
    case ProfileListConstants.PROFILES_BULK_DESTROY_SUCCESS: {
      return { ...state, profiles: [], nextPage: 1, totalCount: null };
    }
    default:
      return state;
  }
}

export default ProfileListReducer;
