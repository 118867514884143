import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';
import Routes from 'routes';
import cn from 'clsx';
import { compose } from 'ramda';
import { Button, CheckBox } from 'grommet';

import { isPresent } from 'utils/HelperMethods';
import { goToTop } from 'utils/DOMHelpers';

import useProfileGrantAccess from 'hooks/client/useProfileGrantAccess';

import ProfilePresenter from 'presenters/ProfilePresenter';
import CurrentUserPresenter, { PROFILE_GUIDE_NAME } from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';

import Guide from 'components/Guide';
import ProfileMeta from 'components/ProfileMeta';
import Anchor from 'components/Anchor';
import AnchorLink from 'components/grommet/AnchorLink';
import RelationshipMappingAdvertisement from 'containers/UserPanel/containers/ProfileRelationshipMappingAdvertisement';

import Sidebar, { isSidebarInformationPresent } from './components/Sidebar';
import UpdateRequestNotice from './components/UpdateRequestNotice';
import Header from './components/Header';
import StickyHeader from './components/StickyHeader';
import Summary from './components/Summary';
import ProfileAlerts from './components/ProfileAlerts';
import GrantAccessNotice from './components/GrantAccessNotice';

import styles from './Profile.module.css';

const Profile = (props) => {
  const {
    backgroundExport,
    currentUser,
    onProfileUpdateRequest,
    profile,
    isRelationshipMappingAdvertisementVisible,
    isUpdateRequestSaving,
    highlightsParameters,
    isSubscriptionsSaving,
    updateBatchSubscriptions,
    subscriptions,
    onLoadProfile,
    hideGuideForever,
    revealGuideForever,
    history,
    indexable,
    canonicalPath,
    boardexProfileUrl,
  } = props;

  const { grantAccessToProfile } = useProfileGrantAccess();
  const [shouldHighlightChanges, setHighlightChangesFlag] = useState(false);
  const [isStickyVisible, setStickyVisibleFlag] = useState(false);

  const [showGuide, setShowGuide] = useState(ProfilePresenter.shouldShowGuide(profile));
  const [isGuideHiddenForever, setIsGuideHiddenForever] = useState(!ProfilePresenter.shouldShowGuide(profile));

  const onCommonSectionChange = (visible) => setStickyVisibleFlag(!visible);
  const handleHiglightChanges = (value) => setHighlightChangesFlag(value);
  const handleCreateLimitedAccessProfile = () => grantAccessToProfile(ProfilePresenter.id(profile)).then(onLoadProfile);

  const stickyStyles = cn([styles.sticky], { [styles.visible]: isStickyVisible });
  const rootStyles = cn({
    [styles.noSidebar]: !isSidebarInformationPresent(profile),
    [styles.hideChangesHighlights]: !shouldHighlightChanges,
  });

  const isVisibleUpdateRequestNotice =
    ProfilePresenter.isUpdateRequestAvailable(profile) && !ProfilePresenter.isMasked(profile);

  const handleOpenGuide = () => {
    setShowGuide(true);
  };

  const handleCloseGuide = () => {
    if (isGuideHiddenForever) {
      hideGuideForever(PROFILE_GUIDE_NAME);
    } else {
      revealGuideForever(PROFILE_GUIDE_NAME);
    }

    setShowGuide(false);
  };

  const handleChangeGuideShowAgainFlag = ({ target: { checked } }) => setIsGuideHiddenForever(checked);

  const handleBack = () => {
    if (history.length <= 2) {
      history.push(Routes.rootPath());
    } else {
      history.goBack();
    }
  };

  if (profile.status === 'currently_unavailable') {
    return (
      <div className={styles.content}>
        <div className={styles.main}>
          <h1 className={styles.title}>Profile currently unavailable</h1>
          <h3 className={styles.text}>Please contact your account manager</h3>
          <Button label="Back" primary className={styles.button} onClick={handleBack} />
        </div>
      </div>
    );
  }

  return (
    <div className={rootStyles}>
      <ProfileMeta profile={profile} indexable={indexable} canonicalPath={canonicalPath} />
      {isVisibleUpdateRequestNotice && (
        <UpdateRequestNotice isSaving={isUpdateRequestSaving} onRequestProfileUpdate={onProfileUpdateRequest} />
      )}
      {ProfilePresenter.isMasked(profile) && (
        <div className={styles.grantAccess}>
          <GrantAccessNotice onCreate={handleCreateLimitedAccessProfile} />
        </div>
      )}
      <VisibilitySensor partialVisibility onChange={onCommonSectionChange}>
        <Header
          onExport={backgroundExport}
          currentUser={currentUser}
          highlightsParameters={highlightsParameters}
          profile={profile}
          shouldHighlightChanges={shouldHighlightChanges}
          onHiglightChanges={handleHiglightChanges}
          onOpenGuide={handleOpenGuide}
          boardexProfileUrl={boardexProfileUrl}
        />
      </VisibilitySensor>
      <div className={stickyStyles}>
        <StickyHeader onScrollTop={goToTop} profile={profile} />
      </div>
      {!isSidebarInformationPresent(profile) && isPresent(currentUser) && (
        <ProfileAlerts
          profile={profile}
          isSubscriptionsSaving={isSubscriptionsSaving}
          onSubscriptionsUpdate={updateBatchSubscriptions}
          subscriptions={subscriptions}
        />
      )}
      <div className={styles.personInfo}>
        {isSidebarInformationPresent(profile) && (
          <div className={styles.side}>
            {isPresent(currentUser) && (
              <ProfileAlerts
                profile={profile}
                isSubscriptionsSaving={isSubscriptionsSaving}
                onSubscriptionsUpdate={updateBatchSubscriptions}
                subscriptions={subscriptions}
              />
            )}
            <Sidebar profile={profile} />
            {isRelationshipMappingAdvertisementVisible && (
              <RelationshipMappingAdvertisement currentUser={currentUser} profile={profile} />
            )}
          </div>
        )}
        <div className={styles.summary}>
          <Summary profile={profile} />
        </div>
      </div>
      <p className={styles.importantText}>All quarterly earnings call content is licensed from Refinitiv.</p>
      {showGuide && (
        <Guide onClose={handleCloseGuide} selector={`.${styles.grantAccess}`} position="bottom-right">
          <div className={styles.guide}>
            This profile is not included in your limited access subscription. You may add the profile to your team&#39;s
            database access in exchange for one profile credit, or{' '}
            <Anchor href={`mailto:${UserPresenter.managerEmail(currentUser)}`} className={styles.guideAnchor}>
              contact your account manager
            </Anchor>{' '}
            to expand your access.{' '}
            <AnchorLink to={Routes.userAccountPath()} className={styles.guideAnchor}>
              View your current access criteria here.
            </AnchorLink>
            <br />
            <br />
            <CheckBox
              className={styles.guideCheckbox}
              checked={isGuideHiddenForever}
              onChange={handleChangeGuideShowAgainFlag}
              label="Don't show this again."
            />
          </div>
        </Guide>
      )}
    </div>
  );
};

Profile.propTypes = {
  backgroundExport: PropTypes.func.isRequired,
  currentUser: CurrentUserPresenter.shape(),
  profile: ProfilePresenter.shape(),
  onProfileUpdateRequest: PropTypes.func.isRequired,
  isUpdateRequestSaving: PropTypes.bool,
  isRelationshipMappingAdvertisementVisible: PropTypes.bool,
  highlightsParameters: PropTypes.shape(),
  isSubscriptionsSaving: PropTypes.bool.isRequired,
  updateBatchSubscriptions: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hideGuideForever: PropTypes.func.isRequired,
  revealGuideForever: PropTypes.func.isRequired,
  onLoadProfile: PropTypes.func,
  indexable: PropTypes.bool,
  canonicalPath: PropTypes.string,
  boardexProfileUrl: PropTypes.string,
};

Profile.defaultProps = {
  highlightsParameters: {},
  isRelationshipMappingAdvertisementVisible: false,
  indexable: false,
  canonicalPath: 'skinnyProfilePath',
  boardexProfileUrl: '',
};

export default compose(withRouter)(Profile);
