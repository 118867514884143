import TopRelationshipsToMyExecutivesConstants from './TopRelationshipsToMyExecutivesConstants';

const initialState = {
  loading: false,
  relationshipsMapping: null,
  userColleaguesExists: true,
  userBoardMembersExists: false,
};

function TopRelationshipsToMyExecutivesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TopRelationshipsToMyExecutivesConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_TO_EXECUTIVES_START: {
      return { ...state, loading: true };
    }
    case TopRelationshipsToMyExecutivesConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_TO_EXECUTIVES_SUCCESS: {
      const { relationshipsMapping, userColleaguesExists, userBoardMembersExists } = action;

      return { ...state, relationshipsMapping, userColleaguesExists, userBoardMembersExists, loading: false };
    }
    default:
      return state;
  }
}

export default TopRelationshipsToMyExecutivesReducer;
