import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, defaultTo } from 'ramda';
import { isBlank, isPresent } from 'utils/HelperMethods';

import { TextInput, Box, CheckBox } from 'grommet';
import Select from 'components/Select';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import ProfilePresenter, { GENDERS } from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';
import PersonalInfoForm from 'forms/profile/PersonalInfoForm';

const GENDER_OPTIONS = GENDERS.map(({ key, title }) => ({ value: key, label: title }));

class EditForm extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { profile } = props;
    this.state = { fields: PersonalInfoForm.defaultAttributes(profile), errors: null };
  }

  updateFields = (fields) => this.setState({ fields: { ...this.state.fields, ...fields } });

  updateField = (fieldName, value) => this.updateFields({ [fieldName]: value });

  handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleSelectChange = (fieldName) => (value) => this.updateField(fieldName, value);

  handleCheckBoxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      this.updateField(fieldName, checked);

  handleCurrentWorkIdsChange = (value) => {
    const { primaryCurrentWorkId } = this.state.fields;
    const fields = { currentWorkIds: value };

    if (!includes(primaryCurrentWorkId, value)) {
      fields.primaryCurrentWorkId = null;
    }

    this.updateFields(fields);
  };

  handleSubmit = () => {
    const { fields } = this.state;
    const primaryCurrentWorkId = isBlank(fields.primaryCurrentWorkId)
      ? this.suggestPrimaryCurrentWork(fields)
      : fields.primaryCurrentWorkId;
    const params = PersonalInfoForm.attributesToSubmit({ ...this.state.fields, primaryCurrentWorkId });

    this.props.onSubmit(params).catch(({ errors }) => this.setState({ errors }));
  };

  suggestPrimaryCurrentWork(fields) {
    const { currentWorkIds } = fields;

    return currentWorkIds.length === 1 ? currentWorkIds[0] : null;
  }

  textValue(fieldName) {
    return defaultTo('', this.state.fields[fieldName]);
  }

  currentWorks = () => {
    const { profile } = this.props;
    const { fields } = this.state;
    const works = ProfilePresenter.works(profile);

    return works.filter(({ id }) => includes(id, fields.currentWorkIds));
  };

  render() {
    const { profile, saving } = this.props;
    const { fields, errors } = this.state;

    return (
      <div>
        <Box direction="column" margin={{ bottom: 'medium' }}>
          <VerticalFormFieldWithBottomErrors label="Salutation" htmlFor="salutation">
            <TextInput
              value={this.textValue('salutation')}
              onChange={this.handleFieldChange('salutation')}
              id="salutation"
            />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors label="Common Name:" htmlFor="commonName">
            <TextInput
              value={this.textValue('commonName')}
              onChange={this.handleFieldChange('commonName')}
              id="commonName"
            />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors label="First Name:" htmlFor="firstName">
            <TextInput
              value={this.textValue('firstName')}
              onChange={this.handleFieldChange('firstName')}
              id="firstName"
            />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors label="Middle Name: " htmlFor="middleName">
            <TextInput
              value={this.textValue('middleName')}
              onChange={this.handleFieldChange('middleName')}
              id="middleName"
            />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors label="Last Name: " htmlFor="lastName">
            <TextInput value={this.textValue('lastName')} onChange={this.handleFieldChange('lastName')} id="lastName" />
          </VerticalFormFieldWithBottomErrors>
          <VerticalFormFieldWithBottomErrors checkbox>
            <CheckBox
              label="Deceased"
              toggle
              checked={fields.deceased}
              onChange={this.handleCheckBoxChange('deceased')}
            />
          </VerticalFormFieldWithBottomErrors>
          <Select
            placeholder="Select Gender"
            selectedOptionValue={fields.gender}
            options={GENDER_OPTIONS}
            onValueChange={this.handleSelectChange('gender')}
          />
          <Select
            isMulti
            isClearable
            placeholder="Current Works"
            selectedOptionValue={fields.currentWorkIds}
            options={ProfilePresenter.works(profile)}
            getOptionLabel={WorkPresenter.title}
            getOptionValue={WorkPresenter.id}
            onValueChange={this.handleCurrentWorkIdsChange}
          />
          {isPresent(fields.currentWorkIds) ? (
            <Select
              errors={errors && errors.primaryCurrentWork}
              isClearable
              placeholder="Primary Current Work"
              selectedOptionValue={fields.primaryCurrentWorkId}
              options={this.currentWorks()}
              getOptionLabel={WorkPresenter.title}
              getOptionValue={WorkPresenter.id}
              onValueChange={this.handleSelectChange('primaryCurrentWorkId')}
              noBottom
            />
          ) : null}
        </Box>
        <Box direction="row" width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default EditForm;
