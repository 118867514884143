export function paginate(collection, currentPage, perPage) {
  const offset = (currentPage - 1) * perPage;

  return collection.slice(offset, offset + perPage);
}

export function getCollectionCurrentPage(collection, currentPage) {
  if (collection.length > 0) {
    return currentPage;
  }

  return currentPage >= 2 ? currentPage - 1 : currentPage;
}
