import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';
import { formatNumber } from 'utils/NumbersHelper';

export default new Presenter(
  {
    id: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    creatorFullName: PropTypes.string,
    createdAt: PropTypes.string,
    deletedAt: PropTypes.string,
    profilesCount: PropTypes.number,
    sharesCount: PropTypes.number,
  },
  {
    formattedCreatedAt(project) {
      return formattedDate(this.createdAt(project));
    },

    formattedDeletedAt(project) {
      return formattedDate(this.deletedAt(project));
    },

    formattedProfilesCount(project) {
      return formatNumber(this.profilesCount(project));
    },
  },
);
