import keyMirror from 'keymirror';

const AvailableProfilesContainerConstants = keyMirror({
  FILTER_PROJECT_AVAILABLE_PROFILES_START: null,
  FILTER_PROJECT_AVAILABLE_PROFILES_SUCCESS: null,

  LOAD_PROJECT_AVAILABLE_PROFILES_START: null,
  LOAD_PROJECT_AVAILABLE_PROFILES_SUCCESS: null,

  DESTROY_PROJECT_AVAILABLE_PROFILE_START: null,
  DESTROY_PROJECT_AVAILABLE_PROFILE_SUCCESS: null,
  DESTROY_PROJECT_AVAILABLE_PROFILE_ERROR: null,
});

export default AvailableProfilesContainerConstants;
