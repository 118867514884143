import React from 'react';
import PropTypes from 'prop-types';

import { TableHeadCell } from 'components/v2/Table';

import styles from './Header.module.css';

const Header = (props) => {
  const { sortings, onSortingsChange } = props;

  const handleSortingChange = (sorting) => onSortingsChange([sorting]);

  return (
    <tr className={styles.header}>
      <TableHeadCell
        field="subject_full_name"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100%"
      >
        User
      </TableHeadCell>

      <TableHeadCell
        field="requestor_full_name"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100%"
      >
        Requested Profile
      </TableHeadCell>

      <TableHeadCell
        field="subject_job_title"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100%"
      >
        Title
      </TableHeadCell>

      <TableHeadCell
        field="subject_organization_name"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100%"
      >
        Company
      </TableHeadCell>

      <TableHeadCell field="state" sortable sortings={sortings} onSortingChange={handleSortingChange} width="100%">
        Status
      </TableHeadCell>

      <TableHeadCell field="created_at" sortable sortings={sortings} onSortingChange={handleSortingChange} width="100%">
        Request Date
      </TableHeadCell>

      <TableHeadCell
        field="delivered_at"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100%"
      >
        Delivered Date
      </TableHeadCell>

      <TableHeadCell
        field="request_type"
        sortable
        sortings={sortings}
        onSortingChange={handleSortingChange}
        width="100%"
      >
        Request Type
      </TableHeadCell>
    </tr>
  );
};

Header.propTypes = {
  sortings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSortingsChange: PropTypes.func.isRequired,
};

export default Header;
