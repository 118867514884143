import React from 'react';

import WysiwygSection from './components/WysiwygSection';
import TopicsList from './components/TopicsList';
import WorksList from './components/WorksList';
import BoardsList from './components/BoardsList';
import InstitutionsList from './components/InstitutionsList';
import ScrollableAnchor from 'components/ScrollableAnchor';

import styles from './Summary.module.css';

import sections from './sections';

import ProfilePresenter from 'presenters/ProfilePresenter';

const STICKY_HEADER_HEIGHT = 60;
const ANCHOR_OFFEST = STICKY_HEADER_HEIGHT + 20;

const Summary = (props) => {
  const { profile } = props;

  return (
    <div className={styles.wrapper}>
      {ProfilePresenter.briefBiography(profile) && (
        <ScrollableAnchor id={sections.BriefBiography.id} offset={ANCHOR_OFFEST}>
          <WysiwygSection header={sections.BriefBiography.name} html={ProfilePresenter.briefBiography(profile)} />
        </ScrollableAnchor>
      )}

      {ProfilePresenter.publicPersonInformation(profile) && (
        <ScrollableAnchor id={sections.PublicPersonInformation.id} offset={ANCHOR_OFFEST}>
          <WysiwygSection
            header={sections.PublicPersonInformation.name}
            html={ProfilePresenter.publicPersonInformation(profile)}
          />
        </ScrollableAnchor>
      )}

      {ProfilePresenter.currentFocusTopics(profile).length > 0 && (
        <ScrollableAnchor id={sections.CurrentFocusTopics.id} offset={ANCHOR_OFFEST}>
          <TopicsList header={sections.CurrentFocusTopics.name} topics={ProfilePresenter.currentFocusTopics(profile)} />
        </ScrollableAnchor>
      )}

      {ProfilePresenter.keyChallengesTopics(profile).length > 0 && (
        <ScrollableAnchor id={sections.KeyChallengesTopics.id} offset={ANCHOR_OFFEST}>
          <TopicsList
            header={sections.KeyChallengesTopics.name}
            topics={ProfilePresenter.keyChallengesTopics(profile)}
          />
        </ScrollableAnchor>
      )}

      {ProfilePresenter.works(profile).length > 0 && (
        <ScrollableAnchor id={sections.Works.id} offset={ANCHOR_OFFEST}>
          <WorksList works={ProfilePresenter.works(profile)} />
        </ScrollableAnchor>
      )}

      {ProfilePresenter.boards(profile).length > 0 && (
        <ScrollableAnchor id={sections.Boards.id} offset={ANCHOR_OFFEST}>
          <BoardsList boards={ProfilePresenter.boards(profile)} />
        </ScrollableAnchor>
      )}

      {ProfilePresenter.institutions(profile).length > 0 && (
        <ScrollableAnchor id={sections.Institutions.id} offset={ANCHOR_OFFEST}>
          <InstitutionsList institutions={ProfilePresenter.institutions(profile)} />
        </ScrollableAnchor>
      )}
    </div>
  );
};

Summary.propTypes = {
  profile: ProfilePresenter.shape().isRequired,
};

export default Summary;
