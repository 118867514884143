import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(profileId, query = {}) {
    const url = Routes.apiV1AdminProfileEditorWorksPath(profileId, query);
    return FetchHelpers.get(url);
  },

  update(profileId, editorWorkId, editorWork) {
    const url = Routes.apiV1AdminProfileEditorWorkPath(profileId, editorWorkId);
    return FetchHelpers.patch(url, { editorWork });
  },

  create(profileId, editorWork) {
    const url = Routes.apiV1AdminProfileEditorWorksPath(profileId);
    return FetchHelpers.post(url, { editorWork });
  },

  destroy(profileId, editorWorkId) {
    const url = Routes.apiV1AdminProfileEditorWorkPath(profileId, editorWorkId);
    return FetchHelpers.delete(url);
  },
};
