import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import ProjectsRepository from 'repositories/admin/ProjectsRepository';

const restSlice = createRestSlice({
  resource: 'project',
  repository: ProjectsRepository,
  slices: ['loadProjects', 'loadMoreProjects'],
});

const initialState = {
  ...restSlice.initialState,
};

const projectsSlice = createSlice({
  name: 'adminProjects',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminProjectsReducer = projectsSlice.reducer;

export default projectsSlice;

export const adminProjectsRestHooks = restSlice.hooks(projectsSlice, 'adminProjectsReducer');
