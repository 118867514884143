import { assocPath, hasPath } from 'ramda';

export const parseErrorKeyToPath = (errorKey) => {
  const parts = errorKey.replace(/(?!^)(\[\d+\])/g, '.$1').split('.');

  return parts.map((part) => (part.match(/\[\d+\]/) ? Number(part.substring(1, part.length - 1)) : part));
};

const setError = (errors, errorPath, error) => {
  const passedPath = [];

  return errorPath.reduce((acc, part, index) => {
    passedPath.push(part);

    if (passedPath.length === errorPath.length) {
      return assocPath(passedPath, error, acc);
    }

    if (!hasPath(passedPath, errors)) {
      const value = Number.isInteger(errorPath[index + 1]) ? [] : {};

      return assocPath(passedPath, value, acc);
    }

    return acc;
  }, errors);
};

export const parseErrors = (errors = {}) =>
  Object.keys(errors).reduce((acc, errorKey) => {
    const errorPath = parseErrorKeyToPath(errorKey);

    return setError(acc, errorPath, errors[errorKey]);
  }, {});
