import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cn from 'clsx';
import { Anchor } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';

import { buildTargetItemCriterias } from 'utils/Project/TargetItemHelper';
import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import ButtonWithConfirmation from 'containers/UserPanel/components/ButtonWithConfirmation';
import EditableTitle from './components/EditableTitle';
import Menu, { MenuItem } from 'containers/UserPanel/components/Menu';
import FiltersList from 'containers/UserPanel/containers/Search/components/FiltersList';
import ProjectCreateFormModal from 'containers/UserPanel/components/DatabaseSearchProjectCreateFormModal';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import UserPolicy from 'policies/UserPolicy';
import SavedSearchPolicy from 'policies/SavedSearchPolicy';

import TargetItemForm from 'forms/project/TargetItemForm';
import SourceForm from 'forms/project/SourceForm';
import NewProjectForm from 'forms/NewProjectForm';
import { INCLUSION_KIND } from 'presenters/Project/TargetItemPresenter';
import { TARGETING_SOURCE_TYPE, DATABASE_SEARCH_KIND } from 'presenters/Project/SourcePresenter';

import Routes from 'routes';

import styles from './SavedSearch.module.css';

import useDetectMobile from 'hooks/useDetectMobile';

const SavedSearchComponent = (props) => {
  const {
    className,
    isDeleting,
    isUpdating,
    isProjectSaving,
    savedSearch,
    onClick,
    onDeleteSavedSearch,
    onCreateSubscription,
    onDestroySubscription,
    onUpdateSavedSearch,
    onCreateProject,
    history,
    currentUser,
  } = props;

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isProjectCreateFormModalOpened, setIsProjectCreateFormModalOpened] = useState(false);
  const [isSubscriptionSaving, setIsSubscriptionSaving] = useState(false);
  const isMobile = useDetectMobile();

  const id = SavedSearchPresenter.id(savedSearch);
  const cardClassName = cn(styles.card, { [className]: isPresent(className) });

  const buildProjectAttributes = () => {
    const filters = SavedSearchPresenter.filters(savedSearch);
    const criterias = buildTargetItemCriterias(filters);
    const targetItem = TargetItemForm.defaultAttributes({ kind: INCLUSION_KIND, criterias });
    const sourceItem = SourceForm.defaultAttributes({
      kind: DATABASE_SEARCH_KIND,
      type: TARGETING_SOURCE_TYPE,
      targetItemsAttributes: [targetItem],
    });

    return NewProjectForm.defaultAttributes({
      name: SavedSearchPresenter.name(savedSearch),
      sourcesAttributes: [sourceItem],
    });
  };

  const handleOpenMenu = () => setIsMenuOpened(true);

  const handleCloseMenu = () => setIsMenuOpened(false);

  const handleStartEditTitle = () => {
    setIsEditingTitle(true);
    setIsMenuOpened(false);
  };

  const handleStopEditTitle = () => setIsEditingTitle(false);

  const handleClick = () => {
    if (isPresent(onClick)) {
      onClick(savedSearch);
    }
  };

  const handleDeleteSavedSearch = () => onDeleteSavedSearch(SavedSearchPresenter.id(savedSearch));

  const handleSubscribe = () => {
    setIsSubscriptionSaving(true);

    return onCreateSubscription(savedSearch).finally(() => setIsSubscriptionSaving(false));
  };

  const handleUnsubscribe = () => {
    setIsSubscriptionSaving(true);

    return onDestroySubscription(savedSearch).finally(() => setIsSubscriptionSaving(false));
  };

  const handleUpdateTitle = (name) => onUpdateSavedSearch(savedSearch.id, { ...savedSearch, name });

  const handleOpenProjectCreateFormModal = () => setIsProjectCreateFormModalOpened(true);

  const handleCloseProjectCreateFormModal = () => setIsProjectCreateFormModalOpened(false);

  const handleCreateProject = (params) => {
    const attributes = NewProjectForm.attributesToSubmit(params);

    return onCreateProject(attributes).then((project) => {
      const path = Routes.projectPath(project.id);

      history.push(path);
    });
  };

  const handlePreventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const canCreateProject = () => isPresent(onCreateProject) && UserPolicy.canSeeProjects(currentUser);

  const canDelete = () => isPresent(onDeleteSavedSearch) && SavedSearchPolicy.canDestroy(savedSearch);

  const canEdit = () => isPresent(onUpdateSavedSearch) && SavedSearchPolicy.canEdit(savedSearch);

  const canSubscribe = () => isPresent(onCreateSubscription) && SavedSearchPolicy.canSubscribe(savedSearch);

  const canUnsubscribe = () => isPresent(onDestroySubscription) && SavedSearchPolicy.canUnsubscribe(savedSearch);

  const isMenuAvailable = () => canCreateProject() || canDelete() || canEdit() || canSubscribe() || canUnsubscribe();

  return (
    <>
      <Anchor key={id} onClick={handleClick} className={cardClassName}>
        {SavedSearchPresenter.isSample(savedSearch) && <div className={styles.sample}>Sample</div>}
        <div className={styles.header}>
          <EditableTitle
            title={SavedSearchPresenter.name(savedSearch)}
            isSaving={isUpdating || isDeleting}
            isEditing={isEditingTitle}
            onCancel={handleStopEditTitle}
            onUpdate={handleUpdateTitle}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className={styles.actions} onClick={handlePreventDefault}>
            {isMenuAvailable() && (
              <Button className={styles.icon} onClick={handleOpenMenu}>
                <MenuIcon />
              </Button>
            )}
            <Menu className={styles.menu} isOpen={isMenuOpened} onClose={handleCloseMenu}>
              {canEdit() && (
                <MenuItem onClick={handleStartEditTitle} disabled={isDeleting || isEditingTitle}>
                  Edit Name
                </MenuItem>
              )}
              {canSubscribe() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  confirmationMessage="Are you sure you want to get email alerts for project?"
                  disabled={isDeleting || isSubscriptionSaving}
                  onClick={handleSubscribe}
                >
                  Add to Alerts
                </MenuItem>
              )}
              {canUnsubscribe() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  confirmationMessage="Are you sure you want to remove email alerts for Project?"
                  disabled={isDeleting || isSubscriptionSaving}
                  onClick={handleUnsubscribe}
                >
                  Remove from Alerts
                </MenuItem>
              )}
              {canCreateProject() && !isMobile && (
                <MenuItem disabled={isDeleting} onClick={handleOpenProjectCreateFormModal}>
                  Create Project
                </MenuItem>
              )}
              {canDelete() && (
                <MenuItem
                  component={ButtonWithConfirmation}
                  disabled={isDeleting || isSubscriptionSaving}
                  onClick={handleDeleteSavedSearch}
                  confirmationMessage="Are you sure want to delete this Saved Search?"
                >
                  Delete
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
        <div className={styles.selectedFilters}>
          <FiltersList filters={SavedSearchPresenter.filters(savedSearch)} />
        </div>
      </Anchor>
      {isProjectCreateFormModalOpened && (
        <ProjectCreateFormModal
          onClose={handleCloseProjectCreateFormModal}
          onSubmit={handleCreateProject}
          isSaving={isProjectSaving}
          defaultAttributes={buildProjectAttributes()}
        />
      )}
    </>
  );
};

const SavedSearch = withRouter(SavedSearchComponent);

SavedSearchComponent.propTypes = {
  className: PropTypes.string,
  currentUser: CurrentUserPresenter.shape(),
  savedSearch: SavedSearchPresenter.shape().isRequired,
  onCreateProject: PropTypes.func,
  onCreateSubscription: PropTypes.func,
  onDestroySubscription: PropTypes.func,
  onDeleteSavedSearch: PropTypes.func,
  onClick: PropTypes.func,
  onUpdateSavedSearch: PropTypes.func,
  isDeleting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isProjectSaving: PropTypes.bool,
  history: PropTypes.shape().isRequired,
};

SavedSearchComponent.defaultProps = {
  currentUser: null,
  onClick: null,
  onCreateProject: null,
  onDeleteSavedSearch: null,
  isProjectSaving: false,
  isUpdating: false,
  isDeleting: false,
};

export default SavedSearch;
