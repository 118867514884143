import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tabs, Tab } from 'grommet';
import { includes, keys, pickBy, prop } from 'ramda';

import Routes from 'routes';
import { isPresent, debounce, reactSelectDebounce } from 'utils/HelperMethods';
import { buildParams, parseLocationToParams } from 'utils/UrlHelper';

import Search from 'components/SearchInput';

import RequestsTable from './containers/RequestsTable';
import RequestsKanbanBoard from './containers/RequestsKanbanBoard';
import Select from 'components/Select';
import PageHeader from 'components/PageHeader';
import RequestStatisticBlock from './containers/RequestStatisticBlock';
import UsersSelect from './components/UsersSelect';

import UserPresenter, { ADMIN_ROLES } from 'presenters/UserPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RequestPolicy from 'policies/RequestPolicy';
import { humanRequestStateName } from 'presenters/RequestPresenter';

import { REQUEST_TYPES } from 'presenters/RequestPresenter';

import styles from './RequestsPage.module.css';

const MAX_SEARCH_LENGTH = 40;
const REQUEST_TYPE_OPTIONS = REQUEST_TYPES.map((r) => ({ label: r.title, value: r.key }));
const REQUEST_SUBJECT_TYPE_OPTIONS = ['Organization', 'Profile'].map((label) => ({ label, value: label }));
const ASSIGNEE_UNASSIGNED_OPTION = { id: 'withoutUser', label: 'Unassigned' };
const DUE_DATE_OPTIONS = [
  { label: 'Past Due', value: 'passDueTrue' },
  { label: 'Due Today', value: 'dueTodayTrue' },
];
const REQUEST_BOARD_MEMBERSHIP_OPTIONS = [
  { label: 'Fortune 500', value: 'fortune500_board' },
  { label: 'Relevant Board', value: 'relevant_board' },
  { label: 'Fortune 500 Or Relevant Board', value: 'fortune500_or_relevant_board' },
  { label: 'FTSE 100', value: 'ftse100_board' },
];

const DEFAULT_FILTERS = {
  subjectTypeIn: [],
  stateIn: [],
  requestTypeIn: [],
  passDueTrue: null,
  dueTodayTrue: null,
  searchFieldCont: '',
};

const RequestsPage = (props) => {
  const { currentUser, searchUser, history } = props;
  const { q } = parseLocationToParams(history.location);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS, ...q });

  let defaultAssignee = null;
  let defaultAssigneeId = null;
  let defaultRequestorId = null;

  if (isPresent(q)) {
    if (isPresent(q.assigneeIdNull)) {
      defaultAssignee = ASSIGNEE_UNASSIGNED_OPTION;
    } else {
      defaultAssigneeId = q.assigneeIdEq;
    }

    defaultRequestorId = q.requestorIdEq;
  }

  const getAssigneeSelectOptionLabel = (option) => (option.label ? option.label : UserPresenter.fullName(option));

  const handleRequestsFiltersChange = (newFilters) => {
    const search = buildParams({ q: newFilters });

    setFilters(newFilters);
    history.push({ pathname: Routes.adminProfileRequestsPath(), search });
  };

  const handleRequestsFiltersChangeDebounce = debounce(handleRequestsFiltersChange);

  const handleSelectFilterChange = (filterName) => (value) => {
    handleRequestsFiltersChangeDebounce({ ...filters, [filterName]: value });
  };

  const handleFilterChange =
    (filterName) =>
    ({ target: { value } }) => {
      handleRequestsFiltersChangeDebounce({ ...filters, [filterName]: value });
    };

  const handleAssigneeChange = (option) => {
    if (!option) {
      return handleRequestsFiltersChangeDebounce({ ...filters, assigneeIdEq: null, assigneeIdNull: null });
    }

    if (option.id === 'withoutUser') {
      return handleRequestsFiltersChangeDebounce({ ...filters, assigneeIdEq: null, assigneeIdNull: true });
    }

    return handleRequestsFiltersChangeDebounce({ ...filters, assigneeIdEq: option.id, assigneeIdNull: null });
  };

  const handleRequestorChange = (option) =>
    handleRequestsFiltersChangeDebounce({ ...filters, requestorIdEq: option && option.id });

  const handleUserByIdSearch = (value) => searchUser({ idEq: value }).then(([user]) => user);

  const handleAssigneeSearch = (value) => {
    const roleIn = ADMIN_ROLES;
    return searchUser({ fullNameCont: value, roleIn }).then((users) => {
      const constantOptions = [{ ...currentUser, label: 'Assigned to me' }, ASSIGNEE_UNASSIGNED_OPTION];

      return [{ options: constantOptions }, { label: 'Assignee', options: users }];
    });
  };

  const handleAssigneeSearchDebounced = reactSelectDebounce(handleAssigneeSearch);

  const handleRequestorSearch = (value) => searchUser({ fullNameCont: value });

  const handleRequestorSearchDebounced = reactSelectDebounce(handleRequestorSearch);

  const handleDueDateStateChange = (value) => {
    const values = { passDueTrue: null, dueTodayTrue: null };

    if (isPresent(value)) {
      values[value] = true;
    }

    handleRequestsFiltersChangeDebounce({ ...filters, ...values });
  };

  const handleBoardMembershipStateChange = (value) => {
    handleRequestsFiltersChangeDebounce({
      ...filters,
      profileBoardsQ: { ...filters.profileBoardsQ, collection: value },
    });
  };

  const dueDateFilterValue = () => {
    const values = DUE_DATE_OPTIONS.map(prop('value'));
    const dueDateValue = keys(pickBy((value, key) => isPresent(value) && includes(key, values), filters))[0];

    return dueDateValue || null;
  };

  const statusOptions = () =>
    RequestPolicy.allowedStates(currentUser).map(({ name }) => ({
      value: name,
      label: humanRequestStateName(name),
    }));

  return (
    <Box margin="none" width={{ min: '100%', max: '100%' }}>
      <Box>
        <PageHeader title="Profile Requests" />
      </Box>
      <RequestStatisticBlock />
      <Box direction="row" margin={{ bottom: 'medium' }} justify="between">
        <Box width="medium" margin={{ right: 'small' }}>
          <Search placeholder="Search" onChange={handleFilterChange('searchFieldCont')} maxLength={MAX_SEARCH_LENGTH} />
        </Box>
      </Box>
      <Box direction="row" margin={{ bottom: 'small' }} align="start">
        <div className={styles.selectContainer}>
          <Select
            placeholder="Subject"
            selectedOptionValue={filters.subjectTypeIn}
            onValueChange={handleSelectFilterChange('subjectTypeIn')}
            options={REQUEST_SUBJECT_TYPE_OPTIONS}
            isMulti
            isClearable
            noBottom
            classNamePrefix="react-select"
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            placeholder="Status"
            selectedOptionValue={filters.stateIn}
            onValueChange={handleSelectFilterChange('stateIn')}
            options={statusOptions()}
            isMulti
            isClearable
            noBottom
          />
        </div>
        {RequestPolicy.isColumnPermitted(currentUser, 'requestType') && (
          <div className={styles.selectContainer}>
            <Select
              placeholder="Type"
              selectedOptionValue={filters.requestTypeIn}
              onValueChange={handleSelectFilterChange('requestTypeIn')}
              options={REQUEST_TYPE_OPTIONS}
              isMulti
              isClearable
              noBottom
            />
          </div>
        )}
        <div className={styles.selectContainer}>
          <UsersSelect
            defaultValue={defaultAssignee}
            defaultOptionValue={defaultAssigneeId}
            loadDefaultValue={handleUserByIdSearch}
            placeholder="Assignee"
            loadOptions={handleAssigneeSearchDebounced}
            defaultOptions
            onChange={handleAssigneeChange}
            getOptionValue={UserPresenter.id}
            getOptionLabel={getAssigneeSelectOptionLabel}
            isClearable
            noBottom
          />
        </div>
        <div className={styles.selectContainer}>
          <UsersSelect
            defaultOptionValue={defaultRequestorId}
            loadDefaultValue={handleUserByIdSearch}
            placeholder="Requestor"
            loadOptions={handleRequestorSearchDebounced}
            defaultOptions
            onChange={handleRequestorChange}
            getOptionValue={UserPresenter.id}
            getOptionLabel={UserPresenter.fullName}
            isClearable
            noBottom
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            placeholder="Due Dates"
            selectedOptionValue={dueDateFilterValue()}
            onValueChange={handleDueDateStateChange}
            options={DUE_DATE_OPTIONS}
            isClearable
            noBottom
          />
        </div>
        <div className={styles.selectContainer}>
          <Select
            placeholder="Board Membership"
            selectedOptionValue={filters.profileBoardsQ?.collection}
            onValueChange={handleBoardMembershipStateChange}
            options={REQUEST_BOARD_MEMBERSHIP_OPTIONS}
            isClearable
            noBottom
          />
        </div>
      </Box>
      <Box>
        <Tabs justify="end" className={styles.tabs}>
          <Tab title="Kanban">
            <RequestsKanbanBoard currentUser={currentUser} filters={filters} />
          </Tab>
          <Tab title="Table">
            <RequestsTable currentUser={currentUser} filters={filters} onUserSearch={searchUser} />
          </Tab>
        </Tabs>
      </Box>
    </Box>
  );
};

RequestsPage.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  searchUser: PropTypes.func,
  history: PropTypes.shape(),
};

export default RequestsPage;
