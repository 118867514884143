import React from 'react';
import config from 'config';

import Link from 'components/v2/Link';
import Typography from 'components/v2/Typography';

import styles from './Instruction.module.css';

const Instruction = () => (
  <div className={styles.information}>
    <Typography className={styles.header} variant="h1">
      Can&apos;t find who you need in our database? No problem. <br /> Ordering new executive profiles is easy!
    </Typography>
    <ol className={styles.instruction}>
      <li>
        Tell us which executive(s) you want profiled by filling in the form below. If you are unsure of the name of the
        executive in a role, please contact{' '}
        <Link color="primary" href={`mailto:${config.app.customerSupportEmail}`}>
          customer success
        </Link>{' '}
        for details on how we can assist with executive role verification.
      </li>
      <li>
        Add recipients within your company by including their email addresses in the &quot;Send To Email&quot; field.
      </li>
      <li>
        Click the &quot;Submit&quot; button, and you&apos;re done! Orders by your company account of fewer than 10
        profiles will be delivered within 2 business days (Pacific Standard Time).
      </li>
    </ol>
  </div>
);

export default Instruction;
