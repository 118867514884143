import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { formattedYear, timeInterval } from 'utils/DateTime';
import { isPresent } from 'utils/HelperMethods';
import { isNil } from 'ramda';

import OrganizationPresenter from './OrganizationPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    description: PropTypes.string,
    organization: OrganizationPresenter.shape(),
    from: PropTypes.string,
    to: PropTypes.string,
    toPresentTime: PropTypes.bool,
    isMasked: PropTypes.bool,
    isRecentlyUpdated: PropTypes.bool,
    relevant: PropTypes.bool,
    isMemberFortune500: PropTypes.bool,
    isMemberFtse100: PropTypes.bool,
  },
  {
    fromYear(board) {
      const from = this.from(board);

      return formattedYear(from);
    },

    toYear(board) {
      const to = this.to(board);

      return formattedYear(to);
    },

    title(work) {
      const organization = this.organization(work);
      const description = this.description(work);

      if (isPresent(description)) {
        return `${description}, ${OrganizationPresenter.name(organization)}`;
      }

      return OrganizationPresenter.name(organization);
    },

    organizationName(board) {
      const organization = this.organization(board);

      return OrganizationPresenter.name(organization);
    },

    timeInterval(board) {
      const from = this.fromYear(board);
      const to = this.toPresentTime(board) ? 'Present' : this.toYear(board);

      return timeInterval(from, to);
    },

    isRelevant(board) {
      return !isNil(this.relevant(board)) && this.relevant(board);
    },
  },
);
