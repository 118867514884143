import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Modal, { Header, Actions } from 'containers/UserPanel/components/Modal';

import styles from './ConfirmationModal.module.css';

export default class ConfirmationModal extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  render() {
    const { onCancel, onConfirm, header } = this.props;

    return (
      <Modal className={styles.modal} onClose={onCancel}>
        <Header title={header} />
        <Actions>
          <Button className={styles.button} label="Ok" primary onClick={onConfirm} />
          <Button className={styles.button} label="Cancel" onClick={onCancel} />
        </Actions>
      </Modal>
    );
  }
}
