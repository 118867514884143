import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isPresent, reactSelectDebounce } from 'utils/HelperMethods';

import AsyncSelect from 'components/AsyncSelect';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

const OrganizationsSelect = (props) => {
  const { defaultOrganizationId, onSearchOrganizations, onOrganizationIdChange, placeholder } = props;
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    if (isPresent(defaultOrganizationId)) {
      onSearchOrganizations({ idEq: defaultOrganizationId }).then(([newOrganization]) =>
        setOrganization(newOrganization),
      );
    }
  }, []);

  const handleOrganizationSearch = (value) => onSearchOrganizations({ searchFieldCont: value });

  const handleOrganizationSearchDebounced = reactSelectDebounce(handleOrganizationSearch);

  const handleOrganizationChange = (newOrganization) => {
    setOrganization(newOrganization);
    onOrganizationIdChange(newOrganization && newOrganization.id);
  };

  return (
    <AsyncSelect
      placeholder={placeholder}
      loadOptions={handleOrganizationSearchDebounced}
      defaultOptions
      value={organization}
      onChange={handleOrganizationChange}
      getOptionValue={OrganizationPresenter.id}
      getOptionLabel={OrganizationPresenter.name}
      isClearable
      noBottom
    />
  );
};

OrganizationsSelect.propTypes = {
  onSearchOrganizations: PropTypes.func.isRequired,
  onOrganizationIdChange: PropTypes.func.isRequired,
  defaultOrganizationId: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
};

export default OrganizationsSelect;
