import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';

import ProjectSharedNotification from './components/ProjectSharedNotification';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import styles from './BackendNotifications.module.css';

const NOTIFICATION_KINDS_MAPPING = {
  project_shared: ProjectSharedNotification,
};

class BackendNotifications extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    isPolling: PropTypes.bool.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    startPollingNotifications: PropTypes.func.isRequired,
    stopPollingNotifications: PropTypes.func.isRequired,
    closeNotification: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.handleVisibilityChange();

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    this.handleStopNotificationsPolling();

    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.handleStopNotificationsPolling();
    } else {
      this.handleStartNotificationsPolling();
    }
  };

  handleStartNotificationsPolling = () => {
    const { currentUser, startPollingNotifications } = this.props;

    if (isPresent(currentUser)) {
      startPollingNotifications();
    }
  };

  handleStopNotificationsPolling = () => {
    const { isPolling, stopPollingNotifications } = this.props;

    if (isPolling) {
      stopPollingNotifications();
    }
  };

  handleCloseNotification = (notification) => {
    const { currentUser, closeNotification } = this.props;

    if (!CurrentUserPresenter.isImpersonating(currentUser)) {
      closeNotification(notification.id);
    }
  };

  renderNotification = (notification) => {
    const NotificationComponent = NOTIFICATION_KINDS_MAPPING[notification.kind];

    if (isBlank(NotificationComponent)) {
      return null;
    }

    return (
      <NotificationComponent key={notification.id} notification={notification} onClose={this.handleCloseNotification} />
    );
  };

  render() {
    const { notifications } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.notifications}>{notifications.map(this.renderNotification)}</div>
      </div>
    );
  }
}

export default BackendNotifications;
