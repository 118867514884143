import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';

import Routes from 'routes';
import { logClickOnAlertsRemoveFromAlerts } from 'utils/amplitude';

import ProfilePresenter from 'presenters/ProfilePresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import WorkPresenter from 'presenters/WorkPresenter';

import AnchorLink from 'components/grommet/AnchorLink';
import Button from 'components/Button';
import ProfileAvatar from 'components/ProfileAvatar';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';

import styles from './ProfileSubscription.module.css';

const ProfileSubscription = (props) => {
  const { subscription, onDelete, onUndoDelete } = props;
  const { isDestroyed } = subscription;
  const { subject: profile } = subscription;

  const [isDestroing, setIsDestroing] = useState(false);
  const [isUndo, setIsUndo] = useState(false);

  const handleDelete = () => {
    const id = SubscriptionPresenter.id(subscription);

    setIsDestroing(true);

    onDelete(id).finally(() => {
      setIsDestroing(false);
    });

    logClickOnAlertsRemoveFromAlerts();
  };

  const handleUndoDelete = () => {
    setIsUndo(true);

    onUndoDelete(subscription).finally(() => {
      setIsUndo(false);
    });
  };

  return (
    <div className={styles.root}>
      <AnchorLink className={styles.profile} to={Routes.profilePath(ProfilePresenter.slug(profile))}>
        <ProfileAvatar className={styles.avatar} src={ProfilePresenter.photoUrl(profile)} size="92" />
        <div className={styles.info}>
          <div className={styles.header}>
            <div className={styles.name}>{ProfilePresenter.fullName(profile)}</div>

            <div className={styles.profileLabels}>
              {ProfileSearchPresenter.isNewToRole(profile) && <NewToRoleBadge />}
              {ProfileSearchPresenter.isMemberFortune500(profile) && <Fortune500BoardMemberBadge />}
              {ProfileSearchPresenter.isMemberFtse100(profile) && <Ftse100BoardMemberBadge />}
            </div>
          </div>

          <div className={styles.works}>
            {ProfilePresenter.currentWorks(profile).map((currentWork) => (
              <div key={WorkPresenter.id(currentWork)}>
                {WorkPresenter.organizationName(currentWork)}
                {`, ${WorkPresenter.jobTitle(currentWork)}`}
              </div>
            ))}
          </div>
        </div>
      </AnchorLink>

      <div className={styles.actions}>
        {isDestroyed ? (
          <div>
            Successfully Removed.
            <Anchor className={styles.undoLink} disabled={isUndo} label="Undo" onClick={handleUndoDelete} />
          </div>
        ) : (
          <Button
            className={styles.removeButton}
            disabled={isDestroing}
            label="Remove From Alerts"
            onClick={handleDelete}
          />
        )}
      </div>
    </div>
  );
};

ProfileSubscription.propTypes = {
  subscription: SubscriptionPresenter.shape().isRequired,
  onDelete: PropTypes.func.isRequired,
  onUndoDelete: PropTypes.func.isRequired,
};

export default ProfileSubscription;
