import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import styles from './SortableList.module.css';

const SortableList = SortableContainer(({ children }) => <ul className={styles.list}> {children} </ul>);

SortableList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape()),
};

export default SortableList;
