import { defaultTo, pick } from 'ramda';

import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

const defaultSeatsNumber = ({ accountType, seatsNumber, persisted }) =>
  !persisted && CompanyDepartmentPresenter.isIndividualAccountAccountType({ accountType })
    ? 1
    : defaultTo(0, seatsNumber);

export default {
  defaultAttributes(department) {
    return {
      name: '',
      salesforceAutoRegistration: false,
      persisted: false,
      ...department,
      seatsNumber: defaultSeatsNumber(department),
      requestsNumber: defaultTo(0, department.requestsNumber),
      projectsNumber: defaultTo(0, department.projectsNumber),
      accountType: defaultTo('', department.accountType),
      profileViewsNumber: defaultTo(0, department.profileViewsNumber),
      profileDownloadsNumber: defaultTo(0, department.profileDownloadsNumber),
      projectsAvailable: defaultTo(false, department.projectsAvailable),
      projectsExportEnabled: defaultTo(false, department.projectsExportEnabled),
      databaseOnly: defaultTo(false, department.databaseOnly),
    };
  },

  attributesToSubmit(attributes) {
    const propsToSend = [
      'name',
      'salesforceAutoRegistration',
      'seatsNumber',
      'requestsNumber',
      'projectsNumber',
      'accountType',
      'profileViewsNumber',
      'profileDownloadsNumber',
      'projectsAvailable',
      'projectsExportEnabled',
      'databaseOnly',
    ];

    return pick(propsToSend, attributes);
  },

  reinitilizeForAccountType({ accountType, seatsNumber }) {
    const propsToReinitilize = [
      'accountType',
      'profileViewsNumber',
      'profileDownloadsNumber',
      'requestsNumber',
      'databaseOnly',
      'seatsNumber',
    ];
    const attributes = this.defaultAttributes({ accountType, seatsNumber });

    return pick(propsToReinitilize, attributes);
  },
};
