import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { reactSelectDebounce } from 'utils/HelperMethods';

import AsyncSelect from 'components/AsyncSelect';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';

import CountryPresenter from 'presenters/CountryPresenter';

import styles from './OriginCountry.module.css';

class OriginCountry extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    saving: PropTypes.bool,
    originCountry: CountryPresenter.shape(),
    onCountrySearch: PropTypes.func.isRequired,
    onOriginCountryChange: PropTypes.func.isRequired,
    isChanged: PropTypes.bool.isRequired,
    onReviewChanges: PropTypes.func.isRequired,
  };

  handleCountrySearch = (searchText) => {
    const { onCountrySearch } = this.props;

    return onCountrySearch(searchText);
  };

  handleCountryChange = (country) => {
    const { onOriginCountryChange } = this.props;

    onOriginCountryChange(country);
  };

  handleCountrySearchDebounced = reactSelectDebounce(this.handleCountrySearch);

  render() {
    const { saving, editable, isChanged, onReviewChanges, originCountry } = this.props;

    return (
      <>
        <Box margin={{ bottom: 'small' }}>
          <HeadlineWithReviewChangesLink
            strong
            size="small"
            title="Origin Country"
            isChanged={isChanged}
            onReviewChanges={onReviewChanges}
          />
        </Box>

        <div className={styles.options}>
          <div className={styles.field}>
            <AsyncSelect
              isDisabled={!editable || saving}
              loadOptions={this.handleCountrySearchDebounced}
              placeholder="Select origin country"
              isClearable
              defaultOptions
              value={originCountry}
              onChange={this.handleCountryChange}
              getOptionValue={CountryPresenter.id}
              getOptionLabel={CountryPresenter.name}
              noBottom
            />
          </div>
        </div>
      </>
    );
  }
}

export default OriginCountry;
