import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import LimitedAccessRepository from 'repositories/admin/Companies/Departments/LimitedAccessRepository';

const restSlice = createRestSlice({
  resource: 'limitedAccess',
  repository: LimitedAccessRepository,
  slices: ['loadLimitedAccess', 'updateLimitedAccess'],
});

const initialState = {
  ...restSlice.initialState,
};

const limitedAccessSlice = createSlice({
  name: 'adminCompanyDepartmentLimitedAccess',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDepartmentLimitedAccessReducer = limitedAccessSlice.reducer;

export default limitedAccessSlice;

export const adminCompanyDepartmentLimitedAccessRestHooks = restSlice.hooks(
  limitedAccessSlice,
  'adminCompanyDepartmentLimitedAccessReducer',
);
