import numeral from 'numeral';

export function formatNumber(number, locale = 'en') {
  numeral.locale(locale);
  return numeral(number).format();
}

export function formatNumberAsPercentages(number, locale = 'en') {
  numeral.locale(locale);
  return `${numeral(number).format('0[.]00')}%`;
}
