import React from 'react';

import MobileDeviceDummy from 'components/MobileDeviceDummy';

const ProjectMobileDummy = () => (
  <MobileDeviceDummy>
    BI Pro is not currently supported on mobile devices. Please access the website on a desktop, laptop, or tablet
    device.
  </MobileDeviceDummy>
);

export default ProjectMobileDummy;
