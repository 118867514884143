import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PopularOrganizationTranscripts from './PopularOrganizationTranscripts';
import * as actions from './PopularOrganizationTranscriptsActions';

const mapStateToProps = (state) => ({
  loading: state.PopularOrganizationTranscriptsReducer.loading,
  transcripts: state.PopularOrganizationTranscriptsReducer.transcripts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PopularOrganizationTranscripts);
