import Validator from 'password-validator';

/*eslint-disable */
const validationSchema = new Validator()
  .is().min(9)
  .has().uppercase(1)
  .has().lowercase(1)
  .has().digits(1)
  .has().symbols(1)
  .has().not().spaces();
/* eslint-enable */

export const PASSWORD_CONTAIN_NUMBER_OF_CHARACTERS = { label: '9 characters', key: 'min' };
export const PASSWORD_CONTAIN_A_DIGIT = { label: '1 number', key: 'digits' };
export const PASSWORD_CONTAIN_A_LOWER_CASE_CHARACTER = { label: '1 lowercase letter', key: 'lowercase' };
export const PASSWORD_CONTAIN_A_LOWER_UPPER_CHARACTER = { label: '1 uppercase letter', key: 'uppercase' };
export const PASSWORD_CONTAIN_A_SPECIAL_CHARACTER = { label: '1 special character', key: 'symbols' };

export const validatePassword = (password) => validationSchema.validate(password, { list: true });
