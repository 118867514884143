import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intersection, values } from 'ramda';

import {
  FILTERS,
  USER_BASED_FILTERS,
  SCOPE_FILTER_VALUES,
  humanFilterName,
  humanFilterValue,
} from 'forms/ProfileFilteredSearchForm';

import styles from './FiltersList.module.css';

class FiltersList extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  renderUserBasedFilterRow = (filter) => (
    <div className={styles.row} key={filter}>
      <div className={styles.filterName}>{humanFilterName(filter)}</div>
    </div>
  );

  renderSearchFilterRow = (filter) => {
    const { filters } = this.props;
    const valuesNames = humanFilterValue(filter, filters[filter]);

    return (
      <div className={styles.row} key={filter}>
        <div className={styles.filterName}>{humanFilterName(filter)}:</div>
        <div className={styles.filterValue}>{valuesNames}</div>
      </div>
    );
  };

  render() {
    const { filters } = this.props;
    const filtersKeys = Object.keys(filters);
    const userBasedFiltersKeys = intersection(filtersKeys, values(USER_BASED_FILTERS));
    const searchFiltersKeys = intersection(filtersKeys, values({ ...SCOPE_FILTER_VALUES, ...FILTERS }));

    return (
      <div className={styles.root}>
        {userBasedFiltersKeys.map(this.renderUserBasedFilterRow)}
        {searchFiltersKeys.map(this.renderSearchFilterRow)}
      </div>
    );
  }
}

export default FiltersList;
