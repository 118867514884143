import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import { withRouter } from 'react-router-dom';
import { toRelativeCalendar } from 'utils/DateTime';
import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';

import ProfileAvatar from 'components/ProfileAvatar';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';

import styles from './Statistics.module.css';

class StatisticsComponent extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    history: PropTypes.shape().isRequired,
    path: PropTypes.string.isRequired,
    profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
    profilesTotalCount: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
  };

  handleClick = (e) => {
    const { history, path } = this.props;
    e.stopPropagation();
    e.preventDefault();

    history.push(path);
  };

  renderProfileAvatar(profile) {
    return (
      <div key={ProfilePresenter.id(profile)} className={styles.profile}>
        <div className={styles.avatarBox}>
          <ProfileAvatar className={styles.avatar} src={ProfilePresenter.photoUrl(profile)} size="40" />
        </div>
      </div>
    );
  }

  renderProfilesCount(count) {
    return (
      <div className={styles.profile}>
        <div className={styles.avatarBox}>
          <div className={styles.avatar}>{count} +</div>
        </div>
      </div>
    );
  }

  render() {
    const { description, profiles, profilesTotalCount, title, updatedAt } = this.props;
    const visibleProfiles = take(5, profiles);
    const notVisibleProfilesCount = profilesTotalCount - visibleProfiles.length;

    return (
      <Button className={styles.root} onClick={this.handleClick}>
        <div className={styles.title}> {title} </div>
        <div className={styles.profiles}>
          {visibleProfiles.map(this.renderProfileAvatar)}
          {notVisibleProfilesCount > 0 && this.renderProfilesCount(notVisibleProfilesCount)}
        </div>
        <div className={styles.info}>
          <div className={styles.count}>{description}</div>
          {isPresent(updatedAt) && <div className={styles.date}>Updated: {toRelativeCalendar(updatedAt)}</div>}
        </div>
      </Button>
    );
  }
}

const Statistics = withRouter(StatisticsComponent);

export default Statistics;
