import { defaultTo, pick } from 'ramda';

export default {
  defaultAttributes(department) {
    return {
      ...department,
      registrationFormHeader: defaultTo('', department.registrationFormHeader),
      registrationFormWysiwyg: defaultTo('', department.registrationFormWysiwyg),
      registrationFormVideoCode: defaultTo('', department.registrationFormVideoCode),
    };
  },

  attributesToSubmit(department) {
    const propsToSend = ['registrationFormHeader', 'registrationFormWysiwyg', 'registrationFormVideoCode'];

    return pick(propsToSend, department);
  },
};
