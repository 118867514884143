import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(user) {
    const url = Routes.apiV1CurrentUserCompanyUsersPath();
    return FetchHelpers.post(url, { user });
  },

  index(query) {
    const url = Routes.apiV1CurrentUserCompanyUsersPath(query);
    return FetchHelpers.get(url);
  },

  bulkDestroy(query) {
    const url = Routes.bulkDestroyApiV1CurrentUserCompanyUsersPath(query);
    return FetchHelpers.delete(url);
  },
};
