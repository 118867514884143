import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import ProfilePolicy from 'policies/ProfilePolicy';
import UserPolicy from 'policies/UserPolicy';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import NotificationsSummaryPresenter from 'presenters/NotificationsSummaryPresenter';

import AnchorLink from 'components/grommet/AnchorLink';

import Routes from 'routes';

import styles from './AuthorizedMenu.module.css';

class AuthorizedMenu extends Component {
  handleMenuClose = () => {
    this.props.onCloseMenu();
  };

  render() {
    const { currentUser, notificationsSummary } = this.props;

    return (
      <div className={styles.nav}>
        <ul>
          {ProfilePolicy.canSearch(currentUser) && (
            <li onClick={this.handleMenuClose} role="presentation">
              <NavLink to={Routes.searchPath()} activeStyle={{ color: 'var(--primary-color)' }}>
                Search Profiles
              </NavLink>
            </li>
          )}
          {UserPolicy.canRequestProfile(currentUser) && (
            <li onClick={this.handleMenuClose} role="presentation">
              <NavLink to={Routes.newRequestPath()} activeStyle={{ color: 'var(--primary-color)' }}>
                Request Profiles
              </NavLink>
            </li>
          )}
          {UserPolicy.canSeeProjects(currentUser) && (
            <li onClick={this.handleMenuClose} role="presentation">
              <NavLink to={Routes.projectsPath()} activeStyle={{ color: 'var(--primary-color)' }}>
                Projects
                {NotificationsSummaryPresenter.isProjectsUpdated(notificationsSummary) && (
                  <div className={styles.notificationBadge} />
                )}
              </NavLink>
            </li>
          )}
          <li onClick={this.handleMenuClose} role="presentation">
            <AnchorLink to={Routes.productsUrl()} label="Products" target="_blank" />
          </li>
          <li onClick={this.handleMenuClose} role="presentation">
            <AnchorLink to={Routes.contactUsUrl()} label="Contact Us" target="_blank" />
          </li>
          <li onClick={this.handleMenuClose} role="presentation">
            <AnchorLink to={Routes.articlesUrl()} label="Articles" target="_blank" />
          </li>
        </ul>
      </div>
    );
  }
}

AuthorizedMenu.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  onCloseMenu: PropTypes.func,
  notificationsSummary: NotificationsSummaryPresenter.shape(),
};

export default AuthorizedMenu;
