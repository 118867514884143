import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { equals, find, not, isNil, pipe, reject } from 'ramda';
import { Box, Heading, TextInput, CheckBox } from 'grommet';

import { isSameValues } from 'utils/ArrayHelpers';
import { trimExtraEmptyLinesFromHTML } from 'utils/HTMLHelpers';

import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import Card from 'components/Card';
import Wysiwyg from 'components/Wysiwyg';
import TopicTagsSelect from 'containers/AdminPanel/components/TopicTagsSelect';

import TopicEditForm from 'forms/organization/TopicEditForm';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import TopicPresenter from 'presenters/TopicPresenter';
import TagPresenter from 'presenters/TagPresenter';

class TopicForm extends Component {
  static propTypes = {
    jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()).isRequired,
    topic: TopicPresenter.shape(),
    saving: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
    displayTags: PropTypes.bool.isRequired,
  };

  constructor(...attrs) {
    super(...attrs);

    const { topic } = this.props;
    this.state = {
      form: TopicEditForm.defaultAttributes(topic),
      errors: null,
    };
  }

  handleChange = (fieldName) => (event) => {
    this.setState({ form: { ...this.state.form, [fieldName]: event.target.value } });
  };

  handleCheckboxChange =
    (fieldName) =>
    ({ target: { checked } }) =>
      this.setState({ form: { ...this.state.form, [fieldName]: checked } });

  handleWysiwygChange = (fieldName) => (data) => {
    this.setState({ form: { ...this.state.form, [fieldName]: data } });
  };

  handleSubmit = () => {
    const attributes = TopicEditForm.attributesToSubmit(this.state.form);
    const trimmedDescription = trimExtraEmptyLinesFromHTML(attributes.description);
    const updatedAttributes = { ...attributes, description: trimmedDescription };

    this.props.onSubmit(updatedAttributes).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  handleJobFunctionChange =
    (jobFunctionId) =>
    ({ target }) => {
      const { jobFunctions } = this.props;
      const {
        form: { jobFunctionIds },
      } = this.state;
      const newJobFunctionIds = target.checked
        ? [...jobFunctionIds, jobFunctionId]
        : reject(equals(jobFunctionId), jobFunctionIds);
      const allJobFunctions = isSameValues(newJobFunctionIds, jobFunctions.map(JobFunctionPresenter.id));

      this.setState({ form: { ...this.state.form, jobFunctionIds: newJobFunctionIds, allJobFunctions } });
    };

  isJobFunctionSelected(jobFunction) {
    return pipe(find(equals(jobFunction.id)), isNil, not)(this.state.form.jobFunctionIds);
  }

  handleAllJobFunctionsChange = () => {
    const { jobFunctions } = this.props;
    const { form } = this.state;
    const allJobFunctions = !form.allJobFunctions;
    const jobFunctionIds = allJobFunctions ? jobFunctions.map(JobFunctionPresenter.id) : [];

    this.setState({ form: { ...form, allJobFunctions: !form.allJobFunctions, jobFunctionIds } });
  };

  handleTagAdd = (tag) => {
    const { form } = this.state;

    this.setState({ form: { ...form, tags: [...form.tags, tag] } });
  };

  handleTagDelete = (tagToDelete) => {
    const { form } = this.state;
    const tags = form.tags.filter((tag) => TagPresenter.id(tag) !== TagPresenter.id(tagToDelete));

    this.setState({ form: { ...form, tags } });
  };

  decodeHtmlBody(htmlBody) {
    const htmlValue = isNil(htmlBody) ? '' : htmlBody;
    const parser = new DOMParser();
    const dom = parser.parseFromString(`<!doctype html><body>${htmlValue}`, 'text/html');

    return dom.body.innerHTML;
  }

  render() {
    const { form, errors } = this.state;
    const { jobFunctions, saving, onTagSearch, displayTags } = this.props;

    return (
      <>
        <VerticalFormFieldWithBottomErrors label="Title" errors={errors && errors.title} htmlFor="title">
          <TextInput value={form.title} onChange={this.handleChange('title')} id="title" />
        </VerticalFormFieldWithBottomErrors>
        <Box margin={{ bottom: 'small' }}>
          <Wysiwyg
            editableContent={this.decodeHtmlBody(form.description)}
            onChange={this.handleWysiwygChange('description')}
          />
        </Box>

        {displayTags && (
          <TopicTagsSelect
            saving={saving}
            onTagAdd={this.handleTagAdd}
            onTagDelete={this.handleTagDelete}
            onTagSearch={onTagSearch}
            tags={form.tags}
          />
        )}

        <VerticalFormFieldWithBottomErrors errors={errors && errors.pinned} checkbox>
          <CheckBox label="Pinned" toggle checked={!!form.pinned} onChange={this.handleCheckboxChange('pinned')} />
        </VerticalFormFieldWithBottomErrors>

        <Box margin={{ bottom: 'medium' }}>
          <Card>
            <Heading weight="bold" level="4">
              Job Functions
            </Heading>
            <Box margin={{ bottom: 'small' }}>
              <CheckBox
                label="All Job Functions"
                checked={!!form.allJobFunctions}
                onChange={this.handleAllJobFunctionsChange}
              />
            </Box>
            {jobFunctions.map((jobFunction) => (
              <Box key={jobFunction.id} margin={{ bottom: 'small' }}>
                <CheckBox
                  label={JobFunctionPresenter.name(jobFunction)}
                  checked={this.isJobFunctionSelected(jobFunction)}
                  onChange={this.handleJobFunctionChange(jobFunction.id)}
                />
              </Box>
            ))}
          </Card>
        </Box>

        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </>
    );
  }
}

export default TopicForm;
