import React, { Component } from 'react';

import { Anchor } from 'grommet';

import styles from './RequestDemo.module.css';

import Routes from 'routes';

export default class RequestDemo extends Component {
  render() {
    return (
      <div className={styles.root}>
        Relationship maps are a new feature available to BI PRO customers. For full access to our Relationship Mapping
        tool,{' '}
        <Anchor
          className={styles.link}
          href={Routes.biProDemoRequestUrl()}
          target="_blank"
          label="contact your account manager for a demo"
        />{' '}
        or <Anchor className={styles.link} href={Routes.biProUrl()} target="_blank" label="click here to learn more" />.
      </div>
    );
  }
}
