import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import styles from './MenuItem.module.css';

const MenuItem = (props) => {
  const { children, component: Item, ...rest } = props;

  return (
    <Item className={styles.root} {...rest}>
      {children}
    </Item>
  );
};

MenuItem.propTypes = {
  component: PropTypes.func,
  children: PropTypes.node,
};

MenuItem.defaultProps = {
  component: Button,
};

export default MenuItem;
