import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formatNumber } from 'utils/NumbersHelper';

export default new Presenter(
  {
    thisWeek: PropTypes.number,
    thisMonth: PropTypes.number,
    lastThreeMonths: PropTypes.number,
    lastYear: PropTypes.number,
  },
  {
    formattedThisWeek(r) {
      return formatNumber(this.thisWeek(r));
    },
    formattedThisMonth(r) {
      return formatNumber(this.thisMonth(r));
    },
    formattedLastThreeMonths(r) {
      return formatNumber(this.lastThreeMonths(r));
    },
    formattedLastYear(r) {
      return formatNumber(this.lastYear(r));
    },
  },
);
