import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Actions.module.css';

export default class Actions extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  };

  render() {
    const { children } = this.props;

    return <div className={styles.actions}>{children}</div>;
  }
}
