import React from 'react';
import PropTypes from 'prop-types';

import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CustomizedTooltip from './components/CustomizedTooltip';
import CustomizedTick from './components/CustomizedTick';

import RequestStatisticPresenter from 'presenters/EditorialDashboard/Productivity/RequestStatisticPresenter';

import styles from './Chart.module.css';

const Chart = (props) => {
  const { data } = props;
  return (
    <div className={styles.root}>
      <ResponsiveContainer width="100%" height={205}>
        <LineChart data={data}>
          <XAxis
            dataKey="textTime"
            interval="preserveStartEnd"
            axisLine={false}
            tickLine={false}
            tick={<CustomizedTick />}
          />
          <CartesianGrid strokeDasharray="0" horizontalPoints={[130]} verticalPoints={[180]} />
          <Tooltip content={<CustomizedTooltip />} />
          <Line type="monotone" dataKey="count" stroke="#00A0CE" dot={false} activeDot={{ r: 5 }} />
          <Line
            type="monotone"
            dataKey="previousCount"
            stroke="#00A0CE"
            dot={false}
            strokeDasharray="3 4 5 2"
            activeDot={{ r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(RequestStatisticPresenter.shape()).isRequired,
};

export default Chart;
