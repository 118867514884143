import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import CategoriesRepository from 'repositories/customer/ResourceCenter/CategoriesRepository';

const restSlice = createRestSlice({
  resource: 'category',
  repository: CategoriesRepository,
  slices: ['loadAllCategories'],
});

const initialState = {
  ...restSlice.initialState,
};

const categoriesSlice = createSlice({
  name: 'clientCustomerResourceCenterCategories',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientCustomerResourceCenterCategoriesReducer = categoriesSlice.reducer;

export default categoriesSlice;

export const clientCustomerResourceCenterCategoriesRestHooks = restSlice.hooks(
  categoriesSlice,
  'clientCustomerResourceCenterCategoriesReducer',
);
