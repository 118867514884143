import React from 'react';
import DashboardPresenter from 'presenters/DashboardPresenter';
import UserPolicy from 'policies/UserPolicy';

export const GUIDES_NAMES = {
  SEARCH_GUIDE: 'SEARCH_GUIDE',
  PROJECTS_GUIDE: 'PROJECTS_GUIDE',
  SAVED_SEARCHES_GUIDE: 'SAVED_SEARCHES_GUIDE',
  ENTIRE_DATABASE_GUIDE: 'ENTIRE_DATABASE_GUIDE',
  RECENT_REQUESTS_GUIDE: 'RECENT_REQUESTS_GUIDE',
};

export default [
  {
    name: GUIDES_NAMES.SEARCH_GUIDE,
    guideProps: {
      selector: `#${GUIDES_NAMES.SEARCH_GUIDE}`,
      position: 'left',
    },
    children: (
      <div>
        Want to go straight to search?
        <br /> Click here.
      </div>
    ),
    isAvailable: () => true,
  },
  {
    name: GUIDES_NAMES.PROJECTS_GUIDE,
    guideProps: {
      selector: `#${GUIDES_NAMES.PROJECTS_GUIDE} > div > :first-child`,
      position: 'right',
    },
    children: (
      <div>
        Since you last logged in, there have been
        <br /> some changes at the accounts you care about
        <br /> most. To see what’s new, click on your ‘Project’
      </div>
    ),
    isAvailable: ({ dashboard }) => DashboardPresenter.changedProjects(dashboard).length > 0,
  },
  {
    name: GUIDES_NAMES.SAVED_SEARCHES_GUIDE,
    guideProps: {
      selector: `#${GUIDES_NAMES.SAVED_SEARCHES_GUIDE} > div > :first-child`,
      position: 'right',
    },
    children: (
      <div>
        Since you last logged in, there have been
        <br /> some changes at the accounts you care about
        <br /> most. To see what’s new, click on your ‘Saved Search’
      </div>
    ),
    isAvailable: ({ dashboard }) => DashboardPresenter.changedSavedSearches(dashboard).length > 0,
  },
  {
    name: GUIDES_NAMES.ENTIRE_DATABASE_GUIDE,
    guideProps: {
      selector: `#${GUIDES_NAMES.ENTIRE_DATABASE_GUIDE}`,
      position: 'top',
    },
    children: (
      <div>
        Our new customer dashboard highlights what has changed
        <br /> in our database in the last 90 days, including executive
        <br /> changes, new profiles and updated profiles.
      </div>
    ),
    isAvailable: () => true,
  },
  {
    name: GUIDES_NAMES.RECENT_REQUESTS_GUIDE,
    guideProps: {
      selector: `#${GUIDES_NAMES.RECENT_REQUESTS_GUIDE}`,
      position: 'top',
    },
    children: (
      <div>You can now view your profile request history and monitor the status of profile requests right here.</div>
    ),
    isAvailable: ({ currentUser }) => UserPolicy.canRequestProfile(currentUser),
  },
];
