import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formatNumber, formatNumberAsPercentages } from 'utils/NumbersHelper';
import { formattedDurationFromSeconds } from 'utils/DateTime';
import StatisticItemPresenter from './StatisticItemPresenter';

export const REQUESTS_COUNT_VALUE = 'requests_count';
export const SPENT_TIME_VALUE = 'spent_time';

export const VALUE_OPTIONS = [
  { label: 'Count Of Processed Requests', value: REQUESTS_COUNT_VALUE },
  { label: 'Time Spended On Requests', value: SPENT_TIME_VALUE },
];

export const SUM_STATISTIC = 'sum';
export const AVG_STATISTIC = 'avg';

export const STATISTIC_OPTIONS = [
  { label: 'Sum', value: SUM_STATISTIC },
  { label: 'Average', value: AVG_STATISTIC },
];

export default new Presenter(
  {
    data: PropTypes.arrayOf(StatisticItemPresenter.shape()),
    totalValue: PropTypes.number,
  },
  {
    formattedTotalValue(statistic, value, compare) {
      const totalValue = this.totalValue(statistic);

      if (compare) {
        return formatNumberAsPercentages(totalValue);
      }

      return value === SPENT_TIME_VALUE ? formattedDurationFromSeconds(totalValue) : formatNumber(totalValue);
    },
  },
);
