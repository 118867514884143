import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1AdminSampleProfilesPath(params);

    return FetchHelpers.get(url);
  },

  update(sampleProfileId, sampleProfile) {
    const url = Routes.apiV1AdminSampleProfilePath(sampleProfileId);

    return FetchHelpers.patch(url, { sampleProfile });
  },
};
