import React from 'react';

import Button from 'components/v2/Button';
import Loader from 'components/v2/Loader';
import Typography from 'components/v2/Typography';

import styles from './ValidatingStatus.module.css';

const ValidatingStatus = () => (
  <div className={styles.validatingStatus}>
    <Loader className={styles.loader} />
    <Typography variant="h3">File is validating</Typography>
    <Button variant="link">
      <Typography color="primary" variant="h3">
        Upload another file
      </Typography>
    </Button>
  </div>
);

export default ValidatingStatus;
