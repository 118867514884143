import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import cn from 'clsx';
import styles from './HighlightsItem.module.css';

const LONG_TEXT_LENGTH = 170;

export default class HighlightsItem extends Component {
  static propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
    onDelete: PropTypes.func,
    saving: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
  };

  state = {
    isLongTextHidden: null,
  };

  componentDidMount() {
    if (this.isTextLong()) {
      this.handleHideLongText();
    }
  }

  isTextLong() {
    const { text } = this.props;
    return text.length > LONG_TEXT_LENGTH;
  }

  handleShowLongText = () => {
    this.setState({ isLongTextHidden: false });
  };

  handleHideLongText = () => {
    this.setState({ isLongTextHidden: true });
  };

  handleDeleteHighlight = () => {
    const { onDelete, id } = this.props;
    onDelete(id);
  };

  renderButton = () => {
    const { isLongTextHidden } = this.state;

    if (isLongTextHidden) {
      return (
        <button className={styles.button} onClick={this.handleShowLongText}>
          Show more
        </button>
      );
    }

    return (
      <button className={styles.button} onClick={this.handleHideLongText}>
        Hide
      </button>
    );
  };

  render() {
    const rootStyles = cn([styles.root], { [styles.collapsed]: this.state.isLongTextHidden });
    const { text, title, saving, editable } = this.props;
    return (
      <div className={rootStyles}>
        <div className={styles.title}>
          <span className={styles.titleText}>{title}</span>
          {editable && (
            <button className={styles.deleteTag} onClick={this.handleDeleteHighlight} disabled={saving}>
              <Icon name="close" style={{ width: 7, height: 7 }} />
            </button>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.text}>{text}</div>
          {this.isTextLong() ? this.renderButton() : null}
        </div>
      </div>
    );
  }
}
