import keyMirror from 'keymirror';

const PhoneNumberConstants = keyMirror({
  UPDATE_CURREN_USER_PHONE_NUMBER_START: null,
  UPDATE_CURREN_USER_PHONE_NUMBER_SUCCESS: null,
  UPDATE_CURREN_USER_PHONE_NUMBER_ERROR: null,

  CONFIRM_CURRENT_USER_PHONE_NUMBER_START: null,
  CONFIRM_CURRENT_USER_PHONE_NUMBER_SUCCESS: null,
  CONFIRM_CURRENT_USER_PHONE_NUMBER_ERROR: null,
});

export default PhoneNumberConstants;
