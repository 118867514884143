import keyMirror from 'keymirror';

const ProjectSubscriptionsListContainerConstants = keyMirror({
  LOAD_PROJECT_SUBSCRIPTION_START: null,
  LOAD_PROJECT_SUBSCRIPTION_SUCCESS: null,
  LOAD_PROJECT_SUBSCRIPTION_ERROR: null,

  DELETE_PROJECT_SUBSCRIPTION_START: null,
  DELETE_PROJECT_SUBSCRIPTION_SUCCESS: null,
  DELETE_PROJECT_SUBSCRIPTION_ERROR: null,

  FILTER_PROJECT_SUBSCRIPTION_START: null,
  FILTER_PROJECT_SUBSCRIPTION_SUCCESS: null,
  FILTER_PROJECT_SUBSCRIPTION_ERROR: null,
});

export default ProjectSubscriptionsListContainerConstants;
