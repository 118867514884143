import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { buildTargetItemCriterias, parseTargetItemCriterias } from 'utils/Project/TargetItemHelper';

import useClientSearch from 'hooks/client/useSearch';

import Button from 'components/v2/Button';
import Loader from 'components/v2/Loader';
import Typography from 'components/v2/Typography';
import { Field, Switch } from 'components/v2/Form';

import Filters from 'containers/UserPanel/components/SearchFilters';
import ProfileList from 'containers/UserPanel/components/SearchProfilesList';

import LimitedAccessPresenter, { STATE_EVENTS } from 'presenters/Company/Department/LimitedAccessPresenter';
import NumberPresenter from 'presenters/NumberPresenter';

import styles from './EditForm.module.css';

const PER_PAGE = 10;
const MAX_PAGE = 100;

const EditForm = (props) => {
  const { initialValues, isSubmitting, errors, onFieldChange, onSubmit, values: limitedAccess } = props;

  const { isExecutivesLoading, executives, executivesMeta, filterExecutives, loadFilterOptions } = useClientSearch();
  const [filtersAutocomplete, setFiltersAutocomplete] = useState({});

  const isInitiallyEnabled = LimitedAccessPresenter.isEnabled(initialValues);
  const isEnableStateEvent = STATE_EVENTS.ENABLE === limitedAccess.stateEvent;
  const isEnabled = isNil(limitedAccess.stateEvent) ? isInitiallyEnabled : isEnableStateEvent;
  const filters = useMemo(() => parseTargetItemCriterias(limitedAccess.criterias), [limitedAccess.criterias]);

  const handleLimitedAccessStateChange = (value) => {
    let stateEvent = null;

    if (value !== isInitiallyEnabled) {
      stateEvent = value ? STATE_EVENTS.ENABLE : STATE_EVENTS.DISABLE;
    }

    onFieldChange('stateEvent', stateEvent);
  };

  const handleFilterChange = (filterName) => (value) => {
    const criterias = buildTargetItemCriterias({ ...filters, [filterName]: value });

    onFieldChange('criterias', criterias);
  };

  const handleFilterOptionsLoad = (filterName) => (value) =>
    loadFilterOptions(filterName, value, filters).then((response) => response[filterName] || []);

  const handleExecutivesPageLoad = (page) =>
    filterExecutives({ ...filters, page, per: PER_PAGE }).then(({ autocomplete }) => {
      setFiltersAutocomplete(autocomplete);
    });

  useEffect(() => {
    handleExecutivesPageLoad(1);
  }, [filters]);

  return (
    <div className={styles.form}>
      <div className={styles.sidebar}>
        <Typography variant="h3">Limited Access Settings</Typography>
        <Field title="Limited Access" error={errors.state} variant="outlined">
          <Switch checked={isEnabled} onChange={handleLimitedAccessStateChange} />
        </Field>
        <Filters
          key={executivesMeta.totalCount}
          autocomplete={filtersAutocomplete}
          isLoading={isExecutivesLoading}
          onFilterChange={handleFilterChange}
          onLoadOptions={handleFilterOptionsLoad}
          onSortChange={() => {}}
          searchParams={filters}
          sort={filters.sort}
        />
        <div className={styles.actions}>
          <Button className={styles.saveButton} disabled={isSubmitting} onClick={onSubmit}>
            Save
          </Button>
        </div>
      </div>
      <div className={styles.profileList}>
        <Typography variant="h3">
          Profiles{' '}
          {!isExecutivesLoading && (
            <span className={styles.counter}>{NumberPresenter.withDelim(executivesMeta.totalCount)}</span>
          )}
        </Typography>
        {!isExecutivesLoading && executivesMeta.totalCount === 0 && (
          <Typography variant="body1" className={styles.message}>
            Sorry, no results.
          </Typography>
        )}
        {isExecutivesLoading ? (
          <Loader />
        ) : (
          <ProfileList
            currentPage={executivesMeta.currentPage}
            isSelectable={false}
            maxPages={MAX_PAGE}
            highlights={executivesMeta.highlights}
            highlightedKeywords={executivesMeta.highlightedKeywords}
            onLoadPage={handleExecutivesPageLoad}
            perPage={PER_PAGE}
            profiles={executives}
            totalCount={executivesMeta.totalCount}
          />
        )}
      </div>
    </div>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  initialValues: LimitedAccessPresenter.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: LimitedAccessPresenter.shape().isRequired,
};

export default EditForm;
