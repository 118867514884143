import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TREventsBlock from './TREventsBlock';
import * as actions from './TREventsBlockActions';

const mapStateToProps = (state) => ({
  loading: state.TREventsBlockReducer.loading,
  events: state.TREventsBlockReducer.events,
  nextPage: state.TREventsBlockReducer.nextPage,
  errorStatus: state.TREventsBlockReducer.errorStatus,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TREventsBlock);
