import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Summarize—in list format, similar to how you do it on LinkedIn—everywhere the executive has worked. Include
        titles and years served, when that information is available.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <p>
        General Motors
        <br />
        CEO
        <br />
        2017-Present
      </p>

      <p>
        Ford Motor Company
        <br />
        EVP, Strategy
        <br />
        2014-2017
      </p>

      <p>
        Fiat
        <br />
        VP, Finance
        <br />
        2012-2014
      </p>

      <p>
        Fiat
        <br />
        Senior Director, Finance Operations
        <br />
        2010-2012
      </p>
      <p>
        <strong>Important Note: </strong>
        If the organization you need to enter does not come up in the menu, you need to go to the “Organizations” module
        and add your organization.
      </p>
      <p>
        <strong>Style Tips:</strong>
      </p>
      <ul>
        <li>
          We are not looking for volunteer positions here. Those would go into the “Personal Interests and Attributes”
          section.
        </li>
        <li>This section is reserved for full-time employment history</li>
        <li>
          Always select an existing organization from the menu if it exists. Do not create dupes!!! e.g., “General
          Motors” and “General Motors, Inc.”
        </li>
        <li>Titles should be capitalized, as in the example above</li>
        <li>
          Include years served. If they are still on the board, select “Present”. We are no longer include months.
        </li>
        <li>
          If someone worked at a single company in many different roles, you need to create a unique entry for every
          role.
        </li>
        <li>If you do not know the exact roles they held for certain timeframes, just put “Various Roles 2000-2010”</li>
      </ul>
    </div>
  );
}
