/* eslint-disable no-param-reassign */
import { loadAll } from 'utils/api/batchHelpers';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import { extractResourceData } from './utils';

export default function loadAllResourcesSlice(options) {
  const { pluralResource, repository } = options;

  return {
    initialState: {
      [pluralResource]: {
        loadingStatus: FETCH_STATUSES.idle,
        items: [],
        meta: {},
        error: null,
      },
    },
    reducers: {
      loadAllResourcesStart(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.pending;
        state[pluralResource].items = [];
        state[pluralResource].error = null;
      },
      loadAllResourcesSuccess(state, { payload }) {
        const items = extractResourceData(payload);
        state[pluralResource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[pluralResource].items = items;
        state[pluralResource].meta = payload?.meta;
      },
      loadAllResourcesFail(state, { payload: { error } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
    },
    actionCreators(restDispatch) {
      return {
        loadAllResources: async (...params) => {
          restDispatch('loadAllResourcesStart');

          try {
            const data = await loadAll(repository.index, params, 'items');
            restDispatch('loadAllResourcesSuccess', data);
          } catch (error) {
            restDispatch('loadAllResourcesFail', { error });
            throw error;
          }
        },
      };
    },
    abstractSelector: (state) => ({
      resources: state[pluralResource],
    }),
  };
}
