import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import config from 'config';

import InterestPresenter from 'presenters/InterestPresenter';

class InterestsList extends Component {
  static propTypes = {
    interests: PropTypes.arrayOf(InterestPresenter.shape()),
  };

  renderInterest(interest) {
    const className = cn({ [config.changesHighlightsClassName]: InterestPresenter.isRecentlyUpdated(interest) });

    return (
      <li className={className} key={InterestPresenter.id(interest)}>
        {InterestPresenter.title(interest)}
      </li>
    );
  }

  render() {
    const { interests } = this.props;

    return (
      <>
        <h5>Personal Interests</h5>
        <ul>{interests.map(this.renderInterest)}</ul>
      </>
    );
  }
}

export default InterestsList;
