import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

import TagPresenter from './TagPresenter';

export default new Presenter(
  {
    title: PropTypes.string,
    profilesCount: PropTypes.number,
    tags: PropTypes.arrayOf(TagPresenter.shape()),
  },
  {},
);
