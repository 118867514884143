/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function bulkDestroyResourcesSlice(options) {
  const { pluralResource, repository } = options;

  return {
    initialState: {
      [pluralResource]: {
        bulkDestroyingStatus: FETCH_STATUSES.idle,
        error: null,
      },
    },
    reducers: {
      bulkDestroyResourceStart(state) {
        state[pluralResource].bulkDestroyingStatus = FETCH_STATUSES.pending;
        state[pluralResource].error = null;
      },
      bulkDestroyResourceSuccess(state) {
        state[pluralResource].bulkDestroyingStatus = FETCH_STATUSES.fulfilled;
      },
      bulkDestroyResourceFail(state, { payload: { error } }) {
        state[pluralResource].bulkDestroyingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
    },
    actionCreators(restDispatch) {
      return {
        bulkDestroyResources: async (...params) => {
          restDispatch('bulkDestroyResourceStart');

          try {
            const { data } = await repository.bulkDestroy(...params);
            restDispatch('bulkDestroyResourceSuccess', data);
          } catch (error) {
            restDispatch('bulkDestroyResourceFail', { error });
            throw error;
          }
        },
      };
    },
    abstractSelector: (state) => ({ pluralResource: state[pluralResource] }),
  };
}
