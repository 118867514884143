import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import { useAdminUsers } from 'hooks/admin';

import CompanyPresenter from 'presenters/CompanyPresenter';

import CheckmarkIcon from 'icons/CheckmarkIcon';
import CrossIcon from 'icons/CrossIcon';

import { Field, AsyncSelect } from 'components/v2/Form';
import Button from 'components/v2/Button';
import Loader from 'components/v2/Loader';

import UserOption from './components/UserOption';

import styles from './EditForm.module.css';

const { Option, SingleValue } = components;

const OptionComponent = (optionProps) => (
  <Option {...optionProps}>
    <UserOption {...optionProps} />
  </Option>
);

const SingleValueComponent = (valueProps) => (
  <SingleValue {...valueProps}>
    <UserOption {...valueProps} />
  </SingleValue>
);

const EditForm = (props) => {
  const { errors, onCancel, onFieldChange, onSubmit, isSubmitting, values } = props;

  const { loadUsers } = useAdminUsers();

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);
  const handleUsersSearch = async (fullNameCont) => {
    const searchParams = buildFilterParams({
      subscriptionStatusEq: 'active',
      stateEq: 'active',
      roleEq: 'admin',
      fullNameCont,
    });
    const { items } = await loadUsers(searchParams);

    return items;
  };

  return (
    <>
      <Field className={styles.field} errors={errors.manager} variant="outlined">
        <AsyncSelect
          components={{
            Option: OptionComponent,
            SingleValue: SingleValueComponent,
          }}
          defaultOptions
          isClearable
          loadOptions={handleUsersSearch}
          onChange={handleChange('manager')}
          value={values.manager}
        />
      </Field>
      {isSubmitting ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <Button disabled={isSubmitting} onClick={onSubmit} variant="icon">
            <CheckmarkIcon width={14} height={14} />
          </Button>
          <Button disabled={isSubmitting} onClick={onCancel} variant="icon">
            <CrossIcon width={14} height={14} />
          </Button>
        </>
      )}
    </>
  );
};

EditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: CompanyPresenter.shape().isRequired,
};

export default EditForm;
