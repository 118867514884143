import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';
import Headline from 'components/grommet/Headline';
import Card from 'components/Card';
import Button from 'components/Button';

import styles from './Limbo.module.css';

class Limbo extends Component {
  static propTypes = {
    onVisibilityChange: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    state: PropTypes.string.isRequired,
  };

  isVisible() {
    return this.props.state === 'visible';
  }

  headerTitle() {
    const state = this.isVisible() ? 'Visible' : 'Hidden';

    return `${state} for customers`;
  }

  handleButtonClick = (stateEvent) => () => {
    this.props.onVisibilityChange({ stateEvent });
  };

  renderButton() {
    const { saving } = this.props;

    if (this.isVisible()) {
      return <Button disabled={saving} label="Hide for customers" primary onClick={this.handleButtonClick('hide')} />;
    }

    return (
      <Button
        className={styles.danger}
        disabled={saving}
        label="Show for customers"
        primary
        onClick={this.handleButtonClick('show')}
      />
    );
  }

  render() {
    return (
      <Box margin={{ bottom: 'medium' }}>
        <Card>
          <Headline strong size="small" margin="none" className={styles.header}>
            {this.headerTitle()}
          </Headline>
          {this.renderButton()}
        </Card>
      </Box>
    );
  }
}

export default Limbo;
