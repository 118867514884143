import { equals, reject, prop } from 'ramda';
import InterestListPageConstants from './InterestListPageConstants';

const initialState = {
  saving: false,
  loading: false,
  interests: [],
  nextPage: 1,
  filters: {
    strIdOrTitleCont: '',
    sortFields: [],
  },
  selectedInterestIds: [],
  totalCount: null,
};

function InterestListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case InterestListPageConstants.INITIALIZE_INTEREST_LIST_SUCCESS: {
      return initialState;
    }
    case InterestListPageConstants.LOAD_INTERESTS_START: {
      return { ...state, loading: true };
    }
    case InterestListPageConstants.LOAD_INTERESTS_SUCCESS: {
      const { nextPage, totalCount } = action;
      const interests = [...state.interests, ...action.interests];

      return { ...state, interests, nextPage, totalCount, loading: false };
    }
    case InterestListPageConstants.FILTER_INTERESTS_START: {
      return {
        ...state,
        interests: [],
        selectedInterestIds: [],
        loading: true,
        nextPage: 1,
        totalCount: null,
        filters: action.filters,
      };
    }
    case InterestListPageConstants.FILTER_INTERESTS_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { interests, nextPage, totalCount } = action;
      return { ...state, interests, nextPage, totalCount, loading: false };
    }
    case InterestListPageConstants.SELECT_INTEREST_SUCCESS: {
      const selectedInterestIds = [...state.selectedInterestIds, action.interestId];

      return { ...state, selectedInterestIds };
    }
    case InterestListPageConstants.UNSELECT_INTEREST_SUCCESS: {
      const selectedInterestIds = reject(equals(action.interestId), state.selectedInterestIds);

      return { ...state, selectedInterestIds };
    }
    case InterestListPageConstants.SELECT_ALL_INTERESTS_SUCCESS: {
      const selectedInterestIds = state.interests.map(prop('id'));

      return { ...state, selectedInterestIds };
    }
    case InterestListPageConstants.UNSELECT_ALL_INTERESTS_SUCCESS: {
      return { ...state, selectedInterestIds: [] };
    }
    case InterestListPageConstants.BULK_DESTROY_INTERESTS_SUCCESS: {
      return { ...state, selectedInterestIds: [], interests: [], nextPage: 1, totalCount: null };
    }
    default:
      return state;
  }
}

export default InterestListPageReducer;
