import React from 'react';
import { Spinner as Spinning } from 'grommet';

import styles from './Loader.module.css';

export default function () {
  return (
    <div className={styles.loaderBox}>
      <Spinning />
    </div>
  );
}
