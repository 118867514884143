import React, { Component } from 'react';
import { DocumentStore, DocumentVerified, DocumentTime } from 'grommet-icons';

import CompanyDashboardPresenter from 'presenters/Company/DashboardPresenter';

import styles from './Counters.module.css';

export default class Counters extends Component {
  static propTypes = {
    dashboard: CompanyDashboardPresenter.shape(),
  };

  render() {
    const { dashboard } = this.props;

    return (
      <div className={styles.profilesInfo}>
        <div className={styles.header}>Custom Profiles</div>
        <div className={styles.counters}>
          <div className={styles.item}>
            <div className={styles.top}>
              <div className={styles.icon}>
                <DocumentStore color="#00a0ce" />
              </div>
              <div className={styles.number}>{CompanyDashboardPresenter.purchasedProfileNumber(dashboard)}</div>
            </div>
            <div className={styles.label}>Purchased</div>
          </div>
          <div className={styles.item}>
            <div className={styles.top}>
              <div className={styles.icon}>
                <DocumentVerified color="#00a0ce" />
              </div>
              <div className={styles.number}>{CompanyDashboardPresenter.deliveredProfileNumber(dashboard)}</div>
            </div>
            <div className={styles.label}>Delivered</div>
          </div>
          <div className={styles.item}>
            <div className={styles.top}>
              <div className={styles.icon}>
                <DocumentTime color="#00a0ce" />
              </div>
              <div className={styles.number}>{CompanyDashboardPresenter.pendingProfileNumber(dashboard)}</div>
            </div>
            <div className={styles.label}>Pending</div>
          </div>
        </div>
      </div>
    );
  }
}
