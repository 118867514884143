import React, { useEffect } from 'react';

import CreationInProgress from './components/CreationInProgress';
import CreationSuccess from './components/CreationSuccess';
import CreationFail from './components/CreationFail';

import styles from './CreateUsersWizardStep.module.css';

const CreateUsersWizardStep = (props) => {
  const { onCreateUsers, usersBulkCreatingStatus, companyId, onPreviousStep, onNextStep, WizardNavigation } = props;

  useEffect(() => {
    onCreateUsers();
  }, []);

  return (
    <div className={styles.root}>
      {usersBulkCreatingStatus.isPending && <CreationInProgress />}
      {usersBulkCreatingStatus.isFulfilled && <CreationSuccess companyId={companyId} />}
      {usersBulkCreatingStatus.isFailed && (
        <CreationFail onPreviousStep={onPreviousStep} onNextStep={onNextStep} WizardNavigation={WizardNavigation} />
      )}
    </div>
  );
};

export default CreateUsersWizardStep;
