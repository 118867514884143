import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter({
  purchasedProfileNumber: PropTypes.number,
  deliveredProfileNumber: PropTypes.number,
  pendingProfileNumber: PropTypes.number,
  period: PropTypes.string,
  activeProjectsCount: PropTypes.number,
  projectsCount: PropTypes.number,
});
