import React, { useEffect } from 'react';

import { useAlert } from 'hooks';
import { useAdminCompanies, useAdminCompanySingleSignOn } from 'hooks/admin';

import SingleSignOnForm from 'forms/company/SingleSignOnForm';

import Form from 'components/v2/Form';
import Spinner from 'components/Spinner';

import EditForm from './components/EditForm';

// import styles from './SingleSignOnSettings.module.css';

const SingleSignOnSettingsTab = () => {
  const alert = useAlert();
  const { company } = useAdminCompanies();
  const { singleSignOnLoadingStatus, loadSingleSignOn, updateSingleSignOn, singleSignOn } =
    useAdminCompanySingleSignOn();

  const handleSingleSignOnUpdate = async (attributes) => {
    await updateSingleSignOn(company.id, SingleSignOnForm.attributesToSubmit(attributes));

    alert.successAction('Single Sign On Settings successfully updated');
  };

  useEffect(() => {
    loadSingleSignOn(company.id);
  }, []);

  if (singleSignOnLoadingStatus.isPending || company.id !== singleSignOn?.companyId) {
    return <Spinner />;
  }

  return (
    <Form
      component={EditForm}
      enableReinitialize
      initialValues={SingleSignOnForm.defaultAttributes(singleSignOn)}
      onSubmit={handleSingleSignOnUpdate}
    />
  );
};

/*
class SingleSignOnSettings extends Component {
  static propTypes = {
    company: CompanyPresenter.shape(),
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  constructor(...args) {
    super(...args);

    const companyForm = CompanyEditForm.defaultAttributes(this.props.company);
    this.state = { form: companyForm.singleSignOnAttributes, errors: null };
  }

  errorsFor = (fieldName) => {
    const { errors } = this.state;

    return pathOr(null, ['singleSignOn', fieldName], errors);
  };

  handleChange = (fieldName, value) => this.setState({ form: { ...this.state.form, [fieldName]: value } });

  handleChangeTextField = (fieldName) => ({ target: { value } }) => this.handleChange(fieldName, value);

  handleChangeCheckbox = (fieldName) => ({ target: { checked } }) => this.handleChange(fieldName, checked);

  handleSubmit = () => {
    const { company, onSubmit } = this.props;
    const { form } = this.state;
    const attributes = CompanyEditForm.attributesToSubmit(
      {
        ...CompanyEditForm.defaultAttributes(company),
        singleSignOnAttributes: form,
      },
      company,
    );

    onSubmit(attributes)
      .then((newCompany) => {
        const newForm = CompanyEditForm.defaultAttributes(newCompany).singleSignOnAttributes;

        this.setState({ form: newForm, errors: null });
      })
      .catch(({ errors }) => this.setState({ errors }));
  };

  render() {
    const { company, saving } = this.props;
    const { form } = this.state;
    const signOnUrl = company.ssoUrl;
    const isSingleSignOnAvailable = isPresent(form.id) && isPresent(form.cert) && isPresent(form.siteUrl);

    return (
      <Box>
        <Heading tag="h4" strong>
          General Settings
        </Heading>
        <Box margin={{ bottom: 'small' }} direction="row">
          <Box margin={{ right: 'large' }} size="medium">
            <VerticalFormField>
              <CheckBox
                toggle
                label="Enable SSO"
                checked={form.isEnabled}
                onChange={this.handleChangeCheckbox('isEnabled')}
              />
            </VerticalFormField>
            <VerticalFormField label="Company Sign On URL:">
              <div className={styles.urlCard}>{signOnUrl}</div>
            </VerticalFormField>
          </Box>
          {form.isEnabled && !isSingleSignOnAvailable && (
            <Box className={styles.infoAlert} size="medium">
              <h4>
                <AlertIcon />
                Single Sign On Is Not Available!
              </h4>
              {isBlank(form.siteUrl) && <p>Identity Provider &quot;Single Sign On Url&quot; is missing!</p>}
              {isBlank(form.cert) && <p>Identity Provider &quot;Certificate&quot; is missing!</p>}
            </Box>
          )}
        </Box>
        <Box margin={{ bottom: 'small' }} direction="row">
          <Box margin={{ right: 'large' }} size="medium">
            <Heading tag="h4" strong>
              Identity Provider Settings
            </Heading>
            <VerticalFormField label="Single Sign On Url:" errors={this.errorsFor('siteUrl')}>
              <TextInput value={form.siteUrl} onDOMChange={this.handleChangeTextField('siteUrl')} />
            </VerticalFormField>
            <VerticalFormField label="Certificate:" errors={this.errorsFor('cert')}>
              <textarea className={styles.textarea} onChange={this.handleChangeTextField('cert')}>
                {form.cert}
              </textarea>
            </VerticalFormField>
          </Box>
          <Box size="medium">
            <Heading tag="h4" strong>
              Service Provider Information
            </Heading>
            <VerticalFormField label="Assertion Consumer Service Url:">
              <div className={styles.urlCard}>{form.assertionConsumerServiceUrl}</div>
            </VerticalFormField>
            <VerticalFormField label="Entity ID:">
              <div className={styles.urlCard}>{form.spEntityId}</div>
            </VerticalFormField>
            <VerticalFormField label="First Name Attribute Name:" errors={this.errorsFor('firstNameAttributeName')}>
              <TextInput
                value={form.firstNameAttributeName}
                onDOMChange={this.handleChangeTextField('firstNameAttributeName')}
              />
            </VerticalFormField>
            <VerticalFormField label="Last Name Attribute Name:" errors={this.errorsFor('lastNameAttributeName')}>
              <TextInput
                value={form.lastNameAttributeName}
                onDOMChange={this.handleChangeTextField('lastNameAttributeName')}
              />
            </VerticalFormField>
            <VerticalFormField label="Email Attribute Name:" errors={this.errorsFor('emailAttributeName')}>
              <TextInput
                value={form.emailAttributeName}
                onDOMChange={this.handleChangeTextField('emailAttributeName')}
              />
            </VerticalFormField>
            <Button
              disabled={isBlank(form.id)}
              href={Routes.downloadSamlMetadataApiV1AdminSingleSignOnPath(form.id)}
              label="Download Metadata"
              secondary
            />
          </Box>
        </Box>
        <Box direction="row" margin={{ bottom: 'medium' }} size="medium">
          <Box margin={{ right: 'small' }}>
            <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
          </Box>
        </Box>
      </Box>
    );
  }
}
*/

export default SingleSignOnSettingsTab;
