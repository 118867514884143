import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Interest from './InterestPage';
import { loadInterest, updateInterest } from './InterestPageActions';

const mapStateToProps = (state, props) => ({
  interest: state.InterestPageReducer.interest,
  loading: state.InterestPageReducer.loading,
  saving: state.InterestPageReducer.saving,
  interestId: props.match.params.id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadInterest,
      updateInterest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Interest);
