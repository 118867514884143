import React from 'react';
import routes from 'routes';

import AppSwitch from 'components/AppSwitch';
import AppRoute from 'components/AppRoute';

import DepartmentPage from './components/DepartmentPage';
import CompanyEditPage from './components/CompanyEditPage';

const CompanyRoutes = () => (
  <AppSwitch>
    <AppRoute
      path={routes.adminCompanyDepartmentPath(':companyId', ':companyDepartmentId')}
      component={DepartmentPage}
    />
    <AppRoute path={routes.adminCompanyPath(':companyId')} component={CompanyEditPage} />
  </AppSwitch>
);

export default CompanyRoutes;
