import config from 'config';
import { isNil } from 'ramda';
import { isBlank } from 'utils/HelperMethods';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

const devAmplitude = {
  init: () => {},
  setUserId: () => {},
  clearUserProperties: () => {},
  logEvent: () => {},
  getInstance: () => ({ setGroup: () => {}, setOptOut: () => {}, setUserProperties: () => {} }),
};

const amplitude = window.amplitude || devAmplitude;

amplitude.init(config.amplitude.MpDhboSukh);

const logEvent = (event, props) => amplitude.logEvent(event, props);

const formatText = (text) => text.toUpperCase().split(' ').join('_');

const formatBool = (value) => {
  if (isBlank(value)) return 'UNDEFINED';

  return value ? 'CHECKED' : 'UNCHECKED';
};

export const setAmplitudeUser = (user) => {
  if (isNil(user)) {
    return;
  }

  const userId = UserPresenter.id(user);
  const role = UserPresenter.role(user);
  const company = UserPresenter.company(user);
  const companyId = CompanyPresenter.id(company);
  const isImpersonating = CurrentUserPresenter.isImpersonating(user);
  const userFullName = UserPresenter.fullName(user);
  const userCreatedAt = UserPresenter.createdAt(user);
  const companyName = CompanyPresenter.name(company);

  amplitude.setUserId(userId);
  amplitude.getInstance().setGroup('companyId', `${companyId}`);
  amplitude.getInstance().setOptOut(isImpersonating);
  amplitude.getInstance().setUserProperties({ role, userFullName, userCreatedAt, companyName });
};

export const clearAmplitudeUser = () => {
  amplitude.setUserId(null);
  amplitude.getInstance().setGroup('companyId', '');
  amplitude.getInstance().setOptOut(true);
  amplitude.clearUserProperties();
};

export const logClickOnRowOfCustomerRequestHistoryTable = () =>
  logEvent('CLICK_ON_ROW_OF_CUSTOMER_REQUEST_HISTORY_TABLE');

export const logSearchInCustomerRequestHistoryTable = () => logEvent('SEARCH_IN_CUSTOMER_REQUEST_HISTORY_TABLE');

export const logShareOfRelationshipMappingDetails = () => logEvent('SHARE_OF_RELATIONSHIP_MAPPING_DETAILS');

export const logClickOnExcludeExecutivesInRelationshipMapping = () =>
  logEvent('CLICK_ON_EXCLUDE_EXECUTIVES_IN_RELATIONSHIP_MAPPING');

export const logClickOnRelationshipMappingAdvertisment = () => logEvent('CLICK_ON_RELATIONSHIP_MAPPING_ADVERTISMENT');

export const logCheckSearchProfilesShowOnlyMyAlerts = () => logEvent('CHECK_SEARCH_PROFILES_SHOW_ONLY_MY_ALERTS');

export const logClickOnMyAccount = () => logEvent('CLICK_ON_MY_ACCOUNT');

export const logLogOut = () => logEvent('LOG_OUT');

export const logLogIn = () => logEvent('LOG_IN');

export const logClickOnUserPanelDashboardQuestionMark = () => logEvent('CLICK_ON_USER_PANEL_DASHBOARD_QUESTION_MARK');

export const logClickOnUserPanelDashboardDatabaseStatisticsAlert = () =>
  logEvent('CLICK_ON_USER_PANEL_DASHBOARD_DATABASE_STATISTICS_ALERT');

export const logClickOnUserPanelDashboardSavedSearch = () => logEvent('CLICK_ON_USER_PANEL_DASHBOARD_SAVED_SEARCH');

export const logSearchUserPanelQuickSearch = () => logEvent('SEARCH_USER_PANEL_QUICK_SEARCH');

export const logClickOnUserPanelSearchProfilesScopeFilter = (filterName) =>
  logEvent(`CLICK_ON_USER_PANEL_SEARCH_PROFILES_SCOPE_FILTER_${formatText(filterName)}`);

export const logChangeUserPanelSearchProfilesFilter = (filterName) =>
  logEvent(`CHANGE_USER_PANEL_SEARCH_PROFILES_FILTER_${formatText(filterName)}`);

export const logClickOnUserPanelSearchProfilesSavedSearchTab = () =>
  logEvent('CLICK_ON_USER_PANEL_SEARCH_PROFILES_SAVED_SEARCH_TAB');

export const logClickOnUserPanelSearchProfilesSaveFilters = () =>
  logEvent('CLICK_ON_USER_PANEL_SEARCH_PROFILES_SAVE_FILTERS');

export const logClickOnUserPanelProfileGetAlerts = () => logEvent('CLICK_ON_USER_PANEL_PROFILE_GET_ALERTS');

export const logToggleUserPanelProfileWhatNew = () => logEvent('TOGGLE_USER_PANEL_PROFILE_WHAT_NEW');

export const logClickOnUserPanelProfileSocial = (social) =>
  logEvent(`CLICK_ON_USER_PANEL_PROFILE_SOCIAL_${social.toUpperCase()}`);

export const logClickOnUserPanelProfileClickHere = () => logEvent('CLICK_ON_USER_PANEL_PROFILE_CLICK_HERE');

export const logClickOnUserPanelProfileDownloadProfile = () => logEvent('CLICK_ON_USER_PANEL_PROFILE_DOWNLOAD_PROFILE');

export const logClickOnUserPanelSearchProfilesDownloadProfile = () =>
  logEvent('CLICK_ON_USER_PANEL_SEARCH_PROFILES_DOWNLOAD_PROFILE');

export const logClickOnAlertsTab = () => logEvent('CLICK_ON_ALERTS_TAB');

export const logClickOnAlertsRemoveFromAlerts = () => logEvent('CLICK_ON_ALERTS_REMOVE_FROM_ALERTS');

export const logClickOnMyProjectsAddProject = () => logEvent('CLICK_ON_MY_PROJECTS_ADD_PROJECT');

export const logClickOnMyProjectsDeleteProject = () => logEvent('CLICK_ON_MY_PROJECTS_DELETE_PROJECT');

export const logClickOnMyProjectsCloneProject = () => logEvent('CLICK_ON_MY_PROJECTS_CLONE_PROJECT');

export const logClickOnMyProjectsAddAlerts = () => logEvent('CLICK_ON_MY_PROJECTS_ADD_ALERTS');

export const logChangeMyProjectsSearchProject = () => logEvent('CHANGE_MY_PROJECTS_SEARCH_PROJECT');

export const logChangeMyProjectsSortProject = () => logEvent('CHANGE_MY_PROJECTS_SORT_PROJECT');

export const logChangeMyProjectsTypeProject = () => logEvent('CHANGE_MY_PROJECTS_TYPE_PROJECT');

export const logClickOnProjectPageActionAudienceInsights = () =>
  logEvent('CLICK_ON_PROJECT_PAGE_ACTION_AUDIENCE_INSIGHTS');

export const logClickOnProjectPageActionBusinessPriorityAnalysis = () =>
  logEvent('CLICK_ON_PROJECT_PAGE_ACTION_BUSINESS_PRIORITY_ANALYSIS');

export const logClickOnProjectPageActionRelationshipMapping = () =>
  logEvent('CLICK_ON_PROJECT_PAGE_ACTION_RELATIONSHIP_MAPPING');

export const logClickOnProjectPageActionCommonsThreads = () => logEvent('CLICK_ON_PROJECT_PAGE_ACTION_COMMONS_THREADS');

export const logClickOnProjectPageAddList = () => logEvent('CLICK_ON_PROJECT_PAGE_ADD_LIST');

export const logClickOnProjectPageEditList = () => logEvent('CLICK_ON_PROJECT_PAGE_EDIT_LIST');

export const logClickOnProjectPageDeleteList = () => logEvent('CLICK_ON_PROJECT_PAGE_DELETE_LIST');

export const logClickOnProjectPageSortBy = () => logEvent('CLICK_ON_PROJECT_PAGE_SORT_BY');

export const logClickOnProjectPageFilter = (filterName) =>
  logEvent(`CLICK_ON_PROJECT_PAGE_SCOPE_FILTER_${formatText(filterName)}`);

export const logClickOnProjectPageProfileDownload = () => logEvent('CLICK_ON_PROJECT_PAGE_PROFILE_DOWNLOAD');

export const logClickOnProjectPageProfileDelete = () => logEvent('CLICK_ON_PROJECT_PAGE_PROFILE_DELETE');

export const logClickOnProjectPageHamburgerAction = (action) =>
  logEvent(`CLICK_ON_PROJECT_PAGE_PROFILE_HAMBURGER_ACTION_${action}`);

export const logClickOnProjectPageShare = () => logEvent('CLICK_ON_PROJECT_PAGE_SHARE');

export const logClickOnProjectPageSendNow = () => logEvent('CLICK_ON_PROJECT_PAGE_SEND_NOW');

export const logClickOnProjectPageShareLiveVersion = () => logEvent('CLICK_ON_PROJECT_PAGE_SHARE_LIVE_VESION');

export const logUncheckProjectPageShareFiles = (name) =>
  logEvent(`UNCHECK_PROJECT_PAGE_SHARE_FILES_${formatText(name)}`);

export const logClickOnAudienceInsightsShare = () => logEvent('CLICK_ON_AUDIENCE_INSIGHTS_SHARE');

export const logClickOnAudienceInsightsTop = (name) => logEvent(`CLICK_ON_AUDIENCE_INSIGHTS_TOP_${name}`);

export const logClickOnAudienceInsightsShareMostConnectedExecutive = () =>
  logEvent('CLICK_ON_AUDIENCE_INSIGHTS_SHARE_MOST_CONNECTED_EXECUTIVE');

export const logClickOnBusinessPriorityAnalysisShare = () => logEvent('CLICK_ON_BUSINESS_PRIORITY_ANALYSIS_SHARE');

export const logClickOnBusinessPriorityAnalysisTab = (tabName) =>
  logEvent('CLICK_ON_BUSINESS_PRIORITY_ANALYSIS_TAB', { tabName });

export const logClickOnBusinessPriorityAnalysisTabDetails = (tabName, title, subTitle = null) =>
  logEvent('CLICK_ON_BUSINESS_PRIORITY_ANALYSIS_TAB_DETAILS', { tabName, title, subTitle });

export const logClickOnBusinessPriorityAnalysisShareDetails = () =>
  logEvent('CLICK_ON_BUSINESS_PRIORITY_ANALYSIS_SHARE_DETAILS');

export const logClickOnRelationshipMappingShare = () => logEvent('CLICK_ON_RELATIONSHIP_MAPPING_SHARE');

export const logClickOnRelationshipMappingTab = (tabName) =>
  logEvent(`CLICK_ON_RELATIONSHIP_MAPPING_TAB_${formatText(tabName)}`);

export const logClickOnRelationshipMappingListShowAllRelationships = () =>
  logEvent('CLICK_ON_RELATIONSHIP_MAPPING_LIST_SHOW_ALL_RELATIONSHIPS');

export const logClickOnRelationshipMappingDetails = () => logEvent('CLICK_ON_RELATIONSHIP_MAPPING_DETAILS');

export const logClickOnCommonThreadsShare = () => logEvent('CLICK_ON_COMMON_THREADS_SHARE');

export const logToggleCommonThreadsFilter = () => logEvent('TOGGLE_COMMON_THREADS_FILTER');

export const logToggleCommonThreadsIncludeMyExecutives = () => logEvent('TOGGLE_COMMON_THREADS_INCLUDE_MY_EXECUTIVES');

export const logClickOnCommonThreadsDetails = () => logEvent('CLICK_ON_COMMON_THREADS_DETAILS');

export const logClickOnCommonThreadsDetailsShare = () => logEvent('CLICK_ON_COMMON_THREADS_DETAILS_SHARE');

export const logClickOnSearchPageTabBoardOfDirectors = () => logEvent('CLICK_ON_SEARCH_PAGE_TAB_BOARD_OF_DIRECTORS');

export const toggleProjectDatabaseSearchIncludeExecutives = (value) =>
  logEvent(`TOGGLE_PROJECT_DATABASE_SEARCH_INCLUDE_EXECUTIVES_${formatBool(value)}`);

export const toggleProjectDatabaseSearchIncludeBoardOfDirectors = (value) =>
  logEvent(`TOGGLE_PROJECT_DATABASE_SEARCH_INCLUDE_BOARD_OF_DIRECTORS_${formatBool(value)}`);

export const toggleProjectDatabaseSearchIncludeBoardOfDirectorsFtse100 = (value) =>
  logEvent(`TOGGLE_PROJECT_DATABASE_SEARCH_INCLUDE_BOARD_OF_DIRECTORS_FTSE100_${formatBool(value)}`);

export const toggleProjectCompaniesSearchIncludeExecutives = (value) =>
  logEvent(`TOGGLE_PROJECT_COMPANIES_SEARCH_INCLUDE_EXECUTIVES_${formatBool(value)}`);

export const toggleProjectCompaniesSearchIncludeBoardOfDirectors = (value) =>
  logEvent(`TOGGLE_PROJECT_COMPANIES_SEARCH_INCLUDE_BOARD_OF_DIRECTORS_${formatBool(value)}`);

export const toggleProjectCompaniesSearchIncludeBoardOfDirectorsFtse100 = (value) =>
  logEvent(`TOGGLE_PROJECT_COMPANIES_SEARCH_INCLUDE_BOARD_OF_DIRECTORS_FTSE100_${formatBool(value)}`);

export const toggleRelationshipMappingFilterExecutives = (value) =>
  logEvent(`TOGGLE_RELATIONSHIP_MAPPING_FILTER_EXECUTIVES_${formatBool(value)}`);

export const toggleRelationshipMappingFilterBoardOfDirectors = (value) =>
  logEvent(`TOGGLE_RELATIONSHIP_MAPPING_FILTER_BOARD_OF_DIRECTORS_${formatBool(value)}`);

export const toggleRelationshipMappingFilterBoardOfDirectorsFtse100 = (value) =>
  logEvent(`TOGGLE_RELATIONSHIP_MAPPING_FILTER_BOARD_OF_DIRECTORS_FTSE100_${formatBool(value)}`);

export const toggleRelationshipMappingFilterMyExecutives = (value) =>
  logEvent(`TOGGLE_RELATIONSHIP_MAPPING_FILTER_MY_EXECUTIVES_${formatBool(value)}`);

export const toggleRelationshipMappingFilterMyBoardMembers = (value) =>
  logEvent(`TOGGLE_RELATIONSHIP_MAPPING_FILTER_MY_BOARD_MEMBERS_${formatBool(value)}`);
