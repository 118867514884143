import React from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';

import RequestStatisticPresenter from 'presenters/EditorialDashboard/Productivity/RequestStatisticPresenter';

import styles from './CustomizedTooltip.module.css';

const CustomizedTooltip = (props) => {
  const { active, payload } = props;

  if (!active || isBlank(payload)) {
    return null;
  }

  const [{ payload: requestStatistics }] = payload;

  return (
    <div className={styles.customTooltip}>
      {isPresent(RequestStatisticPresenter.count(requestStatistics)) && (
        <>
          <strong>Current</strong>
          <div>Date: {RequestStatisticPresenter.textTime(requestStatistics)}</div>
          <div>Requests: {RequestStatisticPresenter.count(requestStatistics)}</div>
        </>
      )}
      {isPresent(RequestStatisticPresenter.previousTextTime(requestStatistics)) && (
        <>
          <strong>Previous</strong>
          <div>Date: {RequestStatisticPresenter.previousTextTime(requestStatistics)}</div>
          <div>Requests: {RequestStatisticPresenter.previousCount(requestStatistics)}</div>
        </>
      )}
    </div>
  );
};

CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(RequestStatisticPresenter.shape()),
};

export default CustomizedTooltip;
