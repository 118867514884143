import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AdminSavedSearches from './AdminSavedSearches';
import * as actions from './AdminSavedSearchesActions';

const mapStateToProps = (state) => ({
  isLoading: state.AdminSavedSearchesReducer.isLoading,
  savedSearches: state.AdminSavedSearchesReducer.savedSearches,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminSavedSearches);
