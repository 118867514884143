import React from 'react';
import PropTypes from 'prop-types';

import InterestPresenter from 'presenters/InterestPresenter';

const ReviewInterestsList = (props) => {
  const { value } = props;

  return (
    <>
      <h5>Personal Interests</h5>
      <ul>
        {value.map(({ id, interest }) => (
          <li key={id}>{InterestPresenter.title(interest)}</li>
        ))}
      </ul>
    </>
  );
};

ReviewInterestsList.propTypes = {
  value: PropTypes.arrayOf(InterestPresenter.shape()).isRequired,
};

export default ReviewInterestsList;
