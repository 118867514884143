import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import CrossIcon from 'icons/CrossIcon';

import Button from 'components/v2/Button';
import { Field, Input } from 'components/v2/Form';

import styles from './EmailSuffixes.module.css';

const EmailSuffixes = (props) => {
  const {
    collectionErrors,
    collectionItems,
    form,
    isItemMarkedForDestruction,
    itemKey,
    name,
    onItemAdd,
    onItemRemove,
  } = props;
  const { onFieldChange } = form;

  const handleChange = (index, fieldName) => (value) => onFieldChange(`${name}[${index}].${fieldName}`, value);
  const handleItemAdd = () => onItemAdd({ suffix: '' });
  const handleItemRemove = (itemIndex) => () => onItemRemove(itemIndex);

  return (
    <div className={styles.emailSuffixes}>
      {collectionItems.map((emailSuffix, index) =>
        isItemMarkedForDestruction(emailSuffix) ? null : (
          <Field key={itemKey(index)} error={path([index, 'suffix'])(collectionErrors)} variant="outlined">
            <Input
              onChange={handleChange(index, `suffix`)}
              suffix={
                <Button onClick={handleItemRemove(index)} variant="icon">
                  <CrossIcon width={10} height={10} />
                </Button>
              }
              value={emailSuffix.suffix}
              placeholder="Email domain (Example: boardroominsiders.com)"
            />
          </Field>
        ),
      )}
      <Button className={styles.addButton} onClick={handleItemAdd} variant="link">
        Add Email domain
      </Button>
    </div>
  );
};

EmailSuffixes.propTypes = {
  collectionErrors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  collectionItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  form: PropTypes.shape({ onFieldChange: PropTypes.func.isRequired }).isRequired,
  isItemMarkedForDestruction: PropTypes.func.isRequired,
  itemKey: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onItemAdd: PropTypes.func.isRequired,
  onItemRemove: PropTypes.func.isRequired,
};

export default EmailSuffixes;
