import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes/App';
import { isNil } from 'ramda';

import Table from 'components/Table';
import ProfileRowCells from './components/ProfileRowCells';

import ProfilePresenter from 'presenters/ProfilePresenter';

const TABLE_COLUMNS = [{ name: 'Name' }, { name: 'Organization' }, { name: 'Title' }];

const ProfileList = (props) => {
  const { profiles, totalCount, loading, nextPage, onDelete, onLoad } = props;
  const hasMore = !isNil(nextPage);

  const handleProfileClick = (profileId) => window.open(Routes.editAdminProfilePath(profileId));

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      onLoad(nextPage);
    }
  };

  return (
    <Table
      columns={TABLE_COLUMNS}
      canDeleteSelectedRows={() => true}
      onDelete={onDelete}
      onMore={handleLoadMore}
      onRowClick={handleProfileClick}
      onSort={() => {}}
      hasMore={hasMore}
      rows={profiles}
      rowCellsComponent={<ProfileRowCells columns={TABLE_COLUMNS} />}
      totalRows={totalCount}
      rowsDeletable
    />
  );
};

ProfileList.propTypes = {
  profiles: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  nextPage: PropTypes.number,
  onDelete: PropTypes.func,
  onLoad: PropTypes.func,
};

export default ProfileList;
