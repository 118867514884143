import { isPresent } from 'utils/HelperMethods';

const CLASS_NAME = 'background';

function mount(nodeGroup, props) {
  nodeGroup
    .append('circle')
    .attr('class', CLASS_NAME)
    .attr('r', props.node.avatarRadius)
    .attr('fill', (d) => (isPresent(d.photoUrl) ? '#D8D8D8' : 'white'));
}

export default { mount };
