import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';

import WorkPresenter from 'presenters/WorkSearchPresenter';

import styles from './CurrentWork.module.css';

const CurrentWork = (props) => {
  const { work, isMasked } = props;

  const workClassNames = cn(styles.work, { [styles.workMasked]: isMasked });

  return (
    <div key={WorkPresenter.id(work)} className={workClassNames}>
      <div className={styles.position}>
        <strong>{WorkPresenter.jobTitle(work)}</strong>
      </div>
      <div className={styles.company}>
        <strong>{WorkPresenter.organizationName(work)}</strong>
      </div>
    </div>
  );
};

CurrentWork.propTypes = {
  work: WorkPresenter.shape(),
  isMasked: PropTypes.bool.isRequired,
};

export default CurrentWork;
