import TargetingSearchFormConstants from './TargetingSearchFormConstants';

const initialState = {
  loading: true,
  profiles: [],
  meta: {},
};

function TargetingSearchFormReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TargetingSearchFormConstants.LOAD_TARGETED_PROFILES_START: {
      return { ...state, loading: true };
    }
    case TargetingSearchFormConstants.LOAD_TARGETED_PROFILES_SUCCESS: {
      const { profiles, meta } = action;

      return { ...state, loading: false, profiles, meta };
    }
    default:
      return state;
  }
}

export default TargetingSearchFormReducer;
