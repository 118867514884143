function getStartSelectionCoord() {
  const { length, ...elements } = window.getSelection().getRangeAt(0).getClientRects();

  const lastElement = elements[length - 1];

  return lastElement;
}

export function getSelectionCoord() {
  const { x, y, height, width } = getStartSelectionCoord();

  const left = width > x ? width - x : x - width;
  const top = y - height;

  return { left, top };
}

export function setSelectionEmpty() {
  document.getSelection().empty();
}

export function getSelectedText() {
  return document.getSelection().toString();
}
