import Routes from 'routes';
import { REQUEST_ENTITY_TOO_LARGE_ERROR_CODE } from 'utils/ErrorsHelper';
import { isPresent } from 'utils/HelperMethods';
import { isProfilePath } from 'utils/UrlHelper';

import { setAccessDeniedPageContent } from 'containers/UserPanel/components/AccessDeniedPage';

function getForbiddenPath(reason) {
  switch (reason) {
    case 'user_deactivated':
      return Routes.notAnActiveUserErrorPath();
    case 'company_deactivated':
      return Routes.companySubscriptionExpiredErrorPath();
    case 'run_out_of_profile_views_credits':
      return Routes.runOutOfCreditsPath('views');
    case 'run_out_of_profile_download_credits':
      return Routes.runOutOfCreditsPath('downloads');
    default:
      return Routes.errorPath(403);
  }
}

function processError(response, { reason }) {
  const isProfilePage = isProfilePath(window.location.pathname);

  let navigateToPath = null;

  if (response.status === REQUEST_ENTITY_TOO_LARGE_ERROR_CODE) {
    throw response;
  }

  if ([404, 414, 500, 502, 503].includes(response.status)) {
    navigateToPath = Routes.errorPath(response.status);
  }

  if (response.status === 403 || response.status === 401) {
    navigateToPath = getForbiddenPath(reason);
  }

  const isGuest = !window.currentUserId;
  if (isProfilePage && isGuest) {
    navigateToPath = Routes.signInPath({ redirectToPath: window.location.pathname });
  }

  if (isPresent(navigateToPath) && window.location.pathname !== navigateToPath) {
    window.location = navigateToPath;
  }
}

const isWAFError = (response) => response.status === 403 && response.headers.get('Content-Type')?.match(/^text\/html/);

const processWAFError = (_response, html) => {
  const navigateToPath = Routes.wafAccessDenied();

  if (window.location.pathname !== navigateToPath) {
    setAccessDeniedPageContent(unescape(html));
    window.location = navigateToPath;
  }
};

function processAsyncError(error) {
  return new Promise((onSuccess, onError) => {
    if (error instanceof Response) {
      if (isWAFError(error)) {
        error
          .text()
          .then((html) => processWAFError(error, html))
          .catch((notProcessedError) => onError(notProcessedError));
      } else {
        error
          .json()
          .catch(() => ({}))
          .then((json) => processError(error, json))
          .catch((notProcessedError) => onError(notProcessedError));
      }
    } else {
      onError(error);
    }
  });
}

export default () => (next) => (action) => {
  if (typeof action === 'function') {
    return next(action).catch(processAsyncError);
  }

  if (isPresent(action?.payload?.error)) {
    processAsyncError(action?.payload?.error);
  }

  return next(action);
};
