import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dashboard from './CustomerUsageDashboard';
import * as actions from './CustomerUsageDashboardActions';
import { actions as projectsActions } from './containers/CustomerUsageDashboardProjects'; // eslint-disable-line import/named

const mapStateToProps = (state) => ({
  loading: state.CustomerUsageDashboardReducer.loading,
  dashboard: state.CustomerUsageDashboardReducer.dashboard,
  form: state.CustomerUsageDashboardReducer.form,
  publicApiStatisticsLoading: state.CustomerUsageDashboardReducer.publicApiStatisticsLoading,
  publicApiStatistics: state.CustomerUsageDashboardReducer.publicApiStatistics,
  searchProfilesStatisticsLoading: state.CustomerUsageDashboardReducer.searchProfilesStatisticsLoading,
  searchProfilesStatistics: state.CustomerUsageDashboardReducer.searchProfilesStatistics,
  signInStatisticsLoading: state.CustomerUsageDashboardReducer.signInStatisticsLoading,
  signInStatistics: state.CustomerUsageDashboardReducer.signInStatistics,
  viewsAndDownloadsStatisticsLoading: state.CustomerUsageDashboardReducer.viewsAndDownloadsStatisticsLoading,
  viewsAndDownloadsStatistics: state.CustomerUsageDashboardReducer.viewsAndDownloadsStatistics,
  popularOrganizations: state.CustomerUsageDashboardReducer.popularOrganizations,
  popularOrganizationsLoading: state.CustomerUsageDashboardReducer.popularOrganizationsLoading,
  powerUsers: state.CustomerUsageDashboardReducer.powerUsers,
  powerUsersLoading: state.CustomerUsageDashboardReducer.powerUsersLoading,
  projectsLoading: state.CustomerUsageDashboardProjectsReducer.loading,
  projects: state.CustomerUsageDashboardProjectsReducer.projects,
  projectsNextPage: state.CustomerUsageDashboardProjectsReducer.nextPage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actions,
      loadDashboard: (params) => actions.loadDashboard(params),
      loadProjects: (params) => projectsActions.loadProjects(params),
      filterProjects: (params) => projectsActions.filterProjects(params),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
