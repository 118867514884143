import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { isBlank } from 'utils/HelperMethods';

import Spinner from 'components/Spinner';

import AgingStatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/AgingStatisticsPresenter';
import StatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/AgingStatistics/StatisticsPresenter';

import styles from './AgingStatistics.module.css';

export default class AgingStatistics extends Component {
  static propTypes = {
    agingStatistics: AgingStatisticsPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    loadAgingStatistics: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadAgingStatistics } = this.props;

    loadAgingStatistics();
  }

  renderStatistics() {
    const { agingStatistics } = this.props;

    const editorialAgingStatistics = AgingStatisticsPresenter.editorialAgingStatistics(agingStatistics);
    const customerAgingStatistics = AgingStatisticsPresenter.customerAgingStatistics(agingStatistics);

    return (
      <>
        <div className={styles.item}>
          <div className={styles.title}>Less than 6 Months:</div>
          <div className={cx(styles.number, styles.subheader)}>
            <span className={styles.label}>Editorial</span>
            {StatisticsPresenter.formattedLessThanSixMonths(editorialAgingStatistics)}
          </div>
          <div className={cx(styles.number, styles.subheader)}>
            <span className={styles.label}>Customer</span>
            {StatisticsPresenter.formattedLessThanSixMonths(customerAgingStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>6 Months To 1 Year:</div>
          <div className={styles.number}>
            {StatisticsPresenter.formattedBetweenSixMonthsAndYear(editorialAgingStatistics)}
          </div>
          <div className={styles.number}>
            {StatisticsPresenter.formattedBetweenSixMonthsAndYear(customerAgingStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Over 1 Year:</div>
          <div className={styles.number}>{StatisticsPresenter.formattedOverOneYear(editorialAgingStatistics)}</div>
          <div className={styles.number}>{StatisticsPresenter.formattedOverOneYear(customerAgingStatistics)}</div>
        </div>
      </>
    );
  }

  render() {
    const { agingStatistics, loading } = this.props;

    return (
      <div>
        <h3 className={styles.header}>Aging</h3>
        {loading || isBlank(agingStatistics) ? <Spinner /> : this.renderStatistics()}
      </div>
    );
  }
}
