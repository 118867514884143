import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';

import ProfilePresenter from 'presenters/ProfileSearchPresenter';
import WorkPresenter from 'presenters/WorkSearchPresenter';
import ContextPresenter from 'presenters/Relationship/ContextPresenter';
import WordPresenter from 'presenters/Project/WordPresenter';

import { Anchor } from 'grommet';
import ProfileAvatar from 'components/ProfileAvatar';
import Fortune500BoardMemberBadge from 'components/client/Fortune500BoardMemberBadge';
import Ftse100BoardMemberBadge from 'components/client/Ftse100BoardMemberBadge';
import NewToRoleBadge from 'components/client/NewToRoleBadge';

import Contexts from './components/Contexts';

import styles from './ProfileWithContexts.module.css';

const ProfileWithContexts = (props) => {
  const { contexts, word, profile } = props;

  return (
    <div className={styles.root}>
      <Anchor
        className={styles.link}
        key={ProfilePresenter.id(profile)}
        href={Routes.profilePath(ProfilePresenter.slug(profile))}
        target="_blank"
      >
        <div className={styles.item}>
          <ProfileAvatar size="60" src={ProfilePresenter.photoUrl(profile)} />
          <div className={styles.info}>
            <div className={styles.header}>
              <div className={styles.name}>{ProfilePresenter.fullName(profile)}</div>
            </div>

            {ProfilePresenter.currentWorks(profile).map((currentWork) => (
              <div key={WorkPresenter.id(currentWork)} className={styles.work}>
                {WorkPresenter.organizationName(currentWork)}
                {`, ${WorkPresenter.jobTitle(currentWork)}`}
              </div>
            ))}

            <div className={styles.profileLabels}>
              {ProfilePresenter.isNewToRole(profile) && <NewToRoleBadge />}
              {ProfilePresenter.isMemberFortune500(profile) && <Fortune500BoardMemberBadge />}
              {ProfilePresenter.isMemberFtse100(profile) && <Ftse100BoardMemberBadge />}
            </div>
          </div>
        </div>
      </Anchor>

      <Contexts contexts={contexts} word={word} />
    </div>
  );
};

ProfileWithContexts.propTypes = {
  profile: ProfilePresenter.shape().isRequired,
  contexts: PropTypes.arrayOf(ContextPresenter.shape()).isRequired,
  word: WordPresenter.shape().isRequired,
};

export default ProfileWithContexts;
