import ProfileListPageConstants from './ProfileListPageConstants';

const initialState = {
  saving: false,
  filters: {
    searchFieldCont: '',
    sortFields: [],
  },
};

function ProfileListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileListPageConstants.INITIALIZE_PROFILE_LIST_PAGE: {
      const { filters } = action;

      return { ...state, filters };
    }
    case ProfileListPageConstants.PROFILE_CREATE: {
      return { ...state, saving: true };
    }
    case ProfileListPageConstants.PROFILE_CREATE_SUCCESS: {
      return { ...state, saving: false };
    }
    case ProfileListPageConstants.PROFILES_CHANGE_FILTERS: {
      const { filters } = action;

      return { ...state, filters };
    }
    default:
      return state;
  }
}

export default ProfileListPageReducer;
