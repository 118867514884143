import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { isEmpty, isNil } from 'ramda';
import { FETCH_STATUSES, useFetchStatus } from 'utils/fetchStatusUtils';

import FailStatus from './components/FailStatus';
import IdleStatus from './components/IdleStatus';
import SuccessStatus from './components/SuccessStatus';
import ValidatingStatus from './components/ValidatingStatus';

import styles from './FileStatus.module.css';

const FileStatus = (props) => {
  const { className, errors, file, uploadMessage, successIcon, successMessage, validationStatus } = props;

  const fileStatusClassName = clsx(styles.fileStatus, className, {
    [styles.invalid]: !validationStatus.isPending && !isEmpty(errors),
  });

  if (isNil(file)) {
    return (
      <div className={fileStatusClassName}>
        <IdleStatus message={uploadMessage} />
      </div>
    );
  }

  if (validationStatus.isPending) {
    return (
      <div className={fileStatusClassName}>
        <ValidatingStatus />
      </div>
    );
  }

  if (!isEmpty(errors)) {
    return (
      <div className={fileStatusClassName}>
        <FailStatus errors={errors} />
      </div>
    );
  }

  return (
    <div className={fileStatusClassName}>
      <SuccessStatus icon={successIcon} message={successMessage} />
    </div>
  );
};

FileStatus.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  file: PropTypes.shape(),
  successIcon: PropTypes.node,
  successMessage: PropTypes.string,
  uploadMessage: PropTypes.string,
  validationStatus: PropTypes.shape(),
};

FileStatus.defaultProps = {
  className: null,
  errors: [],
  successIcon: null,
  successMessage: 'File successfully uploaded',
  uploadMessage: 'Drag & drop file here',
  validationStatus: useFetchStatus(FETCH_STATUSES.idle),
};

export default FileStatus;
