/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import { extractResourceData } from './utils';

export default function loadResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        loadingStatus: FETCH_STATUSES.idle,
        item: null,
        error: null,
      },
    },

    reducers: {
      loadResourceStart(state) {
        state[resource].loadingStatus = FETCH_STATUSES.pending;
        state[resource].item = null;
        state[resource].error = null;
      },
      loadResourceSuccess(state, { payload }) {
        const item = extractResourceData(payload);
        state[resource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[resource].item = item;
      },
      loadResourceFail(state, { payload: { error } }) {
        state[resource].loadingStatus = FETCH_STATUSES.failed;
        state[resource].error = error;
      },
    },

    actionCreators(restDispatch) {
      return {
        loadResource: async (...params) => {
          restDispatch('loadResourceStart');

          try {
            const data = await repository.show(...params);
            restDispatch('loadResourceSuccess', data);

            return data;
          } catch (error) {
            restDispatch('loadResourceFail', { error });
            throw error;
          }
        },
      };
    },
  };
}
