import keyMirror from 'keymirror';

const ProfileRelationshipMappingAdvertisementConstants = keyMirror({
  LOAD_PROFILE_RELATIONSHIPS_START: null,
  LOAD_PROFILE_RELATIONSHIPS_SUCCESS: null,
  LOAD_PROFILE_RELATIONSHIPS_ERROR: null,

  CLEAR_PROFILE_RELATIONSHIPS: null,
});

export default ProfileRelationshipMappingAdvertisementConstants;
