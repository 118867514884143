import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LanguagePresenter from 'presenters/LanguagePresenter.js';

class LanguageRowCells extends Component {
  static propTypes = {
    row: PropTypes.shape(),
  };

  render() {
    const { row: language } = this.props;

    return (
      <>
        <td>{LanguagePresenter.id(language)}</td>
        <td>{LanguagePresenter.name(language)}</td>
        <td>{LanguagePresenter.nativeName(language)}</td>
        <td>{LanguagePresenter.alpha2(language)}</td>
      </>
    );
  }
}

export default LanguageRowCells;
