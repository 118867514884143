import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SampleProfilesTab from './SampleProfilesTab';
import * as actions from './SampleProfilesActions';

const mapStateToProps = (state) => ({
  loading: state.SampleProfilesReducer.loading,
  savingSampleProfileIds: state.SampleProfilesReducer.savingSampleProfileIds,
  sampleProfiles: state.SampleProfilesReducer.sampleProfiles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SampleProfilesTab);
