import { equals } from 'ramda';
import SharesContainerConstants from './SharesContainerConstants';

const initialState = {
  isLoading: true,
  isSaving: false,
  isDestroying: false,
  shares: [],
  filters: {},
  nextPage: 1,
};

function SharesContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SharesContainerConstants.FILTER_PROJECT_SHARES_START: {
      const { filters } = action;

      return { ...state, shares: [], isLoading: true, filters, nextPage: 1 };
    }
    case SharesContainerConstants.FILTER_PROJECT_SHARES_SUCCESS: {
      const { shares, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      return { ...state, shares, isLoading: false, ...meta };
    }
    case SharesContainerConstants.LOAD_PROJECT_SHARES_START: {
      return { ...state, isLoading: true };
    }
    case SharesContainerConstants.LOAD_PROJECT_SHARES_SUCCESS: {
      const { shares, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      return { ...state, shares: [...state.shares, ...shares], isLoading: false, meta };
    }
    case SharesContainerConstants.BATCH_UPDATE_PROJECT_SHARES_START: {
      return { ...state, isSaving: true };
    }
    case SharesContainerConstants.BATCH_UPDATE_PROJECT_SHARES_SUCCESS:
    case SharesContainerConstants.BATCH_UPDATE_PROJECT_SHARES_ERROR: {
      return { ...state, isSaving: false };
    }
    case SharesContainerConstants.DESTROY_PROJECT_SOURCE_START: {
      return { ...state, isDestroying: true };
    }
    case SharesContainerConstants.DESTROY_PROJECT_SOURCE_SUCCESS:
    case SharesContainerConstants.DESTROY_PROJECT_SHARE_ERROR: {
      return { ...state, isDestroying: false };
    }
    default:
      return state;
  }
}

export default SharesContainerReducer;
