import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import CompanyDepartmentsStatisticsRepository from 'repositories/admin/Companies/DepartmentsStatisticsRepository';

const restSlice = createRestSlice({
  resource: 'companyDepartmentsStatistic',
  repository: CompanyDepartmentsStatisticsRepository,
  slices: ['loadCompanyDepartmentsStatistic'],
});

const initialState = {
  ...restSlice.initialState,
};

const departmentsStatisticsSlice = createSlice({
  name: 'adminCompanyDepartmentsStatistics',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminCompanyDepartmentsStatisticsReducer = departmentsStatisticsSlice.reducer;

export default departmentsStatisticsSlice;

export const adminCompanyDepartmentsRestHooks = restSlice.hooks(
  departmentsStatisticsSlice,
  'adminCompanyDepartmentsStatisticsReducer',
);
