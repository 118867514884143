import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './ScrollableAnchor.module.css';

class ScrollableAnchor extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    offset: PropTypes.number,
  };

  static defaultProps = {
    offset: 0,
  };

  render() {
    const { children, id, offset } = this.props;
    const style = { transform: `translateY(${-offset}px)` };

    return (
      <>
        <div className={styles.anchor} id={id} style={style} />
        {children}
      </>
    );
  }
}

export default ScrollableAnchor;
