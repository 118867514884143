import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewProfilesStatistics from './NewProfilesStatistics';
import * as actions from './NewProfilesStatisticsActions';

const mapStateToProps = (state) => ({
  loading: state.NewProfilesStatisticsReducer.loading,
  newProfilesStatistics: state.NewProfilesStatisticsReducer.newProfilesStatistics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewProfilesStatistics);
