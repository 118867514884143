import React from 'react';
import styles from './CurrentFocusStyleGuide.module.css';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Summarize the executive’s role and responsibilities; the company’s current strategy and key initiatives overall;
        as well as those that are relevant to the function of the executive you are profiling (e.g., marketing, sales,
        IT).
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <div className={styles.indent}>
        <p>
          <strong>Current Role and Responsibilities</strong>
        </p>
        <p>
          As CFO, Barry is &quot;responsible for overseeing all aspects of global finance, strategic planning and
          corporate
          <br /> development, as well as information technology, information security, audit, procurement and pricing
          function.&quot;
        </p>
        <p>
          <strong>On Omnichannel</strong>
        </p>
        <p>
          Barry said in May 2018, &quot;our data shows us, we literally do not have a customer who only shops us in one
          channel. I mean, there might be one, but on the whole, we don&#39;t have customers that only shop us in one
          channel...our customers are in multiple channels, I mean the fact that we continue to see material increases
          in in-store pickups, even with all of the fulfillment options that are available to customers just as to me,
          people want to play amongst the channels and get things and see things and touch them in the way that they
          want, but I don&#39;t want to imply I think the whole world is shifting, but I think it just continues to
          emphasize our point of view that perfectly isolating these channels separately and talking about their growth
          individually is exceptionally hard to do.&quot;
        </p>
        <p>
          <strong>Supply Chain Transformation</strong>
        </p>
        <p>
          In discussing the company&#39;s upcoming initiatives with analysts in May 2018, CEO Hubert Joly said,
          &quot;We&#39;ve...begun a multi-year transformation of our supply chain designed to expand our bandwidth for
          growth and speed. We invest into significantly increased automation, deals more local distribution
          capabilities for online orders and expand the space and improve the customer experience while growing sales of
          large products that must be delivered.&quot; He went on: &quot;To help offset our investments and pressures in
          the business, we continue our long-standing diligence on increasing productivity and decreasing cost. Our
          current productivity target established in Q2 of fiscal 2018 is $600 million in additional annualized cost
          reductions and gross profits optimization to be completed by the end of fiscal 2021. During the first quarter,
          we achieved approximately $70 million in annualized reductions bringing the cumulative total to $305 million
          towards our goal.&quot;
        </p>
        <p>
          <strong>2020 Strategy</strong>
        </p>
        <p>
          “Now looking ahead, our focus is on the continued implementation of our Best Buy 2020 strategy,” CEO Hubert
          Joly told analysts on the company’s March 2018 earnings call. “We continue to believe we have a material
          opportunity ahead of us to grow the company. We also know there continues to be real pressures from areas like
          wages, supply chain and channel shift. In that context, our mindset has not changed. We are playing to win
          and, of course, the strategy we outlined in September has not changed. The purpose of our Best Buy 2020
          strategy is to enrich lives through technology. As we outlined last September, we plan to fulfill our purpose
          and grow the company by expanding what we sell and evolving how we sell and building the key enablers that are
          needed, while continuing to take cost out.”
        </p>
        <p>
          <strong>Fiscal 2019 Priorities</strong>
        </p>
        <p>
          “Let me highlight a few of our fiscal 2019 priorities,” CEO Hubert Joly told analysts on the company’s March
          2018 earnings call.
        </p>
        <ul>
          <li>
            “First, in fiscal 2019, we will continue to ramp up our In-Home Advisor program as we add advisors and
            improve the related tools and systems that help them do their jobs.
          </li>
          <li>
            Second, we are planning a nationwide launch of our Total Tech Support program this coming spring. As a
            reminder, Total Tech Support is a new Geek Squad offering we began testing last year. We provide support for
            all of the customers&#39; technology, no matter where and when they bought it. We believe that customer
            support needs are often not limited to a specific product. The need now is to have all of their technology
            work together. This support is available to customers 24/7 online, in-store and on the phone. The price of
            this service is $199 per year and includes discounts on other services like in-home services and extended
            warranty programs.
          </li>
          <li>
            Third, we will continue to develop the proficiency of our associates and their ability to deeply understand
            and address customer needs.
          </li>
          <li>
            Fourth, we will continue to streamline the online buying process for our customers. Online shoppers need to
            be able to seamlessly navigate our site and quickly and intelligently narrow their product or service
            selection. For example, we will be further enhancing the search functionality with natural language
            capability, so customers can find what they need by simply typing in phrases like best laptop for students,
            quiet dishwasher or sturdy camera.
          </li>
          <li>
            Fifth, from the multi-channel standpoint, building on our success with in-store pickup, we continue to test
            new and convenient ways for customers to get their orders. For example, we are piloting a new feature on our
            mobile app that allows customers to tell us when they are on their way to pick up their large TV or major
            appliance, so we can have it ready for them when they arrive and they won&#39;t have to wait at the store
            pickup counter for an associate to bring the product from the backroom. And we are also testing lockers and
            curbside pickup solutions. In addition, we will continue to explore and invest in a range of digital
            solutions such as voice assistance across a variety of customer touch points as well as personalization
            initiatives.”
          </li>
        </ul>
      </div>
      <p>
        <strong>Important Note: </strong>
        Some of the above items will live at the “Company” level in our new system and thus will automatically be pulled
        into the profiles. For example, if you read a new earnings call and write up a new “Company Strategy” item (such
        as the last two items above: “2020 Strategy” and “Fiscal 2019 Priorities”), it should live at the company level
        and you should specify that it gets pulled into ALL profiles for that company.
      </p>
      <p>
        <strong>Current Focus &amp; Key Challenges Style Guidelines</strong>
      </p>
      <ul>
        <li>
          In the top field write a headline for the item. It should be brief and all words should be capitalized except
          for prepositions and articles as in the example above. Avoid using vague titles such as “CEO’s Perspective.”
        </li>
        <li>
          You may use bullets (as in the “four priorities” example above.) but do not use paragraph breaks in these
          items.
        </li>
        <li>
          Use last name only when talking about the person (unless you are working at the company record level, in which
          case you need to use the person’s full name and title, since that item will be pulled into other executives’
          profiles). When referencing the words of another executive (e.g., the CEO speaking on an earnings call),
          always include their full name and title, so the reader knows who you are talking about.
        </li>
        <ul>
          <li>e.g., “In April 2018 CEO Hubert Joly said...”</li>
        </ul>
        <li>Make sure every item has a date referenced somewhere so we can determine the date of the item.</li>
        <li>Use “in their own words” whenever possible, from interviews. Cite sources in text and link to them.</li>
        <ul>
          <li>
            e.g., Barry is going to be investing in automation technologies, she told the New York Times in April
            2018...
          </li>
        </ul>
        <li>
          Avoid anything too timely or anything that is written in a way that the timeliness cannot be determined; the
          goal is for this section to be relevant for at least 6 to 12 months.
        </li>
        <ul>
          <li>e.g., Do not write, “Next month the company will roll out a promotion for the holidays...”</li>
          <li>e.g., Do not include financial results from the previous quarter, etc.</li>
        </ul>
        <li>If quoting another executive in the profile, confirm that they are still in the role mentioned.</li>
        <li>
          Avoid beginning bullet points with items that refer to other bullet points such as “He also...” The order of
          the focus items may change over time and the wording may not make sense.
        </li>
        <li>Avoid anything controversial and sensational unless it relevant and well-documented</li>
        <ul>
          <li>
            e.g., we don’t need to know about a sexual harassment scandal or a discrimination lawsuit unless it is
            broadly impacting the company&#39;s reputation or performance, or unless it resulted in the firing of a very
            high-powered exec—or the exec who is being profiled.
          </li>
        </ul>
        <li>
          Avoid being too “wordy.” If the scroll bar shows up when you are typing your item, it is probably too long.
          (Exception - creating a bulleted list with brief bulleted items)
        </li>
        <li>
          Avoid copying entire articles. Find the sections applicable to the executive about whom you are writing and
          include only the relevant sections (with proper source credit)
        </li>
      </ul>
      <p>
        <strong>Sources and Search Tips for This Section</strong>
      </p>
      <ul>
        <li>
          Reading the latest earnings call is a must. These are usually accessible from the profile and company records.
          Search the call transcript for key words that are relevant to the executive you are profiling. E.g., if the
          executive is a CMO, search the transcript for “marketing” “digital” and “advertising.”
        </li>
        <li>As always search for the name of the person, the company name and the current year.</li>
        <li>
          Search for the company name alongside key words from the person’s title. Add the year to your search weed out
          old content. E.g. “Johnson & Johnson” “supply chain” 2019
        </li>
      </ul>
    </div>
  );
}
