import React from 'react';
import { pipe, map, uniq } from 'ramda';

import CompanyPresenter from 'presenters/CompanyPresenter';
import UserPresenter from 'presenters/UserPresenter';
import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';

const CompanyRowCells = (props) => {
  const { row: company } = props;
  const manager = CompanyPresenter.manager(company);
  const departments = CompanyPresenter.departments(company);
  const departmentAccountTypes = pipe(map(DepartmentPresenter.humanAccoutType), uniq)(departments);

  return (
    <>
      <td>{CompanyPresenter.id(company)}</td>
      <td>{CompanyPresenter.name(company)}</td>
      <td>{CompanyPresenter.humanStateName(company)}</td>
      <td>{CompanyPresenter.formattedCreatedAt(company)}</td>
      <td>{CompanyPresenter.formattedSubscriptionExpireAt(company)}</td>
      <td>{UserPresenter.fullName(manager)}</td>
      <td>{CompanyPresenter.seatsNumber(company)}</td>
      <td>{CompanyPresenter.activeSeatsNumber(company)}</td>
      {/* <td>{CompanyPresenter.viewsAndDownloadsNumber(company)}</td> */}
      {/* <td>{CompanyPresenter.purchasedRequestsNumber(company)}</td> */}
      {/* <td>{CompanyPresenter.requestedRequestsNumber(company)}</td> */}
      {/* <td>{CompanyPresenter.pendingRequestsNumber(company)}</td> */}
      {/* <td>{CompanyPresenter.deliveredRequestsNumber(company)}</td> */}
      {/* <td>{CompanyPresenter.requestsNumber(company)}</td> */}
      <td>
        {departmentAccountTypes.map((department) => (
          <div key={department}>{department}</div>
        ))}
      </td>
    </>
  );
};

CompanyRowCells.propTypes = {
  row: CompanyPresenter.shape(),
};

export default CompanyRowCells;
