import { equals, propEq } from 'ramda';
import u from 'updeep';
import TagListPageConstants from './TagListPageConstants';

const initialState = {
  tags: [],
  nextPage: 1,
  totalCount: null,
  loading: false,
  populatingTagId: null,
  bulkPopulatingTags: false,
  filters: {
    sortFields: [],
  },
};

function TagListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TagListPageConstants.INITIALIZE_TAGS_LIST_SUCCESS: {
      return initialState;
    }
    case TagListPageConstants.LOAD_TAGS_START: {
      return { ...state, loading: true };
    }
    case TagListPageConstants.LOAD_TAGS_SUCCESS: {
      const { nextPage, totalCount } = action;
      const tags = [...state.tags, ...action.tags];

      return { ...state, tags, nextPage, totalCount, loading: false };
    }
    case TagListPageConstants.BULK_DESTROY_TAGS_SUCCESS: {
      return { ...state, tags: [], nextPage: 1, totalCount: null };
    }
    case TagListPageConstants.FILTER_TAGS_START: {
      return {
        ...state,
        tags: [],
        loading: true,
        nextPage: 1,
        totalCount: null,
        filters: action.filters,
      };
    }
    case TagListPageConstants.FILTER_TAGS_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { tags, nextPage, totalCount } = action;
      return { ...state, tags, nextPage, totalCount, loading: false };
    }
    case TagListPageConstants.POPULATE_TAG_START: {
      const { tagId } = action;

      return { ...state, populatingTagId: tagId };
    }
    case TagListPageConstants.POPULATE_TAG_SUCCESS: {
      const { tag } = action;
      const tags = u.map(u.if(propEq(tag.id, 'id'), tag), state.tags);

      return { ...state, tags, populatingTagId: null };
    }
    case TagListPageConstants.POPULATE_TAG_ERROR: {
      return { ...state, populatingTagId: null };
    }
    case TagListPageConstants.BULK_POPULATE_TAGS_START: {
      return { ...state, bulkPopulatingTags: true };
    }
    case TagListPageConstants.BULK_POPULATE_TAGS_SUCCESS: {
      return { ...state, bulkPopulatingTags: false };
    }
    default:
      return state;
  }
}

export default TagListPageReducer;
