import NewOrganizationModalConstants from './NewOrganizationModalConstants';

const initialState = {
  saving: false,
};

function NewOrganizationModalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NewOrganizationModalConstants.CREATE_ORGANIZATION_START: {
      return { ...state, saving: true };
    }
    case NewOrganizationModalConstants.CREATE_ORGANIZATION_SUCCESS: {
      return { ...state, saving: false };
    }
    case NewOrganizationModalConstants.CREATE_ORGANIZATION_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default NewOrganizationModalReducer;
