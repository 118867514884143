import { buildFilterParams } from 'utils/FilterParamsBuilder';
import OrganizationListConstants from './OrganizationListConstants';
import OrganizationsRepository from 'repositories/admin/OrganizationsRepository';

export function loadOrganizations(page, filters) {
  return (dispatch) => {
    dispatch({ type: OrganizationListConstants.LOAD_ORGANIZATIONS_START });

    const params = { page, ...buildFilterParams(filters) };
    return OrganizationsRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: OrganizationListConstants.LOAD_ORGANIZATIONS_SUCCESS,
          organizations: items,
          ...meta,
        });
      })
      .catch((error) => {
        dispatch({ type: OrganizationListConstants.LOAD_ORGANIZATIONS_ERROR });

        throw error;
      });
  };
}

export function filterOrganizations(filters) {
  return (dispatch) => {
    dispatch({ type: OrganizationListConstants.FILTER_ORGANIZATIONS_START, filters });

    const params = buildFilterParams(filters);
    return OrganizationsRepository.index(params)
      .then(({ items, meta }) => {
        dispatch({
          type: OrganizationListConstants.FILTER_ORGANIZATIONS_SUCCESS,
          organizations: items,
          filters,
          ...meta,
        });
      })
      .catch((error) => {
        dispatch({ type: OrganizationListConstants.FILTER_ORGANIZATIONS_ERROR });

        throw error;
      });
  };
}

export function bulkDestroyOrganizations(organizationIds) {
  return (dispatch) =>
    OrganizationsRepository.bulkDestroy(organizationIds).then(() => {
      dispatch({ type: OrganizationListConstants.BULK_DESTROY_ORGANIZATIONS_SUCCESS });
    });
}
