import React from 'react';

import { TableHeadCell } from 'components/v2/Table';

const TableHeader = () => (
  <tr>
    <TableHeadCell width="60px" />
    <TableHeadCell>First name</TableHeadCell>
    <TableHeadCell>Last name</TableHeadCell>
    <TableHeadCell>Title</TableHeadCell>
    <TableHeadCell>Company</TableHeadCell>
    <TableHeadCell width="180px" />
  </tr>
);

export default TableHeader;
