import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TargetingSearchForm from './TargetingSearchForm';
import * as actions from './TargetingSearchFormActions';

const mapStateToProps = (state) => ({
  loading: state.TargetingSearchFormReducer.loading,
  profiles: state.TargetingSearchFormReducer.profiles,
  meta: state.TargetingSearchFormReducer.meta,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TargetingSearchForm);
