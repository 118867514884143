import keyMirror from 'keymirror';

const ProjectListPageConstants = keyMirror({
  FILTER_CURRENT_USER_PROJECTS_START: null,
  FILTER_CURRENT_USER_PROJECTS_SUCCESS: null,
  FILTER_CURRENT_USER_PROJECTS_ERROR: null,

  LOAD_CURRENT_USER_PROJECTS_START: null,
  LOAD_CURRENT_USER_PROJECTS_SUCCESS: null,
  LOAD_CURRENT_USER_PROJECTS_ERROR: null,

  DESTROY_CURRENT_USER_PROJECT_START: null,
  DESTROY_CURRENT_USER_PROJECT_SUCCESS: null,
  DESTROY_CURRENT_USER_PROJECT_ERROR: null,

  CLONE_CURRENT_USER_PROJECT_START: null,
  CLONE_CURRENT_USER_PROJECT_SUCCESS: null,
  CLONE_CURRENT_USER_PROJECT_ERROR: null,

  CHANGE_CURRENT_USER_PROJECT: null,
});

export default ProjectListPageConstants;
