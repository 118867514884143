import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './AuthenticatorActions';
import { actions as sessionActions } from './containers/Session'; // eslint-disable-line import/named
import { actions as phoneNumberActions } from './containers/PhoneNumber'; // eslint-disable-line import/named
import { actions as companyActions } from './containers/Company'; // eslint-disable-line import/named
import { isPresent } from 'utils/HelperMethods';

const authenticatorActions = { ...actions, ...sessionActions, ...phoneNumberActions, ...companyActions };

const mapStateToProps = (state) => ({
  isCurrentUserLoading: state.AuthenticatorReducer.isCurrentUserLoading,
  isCurrentUserReloading: state.AuthenticatorReducer.isCurrentUserReloading,
  isCurrentUserSaving: state.AuthenticatorReducer.isCurrentUserSaving,
  isPhoneNumberSaving: state.AuthenticatorReducer.isPhoneNumberSaving,
  currentUser: state.AuthenticatorReducer.currentUser,
  isCompanyLoading: state.AuthenticatorReducer.isCompanyLoading,
  company: state.AuthenticatorReducer.company,
});

export default (component, actionsSelector) => {
  const selectedActions = isPresent(actionsSelector) ? actionsSelector(authenticatorActions) : {};

  return connect(mapStateToProps, (dispatch) => bindActionCreators(selectedActions, dispatch))(component);
};
