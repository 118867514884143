import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(companyId) {
    const url = Routes.apiV1AdminCompanySingleSignOnPath(companyId);

    return FetchHelpers.get(url);
  },

  update(companyId, singleSignOn) {
    const url = Routes.apiV1AdminCompanySingleSignOnPath(companyId);

    return FetchHelpers.put(url, { singleSignOn });
  },
};
