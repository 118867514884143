import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import AsyncSelect from 'components/AsyncSelect';
import Button from 'components/Button';

import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter from 'presenters/UserPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RequestPolicy from 'policies/RequestPolicy';

class RequestAssigneeBlock extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    onUserChange: PropTypes.func.isRequired,
    onUserSearch: PropTypes.func.isRequired,
    request: RequestPresenter.shape(),
    saving: PropTypes.bool.isRequired,
  };

  handleAssignToMe = () => {
    const { currentUser, onUserChange } = this.props;

    onUserChange(currentUser);
  };

  handleUserSearch = reactSelectDebounce(this.props.onUserSearch);

  renderAssigneeSelect() {
    const { request, onUserChange, saving } = this.props;

    return (
      <Box>
        <AsyncSelect
          placeholder="Assignee"
          loadOptions={this.handleUserSearch}
          defaultOptions
          isClearable
          value={request.assignee}
          isDisabled={saving}
          onChange={onUserChange}
          getOptionValue={UserPresenter.id}
          getOptionLabel={UserPresenter.fullName}
        />
      </Box>
    );
  }

  renderClaimBlock() {
    const { saving } = this.props;
    return (
      <Box>
        <Button disabled={saving} label="Assign to Me" onClick={this.handleAssignToMe} />
      </Box>
    );
  }

  renderDisabledAssigneeSelect() {
    const { request } = this.props;

    return (
      <Box>
        <AsyncSelect
          placeholder="Assignee"
          value={request.assignee}
          isDisabled
          getOptionValue={UserPresenter.id}
          getOptionLabel={UserPresenter.fullName}
        />
      </Box>
    );
  }

  render() {
    const { currentUser, request } = this.props;

    if (RequestPolicy.canChangeAssignee(currentUser, request)) {
      return this.renderAssigneeSelect();
    }

    if (RequestPolicy.canClaimAssignee(currentUser, request)) {
      return this.renderClaimBlock();
    }

    return this.renderDisabledAssigneeSelect();
  }
}

export default RequestAssigneeBlock;
