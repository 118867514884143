import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'grommet';

import Routes from 'routes';

import Link from 'components/v2/Link';
import Logotype from 'components/v2/Logotype';

import styles from './RunOutOfCreditsErrorPage.module.css';

class RunOutOfCreditsErrorPage extends Component {
  static propTypes = {
    match: PropTypes.shape(),
  };

  creditsType() {
    const { match } = this.props;
    const { type } = match.params;

    switch (type) {
      case 'views':
        return 'view';
      case 'downloads':
        return 'download';
      default:
        return '';
    }
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <Link href="/">
            <Logotype />
          </Link>
        </div>
        <div className={styles.main}>
          <h1 className={styles.text}>
            That’s great!
            <br />
            {`You’ve used all your ${this.creditsType()} credits.`}
          </h1>
          <div className={styles.recomendation}>
            Please contact your account executive or customerservice@boardroominsiders.com to get more.
          </div>
          <Button label="Back to Main" primary className={styles.button} href={Routes.rootPath()} />
        </div>
      </div>
    );
  }
}

export default RunOutOfCreditsErrorPage;
