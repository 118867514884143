import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { Close as CloseIcon } from 'grommet-icons';

import styles from './Promotion.module.css';

export default class Promotion extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { children, onClose } = this.props;

    return (
      <div className={styles.container}>
        <Button className={styles.close} onClick={onClose}>
          <CloseIcon size="small" />
        </Button>
        {children}
      </div>
    );
  }
}
