import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(organizationId, query = {}) {
    const url = Routes.apiV1AdminOrganizationEditorNotesPath(organizationId, query);
    return FetchHelpers.get(url);
  },

  update(organizationId, editorNoteId, editorNote) {
    const url = Routes.apiV1AdminOrganizationEditorNotePath(organizationId, editorNoteId);
    return FetchHelpers.patch(url, { editorNote });
  },

  create(organizationId, editorNote) {
    const url = Routes.apiV1AdminOrganizationEditorNotesPath(organizationId);
    return FetchHelpers.post(url, { editorNote });
  },

  destroy(organizationId, editorNoteId) {
    const url = Routes.apiV1AdminOrganizationEditorNotePath(organizationId, editorNoteId);
    return FetchHelpers.delete(url);
  },
};
