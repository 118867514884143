import PopularOrganizationTranscriptsConstants from './PopularOrganizationTranscriptsConstants';
import PopularOrganizationTranscriptsRepository from 'repositories/admin/PopularOrganizationTranscriptsRepository';

export function loadPopularOrganisationTranscripts() {
  const params = { page: 1, per: 50 };

  return (dispatch) => {
    dispatch({ type: PopularOrganizationTranscriptsConstants.LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_START });

    return PopularOrganizationTranscriptsRepository.index(params)
      .then(({ items }) =>
        dispatch({
          type: PopularOrganizationTranscriptsConstants.LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_SUCCESS,
          items,
        }),
      )
      .catch(() =>
        dispatch({
          type: PopularOrganizationTranscriptsConstants.LOAD_POPULAR_ORGANIZATION_TRANSCRIPTS_FAILURE,
        }),
      );
  };
}

export function checkPopularOrganizationTranscript(transcriptId) {
  return (dispatch) => {
    dispatch({
      type: PopularOrganizationTranscriptsConstants.CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_START,
    });

    return PopularOrganizationTranscriptsRepository.update(transcriptId)
      .then(({ transcript }) =>
        dispatch({
          type: PopularOrganizationTranscriptsConstants.CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_SUCCESS,
          transcript,
        }),
      )
      .catch(() =>
        dispatch({
          type: PopularOrganizationTranscriptsConstants.CHECK_POPULAR_ORGANIZATION_TRANSCRIPT_FAILURE,
        }),
      );
  };
}
