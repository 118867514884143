import React from 'react';
import PropTypes from 'prop-types';

import ProfilePresenter from 'presenters/ProfilePresenter';
import BoardPresenter from 'presenters/BoardPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import Typography from 'components/v2/Typography';

import styles from './Ftse100Boards.module.css';

const Ftse100Boards = (props) => {
  const { ftse100Boards } = props;

  return (
    <div className={styles.fortune500Boards}>
      <Typography className={styles.fortune500Title} color="sundance">
        Current FTSE 100 Boards:
      </Typography>
      <ul className={styles.fortune500BoardsList}>
        {ftse100Boards.map((board) => (
          <li key={BoardPresenter.id(board)}>
            {BoardPresenter.description(board)}, {OrganizationPresenter.name(BoardPresenter.organization(board))}
          </li>
        ))}
      </ul>
    </div>
  );
};

Ftse100Boards.propTypes = {
  ftse100Boards: PropTypes.arrayOf(ProfilePresenter.shape()).isRequired,
};

export default Ftse100Boards;
