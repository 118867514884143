import keyMirror from 'keymirror';

const ProfilesListConstants = keyMirror({
  FILTER_PROJECT_WORD_PROFILES: null,

  LOAD_PROJECT_WORD_PROFILES_START: null,
  LOAD_PROJECT_WORD_PROFILES_SUCCESS: null,
});

export default ProfilesListConstants;
