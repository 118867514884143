import { pick } from 'ramda';

export default {
  defaultAttributes(organization = {}) {
    return { ...organization };
  },

  attributesToSubmit(organization) {
    const propsToSend = ['address', 'city', 'stateId', 'zip', 'countryId', 'phone', 'url', 'address2'];
    const { state, country } = organization;

    return pick(propsToSend, {
      ...organization,
      stateId: state && state.id,
      countryId: country && country.id,
    });
  },
};
