import InterestPageConstants from './InterestPageConstants';
import InterestsRepository from 'repositories/admin/InterestsRepository';
import { addNotification } from 'containers/Notifications';

export function loadInterest({ id }) {
  return (dispatch) => {
    dispatch({ type: InterestPageConstants.LOAD_INTEREST_START });

    return InterestsRepository.show(id).then(({ interest }) => {
      dispatch({ type: InterestPageConstants.LOAD_INTEREST_SUCCESS, interest });
    });
  };
}

export function updateInterest(id, params) {
  return (dispatch) => {
    dispatch({ type: InterestPageConstants.UPDATE_INTEREST_START });

    return InterestsRepository.update(id, params)
      .then(({ interest }) => {
        dispatch({ type: InterestPageConstants.UPDATE_INTEREST_SUCCESS, interest });
        addNotification('Interest was successfully saved', 'normal')(dispatch);
      })
      .catch((json) => {
        dispatch({ type: InterestPageConstants.UPDATE_INTEREST_ERROR });
        throw json;
      });
  };
}
