import cn from 'clsx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select/async-creatable';
import { isNil, is } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import styles from './CreatableSelect.module.css';
import customStyles from './styles.js';

const disableRootContainerScroll = () => {
  const client = document.querySelector('.client');

  if (client) {
    client.style.overflow = 'hidden';
  }
};

const enableRootContainerScroll = () => {
  const client = document.querySelector('.client');

  if (client) {
    client.style.removeProperty('overflow');
  }
};

class CreatableSelect extends Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
  }

  getOptionValue(option) {
    const { getOptionValue } = this.props;

    if (option === null) {
      return null;
    }

    return getOptionValue ? getOptionValue(option) : option.value;
  }

  handleChange = (option, { action }) => {
    const { onChange, onValueChange } = this.props;

    if (action === 'clear') {
      enableRootContainerScroll();
    }

    if (onChange) {
      onChange(option);
    }

    if (onValueChange) {
      const value = this.getOptionValue(option);

      onValueChange(value);
    }
  };

  handleLoadOptionsAfterMenuOpen = () => {
    const { loadOptions } = this.props;
    const cb = (options) => {
      this.selectRef.setState({ defaultOptions: options });
      this.selectRef.setState({ isLoading: false });
    };

    this.selectRef.setState({ isLoading: true });

    const options = loadOptions('', cb);

    if (is(Promise, options)) {
      options.then(cb);
    }
  };

  handleMenuOpen = (...args) => {
    const { onMenuOpen, menuIsOpen, shouldLoadOptionsAfterMenuOpen } = this.props;

    if (isNil(menuIsOpen) || menuIsOpen) {
      disableRootContainerScroll();
    }

    if (shouldLoadOptionsAfterMenuOpen) {
      this.handleLoadOptionsAfterMenuOpen();
    }

    if (onMenuOpen) {
      onMenuOpen(...args);
    }
  };

  handleMenuClose = (...args) => {
    const { onMenuClose } = this.props;

    enableRootContainerScroll();

    if (onMenuClose) {
      onMenuClose(...args);
    }
  };

  rootStyles = () => {
    const { isDisabled, errors, noBottom } = this.props;
    return cn([styles.root], { [styles.disabled]: isDisabled, [styles.error]: errors, [styles.noBottom]: noBottom });
  };

  value() {
    const { options, selectedOptionValue, value } = this.props;

    if (selectedOptionValue) {
      return options.find((option) => this.getOptionValue(option) === selectedOptionValue) || '';
    }

    return value;
  }

  render() {
    const { placeholder, inlinePlaceholder, errors, ...rest } = this.props;
    const errorsText = Array.isArray(errors) ? errors.join(', ') : errors;

    return (
      <div className={this.rootStyles()}>
        <div className={styles.info}>
          <div className={styles.placeholder}>{!inlinePlaceholder && placeholder}</div>
          <div className={styles.errorText}>{errorsText}</div>
        </div>
        <ReactSelect
          {...rest}
          ref={(ref) => {
            this.selectRef = ref;
          }}
          styles={customStyles}
          value={this.value()}
          onChange={this.handleChange}
          placeholder={inlinePlaceholder && isPresent(placeholder) ? placeholder : ''}
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
        />
      </div>
    );
  }
}

CreatableSelect.propTypes = {
  placeholder: PropTypes.string,
  inlinePlaceholder: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  getOptionValue: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
  selectedOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  errors: PropTypes.string,
  noBottom: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  loadOptions: PropTypes.func,
  shouldLoadOptionsAfterMenuOpen: PropTypes.bool,
};

export default CreatableSelect;
