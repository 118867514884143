import React, { Component } from 'react';

import Spoiler from 'components/Spoiler';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './ReviewParentChildRelationship.module.css';

class ReviewParentChildRelationship extends Component {
  static propTypes = {
    value: OrganizationPresenter.shape(),
  };

  render() {
    const { value } = this.props;

    return (
      <Spoiler>
        <h4 className={styles.strong}>{OrganizationPresenter.name(value)}</h4>
      </Spoiler>
    );
  }
}

export default ReviewParentChildRelationship;
