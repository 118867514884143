import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewProjectSourceWizard from './NewProjectSourceWizard';
import * as actions from './NewProjectSourceWizardActions';
import * as projectActions from 'containers/UserPanel/containers/Project/ProjectActions';

const mapStateToProps = (state) => ({
  source: state.NewProjectSourceWizardReducer.form,
  saved: state.NewProjectSourceWizardReducer.saved,
  loading: state.NewProjectSourceWizardReducer.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...actions, createProjectSource: projectActions.createProjectSource }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectSourceWizard);
