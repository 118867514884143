import * as Routes from 'routes/Api';
import JsonFetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(phoneNumber = {}) {
    const url = Routes.apiV1CurrentUserPhoneNumberPath();

    return JsonFetchHelpers.patch(url, { phone_number: phoneNumber });
  },

  confirm(phoneNumber = {}) {
    const url = Routes.confirmApiV1CurrentUserPhoneNumberPath();

    return JsonFetchHelpers.post(url, { phone_number: phoneNumber });
  },

  resendConfirmation() {
    const url = Routes.resendConfirmationApiV1CurrentUserPhoneNumberPath();

    return JsonFetchHelpers.post(url);
  },
};
