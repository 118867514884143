import CorporateAccountConstants from './CorporateAccountConstants';
import CompanyRepository from 'repositories/CurrentUser/CompanyRepository';

export function loadCorporateAccount() {
  return (dispatch) => {
    dispatch({ type: CorporateAccountConstants.LOAD_CORPORATE_ACCOUNT_START });

    return CompanyRepository.show()
      .then(({ company }) => {
        dispatch({ type: CorporateAccountConstants.LOAD_CORPORATE_ACCOUNT_SUCCESS, company });

        return company;
      })
      .catch((error) => {
        dispatch({ type: CorporateAccountConstants.LOAD_CORPORATE_ACCOUNT_ERROR, error });

        throw error;
      });
  };
}

export function reloadCorporateAccount() {
  return (dispatch) =>
    CompanyRepository.show().then(({ company }) => {
      dispatch({ type: CorporateAccountConstants.LOAD_CORPORATE_ACCOUNT_SUCCESS, company });

      return company;
    });
}
