import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';
import AsyncSelect from 'components/AsyncSelect';

import BulkAssignRequestForm from 'forms/BulkAssignRequestForm';

import UserPresenter, { ADMIN_ROLES } from 'presenters/UserPresenter';

class BulkAssignUserModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onUserSearch: PropTypes.func.isRequired,
    onAssign: PropTypes.func.isRequired,
    requestIds: PropTypes.arrayOf(PropTypes.number),
    saving: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const { requestIds } = this.props;
    this.state = { form: BulkAssignRequestForm.defaultAttributes({ ids: requestIds }) };
  }

  handleUserSearch = (value) => {
    const { onUserSearch } = this.props;
    const roleIn = ADMIN_ROLES;

    return onUserSearch({ fullNameCont: value, roleIn });
  };

  handleUserSearchDebounced = reactSelectDebounce(this.handleUserSearch);

  handleUserAssign = (value) => {
    const { form } = this.state;

    this.setState({ form: { ...form, assignee: value } });
  };

  handleSubmit = () => {
    const { onAssign } = this.props;
    const { form } = this.state;

    onAssign(BulkAssignRequestForm.attributesToSubmit(form));
  };

  render() {
    const { onClose, saving } = this.props;
    const { form } = this.state;

    return (
      <Modal header="Assign" size="small" onClose={onClose} overflow>
        <Box margin={{ bottom: 'small' }}>
          <AsyncSelect
            placeholder="Assignee"
            loadOptions={this.handleUserSearchDebounced}
            defaultOptions
            value={form.assignee}
            onChange={this.handleUserAssign}
            getOptionValue={UserPresenter.id}
            getOptionLabel={UserPresenter.fullName}
          />
        </Box>
        <Box direction="row">
          <Button primary label="Assign" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}

export default BulkAssignUserModal;
