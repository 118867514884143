import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DashboardPage from './DashboardPage';
import * as actions from './DashboardPageActions';
import { actions as backendNotificationsActions } from 'containers/UserPanel/containers/BackendNotifications'; // eslint-disable-line import/named

const mapStateToProps = (state) => ({
  dashboard: state.DashboardPageReducer.dashboard,
  isLoading: state.DashboardPageReducer.isLoading,
  currenUserRequestsHistory: state.DashboardPageReducer.currenUserRequestsHistory,
  currenUserRequestsHistoryMeta: state.DashboardPageReducer.currenUserRequestsHistoryMeta,
  currenUserRequestsHistoryFilters: state.DashboardPageReducer.currenUserRequestsHistoryFilters,
  loadingCurrenUserRequestsHistory: state.DashboardPageReducer.loadingCurrenUserRequestsHistory,
});

const { markDashboardViewed } = backendNotificationsActions;
const mapDispatchToProps = (dispatch) => bindActionCreators({ markDashboardViewed, ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
