import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isPresent, isBlank } from 'utils/HelperMethods';

import UsersMatchPresenter from 'presenters/Company/UsersMatchPresenter';

import Tabs, { TabList, Tab, TabPanel } from 'components/v2/Tabs';
import Loader from 'components/v2/Loader';

import Users from './components/Users';
import SelectableUserItem from './components/SelectableUserItem';
import UserItemWithAvatar from './components/UserItemWithAvatar';

import styles from './MatchUsersWizardStep.module.css';

const countMatchUsers = (users) => users?.length || 0;

const MatchUsersWizardStep = (props) => {
  const {
    onNextStep,
    onPreviousStep,
    WizardTitle,
    WizardNavigation,
    usersMatches,
    isLoading,
    onLoad,
    onSelectedUserIndicesChange,
    selectedUserIndices,
  } = props;

  const [validUserFilters, setValidUserFilters] = useState({});
  const [invalidUserFilters, setInvalidUserFilters] = useState({});
  const [existsUserFilters, setExistsUserFilters] = useState({});

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <WizardTitle subtitle="Choose users to import into department." />
      <div className={styles.fileUploadContainer}>
        {isLoading && <Loader />}
        {isPresent(usersMatches) && !isLoading && (
          <Tabs className={styles.tabs}>
            <TabList className={styles.tabList}>
              <Tab activeClassName={styles.activeTab} className={styles.tab}>
                Fully Compliant Users:{' '}
                <div className={styles.countDataItems}>
                  {countMatchUsers(UsersMatchPresenter.validMissingUsers(usersMatches))}
                </div>
              </Tab>
              <Tab activeClassName={styles.activeTab} className={styles.tab}>
                Users with Missing Rows:{' '}
                <div className={styles.countDataItems}>
                  {countMatchUsers(UsersMatchPresenter.invalidMissingUsers(usersMatches))}
                </div>
              </Tab>
              <Tab activeClassName={styles.activeTab} className={styles.tab}>
                Duplicated Users:{' '}
                <div className={styles.countDataItems}>
                  {countMatchUsers(UsersMatchPresenter.existingUsers(usersMatches))}
                </div>
              </Tab>
            </TabList>
            <div className={styles.tabPanels}>
              <TabPanel>
                <Users
                  UserComponent={SelectableUserItem}
                  users={UsersMatchPresenter.validMissingUsers(usersMatches)}
                  onSelectedUserIndicesChange={onSelectedUserIndicesChange}
                  selectedUserIndices={selectedUserIndices}
                  onFilterChange={setValidUserFilters}
                  filters={validUserFilters}
                  isSelectable
                />
              </TabPanel>

              <TabPanel>
                <Users
                  UserComponent={SelectableUserItem}
                  users={UsersMatchPresenter.invalidMissingUsers(usersMatches)}
                  onFilterChange={setInvalidUserFilters}
                  filters={invalidUserFilters}
                />
              </TabPanel>

              <TabPanel>
                <Users
                  UserComponent={UserItemWithAvatar}
                  users={UsersMatchPresenter.existingUsers(usersMatches)}
                  onFilterChange={setExistsUserFilters}
                  filters={existsUserFilters}
                />
              </TabPanel>
            </div>
          </Tabs>
        )}
      </div>
      <WizardNavigation
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        labelNext="Import Executives"
        isNextButtonDisabled={isBlank(selectedUserIndices)}
      />
    </>
  );
};

MatchUsersWizardStep.propTypes = {
  onLoad: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  usersMatches: UsersMatchPresenter.shape(),
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  WizardTitle: PropTypes.func.isRequired,
  WizardNavigation: PropTypes.func.isRequired,
  onSelectedUserIndicesChange: PropTypes.func.isRequired,
  selectedUserIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default MatchUsersWizardStep;
