import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CheckBox as GrommetCheckBox } from 'grommet';

import { isPresent } from 'utils/HelperMethods';

import styles from './Checkbox.module.css';

export default class Checkbox extends Component {
  static propTypes = {
    className: PropTypes.string,
    indeterminate: PropTypes.bool,
    checked: PropTypes.bool,
  };

  static defaultProps = {
    indeterminate: false,
  };

  render() {
    const { className, checked, indeterminate, ...rest } = this.props;
    const classNames = cx(styles.checkbox, {
      [className]: isPresent(className),
      [styles.indeterminate]: indeterminate,
    });
    const isChecked = indeterminate ? false : checked;

    return <GrommetCheckBox className={classNames} checked={isChecked} {...rest} />;
  }
}
