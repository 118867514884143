import React from 'react';
import PropTypes from 'prop-types';
import { reactSelectDebounce } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Button from 'components/Button';
import Modal from 'components/Modal';
import AsyncSelect from 'components/AsyncSelect';

import RequestPresenter from 'presenters/RequestPresenter';
import UserPresenter from 'presenters/UserPresenter';

export default class AssignRequestPopup extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onUserSearch: PropTypes.func.isRequired,
    request: RequestPresenter.shape(),
    saving: PropTypes.bool.isRequired,
    stateEvent: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const { request } = this.props;
    this.state = { form: { assignee: request.assignee }, errors: null };
  }

  handleSelectChange = (field) => (value) => {
    const { form } = this.state;

    this.setState({ form: { ...form, [field]: value } });
  };

  handleSubmit = () => {
    const { onSubmit, request, stateEvent } = this.props;
    const {
      form: { assignee },
    } = this.state;
    const assigneeId = assignee && assignee.id;

    onSubmit({ ...request, stateEvent, assigneeId }).catch(({ errors }) => this.setState({ errors }));
  };

  handleUserAssign = (user) => {
    const { form } = this.state;

    this.setState({ form: { ...form, assignee: user } });
  };

  handleUserSearch = reactSelectDebounce(this.props.onUserSearch);

  render() {
    const { onClose, saving } = this.props;
    const { form, errors } = this.state;

    return (
      <Modal header="Assignee to Editor" onClose={onClose} size="small" overflow>
        <Box margin={{ bottom: 'small' }}>
          <AsyncSelect
            placeholder="Assignee"
            errors={errors && errors.assignee}
            loadOptions={this.handleUserSearch}
            defaultOptions
            value={form.assignee}
            onChange={this.handleUserAssign}
            getOptionValue={UserPresenter.id}
            getOptionLabel={UserPresenter.fullName}
          />
        </Box>
        <Box direction="row">
          <Button primary label="Save" disabled={saving} onClick={this.handleSubmit} />
        </Box>
      </Modal>
    );
  }
}
