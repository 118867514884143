import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Heading } from 'grommet';
import { PasswordInput } from 'grommet-controls';

import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import Button from 'components/Button';
import PasswordValidator from 'components/PasswordValidator';
import PasswordStrengthRating from 'components/PasswordStrengthRating';

import styles from './NewPassword.module.css';

const NewPassword = (props) => {
  const { initializeNewPasswordForm, location, changeForm, form, updatePassword, errors, isPasswordChanged, saving } =
    props;

  const [isValidPassword, setIsValidPassword] = useState(false);

  useEffect(() => {
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true });

    initializeNewPasswordForm(token);
  }, []);

  const handleFormFieldChange =
    (field) =>
    ({ target: { value } }) =>
      changeForm({ ...form, [field]: value });

  const handleUpdatePassword = () => updatePassword(form);

  const handleValidationPassword = (isValid) => setIsValidPassword(isValid);

  return (
    <div className={styles.root}>
      {isPasswordChanged && <div className={styles.message}>Password has been changed</div>}
      {!isPasswordChanged && (
        <div className={styles.container}>
          <Heading level="3" weight="bold">
            New password
          </Heading>
          <div className={styles.info}>Please enter your new password</div>
          <div className={styles.form}>
            {errors && errors.token && <div className={styles.danger}>{errors.token.join(',')}</div>}
            <div className={styles.credentialsBlock}>
              <div className={styles.fields}>
                <VerticalFormFieldWithBottomErrors label="Password" errors={errors && errors.password}>
                  <PasswordInput value={form.password} onChange={handleFormFieldChange('password')} />
                </VerticalFormFieldWithBottomErrors>

                <div className={styles.passwordStrengthRating}>
                  <PasswordStrengthRating isValidPassword={isValidPassword} />
                </div>
                <div className={styles.mobilePasswordValidator}>
                  <PasswordValidator password={form.password} onValidation={handleValidationPassword} />
                </div>

                <VerticalFormFieldWithBottomErrors
                  label="Confirm password"
                  errors={errors && errors.passwordConfirmation}
                >
                  <PasswordInput
                    value={form.passwordConfirmation}
                    onChange={handleFormFieldChange('passwordConfirmation')}
                  />
                </VerticalFormFieldWithBottomErrors>
              </div>
              <div>
                <div className={styles.desktopPasswordValidatorBlock}>
                  <PasswordValidator
                    password={form.password}
                    onValidation={handleValidationPassword}
                    className={styles.passwordValidator}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <Button disabled={saving} label="Submit" onClick={handleUpdatePassword} />
          </div>
        </div>
      )}
    </div>
  );
};

NewPassword.propTypes = {
  changeForm: PropTypes.func.isRequired,
  errors: PropTypes.shape({}),
  form: PropTypes.shape({ password: PropTypes.string, passwordConfirmation: PropTypes.string }),
  initializeNewPasswordForm: PropTypes.func.isRequired,
  isPasswordChanged: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  saving: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

export default NewPassword;
