import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { parseISO8601 } from 'utils/DateTime';

import CompanyPresenter from 'presenters/CompanyPresenter';

import Button from 'components/v2/Button';
import Typography from 'components/v2/Typography';
import { Field, DatePicker, Switch } from 'components/v2/Form';

import styles from './SubscriptionSettingsEditForm.module.css';

const SubscriptionSettingsEditForm = (props) => {
  const { errors, isSubmitting, onFieldChange, onFieldsChange, onSubmit, values: companyValues } = props;

  const handleChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  const handleSubscriptionStartsAtChange = (subscriptionStartsAt) => {
    const fields = { subscriptionStartsAt };
    const { subscriptionExpireAt } = companyValues;

    if (!isNil(subscriptionStartsAt) && isNil(subscriptionExpireAt)) {
      fields.subscriptionExpireAt = parseISO8601(subscriptionStartsAt).plus({ years: 1 }).toISO();
    }

    onFieldsChange(fields);
  };

  const handleProjectSharingAvailabilityChange = (projectSharingAvailable) =>
    onFieldsChange({ projectSharingAvailable, projectSharingToNewCompanyMembersAvailable: false });

  return (
    <div className={styles.fields}>
      <Typography variant="h3">Common Settings</Typography>
      <Typography variant="body1">Subscription Valid Between</Typography>
      <div className={styles.subscriptionDates}>
        <Field error={errors.subscriptionStartsAt} variant="outlined">
          <DatePicker
            className={styles.datePicker}
            onChange={handleSubscriptionStartsAtChange}
            value={companyValues.subscriptionStartsAt}
          />
        </Field>
        <Field error={errors.subscriptionExpireAt} variant="outlined">
          <DatePicker
            className={styles.datePicker}
            onChange={handleChange('subscriptionExpireAt')}
            value={companyValues.subscriptionExpireAt}
          />
        </Field>
      </div>
      <Field title="Show previous interval data on charts" error={errors.showPreviousIntervalData} variant="outlined">
        <Switch checked={companyValues.showPreviousIntervalData} onChange={handleChange('showPreviousIntervalData')} />
      </Field>
      <Field
        title="Project sharing with registered users at this company"
        error={errors.projectSharingAvailable}
        variant="outlined"
      >
        <Switch checked={companyValues.projectSharingAvailable} onChange={handleProjectSharingAvailabilityChange} />
      </Field>
      {companyValues.projectSharingAvailable && (
        <Field
          title="Project sharing with new users (not yet registered) at this company"
          error={errors.projectSharingToNewCompanyMembersAvailable}
          variant="outlined"
        >
          <Switch
            checked={companyValues.projectSharingToNewCompanyMembersAvailable}
            onChange={handleChange('projectSharingToNewCompanyMembersAvailable')}
          />
        </Field>
      )}
      <Button disabled={isSubmitting} onClick={onSubmit}>
        Save
      </Button>
    </div>
  );
};

SubscriptionSettingsEditForm.propTypes = {
  errors: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: CompanyPresenter.shape().isRequired,
};

export default SubscriptionSettingsEditForm;
