import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import OrganizationUpdates from './OrganizationUpdates';
import * as actions from './OrganizationUpdatesActions';

const mapStateToProps = (state) => ({
  loading: state.OrganizationUpdatesReducer.loading,
  organizationUpdates: state.OrganizationUpdatesReducer.organizationUpdates,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUpdates);
