import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import config from 'config';

import Spoiler from 'components/Spoiler';

import BoardPresenter from 'presenters/BoardPresenter';

const BoardsList = (props) => {
  const { boards } = props;

  const renderBoard = (board) => {
    const className = cn({
      [config.changesHighlightsClassName]: BoardPresenter.isRecentlyUpdated(board),
      maskedText: BoardPresenter.isMasked(board),
    });

    return (
      <li className={className} key={board.id}>
        {BoardPresenter.description(board)}, {BoardPresenter.organizationName(board)}
        {` ${BoardPresenter.timeInterval(board)}`}
      </li>
    );
  };

  return (
    <section>
      <Spoiler header="Other Boards and Organizations">
        <ul>{boards.map(renderBoard)}</ul>
      </Spoiler>
    </section>
  );
};

BoardsList.propTypes = {
  boards: PropTypes.arrayOf(BoardPresenter.shape()).isRequired,
};

export default BoardsList;
