import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Roles from './Roles';
import * as actions from './RolesActions';

const mapStateToProps = (state) => ({
  loading: state.RolesReducer.loading,
  roles: state.RolesReducer.roles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
