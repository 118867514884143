import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import CalendarIcon from 'icons/CalendarIcon';

import Input from 'components/v2/Form/components/Input';

import styles from './DateInput.module.css';

const DateInput = forwardRef((props, ref) => {
  const { onChange, ...rest } = props;

  const handleChange = (value, event) => onChange(event);

  return (
    <Input
      ref={ref}
      {...rest}
      onChange={handleChange}
      suffix={<CalendarIcon className={styles.calendarIcon} width={18} height={18} />}
    />
  );
});

DateInput.propTypes = {
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  onChange: () => {},
};

export default DateInput;
