import { adminEditorialDashboardFtse100ProfilesStatisticsRestHooks } from 'slices/admin/editorialDashboard/databaseAnalytic/ftse100ProfilesStatisticsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useFtse100ProfilesStatistics = () => {
  const { editorialDashboardFtse100ProfilesStatistic, loadEditorialDashboardFtse100ProfilesStatistic } =
    adminEditorialDashboardFtse100ProfilesStatisticsRestHooks.use();

  return {
    editorialDashboardFtse100ProfilesStatistic: editorialDashboardFtse100ProfilesStatistic.item,
    editorialDashboardFtse100ProfilesStatisticLoadingStatus: useFetchStatus(
      editorialDashboardFtse100ProfilesStatistic.loadingStatus,
    ),
    loadEditorialDashboardFtse100ProfilesStatistic,
  };
};

export default useFtse100ProfilesStatistics;
