import qs from 'qs';
import { concat, pipe, map, flatten, uniq } from 'ramda';
import { isPresent, isBlank } from './HelperMethods';
import { currentYear } from './DateTime';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';

const GOOGLE_HOST = 'https://www.google.com/search';

function buildGoogleSearchUrl(searchText) {
  const query = qs.stringify({ q: searchText });

  return `${GOOGLE_HOST}?${query}`;
}

function wrapInQuotes(string) {
  return isBlank(string) ? null : `"${string}"`;
}

function withPostfix(withString) {
  if (isBlank(withString)) {
    return (string) => string;
  }

  return (string) => (isBlank(string) ? null : `${string} ${withString}`);
}

function getOrganizationName(work) {
  const name = WorkPresenter.organizationName(work);

  if (isBlank(name) || name.split(' ').length <= 1) {
    return name;
  }

  return wrapInQuotes(name);
}

function fullNameAndOrganizationLink(profile, organizationName) {
  return pipe(ProfilePresenter.firstNameWithLastName, wrapInQuotes, withPostfix(organizationName))(profile);
}

function fullNameAndOrganizationWithYearLink(profile, organizationName) {
  const fullNameAndOrganization = fullNameAndOrganizationLink(profile, organizationName);

  return withPostfix(currentYear())(fullNameAndOrganization);
}

function commonNameAndOrganizationWithYearLink(profile, organizationName) {
  return pipe(
    ProfilePresenter.commonName,
    withPostfix(ProfilePresenter.lastName(profile)),
    wrapInQuotes,
    withPostfix(organizationName),
    withPostfix(currentYear()),
  )(profile);
}

function jobFunctionWithYearLink(profile, jobFunction, organizationName) {
  return pipe(
    JobFunctionPresenter.name,
    withPostfix('strategy'),
    withPostfix(organizationName),
    withPostfix(currentYear()),
  )(jobFunction);
}

function companyStrategyWithYearLink(organizationName) {
  return pipe(withPostfix('strategy'), withPostfix(currentYear()))(organizationName);
}

function buildWorkSpecificAutoSearchLinks(profile, work) {
  const organizationName = getOrganizationName(work);

  return [
    fullNameAndOrganizationLink(profile, organizationName),
    fullNameAndOrganizationWithYearLink(profile, organizationName),
    commonNameAndOrganizationWithYearLink(profile, organizationName),
    companyStrategyWithYearLink(organizationName),
  ];
}

export function buildProfileAutoSearchLinks(profile, allJobFunctions) {
  const jobFunctions = ProfilePresenter.currentWorksJobFunctions(profile, allJobFunctions);
  const jobFunctionLinks = jobFunctions.map((jobFunction) => jobFunctionWithYearLink(profile, jobFunction));
  const currentWorks = ProfilePresenter.currentWorks(profile);
  const queries = pipe(
    map((work) => buildWorkSpecificAutoSearchLinks(profile, work)),
    flatten,
    uniq,
    concat(jobFunctionLinks),
  )(currentWorks);

  return queries.filter(isPresent).map((query) => ({ title: query, href: buildGoogleSearchUrl(query) }));
}
