import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import u from 'updeep';
import { dissoc } from 'ramda';
import { isPresent } from 'utils/HelperMethods';
import {
  logClickOnExcludeExecutivesInRelationshipMapping,
  logShareOfRelationshipMappingDetails,
  logClickOnRelationshipMappingDetails,
  toggleRelationshipMappingFilterExecutives,
  toggleRelationshipMappingFilterBoardOfDirectors,
  toggleRelationshipMappingFilterBoardOfDirectorsFtse100,
  toggleRelationshipMappingFilterMyExecutives,
  toggleRelationshipMappingFilterMyBoardMembers,
} from 'utils/amplitude';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import RelationshipsMappingPresenter, { FILTERS } from 'presenters/RelationshipsMappingPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import ExcludeProfilesButton from 'containers/UserPanel/containers/Project/containers/RelationshipsMapping/components/ExcludeProfilesButton';
import SidebarFilters from './components/SidebarFilters';
import Sidebar from 'containers/UserPanel/components/Sidebar';
import PageOverlay from 'containers/UserPanel/components/PageOverlay';

import Graph from './components/Graph';
import ExcludeProfilesForm from './containers/ExcludeProfilesForm';
import ProfileRelationshipsToMyExecutivesList from './containers/ProfileRelationshipsToMyExecutivesList';

import styles from './TopRelationshipsToMyExecutives.module.css';

const TopRelationshipsToMyExecutives = (props) => {
  const {
    loadTopRelationshipsToMyExecutivesToExecutives,
    project,
    onRelationshipsExport,
    currentUser,
    loading,
    userColleaguesExists,
    userBoardMembersExists,
    relationshipsMapping,
    filters,
    onFiltersChange,
    isShowFilters,
    onFilterVisibilityChange,
    hasAnyCheckedMyProjectFilter,
    hasAnyCheckedMyCompanyFilter,
  } = props;

  const [isExcludeProfilesSidebarOpened, setIsExcludeProfilesSidebarOpened] = useState(false);
  const [isProfileRelationshipsSidebarOpened, setIsProfileRelationshipsSidebarOpened] = useState(false);
  const [profile, setProfile] = useState(null);
  const [countOfProfileNeighbors, setCountOfProfileNeighbors] = useState(null);
  const { excludeIds } = filters;
  const isFiltersDisabled = isProfileRelationshipsSidebarOpened || isExcludeProfilesSidebarOpened || loading;

  useEffect(() => {
    if (!hasAnyCheckedMyProjectFilter(filters) || !hasAnyCheckedMyCompanyFilter(filters)) return;

    loadTopRelationshipsToMyExecutivesToExecutives(ProjectPresenter.id(project), filters).then((data) => {
      onFilterVisibilityChange(isPresent(RelationshipsMappingPresenter.nodes(data)));
    });
  }, []);

  const handleProfileRelationshipsSidebarOpen = (newProfile, newCountOfProfileNeighbors) => {
    logClickOnRelationshipMappingDetails();
    setProfile(newProfile);
    setCountOfProfileNeighbors(newCountOfProfileNeighbors);
    setIsProfileRelationshipsSidebarOpened(true);
  };

  const handleExcludeProfilesSidebarOpen = () => {
    logClickOnExcludeExecutivesInRelationshipMapping();
    setIsExcludeProfilesSidebarOpened(true);
  };

  const handleProfileRelationshipsSidebarClose = () => {
    setIsProfileRelationshipsSidebarOpened(false);
    setProfile(null);
    setCountOfProfileNeighbors(null);
  };

  const handleExcludeProfilesSidebarClose = () => setIsExcludeProfilesSidebarOpened(false);

  const handleRelationshipsExport = () => {
    logShareOfRelationshipMappingDetails();
    return onRelationshipsExport(profile, filters);
  };

  const handleFiltersChange = (newFilters) => {
    if (hasAnyCheckedMyProjectFilter(newFilters) && hasAnyCheckedMyCompanyFilter(newFilters)) {
      loadTopRelationshipsToMyExecutivesToExecutives(ProjectPresenter.id(project), newFilters);
    }

    onFiltersChange(newFilters);
  };

  const handleExcludeProfileIdsChange = (newExcludeIds) => {
    const newFilters = { ...filters, [FILTERS.EXCLUDE_IDS]: newExcludeIds };

    handleFiltersChange(newFilters);
    handleExcludeProfilesSidebarClose();
  };

  const handleFiltersToggle = (path) => (value) => {
    const newFilters = { ...filters, ...u.updateIn(path, value, filters), [FILTERS.EXCLUDE_IDS]: [] };

    if (path === FILTERS.MUST_BE_EXECUTIVE) toggleRelationshipMappingFilterExecutives(value);
    if (path === FILTERS.MUST_BE_BOARD_OF_DIRECTORS) toggleRelationshipMappingFilterBoardOfDirectors(value);
    if (path === FILTERS.MUST_BE_BOARD_OF_DIRECTORS_FTSE100)
      toggleRelationshipMappingFilterBoardOfDirectorsFtse100(value);
    if (path === FILTERS.COMPANY_MEMBER_MUST_BE_EXECUTIVE) toggleRelationshipMappingFilterMyExecutives(value);
    if (path === FILTERS.COMPANY_MEMBER_MUST_BE_BOARD_MEMBER) toggleRelationshipMappingFilterMyBoardMembers(value);

    handleFiltersChange(newFilters);
  };

  return (
    <>
      {isExcludeProfilesSidebarOpened && (
        <PageOverlay>
          <Sidebar onClose={handleExcludeProfilesSidebarClose}>
            <ExcludeProfilesForm
              excludedProfileIds={excludeIds}
              onExcludedProfileIdsChange={handleExcludeProfileIdsChange}
              project={project}
              relationshipFilters={{ ...dissoc(FILTERS.EXCLUDE_IDS, filters) }}
            />
          </Sidebar>
        </PageOverlay>
      )}

      {isProfileRelationshipsSidebarOpened && (
        <PageOverlay>
          <Sidebar onClose={handleProfileRelationshipsSidebarClose}>
            <ProfileRelationshipsToMyExecutivesList
              projectId={ProjectPresenter.id(project)}
              profileId={ProfileSearchPresenter.id(profile)}
              onExport={handleRelationshipsExport}
              isExportDisabled={!ProjectPolicy.canExport(project)}
              askForMoreRelationshipsThan={countOfProfileNeighbors}
              title={ProfileSearchPresenter.fullName(profile)}
              profilesFilters={filters}
            />
          </Sidebar>
        </PageOverlay>
      )}

      <div className={styles.main}>
        <div className={styles.sidebarLegend}>
          <SidebarFilters
            filters={filters}
            onFiltersChange={handleFiltersToggle}
            disabled={isFiltersDisabled}
            isShowFilters={isShowFilters}
          >
            <ExcludeProfilesButton
              excludedProfilesCount={excludeIds.length}
              onClick={handleExcludeProfilesSidebarOpen}
            />
          </SidebarFilters>
        </div>

        <div className={styles.graph}>
          <Graph
            loading={loading}
            currentUser={currentUser}
            relationshipsMapping={relationshipsMapping}
            profile={profile}
            userColleaguesExists={userColleaguesExists}
            userBoardMembersExists={userBoardMembersExists}
            onProfileRelationshipsSidebarOpen={handleProfileRelationshipsSidebarOpen}
            isAllProjectFiltersDisabled={!hasAnyCheckedMyProjectFilter(filters)}
            isAllCompanyFiltersDisabled={!hasAnyCheckedMyCompanyFilter(filters)}
          />
        </div>
      </div>
    </>
  );
};

TopRelationshipsToMyExecutives.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  loadTopRelationshipsToMyExecutivesToExecutives: PropTypes.func.isRequired,
  relationshipsMapping: RelationshipsMappingPresenter.shape(),
  project: ProjectPresenter.shape().isRequired,
  userColleaguesExists: PropTypes.bool.isRequired,
  userBoardMembersExists: PropTypes.bool.isRequired,
  onRelationshipsExport: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isShowFilters: PropTypes.bool,
  onFilterVisibilityChange: PropTypes.func.isRequired,
  hasAnyCheckedMyProjectFilter: PropTypes.func.isRequired,
  hasAnyCheckedMyCompanyFilter: PropTypes.func.isRequired,
};

export default TopRelationshipsToMyExecutives;
