import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext();

const ContextProvider = ({ children, container }) => {
  const value = useMemo(() => ({ container }), [container]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.shape(),
};

export { Context, ContextProvider };
