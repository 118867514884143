import { ReactComponent as drag } from './images/drag.svg';
import { ReactComponent as avatarPlaceholder } from './images/avatar-placeholder.svg';
import { ReactComponent as logout } from './images/logout.svg';
import { ReactComponent as organizationPlaceholder } from './images/organization-placeholder.svg';
import { ReactComponent as external } from './images/external.svg';
import { ReactComponent as profile } from './images/profile.svg';
import { ReactComponent as organization } from './images/organization.svg';
import { ReactComponent as logo } from './images/logo.svg';
import { ReactComponent as close } from './images/close.svg';
import { ReactComponent as fullLogo } from './images/full-logo.svg';
import { ReactComponent as arrowUp } from './images/arrowUp.svg';
import { ReactComponent as filter } from './images/filter.svg';
import { ReactComponent as search } from './images/search.svg';
import { ReactComponent as information } from './images/information.svg';

import { ReactComponent as facebook } from './images/facebook.svg';
import { ReactComponent as googlePlus } from './images/google-plus.svg';
import { ReactComponent as twitter } from './images/twitter.svg';
import { ReactComponent as rss } from './images/rss.svg';
import { ReactComponent as headerProfile } from './images/header-profile.svg';
import { ReactComponent as down } from './images/down.svg';
import { ReactComponent as hamburger } from './images/hamburger.svg';
import { ReactComponent as mobileClose } from './images/mobile-close.svg';
import { ReactComponent as linkedin } from './images/linkedin.svg';
import { ReactComponent as target } from './images/target.svg';
import { ReactComponent as CSVFile } from './images/CSVFile.svg';
import { ReactComponent as correct } from './images/correct.svg';
import { ReactComponent as wrong } from './images/wrong.svg';
import { ReactComponent as bubbles } from './images/bubbles.svg';
import { ReactComponent as chart } from './images/chart.svg';
import { ReactComponent as lamp } from './images/lamp.svg';
import { ReactComponent as molecule } from './images/molecule.svg';
import { ReactComponent as cloud } from './images/cloud.svg';
import { ReactComponent as csvIcon } from './images/csv-icon.svg';
import { ReactComponent as notAllowed } from './images/not-allowed.svg';
import { ReactComponent as csvDownload } from './images/csv-download.svg';
import { ReactComponent as person } from './images/person.svg';
import { ReactComponent as flag } from './images/flag.svg';
import { ReactComponent as profiles } from './images/profiles.svg';
import { ReactComponent as company } from './images/company.svg';
import { ReactComponent as addDocument } from './images/add-document.svg';
import { ReactComponent as guideArrow } from './images/guide-arrow.svg';
import { ReactComponent as openEye } from './images/open-eye.svg';
import { ReactComponent as closeEye } from './images/close-eye.svg';

export default {
  drag,
  avatarPlaceholder,
  logout,
  organizationPlaceholder,
  external,
  profile,
  organization,
  logo,
  facebook,
  googlePlus,
  twitter,
  rss,
  headerProfile,
  down,
  hamburger,
  mobileClose,
  linkedin,
  close,
  fullLogo,
  arrowUp,
  filter,
  search,
  target,
  CSVFile,
  correct,
  wrong,
  bubbles,
  chart,
  lamp,
  molecule,
  cloud,
  csvIcon,
  notAllowed,
  csvDownload,
  person,
  flag,
  profiles,
  company,
  addDocument,
  guideArrow,
  information,
  openEye,
  closeEye,
};
