import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Purpose: </strong>
        Provide as much accurate contact information as we can about an individual executive.
      </p>
      <p>
        <strong>Examples:</strong>
      </p>
      <p>
        4444 Industry Avenue
        <br />
        Santa Ana, CA 93544
        <br />
        USA
      </p>
      <p>
        858-999-7654
        <br />
        <a href="mailto:john.jacobs@123co.com">john.jacobs@123co.com</a>
      </p>
      <p>Social Media slugs go here</p>
      <p>
        <strong>Style Tips:</strong>
      </p>
      <ul>
        <li>Use normal abbreviations often used in addresses such as “Ave.” “Blvd.” “St.”</li>
        <li>If they have a blog or a LinkedIn Pulse page, include it in the blog field.</li>
        <li>All social media link urls must include the http:// prefix in order to work. Please include it always.</li>
        <li>
          See if you can find the address of the office where the person actually works or is based. The easiest way to
          determine this is to look on their LinkedIn and see if the city they list matches the one where the corporate
          HQ for their company is. People often work out of offices that are in different locations than the corporate
          HQ and if we can determine this, we want to point it out in the profile. If you cannot find the address of the
          office where they work, then just include the corporate HQ address here and make a note in the “Personal
          Attributes and Interests” section that “According to his LinkedIn profile, Joy works out of ExxonMobil’s New
          York City office.”
        </li>
      </ul>
    </div>
  );
}
