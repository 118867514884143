import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'grommet';
import FiltersList from 'containers/UserPanel/containers/Search/components/FiltersList';
import Tooltip from 'rc-tooltip';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import styles from './SavedSearchBadge.module.css';

class SavedSearchBadge extends Component {
  static propTypes = {
    savedSearch: SavedSearchPresenter.shape().isRequired,
    onResetSavedSearch: PropTypes.func.isRequired,
    profilesTotalCount: PropTypes.number,
  };

  renderFiltersList() {
    const { savedSearch } = this.props;
    const filters = SavedSearchPresenter.filters(savedSearch);

    return (
      <div className={styles.filtersList}>
        <FiltersList filters={filters} />
      </div>
    );
  }

  render() {
    const { savedSearch, onResetSavedSearch, profilesTotalCount } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.name}>{SavedSearchPresenter.name(savedSearch)}</div>
          <div className={styles.info}>
            <Tooltip
              overlayClassName={styles.tooltip}
              align={{ points: ['tl', 'bl'], offset: [-16, 0] }}
              overlay={this.renderFiltersList()}
            >
              <Anchor
                className={styles.filtersCount}
                label={`${SavedSearchPresenter.filtersCount(savedSearch)} filters`}
              />
            </Tooltip>
            <div>{profilesTotalCount} Profiles</div>
          </div>
        </div>
        <Anchor onClick={onResetSavedSearch} className={styles.resetFilters}>
          Reset filters
        </Anchor>
      </div>
    );
  }
}

export default SavedSearchBadge;
