import OrganizationSubscriptionsListContainerConstants from './OrganizationSubscriptionsListContainerConstants';
import SubscriptionsRepository from 'repositories/CurrentUser/SubscriptionsRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';

const BASE_FILTERS = { subjectTypeEq: 'Organization' };

export function loadOrganizationSubscriptions({ page = 1, per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: OrganizationSubscriptionsListContainerConstants.LOAD_ORGANIZATION_SUBSCRIPTION_START });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: OrganizationSubscriptionsListContainerConstants.LOAD_ORGANIZATION_SUBSCRIPTION_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: OrganizationSubscriptionsListContainerConstants.LOAD_ORGANIZATION_SUBSCRIPTION_ERROR });

        throw errors;
      });
  };
}

export function filterOrganizationSubscriptions({ per, ...filters }) {
  return (dispatch) => {
    dispatch({ type: OrganizationSubscriptionsListContainerConstants.FILTER_ORGANIZATION_SUBSCRIPTION_START, filters });

    const searchParams = {
      ...buildFilterParams({ ...filters, ...BASE_FILTERS }),
      page: 1,
      per,
    };

    return SubscriptionsRepository.index(searchParams)
      .then(({ items: subscriptions, meta }) => {
        dispatch({
          type: OrganizationSubscriptionsListContainerConstants.FILTER_ORGANIZATION_SUBSCRIPTION_SUCCESS,
          subscriptions,
          meta,
          filters,
        });

        return subscriptions;
      })
      .catch((errors) => {
        dispatch({ type: OrganizationSubscriptionsListContainerConstants.FILTER_ORGANIZATION_SUBSCRIPTION_ERROR });

        throw errors;
      });
  };
}

export function deleteOrganizationSubscription(id) {
  return (dispatch) => {
    dispatch({ type: OrganizationSubscriptionsListContainerConstants.DELETE_ORGANIZATION_SUBSCRIPTION_START });

    return SubscriptionsRepository.destroy(id)
      .then(({ subscription }) => {
        dispatch({
          type: OrganizationSubscriptionsListContainerConstants.DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
          id,
        });

        return subscription;
      })
      .catch((errors) => {
        dispatch({
          type: OrganizationSubscriptionsListContainerConstants.DELETE_ORGANIZATION_SUBSCRIPTION_ERROR,
        });

        throw errors;
      });
  };
}

export function undoDeleteOrganizationSubscription({ id, kind, subjectId }) {
  const params = { kind, organizationId: subjectId };

  return (dispatch) => {
    dispatch({ type: OrganizationSubscriptionsListContainerConstants.UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_START });

    return SubscriptionsRepository.create(params)
      .then(({ subscription }) => {
        dispatch({
          type: OrganizationSubscriptionsListContainerConstants.UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
          subscription,
          id,
        });

        return subscription;
      })
      .catch((errors) => {
        dispatch({ type: OrganizationSubscriptionsListContainerConstants.UNDO_DELETE_ORGANIZATION_SUBSCRIPTION_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw errors;
      });
  };
}
