import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { keys, omit, difference, pluck, isEmpty, without, mapObjIndexed } from 'ramda';
import { paginate } from 'utils/PaginationHelper';
import { isBlank, isPresent } from 'utils/HelperMethods';

import UserPresenter from 'presenters/UserPresenter';

import Pagination from 'containers/UserPanel/components/Pagination';
import { Checkbox } from 'components/v2/Form';

import FiltersForm from './components/FiltersForm';

import styles from './Users.module.css';

const PER_PAGE = 20;

const isUserAttributesMatchFilters = (filters) => (user) =>
  keys(filters).filter((field) => !filters[field].test(user[field])).length === 0;

const filterUsers = (users, filters) => {
  if (isBlank(filters)) {
    return users;
  }

  const filtersWithRegexp = mapObjIndexed((v) => new RegExp(v, 'i'))(filters);

  return users.filter(isUserAttributesMatchFilters(filtersWithRegexp));
};

const isAllFilteredUsersSelected = (filteredUsers, selectedUserIndices) => {
  if (isEmpty(filteredUsers) && isEmpty(selectedUserIndices)) {
    return false;
  }

  const filteredUserIndices = pluck('index', filteredUsers);

  return isEmpty(difference(filteredUserIndices, selectedUserIndices));
};

const Users = (props) => {
  const {
    users,
    isSelectable,
    UserComponent,
    filters,
    onSelectedUserIndicesChange,
    selectedUserIndices,
    onFilterChange,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const filteredUsers = filterUsers(users, filters);
  const isAllUsersSelected = isAllFilteredUsersSelected(filteredUsers, selectedUserIndices);
  const totalCount = filteredUsers.length;
  const paginatedUsers = paginate(filteredUsers, currentPage, PER_PAGE);
  const handlePageChange = (page) => setCurrentPage(page);

  const handleUserFiltersChange = (field, value) => {
    const updatedFilters = { ...filters, [field]: value };
    const newFilters = isBlank(value) ? omit([field], updatedFilters) : updatedFilters;

    setCurrentPage(1);
    onFilterChange(newFilters);
    onSelectedUserIndicesChange([]);
  };

  const handleChooseAllChange = (checked) => {
    const newIndices = checked ? pluck('index', filteredUsers) : [];

    onSelectedUserIndicesChange(newIndices);
  };

  const handleUserSelectionChange = (user) => (checked) => {
    const newIndices = checked ? [...selectedUserIndices, user.index] : without([user.index], selectedUserIndices);

    onSelectedUserIndicesChange(newIndices);
  };

  return (
    <div className={styles.root}>
      <div className={styles.filtersSide}>
        <FiltersForm onFilterChange={handleUserFiltersChange} filters={filters} />
      </div>
      <div className={styles.contentSide}>
        <div className={styles.headerSide}>
          {isSelectable && (
            <div className={styles.headerAllChoose}>
              <div className={styles.usersAllSelectorBox}>
                <div className={styles.usersAllSelector}>
                  <Checkbox disabled={!isSelectable} onChange={handleChooseAllChange} checked={isAllUsersSelected} />
                </div>
                <div className={styles.usersAllSelectorLabel}>Choose All</div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.usersBox}>
          <div className={styles.users}>
            {paginatedUsers.map((user, index) => (
              <UserComponent
                key={index}
                user={user}
                isSelectable={isSelectable}
                isSelected={selectedUserIndices?.includes(user.index)}
                onSelectionChange={handleUserSelectionChange(user)}
              />
            ))}
            {isPresent(currentPage) && isPresent(totalCount) && (
              <div className={styles.pagination}>
                <Pagination
                  currentPage={currentPage}
                  perPage={PER_PAGE}
                  totalCount={totalCount}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(UserPresenter.shape()),
  isSelectable: PropTypes.bool,
  filters: PropTypes.shape(),
  onSelectedUserIndicesChange: PropTypes.func,
  selectedUserIndices: PropTypes.arrayOf(PropTypes.number),
};

Users.defaultProps = {
  isSelectable: false,
  selectedUserIndices: [],
  onSelectedUserIndicesChange: () => {},
};

export default Users;
