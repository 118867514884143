import { equals } from 'ramda';
import CompaniesListPageConstants from './CompaniesListPageConstants';

const initialState = {
  companies: [],
  loading: false,
  nextPage: 1,
  filters: {
    sortFields: [{ field: 'createdAt', value: 'desc' }],
  },
  saving: false,
  totalCount: null,
};

function CompaniesListPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CompaniesListPageConstants.INITIALIZE_COMPANIES_LIST: {
      return initialState;
    }
    case CompaniesListPageConstants.LOAD_COMPANIES_START: {
      return { ...state, loading: true };
    }
    case CompaniesListPageConstants.LOAD_COMPANIES_SUCCESS: {
      const { nextPage, totalCount } = action;
      const companies = [...state.companies, ...action.companies];

      return { ...state, companies, nextPage, totalCount, loading: false };
    }
    case CompaniesListPageConstants.FILTER_COMPANIES_START: {
      return {
        ...state,
        companies: [],
        loading: true,
        nextPage: 1,
        totalCount: null,
        filters: action.filters,
      };
    }
    case CompaniesListPageConstants.FILTER_COMPANIES_SUCCESS: {
      if (!equals(state.filters, action.filters)) {
        return state;
      }

      const { companies, nextPage, totalCount } = action;
      return { ...state, companies, nextPage, totalCount, loading: false };
    }
    case CompaniesListPageConstants.BULK_DESTROY_COMPANIES_SUCCESS: {
      return { ...state, companies: [], nextPage: 1, totalCount: null };
    }
    case CompaniesListPageConstants.CREATE_COMPANY_START: {
      return { ...state, saving: true };
    }
    case CompaniesListPageConstants.CREATE_COMPANY_SUCCESS: {
      return { ...state, saving: false };
    }
    case CompaniesListPageConstants.CREATE_COMPANY_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default CompaniesListPageReducer;
