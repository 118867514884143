import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { formattedDate } from 'utils/DateTime';

import CountryPresenter from './CountryPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    createdAt: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    ipAddress: PropTypes.string,
    country: CountryPresenter.shape(),
    gdprCustomer: PropTypes.bool,
    profileId: PropTypes.number,
    sentFullProfilePdf: PropTypes.bool,
    dataStoreAgreement: PropTypes.bool,
  },
  {
    fullName(r) {
      return `${this.firstName(r)} ${this.lastName(r)}`;
    },
    formattedCreatedAt(r) {
      return formattedDate(this.createdAt(r));
    },
    countryName(p) {
      const country = this.country(p);

      return country ? CountryPresenter.name(country) : null;
    },
  },
);
