import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import EditorialDashboardDatabaseAnalyticFtse100ProfilesStatisticsRepository from 'repositories/admin/EditorialDashboard/DatabaseAnalytic/Ftse100ProfilesStatisticsRepository';

const restSlice = createRestSlice({
  resource: 'editorialDashboardFtse100ProfilesStatistic',
  repository: EditorialDashboardDatabaseAnalyticFtse100ProfilesStatisticsRepository,
  slices: ['loadEditorialDashboardFtse100ProfilesStatistic'],
});

const initialState = {
  ...restSlice.initialState,
};

const ftse100ProfilesStatisticsSlice = createSlice({
  name: 'adminEditorialDashboardFtse100ProfilesStatistics',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const adminEditorialDashboardFtse100ProfilesStatisticsReducer = ftse100ProfilesStatisticsSlice.reducer;

export default ftse100ProfilesStatisticsSlice;

export const adminEditorialDashboardFtse100ProfilesStatisticsRestHooks = restSlice.hooks(
  ftse100ProfilesStatisticsSlice,
  'adminEditorialDashboardFtse100ProfilesStatisticsReducer',
);
