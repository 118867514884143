import keyMirror from 'keymirror';

const LanguagesListPageConstants = keyMirror({
  LOAD_LANGUAGES_START: null,
  LOAD_LANGUAGES_SUCCESS: null,

  FILTER_LANGUAGES_START: null,
  FILTER_LANGUAGES_SUCCESS: null,

  CREATE_LANGUAGE_START: null,
  CREATE_LANGUAGE_SUCCESS: null,
  CREATE_LANGUAGE_ERROR: null,

  BULK_DESTROY_LANGUAGES_START: null,
  BULK_DESTROY_LANGUAGES_SUCCESS: null,
});

export default LanguagesListPageConstants;
