import SubscriptionConfigurationContainerConstants from './SubscriptionConfigurationContainerConstants';

const initialState = {
  subscriptionConfiguration: {},
  loading: false,
};

function SubscriptionConfigurationContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SubscriptionConfigurationContainerConstants.LOAD_SUBSCRIPTION_CONFIGURATION_START: {
      return { ...state, loading: true };
    }
    case SubscriptionConfigurationContainerConstants.LOAD_SUBSCRIPTION_CONFIGURATION_SUCCESS: {
      const { subscriptionConfiguration } = action;

      return { ...state, loading: false, subscriptionConfiguration };
    }
    case SubscriptionConfigurationContainerConstants.LOAD_SUBSCRIPTION_CONFIGURATION_ERROR: {
      return { ...state, loading: false };
    }
    case SubscriptionConfigurationContainerConstants.UPDATE_SUBSCRIPTION_CONFIGURATION_START: {
      return { ...state, loading: true };
    }
    case SubscriptionConfigurationContainerConstants.UPDATE_SUBSCRIPTION_CONFIGURATION_SUCCESS: {
      const { subscriptionConfiguration } = action;

      return { ...state, loading: false, subscriptionConfiguration };
    }
    case SubscriptionConfigurationContainerConstants.UPDATE_SUBSCRIPTION_CONFIGURATION_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}

export default SubscriptionConfigurationContainerReducer;
