import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { Box } from 'grommet';
import { NumberInput } from 'grommet-controls';

import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import Modal from 'components/Modal';
import Select from 'components/Select';

import styles from './EditorWorkModal.module.css';

import EditorWorkPresenter, { PROFILE_TASK_TYPES, ORGANIZATION_TASK_TYPES } from 'presenters/EditorWorkPresenter';

const PROFILE_TASK_TYPES_OPTIONS = PROFILE_TASK_TYPES.map((r) => ({ label: r.title, value: r.key }));
const ORGANIZATION_TASK_TYPES_OPTIONS = ORGANIZATION_TASK_TYPES.map((r) => ({ label: r.title, value: r.key }));
const OPTIONS = {
  profile: PROFILE_TASK_TYPES_OPTIONS,
  organization: ORGANIZATION_TASK_TYPES_OPTIONS,
};

const getOptions = (kind) => OPTIONS[kind];

const EditorWorkModal = (props) => {
  const { onSubmit, onClose, saving, title, kind, editorWork } = props;
  const { description, taskType, hours } = editorWork;

  const [newDescription, setDescription] = useState(description);
  const [newTaskType, setTaskType] = useState(taskType);
  const [newHours, setHours] = useState(hours);
  const [errors, setErrors] = useState(null);

  const handleTextChange = ({ target: { value } }) => setDescription(value);
  const handleTaskTypeChange = (value) => setTaskType(value);
  const handleHoursChange = ({ target: { value } }) => setHours(value);

  const isOrganizationOther = EditorWorkPresenter.isOrganizationOther(newTaskType);

  const handleSubmit = () => {
    onSubmit({ description: newDescription, taskType: newTaskType, hours: newHours })
      .then(() => {
        onClose();
      })
      .catch((data) => {
        setErrors(data.errors);
      });
  };

  return (
    <Modal onClose={onClose} size="medium" header={title} overflow>
      <div>
        <Select
          placeholder="Task Type"
          onValueChange={handleTaskTypeChange}
          selectedOptionValue={newTaskType}
          options={getOptions(kind)}
          errors={errors && errors.taskType}
        />

        {!isOrganizationOther && (
          <VerticalFormFieldWithBottomErrors label="Time Spent Working" errors={errors && errors.hours}>
            <NumberInput value={newHours} onChange={handleHoursChange} widgets={[]} />
          </VerticalFormFieldWithBottomErrors>
        )}

        <VerticalFormFieldWithBottomErrors label="Text:" errors={errors && errors.description}>
          <TextareaAutosize
            onChange={handleTextChange}
            value={newDescription}
            className={styles.textInput}
            maxRows={15}
          />
        </VerticalFormFieldWithBottomErrors>

        <Box direction="row" margin={{ top: 'small' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={handleSubmit} />
        </Box>
      </div>
    </Modal>
  );
};

EditorWorkModal.propTypes = {
  saving: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  editorWork: PropTypes.shape(),
};

EditorWorkModal.defaultProps = {
  editorWork: { description: '', taskType: '', hours: '' },
};

export default EditorWorkModal;
