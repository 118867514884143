import React from 'react';
import PropTypes from 'prop-types';

import { useAsyncDebounce } from 'utils/debounceUtils';

import AsyncSelect from 'components/AsyncSelect';
import Button from 'components/Button';
import Form, { Field, Input } from 'components/v2/Form';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import IndustryPresenter from 'presenters/IndustryPresenter';
import NaicsIndustryCodePresenter from 'presenters/NaicsIndustryCodePresenter';
import OrganizationForm from 'forms/OrganizationForm';

import styles from './NewOrganizationForm.module.css';

const NewOrganizationForm = (props) => {
  const { onNaicsIndustryCodeSearch, saving, onSubmit, onIndustrySearch, organization } = props;

  const handleSubmit = (attributes) => {
    const attributesForSubmit = OrganizationForm.attributesToSubmit(attributes);

    return onSubmit(attributesForSubmit);
  };

  const handleIndustrySearch = (searchText) => {
    const { industryName } = organization;
    const filters = { nameCont: industryName || searchText };

    return onIndustrySearch(filters);
  };

  const handleIndustrySearchDebounced = useAsyncDebounce(handleIndustrySearch, []);

  const handleNaicsIndustryCodeSearch = (searchText) => {
    const filters = { nameOrCodeCont: searchText };

    return onNaicsIndustryCodeSearch(filters);
  };

  const handleNaicsIndustryCodeSearchDebounced = useAsyncDebounce(handleNaicsIndustryCodeSearch, []);

  return (
    <Form initialValues={OrganizationForm.defaultAttributes(organization)} onSubmit={handleSubmit}>
      {({ values, errors, onFieldChange, onSubmit: onSubmitForm }) => (
        <>
          <div className={styles.field}>
            <Field title="Name" variant="outlined" error={errors.name}>
              <Input value={values.name} onChange={(value) => onFieldChange('name', value)} />
            </Field>
          </div>

          <div className={styles.field}>
            <Field title="Ticker" variant="outlined" error={errors.ticker}>
              <Input value={values.ticker} onChange={(value) => onFieldChange('ticker', value)} />
            </Field>
          </div>

          <AsyncSelect
            errors={errors.industry}
            loadOptions={handleIndustrySearchDebounced}
            placeholder="Industry:"
            defaultOptions
            value={values.industry}
            onChange={(value) => onFieldChange('industry', value)}
            getOptionValue={IndustryPresenter.id}
            getOptionLabel={IndustryPresenter.name}
          />

          <AsyncSelect
            errors={errors.naicsIndustryCode}
            placeholder="NAICS Industry Code:"
            loadOptions={handleNaicsIndustryCodeSearchDebounced}
            defaultOptions
            value={values.naicsIndustryCode}
            onChange={(value) => onFieldChange('naicsIndustryCode', value)}
            getOptionValue={NaicsIndustryCodePresenter.id}
            getOptionLabel={NaicsIndustryCodePresenter.title}
          />

          <div className={styles.field}>
            <Field title="Fortune Rank" variant="outlined" error={errors.fortuneRank}>
              <Input value={values.fortuneRank} onChange={(value) => onFieldChange('fortuneRank', value)} />
            </Field>
          </div>

          <div className={styles.field}>
            <Field title="FTSE Rank" variant="outlined" error={errors.ftseRank}>
              <Input value={values.ftseRank} onChange={(value) => onFieldChange('ftseRank', value)} />
            </Field>
          </div>

          <div className={styles.field}>
            <Field title="Global 2000 Rank" variant="outlined" error={errors.global2000Rank}>
              <Input value={values.global2000Rank} onChange={(value) => onFieldChange('global2000Rank', value)} />
            </Field>
          </div>

          <div className={styles.field}>
            <Field title="corporate DUNS#" variant="outlined" error={errors.corporateDuns}>
              <Input value={values.corporateDuns} onChange={(value) => onFieldChange('corporateDuns', value)} />
            </Field>
          </div>

          <div className={styles.submitButton}>
            <Button label="Save" disabled={saving} primary onClick={onSubmitForm} />
          </div>
        </>
      )}
    </Form>
  );
};

NewOrganizationForm.propTypes = {
  onIndustrySearch: PropTypes.func.isRequired,
  onNaicsIndustryCodeSearch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organization: OrganizationPresenter.shape(),
  saving: PropTypes.bool.isRequired,
};

NewOrganizationForm.defaultProps = {
  organization: {},
};

export default NewOrganizationForm;
