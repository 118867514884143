import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  update(profileId, topicId, topic) {
    const url = Routes.apiV1AdminProfileDraftTopicPath(profileId, topicId);
    return FetchHelpers.patch(url, { topic });
  },

  create(profileId, topic) {
    const url = Routes.apiV1AdminProfileDraftTopicsPath(profileId);
    return FetchHelpers.post(url, { topic });
  },

  destroy(profileId, topicId) {
    const url = Routes.apiV1AdminProfileDraftTopicPath(profileId, topicId);
    return FetchHelpers.delete(url);
  },
};
