import { buildUrl } from 'utils/UrlHelper';

import Companies from './Companies';

export default {
  adminRootPath: () => '/admin',

  adminProfilesPath: (query) => buildUrl('/admin/profiles', query),
  editAdminProfilePath: (id) => `/admin/profiles/${id}/edit`,

  adminUsersPath: () => '/admin/users',
  editAdminUserPath: (id) => `/admin/users/${id}/edit`,

  adminProfileRequestsPath: (query) => buildUrl('/admin/profile-requests', query),

  adminOrganizationsPath: () => '/admin/organizations',
  editAdminOrganizationPath: (id) => `/admin/organizations/${id}/edit`,

  adminInterestsPath: () => '/admin/interests',
  editAdminInterestPath: (id) => `/admin/interests/${id}/edit`,

  adminInstitutionsPath: () => '/admin/institutions',
  editAdminInstitutionPath: (id) => `/admin/institutions/${id}/edit`,

  adminFullProfileRequestsPath: () => '/admin/full-profile-requests',

  adminCountriesPath: () => '/admin/countries',
  editAdminCountryPath: (id) => `/admin/countries/${id}/edit`,

  adminLanguagesPath: () => '/admin/languages',
  editAdminLanguagePath: (id) => `/admin/languages/${id}/edit`,

  adminTagListPath: () => '/admin/tags',
  editAdminTagPath: (id) => `/admin/tags/${id}/edit`,

  adminDashboard: () => `/admin/dashboard`,
  adminDashboardProduction: () => `/admin/dashboard/production`,
  adminDashboardProductivity: () => `/admin/dashboard/productivity`,
  adminDashboardTeamProductivity: () => `/admin/dashboard/team-productivity`,
  adminDashboardDatabaseAnalytics: () => `/admin/dashboard/database-analytics`,
  adminDashboardCustomerUsageDashboard: () => `/admin/dashboard/customer-usage-dashboard`,
  adminDashboardExpiringCustoms: () => `/admin/dashboard/expiring-customs`,

  adminSamplesPath: () => '/admin/samples',
  adminSamplesProfilesPath: () => '/admin/samples/profiles',
  adminSamplesProjectsPath: () => '/admin/samples/projects',
  adminSamplesSavedSearchesPath: () => '/admin/samples/saved-searches',

  ...Companies,
};
