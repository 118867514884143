import PropTypes from 'prop-types';
import React from 'react';
import { itself } from 'utils/HelperMethods';

import Typography from 'components/v2/Typography';
import { Field, CreatableSelect, Textarea } from 'components/v2/Form';

import styles from './CommonFields.module.css';

const CommonFields = (props) => {
  const { errors, onFieldChange, values } = props;
  const { recipientEmails, helpRequest } = values;

  const handleFieldChange = (fieldName) => (value) => onFieldChange(fieldName, value);

  return (
    <div>
      <div className={styles.row}>
        <Typography variant="body1">Enter the email address where to send the results</Typography>
        <Field error={errors.recipientEmails} variant="outlined">
          <CreatableSelect
            createOptionKeys={['Enter', 'Tab', ' ', ',']}
            createOptionOnBlur
            getOptionLabel={itself}
            getOptionValue={itself}
            getNewOptionData={itself}
            isMulti
            menuIsOpen={false}
            onChange={handleFieldChange('recipientEmails')}
            placeholder="Enter your email"
            value={recipientEmails}
          />
        </Field>
      </div>
      <div className={styles.row}>
        <Typography variant="body1">
          If you have a deadline or want us to prioritize certain accounts/requests, let us know and we will do our best
          to meet your needs. Requests of 10 or less profiles will be delivered within 2 business days. Larger orders
          may take longer.
        </Typography>
        <Field error={errors.helpRequest?.message} variant="outlined">
          <Textarea
            className={styles.helpRequestMessage}
            onChange={handleFieldChange('helpRequest.message')}
            placeholder="Let us know how we can prioritize your requests"
            value={helpRequest.message}
          />
        </Field>
      </div>
    </div>
  );
};

CommonFields.propTypes = {
  errors: PropTypes.shape,
  onFieldChange: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
};

CommonFields.defaultProps = {
  errors: null,
};

export default CommonFields;
