import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import ExportButton from 'containers/UserPanel/components/ExportButton';
import Sidebar from 'containers/UserPanel/components/Sidebar';
import ProjectProfilesList from './containers/ProjectProfilesList';
import PageOverlay from 'containers/UserPanel/components/PageOverlay';

import ProjectPresenter from 'presenters/ProjectPresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import styles from './ProjectProfilesListSidebar.module.css';

export default class ProjectProfilesListSidebar extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    project: ProjectPresenter.shape().isRequired,
    onClose: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    profilePathQuery: PropTypes.shape({}),
    onExport: PropTypes.func,
  };

  isExportEnabled = () => {
    const { onExport } = this.props;

    return isPresent(onExport);
  };

  render() {
    const { onClose, onExport, project, filters, title, profilePathQuery } = this.props;

    return (
      <PageOverlay>
        <Sidebar onClose={onClose}>
          <div className={styles.container}>
            <h2 className={styles.title}> {title}</h2>
            <div className={styles.actions}>
              {this.isExportEnabled() && (
                <BackgroundExportResultNotifier
                  className={styles.export}
                  component={ExportButton}
                  onExport={onExport}
                  onExportPropName="onClick"
                  disabled={!ProjectPolicy.canExport(project)}
                  label="Share"
                />
              )}
            </div>
            <ProjectProfilesList project={project} filters={filters} profilePathQuery={profilePathQuery} />
          </div>
        </Sidebar>
      </PageOverlay>
    );
  }
}
