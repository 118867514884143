import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserCards from './UserCards';
import * as actions from './UserCardsActions';

const mapStateToProps = (state) => ({
  loading: state.UserCardsReducer.loading,
  profiles: state.UserCardsReducer.profiles,
  saving: state.UserCardsReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserCards);
