import React from 'react';
import PropTypes from 'prop-types';

import RawHtml from 'components/RawHtml';
import Spoiler from 'components/Spoiler';

import styles from './WysiwygSection.module.css';

const WysiwygSection = (props) => {
  const { header, html } = props;

  return (
    <section>
      <Spoiler header={header}>
        <RawHtml component="p" className={styles.text} innerHTML={html} />
      </Spoiler>
    </section>
  );
};

WysiwygSection.propTypes = {
  header: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

export default WysiwygSection;
