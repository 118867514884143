import { clientRequestsHelpRequestRestHooks } from 'slices/client/requestsHelpRequestSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useRequestsHelpRequest = () => {
  const { requestsHelpRequest, createRequestsHelpRequest, destroyRequestsHelpRequest } =
    clientRequestsHelpRequestRestHooks.use();

  return {
    requestsHelpRequest: requestsHelpRequest.item,
    requestsHelpRequestCreatingStatus: useFetchStatus(requestsHelpRequest.creatingStatus),
    requestsHelpRequestDestroyingStatus: useFetchStatus(requestsHelpRequest.destroyingStatus),
    createRequestsHelpRequest,
    destroyRequestsHelpRequest,
  };
};

export default useRequestsHelpRequest;
