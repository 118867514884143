import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(userId) {
    const url = Routes.apiV1AdminImpersonalizationPath();
    return FetchHelpers.post(url, { userId });
  },

  destroy() {
    const url = Routes.apiV1AdminImpersonalizationPath();
    return FetchHelpers.delete(url);
  },
};
