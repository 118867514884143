import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    kind: PropTypes.string,
    subject: PropTypes.shape(),
    subjectId: PropTypes.number,
    subjectType: PropTypes.string,
  },
  {
    isOrganizationSubscription(subscription) {
      return this.subjectType(subscription) === 'Organization';
    },

    isSubscriptionForOrganization(subscription, organization) {
      return this.isOrganizationSubscription(subscription) && this.subjectId(subscription) === organization.id;
    },

    isSubscriptionForProfile(subscription, profile) {
      return this.isProfileSubscription(subscription) && this.subjectId(subscription) === profile.id;
    },

    isProfileSubscription(subscription) {
      return this.subjectType(subscription) === 'Profile';
    },
  },
);
