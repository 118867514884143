import keyMirror from 'keymirror';

const AdminSavedSearchesConstants = keyMirror({
  FILTER_ADMIN_SAVED_SEARCHES_START: null,
  FILTER_ADMIN_SAVED_SEARCHES_SUCCESS: null,

  LOAD_ADMIN_SAVED_SEARCHES_START: null,
  LOAD_ADMIN_SAVED_SEARCHES_SUCCESS: null,
});

export default AdminSavedSearchesConstants;
