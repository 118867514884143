import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TopRelationshipsToMyExecutives from './TopRelationshipsToMyExecutives';
import * as actions from './TopRelationshipsToMyExecutivesActions';

const mapStateToProps = (state) => ({
  loading: state.TopRelationshipsToMyExecutivesReducer.loading,
  relationshipsMapping: state.TopRelationshipsToMyExecutivesReducer.relationshipsMapping,
  userColleaguesExists: state.TopRelationshipsToMyExecutivesReducer.userColleaguesExists,
  userBoardMembersExists: state.TopRelationshipsToMyExecutivesReducer.userBoardMembersExists,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopRelationshipsToMyExecutives);
