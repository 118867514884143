import { buildFilterParams } from 'utils/FilterParamsBuilder';
import ProfileListConstants from './ProfileListConstants';
import ProfilesRepository from 'repositories/admin/ProfilesRepository';

export function loadProfiles(page, filters) {
  return (dispatch) => {
    dispatch({ type: ProfileListConstants.PROFILES_LOAD });

    const params = { page, ...buildFilterParams(filters) };
    return ProfilesRepository.index(params).then(({ items, meta }) => {
      dispatch({
        type: ProfileListConstants.PROFILES_LOAD_SUCCESS,
        profiles: items,
        ...meta,
      });
    });
  };
}

export function filterProfiles(filters) {
  return (dispatch) => {
    dispatch({ type: ProfileListConstants.PROFILES_FILTER, filters });

    const params = buildFilterParams(filters);
    return ProfilesRepository.index(params).then(({ items, meta }) => {
      dispatch({ type: ProfileListConstants.PROFILES_FILTER_SUCCESS, profiles: items, filters, ...meta });
    });
  };
}

export function bulkDestroyProfiles(params) {
  return (dispatch) => {
    dispatch({ type: ProfileListConstants.PROFILES_BULK_DESTROY });

    return ProfilesRepository.bulkDestroy(params).then(() => {
      dispatch({ type: ProfileListConstants.PROFILES_BULK_DESTROY_SUCCESS });
    });
  };
}
