import React, { Component } from 'react';
import AudienceInsightPresenter from 'presenters/Project/AudienceInsightPresenter';
import { isBlank } from 'utils/HelperMethods';
import styles from './LineChart.module.css';

const colors = ['#00CE34', '#F8E71C'];

export default class LineChart extends Component {
  static propTypes = {
    data: AudienceInsightPresenter.shape(),
  };

  renderChart = () => {
    const {
      data: { genders },
    } = this.props;

    if (isBlank(genders)) {
      return <div className={styles.empty} />;
    }

    return genders.map(({ title, percentOfTotal }, index) => (
      <div className={styles.wrapper} key={`${index}${percentOfTotal}`}>
        <div
          className={styles.item}
          style={{ backgroundColor: colors[index], width: `${percentOfTotal}%` }}
          title={`${title}, ${percentOfTotal}%`}
          data-value={percentOfTotal}
        />
        <div className={styles.data}>
          <div className={styles.title}>{title}</div>
          <div className={styles.value}>{percentOfTotal}%</div>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.chart}>{this.renderChart()}</div>
      </div>
    );
  }
}
