import { pick, propOr, defaultTo } from 'ramda';

export default {
  defaultAttributes(institution = {}) {
    return {
      ...institution,
      degree: defaultTo('', institution.degree),
    };
  },

  attributesToSubmit(institution) {
    const propsToSend = ['id', 'degree', 'to', 'from'];
    const { organization } = institution;

    return {
      ...pick(propsToSend, institution),
      organizationId: propOr(null, 'id', organization),
    };
  },
};
