import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { imagePath } from 'utils/AssetsHelper';
import { isBlank } from 'utils/HelperMethods';

import Button from 'components/Button';
import RawHtml from 'components/RawHtml';

import NewProjectForm from 'forms/NewProjectForm';

import { WizardTitle } from 'components/v2/FramedWizard';

import styles from './Final.module.css';

class Final extends Component {
  state = { errors: null };

  componentDidMount() {
    const { onCreateProject, form, saved } = this.props;

    if (!saved) {
      onCreateProject(NewProjectForm.attributesToSubmit(form)).catch(({ errors }) => this.setState({ errors }));
    }
  }

  renderLoader() {
    return (
      <div className={styles.loader}>
        <img className={styles.loaderImg} src={imagePath('project-creation-loader.gif')} alt="loading" />
      </div>
    );
  }

  renderSuccesefullyCreatedMessage() {
    const { project } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.title}>Your project has been successfully created.</div>
        <Button label="Go to my Project" primary href={Routes.projectPath(project.id)} className={styles.button} />
      </div>
    );
  }

  renderErrorMessage() {
    const { errors } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.title}>Cannot save the project.</div>
        <RawHtml className={styles.error} innerHTML={errors.base?.join(', ')} />
        <Button label="Return to Project" primary href={Routes.projectsPath()} className={styles.button} />
      </div>
    );
  }

  renderComponent() {
    const { saving, saved } = this.props;
    const { errors } = this.state;

    if ((!saved && isBlank(errors)) || saving) {
      return this.renderLoader();
    }

    if (saved) {
      return this.renderSuccesefullyCreatedMessage();
    }

    return this.renderErrorMessage();
  }

  render() {
    const { saving } = this.props;

    return (
      <>
        {saving && <WizardTitle title="Please wait, we are saving your project." />}
        {this.renderComponent()}
      </>
    );
  }
}

Final.propTypes = {
  onCreateProject: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  form: PropTypes.shape(),
  project: PropTypes.shape(),
};

export default Final;
