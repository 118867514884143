import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnchorLink from 'components/grommet/AnchorLink';
import Icon from 'components/Icon';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

import Routes from 'routes';

import styles from './OrganizationOption.module.css';

class OrganizationOption extends Component {
  static propTypes = {
    organization: OrganizationPresenter.shape(),
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { organization } = this.props;
    const photoUrl = OrganizationPresenter.logoUrl(organization);
    return (
      <AnchorLink
        to={Routes.searchPath({ currentOrganizationName: [OrganizationPresenter.name(organization)] })}
        className={styles.root}
        onClick={this.props.onClick}
      >
        <div className={styles.image}>
          {photoUrl ? (
            <img src={OrganizationPresenter.logoUrl(organization)} alt="userpic" className={styles.userpic} />
          ) : (
            <Icon name="organizationPlaceholder" />
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.text}>
            <span className={styles.info}>
              <strong className={styles.name} title={OrganizationPresenter.name(organization)}>
                {OrganizationPresenter.name(organization)}
              </strong>
            </span>
          </div>
        </div>
      </AnchorLink>
    );
  }
}

export default OrganizationOption;
