import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NewProjectWizard from './NewProjectWizard';
import * as actions from './NewProjectWizardActions';

const mapStateToProps = (state) => ({
  form: state.NewProjectWizardReducer.form,
  project: state.NewProjectWizardReducer.project,
  saving: state.NewProjectWizardReducer.saving,
  saved: state.NewProjectWizardReducer.saved,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectWizard);
