import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';
import { logClickOnAlertsRemoveFromAlerts } from 'utils/amplitude';

import Button from 'components/Button';
import AnchorLink from 'components/grommet/AnchorLink';
import OrganizationAvatar from 'components/OrganizationAvatar';

import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import Routes from 'routes';

import styles from './OrganizationSubscription.module.css';

class OrganizationSubscription extends Component {
  static propTypes = {
    subscription: SubscriptionPresenter.shape().isRequired,
    onUndoDelete: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  };

  state = {
    isDestroing: false,
    isUndo: false,
  };

  handleDelete = () => {
    const { subscription, onDelete } = this.props;
    const id = SubscriptionPresenter.id(subscription);

    this.setState({ isDestroing: true });

    onDelete(id).finally(() => {
      this.setState({ isDestroing: false });
    });

    logClickOnAlertsRemoveFromAlerts();
  };

  handleUndoDelete = () => {
    const { subscription, onUndoDelete } = this.props;
    this.setState({ isUndo: true });

    onUndoDelete(subscription).finally(() => {
      this.setState({ isUndo: false });
    });
  };

  renderActions() {
    const { isDestroyed } = this.props.subscription;
    const { isDestroing, isUndo } = this.state;

    if (isDestroyed) {
      return (
        <div>
          Successefully Removed.
          <Anchor className={styles.undoLink} disabled={isUndo} label="Undo" onClick={this.handleUndoDelete} />
        </div>
      );
    }

    return (
      <Button
        disabled={isDestroing}
        className={styles.removeButton}
        label="Remove From Alerts"
        onClick={this.handleDelete}
      />
    );
  }

  render() {
    const { subscription } = this.props;
    const { subject: organization } = subscription;
    const path = Routes.searchPath({ currentOrganizationName: [OrganizationPresenter.name(organization)] });

    return (
      <div className={styles.root}>
        <AnchorLink className={styles.organization} to={path}>
          <div className={styles.avatar}>
            <OrganizationAvatar src={OrganizationPresenter.logoUrl(organization)} width="92" height="92" />
          </div>
          <div className={styles.info}>
            <div className={styles.header}>{OrganizationPresenter.name(organization)}</div>
            <div className={styles.industries}>{OrganizationPresenter.industryName(organization)}</div>
          </div>
        </AnchorLink>
        <div className={styles.actions}>{this.renderActions()}</div>
      </div>
    );
  }
}

export default OrganizationSubscription;
