import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, sum, pluck, pipe, sortBy, path, reverse, toPairs, head } from 'ramda';
import { formatNumber } from 'utils/NumbersHelper';

import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

import styles from './TopRequestors.module.css';

const UNDEFINED_COMPANY_NAME = '(undefined company)';

const TopRequestors = (props) => {
  const { items } = props;
  const groupedByCompany = pipe(
    groupBy(path(['company', 'id'])),
    toPairs,
    sortBy(([, users]) => pipe(pluck('requestsCount'), sum)(users)),
    reverse,
  )(items);

  const renderUser = ({ requestsCount, ...user }) => {
    const userName = UserPresenter.fullName(user);

    return (
      <div className={styles.item} key={UserPresenter.id(user)}>
        <div className={styles.title} title={userName}>
          <span>{userName}</span>
        </div>
        <div className={styles.value}>{formatNumber(requestsCount)}</div>
      </div>
    );
  };

  const renderCompany = (item) => {
    const [, users] = item;
    const { company } = head(users);
    const companyName = CompanyPresenter.name(company) || UNDEFINED_COMPANY_NAME;

    return (
      <div className={styles.companyItem} key={companyName}>
        <strong>{companyName}:</strong>
        {users.map(renderUser)}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <h3 className={styles.header}>Top Requestors</h3>
      <div className={styles.list}>{groupedByCompany.map(renderCompany)}</div>
    </div>
  );
};

TopRequestors.propTypes = {
  items: PropTypes.arrayOf(UserPresenter.shape()).isRequired,
};

export default TopRequestors;
