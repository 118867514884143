import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import styles from './Header.module.css';

export default class Header extends Component {
  static propTypes = {
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.string.isRequired,
  };

  render() {
    const { description, error, title } = this.props;

    return (
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {isPresent(description) && <div className={styles.description}>{description}</div>}
        {isPresent(error) && <div className={styles.error}>{error}</div>}
      </div>
    );
  }
}
