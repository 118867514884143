import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1CurrentUserNotificationsPath(params);

    return FetchHelpers.get(url);
  },

  destroy(id) {
    const url = Routes.apiV1CurrentUserNotificationPath(id);

    return FetchHelpers.delete(url);
  },
};
