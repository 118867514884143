export default (options) => {
  const { isDropdownIndicatorVisible, isFieldChild, container: containerStyles, control: controlStyles } = options;

  return {
    container: (base) => ({
      ...base,
      position: isFieldChild ? 'static' : 'relative',
      width: '100%',
      ...containerStyles,
    }),

    control: (base) => ({
      ...base,
      boxShadow: 'none',
      paddingBottom: '0',
      border: 'none',
      height: 'auto',
      minHeight: 'inherit',
      position: 'static',
      fontSize: '14px',

      '&:hover': {
        border: 'none',
      },

      '& > div': {
        padding: 0,
      },

      '& > div > div': {
        padding: 0,
        margin: '2px',
      },
      ...controlStyles,
    }),

    clearIndicator: (base) => ({
      ...base,
      backgroundImage:
        'url("data:image/svg+xml, %3Csvg%20width%3D%2210%22%20height%3D%2210%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1%201l8%208M1%209l8-8%22%20stroke%3D%22%23666%22%20stroke-width%3D%221.2%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22/%3E%3C/svg%3E")',
      backgroundRepeat: 'no-repeat',
      width: '10px',
      height: '10px',
      padding: '0px',
      '> svg': {
        display: 'none',
      },
    }),

    dropdownIndicator: (base) => ({
      ...base,
      backgroundImage:
        'url("data:image/svg+xml, %3Csvg%20width%3D%2210%22%20height%3D%226%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M9%201L5%205%201%201%22%20stroke%3D%22%23444%22%20stroke-width%3D%221.2%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22/%3E%3C/svg%3E")',
      backgroundRepeat: 'no-repeat',
      width: '10px',
      height: '6px',
      padding: '0px',
      display: isDropdownIndicatorVisible ? 'flex' : 'none',
      '> svg': {
        display: 'none',
      },
    }),

    indicatorsContainer: (base) => ({
      ...base,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '12px',
      '> *:not(:last-child)': {
        marginRight: '8px',
      },
    }),

    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),

    input: (base) => ({
      ...base,
      maxWidth: '100%',
      fontWeight: 400,
    }),

    loadingMessage: (base) => ({
      ...base,
      fontSize: '14px',
    }),

    menu: (base) => ({
      ...base,
      borderRadius: 0,
      margin: 0,
      boxShadow: 'none',
      border: 'none',
      outline: '1px solid #dee0e3',
      left: 0,
      zIndex: '10',
    }),

    menuList: (base) => ({
      ...base,
      padding: 0,
      zIndex: 10,
    }),

    noOptionsMessage: (base) => ({
      ...base,
      fontSize: '14px',
      borderRadius: 0,
    }),

    option: (base, state) => ({
      ...base,
      paddingTop: '20px',
      paddingBottom: '20px',
      backgroundColor: state.isFocused ? '#f4f5f7' : 'transparent',
      color: '#000',
      fontSize: '14px',
      maxWidth: '100%',

      '&:hover': {
        backgroundColor: '#f4f5f7',
      },
    }),

    placeholder: (base) => ({
      ...base,
      color: 'rgba(51, 51, 51, .2)',
      fontWeight: 400,
    }),

    singleValue: (base) => ({
      ...base,
      top: 'auto',
      transform: 'none',
      position: 'static',
    }),
  };
};
