import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propEq } from 'ramda';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { formattedDate } from 'utils/DateTime';
import { logClickOnUserPanelDashboardQuestionMark } from 'utils/amplitude';

import Button from 'components/Button';
import DatabaseStatistics from './components/DatabaseStatistics';
import ChangedProjects from './components/ChangedProjects';
import ChangedSavedSearches from './components/ChangedSavedSearches';
import Guide from 'components/Guide';
import Projects from './components/Projects';
import SavedSearches from './components/SavedSearches';
import Spinner from 'components/Spinner';
import QuestionMarkButton from './components/QuestionMarkButton';
import RequestsHistory from 'containers/UserPanel/components/RequestsHistory';

import DashboardPresenter from 'presenters/DashboardPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';

import UserPolicy from 'policies/UserPolicy';

import Routes from 'routes';

import GUIDES, { GUIDES_NAMES } from './guides';

import styles from './DashboardPage.module.css';

class DashboardPage extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    dashboard: PropTypes.shape(),
    isLoading: PropTypes.bool.isRequired,
    loadDashboard: PropTypes.func.isRequired,
    markDashboardViewed: PropTypes.func.isRequired,
    filterRequestHistory: PropTypes.func.isRequired,
    currenUserRequestsHistory: PropTypes.arrayOf(RequestPresenter.shape()).isRequired,
    currenUserRequestsHistoryMeta: PropTypes.shape().isRequired,
    loadingCurrenUserRequestsHistory: PropTypes.bool.isRequired,
    currenUserRequestsHistoryFilters: PropTypes.shape(),
  };

  state = { showAllGuides: false, activeGuideIndex: null };

  componentDidMount() {
    const { currentUser, loadDashboard, markDashboardViewed } = this.props;

    loadDashboard().then(({ meta }) => {
      const isRealUser = !CurrentUserPresenter.isImpersonating(currentUser);
      const showAllGuides = meta.showGuides && isRealUser;
      const activeGuideIndex = showAllGuides ? 0 : null;

      if (isRealUser) {
        markDashboardViewed();
      }

      this.setState({ showAllGuides, activeGuideIndex });
    });
  }

  handleOpenGuide = (guideName) => {
    const guides = this.availableGuides();
    const activeGuideIndex = guides.findIndex(propEq(guideName, 'name'));

    this.setState({ showAllGuides: false, activeGuideIndex });
    logClickOnUserPanelDashboardQuestionMark();
  };

  handleCloseGuide = () => {
    const { showAllGuides, activeGuideIndex } = this.state;

    if (!showAllGuides) {
      this.setState({ activeGuideIndex: null });
      return;
    }

    const guides = this.availableGuides();

    if (activeGuideIndex + 1 < guides.length) {
      this.setState({ activeGuideIndex: activeGuideIndex + 1 });
    } else {
      this.setState({ showAllGuides: false, activeGuideIndex: null });
    }
  };

  availableGuides() {
    return GUIDES.filter(({ isAvailable }) => isAvailable(this.props));
  }

  renderGuide() {
    const { activeGuideIndex } = this.state;
    const guides = this.availableGuides();

    if (isBlank(activeGuideIndex)) {
      return null;
    }

    return (
      <Guide key={activeGuideIndex} {...guides[activeGuideIndex].guideProps} onClose={this.handleCloseGuide}>
        {guides[activeGuideIndex].children}
      </Guide>
    );
  }

  render() {
    const {
      currentUser,
      dashboard,
      isLoading,
      currenUserRequestsHistory,
      loadingCurrenUserRequestsHistory,
      filterRequestHistory,
      currenUserRequestsHistoryFilters,
    } = this.props;

    const isRequestPresent = currenUserRequestsHistory.length > 0;

    if (isBlank(dashboard) || isLoading) {
      return <Spinner />;
    }

    const statisticsStartedAt = DashboardPresenter.statisticsStartedAt(dashboard);
    const changedProjects = DashboardPresenter.changedProjects(dashboard);
    const changedSavedSearches = DashboardPresenter.changedSavedSearches(dashboard);
    const projects = DashboardPresenter.projects(dashboard);
    const savedSearches = DashboardPresenter.savedSearches(dashboard);

    return (
      <div className={styles.root}>
        <div className={styles.header}>Welcome, {CurrentUserPresenter.firstName(currentUser)}!</div>
        <div className={styles.info}>
          <span>
            The following changes have been made since your last visit on {formattedDate(statisticsStartedAt)}.
          </span>

          <div className={styles.searchActions}>
            <QuestionMarkButton onClick={() => this.handleOpenGuide(GUIDES_NAMES.SEARCH_GUIDE)} />
            <Button
              id={GUIDES_NAMES.SEARCH_GUIDE}
              className={styles.searchButton}
              label="Start New Search"
              href={Routes.searchPath()}
              primary
            />
          </div>
        </div>
        {changedProjects.length > 0 && (
          <div className={styles.details}>
            <div className={styles.detailsTitle}>
              Recent Changes: Your Projects
              <QuestionMarkButton onClick={() => this.handleOpenGuide(GUIDES_NAMES.PROJECTS_GUIDE)} />
            </div>
            <div id={GUIDES_NAMES.PROJECTS_GUIDE}>
              <ChangedProjects currentUser={currentUser} projects={changedProjects} />
            </div>
          </div>
        )}
        {projects.length > 0 && (
          <div className={styles.details}>
            <div className={styles.detailsTitle}>Most Recent Projects</div>
            <Projects currentUser={currentUser} projects={projects} />
          </div>
        )}
        {changedSavedSearches.length > 0 && (
          <div className={styles.details}>
            <div className={styles.detailsTitle}>
              Recent Changes: Your Saved Searches
              <QuestionMarkButton onClick={() => this.handleOpenGuide(GUIDES_NAMES.SAVED_SEARCHES_GUIDE)} />
            </div>
            <div id={GUIDES_NAMES.SAVED_SEARCHES_GUIDE}>
              <ChangedSavedSearches savedSearches={changedSavedSearches} statisticsStartedAt={statisticsStartedAt} />
            </div>
          </div>
        )}
        {savedSearches.length > 0 && (
          <div className={styles.details}>
            <div className={styles.detailsTitle}>Most Recent Saved Searches</div>
            <SavedSearches savedSearches={savedSearches} />
          </div>
        )}
        <div id={GUIDES_NAMES.ENTIRE_DATABASE_GUIDE} className={styles.details}>
          <div className={styles.detailsTitle}>
            Changes Last 90 Days: Entire Database
            <QuestionMarkButton onClick={() => this.handleOpenGuide(GUIDES_NAMES.ENTIRE_DATABASE_GUIDE)} />
          </div>
          <DatabaseStatistics database={DashboardPresenter.database(dashboard)} />
        </div>

        {UserPolicy.canRequestProfile(currentUser) && (
          <div id={GUIDES_NAMES.RECENT_REQUESTS_GUIDE} className={styles.details}>
            <RequestsHistory
              title={
                <div className={styles.detailsTitle}>
                  Recent requests
                  <QuestionMarkButton onClick={() => this.handleOpenGuide(GUIDES_NAMES.RECENT_REQUESTS_GUIDE)} />
                </div>
              }
              loading={loadingCurrenUserRequestsHistory}
              requests={currenUserRequestsHistory}
              filterRequest={filterRequestHistory}
              filters={currenUserRequestsHistoryFilters}
            />
            <div className={styles.actions}>
              {!loadingCurrenUserRequestsHistory && isRequestPresent && (
                <Button className={styles.button} label="See More" href={Routes.newRequestPath()} />
              )}
              {loadingCurrenUserRequestsHistory && <Spinner />}
            </div>
          </div>
        )}

        {!isLoading && isPresent(dashboard) && this.renderGuide()}
      </div>
    );
  }
}

export default DashboardPage;
