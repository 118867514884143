import CompanyDepartmentUserRegistrationConstants from './CompanyDepartmentUserRegistrationConstants';
import CompanyDepartmentsRepository from 'repositories/Company/DepartmentsRepository';
import CompanyDepartmentUsersRepository from 'repositories/Company/Department/UsersRepository';

export function loadCompanyDepartment(companyId, departmentId) {
  return (dispatch) => {
    dispatch({ type: CompanyDepartmentUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_DEPARTMENT_START });

    return CompanyDepartmentsRepository.show(companyId, departmentId)
      .then(({ companyDepartment }) => {
        dispatch({
          type: CompanyDepartmentUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_DEPARTMENT_SUCCESS,
          companyDepartment,
        });

        return companyDepartment;
      })
      .catch((error) => {
        dispatch({ type: CompanyDepartmentUserRegistrationConstants.LOAD_REGISTRATION_COMPANY_DEPARTMENT_ERROR });

        throw error;
      });
  };
}

export function createCompanyDepartmentUser(companyId, departmentId, params) {
  return (dispatch) => {
    dispatch({ type: CompanyDepartmentUserRegistrationConstants.CREATE_COMPANY_DEPARTMENT_USER_START });

    return CompanyDepartmentUsersRepository.create(companyId, departmentId, params)
      .then(({ user, meta }) => {
        dispatch({ type: CompanyDepartmentUserRegistrationConstants.CREATE_COMPANY_DEPARTMENT_USER_SUCCESS, user });

        return { user, meta };
      })
      .catch((error) => {
        dispatch({ type: CompanyDepartmentUserRegistrationConstants.CREATE_COMPANY_DEPARTMENT_USER_ERROR });

        throw error;
      });
  };
}
