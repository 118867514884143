import keyMirror from 'keymirror';

const ProjectMatchedOrganizationsWizardStepConstants = keyMirror({
  MATCH_PROJECT_ORGANIZATIONS_START: null,
  MATCH_PROJECT_ORGANIZATIONS_SUCCESS: null,
  MATCH_PROJECT_ORGANIZATIONS_ERROR: null,

  TOGGLE_PROJECT_ORGANIZATIONS_DETAILS_VIEW: null,
});

export default ProjectMatchedOrganizationsWizardStepConstants;
