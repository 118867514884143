import { pick, prop } from 'ramda';

export default {
  defaultAttributes(topic = {}) {
    return {
      description: '',
      jobFunctionIds: [],
      tags: [],
      title: '',
      pinned: false,
      ...topic,
    };
  },

  attributesToSubmit(topic) {
    const propsToSend = ['id', 'allJobFunctions', 'jobFunctionIds', 'title', 'description', 'pinned', 'kind', 'tagIds'];

    return {
      ...pick(propsToSend, topic),
      tagIds: topic.tags.map(prop('id')),
    };
  },
};
