import RelationshipsMappingPageConstants from './RelationshipsMappingPageConstants';

const initialState = {
  loading: false,
  relationshipsMapping: null,
  information: null,
  saving: false,
};

function RelationshipsMappingPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RelationshipsMappingPageConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_START: {
      return { ...initialState, loading: true };
    }
    case RelationshipsMappingPageConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_SUCCESS: {
      const { relationshipsMapping, information } = action;

      return { ...state, loading: false, relationshipsMapping, information };
    }
    case RelationshipsMappingPageConstants.CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_START: {
      return { ...state, saving: true };
    }
    case RelationshipsMappingPageConstants.CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_SUCCESS: {
      return { ...state, saving: false };
    }
    case RelationshipsMappingPageConstants.CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default RelationshipsMappingPageReducer;
