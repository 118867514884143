import React from 'react';

import SearchIcon from 'icons/SearchIcon';

import { Input } from 'components/v2/Form';

import styles from './SearchInput.module.css';

const SearchInput = (props) => (
  <Input {...props} className={styles.searchInput} suffix={<SearchIcon width={12} height={12} />} />
);

export default SearchInput;
