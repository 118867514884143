import { pick, propOr } from 'ramda';

import SourceForm from './project/SourceForm';

export default {
  defaultAttributes(attributes) {
    return { name: '', sourcesAttributes: [], ...attributes };
  },

  attributesToSubmit(project) {
    const propsToSend = ['name', 'sourcesAttributes'];
    const sourcesAttributes = propOr([], 'sourcesAttributes', project).map((source) =>
      SourceForm.attributesToSubmit(source),
    );

    return pick(propsToSend, { ...project, sourcesAttributes });
  },
};
