/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isBlank } from 'utils/HelperMethods';

import { buildFilterParams } from 'utils/FilterParamsBuilder';
import RequestsStatisticRepository from 'repositories/admin/EditorialDashboard/Productivity/RequestsStatisticRepository';
import TopRequestorsRepository from 'repositories/admin/EditorialDashboard/Productivity/TopRequestorsRepository';

const initialState = {
  loading: false,
  requestsStatistics: null,
  topRequestors: [],
};

export function getPartialState(state, name) {
  if (isBlank(state[name])) {
    return initialState;
  }
  return state[name];
}

function updatePartialState(state, name, update) {
  const partialState = getPartialState(state, name);

  return { ...state, [name]: update(partialState) };
}

const productivityStatistics = createSlice({
  name: 'productivityStatistics',
  initialState: {},
  reducers: {
    loadProductivityStart(state, { payload }) {
      const { name } = payload;

      return updatePartialState(state, name, (partialState) => ({ ...partialState, loading: true }));
    },
    loadProductivitySuccess(state, { payload }) {
      const { requestsStatistics, name } = payload;

      return updatePartialState(state, name, (partialState) => ({
        ...partialState,
        loading: false,
        requestsStatistics,
      }));
    },
    loadProductivityTopRequestorsStart(state, { payload }) {
      const { name } = payload;

      return updatePartialState(state, name, (partialState) => ({ ...partialState, loading: true }));
    },
    loadProductivityTopRequestorsSuccess(state, { payload }) {
      const { items: topRequestors, name } = payload;

      return updatePartialState(state, name, (partialState) => ({
        ...partialState,
        loading: false,
        topRequestors,
      }));
    },
  },
});

const { actions } = productivityStatistics;

export function loadProductivity(name, statisticsFilter, userFilter) {
  return (dispatch) => {
    dispatch(actions.loadProductivityStart({ name }));

    const query = { ...statisticsFilter, ...buildFilterParams(userFilter) };
    return RequestsStatisticRepository.show(query).then(({ requestsStatistics }) => {
      dispatch(actions.loadProductivitySuccess({ requestsStatistics, name }));
    });
  };
}

export function loadProductivityTopRequestors(name, statisticsFilter, userFilter) {
  return (dispatch) => {
    dispatch(actions.loadProductivityTopRequestorsStart({ name }));

    const query = { ...statisticsFilter, ...buildFilterParams(userFilter) };
    return TopRequestorsRepository.index(query).then(({ items }) => {
      dispatch(actions.loadProductivityTopRequestorsSuccess({ items, name }));
    });
  };
}

export const useProductivityStatisticsActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      loadProductivity,
      loadProductivityTopRequestors,
    },
    dispatch,
  );
};

export default productivityStatistics.reducer;
