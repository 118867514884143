import React from 'react';

import { useAlert } from 'hooks';
import { useAdminCompanies } from 'hooks/admin';

import CompanyEditForm from 'forms/CompanyEditForm';

import Form from 'components/v2/Form';

import DepartmentList from './components/DepartmentList';
import SubscriptionSettingsEditForm from './components/SubscriptionSettingsEditForm';

const SubscriptionSettingsEditTab = () => {
  const alert = useAlert();
  const { company, updateCompany } = useAdminCompanies();

  const handleCompanyUpdate = async (attributes) => {
    await updateCompany(company.id, CompanyEditForm.attributesToSubmit(attributes));

    alert.successAction('Subscription Settings successfully updated');
  };

  return (
    <div>
      <Form
        component={SubscriptionSettingsEditForm}
        enableReinitialize
        initialValues={CompanyEditForm.defaultAttributes(company)}
        onSubmit={handleCompanyUpdate}
      />
      <DepartmentList />
    </div>
  );
};

export default SubscriptionSettingsEditTab;
