import React, { Component } from 'react';
import { values } from 'ramda';
import { scrollToAnchor } from 'components/ScrollableAnchor';

import { Anchor } from 'grommet';
import { SECTIONS } from 'containers/UserPanel/components/Profile/components/Summary';

import ProfilePresenter from 'presenters/ProfilePresenter';

import styles from './RecentlyChangedSections.module.css';

export function recentlyChangedSections(profile) {
  return values(SECTIONS).filter((section) => section.isRecentlyUpdated(profile));
}

class RecentlyChangedSections extends Component {
  static propTypes = {
    profile: ProfilePresenter.shape(),
  };

  render() {
    const { profile } = this.props;

    return (
      <div className={styles.root}>
        {recentlyChangedSections(profile).map(({ name, id }) => (
          <Anchor className={styles.link} key={id} onClick={() => scrollToAnchor(id)} label={name} />
        ))}
      </div>
    );
  }
}

export default RecentlyChangedSections;
