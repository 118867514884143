import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'grommet';

import { isBlank } from 'utils/HelperMethods';

import RawHtml from 'components/RawHtml';
import Spinner from 'components/Spinner';
import UserRegistrationForm from './components/UserRegistrationForm';

import CompanyPresenter from 'presenters/CompanyPresenter';

import Routes from 'routes';

import styles from './CompanyUserRegistration.module.css';

class CompanyUserRegistration extends Component {
  componentDidMount() {
    const { companyId, loadCompany } = this.props;

    loadCompany(companyId);
  }

  handleSubmit = (params) => {
    const { companyId, createCompanyDepartmentUser, history } = this.props;

    return createCompanyDepartmentUser(companyId, params.companyDepartmentId, params).then(({ meta }) => {
      history.push(Routes.signInPath({ twoFactorAuthentication: meta.twoFactorAuthentication }));
    });
  };

  renderRegistrationFormVideoCode() {
    const { company } = this.props;

    const registrationFormVideoCode = CompanyPresenter.registrationFormVideoCode(company);

    if (isBlank(registrationFormVideoCode)) {
      return null;
    }

    return (
      <>
        <Heading level="2" className={styles.header}>
          Watch Our Video
        </Heading>
        <div className={styles.video}>
          <RawHtml component="div" innerHTML={registrationFormVideoCode} additionalWhitelistedTags={['iframe']} />
        </div>
      </>
    );
  }

  render() {
    const { company, loading, saving } = this.props;

    if (loading || !company) {
      return <Spinner />;
    }

    if (CompanyPresenter.remainingSeatsNumber(company) <= 0) {
      return (
        <div className={styles.self}>
          <div className={styles.media}>
            <Heading level="2" className={styles.notification}>
              Sorry, all the seat licenses for your company have been filled.
              <br />
              Please contact{' '}
              <a href="mailto:customerservice@boardroominsiders.com">customerservice@boardroominsiders.com</a> for more
              information.
            </Heading>
          </div>
        </div>
      );
    }

    const registrationFormWysiwyg = CompanyPresenter.registrationFormWysiwyg(company);

    return (
      <div className={styles.self}>
        <div className={styles.media}>
          {registrationFormWysiwyg && (
            <div className={styles.text}>
              <RawHtml component="p" innerHTML={registrationFormWysiwyg} />
            </div>
          )}
          {this.renderRegistrationFormVideoCode()}
        </div>
        <div className={styles.form}>
          <Heading level="2" className={styles.header}>
            {CompanyPresenter.registrationFormHeader(company)}
          </Heading>
          <UserRegistrationForm
            onSubmit={this.handleSubmit}
            saving={saving}
            companyDepartments={CompanyPresenter.departments(company)}
          />
        </div>
      </div>
    );
  }
}

CompanyUserRegistration.propTypes = {
  company: CompanyPresenter.shape(),
  companyId: PropTypes.string.isRequired,
  createCompanyDepartmentUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
};

export default CompanyUserRegistration;
