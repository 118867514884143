import keyMirror from 'keymirror';

const SubscriptionsContainerConstants = keyMirror({
  CREATE_CURRENT_USER_SUBSCRIPTION_START: null,
  CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS: null,
  CREATE_CURRENT_USER_SUBSCRIPTION_ERROR: null,

  DESTROY_CURRENT_USER_SUBSCRIPTION_START: null,
  DESTROY_CURRENT_USER_SUBSCRIPTION_SUCCESS: null,
  DESTROY_CURRENT_USER_SUBSCRIPTION_ERROR: null,
});

export default SubscriptionsContainerConstants;
