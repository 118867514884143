import { prop } from 'ramda';
import TagListPageConstants from './TagListPageConstants';
import TagRepository from 'repositories/admin/TagRepository';
import PopulateRepository from 'repositories/admin/Tag/PopulateRepository';
import { addNotification } from 'containers/Notifications';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { isPresent } from 'utils/HelperMethods';

export function initializeTagsList() {
  return { type: TagListPageConstants.INITIALIZE_TAGS_LIST_SUCCESS };
}

export function loadTags({ page, filters }) {
  return (dispatch) => {
    dispatch({ type: TagListPageConstants.LOAD_TAGS_START });
    const query = { page, ...buildFilterParams(filters) };

    return TagRepository.index(query).then(({ items, meta }) => {
      dispatch({
        type: TagListPageConstants.LOAD_TAGS_SUCCESS,
        tags: items,
        ...meta,
      });
    });
  };
}

export function searchTags(filters) {
  return () => {
    const query = { ...buildFilterParams(filters) };
    return TagRepository.index(query).then(({ items }) => items);
  };
}

export function createTag(tagParams) {
  return (dispatch) => {
    dispatch({ type: TagListPageConstants.CREATE_TAG_START });

    return TagRepository.create(tagParams)
      .then(() => {
        dispatch({
          type: TagListPageConstants.CREATE_TAG_SUCCESS,
        });

        addNotification('Tag was successfully added', 'normal')(dispatch);
      })
      .catch((error) => {
        dispatch({ type: TagListPageConstants.CREATE_TAG_ERROR });

        throw error;
      });
  };
}

export function bulkDestroyTags(query) {
  return (dispatch) => {
    dispatch({ type: TagListPageConstants.BULK_DESTROY_TAGS_START });

    return TagRepository.bulkDestroy(query).then(({ errors }) => {
      if (isPresent(errors)) {
        const tagIds = errors.map(prop('id')).join(', ');
        addNotification(`Tags with IDs ${tagIds} weren't deleted`, 'critical')(dispatch);
      }

      dispatch({ type: TagListPageConstants.BULK_DESTROY_TAGS_SUCCESS });
    });
  };
}

export function filterTags(filters) {
  return (dispatch) => {
    dispatch({ type: TagListPageConstants.FILTER_TAGS_START, filters });

    const query = buildFilterParams(filters);
    return TagRepository.index(query).then(({ items, meta }) => {
      dispatch({ type: TagListPageConstants.FILTER_TAGS_SUCCESS, tags: items, filters, ...meta });
    });
  };
}

export function populateTag(tagId) {
  return (dispatch) => {
    dispatch({ type: TagListPageConstants.POPULATE_TAG_START, tagId });

    return PopulateRepository.create(tagId, {})
      .then(({ tag }) => {
        dispatch({ type: TagListPageConstants.POPULATE_TAG_SUCCESS, tag });
        addNotification(
          `We start the job to populate profiles with tag "${tag.title}" automatically.`,
          'normal',
        )(dispatch);

        return tag;
      })
      .catch((error) => {
        dispatch({ type: TagListPageConstants.POPULATE_TAG_ERROR, tagId });

        throw error;
      });
  };
}

export function bulkPopulateTags() {
  return (dispatch) => {
    dispatch({ type: TagListPageConstants.BULK_POPULATE_TAGS_START });

    return TagRepository.bulkPopulate().then(() => {
      addNotification(`We start the job to populate all tags automatically.`, 'normal')(dispatch);

      dispatch({ type: TagListPageConstants.BULK_POPULATE_TAGS_SUCCESS });
    });
  };
}
