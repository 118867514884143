import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Icon from 'components/Icon';

import styles from './UserAvatar.module.css';

export default class UserAvatar extends Component {
  static propTypes = {
    size: PropTypes.number,
    src: PropTypes.string,
    crop: PropTypes.shape({}),
  };

  imageStyles() {
    const { crop, size } = this.props;

    if (isBlank(crop)) {
      return {
        minWidth: `${size}px`,
        maxWidth: `${size}px`,
        minHeight: `${size}px`,
        maxHeight: `${size}px`,
      };
    }

    const aspectRatio = size / crop.width;

    return {
      position: 'absolute',
      minWidth: `${crop.fullWidth * aspectRatio}px`,
      maxWidth: `${crop.fullWidth * aspectRatio}px`,
      minHeight: `${crop.fullHeight * aspectRatio}px`,
      maxHeight: `${crop.fullHeight * aspectRatio}px`,
      top: `-${crop.y * aspectRatio}px`,
      left: `-${crop.x * aspectRatio}px`,
    };
  }

  render() {
    const { size, src } = this.props;
    const image = <img src={src} style={this.imageStyles()} alt="avatar" />;
    const placeholder = <Icon name="avatarPlaceholder" style={{ width: size, height: size }} />;

    return (
      <div
        className={styles.avatar}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        {src ? image : placeholder}
      </div>
    );
  }
}
