import ProfileRelationshipsListConstants from './ProfileRelationshipsListConstants';
import RelationshipsRepository from 'repositories/Profile/RelationshipsMapping/RelationshipsRepository';

export function initializeProfileRelationships(profileId) {
  return {
    type: ProfileRelationshipsListConstants.INITIALIZE_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_START,
    profileId,
  };
}

export function loadProfileRelationships(currentProfileId, profileId, { page, per }) {
  return (dispatch) => {
    dispatch({ type: ProfileRelationshipsListConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_START });
    const params = { page, per };

    return RelationshipsRepository.index(currentProfileId, profileId, params).then(({ items, meta }) => {
      dispatch({
        type: ProfileRelationshipsListConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_RELATIONSHIPS_SUCCESS,
        relationships: items,
        meta,
        profileId,
      });
    });
  };
}
