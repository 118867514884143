import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { debounce } from 'utils/HelperMethods';

import PageHeader from 'components/PageHeader';
import { Box, Button } from 'grommet';
import Search from 'components/SearchInput';
import Select from 'components/Select';
import Table from 'components/Table';
import Modal from 'components/Modal';
import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import CompanyRowCells from './components/CompanyRowCells';
import NewCompanyForm from './components/NewCompanyForm';
import AccountManagersSelect from './components/AccountManagersSelect';
import ExportButton from 'components/ExportButton';

import Routes from 'routes';
import CompanyPresenter, {
  STATE_OPTIONS as COMPANY_STATE_OPTIONS,
  COMPANY_FILTERED_SEARCH_CSV_EXPORT,
} from 'presenters/CompanyPresenter';
import { ACCOUNT_TYPES as COMPANY_ACCOUNT_TYPES } from 'presenters/Company/DepartmentPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import CompanyPolicy from 'policies/CompanyPolicy';

import styles from './CompaniesListPage.module.css';

const MAX_SEARCH_LENGTH = 40;
const COMPANY_ACCOUNT_TYPE_FILTER_OPTIONS = COMPANY_ACCOUNT_TYPES.map(({ key, title }) => ({
  value: key,
  label: title,
}));

const CompaniesListPage = (props) => {
  const {
    currentUser,
    companies,
    filters,
    saving,
    totalCount,
    searchUser,
    initializeCompaniesList,
    filterCompanies,
    loading,
    nextPage,
    loadCompanies,
    bulkDestroyCompanies,
    createCompany,
    createExportCompaniesToCsv,
    pollExportCompaniesToCsv,
  } = props;

  const [isNewCompanyModalOpen, setNewCompanyModalOpen] = useState(null);

  useEffect(() => {
    initializeCompaniesList();
    loadCompanies({ page: 1, filters });
  }, []);

  const tableColumns = [
    {
      name: 'id',
      field: 'id',
      width: '50',
    },
    {
      name: 'company name',
      field: 'name',
    },
    {
      name: 'status',
      field: 'state',
    },
    {
      name: 'created at',
      field: 'createdAt',
    },
    {
      name: 'expiration',
      field: 'subscriptionExpireAt',
    },
    {
      name: 'account manager',
      field: 'managerFullName',
    },
    {
      name: 'seats',
      field: 'seatsNumber',
    },
    {
      name: 'used seats',
      field: 'activeSeatsNumber',
    },
    // {
    //   name: 'views/dl`s',
    //   disableSort: true,
    // },
    // {
    //   name: 'purchased',
    //   disableSort: true,
    // },
    // {
    //   name: 'requests',
    //   disableSort: true,
    // },
    // {
    //   name: 'pending',
    //   disableSort: true,
    // },
    // {
    //   name: 'delivered',
    //   disableSort: true,
    // },
    // {
    //   name: 'owed',
    //   disableSort: true,
    // },
    {
      name: 'account type',
      disableSort: true,
    },
  ];

  const isAllCompaniesLoaded = () => isNil(nextPage);

  const handleDelete = (ids) => bulkDestroyCompanies({ ids }).then(() => loadCompanies({ page: 1, filters }));

  const handleLoadMore = () => {
    if (!loading && !isAllCompaniesLoaded()) {
      loadCompanies({ page: nextPage, filters });
    }
  };

  const handleSort = (sortFields) => {
    filterCompanies({ ...filters, sortFields });
  };

  const handleCompanyClick = (id) => {
    if (CompanyPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminCompanyPath(id));
    }
  };

  const handleCreateNewCompany = () => setNewCompanyModalOpen(true);

  const handleCancelNewCompany = () => setNewCompanyModalOpen(false);

  const handleSubmitNewCompany = (companyParams) =>
    createCompany(companyParams).then((company) => {
      setNewCompanyModalOpen(false);
      window.open(Routes.editAdminCompanyPath(company.id));
    });

  const updateFilters = (changedFields) => {
    const newFilters = { ...filters, ...changedFields };

    filterCompanies(newFilters);
  };

  const updateFilter = (fieldName, value) => updateFilters({ [fieldName]: value });

  const updateFilterDebounced = debounce(updateFilter);

  const findOption = (selectedValue, options) => options.find(({ value }) => value === selectedValue);

  const handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      updateFilterDebounced(fieldName, value);

  const handleFilterSelectChange = (fieldName) => (value) => updateFilterDebounced(fieldName, value);

  const handleExportToCsv = () => createExportCompaniesToCsv(filters, { type: COMPANY_FILTERED_SEARCH_CSV_EXPORT });

  return (
    <>
      <Box margin="none" full="horizontal">
        <PageHeader title="Companies List" />
        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box width="medium" margin={{ right: 'small' }}>
            <Search
              placeholder="Search"
              onChange={handleFilterChange('searchFieldCont')}
              maxLength={MAX_SEARCH_LENGTH}
            />
          </Box>
          {CompanyPolicy.canAddElement(currentUser) && (
            <Box margin={{ right: 'small' }}>
              <Button primary label="Add Company" onClick={handleCreateNewCompany} />
            </Box>
          )}
          {CompanyPolicy.canExportList(currentUser) && (
            <Box margin={{ right: 'small' }}>
              <BackgroundExportResultNotifier
                component={ExportButton}
                onExport={handleExportToCsv}
                onPoll={pollExportCompaniesToCsv}
                onExportPropName="onClick"
                label="Export"
                shouldOpenOnCompletion
                className={styles.exportButton}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange('stateEq')}
            options={COMPANY_STATE_OPTIONS}
            value={findOption(filters.stateEq, COMPANY_STATE_OPTIONS)}
            isClearable
            noBottom
            placeholder="Status"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <Select
            onValueChange={handleFilterSelectChange('departmentsAccountTypeEq')}
            options={COMPANY_ACCOUNT_TYPE_FILTER_OPTIONS}
            value={findOption(filters.departmentsAccountTypeEq, COMPANY_ACCOUNT_TYPE_FILTER_OPTIONS)}
            isClearable
            noBottom
            placeholder="Account Type"
          />
        </Box>
        <Box width="small" margin={{ right: 'small' }}>
          <AccountManagersSelect
            onAccountManagerIdChange={handleFilterSelectChange('managerIdEq')}
            onSearchAccountManager={searchUser}
          />
        </Box>
      </Box>
      <Table
        columns={tableColumns}
        sorts={filters.sortFields}
        onDelete={handleDelete}
        onMore={handleLoadMore}
        onRowClick={handleCompanyClick}
        onSort={handleSort}
        hasMore={!isAllCompaniesLoaded()}
        rows={companies}
        rowCellsComponent={<CompanyRowCells />}
        totalRows={totalCount}
        rowsDeletable={CompanyPolicy.canDestroyElement(currentUser)}
      />
      {isNewCompanyModalOpen && (
        <Modal onClose={handleCancelNewCompany} size="medium" header="New Company" overflow>
          <NewCompanyForm onSubmit={handleSubmitNewCompany} saving={saving} />
        </Modal>
      )}
    </>
  );
};

CompaniesListPage.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  bulkDestroyCompanies: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(CompanyPresenter.shape()),
  createCompany: PropTypes.func.isRequired,
  initializeCompaniesList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  filterCompanies: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  saving: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  searchUser: PropTypes.func.isRequired,
  createExportCompaniesToCsv: PropTypes.func.isRequired,
  pollExportCompaniesToCsv: PropTypes.func.isRequired,
};

export default CompaniesListPage;
