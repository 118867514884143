import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { itself } from 'utils/HelperMethods';
import { useDebounce } from 'utils/debounceUtils';

import RequestPresenter from 'presenters/RequestPresenter';

import CrossIcon from 'icons/CrossIcon';

import Button from 'components/v2/Button';
import { CreatableSelect, Field, Input } from 'components/v2/Form';

import ProfileSuggestions from './components/ProfileSuggestions';

import styles from './RequestFields.module.css';

const RequestFields = (props) => {
  const { errors, request, onRequestRemove, onRequestFieldChange, onRequestSuggestionsLoad } = props;

  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (fieldName) => (value) => onRequestFieldChange(fieldName, value);
  const handleRequestSuggestionsLoad = useDebounce(async () => {
    const data = await onRequestSuggestionsLoad(request);
    setSuggestions(data.items);
  });

  useEffect(() => {
    handleRequestSuggestionsLoad();
  }, [request.subjectAttributes]);

  return (
    <div className={styles.requestFields}>
      <Field className={styles.field} variant="outlined" error={errors?.subjectAttributes?.firstName}>
        <ProfileSuggestions suggestions={suggestions}>
          <Input
            onChange={handleChange('subjectAttributes.firstName')}
            placeholder="First Name"
            value={request.subjectAttributes.firstName}
          />
        </ProfileSuggestions>
      </Field>
      <Field className={styles.field} variant="outlined" error={errors?.subjectAttributes?.lastName}>
        <ProfileSuggestions suggestions={suggestions}>
          <Input
            onChange={handleChange('subjectAttributes.lastName')}
            placeholder="Last Name"
            value={request.subjectAttributes.lastName}
          />
        </ProfileSuggestions>
      </Field>
      <Field className={styles.field} variant="outlined" error={errors?.subjectAttributes?.organizationName}>
        <ProfileSuggestions suggestions={suggestions}>
          <Input
            onChange={handleChange('subjectAttributes.organizationName')}
            placeholder="Company"
            value={request.subjectAttributes.organizationName}
          />
        </ProfileSuggestions>
      </Field>
      <Field className={styles.field} variant="outlined" error={errors?.subjectAttributes?.jobTitle}>
        <ProfileSuggestions suggestions={suggestions}>
          <Input
            onChange={handleChange('subjectAttributes.jobTitle')}
            placeholder="Title"
            value={request.subjectAttributes.jobTitle}
          />
        </ProfileSuggestions>
      </Field>
      <Field className={styles.field} variant="outlined" error={errors.recipientEmails}>
        <CreatableSelect
          createOptionKeys={['Enter', 'Tab', ' ', ',']}
          createOptionOnBlur
          getOptionLabel={itself}
          getOptionValue={itself}
          getNewOptionData={itself}
          isMulti
          menuIsOpen={false}
          onChange={handleChange('recipientEmails')}
          placeholder="Send-To E-mail"
          value={request.recipientEmails}
        />
      </Field>
      <Button className={styles.removeIconButton} color="secondary" onClick={onRequestRemove} variant="icon">
        <CrossIcon width={20} height={20} />
      </Button>
      <Button className={styles.removeButton} onClick={onRequestRemove} variant="link">
        Remove
      </Button>
    </div>
  );
};

RequestFields.propTypes = {
  errors: PropTypes.shape().isRequired,
  onRequestFieldChange: PropTypes.func.isRequired,
  onRequestRemove: PropTypes.func.isRequired,
  onRequestSuggestionsLoad: PropTypes.func.isRequired,
  request: RequestPresenter.shape().isRequired,
};

export default RequestFields;
