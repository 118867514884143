import { buildFilterParams } from 'utils/FilterParamsBuilder';
import AvailableProfilesAndColleaguesContainerConstants from './AvailableProfilesAndColleaguesContainerConstants';
import AvailableProfilesAndColleaguesRepository from 'repositories/CurrentUser/Project/AvailableProfilesAndColleaguesRepository';

export function filterProjectAvailableProfilesAndColleagues(projectId, filters) {
  return (dispatch) => {
    dispatch({
      type: AvailableProfilesAndColleaguesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_START,
      filters,
    });

    const params = buildFilterParams(filters);

    return AvailableProfilesAndColleaguesRepository.index(projectId, params).then(({ items, meta }) => {
      dispatch({
        type: AvailableProfilesAndColleaguesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_SUCCESS,
        profiles: items,
        meta,
        filters,
      });

      return items;
    });
  };
}

export function loadProjectAvailableProfilesAndColleagues(projectId, { page, per, ...filters }) {
  return (dispatch) => {
    dispatch({
      type: AvailableProfilesAndColleaguesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_START,
      filters,
    });

    const params = { ...buildFilterParams(filters), page, per };

    return AvailableProfilesAndColleaguesRepository.index(projectId, params).then(({ items, meta }) => {
      dispatch({
        type: AvailableProfilesAndColleaguesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_AND_COLLEAGUES_SUCCESS,
        profiles: items,
        meta,
        filters,
      });

      return items;
    });
  };
}
