import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export default new Presenter(
  {
    updatedProjectsCount: PropTypes.number,
    updatedSavedSearchesCount: PropTypes.number,
  },
  {
    isProjectsUpdated(notificationsSummary) {
      return this.updatedProjectsCount(notificationsSummary) > 0;
    },

    isSavedSearchesUpdated(notificationsSummary) {
      return this.updatedSavedSearchesCount(notificationsSummary) > 0;
    },

    isDashboardUpdated(notificationsSummary) {
      return this.isProjectsUpdated(notificationsSummary) || this.isSavedSearchesUpdated(notificationsSummary);
    },
  },
);
