import { addNotification } from 'containers/Notifications';

import SubscriptionsContainerConstants from './SubscriptionsContainerConstants';
import SubscriptionsRepository from 'repositories/CurrentUser/SubscriptionsRepository';

export function destroySubscription(id) {
  return (dispatch) => {
    dispatch({ type: SubscriptionsContainerConstants.DESTROY_CURRENT_USER_SUBSCRIPTION_START });

    return SubscriptionsRepository.destroy(id)
      .then(({ subscription }) => {
        dispatch({ type: SubscriptionsContainerConstants.DESTROY_CURRENT_USER_SUBSCRIPTION_SUCCESS, id });

        return subscription;
      })
      .catch((errors) => {
        dispatch({ type: SubscriptionsContainerConstants.DESTROY_CURRENT_USER_SUBSCRIPTION_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw errors;
      });
  };
}

export function createSubscription(params) {
  return (dispatch) => {
    dispatch({ type: SubscriptionsContainerConstants.CREATE_CURRENT_USER_SUBSCRIPTION_START });

    return SubscriptionsRepository.create(params)
      .then(({ subscription }) => {
        dispatch({ type: SubscriptionsContainerConstants.CREATE_CURRENT_USER_SUBSCRIPTION_SUCCESS, subscription });

        return subscription;
      })
      .catch((errors) => {
        dispatch({ type: SubscriptionsContainerConstants.CREATE_CURRENT_USER_SUBSCRIPTION_ERROR });
        addNotification('Sorry, Something went wrong', 'critical')(dispatch);

        throw errors;
      });
  };
}
