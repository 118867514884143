import DepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import NewUserForm from './NewUserForm';

export default {
  defaultAttributes() {
    return {
      source: { file: null, delimiter: null },
      department: null,
      selectedUserIndices: [],
      users: [],
    };
  },

  attributesToSubmit(attributes) {
    const { users, department, selectedUserIndices } = attributes;
    const companyDepartmentId = DepartmentPresenter.id(department);
    const usersToSubmit = this.filterUsersToSubmit(users, selectedUserIndices);

    return this.usersAttributesToSubmit(usersToSubmit, companyDepartmentId);
  },

  filterUsersToSubmit(users, selectedUserIndices) {
    return users.filter((u) => selectedUserIndices.includes(u.index));
  },

  usersAttributesToSubmit(users, companyDepartmentId) {
    return users.map((u) => ({ ...u, companyDepartmentId })).map((u) => NewUserForm.attributesToSubmit(u));
  },
};
