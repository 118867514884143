import React from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'utils/debounceUtils';

import Anchor from 'components/Anchor';
import AsyncSelect from 'components/AsyncSelect';
import Button from 'components/Button';
import MultiplyCreatableSelect from 'components/MultiplyCreatableSelect';
import Form, { Field, Input } from 'components/v2/Form';

import IndustryPresenter from 'presenters/IndustryPresenter';
import NaicsIndustryCodePresenter from 'presenters/NaicsIndustryCodePresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import OrganizationForm from 'forms/OrganizationForm';

import styles from './EditForm.module.css';

const EditForm = (props) => {
  const { organization, onSubmit, onRicCodeUpdate, saving, onIndustrySearch, onNaicsIndustryCodeSearch } = props;

  const handleSubmit = (attributes) => onSubmit(OrganizationForm.attributesToSubmit(attributes));

  const handleRicCodeUpdate = (ticker, onTickerChange) => {
    onRicCodeUpdate(ticker).then(({ ricCode }) => onTickerChange('ricCode', ricCode));
  };

  const handleIndustrySearch = useAsyncDebounce(onIndustrySearch, []);

  const handleNaicsIndustryCodeSearch = useAsyncDebounce(onNaicsIndustryCodeSearch, []);

  const handleAddNewEmailDomain = (newDomain, prevDomains, onDomainChange) => {
    const normalizedValue = newDomain.toString().trim().toLowerCase();

    if (prevDomains.includes(normalizedValue)) {
      return false;
    }

    onDomainChange('emailDomains', [...prevDomains, normalizedValue]);

    return true;
  };

  return (
    <Form initialValues={OrganizationForm.defaultAttributes(organization)} onSubmit={handleSubmit}>
      {({ values, errors, onFieldChange, onSubmit: onSubmitForm }) => (
        <>
          <div className={styles.inputGroup}>
            <div className={styles.field}>
              <Field title="Organization name:" variant="outlined" error={errors.name}>
                <Input value={values.name} onChange={(value) => onFieldChange('name', value)} />
              </Field>
            </div>

            <div className={styles.field}>
              <Field title="Ticker:" variant="outlined" error={errors.ticker}>
                <Input value={values.ticker} onChange={(value) => onFieldChange('ticker', value)} />
              </Field>
            </div>

            <div className={styles.field}>
              <Field title="RIC code:" variant="outlined" error={errors.ricCode}>
                <div className={styles.subField}>
                  <Input value={values.ricCode} onChange={(value) => onFieldChange('ricCode', value)} />
                  <Anchor
                    className={styles.updateRicCode}
                    label="Update from TR by ticker"
                    onClick={() => handleRicCodeUpdate(values.ticker, onFieldChange)}
                    disabled={saving}
                  />
                </div>
              </Field>
            </div>

            <AsyncSelect
              errors={errors.industry}
              placeholder="Industry:"
              loadOptions={handleIndustrySearch}
              defaultOptions
              value={values.industry}
              onChange={(value) => onFieldChange('industry', value)}
              getOptionValue={IndustryPresenter.id}
              getOptionLabel={IndustryPresenter.name}
              isClearable
            />

            <AsyncSelect
              errors={errors.naicsIndustryCode}
              placeholder="NAICS Industry Code:"
              loadOptions={handleNaicsIndustryCodeSearch}
              defaultOptions
              value={values.naicsIndustryCode}
              onChange={(value) => onFieldChange('naicsIndustryCode', value)}
              getOptionValue={NaicsIndustryCodePresenter.id}
              getOptionLabel={NaicsIndustryCodePresenter.title}
              isClearable
            />

            <div className={styles.field}>
              <Field title="Fortune Rank:" variant="outlined" error={errors.fortuneRank}>
                <Input value={values.fortuneRank} onChange={(value) => onFieldChange('fortuneRank', value)} />
              </Field>
            </div>

            <div className={styles.field}>
              <Field title="FTSE Rank:" variant="outlined" error={errors.ftseRank}>
                <Input value={values.ftseRank} onChange={(value) => onFieldChange('ftseRank', value)} />
              </Field>
            </div>

            <div className={styles.field}>
              <Field title="Global 2000 Rank:" variant="outlined" error={errors.global2000Rank}>
                <Input value={values.global2000Rank} onChange={(value) => onFieldChange('global2000Rank', value)} />
              </Field>
            </div>

            <div className={styles.field}>
              <Field title="corporate DUNS#:" variant="outlined" error={errors.corporateDuns}>
                <Input value={values.corporateDuns} onChange={(value) => onFieldChange('corporateDuns', value)} />
              </Field>
            </div>

            <MultiplyCreatableSelect
              placeholder="Email Domains:"
              errors={errors.emailDomains}
              value={values.emailDomains}
              onCreateNewValue={(value) => handleAddNewEmailDomain(value, values.emailDomains, onFieldChange)}
              onChange={(value) => onFieldChange('emailDomains', value)}
              getOptionValue={(value) => value}
              getOptionLabel={(value) => value}
            />

            {(OrganizationPresenter.isMemberFortune1000(organization) ||
              OrganizationPresenter.isMemberFtse100(organization)) && (
              <div className={styles.field}>
                <Field
                  title="Link to the Board members list:"
                  variant="outlined"
                  error={errors.boardOfDirectorsListUrl}
                >
                  <Input
                    value={values.boardOfDirectorsListUrl}
                    onChange={(value) => onFieldChange('boardOfDirectorsListUrl', value)}
                    placeholder="http:// or https://"
                  />
                </Field>
              </div>
            )}

            <div className={styles.field}>
              <Field title="Company leadership page:" variant="outlined" error={errors.companyLeadershipPage}>
                <Input
                  value={values.companyLeadershipPage}
                  onChange={(value) => onFieldChange('companyLeadershipPage', value)}
                  placeholder="http:// or https://"
                />
              </Field>
            </div>
          </div>

          <div className={styles.submitButton}>
            <Button label="Save" disabled={saving} primary onClick={onSubmitForm} />
          </div>
        </>
      )}
    </Form>
  );
};

EditForm.propTypes = {
  organization: OrganizationPresenter.shape().isRequired,
  onIndustrySearch: PropTypes.func.isRequired,
  onNaicsIndustryCodeSearch: PropTypes.func.isRequired,
  onRicCodeUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default EditForm;
