import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { components as ReactSelectComponents } from 'react-select';

import Select from 'components/Select';

import { PRIORITIES } from 'presenters/RequestPresenter';
import RequestPresenter, { priorityColor } from 'presenters/RequestPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import RequestPolicy from 'policies/RequestPolicy';

import styles from './RequestPrioritySelect.module.css';

const PRIORITY_OPTIONS = PRIORITIES.map(({ key, title }) => ({ value: key, label: title }));

export default class RequestPrioritySelect extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    request: RequestPresenter.shape(),
    value: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
  };

  renderOption = (args) => {
    const { label, data } = args;
    const { value } = data;

    return (
      <ReactSelectComponents.Option {...args}>
        <div className={styles.priority}>
          <div className={styles.priorityColor} style={{ backgroundColor: priorityColor(value) }} />
          <div>{label}</div>
        </div>
      </ReactSelectComponents.Option>
    );
  };

  renderSingleValue = (args) => {
    const { children, ...props } = args;
    const { data } = args;
    const { value } = data;

    return (
      <ReactSelectComponents.SingleValue {...props}>
        <div className={styles.priority}>
          <div className={styles.priorityColor} style={{ backgroundColor: priorityColor(value) }} />
          <div>{children}</div>
        </div>
      </ReactSelectComponents.SingleValue>
    );
  };

  render() {
    const { currentUser, isDisabled, onChange, request, value } = this.props;

    return (
      <Select
        components={{ Option: this.renderOption, SingleValue: this.renderSingleValue }}
        isDisabled={!RequestPolicy.canChangePriority(currentUser, request) || isDisabled}
        isClearable
        onValueChange={onChange}
        options={PRIORITY_OPTIONS}
        placeholder="Priority"
        selectedOptionValue={value}
      />
    );
  }
}
