import CountryPageConstants from './CountryPageConstants';
import CountriesRepository from 'repositories/admin/CountriesRepository';
import { addNotification } from 'containers/Notifications';

export function loadCountry({ id }) {
  return (dispatch) => {
    dispatch({ type: CountryPageConstants.LOAD_COUNTRY_START });

    return CountriesRepository.show(id)
      .then(({ country }) => {
        dispatch({ type: CountryPageConstants.LOAD_COUNTRY_SUCCESS, country });
      })
      .catch((response) => {
        dispatch({ type: CountryPageConstants.LOAD_COUNTRY_ERROR });
        throw response;
      });
  };
}

export function updateCountry(id, params) {
  return (dispatch) => {
    dispatch({ type: CountryPageConstants.UPDATE_COUNTRY_START });

    return CountriesRepository.update(id, params)
      .then(({ country }) => {
        dispatch({ type: CountryPageConstants.UPDATE_COUNTRY_SUCCESS, country });
        addNotification('Country was successfully saved', 'normal')(dispatch);
      })
      .catch((json) => {
        dispatch({ type: CountryPageConstants.UPDATE_COUNTRY_ERROR });
        throw json;
      });
  };
}
