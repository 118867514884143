import { equals } from 'ramda';
import AvailableProfilesContainerConstants from './AvailableProfilesContainerConstants';

const initialState = {
  loading: false,
  profiles: [],
  totalCount: 0,
  currentPage: 0,
  nextPage: 1,
  filters: null,
  saving: false,
};

function AvailableProfilesContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AvailableProfilesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_START: {
      const { filters } = action;

      return { ...state, ...initialState, filters, loading: true };
    }
    case AvailableProfilesContainerConstants.FILTER_PROJECT_AVAILABLE_PROFILES_SUCCESS: {
      const { profiles, meta, filters } = action;

      if (!equals(state.filters, filters)) {
        return state;
      }

      return { ...state, loading: false, profiles, ...meta };
    }
    case AvailableProfilesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_START: {
      const { filters } = action;

      return { ...state, filters, loading: true };
    }
    case AvailableProfilesContainerConstants.LOAD_PROJECT_AVAILABLE_PROFILES_SUCCESS: {
      const { profiles, meta, filters } = action;

      if (!equals(state.filters, filters)) {
        return state;
      }

      return { ...state, loading: false, profiles: [...state.profiles, ...profiles], ...meta };
    }
    case AvailableProfilesContainerConstants.DESTROY_PROJECT_AVAILABLE_PROFILE_START: {
      return { ...state, saving: true };
    }
    case AvailableProfilesContainerConstants.DESTROY_PROJECT_AVAILABLE_PROFILE_SUCCESS: {
      const { profileId } = action;
      const profiles = state.profiles.filter(({ id }) => id === profileId);

      return { ...state, profiles, saving: false };
    }
    case AvailableProfilesContainerConstants.DESTROY_PROJECT_AVAILABLE_PROFILE_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default AvailableProfilesContainerReducer;
