import RolesConstants from './RolesConstants';
import TopRolesRepository from 'repositories/CurrentUser/Project/TopRolesRepository';

export function loadTopRoles(projectId) {
  return (dispatch) => {
    dispatch({ type: RolesConstants.LOAD_PROJECT_TOP_ROLES_START });

    return TopRolesRepository.index(projectId).then(({ items }) => {
      dispatch({ type: RolesConstants.LOAD_PROJECT_TOP_ROLES_SUCCESS, roles: items });

      return items;
    });
  };
}
