import React, { useEffect } from 'react';
import { Provider, useStore } from 'react-redux';
import { Layer, Heading, Box } from 'grommet';
import cn from 'clsx';
import PropTypes from 'prop-types';
import { Close as CloseIcon } from 'grommet-icons';

import Button from 'components/Button';

import styles from './Modal.module.css';

const handleKeyDown = (event) => {
  if (event.keyCode === 27) {
    event.stopPropagation();
  }
};

const Modal = (props) => {
  const {
    children,
    size,
    header,
    onClose,
    overflow,
    subheader,
    collapseContentMargins,
    disableCloseIcon,
    disableOverlayClose,
    disableCloseByEsc,
    inlineSubheader,
    disableHeader,
    className,
  } = props;
  const LayerStyles = cn(
    [styles.modal],
    {
      [styles.overflow]: overflow,
      [styles.collapseContentMargins]: collapseContentMargins,
    },
    className,
  );
  const containerSize = styles[size];
  const containerStyles = cn(containerSize, [styles.container]);

  const store = useStore();

  useEffect(() => {
    if (disableCloseByEsc) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (disableCloseByEsc) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  const handleEsc = () => (disableCloseByEsc ? () => {} : onClose);
  const handleClickOutside = () => (disableOverlayClose ? () => {} : onClose);

  return (
    <Layer onEsc={handleEsc} onClickOutside={handleClickOutside} className={LayerStyles} animation="none">
      <Box overflow="initial">
        <Provider store={store}>
          <div className={containerStyles}>
            <Box direction="column" margin="medium">
              <Box direction="row" justify="between" align="start">
                <Box align="start" margin={{ bottom: 'medium' }}>
                  <Box direction="column">
                    {!disableHeader ? (
                      <div className={styles.headerBox}>
                        <Heading weight="bold" level="3" margin="none">
                          {header}
                        </Heading>
                        <span className={styles.inlineSubheader}>{inlineSubheader}</span>
                      </div>
                    ) : null}
                    {subheader && (
                      <Heading weight="bold" level="3" margin="none">
                        {subheader}
                      </Heading>
                    )}
                  </Box>
                </Box>
                {!disableCloseIcon ? (
                  <Button onClick={onClose} className={styles.close}>
                    <CloseIcon size="small" />
                  </Button>
                ) : null}
              </Box>
              {children}
            </Box>
          </div>
        </Provider>
      </Box>
    </Layer>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  onClose: PropTypes.func,
  header: PropTypes.string,
  overflow: PropTypes.bool,
  collapseContentMargins: PropTypes.bool,
  subheader: PropTypes.string,
  disableOverlayClose: PropTypes.bool,
  disableCloseByEsc: PropTypes.bool,
  disableCloseIcon: PropTypes.bool,
  inlineSubheader: PropTypes.string,
  disableHeader: PropTypes.bool,
  className: PropTypes.string,
};

Modal.defaultProps = {
  disableOverlayClose: false,
  disableCloseByEsc: false,
  disableHeader: false,
};

export default Modal;
