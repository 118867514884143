import SampleSavedSearchesConstants from './SampleSavedSearchesConstants';

import SampleSavedSearchesRepository from 'repositories/admin/SampleSavedSearchesRepository';

export function loadSampleSavedSearches() {
  return (dispatch) => {
    dispatch({ type: SampleSavedSearchesConstants.LOAD_ADMIN_SAMPLE_SAVED_SEARCHES_START });

    return SampleSavedSearchesRepository.index().then(({ items }) => {
      dispatch({ type: SampleSavedSearchesConstants.LOAD_ADMIN_SAMPLE_SAVED_SEARCHES_SUCCESS, items });

      return items;
    });
  };
}

export function updateSampleSavedSearch(sampleSavedSearchId, attributes) {
  return (dispatch) => {
    dispatch({ type: SampleSavedSearchesConstants.UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_START });

    return SampleSavedSearchesRepository.update(sampleSavedSearchId, attributes)
      .then(({ sampleSavedSearch }) => {
        dispatch({ type: SampleSavedSearchesConstants.UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_SUCCESS, sampleSavedSearch });

        return sampleSavedSearch;
      })
      .catch((errors) => {
        dispatch({ type: SampleSavedSearchesConstants.UPDATE_ADMIN_SAMPLE_SAVED_SEARCH_ERROR });

        return errors;
      });
  };
}
