import React, { Component } from 'react';
import { omit, pick, prop } from 'ramda';
import {
  logClickOnMyProjectsAddProject,
  logClickOnMyProjectsDeleteProject,
  logClickOnMyProjectsCloneProject,
  logClickOnMyProjectsAddAlerts,
  logChangeMyProjectsSearchProject,
  logChangeMyProjectsSortProject,
  logChangeMyProjectsTypeProject,
} from 'utils/amplitude';

import PropTypes from 'prop-types';
import { debounce, isPresent, isBlank } from 'utils/HelperMethods';
import Routes from 'routes';

import ProjectPresenter from 'presenters/ProjectPresenter';

import Button from 'components/Button';
import InfiniteScroll from 'components/InfiniteScroll';
import ProjectCloneFormModal from 'containers/UserPanel/components/ProjectCloneFormModal';
import ProjectItem from 'containers/UserPanel/components/ProjectItem';
import SearchInput from 'components/SearchInput';
import Select from 'components/Select';
import Spinner from 'components/Spinner';

import EditProjectForm from 'forms/EditProjectForm';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import styles from './ProjectListPage.module.css';

const PER_PAGE = 10;

const OWNERSHIP_OPTIONS = [
  { label: 'All projects', value: ' ' },
  { label: 'My projects', value: 'user_id_eq' },
  { label: 'Shared with Me', value: 'user_id_not_eq' },
];

const SORT_OPTIONS = [
  { label: 'Recently Updated', field: 'target_scope_updated_at', value: 'desc' },
  { label: 'Project Name A-Z', field: 'name', value: 'asc' },
  { label: 'Project Name Z-A', field: 'name', value: 'desc' },
];

const DEFAULT_FILTERS = {
  per: PER_PAGE,
  sortFields: [SORT_OPTIONS[0]],
  [OWNERSHIP_OPTIONS[0].value]: true,
};

export default class ProjectListPage extends Component {
  state = { isCloneModalOpened: false, projectToClone: null };

  componentDidMount() {
    const { currentUser, markProjectsViewed, filterProjects } = this.props;

    filterProjects(DEFAULT_FILTERS).then(() => {
      if (!CurrentUserPresenter.isImpersonating(currentUser)) {
        markProjectsViewed();
      }
    });
  }

  isAllProjectsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleFilter = debounce((filters) => {
    const { filterProjects } = this.props;

    filterProjects(filters);
  });

  handleLoadMore = () => {
    const { loading, loadProjects, nextPage, filters } = this.props;

    if (!loading && !this.isAllProjectsLoaded()) {
      loadProjects({ ...filters, page: nextPage });
    }
  };

  handleOpenCloneModal = (projectToClone) => this.setState({ isCloneModalOpened: true, projectToClone });

  handleCloseCloneModal = () => this.setState({ isCloneModalOpened: false, projectToClone: null });

  handleClone = (params) => {
    const { cloneProject, history } = this.props;
    const { projectToClone } = this.state;
    const projectId = ProjectPresenter.id(projectToClone);
    const attributes = EditProjectForm.attributesToSubmit(params);

    logClickOnMyProjectsCloneProject();

    return cloneProject(projectId, attributes).then((clonedProject) => {
      const clonedProjectId = ProjectPresenter.id(clonedProject);
      const path = Routes.projectPath(clonedProjectId);

      history.push(path);

      return clonedProject;
    });
  };

  handleDelete = (project) => {
    const { destroyProject, filters, filterProjects } = this.props;

    logClickOnMyProjectsDeleteProject();

    return destroyProject(ProjectPresenter.id(project)).then(() => {
      filterProjects(filters);
    });
  };

  handleCreateSubscription = (project) => {
    const { changeProject, createSubscription } = this.props;
    const params = {
      kind: 'email_alert',
      projectId: ProjectPresenter.id(project),
    };

    logClickOnMyProjectsAddAlerts();

    return createSubscription(params).then(({ id }) => {
      changeProject({ ...project, subscriptionId: id });
    });
  };

  handleDestroySubscription = (project) => {
    const { changeProject, destroySubscription } = this.props;
    const subscriptionId = ProjectPresenter.subscriptionId(project);

    return destroySubscription(subscriptionId).then(() => {
      changeProject({ ...project, subscriptionId: null });
    });
  };

  handleDestroyProjectShare = (project) => {
    const { destroyProjectShare, filters } = this.props;
    const shareId = ProjectPresenter.shareId(project);

    return destroyProjectShare(project.id, shareId).then(() => {
      this.handleFilter(filters);
    });
  };

  handleChangeSearchInput = ({ target: { value } }) => {
    const { filters } = this.props;

    logChangeMyProjectsSearchProject();

    this.handleFilter({ ...filters, nameCont: value });
  };

  handleChangeSort = (value) => {
    const { filters } = this.props;

    logChangeMyProjectsSortProject();

    this.handleFilter({ ...filters, sortFields: [value] });
  };

  handleChangeOwnershipFilter = (value) => {
    const { currentUser, filters: oldFilters } = this.props;
    const filterNames = OWNERSHIP_OPTIONS.map(prop('value'));
    const filters = omit(filterNames, oldFilters);

    logChangeMyProjectsTypeProject();

    if (isPresent(value)) {
      filters[value] = currentUser.id;
    }

    this.handleFilter(filters);
  };

  selectedOwnershipOptionValue = () => {
    const { filters } = this.props;
    const filterNames = OWNERSHIP_OPTIONS.map(prop('value'));

    return Object.keys(pick(filterNames, filters))[0] || ' ';
  };

  renderLoader = () => (
    <div key="loader" className={styles.spinner}>
      <Spinner />
    </div>
  );

  render() {
    const { currentUser, projects, saving, totalCount, canCreateProject, filters } = this.props;
    const { isCloneModalOpened, projectToClone } = this.state;
    const sort = filters.sortFields && filters.sortFields[0];

    return (
      <>
        {isCloneModalOpened && (
          <ProjectCloneFormModal
            onClose={this.handleCloseCloneModal}
            onSubmit={this.handleClone}
            project={projectToClone}
            isSaving={saving}
          />
        )}
        <div className={styles.headLine}>
          <div className={styles.title}>
            My Projects <span className={styles.counter}>{totalCount}</span>
          </div>

          <div className={styles.action}>
            <Button
              label="Add Project"
              className={styles.button}
              href={Routes.newProjectPath()}
              disabled={!canCreateProject}
              onClick={logClickOnMyProjectsAddProject}
            />
          </div>
        </div>
        <div className={styles.filters}>
          <SearchInput
            className={styles.searchInput}
            onChange={this.handleChangeSearchInput}
            placeholder="Search Project"
          />
          {CurrentUserPresenter.projectsSharingAvailable(currentUser) && (
            <div className={styles.select}>
              <Select
                noBottom
                onValueChange={this.handleChangeOwnershipFilter}
                options={OWNERSHIP_OPTIONS}
                placeholder="Type"
                selectedOptionValue={this.selectedOwnershipOptionValue()}
              />
            </div>
          )}
          <div className={styles.select}>
            <Select
              noBottom
              onChange={this.handleChangeSort}
              options={SORT_OPTIONS}
              placeholder="Sort By"
              value={sort}
            />
          </div>
        </div>
        <div className={styles.gridList}>
          <InfiniteScroll
            hasMore={!this.isAllProjectsLoaded()}
            loadMore={this.handleLoadMore}
            loader={this.renderLoader()}
            useWindow={false}
            researchForScrollParent
          >
            {projects.map((project) => (
              <ProjectItem
                currentUser={currentUser}
                project={project}
                key={project.id}
                onCreateSubscription={this.handleCreateSubscription}
                onDestroySubscription={this.handleDestroySubscription}
                onDestroyShare={this.handleDestroyProjectShare}
                onClone={this.handleOpenCloneModal}
                onDelete={this.handleDelete}
                saving={saving}
              />
            ))}
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

ProjectListPage.propTypes = {
  currentUser: CurrentUserPresenter.shape().isRequired,
  changeProject: PropTypes.func.isRequired,
  cloneProject: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired,
  destroySubscription: PropTypes.func.isRequired,
  destroyProjectShare: PropTypes.func.isRequired,
  destroyProject: PropTypes.func.isRequired,
  filterProjects: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadProjects: PropTypes.func.isRequired,
  markProjectsViewed: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  projects: PropTypes.arrayOf(ProjectPresenter.shape()),
  saving: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  canCreateProject: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
};
