import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnProjectPageAddList } from 'utils/amplitude';

import styles from './Lists.module.css';

import Button from 'components/Button';
import Item from './components/Item';

import ProjectPresenter from 'presenters/ProjectPresenter';
import SourcePresenter from 'presenters/Project/SourcePresenter';

import ProjectPolicy from 'policies/ProjectPolicy';

import Routes from 'routes';

export default class Lists extends Component {
  static propTypes = {
    project: ProjectPresenter.shape().isRequired,
    sources: PropTypes.arrayOf(SourcePresenter.shape()).isRequired,
    onDestroy: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  handleSourceDestroy = (source) => {
    const { onDestroy, project } = this.props;
    const projectId = ProjectPresenter.id(project);
    const sourceId = SourcePresenter.id(source);

    return onDestroy(projectId, sourceId);
  };

  render() {
    const { project, saving, sources } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.headLine}>
          <div className={styles.title}>My Lists</div>

          {ProjectPolicy.canAddSource(project) && (
            <Button
              label="Add List"
              primary
              className={styles.button}
              href={Routes.newProjectSourcePath(ProjectPresenter.id(project))}
              onClick={logClickOnProjectPageAddList}
            />
          )}
        </div>
        {sources.map((source) => (
          <Item
            key={SourcePresenter.id(source)}
            saving={saving}
            source={source}
            onDestroy={this.handleSourceDestroy}
            project={project}
          />
        ))}
      </div>
    );
  }
}
