import PropTypes from 'prop-types';
import React from 'react';
import { isNil } from 'ramda';

import Typography from 'components/v2/Typography';
import { useWizardContext } from 'components/v2/Wizard';

import styles from './WizardTitle.module.css';

const WizardTitle = (props) => {
  const { title, subtitle } = props;

  const { currentWizardStepIndex } = useWizardContext();

  return (
    <div className={styles.title}>
      <Typography className={styles.step} variant="h2">
        {isNil(title) ? `Step ${currentWizardStepIndex + 1}` : title}
      </Typography>
      {!isNil(subtitle) && (
        <Typography className={styles.description} variant="h3">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

WizardTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

WizardTitle.defaultProps = {
  title: null,
  subtitle: null,
};

export default WizardTitle;
