import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

import KanbanColumn from './components/KanbanColumn';

import styles from './KanbanBoard.module.css';

class KanbanBoard extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()),
    cardComponent: PropTypes.func,
    cardClassName: PropTypes.string,
    headerComponent: PropTypes.func,
    onCardClick: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragEnd: PropTypes.func,
    onLoadMore: PropTypes.func,
  };

  state = { isDragging: false };

  handleDragStart = (object) => {
    this.setState({ isDragging: true });
    this.props.onDragStart(object);
  };

  handleDragEnd = (object) => {
    this.setState({ isDragging: false });
    this.props.onDragEnd(object);
  };

  render() {
    const { cardComponent, cardClassName, columns, headerComponent, onCardClick, onLoadMore } = this.props;
    const { isDragging } = this.state;

    return (
      <DragDropContext columns={columns} onDragStart={this.handleDragStart} onDragEnd={this.handleDragEnd}>
        <div className={styles.board}>
          {columns.map((column) => (
            <KanbanColumn
              key={column.id}
              cardComponent={cardComponent}
              cardClassName={cardClassName}
              column={column}
              headerComponent={headerComponent}
              onCardClick={onCardClick}
              onLoadMore={onLoadMore}
              isDragging={isDragging}
            />
          ))}
        </div>
      </DragDropContext>
    );
  }
}

export default KanbanBoard;
