import CorporateAccountReducer from './CorporateAccountReducer';
import { UserListReducers } from './containers/UserList';
import { DashboardReducers } from './containers/Dashboard';

export default from './CorporateAccountContainer';
export const CorporateAccountReducers = {
  CorporateAccountReducer,
  ...UserListReducers,
  ...DashboardReducers,
};
