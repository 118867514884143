import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CompanyDepartmentUserRegistration from './CompanyDepartmentUserRegistration';
import * as actions from './CompanyDepartmentUserRegistrationActions';

const mapStateToProps = (state, props) => ({
  loading: state.CompanyDepartmentUserRegistrationReducer.loading,
  companyDepartment: state.CompanyDepartmentUserRegistrationReducer.companyDepartment,
  saving: state.CompanyDepartmentUserRegistrationReducer.saving,
  companyId: props.match.params.companyId,
  departmentId: props.match.params.departmentId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDepartmentUserRegistration);
