import UserPresenter from 'presenters/UserPresenter';

export default {
  canSeeList(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchEditor(user);
  },
  canSeeElement(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchEditor(user);
  },
  canDestroyElement(user) {
    return UserPresenter.isAdmin(user);
  },
  canAddElement(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchEditor(user);
  },
};
