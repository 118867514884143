/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import EditorWorkRepository from 'repositories/admin/Profile/EditorWorkRepository';

const initialState = {
  isSaving: false,
  isDestroying: false,
  isLoading: false,
  editorWorks: [],
  meta: {
    nextPage: 1,
  },
};

const adminProfileEditorWorks = createSlice({
  name: 'adminProfileEditorWorks',
  initialState,
  reducers: {
    createStart(state) {
      state.isSaving = true;
    },
    createSuccess(state) {
      state.isSaving = false;
    },
    createError(state) {
      state.isSaving = false;
    },
    updateStart(state) {
      state.isSaving = true;
    },
    updateSuccess(state) {
      state.isSaving = false;
    },
    updateError(state) {
      state.isSaving = false;
    },
    destroyStart(state) {
      state.isDestroying = true;
    },
    destroySuccess(state) {
      state.isDestroying = false;
    },
    destroyError(state) {
      state.isDestroying = false;
    },
    loadStart(state) {
      state.isLoading = true;
    },
    loadSuccess(state, { payload }) {
      const { editorWorks, meta } = payload;

      state.isLoading = false;
      state.editorWorks = [...state.editorWorks, ...editorWorks];
      state.meta = meta;
    },
    loadError(state) {
      state.isLoading = false;
    },
    filterStart(state) {
      state.isLoading = true;
    },
    filterSuccess(state, { payload }) {
      const { editorWorks, meta } = payload;

      state.isLoading = false;
      state.editorWorks = editorWorks;
      state.meta = meta;
    },
    filterError(state) {
      state.isLoading = false;
    },
  },
});

const { actions } = adminProfileEditorWorks;

function filterEditorWorks(profileId, { per, filters }) {
  return (dispatch) => {
    dispatch(actions.filterStart());

    const query = buildFilterParams(filters);

    return EditorWorkRepository.index(profileId, { ...query, per })
      .then(({ editorWorks, meta }) => {
        dispatch(actions.filterSuccess({ editorWorks, meta }));
      })
      .catch((errors) => {
        dispatch(actions.filterError());

        throw errors;
      });
  };
}

function loadEditorWorks(profileId, { page, per, filters }) {
  return (dispatch) => {
    dispatch(actions.loadStart());

    const query = buildFilterParams(filters);

    return EditorWorkRepository.index(profileId, { ...query, page, per })
      .then(({ editorWorks, meta }) => {
        dispatch(actions.loadSuccess({ editorWorks, meta }));
      })
      .catch((errors) => {
        dispatch(actions.loadError());

        throw errors;
      });
  };
}

function createEditorWork(profileId, params) {
  return (dispatch) => {
    dispatch(actions.createStart());

    return EditorWorkRepository.create(profileId, params)
      .then(({ editorWork }) => {
        dispatch(actions.createSuccess());

        return editorWork;
      })
      .catch((errors) => {
        dispatch(actions.createError());

        throw errors;
      });
  };
}

function updateEditorWork(profileId, editorWorkId, params) {
  return (dispatch) => {
    dispatch(actions.updateStart());

    return EditorWorkRepository.update(profileId, editorWorkId, params)
      .then(({ editorWork }) => {
        dispatch(actions.updateSuccess());

        return editorWork;
      })
      .catch((errors) => {
        dispatch(actions.updateError());

        throw errors;
      });
  };
}

function destroyEditorWork(profileId, editorWorkId) {
  return (dispatch) => {
    dispatch(actions.destroyStart());

    return EditorWorkRepository.destroy(profileId, editorWorkId)
      .then(() => {
        dispatch(actions.destroySuccess());

        return editorWorkId;
      })
      .catch((errors) => {
        dispatch(actions.destroyError());

        throw errors;
      });
  };
}

export const useAdminProfileEditorWorksActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      createEditorWork,
      updateEditorWork,
      destroyEditorWork,
      loadEditorWorks,
      filterEditorWorks,
    },
    dispatch,
  );
};

export default adminProfileEditorWorks.reducer;
