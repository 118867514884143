import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnUserPanelSearchProfilesSaveFilters } from 'utils/amplitude';

import { Anchor } from 'grommet';
import Tooltip from 'rc-tooltip';

import FiltersList from 'containers/UserPanel/containers/Search/components/FiltersList';
import NumberPresenter from 'presenters/NumberPresenter';

import styles from './FilterBadge.module.css';

class FilterBadge extends Component {
  static propTypes = {
    activeFilters: PropTypes.shape(),
    canSaveFilters: PropTypes.bool.isRequired,
    onSaveFilters: PropTypes.func.isRequired,
    onResetFilters: PropTypes.func.isRequired,
    totalCount: PropTypes.string,
  };

  renderFiltersList() {
    const { activeFilters } = this.props;

    return (
      <div className={styles.filtersList}>
        <FiltersList filters={activeFilters} />
      </div>
    );
  }

  render() {
    const { activeFilters, canSaveFilters, onSaveFilters, onResetFilters, totalCount } = this.props;

    return (
      <div className={styles.root}>
        <Tooltip
          overlayClassName={styles.tooltip}
          align={{ points: ['tl', 'bl'], offset: [-16, 0] }}
          overlay={this.renderFiltersList()}
        >
          <Anchor className={styles.filtersCount} label={`${Object.keys(activeFilters).length} Filters`} />
        </Tooltip>
        <div>{NumberPresenter.withDelim(totalCount)} Profiles </div>
        <Anchor onClick={onResetFilters} className={styles.resetFilters}>
          Reset filters
        </Anchor>
        <Anchor
          disabled={!canSaveFilters}
          onClick={() => {
            onSaveFilters();
            logClickOnUserPanelSearchProfilesSaveFilters();
          }}
          className={styles.saveFilters}
        >
          Save filters
        </Anchor>
      </div>
    );
  }
}

export default FilterBadge;
