import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';

import { logClickOnBusinessPriorityAnalysisTabDetails } from 'utils/amplitude';

import Spinner from 'components/Spinner';
import Sidebar from 'containers/UserPanel/containers/Project/containers/ProjectProfilesListSidebar';

import ProjectPresenter, { PROJECT_TAG_PROFILES_CSV } from 'presenters/ProjectPresenter';
import TagPresenter from 'presenters/Project/BPA/TagPresenter';

import styles from './TopTags.module.css';

const COLORS = ['#00A0CE', '#00CE34', '#F8E71C', '#FA652C'];

export default class TopTags extends Component {
  static propTypes = {
    project: ProjectPresenter.shape().isRequired,
    loading: PropTypes.bool.isRequired,
    loadTopTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(TagPresenter.shape()).isRequired,
    onExport: PropTypes.func.isRequired,
  };

  state = { isSidebarOpened: false, sidebarProps: {} };

  componentDidMount() {
    const { loadTopTags, project } = this.props;

    loadTopTags(ProjectPresenter.id(project));
  }

  handleProfilesExport = (params) => () => {
    const { onExport } = this.props;

    return onExport({ type: PROJECT_TAG_PROFILES_CSV, params });
  };

  handleOpenSidebar = (sidebarProps) => this.setState({ ...this.state, isSidebarOpened: true, sidebarProps });

  handleCloseSidebar = () => this.setState({ ...this.state, isSidebarOpened: false, sidebarProps: {} });

  handleClickRootTag = (tag) => () => {
    const tagId = TagPresenter.id(tag);
    const tagTitle = TagPresenter.title(tag);
    const sidebarProps = {
      title: tagTitle,
      filters: { rootTagId: tagId },
      profilePathQuery: { tags: [tagId] },
      onExport: this.handleProfilesExport({ search: { rootTagId: tagId }, tagId }),
    };

    logClickOnBusinessPriorityAnalysisTabDetails('By Initiatives', tagTitle);

    this.handleOpenSidebar(sidebarProps);
  };

  handleClickTag = (rootTag, tag) => () => {
    const rootTagTitle = TagPresenter.title(rootTag);
    const tagId = TagPresenter.id(tag);
    const tagTitle = TagPresenter.title(tag);
    const sidebarProps = {
      title: `${rootTagTitle}: ${tagTitle}`,
      filters: { tagId },
      profilePathQuery: { tags: [tagId] },
      onExport: this.handleProfilesExport({ search: { tagId }, tagId }),
    };

    logClickOnBusinessPriorityAnalysisTabDetails('By Initiatives', rootTagTitle, tagTitle);

    this.handleOpenSidebar(sidebarProps);
  };

  tagPercentage = () => {
    const { tags } = this.props;

    return 100 / tags.length;
  };

  renderNestedTags = (tag) => {
    const nestedTags = TagPresenter.nestedTags(tag);

    return (
      <ul>
        {nestedTags.map((nestedTag) => (
          <li key={TagPresenter.title(nestedTag)}>
            <Anchor onClick={this.handleClickTag(tag, nestedTag)}>{TagPresenter.title(nestedTag)}</Anchor>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { loading, tags, project } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const { isSidebarOpened, sidebarProps } = this.state;

    return (
      <>
        {isSidebarOpened && <Sidebar project={project} onClose={this.handleCloseSidebar} {...sidebarProps} />}
        <div className={styles.tags}>
          <table className={styles.table}>
            <tbody>
              <tr>
                {tags.map((tag, index) => (
                  <td key={TagPresenter.title(tag)}>
                    <div className={styles.tag}>
                      <div className={styles.label} style={{ backgroundColor: COLORS[index] }} />
                      <div className={styles.text}>
                        <Anchor onClick={this.handleClickRootTag(tag)}>{TagPresenter.title(tag)}</Anchor>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.tagsLine}>
          {tags.map((tag, index) => (
            <div
              key={TagPresenter.title(tag)}
              className={styles.part}
              style={{ width: `${this.tagPercentage(tag)}%`, backgroundColor: COLORS[index] }}
            />
          ))}
        </div>
        <table className={styles.table}>
          <tbody>
            <tr>
              {tags.map((tag) => (
                <td key={TagPresenter.title(tag)}> {this.renderNestedTags(tag)} </td>
              ))}
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}
