import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import onClickOutside from 'react-onclickoutside';
import { Tag as TagIcon } from 'grommet-icons';
import { compose, isNil } from 'ramda';

import { reactSelectDebounce } from 'utils/HelperMethods';

import AsyncSelect from 'components/AsyncSelect';
import TagPresenter from 'presenters/TagPresenter';

import styles from './Popover.module.css';

class Popover extends Component {
  static propTypes = {
    left: PropTypes.number,
    top: PropTypes.number,
    onClose: PropTypes.func,
    onAddTag: PropTypes.func,
    handleLoadTags: PropTypes.func,
    onFocus: PropTypes.func,
    saving: PropTypes.bool.isRequired,
  };

  state = {
    isSearch: false,
    selectedTagId: null,
  };

  handleShowSearch = () => {
    this.setState({ isSearch: true });
  };

  handleClickOutside = () => {
    this.handleClose();
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleAddTag = () => {
    const { selectedTagId } = this.state;
    this.props.onAddTag(selectedTagId).then(this.handleClose);
  };

  handleSelect = ({ id }) => this.setState({ selectedTagId: id });

  handleLoadTagsDebounced = reactSelectDebounce(this.props.handleLoadTags);

  renderSearch = () => {
    const { saving } = this.props;
    const { selectedTagId } = this.state;
    const isButtonDisabled = isNil(selectedTagId) || saving;

    return (
      <div className={styles.search}>
        <div className={styles.searchСontainer}>
          <AsyncSelect
            placeholder="Search the Tag"
            getOptionValue={TagPresenter.id}
            getOptionLabel={TagPresenter.fullTitle}
            defaultOptions
            loadOptions={this.handleLoadTagsDebounced}
            onChange={this.handleSelect}
            noBottom
          />
        </div>

        <button className={styles.button} disabled={isButtonDisabled} onClick={this.handleAddTag}>
          Add Tag
        </button>
      </div>
    );
  };

  render() {
    const { left, top } = this.props;
    const { isSearch } = this.state;
    const rootStyles = cn([styles.root], { [styles.isSearch]: isSearch });

    return (
      <div
        className={rootStyles}
        style={{
          left,
          top,
        }}
        onMouseUp={this.props.onFocus}
        role="presentation"
      >
        <div className={styles.content}>
          {isSearch ? (
            this.renderSearch()
          ) : (
            <button className={styles.icon} onClick={this.handleShowSearch}>
              <TagIcon />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default compose(onClickOutside)(Popover);
