import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Spoiler from 'components/Spoiler';

import WorkPresenter from 'presenters/WorkPresenter';

class ReviewWorksList extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(WorkPresenter.shape()).isRequired,
  };

  render() {
    const { value } = this.props;

    return (
      <section>
        <Spoiler>
          <ul>
            {value.map((work) => (
              <li key={work.id}>
                {WorkPresenter.fullJobTitle(work)}, {WorkPresenter.organizationName(work)}
                {` ${WorkPresenter.timeInterval(work)}`}
              </li>
            ))}
          </ul>
        </Spoiler>
      </section>
    );
  }
}

export default ReviewWorksList;
