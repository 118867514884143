import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';

import Button from 'components/v2/Button';
import PowerPlugIcon from 'icons/PowerPlugIcon';

import TagPresenter from 'presenters/TagPresenter.js';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import TagPolicy from 'policies/TagPolicy';

import styles from './TagsRowCells.module.css';

const TagsRowCells = (props) => {
  const { row: tag, onPopulate, currentUser, populatingTagId } = props;

  const handlePopulate = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onPopulate(tag);
  };

  const statusClass = cx([styles.status], {
    [styles.runned]: TagPresenter.isPopulatingRunned(tag),
    [styles.completed]: TagPresenter.isPopulatingCompleted(tag),
    [styles.failed]: TagPresenter.isPopulatingFailed(tag),
  });
  const isPopulating = populatingTagId === TagPresenter.id(tag);

  return (
    <>
      <td>{TagPresenter.title(tag)}</td>
      <td>{TagPresenter.parentFullTitle(tag)}</td>
      {TagPolicy.canPopulateWithTag(currentUser) && (
        <>
          <td className={statusClass}> {TagPresenter.humanPopulatingStateName(tag)} </td>
          <td>{TagPresenter.formattedLastPopulatingCompletedAt(tag)}</td>
          <td>
            {!TagPresenter.isPopulatingRunned(tag) && (
              <Button className={styles.close} onClick={handlePopulate} disabled={isPopulating} variant="icon">
                <PowerPlugIcon width={14} height={14} color="primary" className={styles.populateIcon} />
                Populate Automatically
              </Button>
            )}
          </td>
        </>
      )}
    </>
  );
};

TagsRowCells.propTypes = {
  row: PropTypes.shape(),
  currentUser: CurrentUserPresenter.shape(),
  onPopulate: PropTypes.func.isRequired,
  populatingTagId: PropTypes.number,
};

export default TagsRowCells;
