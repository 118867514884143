import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { Box } from 'grommet';

import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { debounce } from 'utils/HelperMethods';

import Table from 'components/Table';
import Button from 'components/Button';
import Search from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import InterestRowCells from './components/InterestRowCells';
import NewInterestModal from 'containers/AdminPanel/containers/NewInterestModal';

import Routes from 'routes';
import InterestPresenter from 'presenters/InterestPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import InterestPolicy from 'policies/InterestPolicy';

const MAX_SEARCH_LENGTH = 40;

class InterestListPage extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    interests: PropTypes.arrayOf(InterestPresenter.shape()).isRequired,
    loadInterests: PropTypes.func.isRequired,
    bulkDestroyInterests: PropTypes.func.isRequired,
    filters: PropTypes.shape(),
    filterInterests: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    initializeInterestList: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
  };

  state = {
    showCreateInterestPopup: false,
  };

  componentDidMount() {
    this.props.initializeInterestList();
    this.props.loadInterests({ page: 1, filters: this.props.filters });
  }

  tableColumns = [
    {
      name: 'id',
      field: 'id',
      width: '125',
    },
    {
      name: 'title',
      field: 'title',
    },
    {
      name: 'Status',
      field: 'state',
    },
    {
      name: 'Created At',
      field: 'createdAt',
    },
  ];

  handleCreateNewInterest = () => this.setState({ showCreateInterestPopup: true });

  handleCancelNewInterest = () => this.setState({ showCreateInterestPopup: false });

  handleSubmitNewInterest = () => {
    const { loadInterests, initializeInterestList, filters } = this.props;
    initializeInterestList();
    loadInterests(1, filters).then(() => this.setState({ showCreateInterestPopup: false }));
  };

  isAllInterestsLoaded = () => {
    const { nextPage } = this.props;

    return isNil(nextPage);
  };

  handleLoadMore = () => {
    const { loading, nextPage, loadInterests, filters } = this.props;

    if (!loading && !this.isAllInterestsLoaded()) {
      loadInterests({ page: nextPage, filters });
    }
  };

  handleFilter = (fieldName, value) => {
    const { filters, filterInterests } = this.props;

    filterInterests({ ...filters, [fieldName]: value });
  };

  handleFilterDebounced = debounce(this.handleFilter);

  handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFilterDebounced(fieldName, value);

  handleDelete = (ids) => {
    const { bulkDestroyInterests, filterInterests, filters } = this.props;

    bulkDestroyInterests({ ids }).then(() => filterInterests(filters));
  };

  handleSort = (sortFields) => {
    const { filters, filterInterests } = this.props;

    filterInterests({ ...filters, sortFields });
  };

  handleInterestClick = (interestId) => {
    const { currentUser } = this.props;

    if (InterestPolicy.canSeeElement(currentUser)) {
      window.open(Routes.editAdminInterestPath(interestId));
    }
  };

  render() {
    const { currentUser, filters, interests, totalCount } = this.props;
    const { showCreateInterestPopup } = this.state;

    return (
      <Box margin="none" full="horizontal">
        <PageHeader title="Interests" />

        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box width="medium" margin={{ right: 'small' }}>
            <Search
              placeholder="Search"
              onChange={this.handleFilterChange('strIdOrTitleCont')}
              maxLength={MAX_SEARCH_LENGTH}
            />
          </Box>
          {InterestPolicy.canAddElement(currentUser) && (
            <Box margin={{ right: 'small' }}>
              <Button primary label="Add Interest" onClick={this.handleCreateNewInterest} />
            </Box>
          )}
          {InterestPolicy.canExportList(currentUser) && (
            <Box>
              <Button
                label="Export"
                target="_blank"
                href={Routes.csvV1AdminInterestsPath(buildFilterParams(filters))}
              />
            </Box>
          )}
        </Box>

        <Table
          columns={this.tableColumns}
          sorts={filters.sortFields}
          onDelete={this.handleDelete}
          onMore={this.handleLoadMore}
          onRowClick={this.handleInterestClick}
          onSort={this.handleSort}
          hasMore={!this.isAllInterestsLoaded()}
          rows={interests}
          rowCellsComponent={<InterestRowCells />}
          totalRows={totalCount}
          rowsDeletable={InterestPolicy.canDestroyElement(currentUser)}
        />

        {showCreateInterestPopup && (
          <NewInterestModal onClose={this.handleCancelNewInterest} onCreateSuccess={this.handleSubmitNewInterest} />
        )}
      </Box>
    );
  }
}

export default InterestListPage;
