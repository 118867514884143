import rollbar from 'rollbar';
import config from 'config';
import { retrieveCurrentUserId } from 'utils/currentUserHelper';

const rollbarConfig = {
  accessToken: config.rollbar.szexQJHhkE,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: config.app.env,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.app.version,
        guess_uncaught_frames: true,
      },
    },
  },
  enabled: config.rollbar.enabled,
  transform: (payload) => {
    const currentUserId = retrieveCurrentUserId();

    if (currentUserId) {
      payload.person = { id: currentUserId }; // eslint-disable-line no-param-reassign
    }
  },
  ignoredMessages: ['^API Network Error:.*', '[object Response]'],
};

export default rollbar.init(rollbarConfig);
