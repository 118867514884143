import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { includes, equals, reject } from 'ramda';

import { isPresent } from 'utils/HelperMethods';
import { paginate } from 'utils/PaginationHelper';

import { Checkbox } from 'components/v2/Form';
import QuestionMarkButton from 'components/QuestionMarkButton';
import Button from 'components/Button';
import MatchItem from './components/MatchItem';
import Pagination from 'containers/UserPanel/components/Pagination';

import styles from './MatchesList.module.css';

const PER_PAGE = 10;

const MatchesList = (props) => {
  const {
    confirmedMatchesNames,
    onConfirmedMatchesNamesChange,
    matches,
    matchedCount,
    notMatchedCount,
    isIncludedExecutives,
    isIncludedDirectorsBoard,
    isIncludedDirectorsBoardFtse100,
    onIncludeExecutivesFilterChange,
    onIncludeDirectorsBoardFilterChange,
    onIncludeDirectorsBoardFtse100FilterChange,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ isMatched: true });

  const isFilterSelected = (filterName) => {
    const { [filterName]: filter } = filters;

    return filter;
  };

  const handleShowMatched = () => {
    setCurrentPage(1);
    setFilters({ ...filters, isMatched: true });
  };

  const handleShowNotFounded = () => {
    setCurrentPage(1);
    setFilters({ ...filters, isMatched: false });
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleMatchItemInclusionChange = (matchItem) => {
    const { organization } = matchItem;
    const isMatchItemIncluded = includes(organization.name, confirmedMatchesNames);

    if (isMatchItemIncluded) {
      const newConfirmedMatchesNames = reject(equals(organization.name), confirmedMatchesNames);

      onConfirmedMatchesNamesChange(newConfirmedMatchesNames);
    } else {
      onConfirmedMatchesNamesChange([...confirmedMatchesNames, organization.name]);
    }
  };

  const filterMatches = () => {
    const { isMatched } = filters;

    return matches.filter(({ organization }) => isPresent(organization) === isMatched);
  };

  const renderMatch = (match) => {
    const { organization } = match;
    const isIncluded = isPresent(organization) && includes(organization.name, confirmedMatchesNames);

    return (
      <MatchItem
        key={match.index}
        item={match}
        isIncluded={isIncluded}
        onInclusionChange={handleMatchItemInclusionChange}
      />
    );
  };

  const matchedClassNames = cn([styles.button], { [styles.primary]: isFilterSelected('isMatched') });
  const notFoundClassNames = cn([styles.button], { [styles.primary]: !isFilterSelected('isMatched') });

  const filteredMatches = filterMatches();
  const totalCount = filteredMatches.length;
  const paginatedMatches = paginate(filteredMatches, currentPage, PER_PAGE);

  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <div className={styles.match}>
          <Button className={matchedClassNames} primary={isFilterSelected('isMatched')} onClick={handleShowMatched}>
            Matched: <span className={styles.counter}>{matchedCount}</span>
          </Button>
          <Button
            className={notFoundClassNames}
            primary={!isFilterSelected('isMatched')}
            onClick={handleShowNotFounded}
          >
            Not Found: <span className={styles.counter}>{notMatchedCount}</span>
          </Button>
        </div>
      </div>

      {matchedCount > 0 && (
        <div className={styles.filters}>
          <div className={styles.filter}>
            <Checkbox checked={isIncludedExecutives} onChange={onIncludeExecutivesFilterChange} />
            <div className={styles.checkboxLabel}>Include Executives</div>
          </div>

          <div className={styles.filter}>
            <Checkbox checked={isIncludedDirectorsBoard} onChange={onIncludeDirectorsBoardFilterChange} />
            <div className={styles.checkboxLabel}>Include Board of Directors (Fortune 500)</div>
          </div>

          <div className={styles.filter}>
            <Checkbox checked={isIncludedDirectorsBoardFtse100} onChange={onIncludeDirectorsBoardFtse100FilterChange} />
            <div className={styles.checkboxLabel}>Include Board of Directors (FTSE 100)</div>
          </div>

          <div className={styles.filter}>
            <QuestionMarkButton
              onClick={() => {}}
              text="We have up-to-date profiles on board members at all Fortune 500 and FTSE 100 companies. Use the check-boxes to include or exclude them from your project"
              placement="right"
              overlayClassName={styles.tooltip}
            />
          </div>
        </div>
      )}

      <div className={styles.list}>{paginatedMatches.map(renderMatch)}</div>
      {isPresent(currentPage) && isPresent(totalCount) && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={currentPage}
            perPage={PER_PAGE}
            totalCount={totalCount}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

MatchesList.propTypes = {
  confirmedMatchesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onConfirmedMatchesNamesChange: PropTypes.func.isRequired,
  matches: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  matchedCount: PropTypes.number,
  notMatchedCount: PropTypes.number,
  isIncludedExecutives: PropTypes.bool,
  isIncludedDirectorsBoard: PropTypes.bool,
  onIncludeExecutivesFilterChange: PropTypes.func.isRequired,
  onIncludeDirectorsBoardFilterChange: PropTypes.func.isRequired,
};

export default MatchesList;
