import CsvSourceEditPageConstants from './CsvSourceEditPageConstants';
import CsvSourcesRepository from 'repositories/CurrentUser/Project/CsvSourcesRepository';

export function loadTargetingSource(projectId, sourceId) {
  return (dispatch) => {
    dispatch({ type: CsvSourceEditPageConstants.LOAD_PROJECT_CSV_SOURCE_START });

    return CsvSourcesRepository.show(projectId, sourceId).then(({ source, meta: { remainingRequestsCount } }) => {
      dispatch({ type: CsvSourceEditPageConstants.LOAD_PROJECT_CSV_SOURCE_SUCCESS, source, remainingRequestsCount });

      return source;
    });
  };
}

export function changeRemainingRequestsCount(remainingRequestsCount) {
  return {
    type: CsvSourceEditPageConstants.CHANGE_PROJECT_CSV_SOURCE_REMAINING_REQUESTS_COUNT,
    remainingRequestsCount,
  };
}
