import keyMirror from 'keymirror';

const RelationshipsMappingPageConstants = keyMirror({
  LOAD_PROFILE_RELATIONSHIPS_MAPPING_START: null,
  LOAD_PROFILE_RELATIONSHIPS_MAPPING_SUCCESS: null,

  CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_START: null,
  CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_SUCCESS: null,
  CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_ERROR: null,
});

export default RelationshipsMappingPageConstants;
