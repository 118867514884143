import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

import { fromJSDate, parseISO8601 } from 'utils/DateTime';

import 'react-datepicker/dist/react-datepicker.css';

import DateInput from './components/DateInput';

import styles from './DatePicker.module.css';

const DatePicker = (props) => {
  const { className, onChange, value, ...rest } = props;

  const date = parseISO8601(value)?.toJSDate();
  const dateInputClassName = clsx(styles.dateInput, className);

  const handleChange = (newValue) => onChange(fromJSDate(newValue)?.toISO());

  return (
    <ReactDatePicker
      {...rest}
      className={dateInputClassName}
      calendarClassName={styles.datePicker}
      customInput={<DateInput />}
      selected={date}
      onChange={handleChange}
      showPopperArrow={false}
    />
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  className: null,
};

export default DatePicker;
