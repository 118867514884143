import React from 'react';
import ContentWrapper from 'components/ContentWrapper';
import PropTypes from 'prop-types';
import cn from 'clsx';

import { withAuthenticator } from 'containers/Authenticator';

import BackendNotifications from 'containers/UserPanel/containers/BackendNotifications';
import Header from './components/Header';
import Footer from './components/Footer';
import Notifications from 'containers/Notifications';

import styles from './UserLayout.module.css';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import NotificationsSummaryPresenter from 'presenters/NotificationsSummaryPresenter';

const UserLayoutComponent = (props) => {
  const { children, onSignOut, onStopImpersonate, currentUser, notificationsSummary } = props;
  const rootStyles = cn([styles.root], 'client');

  const handleSignOut = () => {
    onSignOut();
  };

  return (
    <div className={rootStyles}>
      <div className={styles.siteContent}>
        <ContentWrapper>
          <Header
            currentUser={currentUser}
            onStopImpersonate={onStopImpersonate}
            onSignOut={handleSignOut}
            notificationsSummary={notificationsSummary}
          />
          <BackendNotifications currentUser={currentUser} />
          <main className={styles.main}>{children}</main>
        </ContentWrapper>
        <Notifications />
      </div>
      <Footer />
    </div>
  );
};

const UserLayout = withAuthenticator(UserLayoutComponent, ({ signOut, stopImpersonate }) => ({
  onSignOut: signOut,
  onStopImpersonate: stopImpersonate,
}));

UserLayoutComponent.propTypes = {
  children: PropTypes.node,
  currentUser: CurrentUserPresenter.shape(),
  onSignOut: PropTypes.func.isRequired,
  onStopImpersonate: PropTypes.func.isRequired,
  notificationsSummary: NotificationsSummaryPresenter.shape(),
};

export default UserLayout;
