import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomizedXAxisCompanyNameTick.module.css';

const CustomizedXAxisCompanyNameTick = (props) => {
  const {
    x,
    y,
    payload: { index },
    data,
    barWidth,
    height,
    width: chartWidth,
  } = props;

  const companyIds = data.map((item) => item.companyId);
  const currentCompanyId = data[index].companyId;
  const firstIndexForCurrentCompany = companyIds.indexOf(currentCompanyId);

  if (firstIndexForCurrentCompany !== index) {
    return null;
  }

  const barWidthWithPadding = chartWidth / data.length;
  const barPaddingWidth = (barWidthWithPadding - barWidth) / 2;
  const countRequestorsForCurrentCompany = companyIds.filter((companyId) => companyId === currentCompanyId).length;
  const xPosition = x - Math.round(barWidth / 2) - barPaddingWidth;

  return (
    <foreignObject x={xPosition} y={y} width={barWidthWithPadding * countRequestorsForCurrentCompany} height={height}>
      <div className={styles.root}>{data[index].companyName}</div>
    </foreignObject>
  );
};

CustomizedXAxisCompanyNameTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape(),
};

export default CustomizedXAxisCompanyNameTick;
