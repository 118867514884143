import React from 'react';
import PropTypes from 'prop-types';

const CustomizedTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({}),
};

export default CustomizedTick;
