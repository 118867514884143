import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SavedSearch from './components/SavedSearch';

import SavedSearchPresenter from 'presenters/SavedSearchPresenter';

import styles from './ChangedSavedSearches.module.css';

class ChangedSavedSearches extends Component {
  static propTypes = {
    savedSearches: PropTypes.arrayOf(SavedSearchPresenter.shape()).isRequired,
    statisticsStartedAt: PropTypes.string.isRequired,
  };

  render() {
    const { savedSearches, statisticsStartedAt } = this.props;

    return (
      <div className={styles.root}>
        {savedSearches.map((savedSearch) => (
          <SavedSearch
            key={SavedSearchPresenter.id(savedSearch)}
            savedSearch={savedSearch}
            statisticsStartedAt={statisticsStartedAt}
          />
        ))}
      </div>
    );
  }
}

export default ChangedSavedSearches;
