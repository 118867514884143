import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';

import styles from './Modal.module.css';

ReactModal.setAppElement('#root');

const Modal = (props) => {
  const { className, children, isOpen, onClose } = props;

  const modalClassName = clsx(styles.modal, className);
  const overlayClassName = styles.overlay;

  return (
    <ReactModal className={modalClassName} isOpen={isOpen} onRequestClose={onClose} overlayClassName={overlayClassName}>
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  className: null,
};

export default Modal;
