import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import UserPresenter from 'presenters/UserPresenter';

import PencilIcon from 'icons/PencilIcon';

import Button from 'components/v2/Button';
import Form from 'components/v2/Form';
import Typography from 'components/v2/Typography';
import UserAvatar from 'components/UserAvatar';

import EditForm from './components/EditForm';

import styles from './Manager.module.css';

const Manager = (props) => {
  const { manager, onManagerUpdate } = props;

  const [isEditFormOpen, setEditFormOpen] = useState(false);

  const isManagerPresent = !isNil(manager);

  const handleEditFormOpen = () => setEditFormOpen(true);
  const handleEditFormClose = () => setEditFormOpen(false);
  const handleEditFormSubmit = async (attributes) => {
    await onManagerUpdate(attributes.manager);
    handleEditFormClose();
  };

  useEffect(() => {
    handleEditFormClose();
  }, [manager]);

  return (
    <div className={styles.manager}>
      <Typography color="boulder" variant="body1">
        Account Manager:
      </Typography>
      {isEditFormOpen ? (
        <Form initialValues={{ manager }} onSubmit={handleEditFormSubmit}>
          {(formProps) => <EditForm {...formProps} onCancel={handleEditFormClose} />}
        </Form>
      ) : (
        <>
          {isManagerPresent && <UserAvatar src={UserPresenter.avatarUrl(manager)} size={24} />}
          <Typography className={styles.fullName} color="primary" variant="body1">
            {isManagerPresent ? UserPresenter.fullName(manager) : 'None'}
          </Typography>
          <Typography variant="body1">{UserPresenter.email(manager)}</Typography>
          <Button onClick={handleEditFormOpen} variant="icon">
            <PencilIcon width={14} height={14} />
          </Button>
        </>
      )}
    </div>
  );
};

Manager.propTypes = {
  manager: UserPresenter.shape().isRequired,
  onManagerUpdate: PropTypes.func.isRequired,
};

export default Manager;
