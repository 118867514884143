import { adminProjectsRestHooks } from 'slices/admin/projectsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useProjects = () => {
  const { projects, loadProjects, loadMoreProjects } = adminProjectsRestHooks.use();

  return {
    projects: projects.items,
    projectsMeta: projects.meta,
    projectsLoadingStatus: useFetchStatus(projects.loadingStatus),
    projectsLoadingMoreStatus: useFetchStatus(projects.moreLoadingStatus),
    loadProjects,
    loadMoreProjects,
  };
};

export default useProjects;
