import { createSlice } from '@reduxjs/toolkit';

import { createRestSlice } from 'utils/RestSlice';
import TargetingSearchRepository from 'repositories/CurrentUser/TargetingSearchRepository';

const restSlice = createRestSlice({
  resource: 'targetingSearch',
  repository: TargetingSearchRepository,
  slices: ['loadTargetingSearches'],
});

const initialState = {
  ...restSlice.initialState,
};

const targetingSearchSlice = createSlice({
  name: 'clientTargetingSearches',
  initialState,
  reducers: {
    ...restSlice.reducers,
  },
});

export const clientTargetingSearchReducer = targetingSearchSlice.reducer;

export default targetingSearchSlice;

export const clientTargetingSearchRestHooks = restSlice.hooks(targetingSearchSlice, 'clientTargetingSearchReducer');
