import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propOr, update } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Checkbox from './components/Checkbox';
import Modal from 'containers/UserPanel/components/Modal';

import ProfilePresenter from 'presenters/ProfilePresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

import styles from './ChangeAlertsPreferencesFormModal.module.css';

class ChangeAlertsPreferencesFormModal extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    profile: ProfilePresenter.shape().isRequired,
    subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()).isRequired,
    organizations: PropTypes.arrayOf(OrganizationPresenter.shape()).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(...props) {
    super(...props);
    this.state = {
      profileSubscription: this.getCurrentProfileSubscription(),
      organizationSubscriptions: this.getCurrentOrganizationSubscriptions(),
      profileSubscriptionErrors: {},
      organizationSubscriptionsErrors: [],
    };
  }

  getCurrentProfileSubscription = () => {
    const { profile, subscriptions } = this.props;
    const profileSubscription = subscriptions.find((subscription) =>
      SubscriptionPresenter.isSubscriptionForProfile(subscription, profile),
    );

    return {
      profileId: profile.id,
      isSubscribed: isPresent(profileSubscription),
      subscriptionId: propOr(null, 'id', profileSubscription),
      kind: 'email_alert',
    };
  };

  getCurrentOrganizationSubscriptions = () => {
    const { organizations, subscriptions } = this.props;

    return organizations.map((organization) => {
      const organizationSubscription = subscriptions.find((sub) =>
        SubscriptionPresenter.isSubscriptionForOrganization(sub, organization),
      );
      return {
        organizationId: organization.id,
        isSubscribed: isPresent(organizationSubscription),
        subscriptionId: propOr(null, 'id', organizationSubscription),
        name: `Get alerts on all executives from ${OrganizationPresenter.name(organization)}`,
        kind: 'email_alert',
      };
    });
  };

  buildSubscriptionParams = ({ subscriptionId, isSubscribed, ...rest }) =>
    isSubscribed ? { id: subscriptionId, ...rest } : { id: subscriptionId, _delete: true };

  handleSubmit = () => {
    const { profileSubscription, organizationSubscriptions } = this.state;
    const { onSubmit } = this.props;
    const profileSubscriptionParams = this.buildSubscriptionParams(profileSubscription);
    const organizationSubscriptionsParams = organizationSubscriptions.map(this.buildSubscriptionParams);
    const subscriptionsParams = [profileSubscriptionParams, ...organizationSubscriptionsParams];

    onSubmit(subscriptionsParams).catch((errors) => {
      const subscriptionsErrors = propOr({}, 'subscriptions', errors);
      const profileSubscriptionErrors = subscriptionsErrors[0];
      const organizationSubscriptionsErrors = Object.keys(subscriptionsErrors).reduce((acc, errorIndex) => {
        acc[errorIndex - 1] = subscriptionsErrors[errorIndex];

        return acc;
      }, {});

      this.setState({ profileSubscriptionErrors, organizationSubscriptionsErrors });
    });
  };

  handleProfileSubscriptionChange = ({ target: { checked } }) => {
    const { profileSubscription: subscription } = this.state;

    this.setState({ profileSubscription: { ...subscription, isSubscribed: checked } });
  };

  handleOrganizationSubscriptionChange =
    (organization, index) =>
    ({ target: { checked } }) => {
      const { organizationSubscriptions } = this.state;
      const subscriptions = update(index, { ...organization, isSubscribed: checked }, organizationSubscriptions);

      this.setState({ organizationSubscriptions: subscriptions });
    };

  renderOrganizationSubscriptionsCheckBoxes = () => {
    const { organizationSubscriptions, organizationSubscriptionsErrors } = this.state;

    return organizationSubscriptions.map((subscription, index) => (
      <Checkbox
        key={subscription.organizationId}
        label={subscription.name}
        onChange={this.handleOrganizationSubscriptionChange(subscription, index)}
        checked={subscription.isSubscribed}
        errors={organizationSubscriptionsErrors[index]}
      />
    ));
  };

  render() {
    const { onClose, loading } = this.props;
    const { profileSubscription, profileSubscriptionErrors } = this.state;

    return (
      <Modal className={styles.modal} onClose={onClose}>
        <div className={styles.header}>Choose your alert preference.</div>
        <div className={styles.content}>
          <Checkbox
            label="Get alerts on this executive"
            onChange={this.handleProfileSubscriptionChange}
            checked={profileSubscription.isSubscribed}
            errors={profileSubscriptionErrors}
          />
          {this.renderOrganizationSubscriptionsCheckBoxes()}
        </div>
        <div className={styles.actions}>
          <Button className={styles.button} label="Save" disabled={loading} primary onClick={this.handleSubmit} />
        </div>
      </Modal>
    );
  }
}

export default ChangeAlertsPreferencesFormModal;
