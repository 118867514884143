export default {
  app: {
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    defaultAccountManagerEmail: 'customerservice@boardroominsiders.com',
    customerSupportEmail: 'customerservice@boardroominsiders.com',
  },
  rollbar: {
    szexQJHhkE: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    enabled: true,
  },
  host: 'https://app.boardroominsiders.com',
  assetsHost: 'https://assets.boardroominsiders.com',
  logOutPath: '//boardroominsiders.com',
  recaptcha: {
    KvujulVHW0: '6LeALYUUAAAAAApcNRf77JVgqQDWzq8flQbY-2Ks',
  },
  hubspot: {
    portalId: 273391,
  },
  search: {
    textValueMaxLength: 100,
    queryValueMaxLength: 200,
  },
  attachments: {
    maxFileSize: '4 megabytes',
  },
  changesHighlightsClassName: 'changesHighlights',
  amplitude: {
    MpDhboSukh: '59141c5d3411a96605cc264ec12524ca',
  },
  ga: {
    v3: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
    v4: process.env.REACT_APP_GOOGLE_ANALYTICS_ID_SECOND,
  },
};
