import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import CompanyPresenter from 'presenters/CompanyPresenter';
import CompanyDepartmentPresenter from 'presenters/Company/DepartmentPresenter';

import Loader from 'components/v2/Loader';
import InfiniteScroll from 'components/v2/InfiniteScroll';
import Table, { TableBody, TableHead, TableLoaderRow } from 'components/v2/Table';

import Header from './components/Header';
import Row from './components/Row';

import styles from './DepartmentsTable.module.css';

const DepartmentsTable = (props) => {
  const {
    company,
    companyDepartments,
    companyDepartmentsStatistic,
    companyDepartmentsStatisticLoadingStatus,
    loadingStatus,
    loadingMoreStatus,
    meta,
    onLoadMore,
    onSortingsChange,
    sortings,
  } = props;

  const remainingRequestsTotalNumber = CompanyDepartmentPresenter.requestsNumber(companyDepartmentsStatistic);
  const purchasedRequestsTotalNumber = CompanyDepartmentPresenter.purchasedRequestsNumber(companyDepartmentsStatistic);
  const projectsTotalNumber = CompanyDepartmentPresenter.projectsNumber(companyDepartmentsStatistic);
  const purchasedProjectsTotalNumber = CompanyDepartmentPresenter.purchasedProjectsNumber(companyDepartmentsStatistic);
  const seatsTotalNumber = CompanyDepartmentPresenter.seatsNumber(companyDepartmentsStatistic);
  const activeSeatsTotalNumber = CompanyDepartmentPresenter.activeSeatsNumber(companyDepartmentsStatistic);

  return (
    <InfiniteScroll hasMore={!isNil(meta.nextPage)} loadingMoreStatus={loadingMoreStatus} onLoadMore={onLoadMore}>
      <Table>
        <TableHead>
          <Header sortings={sortings} onSortingsChange={onSortingsChange} />
        </TableHead>
        <TableBody>
          {companyDepartments.map((companyDepartment) => (
            <Row key={companyDepartment.id} company={company} companyDepartment={companyDepartment} />
          ))}

          {(loadingStatus.isPending || loadingMoreStatus.isPending) && <TableLoaderRow colSpan={10} />}

          <tr className={styles.totalRow}>
            {companyDepartmentsStatisticLoadingStatus.isPending && (
              <td colSpan={10}>
                <Loader className={styles.loader} />
              </td>
            )}
            {!isNil(companyDepartmentsStatistic) && (
              <>
                <td>Total</td>
                <td>{`${activeSeatsTotalNumber}/${seatsTotalNumber}`}</td>
                <td />
                <td />
                <td />
                <td>{`${remainingRequestsTotalNumber}/${purchasedRequestsTotalNumber}`}</td>
                <td />
                <td>{`${projectsTotalNumber}/${purchasedProjectsTotalNumber}`}</td>
                <td />
                <td />
              </>
            )}
          </tr>
        </TableBody>
      </Table>
    </InfiniteScroll>
  );
};

DepartmentsTable.propTypes = {
  company: CompanyPresenter.shape().isRequired,
  companyDepartments: PropTypes.arrayOf(CompanyDepartmentPresenter.shape()).isRequired,
  companyDepartmentsStatistic: CompanyDepartmentPresenter.shape(),
  companyDepartmentsStatisticLoadingStatus: PropTypes.shape(),
  loadingMoreStatus: PropTypes.shape({ isPending: PropTypes.bool }).isRequired,
  loadingStatus: PropTypes.shape({ isPending: PropTypes.bool }).isRequired,
  meta: PropTypes.shape({ nextPage: PropTypes.number }).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onSortingsChange: PropTypes.func.isRequired,
  sortings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default DepartmentsTable;
