import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomizedTooltip.module.css';

const CustomizedTooltip = (props) => {
  const { x, y, barWidth, companiesStatisctics, totalCountKeyName, day, scrollOffset } = props;
  const newX = x - scrollOffset + barWidth / 2;
  const filteredCompaniesStatisctics = companiesStatisctics
    .filter((company) => company[totalCountKeyName] > 0)
    .sort((a, b) => b[totalCountKeyName] - a[totalCountKeyName]);

  return (
    <div className={styles.root} style={{ top: y, left: newX }}>
      <div className={styles.tooltip}>
        {filteredCompaniesStatisctics.map((company) => (
          <div className={styles.item} key={`${day}${company.companyId}`}>
            <div>
              <div>{company.companyName}</div>
            </div>
            <div className={styles.totalCount}>{company[totalCountKeyName]}</div>
          </div>
        ))}
        <div className={styles.arrow} />
      </div>
    </div>
  );
};

CustomizedTooltip.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  barWidth: PropTypes.number.isRequired,
  companiesStatisctics: PropTypes.arrayOf(PropTypes.shape()),
  totalCountKeyName: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
};

export default CustomizedTooltip;
