import { toPairs } from 'ramda';
import { camelize, decamelize } from 'utils/keysConverter';

import ProfileFilteredSearchForm from 'forms/ProfileFilteredSearchForm';

export function parseTargetItemCriterias(criterias) {
  return camelize(criterias.reduce((acc, { type, value }) => ({ ...acc, [type]: value }), {}));
}

export function buildTargetItemCriterias(searchParams) {
  const { filters } = ProfileFilteredSearchForm.attributesToSubmit(searchParams).q;

  return toPairs(decamelize(filters))
    .filter(([, value]) => value.length > 0)
    .map(([type, value]) => ({ type, value }));
}
