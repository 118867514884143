import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import cn from 'clsx';
import styles from './Card.module.css';

class Card extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    fullHeight: PropTypes.bool,
  };

  render() {
    const { className, fullHeight } = this.props;
    const rootStyles = cn([styles.root], className, { [styles.fullHeight]: fullHeight });
    return (
      <div className={rootStyles}>
        <Box pad="medium">{this.props.children}</Box>
      </div>
    );
  }
}

export default Card;
