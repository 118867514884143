import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from './Sidebar.module.css';

class Sidebar extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { children, onClose } = this.props;

    return (
      <div className={styles.sidebar}>
        <div className={styles.root}>
          <div className={styles.header}>
            <button title="Close" onClick={onClose} className={styles.closeButton}>
              <Icon name="close" style={{ width: 19, height: 19 }} />
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default Sidebar;
