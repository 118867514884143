import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfileListPage from './ProfileListPage';
import * as actions from './ProfileListPageActions';

const mapStateToProps = (state) => ({
  saving: state.ProfileListPageReducer.saving,
  filters: state.ProfileListPageReducer.filters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileListPage);
