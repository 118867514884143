import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LUXON_DATE_FORMAT, GROMMET_DATE_FORMAT } from 'utils/DateTime';

import Select from 'components/Select';
import { Field, DatePicker } from 'components/v2/Form';

import styles from './SelectWithDatePicker.module.css';

class SelectWithDatePicker extends Component {
  static propTypes = {
    datepickerValue: PropTypes.string,
    onDatepickerChange: PropTypes.func.isRequired,
  };

  renderPlaceholder() {
    const { datepickerValue, onDatepickerChange } = this.props;

    return (
      <div className={styles.datepickerLabel}>
        <span>Since</span>
        <Field className={styles.field}>
          <DatePicker
            value={datepickerValue}
            onChange={onDatepickerChange}
            dateFormat={LUXON_DATE_FORMAT}
            className={styles.datepicker}
            placeholderText={GROMMET_DATE_FORMAT}
          />
        </Field>
      </div>
    );
  }

  render() {
    const { datepickerValue, onDatepickerChange, ...props } = this.props;

    return <Select {...props} placeholder={this.renderPlaceholder()} />;
  }
}

export default SelectWithDatePicker;
