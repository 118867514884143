import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnchorLink from 'components/grommet/AnchorLink';

import styles from './DeliverableItem.module.css';

export default class DeliverableItem extends Component {
  static propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  render() {
    const { icon, title, description, path, disabled, ...restProps } = this.props;

    return (
      <AnchorLink to={path} className={styles.actionsItem} disabled={disabled} {...restProps}>
        <div className={styles.actionsIcon}> {icon} </div>
        <div className={styles.actionsTitle}> {title}</div>
        <div className={styles.actionsDescription}> {description} </div>
      </AnchorLink>
    );
  }
}
