import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box, TextInput } from 'grommet';
import { PasswordInput } from 'grommet-controls';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Spoiler from 'components/Spoiler';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import PasswordValidator from 'components/PasswordValidator';
import PasswordStrengthRating from 'components/PasswordStrengthRating';
import ChangePhoneNumberModal from './components/ChangePhoneNumberModal';

import styles from './UserEditForm.module.css';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import CurrentUserEditForm from 'forms/CurrentUserEditForm';
import UserPresenter from 'presenters/UserPresenter';
import CompanyPresenter from 'presenters/CompanyPresenter';

const UserEditForm = (props) => {
  const {
    currentUser,
    isSaving,
    isPhoneNumberSaving,
    onUpdatePhoneNumber,
    onResendPhoneNumberConfirmation,
    onUpdate,
    onConfirmPhoneNumber,
  } = props;

  const company = UserPresenter.company(currentUser);
  const [userForm, setUserForm] = useState(CurrentUserEditForm.defaultAttributes(currentUser));
  const [errors, setErrors] = useState(null);
  const [isChangePhoneNumberModalShown, setChangePhoneNumberModalShown] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const updateField = (fieldName, value) => {
    setUserForm({ ...userForm, [fieldName]: value });
  };

  const handleFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      updateField(fieldName, value);

  const handleUserSubmit = () => {
    const attributesToSubmit = CurrentUserEditForm.attributesToSubmit(userForm);

    return onUpdate(attributesToSubmit)
      .then(() => {
        setErrors(null);
      })
      .catch((data) => setErrors(data.errors));
  };

  const handleUserCancel = () => {
    setUserForm(CurrentUserEditForm.defaultAttributes(currentUser));
    setErrors(null);
  };

  const handleChangePhoneNumberShow = () => setChangePhoneNumberModalShown(true);

  const handleChangePhoneNumberModalClose = () => setChangePhoneNumberModalShown(false);

  const handleCurrentUserPhoneNumberConfirm = (params) =>
    onConfirmPhoneNumber(params).then((user) => {
      setUserForm(CurrentUserEditForm.defaultAttributes({ ...currentUser, phone: user.phone }));
      handleChangePhoneNumberModalClose();

      return user;
    });

  const handleValidationPassword = (isValid) => setIsValidPassword(isValid);

  return (
    <div className={styles.form}>
      {isChangePhoneNumberModalShown && (
        <ChangePhoneNumberModal
          onClose={handleChangePhoneNumberModalClose}
          onConfirm={handleCurrentUserPhoneNumberConfirm}
          onUpdate={onUpdatePhoneNumber}
          onResendConfirmation={onResendPhoneNumberConfirmation}
          saving={isPhoneNumberSaving}
        />
      )}
      <Spoiler header="Personal Information" className={styles.collapse}>
        <div className={styles.header}>Personal Information</div>
        <VerticalFormFieldWithBottomErrors label="First Name" errors={errors && errors.firstName} htmlFor="firstName">
          <TextInput value={userForm.firstName} onChange={handleFieldChange('firstName')} id="firstName" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors label="Last Name" errors={errors && errors.lastName} htmlFor="lastName">
          <TextInput value={userForm.lastName} onChange={handleFieldChange('lastName')} id="lastName" />
        </VerticalFormFieldWithBottomErrors>
        {company && (
          <VerticalFormFieldWithBottomErrors label="Company Title">
            <TextInput value={CompanyPresenter.name(company)} disabled />
          </VerticalFormFieldWithBottomErrors>
        )}
        <VerticalFormFieldWithBottomErrors label="Email" errors={errors && errors.email} htmlFor="email">
          <TextInput value={userForm.email} onChange={handleFieldChange('email')} id="email" />
        </VerticalFormFieldWithBottomErrors>
        <VerticalFormFieldWithBottomErrors label="Phone" errors={errors && errors.phone}>
          <TextInput value={userForm.phone} disabled />
        </VerticalFormFieldWithBottomErrors>
        <Box margin={{ bottom: 'medium' }}>
          <Anchor
            label="Change Phone Number"
            className={styles.changePhoneNumber}
            onClick={handleChangePhoneNumberShow}
            size="xsmall"
          />
        </Box>
        <div className={styles.actions}>
          <Button disabled={isSaving} label="Save" primary onClick={handleUserSubmit} />
          <Button disabled={isSaving} label="Cancel" onClick={handleUserCancel} />
        </div>
      </Spoiler>
      <Spoiler header="Change password" className={styles.collapse}>
        <div className={styles.header}>Change password</div>
        <VerticalFormFieldWithBottomErrors label="New password" errors={errors && errors.password} htmlFor="password">
          <PasswordInput
            value={userForm.password}
            onChange={handleFieldChange('password')}
            autoComplete="new-password"
            id="password"
          />
        </VerticalFormFieldWithBottomErrors>
        {isPresent(userForm.password) && (
          <>
            <div className={styles.passwordStrengthRating}>
              <PasswordStrengthRating isValidPassword={isValidPassword} />
            </div>
            <div className={styles.passwordValidator}>
              <PasswordValidator password={userForm.password} onValidation={handleValidationPassword} />
            </div>
          </>
        )}
        <VerticalFormFieldWithBottomErrors
          label="Confirm new password"
          errors={errors && errors.passwordConfirmation}
          htmlFor="passwordConfirmation"
        >
          <PasswordInput
            value={userForm.passwordConfirmation}
            onChange={handleFieldChange('passwordConfirmation')}
            autoComplete="new-password"
            id="passwordConfirmation"
          />
        </VerticalFormFieldWithBottomErrors>
        <div className={styles.actions}>
          <Button disabled={isSaving} label="Save" primary onClick={handleUserSubmit} />
          <Button disabled={isSaving} label="Cancel" onClick={handleUserCancel} />
        </div>
      </Spoiler>
    </div>
  );
};

UserEditForm.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  onUpdate: PropTypes.func.isRequired,
  onUpdatePhoneNumber: PropTypes.func.isRequired,
  onConfirmPhoneNumber: PropTypes.func.isRequired,
  onResendPhoneNumberConfirmation: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isPhoneNumberSaving: PropTypes.bool.isRequired,
};

export default UserEditForm;
