import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Anchor } from 'grommet';

import Routes from 'routes';

import styles from './UnauthorizedMenu.module.css';

export default class UnauthorizedMenu extends Component {
  static propTypes = {
    onCloseMenu: PropTypes.func,
  };

  handleMenuClose = () => {
    this.props.onCloseMenu();
  };

  render() {
    return (
      <div className={styles.nav}>
        <ul>
          <li onClick={this.handleMenuClose} role="presentation">
            <Anchor href={Routes.productsUrl()} label="Products" target="_blank" />
          </li>
          <li onClick={this.handleMenuClose} role="presentation">
            <Anchor href={Routes.guidesUrl()} label="Guides" target="_blank" />
          </li>
          <li onClick={this.handleMenuClose} role="presentation">
            <Anchor href={Routes.articlesUrl()} label="Articles" target="_blank" />
          </li>
          <li onClick={this.handleMenuClose} role="presentation">
            <Anchor href={Routes.contactUsUrl()} label="Contact Us" target="_blank" />
          </li>
          <li onClick={this.handleMenuClose} role="presentation">
            <Anchor href={Routes.sampleProfilesPath()} target="_blank">
              View Sample Profiles
            </Anchor>
          </li>
        </ul>
      </div>
    );
  }
}
