import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Spinner from 'components/Spinner';

import ExecutivesChangesStatisticsPresenter from 'presenters/EditorialDashboard/DatabaseAnalytics/ExecutivesChangesStatisticsPresenter';

import styles from './ExecutivesChangesStatistics.module.css';

export default class ExecutivesChangesStatistics extends Component {
  static propTypes = {
    executivesChangesStatistics: ExecutivesChangesStatisticsPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    loadExecutivesChangesStatistics: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadExecutivesChangesStatistics } = this.props;

    loadExecutivesChangesStatistics();
  }

  renderStatistics() {
    const { executivesChangesStatistics } = this.props;

    return (
      <>
        <div className={styles.item}>
          <div className={styles.title}>This week:</div>
          <div className={styles.number}>
            {ExecutivesChangesStatisticsPresenter.formattedThisWeek(executivesChangesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>This Month:</div>
          <div className={styles.number}>
            {ExecutivesChangesStatisticsPresenter.formattedThisMonth(executivesChangesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Last 3 Months:</div>
          <div className={styles.number}>
            {ExecutivesChangesStatisticsPresenter.formattedLastThreeMonths(executivesChangesStatistics)}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>Last 12 Months:</div>
          <div className={styles.number}>
            {ExecutivesChangesStatisticsPresenter.formattedLastYear(executivesChangesStatistics)}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { executivesChangesStatistics, loading } = this.props;

    return (
      <div>
        <h3 className={styles.header}>Executive Changes</h3>
        {loading || isBlank(executivesChangesStatistics) ? <Spinner /> : this.renderStatistics()}
      </div>
    );
  }
}
