import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box } from 'grommet';
import ProfileAvatar from 'components/ProfileAvatar';

import styles from './ProfileBlock.module.css';

import ProfilePresenter from 'presenters/ProfilePresenter';
import WorkPresenter from 'presenters/WorkPresenter';

import Routes from 'routes';

class ProfileBlockWithoutRouter extends Component {
  onClick = () => {
    const { history, profile } = this.props;

    history.push(Routes.sampleProfilePath(ProfilePresenter.slug(profile)));
  };

  render() {
    const { profile } = this.props;
    const currentWorks = ProfilePresenter.currentWorks(profile) || [];
    const photoUrl = ProfilePresenter.photoUrl(profile);

    return (
      <Box direction="column" className={styles.sampleProfile} onClick={this.onClick}>
        <Box className={styles.avatar}>
          <ProfileAvatar src={photoUrl} size={97} />
        </Box>
        <Box className={styles.info}>
          <div className={styles.header}> {ProfilePresenter.fullNameWithSalutation(profile)} </div>
          {currentWorks.map((currentWork) => (
            <div key={WorkPresenter.id(currentWork)} className={styles.work}>
              <div className={styles.workInfo}> {WorkPresenter.jobTitle(currentWork)} </div>
              <div className={styles.workInfo}> {WorkPresenter.organizationName(currentWork)} </div>
            </div>
          ))}
          <div className={styles.small}> {`Last Updated: ${ProfilePresenter.formattedLastUpdatedAt(profile)}`} </div>
        </Box>
      </Box>
    );
  }
}

ProfileBlockWithoutRouter.propTypes = {
  history: PropTypes.shape(),
  profile: ProfilePresenter.shape(),
};

const ProfileBlock = withRouter(ProfileBlockWithoutRouter);

export default ProfileBlock;
