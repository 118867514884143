import keyMirror from 'keymirror';

const UserPageConstants = keyMirror({
  LOAD_USER_START: null,
  LOAD_USER_SUCCESS: null,

  UPDATE_USER_START: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_ERROR: null,

  SEND_USER_RESET_PASSWORD_EMAIL_START: null,
  SEND_USER_RESET_PASSWORD_EMAIL_SUCCESS: null,
  SEND_USER_RESET_PASSWORD_EMAIL_ERROR: null,
});

export default UserPageConstants;
