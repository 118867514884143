import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(priorityUpdateId, params) {
    const url = Routes.apiV1AdminEditorialDashboardDatabaseAnalyticPriorityUpdateRequestPath(priorityUpdateId);

    return FetchHelpers.post(url, params);
  },
};
