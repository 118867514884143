import NewPasswordConstants from './NewPasswordConstants';
import ResetPasswordRepository from 'repositories/ResetPasswordRepository';

export function changeForm(form) {
  return { type: NewPasswordConstants.CHANGE_NEW_PASSWORD_FORM, form };
}

export function updatePassword(params) {
  return (dispatch) => {
    dispatch({ type: NewPasswordConstants.UPDATE_NEW_PASSWORD_START });

    return ResetPasswordRepository.update(params)
      .then(() => dispatch({ type: NewPasswordConstants.UPDATE_NEW_PASSWORD_SUCCESS }))
      .catch(({ errors }) => dispatch({ type: NewPasswordConstants.UPDATE_NEW_PASSWORD_ERROR, errors }));
  };
}

export function initializeNewPasswordForm(token) {
  return { type: NewPasswordConstants.INITIALIZE_NEW_PASSWORD_FORM, token };
}
