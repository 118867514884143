import { adminAttributeSafeCompaniesRestHooks } from 'slices/admin/attributeSafeCompaniesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useAttributeSafeCompanies = () => {
  const { attributeSafeCompanies, loadAttributeSafeCompanies } = adminAttributeSafeCompaniesRestHooks.use();

  return {
    attributeSafeCompanies: attributeSafeCompanies.items,
    companiesLoadingStatus: useFetchStatus(attributeSafeCompanies.loadingStatus),
    loadAttributeSafeCompanies,
  };
};

export default useAttributeSafeCompanies;
