import React, { useState } from 'react';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import { useCurrentUserRequests } from 'hooks/currentUser';

import RequestsHistoryTable from 'containers/UserPanel/components/RequestsHistory';
import Typography from 'components/v2/Typography';

const RequestsHistory = () => {
  const [requestsFilters, setRequestsFilters] = useState({ sortFields: [] });
  const { loadRequests, loadMoreRequests, requests, requestsLoadingStatus, requestsLoadingMoreStatus, requestsMeta } =
    useCurrentUserRequests();

  const handleRequestsLoad = (page) => loadMoreRequests({ ...buildFilterParams(requestsFilters), page });

  const handleRequestsFilter = (filters) => {
    setRequestsFilters(filters);
    loadRequests(buildFilterParams(filters));
  };

  return (
    <RequestsHistoryTable
      title={<Typography variant="h3">Request History</Typography>}
      loading={requestsLoadingStatus.isPending || requestsLoadingMoreStatus.isPending}
      requests={requests}
      meta={requestsMeta}
      onLoad={handleRequestsLoad}
      filterRequest={handleRequestsFilter}
      filters={requestsFilters}
      searchable
    />
  );
};

export default RequestsHistory;
