import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './RequestsKanbanHeader.module.css';

import { humanRequestStateName } from 'presenters/RequestPresenter';

const laneColors = [
  { id: 'requested', color: '#00a0ce' },
  { id: 'in_research', color: '#00a0ce' },
  { id: 'in_editorial', color: '#00a0ce' },
  { id: 'completed', color: '#00a0ce' },
  { id: 'canceled', color: '#fa652c' },
  { id: 'delivered', color: '#00ce34' },
];

const findColor = (id) => {
  const lane = laneColors.find((element) => element.id === id);
  return lane.color;
};

export default class RequestsKanbanHeader extends Component {
  static propTypes = {
    id: PropTypes.string,
  };

  render() {
    const { id } = this.props;
    return (
      <>
        <div className={styles.line} style={{ backgroundColor: findColor(id) }} />
        <div className={styles.title}>{humanRequestStateName(id)}</div>
      </>
    );
  }
}
