import React, { useState } from 'react';
import { isBlank, isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Headline from 'components/grommet/Headline';
import Card from 'components/Card';
import Anchor from 'components/Anchor';
import Spinner from 'components/Spinner';
import InfiniteScroll from 'components/InfiniteScroll';
import EditorNote from './components/EditorNote';
import EditorNoteModal from './components/EditorNoteModal';

import EditorNotePresenter from 'presenters/EditorNotePresenter';

import styles from './EditorNotes.module.css';

const PERMANENT_SORT_OPTION = { field: 'updatedAt', value: 'desc' };
const PER_PAGE = 2;

const EditorNotes = (props) => {
  const { onCreate, onUpdate, onDestroy, onLoad, onFilter, isSaving, isLoading, isDestroying, editorNotes, meta } =
    props;

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEditableEditorNote, setCurrentEditableEditorNote] = useState(null);
  const [destroyEditorNoteId, setDestroyEditorNoteId] = useState(null);
  const filters = { sortFields: [PERMANENT_SORT_OPTION] };

  const handleEditorNoteDestroy = (editorNoteId) => {
    setDestroyEditorNoteId(editorNoteId);

    onDestroy(editorNoteId).then(() => {
      setDestroyEditorNoteId(null);
      onFilter({ filters, per: PER_PAGE });
    });
  };

  const handleEditorNoteCreate = (params) =>
    onCreate(params).then(() => {
      onFilter({ filters, per: PER_PAGE });
    });

  const handleNewModalOpen = () => {
    setIsNewModalOpen(true);
  };

  const handleNewModalClose = () => {
    setIsNewModalOpen(false);
  };

  const handleEditModalOpen = (editableEditorNote) => {
    setIsEditModalOpen(true);
    setCurrentEditableEditorNote(editableEditorNote);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setCurrentEditableEditorNote(null);
  };

  const isAllProjectsLoaded = () => isBlank(meta.nextPage);

  const handleLoadMore = () => {
    if (!isLoading && !isAllProjectsLoaded()) {
      onLoad({ filters, page: meta.nextPage, per: PER_PAGE });
    }
  };

  const handleUpdateEditorNote = (params) =>
    onUpdate(EditorNotePresenter.id(currentEditableEditorNote), params).then(() => {
      onFilter({ filters, per: PER_PAGE });
    });

  const renderSpinner = () => (
    <div className={styles.spinner} key="loader">
      <Spinner />
    </div>
  );

  const scrollContainerStyles = cn(styles.scrollContainer, {
    [styles.filledScrollContainer]: isPresent(editorNotes),
  });

  return (
    <>
      <Card>
        <div className={styles.headLine}>
          <Headline strong size="small" margin="none">
            Editor's Notes
          </Headline>
          <Anchor label="+ Add New Note" onClick={handleNewModalOpen} className={styles.add} />
        </div>

        <div className={scrollContainerStyles}>
          <InfiniteScroll
            hasMore={!isAllProjectsLoaded()}
            loadMore={handleLoadMore}
            loader={renderSpinner()}
            useWindow={false}
            threshold={5}
          >
            {editorNotes.map((editorNote) => (
              <div key={EditorNotePresenter.id(editorNote)} className={styles.editorNote}>
                <EditorNote
                  editorNote={editorNote}
                  onUpdate={handleEditModalOpen}
                  onDestroy={handleEditorNoteDestroy}
                  isDestroying={isDestroying && EditorNotePresenter.id(editorNote) === destroyEditorNoteId}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </Card>

      {isNewModalOpen && (
        <EditorNoteModal
          title="New Note"
          onClose={handleNewModalClose}
          onSubmit={handleEditorNoteCreate}
          saving={isSaving}
        />
      )}

      {isEditModalOpen && (
        <EditorNoteModal
          title="Edit Note"
          onClose={handleEditModalClose}
          onSubmit={handleUpdateEditorNote}
          saving={isSaving}
          description={EditorNotePresenter.description(currentEditableEditorNote)}
        />
      )}
    </>
  );
};

export default EditorNotes;
