import UserPresenter from 'presenters/UserPresenter';
import { isBlank, isPresent } from 'utils/HelperMethods';

export default {
  canSeeEditorialDashboard(user) {
    if (isBlank(user)) {
      return false;
    }

    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchManager(user);
  },
  canSeeTeamProductivity(user) {
    return isPresent(user) && UserPresenter.teamProductivityViewAvailable(user);
  },
};
