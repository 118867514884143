import React from 'react';
import { Provider, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { Layer } from 'grommet';
import { Close as CloseIcon } from 'grommet-icons';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';

import styles from './Modal.module.css';

const Modal = (props) => {
  const { className, children, onClose, overlayClassName } = props;
  const modalClassName = cn(styles.modal, { [overlayClassName]: isPresent(overlayClassName) });
  const containerClassName = cn(styles.container, { [className]: isPresent(className) });
  const store = useStore();

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} className={modalClassName} animation="none">
      <Provider store={store}>
        <div className={containerClassName}>
          <div className={styles.close}>
            <Button onClick={onClose}>
              <CloseIcon size="small" />
            </Button>
          </div>
          {children}
        </div>
      </Provider>
    </Layer>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  onClose: PropTypes.func.isRequired,
  overlayClassName: PropTypes.string,
};

Modal.defaultProps = {
  className: null,
  overlayClassName: null,
};

export default Modal;
