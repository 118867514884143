import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Tile.module.css';

export default class Tile extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { children, name, icon, isChecked, onChange, title } = this.props;

    return (
      <label htmlFor={name} className={styles.item}>
        <input type="radio" id={name} className={styles.input} onChange={onChange} checked={isChecked} />
        <div className={styles.content}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{children}</div>
        </div>
      </label>
    );
  }
}
