import { adminCompanyDepartmentsRestHooks } from 'slices/admin/company/departmentsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useDepartments = () => {
  const {
    companyDepartment,
    companyDepartments,
    createCompanyDepartment,
    destroyCompanyDepartment,
    loadCompanyDepartment,
    loadCompanyDepartments,
    loadMoreCompanyDepartments,
    updateCompanyDepartment,
  } = adminCompanyDepartmentsRestHooks.use();

  const resetCompanyDepartmentSalesforceToken = (companyId, companyDepartmentId) =>
    updateCompanyDepartment(companyId, companyDepartmentId, { salesforceRegenerateToken: true });

  return {
    companyDepartment: companyDepartment.item,
    companyDepartmentCreatingStatus: useFetchStatus(companyDepartment.creatingStatus),
    companyDepartmentDestroyingStatus: useFetchStatus(companyDepartment.destroyingStatus),
    companyDepartmentLoadingStatus: useFetchStatus(companyDepartment.loadingStatus),
    companyDepartmentUpdatingStatus: useFetchStatus(companyDepartment.updatingStatus),
    companyDepartments: companyDepartments.items,
    companyDepartmentsLoadingMoreStatus: useFetchStatus(companyDepartments.moreLoadingStatus),
    companyDepartmentsLoadingStatus: useFetchStatus(companyDepartments.loadingStatus),
    companyDepartmentsMeta: companyDepartments.meta,
    createCompanyDepartment,
    destroyCompanyDepartment,
    loadCompanyDepartment,
    loadCompanyDepartments,
    loadMoreCompanyDepartments,
    resetCompanyDepartmentSalesforceToken,
    updateCompanyDepartment,
  };
};

export default useDepartments;
