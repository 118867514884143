import { pick, prop, difference, pipe, map } from 'ramda';

export default {
  defaultAttributes(profile = {}) {
    const { currentWorks } = profile;
    return {
      ...profile,
      currentWorkIds: map(prop('id'), currentWorks),
    };
  },

  currentWorksAttributes(currentWorkIds, oldCurrentWorkIds) {
    const newCurrentWorks = pipe(
      difference,
      map((id) => ({ id, isCurrentWork: true })),
    )(currentWorkIds, oldCurrentWorkIds);

    const oldCurrentWorks = pipe(
      difference,
      map((id) => ({ id, isCurrentWork: false })),
    )(oldCurrentWorkIds, currentWorkIds);

    return newCurrentWorks.concat(oldCurrentWorks);
  },

  attributesToSubmit(profile) {
    const propsToSend = [
      'salutation',
      'commonName',
      'firstName',
      'middleName',
      'lastName',
      'currentWorkId',
      'gender',
      'deceased',
      'worksAttributes',
      'primaryCurrentWorkId',
    ];

    const { currentWorkIds = [] } = profile;
    const oldCurrentWorkIds = profile.currentWorks.map(({ id }) => id);
    const worksAttributes = this.currentWorksAttributes(currentWorkIds, oldCurrentWorkIds);
    const primaryCurrentWorkId = currentWorkIds.includes(profile.primaryCurrentWorkId)
      ? profile.primaryCurrentWorkId
      : null;

    return pick(propsToSend, {
      ...profile,
      worksAttributes,
      primaryCurrentWorkId,
    });
  },
};
