import TagPresenter from 'presenters/TagPresenter';

export default {
  defaultAttributes(tag) {
    return {
      title: '',
      ...tag,
    };
  },

  attributesToSubmit(tag) {
    const { id, title } = tag;

    return {
      id,
      title,
      parentId: TagPresenter.parentId(tag),
    };
  },
};
