import { useAdminCompaniesUsersActions, adminCompaniesUsersSelector } from 'slices/admin/company/usersSlice';
import { useFetchStatus } from 'utils/fetchStatusUtils';

const useUsers = () => {
  const { bulkCreate } = adminCompaniesUsersSelector();
  const actions = useAdminCompaniesUsersActions();

  return {
    usersBulkCreatingStatus: useFetchStatus(bulkCreate.bulkCreatingStatus),
    ...actions,
  };
};

export default useUsers;
