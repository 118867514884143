import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TagPage from './TagPage';
import { loadTag, updateTag, searchTags } from './TagPageActions';

const mapStateToProps = (state, props) => ({
  tag: state.TagPageReducer.tag,
  loading: state.TagPageReducer.loading,
  saving: state.TagPageReducer.saving,
  tagId: props.match.params.id,
  items: state.TagPageReducer.items,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadTag,
      searchTags,
      updateTag,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TagPage);
