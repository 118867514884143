import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AudienceInsights from './AudienceInsights';
import * as actions from './AudienceInsightsActions';

const mapStateToProps = (state) => ({
  loading: state.AudienceInsightsReducer.loading,
  audienceInsight: state.AudienceInsightsReducer.audienceInsight,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AudienceInsights);
