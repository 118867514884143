import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        alpha2: PropTypes.string,
        profilesCount: PropTypes.number,
      }),
    ),
    maxProfilesCountInGeo: PropTypes.number,
    minProfilesCountInGeo: PropTypes.number,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        abbreviation: PropTypes.string,
        profilesCount: PropTypes.number,
      }),
    ),
  },
  {},
);
