import React, { createContext, useContext, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

const ReactRouterContext = createContext();

export const ReactRouterContextProvider = withRouter((props) => {
  const { history, children, match } = props;
  const context = useMemo(() => ({ history, params: match.params }), [match.path, match.params]);

  return <ReactRouterContext.Provider value={context}>{children}</ReactRouterContext.Provider>;
});

export const useParams = () => useContext(ReactRouterContext)?.params;
export const useHistory = () => useContext(ReactRouterContext)?.history;
