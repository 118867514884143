import React from 'react';
import PropTypes from 'prop-types';

import CancelRequestPopup from './components/CancelRequestPopup';
import AssignRequestPopup from './components/AssignRequestPopup';

function MoveRequestPopups(props) {
  const { onClose, onSubmit, request, saving, searchUser } = props;
  const { stateEvent } = request;

  if (stateEvent === 'cancel') {
    return <CancelRequestPopup onClose={onClose} onSubmit={onSubmit} request={request} saving={saving} />;
  }

  if (['to_research', 'to_editorial'].includes(stateEvent)) {
    return (
      <AssignRequestPopup
        onClose={onClose}
        onSubmit={onSubmit}
        onUserSearch={searchUser}
        request={request}
        saving={saving}
        stateEvent={stateEvent}
      />
    );
  }

  return null;
}

MoveRequestPopups.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  request: PropTypes.shape(),
  saving: PropTypes.bool,
  searchUser: PropTypes.func,
};

export default MoveRequestPopups;
