import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Heading, TextInput } from 'grommet';

import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';
import Button from 'components/Button';

import styles from './ResetPassword.module.css';

const ResetPassword = (props) => {
  const {
    changeForm,
    form,
    sendResetPasswordEmail,
    errors,
    isResetPasswordEmailSent,
    saving,
    initializeResetPasswordForm,
  } = props;

  useEffect(() => {
    initializeResetPasswordForm();
  }, []);

  const handleEmailChange = ({ target: { value } }) => {
    changeForm({ ...form, email: value });
  };

  const handleSendResetPasswordEmail = () => sendResetPasswordEmail(form);

  return (
    <div className={styles.root}>
      {isResetPasswordEmailSent && (
        <div className={styles.message}> {`We have sent an email to ${form.email} with a reset password link.`} </div>
      )}
      {!isResetPasswordEmailSent && (
        <div className={styles.container}>
          <Heading level="3" weight="bold">
            Reset password
          </Heading>
          <div className={styles.info}>Please enter your email address</div>
          <div className={styles.form}>
            <VerticalFormFieldWithBottomErrors label="email" errors={errors && errors.email}>
              <TextInput value={form.email} onChange={handleEmailChange} />
            </VerticalFormFieldWithBottomErrors>
          </div>
          <div className={styles.action}>
            <Button disabled={saving} label="Submit" onClick={handleSendResetPasswordEmail} />
          </div>
        </div>
      )}
    </div>
  );
};

ResetPassword.propTypes = {
  changeForm: PropTypes.func.isRequired,
  isResetPasswordEmailSent: PropTypes.bool.isRequired,
  form: PropTypes.shape({ email: PropTypes.string.isRequired }).isRequired,
  errors: PropTypes.shape(),
  initializeResetPasswordForm: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  sendResetPasswordEmail: PropTypes.func.isRequired,
};

export default ResetPassword;
