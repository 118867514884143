import AdminDashboardConstants from './AdminDashboardConstants';

const initialState = {
  loading: false,
  dashboard: null,
  form: { from: null, to: null },
};

function AdminDashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AdminDashboardConstants.LOAD_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_START: {
      const { form } = action;

      return { ...state, loading: true, dashboard: null, form };
    }
    case AdminDashboardConstants.LOAD_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_SUCCESS: {
      return { ...state, loading: false, dashboard: action.dashboard };
    }
    case AdminDashboardConstants.LOAD_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_ERROR: {
      return { ...state, loading: false };
    }
    case AdminDashboardConstants.CHANGE_ADMIN_CORPORATE_ACCOUNT_DASHBOARD_FORM: {
      return { ...state, form: action.form };
    }
    default:
      return state;
  }
}

export default AdminDashboardReducer;
