import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findScrollParents } from 'grommet/utils/DOM';
import { isBlank, isPresent } from 'utils/HelperMethods';

import ReactInfiniteScroll from 'react-infinite-scroller';

class InfiniteScroll extends Component {
  static propTypes = {
    researchForScrollParent: PropTypes.bool,
    getScrollParent: PropTypes.func,
    threshold: PropTypes.number,
  };

  static defaultProps = {
    researchForScrollParent: false,
    getScrollParent: null,
    threshold: 250,
  };

  setScrollComponent = (ref) => {
    this.scrollComponent = ref;
  };

  findScrollParent = () => {
    if (isBlank(this.scrollComponent)) {
      return document.body;
    }

    const scrollParents = findScrollParents(this.scrollComponent);
    const scrollParent = scrollParents[0];

    if (scrollParent === document) {
      return document.body;
    }

    return scrollParent;
  };

  handleResearchForScrollParent = () => {
    if (isPresent(this.scrollParent)) {
      return this.scrollParent;
    }

    const scrollParent = this.findScrollParent();

    if (scrollParent !== document.body) {
      this.scrollParent = scrollParent;
    }

    return scrollParent;
  };

  threshold = () => {
    const { researchForScrollParent, threshold } = this.props;

    if (!researchForScrollParent) {
      return threshold;
    }

    if (isBlank(this.scrollComponent) || isBlank(this.scrollParent)) {
      return threshold;
    }

    return threshold + (this.scrollParent.getBoundingClientRect().y - this.scrollComponent.getBoundingClientRect().y);
  };

  render() {
    const { researchForScrollParent, getScrollParent, threshold, ...rest } = this.props;

    return (
      <>
        {researchForScrollParent && <div ref={this.setScrollComponent} />}
        <ReactInfiniteScroll
          {...rest}
          getScrollParent={researchForScrollParent ? this.handleResearchForScrollParent : getScrollParent}
          threshold={this.threshold()}
        />
      </>
    );
  }
}

export default InfiniteScroll;
