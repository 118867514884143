import { pick } from 'ramda';

export default {
  defaultAttributes(user = {}) {
    return {
      firstName: '',
      lastName: '',
      companyPosition: null,
      companyRegion: null,
      email: '',
      emailConfirmation: '',
      companyPositionId: '',
      companyRegionId: '',
      companyDepartmentId: null,
      ...user,
    };
  },

  attributesToSubmit(user) {
    const propsToSend = [
      'firstName',
      'lastName',
      'email',
      'companyPositionId',
      'companyRegionId',
      'emailConfirmation',
      'companyDepartmentId',
    ];

    return pick(propsToSend, user);
  },
};
