import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(companyId, companyDepartmentId) {
    const url = Routes.apiV1AdminCompanyDepartmentApiV2AccessSettingsPath(companyId, companyDepartmentId);

    return FetchHelpers.get(url);
  },

  update(companyId, companyDepartmentId, apiV2AccessSettings) {
    const url = Routes.apiV1AdminCompanyDepartmentApiV2AccessSettingsPath(companyId, companyDepartmentId);

    return FetchHelpers.put(url, { apiV2AccessSettings });
  },
};
