import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  show(companySlug) {
    const url = Routes.apiV1CompanyPath(companySlug);

    return FetchHelpers.get(url);
  },
};
