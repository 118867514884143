import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { keys, propOr } from 'ramda';

import { useFieldContext } from 'components/v2/Form/components/Field';

import styles from './Input.module.css';

const VARIANTS = {
  outlined: styles.outlined,
  default: styles.default,
};

const FIELD_VARIANT_TO_INPUT_VARIANT = {
  outlined: 'default',
  default: 'outlined',
};

const Input = forwardRef((props, ref) => {
  const { className, onChange, value, variant, suffix, ...rest } = props;

  const fieldContext = useFieldContext();

  const inputVariant = variant || FIELD_VARIANT_TO_INPUT_VARIANT[fieldContext?.variant];
  const variantClassName = propOr(VARIANTS.default, inputVariant)(VARIANTS);
  const inputClassName = clsx(styles.input, variantClassName, className);

  const handleChange = (event) => onChange(event.target.value, event);

  return (
    <div className={inputClassName}>
      <input ref={ref} onChange={handleChange} value={value} {...rest} />
      {suffix}
    </div>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  suffix: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(keys(VARIANTS)),
};

Input.defaultProps = {
  className: null,
  onChange: () => {},
  suffix: null,
  value: undefined,
  variant: undefined,
};

export default Input;
