import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LineChart, Line, CartesianGrid, ResponsiveContainer } from 'recharts';

import StatisticPresenter from 'presenters/EditorialDashboard/UserProductivity/StatisticPresenter';

import styles from './Chart.module.css';

export default class Chart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(StatisticPresenter.shape()).isRequired,
    height: PropTypes.number,
  };

  render() {
    const { data, height } = this.props;

    return (
      <div className={styles.root}>
        <ResponsiveContainer width="100%" height={height}>
          <LineChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="0" horizontalPoints={[46]} verticalPoints={[81]} />
            <Line type="monotone" dataKey="value" stroke="#00A0CE" dot={false} activeDot={{ r: 5 }} />
            <Line
              type="monotone"
              dataKey="previousValue"
              stroke="#00A0CE"
              dot={false}
              strokeDasharray="3 4 5 2"
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
