import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';
import cn from 'clsx';

import Icon from 'components/Icon';

import styles from './ProfileAvatar.module.css';

export default class ProfileAvatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    src: PropTypes.string,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    style: {},
  };

  getContainerInlineStyles() {
    const { size } = this.props;
    const inlineStyles = {};

    if (isPresent(size)) {
      inlineStyles.maxWidth = `${size}px`;
      inlineStyles.maxHeight = `${size}px`;
      inlineStyles.minWidth = `${size}px`;
      inlineStyles.width = `${size}px`;
      inlineStyles.height = `${size}px`;
    }

    return inlineStyles;
  }

  renderImage() {
    const { src, size, style } = this.props;
    const inlineStyles = { ...style };

    if (isPresent(size)) {
      inlineStyles.maxWidth = `${size}px`;
      inlineStyles.maxHeight = `${size}px`;
      inlineStyles.minWidth = `${size}px`;
      inlineStyles.minHeight = `${size}px`;
      inlineStyles.width = `${size}px`;
      inlineStyles.height = `${size}px`;
    }

    return <img src={src} alt="userpic" style={inlineStyles} />;
  }

  renderPlaceholder() {
    const { size } = this.props;
    const style = {};

    if (isPresent(size)) {
      style.width = size.toString();
      style.height = size.toString();
    }

    return <Icon name="avatarPlaceholder" style={style} />;
  }

  render() {
    const { className, src } = this.props;
    const classNames = cn([styles.userpic], { [className]: isPresent(className) });

    return (
      <div className={classNames} style={this.getContainerInlineStyles()}>
        {isPresent(src) ? this.renderImage() : this.renderPlaceholder()}
      </div>
    );
  }
}
