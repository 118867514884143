import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import {
  CircleInformation as CircleInformationIcon,
  Edit as EditIcon,
  Pin as PinIcon,
  Close as CloseIcon,
} from 'grommet-icons';

import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import Headline from 'components/grommet/Headline';
import Icon from 'components/Icon';
import RawHtml from 'components/RawHtml';
import StyleGuideModal from 'components/StyleGuideModal';
import TextSelection from 'components/TextSelection';

import Tags from 'containers/AdminPanel/components/Tags';

import TopicPresenter from 'presenters/TopicPresenter';

import ProfileTopicFormPopup from '../ProfileTopicFormPopup';

import styles from './ProfileTopicCard.module.css';

const LONG_TEXT_LENGTH = 350;

const ProfileTopicCard = (props) => {
  const {
    editable,
    topic,
    formTitle,
    saving,
    tooltipComponent,
    collapsed,
    onTagSearch,
    popoverNode,
    handleAddHighlight,
    enableHighlight,
    displayTags,
    onUpdate,
    onDestroy,
    onCardShow,
    onCardHide,
    onTopicVisibilityUpdate,
    hidden,
  } = props;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleCancelEdit = () => setIsEditModalOpen(false);

  const handleSubmit = ({ id, ...params }) =>
    onUpdate(id, params).then(() => {
      setIsEditModalOpen(false);
    });

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const toggleHide = () => {
    onTopicVisibilityUpdate(TopicPresenter.sortUuid(topic));
  };

  const handleDestroy = () => {
    onDestroy(topic);
  };

  const handleShow = () => {
    onCardShow(topic.id);
  };

  const handleHide = () => {
    onCardHide(topic.id);
  };

  const isTextLong = () => {
    const description = TopicPresenter.description(topic);

    return isPresent(description) && description.length > LONG_TEXT_LENGTH;
  };

  const renderCollapseControl = () => (
    <div className={cn(styles.collapseControl, { [styles.hidden]: hidden })}>
      {collapsed ? (
        <button className={styles.collapseButton} onClick={handleShow}>
          Show
        </button>
      ) : (
        <button className={styles.collapseButton} onClick={handleHide}>
          Hide
        </button>
      )}
    </div>
  );

  const rootStyles = cn([styles.root], {
    [styles.collapsed]: isTextLong() && collapsed,
  });

  return (
    <div className={rootStyles}>
      <div className={styles.content}>
        <Headline strong size="small" margin="none" className={cn(styles.title, { [styles.hidden]: hidden })}>
          {TopicPresenter.pinned(topic) && (
            <div className={styles.pinIcon}>
              <PinIcon size="small" />
            </div>
          )}
          <div className={styles.titleText}> {TopicPresenter.title(topic)} </div>
        </Headline>
        {displayTags && (
          <div className={cn(styles.tags, { [styles.hidden]: hidden })}>
            <Tags items={TopicPresenter.tags(topic)} />
          </div>
        )}
        {enableHighlight ? (
          <div className={cn({ [styles.hidden]: hidden })}>
            <TextSelection
              node={popoverNode}
              onAddHighlight={handleAddHighlight}
              handleLoadTags={onTagSearch}
              saving={saving}
            >
              <RawHtml className={styles.text} innerHTML={TopicPresenter.description(topic)} />
            </TextSelection>
          </div>
        ) : (
          <RawHtml className={styles.text} innerHTML={TopicPresenter.description(topic)} />
        )}

        {hidden && (
          <div className={styles.visibilityLabel}>
            <Icon name="closeEye" />
          </div>
        )}

        <div className={styles.panel}>
          {editable && (
            <>
              <div className={styles.action}>
                <Button
                  disabled={saving}
                  icon={<Icon name={hidden ? 'closeEye' : 'openEye'} />}
                  onClick={toggleHide}
                  plain
                />
              </div>
              <div className={styles.action}>
                <EditIcon color="light-1" onClick={handleEdit} />
              </div>
              <div className={styles.action}>
                <CloseIcon color="light-1" onClick={handleDestroy} />
              </div>
            </>
          )}
          <div className={styles.action}>
            <StyleGuideModal modalContent={tooltipComponent}>
              <CircleInformationIcon color="light-1" />
            </StyleGuideModal>
          </div>
        </div>
        {isEditModalOpen && (
          <ProfileTopicFormPopup
            topic={topic}
            title={formTitle}
            onSubmit={handleSubmit}
            onCancel={handleCancelEdit}
            onTagSearch={onTagSearch}
            saving={saving}
            displayTags={displayTags}
          />
        )}
      </div>
      {isTextLong() ? renderCollapseControl() : null}
    </div>
  );
};

ProfileTopicCard.propTypes = {
  editable: PropTypes.bool.isRequired,
  topic: TopicPresenter.shape(),
  formTitle: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  tooltipComponent: PropTypes.func.isRequired,
  onCardShow: PropTypes.func,
  onCardHide: PropTypes.func,
  collapsed: PropTypes.bool,
  onTagSearch: PropTypes.func.isRequired,
  popoverNode: PropTypes.shape(),
  handleAddHighlight: PropTypes.func,
  enableHighlight: PropTypes.bool,
  displayTags: PropTypes.bool.isRequired,
  onTopicVisibilityUpdate: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default ProfileTopicCard;
