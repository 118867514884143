import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import PhoneNumberInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import styles from './PhoneInput.module.css';

export default class PhoneInput extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    const allClassNames = cx(styles.phone, { [className]: isPresent(className) });

    return <PhoneNumberInput {...this.props} className={allClassNames} />;
  }
}
