import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setBodyOverflow, removeBodyOverflow } from 'utils/DOMHelpers';
import cn from 'clsx';

import styles from './Overlay.module.css';

class Overlay extends Component {
  static propTypes = {
    isShow: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const { isShow, children, className } = this.props;

    if (isShow) {
      setBodyOverflow();
    } else {
      removeBodyOverflow();
    }

    const rootStyles = cn([styles.root], className, { [styles.hidden]: !isShow });

    return <div className={rootStyles}>{children}</div>;
  }
}

export default Overlay;
