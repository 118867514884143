import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { clone, propEq } from 'ramda';
import { isPresent, isBlank } from 'utils/HelperMethods';

import GraphVisualizer from './GraphVisualizer';
import Graph from './Graph';

import RelationshipsMappingPresenter from 'presenters/RelationshipsMappingPresenter';

class RelationshipsMappingGraph extends Component {
  constructor(props) {
    super(props);

    this.svgRef = React.createRef();
  }

  componentDidMount() {
    const svg = this.svgRef.current;
    const { mapping, showOrganizationName, zoomedNodeId } = this.props;

    this.graph = new Graph(clone(mapping.nodes), clone(mapping.links));
    this.graph.addGroupToNodes();
    this.graphVisualizer = new GraphVisualizer(svg, this.graph, { showOrganizationName });
    this.graphVisualizer.onNodeClick(this.handleNodeClick);

    if (isPresent(zoomedNodeId)) {
      this.graphVisualizer.onSimulationEnd(() => this.handleNodeFocus(zoomedNodeId));
    } else {
      this.graphVisualizer.onSimulationEnd(this.graphVisualizer.fitGraphInContainer);
    }
  }

  componentDidUpdate(prevProps) {
    const { zoomedNodeId } = this.props;
    if (isPresent(zoomedNodeId) && zoomedNodeId !== prevProps.zoomedNodeId) {
      this.handleNodeFocus(zoomedNodeId);
    }

    if (isBlank(zoomedNodeId) && zoomedNodeId !== prevProps.zoomedNodeId) {
      this.handleNodeBlur();
    }
  }

  componentWillUnmount() {
    if (this.graphVisualizer) {
      this.graphVisualizer.stopSimulation();
    }

    this.graph = null;
    this.graphVisualizer = null;
  }

  handleNodeFocus = (nodeId) => {
    const node = this.graph.nodes.find(propEq(nodeId, 'id'));

    this.graphVisualizer.onSimulationEnd(null);
    this.graphVisualizer.zoomInNode(nodeId);
    this.graphVisualizer.handleNodeFocus(node);
    this.graphVisualizer.trackMouseEvents = false;
  };

  handleNodeBlur = () => {
    this.graphVisualizer.onSimulationEnd(this.graphVisualizer.fitGraphInContainer);
    this.graphVisualizer.fitGraphInContainer();
    this.graphVisualizer.trackMouseEvents = true;
    this.graphVisualizer.handleNodeBlur();
  };

  handleNodeClick = (node) => {
    const { onNodeClick } = this.props;
    const countOfProfileNeighbors = this.graph.getNeighNodesIds(node.id).length;

    onNodeClick(node, countOfProfileNeighbors);
  };

  render() {
    const { width, height } = this.props;

    return <svg ref={this.svgRef} preserveAspectRatio="xMinYMid meet" width={width} height={height} />;
  }
}

RelationshipsMappingGraph.propTypes = {
  onNodeClick: PropTypes.func.isRequired,
  mapping: RelationshipsMappingPresenter.shape().isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zoomedNodeId: PropTypes.number,
  showOrganizationName: PropTypes.bool,
};

RelationshipsMappingGraph.defaultProps = {
  width: '100%',
  height: '700px',
  showOrganizationName: false,
};

export default RelationshipsMappingGraph;
