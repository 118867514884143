import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import connectProfile from 'containers/UserPanel/containers/ProfileContainer';

import Spinner from 'components/Spinner';
import Profile from 'containers/UserPanel/components/Profile';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import SubscriptionPresenter from 'presenters/SubscriptionPresenter';

class SampleProfilePageComponent extends Component {
  static propTypes = {
    backgroundExport: PropTypes.func.isRequired,
    currentUser: CurrentUserPresenter.shape(),
    loading: PropTypes.bool.isRequired,
    loadProfile: PropTypes.func.isRequired,
    profile: ProfilePresenter.shape(),
    requestProfileUpdate: PropTypes.func.isRequired,
    subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()),
    isSubscriptionsSaving: PropTypes.bool.isRequired,
    isUpdateRequestSaving: PropTypes.bool.isRequired,
    match: PropTypes.shape({}).isRequired,
    updateBatchSubscriptions: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadProfile, match } = this.props;
    const profileSlug = match.params.slug;

    loadProfile(profileSlug);
  }

  handleProfileUpdateRequest = () => {
    const { profile, requestProfileUpdate } = this.props;
    const profileId = ProfilePresenter.id(profile);

    requestProfileUpdate(profileId);
  };

  render() {
    const {
      backgroundExport,
      currentUser,
      loading,
      profile,
      subscriptions,
      isSubscriptionsSaving,
      isUpdateRequestSaving,
      updateBatchSubscriptions,
    } = this.props;

    if (loading || isBlank(profile)) {
      return <Spinner />;
    }

    return (
      <Profile
        backgroundExport={backgroundExport}
        currentUser={currentUser}
        onProfileUpdateRequest={this.handleProfileUpdateRequest}
        profile={profile}
        subscriptions={subscriptions}
        isSubscriptionsSaving={isSubscriptionsSaving}
        isUpdateRequestSaving={isUpdateRequestSaving}
        updateBatchSubscriptions={updateBatchSubscriptions}
        indexable
        canonicalPath="sampleProfilePath"
      />
    );
  }
}

const SampleProfilePage = connectProfile(SampleProfilePageComponent);

export default SampleProfilePage;
