import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export const PROFILE_TASK_TYPES = [
  {
    key: 'new_profile',
    title: 'New profile',
  },
  {
    key: 'update_request',
    title: 'Update request',
  },
  {
    key: 'nqu_needs_update',
    title: 'News queue update - existing company record needs update',
  },
  {
    key: 'nqu_updated',
    title: 'News queue update - existing company record updated',
  },
  {
    key: 'nqu_rewrites',
    title: 'New queue update - rewrites/no company record information',
  },
  {
    key: 'profile_other',
    title: 'Other',
  },
];

export const ORGANIZATION_TASK_TYPES = [
  {
    key: 'transcript_completion',
    title: 'Transcript Completion',
  },
  {
    key: 'organization_other',
    title: 'Other',
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    kind: PropTypes.string,
    taskType: PropTypes.string,
    hours: PropTypes.number,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    canEdit: PropTypes.bool,
    isNew: PropTypes.bool,
    isTodayCreated: PropTypes.bool,
  },
  {
    taskTypeTitle(e) {
      const taskType = this.taskType(e);
      if (!taskType) {
        return '';
      }
      return [...PROFILE_TASK_TYPES, ...ORGANIZATION_TASK_TYPES].find((r) => r.key === taskType).title;
    },
    isOrganizationOther(value) {
      return value === 'organization_other';
    },
  },
);
