import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'containers/UserPanel/containers/SubscriptionsContainer'; // eslint-disable-line import/named

export default () => {
  const dispatch = useDispatch();
  const { isSaving } = useSelector((state) => state.SubscriptionsContainerReducer);

  return { isSaving, ...bindActionCreators(actions, dispatch) };
};
