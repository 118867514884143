import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CsvSourceEditPage from './CsvSourceEditPage';
import * as actions from './CsvSourceEditPageActions';

const mapStateToProps = (state, props) => ({
  sourceId: props.match.params.sourceId,
  source: state.CsvSourceEditPageReducer.source,
  loading: state.CsvSourceEditPageReducer.loading,
  remainingRequestsCount: state.CsvSourceEditPageReducer.remainingRequestsCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CsvSourceEditPage);
