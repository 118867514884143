import React from 'react';
import PropTypes from 'prop-types';

import { Anchor } from 'grommet';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

import Routes from 'routes';

import styles from './Children.module.css';

const Children = (props) => {
  const { childs } = props;
  const childsCount = childs.length;

  return (
    <div>
      <div>
        <h4 className={styles.title}>Organization Children</h4>
      </div>
      <div>
        {childs.length === 0 && <span className={styles.missing}> No children have been added yet. </span>}

        {childs.map((child, index) => {
          const organizationName = OrganizationPresenter.nameWithState(child);
          const organizationId = OrganizationPresenter.id(child);
          const isLastChild = index === childsCount - 1;

          return (
            <div key={organizationId}>
              <Anchor
                key={organizationId}
                className={styles.children}
                disabled={OrganizationPresenter.isOutdated(child)}
                href={Routes.editAdminOrganizationPath(organizationId)}
                target="_blank"
                label={isLastChild ? organizationName : `${organizationName},`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

Children.propTypes = {
  childs: PropTypes.arrayOf(OrganizationPresenter.shape()).isRequired,
};

export default Children;
