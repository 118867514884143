import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { any, not } from 'ramda';
import { isBlank } from 'utils/HelperMethods';

import { Box } from 'grommet';
import Aliases from './components/Aliases';
import OrganizationInformation from './components/OrganizationInformation';
import ContactInformation from './components/ContactInformation';
import TopicsList from './components/TopicsList';
import Spinner from 'components/Spinner';
import VersionStatusBlock from 'containers/AdminPanel/components/VersionStatusBlock';
import RequestForm from '../RequestForm';
import RequestBlock from '../../components/RequestBlock';
import TREventsBlock from 'containers/AdminPanel/containers/TREventsBlock';
import OrganizationProfiles from './components/OrganizationProfiles';
import EditableCard from 'containers/AdminPanel/components/EditableCard';
import ParentChildRelationship from './components/ParentChildRelationship';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';
import ReviewWysiwyg from 'containers/AdminPanel/components/ReviewWysiwyg';
import ReviewParentChildRelationship from './components/ReviewParentChildRelationship';
import EditorNotes from 'containers/AdminPanel/components/EditorNotes';
import EditorWorks from 'containers/AdminPanel/components/EditorWorks';

import styles from './OrganizationPage.module.css';

import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import OrganizationPresenter from 'presenters/OrganizationPresenter';
import UserPresenter from 'presenters/UserPresenter';
import EditorWorkPresenter from 'presenters/EditorWorkPresenter';
import OrganizationPolicy from 'policies/OrganizationPolicy';
import TagPolicy from 'policies/TagPolicy';

import withReviewChanges from 'hoc/withReviewChanges';
import ReviewContactInformation from './components/ReviewContactInformation';
import ReviewOrganizationInformation from './components/ReviewOrganizationInformation';
import ReviewTopicsList from './components/ReviewTopicsList';
import ReviewAliases from './components/ReviewAliases';

import useAdminOrganization from 'hooks/admin/useAdminOrganization';
import useAdminRequestForm from 'hooks/admin/useAdminRequestForm';
import useAdminOrganizationEditorNotes from 'hooks/admin/organization/useEditorNotes';
import useAdminOrganizationEditorWorks from 'hooks/admin/organization/useEditorWorks';

const OrganizationPageComponent = (props) => {
  const {
    match,
    currentUser,
    isCollectionChanged,
    reviewChanges,
    isChanged,
    loadOrganization,
    updateOrganization,
    loadJobFunctions,
    createOrganizationTopic,
    updateOrganizationTopic,
    destroyOrganizationTopic,
    updateLogo,
    searchIndustry,
    searchNaicsIndustryCode,
    searchCountry,
    searchState,
    searchTags,
    createAlias,
    deleteAlias,
    jobFunctions,
    loading,
    organization,
    publishedOrganization,
    isThomsonReutersAvailable,
    saving,
  } = props;

  const { id: organizationId } = match.params;
  const { request } = useAdminRequestForm();

  const {
    createEditorNote,
    updateEditorNote,
    destroyEditorNote,
    loadEditorNotes,
    filterEditorNotes,
    isSaving: isEditorNoteSaving,
    isLoading: isEditorNoteLoading,
    isDestroying: isEditorNoteDestroying,
    editorNotes,
    meta: editorNotesMeta,
  } = useAdminOrganizationEditorNotes();

  const {
    createEditorWork,
    updateEditorWork,
    destroyEditorWork,
    loadEditorWorks,
    filterEditorWorks,
    isSaving: isEditorWorkSaving,
    isLoading: isEditorWorkLoading,
    isDestroying: isEditorWorkDestroying,
    editorWorks,
    meta: editorWorksMeta,
  } = useAdminOrganizationEditorWorks();

  const [isEditorWorkModalOpen, setIsEditorWorkModalOpen] = useState(false);

  const handleEditorNotesFilter = (params) => filterEditorNotes(organizationId, params);
  const handleEditorNotesLoad = (params) => loadEditorNotes(organizationId, params);
  const handleEditorNoteCreate = (params) => createEditorNote(organizationId, params);
  const handleEditorNoteUpdate = (editorNoteId, params) => updateEditorNote(organizationId, editorNoteId, params);
  const handleEditorNoteDestroy = (editorNoteId) => destroyEditorNote(organizationId, editorNoteId);

  const handleEditorWorksFilter = (params) => filterEditorWorks(organizationId, params);
  const handleEditorWorksLoad = (params) => loadEditorWorks(organizationId, params);
  const handleEditorWorkCreate = (params) => createEditorWork(organizationId, params);
  const handleEditorWorkUpdate = (editorWorkId, params) => updateEditorWork(organizationId, editorWorkId, params);
  const handleEditorWorkDestroy = (editorWorkId) => destroyEditorWork(organizationId, editorWorkId);

  const handleLoadOrganization = () => {
    loadJobFunctions();
    loadOrganization(organizationId);
  };

  useEffect(() => {
    handleLoadOrganization();
  }, []);

  const handleOrganizationUpdate = (params) =>
    updateOrganization(OrganizationPresenter.id(organization), { ...organization, ...params });

  const handleFieldUpdate = (field) => (value) => handleOrganizationUpdate({ [field]: value });

  const handleTopicCreate = (topic) => createOrganizationTopic(OrganizationPresenter.id(organization), topic);

  const handleTopicUpdate = (topicId, topic) =>
    updateOrganizationTopic(OrganizationPresenter.id(organization), topicId, topic);

  const handleTopicDestroy = (topicId) => destroyOrganizationTopic(OrganizationPresenter.id(organization), topicId);

  const handleLogoUpdate = (file, pixelCrop) => {
    const params = {
      logo: file,
      logoCropParams: pixelCrop,
    };

    return updateLogo(OrganizationPresenter.id(organization), params);
  };

  const handleLogoDelete = () => {
    const params = { removeLogo: true };

    return updateLogo(OrganizationPresenter.id(organization), params);
  };

  const handleRequestCreate = () => handleOrganizationUpdate({});

  const handleIndustrySearch = (searchText) => {
    const filters = { nameCont: searchText };

    return searchIndustry(filters);
  };

  const handleNaicsIndustryCodeSearch = (searchText) => {
    const filters = { nameOrCodeCont: searchText };

    return searchNaicsIndustryCode(filters);
  };

  const handleCountrySearch = (searchText) => {
    const filters = { nameCont: searchText, sortFields: [{ field: 'name', value: 'ASC' }] };

    return searchCountry(filters);
  };

  const handleStateSearch = (searchText) => {
    const filters = { nameCont: searchText, sortFields: [{ field: 'name', value: 'ASC' }] };

    return searchState(filters);
  };

  const handleTagSearch = (searchText) => {
    const filters = { titleCont: searchText };

    return searchTags(filters);
  };

  const handleReviewWysiwygChanges = (title, key) => reviewChanges(ReviewWysiwyg, title, key);

  const handleUpdateRicCode = (ticker) => handleOrganizationUpdate({ ticker, updateRicCode: true });

  const handleUpdateParentOrganization = (parentOrganization) => {
    const parentOrganizationId = isBlank(parentOrganization) ? null : OrganizationPresenter.id(parentOrganization);

    return handleOrganizationUpdate({ parentOrganizationId });
  };

  const canViewEditorNotes = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);
  const canViewEditorWorks = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);
  const hasEditorWorkToday = any(EditorWorkPresenter.isTodayCreated)(editorWorks);
  const needEditorWork = not(hasEditorWorkToday);

  const isEditable = () => OrganizationPolicy.canEdit(currentUser, organization, request);
  const editable = isEditable();

  if (loading || isBlank(organization) || Number(organizationId) !== organization.id) {
    return <Spinner />;
  }

  return (
    <div className={styles.root}>
      <div>
        <Box pad="small" width="766px">
          <OrganizationInformation
            editable={editable}
            onLogoUpdate={handleLogoUpdate}
            onLogoDelete={handleLogoDelete}
            onIndustrySearch={handleIndustrySearch}
            onNaicsIndustryCodeSearch={handleNaicsIndustryCodeSearch}
            onUpdate={handleOrganizationUpdate}
            onRicCodeUpdate={handleUpdateRicCode}
            organization={organization}
            saving={saving}
            isChanged={isChanged('organizationInformationDigest')}
            onReviewChanges={reviewChanges(ReviewOrganizationInformation, 'Contact Information')}
          />

          <Box margin={{ bottom: 'medium', top: 'medium' }}>
            <Aliases
              aliases={OrganizationPresenter.orderedAliases(organization)}
              editable={editable}
              organization={organization}
              saving={saving}
              isChanged={isCollectionChanged('orderedAliases', 'title')}
              onReviewChanges={reviewChanges(ReviewAliases, 'Organization Name Aliases', 'orderedAliases')}
              onCreate={createAlias}
              onDelete={deleteAlias}
            />
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <ParentChildRelationship
              childs={OrganizationPresenter.children(organization)}
              isEditable={editable}
              isSaving={saving}
              onChange={handleUpdateParentOrganization}
              parent={OrganizationPresenter.parent(organization)}
              isChanged={isChanged('parentId')}
              onReviewChanges={reviewChanges(ReviewParentChildRelationship, 'Parent-Child Relationship', 'parent')}
            />
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <OrganizationProfiles currentUser={currentUser} organization={organization} />
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <EditableCard
              editable={editable}
              saving={saving}
              title="Sources"
              htmlBody={OrganizationPresenter.sources(organization)}
              onUpdate={handleFieldUpdate('sources')}
              tooltipComponent={StyleGuides.SourcesStyleGuide}
              isChanged={isChanged('sources')}
              onReviewChanges={handleReviewWysiwygChanges('Sources', 'sources')}
            />
          </Box>
        </Box>
      </div>
      <div>
        <Box pad="small" width="medium">
          <Box margin={{ bottom: 'medium' }}>
            <ContactInformation
              editable={editable}
              onUpdate={handleOrganizationUpdate}
              onCountrySearch={handleCountrySearch}
              onStateSearch={handleStateSearch}
              organization={organization}
              saving={saving}
              isChanged={isChanged('contactInformationDigest')}
              onReviewChanges={reviewChanges(ReviewContactInformation, 'Contact Information')}
            />
          </Box>

          {canViewEditorNotes && (
            <Box margin={{ bottom: 'medium' }}>
              <EditorNotes
                onFilter={handleEditorNotesFilter}
                onLoad={handleEditorNotesLoad}
                onCreate={handleEditorNoteCreate}
                onUpdate={handleEditorNoteUpdate}
                onDestroy={handleEditorNoteDestroy}
                editorNotes={editorNotes}
                meta={editorNotesMeta}
                isSaving={isEditorNoteSaving}
                isLoading={isEditorNoteLoading}
                isDestroying={isEditorNoteDestroying}
              />
            </Box>
          )}

          {canViewEditorWorks && (
            <Box margin={{ bottom: 'medium' }}>
              <EditorWorks
                onFilter={handleEditorWorksFilter}
                onLoad={handleEditorWorksLoad}
                onCreate={handleEditorWorkCreate}
                onUpdate={handleEditorWorkUpdate}
                onDestroy={handleEditorWorkDestroy}
                editorWorks={editorWorks}
                meta={editorWorksMeta}
                isSaving={isEditorWorkSaving}
                isLoading={isEditorWorkLoading}
                isDestroying={isEditorWorkDestroying}
                kind="organization"
                isModalOpen={isEditorWorkModalOpen}
                onCloseModal={() => setIsEditorWorkModalOpen(false)}
              />
            </Box>
          )}

          <Box margin={{ bottom: 'medium' }}>
            <TopicsList
              editable={editable}
              jobFunctions={jobFunctions}
              kind="key_challenges"
              onCreate={handleTopicCreate}
              onUpdate={handleTopicUpdate}
              onDelete={handleTopicDestroy}
              saving={saving}
              title="Key Challenges"
              topics={OrganizationPresenter.keyChallengesTopics(organization)}
              isChanged={isCollectionChanged('keyChallengesTopics')}
              onReviewChanges={reviewChanges(ReviewTopicsList, 'Key Challenges', 'keyChallengesTopics')}
              onTagSearch={handleTagSearch}
              displayTags={TagPolicy.canSeeTagBlocks(currentUser)}
            />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <TopicsList
              editable={editable}
              jobFunctions={jobFunctions}
              kind="current_focus"
              onCreate={handleTopicCreate}
              onUpdate={handleTopicUpdate}
              onDelete={handleTopicDestroy}
              saving={saving}
              title="Current Focus"
              topics={OrganizationPresenter.currentFocusTopics(organization)}
              isChanged={isCollectionChanged('currentFocusTopics')}
              onReviewChanges={reviewChanges(ReviewTopicsList, 'Current Focus', 'currentFocusTopics')}
              onTagSearch={handleTagSearch}
              displayTags={TagPolicy.canSeeTagBlocks(currentUser)}
            />
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <VersionStatusBlock
              object={publishedOrganization}
              presenter={OrganizationPresenter}
              request={request}
              onRequestCreate={handleRequestCreate}
              saving={saving}
              canCreateRequest={editable}
            />
          </Box>

          {request && (
            <Box margin={{ bottom: 'medium' }}>
              <RequestForm
                currentUser={currentUser}
                onDeliver={handleLoadOrganization}
                onCanceled={handleLoadOrganization}
                request={request}
                onOpenEditorWorkModal={() => setIsEditorWorkModalOpen(true)}
                needEditorWork={needEditorWork}
                view={RequestBlock}
              />
            </Box>
          )}

          {isThomsonReutersAvailable && (
            <TREventsBlock
              organizationId={OrganizationPresenter.id(organization)}
              ricCode={OrganizationPresenter.ricCode(organization)}
            />
          )}
        </Box>
      </div>
    </div>
  );
};

const OrganizationPage = withReviewChanges(OrganizationPageComponent, {
  currentValueKey: 'organization',
  previousValueKey: 'publishedOrganization',
  getter: OrganizationPresenter,
  useAdminSubject: useAdminOrganization,
});

OrganizationPageComponent.propTypes = {
  currentUser: UserPresenter.shape(),
  isChanged: PropTypes.func.isRequired,
  isCollectionChanged: PropTypes.func.isRequired,
  reviewChanges: PropTypes.func.isRequired,
  loadOrganization: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  loadJobFunctions: PropTypes.func.isRequired,
  createOrganizationTopic: PropTypes.func.isRequired,
  updateOrganizationTopic: PropTypes.func.isRequired,
  destroyOrganizationTopic: PropTypes.func.isRequired,
  updateLogo: PropTypes.func.isRequired,
  searchIndustry: PropTypes.func.isRequired,
  searchNaicsIndustryCode: PropTypes.func.isRequired,
  searchCountry: PropTypes.func.isRequired,
  searchState: PropTypes.func.isRequired,
  searchTags: PropTypes.func.isRequired,
  createAlias: PropTypes.func.isRequired,
  deleteAlias: PropTypes.func.isRequired,
  jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  organization: OrganizationPresenter.shape(),
  publishedOrganization: OrganizationPresenter.shape(),
  isThomsonReutersAvailable: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default OrganizationPage;
