import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(query = {}) {
    const url = Routes.apiV1AdminCountriesPath(query);

    return FetchHelpers.get(url);
  },

  create(country) {
    const url = Routes.apiV1AdminCountriesPath();

    return FetchHelpers.post(url, { country });
  },

  show(id) {
    const url = Routes.apiV1AdminCountryPath(id);

    return FetchHelpers.get(url);
  },

  update(id, country) {
    const url = Routes.apiV1AdminCountryPath(id);

    return FetchHelpers.put(url, { country });
  },

  bulkDestroy(query) {
    const url = Routes.bulkDestroyApiV1AdminCountriesPath(query);

    return FetchHelpers.delete(url);
  },
};
