import { adminCompanyDepartmentsRestHooks } from 'slices/admin/company/departmentsStatisticsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useDepartmentsStatistics = () => {
  const { companyDepartmentsStatistic, loadCompanyDepartmentsStatistic } = adminCompanyDepartmentsRestHooks.use();

  return {
    companyDepartmentsStatistic: companyDepartmentsStatistic.item,
    companyDepartmentsStatisticLoadingStatus: useFetchStatus(companyDepartmentsStatistic.loadingStatus),
    loadCompanyDepartmentsStatistic,
  };
};

export default useDepartmentsStatistics;
