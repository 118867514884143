/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import { extractResourceData } from './utils';

export default function createResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        creatingStatus: FETCH_STATUSES.idle,
        error: null,
      },
    },
    reducers: {
      createResourceStart(state) {
        state[resource].creatingStatus = FETCH_STATUSES.pending;
        state[resource].error = null;
      },
      createResourceSuccess(state, { payload: { item, meta } }) {
        state[resource].creatingStatus = FETCH_STATUSES.fulfilled;
        state[resource].item = item;
        state[resource].meta = meta;
      },
      createResourceFail(state, { payload: { error } }) {
        state[resource].creatingStatus = FETCH_STATUSES.failed;
        state[resource].error = error;
      },
    },
    actionCreators(restDispatch) {
      return {
        createResource: async (...params) => {
          restDispatch('createResourceStart');

          try {
            const data = await repository.create(...params);
            const item = extractResourceData(data);
            restDispatch('createResourceSuccess', { item, meta: data.meta });

            return { item, meta: data.meta };
          } catch (error) {
            restDispatch('createResourceFail', { error });
            throw error;
          }
        },
      };
    },
    abstractSelector: (state) => ({ resource: state[resource] }),
  };
}
