import keyMirror from 'keymirror';

const ProfileRelationshipsToMyExecutivesListConstants = keyMirror({
  INITIALIZE_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES: null,

  LOAD_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES_START: null,
  LOAD_PROJECT_PROFILE_RELATIONSHIPS_TO_MY_EXECUTIVES_SUCCESS: null,
});

export default ProfileRelationshipsToMyExecutivesListConstants;
