import React, { Component } from 'react';
import { isBlank } from 'utils/HelperMethods';

import CountryPresenter from 'presenters/CountryPresenter';

export default class ReviewOriginCountry extends Component {
  static propTypes = {
    value: CountryPresenter.shape(),
  };

  render() {
    const { value: country } = this.props;

    return <div>{isBlank(country) ? null : CountryPresenter.name(country)}</div>;
  }
}
