import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil, propEq, includes, intersection } from 'ramda';

import Table from 'components/Table';
import Modal from 'components/Modal';
import RequestRowCells from './components/RequestRowCells';
import RequestForm from '../../../RequestForm';
import RequestDetailsPopup from '../../components/RequestDetailsPopup';
import AssignUserTableAction from './components/AssignUserTableAction';
import UpdateStatusRequestTableAction from './components/UpdateStatusRequestTableAction';
import BulkAssignUserModal from './components/BulkAssignUserModal';
import BulkUpdateStatusRequestsModal from './components/BulkUpdateStatusRequestsModal';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPresenter from 'presenters/UserPresenter';
import RequestPresenter from 'presenters/RequestPresenter';
import RequestPolicy from 'policies/RequestPolicy';

import useRequestsPageRequestsTable from 'hooks/admin/useRequestsPageRequestsTable';

const TABLE_COLUMNS = [
  {
    name: 'priority',
    field: 'complexPriority',
  },
  {
    name: 'status',
    field: 'state',
  },
  {
    name: 'type',
    field: 'requestType',
  },
  {
    name: 'requestor',
    field: 'requestorFullName',
  },
  {
    name: 'requestor company',
    field: 'companyName',
  },
  {
    name: 'request name',
    field: 'subjectFullName',
  },
  {
    name: 'request title',
    field: 'subjectJobTitle',
  },
  {
    name: 'request company',
    field: 'subjectOrganizationName',
  },
  {
    name: 'assignee',
    field: 'assigneeFullName',
  },
  {
    name: 'REQUEST DATE',
    field: 'createdAt',
  },
  {
    name: 'due date',
    field: 'dueDate',
  },
  {
    name: 'delivery date',
    field: 'deliveredAt',
  },
];

const getAvailableStateEvents = (requests, ids) => {
  const [selectedRequest, ...selectedRequests] = requests.filter((r) => includes(r.id, ids));

  return selectedRequests.reduce((acc, r) => intersection(acc, r.stateEvents), [...selectedRequest.stateEvents]);
};

const RequestsTable = (props) => {
  const { currentUser, filters, onUserSearch } = props;

  const {
    requests,
    sortFields,
    totalCount,
    saving,
    nextPage,
    loading,
    initializeRequestsTable,
    filterRequests,
    loadRequests,
    updateRequestData,
    bulkDestroyRequests,
    bulkAssignRequests,
    bulkUpdateStatusRequests,
  } = useRequestsPageRequestsTable();

  const [openedRequestId, setOpenedRequestId] = useState(null);
  const [bulkAssigneeRequestIds, setBulkAssigneeRequestIds] = useState(null);
  const [bulkUpdateStatusRequestIds, setBulkUpdateStatusRequestIds] = useState(null);
  const [availableStateEvents, setAvailableStateEvents] = useState(null);
  const [isEditorWorkModalOpen, setIsEditorWorkModalOpen] = useState(false);

  const canViewEditorWorks = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);

  useEffect(() => {
    initializeRequestsTable();
  }, []);

  useEffect(() => {
    filterRequests({ ...filters, sortFields });
  }, [filters]);

  const isAllRequestsLoaded = () => isNil(nextPage);

  const handleLoadMore = () => {
    if (!loading && !isAllRequestsLoaded()) {
      loadRequests({ page: nextPage, filters: { ...filters, sortFields } });
    }
  };

  const handleSort = (newSortFields) => {
    filterRequests({ ...filters, sortFields: newSortFields });
  };

  const handleRequestClick = (requestId) => setOpenedRequestId(requestId);

  const handleCloseRequestDetailsPopup = () => setOpenedRequestId(null);

  const handleDelete = (ids) =>
    bulkDestroyRequests({ ids }).then(() => {
      filterRequests({ ...filters, sortFields });
    });

  const closeBulkAssignModal = () => setBulkAssigneeRequestIds(null);

  const closeBulkUpdateStatusRequestsModal = () => setBulkUpdateStatusRequestIds(null);

  const handleBulkAssign = (params) =>
    bulkAssignRequests(params)
      .then(closeBulkAssignModal)
      .then(() => {
        filterRequests({ ...filters, sortFields });
      });

  const handleBulkUpdateStatusRequests = (params) =>
    bulkUpdateStatusRequests(params)
      .then(closeBulkUpdateStatusRequestsModal)
      .then(() => {
        filterRequests({ ...filters, sortFields });
      });

  const showBulkAssignModal = (ids) => setBulkAssigneeRequestIds(ids);

  const showBulkUpdateStatusRequestsModal = (ids) => {
    setBulkUpdateStatusRequestIds(ids);
    setAvailableStateEvents(getAvailableStateEvents(requests, ids));
  };

  const request = (requestId) => requests.find(propEq(requestId, 'id'));

  const tableCustomActions = () => {
    let actions = [];

    if (RequestPolicy.canBulkAssign(currentUser)) {
      actions = [...actions, <AssignUserTableAction onClick={showBulkAssignModal} />];
    }

    if (RequestPolicy.canBulkUpdateStatusRequests(currentUser)) {
      actions = [...actions, <UpdateStatusRequestTableAction onClick={showBulkUpdateStatusRequestsModal} />];
    }

    return actions;
  };

  return (
    <>
      <Table
        columns={RequestPolicy.permittedTableColumns(currentUser, TABLE_COLUMNS)}
        sorts={sortFields}
        onDelete={handleDelete}
        onMore={handleLoadMore}
        onRowClick={handleRequestClick}
        onSort={handleSort}
        hasMore={!isAllRequestsLoaded()}
        rows={requests}
        rowCellsComponent={<RequestRowCells currentUser={currentUser} />}
        totalRows={totalCount}
        rowsDeletable={RequestPolicy.canBulkDelete(currentUser)}
        customActions={tableCustomActions()}
      />

      {openedRequestId && (
        <RequestForm
          currentUser={currentUser}
          request={request(openedRequestId)}
          onChange={updateRequestData}
          onClose={handleCloseRequestDetailsPopup}
          view={RequestDetailsPopup}
          title={RequestPresenter.title(request(openedRequestId))}
          refreshRequestOnMount
          onOpenEditorWorkModal={() => setIsEditorWorkModalOpen(true)}
          needEditorWork={canViewEditorWorks}
        />
      )}

      {isEditorWorkModalOpen && (
        <Modal
          header="Have you entered your effort tracking data?"
          onClose={() => setIsEditorWorkModalOpen(false)}
          size="large"
          overflow
        >
          If not, please fill out the "Editor's Works" form in this profile to get credit for your work.
        </Modal>
      )}

      {bulkAssigneeRequestIds && (
        <BulkAssignUserModal
          requestIds={bulkAssigneeRequestIds}
          onAssign={handleBulkAssign}
          onClose={closeBulkAssignModal}
          onUserSearch={onUserSearch}
          saving={saving}
        />
      )}

      {bulkUpdateStatusRequestIds && (
        <BulkUpdateStatusRequestsModal
          currentUser={currentUser}
          requestIds={bulkUpdateStatusRequestIds}
          onUpdateStatusRequests={handleBulkUpdateStatusRequests}
          onClose={closeBulkUpdateStatusRequestsModal}
          onUserSearch={onUserSearch}
          saving={saving}
          stateEvents={availableStateEvents}
        />
      )}
    </>
  );
};

RequestsTable.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  onUserSearch: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
};

export default RequestsTable;
