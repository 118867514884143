import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { withAuthenticator } from 'containers/Authenticator';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

import AccessDeniedPage from './components/AccessDeniedPage';
import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import DashboardPage from './containers/DashboardPage';
import DownloadPage from './components/DownloadPage';
import ErrorPage from 'components/ErrorPage';
import ProfileScope from './containers/ProfileScope';
import ViewSkinnyProfile from './containers/ViewSkinnyProfile';
import SampleProfilePage from './components/SampleProfilePage';
import SampleProfilesPage from './containers/SampleProfilesPage';
import CompanyDepartmentUserRegistration from './containers/CompanyDepartmentUserRegistration';
import CompanyUserRegistration from './containers/CompanyUserRegistration';
import UserLayout from './components/UserLayout';
import ProjectMobileDummy from './components/ProjectMobileDummy';

import UserPolicy from 'policies/UserPolicy';
import UserAccountPage from './components/UserAccountPage';
import Search from './containers/Search';
import AlertsPage from './containers/AlertsPage';
import CorporateAccount from './containers/CorporateAccount';

import ResetPassword from './containers/ResetPassword';
import NewPassword from './containers/NewPassword';
import ProjectListPage from './containers/ProjectListPage';
import NewProjectWizard from './containers/NewProjectWizard';

import ImportRequestsWizardRoutes from './components/ImportRequestsWizardRoutes';
import NewRequestsPage from './components/NewRequestsPage';

import Project from './containers/Project';

import NotificationsSummaryPresenter from 'presenters/NotificationsSummaryPresenter';

import Routes from 'routes';

import useDetectMobile from 'hooks/useDetectMobile';

const UserPanelComponent = (componentProps) => {
  const { currentUser, backgroundExport, notificationsSummary } = componentProps;
  const sharedContext = {
    currentUser,
    backgroundExport,
    notificationsSummary,
  };

  const isMobile = useDetectMobile();
  const rootRedirectRoute = isMobile ? Routes.searchPath() : Routes.dashboardPath();

  return (
    <AppSwitch layout={UserLayout} sharedContext={sharedContext}>
      <AppRoute
        exact
        path={Routes.rootPath()}
        render={() => (currentUser ? <Redirect to={rootRedirectRoute} /> : <Redirect to={Routes.signInPath()} />)}
      />
      <AppRoute
        path={Routes.errorPath(':code')}
        exact
        render={(props) => <ErrorPage code={props.match.params.code} />}
      />
      <AppRoute path={Routes.wafAccessDenied()} exact component={AccessDeniedPage} />
      <AppRoute
        path={Routes.profilePath(':slug')}
        render={(props) => <ProfileScope key={props.match.params.slug} {...props} />}
      />
      <AppRoute
        path={Routes.skinnyProfilePath(':slug')}
        render={(props) => <ViewSkinnyProfile key={props.match.params.slug} {...props} />}
      />
      <AppRoute exact path={Routes.sampleProfilesPath()} component={SampleProfilesPage} />
      <AppRoute
        path={Routes.sampleProfilePath(':slug')}
        render={(props) => <SampleProfilePage key={props.match.params.slug} {...props} />}
      />
      <AppRoute path={Routes.newCompanyUserPath(':companyId')} component={CompanyUserRegistration} />
      <AppRoute
        path={Routes.newCompanyDepartmentUserPath(':companyId', ':departmentId')}
        component={CompanyDepartmentUserRegistration}
      />
      <AppRoute exact path={Routes.newRequestPath()} render={(props) => <NewRequestsPage {...props} />} />
      {UserPolicy.canRequestProfile(currentUser) && (
        <AppRoute path={Routes.importRequestsWizardPath()} component={ImportRequestsWizardRoutes} />
      )}
      <AppRoute exact path={Routes.userAccountPath()} component={UserAccountPage} />
      <AppRoute
        exact
        path={Routes.userAlertsPath()}
        render={(props) => (currentUser ? <AlertsPage {...props} /> : <Redirect to={Routes.signInPath()} />)}
      />
      <AppRoute
        exact
        path={[Routes.searchPath(), Routes.savedSearchesPath(), Routes.savedSearchPath(':savedSearchId')]}
        component={Search}
      />
      {UserPolicy.canSeeCorporateAccount(currentUser) && (
        <AppRoute path={Routes.corporateAccountPath()} component={CorporateAccount} />
      )}
      <AppRoute exact path={Routes.resetPasswordPath()} component={ResetPassword} />
      <AppRoute exact path={Routes.newPasswordPath()} component={NewPassword} />

      {UserPolicy.canSeeProjects(currentUser) && (
        <AppRoute
          exact
          path={Routes.projectsPath()}
          component={ProjectListPage}
          dummyComponent={ProjectMobileDummy}
          desktopOnly
        />
      )}

      {UserPolicy.canSeeProjects(currentUser) && (
        <AppRoute
          path={Routes.newProjectPath()}
          component={NewProjectWizard}
          dummyComponent={ProjectMobileDummy}
          desktopOnly
        />
      )}

      {UserPolicy.canSeeProjects(currentUser) && (
        <AppRoute
          path={Routes.projectPath(':projectId')}
          render={(props) => <Project key={props.match.params.projectId} {...props} />}
          dummyComponent={ProjectMobileDummy}
          desktopOnly
        />
      )}
      <AppRoute path="/download" component={DownloadPage} />
      <AppRoute
        path={Routes.dashboardPath()}
        render={(props) => (currentUser ? <DashboardPage {...props} /> : <Redirect to={Routes.signInPath()} />)}
      />
    </AppSwitch>
  );
};

const UserPanel = withAuthenticator(UserPanelComponent);

UserPanelComponent.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  backgroundExport: PropTypes.func.isRequired,
  notificationsSummary: NotificationsSummaryPresenter.shape(),
};

export default UserPanel;
