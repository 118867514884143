import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextInput, Box } from 'grommet';
import Button from 'components/Button';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

class NewInterestForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    interestName: PropTypes.string,
  };

  state = {
    interest: {
      title: this.props.interestName || '',
    },
    errors: null,
  };

  handleSubmit = () => {
    const { interest } = this.state;
    this.setState({ errors: null });

    this.props.onSubmit({ interest }).catch(({ errors }) => {
      this.setState({ errors });
    });
  };

  updateField(fieldName, value) {
    this.setState({ interest: { ...this.state.interest, [fieldName]: value } });
  }

  handleTextFieldChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.updateField(fieldName, value);

  handleSelectChange = (fieldName) => (value) => this.updateField(fieldName, value);

  render() {
    const { saving } = this.props;
    const { interest, errors } = this.state;

    return (
      <div>
        <VerticalFormFieldWithBottomErrors label="Title:" errors={errors && errors.title} htmlFor="title">
          <TextInput value={interest.title} onChange={this.handleTextFieldChange('title')} id="title" />
        </VerticalFormFieldWithBottomErrors>

        <Box direction="row" margin={{ top: 'small' }} width="medium">
          <Button label="Save" disabled={saving} primary onClick={this.handleSubmit} />
        </Box>
      </div>
    );
  }
}

export default NewInterestForm;
