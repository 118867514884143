import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { debounce } from 'utils/HelperMethods';

import Button from 'components/Button';
import { Box } from 'grommet';
import Search from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Select from 'components/Select';
import OrganizationList from 'containers/AdminPanel/containers/OrganizationList';

import Routes from 'routes';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import NewOrganizationModal from 'containers/AdminPanel/containers/NewOrganizationModal';
import OrganizationPolicy from 'policies/OrganizationPolicy';

const MAX_SEARCH_LENGTH = 40;
const ORGANIZATION_STATUS_FILTER_OPTIONS = [
  { label: 'Has a published version', value: 'true' },
  { label: "Doesn't has a Published", value: 'false' },
];

const findOption = (selectedValue, options) => options.find(({ value }) => value === selectedValue);

class OrganizationListPage extends Component {
  state = {
    showCreateOrganizationPopup: false,
  };

  handleCreateNewOrganization = () => this.setState({ ...this.state, showCreateOrganizationPopup: true });

  handleCancelNewOrganization = () => this.setState({ ...this.state, showCreateOrganizationPopup: false });

  handleOrganizationCreate = (organization) => {
    this.setState({ showCreateOrganizationPopup: false });
    window.open(Routes.editAdminOrganizationPath(organization.id));
  };

  handleSortChange = (sortFields) => {
    const { filters, changeFilters } = this.props;

    changeFilters({ ...filters, sortFields });
  };

  handleFilter = (fieldName, value) => {
    const { filters, changeFilters } = this.props;

    changeFilters({ ...filters, [fieldName]: value });
  };

  handleFilterDebounced = debounce(this.handleFilter);

  handleFilterChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFilterDebounced(fieldName, value);

  handleFilterSelectChange = (fieldName) => (value) => this.handleFilterDebounced(fieldName, value);

  render() {
    const { currentUser, filters } = this.props;
    const { showCreateOrganizationPopup } = this.state;

    return (
      <Box margin="none" full="horizontal">
        <PageHeader title="Organizations List" />
        <Box direction="row" align="center" margin={{ bottom: 'medium' }}>
          <Box width="medium" margin={{ right: 'small' }}>
            <Search
              onChange={this.handleFilterChange('searchFieldCont')}
              placeholder="Search"
              maxLength={MAX_SEARCH_LENGTH}
              defaultValue={filters.searchFieldCont}
            />
          </Box>
          {OrganizationPolicy.canAddElement(currentUser) && (
            <Box margin={{ right: 'small' }}>
              <Button primary label="Add Organization" onClick={this.handleCreateNewOrganization} />
            </Box>
          )}
          {OrganizationPolicy.canExportList(currentUser) && (
            <Box margin={{ right: 'small' }}>
              <Button
                label="Export"
                target="_blank"
                href={Routes.csvV1AdminOrganizationsPath(buildFilterParams(filters))}
              />
            </Box>
          )}
        </Box>
        <Box direction="row" margin={{ bottom: 'medium' }}>
          <Box width="small" margin={{ right: 'small' }}>
            <Select
              onValueChange={this.handleFilterSelectChange('hasPublishedTrue')}
              options={ORGANIZATION_STATUS_FILTER_OPTIONS}
              isClearable
              noBottom
              placeholder="Status"
              value={findOption(filters.hasPublishedTrue, ORGANIZATION_STATUS_FILTER_OPTIONS)}
            />
          </Box>
        </Box>
        <OrganizationList currentUser={currentUser} filters={filters} onSortChange={this.handleSortChange} />
        {showCreateOrganizationPopup && (
          <NewOrganizationModal
            onClose={this.handleCancelNewOrganization}
            onCreateSuccess={this.handleOrganizationCreate}
          />
        )}
      </Box>
    );
  }
}

OrganizationListPage.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
  changeFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape(),
};

export default OrganizationListPage;
