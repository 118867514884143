import ProjectSubscriptionsListContainerConstants from './ProjectSubscriptionsListContainerConstants';
import { propEq, reject, equals } from 'ramda';

const initialState = {
  loading: false,
  subscriptions: [],
  meta: {
    totalCount: 0,
    nextPage: 1,
  },
  filters: {},
};

function ProjectSubscriptionsListContainerReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProjectSubscriptionsListContainerConstants.LOAD_PROJECT_SUBSCRIPTION_START: {
      return { ...state, loading: true };
    }
    case ProjectSubscriptionsListContainerConstants.LOAD_PROJECT_SUBSCRIPTION_SUCCESS: {
      const { filters, meta } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      const subscriptions = [...state.subscriptions, ...action.subscriptions];

      return { ...state, loading: false, subscriptions, meta };
    }
    case ProjectSubscriptionsListContainerConstants.LOAD_PROJECT_SUBSCRIPTION_ERROR: {
      return { ...state, loading: false };
    }
    case ProjectSubscriptionsListContainerConstants.FILTER_PROJECT_SUBSCRIPTION_START: {
      const { filters } = action;

      return { ...state, loading: true, filters };
    }
    case ProjectSubscriptionsListContainerConstants.FILTER_PROJECT_SUBSCRIPTION_SUCCESS: {
      const { subscriptions, meta, filters } = action;

      if (!equals(filters, state.filters)) {
        return state;
      }

      return { ...state, loading: false, subscriptions, meta };
    }
    case ProjectSubscriptionsListContainerConstants.FILTER_PROJECT_SUBSCRIPTION_ERROR: {
      return { ...state, loading: false };
    }
    case ProjectSubscriptionsListContainerConstants.DELETE_PROJECT_SUBSCRIPTION_START: {
      return { ...state, loading: true };
    }
    case ProjectSubscriptionsListContainerConstants.DELETE_PROJECT_SUBSCRIPTION_SUCCESS: {
      const { id } = action;
      const subscriptions = reject(propEq(id, 'id'), state.subscriptions);
      const meta = { ...state.meta, totalCount: state.meta.totalCount - 1 };

      return { ...state, loading: false, subscriptions, meta };
    }
    case ProjectSubscriptionsListContainerConstants.DELETE_PROJECT_SUBSCRIPTION_ERROR: {
      return { ...state, loading: true };
    }
    default:
      return state;
  }
}

export default ProjectSubscriptionsListContainerReducer;
