import React, { useEffect } from 'react';

import { useHistory } from 'reactRouterPolyfill';
import { useCurrentUser } from 'hooks';
import UserPolicy from 'policies/UserPolicy';

import { isPresent, isBoardexValid } from 'utils/HelperMethods';
import { parseLocationToParams, buildUrl } from 'utils/UrlHelper';
import Routes from 'routes';

import ErrorPage from 'components/ErrorPage';

import NewRequestsForm from './components/NewRequestsForm';
import RequestsHistory from './components/RequestsHistory';

const NewRequestsPage = () => {
  const { currentUser } = useCurrentUser();
  const history = useHistory();
  const { source, sign_in_url: signInUrl, profile } = parseLocationToParams(history.location);
  const isBoardexGuest = source && source.toLowerCase() === 'boardex' && !isPresent(currentUser);

  useEffect(() => {
    if (isBoardexValid({ source, url: signInUrl }) && isBoardexGuest) {
      const url = buildUrl(signInUrl, {
        source,
        sign_in_url: signInUrl,
        profile,
        success_path: Routes.newRequestPath(),
      });
      window.location = url;
    }
  }, []);

  const isGuest = !window.currentUserId;
  if (isGuest) {
    const navigateToPath = Routes.signInPath({ redirectToPath: window.location.pathname + window.location.search });
    window.location = navigateToPath;
    return null;
  }

  if (!UserPolicy.canRequestProfile(currentUser)) {
    return <ErrorPage code="404" text="Page not found" />;
  }

  return (
    <div>
      <NewRequestsForm />
      <RequestsHistory />
    </div>
  );
};

export default NewRequestsPage;
