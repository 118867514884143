import ProfileRelationshipMappingAdvertisementConstants from './ProfileRelationshipMappingAdvertisementConstants';

const initialState = {
  profileId: null,
  relationships: [],
  loading: false,
};

function ProfileRelationshipMappingAdvertisementReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ProfileRelationshipMappingAdvertisementConstants.LOAD_PROFILE_RELATIONSHIPS_START: {
      const { profileId } = action;

      return { ...state, profileId, loading: true };
    }
    case ProfileRelationshipMappingAdvertisementConstants.LOAD_PROFILE_RELATIONSHIPS_SUCCESS:
    case ProfileRelationshipMappingAdvertisementConstants.LOAD_PROFILE_RELATIONSHIPS_ERROR: {
      const { profileId, relationships } = action;

      if (profileId === state.profileId) {
        return { ...state, relationships, loading: false };
      }

      return state;
    }
    case ProfileRelationshipMappingAdvertisementConstants.CLEAR_PROFILE_RELATIONSHIPS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export default ProfileRelationshipMappingAdvertisementReducer;
