import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert as AlertIcon } from 'grommet-icons';

import Button from 'components/Button';
import Modal from 'components/UserModal';

import styles from './ErrorModal.module.css';

export default class ErrorModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { onClose } = this.props;

    return (
      <Modal size="large" onClose={onClose}>
        <div className={styles.root}>
          <div className={styles.error}>
            <h4>
              <AlertIcon />
              Sorry Something Went Wrong, Please try again.
            </h4>
            If this error persists, please contact{' '}
            <a href="mailto:customerservice@boardroominsiders.com">customerservice@boardroominsiders.com</a>.
          </div>
          <Button onClick={onClose} primary label="OK" />
        </div>
      </Modal>
    );
  }
}
