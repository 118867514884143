import React, { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import ExportJobPresenter from 'presenters/ExportJobPresenter';
import usePolling from 'hooks/usePolling';

import SuccessModal from './components/SuccessModal';
import ErrorModal from './components/ErrorModal';

const BackgroundExportResultNotifier = (props) => {
  const { component, onExport, onPoll, onExportPropName, shouldOpenOnCompletion, ...restProps } = props;

  const [currentExportJob, setExportJob] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSuccessModalOpened, setSuccessModalOpened] = useState(false);
  const [isErrorModalOpened, setErrorModalOpened] = useState(false);

  const handleOpenSuccessModal = () => {
    setLoading(false);
    setSuccessModalOpened(true);
    setErrorModalOpened(false);
  };

  const handleOpenErrorModal = () => {
    setLoading(false);
    setSuccessModalOpened(false);
    setErrorModalOpened(true);
  };

  const handleCloseAllModals = () => {
    setSuccessModalOpened(false);
    setErrorModalOpened(false);
  };

  const handleOpenFileInNewTab = (fileUrl) => window.open(fileUrl);

  const handlePoll = () =>
    onPoll(ExportJobPresenter.id(currentExportJob))
      .then((exportJob) => {
        if (ExportJobPresenter.isCompleted(exportJob)) {
          setLoading(false);
          setExportJob(exportJob);

          if (shouldOpenOnCompletion) {
            handleOpenFileInNewTab(ExportJobPresenter.fileUrl(exportJob));
            return;
          }

          handleOpenSuccessModal();
        }
      })
      .catch((error) => {
        if ([401, 403, 404, 500, 502].includes(error.status)) {
          handleOpenErrorModal();
        }
      });

  usePolling(handlePoll, !isNil(onPoll) && ExportJobPresenter.isQueued(currentExportJob));

  const handleExport = (params) => {
    setLoading(true);

    return onExport(params)
      .then((exportJob) => {
        setExportJob(exportJob);

        if (shouldOpenOnCompletion && isNil(onPoll)) {
          handleOpenFileInNewTab(ExportJobPresenter.fileUrl(exportJob));
          return;
        }

        if (!shouldOpenOnCompletion && isNil(onPoll)) {
          handleOpenSuccessModal();
        }
      })
      .catch(handleOpenErrorModal);
  };

  const isEnable = () => isNil(restProps.disabled) || !restProps.disabled;

  return (
    <>
      {isErrorModalOpened && <ErrorModal onClose={handleCloseAllModals} />}
      {isSuccessModalOpened && <SuccessModal onClose={handleCloseAllModals} />}
      {createElement(component, {
        ...restProps,
        isLoading,
        disabled: restProps.disabled || isLoading,
        [onExportPropName]: !isLoading && isEnable() ? handleExport : null,
      })}
    </>
  );
};

BackgroundExportResultNotifier.propTypes = {
  component: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onPoll: PropTypes.func,
  onExportPropName: PropTypes.string,
  shouldOpenOnCompletion: PropTypes.bool,
};

BackgroundExportResultNotifier.defaultProps = {
  onExportPropName: 'onExport',
  shouldOpenOnCompletion: false,
};

export default BackgroundExportResultNotifier;
