import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/v2/Typography';

import styles from './CreationFail.module.css';

const CreationFail = (props) => {
  const { onPreviousStep, onNextStep, WizardNavigation } = props;

  return (
    <div>
      <Typography variant="h1" className={styles.message}>
        Something went wrong.
      </Typography>
      <div className={styles.actions}>
        <WizardNavigation onPreviousStep={onPreviousStep} onNextStep={onNextStep} isNextButtonHidden />
      </div>
    </div>
  );
};

CreationFail.propTypes = {
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  WizardNavigation: PropTypes.func.isRequired,
};

export default CreationFail;
