import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import styles from './CustomizedLegend.module.css';

export default class CustomizedLegend extends Component {
  static propTypes = {
    payload: PropTypes.arrayOf(PropTypes.shape({})),
    options: PropTypes.shape({}).isRequired,
  };

  generateLineStyles = (strokeDasharray, color) => {
    const classes = cn(styles.line, { [styles.dashed]: isPresent(strokeDasharray) });
    const inlineStyles = { borderColor: color };

    return [classes, inlineStyles];
  };

  renderLegendItem(entry, index, options) {
    const {
      color,
      value,
      payload: { strokeDasharray },
    } = entry;

    const [classes, inlineStyles] = this.generateLineStyles(strokeDasharray, color);
    const option = options[value];

    return (
      <div key={index} className={styles.legendItem}>
        <hr className={classes} style={inlineStyles} />
        <div className={styles.label}>{option.label}</div>
      </div>
    );
  }

  render() {
    const { payload, options } = this.props;

    return (
      <div className={styles.customLegend}>
        {payload.map((entry, index) => this.renderLegendItem(entry, index, options))}
      </div>
    );
  }
}
