import React, { Component } from 'react';
import { mapObjIndexed } from 'ramda';
import PropTypes from 'prop-types';
import { isPresent } from 'utils/HelperMethods';

import ConfirmationModal from './components/ConfirmationModal';

export class ConfirmationWrapper extends Component {
  static propTypes = {
    Modal: PropTypes.func,
    WrappedComponent: PropTypes.func.isRequired,
    targetFunctions: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    Modal: ConfirmationModal,
  };

  state = { confirmationModalOpened: false, currentTarget: null };

  handleEvalTargetFunction = () => {
    const { currentTarget } = this.state;
    if (!currentTarget) {
      return Promise.reject();
    }

    this.setState({ confirmationModalOpened: false, currentTarget: null });
    return this.props[currentTarget.functionName](...currentTarget.args);
  };

  handleShowDeleteConfirmation =
    (functionName, header) =>
    (...args) => {
      this.setState({ confirmationModalOpened: true });
      this.setState({
        currentTarget: {
          functionName,
          header,
          args,
        },
      });
    };

  handleCancelConfirmation = () => {
    this.setState({ confirmationModalOpened: false });
    this.setState({ currentTarget: null });
  };

  wrappedComponentProps = () => {
    const { targetFunctions, WrappedComponent, Modal, ...props } = this.props;
    const wrappedComponentFunctions = mapObjIndexed(
      (header, functionName) =>
        isPresent(this.props[functionName]) ? this.handleShowDeleteConfirmation(functionName, header) : null,
      targetFunctions,
    );

    return {
      ...props,
      ...wrappedComponentFunctions,
    };
  };

  render() {
    const { confirmationModalOpened, currentTarget } = this.state;
    const { Modal, WrappedComponent } = this.props;

    return (
      <>
        <WrappedComponent {...this.wrappedComponentProps()} />
        {confirmationModalOpened && currentTarget && (
          <Modal
            header={currentTarget.header}
            onCancel={this.handleCancelConfirmation}
            onConfirm={this.handleEvalTargetFunction}
          />
        )}
      </>
    );
  }
}

export default function withConfirmation(WrappedComponent, targetFunctions, Modal = ConfirmationModal) {
  return (props) => (
    <ConfirmationWrapper
      {...props}
      Modal={Modal}
      targetFunctions={targetFunctions}
      WrappedComponent={WrappedComponent}
    />
  );
}
