import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClickOnRelationshipMappingAdvertisment } from 'utils/amplitude';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import RelationshipPresenter from 'presenters/RelationshipPresenter';
import WorkSearchPresenter from 'presenters/WorkSearchPresenter';

import Routes from 'routes';

import { Anchor } from 'grommet';
import ProfileAvatar from 'components/ProfileAvatar';

import styles from './Relationship.module.css';

export default class Relationship extends Component {
  static propTypes = {
    currentProfileId: PropTypes.number.isRequired,
    currentProfileSlug: PropTypes.string.isRequired,
    relationship: RelationshipPresenter.shape().isRequired,
  };

  getProfile() {
    const { currentProfileId, relationship } = this.props;
    const profiles = RelationshipPresenter.profiles(relationship);
    const [profile] = profiles.filter(({ id }) => currentProfileId !== id);

    return profile;
  }

  render() {
    const { currentProfileSlug } = this.props;
    const profile = this.getProfile();
    const currentWorks = ProfileSearchPresenter.currentWorks(profile);
    const profileId = ProfileSearchPresenter.id(profile);

    return (
      <Anchor
        className={styles.root}
        href={Routes.profileRelationshipsMappingRelationshipPath(currentProfileSlug, profileId)}
        onClick={logClickOnRelationshipMappingAdvertisment}
        target="_blank"
      >
        <ProfileAvatar className={styles.avatar} size={60} src={ProfileSearchPresenter.photoUrl(profile)} />
        <div className={styles.info}>
          <div className={styles.header}>{ProfileSearchPresenter.fullName(profile)}</div>
          {currentWorks.map((work) => (
            <div key={work.id} className={styles.description}>
              {WorkSearchPresenter.title(work)}
            </div>
          ))}
        </div>
      </Anchor>
    );
  }
}
