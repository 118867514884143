import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(profileId, params) {
    const url = Routes.apiV1AdminProfileDraftHighlightsPath(profileId);
    return FetchHelpers.post(url, params);
  },
  destroy(profileId, highlightId) {
    const url = Routes.apiV1AdminProfileDraftHighlightPath(profileId, highlightId);
    return FetchHelpers.delete(url);
  },
};
