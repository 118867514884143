import React from 'react';

export default function () {
  return (
    <div>
      <p>
        <strong>Personal Interests Tags:</strong>
      </p>
      <ul>
        <li>
          Do not add a lot of new, very specific tags as they clutter up our tag list. For example, do not add names of
          artists like “Kid Rock” or variations on other existing tags that would work perfectly fine. e.g., “reading”
          or “books” will suffice—do not add another tag that is “reading books.”
        </li>
        <li>
          Be very conservative about creating new tags. The reason we have them is to connect people by interests. So if
          we have one person tagged as “reading books” and another as “reading” they will not connect, which defeats the
          purpose of tagging.
        </li>
        <li>“Family” is not a tag we want to use as everyone is interested in their family.</li>
        <li>
          This quick reference should be a summary of any hobbies or interests discussed in the “Personal Attributes and
          Interests” section; the two sections should be well-aligned. For example, if you quote an executive talking
          about having done a bunch of marathons in that section, then you should add “Running” and “Marathons” to the
          Personal Interests list. Likewise, if they are quoted as saying they enjoy mentoring students interested in
          technology, then you should list “Mentoring” and in the Personal Interests list.
        </li>
      </ul>
    </div>
  );
}
