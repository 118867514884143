import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { isBlank } from 'utils/HelperMethods';

import CsvItemsTable from './containers/CsvItemsTable';
import Spinner from 'components/Spinner';
import Header from 'containers/UserPanel/containers/Project/components/Header';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import ProjectPresenter from 'presenters/ProjectPresenter';
import SourcePresenter from 'presenters/Project/SourcePresenter';
import { STATES, humanStateName, stateColor } from 'presenters/Project/CsvItemPresenter';

import styles from './CsvSourceEditPage.module.css';

export default class CsvSourceEditPage extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    sourceId: PropTypes.string.isRequired,
    project: ProjectPresenter.shape().isRequired,
    source: SourcePresenter.shape(),
    loading: PropTypes.bool.isRequired,
    remainingRequestsCount: PropTypes.number,
    loadTargetingSource: PropTypes.func.isRequired,
    changeRemainingRequestsCount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { project, sourceId, loadTargetingSource } = this.props;

    loadTargetingSource(ProjectPresenter.id(project), sourceId);
  }

  breadcrumbs() {
    const { project } = this.props;

    return [
      { name: 'Projects', to: Routes.projectsPath() },
      { to: Routes.projectPath(ProjectPresenter.id(project)), name: ProjectPresenter.name(project) },
      { name: 'Edit List' },
    ];
  }

  handleRemainingRequestsCountChange = (remainingRequestsCount) => {
    const { changeRemainingRequestsCount } = this.props;

    changeRemainingRequestsCount(remainingRequestsCount);
  };

  renderState = ({ key: state }) => (
    <span key={state} className={styles.status} style={{ borderColor: stateColor(state) }}>
      {humanStateName(state)}
    </span>
  );

  render() {
    const { currentUser, remainingRequestsCount, project, source, sourceId, loading } = this.props;

    if (loading || isBlank(source) || SourcePresenter.id(source) !== parseInt(sourceId, 10)) {
      return <Spinner />;
    }

    return (
      <div className={styles.root}>
        <Header
          currentUser={currentUser}
          title={`${ProjectPresenter.name(project)}: ${SourcePresenter.name(source)}`}
          breadcrumbs={this.breadcrumbs()}
          project={project}
        />
        <div className={styles.general}>
          <div className={styles.main}>
            <div className={styles.info}>
              <div className={styles.limits}>
                <div className={styles.limit}>
                  Credits Remaining: <span className={styles.counter}>{remainingRequestsCount}</span>
                </div>
              </div>
              <div>{STATES.map(this.renderState)}</div>
            </div>
            <CsvItemsTable
              project={project}
              source={source}
              onRemainingRequestsCountChange={this.handleRemainingRequestsCountChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
