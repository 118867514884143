import React from 'react';
import { Context } from 'components/ContextProvider';

const withContext = (Component) =>
  class App extends React.Component {
    render() {
      return <Context.Consumer>{(context) => <Component {...this.props} context={context} />}</Context.Consumer>;
    }
  };

export default withContext;
