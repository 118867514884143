import { defaultTo, head, pick, find, propEq } from 'ramda';
import UserPresenter, { ROLES, STATES } from 'presenters/UserPresenter';

export default {
  defaultAttributes(user) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      role: head(ROLES).key,
      ...user,
      requestsNumber: defaultTo(0, user.requestsNumber),
      projectsNumber: defaultTo(0, user.projectsNumber),
      profileViewsNumber: defaultTo(0, user.profileViewsNumber),
      profileDownloadsNumber: defaultTo(0, user.profileDownloadsNumber),
      isActive: UserPresenter.isActive(user),
      databaseOnly: defaultTo(false, user.databaseOnly),
      companyDepartment: defaultTo(null, user.companyDepartment),
      corporateViewAvailable: defaultTo(false, user.corporateViewAvailable),
      projectsExportEnabled: defaultTo(false, user.projectsExportEnabled),
    };
  },

  attributesToSubmit(newUser, oldUser) {
    let propsToSend = [
      'firstName',
      'lastName',
      'email',
      'password',
      'passwordConfirmation',
      'role',
      'requestsNumber',
      'projectsNumber',
      'profileViewsNumber',
      'profileDownloadsNumber',
      'companyId',
      'companyDepartmentId',
      'corporateViewAvailable',
      'exportSearchResultInCsvAvailable',
      'projectsAvailable',
      'phone',
      'twoFactorAuthenticationDisabled',
      'projectsExportEnabled',
      'avatar',
      'avatarCropParams',
      'databaseOnly',
    ];

    if (newUser.state !== UserPresenter.state(oldUser)) {
      propsToSend = [...propsToSend, 'stateEvent'];
    }

    const stateEvent = find(propEq(newUser.state, 'key'))(STATES).event;

    return {
      ...pick(propsToSend, {
        ...newUser,
        companyId: newUser.company && newUser.company.id,
        companyDepartmentId: newUser.companyDepartment && newUser.companyDepartment.id,
        stateEvent,
      }),
    };
  },
};
