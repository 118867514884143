import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Spinner as Spinning } from 'grommet';

import styles from './Loader.module.css';

const Loader = (props) => {
  const { className } = props;
  const loaderClassName = clsx(styles.root, className);

  return (
    <div className={loaderClassName}>
      <Spinning />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: null,
};

export default Loader;
