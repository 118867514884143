import React from 'react';
import routes from 'routes';

import { withAuthenticator } from 'containers/Authenticator';

import AppSwitch from 'components/AppSwitch';
import AppRoute from 'components/AppRoute';
import CorporateAccountImportUsersWizard from 'components/CorporateAccountImportUsersWizard';
import Layout from './components/Layout';
import ProfileListPage from './containers/ProfileListPage';
import ProfilePage from './containers/ProfilePage';
import UserListPage from './containers/UserListPage';
import UserPage from './containers/UserPage';
import InterestListPage from './containers/InterestListPage';
import InterestPage from './containers/InterestPage';
import RequestsPage from './containers/RequestsPage';
import OrganizationListPage from './containers/OrganizationListPage';
import OrganizationPage from './containers/OrganizationPage';
import CompaniesListPage from './containers/CompaniesListPage';
import CompanyRoutes from './containers/CompanyRoutes';
import SamplesPage from './containers/SamplesPage';
import FullProfileRequestListPage from './containers/FullProfileRequestListPage';
import TagListPage from './containers/TagListPage';
import TagPage from './containers/TagPage';
import DashboardPage from './containers/DashboardPage';
import LanguagesListPage from './containers/LanguagesListPage';
import LanguagePage from './containers/LanguagePage';
import CountriesListPage from './containers/CountriesListPage';
import CountryPage from './containers/CountryPage';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import UserPolicy from 'policies/UserPolicy';

function AdminPanelComponent({ currentUser }) {
  const sharedContext = { currentUser };

  return (
    <AppSwitch layout={Layout} sharedContext={sharedContext}>
      <AppRoute exact path={routes.adminProfilesPath()} component={ProfileListPage} />
      <AppRoute path={routes.editAdminProfilePath(':id')} component={ProfilePage} />

      {UserPolicy.canSeeList(currentUser) && <AppRoute exact path={routes.adminUsersPath()} component={UserListPage} />}
      {UserPolicy.canSeeElement(currentUser) && (
        <AppRoute path={routes.editAdminUserPath(':id')} component={UserPage} />
      )}

      <AppRoute exact path={routes.adminProfileRequestsPath()} component={RequestsPage} />

      <AppRoute exact path={routes.adminOrganizationsPath()} component={OrganizationListPage} />
      <AppRoute path={routes.editAdminOrganizationPath(':id')} component={OrganizationPage} />

      <AppRoute exact path={routes.adminCompaniesPath()} component={CompaniesListPage} />
      <AppRoute path={routes.newAdminCompanyUsersImportPath(':id')} component={CorporateAccountImportUsersWizard} />
      <AppRoute path={routes.adminCompanyPath(':companyId')} component={CompanyRoutes} />

      <AppRoute exact path={routes.adminInterestsPath()} component={InterestListPage} />
      <AppRoute path={routes.editAdminInterestPath(':id')} component={InterestPage} />

      <AppRoute exact path={routes.adminRootPath()} component={DashboardPage} />
      <AppRoute path={routes.adminDashboard()} component={DashboardPage} />
      <AppRoute path={routes.adminSamplesPath()} component={SamplesPage} />
      <AppRoute exact path={routes.adminTagListPath()} component={TagListPage} />
      <AppRoute exact path={routes.editAdminTagPath(':id')} component={TagPage} />

      <AppRoute exact path={routes.adminLanguagesPath()} component={LanguagesListPage} />
      <AppRoute exact path={routes.editAdminLanguagePath(':id')} component={LanguagePage} />

      <AppRoute exact path={routes.adminCountriesPath()} component={CountriesListPage} />
      <AppRoute exact path={routes.editAdminCountryPath(':id')} component={CountryPage} />

      <AppRoute exact path={routes.adminFullProfileRequestsPath()} component={FullProfileRequestListPage} />
    </AppSwitch>
  );
}

AdminPanelComponent.propTypes = {
  currentUser: CurrentUserPresenter.shape(),
};

const AdminPanel = withAuthenticator(AdminPanelComponent);

export default AdminPanel;
