import Routes from 'routes/App';
import { isPresent } from 'utils/HelperMethods';
import { storeCurrentUserId } from 'utils/currentUserHelper';
import SessionConstants from './SessionConstants';
import SessionsRepository from 'repositories/SessionsRepository';
import TwoFactorAuthenticationRepository from 'repositories/TwoFactorAuthenticationRepository';
import TwoFactorAuthenticationPhoneNumberRepository from 'repositories/TwoFactorAuthentication/PhoneNumberRepository';
import { clearAmplitudeUser, logLogOut, logLogIn } from 'utils/amplitude';

export function authenticate(params, { redirectToUrl } = {}) {
  return (dispatch) => {
    dispatch({ type: SessionConstants.AUTHENTICATE_START });

    return SessionsRepository.create(params).then((data) => {
      if (isPresent(data.twoFactorAuthentication)) {
        return data;
      }

      logLogIn();
      window.location = redirectToUrl || Routes.rootPath();
      return undefined;
    });
  };
}

export function signOut() {
  return (dispatch) => {
    dispatch({ type: SessionConstants.SIGN_OUT_START });

    return SessionsRepository.destroy()
      .then(() => {
        storeCurrentUserId(null);
        clearAmplitudeUser(null);
        logLogOut();
        window.location = Routes.logOutPath();
      })
      .catch((error) => {
        dispatch({ type: SessionConstants.SIGN_OUT_ERROR });

        throw error;
      });
  };
}

export function confirm2FA(params, { redirectToUrl } = {}) {
  return () =>
    TwoFactorAuthenticationRepository.create(params).then(() => {
      window.location = redirectToUrl || Routes.rootPath();
    });
}

export function resendConfirmationFor2FA() {
  return () => TwoFactorAuthenticationRepository.resendConfirmation();
}

export function updatePhoneNumberFor2FA(params) {
  return () => TwoFactorAuthenticationPhoneNumberRepository.update(params);
}
