import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'containers/UserPanel/containers/Project/containers/AvailableProfilesContainer'; // eslint-disable-line import/named
import ProjectProfilesList from './ProjectProfilesList';

const mapStateToProps = (state) => ({
  loading: state.AvailableProfilesContainerReducer.loading,
  profiles: state.AvailableProfilesContainerReducer.profiles,
  totalCount: state.AvailableProfilesContainerReducer.totalCount,
  nextPage: state.AvailableProfilesContainerReducer.nextPage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfilesList);
