import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { formatNumber } from 'utils/NumbersHelper';

import TopFortune500OrganizationsPresenter from './Fortune500ProfilesStatistics/TopFortune500OrganizationsPresenter';

export default new Presenter(
  {
    totalProfilesNumber: PropTypes.number,
    avgProfilesNumberPerCompany: PropTypes.number,
    totalBoardMembersNumber: PropTypes.number,
    topFortune500Organizations: PropTypes.arrayOf(TopFortune500OrganizationsPresenter.shape()),
  },
  {
    formattedTotalProfilesNumber(s) {
      return formatNumber(this.totalProfilesNumber(s));
    },

    formattedAvgProfilesNumberPerCompany(s) {
      return formatNumber(this.avgProfilesNumberPerCompany(s));
    },

    formattedTotalBoardMembersNumber(s) {
      return formatNumber(this.totalBoardMembersNumber(s));
    },
  },
);
