import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';
import { includes } from 'ramda';

import ContextPresenter from './ContextPresenter';

const INTERESTS_TYPE = 'interest';
const TAGS_TYPE = 'tag';

const SIMPLE_FACT_TYPES = [INTERESTS_TYPE, TAGS_TYPE];

export default new Presenter(
  {
    title: PropTypes.string,
    type: PropTypes.string,
    profileIds: PropTypes.arrayOf(PropTypes.number),
    contexts: PropTypes.arrayOf(PropTypes.arrayOf(ContextPresenter.shape())),
    isInderect: PropTypes.bool,
  },
  {
    id(fact) {
      return `${this.profileIds(fact).join('-')}-${this.title(fact)}`;
    },

    isSimpleFact(fact) {
      return includes(this.type(fact), SIMPLE_FACT_TYPES);
    },

    humanType(context) {
      const type = this.type(context);

      switch (type) {
        case INTERESTS_TYPE: {
          return 'Personal Interests';
        }
        case TAGS_TYPE: {
          return 'Business Initiatives';
        }
        default: {
          return null;
        }
      }
    },
  },
);
