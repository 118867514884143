import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { trimExtraEmptyLinesFromHTML } from 'utils/HTMLHelpers';

import Button from 'components/Button';
import { Box } from 'grommet';

import Wysiwyg from 'components/Wysiwyg';

const WysiwygForm = (props) => {
  const { value, onSubmit, saving } = props;

  const [formValue, setFormValue] = useState(value);

  const handleChange = (newFormValue) => {
    setFormValue(newFormValue);
  };

  const handleSubmit = () => {
    const trimmedValue = formValue === null ? formValue : trimExtraEmptyLinesFromHTML(formValue);
    onSubmit(trimmedValue);
  };

  return (
    <div>
      <Box>
        <Wysiwyg editableContent={formValue} onChange={handleChange} />
      </Box>

      <Box margin={{ top: 'medium' }} direction="row">
        <Button primary label="Save" disabled={saving} onClick={handleSubmit} />
      </Box>
    </div>
  );
};

WysiwygForm.propTypes = {
  saving: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default WysiwygForm;
