import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'clsx';

import KanbanCard from './components/KanbanCard';
import Loader from './components/Loader';

import styles from './KanbanColumn.module.css';

class KanbanColumn extends Component {
  static propTypes = {
    cardComponent: PropTypes.func,
    cardClassName: PropTypes.string,
    headerComponent: PropTypes.func,
    column: PropTypes.shape(),
    onCardClick: PropTypes.func,
    onLoadMore: PropTypes.func,
    isDragging: PropTypes.bool,
  };

  handleLoadMore = () => {
    const { column, onLoadMore } = this.props;

    return onLoadMore(column);
  };

  needLoadMore() {
    const { column } = this.props;

    return !isNil(column.nextPage);
  }

  render() {
    const { cardComponent, cardClassName, column, onCardClick, headerComponent, isDragging } = this.props;
    const { id: columnId, droppable } = column;
    const droppableBoxClassName = cx(styles.droppableBox, { [styles.availableDroppableBox]: isDragging && droppable });

    return (
      <div className={styles.column}>
        {createElement(headerComponent, column)}
        <Droppable droppableId={`${columnId}`} isDropDisabled={!droppable}>
          {(provided) => (
            <div className={droppableBoxClassName} ref={provided.innerRef}>
              <InfiniteScroll
                loadMore={this.handleLoadMore}
                hasMore={this.needLoadMore()}
                loader={<Loader key="loader" />}
                useWindow={false}
              >
                {column.cards.map((card, index) => (
                  <KanbanCard
                    key={card.id}
                    card={card}
                    component={cardComponent}
                    className={cardClassName}
                    index={index}
                    onClick={onCardClick}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

export default KanbanColumn;
