import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Modal from 'components/UserModal';

import styles from './SuccessModal.module.css';

export default class SuccessModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { onClose } = this.props;

    return (
      <Modal size="large" onClose={onClose}>
        <div className={styles.root}>
          <div className={styles.success}>
            <h4>Your email is being sent now. Check your email inbox.</h4>
          </div>
          <Button onClick={onClose} primary label="OK" />
        </div>
      </Modal>
    );
  }
}
