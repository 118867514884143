import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { Form, Anchor, Box, Paragraph, Heading, TextInput, Footer } from 'grommet';

import { isPresent } from 'utils/HelperMethods';
import { fromISO } from 'utils/DateTime';

import Button from 'components/Button';
import PhoneInput from 'components/PhoneInput';
import VerticalFormFieldWithBottomErrors from 'components/VerticalFormFieldWithBottomErrors';

import styles from './TwoFactorAuthenticationForm.module.css';

const VERIFICATION_CODE_TIMEOUT = 60 * 1000;

export default class TwoFactorAuthenticationForm extends Component {
  static propTypes = {
    twoFactorAuthentication: PropTypes.shape({}).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onUpdatPhone: PropTypes.func.isRequired,
    onResendConfirmation: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { twoFactorAuthentication } = this.props;
    this.state = {
      form: { unconfirmedPhone: '+1', verificationCode: '' },
      errors: null,
      verificationMeta: twoFactorAuthentication.verificationCode,
    };
  }

  handleInputChange =
    (fieldName) =>
    ({ target: { value } }) =>
      this.handleFieldChange(fieldName)(value);

  handleFieldChange = (fieldName) => (value) =>
    this.setState({ ...this.state, form: { ...this.state.form, [fieldName]: value } });

  handleConfirm = () => {
    const { form } = this.state;
    const { onConfirm } = this.props;

    onConfirm(form).catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  handleUpdatePhone = () => {
    const { form } = this.state;
    const { onUpdatPhone } = this.props;

    onUpdatPhone(form)
      .then(({ verificationCode }) =>
        this.setState({ ...this.state, verificationMeta: verificationCode, errors: null }),
      )
      .catch(({ errors }) => this.setState({ ...this.state, errors }));
  };

  handleResendVerificationCode = () => {
    const { onResendConfirmation } = this.props;

    onResendConfirmation().then(({ verificationCode }) => {
      this.setState({ ...this.state, verificationMeta: verificationCode });
    });
  };

  handlePreventDefault = (event) => event.preventDefault();

  renderCountdown = ({ seconds, completed }) => {
    if (completed) {
      return (
        <Box>
          <span>
            {`Click `}
            <Anchor
              className={styles.resendVerificationCodeLink}
              label="here"
              onClick={this.handleResendVerificationCode}
            />
            {` to request new verification code`}
          </span>
        </Box>
      );
    }

    return (
      <Box>
        <span> Did not receive the verification code? </span>
        <span> {`You will be able to request a new code in a ${seconds} seconds`} </span>
      </Box>
    );
  };

  renderResentVerificationCode() {
    const { form } = this.state;

    return (
      <Countdown
        key={form.codeSentAt}
        date={fromISO(form.codeSentAt) + VERIFICATION_CODE_TIMEOUT}
        renderer={this.renderCountdown}
      />
    );
  }

  renderUpdatePhoneForm() {
    const { form, errors } = this.state;

    return (
      <>
        <fieldset>
          <VerticalFormFieldWithBottomErrors
            size="medium"
            padding="none"
            label="Phone:"
            errors={errors && errors.unconfirmedPhone}
            htmlFor="unconfirmedPhone"
          >
            <PhoneInput
              defaultCountry="US"
              value={form.unconfirmedPhone}
              onChange={this.handleFieldChange('unconfirmedPhone')}
              id="unconfirmedPhone"
            />
          </VerticalFormFieldWithBottomErrors>
        </fieldset>

        <Footer size="small" direction="column" align="stretch">
          <Button primary label="Send Verification code" fill onClick={this.handleUpdatePhone} />
        </Footer>
      </>
    );
  }

  renderConfirmationForm() {
    const { twoFactorAuthentication } = this.props;
    const { form, errors, verificationMeta } = this.state;

    const phone = twoFactorAuthentication.phone || form.unconfirmedPhone;

    return (
      <>
        <fieldset>
          <Box margin={{ bottom: 'small' }}>
            <span>
              A text message with a 6-digit verification code was just sent to
              <span className={styles.phoneNumber}>{phone}</span>
            </span>
          </Box>
          <VerticalFormFieldWithBottomErrors
            size="medium"
            padding="none"
            label="Verification Code:"
            errors={errors && errors.verificationCode}
            htmlFor="verificationCode"
          >
            <TextInput
              value={form.verificationCode}
              onChange={this.handleInputChange('verificationCode')}
              id="verificationCode"
            />
          </VerticalFormFieldWithBottomErrors>
          <Countdown
            key={verificationMeta.codeSentAt}
            date={fromISO(verificationMeta.codeSentAt) + VERIFICATION_CODE_TIMEOUT}
            renderer={this.renderCountdown}
          />
        </fieldset>

        <Footer size="small" direction="column" align="stretch">
          <Button primary label="Confirm" fill onClick={this.handleConfirm} />
        </Footer>
      </>
    );
  }

  render() {
    const { verificationMeta } = this.state;

    return (
      <Form pad="medium" onSubmit={this.handlePreventDefault}>
        <Box align="center" pad="none">
          <Heading weight="bold"> 2-step Verification </Heading>
          <Paragraph margin="none"> This extra step shows that it’s really you trying to sign in </Paragraph>
        </Box>

        {isPresent(verificationMeta) ? this.renderConfirmationForm() : this.renderUpdatePhoneForm()}
      </Form>
    );
  }
}
