import NewProjectWizardConstants from './NewProjectWizardConstants';
import NewProjectForm from 'forms/NewProjectForm';
import SourceForm from 'forms/project/SourceForm';

const initialState = {
  form: NewProjectForm.defaultAttributes({ sourcesAttributes: [SourceForm.defaultAttributes()] }),
  project: null,
  saving: false,
  saved: false,
  loading: false,
};

function NewProjectWizardReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NewProjectWizardConstants.INITIALIZE_NEW_PROJECT_WIZARD: {
      const { form } = action;

      return { ...state, form, saved: false };
    }
    case NewProjectWizardConstants.CHANGE_NEW_PROJECT_FORM: {
      const { form } = action;

      return { ...state, form };
    }
    case NewProjectWizardConstants.CREATE_NEW_PROJECT_START: {
      return { ...state, saving: true };
    }
    case NewProjectWizardConstants.CREATE_NEW_PROJECT_SUCCESS: {
      const { project } = action;

      return { ...state, saving: false, saved: true, project };
    }
    case NewProjectWizardConstants.CREATE_NEW_PROJECT_ERROR: {
      return { ...state, saving: false };
    }
    default:
      return state;
  }
}

export default NewProjectWizardReducer;
