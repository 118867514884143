import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileInterestRepository from 'repositories/admin/Profile/InterestsRepository';
import InterestsRepository from 'repositories/admin/InterestsRepository';
import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';

import { actions } from 'slices/admin/profileSlice';

function searchInterests(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return InterestsRepository.index(params).then(({ items }) => items);
  };
}

function createProfileInterest(profileId, params) {
  return (dispatch) => {
    dispatch(actions.createProfileInterestStart());

    return ProfileInterestRepository.create(profileId, { interest: params })
      .then(({ profileInterest, meta }) => {
        dispatch(
          actions.createProfileInterestSuccess({
            profileInterest,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta.interestsSortOrder,
          }),
        );

        addNotification('Interest was successfully added', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return profileInterest;
      })
      .catch((error) => {
        dispatch(actions.createProfileInterestError());

        throw error;
      });
  };
}

function destroyProfileInterest(profileId, interestId) {
  return (dispatch) => {
    dispatch(actions.destroyProfileInterestStart());
    return ProfileInterestRepository.destroy(profileId, interestId).then(({ meta }) => {
      dispatch(actions.destroyProfileInterestSuccess({ interestId, profileUpdatedAt: meta.profileUpdatedAt }));
      addNotification('Interest was successfully removed', 'normal')(dispatch);
      dispatch(initializeRequestForm(meta.request));
    });
  };
}

export const useAdminProfileDraftInterestActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      searchInterests,
      createProfileInterest,
      destroyProfileInterest,
    },
    dispatch,
  );
};
