import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';
import { isExceedMaxSize } from 'utils/fileUtils';
import example from './images/example.png';

import UploadCsvFileForm from 'containers/UserPanel/components/UploadCsvFileForm';

import SourcePresenter from 'presenters/Project/SourcePresenter';

import { WizardTitle, WizardNavigation } from 'components/v2/FramedWizard';

const CSV_HEADERS = [
  { title: 'Company Name', csvTitle: 'company-name', required: true },
  { title: 'Company *DUNS', csvTitle: 'company-duns', required: false },
];

export default class ProjectUploadOrganizationsCsvWizardStep extends Component {
  static propTypes = {
    source: SourcePresenter.shape().isRequired,
    updateSource: PropTypes.func.isRequired,
  };

  handleUpdateSource = (attributes) => {
    const { source, updateSource } = this.props;

    updateSource({ ...source, ...attributes });
  };

  handleFileChange = (file, delimiter) => {
    this.handleUpdateSource({ file, delimiter });
  };

  render() {
    const { source } = this.props;
    const { file } = source;

    return (
      <>
        <WizardTitle subtitle="Upload your list of target companies." />
        <UploadCsvFileForm
          csvHeaders={CSV_HEADERS}
          file={file}
          onFileChange={this.handleFileChange}
          templateImagePath={example}
          templateCsvPath="/static/csv/companies-list-example.csv"
        />
        <WizardNavigation isNextButtonDisabled={isBlank(file) || isExceedMaxSize(file)} />
      </>
    );
  }
}
