import React from 'react';
import PropTypes from 'prop-types';
import { splitAt } from 'ramda';
import { isPresent } from 'utils/HelperMethods';

import styles from './CustomizedTooltip.module.css';

const MAX_COMPANIES = 5;

const CustomizedTooltip = (props) => {
  const { active, payload, label } = props;
  const data = (isPresent(payload) && payload[0].payload) || {};
  const { companies = [], totalRemainingRequestsNumber } = data;

  const reducedCompanies = () => {
    const [head, tail] = splitAt(MAX_COMPANIES)(companies);
    const others = tail.reduce((acc, company) => {
      const prevRemainingRequestsNumber = acc.remainingRequestsNumber || 0;

      return {
        id: 0,
        name: 'Others',
        remainingRequestsNumber: prevRemainingRequestsNumber + company.remainingRequestsNumber,
      };
    }, {});

    return [...head, others];
  };

  if (!active) {
    return null;
  }

  return (
    <div className={styles.root}>
      {reducedCompanies().map((company) => (
        <div key={company.id + label} className={styles.item}>
          <div>{company.name}</div>
          <div className={styles.count}>{company.remainingRequestsNumber}</div>
        </div>
      ))}
      <hr className={styles.divider} />
      <div className={styles.total}>
        <div>Total:</div>
        <div className={styles.count}>{totalRemainingRequestsNumber}</div>
      </div>
    </div>
  );
};

CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.number,
};

export default CustomizedTooltip;
