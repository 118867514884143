import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import WordsCloud from './WordsCloud';
import * as actions from './WordsCloudActions';

const mapStateToProps = (state) => ({
  loading: state.WordsCloudReducer.loading,
  words: state.WordsCloudReducer.words,
  filters: state.WordsCloudReducer.filters,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WordsCloud);
