import React from 'react';
import { useParams } from 'react-router-polyfill';

import { useAlert } from 'hooks';
import { useAdminCompanyDepartmentLimitedAccess } from 'hooks/admin';

import Form from 'components/v2/Form';
import Loader from 'components/v2/Loader';

import EditForm from './components/EditForm';

import LimitedAccessForm from 'forms/company/department/LimitedAccessForm';

const SetupLimitedAccessTab = () => {
  const alert = useAlert();
  const { companyId, companyDepartmentId } = useParams();
  const { limitedAccess, limitedAccessLoadingStatus, updateLimitedAccess } = useAdminCompanyDepartmentLimitedAccess();

  const handleLimitedAccessUpdate = async (attributes) => {
    await updateLimitedAccess(companyId, companyDepartmentId, attributes);

    alert.successAction('Limited Access Settings was successfully updated');
  };

  const isLimitedAccessValid = limitedAccess?.companyDepartmentId !== Number(companyDepartmentId);

  if (limitedAccessLoadingStatus.isPending || isLimitedAccessValid) {
    return <Loader />;
  }

  return (
    <Form
      component={EditForm}
      enableReinitialize
      initialValues={LimitedAccessForm.defaultAttributes(limitedAccess)}
      onSubmit={handleLimitedAccessUpdate}
    />
  );
};

export default SetupLimitedAccessTab;
