import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';

export const WORLD_REGIONS = [
  {
    key: 'EMEA',
    title: 'Europe, Middle East, Africa and Russia',
  },
  {
    key: 'LATAM',
    title: 'Latin America',
  },
  {
    key: 'APAC',
    title: 'Asia Pacific',
  },
  {
    key: 'CAN_AND_USA',
    title: 'North America',
  },
];

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    alpha2: PropTypes.string,
    worldRegion: PropTypes.string,
    isGdpr: PropTypes.bool,
  },
  {
    humanWorldRegion(country) {
      if (!country.worldRegion) {
        return '';
      }

      return WORLD_REGIONS.find((r) => r.key === country.worldRegion).title;
    },
  },
);
