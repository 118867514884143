import { propOr } from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import styles from './Label.module.css';

const COLORS = {
  primary: styles.primaryColor,
};

const VARIANTS = {
  default: styles.default,
  outlined: styles.outlined,
};

const Label = (props) => {
  const { children, className, color, variant } = props;
  const colorClassName = propOr(COLORS.primary, color)(COLORS);
  const variantClassName = propOr(VARIANTS.default, variant)(VARIANTS);
  const labelClassName = clsx(colorClassName, variantClassName, className);

  return <span className={labelClassName}>{children}</span>;
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
};

Label.defaultProps = {
  className: null,
  color: 'primary',
  variant: 'default',
};

export default Label;
