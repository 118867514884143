import { pick } from 'ramda';

export default {
  defaultAttributes(alias = {}) {
    return { title: '', ...alias };
  },

  attributesToSubmit(alias) {
    const propsToSend = ['id', 'title'];

    return pick(propsToSend, alias);
  },
};
