import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  create(tagId, params) {
    const url = Routes.apiV1AdminTagPopulatePath(tagId);

    return FetchHelpers.post(url, params);
  },
};
