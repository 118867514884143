import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isBlank } from 'utils/HelperMethods';
import { logClickOnAlertsRemoveFromAlerts } from 'utils/amplitude';

import AnchorLink from 'components/grommet/AnchorLink';
import Select from 'components/Select';
import Icon from 'components/Icon';
import SavedSearch from 'components/SavedSearch';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/v2/Loader';
import Button from 'components/Button';

import GearsIcon from 'icons/GearsIcon';
import MenuIcon from 'icons/MenuIcon';
import SubscriptionConfigurationPopUp from '../SubscriptionConfigurationPopUp';

import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import { SAVED_SEARCH_SUBSCRIPTION_CONFIGURATION } from 'presenters/SubscriptionConfigurationPresenter';

import Routes from 'routes';

import styles from './SavedSearchSubscriptionsList.module.css';

const SORT_OPTIONS = [
  {
    label: 'Recently Updated',
    field: 'subject_of_SavedSearch_type_target_scope_updated_at',
    value: 'desc',
    preventDecamilization: true,
  },
  {
    label: 'Name A-Z',
    field: 'subject_of_SavedSearch_type_name',
    value: 'asc',
    preventDecamilization: true,
  },
  {
    label: 'Name Z-A',
    field: 'subject_of_SavedSearch_type_name',
    value: 'desc',
    preventDecamilization: true,
  },
];

export const INITIAL_FILTERS = { savedSearchSearchFieldCont: '', sortFields: [SORT_OPTIONS[0]] };

class SavedSearchSubscriptionsList extends Component {
  state = {
    isSubscriptionConfigurationPopUpOpen: false,
  };

  isAllSubscriptionsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleSearchFieldChange = ({ target: { value } }) => {
    const { filters, onFiltersChange } = this.props;

    onFiltersChange({ ...filters, savedSearchSearchFieldCont: value });
  };

  handleSortChange = (option) => {
    const { filters, onFiltersChange } = this.props;

    onFiltersChange({ ...filters, sortFields: [option] });
  };

  handleLoadMore = () => {
    const { isLoading, nextPage, loadSubscriptions, filters } = this.props;

    if (!isLoading && !this.isAllSubscriptionsLoaded()) {
      loadSubscriptions({ page: nextPage, ...filters });
    }
  };

  handleSubscriptionDelete = (subscription) => () => {
    const { filters, onFiltersChange, onSubscriptionDelete } = this.props;

    logClickOnAlertsRemoveFromAlerts();

    return onSubscriptionDelete(subscription.id).then(() => {
      onFiltersChange(filters);
    });
  };

  handleOpenSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: true });
  };

  handleCloseSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: false });
  };

  handleClick = (savedSearch) => {
    const { history } = this.props;
    const path = Routes.savedSearchPath(savedSearch.id);

    history.push(path);
  };

  render() {
    const {
      subscriptions,
      filters,
      totalCount,
      isLoading,
      subscriptionConfigurationLoading,
      updateSubscriptionConfiguration,
      loadSubscriptionConfiguration,
    } = this.props;

    const { isSubscriptionConfigurationPopUpOpen } = this.state;

    const { savedSearchSearchFieldCont, sortFields } = filters;
    const [sort] = sortFields;
    const isSubscriptionsEmpty = totalCount === 0 && savedSearchSearchFieldCont === '' && !isLoading;

    if (isSubscriptionsEmpty) {
      return (
        <div className={styles.info}>
          <div className={styles.noticeHeader}>You have not added any Saved Searches to your alerts yet</div>
          <div className={styles.notice}>
            To add a Saved Search to your alerts, click on a
            <MenuIcon className={styles.menuIcon} color="primary" /> icon on the
            <AnchorLink
              to={Routes.savedSearchesPath()}
              label="Saved Searches section"
              className={styles.linkSavedSearch}
            />
            of the search page and click "Add to Alerts"
          </div>
        </div>
      );
    }

    return (
      <div>
        {isSubscriptionConfigurationPopUpOpen && (
          <SubscriptionConfigurationPopUp
            loading={subscriptionConfigurationLoading}
            onSave={updateSubscriptionConfiguration}
            onClose={this.handleCloseSubscriptionConfiguration}
            onLoadConfiguration={loadSubscriptionConfiguration}
            kind={SAVED_SEARCH_SUBSCRIPTION_CONFIGURATION}
          />
        )}
        <div className={styles.filters}>
          <div className={styles.searchArea}>
            <input
              value={savedSearchSearchFieldCont}
              autoComplete="off"
              placeholder="Start typing Saved Search Name"
              className={styles.searchInput}
              onChange={this.handleSearchFieldChange}
            />
            <Icon name="search" style={{ width: 14, height: 14 }} />
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.configurationButton}
              label={<GearsIcon color="doveGray" />}
              onClick={this.handleOpenSubscriptionConfiguration}
            />
            <Select
              className={styles.filterBySelect}
              placeholder="Sort By"
              options={SORT_OPTIONS}
              value={sort}
              onChange={this.handleSortChange}
              noBottom
            />
          </div>
        </div>
        <div className={styles.savedSearches}>
          <InfiniteScroll
            loadMore={this.handleLoadMore}
            hasMore={!this.isAllSubscriptionsLoaded()}
            loader={<Loader />}
            useWindow={false}
            researchForScrollParent
          >
            {subscriptions.map((subscription) => (
              <SavedSearch
                key={SubscriptionPresenter.id(subscription)}
                className={styles.savedSearch}
                savedSearch={subscription.subject}
                onClick={this.handleClick}
                onDestroySubscription={this.handleSubscriptionDelete(subscription)}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

SavedSearchSubscriptionsList.propTypes = {
  subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()).isRequired,
  filters: PropTypes.shape().isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextPage: PropTypes.number,
  loadSubscriptions: PropTypes.func.isRequired,
  onSubscriptionDelete: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  subscriptionConfigurationLoading: PropTypes.bool,
  updateSubscriptionConfiguration: PropTypes.func,
  loadSubscriptionConfiguration: PropTypes.func,
  history: PropTypes.shape().isRequired,
};

export default SavedSearchSubscriptionsList;
