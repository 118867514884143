import RelationshipsMappingPageConstants from './RelationshipsMappingPageConstants';
import RelationshipsMappingRepository from 'repositories/Profile/RelationshipsMappingRepository';
import ProjectsRepository from 'repositories/CurrentUser/ProjectsRepository';

export function loadRelationshipsMapping(profileId) {
  return (dispatch) => {
    dispatch({ type: RelationshipsMappingPageConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_START });

    return RelationshipsMappingRepository.show(profileId).then(({ relationshipsMapping, meta }) => {
      dispatch({
        type: RelationshipsMappingPageConstants.LOAD_PROFILE_RELATIONSHIPS_MAPPING_SUCCESS,
        relationshipsMapping,
        information: meta.information,
      });

      return relationshipsMapping;
    });
  };
}

export function createProject(params) {
  return (dispatch) => {
    dispatch({ type: RelationshipsMappingPageConstants.CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_START });

    return ProjectsRepository.create(params)
      .then(({ project }) => {
        dispatch({
          type: RelationshipsMappingPageConstants.CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_SUCCESS,
          project,
        });

        return project;
      })
      .catch((errors) => {
        dispatch({ type: RelationshipsMappingPageConstants.CREATE_PROFILE_RELATIONSHIPS_MAPPING_PROJECT_ERROR });

        throw errors;
      });
  };
}
