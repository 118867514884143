import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, isPresent } from 'utils/HelperMethods';

import RelationshipsList from 'containers/UserPanel/components/RelationshipsList';

import ProfileSearchPresenter from 'presenters/ProfileSearchPresenter';
import RelationshipPresenter from 'presenters/RelationshipPresenter';

class ProfileRelationshipsList extends Component {
  static propTypes = {
    profileId: PropTypes.number.isRequired,
    initializeProfileRelationships: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadProfileRelationships: PropTypes.func.isRequired,
    nextPage: PropTypes.number,
    anotherProfileId: PropTypes.number.isRequired,
    relationships: PropTypes.arrayOf(RelationshipPresenter.shape()).isRequired,
    title: PropTypes.string,
  };

  title() {
    const { relationships, title } = this.props;
    const [relationship] = relationships;

    if (isPresent(title)) {
      return title;
    }

    if (isBlank(relationship)) {
      return '';
    }

    const [profile] = RelationshipPresenter.profiles(relationship);

    return ProfileSearchPresenter.fullName(profile);
  }

  handleInitializeRelationships = () => {
    const { initializeProfileRelationships, anotherProfileId } = this.props;

    initializeProfileRelationships(anotherProfileId);
  };

  handleLoadRelationships = (params) => {
    const { profileId, loadProfileRelationships, anotherProfileId } = this.props;

    return loadProfileRelationships(profileId, anotherProfileId, params);
  };

  render() {
    const { loading, nextPage, relationships } = this.props;

    return (
      <RelationshipsList
        initializeRelationships={this.handleInitializeRelationships}
        loading={loading}
        loadRelationships={this.handleLoadRelationships}
        nextPage={nextPage}
        relationships={relationships}
        title={this.title()}
      />
    );
  }
}

export default ProfileRelationshipsList;
