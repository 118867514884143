import React, { Component } from 'react';

import { Paragraph, Box, Anchor } from 'grommet';
import {
  Blog as BlogIcon,
  Linkedin as SocialLinkedin,
  Facebook as SocialFacebook,
  Twitter as SocialTwitter,
} from 'grommet-icons';

import ProfilePresenter from 'presenters/ProfilePresenter';

import styles from './ReviewContactInformation.module.css';

export default class ReviewContactInformation extends Component {
  static propTypes = {
    value: ProfilePresenter.shape(),
  };

  render() {
    const { value: profile } = this.props;
    return (
      <>
        <Box margin={{ vertical: 'medium' }} flex="grow" align="start">
          <Paragraph margin="none">
            {ProfilePresenter.address1WithFallback(profile)} <br /> {ProfilePresenter.address2WithFallback(profile)}
          </Paragraph>
          <Paragraph margin="none">{ProfilePresenter.cityWithStateAndZipCode(profile)}</Paragraph>
          <Paragraph margin="none">{ProfilePresenter.countryName(profile)}</Paragraph>
          <Paragraph margin="none">{ProfilePresenter.phoneWithFallback(profile)}</Paragraph>
          <Anchor
            animateIcon={false}
            href={`mailto:${ProfilePresenter.emailWithFallback(profile)}`}
            className={styles.email}
          >
            {ProfilePresenter.emailWithFallback(profile)}
          </Anchor>
        </Box>
        <Box direction="row" className={styles.socialLinks}>
          {ProfilePresenter.linkedinUrl(profile) && (
            <Anchor
              animateIcon={false}
              href={ProfilePresenter.linkedinUrl(profile)}
              icon={<SocialLinkedin color="plain" />}
              target="_blank"
            />
          )}
          {ProfilePresenter.facebookUrl(profile) && (
            <Anchor
              animateIcon={false}
              href={ProfilePresenter.facebookUrl(profile)}
              icon={<SocialFacebook color="plain" />}
              target="_blank"
            />
          )}
          {ProfilePresenter.twitterUrl(profile) && (
            <Anchor
              animateIcon={false}
              href={ProfilePresenter.twitterUrl(profile)}
              icon={<SocialTwitter color="plain" />}
              target="_blank"
            />
          )}
          {ProfilePresenter.blogUrl(profile) && (
            <Anchor href={ProfilePresenter.blogUrl(profile)} icon={<BlogIcon color="plain" />} target="_blank" />
          )}
        </Box>
      </>
    );
  }
}
