import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { take } from 'ramda';
import { isBlank, isPresent } from 'utils/HelperMethods';
import { logClickOnRelationshipMappingListShowAllRelationships } from 'utils/amplitude';

import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import Button from 'components/Button';
import Relationship from './components/Relationship';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner as Spinning } from 'grommet';
import ExportButton from 'containers/UserPanel/components/ExportButton';

import RelationshipPresenter from 'presenters/RelationshipPresenter';

import styles from './RelationshipsList.module.css';

class RelationshipsList extends Component {
  state = { showAllRelationships: false };

  componentDidMount() {
    const { initializeRelationships } = this.props;

    initializeRelationships();
  }

  handleLoadMore = () => {
    const { loading, loadRelationships, nextPage } = this.props;

    if (!loading && !this.isAllRelationshipsLoaded()) {
      loadRelationships({ page: nextPage });
    }
  };

  isAllRelationshipsLoaded = () => {
    const { askForMoreRelationshipsThan, nextPage, relationships } = this.props;
    const { showAllRelationships } = this.state;

    if (isBlank(nextPage)) {
      return true;
    }

    if (isPresent(askForMoreRelationshipsThan) && !showAllRelationships) {
      return askForMoreRelationshipsThan <= relationships.length;
    }

    return false;
  };

  isExportButtonVisible = () => {
    const { onExport } = this.props;

    return isPresent(onExport);
  };

  isShowAllRelationshipsButtonVisible = () => {
    const { askForMoreRelationshipsThan, relationships } = this.props;
    const { showAllRelationships } = this.state;

    if (isBlank(askForMoreRelationshipsThan)) {
      return false;
    }

    return !showAllRelationships && relationships.length > askForMoreRelationshipsThan;
  };

  relationships = () => {
    const { relationships, askForMoreRelationshipsThan } = this.props;
    const { showAllRelationships } = this.state;

    if (isPresent(askForMoreRelationshipsThan) && !showAllRelationships) {
      return take(askForMoreRelationshipsThan, relationships);
    }

    return relationships;
  };

  handleShowAllRelationships = () => {
    logClickOnRelationshipMappingListShowAllRelationships();
    this.setState({ showAllRelationships: true });
  };

  renderLoader() {
    return (
      <div key="loader" className={styles.loaderBox}>
        <Spinning />
      </div>
    );
  }

  render() {
    const { isExportDisabled, onExport, title, totalCount, showTotalCount } = this.props;
    const relationships = this.relationships();

    return (
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        {(showTotalCount || this.isExportButtonVisible()) && (
          <div className={styles.actions}>
            {showTotalCount && (
              <div className={styles.profiles}>
                Connections to Other Executives in Your Group: <span>{totalCount}</span>
              </div>
            )}

            {this.isExportButtonVisible() && (
              <BackgroundExportResultNotifier
                className={styles.export}
                component={ExportButton}
                onExport={onExport}
                onExportPropName="onClick"
                disabled={isExportDisabled}
                label="Share"
              />
            )}
          </div>
        )}
        <div className={styles.list}>
          <InfiniteScroll
            loadMore={this.handleLoadMore}
            hasMore={!this.isAllRelationshipsLoaded()}
            loader={this.renderLoader()}
            useWindow={false}
          >
            {relationships.map((relationship) => (
              <Relationship key={RelationshipPresenter.id(relationship)} relationship={relationship} />
            ))}
            {this.isShowAllRelationshipsButtonVisible() && (
              <div className={styles.allRelationshipsButtonContainer}>
                <Button label="Show all relationships" onClick={this.handleShowAllRelationships} />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

RelationshipsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  relationships: PropTypes.arrayOf(RelationshipPresenter.shape()).isRequired,
  initializeRelationships: PropTypes.func.isRequired,
  loadRelationships: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  askForMoreRelationshipsThan: PropTypes.number,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
  showTotalCount: PropTypes.bool,
  onExport: PropTypes.func,
  isExportDisabled: PropTypes.bool,
};

RelationshipsList.defaultProps = {
  askForMoreRelationshipsThan: null,
  showTotalCount: false,
  isExportDisabled: false,
};

export default RelationshipsList;
