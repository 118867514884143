import React, { Component } from 'react';

import { Anchor, Box } from 'grommet';
import Card from 'components/Card';
import Headline from 'components/grommet/Headline';
import ProfileList from 'containers/AdminPanel/containers/ProfileList';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import UserPresenter from 'presenters/UserPresenter';

import Routes from 'routes';

import styles from './OrganizationProfiles.module.css';

class OrganizationProfiles extends Component {
  static propTypes = {
    currentUser: UserPresenter.shape().isRequired,
    organization: OrganizationPresenter.shape().isRequired,
  };

  constructor(props) {
    super(props);

    const { organization } = this.props;
    this.state = {
      filters: { associatedWithOrganizationIdsInclude: organization.id, sortFields: [] },
    };
  }

  profileListColumns = [
    {
      name: 'id',
      field: 'id',
      width: '125',
    },
    {
      name: 'name',
      field: 'draftVersionLastName,draftVersionFirstName',
    },
    {
      name: 'title',
      field: 'draftVersionPrimaryCurrentWorkJobTitleStr',
    },
  ];

  handleSortChange = (sortFields) => this.setState({ ...this.state, filters: { ...this.state.filters, sortFields } });

  render() {
    const { currentUser } = this.props;
    const { filters } = this.state;

    return (
      <Card>
        <Box direction="row" justify="between" margin={{ bottom: 'small' }}>
          <Headline className={styles.header} strong size="small" margin="small">
            <Anchor
              label="Associated Profiles"
              href={Routes.adminProfilesPath({ q: filters })}
              target="_blank"
              className={styles.link}
            />
          </Headline>
        </Box>
        <div className={styles.tableContainer}>
          <ProfileList
            currentUser={currentUser}
            filters={filters}
            rowsDeletable={false}
            tableColumns={this.profileListColumns}
            onSortChange={this.handleSortChange}
          />
        </div>
      </Card>
    );
  }
}

export default OrganizationProfiles;
