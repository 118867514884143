import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AvailableProfilesList from './AvailableProfilesList';
import { actions } from 'containers/UserPanel/containers/Project/containers/AvailableProfilesContainer'; // eslint-disable-line import/named

const mapStateToProps = (state) => ({
  loading: state.AvailableProfilesContainerReducer.loading,
  profiles: state.AvailableProfilesContainerReducer.profiles,
  totalCount: state.AvailableProfilesContainerReducer.totalCount,
  currentPage: state.AvailableProfilesContainerReducer.currentPage,
  saving: state.AvailableProfilesContainerReducer.saving,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AvailableProfilesList);
