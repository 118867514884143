import { isBlank, isPresent } from 'utils/HelperMethods';

import ClipPath, { clipPathId } from './ClipPath';
import Image from './Image';
import Placeholder from './Placeholder';

function mount(nodeGroup, props) {
  const nodeGroupWithPhotoUrl = nodeGroup.filter((d) => isPresent(d.photoUrl));
  ClipPath.mount(nodeGroupWithPhotoUrl, props);
  Image.mount(nodeGroupWithPhotoUrl, props, clipPathId);

  const nodeGroupWithoutPhotoUrl = nodeGroup.filter((d) => isBlank(d.photoUrl));
  Placeholder.mount(nodeGroupWithoutPhotoUrl, props);
}

export default { mount };
