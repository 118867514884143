import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { FormTrash as TrashIcon } from 'grommet-icons';
import { Anchor } from 'grommet';

import config from 'config';

import { isRequestEntityTooLargeError } from 'utils/ErrorsHelper';
import withConfirmation from 'hoc/withConfirmation';

import Button from 'components/Button';
import Modal from 'components/Modal';
import UploadForm from 'components/AvatarUploadForm';
import Icon from 'components/Icon';

import styles from './Logo.module.css';

class LogoComponent extends Component {
  static propTypes = {
    editable: PropTypes.bool.isRequired,
    logoUrl: PropTypes.string,
    logoUpdatedAt: PropTypes.string,
    alt: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
  };

  state = { edit: false, errors: [] };

  handleEdit = () => {
    const { editable } = this.props;

    this.setState({ edit: editable });
  };

  handleCancel = () => this.setState({ edit: false, errors: [] });

  handleErrors = (response) => {
    if (isRequestEntityTooLargeError(response)) {
      const errors = [`File size should be less than ${config.attachments.maxFileSize}`];
      this.setState({ errors });
    }
  };

  handleSubmit = (file, pixelCrop) =>
    this.props.onUpdate(file, pixelCrop).then(this.handleCancel).catch(this.handleErrors);

  handleRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();

    return this.props.onDelete();
  };

  render() {
    const { editable, logoUrl, logoUpdatedAt, alt, saving } = this.props;
    const { edit, errors } = this.state;

    return (
      <>
        <div className={styles.avatar}>
          <Button plain disabled={!editable} onClick={this.handleEdit} className={styles.root}>
            {isNil(logoUrl) ? (
              <Icon name="organizationPlaceholder" />
            ) : (
              <div className={styles.container}>
                <img src={logoUrl} className={styles.logo} alt={alt} />
              </div>
            )}
          </Button>
          {!isNil(logoUrl) && editable && (
            <Anchor
              disabled={saving}
              onClick={this.handleRemove}
              icon={<TrashIcon color="dark-1" />}
              className={styles.delete}
            />
          )}
        </div>
        {edit && (
          <Modal header="Upload a logo" size="medium" onClose={this.handleCancel}>
            <UploadForm updatedAt={logoUpdatedAt} onSubmit={this.handleSubmit} errors={errors} saving={saving} />
          </Modal>
        )}
      </>
    );
  }
}

const Logo = withConfirmation(LogoComponent, {
  onDelete: 'Are you sure want to delete logo?',
});

export default Logo;
