import React, { Component } from 'react';
import Routes from 'routes';
import { Redirect } from 'react-router-dom';

import AppRoute from 'components/AppRoute';
import AppSwitch from 'components/AppSwitch';
import RoutedTabs, { RoutedTab } from 'components/v2/RoutedTabs';
import PageHeader from 'components/PageHeader';

import Production from './containers/Production';
import DatabaseAnalytics from './containers/DatabaseAnalytics';
import Productivity from './containers/Productivity';
import TeamProductivity from './containers/TeamProductivity';
import CustomerUsageDashboard from './containers/CustomerUsageDashboard';
import RemainingRequestNumbersStatisticTab from './components/RemainingRequestNumbersStatisticTab';

import CurrentUserPresenter from 'presenters/CurrentUserPresenter';
import EditorialDashboardPolicy from 'policies/EditorialDashboardPolicy';

import styles from './EditorialDashboardPage.module.css';

export default class EditorialDashboardPage extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape(),
  };

  render() {
    const { currentUser } = this.props;

    return (
      <div className={styles.root}>
        <PageHeader title="Admin Dashboard" />

        <div className={styles.tabs}>
          <RoutedTabs>
            <RoutedTab path={Routes.adminDashboardProduction()} className={styles.tab}>
              Production
            </RoutedTab>
            <RoutedTab path={Routes.adminDashboardProductivity()} className={styles.tab}>
              Productivity
            </RoutedTab>
            {EditorialDashboardPolicy.canSeeTeamProductivity(currentUser) && (
              <RoutedTab path={Routes.adminDashboardTeamProductivity()} className={styles.tab}>
                Team Productivity
              </RoutedTab>
            )}
            <RoutedTab path={Routes.adminDashboardDatabaseAnalytics()} className={styles.tab}>
              Database Analytics
            </RoutedTab>
            <RoutedTab path={Routes.adminDashboardCustomerUsageDashboard()} className={styles.tab}>
              Customer Usage Dashboard
            </RoutedTab>
            <RoutedTab path={Routes.adminDashboardExpiringCustoms()} className={styles.tab}>
              Expiring Customs
            </RoutedTab>
          </RoutedTabs>

          <AppSwitch>
            <AppRoute exact path={Routes.adminDashboardProduction()} component={Production} />
            <AppRoute exact path={Routes.adminDashboardProductivity()} component={Productivity} />
            <AppRoute exact path={Routes.adminDashboardTeamProductivity()} component={TeamProductivity} />
            <AppRoute exact path={Routes.adminDashboardDatabaseAnalytics()} component={DatabaseAnalytics} />
            <AppRoute exact path={Routes.adminDashboardCustomerUsageDashboard()} component={CustomerUsageDashboard} />
            <AppRoute
              exact
              path={Routes.adminDashboardExpiringCustoms()}
              component={RemainingRequestNumbersStatisticTab}
            />
            <Redirect to={Routes.adminDashboardProduction()} />
          </AppSwitch>
        </div>
      </div>
    );
  }
}
