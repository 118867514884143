import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1CurrentUserCompanyDashboardRequestsPath(params);

    return FetchHelpers.get(url);
  },
};
