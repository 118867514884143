import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Routes from 'routes';
import {
  logClickOnBusinessPriorityAnalysisShare,
  logClickOnBusinessPriorityAnalysisTab,
  logClickOnBusinessPriorityAnalysisShareDetails,
} from 'utils/amplitude';

import BackgroundExportResultNotifier from 'components/BackgroundExportResultNotifier';
import Tabs, { Tab } from 'components/Tabs';
import TopTags from './containers/TopTags';
import Roles from './containers/Roles';
import ExportButton from 'containers/UserPanel/components/ExportButton';
import TopJobFunctions from './containers/TopJobFunctions';
import Header from 'containers/UserPanel/containers/Project/components/Header';

import ProjectPolicy from 'policies/ProjectPolicy';
import ProjectPresenter, {
  PROJECT_TOP_TAGS_PDF_EXPORT,
  PROJECT_TOP_ROLES_PDF_EXPORT,
  PROJECT_TOP_JOB_FUNCTIONS_PDF_EXPORT,
} from 'presenters/ProjectPresenter';
import CurrentUserPresenter from 'presenters/CurrentUserPresenter';

export default class BusinessPriorityAnalysis extends Component {
  static propTypes = {
    currentUser: CurrentUserPresenter.shape().isRequired,
    project: ProjectPresenter.shape().isRequired,
    onProjectDataExport: PropTypes.func.isRequired,
  };

  state = { activeTabIndex: 0 };

  breadcrumbs() {
    const { project } = this.props;

    return [
      { name: 'Projects', to: Routes.projectsPath() },
      { name: ProjectPresenter.name(project), to: Routes.projectPath(ProjectPresenter.id(project)) },
      { name: 'Business Priority Analysis' },
    ];
  }

  handleTabChange = (activeTabIndex) => this.setState({ ...this.state, activeTabIndex });

  handleExport = () => {
    const { onProjectDataExport } = this.props;
    const { activeTabIndex } = this.state;
    const exportKinds = [
      PROJECT_TOP_TAGS_PDF_EXPORT,
      PROJECT_TOP_ROLES_PDF_EXPORT,
      PROJECT_TOP_JOB_FUNCTIONS_PDF_EXPORT,
    ];
    const type = exportKinds[activeTabIndex];

    logClickOnBusinessPriorityAnalysisShare();

    return onProjectDataExport({ type });
  };

  handleLogClickOnTab = (name) => () => logClickOnBusinessPriorityAnalysisTab(name);

  handleProjectDataExportDetails = (params) => {
    const { onProjectDataExport } = this.props;

    logClickOnBusinessPriorityAnalysisShareDetails();

    return onProjectDataExport(params);
  };

  render() {
    const { currentUser, project } = this.props;
    const { activeTabIndex } = this.state;

    return (
      <>
        <Header
          currentUser={currentUser}
          title={`${ProjectPresenter.name(project)}: Business Priority Analysis`}
          breadcrumbs={this.breadcrumbs()}
          project={project}
        >
          <BackgroundExportResultNotifier
            component={ExportButton}
            onExport={this.handleExport}
            onExportPropName="onClick"
            disabled={!ProjectPolicy.canExport(project)}
            label="Share"
          />
        </Header>
        <Tabs activeIndex={activeTabIndex} responsive={false} justify="start" onActive={this.handleTabChange}>
          <Tab title="By Initiatives" onClick={this.handleLogClickOnTab('By Initiatives')}>
            <TopTags project={project} onExport={this.handleProjectDataExportDetails} />
          </Tab>
          <Tab title="By Role" onClick={this.handleLogClickOnTab('By Role')}>
            <Roles project={project} onExport={this.handleProjectDataExportDetails} />
          </Tab>
          <Tab title="By Job Functions" onClick={this.handleLogClickOnTab('By Job Functions')}>
            <TopJobFunctions project={project} onExport={this.handleProjectDataExportDetails} />
          </Tab>
        </Tabs>
      </>
    );
  }
}
