import { clientTargetingSearchRestHooks } from 'slices/client/targetingSearchSlice';

import TargetingSearchRepository from 'repositories/CurrentUser/TargetingSearchRepository';

import { decamelizeString } from 'utils/keysConverter';
import { useFetchStatus } from 'utils/fetchStatusUtils';

const autocomplete = (filter, value, targetItem) => {
  const params = { autocomplete: { [decamelizeString(filter)]: value }, targetItems: [targetItem] };

  return TargetingSearchRepository.autocomplete(params);
};

const useTargetingSearch = () => {
  const { targetingSearches, loadTargetingSearches } = clientTargetingSearchRestHooks.use();

  return {
    targetingSearches: targetingSearches.items,
    targetingSearchesLoadingStatus: useFetchStatus(targetingSearches.loadingStatus),
    targetingSearchesMeta: targetingSearches.meta,
    loadTargetingSearches,
    autocomplete,
  };
};

export default useTargetingSearch;
