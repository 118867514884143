import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import Icon from 'components/Icon';
import GearsIcon from 'icons/GearsIcon';
import Select from 'components/Select';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/v2/Loader';
import Button from 'components/Button';
import SubscriptionConfigurationPopUp from '../SubscriptionConfigurationPopUp';

import OrganizationSubscription from './components/OrganizationSubscription';

import SubscriptionPresenter from 'presenters/SubscriptionPresenter';
import { ORGANIZATION_SUBSCRIPTION_CONFIGURATION } from 'presenters/SubscriptionConfigurationPresenter';

import styles from './OrganizationSubscriptionsList.module.css';

const SORT_OPTIONS = [
  {
    label: 'Recently Updated',
    field: 'updated_at',
    value: 'asc',
  },
  {
    label: 'Companies A-Z',
    field: 'subject_of_Organization_type_published_version_name',
    value: 'asc',
    preventDecamilization: true,
  },
  {
    label: 'Companies Z-A',
    field: 'subject_of_Organization_type_published_version_name',
    value: 'desc',
    preventDecamilization: true,
  },
];

export const INITIAL_FILTERS = { organizationSearchFieldCont: '', sortFields: [SORT_OPTIONS[0]] };

class OrganizationSubscriptionsList extends Component {
  state = {
    isSubscriptionConfigurationPopUpOpen: false,
  };

  isAllOrganizationSubscriptionsLoaded = () => {
    const { nextPage } = this.props;

    return isBlank(nextPage);
  };

  handleLoadMore = () => {
    const { isLoading, nextPage, loadSubscriptions, filters } = this.props;

    if (!isLoading && !this.isAllOrganizationSubscriptionsLoaded()) {
      loadSubscriptions({ page: nextPage, ...filters });
    }
  };

  handleSortChange = (option) => {
    const { filters, onFilterChange } = this.props;

    onFilterChange({ ...filters, sortFields: [option] });
  };

  handleSearchFieldChange = ({ target: { value } }) => {
    const { filters, onFilterChange } = this.props;

    onFilterChange({ ...filters, organizationSearchFieldCont: value });
  };

  handleOpenSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: true });
  };

  handleCloseSubscriptionConfiguration = () => {
    this.setState({ isSubscriptionConfigurationPopUpOpen: false });
  };

  render() {
    const {
      subscriptions,
      onSubscriptionDelete,
      onSubscriptionUndoDelete,
      filters,
      totalCount,
      isLoading,
      subscriptionConfigurationLoading,
      updateSubscriptionConfiguration,
      loadSubscriptionConfiguration,
    } = this.props;
    const { organizationSearchFieldCont, sortFields } = filters;
    const [sort] = sortFields;
    const isSubscriptionsEmpty = totalCount === 0 && subscriptions.length === 0 && organizationSearchFieldCont === '';
    const { isSubscriptionConfigurationPopUpOpen } = this.state;

    if (isSubscriptionsEmpty && !isLoading) {
      return (
        <div className={styles.info}>
          <div className={styles.noticeHeader}>You have not set up any companies subscriptions yet</div>
          <div className={styles.notice}>
            You may subscribe to alerts on specific companies on their executives pages. Look for the blue "Get Alert"
            button
          </div>
        </div>
      );
    }

    return (
      <div>
        {isSubscriptionConfigurationPopUpOpen && (
          <SubscriptionConfigurationPopUp
            loading={subscriptionConfigurationLoading}
            onSave={updateSubscriptionConfiguration}
            onClose={this.handleCloseSubscriptionConfiguration}
            kind={ORGANIZATION_SUBSCRIPTION_CONFIGURATION}
            onLoadConfiguration={loadSubscriptionConfiguration}
          />
        )}
        <div className={styles.filters}>
          <div className={styles.searchArea}>
            <input
              value={organizationSearchFieldCont}
              autoComplete="off"
              placeholder="Start typing Name, Company or Keyword"
              onChange={this.handleSearchFieldChange}
              className={styles.searchInput}
            />
            <Icon name="search" style={{ width: 14, height: 14 }} />
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.configurationButton}
              label={<GearsIcon color="doveGray" />}
              onClick={this.handleOpenSubscriptionConfiguration}
            />
            <Select
              className={styles.filterBySelect}
              placeholder="Sort By"
              options={SORT_OPTIONS}
              value={sort}
              onChange={this.handleSortChange}
              noBottom
            />
          </div>
        </div>
        <div>
          <InfiniteScroll
            loadMore={this.handleLoadMore}
            hasMore={!this.isAllOrganizationSubscriptionsLoaded()}
            loader={<Loader />}
            useWindow={false}
            researchForScrollParent
          >
            {subscriptions.map((subscription) => (
              <OrganizationSubscription
                key={SubscriptionPresenter.id(subscription)}
                subscription={subscription}
                onDelete={onSubscriptionDelete}
                onUndoDelete={onSubscriptionUndoDelete}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

OrganizationSubscriptionsList.propTypes = {
  subscriptions: PropTypes.arrayOf(SubscriptionPresenter.shape()).isRequired,
  onSubscriptionDelete: PropTypes.func.isRequired,
  onSubscriptionUndoDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
  onFilterChange: PropTypes.func.isRequired,
  loadSubscriptions: PropTypes.func.isRequired,
  nextPage: PropTypes.number,
  totalCount: PropTypes.number,
  subscriptionConfigurationLoading: PropTypes.bool,
  updateSubscriptionConfiguration: PropTypes.func,
  loadSubscriptionConfiguration: PropTypes.func,
};

export default OrganizationSubscriptionsList;
