/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import downloadExportsRepository from 'repositories/downloadExportsRepository';

const initialState = {
  isLoading: false,
  exportJobUrl: null,
};

const exportJobUrlSlice = createSlice({
  name: 'exportJobUrl',
  initialState,
  reducers: {
    loadExportJobUrlStart(state) {
      state.isLoading = true;
    },
    loadExportJobUrlSuccess(state, { payload }) {
      state.exportJobUrl = payload;
      state.isLoading = false;
    },
    loadExportJobUrlError(state) {
      state.isLoading = false;
    },
  },
});

const { actions } = exportJobUrlSlice;

export function loadExportJobUrl(token) {
  return (dispatch) => {
    dispatch(actions.loadExportJobUrlStart());

    return downloadExportsRepository
      .show(token)
      .then(({ exportJob: { fileUrl } }) => {
        dispatch(actions.loadExportJobUrlSuccess(fileUrl));

        return fileUrl;
      })
      .catch((errors) => {
        dispatch(actions.loadExportJobUrlError());
        throw errors;
      });
  };
}

export const useExportJobUrlActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      loadExportJobUrl,
    },
    dispatch,
  );
};

export default exportJobUrlSlice.reducer;
