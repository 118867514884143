import React from 'react';
import PropTypes from 'prop-types';
import { isBlank } from 'utils/HelperMethods';

import FactPresenter from 'presenters/Relationship/FactPresenter';
import Typography from 'components/v2/Typography';

import Context from './components/Context';

import styles from './Fact.module.css';

const Fact = (props) => {
  const { fact, index, facts } = props;

  const isInderect = FactPresenter.isInderect(fact);
  const [firstProfileContext, secondProfileContext] = FactPresenter.contexts(fact);

  const shouldRenderFactTitle = () => {
    if (!FactPresenter.isSimpleFact(fact)) {
      return true;
    }

    const humanType = FactPresenter.humanType(fact);

    if (isBlank(humanType)) {
      return false;
    }

    const previousFact = facts[index - 1];

    return index === 0 || FactPresenter.type(previousFact) !== FactPresenter.type(fact);
  };

  return (
    <div key={FactPresenter.id(fact)}>
      {shouldRenderFactTitle() && (
        <Typography className={styles.title} color="mineShaft" variant="h3">
          {FactPresenter.isSimpleFact(fact) ? FactPresenter.humanType(fact) : FactPresenter.title(fact)}
        </Typography>
      )}
      <div className={styles.row}>
        {FactPresenter.isSimpleFact(fact) && <div className={styles.column}>{FactPresenter.title(fact)}</div>}
        {!FactPresenter.isSimpleFact(fact) && (
          <>
            <div className={styles.column}>
              {firstProfileContext.map((context, indexContext) => (
                <Context key={indexContext} context={context} index={indexContext} highlightTitle={isInderect} />
              ))}
            </div>

            <div className={styles.column}>
              {secondProfileContext.map((context, indexContext) => (
                <Context key={indexContext} context={context} index={indexContext} highlightTitle={isInderect} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Fact.propTypes = {
  fact: FactPresenter.shape(),
  facts: PropTypes.arrayOf(FactPresenter.shape()),
  index: PropTypes.number,
};

export default Fact;
