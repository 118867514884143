import TopRelationshipsConstants from './TopRelationshipsConstants';
import RelationshipsMappingRepository from 'repositories/CurrentUser/Project/RelationshipsMappingRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';

export function loadTopRelationships(projectId, profilesFilters = {}) {
  return (dispatch) => {
    dispatch({ type: TopRelationshipsConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_START });

    const params = buildFilterParams(profilesFilters, 'profilesQ');

    return RelationshipsMappingRepository.show(projectId, params).then(({ relationshipsMapping }) => {
      dispatch({
        type: TopRelationshipsConstants.LOAD_PROJECT_RELATIONSHIPS_MAPPING_SUCCESS,
        relationshipsMapping,
      });

      return relationshipsMapping;
    });
  };
}
