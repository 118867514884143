import { propOr } from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import styles from './Button.module.css';

const VARIANTS = {
  default: styles.default,
  icon: styles.icon,
  raw: styles.raw,
  outlined: styles.outlined,
  outlinedIcon: styles.outlinedIcon,
  link: styles.link,
};

const COLORS = {
  primary: styles.primaryColor,
  secondary: styles.secondaryColor,
  orange: styles.orangeColor,
};

const Button = (props) => {
  const { className, color, component: Component, variant, ...rest } = props;
  const variantClassName = propOr(VARIANTS.default, variant)(VARIANTS);
  const colorClassName = propOr(COLORS.primary, color)(COLORS);
  const buttonClassName = clsx(variantClassName, colorClassName, className);

  return <Component {...rest} className={buttonClassName} />;
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.elementType,
  variant: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  color: 'primary',
  component: (props) => <button type="button" {...props} />,
  variant: 'default',
};

export default Button;
