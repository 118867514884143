import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { isPresent } from 'utils/HelperMethods';

import Button from 'components/Button';
import { FormClose as FormCloseIcon, FormCheckmark as FormCheckMarkIcon } from 'grommet-icons';
import { Spinner as Spinning } from 'grommet';

import styles from './InputField.module.css';

class InputField extends Component {
  static propTypes = {
    isSaving: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    title: PropTypes.string,
  };

  constructor(...props) {
    super(...props);

    const { title } = this.props;
    this.state = { form: { title }, errors: null };
  }

  handleTitleChange = ({ target: { value } }) =>
    this.setState({ ...this.state, form: { ...this.state.form, title: value } });

  handleSetErrors = ({ errors }) => this.setState({ ...this.state, errors });

  handleSubmit = () => {
    const { onCancel, onUpdate } = this.props;
    const { form } = this.state;

    onUpdate(form.title).then(onCancel).catch(this.handleSetErrors);
  };

  renderEditControls() {
    const { isSaving, onCancel } = this.props;

    if (isSaving) {
      return (
        <div className={styles.icon}>
          <Spinning />
        </div>
      );
    }

    return (
      <>
        <Button className={styles.icon} onClick={this.handleSubmit}>
          <FormCheckMarkIcon />
        </Button>
        <Button className={styles.icon} onClick={onCancel}>
          <FormCloseIcon />
        </Button>
      </>
    );
  }

  render() {
    const { isSaving } = this.props;
    const { errors, form } = this.state;
    const className = cn(styles.input, { [styles.errors]: isPresent(errors) });

    return (
      <>
        <input
          type="text"
          className={className}
          disabled={isSaving}
          value={form.title}
          onClick={this.handleStopPropagation}
          onChange={this.handleTitleChange}
          size={form.title.length}
        />
        {this.renderEditControls()}
      </>
    );
  }
}

export default InputField;
