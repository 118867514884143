import ProjectConstants from './ProjectConstants';
import ProjectsRepository from 'repositories/CurrentUser/ProjectsRepository';
import SourcesRepository from 'repositories/CurrentUser/Project/SourcesRepository';
import GuideRepository from 'repositories/CurrentUser/Project/GuideRepository';

export function loadProject(id) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.LOAD_PROJECT_START });

    return ProjectsRepository.show(id).then(({ project }) => {
      dispatch({ type: ProjectConstants.LOAD_PROJECT_SUCCESS, project });
    });
  };
}

export function reloadProject(id) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.RELOAD_PROJECT_START });

    return ProjectsRepository.show(id).then(({ project }) => {
      dispatch({ type: ProjectConstants.RELOAD_PROJECT_SUCCESS, project });
    });
  };
}

export function updateProject(id, params) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.UPDATE_PROJECT_START });

    return ProjectsRepository.update(id, params)
      .then(({ project }) => {
        dispatch({ type: ProjectConstants.UPDATE_PROJECT_SUCCESS, project });

        return project;
      })
      .catch((error) => {
        dispatch({ type: ProjectConstants.UPDATE_PROJECT_ERROR });

        throw error;
      });
  };
}

export function destroyProject(id) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.DESTROY_PROJECT_START });

    return ProjectsRepository.destroy(id)
      .then(() => {
        dispatch({ type: ProjectConstants.DESTROY_PROJECT_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: ProjectConstants.DESTROY_PROJECT_ERROR });

        throw error;
      });
  };
}

export function cloneProject(id, params) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.CLONE_PROJECT_START });

    return ProjectsRepository.clone(id, params)
      .then(({ project }) => {
        dispatch({ type: ProjectConstants.CLONE_PROJECT_SUCCESS, project });

        return project;
      })
      .catch((error) => {
        dispatch({ type: ProjectConstants.CLONE_PROJECT_ERROR });

        throw error;
      });
  };
}

export function createProjectSource(projectId, params) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.CREATE_PROJECT_SOURCE_START });

    return SourcesRepository.create(projectId, params)
      .then(({ source }) => {
        dispatch({ type: ProjectConstants.CREATE_PROJECT_SOURCE_SUCCESS, source });
        dispatch(reloadProject(projectId));

        return source;
      })
      .catch((error) => {
        dispatch({ type: ProjectConstants.CREATE_PROJECT_SOURCE_ERROR });

        throw error;
      });
  };
}

export function destroyProjectSource(projectId, sourceId) {
  return (dispatch) => {
    dispatch({ type: ProjectConstants.DESTROY_PROJECT_SOURCE_START });

    return SourcesRepository.destroy(projectId, sourceId)
      .then(() => {
        dispatch({ type: ProjectConstants.DESTROY_PROJECT_SOURCE_SUCCESS, sourceId });
        dispatch(reloadProject(projectId));

        return null;
      })
      .catch((error) => {
        dispatch({ type: ProjectConstants.DESTROY_PROJECT_SOURCE_ERROR });

        throw error;
      });
  };
}

export function closeGuide(projectId) {
  return () => GuideRepository.destroy(projectId);
}
