import React from 'react';
import PropTypes from 'prop-types';
import { isPresent, isBlank } from 'utils/HelperMethods';

import CorrectIcon from 'icons/CorrectIcon';
import WrongIcon from 'icons/WrongIcon';

import styles from './CsvHeader.module.css';

const ERROR_TYPE = 'header';

const getHeaderErrors = (errors) => {
  const fileErrors = errors?.errors?.file;

  if (isBlank(fileErrors)) {
    return [];
  }

  return fileErrors
    .map((err) => {
      const [type, field, message] = err.split('.');
      return { type, field, message };
    })
    .filter((err) => err.type === ERROR_TYPE);
};

const CsvHeader = (props) => {
  const { title, csvTitle, required, errors, file, isValidating } = props;

  const headerErrors = getHeaderErrors(errors);
  const hasHeaderError = headerErrors.filter((err) => err.field === csvTitle).length > 0;
  const isCorrect = isBlank(errors) || (isPresent(headerErrors) && !hasHeaderError);

  return (
    <div key={title} className={styles.csvTitle}>
      {title}
      {required ? ` (Required)` : ` (Optional)`}
      <div className={styles.checkIcon}>
        {isPresent(file) &&
          !isValidating &&
          (isCorrect ? <CorrectIcon className={styles.correctIcon} /> : <WrongIcon className={styles.wrongIcon} />)}
      </div>
    </div>
  );
};

CsvHeader.propTypes = {
  title: PropTypes.string.isRequired,
  csvTitle: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errors: PropTypes.shape(),
  file: PropTypes.shape(),
  isValidating: PropTypes.bool,
};

export default CsvHeader;
