import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileWorksRepository from 'repositories/admin/Profile/WorksRepository';
import StandardizedJobTitlesRepository from 'repositories/admin/StandardizedJobTitlesRepository';

import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';
import { buildFilterParams } from 'utils/FilterParamsBuilder';

import { actions } from 'slices/admin/profileSlice';

function createWork(profileId, params) {
  return (dispatch) => {
    dispatch(actions.createWorkStart());

    return ProfileWorksRepository.create(profileId, params)
      .then(({ work, meta }) => {
        dispatch(
          actions.createWorkSuccess({
            work,
            order: meta.worksSortOrder,
            profileUpdatedAt: meta.profileUpdatedAt,
            draftVersion: meta.draftVersion,
          }),
        );

        addNotification('Employment history was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return work;
      })
      .catch((error) => {
        dispatch(actions.createWorkError());

        throw error;
      });
  };
}

function updateWork(profileId, workId, params) {
  return (dispatch) => {
    dispatch(actions.updateWorkStart());

    return ProfileWorksRepository.update(profileId, workId, params)
      .then(({ work, meta }) => {
        dispatch(
          actions.updateWorkSuccess({
            work,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta.worksSortOrder,
            personalInformationDigest: meta.personalInformationDigest,
            draftVersion: meta.draftVersion,
          }),
        );

        addNotification('Employment history was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return work;
      })
      .catch((error) => {
        dispatch(actions.updateWorkError());

        throw error;
      });
  };
}

function destroyWork(profileId, workId) {
  return (dispatch) =>
    ProfileWorksRepository.destroy(profileId, workId).then(({ meta }) => {
      dispatch(
        actions.destroyWorkSuccess({
          workId,
          profileUpdatedAt: meta.profileUpdatedAt,
          draftVersion: meta.draftVersion,
        }),
      );

      addNotification('Employment history was successfully updated', 'normal')(dispatch);
      dispatch(initializeRequestForm(meta.request));
    });
}

function searchStandardizedJobTitle(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, per: 30, ...buildFilterParams(filters) };

    return StandardizedJobTitlesRepository.index(params).then(({ items }) => items);
  };
}

export const useAdminProfileDraftWorkActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      createWork,
      updateWork,
      destroyWork,
      searchStandardizedJobTitle,
    },
    dispatch,
  );
};
