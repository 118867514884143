import UserPresenter from 'presenters/UserPresenter';
import UserPolicy from './UserPolicy';

export default {
  canSeeList(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },
  canSeeElement(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },
  canDestroyElement(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user);
  },
  canAddElement(user) {
    return UserPolicy.isHaveAdminAccess(user);
  },
  canExportList(user) {
    return UserPresenter.isAdmin(user) || UserPresenter.isEditor(user) || UserPresenter.isResearchManager(user);
  },
};
