/* eslint-disable no-param-reassign */
import { concat } from 'ramda';

import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

import { extractResourceData } from './utils';

export default function loadMoreResourcesSlice(options) {
  const { pluralResource, repository } = options;

  return {
    initialState: {
      [pluralResource]: {
        moreLoadingStatus: FETCH_STATUSES.idle,
        meta: {},
        error: null,
      },
    },
    reducers: {
      loadMoreResourcesStart(state) {
        state[pluralResource].moreLoadingStatus = FETCH_STATUSES.pending;
        state[pluralResource].error = null;
      },
      loadMoreResourcesSuccess(state, { payload }) {
        const items = extractResourceData(payload);
        state[pluralResource].moreLoadingStatus = FETCH_STATUSES.fulfilled;
        state[pluralResource].items = concat(state[pluralResource].items, items);
        state[pluralResource].meta = payload?.meta;
      },
      loadMoreResourcesFail(state, { payload: { error } }) {
        state[pluralResource].moreLoadingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
    },

    actionCreators(restDispatch) {
      return {
        loadMoreResources: async (...params) => {
          restDispatch('loadMoreResourcesStart');

          try {
            const data = await repository.index(...params);
            restDispatch('loadMoreResourcesSuccess', data);
          } catch (error) {
            restDispatch('loadMoreResourcesFail', { error });
            throw error;
          }
        },
      };
    },
  };
}
