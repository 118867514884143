import PropTypes from 'prop-types';
import Presenter from '../utils/PropTypesPresenter';
import { formattedYear, timeInterval } from 'utils/DateTime';
import { isPresent } from 'utils/HelperMethods';

import OrganizationPresenter from './OrganizationPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    degree: PropTypes.string,
    organization: OrganizationPresenter.shape(),
    from: PropTypes.string,
    to: PropTypes.string,
    isMasked: PropTypes.bool,
    isRecentlyUpdated: PropTypes.bool,
    skinnyDegree: PropTypes.string,
  },
  {
    fromYear(work) {
      const from = this.from(work);

      return formattedYear(from);
    },

    toYear(work) {
      const to = this.to(work);

      return formattedYear(to);
    },

    organizationName(board) {
      const organization = this.organization(board);

      return OrganizationPresenter.name(organization);
    },

    title(work) {
      const organization = this.organization(work);
      const degree = this.degree(work);

      if (isPresent(degree)) {
        return `${degree}, ${OrganizationPresenter.name(organization)}`;
      }

      return OrganizationPresenter.name(organization);
    },

    timeInterval(board) {
      const from = this.fromYear(board);
      const to = this.toYear(board);

      return timeInterval(from, to);
    },
  },
);
