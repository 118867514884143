import React, { useEffect } from 'react';
import { useParams } from 'react-router-polyfill';

import { useAdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfiles } from 'hooks/admin';

import Typography from 'components/v2/Typography';

import ProfileList from './components/ProfileList';

import styles from './ExplicitlyGrantedProfilesTab.module.css';

const ExplicitlyGrantedProfilesTab = () => {
  const { companyId, companyDepartmentId } = useParams();
  const {
    bulkDestroyExplicitlyGrantedProfiles,
    explicitlyGrantedProfiles,
    explicitlyGrantedProfilesMeta,
    explicitlyGrantedProfilesLoadingStatus,
    explicitlyGrantedProfilesLoadingMoreStatus,
    loadExplicitlyGrantedProfiles,
    loadMoreExplicitlyGrantedProfiles,
  } = useAdminCompanyDepartmentLimitedAccessExplicitlyGrantedProfiles();

  const handleExplicitlyGrantedProfilesFilter = () => loadExplicitlyGrantedProfiles(companyId, companyDepartmentId);
  const handleExplicitlyGrantedProfilesLoad = (page) =>
    loadMoreExplicitlyGrantedProfiles(companyId, companyDepartmentId, { page });

  useEffect(() => {
    handleExplicitlyGrantedProfilesFilter();
  }, []);

  const handleProfilesBulkDestroy = async (profileIds) => {
    await bulkDestroyExplicitlyGrantedProfiles(companyId, companyDepartmentId, { profileIds });
    handleExplicitlyGrantedProfilesFilter();
  };

  return (
    <div>
      <Typography className={styles.title} variant="h3">
        Profiles added to the &lsquo;Limited Access&rsquo; scope by customer via credit usage will appear here.
      </Typography>
      <ProfileList
        profiles={explicitlyGrantedProfiles}
        loading={
          explicitlyGrantedProfilesLoadingStatus.isPending || explicitlyGrantedProfilesLoadingMoreStatus.isPending
        }
        nextPage={explicitlyGrantedProfilesMeta.nextPage}
        totalCount={explicitlyGrantedProfilesMeta.totalCount}
        onDelete={handleProfilesBulkDestroy}
        onLoad={handleExplicitlyGrantedProfilesLoad}
      />
    </div>
  );
};

export default ExplicitlyGrantedProfilesTab;
