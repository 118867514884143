import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(params = {}) {
    const url = Routes.apiV1CurrentUserSubscriptionsPath(params);

    return FetchHelpers.get(url);
  },

  create(subscription) {
    const url = Routes.apiV1CurrentUserSubscriptionsPath();

    return FetchHelpers.post(url, { subscription });
  },

  destroy(id) {
    const url = Routes.apiV1CurrentUserSubscriptionPath(id);

    return FetchHelpers.delete(url);
  },

  batchUpdate(subscriptions) {
    const url = Routes.batchUpdateApiV1CurrentUserSubscriptionsPath();

    return FetchHelpers.post(url, { subscriptions });
  },
};
